import { Injectable } from '@angular/core';
import { ConsultRetainedResponse, TRetained } from '../interface/dto/ConsultRetainedResponse';
import { StorageService } from './storage.service';
import { IBaseService } from './i-base-service.service';
import { AccountInfoStatusService } from './account-info-status.service';
import { ConsultRetainedRequest } from '../interface/dto/ConsultRetainedRequest';
import { Constants, Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { BusinessError } from 'src/core/exceptions/BusinessError';

@Injectable({
  providedIn: 'root',
})
export class RetainedBalanceService {
   constructor(
    private readonly storageService: StorageService,
    private readonly baseService: IBaseService,
    private readonly accountInfoService: AccountInfoStatusService
  ) { }

  /**
   * Obtiene movimientos retenidos
   */
  async getRetainedBalance(): Promise<Array<TRetained>> {
      const request: ConsultRetainedRequest = new ConsultRetainedRequest({
        sesion: this.storageService.getSession(),
        idCuentaProducto: this.accountInfoService.account.idCuentaProducto
      });

      const response = await this.baseService.genericPost<ConsultRetainedResponse>(request, { isSOA: true });
      if (!response || !response?.retencion || response?.retencion.length === Numbers.Zero) {
        throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
      }
      return response.retencion;
  }

}
