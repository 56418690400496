import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  WritableSignal,
  signal
} from '@angular/core';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { map } from 'rxjs';
import { MapInfoWindow } from '@angular/google-maps';
import { ClassHTML, Constants, Length, Numbers } from 'src/core/constants/Constants';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Resources } from 'src/core/constants/Resources';
import { Directions, DataCheckbookService } from 'src/app/services/data-checkbook.service';
import { Strings } from 'src/core/constants/Strings';
import { environment } from 'src/environments/environment';
import { LocationConstants } from 'src/core/constants/LocationConstants';
import { AforeStrings } from 'src/core/constants/AforeStrings';
import { ModalService } from 'src/app/shared/modal.service';
import { StringsChecks } from 'src/core/constants/ChecksStrings';
import { FormRegex } from 'src/core/constants/FormConstants';
import { ControlCardService } from 'src/app/services/control-card.service';
import { ModalUtils } from 'src/core/utils/ModalUtils';
import { ModalEndProcessAlertComponent } from 'src/app/shared/components/modal-end-process-alert/modal-end-process-alert.component';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalMyCardsExtensionTwoService } from 'src/app/services/modal-my-cards.extension-two.service';
import { MapService, PickerOptions } from 'src/app/services/map.service';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { StateCardNumberInfoService } from 'src/app/services/state-card-number-info.service';

@Component({
  selector: 'app-modal-map',
  templateUrl: './modal-map.component.html',
  styleUrls: ['./modal-map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalMapComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild('main', { static: false }) map: ElementRef<HTMLElement>;
  @Input() headerTemplate: TemplateRef<HTMLElement> = null;
  @Input() footerTemplate: TemplateRef<HTMLElement> = null;
  @Input() customComponentTemplate: TemplateRef<HTMLElement> = null;
  @Input() isCustomTemplate: boolean = false;
  @Input() context: unknown;
  @Input() isAfore: boolean = true;
  @Input() isChecksRequest:boolean = false;
  @Input() title: string = AforeStrings.AFORE_TITLE;
  @Input() subtitle: string = AforeStrings.AFORE_SUBTITLE;
  @Input() classBtnContinue: string = ClassHTML.SendButton;
  @Input() textErrorButton: string = Strings.MSG_POPUP_ACCEPT;
  @Input() isControlCard:boolean = false;
  markerPositions: google.maps.LatLngLiteral[] = this.mapService.markerPositions;
  markerOptions: google.maps.MarkerOptions = this.mapService.markerOptions;
  directions: WritableSignal<Array<Directions>> = this.mapService.directionsSignal;
  directionCopy: WritableSignal<Array<Directions>> = this.mapService.directionCopySignal;
  hasResult: WritableSignal<boolean> = this.mapService.hasResultSignal;
  errorMessage: WritableSignal<string> = this.mapService.errorMessageSignal;
  beta: boolean = environment.beta;
  selectedId: WritableSignal<PickerOptions> = this.mapService.selectedIdSignal;
  modalInstance: NgbModalRef;
  frameClose: string = Resources.CLOSE_CIRCLE_BLUE;
  withoutResults: WritableSignal<boolean> = this.mapService.withoutResultsSignal;
  iconChevron: string = Resources.BTN_CHEVRON_RIGHT;
  @Input() functionClose?: () => void;
  isMobile: boolean = false;
  mapHeight: WritableSignal<number> = signal<number>(Numbers.OneHundred);
  mapWidth: WritableSignal<number> = signal<number>(Numbers.OneHundred);
  branchSelection: string = StringsChecks.branchSelection;
  nearbyBranchPickupPrompt: string = StringsChecks.nearbyBranchPickupPrompt;
  stringLocation = FormRegex.Locations;
  @ViewChild(MapInfoWindow) infoWindow?: MapInfoWindow;
  withResults: WritableSignal<boolean> = this.mapService.withResultsSginal;
  display: {
    lat: number;
    lng: number;
  };
  showMap: WritableSignal<boolean> = this.mapService.showMapSignal;
  isCancellation: boolean = false;

  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => {
      this.isMobile = breakpoints.mobile;
      const maxHeight = LocationConstants.MAX_HEIGHT_MAP;
      this.mapHeight.set(breakpoints.mobile ? Numbers.ThreeHundred : maxHeight);
      return { breakpoints };
    })
  );

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly dataCheckbookService: DataCheckbookService,
    readonly componentExtensionTwo: ModalMyCardsExtensionTwoService,
    private readonly controlCard:ControlCardService,
    private readonly mapService: MapService,
    private readonly cardNumberInfoService: StateCardNumberInfoService
  ) {}

  ngOnDestroy(): void {
    this.mapService.resetSelection();
  }

  async ngOnInit() {
    this.isCancellation = this.cardNumberInfoService.isCardCancelling;
    await this.mapService.getDirections();
    setTimeout(() => this.calculate(), Numbers.Zero);
  }

  @HostListener('window:resize', ['$event'])
  calculate() {
    this.mapWidth.set(this.map?.nativeElement?.offsetWidth);
  }

  ngAfterViewChecked(): void {
    this.calculate();
  }

  ngAfterViewInit(): void {
    this.calculate();
  }

  close() {
    if (this.isControlCard) {
      const options: ModalOptions = {
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeSm,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAlertEndProcess
      };
      this.controlCard.controlMapClose = true;
      ModalUtils.showModal(this.modalService, ModalEndProcessAlertComponent, options, ModalConstants.MODAL_OPTIONS.ModalConent, ModalConstants.MODAL_OPTIONS.ModalMap);
      this.componentExtensionTwo.backdropCustom();
    } else if (this.functionClose) {
      this.functionClose();
    } else {
      this.modalInstance?.close();
    }
  }

  receiveDataFromSearchMap(searchFilter: string) {
    this.mapService.directionCopy = [...this.directions()];

    if (searchFilter === Strings.EMPTY) {
      this.mapService.withResults = true;
      this.dataCheckbookService.resultsBranches = true;
    } else {
      const result = this.directionCopy().filter((directionFiltered) => directionFiltered.address.toLowerCase().includes(searchFilter.toLowerCase()));
      if (result.length === Length.Empty) {
        this.dataCheckbookService.resultsBranches = false;
        this.mapService.withResults = false;
      } else {
        this.dataCheckbookService.resultsBranches = true;
        this.mapService.withResults = true;
        this.setLocation(this.directionCopy().findIndex(item => item.id === result[Numbers.Zero].id));
      }
      this.mapService.directionCopy = result;
    }

    if(this.withResults() && searchFilter !== Strings.EMPTY) {
      setTimeout(() => this.mapService.selectedId = { id: Numbers.Zero, scroll: false });
    }
  }

  setLocation(event: number){
    this.mapService.setLocation(event);
  }

  setTab(event: number){
    this.mapService.selectedId = { id: event, scroll: true };
  }

  @HostListener('window:popstate')
  onBack() {
    this.modalInstance?.close();
  }

}
