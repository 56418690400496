import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IServiceTransferId } from '../interface/IServiceTransferId';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
  providedIn: 'root'
})
export class ServiceTransferIdService {

  private readonly stateTransferId: BehaviorSubject<IServiceTransferId> = new BehaviorSubject<IServiceTransferId>({
    id: Strings.EMPTY
    });

  constructor() { }

  get stateTransferIdObservable() {
    return this.stateTransferId.asObservable();
  }

  set stateTransferIdData(data: IServiceTransferId) {
    this.stateTransferId.next(data);
  }

  get stateTransferIdServiceBS(): BehaviorSubject<IServiceTransferId> {
    return this.stateTransferId;
  }
  clearStateTransferId(){
    this.stateTransferId.next({id: Strings.EMPTY});
  }
}
