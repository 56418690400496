import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccountModel } from '../interface/AccountModel';

@Injectable({
  providedIn: 'root'
})
export class AccountInfoStatusService {
  private emptyAccount: AccountModel = { descripcionCuenta: '', numeroCuenta: '', cuentaClabe: '', NumeroTarjeta: '', idCuentaProducto: 0 };
  private readonly _account: BehaviorSubject<AccountModel> = new BehaviorSubject<AccountModel>(this.emptyAccount);
  private readonly _accountInvestment: BehaviorSubject<IAccountsInvestment> = new BehaviorSubject<IAccountsInvestment>({
    debitCards: []
  });

  constructor() { }

  get account$() {
    return this._account.asObservable();
  }

  get account(){
    return this._account.getValue();
  }

  set account(data: AccountModel) {
    this._account.next(data);
  }

  
  get accountInvestment$() {
    return this._accountInvestment.asObservable();
  }

  get accountInvestment(){
    return this._accountInvestment.getValue();
  }

  set accountInvestment(data: IAccountsInvestment) {
    this._accountInvestment.next(data);
  }

  clearAccount() {
    this.emptyAccount = { descripcionCuenta: '', numeroCuenta: '', cuentaClabe: '', NumeroTarjeta: '', idCuentaProducto: 0};
    this._account.next(this.emptyAccount);
  }
}

interface IAccountsInvestment{
  debitCards?: AccountModel[]
}
