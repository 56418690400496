import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatestWith, map, tap } from 'rxjs';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { AccountCardService } from 'src/app/services/account-card.service';
import { AlertService } from 'src/app/services/alert.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { SuaService } from 'src/app/services/sua.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Char, Constants, ElementsHTML, FormatDate, Regex } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { SuaConstants } from 'src/core/constants/SuaConstants';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { FileUtils } from 'src/core/utils/FileUtils';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';
import { Utils } from 'src/core/utils/utils';

@Component({
  selector: 'app-complete-sua-payment',
  templateUrl: './complete-sua-payment.component.html',
  styleUrls: ['./complete-sua-payment.component.css']
})
export class CompleteSuaPaymentComponent implements OnInit {
  completeDate: string = Strings.EMPTY;
  contentMargin: string = ElementsHTML.ContentMargin;
  selectedCard: ICardAccount;
  maskedAccountNumber: string = Strings.EMPTY;
  show = new BehaviorSubject<boolean>(false);
  paymentFolio: string = Strings.EMPTY;
  errorMsg: string = Strings.EMPTY;
  servicesHome: string = PATH.Services;
  otpCode: string = Strings.EMPTY;

  vm$ = this.responsiveService.observe.pipe(
    tap(breakpoints => {
      this.contentMargin = breakpoints.mobile ? ElementsHTML.ContentMargin : ElementsHTML.ContentMarginBreakpoints;
      this.completeDate = formatDate(
        new Date(),
        FormatDate.DayMonthYearComplete,
        Constants.LOCALE_ES_MX
      );
    }),
    combineLatestWith(
      this.accountCardService.accountCard$,
      this.notifyAmountService.amount$,
      this.suaService.selectedRegistry$,
      this.show.asObservable(),
      this.stateServicePayIdService.service$
    ),
    map(([breakpoints, account, amount, selected, show, service]) => ({ breakpoints, account, amount, selected, show, service }))
  );
  greenCheckFill: string = Resources.GREEN_CHECK_FILL;

  constructor(
    private readonly router: Router,
    private readonly responsiveService: ResponsiveService,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly accountCardService: AccountCardService,
    private readonly modalService: ModalService,
    private readonly changeDetectorRef : ChangeDetectorRef,
    private readonly suaService: SuaService,
    private readonly stateServicePayIdService: StateServicePayIdService,
    private readonly alertService: AlertService
  ) {
    if (router.getCurrentNavigation()?.extras?.state) {
      this.otpCode = router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.OtpCode];
    }
  }

  async ngOnInit(): Promise<void> {
    NavigationUtils.fixNavigation(this.changeDetectorRef, [PATH.Services, PATH.SuaPaymentCompleted]);
    if(!this.notifyAmountService.amount.amount){
      this.router.navigate([PATH.Services]);
      return;
    }
    this.selectedCard = this.accountCardService.accountCard;
    this.maskedAccountNumber = this.selectedCard.cardNumber.replace(Regex.HideAccountNumber, Char.Asterisk);
    await this.completePayment();
  }

  next() {
    this.router.navigate([PATH.Home]);
  }

  back() {
    this.router.navigate([PATH.Services]);
  }

  ngOnDestroy() {
    this.modalService.close();
  }

  async completePayment() {
    try{
      this.paymentFolio = await this.suaService.suaPayment(this.otpCode);
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.errorMsg = Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code);
    }
    this.show.next(true);
  }

  async downloadPdf() {
    try {
      const response = await this.suaService.getSuaPaymentVaucher();
      if(!response?.Comprobante){
        throw new BusinessError(Strings.ERROR_SERVICE_GENERIC, Constants.NULL_CODE);
      }

      FileUtils.downloadPdf(response.Comprobante, SuaConstants.NAME_PDF_SUA_PAYMENT);
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        imgHead: Resources.CLOSE_ICON_RED,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        btnExit: false
      });
    }
  }
}
