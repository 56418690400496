import { PathModel } from 'src/app/models/PathModel';

type TAforePartialWithdrawalsFoliosRequest = {
    nss: string,
    tipo: string,
    otp: string
};

export class AforePartialWithdrawalsFoliosRequest {
    constructor(public data: TAforePartialWithdrawalsFoliosRequest) { }

    get endpoint(): PathModel {
        return PathModel.AforePartialWithdrawalFolios;
    }

}
