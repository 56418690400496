import { PathModel } from '../../models/PathModel';

interface IGetWithdrawalsRequest {
  idCuentaProductoOrigen     : number;
  idCuentaProductoDestino    : number;
  monto                      : number;
  dias                       : number;
}

export class GetWithdrawalsRequest{
  constructor (public data: IGetWithdrawalsRequest){}

  get endpoint(): PathModel {
    return PathModel.WithdrawalsCodeFlex;
  }
}
