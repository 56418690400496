import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
    providedIn: 'root'
})
export class CheckBoxService {
    private _checkboxState: boolean = false;
    private _checkValue: string = Strings.EMPTY;
    private _status: string = Strings.EMPTY;
    private _book: string = Strings.EMPTY;
    private _reason: string = Strings.EMPTY;
    private _bookReturn: string = Strings.EMPTY;
    private _homeChecks: boolean = false;
    private _reasonTwo: string = Strings.EMPTY;
    private _showDataChecks: {showChecks: boolean, account: number} = {showChecks: false, account: Numbers.Zero};
    private readonly disabledBtn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly checkBoxReset: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() { }

    setCheckState(state: boolean) {
        this._checkboxState = state;
    }

    getCheckboxState(){
        return this._checkboxState;
    }

    setCheckValue(value: string){
        this._checkValue = value;
    }

    getCheckValue(): string{
        return this._checkValue;
    }

    setCheckValueState (value:string){
        this._status = value;
    }

    getCheckValueState(): string {
        return this._status;
    }

    setBookValue(value: string){
        this._book = value;
    }

    getBookValue(): string{
        return this._book;
    }

    setReasonValue(value: string){
        this._reason = value;
    }

    getReasonValue(): string{
        return this._reason;
    }

    setBookReturn(value: string){
        this._bookReturn = value;
    }

    getBookReturn(): string{
        return this._bookReturn;
    }

    setCheckHome(state: boolean) {
        this._homeChecks = state;
    }

    getCheckHome(){
        return this._homeChecks;
    }
    setReasonTwoValue(value: string){
        this._reasonTwo = value;
    }

    getReasonTwoValue(): string{
        return this._reasonTwo;
    }
    reset() {
        this._checkboxState = false;
        this._checkValue = Strings.EMPTY;
        this._status = Strings.EMPTY;
        this._reasonTwo = Strings.EMPTY;
    }

    resetBook() {
        this._bookReturn = Strings.EMPTY;
        this._reason = Strings.EMPTY;
        this._book = Strings.EMPTY;
    }
    resetHome(){
        this._homeChecks = false;
        this._checkboxState = false;
    }
    resetReasonTwo(){
        this._reasonTwo = Strings.EMPTY;
    }
    getDisabledBtn(){
      return this.disabledBtn;
    }
    setDisabledBtn(value: boolean) {
      this.disabledBtn.next(value);
   }

   get checkBoxReset$(): Observable<boolean>{
    return this.checkBoxReset;
   }

   set checkBoxResetValue(value: boolean){
    this.checkBoxReset.next(value);
   }

    get showDataChecks() {
      return this._showDataChecks;
    }

    set showDataChecks(data: {showChecks: boolean, account: number}) {
      this._showDataChecks = data;
    }

}
