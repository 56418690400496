import { PathModel } from 'src/app/models/PathModel';

export interface IUserTaxDataRequest {
    IdSession: string;
    CodigoUsuario:string;
    RegimenFiscales?:string[]
    RegimenModificado?:string;
    NombreFiscal?: string;
    CodigoPostalFiscal?: string;
}

export class ModifyTaxDataRequest {
    constructor(public data: IUserTaxDataRequest) { }
    get endpoint(): PathModel {
        return PathModel.EditUserTaxData;
    }
}
