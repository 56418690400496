import { PathModel } from 'src/app/models/PathModel';

type TCancelInternationalAgendaRequest = {
    
        IdSession : string,
        IdAgenda : string,
        TipoAgenda : string,
        NumeroCuenta : null,
        TipoAcceso : string,
        Token : {
            OTP : string,
            HAC : null,
            NumeroDeSerie : null
          }
};
export class CancelInternationalAgendaRequest {
    constructor(public data: TCancelInternationalAgendaRequest) { }

    get endpoint(): PathModel {
        return PathModel.CancelInternationalAgendas;
    }
}