<div class="form-code-security-alert mt-32" *ngIf="alert">
  <div class="col-10">
        <span class="detail-m color-white">Utiliza el token móvil de tu app Inbursa Móvil para realizar esta
            validación</span>
  </div>

  <div class="col-2 d-flex  justify-content-end align-content-end ">
    <a class="a-boton " role="button" (click)="closeAlert()">
      <img alt="Close icon" [src]="closeIcon | cdnImage" width="auto" height="auto">
    </a>
  </div>
</div>

<div class="pt-2" [class.mt-5-CS]="alert" [class.pt-3]="alert">
  <div class="d-flex justify-content-center align-items-center gap-1-CS">
    <label class="button-s">{{head}}</label>
    <div class="d-flex justify-content-center align-items-center">
      <img class="icon-image-security cursor-pointer" tooltipClass="my-custom-class" width="auto" height="auto"
           [src]="helpIcon | cdnImage" alt="Help icon" (click)="showModalHelpToken()">
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div class="d-flex flex-column token-input-container">
  <div class="mt-3 d-flex justify-content-center input-border">
    <input (input)="inputToken(1)" type="text" required id="token1" name="token1" #token1 autofocus
           (keyup)="keyToken($event, 1, {token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})"
           onpaste="return false" class="input-token-code number-token-code text-center form-control input-otp"
           maxlength="2" [appValidateInputRegex]="regexT" autocomplete="off"
           [ngClass]="tokenError1? 'border-error':''" (input)="valueToken1(token1.value)" inputmode="numeric">
    <input (input)="inputToken(2)" type="text" required id="token2" name="token2" #token2
           (keyup)="keyToken($event, 2, {token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})"
           onpaste="return false" class="input-token-code number-token-code text-center form-control input-otp"
           maxlength="2" [appValidateInputRegex]="regexT" autocomplete="off"
           [ngClass]="tokenError2? 'border-error':''" (input)="valueToken2(token2.value)" inputmode="numeric">
    <input (input)="inputToken(3)" type="text" required id="token3" name="token3" #token3
           (keyup)="keyToken($event, 3, {token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})"
           onpaste="return false" class="input-token-code number-token-code text-center form-control input-otp"
           maxlength="2" [appValidateInputRegex]="regexT" autocomplete="off"
           [ngClass]="tokenError3? 'border-error':''" (input)="valueToken3(token3.value)" inputmode="numeric">
    <input (input)="inputToken(4)" type="text" required id="token4" name="token4" #token4
           (keyup)="keyToken($event, 4, {token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})"
           onpaste="return false" class="input-token-code number-token-code text-center form-control input-otp"
           maxlength="2" [appValidateInputRegex]="regexT" autocomplete="off"
           [ngClass]="tokenError4? 'border-error':''" (input)="valueToken4(token4.value)" inputmode="numeric">
    <input (input)="inputToken(5)" type="text" required id="token5" name="token5" #token5
           (keyup)="keyToken($event, 5, {token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})"
           onpaste="return false" class="input-token-code number-token-code text-center form-control input-otp"
           maxlength="2" [appValidateInputRegex]="regexT" autocomplete="off"
           [ngClass]="tokenError5? 'border-error':''" (input)="valueToken5(token5.value)" inputmode="numeric">
    <input (input)="inputToken(6)" type="text" required id="token6" name="token6"
           class="input-token-code number-token-code text-center form-control input-otp" maxlength="2" #token6
           (keyup)="keyToken($event, 6, {token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})"
           onpaste="return false" [appValidateInputRegex]="regexT" autocomplete="off"
           [ngClass]="tokenError6? 'border-error':''" (input)="valueToken6(token6.value)" inputmode="numeric">
    <input (input)="inputToken(7)" type="text" required id="token7" name="token7" #token7
           (keyup)="keyToken($event, 7, {token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})"
           onpaste="return false" class="input-token-code number-token-code text-center form-control input-otp"
           maxlength="2" [appValidateInputRegex]="regexT" autocomplete="off"
           [ngClass]="tokenError7? 'border-error':''" (input)="valueToken7(token7.value)" inputmode="numeric">
    <input (input)="inputToken(8)" type="text" required id="token8" name="token8" #token8
           (keyup)="keyToken($event, 8, {token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})"
            onpaste="return false" class="input-token-code number-token-code text-center form-control input-otp"
            maxlength="2" [appValidateInputRegex]="regexT" autocomplete="off"
            [ngClass]="tokenError8? 'border-error':''" (input)="valueToken8(token8.value)" inputmode="numeric"
            (blur)="valueToken8(token8.value)">
    </div>
      <span class="detail-s label-error label-error-b" *ngIf="tokenError">
        Este campo es requerido</span>
    </div>
  </div>

  <div class="mt-4 pt-2 d-flex justify-content-center">
    <button class="button-m mb-3 btn-form-code-security btn-generic"
            (click)="showConfirmAddress({token1: token1.value, token2: token2.value,
              token3: token3.value, token4: token4.value, token5: token5.value, token6: token6.value,
              token7: token7.value, token8: token8.value})">Continuar
      <img alt="Continue icon" [src]="chevronRight | cdnImage" width="auto" height="auto"></button>
  </div>
</div>

<ng-template #modalToken>
    <app-modal-help-token [modalId]="modalHelpToken"></app-modal-help-token>
</ng-template>
