import { Injectable } from '@angular/core';
import { ILocalTaxes } from 'src/app/interface/ILocalTaxes';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Strings } from 'src/core/constants/Strings';
import { InputConfig } from 'src/app/interface/IInputConfig';
import { FormValidations, InputTypes } from 'src/core/constants/Constants';
import { Validators } from '@angular/forms';
import { LocalTaxesConstants } from 'src/core/constants/LocalTaxesConstants';
import { Numbers } from 'src/core/constants/Numbers';
import { PaymentServiceCardStatusService } from 'src/app/services/payment-service-card-status.service';
import { NavigationStart, Router } from '@angular/router';
import { LocalTaxesCardService } from 'src/app/services/local-taxes-card.service';
import { GetIFMPromotionCDMXRequest } from 'src/app/interface/dto/GetIFMPromotionCDMXRequest';
import { IGetIFMPromotionCDMXResponse } from 'src/app/interface/dto/GetIFMPromotionCDMXResponse';
import { LocalTaxesDynamicService } from 'src/app/services/local-taxes-dynamic-form.service';
import { ValidLineResponse } from 'src/app/interface/dto/ValidLineCaptureResponse';
import { IAdditionalTaxes } from 'src/app/interface/IAdditionalTaxes';
import { StorageService } from 'src/app/services/storage.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { ServiceConstants } from 'src/core/constants/ServiceConstants';

@Injectable({
  providedIn: 'root'
})

export class LocalTaxesService {
  private _goback:boolean = false;
  private readonly _basicValidation = {
    name: FormValidations.Required,
    validator: Validators.required,
    message: Strings.GENERIC_ERRORS.Required
  };

  private readonly _minLengthValidation = {
    name: FormValidations.MinLength,
    validator: Validators.minLength(Numbers.Sixteen),
    message: LocalTaxesConstants.MESSAGES.LineCapture
  };

  private readonly _captureLineForm: InputConfig[] = [
    {
      type: InputTypes.Text,
      label: LocalTaxesConstants.FORM_CONTROLS.CaptureLineLabel,
      name: LocalTaxesConstants.FORM_CONTROLS.CaptureLineName,
      placeholder: Strings.EMPTY,
      value: Strings.EMPTY,
      maxlength: Numbers.TwentySeven,
      validations: [
        this._basicValidation,
        this._minLengthValidation
      ]
    }
  ];

  private readonly _localTaxesForm: InputConfig[] = [
    {
      type: InputTypes.Text,
      label: LocalTaxesConstants.FORM_CONTROLS.ConceptLabel,
      name: LocalTaxesConstants.FORM_CONTROLS.ConceptName,
      placeholder: Strings.EMPTY,
      value: Strings.EMPTY
    }
  ];

  private readonly _supplyWaterTaxesForm: InputConfig[] = [
    {
      type: InputTypes.Text,
      label: LocalTaxesConstants.FORM_CONTROLS.FiscalExerciseLabel,
      name: LocalTaxesConstants.FORM_CONTROLS.FiscalExerciseName,
      placeholder: Strings.EMPTY,
      value: Strings.EMPTY
    },
    {
      type: InputTypes.Text,
      label: LocalTaxesConstants.FORM_CONTROLS.BimesterLabel,
      name: LocalTaxesConstants.FORM_CONTROLS.BimesterName,
      placeholder: Strings.EMPTY,
      value: Strings.EMPTY
    },
    {
      type: InputTypes.Text,
      label: LocalTaxesConstants.FORM_CONTROLS.WaterAccountNumberLabel,
      name: LocalTaxesConstants.FORM_CONTROLS.WaterAccountNumberName,
      placeholder: LocalTaxesConstants.WATER_SUPPLY_ACCOUNT_PLACEHOLDER,
      value: Strings.EMPTY,
      validations: [
        this._basicValidation,
        {
          name: FormValidations.MinLength,
          validator: Validators.minLength(Numbers.Two),
          message: LocalTaxesConstants.MESSAGES.InvalidWaterAccount
        }
      ]
    }
  ];

  private readonly _propertyTaxesForm: InputConfig[] = [
    {
      type: InputTypes.Text,
      label: LocalTaxesConstants.FORM_CONTROLS.FiscalExerciseLabel,
      name: LocalTaxesConstants.FORM_CONTROLS.FiscalExerciseName,
      placeholder: Strings.EMPTY,
      value: Strings.EMPTY
    },
    {
      type: InputTypes.Text,
      label: LocalTaxesConstants.FORM_CONTROLS.BimesterLabel,
      name: LocalTaxesConstants.FORM_CONTROLS.BimesterName,
      placeholder: Strings.EMPTY,
      value: Strings.EMPTY,
      hide:false
    }
  ];

  private readonly _paymentTypeForm: InputConfig[] = [
    {
      type: InputTypes.Select,
      label: LocalTaxesConstants.FORM_CONTROLS.PaymentTypeLabel,
      name: LocalTaxesConstants.FORM_CONTROLS.PaymentTypeName,
      value: Strings.EMPTY,
      options: [
        LocalTaxesConstants.TDC_PAYMENT.Msi0,
        LocalTaxesConstants.TDC_PAYMENT.Msi3,
        LocalTaxesConstants.TDC_PAYMENT.Msi6
      ],
      validations: [ this._basicValidation]
    }
  ];

  private readonly _vehicleTaxForm: InputConfig[]  = [
    {
      type: InputTypes.Text,
      label: LocalTaxesConstants.FORM_CONTROLS.BrandLabel,
      name: LocalTaxesConstants.FORM_CONTROLS.BrandName,
      placeholder: Strings.EMPTY,
      value: Strings.EMPTY,
      maxlength: Numbers.TwentyFive,
      validations: [ this._basicValidation]
    },
    {
      type: InputTypes.Text,
      label: LocalTaxesConstants.FORM_CONTROLS.BillValueLabel,
      name: LocalTaxesConstants.FORM_CONTROLS.BillValueName,
      placeholder: Strings.EMPTY,
      value: Strings.EMPTY,
      maxlength: Numbers.Twenty,
      validations: [ this._basicValidation]
    },{
      type: InputTypes.Text,
      label: LocalTaxesConstants.FORM_CONTROLS.SubBrandLabel,
      name: LocalTaxesConstants.FORM_CONTROLS.SubBrandName,
      placeholder: Strings.EMPTY,
      value: Strings.EMPTY,
      maxlength: Numbers.TwentyFive,
      validations: [ this._basicValidation]
    },
    {
      type: InputTypes.Text,
      label: LocalTaxesConstants.FORM_CONTROLS.NumCilindersLabel,
      name: LocalTaxesConstants.FORM_CONTROLS.NumCilindersName,
      placeholder: Strings.EMPTY,
      value: Strings.EMPTY,
      maxlength: Numbers.Two,
      validations: [ this._basicValidation]
    }
  ];
  private readonly _dynamicForm=[
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TRANSAC_CAR.ID,
      hasrange:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TRANSAC_CAR.CODE_RANGE.IS_RANGE,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TRANSAC_CAR.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TRANSAC_CAR.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TRANSAC_CAR.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TRANSAC_CAR.CODE_RANGE.LIST,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TRANSAC_CAR.NAME,
      inputFields: LocalTaxesDynamicService._car
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.POLLUTING_VEHICLES.ID,
      hasrange:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.POLLUTING_VEHICLES.CODE_RANGE.IS_RANGE,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.POLLUTING_VEHICLES.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.POLLUTING_VEHICLES.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.POLLUTING_VEHICLES.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.POLLUTING_VEHICLES.CODE_RANGE.LIST,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.POLLUTING_VEHICLES.NAME,
      inputFields: LocalTaxesDynamicService._pollutingVehicles
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.ID,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.NAME,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.CODE_RANGE.LIST,
      inputFields: LocalTaxesDynamicService._tenure
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.GUARDIANSHIP.ID,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.GUARDIANSHIP.NAME,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.GUARDIANSHIP.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.GUARDIANSHIP.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.GUARDIANSHIP.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.GUARDIANSHIP.CODE_RANGE.LIST,
      inputFields: LocalTaxesDynamicService._guardianship
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSE.ID,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSE.NAME,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSE.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSE.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSE.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSE.CODE_RANGE.LIST,
      inputFields: LocalTaxesDynamicService._license
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VERIFICATION_FINES.ID,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VERIFICATION_FINES.NAME,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VERIFICATION_FINES.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VERIFICATION_FINES.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VERIFICATION_FINES.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VERIFICATION_FINES.CODE_RANGE.LIST,
      inputFields: LocalTaxesDynamicService._verification
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.ID,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.NAME,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.CODE_RANGE.LIST,
      inputFields: LocalTaxesDynamicService._current
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.DUTY.ID,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.DUTY.NAME,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.DUTY.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.DUTY.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.DUTY.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.DUTY.CODE_RANGE.LIST,
      inputFields: LocalTaxesDynamicService._duty
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.ARCHIVE_NOTARIES.ID,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.ARCHIVE_NOTARIES.NAME,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.ARCHIVE_NOTARIES.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.ARCHIVE_NOTARIES.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.ARCHIVE_NOTARIES.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.ARCHIVE_NOTARIES.CODE_RANGE.LIST,
      inputFields: LocalTaxesDynamicService._notaries
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.RECTIFICATION_MINUTES.ID,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.RECTIFICATION_MINUTES.NAME,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.RECTIFICATION_MINUTES.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.RECTIFICATION_MINUTES.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.RECTIFICATION_MINUTES.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.RECTIFICATION_MINUTES.CODE_RANGE.LIST,
      inputFields: LocalTaxesDynamicService._rectification
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.COPIES_DOCUMENT.ID,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.COPIES_DOCUMENT.NAME,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.COPIES_DOCUMENT.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.COPIES_DOCUMENT.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.COPIES_DOCUMENT.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.COPIES_DOCUMENT.CODE_RANGE.LIST,
      inputFields: LocalTaxesDynamicService._copy
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.DIVORCE.ID,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.DIVORCE.NAME,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.DIVORCE.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.DIVORCE.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.DIVORCE.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.DIVORCE.CODE_RANGE.LIST,
      inputFields: LocalTaxesDynamicService._divorce
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.MOTORCYCLE.ID,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.MOTORCYCLE.NAME,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.MOTORCYCLE.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.MOTORCYCLE.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.MOTORCYCLE.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.MOTORCYCLE.CODE_RANGE.LIST,
      inputFields: LocalTaxesDynamicService._motorcycle
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSES.ID,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSES.NAME,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSES.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSES.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSES.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSES.CODE_RANGE.LIST,
      inputFields: LocalTaxesDynamicService._licenses
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VIOLATIONS.ID,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VIOLATIONS.NAME,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VIOLATIONS.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VIOLATIONS.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VIOLATIONS.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VIOLATIONS.CODE_RANGE.LIST,
      inputFields: LocalTaxesDynamicService._violations
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.IMMOBILIZER.ID,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.IMMOBILIZER.NAME,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.IMMOBILIZER.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.IMMOBILIZER.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.IMMOBILIZER.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.IMMOBILIZER.CODE_RANGE.LIST,
      inputFields: LocalTaxesDynamicService._immobilizer
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.HOLOGRAMS.ID,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.HOLOGRAMS.NAME,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.HOLOGRAMS.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.HOLOGRAMS.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.HOLOGRAMS.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.HOLOGRAMS.CODE_RANGE.LIST,
      inputFields: LocalTaxesDynamicService._hologram
    },
    {
      id:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TREASURY.ID,
      name:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TREASURY.NAME,
      range: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TREASURY.CODE_RANGE.IS_RANGE ?
      this.makeSequence(LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TREASURY.CODE_RANGE.START,
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TREASURY.CODE_RANGE.END):
        LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TREASURY.CODE_RANGE.LIST,
      inputFields: LocalTaxesDynamicService._treasury
    }
  ];
  private _detailLine:ValidLineResponse;
  private _dynamicFormData:IAdditionalTaxes[];
  private _dynamicStructure:InputConfig[];
  private _dynamicId:string;
  private readonly localTaxesDefault = {
    bimestre: Strings.EMPTY,
    fechaPago: Strings.EMPTY,
    noCuentaAgua: Strings.EMPTY,
    concept: Strings.EMPTY
  };

  private readonly _localTaxes: BehaviorSubject<ILocalTaxes> = new BehaviorSubject<ILocalTaxes>(this.localTaxesDefault);
  private readonly _deferredPayment: BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);
  private readonly _getIFMPromotionCDMXResponse: BehaviorSubject<IGetIFMPromotionCDMXResponse> = new BehaviorSubject<IGetIFMPromotionCDMXResponse>(null);
  constructor(
    private readonly paymentStatus: PaymentServiceCardStatusService,
    private readonly router: Router,
    private readonly cardSvc: LocalTaxesCardService,
    private readonly storageService: StorageService,
    private readonly baseService: IBaseService) { }

  get localTaxes$() {
    return this._localTaxes.asObservable();
  }

  set localTaxes(data: ILocalTaxes) {
    this._localTaxes.next(data);
  }

  get localTaxes() : ILocalTaxes{
    return this._localTaxes.getValue();
  }

  get deferredPayment$() {
    return this._deferredPayment.asObservable();
  }

  set deferredPayment(data: string) {
    this._deferredPayment.next(data);
  }

  get deferredPayment() : string{
    return this._deferredPayment.getValue();
  }

  get getIFMPromotionCDMXResponse$() {
    return this._getIFMPromotionCDMXResponse.asObservable();
  }

  set getIFMPromotionCDMXResponse(data: IGetIFMPromotionCDMXResponse) {
    this._getIFMPromotionCDMXResponse.next(data);
  }

  get getIFMPromotionCDMXResponse() : IGetIFMPromotionCDMXResponse{
    return this._getIFMPromotionCDMXResponse.getValue();
  }

  clearLocalTaxes(): void {
    this._localTaxes.next(this.localTaxesDefault);
    this.paymentStatus.status = false;
    this.paymentStatus.checkStatus = false;
    this.paymentStatus.isLineCaptureModified = false;
    this.cardSvc.clearLocalCardTaxes();
  }

  get vehicleTaxForm(): InputConfig[]{
    return this._vehicleTaxForm;
  }

  get paymentTypeForm(): InputConfig[]{
    return this._paymentTypeForm;
  }

  get localTaxesForm(): InputConfig[]{
    return this._localTaxesForm;
  }

  get supplyWaterTaxesForm(): InputConfig[]{
    return this._supplyWaterTaxesForm;
  }

  get captureLineForm(): InputConfig[]{
    return this._captureLineForm;
  }

  get propertyTaxesForm(): InputConfig[]{
    return this._propertyTaxesForm;
  }

  checkNavigation(): Subscription {
    return this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && !ServiceConstants.LOCAL_TAX_PATHS.includes(event.url)) {
        this.clearLocalTaxes();
      }
    });
  }

  get dynamicForm(){
    return this._dynamicForm;
  }
  get captureLineDetail(){
    return this._detailLine;
  }
  set captureLineDetail(data: ValidLineResponse) {
    this._detailLine=data;
  }
  get captureLineGoBack(){
    return this._goback;
  }
  set captureLineGoBack(data: boolean) {
    this._goback=data;
  }
  get dynamiFormData(){
    return this._dynamicFormData;
  }
  set dynamiFormData(data: IAdditionalTaxes[]) {
    this._dynamicFormData=data;
  }
  get dynamiFormStructure(){
    return this._dynamicStructure;
  }
  set dynamiFormStructure(data: InputConfig[]) {
    this._dynamicStructure=data;
  }
 async getIFMPromotionCDMX(): Promise<IGetIFMPromotionCDMXResponse> {
    if (!this.getIFMPromotionCDMXResponse) {
      const request: GetIFMPromotionCDMXRequest = new GetIFMPromotionCDMXRequest({
        idSesion: this.storageService.getSession()
      });
      this.getIFMPromotionCDMXResponse = await this.baseService.genericPost<IGetIFMPromotionCDMXResponse>(request, { isSOA: true });
    }
    return this.getIFMPromotionCDMXResponse;
  }

  makeSequence(start:string, end:string){
    const dataRange=[];
    let init = Number(start);
    const endRange = Number(end);
    for(init;init<=endRange; init++){
        dataRange.push(String(init));
    }
    return dataRange;
  }
  get dynamiFormId(){
    return this._dynamicId;
  }
  set dynamiFormId(data: string) {
    this._dynamicId=data;
  }
}
