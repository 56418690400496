import { formatDate } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AccountsStatusService } from 'src/app/services/accounts-status.service';
import { AlertService } from 'src/app/services/alert.service';
import { BureauAlertsService } from 'src/app/services/bureau-alerts.service';
import { CreditCardsStatusService } from 'src/app/services/credit-cards-status.service';
import { ModalService } from 'src/app/shared/modal.service';
import { BreakpointState, ResponsiveService } from 'src/app/shared/responsive.service';
import { BureauAlertsConstants } from 'src/core/constants/BureauAlertsConstants';
import { BureauAlertsStrings } from 'src/core/constants/BureauAlertsStrings';
import { Constants, FormatDate } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';

@Component({
  selector: 'app-modal-successful-request',
  templateUrl: './modal-successful-request.component.html',
  styleUrls: ['./modal-successful-request.component.css']
})
export class ModalSuccessfulRequestComponent implements OnInit {
  breakpoints$: Observable<BreakpointState> = this.responsiveService.observe;
  title: string = BureauAlertsStrings.BUREAU_ALERTS.SuccessfulRequest.Title;
  labelInvoice: string = BureauAlertsStrings.BUREAU_ALERTS.SuccessfulRequest.LabelInvoice;
  invoice: string;
  date: string;
  info: string = BureauAlertsStrings.BUREAU_ALERTS.SuccessfulRequest.Info;
  message: string = BureauAlertsStrings.BUREAU_ALERTS.SuccessfulRequest.Message;
  hrefBureau: string = BureauAlertsStrings.BUREAU_ALERTS.SuccessfulRequest.HrefBureau;
  bureauUrl: string = BureauAlertsStrings.BUREAU_ALERTS.SuccessfulRequest.BureauUrl;
  ready: string = BureauAlertsStrings.BUREAU_ALERTS.SuccessfulRequest.Ready;
  removeIcon: string = Resources.CLOSE_CIRCLE_BLUE;
  checkIcon: string = Resources.GREEN_CHECK_FILL;
  isPhone: boolean = false;
  isiPad: boolean = false;
  isIphone: boolean = false;
  modalData: string;
  hasResult: boolean = false;
  modalInstance: NgbModalRef;

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly bureauAlertsService: BureauAlertsService,
    private readonly creditCardsStatusService: CreditCardsStatusService,
    private readonly accountsStatusService: AccountsStatusService,
    private readonly alertService: AlertService) { }

  async ngOnInit() {
    try {
      const bureauAlertData = this.creditCardsStatusService.status.value ? BureauAlertsConstants.BUREAU_ALERT_CREDIT :
        this.accountsStatusService.status.value ? BureauAlertsConstants.BUREAU_ALERT_DEBIT : Strings.EMPTY;
      if (this.bureauAlertsService.dataBureauAlert.email === this.bureauAlertsService.dataBureauAlert.oldEmail) {
        this.bureauAlertsService.dataBureauAlert.email = Strings.EMPTY;
      }
      const response = await this.bureauAlertsService.bureauAlerts(bureauAlertData, this.modalData, this.bureauAlertsService.dataBureauAlert.email);
      this.invoice = response.datos?.toString();
      this.bureauAlertsService.alertSuccess = true;
      this.bureauAlertsService.bureauAlertExists.value = true;
      this.hasResult = true;
      const date = new Date();
      this.date = formatDate(date, FormatDate.DayMonthYearComplete, Constants.LOCALE_ES_MX);
      this.isMobile();
    } catch (error) {
      this.hasResult = true;
      this.modalService.close();
      const errorDetail = Utils.getErrorMsg(error);
      const alertInstance = this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetail.msg, errorDetail.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      }, {
        onSuccess: () => alertInstance.dismiss(),
        onClose: () => alertInstance.dismiss()
      });
    }
  }

  close() {
    this.modalInstance?.close();
  }

  isMobile() {
    const agent = navigator.userAgent;
    const maxWidth = Constants.RESOLUTIONS.PhoneMaxWidth;
    const minWidth = Constants.RESOLUTIONS.PhoneMinWidth;
    if (((window.screen.width <= maxWidth) && (window.screen.width >= minWidth))
      && agent.match(Constants.USER_AGENT.MobileIos)) {
      this.isPhone = true;
      if (agent.match(Constants.USER_AGENT.Iphone)) {
        this.isIphone = true;
      } else {
        this.isIphone = false;
      }
    } else {
      this.isPhone = false;
      this.isIphone = false;
    }
  }

  confirmLeavePage(link: string) {
    this.modalService.close();
    this.bureauAlertsService.leaveModalSuccess.value = true;
    Utils.confirmLeavePage(link, this.modalService);
  }

  @HostListener('window:popstate')
  onBack() {
    this.close();
  }

}
