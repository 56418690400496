import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAforePL, IAforePLResponse, IAforeTotalStatus } from 'src/app/interface/IAforePLResponse';
import { ISimpleValue } from 'src/app/interface/ISimpleValue';
import { Strings } from 'src/core/constants/Strings';
import { Constants, InputTypes, Numbers } from 'src/core/constants/Constants';
import { GetAforeBalancesCertificateRequest } from 'src/app/interface/dto/GetAforeBalancesCertificateRequest';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { AforeStrings } from 'src/core/constants/AforeStrings';
import { AforeConstants } from 'src/core/constants/AforeConstants';
import { AforeFormValidations } from 'src/core/constants/AforeFormValidations';
import { Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class AforeDataService {
  private readonly emptyModel = {
    cuenta: {
      nss: Strings.EMPTY,
      idCuenta: Strings.EMPTY,
      saldos: {
        saldoFovissste: Numbers.Zero,
        saldoImss: Numbers.Zero,
        saldoInfonavit: Numbers.Zero,
        saldoIssste: Numbers.Zero,
        saldoTotal: Numbers.Zero,
        saldoVoluntario: Numbers.Zero
      }
    }
  };
  private readonly detailAforeTotal = {
    pensionResolution: true,//Variable que define si el proceso de Retiro Total por PENSIÓN continua.
    retirementByAge: true,//Variable que define si el proceso de Retiro Total por EDAD continua.
    validCertificate: true,//Variable que define si el usuario cuenta con certificado VÁLIDO.
    retirementInProcess: false//Variable que define si YA EXISTE solicitud de retiro Total.
  };

  cardProductList: IAforePL[] = new Array<IAforePL>;
  private readonly accessWPartial: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly withEnrollment: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: true });//Variable de enrolamiento
  private readonly statusPDF: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly userApplication: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly startProcess: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly _afore: BehaviorSubject<IAforePLResponse> = new BehaviorSubject<IAforePLResponse>(this.emptyModel);
  private readonly _showAddProduct: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly cardSelect: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({value: false});
  private readonly accessWTotal: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly endProcess: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly stopProcess: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly isForAdvancedAge: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly isVoluntaryContributions: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly invalidAge: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly notResolutionRecord: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly isVoluntary: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly validRightorAdvancedage: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly showOrigin: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly getHomeAccount :BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);
  private readonly retirementTotal: BehaviorSubject<IAforeTotalStatus> = new BehaviorSubject<IAforeTotalStatus>(this.detailAforeTotal);
  private readonly _base64: BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);
  private readonly AforeSpouseFormConfig = [
    {
      type: Constants.DATE,
      label: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.WeddingDate,
      name: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.DateMarriage,
      placeholder: AforeStrings.VOLUNTARY_SAVINGS_CONTRIBUTIONS.DateMin,
      validations: [AforeFormValidations.FORM.validations[Numbers.Zero]]
    },
    {
      type: InputTypes.Text,
      label: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.NameSpouse,
      name: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Name,
      value: Strings.EMPTY,
      placeholder: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.NameSpouse,
      validations: AforeFormValidations.FORM.validations,
      maxlength: Numbers.Fifty,
      fnGeneric: (event: Event) => {
      },
    },
    {
      type: InputTypes.Text,
      label: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.SecondNameSpouse,
      name: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.SecondName,
      value: Strings.EMPTY,
      placeholder: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.SecondNameSpouse,
      validations: [ {
        name: Constants.FORM_CONTROLS.MinimumLength,
        validator: Validators.minLength(Numbers.Two),
        message: AforeStrings.VOLUNTARY_SAVINGS_CONTRIBUTIONS.MarriageAlert
      }],
      maxlength: Numbers.Fifty
    },
    {
      type: InputTypes.Text,
      label: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.FirstName,
      name: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Pattern,
      value: Strings.EMPTY,
      placeholder: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.FirstName,
      validations: AforeFormValidations.FORM.validations,
      maxlength: Numbers.Fifty
    },
    {
      type: InputTypes.Text,
      label: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.LastName,
      name: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Maternal,
      value: Strings.EMPTY,
      placeholder: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.LastName,
      validations: AforeFormValidations.FORM.validations,
      maxlength: Numbers.Fifty
    },
    {
      type: InputTypes.Select,
      label: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.GenderSpouse,
      name: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Gender,
      options: [
        {
          value: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Masculine,
          label: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Masculine
        },
        {
          value: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Feminine,
          label: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Feminine
        }
      ],
      validations: [AforeFormValidations.FORM.validations[Numbers.Zero]]
    },
    {
      type: InputTypes.Select,
      label: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.MarriageState,
      name: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.State,
      placeholder: AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.MarriageState,
      options: AforeConstants.MarriagePlace,
      validations: [AforeFormValidations.FORM.validations[Numbers.Zero]]
    }
  ];

  constructor(
    private readonly baseService: IBaseService
  ) { }

  get afore$() {
    return this._afore.asObservable();
  }

  get afore() {
    return this._afore.getValue();
  }

  set afore(data: IAforePLResponse) {
    this._afore.next(data);
  }

  get showAddProduct$() {
    return this._showAddProduct.asObservable();
  }

  set showAddProduct(data: boolean) {
    this._showAddProduct.next(data);
  }

  get detailWPartialObservable(): Observable<ISimpleValue> {
    return this.accessWPartial.asObservable();
  }

  set detailWPartialData(data: ISimpleValue) {
    this.accessWPartial.next(data);
  }

  get userEnrollmentObservable(): Observable<ISimpleValue> {
    return this.withEnrollment.asObservable();
  }

  set userEnrollmentData(data: ISimpleValue) {
    this.withEnrollment.next(data);
  }
  get userApplicationbservable(): Observable<ISimpleValue> {
    return this.userApplication.asObservable();
  }

  set userApplicationData(data: ISimpleValue) {
    this.userApplication.next(data);
  }

  get startProcessObservable(): Observable<ISimpleValue> {
    return this.startProcess.asObservable();
  }

  set startProcessData(data: ISimpleValue) {
    this.startProcess.next(data);
  }

  get consultStatusPDFObs(): Observable<ISimpleValue> {
    return this.statusPDF.asObservable();
  }

  set consultStatusPDFData(data: ISimpleValue) {
    this.statusPDF.next(data);
  }

  clearAforeModel() {
    this.afore = this.emptyModel;
  }

  addProductAfore(cuenta: IAforePL[]) {
    this.cardProductList.push(...cuenta);
  }

  get dataOBS(): IAforePL[] {
    return this.cardProductList;
  }

  get selectConsultOBS(): Observable<ISimpleValue> {
    return this.cardSelect.asObservable();
  }

  set selectConsultData(data: ISimpleValue) {
    this.cardSelect.next(data);
  }
  get detailWTotalObservable(): Observable<ISimpleValue> {
    return this.accessWTotal.asObservable();
  }

  set detailWTotalData(data: ISimpleValue) {
    this.accessWTotal.next(data);
  }

  get endProcessObservable(): Observable<ISimpleValue> {
    return this.endProcess.asObservable();
  }

  set endProcessData(data: ISimpleValue) {
    this.endProcess.next(data);
  }

  get stopProcessObservable(): Observable<ISimpleValue> {
    return this.stopProcess.asObservable();
  }

  set stopProcessData(data: ISimpleValue) {
    this.stopProcess.next(data);
  }

  get detailIsForAdvancedageObservable(): Observable<ISimpleValue> {
    return this.isForAdvancedAge.asObservable();
  }

  set detailIsForAdvancedage(data: ISimpleValue) {
    this.isForAdvancedAge.next(data);
  }

  get detailIsVoluntaryContributionsObservable(): Observable<ISimpleValue> {
    return this.isVoluntaryContributions.asObservable();
  }

  set detailIsVoluntaryContributions(data: ISimpleValue) {
    this.isVoluntaryContributions.next(data);
  }

  get detailnotvalidageObservable(): Observable<ISimpleValue> {
    return this.invalidAge.asObservable();
  }

  set detailnotvalidage(data: ISimpleValue) {
    this.invalidAge.next(data);
  }

  get detailnotresolutionObservable(): Observable<ISimpleValue> {
    return this.notResolutionRecord.asObservable();
  }

  set detailnotresolution(data: ISimpleValue) {
    this.notResolutionRecord.next(data);
  }

  get detailIsVoluntaryObservable(): Observable<ISimpleValue> {
    return this.isVoluntary.asObservable();
  }

  set detailIsVoluntary(data: ISimpleValue) {
    this.isVoluntary.next(data);
  }

  get detailRightOrAdvancedAgeObservable(): Observable<ISimpleValue> {
    return this.validRightorAdvancedage.asObservable();
  }

  set detailRightOrAdvancedAge(data: ISimpleValue) {
    this.validRightorAdvancedage.next(data);
  }

  get showOriginObservable(): Observable<ISimpleValue> {
    return this.showOrigin.asObservable();
  }

  set showOriginData(data: ISimpleValue) {
    this.showOrigin.next(data);
  }

  get tabSelectedObservable() : Observable<string> {
    return this.getHomeAccount.asObservable();
  }

  set tabSelectedData(data: string) {
    this.getHomeAccount.next(data);
  }

  get detailRetirementTotalObs() {
    return this.retirementTotal.getValue();
  }

  set detailRetirementTotalData(data: IAforeTotalStatus) {
    this.retirementTotal.next(data);
  }

  get base64$(): Observable<string> {
    return this._base64.asObservable();
  }

  set base64(data: string) {
    this._base64.next(data);
  }

  get base64() {
    return this._base64.getValue();
  }


  async getBalancesCertificate(request: GetAforeBalancesCertificateRequest): Promise<string> {
    const response = await this.baseService.genericPost<GenericResponse<string>>(request, {
      isSecure: true, addSession: true
    });

    if (!response || !response?.datos) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response.datos;
  }

  get aforeSpouseFormConfig(){
    return this.AforeSpouseFormConfig;
  }

}
