import { Injectable } from '@angular/core';
import { IBaseService } from './i-base-service.service';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { Strings } from 'src/core/constants/Strings';
import { Constants, Length, Numbers } from 'src/core/constants/Constants';
import { GetDirectDebitDebitCardRequest } from 'src/app/interface/dto/GetDirectDebitDebitCardRequest';
import { DirectDebitCard } from 'src/app/interface/dto/GetDirectDebitDebitCardResponse';
import { GetDirectDebitCreditCardRequest, IGetDirectDebitCreditCardRequest } from 'src/app/interface/dto/GetDirectDebitCreditCardRequest';
import { GetDirectDebitCreditCardResponse } from 'src/app/interface/dto/GetDirectDebitCreditCardResponse';
import { DirectDebitCardResponse } from 'src/app/interface/dto/DirectDebitCardResponse';
import { GetListDirectDebitRequest } from 'src/app/interface/dto/GetListDirectDebitRequest';
import { AddDirectDebitServiceDebitCardRequest  } from 'src/app/interface/dto/AddDirectDebitServiceDebitCardRequest';
import { AddDirectDebitServiceCreditCardRequest } from 'src/app/interface/dto/AddDirectDebitServiceCreditCardRequest';
import { CancelDirectDebitDebitCardRequest } from 'src/app/interface/dto/CancelDirectDebitServiceDebitCardRequest';
import { CancelDirectDebitCreditCardRequest } from 'src/app/interface/dto/CancelDirectDebitServiceCreditCardRequest';
import { UpdateDirectDebitDebitCardRequest } from 'src/app/interface/dto/UpdateDirectDebitDebitCardRequest';
import { GetDirectDebitCreditCardOwnRequest } from 'src/app/interface/dto/GetDirectDebitCreditCardOwnRequest';
import { AccountsDirectDebitCreditCards } from 'src/app/interface/dto/GetAccountsDirectDebitCreditCardsResponse';
import { ProductUtils } from 'src/core/utils/ProductUtils';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { GetCardsDirectDebitRequest } from 'src/app/interface/dto/GetCardsDirectDebitRequest';
import { CancelDirectDebitServiceOwnCreditCardRequest } from 'src/app/interface/dto/CancelDirectDebitServiceOwnCreditCardRequest';
import { UpdateDirectDebitDebitCardOwnRequest } from 'src/app/interface/dto/UpdateDirectDebitDebitCardOwnRequest';

type DirectDebitRequest = AddDirectDebitServiceDebitCardRequest | UpdateDirectDebitDebitCardOwnRequest |
  AddDirectDebitServiceCreditCardRequest | CancelDirectDebitDebitCardRequest |
  CancelDirectDebitCreditCardRequest | UpdateDirectDebitDebitCardRequest | CancelDirectDebitServiceOwnCreditCardRequest;

@Injectable({
  providedIn: 'root'
})
export class DirectDebitServicesService {

  private _isDirectDebit: boolean = false;
  private _isDirectCredit: boolean = false;

  constructor(
    private readonly baseService: IBaseService
  ) { }

  get isDirectDebit(){
    return this._isDirectDebit;
  }

  set isDirectDebit(data: boolean) {
    this._isDirectDebit = data;
  }

  get isDirectCredit(){
    return this._isDirectCredit;
  }

  set isDirectCredit(data: boolean) {
    this._isDirectCredit = data;
  }

  async getDirectDebitServicesTDD(request: GetDirectDebitDebitCardRequest): Promise<GenericResponse<DirectDebitCard[]>> {
    let response = await this.baseService.genericPost<GenericResponse<DirectDebitCard[]>>(request, {
      addSession: true,
      isSecure: true
    });
    if (!response?.datos || response?.datos?.length === Numbers.Zero) {
      response = { datos: null };
    }
    return response;
  }

  async getDirectDebitServices(requestAccount: IGetDirectDebitCreditCardRequest): Promise<GenericResponse<GetDirectDebitCreditCardResponse[]>> {
    const request = new GetDirectDebitCreditCardRequest({numeroCuenta: requestAccount.numeroCuenta , origen:  requestAccount.origen });
    const requestTDC = new GetDirectDebitCreditCardOwnRequest({numeroCuenta: requestAccount.numeroCuenta , origen:  requestAccount.origen });
    const responseDirectDebitServices = await this.getDirectDebitServicesTDC(request);
    const responseDirectDebitServicesTDC = await this.getDirectDebitServicesTDCOwn(requestTDC);

    if(responseDirectDebitServices?.datos === null && responseDirectDebitServicesTDC?.datos == null){
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    const list = ProductUtils.addListService(responseDirectDebitServices?.datos, false, requestAccount.description, requestAccount.card);
    const listTDC = Object.keys(responseDirectDebitServicesTDC?.datos).length === Length.Empty ? [] :
    ProductUtils.addService(responseDirectDebitServicesTDC?.datos, requestAccount.card);

    if(list.length !== Numbers.Zero && listTDC.length !== Numbers.Zero){
      responseDirectDebitServices.datos = list.concat(listTDC);
    }
    responseDirectDebitServices.datos = list.length === Numbers.Zero ? listTDC : listTDC.length === Numbers.Zero ? list : responseDirectDebitServices.datos;

    return responseDirectDebitServices;
  }

  async getDirectDebitServicesTDC(request: GetDirectDebitCreditCardRequest): Promise<GenericResponse<GetDirectDebitCreditCardResponse[]>> {
    try {
      let response: GenericResponse<GetDirectDebitCreditCardResponse[]> = await this.baseService.genericPost<GenericResponse<GetDirectDebitCreditCardResponse[]>>(request, {
        addSession: true,
        isSecure: true
      });
      if (!response?.datos || response?.datos?.length === Numbers.Zero) {
        response = { datos: [] };
      }
      return response;
    } catch (error) {
      return { datos: null };
    }
  }

  async getDirectDebitServicesTDCOwn(request: GetDirectDebitCreditCardOwnRequest): Promise<GenericResponse<AccountsDirectDebitCreditCards>> {

    try {
      let response: GenericResponse<AccountsDirectDebitCreditCards> = await this.baseService.genericPost<GenericResponse<AccountsDirectDebitCreditCards>>(request, {
        addSession: true,
        isSecure: true
      });
      if (!response?.datos) {
        response = { datos: {} };
      }
      return response;
    } catch (error) {
      return { datos: null };
    }
  }

  async getCardsDirectDebitService(request: GetCardsDirectDebitRequest): Promise<GenericResponse<DirectDebitCardResponse[]>> {
    const response = await this.baseService.genericPost<GenericResponse<DirectDebitCardResponse[]>>(request, {
      addSession: true,
      isSecure: true
    });
    if (!response?.datos || response?.datos?.length === Numbers.Zero) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response;
  }

  async getListDirectDebitService(request: GetListDirectDebitRequest): Promise<GenericResponse<string[]>> {
    const response: GenericResponse<string[]> = await this.baseService.genericGet<GenericResponse<string[]>>(request, {
      addSession: true,
      isSecure: true
    });
    if (!response?.datos || response?.datos?.length === Numbers.Zero) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response;
  }

  async manageDirectDebit(request: DirectDebitRequest): Promise< GenericResponse<string>> {
    const response: GenericResponse<string> = await this.baseService.genericPost< GenericResponse<string>>(request, {
      addSession: true,
      isSecure: true
    });
    if (!response?.datos) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response;
  }

  async addDirect(request: DirectDebitRequest): Promise<GenericResponse<number>> {
    const response: GenericResponse<number> = await this.baseService.genericPost< GenericResponse<number>>(request, {
      addSession: true,
      isSecure: true
    });
    if (!response?.datos) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response;
  }
}
