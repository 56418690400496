import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions, OptionsModalToken } from 'src/app/interface/modal-options';
import { AforeDataService } from 'src/app/services/afore-data.service';
import { NotifyRetirementTypeService } from 'src/app/services/notify-retirement-type.service';
import { ModalService } from 'src/app/shared/modal.service';
import { Char, Constants, FileFormats, Position } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { AforeStrings } from 'src/core/constants/AforeStrings';
import { RetirementType } from 'src/core/constants/AforeConstants';
import { ModalPartialConfirmComponent } from 'src/app/home/components/modal-partial-confirm/modal-partial-confirm.component';
import { ModalPartialSuccesfulComponent } from 'src/app/home/components/modal-partial-succesful/modal-partial-succesful.component';
import { ModalEndProcessAlertComponent } from 'src/app/shared/components/modal-end-process-alert/modal-end-process-alert.component';
import { ModalUtils } from 'src/core/utils/ModalUtils';
import { StringsRetirement } from 'src/core/constants/StringsRetirement';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { AforeResources } from 'src/core/constants/AforeResources';
import { DomiciliationResources } from 'src/core/constants/DomiciliationResources';
import { AforePartialWithdrawalsUnemploymentRequest } from 'src/app/interface/dto/AforePartialWithdrawalsUnemploymentRequest';
import { Utils } from 'src/core/utils/utils';
import { AforeService } from 'src/app/services/afore.service';
import { AccountCardService } from 'src/app/services/account-card.service';
import { AlertService } from 'src/app/services/alert.service';
import { StateVoluntaryWithdrawalService } from 'src/app/services/state-voluntary-withdrawal.service';
import { FileUtils } from 'src/core/utils/FileUtils';
import { AforePartialWithdrawalMarriageCertificateRequest } from 'src/app/interface/dto/AforePartialWithdrawalMarriageCertificateRequest';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';

@Component({
  selector: 'app-modal-unemployment-withdrawal-request',
  templateUrl: './modal-unemployment-withdrawal-request.component.html',
  styleUrls: ['./modal-unemployment-withdrawal-request.component.css']
})
export class ModalUnemploymentWithdrawalRequestComponent implements OnInit {
  optionsAlert: ModalOptions = {
    centered:true,
    size: Constants.MODAL_OPTIONS.SizeSm,
    modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAlertEndProcess
  };
  optionsConfirm: ModalOptions = {
    size: Constants.MODAL_OPTIONS.SizeXl,
    centered:true,
    modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalPartialConfirmComponent
  };
  title: string = this.notifyRetirementType.retirementTypeBs.value ===
   StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.Unemployment ?
    StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.UnemploymentApplication :
    AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.MarriageApplication;
  aforeUnemployment:string=DomiciliationResources.CDN_URL_DOMICILIATION(AforeResources.PROOF_OF_UNEMPLOYMENT);
  viewPdf: SafeResourceUrl;
  nextPDF: boolean = false;
  buttonClickedOne: boolean = false;
  buttonClickedTwo: boolean = false;
  buttonContinue: string = Strings.BUTTONS.Continue;
  closeOtpModal: NgbModalRef;
  @ViewChild(Strings.LABEL_ADD_PRODUCT.OTP) otpModal: ElementRef<HTMLInputElement>;
  optionsModalToken: OptionsModalToken;
  iconClose: string = Resources.CLOSE_CIRCLE_BLUE;
  chevronRight: string = Resources.SHAPE_CONTINUE;
  modalInstance: NgbModalRef;

  constructor(private readonly modalService: ModalService,
    private readonly aforeDataService: AforeDataService,
    private readonly notifyRetirementType: NotifyRetirementTypeService,
    private readonly domSanitizer: DomSanitizer,
    private readonly aforeService: AforeService,
    private readonly accountCardService: AccountCardService,
    private readonly alertService: AlertService,
    public readonly dynamicFormService: DynamicFormService,
    private readonly stateVoluntaryWithdrawalService: StateVoluntaryWithdrawalService,
    private readonly notifyAmountService: NotifyAmountService) {
  }

  async ngOnInit() {
    if(this.aforeDataService.base64 === Strings.EMPTY){
      await this.getVoucher();
    }else{
      this.generatePDF(this.aforeDataService.base64);
    }
    this.aforeDataService.consultStatusPDFObs.subscribe(
      (data) => {
        this.nextPDF = data.value;
        if (this.nextPDF) {
          this.nextPDF = true;
          this.buttonClickedOne = true;
        }
      }
    );
  }

  close(): void {
    ModalUtils.showModal(this.modalService, ModalEndProcessAlertComponent, this.optionsAlert,'modal-unemployment','modal-unemployment');
  }

  nextModal() {
    if (this.notifyRetirementType.retirementTypeBs.value === StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.Unemployment) {
      if (this.nextPDF) {
        this.buttonClickedTwo = true;
        this.modalService.close();
        this.aforeDataService.consultStatusPDFData = { value: true };
        this.modalService.open(ModalPartialConfirmComponent, this.optionsConfirm);
      } else {
        this.nextPDF = true;
        this.buttonClickedOne = true;
        this.viewPdf = this.domSanitizer.bypassSecurityTrustResourceUrl(this.aforeUnemployment);
      }
    } else {
      this.buttonClickedOne = true;
      this.buttonClickedTwo = true;
      this.modalService.close();
      this.aforeDataService.consultStatusPDFData = { value: true };
      this.optionsModalToken = {
        backModal: ModalUnemploymentWithdrawalRequestComponent,
        backClass: {
          modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal,
          centered:true
        },
        nextModal: ModalPartialSuccesfulComponent,
        nextClass: {
          centered: true,
           modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal
        }
      };
      const modalOptions: ModalOptions = {
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeLg,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.OtpChecksConfirmation
      };
      this.closeOtpModal = this.modalService.open(this.otpModal, modalOptions);
    }
  }

  async getVoucher(){
    try {
      if (this.aforeService.withdrawals === RetirementType.unemployment) {
        const request = new AforePartialWithdrawalsUnemploymentRequest({
          folio: this.aforeService.aforeFolio.folioAfore,
          modalidad: this.aforeService.calculations.tipo,
          clienteInbursaAfore: this.stateVoluntaryWithdrawalService.aforeAssociation.clienteInbursaAfore,
          cuentaClabe: this.accountCardService.accountCard.clabe
        });
        const response = await this.aforeService.getAforeVoucher<AforePartialWithdrawalsUnemploymentRequest>(request);
        this.generatePDF(response);
      } else {
        const formData = this.dynamicFormService?.formBS?.getValue();
        const year = formData?.get(AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.DateMarriage)?.value?.marriageDate?.split(Char.Slash)[Position.Two];
        const response = await this.aforeService.getAforeVoucher<AforePartialWithdrawalMarriageCertificateRequest>(new AforePartialWithdrawalMarriageCertificateRequest({
          anio: year,
          clienteInbursaAfore: this.stateVoluntaryWithdrawalService.aforeAssociation.clienteInbursaAfore,
          conyuge:{
            nombre:formData.get(AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Name)?.value,
            segundoNombre:formData.get(AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.SecondName)?.value ?
              formData.get(AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.SecondName)?.value:Strings.EMPTY,
            apellidoPaterno:formData?.get(AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Pattern)?.value,
            apellidoMaterno:formData?.get(AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Maternal)?.value
          },
          cuentaClabe:this.accountCardService.accountCard.clabe,
          descripcionEntidadFederativa:formData.get(AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.State)?.value,
          folio:this.aforeService.aforeFolio.folioAfore
        }));
        this.generatePDF(response);
        this.aforeService.errorAforeService = true;
      }
    } catch (error) {
      this.modalService.close();
      this.aforeService.errorAforeService = false;
      const errorDetails = Utils.getErrorMsg(error);
      this.aforeService.cleanData();
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        imgHead: Resources.CLOSE_ICON_RED,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        btnExit: false
      });
    }
  }

  generatePDF(response: string) {
    this.aforeDataService.base64 = response;
    const pdf = FileUtils.convertToPdf(response, FileFormats.TypePDF);
    this.viewPdf = this.domSanitizer.bypassSecurityTrustResourceUrl(pdf);
    this.modalService.modalInstance.update({
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeXl,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalUnemploymentWithdrawalRequestComponent
    });
  }

  ngOnDestroy() {
    if (!this.buttonClickedOne || !this.buttonClickedTwo) {
      this.aforeDataService.consultStatusPDFData = { value: false };
      this.aforeDataService.base64 = Strings.EMPTY;
    }
  }

  @HostListener('window:popstate')
  onBack() {
    this.modalInstance?.close();
    this.aforeService.aforeInformation = null;
    this.aforeDataService.base64 = Strings.EMPTY;
    this.accountCardService.clearCardAccount();
    this.notifyAmountService.amount = { amount: Strings.EMPTY };
  }

}
