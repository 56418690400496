import { Component, OnInit } from '@angular/core';
import { ButtonProperties } from 'src/app/interface/IButtonProperties';
import { Router } from '@angular/router';
import { Resources } from 'src/core/constants/Resources';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { map } from 'rxjs';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { WebCardActiveService } from 'src/app/services/web-card-active.service';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { WebCardStrings } from 'src/core/constants/WebCardStrings';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';

@Component({
  selector: 'app-modal-web-card',
  templateUrl: './modal-web-card.component.html',
  styleUrls: ['./modal-web-card.component.css']
})
export class ModalWebCardComponent implements OnInit {
  src = Resources.CIRCLE_BLUE_QUESTION;
  modalInstance: NgbModalRef;
  alt = this.src;
  Strings = WebCardStrings;
  closeCircle: string = Resources.CLOSE_CIRCLE_BLUE;
  showWebCard = false;
  imgBankCard: string = Resources.BANK_CARD_NAVY;
  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints }))
  );
  infoWeb = Strings.INFO_WEB;
  cvv = Strings.CVV;
  dueDate = Strings.DUE_DATE;
  accept = Strings.MSG_POPUP_ACCEPT;

  primaryProperties: ButtonProperties = {
    styles: {},
    classes: WebCardStrings.WebCard.CLASSES.MODALS_BTN,
    doAction: () => {
      this.dynamicFormService.clearForm();
      this._router.navigate([PATH.WebCardRequest]).then( () => {
        this.modalInstance.close();
      });
    }
  };

  secondaryProperties: ButtonProperties = {
    classes: WebCardStrings.WebCard.CLASSES.MODALS_BTN_SECONDARY,
    doAction: () => {
      this.modalInstance.close();
    }
  };

  constructor(private readonly _router: Router,
              private readonly responsiveService: ResponsiveService,
              private readonly webCardActiveService: WebCardActiveService,
              private readonly dynamicFormService: DynamicFormService) { }

  ngOnInit(): void {
    this.showWebCard = this.webCardActiveService.getInfoWebCard();
  }

  close(): void {
    this.modalInstance.close();
  }

  ngOnDestroy(): void {
    this.webCardActiveService.setInfoWebCard(false);
  }
}
