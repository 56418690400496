import { PathModel } from 'src/app/models/PathModel';
export interface IUpdateLockLimitsCardRequest {
  Account: string;
  CardNumber: string;
  Changes: {
    Name: string,
    Type: string,
    Value: string
  }[],
  medioAcceso: string;
  CustomerId?: string;
  Delivery?: string;
  IdCuentaProducto?: string;
  IdsPersona?: Array<string>;
  NewState?: string;
  NickName?: string;
  Source: string;
  UserAgent: string;
  SerialNumber?: string;
  Ip: string;
  IsAditional: boolean;
  IdSession: string;
}

export class UpdateLockLimitsCardRequest {
  constructor(public data: IUpdateLockLimitsCardRequest) { }

  get endpoint(): PathModel {
    return PathModel.UpdateLockLimitsCard;
  }
}
