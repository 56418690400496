import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MovementsCardService } from 'src/app/services/movements-card.service';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';

@Component({
  selector: 'app-reset-input-value',
  templateUrl: './reset-input-value.component.html',
  styleUrls: ['./reset-input-value.component.css']
})
export class ResetInputValueComponent implements OnInit {
  @ViewChild('ResetInputValue') template: TemplateRef<ResetInputValueComponent>;
  @Input() id: string = Strings.EMPTY;
  @Input() targetInput: HTMLInputElement;
  @Output() clickEmmiter: EventEmitter<null> = new EventEmitter<null>();
  @Input() customColor: string = Strings.EMPTY;
  @Input() styleReset: string = Strings.EMPTY;

  modalCloseWhiteImage: string = Resources.MODAL_CLOSE_WHITE;

  constructor(private readonly movementsCardService: MovementsCardService) { }

  ngOnInit(): void {
    this.movementsCardService.signalCleanInput.subscribe(() => { this.cleanInput(); });
  }

  resetInputValue(): void {
    this.targetInput.value = Strings.EMPTY;
    this.clickEmmiter.emit();
  }

  cleanInput() { this.targetInput.value = Strings.EMPTY; }
}
