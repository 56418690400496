<div class="content">
  <header class="d-flex justify-content-center">
    <img
      [src]="exclamationMark | cdnImage"
      alt="Atención"
      width="48"
      height="48"
    />
  </header>
  <main class="d-flex flex-column">
    <span class="detail-m text-center px-4">
      Estás abandonando la página del Grupo Financiero Inbursa e ingresando a un
      sitio distinto cuyo contenido no es responsabilidad del Grupo Financiero
      Inbursa
    </span>
    <span class="detail-m text-center mt-3">¿Deseas continuar?</span>
  </main>
  <footer class="d-flex flex-row justify-content-center gap-3">
    <button class="button-m outline-btn footer-btn" (click)="leave()"> Sí </button>
    <button class="button-m colored-btn footer-btn" (click)="close()"> No </button>
  </footer>
</div>
