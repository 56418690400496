import { PathModel } from 'src/app/models/PathModel';

export interface IUserEmailRequest {
    IdSession: string;
    TipoOperacion: string;
    OtpSms: string;
    Localizador: {
        Nuevo: string;
        Anterior: string;
    };
}
export class ModifyEmailRequest {
    constructor(public data: IUserEmailRequest) { }
    get endpoint(): PathModel {
        return PathModel.EditUserEmail;
    }
}
