import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IQrEvent } from 'src/app/interface/IQrEvent';

@Injectable({
  providedIn: 'root'
})
export class StateQrService {

  private readonly stateQr: BehaviorSubject<IQrEvent> = new BehaviorSubject<IQrEvent>({value: false});
  private readonly _transaction: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() { }

  get stateQrObservable() {
    return this.stateQr.asObservable();
  }

  set stateQrData(data: IQrEvent) {
    this.stateQr.next(data);
  }

  get transaction$() {
    return this._transaction.asObservable();
  }

  get transaction() {
    return this._transaction.getValue();
  }

  set transaction(value: string) {
    this._transaction.next(value);
  }

}
