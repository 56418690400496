import { PathModel } from '../../models/PathModel';

interface IUserImageRequest {
  idUsuario: number;
  tipo: number;
}

export class UserImageRequest {
  constructor(public data: IUserImageRequest) { }

  get endpoint(): PathModel {
    return PathModel.UserImage;
  }
}