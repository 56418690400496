import { Component } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { LoginResources } from 'src/core/constants/LoginResources';

@Component({
  selector: 'app-modal-facepass-manual',
  templateUrl: './modal-facepass-manual.component.html',
  styleUrls: ['./modal-facepass-manual.component.css']
})
export class ModalFacepassManualComponent {

  vm$ = this.responsiveService.observe.pipe(
    map(breakpoints => ({ breakpoints }))
  );

  modalInstance: NgbModalRef;

  images = LoginResources.MANUAL_LOGIN_FACEPASS_CDN;

  constructor(private readonly responsiveService: ResponsiveService) {}

  close() {
    this.modalInstance.close();
  }
}
