import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { TDate } from 'src/app/interface/TDate';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { Constants, FormatDate, Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
  providedIn: 'root'
})
export class NotifyDateService {
  private readonly _dateSubject: BehaviorSubject<TDate> = new BehaviorSubject<TDate>({ date: Strings.EMPTY, isError: false });
  private readonly _dateStruct: BehaviorSubject<NgbDateStruct> = new BehaviorSubject<NgbDateStruct>(null);
  private readonly isError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly isExternalError$: ReplaySubject<boolean> = new ReplaySubject<boolean>(0);
  private readonly _clearDate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  get date$(): Observable<TDate> {
    return this._dateSubject.asObservable();
  }
  /**
    * @deprecated The variable should not be used, use instead dateFormated
    */
  get date(): TDate {
    return this._dateSubject.getValue();
  }
  /**
    * @deprecated The variable should not be used, use instead dateStructData
    */
  set date(data: TDate) {
    this._dateSubject.next(data);
  }

  get dateFormat(): string {
    return formatDate(new Date(this.dateStruct.value?.year,
      this.dateStruct.value?.month - Numbers.One, this.dateStruct.value?.day),
      FormatDate.BasicFormat, Constants.LOCALE_ES_MX);
  }

  get dateStruct(): BehaviorSubject<NgbDateStruct> {
    return this._dateStruct;
  }

  set dateStructData(data: NgbDateStruct) {
    this.dateStruct.next(data);
  }

  get isErrorBS(): BehaviorSubject<boolean> {
    return this.isError;
  }

  get isErrorObservable(): Observable<boolean> {
    return this.isError.asObservable();
  }

  set isErrorData(data: boolean) {
    this.isError.next(data);
  }

  get dateNgbStruct(): Observable<NgbDateStruct> {
    return this.dateStruct.asObservable();
  }

  get dateStructData(){
    return this.dateStruct.getValue();
  }

  get isExternalErrorObservable(): Observable<boolean> {
    return this.isExternalError$.asObservable();
  }

  set isExternalErrorData(data: boolean) {
    this.isExternalError$.next(data);
  }

  get clearDate(){
    return this._clearDate.getValue();
  }

  get clearDate$(): Observable<boolean> {
    return this._clearDate.asObservable();
  }

  set clearDate(data: boolean) {
    this._clearDate.next(data);
  }

}
