<ng-container *ngIf="vm$ | async as vm">
  <content-wrapper [contentMargin]="contentMargin" [useFlex]="true">
    <div content class="token-container">
      <div class="pb-3">
        <app-header-transfer-card [title]="title" [subtitle]="subtitle"
                                  [urlLocation]="beforeView" [option]="option" [paymentInsurance]="sendPaymentInsurance"
                                  [dataToConfirm]="dataInvestment" [returnFn]="navigationBack"
                                  [dataAmountInsurance]="dataAmountInsurance"
                                  [dataConfirm]="dataConfirm" [showSubtitle]="true"></app-header-transfer-card>
      </div>
      <div>
        <app-form-code-security [head]="head" [nextView]="nextView" [transferComplete]="transferComplete"
                                [paymentInsurance]="sendPaymentInsurance" [dataAmount]="dataAmountInsurance"
                                [paymentInvestment]="dataInvestment" [amountInvestment]="dataAmountInvestment"
                                (showConfirmData)="getOtp($event)"
                                [dataInternational]="dataInternational"
                                [labelModal]="classError" [isModal]="false"></app-form-code-security>
      </div>
    </div>
  </content-wrapper>
</ng-container>
