export class PointsConstants {
  static readonly BLACK = 'BLACK';
  static readonly NO_INFORMATION_CODE = 201;
  static readonly TURKISH = 18;
  static readonly MIN_LENGTH_MEMBERSHIP_TAM = 9;
  static readonly MAX_LENGTH_MEMBERSHIP_TAM = 11;
  static readonly HEIGHT_DOMICILIATION = 167;
  static readonly CARD_HEIGHT_DIRECT_DEBIT_AFORE = 65;
  static readonly CARD_HEIGHT = 48;
  static readonly AUTO = 'auto';
  static readonly PX = 'px';
  static readonly ONE_POINT_FOUR = 1.4;
  static readonly BLANK = '_blank';
  static readonly CONTENT_MESSAGE_ERROR = 'content-message-error';
  static readonly IFRAME = 'iframe';
  static readonly RESPONSE = 'response';
  static readonly TIME_AWAIT = 30000;
}
export class PointsClass {
  static readonly CARD_IMG = 'loyalty-promotion-card-img-contain';
  static readonly CLASS_POINT = `
  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: var(--swiper-pagination-bottom, 8px);
    top: auto;
    left: 0;
    width: 100%;
  } `;
  static readonly LOADED = 'loaded';
}
