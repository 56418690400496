import { PathModel } from 'src/app/models/PathModel';

interface BiometricData {
  IdSession: string;
}

export class BiometricDataRequest {
  constructor(public data: BiometricData) { }

  get endpoint(): PathModel {
    return PathModel.BiometricData;
  }
}
