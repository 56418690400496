import { PathModel } from 'src/app/models/PathModel';
export type TGetCreditsPLRequest = {
  IdSession: string;
  MedioAcceso: string;
  OrigenConsulta: string;
  IpCliente: string;
};

export class GetCreditsPLRequest {
  constructor(public data: TGetCreditsPLRequest){ }

  get endpoint(): PathModel {
    return PathModel.GetCredits;
  }

}
