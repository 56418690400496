import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatestWith, map } from 'rxjs';
import { StateQrService } from 'src/app/services/state-qr.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';

@Component({
  selector: 'app-header-card-login-component',
  templateUrl: './header-card-login-component.component.html',
  styleUrls: ['./header-card-login-component.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderCardLoginComponentComponent implements OnInit {

  title: string = Strings.HOME_TITLE;

  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(this.stateQrService.stateQrObservable),
    map(([breakpoints, qr]) => {
      this.initComponent(qr.value.valueOf());
      return { breakpoints };
    })
  );

  constructor(
    private readonly stateQrService: StateQrService,
    private readonly router: Router,
    private readonly responsiveService: ResponsiveService
  ) { }

  ngOnInit(): void { }

  private initComponent(hasQr: boolean) {
    const url = this.router.url;
    if (url === PATH.LoginToken || url === PATH.ValidateToken) {
      this.title = Strings.SECURITY_CODE.TitleToken;
    }
    else {
      this.title = hasQr ? Strings.QR_LOGIN_TITLE : Strings.HOME_TITLE;
    }
  }

}
