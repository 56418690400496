import { Component, ElementRef, HostListener, OnInit, signal, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CustomSelectProperties } from 'src/app/interface/CustomSelectProperties';
import { ButtonProperties } from 'src/app/interface/IButtonProperties';
import { ITitle } from 'src/app/interface/ITitle';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Char, ClassHTML, Constants, Numbers, FormatDate } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { ServiceConstants } from 'src/core/constants/ServiceConstants';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalCancelFederalTaxReceiptComponent } from 'src/app/no-schedulable-services/components/modal-cancel-federal-tax-receipt/modal-cancel-federal-tax-receipt.component';
import { PaginationService } from 'src/app/services/pagination.service';
import { map, Subscription } from 'rxjs';
import { Utils } from 'src/core/utils/utils';
import { FederalTaxReceiptService } from 'src/app/services/federal-tax-receipt-filter.service';
import { formatDate } from '@angular/common';
import { Format} from 'src/core/constants/FormatDate';
import { ModalServicesComponent } from 'src/app/services-module/components/modal-services/modal-services.component';
import { PaymentSATStrings } from 'src/core/constants/PaymentSATStrings';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { SatResources } from 'src/core/constants/PaymentSATResources';
import { ProductsService } from 'src/app/services/products.service';
import { AlertService } from 'src/app/services/alert.service';
import { AccountModel } from 'src/app/interface/AccountModel';
import { GetFederalTaxPaymentRequest } from 'src/app/interface/dto/GetFederalTaxPaymentRequest';
import { GetFederalTaxPaymentResponse } from 'src/app/interface/dto/GetFederalTaxPaymentResponse';
import { IFederalTaxPaymentVoucherRequest } from 'src/app/interface/dto/FederalTaxPaymentVoucherRequest';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { PaymentSatService } from 'src/app/services/payment-sat.service';
import { StorageService } from 'src/app/services/storage.service';
import { FileUtils } from 'src/core/utils/FileUtils';
import { CancelFederalTaxPaymentModel } from 'src/app/interface/FederalTaxPaymentModel';
import { MaskAccountPipe } from 'src/app/pipes/mask-account.pipe';
import { ProductConstants } from 'src/core/constants/ProductConstants';

@Component({
  selector: 'app-modal-federal-tax-receipt-filter',
  templateUrl: './modal-federal-tax-receipt-filter.component.html',
  styleUrls: ['./modal-federal-tax-receipt-filter.component.css'],
  providers: [PaginationService]
})
export class ModalFederalTaxReceiptFilterComponent implements OnInit {

  @ViewChild(ServiceConstants.FederalTaxReceiptFilterComponent.ModalContainerElementRef) modalContainer: ElementRef;
  @ViewChild(ServiceConstants.FederalTaxReceiptFilterComponent.Container) container: ElementRef;
  @ViewChild(ServiceConstants.FederalTaxReceiptFilterComponent.Header) header: ElementRef;
  @ViewChild(ServiceConstants.FederalTaxReceiptFilterComponent.Footer) footer: ElementRef;
  openModalService: boolean = false;
  zero: number = Numbers.Zero;
  one: number = Numbers.One;
  elementsPerPage: number = Numbers.Three;
  private readonly pageSize: number = Numbers.Three;
  vm$ = this.responsiveService.observe;
  resetCustomSelect: boolean = false;
  labelButtonContinue: string = Strings.BUTTONS.Continue;
  labelButtonCancel: string = Strings.BUTTONS.Cancel;
  labelDeclarations: string = PaymentSATStrings.CANCELLATION_AND_CONSULTATION.LabelDeclarations;
  errorMessage: string = Strings.EMPTY;
  errorStatus: boolean = false;
  messageEmpty: string = PaymentSATStrings.EXCEPTIONS.NoPayments;
  dataEmpty: boolean = false;
  editProcess: boolean = false;

  pagination$  = this.paginationService.pagination.pipe(
    map((pagination) =>{
      return {
        data: pagination.data,
        pagination
      };
    })
  );

  btnCrossClose : ButtonProperties = {
    doAction: () => {
      this.modalInstance.dismiss();
      this.federalTaxReceiptService.clearSelected();
      if(this.openModalService){
        const modalOptions: ModalOptions = {
          centered: true,
          size: Constants.MODAL_OPTIONS.SizeLg,
          modalDialogClass: ModalConstants.MODAL_OPTIONS.AllServices
        };
        this.modalService.open(ModalServicesComponent, modalOptions);
      }
    }
  };

  btnContinue: ButtonProperties = {
    doAction: () => {
      this.continue();
    },
    classes: ServiceConstants.FederalTaxReceiptFilterComponent.Modal.BtnContinue.BtnClass,
    classesImg: ServiceConstants.FederalTaxReceiptFilterComponent.Modal.BtnContinue.BtnClassImage
  };

  btnCancel: ButtonProperties = {
    doAction: () => {
      if(!this.selectedOption){
        return;
      }
      const modalOptions: ModalOptions = {
        centered: true,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalCancelFederalTaxReceipt
      };
      this.modalService.modalRef.dismiss();
      const modalInstance = this.modalService.open(ModalCancelFederalTaxReceiptComponent, modalOptions);
      modalInstance.componentInstance.data = this.selectedOption;
      modalInstance.componentInstance.openModalService=this.openModalService;
    },
    classes: ServiceConstants.FederalTaxReceiptFilterComponent.Modal.BtnCancel.BtnClass
  };

  imageReturnBtn: string = Resources.BLUE_CHEVRON_LEFT;
  imgDownload: string = SatResources.FILE_BLUE;
  modalInstance: NgbModalRef;
  title: string = ServiceConstants.FederalTaxReceiptFilterComponent.Title;
  subtitle: string = ServiceConstants.FederalTaxReceiptFilterComponent.Subtitle;
  tableTitle: ITitle[] = ServiceConstants.FederalTaxReceiptFilterComponent.TabTitle;
  dynamicTitle: ITitle[] = this.tableTitle;

  accountListOptions : string[] = [];
  periodListOptions : string[] = [];
  options = [{
    optionsConfig: {
      id: Constants.FORM_CONTROLS.Account,
      label: Strings.SELECT_ACCOUNT.SelectAccount,
      changeError: signal<boolean>(false),
      change: signal<string>(Strings.EMPTY)
    }
  }, {
    optionsConfig: {
      id: ServiceConstants.FederalTaxReceiptFilterComponent.Periods,
      label: ServiceConstants.FederalTaxReceiptFilterComponent.OptionsConfigPeriodsLabel,
      changeError: signal<boolean>(false),
      change: signal<string>(Strings.EMPTY)
    }
  }];

  selectedOption: GetFederalTaxPaymentResponse = null;
  filteredTable: boolean = false;
  progressCancelStatus: boolean = false;
  resetOnPagination: Subscription;
  private currentPage: number = Numbers.Zero;
  today: string = formatDate(
    new Date,
    Format.BasicFormatDayMonthYear,
    Constants.LOCALE_ES_MX
  );
  accounts: AccountModel[];
  listAccounts: CancelFederalTaxPaymentModel[]=[];
  listPayments: Array<GetFederalTaxPaymentResponse>;
  hiddenColumn: boolean = false;

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly paginationService: PaginationService,
    private readonly federalTaxReceiptService: FederalTaxReceiptService,
    private readonly productService: ProductsService,
    private readonly alertService: AlertService,
    private readonly paymentSatService: PaymentSatService,
    private readonly storageService: StorageService
  ) { }

  async ngOnInit(){
    this.checkPageCleaning();
    await this.getAccounts();
    if(this.federalTaxReceiptService.accountSelected !== Strings.EMPTY && this.federalTaxReceiptService.periodSelected !== Strings.EMPTY){
      this.options[Numbers.Zero].optionsConfig.change.set(this.federalTaxReceiptService.valueSelected);
      this.options[Numbers.One].optionsConfig.change.set(this.federalTaxReceiptService.periodSelected);
      await this.continue();
    }
  }

  close() {
    this.modalInstance.dismiss();
    this.federalTaxReceiptService.clearSelected();
    if(this.openModalService && !this.progressCancelStatus){
      const modalOptions: ModalOptions = {
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeLg,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.AllServices
      };
      this.modalService.open(ModalServicesComponent, modalOptions);
    }else if(this.progressCancelStatus || this.errorStatus){
      this.progressCancelStatus = false;
      const modalOptions = {
        centered: true,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal
      };
      const ref = this.modalService.open(ModalFederalTaxReceiptFilterComponent, modalOptions);
      ref.componentInstance.openModalService = this.openModalService;
    }
  }

  setChange(publicCharge: string, customSelect: CustomSelectProperties) {
    this.resetCustomSelect = false;
    if(customSelect.id === Constants.FORM_CONTROLS.Account){
      this.options[Numbers.One].optionsConfig.change.set(Strings.EMPTY);
      this.resetCustomSelect = true;
      this.periodListOptions = [];
      const date = new Date();
      const year = date.getFullYear();
      this.periodListOptions.push(year.toString());
      this.periodListOptions.push((year-Numbers.One).toString());
      this.periodListOptions.push((year-Numbers.Two).toString());
      this.listAccounts.forEach(accounts =>{
        if(accounts.value.trim() === publicCharge){
          this.federalTaxReceiptService.accountSelected = accounts.clabe;
        }
      });
    }
    customSelect.change.set(publicCharge);
    customSelect.changeError.set(Strings.EMPTY === publicCharge);
  }

  changeStyle(data: GetFederalTaxPaymentResponse) {
    this.selectedOption = data;
  }

  @HostListener('window:resize', ['$event'])
  calculate() {
    if(this.paginationService.getPaginationData.data.length>Numbers.Zero){
      const cardHeight = ServiceConstants.CARD_MODAL_HEIGHT;
      const modalContainerHeight = window.innerHeight;
      const containerPadding = Utils.getElementPadding(this.modalContainer.nativeElement);
      const availableHeight = modalContainerHeight - this.header.nativeElement.offsetHeight - this.footer.nativeElement.offsetHeight - containerPadding;
      let elementsPerPage = (Math.floor(availableHeight / cardHeight));
      elementsPerPage = elementsPerPage > Numbers.Three ? elementsPerPage : Numbers.Three;
      this.paginationService.setPageSize(elementsPerPage ?? this.pageSize);
      this.setMaxContent();
      this.hiddenColumns();
    }
  }

  setMaxContent() {
    setTimeout(() => {
      this.modalContainer.nativeElement.style.maxHeight = ClassHTML.MaxContent;
    }, Numbers.OneHundred);
  }

  private checkPageCleaning(): void {
    this.resetOnPagination = this.paginationService.pagination.subscribe(
      pagination =>{
        if(pagination.currentPage !== this.currentPage){
          this.selectedOption = null;
          this.currentPage = pagination.currentPage;
          this.hiddenColumns();
        }
      }
    );
  }

  async continue(){
    if (this.options[this.zero].optionsConfig.change() !== Strings.EMPTY &&
      this.options[this.one].optionsConfig.change() !== Strings.EMPTY) {
      this.federalTaxReceiptService.valueSelected = this.options[this.zero].optionsConfig.change();
      this.federalTaxReceiptService.periodSelected = this.options[this.one].optionsConfig.change();
      this.filteredTable = true;
      this.progressCancelStatus = true;
      try {
        const request = new GetFederalTaxPaymentRequest({
          cuentaClabe: this.federalTaxReceiptService.accountSelected,
          anio: this.federalTaxReceiptService.periodSelected,
        });
        const response = await this.federalTaxReceiptService.consultFederalTaxPayments(request);
        if (!response || !response.datos) {
          this.dataEmpty=true;
        }else{
          this.paginationService.setInitialData(response.datos,this.elementsPerPage);
        }
      } catch (error) {
        const errorDetails = Utils.getErrorMsg(error);
        this.errorMessage = Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code);
        this.errorStatus=true;
      }
    }
    this.options.forEach((element) => {
      element.optionsConfig.changeError.set(element.optionsConfig.change() === Strings.EMPTY);
    });
    if(this.editProcess){
      this.modalService.modalInstance.update({
        centered:true,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalFederalTaxReceiptFilter
      });
    }
    setTimeout(() => {
      this.calculate();
    });
  }

  async getAccounts() {
    try {
      this.accounts = await this.productService.getAccounts({forceOsb: true, reload: true, accountOptions: {
        transactionType: Constants.SERVICES_PAY_TYPE,
        operation: Constants.SERVICE_CODE.Impfed
      }});
      this.accounts.forEach(cardAccount => {
        this.accountListOptions.push(new MaskAccountPipe().transform(cardAccount.numeroCuenta, Numbers.Four) + Char.WhiteSpace + Char.MiddleDash + Char.WhiteSpace
        + cardAccount.descripcionCuenta);
        this.listAccounts.push({
          account: cardAccount.numeroCuenta,
          description: cardAccount.descripcionCuenta,
          clabe: cardAccount.cuentaClabe,
          value: new MaskAccountPipe().transform(cardAccount.numeroCuenta, Numbers.Four) + Char.WhiteSpace + Char.MiddleDash + Char.WhiteSpace + cardAccount.descripcionCuenta
        });
      });
      if(!this.editProcess){
        this.modalService.modalInstance.update({
          centered:true,
          modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalFederalTaxReceiptFilter
        });
      }
    } catch (error) {
      this.federalTaxReceiptService.clearSelected();
      this.modalService.close();
      const errorDetail = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetail.msg, errorDetail.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      }, {
        onSuccess: () => this.modalService.close()
      });
    }
  }

  async federalTaxPaymentVoucher(id:number) {
    try {
      const request: IFederalTaxPaymentVoucherRequest = {
        idImpuestoFederal: id,
        idSesion: this.storageService.getSession()
      };
      const response = await this.paymentSatService.federalTaxPaymentVoucher(request);
      if (!response.ComprobanteBase64) {
        throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
      }
      FileUtils.downloadPdf(response.ComprobanteBase64);
    } catch (error) {
      this.federalTaxReceiptService.clearSelected();
      this.modalService.close();
      const errorDetail = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetail.msg, errorDetail.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      }, {
        onSuccess: () => this.modalService.close()
      });
    }
  }

  changeFormatDate(date: string) {
    return date.gfiReplaceAll(Char.MiddleDash,Char.Slash);
  }

  validState(data: GetFederalTaxPaymentResponse){
    const today = formatDate(new Date(),FormatDate.BasicFormat, Constants.LOCALE_ES_MX);
    const date = this.changeFormatDate(data.fecha);
    if(data.estatus.trim()=== PaymentSATStrings.CANCELLATION_AND_CONSULTATION.Cancelled || today.toString() !== date){
      return true;
    }
    return false;
  }

  hiddenColumns(){
    if(this.paginationService.getPaginationData.data.length>Numbers.Zero){
      this.listPayments = this.paginationService.getPaginationData.data;
      let applyCancel = Numbers.Zero;
      this.listPayments.forEach(data =>{
        if(!this.validState(data)){
          applyCancel++;
        }
      });
      if(applyCancel === Numbers.Zero){
        this.dynamicTitle = this.tableTitle.slice(Numbers.One);
        this.hiddenColumn=true;
      }else{
        this.dynamicTitle = this.tableTitle;
        this.hiddenColumn = false;
      }
    }
  }
}
