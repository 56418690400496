<ngb-toast
  *ngFor="let toast of toastService.toasts()"
  [class]="toast.classname"
  [ngStyle]="toast.styles"
  [autohide]="toast.autohide ?? true"
  [delay]="toast.delay || 1500"
  (hidden)="toastService.remove(toast)"
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="cast(toast.textOrTpl)"></ng-template>
  </ng-template>

  <ng-template #text>{{ toast.textOrTpl }}</ng-template>
</ngb-toast>
