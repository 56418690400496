<ng-container>
    <div [ngClass]="isTransferComplete && isOwnTransfer ? 'without-mt': 'mt-3' ">
        <div class="body-s text-secondary-800">{{ this.getTotalOfPeriodicity() }}</div>
        <div class="detail-s color-details-s">Duración de traspaso</div>
    </div>
    <hr class="my-2">
    <div class="mt-3">
        <div class="body-s text-secondary-800">{{ scheduleData.initialDate | customDate:'dd/Month/yyyy' }}</div>
        <div class="detail-s color-details-s">Fecha inicial</div>
    </div>
    <hr class="my-2">
    <div class="mt-3">
        <div class="body-s text-secondary-800">{{ scheduleData.finalDate | customDate:'dd/Month/yyyy' }}</div>
        <div class="detail-s color-details-s">Fecha final</div>
    </div>
    <hr class="my-2">
</ng-container>