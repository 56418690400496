export const PATH = {
  Login: '/login',
  Home: '/inicio',
  LoginToken: '/login/token',
  Transfers: '/transferencia',
  ConfirmPolicyPayment: '/servicios/noagendables/confirmarpagopolizas',
  Services: '/servicios',
  CheckingAccountProtection: '/proteccioncuentacheques',
  UserLogin: '/loginconusuario',
  LoginPassword: '/login/clave',
  AddService: '/agregarservicio',
  SerialNumber: '/codigodeseguridadserietoken',
  ConfirmInternationalTransfer: '/transferenciasinternacionales/confirmar',
  ValidateToken: '/login/validartoken',
  PaymentPolicies: '/servicios/noagendables/pagopolizas',
  Control: '/control',
  CheckProtectionCompleted: '/proteccioncuentacheques/proteccioncuentachequescompetado',
  CreditPolicies: '/control/creditopolizas',
  ServicesConfirmPayment: '/servicios/noagendables/confirmarpago',
  TransferComplete: '/transferencia/completada',
  InternationalTransfers: '/transferenciasinternacionales',
  CheckConfirm: '/proteccioncuentacheques/proteccioncuentachequesconfirmado',
  NotAgendableServices: '/servicios/noagendables',
  SecurityCode: '/codigodeseguridad',
  BeneficiaryData: '/agregaragendainternacional',
  CompleteInternationalAgendaRegistration: '/agregaragendainternacional/completada',
  PaymentCompleted: '/servicios/noagendables/pagocompletado',
  NewAddressee: '/agregaragendanacional',
  ConfirmNewInternationalAgenda: '/agregaragendainternacional/confirmar',
  ConfirmTransferCTPatrimonial: '/transferenciactpatrimonial/confirmar',
  TransferCTPatrimonial: '/transferenciactpatrimonial',
  ConfirmCreditPayment: '/pagocredito/confirmarpago',
  ConfirmExpressTransfer: '/transferencia/confirmar',
  CompleteInvestmentRegistration: '/altadeinversioncompletada',
  ConfirmInvestment: '/confirmaraltadeinversion',
  RegisteredService: '/agregarservicio/servicioregistrado',
  ConfirmServiceDetails: '/agregarservicio/confirmar',
  CompletePayment: '/servicios/agendables/pagocompletado',
  ConfirmAgendableService: '/servicios/agendables/confirmarpago',
  ConfirmPayment: '/servicios/noagendables/confirmarpago',
  CompleteCreditPayment: '/pagocredito/pagocompletado',
  ConfirmPaymentGMM: '/servicios/noagendables/pagogmmcompletado',
  ConfirmGMM: '/servicios/noagendables/confirmargmm',
  Investment: '/altadeinversion',
  SuaPaymentCompleted: '/pagosuacompletado',
  Cancellations: '/cancelaciones',
  CompleteCancelation: '/cancelaciones/cancelacioncompletada',
  Codeflex: '/retiros',
  CodeflexConfirmWithdrawal: '/confirmarretiro',
  CodeflexCompletedWithdrawal: '/retirocompletado',
  Portability: '/portabilidad',
  PortabilityCompleted: '/portabilidad/exitosa',
  CreditPayment: '/pagocredito',
  CardAdmin: '/control/administrartarjetaadicional',
  ConfirmCancellation: '/cancelaciones/confirmarcancelacion',
  TokenNumber: '/serietoken',
  SchedulableServices: '/servicios/agendables',
  NoSchedulableServices: '/servicios/noagendables',
  ConfirmedServiceScheduled: '/servicios/agendables/confirmarpago',
  GeneralInformation: '/informaciongeneral',
  CardDeductiblePayment: '/pagodeducibledeauto',
  CompleteTransferPatrimonial: '/transferenciactpatrimonial/completada',
  Additional: '/control/administraradicionales',
  EditCard: '/control/editartarjetaadicional',
  Beneficiaries: '/beneficiarios',
  Maintenance: '/mantenimiento',
  AppValidation: 'appvalidation/:target',
  AccessHistory: '/historialacceso',
  AddPortability: '/portabilidad/alta',
  ConfirmPortability: '/portabilidad/confirmar',
  PaymentSAT: '/pagoreferenciadosat',
  ConfirmPaymentConceptSAT: '/servicios/noagendables/confirmarpagocontribucionessat',
  CompletePaymentConceptSAT: '/servicios/noagendables/pagocontribucionessatcompletado',
  LoyaltyPoints: '/puntos',
  Clarification: '/consultaaclaraciones',
  ProgrammedOperations: '/operacionesprogramadas',
  Domiciliation: '/domiciliacion',
  Hiring: '/contratacion',
  AdditionalContributions: '/aportacionesadicionales',
  AdditionalContributionsConfirm: '/confirmaraportacionesadicionales',
  AdditionalContributionsComplete: '/aportacionesadicionalescompleta',
  SiparePayment: '/servicios/noagendables/pagosiparecompletado',
  DomiciliationRegister: '/domiciliacion/alta',
  VerificationRegister: '/domiciliacion/verificacionresgistro',
  DomiciliationCompleted: '/domiciliacion/domiciliacioncompletada',
  InvestmentConfirmFundsSale: '/ventafondosdeinversion/confirmar',
  InvestmentFundsSale: '/ventafondosdeinversion',
  InvestmentCompleteFundsSale: '/ventafondosdeinversion/completado',
  InvestmentFundsBuy: '/comprafondosinversion',
  InvestmentFundsConfirm: '/comprafondosinversion/confirmar',
  InvestmentFundsCompleted: '/comprafondosinversion/completado',
  VoluntarySavingsContributions: '/afore/aportacionesvoluntarias',
  VoluntarySavingsContributionsConfirm: '/afore/aportacionesvoluntarias/confirmar',
  VoluntarySavingsContributionsCompleted: '/afore/aportacionesvoluntarias/completado',
  WebCardRequest: '/solicitudtarjetaweb',
  WebCardRequestConfirmation: '/confirmaciontarjetaweb',
  PayrollCredit: '/payroll-credit',
  PayrollCreditAdvance: '/payroll-advance',
  PaymentCreditOtherBank: '/servicios/pagocreditobanco',
  CreditAdd: '/servicios/agregarcredito',
  CreditConfirm: '/servicios/confirmarcredito',
  ConfirmPaymentCredit: '/servicios/confirmarpagocreditobanco',
  AddCreditOtherBanks: '/agregarcredito',
  ConfirmCreditOtherBanks: '/confirmarcredito',
  ConfirmPaymentCreditOtherBanks: '/confirmarpagocreditobanco',
  PaymentCreditOtherBanks: '/pagocreditobanco',
  RegisterCreditOtherBanks: '/creditoregistrado',
  CreditRegister: '/servicios/creditoregistrado',
  HireService: '/contratacion',
  InsuranceExpress: '/contratacion/segurosExpress',
  ConsultDirectDebitAfore: '/ahorroaportacionesvoluntarias/domiciliacionaportacionesvoluntarias',
  DirectDebitAfore: '/ahorroaportacionesvoluntarias',
  ConfirmDirectDebitAfore: '/ahorroaportacionesvoluntarias/confirmardomiciliacionaportacionesvoluntarias',
  CompleteDirectDebitAfore: '/ahorroaportacionesvoluntarias/domiciliacionaportacionesvoluntariascompleta',
  TransferData: '/transferencia/datos',
  TransferExpress: '/transferenciaExpress',
  ConfirmBrokerageHouse: '/agregaragendanacional/casadebolsaconfirmacion',
  CompleteBrokerageHouse: '/agregaragendanacional/casadebolsacompletada',
  Help: '/ayuda',
  FrequentlyQuestions: '/ayuda/preguntasfrecuentes',
  StepByStepTutorials: '/ayuda/tutoriales'
};
