<ng-container *ngIf="vm$ | async as vm">
    <div class="d-flex justify-content-center ">
        <div id="container-balance-held" class="modal-balance-held"
        [ngClass]="{'modal-balance-desktop':vm.breakpoints.desktop && !isiPad,
                    'modal-balance-tablet':(vm.breakpoints.tablet || isiPad) && !isiPhone,
                    'modal-balance-mobile':vm.breakpoints.mobile || isiPhone}">
            <div class="col-12">
                <div class="d-flex flex-column justify-content-start">
                    <span class="header-s main-color-label"
                    [ngClass]="{'title-size-mobile':vm.breakpoints.mobile}">Operaciones
                        en trámite</span>
                </div>
            </div>
            <ng-container>
                <div class="col-12">
                    <div class="responsive flex-nowrap moz-scroll cardSlider">
                        <div *ngFor="let operations of dataMovements$ | async as tableData">
                            <div class="col-12 detail-m primary-a pb-2">
                                {{operations.fechaRetencion | customDate}}
                            </div>
                            <div class="row-flex justify-content-between align-items-center gap-3"
                                (click)="vm.breakpoints.mobile ? 
                                openModalWithHoldings(detailTransactionSpei, operations) : null"
                                (keydown.enter)="$event.preventDefault()">
                                <div class="row-flex align-items-center gap-2">
                                    <div *ngIf="(vm.breakpoints.desktop || vm.breakpoints.tablet) && !isiPhone">
                                        <img class="close cursor-pointer" width="auto" height="auto" alt="fileLocked"
                                        src="{{ imageCDN | cdnImage }}"
                                        (click)="openModalWithHoldings(detailTransactionSpei, operations)"
                                        (keydown.enter)="$event.preventDefault()">
                                    </div>
                                    <div class="detail-m-highlight">
                                        {{operations.descripcion}}
                                    </div>
                                </div>
                                <div class="d-flex direction-end header-xs primary-a p-0">
                                    {{operations.monto | currency}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="pt-4"></div>
                        <div class="d-flex flex-row-reverse col-12 direction-end header-xs primary-a "
                            [ngClass]="{'p-held-cero':vm.breakpoints.mobile || isiPhone}">
                            Total: {{totalRetainedBalance | currency}}
                        </div>
                        <div class="w-100">
                            <app-pagination></app-pagination>
                        </div>
                        <div class="col-12 d-flex justify-content-center" 
                            [ngClass]="{'pt-4': dataMovements.length <= 4}">
                            <button class="colored-btn" (click)="close()" (keydown.enter)="$event.preventDefault()"
                                [ngClass]="{'btnDesktop':vm.breakpoints.desktop,
                                'btnTablet':vm.breakpoints.tablet,'btnMobile':vm.breakpoints.mobile || isiPhone}">
                            <span class="button-m"> Cerrar</span></button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-template #detailTransactionSpei let-modal>
  <app-detail-transaction-spei-modal [modal]="modal" [movementRetained]="movementDetails"
  [movementSelected]="movementSelected" ></app-detail-transaction-spei-modal>
</ng-template>
