import { PATH } from 'src/core/constants/Path';

export const SearchList = [
  {
    path: PATH.Transfers,
    option: 'Transferir dinero'
  },
  {
    path: PATH.Transfers,
    option: 'Enviar dinero'
  },
  {
    path: PATH.Transfers,
    option: 'Traspasos'
  },
  {
    path: PATH.Transfers,
    option: 'Otros bancos'
  },
  {
    path: PATH.Home,
    option: 'Cuentas'
  },
  {
    path: PATH.Transfers,
    option: 'Cuentas propias'
  },
  {
    path: PATH.Transfers,
    option: 'Terceros Inbursa'
  },
  {
    path: PATH.Transfers,
    option: 'Cuentas internacionales'
  },
  {
    path: PATH.NewAddressee,
    option: 'Agregar nuevo destinatario'
  },
  {
    path: PATH.NewAddressee,
    option: 'Nuevo destinatario internacional'
  },
  {
    path: PATH.NewAddressee,
    option: 'Nuevo destinatario nacional'
  },
  {
    path: PATH.GeneralInformation,
    option: 'Información general y dispositivos'
  },
  {
    path: PATH.GeneralInformation,
    option: 'Información general'
  },
  {
    path: PATH.GeneralInformation,
    option: 'Imagen de perfil'
  },
  {
    path: PATH.GeneralInformation,
    option: 'Imagen de seguridad'
  },
  {
    path: PATH.GeneralInformation,
    option: 'Token asociados'
  },
  {
    path: PATH.GeneralInformation,
    option: 'Facepass asociados'
  },
  {
    path: PATH.Beneficiaries,
    option: 'Beneficiarios'
  },
  {
    path: PATH.FrequentlyQuestions,
    option: 'Preguntas frecuentes'
  },
  {
    path: PATH.Services,
    option: 'Pago de servicios'
  },
  {
    path: PATH.Services,
    option: 'Agregar nuevo servicio'
  },
  {
    path: PATH.Services,
    option: 'Servicios no agendables'
  },
  {
    path: PATH.Services,
    option: 'Servicios agendables'
  },
  {
    path: PATH.Services,
    option: 'Modificar servicio'
  },
  {
    path: PATH.Home,
    option: 'Mis productos'
  },
  {
    path: PATH.Home,
    option: 'Movimientos'
  },
  {
    path: PATH.Home,
    option: 'Número de cuenta'
  },
  {
    path: PATH.Home,
    option: 'Número de tarjeta'
  },
  {
    path: PATH.Home,
    option: 'Clabe interbancaria'
  },
  {
    path: PATH.Home,
    option: 'Saldos'
  },
  {
    path: PATH.Home,
    option: 'Estados de cuenta'
  },
  {
    path: PATH.Control,
    option: 'Control de tarjetas'
  },
  {
    path: PATH.Control,
    option: 'Administrar tarjetas'
  },
  {
    path: PATH.Control,
    option: 'Bloqueos y límites'
  },
  {
    path: PATH.Control,
    option: 'Modificar Nip'
  },
  {
    path: PATH.Control,
    option: 'CVV dinámico'
  }
];

export const HistoryList = [
  {
    path: PATH.AccessHistory,
    option: 'Historial de acceso'
  },
  {
    path: PATH.AccessHistory,
    option: 'Accesos'
  }
];

export const ProgrammedOperationsList = [
  {
    path: PATH.ProgrammedOperations,
    option: 'Operaciones programadas'
  },
  {
    path: PATH.ProgrammedOperations,
    option: 'Operaciones pendientes de aplicarse'
  }
];

export const PortabilityList = [
  {
    path: PATH.AddPortability,
    option: 'Solicitudes de portabilidad'
  },
  {
    path: PATH.Portability,
    option: 'Alta de portabilidad'
  },
  {
    path: PATH.Portability,
    option: 'Portabilidad'
  },
  {
    path: PATH.AddPortability,
    option: 'Cancelar portabilidad'
  }
];

export const ClarificationList = [
  {
    path: PATH.Clarification,
    option: 'Aclaraciones'
  }
];

export const LoyaltyPointsList = [
  {
    path: PATH.LoyaltyPoints,
    option: 'Puntos inbursa'
  },
  {
    path: PATH.LoyaltyPoints,
    option: 'Programas registrados'
  },
  {
    path: PATH.LoyaltyPoints,
    option: 'Agregar programa'
  }
];

export const FacepassList = [
  {
    path: PATH.Transfers,
    option: 'Transferencia express'
  }
];
