import { Injectable, WritableSignal, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISimpleValue } from 'src/app/interface/ISimpleValue';
import { IFundsInvestment } from 'src/app/interface/IFundsInvestment';
import { Strings } from 'src/core/constants/Strings';
import { InvestmentSaleRequest } from 'src/app/interface/dto/InvestmentSaleRequest';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { HttpStatusCode } from '@angular/common/http';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { InvestmentPurchaseRequest } from 'src/app/interface/dto/InvestmentPurchaseRequest';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { IAddresseeAccount } from 'src/app/interface/IAddresseeAccount';
import { Constants, Numbers, Tab } from 'src/core/constants/Constants';
import { Utils } from 'src/core/utils/utils';
import { DebitCardMovementsRequest } from 'src/app/interface/DebitCardMovementsRequest';
import { StorageService } from 'src/app/services/storage.service';
import { DebitCardMovementsResponse } from 'src/app/interface/DebitCardMovementsResponse';
import { InvestmentStrings } from 'src/core/constants/InvestmentsStrings';
import { InvestmentAccountsData } from 'src/app/interface/GetInvestmentAccountsModel';
import { VariableInvestmentFundsRequest } from 'src/app/interface/dto/VariableInvestmentFundsRequest';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { VariableInvestmentSaleRequest } from 'src/app/interface/dto/VariableInvestmentSaleRequest';
import { AccountCardService } from 'src/app/services/account-card.service';
import { CardAddresseeService } from 'src/app/services/card-addressee.service';
import { NotifyRouteService } from 'src/app/services/notify-route.service';
import { InvestmentCompaniesInfoStatusService } from 'src/app/services/investment-companies-info-status.service';
import { Router } from '@angular/router';
import { EnterAmountErrorService } from 'src/app/services/enter-amount-error.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { FundsSaleDinburModel } from 'src/app/interface/FundsSaleDinburModel';
import { DateUtils } from 'src/core/utils/dateUtils';
@Injectable({
  providedIn: 'root'
})
export class FundsInvestmentService {
  private readonly formClean = {
    typeInvestment: Strings.EMPTY,
    detailInvestment: Strings.EMPTY,
    shares: Numbers.Zero,
    disabledType: false
  };
  private readonly _isFundsInvestment: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly _dataFundsInvestment: BehaviorSubject<IFundsInvestment> = new BehaviorSubject<IFundsInvestment>(this.formClean);
  private readonly _showBuyOperationHoursAlert: WritableSignal<boolean> = signal<boolean>(true);
  private readonly _showSellOperationHoursAlert: WritableSignal<boolean> = signal<boolean>(true);

  constructor(private readonly baseService: BaseServiceService, private readonly storageService: StorageService,
    private readonly accountCardService: AccountCardService,
    private readonly cardAddresseeService: CardAddresseeService,
    private readonly notifyRouteService: NotifyRouteService,
    private readonly investmentCompaniesInfoStatusService: InvestmentCompaniesInfoStatusService,
    private readonly router: Router,
    private readonly amountErrorService: EnterAmountErrorService,
    private readonly notifyAmountService: NotifyAmountService) {}

  get isFundsInvestment$(): Observable<ISimpleValue> {
    return this._isFundsInvestment.asObservable();
  }

  set isFundsInvestment(data: ISimpleValue) {
    this._isFundsInvestment.next(data);
  }

  get dataFundsInvestment() {
    return this._dataFundsInvestment.getValue();
  }

  set dataFundsInvestment(data: IFundsInvestment) {
    this._dataFundsInvestment.next(data);
  }

  clearSelected() {
    this._dataFundsInvestment.next(this.formClean);
  }

  /**
   * Realiza el consumo del servicio para realizar la compra de fondos de inversión Dinbur
   * @param {IAddresseeAccount} account - Cuenta Efe.
   * @param {ICardAccount} investmentAccount - Cuenta de inversión
   * @returns Retorna un objeto de tipo `GenericResponse<FundsSaleDinburModel>`
   */
  async purchaseInvestment(account: IAddresseeAccount, investmentAccount: ICardAccount){
    this.detailFormat();
    const request = new InvestmentPurchaseRequest({
      cantidad:this.dataFundsInvestment.shares ,
      concepto: this.dataFundsInvestment.detailInvestment.toLowerCase(),
      cuenta: investmentAccount.cardNumber,
      cuentaInversion: account.accountNumber,
      idCuentaInversion:account.idCuentaProductoDestino,
      idCuentaProducto: investmentAccount.productId
    });

    const response = await this.baseService.genericPost<GenericResponse<FundsSaleDinburModel>>(request,{ isSecure: true, addSession: true });
    if (!response || !response.datos) {
      throw new BusinessError(Strings.WITHOUT_MOVEMENTS_MSG, HttpStatusCode.NoContent);
    }
    return response;
  }

  /**
   * Establece el valor de cantidad para una compra o venta por monto
   * @param {string} amount - Monto ingresado
   */
  validateAmount(amount: string){
    if(this.dataFundsInvestment.shares === Numbers.Zero){
      this.dataFundsInvestment.shares = parseFloat(Utils.transformAmount(amount));
    }
  }

 /**
  * Actualiza el valor de Títulos por titulo
  */
  private detailFormat(){
    if(this.dataFundsInvestment.detailInvestment === InvestmentStrings.INVESTMENT_FUNDS.FundsSale.ComparisonLabelTitle){
      this.dataFundsInvestment.detailInvestment = Constants.NAVIGATION_PARAMETERS.Title;
    }
  }

   /**
   * Realiza el consumo del servicio para realizar la venta de fondos de inversión Dinbur
   * @param {IAddresseeAccount} account - Cuenta Efe.
   * @param {ICardAccount} investmentAccount - Cuenta de inversión
   * @returns Retorna un objeto de tipo `GenericResponse<FundsSaleDinburModel>`
   */
  async saleInvestment(account: IAddresseeAccount, investmentAccount: ICardAccount){
    this.detailFormat();
    const request = new InvestmentSaleRequest({
      cantidad:this.dataFundsInvestment.shares ,
      concepto: this.dataFundsInvestment.detailInvestment.toLowerCase(),
      cuenta: investmentAccount.cardNumber,
      cuentaInversion: account.accountNumber,
      idCuentaInversion:account.idCuentaProductoDestino,
      idCuentaProducto: investmentAccount.productId
    });

    const response = await this.baseService.genericPost<GenericResponse<FundsSaleDinburModel>>(request,{ isSecure: true, addSession: true });
    if (!response || !response.datos) {
      throw new BusinessError(Strings.WITHOUT_MOVEMENTS_MSG, HttpStatusCode.NoContent);
    }
    return response;
  }

   /**
   * Realiza el consumo del servicio para realizar la venta de fondos de inversión variable
   * @param {IAddresseeAccount} account - Cuenta Efe.
   * @param {ICardAccount} investmentAccount - Cuenta de inversión
   * @returns Retorna un objeto de tipo `GenericResponse<number>`
   */
  async variableInvestmentSale(account: IAddresseeAccount, investmentAccount: ICardAccount){
    this.detailFormat();
    const request = new VariableInvestmentSaleRequest({
      cuenta: account.accountNumber,
      idCuentaProducto: investmentAccount.productId,
      titulos: this.dataFundsInvestment.shares
    });
    const response = await this.baseService.genericPost<GenericResponse<number>>(request,{ isSecure: true, addSession: true });
    if (!response || !response.datos) {
      throw new BusinessError(Strings.WITHOUT_MOVEMENTS_MSG, HttpStatusCode.NoContent);
    }
    return response;
  }

  /**
   * Realiza el consumo del servicio para obtener los movimientos de las cuentas de fondos de inversión
   * @param {InvestmentAccountsData} account - Cuenta de inversión.
   * @returns Retorna un objeto de tipo `DebitCardMovementsResponse`
   */
  async getInvestmentMovements(account: InvestmentAccountsData) {
    if (account.cuenta !== Strings.EMPTY) {
      const request: DebitCardMovementsRequest = new DebitCardMovementsRequest({
        idSesion: this.storageService.getSession(),
        numeroCuenta: account.cuenta,
        idCuentaProducto: account.idCuentaProducto,
        fechaInicio: Utils.dateMovements(-Numbers.Three),
        fechaFin: Utils.dateMovements(Numbers.Zero)
      });
      return (await this.baseService.genericPost<DebitCardMovementsResponse>(request, { isSOA: true })).movimientos;
    }else{
      return [];
    }
  }

  get showBuyOperationHoursAlert() {
    return this._showBuyOperationHoursAlert();
  }

  set showBuyOperationHoursAlert(value: boolean) {
    this._showBuyOperationHoursAlert.set(value);
  }

  get showSellOperationHoursAlert() {
    return this._showSellOperationHoursAlert();
  }

  set showSellOperationHoursAlert(value: boolean) {
    this._showSellOperationHoursAlert.set(value);
  }

  resetOperationHoursAlerts(){
    this.showBuyOperationHoursAlert = true;
    this.showSellOperationHoursAlert = true;
  }

  /**
   * Realiza el consumo del servicio para realizar la compra de fondos de inversión variable
   * @param {IAddresseeAccount} account - Cuenta Efe.
   * @param {ICardAccount} investmentAccount - Cuenta de inversión
   * @returns Retorna un objeto de tipo `GenericResponse<number>`
   */
  async variableInvestmentFunds(account: IAddresseeAccount, investmentAccount: ICardAccount){
    this.detailFormat();
    const request = new VariableInvestmentFundsRequest({
      cantidad:this.dataFundsInvestment.shares ,
      concepto: this.dataFundsInvestment.detailInvestment.toLowerCase(),
      cuenta: investmentAccount.cardNumber,
      idCuentaProducto: account.idCuentaProductoDestino
    });
    const response = await this.baseService.genericPost<GenericResponse<number>>(request,{ isSecure: true, addSession: true });
    if (!response || !response.datos) {
      throw new BusinessError(Strings.WITHOUT_MOVEMENTS_MSG, HttpStatusCode.NoContent);
    }
    return response;
  }

 /**
  * Establece la cuenta origen y la cuenta destino.
  * @param {ICardAccount} cardAccount - Cuenta origen.
  * @param {ICardAccount} cardInvestment - Cuenta destino
  */
  setAccounts(cardAccount:  ICardAccount, cardInvestment: ICardAccount) {
    this.accountCardService.accountCard = cardAccount;
    this.cardAddresseeService.cardAddressee = {
      profile_image: cardInvestment.image,
      accountNumber: cardInvestment.cardNumber,
      tipoCuenta: cardInvestment.cardType,
      clabe: cardInvestment.clabe,
      saldo: cardInvestment.balance,
      idCuentaProductoDestino: cardInvestment.productId
    };
  }

/**
 * Reestablece la información de los servicios y navega al tab de fondos de inversión
 */
  returnTabInvestment() {
    this.investmentCompaniesInfoStatusService.clearModel();
    this.accountCardService.clearCardAccount();
    this.cardAddresseeService.clearCardAddressee();
    this.resetOperationHoursAlerts();
    Utils.navigationError(this.router, {tab:Tab.InvestmentCompanies,notifyRouteService: this.notifyRouteService});
  }

 /**
   * Evalúa si es un monto valido
   * @returns Retorna un objeto con el monto y el error
   */
  validAmount(){
    let userAmount = this.notifyAmountService.amount.amount;
    userAmount = userAmount=== Constants.ZERO_VALUE_$ ? Strings.EMPTY: userAmount;
    const amount = Utils.transformAmount(userAmount);
    const isError = !amount;
    this.amountErrorService.amountErrorData = { isError, errorMsg: Strings.GENERIC_ERRORS.Required };
    return {amount, isError};
  }

 /**
  * Evalúa si los títulos ingresados son correctos.
  * @param {string} details - Detalle de la transacción (monto o títulos).
  * @param {number} shares - Cantidad de títulos
  * @returns Devuelve un valor booleano - 'true' o 'false' - basado en la cantidad de títulos.
  */
  validateShares(details: string, shares: number): boolean {
    if (details === InvestmentStrings.INVESTMENT_FUNDS.FundsSale.ComparisonLabelTitle && shares <= Numbers.Zero) {
      this.investmentCompaniesInfoStatusService.sharesError = true;
      return false;
    }
    this.investmentCompaniesInfoStatusService.sharesError = false;
    return true;
  }

/**
 * Evalúa si ya se tiene un detalle de la transacción seleccionado.
 * @param {string} detail - Detalle de la transacción (monto o títulos).
 * @returns Devuelve un valor booleano - 'true' o 'false' - basado en si ya se seleccionó un detalle de la transacción.
 */
  validationSelect(detail: string){
    if(detail === InvestmentStrings.INVESTMENT_FUNDS.FundsSale.ComparisonLabelSelect){
      this.investmentCompaniesInfoStatusService.selectError = true;
      return false;
    }
    return true;
  }

  /**
   * Limpia el componente del monto.
   * @param {boolean} investmentFoundType - Valor booleano que indica si es una inversión Dinbur.
   * @param {boolean} selectedAmount - Valor booleano que indica si el monto esta seleccionado.
   */
  validationDetail(investmentFoundType: boolean, selectedAmount:boolean){
    if(!investmentFoundType || investmentFoundType && selectedAmount){
      this.notifyAmountService.amount = { amount: Constants.ZERO_VALUE_$ };
    }
  }

  isEndAvailableHour(timeStamp: string, endHour: string){
    if(Number(DateUtils.getTimeResponse(timeStamp).hour)===Number(endHour.slice(Numbers.Zero, Numbers.Two))
    && Number(DateUtils.getTimeResponse(timeStamp).minutes)>=Number(endHour.slice(Numbers.Three, Numbers.Five))){
      return false;
    }else if(Number(DateUtils.getTimeResponse(timeStamp).hour)> Number(endHour.slice(Numbers.Zero, Numbers.Two))){
      return false;
    }
    return true;
  }

  isInitialAvailableHour(timeStamp: string, initialHour: string){
    if(Number(DateUtils.getTimeResponse(timeStamp).hour)===Number(initialHour.slice(Numbers.Zero, Numbers.Two))
    && Number(DateUtils.getTimeResponse(timeStamp).minutes)<=Number(initialHour.slice(Numbers.Three, Numbers.Five))){
      return false;
    }else if(Number(DateUtils.getTimeResponse(timeStamp).hour)<Number(initialHour.slice(Numbers.Zero, Numbers.Two))){
      return false;
    }
    return true;
  }
}
