export enum Numbers {
  Zero = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Eight = 8,
  Nine = 9,
  Ten = 10,
  Eleven = 11,
  Twelve = 12,
  Thirteen = 13,
  Fourteen = 14,
  FourteenPointEight = 14.8,
  Fifteen = 15,
  Sixteen = 16,
  Seventeen = 17,
  Eighteen = 18,
  Twenty = 20,
  TwentyThree = 23,
  TwentyFour = 24,
  TwentyFive = 25,
  TwentySix = 26,
  TwentySeven = 27,
  TwentyEight = 28,
  Thirty = 30,
  ThirtyOne = 31,
  ThirtyTwo = 32,
  Fifty = 50,
  FiftyOne = 51,
  FiftySeven = 57,
  Forty = 40,
  FortyFive = 45,
  Sixty = 60,
  FortyEight = 48,
  Ninety = 90,
  TwoHundred = 200,
  NinetySix = 96,
  OneHundred = 100,
  ThreeHundred = 300,
  ThreeHundredTen = 310,
  SixHundredSixtySeven = 667,
  OneThousand = 1000,
  Eighty = 80,
  ThreeThousandSixHundred = 3600
}

export enum Recharges {
  Ten = 10,
  Fifteen = 15,
  Twenty = 20,
  Thirty = 30,
  Fifty = 50,
  Seventy = 70,
  OneHundred = 100,
  OneHundredTwenty = 120,
  OneHundredFifty = 150,
  TwoHundred = 200,
  ThreeHundred = 300,
  FourHundred = 400,
  FiveHundred = 500,
  SixHundred = 600,
  SevenHundred = 700,
  EightHundred = 800,
  NineHundred = 900,
  OneThousand = 1000,
  OneThousandFive = 1500,
  TwoThousand = 2000
}