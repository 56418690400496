<div id={{idSearchStyle}} class="mb-3 search"
  [ngClass]="formatInsurance ? 'format-insurance' : '' || searchCustom ? 'search-custom-styles': ''"
  *ngIf="!searchCustom && !onlyNumber && !searchCard">
  <div
    id={{idSearchStyle}}
    [class]="classSearch"
    [class.p-0]="removePadding"
    [ngClass]="formatInsurance ? 'format-insurance' : ''"
    [style]="styleSearch">
    <input
      id="search"
      #SearchInput
      type="text"
      name="buscar"
      class="search-bar"
      [id]="idSearch"
      (focus)="this.onFocus.emit()"
      [ngStyle]="{ backgroundColor: customColor }"
      [ngClass]="getInputClasses(SearchInput)"
      [placeholder]="placeholder"
      aria-label="search"
      aria-describedby="basic-addon1"
      [maxLength]="referenceLength"
      (input)="searchFn(SearchInput.value)"
      (keypress)="cleanValue(SearchInput.value, $event)"
      [(ngModel)]="valueFilter"
      autocomplete="off"
      [tabIndex]="tabIndex"
      cleanInput [cleanRegex]="regex"/>
      <app-reset-input-value
        #ResetInputValue
        [id]="resetInputId"
        [customColor]="customColor"
        [targetInput]="SearchInput"
        (clickEmmiter)="searchFn('')">
      </app-reset-input-value>
    <label for="buscarI" [ngStyle]="{ backgroundColor: customColor }" class="input-group-text" id="basic-addon1">
      <img [src]="search|cdnImage" width="24px" height="24px" alt="icono de buscar" (click)="searchActionFn()"/>
    </label>
  </div>
</div>

<!--
  SEARCH -> only numbers
-->
<div *ngIf="onlyNumber && !searchCard">
  <div
    id={{idSearchStyle}}
    [class.p-0]="removePadding"
    class="mb-3 input-group search"
    [ngClass]="formatInsurance ? 'format-insurance' : ''">
    <input
      type="text" name="buscar"
      [ngStyle]="{ backgroundColor: customColor }"
      #SearchInput class="form-control" placeholder="{{placeholder}}" aria-label="Username"
      id="search-number" aria-describedby="basic-addon1"
      (input)="searchFn(SearchInput.value)" [maxLength]="referenceLength"
      onkeypress="return regexOneMoreNumbers.test(event.key)" />
      <app-reset-input-value
        [id]="resetInputId"
        [customColor]="customColor"
        [targetInput]="SearchInput"
        (clickEmmiter)="searchFn('')">
      </app-reset-input-value>
    <label for="searchNumber" [ngStyle]="{ backgroundColor: customColor }" class="input-group-text" id="basic-addon1">
      <img [src]="search|cdnImage" width="auto" height="auto" alt="icono de buscar" /></label>
  </div>
</div>


<div
  id={{idSearchStyle}}
  [class.p-0]="removePadding"
  class="input-group mb-3 search"
  [ngClass]="formatInsurance ? 'format-insurance' : '' || searchCustom ? 'search-custom-styles': ''"
  *ngIf="searchCustom && !searchCard">
  <input
    type="text" name="buscar"
    #SearchInput id="search-t"
    [ngStyle]="{ backgroundColor: customColor }"
    class="form-control"
    placeholder="Buscar por C.P."
    aria-label="Username" aria-describedby="basic-addon1"
    (input)="searchFn(SearchInput.value)"
    [appValidateInputRegex]="regexNumber" />
    <app-reset-input-value
      [id]="resetInputId"
      [customColor]="customColor"
      [targetInput]="SearchInput"
      (clickEmmiter)="searchFn('')">
    </app-reset-input-value>
  <label for="searchT" [ngStyle]="{ backgroundColor: customColor }" class="input-group-text" id="basic-addon1">
    <img [src]="search|cdnImage" width="auto" height="auto" alt="icono de buscar" /></label>
</div>
