<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="showLabel">
    <div class="mb-3 pb-3 header-xs default-color" [ngClass]="classes">
      {{ label }}
    </div>
  </ng-container>
  <div
    [attr.id]="showImage ? '' : cardId"
    [ngClass]="showImage ? cardClasses : ''"
  >
    <ng-container *ngIf="!vm.breakpoints.mobile || showImage">
      <div class="align-self-center">
        <img
          width="auto"
          height="auto"
          [src]="cardImage"
          alt="Bank Card"
          [ngClass]="cardImageClass"
        />
      </div>
    </ng-container>
    <div>
      <div>
        <label class="body-s" [ngClass]="headClass">{{ cardName }}</label>
      </div>
      <div class="detail-s" [ngClass]="bodyClass">
        {{ cardNumber }}
      </div>
    </div>
  </div>
  <hr
    [ngClass]="dividerClass"
    [class.mt-4]="!vm.breakpoints.mobile && dividerMargin"
  />
</ng-container>
