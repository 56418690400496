import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { combineLatestWith, map } from 'rxjs';
import { Alert } from 'src/app/interface/Alert';
import { AlertService } from 'src/app/services/alert.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Strings } from 'src/core/constants/Strings';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent {
  @Input() customAlert?: Alert;
  @Input() flux?: string = Strings.EMPTY;
  @Output() closeAlert: EventEmitter<void> = new EventEmitter<void>();
  show: boolean = true;
  investmentFunds:string=Strings.INVESTMENT_FUNDS.InvestmentFunds;
  vm$ = this.alertService.alert$.pipe(
    combineLatestWith(
      this.alertService.isClose$,
      this.responsiveService.observe
    ),
    map(([alert, close, breakpoints]) => {
      return { alert: this.customAlert ?? alert, close, breakpoints };
    })
  );

  constructor(
    private readonly alertService: AlertService,
    private readonly responsiveService: ResponsiveService
  ) { }

  close() {
    if (this.customAlert) {
      this.show = false;
      if (this.flux && this.flux === Strings.INVESTMENT_FUNDS.InvestmentFunds) {
        this.alertService.isClose = true;
      }
    }
    else{
      this.alertService.isClose = true;
    } 
    this.closeAlert.emit();
  }
}
