import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISimpleValue } from 'src/app/interface/ISimpleValue';

interface IReturn {
  isReturn: boolean,
  year?: number,
  month?: number,
  day?: number
}

@Injectable({
  providedIn: 'root'
})
export class SendAnotherDateService {

  private readonly stateReturn: BehaviorSubject<IReturn> = new BehaviorSubject<IReturn>({isReturn: false });
  private readonly _isOtherDate: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({value: false });
  private readonly _isSchedule: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({value: false });

  get ReturnErrorObservable() {
    return this.stateReturn.asObservable();
  }

  get ReturnValue(): IReturn {
    return this.stateReturn.getValue();
  }

  set ReturnData(data: IReturn) {
    this.stateReturn.next(data);
  }

  get isOtherDate(): ISimpleValue {
    return this._isOtherDate.getValue();
  }

  set isOtherDate(data: ISimpleValue) {
    this._isOtherDate.next(data);
  }

  get isSchedule(): ISimpleValue {
    return this._isSchedule.getValue();
  }

  set ReturnisScheduleData(data: ISimpleValue) {
    this._isSchedule.next(data);
  }

}
