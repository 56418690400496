import { BehaviorSubject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { Strings } from 'src/core/constants/Strings';
import { SatReferencedPaymentStrings } from 'src/core/constants/SatReferencedPaymentStrings';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { AccountCardService } from 'src/app/services/account-card.service';
import { PATH } from 'src/core/constants/Path';
import { FormValidations, InputTypes } from 'src/core/constants/Constants';
import { NavigationStart, Router } from '@angular/router';
import { InputConfig } from 'src/app/interface/IInputConfig';
import { ServiceAccountNumberService } from 'src/app/services/service-account-number.service';
import { Validations } from 'src/core/constants/FormConstants';
import { Numbers } from 'src/core/constants/Numbers';
import { Validators } from '@angular/forms';
import { ConsultSatPaymentsResponse, IReferencedPaymentInformationSAT } from 'src/app/interface/ConsultSatPaymentsResponse';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { ConsultSatPaymentsRequest } from 'src/app/interface/ConsultSatPaymentsRequest';
import { StorageService } from 'src/app/services/storage.service';
import { PaySatRequest } from 'src/app/interface/dto/PaySatRequest';
import { IPaySatResponse } from 'src/app/interface/dto/PaySatResponse';
import { IPaySatVoucherResponse } from 'src/app/interface/dto/PaySatVoucherResponse';
import { PaySatVoucherRequest } from 'src/app/interface/dto/PaySatVoucherRequest';
export interface IReceiptFilters {
  filterSearch: string;
  accountNumber: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReferencedPaymentSATService {

  private readonly PAYMENT_SAT_PATHS = [
    PATH.NotAgendableServices,
    PATH.ServicesConfirmPayment,
    PATH.PaymentCompleted,
    PATH.SecurityCode];

  private readonly DEFAULT_FILTERS: IReceiptFilters = {
    filterSearch: Strings.EMPTY,
    accountNumber: Strings.EMPTY
  };

  private readonly _filters: BehaviorSubject<IReceiptFilters> = new BehaviorSubject<IReceiptFilters>(this.DEFAULT_FILTERS);

  private  sat: IReferencedPaymentInformationSAT;
  private readonly _optionSelected:{account: string, idAccount: number, description: string} = {account: Strings.EMPTY, idAccount: Numbers.Zero,
    description: Strings.EMPTY};
  private readonly _satPayment: BehaviorSubject<IPaySatResponse> = new BehaviorSubject<IPaySatResponse>(null);
  private captureLine: string;

  private readonly _accountsFormConfig: InputConfig[] = [
    {
      type: InputTypes.Select,
      label: SatReferencedPaymentStrings.FORM_CONTROLS.AccountNumberLabel,
      name: SatReferencedPaymentStrings.FORM_CONTROLS.AccountNumberName,
      placeholder: Strings.EMPTY,
      value: Strings.EMPTY,
      validations: [Validations.required]
    }
  ];

  private readonly _captureLineForm: InputConfig[] = [
    {
      type: InputTypes.Text,
      label: SatReferencedPaymentStrings.SAT_REFERENCES_PAYMENT.CaptureLineLabel,
      name: SatReferencedPaymentStrings.SAT_REFERENCES_PAYMENT.CaptureLineName,
      placeholder: SatReferencedPaymentStrings.SAT_REFERENCES_PAYMENT.UnderLines,
      value: Strings.EMPTY,
      maxlength: Numbers.Twenty,
      validations: [
        {
          name: FormValidations.Required,
          validator: Validators.required,
          message: Strings.GENERIC_ERRORS.Required
        },
        {
          name: FormValidations.MinLength,
          validator: Validators.minLength(Numbers.Twenty),
          message: SatReferencedPaymentStrings.SAT_REFERENCES_PAYMENT.InvalidCaptureLine
        }
      ]
    }
  ];

  constructor(private readonly notifyAmountService: NotifyAmountService,
    private readonly cardAccountService: AccountCardService,
    private readonly router: Router,
    private readonly serviceAccountNumberService: ServiceAccountNumberService,
    private readonly baseService: IBaseService,
    private readonly storageService: StorageService) {}

  get filters(){
    return this._filters.getValue();
  }

  set filters(filters: IReceiptFilters) {
    this._filters.next(filters);
  }

  cleanFilters(): void {
    this.filters = this.DEFAULT_FILTERS;
  }

  cleanData(): void {
    this.filters = this.DEFAULT_FILTERS;
    this.serviceAccountNumberService.serviceAccountNumberData = {
      accountNumber: Strings.EMPTY, errorMsg: Strings.EMPTY, isError:false
    };
    this.cardAccountService.clearCardAccount();
    this.notifyAmountService.clearAmount();
    this.captureLine = Strings.EMPTY;
  }

  get accountsFormConfig(): InputConfig[] {
    return this._accountsFormConfig;
  }

  get captureLineForm(): InputConfig[] {
    return this._captureLineForm;
  }

  set setArray(sat: IReferencedPaymentInformationSAT) {
    this.sat = sat;
  }

  get getArray(): IReferencedPaymentInformationSAT {
    return this.sat;
  }

  get accountSelected(){
    return this._optionSelected.account;
  }

  set accountSelected(value: string) {
    this._optionSelected.account = value;
  }

  get idSelected(){
    return this._optionSelected.idAccount;
  }

  set idSelected(value: number) {
    this._optionSelected.idAccount = value;
  }

  get description(){
    return this._optionSelected.description;
  }

  set description(value: string) {
    this._optionSelected.description = value;
  }

  clearSelected(){
    this._optionSelected.account = Strings.EMPTY;
    this._optionSelected.idAccount = Numbers.Zero;
    this._optionSelected.description = Strings.EMPTY;
  }

  checkNavigation(): Subscription {
    return this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && !this.PAYMENT_SAT_PATHS.includes(event.url)) {
        this.cleanData();
      }
    });
  }

  async payServiceSat(request: PaySatRequest) {
    return this.baseService.genericPost<IPaySatResponse>(request, { isSOA: true });
  }

  async getSatPayments(accountNumber: string, accountProductId: number){
    const request = new ConsultSatPaymentsRequest({IdCuentaProducto: accountProductId, NumeroCuenta: accountNumber,
      IdSesion:this.storageService.getSession()});
    const response = await this.baseService.genericPost<ConsultSatPaymentsResponse>(request, {isSOA: true});
    return response.ListaPagosSat;
  }

  async getPaySatVoucher(request: PaySatVoucherRequest){
    return this.baseService.genericPost<IPaySatVoucherResponse>(request, { isSOA: true });
  }

  get satPayment$(){
    return this._satPayment.asObservable();
  }

  get satPayment(){
    return this._satPayment.getValue();
  }

  set satPayment(data: IPaySatResponse){
    this._satPayment.next(data);
  }

  get getCaptureLine(){
    return this.captureLine;
  }

  set getCaptureLine(data: string){
    this.captureLine = data;
  }

  deleteCapture(){
    this.captureLine = Strings.EMPTY;
  }
}
