<ng-container *ngIf="vm$ | async as vm">
  <label for="" class="general-type mt-3 mb-3 header-xs">{{title}}</label>
  <form (change)="sendTypes()">
    <div class="content-type drop-shadow-m">
      <ng-container>
        <div class="display-investment">
          <label for="type-investment"
            class="m-2 position-absolute detail-s-highlight pos-label-input style-custom-span">
            <span class="h6 small bg-white px-1">
              Tipos de inversión
            </span>
          </label>
          <select id="type-investment" name="typeInvestment" [ngClass]="{'border-red' : withOutType}"
           class="form-select body-s input-detail inerit-font"
            role="button" (change)="selectType($any($event).target.value)"
            >
            <option selected value=""> Selecciona </option>
            <option [selected]="typeBack == data.Descripcion" *ngFor="let data of vm.ListaSubCuentas"
              [value]="data.Codigo">{{data.Descripcion}}</option>
          </select>
          <div *ngIf="withOutType" class="detail-m validation-error">
            Este campo es requerido.
          </div>
        </div>
        <div class="select display-investment">
          <label for="days" class="m-2 position-absolute detail-s-highlight pos-label-input style-custom-span">
            <span class="h6 small bg-white px-1">
              Plazo
            </span>
          </label>
          <select id="days" name="days" class="form-select body-s input-detail inerit-font" required role="button"
            (change)="selectDate($any($event).target.value)" [ngClass]="{'border-red' : withOutDate}"
            [disabled]="!selectedType">
            <option value="">Selecciona </option>
            <option [selected]="dateBack == data" *ngFor="let data of elementsDate" value="{{data}}">{{data + ' ' +
              days}}</option>
          </select>
          <div *ngIf="withOutDate" class="detail-m validation-error">
            Este campo es requerido.
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</ng-container>
