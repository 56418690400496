import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { map } from 'rxjs';
import { Resources } from 'src/core/constants/Resources';
import { ModalService } from 'src/app/shared/modal.service';
import { Char, Constants, InputTypes, Numbers } from 'src/core/constants/Constants';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { InputConfig } from 'src/app/interface/IInputConfig';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { AforeNumbers, AforeStrings } from 'src/core/constants/AforeStrings';
import { AforeDataService } from 'src/app/services/afore-data.service';
import { AforeMovementsRequest } from 'src/app/interface/dto/AforeMovementsRequest';
import { AlertService } from 'src/app/services/alert.service';
import { NotifyDateService } from 'src/app/services/notify-date.service';
import { AforeDetailMovementsService } from 'src/app/services/afore-detail-movements.service';
import { DateUtils } from 'src/core/utils/dateUtils';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { FileUtils } from 'src/core/utils/FileUtils';

@Component({
  selector: 'app-modal-consultation-afore',
  templateUrl: './modal-consultation-afore.component.html',
  styleUrls: ['./modal-consultation-afore.component.css']
})
export class ModalConsultationAforeComponent {
  @ViewChild('buttonCtn', { static: true }) blur: ElementRef;
  modalInstance: NgbModalRef;
  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints }))
  );
  closeCircleBlue = Resources.CLOSE_CIRCLE_BLUE;
  modalData: { selectedOption: string };
  labelInput: string;
  dateForm: FormGroup;
  movementType: string = Strings.EMPTY;
  movementDetail: string = AforeStrings.LABELS_AFORE.MovementDetail;
  choosePeriod: string = AforeStrings.LABELS_AFORE.ChoosePeriod;
  period: string = AforeStrings.LABELS_AFORE.Period;
  to: string = AforeStrings.LABELS_AFORE.To;
  accountStatus: string = AforeStrings.LABELS_AFORE.AccountStatus;
  msgButton: string = Strings.ACCOUNT_STATEMENT.LabelButton;
  startDate: string = Strings.EMPTY;
  endDate: string = Strings.EMPTY;
  currentDate: boolean = true;
  readonly defaultImg: string = Resources.CALENDAR_BLUE;
  disabled: boolean = true;
  maxStartDate = AforeStrings.MAX_DATE_DETAILS_AFORE;
  minDate = Constants.MIN_DATE_PORTABILITY;
  inputConfig: InputConfig[] = [
    {
      type: InputTypes.Select,
      label: AforeStrings.LABELS_AFORE.Period,
      name: Constants.MODAL_OPTIONS.Card
    }
  ];
  formConsultationAfore: FormGroup = this.dynamicFormService.toFormGroup(this.inputConfig);
  monthsToShow: string[] = AforeStrings.LABELS_AFORE.MonthList;
  monthsFilter: string = AforeStrings.LABELS_AFORE.MonthList[Numbers.Zero];
  showErrorDate: boolean = false;
  errorDateLabel: string = AforeStrings.VALIDATION_DATE;
  downloadDocumentDetailMovements: string = AforeStrings.DOWNLOAD_DOCUMENT_DETAIL_MOVEMENTS;
  minimumConsultation : { year: number, month: number, day: number };
  showErrorSameDay: boolean = false;
  selectDate: string = AforeStrings.SELECT_DATE;
  isDateRange: boolean = true;

  constructor(
    private readonly modalService: ModalService,
    private readonly responsiveService: ResponsiveService,
    private readonly dynamicFormService: DynamicFormService,
    private readonly aforeDataService: AforeDataService,
    private readonly alertService: AlertService,
    private readonly notifyDateService: NotifyDateService,
    private readonly aforeDetailMovementsService: AforeDetailMovementsService
  ) { }

  ngOnInit(): void {
    this.getMinimumDate();
    this.movementType = this.modalData.selectedOption;
    this.currentDate = false;
  }

  changeSelectFilter(): void {
    const date = new Date();
    const month = date.getMonth() + 1;
    if (month >= Numbers.Nine && month <= Numbers.Twelve) {
      this.monthsToShow.splice(Numbers.Two);
    } else if (month >= Numbers.Five && month <= Numbers.Eight) {
      this.monthsToShow = [this.monthsToShow.at(Numbers.Zero)];
    }
  }

  getStartDate(event: string) {
    this.startDate = event;
    this.validateDate();
  }

  getEndDate(event: string) {
    this.endDate = event;
    this.validateDate();
  }

  validateDate() {
    const initialDate = DateUtils.parseSimpleFormatDate(this.startDate);
    const finalDate   = DateUtils.parseSimpleFormatDate(this.endDate);
    const minConsultationDate = new Date(this.minimumConsultation.year, this.minimumConsultation.month - Numbers.One, this.minimumConsultation.day);
    this.showErrorSameDay = this.endDate && this.startDate === this.endDate;
    this.disabled = !this.startDate || !this.endDate || this.showErrorSameDay || !this.areDatesValid(initialDate, finalDate, minConsultationDate);
    if(!this.startDate || !this.endDate || this.showErrorSameDay) {
      this.showErrorDate = false;
      return;
    }
  }

  areDatesValid(initialDate: Date, finalDate: Date, minConsultationDate: Date) {
    return  !this.validateErrorDateEnd(initialDate, finalDate) &&
            initialDate >= minConsultationDate &&
            finalDate >= minConsultationDate;
  }

  validateErrorDateEnd(initialDate: Date, finalDate: Date): boolean {
    if (this.validateMinDateEnd(finalDate) && this.validateMinDate(initialDate)) {
      this.showErrorDate = finalDate <= initialDate;
    }
    return this.showErrorDate;
  }

  validateMinDateEnd(finalDate: Date): boolean {
    const minDateFormatted = new Date(this.minDate.year, this.minDate.month - Numbers.One, this.minDate.day);
    return finalDate >= minDateFormatted;
  }

  validateMinDate(initialDate: Date): boolean {
    const initialDateFormatted = new Date(this.minDate.year, this.minDate.month - Numbers.One, this.minDate.day);
    return initialDate >= initialDateFormatted;
  }

  close() {
    this.modalService.close();
  }

  onChange(selected: string) {
    this.monthsFilter = selected;
    this.disabled = false;
  }

  async downloadFile() {
    const request = new AforeMovementsRequest({
      numeroCuenta: this.aforeDataService.afore.cuenta.idCuenta,
      fechaInicial: DateUtils.formattedDate(this.startDate),
      fechaFinal: DateUtils.formattedDate(this.endDate)
    });
    try {
      const response = await this.aforeDetailMovementsService.aforeMovements(request);
      if (response) {
        const base64 = response.toString();
        FileUtils.downloadPdf(base64, this.downloadDocumentDetailMovements);
        this.close();
       } else {
        throw new BusinessError(Strings.MODAL_EXCEPTIONS_POINTS.Service.Message);
       }
    } catch (error) {
      this.close();
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      },
      {
        onSuccess: () => this.close()
      });
    }
  }

  getMinimumDate(){
    const currentDate = new Date();
    const year: number = currentDate.getFullYear() - Numbers.One;
    const month: number = currentDate.getMonth() + Numbers.One;
    let day: number = currentDate.getDate();

    if(month === Numbers.Two  && day === AforeNumbers.TwentyNine){
      day = DateUtils.isLeapYear(year) ? AforeNumbers.TwentyNine : AforeNumbers.TwentyEight;
    }

    const lastYearDate: Date = Utils.convertStringToDate(`${day}${Char.Slash}${month}${Char.Slash}${year}`);
    this.minimumConsultation = { year: lastYearDate.getFullYear(), month: lastYearDate.getMonth(), day: lastYearDate.getDate()};
  }

  ngOnDestroy(){
    this.notifyDateService.dateStructData = undefined;
    this.dynamicFormService?.clearForm();
  }

  isDateError(isDateError: boolean){
    if (isDateError) {
      this.showErrorDate = this.showErrorSameDay = false;
    }
  }

  @HostListener('window:popstate')
  onBack(){
    this.modalInstance.close();
  }
}
