import { Pipe, PipeTransform } from '@angular/core';
import { Constants, Numbers } from 'src/core/constants/Constants';

@Pipe({
  name: 'ellipsisText'
})
export class EllipsisTextPipe implements PipeTransform {

  transform(text: string, maxLength: number): string {
    if(!text){
      return text;
    }
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substr(Numbers.Zero, maxLength) + Constants.ELLIPSIS;
    }
  }

}
