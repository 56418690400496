import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/modal.service';
import { StorageService } from 'src/app/services/storage.service';
import { Resources } from 'src/core/constants/Resources';
import { Constants } from 'src/core/constants/Constants';
import { ITransactionVoucher } from 'src/app/interface/TransactionVoucherRequest';
import { ITransactionSipare } from 'src/app/interface/ITransactionSipare';
import { Strings } from 'src/core/constants/Strings';
import { Router } from '@angular/router';
import { PATH } from 'src/core/constants/Path';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { Utils } from 'src/core/utils/utils';
import { AlertService } from 'src/app/services/alert.service';
@Component({
  selector: 'app-detail-previous-payments-modal',
  templateUrl: './detail-previous-payments-modal.component.html',
  styleUrls: ['./detail-previous-payments-modal.component.css']
})
export class DetailPreviousPaymentsModalComponent implements OnInit, OnDestroy {

  receiptDataRequest: ITransactionVoucher;
  payment: string = Constants.SERVICE_CODE.Sipare;
  title: string  = Strings.SERVICE_PAYMENT.SipareTitle;
  
  constructor(private readonly modalService: ModalService,
              private readonly storage: StorageService,
              private readonly router: Router,
              private readonly stateServicePayIdService: StateServicePayIdService,
              private readonly alertService: AlertService) { }
  ngOnInit(): void {
   this.generateReceiptData();
  }
  modalData: ITransactionSipare;

  closeIcon: string = Resources.CLOSE_CIRCLE_BLUE;
  
  close() {
    this.modalService.close();
  }

  private generateReceiptData() {
    this.receiptDataRequest = {
      IdSession: this.storage.getSession(),
      NumeroCuenta: this.modalData.numeroCuenta.toString(),
      NumeroReferencia: Number( this.modalData.idPagoSipare)
    };
  }

  async otherOperation() {
    this.modalService.close();
    const serviceSipare = { id: Constants.SERVICE_CODE.Sipare };
    try {
      const serviceDetail = await this.stateServicePayIdService.getServiceDetail(serviceSipare);
      this.stateServicePayIdService.service = {
        id: serviceSipare.id,
        params: { dailyLimit: serviceDetail.maxLimit }
      };
      this.router.navigate([PATH.NotAgendableServices]);
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg,errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT
      });
    }

  }

  ngOnDestroy() {
    this.modalService.close();
  }
}


