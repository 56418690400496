import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IInsurancesEvent } from 'src/app/interface/IInsurancesEvent';
import { StorageService } from 'src/app/services/storage.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { MonthlyPromotionsRequest } from 'src/app/interface/dto/MonthPromotionsInsuranceRequest';
import { Char, Constants, Numbers, Position, Regex } from 'src/core/constants/Constants';
import { InsurancePolicyInfoStatusService } from 'src/app/services/insurance-policy-info-status.service';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { IAmount } from 'src/app/interface/IAmount';
import { Strings } from 'src/core/constants/Strings';
import { SentinelService } from 'src/app/services/sentinel.service';
import { InsurancePolicies } from 'src/app/services/insurance-policies-status.service';
import { PayPoliciesIFMRequest } from 'src/app/interface/dto/PayPoliciesIFMRequest';
import { Utils } from 'src/core/utils/utils';
import { ConsultCurrencyExchangeResponse } from 'src/app/interface/dto/ConsultCurrencyExchangeResponse';
import { IPayPoliciesIFMResponse } from 'src/app/interface/dto/PayPoliciesIFMResponse';
import { ConsultCurrencyExchangeRequest } from 'src/app/interface/dto/ConsultCurrencyExchangeRequest';
import { IMonthPromotionsInsuranceResponse } from 'src/app/interface/dto/MonthPromotionsInsuranceResponse';
import { InsuranceIcons, PolicyConstants } from 'src/core/constants/PolicyConstants';
import { PolicyReceiptsModel } from 'src/app/interface/PolicyReceiptsModel';
import { PolicyReceiptsSettleModel } from 'src/app/interface/PolicyReceiptsSettleModel';

@Injectable({
  providedIn: 'root'
})
export class InsurancesStatusService {
  private readonly insuranceIcons:InsuranceIcons = {
    gmm:{isHidden: true},
    auto:{isHidden: true},
    vida:{isHidden:true},
    daños:{ isHidden:true},
    idSelected: Strings.EMPTY
  };
  private readonly _isHiddenIconsInsurance: BehaviorSubject<InsuranceIcons> = new BehaviorSubject<InsuranceIcons>(this.insuranceIcons);
  private readonly _showOptionsInsurances: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _status: BehaviorSubject<IInsurancesEvent> = new BehaviorSubject<IInsurancesEvent>({value: false});
  private readonly _statusLife: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _monthlyPromotionsResponse: IMonthPromotionsInsuranceResponse;
  private _currencyExchangeResponse: ConsultCurrencyExchangeResponse;
  constructor(
    private readonly storageService: StorageService,
    private readonly baseService: IBaseService,
    private readonly insurancePolicyInfoStatusService :InsurancePolicyInfoStatusService,
    private readonly sentinelService: SentinelService,
    private readonly insurancePoliciesService:InsurancePolicies,
    private readonly insurancePolicies :InsurancePolicies
  ) { }

  get currencyExchangeResponse(){
    return this._currencyExchangeResponse;
  }

  private set currencyExchangeResponse(data:ConsultCurrencyExchangeResponse){
    this._currencyExchangeResponse = data;
  }
  
  get monthlyPromotionsResponse(){
    return this._monthlyPromotionsResponse;
  }

  private set monthlyPromotionsResponse(data:IMonthPromotionsInsuranceResponse){
    this._monthlyPromotionsResponse = data;
  }

  get status$() {
    return this._status.asObservable();
  }

  get status() {
    return this._status.getValue();
  }

  set status(data: IInsurancesEvent) {
    this._status.next(data);
  }

  get statusLife$() {
    return this._statusLife.asObservable();
  }

  get statusLife() {
    return this._statusLife.getValue();
  }

  set statusLife(statusLife: boolean) {
    this._statusLife.next(statusLife);
  }
  
  get isHiddenIconsInsurance$() {
    return this._isHiddenIconsInsurance.asObservable();
  }

  get isHiddenIconsInsurance() {
    return this._isHiddenIconsInsurance.getValue();
  }

  set isHiddenIconsInsurance(insuranceIcons: InsuranceIcons) {
    this._isHiddenIconsInsurance.next(insuranceIcons);
  }

  
  get showOptionsInsurances$() {
    return this._showOptionsInsurances.asObservable();
  }

  get showOptionsInsurances() {
    return this._showOptionsInsurances.getValue();
  }

  set showOptionsInsurances(showOptions: boolean) {
    this._showOptionsInsurances.next(showOptions);
  }
  async getTypeCurrencyExchange() {
    if (!this.currencyExchangeResponse) {
      const request = new ConsultCurrencyExchangeRequest({
        Fecha: Utils.formatDate().gfiReplaceAll(Char.Slash, Char.MiddleDash),
        IdSession: this.storageService.getSession()
      });
        this.currencyExchangeResponse = await this.baseService.genericPost<ConsultCurrencyExchangeResponse>(request, { isSOA: true });
    }
    return this.currencyExchangeResponse;
  }

  async monthlyPromotions() {
    if (!this.monthlyPromotionsResponse) {
      const request = new MonthlyPromotionsRequest({
        idSession: this.storageService.getSession(),
        medioAcceso: PolicyConstants.POLICY_ACCESS_METHOD,
        codigoEmisor: this.insurancePolicyInfoStatusService.insurancePolicy.Emisor,
        numeroPoliza: this.insurancePolicyInfoStatusService.insurancePolicy.Poliza,
        ramo:this.insurancePolicyInfoStatusService.insurancePolicy.Ramo?.toUpperCase(),
        producto: this.insurancePolicyInfoStatusService.insurancePolicy.Producto
      });
      this.monthlyPromotionsResponse = await this.baseService.genericPost<IMonthPromotionsInsuranceResponse>(request, { isSOA: true }); 
    }
    return this.monthlyPromotionsResponse;
  }

  async payPoliciesIFM(selectedAccount?: ICardAccount, serviceAmount?: IAmount, infoInsurance?:
    Array<PolicyReceiptsModel>,currency?:string,
    otp?:string){
    const amount = serviceAmount.amount.replace(Regex.DifferentFromNumbersDecimals, Strings.EMPTY);
    const ambSeg = infoInsurance[Position.Zero].ambSeg;
    const policy = new Array<PolicyReceiptsSettleModel>();
    infoInsurance.forEach(element => {
      policy.push({
        IdGestorCobro: null,
        IdRecibo: element.id,
        CodigoEmisor: element.issuerCode,
        NumeroAnio: element.yearNumber,
        NumeroRecibo: element.receipt,
        CodigoMoneda: element.currencyCode,
        ImporteRecibo: (currency ? Number(element.total) * Number(this.insurancePolicies.currencyValue) : element.total)?.toString(),
        TipoRecibo: element.typeReceipt
      });
    });
    const typePolicy = this.insurancePolicyInfoStatusService.insurancePolicy.Ramo;
    const request = new PayPoliciesIFMRequest({
      InputData: {
        IdSession: this.storageService.getSession(),
        AmbSeg: typePolicy === PolicyConstants.POLICIES.TypeDamage ? PolicyConstants.POLICIES.TypeGMM: ambSeg,
        ImporteDocumento: currency ? this.insurancePolicies.currencyMXN : amount,
        TipoTDC:selectedAccount.cardType,
        TipoCambio: currency ? currency : null,
        CuentaCargo: null,
        CuentaProductoID: null,
        NumeroTarjeta:selectedAccount.cardNumber,
        NumeroMeses:this.insurancePoliciesService.monthSelected ? this.insurancePoliciesService.monthSelected : Numbers.Zero.toString(),
        pTarjerenofecha:selectedAccount.expirationDate,
        ReciboLiquidarList: policy
      },
      CustInfo: {
        CustID: this.storageService.getUser().personId.toString(),
        Branch: null,
        Language: null,
        Organization: null
      },
      usaSesion: null,
      IPServer: this.sentinelService.sentinel.ip,
      MedioAcceso: Constants.ACCESS_METHOD_PORTAL,
      Token : {
        OTP:otp,
        HAC: null
      }
    });
    return this.baseService.genericPost<IPayPoliciesIFMResponse>(request,{isSOA: true});
  }

  cleanData(){
    this.monthlyPromotionsResponse = null;
    this.currencyExchangeResponse = null;
  }
}
