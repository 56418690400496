import { AfterContentInit, Component, HostListener, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Resources } from 'src/core/constants/Resources';
import { Numbers } from 'src/core/constants/Numbers';
import { ClassHTML, HTMLConstants } from 'src/core/constants/HTMLConstants';

@Component({
  selector: 'app-modal-leave-page-app',
  templateUrl: './modal-leave-page-app.component.html',
  styleUrls: ['./modal-leave-page-app.component.css']
})
export class ModalLeavePageAppComponent implements AfterContentInit {
  @Input() href: string = null;
  modalInstance: NgbModalRef;
  exclamationMark: string = Resources.CIRCLE_BLUE_QUESTION;
  @Input() clickFn?: () => void;

  constructor() {}

  ngAfterContentInit(): void {
      this.showModal();
  }

  close() {
    this.modalInstance?.close();
  }

  @HostListener('window:popstate')
  onBack() {
    this.modalInstance?.close();
  }

  showModal (hideModal: boolean = true){
    const openModals = document.getElementsByTagName(HTMLConstants.MODAL_OPTIONS.NgbModalWindow);
    if(openModals.length <= Numbers.One && hideModal === true) return;
    Array.from(openModals).forEach((el, i) => {
      this.addOrRemoveClass(hideModal, el, i);
    });
    const backdrops = document.getElementsByTagName(HTMLConstants.MODAL_OPTIONS.NgbModalBackdrop);
    Array.from(backdrops).forEach((el, i) => {
     this.addOrRemoveClass(hideModal, el, i);
    });
  }

  addOrRemoveClass(hideModal: boolean, element: Element, index: number) {
    if(index === Numbers.Zero){
      hideModal?element.classList.add(ClassHTML.DisplayNone):element.classList.remove(ClassHTML.DisplayNone);
    }
  }

}
