import { formatDate } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Resources } from 'src/core/constants/Resources';
import { CheckBookActivationService } from 'src/app/services/check-book-activation.service';
import { DataCheckbookService } from 'src/app/services/data-checkbook.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Constants, FormatDate } from 'src/core/constants/Constants';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ClassHTML, StringsChecks } from 'src/core/constants/ChecksStrings';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { CheckBoxService } from 'src/app/services/checks-value-service';
import { ActivationCheckBooksRequest } from 'src/app/interface/dto/ActivationCheckBooksRequest';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { AlertService } from 'src/app/services/alert.service';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';
import { ModalService } from 'src/app/shared/modal.service';

@Component({
  selector: 'app-modal-checks-activation',
  templateUrl: './modal-checks-activation.component.html',
  styleUrls: ['./modal-checks-activation.component.css']
})
export class ModalChecksActivationComponent implements OnInit {
  removeOperation: string = Resources.CLOSE_CIRCLE_BLUE;
  checkFill: string = Resources.GREEN_CHECK_FILL;
  completeDate!: string;
  vm$ = this.responsiveService.observe;
  idCheckBook: number;
  activationCheckBooklbl: string = StringsChecks.activationCheckBook;
  ready: string = Strings.BUTTONS.Ready;
  operationInformation: string = StringsChecks.operationInformation;
  referenceNumber: string = Strings.EMPTY;
  reference: string = StringsChecks.reference;
  other: string = Strings.BUTTONS.OtherOperation;
  modalData: string;
  showModalChecksActivation: boolean = false;
  captureImage: { name: string, element: HTMLElement };
  imageName: string = Strings.EMPTY;
  checkDataActivation: string;
  modalInstance: NgbModalRef;

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly activationCheckBook: CheckBookActivationService,
    private readonly dataCheckBookService: DataCheckbookService,
    private readonly activeModal: NgbActiveModal,
    private readonly checkBoxService: CheckBoxService,
    private readonly checkBookService: CheckBookActivationService,
    private readonly accountInfoService: AccountInfoStatusService,
    private readonly checkActivationService: CheckBookActivationService,
    private readonly alertService: AlertService
  ) {
    this.idCheckBook = Number(this.activationCheckBook.selectedOperation.id);
  }

  async ngOnInit() {
    await this.activateCheckBooks();
    const date = new Date();
    this.completeDate = formatDate(date, FormatDate.DayMonthYearComplete, Constants.LOCALE_ES_MX);
    this.captureImage = {
      name: this.completeDate,
      element: document.getElementById(ClassHTML.CheckbookActivationSuccessful)
    };

    this.checkDataActivation = this.checkActivationService.selectedOperation.numberCheckBook;
  }

  async activateCheckBooks() {
    try {
      const request = new ActivationCheckBooksRequest({
        idChequera: this.idCheckBook,
        numeroCuenta: this.accountInfoService.account.numeroCuenta,
        otp: this.modalData
      });
      const response = await this.checkBookService.activationCheckBooks(request);
      this.referenceNumber = response.datos;
      this.dataCheckBookService.statusConfirm = false;
      this.showModalChecksActivation = true;
      this.checkBookService.activationOperationScheduled(this.idCheckBook);
    } catch (error) {
      this.returnChecks();
      this.showModalError(error);
    }
  }

  returnChecks() {
    this.checkBoxService.checkBoxResetValue = true;
    this.activeModal.dismiss(ModalChecksActivationComponent);
    this.activationCheckBook.statusActiveData = false;
    window.location.reload();
  }

  showModalError(error) {
    this.modalService.close();
    const errorDetail = Utils.getErrorMsg(error);
    this.alertService.showPopupAlert({
      message: Utils.SERVICE_ERROR_MSG(errorDetail.msg, errorDetail.code),
      header: Strings.MSG_POPUP_TITLE,
      btnLabel: Strings.MSG_POPUP_ACCEPT,
      imgHead: Resources.CLOSE_ICON_RED,
      btnExit: false
    }, {
      onSuccess: () => this.modalService.close()
    });
  }

  async closedModal(ref: NgbModalRef) {
    const container = document.getElementById(ClassHTML.CheckbookActivationSuccessful);
    NavigationUtils.navigateAndEnsureSingleModalDisplay(container, ref, true);
  }

  @HostListener('window:popstate')
  closeModalAction() {
    this.modalInstance?.close();
  }
}
