import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Strings } from 'src/core/constants/Strings';
import { Constants } from 'src/core/constants/Constants';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { Alert } from 'src/app/interface/Alert';
import { ModalOptions } from 'src/app/interface/modal-options';
import { IAlertInfo } from 'src/app/models/AlertInfo';
import { ModalAlertsComponent } from 'src/app/shared/components/modal-alerts/modal-alerts.component';
import { ModalService } from 'src/app/shared/modal.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private readonly _isClose: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _alert: BehaviorSubject<Alert> = new BehaviorSubject<Alert>({
    class: Strings.EMPTY,
    iconHeader: Strings.EMPTY,
    type: Strings.EMPTY,
    message: Strings.EMPTY,
    icon: Strings.EMPTY
  });
  private _alertMessage: string = null;

  constructor(private readonly modalService: ModalService) { }

  get alert$() {
    return this._alert.asObservable();
  }

  get alert() {
    return this._alert.getValue();
  }

  set alert(data: Alert) {
    this._alert.next(data);
  }

  get isClose$() {
    return this._isClose.asObservable();
  }

  get isClose() {
    return this._isClose.getValue();
  }

  set isClose(data: boolean) {
    this._isClose.next(data);
  }

  get alertMessage() {
    return this._alertMessage;
  }

  set alertMessage(data: string) {
    this._alertMessage = data;
  }

  showAlert(alert: Alert) {
    this.isClose = !alert?.message?.length;
    this.alert = alert;
  }

  showPopupAlert(info: IAlertInfo, alertResult?: AlertResult, extraModalStyles = Strings.EMPTY, sizeSmall: boolean = false): NgbModalRef {
    const options: ModalOptions = {
      windowClass: extraModalStyles,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAlertClass,
      size: sizeSmall ? ModalConstants.MODAL_OPTIONS.SmallSizeAlert : Constants.MODAL_OPTIONS.SizeMd,
      centered:true
    };

    const ref = this.modalService.open(ModalAlertsComponent, options);

    ref.componentInstance.alertInfo = info;

    if (alertResult?.onSuccess) {
      ref.componentInstance.hasAction = true;
    }

    if (alertResult?.onCancel) {
      ref.componentInstance.hasCancel = true;
    }

    ref.componentInstance.onCancel = () => {
      alertResult?.onCancel?.();
      ref.close();
    };

    ref.componentInstance.onSuccess = () => {
      alertResult?.onSuccess?.();
      ref.close();
    };

    if (alertResult?.onClose) {
      ref.componentInstance.onClose = () => {
        alertResult.onClose();
        ref.close();
      };
    }

    return ref;
  }
}

interface AlertResult {
  onCancel?(): void;
  onSuccess?(): void;
  onClose?(): void
}
