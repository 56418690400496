import { SessionManagerService } from 'src/app/services/session-manager.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Renderer2
} from '@angular/core';
import { combineLatestWith, filter, map, tap } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NotifyRouteService } from 'src/app/services/notify-route.service';
import { BreakpointState, ResponsiveService } from 'src/app/shared/responsive.service';
import { AlertService } from 'src/app/services/alert.service';
import { Char, Constants, Numbers } from 'src/core/constants/Constants';
import { StorageService } from 'src/app/services/storage.service';
import { AppUtils } from 'src/core/utils/AppUtils';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalTechnicalRequirements } from 'src/app/component/modal-technical-requirements/modal-technical-requirements.component';
import { Resources } from 'src/core/constants/Resources';
import { PATH } from 'src/core/constants/Path';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { SentinelService } from 'src/app/services/sentinel.service';
import { FileUtils } from 'src/core/utils/FileUtils';
import { StateProfileService } from 'src/app/services/save-profile.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NavigationConstants } from 'src/core/constants/NavigationConstants';
import { Strings } from 'src/core/constants/Strings';
import { ClassHTMLHeader } from 'src/core/constants/HTMLConstants';
import { ConfigurationApp } from 'src/app/services/servicesApp/configuration-app.service';
import { SidebarResponsiveService } from 'src/app/services/sidebar-responsive.service';
import { SearchMovementsService } from 'src/app/services/search-movements.service';
import { StylesConstants } from 'src/core/constants/StylesConstants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  vm$ = this.responsiveService.observe.pipe(
    tap((breakpoints) => {
      this.isMobile = breakpoints.mobile;
    }),
    combineLatestWith(
      this.notifyRouteService.switchRouteObservable,
      this.alertService.isClose$,
      this.stateProfileService.profile$),
    map(([breakpoints, switchRoute, alertState, profile]) => ({
      breakpoints,
      switchRoute: switchRoute?.value,
      alertState,
      profile
    }))
  );

  pageError = false;
  parentMessage = Numbers.One;
  isApp = false;
  isXamarin = false;
  links = Constants.EXTERNAL_LINKS;

  mobile02 = Resources.MOBILE_WHITE;
  phoneVoice = Resources.PHONE_WHITE;
  lock = Resources.LOCK_WHITE;
  monitor = Resources.WHITE_MONITOR;
  icoWarning = Resources.WARNING_ICON;
  warningBrowser = Resources.BROWSER_WARNING;
  locationIcon: string = Resources.LOCATION_WHITE;
  showIdClient : boolean = true;
  classHeight: string = ClassHTMLHeader.HeaderHeight;
  showSearch: boolean = false;
  showModal: boolean = true;
  isMobile: boolean = false;
  touchStartX: number = Numbers.Zero;
  touchEndX: number = Numbers.Zero;
  containerTouch: HTMLElement;

  constructor(
    private readonly _router: Router,
    private readonly notifyRouteService: NotifyRouteService,
    private readonly sessionManager: SessionManagerService,
    private readonly responsiveService: ResponsiveService,
    private readonly alertService: AlertService,
    private readonly storageService: StorageService,
    private readonly modalService: ModalService,
    private readonly httpClient: HttpClient,
    private readonly sentinelService: SentinelService,
    private readonly stateProfileService: StateProfileService,
    private readonly configurationApp: ConfigurationApp,
    private readonly loaderService: LoaderService,
    private readonly sidebarResponsiveService: SidebarResponsiveService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly searchMovementsService: SearchMovementsService,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef
  ) {
    this.sessionManager.onloadHandler();
  }

  ngAfterViewInit() {
    this.containerTouch = this.elementRef.nativeElement.querySelector(StylesConstants.CONTAINER_TOUCH);
    if (this.containerTouch) {
      this.addEventListeners();
    }
  }

  ngOnDestroy() {
    if (this.containerTouch) {
      this.removeEventListeners();
    }
  }

  ngOnInit() {
    this._router.events
      .pipe(tap((event)=> {
          this.validateLazyLoading(event);
      }))
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const actualRoute = event.url;
        this.notifyRouteService.switchRouteData = {
          value: actualRoute.includes(PATH.Login) || actualRoute === Char.Slash,
        };
        this.showSearch = this.isMobile ? false : this.showSearch;
      });
    this.contractValidation();
    this.sentinel();
    setTimeout(() => {
      this.isApp = AppUtils.platform.isApp;
      this.isXamarin = AppUtils.platform.isXamarin;
    });
  }

  toggleSearch(event: boolean){
    this.showSearch = event;
    this.searchMovementsService.setShowButton(event);
    this.changeDetectorRef.detectChanges();
  }

  validateLazyLoading(event) {
    let url = Strings.EMPTY;
    if (event instanceof NavigationStart) {
      url = event.url;
      if (url && NavigationConstants.LAZY_PATHS.some(path => path === url)) {
        this.loaderService.showLoader();
      }
    }
    if (event instanceof NavigationEnd) {
      url = event.url;
      if (url && NavigationConstants.LAZY_PATHS.some(path => path === url)) {
        this.loaderService.hideLoader();
      }
    }
  }

  sentinel() {
    this.httpClient
      .get('/favicon.ico', { observe: 'response', responseType: 'text'})
      .subscribe((response: HttpResponse<unknown>) => {
        this.sentinelService.sentinel = {
          ip: response.headers.get('x-forwarded-for') ?? Constants.DEFAULT_IP
        };
      });
  }

  @HostListener('window:pagehide')
  unloadHandler() {
    this.sessionManager.unloadHandler();
  }

  async onShowRequirements(){
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeSm,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.TechnicalRequirements,
    };
    this.modalService.open(ModalTechnicalRequirements, modalOptions);
  }

  get userId(){
    return this.storageService.getUser()?.personId;
  }

  get userSession(){
    return this.storageService.getSession();
  }

  showGeolocationManual(breakpoints: BreakpointState) {
    window.open(FileUtils.showGeolocationManual(breakpoints));
  }

  contractValidation(){
    this._router.events
    .pipe(filter((event) => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      const actualRoute = event.url;
      this.showIdClient = !actualRoute.includes(PATH.InsuranceExpress);
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleTab(event: KeyboardEvent) {
    if(event.key !== 'Tab') return;

    const activeElement = document.activeElement;
    const modal = document.querySelector('ngb-modal-window');
    if(this.modalService.disableTabNavigation && !modal?.contains(activeElement)){
      event.preventDefault();
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showModal = false;
  }

  onShowModalChanged(value: boolean){
    this.showModal = value;
  }

  get isExternalApp(){
    return this.configurationApp?.configurationApp.isExternal;
  }

  onTouchStart(event: TouchEvent) {
    if (event.changedTouches && event.changedTouches.length > Numbers.Zero) {
      this.touchStartX = event?.changedTouches[Numbers.Zero]?.screenX;
    }
  }

  onTouchEnd(event: TouchEvent) {
    if (!this.isSidebar(event)) return;
    if (event.changedTouches && event.changedTouches.length > Numbers.Zero) {
      this.touchEndX = event.changedTouches[Numbers.Zero].screenX;
      if (this.touchStartX !== this.touchEndX) {
        this.sidebarResponsiveService.stateData = false;
        this.renderer.removeStyle(document.body, StylesConstants.OVERFLOW);
      }
    }
  }

  isSidebar(event: Event){
    const idIconSidebar = document.querySelector(StylesConstants.ID_ICON_SIDEBAR);
    const idComponentSidebar = document.querySelector(StylesConstants.ID_COMPONENT_SIDEBAR);
    const target = event.target as HTMLElement;
    if(idComponentSidebar &&  !idComponentSidebar?.contains(target) && idIconSidebar && !idIconSidebar?.contains(target)){
      return true;
    }
    return false;
  }

  addEventListeners() {
    this.containerTouch.addEventListener(StylesConstants.EVENT_TOUCH_START, this.onTouchStart.bind(this), { passive: true });
    this.containerTouch.addEventListener(StylesConstants.EVENT_TOUCH_END, this.onTouchEnd.bind(this), { passive: true });
  }
  removeEventListeners() {
    this.containerTouch.removeEventListener(StylesConstants.EVENT_TOUCH_START, this.onTouchStart.bind(this));
    this.containerTouch.removeEventListener(StylesConstants.EVENT_TOUCH_END, this.onTouchEnd.bind(this));
  }

  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: Event){
    if(this.isSidebar(event)){
      this.sidebarResponsiveService.stateData = false;
      this.renderer.removeStyle(document.body, StylesConstants.OVERFLOW);
    }
  }
}
