import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { AccountCardService } from 'src/app/services/account-card.service';
import { Constants, Numbers, Regex } from 'src/core/constants/Constants';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { combineLatestWith, map } from 'rxjs';
import {Resources} from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { DirectDebitServicesService } from 'src/app/services/services-direct-debit.service';
import { ToggleAccountBalanceService } from 'src/app/services/toggle-account-balance.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-card-account',
  templateUrl: './card-account.component.html',
  styleUrls: ['./card-account.component.css']
})
export class CardAccountComponent implements OnInit {
  @ViewChild('cardContainer') cardContainer: ElementRef;
  isPatrimonial: boolean = false;
  Resources = Resources;

  @Input('card') card!:
    | ICardAccount
    | {
        image: string;
        cardNumber: string;
        clabe: string;
        balance: string;
        selected: boolean;
        cardType: string;
      };
  @Output() cardInformation = new EventEmitter<ICardAccount>();
  @Input() boxShadow: boolean = false;
  @Input() styleCard: number = Numbers.Zero;
  @Input() accountType!: string;
  @Input() classCard?:string=Strings.EMPTY;
  @Input() useCdnPipe: boolean = false;
  @Input() hideKey?: boolean = false;
  selected: boolean = false;
  @Input() wInvestment: boolean = false;
  @Input() style: string = Strings.EMPTY;
  @Input() isModal: boolean = true;
  @Input() maskClabe: boolean = false;
  isDirectDebit: boolean = this.directDebitServicesService.isDirectDebit;
  showAccountBalance: boolean = environment.hiddenComponents.visibilityAccountBalanceModal;

  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(this.accountCardService.accountCard$,
      this.toggleAccountBalanceService.showAccountBalance$),
    map(([breakpoints, card, showAccountBalance]) => {
      this.selected = card.selected;
      return { breakpoints, showAccountBalance };
    })
  );

  constructor(
    private readonly accountCardService: AccountCardService,
    private readonly responsiveService: ResponsiveService,
    private readonly directDebitServicesService: DirectDebitServicesService,
    private readonly toggleAccountBalanceService: ToggleAccountBalanceService
  ) {}

  ngOnInit(): void {
    this.card.clabe= this.card.clabe.replace(Regex.Space, Strings.EMPTY);
    this.isPatrimonial = this.accountType === Constants.PATRIMONIAL;
  }

  selectedCardButton(card: ICardAccount) {
    this.cardInformation.emit(card);
    this.selected = true;
  }
}
