<ng-container *ngIf="vm$ | async as vm">
  <div>
    <div #Header tabindex="0">
      <div [ngClass]="!vm.breakpoints.mobile ? 'body-m' : 'body-s'" class="subtitle-header mb-4">
        Selecciona un destinatario
      </div>
      <div class="d-flex flex-row justify-content-between">
        <div *ngIf="cards$ | async as listOfRecipients" class="message-add mb-2"
          [routerLink]="newAddresseePath" (click)="closeModal()" (keydown.enter)="$event.preventDefault()">
          <ng-container *ngIf="selectedTabIndex != digit.One">
            <div class="mb-3 d-flex">
              <img height="24" width="24" class="cursor-pointer" [src]="shapePlusImage | cdnImage" alt="Plus icon" />
              <span class="ps-3 detail-m-highlight cursor-pointer custom-padding
              text-decoration">Agregar nuevo
                <br *ngIf="vm.breakpoints.mobile && vm.isFacepassLogin"> destinatario</span>
            </div>
          </ng-container>
        </div>
        <div class="d-flex flex-column align-items-center gap-1 transfer-express" [class.pr-express]="vm.sidebar"
        [class.pb-3]="vm.breakpoints.mobile">
          <ng-container *ngIf="vm.isFacepassLogin">
            <div class="d-flex align-items-start align-content-strech justify-conent-end gap-2 custom-gap"
            (click)="isTransferExpress()" (keydown.enter)="$event.preventDefault()">
              <span [ngClass]="vm.breakpoints.mobile ? 'detail-m-highlight-hypertext underline text-end' :
                'detail-m-highlight'" class="cursor-pointer text-decoration">Traspaso express</span>
              <img [src]="chevronRight | cdnImage" alt="chevron right" width="auto" height="auto">
            </div>
            <span class="detail-s text-express text-end m-0 text-break">
              Sin registrar al destinatario<br> hasta $8,000 pesos</span>
          </ng-container>
        </div>
      </div>
      <div [ngClass]="{'drop-shadow-m shadow-border-addressee p-3':vm.breakpoints.mobile && !hiddenCards}">
        <div [class.mb-3]="vm.breakpoints.desktop"
          class="flex-column justify-content-center gap-4" [ngClass]="hiddenCards ? 'd-none' : 'd-flex'">
            <div [class.padding-inline]="vm.breakpoints.tablet" [class.ps-0]="vm.breakpoints.tablet"
              class="input-group pt-1 search">
              <input
                [disabled]="isLoading$ | async"
                #searchInpt id="addresseeSearch"
                type="text" class="form-control" placeholder="Buscar"
                (input)="setSearch(searchInpt.value)"
                cleanInput [cleanRegex]="regex" />
                <app-reset-input-value
                  [targetInput]="searchInpt"
                  (clickEmmiter)="resetData()"
                  styleDelete="input-group-text-white" [styleReset]="'input-group-text-white'">
                </app-reset-input-value>
              <span class="input-group-text input-group-text-white" id="basic-addon1">
                <img [src]="(vm.breakpoints.mobile ? searchImageMobile : searchImage )
                  | cdnImage" alt="Search" height="24" width="24" />
              </span>
            </div>
          <div class="position-relative w-100" *ngIf="vm.breakpoints.mobile">
            <div ngbDropdown #Dropdown="ngbDropdown" id="scroll" (openChange)="onDropdownOpen($event)">
              <div id="DropdownMenu" ngbDropdownToggle class="custom-select">
                <div class="form-select cursor-pointer option-border inputs body-s d-flex align-items-center"
                  tabIndex="0">
                  <span class="m-0 cut-text" [ngbTooltip]="tabs[selectedTabIndex]">
                    {{ tabs[selectedTabIndex] }}
                  </span>
                </div>
              </div>
              <div class="container-dropdown">
                <div ngbDropdownMenu class="dropdown-item-container w-100 hide-scroll">
                  <ng-container *ngFor="let tab of tabs; let i = index">
                    <div ngbDropdownItem
                      class="cursor-pointer dropdown-item m-0 option-bank"
                      (click)="selectTab(i, true); cleanSearch()" *ngIf="tabsToShow[i]"
                      (keypress)="selectTab(i, true); cleanSearch()"> {{ tab }} </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <label for="floating-select" class="detail-s-highlight position-absolute select-text"
              [class.px-2]="!vm.breakpoints.mobile">
              Tipo de destinatario</label>
          </div>
          <div *ngIf="!vm.breakpoints.mobile" id="scroll">
            <ul class="nav">
              <li class="nav-item-addressee" *ngFor="let tab of tabs; let i = index">
                <ng-container *ngIf="tabsToShow[i]">
                  <button [disabled]="isDisabled" [ngClass]="selectedTabIndex == i || isDisabled? 'active' : ''"
                  role="button" class="body-s" [id]="i" [value]="i"
                    (click)="selectTab(i, true)" (keydown.enter)="$event.preventDefault()">
                    {{ tab }}
                  </button>
                </ng-container>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="cards$ | async as listOfRecipients" class="mt-3">
      <div class="message">
        <label *ngIf="listOfRecipients.legendArray[selectedTabIndex] && !hiddenCards"
          class="error-message">
          {{ listOfRecipients.legendArray[selectedTabIndex] }}
        </label>
      </div>
      <div class="message">
        <label *ngIf="listOfRecipients.legendArray[selectedTabIndex] && listOfRecipients.errorDischarge"
          class="error-message">
          {{ listOfRecipients.errorDischarge }}
        </label>
      </div>
      <div class="content" [hidden]="hiddenCards"
        *ngIf="cards$ | async as listOfRecipients">
        <ng-container *ngFor="let tipo of getUniqueTypes(listOfRecipients.page)">
          <span class="header-m item-title-cb" *ngIf="tipo == digit.Six || tipo == digit.Four">
            {{tipo == digit.Four ? 'Mis contratos' : 'Terceros Inbursa'}}
          </span>
          <ng-container *ngFor="
              let destinatario of listOfRecipients.page;
              let i = index
            ">
            <ng-container *ngIf="tipo == destinatario.type">
              <app-card-addressee (click)="selectedAddressee(i)"
                [selected]="i == addresseeId" [addressee]="destinatario" [id]="i + digit.One"
                (informationAddressee)="detailsRecipient(destinatario)"
                (navigateCard)="selectedAddressee(i); selectedAddresseeCard()"
                (keydown.enter)="$event.preventDefault()"
                [showAddresseeLabel]="true"></app-card-addressee>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="listOfRecipients.page.length === digit.Zero
        && !listOfRecipients.legendArray[selectedTabIndex]
        && !(isLoading$ | async) && !isSelectedTab && filter
      ">
        <div class="header-m d-flex text-center justify-content-center mb-5 px-4"
          [innerHTML]="withoutResultsMessage"></div>
      </ng-container>
      <div *ngIf="cards$ | async as listOfRecipients">
        <div *ngIf="listOfRecipients.legendArray[selectedTabIndex] &&
          !listOfRecipients.errorServiceArray[selectedTabIndex] && !hiddenCards">
          <label class="py-3 w-100 padding-inline header-s text-center">
            Da de alta tu primer agenda
          </label>
        </div>
      </div>
      <div *ngIf="!listOfRecipients.legendArray[selectedTabIndex]" class="px-5" [hidden]="hiddenCards" #Footer>
        <div *ngIf="listOfRecipients?.page?.length !== digit.Zero">
          <app-pagination [startElement]="Header"></app-pagination>
        </div>
        <div *ngIf="false" class="d-flex align-items-center pt-2 mb-4 justify-content-center"
          [ngClass]="listOfRecipients.page.length === digit.Zero ? 'mt-4' : ''">
          <button class="colored-btn button-m btn-generic" (click)="selectedAddresseeCard()"
          (keydown.enter)="$event.preventDefault()">
            Seleccionar
            <img [src]="chevronRightImage | cdnImage" alt="Arrow" height="24" width="24" />
          </button>
        </div>
      </div>
      <ng-container *ngIf="false">
        <div *ngIf="listOfRecipients.legendArray[selectedTabIndex]"
          class="d-flex justify-content-center mb-4 align-items-center pt-3 px-5">
          <button class="colored-btn button-m btn-generic" [routerLink]="newAddresseePath" (click)="closeModal()"
          (keydown.enter)="$event.preventDefault()">
            Alta de agenda
            <img [src]="chevronRightImage | cdnImage" alt="Arrow" height="24" width="24" />
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
