import { Injectable, inject } from '@angular/core';
import { InputConfig } from '../interface/IInputConfig';
import { InputModes, InputTypes } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { AforeStrings } from 'src/core/constants/AforeStrings';
import { DynamicFormService } from './dynamic-form.service';
import { BehaviorSubject } from 'rxjs';
import { Validators } from '@angular/forms';
import { FormUtils } from 'src/core/utils/FormUtils';
import { FormValidations } from 'src/core/constants/FormConstants';
import { Numbers } from 'src/core/constants/Numbers';
import { AforeConstants } from 'src/core/constants/AforeConstants';

export interface VoluntarySavingsContributionsData {
  type: string;
  deductible: string;
  referenceNumber?: number | null;
  reference?: number | null;
  charge: string,
  isPublicCharge: boolean;
  familyOnPublicCharge: boolean;
  publicChargeData?: {
    charge: string,
    dateThatLeaveCharge?: string | null;
  }
  familyOnPublicChargeData?: {
    charge: string,
    dateThatLeaveCharge?: string | null;
    relationship?: string | null;
  }
  aforeAssociationId?: number | null;
  aforeAccountNumber?: string;
  aforeContributionId?: number | null;
  applicationNumber?: number | null;
  deductibleChar?: string;
  messageDeductibility?: string;
}

@Injectable({ providedIn: 'root' })
export class VoluntarySavingsContributionsService {

  private readonly _maxAmountLength = Numbers.Fifteen;

  private readonly dynamicFormService: DynamicFormService = inject(DynamicFormService);

  private readonly referencesInputConfig: InputConfig[] = [
    {
      type: InputTypes.Text,
      label: AforeStrings.REFERENCE_NUMBER,
      name: AforeStrings.FORM.ReferenceNumber,
      placeholder: AforeStrings.VOLUNTARY_SAVINGS_CONTRIBUTIONS.ReferenceDefaultPlaceholder,
      validations: [
        {
          name: FormValidations.Required,
          validator: Validators.required,
          message: Strings.GENERIC_ERRORS.Required
        },
        {
          name: FormValidations.MaxLength,
          validator: Validators.maxLength(Numbers.Nine),
          message: Strings.GENERIC_ERRORS.MaxLengthError(Numbers.Nine)
        }
      ],
      maxlength: Numbers.Nine,
      inputMode: InputModes.Numeric,
      input: FormUtils.onlyNumbersInput
    },
    {
      type: InputTypes.Text,
      label: AforeStrings.REFERENCE_TEXT,
      name: AforeStrings.FORM.Reference,
      placeholder: AforeStrings.VOLUNTARY_SAVINGS_CONTRIBUTIONS.ReferenceDefaultPlaceholder,
      validations: [
        {
          name: FormValidations.Required,
          validator: Validators.required,
          message: Strings.GENERIC_ERRORS.Required
        },
        {
          name: FormValidations.MaxLength,
          validator: Validators.maxLength(Numbers.Forty),
          message: Strings.GENERIC_ERRORS.MaxLengthError(Numbers.Forty)
        }
      ],
      maxlength: Numbers.Forty,
      input: FormUtils.alphaNumericOneSpaceInput
    }
  ];

  private readonly referencesForm = this.dynamicFormService.toFormGroup(this.referencesInputConfig);

  private readonly publicChargeInputConfig: InputConfig[] = [
    {
      type: InputTypes.Text,
      label: AforeStrings.VOLUNTARY_SAVINGS_CONTRIBUTIONS.WhatCharge,
      name: AforeStrings.FORM.Charge,
      placeholder: AforeStrings.VOLUNTARY_SAVINGS_CONTRIBUTIONS.Placeholder,
      validations: [
        {
          name: FormValidations.Required,
          validator: Validators.required,
          message: Strings.GENERIC_ERRORS.Required
        },
        {
          name: FormValidations.MaxLength,
          validator: Validators.maxLength(Numbers.TwentyFive),
          message: Strings.GENERIC_ERRORS.MaxLengthError(Numbers.TwentyFive)
        }
      ],
      maxlength: Numbers.TwentyFive,
      input: FormUtils.onlyTextInput
    }
  ];

  private readonly publicChargeForm = this.dynamicFormService.toFormGroup(this.publicChargeInputConfig);

  private readonly familyOnPublicChargeInputConfig: InputConfig[] = [
    {
      type: InputTypes.Text,
      label: AforeStrings.VOLUNTARY_SAVINGS_CONTRIBUTIONS.WhatCharge,
      name: AforeStrings.FORM.Charge,
      placeholder: AforeStrings.VOLUNTARY_SAVINGS_CONTRIBUTIONS.Placeholder,
      validations: [
        {
          name: FormValidations.Required,
          validator: Validators.required,
          message: Strings.GENERIC_ERRORS.Required
        },
        {
          name: FormValidations.MaxLength,
          validator: Validators.maxLength(Numbers.TwentyFive),
          message: Strings.GENERIC_ERRORS.MaxLengthError(Numbers.TwentyFive)
        }
      ],
      maxlength: Numbers.TwentyFive,
      input: FormUtils.onlyTextInput
    },
    {
      type: InputTypes.Text,
      label: AforeStrings.VOLUNTARY_SAVINGS_CONTRIBUTIONS.Relationship,
      name: AforeStrings.FORM.Relationship,
      placeholder: AforeStrings.VOLUNTARY_SAVINGS_CONTRIBUTIONS.Placeholder,
      validations: [
        {
          name: FormValidations.Required,
          validator: Validators.required,
          message: Strings.GENERIC_ERRORS.Required
        },
        {
          name: FormValidations.MaxLength,
          validator: Validators.maxLength(AforeConstants.TWENTY),
          message: Strings.GENERIC_ERRORS.MaxLengthError(AforeConstants.TWENTY)
        }
      ],
      maxlength: AforeConstants.TWENTY,
      input: FormUtils.onlyTextInput
    }
  ];

  private readonly DEFAULT_DATA: VoluntarySavingsContributionsData = {
    type: Strings.EMPTY,
    deductible: Strings.EMPTY,
    referenceNumber: null,
    reference: null,
    charge: Strings.EMPTY,
    isPublicCharge: false,
    familyOnPublicCharge: false,
    publicChargeData: {
      charge: Strings.EMPTY,
      dateThatLeaveCharge: null
    },
    familyOnPublicChargeData: {
      charge: Strings.EMPTY,
      dateThatLeaveCharge: null,
      relationship: null
    },
    aforeContributionId: null,
    applicationNumber: null,
    deductibleChar: Strings.EMPTY,
    messageDeductibility: Strings.EMPTY
  };

  private readonly _data: BehaviorSubject<VoluntarySavingsContributionsData> = new BehaviorSubject<VoluntarySavingsContributionsData>(this.DEFAULT_DATA);
  private inbursaAforeCustomers: number;

  get inbursaAforeCustomer(){
    return this.inbursaAforeCustomers;
  }

  set inbursaAforeCustomer(data: number) {
    this.inbursaAforeCustomers = data;
  }

  private readonly familyOnPublicChargeForm = this.dynamicFormService.toFormGroup(this.familyOnPublicChargeInputConfig);

  get maxAmountLength() {
    return this._maxAmountLength;
  }

  get referencesConfig() {
    return { form: this.referencesForm, config: this.referencesInputConfig };
  }

  get publicChargeConfig() {
    return { form: this.publicChargeForm, config: this.publicChargeInputConfig };
  }

  get familyOnPublicChargeConfig() {
    return { form: this.familyOnPublicChargeForm, config: this.familyOnPublicChargeInputConfig };
  }

  get data$(){
    return this._data.asObservable();
  }

  get data(){
    return this._data.getValue();
  }

  set data(data: VoluntarySavingsContributionsData) {
    this._data.next(data);
  }

  clearData() {
    this.data = this.DEFAULT_DATA;
  }

  clearDataAnotherOperation() {
    this.data.aforeAssociationId   = null;
    this.data.aforeAccountNumber   = Strings.EMPTY;
  }
}
