import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Strings } from '../../../../core/constants/Strings';
import { Constants } from '../../../../core/constants/Constants';
import { Resources } from '../../../../core/constants/Resources';
import { BreakpointState, ResponsiveService } from 'src/app/shared/responsive.service';
import { combineLatestWith, map } from 'rxjs';
import { LocationConstants } from 'src/core/constants/LocationConstants';

@Component({
  selector: 'app-location-modal',
  templateUrl: './location-modal.component.html',
  styleUrls: ['./location-modal.component.css']
})
export class LocationModalComponent {
  @Input('modal') modal;
  @Output() modalCall = new EventEmitter<string>();
  title: string = Strings.LOCATION_MODAL.Alert;
  subtitle: string = Strings.LOCATION_MODAL.DetailAlert;
  openManual: string = Strings.LOCATION_MODAL.HowDoIDoIt;
  closeButton: string = Strings.BUTTONS.Accept;
  location: string = Resources.LOCATION;

  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(),
    map(([breakpoints]) => ({
      breakpoints
    }))
  );

  constructor(
    private readonly responsiveService: ResponsiveService
  ){}

  ngOnInit(): void {}

  openPdf(data: BreakpointState) {
    const name = data.desktop ? LocationConstants.LOCATION_MODAL.DesktopPDF: data.tablet ? LocationConstants.LOCATION_MODAL.TabletPDF : LocationConstants.LOCATION_MODAL.MobilePDF;
    const url = Resources.CDN_URL(name);
    window.open(url, Constants.ANCHOR_TARGET);
  }

  closeModal() {
    this.modalCall.emit('CerrarModal');
  }

}
