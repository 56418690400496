import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Strings } from 'src/core/constants/Strings';
import { AccountCardService } from 'src/app/services/account-card.service';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { AccountsStatusService } from 'src/app/services/accounts-status.service';
import { AlertService } from 'src/app/services/alert.service';
import { NavigationExtras, Router } from '@angular/router';
import { StringUtils } from 'src/core/utils/stringUtils';
import { AccountModel } from 'src/app/interface/AccountModel';
import { Resources } from 'src/core/constants/Resources';
import { Utils } from 'src/core/utils/utils';
import { PATH } from 'src/core/constants/Path';
import { CodeflexStrings } from 'src/core/constants/CodeflexStrings';
import { Numbers } from 'src/core/constants/Numbers';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { AccountType, Constants, Position } from 'src/core/constants/Constants';
import { ProductsService } from 'src/app/services/products.service';
import { ProductConstants } from 'src/core/constants/ProductConstants';
import { TInvestment } from 'src/app/interface/TInvestment';

@Injectable({
  providedIn: 'root',
})
export class CodeflexLayoutService {
  private readonly _isCodeflex: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _titleOperation: BehaviorSubject<string> = new BehaviorSubject<string>(  Strings.EMPTY );
  private readonly _updateAccounts: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _emptyData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private readonly accountCardService: AccountCardService,
    private readonly accountInfoService: AccountInfoStatusService,
    private readonly accountsStatusService: AccountsStatusService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly productService: ProductsService) {}

  get isCodeflex$(): Observable<boolean> {
    return this._isCodeflex.asObservable();
  }

  get isCodeflex(){
    return this._isCodeflex.getValue();
  }

  set isCodeflex(isCodeflex: boolean) {
    this._isCodeflex.next(isCodeflex);
  }

  get titleOperation$() {
    return this._titleOperation.asObservable();
  }

  set titleOperation(data: string) {
    this._titleOperation.next(data);
  }

  get titleOperation() {
    return this._titleOperation.getValue();
  }

  set updateAccounts(data: boolean) {
    this._updateAccounts.next(data);
  }

  get updateAccounts() {
    return this._updateAccounts.getValue();
  }

  get emptyData$(): Observable<boolean> {
    return this._emptyData.asObservable();
  }

  get emptyData(){
    return this._emptyData.getValue();
  }

  set emptyData(value: boolean) {
    this._emptyData.next(value);
  }

  async shareAccount(navigate: boolean = true){
    try {
      const ejeAccount = this.accountCardService.mainAccountCodeflex;
      if(ejeAccount.numeroCuenta === Strings.EMPTY){
        throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
      }
      const codeflexAccount= this.accountInfoService.account;
      this.accountsStatusService.status = {value: true, isProductSelected: codeflexAccount.tipoCuenta, id: codeflexAccount.idCuentaProducto};
      if (this.titleOperation === CodeflexStrings.CODEFLEX_ACTIONS[Numbers.Zero]) {
        this.accountInfoService.account = ejeAccount;
        this.accountCardService.accountCard = this.mapAccount(codeflexAccount);
      }else{
        this.accountCardService.selectedAccountCard = this.mapAccount(ejeAccount);
        this.accountCardService.selected = true;
      }
      if(navigate){
        const navParams: NavigationExtras = { state: { hasAccountSelected: true } };
        this.router.navigate([PATH.Codeflex], navParams);
      }
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        imgHead: Resources.CLOSE_ICON_RED,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        btnExit: false
      });
    }
  }

  async getEjeAccount(){
    const responseAccount = (await this.productService.getAccounts({reload: false}))
    .filter(item => item.numeroCuenta === this.accountInfoService.account.numeroCuenta);
    let ejeAccount: AccountModel;
    if(this.accountInfoService.account.tipoCuenta !== ProductConstants.CODE_ACCOUNTS.INVBLACK){
      ejeAccount = responseAccount.filter(item => item.category === AccountType.Eje)[Position.Zero];
    }else{
      ejeAccount= responseAccount.filter(item => item.tipoCuenta === ProductConstants.CODE_ACCOUNTS.BLACK)[Position.Zero];
    }
    this.accountCardService.mainAccountCodeflex = ejeAccount?? this.accountCardService.mainAccountCodeflex;
  }

  mapAccount(account: AccountModel){
    return {
      image      : account.directorioImagen,
      cardNumber : account.numeroCuenta,
      clabe      : StringUtils.formatClabe(account.cuentaClabe),
      balance    : account.saldos.disponible.toString(),
      selected   : false,
      cardType   : account.descripcionCuenta,
      productId: account.idCuentaProducto,
      accountNumber: account.numeroCuenta,
      currency: account.currency,
      accountType: account.tipoCuenta
    };
  }

  setSubAccounts(accounts: AccountModel[], accountConcat: TInvestment[]) {
    const subAccount = accounts.filter(value => ProductConstants.SUB_ACCOUNTS.includes(value.tipoCuenta));
    if(subAccount.length > Numbers.Zero){
      subAccount.forEach(element => {
        accountConcat.push({
          directorioImagen: element.directorioImagen,
          numeroCuenta: element.numeroCuenta,
          descripcionCuenta: element.descripcionCuenta,
          saldos: element.saldos,
          type: element.tipoCuenta,
          clabe: element.cuentaClabe,
          idCuentaProducto: element.idCuentaProducto
        });
      });
      accountConcat.sort((a, b) => {
        if (!a.plazo && b.plazo) {
          return Constants.NOT_FOUND_ITEM;
        } else if (a.plazo && !b.plazo) {
          return Numbers.One;
        } else {
          return Numbers.Zero;
        }
      });
    }

    return accountConcat;
  }
}
