import { PathModel } from '../models/PathModel';

interface IStatementsAccountPLRequest{
    Sesion: string;
    idSistema: string;
    cuenta: string;
    tipoConsulta: string;
}

export class StatementsAccountPLRequest {
    constructor(public data: IStatementsAccountPLRequest){}

    get endpoint(): PathModel {
      return PathModel.StatementsAccount;
  }
}
