import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, combineLatestWith, map } from 'rxjs';
import { TLocksCard } from 'src/app/interface/TLocksCard';
import { ICardNumberInfo } from 'src/app/interface/dto/ICardNumberInfo';
import { StateCardNumberInfoService } from 'src/app/services/state-card-number-info.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { CardType, Constants, DebitCardType, Numbers, OperationStatus, Position, Source } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CardDataInfo, ControlCardConstants, OperationType } from 'src/core/constants/ControlCardConstants';
import { ControlCardStrings } from 'src/core/constants/ControlCardStrings';
import { ModalMyCardsComponent } from 'src/app/control-card/components/modal-my-cards/modal-my-cards.component';
import { GetDataCardResponse } from 'src/app/interface/dto/GetDataCardResponse';
import { AlertService } from 'src/app/services/alert.service';
import { CardsAdminService } from 'src/app/services/cards-admin.service';
import { Utils } from 'src/core/utils/utils';
import { Resources } from 'src/core/constants/Resources';
import { ModalMyCardsService } from 'src/app/control-card/components/modal-my-cards/service/modal-my-cards.service';
import { ControlCardService } from 'src/app/services/control-card.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-operation-block',
  templateUrl: './operation-block.component.html',
  styleUrls: ['./operation-block.component.css']
})
export class OperationBlockComponent implements OnInit {
  @ViewChild('IntLock') intLock: ElementRef<HTMLInputElement>;
  @ViewChild('AtmLock') atmLock: ElementRef<HTMLInputElement>;
  @ViewChild('PosLock') posLock: ElementRef<HTMLInputElement>;
  @ViewChild('OpiLock') opiLock: ElementRef<HTMLInputElement>;
  @ViewChild('ModalCards') modalMyCardsComponent: ModalMyCardsComponent;
  additionalCard: boolean = false;
  head: string = Strings.EMPTY;
  titleShopping: string = ControlCardStrings.CONTROL_CARD.ShoppingTitle;
  titleCodi: string = ControlCardStrings.CONTROL_CARD.CODI;
  locksCardInfo: TLocksCard;
  locksCardInfo$: Observable<ICardNumberInfo> = this.stateCardNumberInfoService.cardNumberInfo$;
  sourceInfo$: Observable<ICardNumberInfo> = this.stateCardNumberInfoService.cardNumberInfo$;
  locksOptionList: Array<{ Value: string, Name: string, Type: string }> = [];
  @Output() lockOperation = new EventEmitter<Array<{ Value: string, Name: string, Type: string }>>();
  @Output() lockOperationBlock = new EventEmitter<{look:boolean,messageAlert?:string,textLock?:string}>();
  @Input() dataCard: GetDataCardResponse;
  @Input() isdebit: string = Strings.EMPTY;
  @Input() isUpdateLocks: boolean = false;
  @Input() isAdditional: boolean = false;
  groceryVoucherCard: string = DebitCardType.GroceryVoucherCard;
  walmartGroceryVoucherCard: string = DebitCardType.WalmartGroceryVoucherCard;
  webCard: string = DebitCardType.WebCard;
  typeCard:string;
  creditType:string = Source.Infinitus;
  debitType:string = Source.Mf2;
  debitName: string = CardType.Debit;
  titleMessage:string=Strings.EMPTY;
  view:boolean=false;
  modal:NgbModalRef;
  showCodi: boolean=false;
  hiddenMonthlyLimitDebit = environment.hiddenComponents.monthlyLimitDebit;
  messageInfo:{header: string; message: string; btnLabel: string; btnExit: boolean;} = {
    header: this.titleMessage,
    message: `${ControlCardStrings.MSG_DEACTIVATE} ${this.titleMessage}`,
    btnLabel:  ControlCardStrings.BTN_ACCEPT,
    btnExit:false
  };

  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(this.controlCardService.dataControls$),
    map(([breakpoints, dataControls]) => ({breakpoints,dataControls})));

  constructor(
    private readonly router: Router,
    private readonly stateCardNumberInfoService: StateCardNumberInfoService,
    private readonly responsiveService: ResponsiveService,
    private readonly alertService: AlertService,
    private readonly cardsAdminService: CardsAdminService,
    private readonly modalMyCardsService: ModalMyCardsService,
    private readonly controlCardService: ControlCardService
  ) { }

  ngOnInit(): void {
    this.locksCardInfo$.subscribe(async locksCardData => {
      if (locksCardData.CardDataInfo) {
        this.locksCardInfo = locksCardData.CardDataInfo.CardLocks;
      }
    });
    this.sourceInfo$.subscribe(async data=>{
      if(data.CardDataInfo[CardDataInfo.SourceCard] !== undefined){
        this.typeCard = data.CardDataInfo[CardDataInfo.SourceCard];
      }
    });
    if (this.router.url === PATH.Control) {
      this.additionalCard = true;
      this.head = ControlCardStrings.CONTROL_CARD.HeadUnlocked;
      this.titleShopping = ControlCardStrings.CONTROL_CARD.InternationalPurchases;
    }

    if (this.router.url === PATH.Additional || this.router.url === PATH.CardAdmin) {
      this.head = ControlCardStrings.CONTROL_CARD.HeadUnlockedAdditional;
    }
  }

  updateLocks(value: boolean, name?: string,inputChanged?: Event) {
    const textLock = Constants.UPDATE_LOCK;
    const valueList = value ? OperationStatus.Blocked.toString() : OperationStatus.Unlocked.toString();
    this.validateLockOptions(name,textLock, valueList,inputChanged);
    if(!this.isUpdateLocks) {
      this.lockOperation.emit(this.locksOptionList);
    }
  }

  async validateLockOptions(name: string, textLock: string, valueList: string,inputChanged:Event){
    switch (name) {
      case Constants.UPDATE_ATM_LABEL:
        this.locksOptionList[ControlCardConstants.OPERATION_LOCK.ATM] = { Value: valueList, Name: name, Type: textLock };
        break;
      case Constants.UPDATE_POS_LABEL:
        this.locksOptionList[ControlCardConstants.OPERATION_LOCK.POS] = { Value: valueList, Name: name, Type: textLock };
        break;
      case Constants.UPDATE_OPI_LABEL:
        this.locksOptionList[ControlCardConstants.OPERATION_LOCK.OPI] = { Value: valueList, Name: name, Type: textLock };
        break;
      case Constants.UPDATE_INT_LABEL:
        this.locksOptionList[ControlCardConstants.OPERATION_LOCK.INT] = { Value: valueList, Name: name, Type: textLock };
        break;
      case OperationType.UPDATE_CODI_LABEL:
        this.locksOptionList[ControlCardConstants.OPERATION_LOCK.CODI] = { Value: valueList, Name: name, Type: textLock};
        break;
      case OperationType.UPDATE_LIMIT_MONTHLY:
        this.locksOptionList[ControlCardConstants.OPERATION_LOCK.MONTHLY] = { Value: valueList, Name: name, Type: textLock};
        break;
    }
    if(!this.isUpdateLocks) {
      this.lockOperation.emit(this.locksOptionList);
    } else {
      await this.updateLockLimitsCard(Number(valueList),ControlCardConstants.TYPE_BLOCK_CARDS[Position.Zero][name],inputChanged);
    }
  }

  updateLocksOperations() {
    if (this.dataCard?.Card?.CardType === this.groceryVoucherCard || this.dataCard?.Card?.CardType === this.walmartGroceryVoucherCard) {
     this.resetCheckValues(false, true);
    } else if (this.dataCard?.Card?.CardType === this.webCard) {
      this.resetCheckValues(true);
    } else {
      this.resetCheckValues();
    }
  }

  resetCheckValues(isWeb: boolean = false, isGroceryVoucherWalmar: boolean = false) {
    if(this.intLock?.nativeElement){
      this.intLock.nativeElement.checked = !this.locksCardInfo.Int;
    }
    if(this.opiLock?.nativeElement && !isGroceryVoucherWalmar){
      this.opiLock.nativeElement.checked = !this.locksCardInfo.Opi;
    }
    if(this.atmLock?.nativeElement && !isGroceryVoucherWalmar){
      this.atmLock.nativeElement.checked = !this.locksCardInfo.Atm;
    }
    if(this.posLock?.nativeElement && !isWeb){
      this.posLock.nativeElement.checked = !this.locksCardInfo.Pos;
    }
  }

  async updateLockLimitsCard(valueLock:number,textLock:string,inputChanged: Event) {

    try {
      const response = await this.cardsAdminService.updateLockLimitsCard(
        this.dataCard.Card.Account, this.dataCard.Card.CardNumber, this.locksOptionList,
        this.dataCard.Card.Source);
      if (response.Error.No === Constants.MSG_SERVICE_RESPONSE_SUCCESSFUL) {
        const messageAlert = valueLock !== Numbers.Zero ? `${ControlCardStrings.MSG_DEACTIVATE}`
        :`${ControlCardStrings.MSG_ACTIVATE}`;
        this.lockOperationBlock.emit({look:true,messageAlert,textLock});
      }
    } catch (error) {
      this.modalMyCardsService.hiddenModal(true);
      const errorDetails = Utils.getErrorMsg(error);
      this.alert(errorDetails.msg, true, Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,errorDetails.code);
      const target = inputChanged.target as HTMLInputElement;
      if(target){
        target.checked = valueLock !== Numbers.Zero;
      }
    }
    this.locksOptionList = [];
  }

  alert(msg: string, isError: boolean,headerMessage:string, code?: number) {
     this.alertService.showPopupAlert({
      message: code  ? Utils.SERVICE_ERROR_MSG(msg, code): msg,
      header: headerMessage,
      btnLabel: Strings.MSG_POPUP_ACCEPT,
      imgHead: isError ? Resources.CLOSE_ICON_RED : Strings.EMPTY,
      btnExit: false,
      textCenter:true
    }, {
      onSuccess: () => {this.openModalMyCards();
        this.modalMyCardsService.hiddenModal(false);}
    });
  }

  openModalMyCards(){
    this.alertService.isClose = true;
    this.lockOperationBlock.emit({look:false});
  }
}
