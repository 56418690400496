import { PathModel } from 'src/app/models/PathModel';

interface ICreditDetailsRequest {
  IdSession: string,
  NumeroCredito: string
}

export class CreditDetailsRequest {
  constructor(public data: ICreditDetailsRequest) { }

  get endpoint(): PathModel {
    return PathModel.CreditDetails;
  }
}