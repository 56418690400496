import { PathModel } from 'src/app/models/PathModel';

export interface IGetRedemptions{
    IdSession : string;
}

export class GetRedemptionsRequest{
    constructor(public data: IGetRedemptions){}

    get endpoint():PathModel{
        return PathModel.ConsultRedemptions;
    }
}