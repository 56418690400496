import { Component } from '@angular/core';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Constants } from 'src/core/constants/Constants';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalUtils } from 'src/core/utils/ModalUtils';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent {
  breakpoints$ = this.responsiveService.observe;
  year = new Date().getFullYear();

  noticePrivacy: string = Constants.EXTERNAL_LINKS.NoticeOfPrivacy;
  legalWarning: string = Constants.EXTERNAL_LINKS.LegalWarning;
  documentation: string = Constants.EXTERNAL_LINKS.Documentation;
  condusef: string = Constants.EXTERNAL_LINKS.Condusef;

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService
  ) { }

  confirmLeavePage(link: string) {
    ModalUtils.confirmLeavePage(this.modalService, link);
  }
}
