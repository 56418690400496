import { Component, OnInit, Input } from '@angular/core';
import { ButtonProperties } from 'src/app/interface/IButtonProperties';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { ResponsiveService } from 'src/app/shared/responsive.service';

@Component({
  selector: 'app-template-primary-button',
  templateUrl: './template-primary-button.component.html',
  styleUrls: ['./template-primary-button.component.css']
})
export class TemplatePrimaryButtonComponent implements OnInit {
  @Input() properties: ButtonProperties = {
    styles : Strings.EMPTY,
    classes: Strings.EMPTY,
    classesImg: Strings.EMPTY,
    doAction: () => { }
  };

  @Input() showIcon = false;
  @Input() icon = Resources.SHAPE_CONTINUE;
  @Input() disabledButton = false;

  vm$ = this.responsiveService.observe;

  constructor(private readonly responsiveService: ResponsiveService) { }

  ngOnInit(): void {
  }

}