import { PathModel } from 'src/app/models/PathModel';

export interface GetClausulesInterface {
    ramo:          string;
    codigoEmisor:  string;
    numeroCarpeta: string;
    codigoReporte: string;
}

export class GetClausulesRequest {
    constructor(public data: GetClausulesInterface) {}
    get endpoint(): PathModel {
        return PathModel.getClausules;
    }
}