import { Strings } from 'src/core/constants/Strings';

export class SatReferencedPaymentStrings{
  static readonly GENERAL_TITLE = 'Pago referenciado SAT';
  static readonly ERROR_CODE = '2300047';
  static readonly SAT_REFERENCES_PAYMENT = {
    CaptureLineLabel: 'Línea de captura',
    CaptureLineName: 'captureLine',
    ApplicationDate : 'FechaAplicacion',
    DetailSize: 116,
    ServiceName: 'Recibo bancario de pago de<br>contribuciones, productos<br>y aprovechamientos federales',
    UnderLines: '---- ---- ---- ---- ----',
    PaymentAmount: 'Cantidad',
    InvalidCaptureLine: 'La línea de captura no es válida',
    Service: 'Pago referenciado por línea de captura',
    PresentationMedium: 'Internet',
    PaymentChannel: 'Internet',
    PaymentMethod: 'Transferencia Electrónica de Fondos',
    CLASSES:{
      CUSTOM_CLASS: 'card-frame-sat-referenced-payment-card-data',
        CONTINUE_PROPERTIES_CLASSES: 'btnGeneric btn-generic web-card-data-continue-btn',
        CONTENT_MARGIN_MOBILE: 'mx-3 my-4',
        CONTENT_MARGIN_NOT_MOBILE: 'mx-4 my-4',
        MODAL_TERMS_AND_CONDITION_CLASS: 'modal-terms-and-conditions',
        MODAL_HELP_SIGNATURE_PANEL_CLASS: 'modal-help-signature-panel',
        NG_INVALID: 'ng-invalid',
        CENTER: 'center',
        BUTTON_PRIMARY: 'btn-generic confirm-data-btn',
        MODALS_BTN: 'modals-btn',
        MODALS_BTN_SECONDARY: 'modals-btn modal-web-card-secondary-btn'
    },
    MODAL_EXCEPTION_SAT: {
      TITLE_PAYMENTS: 'No se encontraron pagos registrados para la cuenta',
      TITLE_CAPTURE_LINE: 'La línea de captura debe iniciar con 02 o 04'
    }
  };
  static readonly FORM_CONTROLS = {
    AccountNumberLabel: 'Selecciona la cuenta',
    AccountNumberName: 'accountNumber',
  };
  static readonly PAYMENT_MESSAGE = (hr) => `El pago se verá reflejado en un máximo de ${hr} hrs, respetando la fecha del día de hoy.`;
  static readonly REFERENCED_SAT = {
    Title: this.GENERAL_TITLE,
    IdService: 'referencedSAT',
    CompleteReferencedSAT:'completereferencedSAT',
    ConfirmProcess:{
      Title: this.GENERAL_TITLE,
      Subtitle: Strings.SERVICE_PAYMENT.HeaderSubtitle
    }
  };
}
