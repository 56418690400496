import { PathModel } from 'src/app/models/PathModel';
import { TToken } from '../TToken';

interface IModifyThirdAgendaRequest {
  idAgenda: number;
  limite: number;
  nuevoLimite: number;
  alias: string;
  eMail?: string;
  eMail2?: string;
  eMail3?: string;
  idSesion: string;
  numeroCuenta: string;
  Token: TToken;
  tipoAcceso: string;
  canal: string;
  ip: string;
  nombreAplicacion: string;
  token?: string;
  traza?: string;
  Latitud: string;
  Longitud: string;
  IdAdicional?: string;
  IdPersonaTitular?: string;
  IsAditional: boolean;
  MedioAcceso: string;
  OrigenConsulta?: string;
}

export class ModifyThirdAgendaRequest {
    constructor(public data: IModifyThirdAgendaRequest){}

    get endpoint(): PathModel {
      return PathModel.ModifyThirdAgenda;
  }
}