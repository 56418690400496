import { PathModel } from 'src/app/models/PathModel';

type TGetThirdAgendasRequest = {
    sesion: string;
    tipo?: string;
    Idadicional?: number;
    Idpersonatitular?: number;    
};

export class GetThirdAgendasRequest {
    constructor(public data: TGetThirdAgendasRequest){ }

    get endpoint(): PathModel {
        return PathModel.GetThirdAgendas;
    }
}