import { IAlertInfo } from 'src/app/models/AlertInfo';
import { ControlCardStrings } from 'src/core/constants/ControlCardStrings';
import { Strings } from 'src/core/constants/Strings';

export class ControlCardConstants{
    static readonly PERCENT_MINUS_VALUE = 0.00;

    static readonly MINIMUM_SMART_AMOUNT = 1;
    static readonly MINIMUM_CODI_AMOUNT = 1;
    static readonly MINIMUM_ANDROID_AMOUNT = 1;
    static readonly MINIMUM_IOS_AMOUNT = 1;
    static readonly MINIMUM_FOREING_AMOUNT = 1;

    static readonly MAXIMUM_CODI_AMOUNT = 50000;
    static readonly MAXIMUM_SMART_AMOUNT = 50000;
    static readonly MAXIMUM_ANDROID_AMOUNT = 50000;
    static readonly MAXIMUM_IOS_AMOUNT = 50000;
    static readonly MAXIMUM_FOREING_AMOUNT = 50000;
    static readonly MAXIMUM_ATM_AMOUNT = 25000;
    static readonly MAXIMUM_COMMERCE_AMOUNT = 50000;
    static readonly MAXIMUM_ONLINE_AMOUNT = 50000;
    static readonly MAXIMUM_IMPUT_LENGHT = 50;
    static readonly SEVENTY = 70;
    static readonly SIZE_CONTAINER = 820;
    static readonly MAP_HEIGHT = 350;
    static readonly PERCENT_MIN_VALUE = 0.01;
    static readonly CVV_LENGTH = 3;
    static readonly REPONSE_SUCCESS_STRING = '0';
    static readonly MINIMUM_MONTHLY_AMOUNT = 1;
    static readonly MAXIMUM_MONTHLY_AMOUNT = 999999999;
    static readonly BRANCH_OFFICE_ARRAY_POSITION = 0;
    static readonly BRANCH_OFFICE = '0';
    static readonly ADDRESS_DELIVERY = '1';
    static readonly DEFAULT_DELIVERY = '1';
    static readonly HEIST = 'Robo';
    static readonly HEIST_CODE = '8';
    static readonly LOSS = 'Extravío';
    static readonly LOSS_CODE = '5';
    static readonly DAMAGED = 'Daño';
    static readonly DAMAGED_CODE = '7';
    static readonly IDENTIFIER_DEBIT = 'MF2';
    static readonly INPUT_TYPE_REPOSITION = 'sucursal';
    static readonly IDENTIFIER_INPUT_TYPE_REPOSITION = 'branch';
    static readonly IDENTIFIER_INPUT_TYPE_REASON = 'reason';
    static readonly MESSAGE_BRANCH_REPOSITION = 'Sucursal para recoger tarjeta';
    static readonly MESSAGE_ADDRESS_REPOSITION = 'Domicilio al que se enviará la tarjeta';
    static readonly CONTAINER_REPOSITION_SUCCESSFUL = 'voucher-reposition';
    static readonly WITHOUT_ADDRESS_NUMBER = 'S/N';
    static readonly IMG_SIZE_CLASS = 'img-size';
    static readonly MONTHLY_TEXT= 'Límite mensual';
    static readonly ERROR_CREDIT_MONTHLY= 'Ocurrió un error al actualizar uno o más parámetros (9003)';
    static readonly LIMIT_UPDATE_FAILED = 777005;

    static readonly MONTH = {
        TWELVE:12
    };

    static readonly NUMBER_DAY={
        THIRTYONE:31
    };

    static readonly START_PERCENT = 5;
    static readonly VALUE_HUNDRED = 100;
    static readonly MAX_AGE = 18;

    //fechas
    static readonly MAX_DATE = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    static readonly MIN_DATE = { year: new Date().getFullYear()- ControlCardConstants.VALUE_HUNDRED, month: new Date().getMonth() + 1, day: new Date().getDate() };
    static readonly MAX_DATE_ADDITIONAL_CARD =
    { year: new Date().getFullYear()- ControlCardConstants.MAX_AGE, month: ControlCardConstants.MONTH.TWELVE, day: ControlCardConstants.NUMBER_DAY.THIRTYONE };

    static readonly OPERATION_LOCK = {
        ATM: 0,
        POS: 1,
        OPI: 2,
        INT: 3,
        CODI: 4,
        SMART:5,
        FOREING:6,
        MOBILE:7,
        ANDROID:8,
        MONTHLY: 9
      };

    static readonly CONFIRMATION = 'request-confirmation';


    static readonly PATRIMONIAL_STYLE_ONE = 'patrimonial-style-one';
    static readonly STYLE_CLOSE_ONE = 'style-close-one';
    static readonly RESOLUTIONS = {
        DesktopAlternativeValue:1280
      };
    static readonly REPLACEMENT_NUMBER = '1234567890';

    static readonly MESSAGE_INFO: IAlertInfo = {
      header: ControlCardStrings.MSG_POPUP_TITLE_CANCEL_CARD,
      message: ControlCardStrings.MSG_DESCRIPTION_CANCEL_CARD,
      btnLabel:  ControlCardStrings.CARD_REPLACEMENT,
      hiddenBtnAccept: false,
      btnExit: false
    };
    static readonly MESSAGE_INFO_DEBIT: IAlertInfo = {
      header: ControlCardStrings.MSG_POPUP_TITLE_CANCEL_CARD,
      message: ControlCardStrings.MSG_DESCRIPTION_CANCEL_CARD,
      btnLabel:  Strings.BUTTONS.Ready,
      hiddenBtnAccept: false,
      btnExit: false
    };
    static readonly MESSAGE_CVVDINFO: IAlertInfo = {
      header: ControlCardStrings.MSG_POPUP_TITLE_CVVD,
      message: ControlCardStrings.MSG_DESCRIPTION_CVVD,
      btnLabel:  ControlCardStrings.CARD_CVV_ACEPT,
      btnExit:false
    };
    static readonly MESSAGE_CVV_CHANGE_INFO: IAlertInfo = {
      header: ControlCardStrings.MSG_POPUP_TITLE_CVV,
      message: ControlCardStrings.MSG_DESCRIPTION_CVV,
      btnLabel:  ControlCardStrings.CARD_CVV_ACEPT,
      btnExit:false
    };

    static readonly TABLE_BODY = [
      { id: 1, description: 'TDC CLÁSICA INBURSA 5674239875656565 - Titular1', name: 'Nombre de Titular1', status: 'Recibido por la sucursal1', branch: 'PLAZA INBURSA1' },
      { id: 2, description: 'TDC CLÁSICA INBURSA 5674239875656566 - Titular', name: 'Nombre de Titula2', status: 'Recibido por la sucursal2', branch: 'PLAZA INBURSA2' },
      { id: 3, description: 'TDC CLÁSICA INBURSA 5674239875656567 - Titular', name: 'Nombre de Titular3', status: 'Recibido por la sucursal3', branch: 'PLAZA INBURSA3' },
      { id: 4, description: 'TDC CLÁSICA INBURSA 5674239875656565 - Titular2', name: 'Nombre de Titular4', status: 'Recibido por la sucursal4', branch: 'PLAZA INBURSA4' },
      { id: 5, description: 'TDC CLÁSICA INBURSA 5674239875656565 - Titular3', name: 'Nombre de Titular5', status: 'Recibido por la sucursal5', branch: 'PLAZA INBURSA5' },
      { id: 6, description: 'TDC CLÁSICA INBURSA 5674239875656565 - Titular4', name: 'Nombre de Titular6', status: 'Recibido por la sucursal6', branch: 'PLAZA INBURSA6' },
      { id: 7, description: 'TDC CLÁSICA INBURSA 5674239875656565 - Titular5', name: 'Nombre de Titular7', status: 'Recibido por la sucursal7', branch: 'PLAZA INBURSA7' },
      { id: 8, description: 'TDC CLÁSICA INBURSA 5674239875656565 - Titular6', name: 'Nombre de Titular8', status: 'Recibido por la sucursal8', branch: 'PLAZA INBURSA8' },
      { id: 9, description: 'TDC CLÁSICA INBURSA 5674239875656565 - Titular7', name: 'Nombre de Titular9', status: 'Recibido por la sucursal9', branch: 'PLAZA INBURSA9' },
      { id: 11, description: 'TDC CLÁSICA INBURSA 5674239875656565 - Titular8', name: 'Nombre de Titular10', status: 'Recibido por la sucursal10', branch: 'PLAZA INBURSA10' },
      { id: 10, description: 'TDC CLÁSICA INBURSA 5674239875656565 - Titular9', name: 'Nombre de Titular11', status: 'Recibido por la sucursal11', branch: 'PLAZA INBURSA11' },
      { id: 12, description: 'TDC CLÁSICA INBURSA 5674239875656565 - Titular10', name: 'Nombre de Titular12', status: 'Recibido por la sucursal12', branch: 'PLAZA INBURSA12' },
      { id: 13, description: 'TDC CLÁSICA INBURSA 5674239875656565 - Titular11', name: 'Nombre de Titular13', status: 'Recibido por la sucursal13', branch: 'PLAZA INBURSA13' },
      { id: 14, description: 'TDC CLÁSICA INBURSA 5674239875656565 - Titular12', name: 'Nombre de Titular14', status: 'Recibido por la sucursal14', branch: 'PLAZA INBURSA14' }
    ];

    static readonly TABLE_TITLE = [
      { id: 1, title: 'Tarjeta' },
      { id: 2, title: 'Nombre' },
      { id: 3, title: 'Estatus' },
      { id: 4, title: 'Sucursal' }
    ];

    static readonly TYPE_BLOCK_CARDS = [{
      ATM: 'Cajeros automáticos',
      INT: 'Compras en línea y telefónicas',
      POS: 'Compras en comercios',
      OPI: 'Compras y retiros en el extranjero',
      MEN: ControlCardConstants.MONTHLY_TEXT
    }];

    static readonly DOUBLE_ZERO = '00';
    static readonly ONE_TWO_ZERO = '1.0-0';
    static readonly FILTER_OPTIONS=[
      { id:0, value:'todos',label:'Todos' },
      { id:1, value:'fisica', label:'Fisicas'},
      { id:2, value:'digital', label:'Digitales'}
    ];
    static readonly DIGITAL='digital';
    static readonly PHYSICAL='fisicas';
    static readonly DIGITAL_FILTER='digitales';
    static readonly ROTATE_CLASS='rotate-icon';

    static readonly ALLOW_DIGITAL_CARDS = ['CT','CTMAX','EFE','NOM','DORADA','INBURCAN','SANBORNS','WALMART'];
    static readonly PROPERTY_CARD_TYPE = 'CardType';
}
export class OperationType{
    static readonly UPDATE_CODI_LABEL = 'CODI';
    static readonly UPDATE_SMART_LABEL = 'SMART';
    static readonly UPDATE_SMART_LABEL_OBJECT = 'WEARABLE';
    static readonly UPDATE_OPI_LABEL = 'OPI';
    static readonly UPDATE_MOBILE_LABEL = 'MOBILE';
    static readonly UPDATE_MOBILE_ANDROID_LABEL = 'ANDROID';
    static readonly UPDATE_ANDROID_LABEL = 'WALLET';
    static readonly UPDATE_IOS_LABEL = 'APPLEPAY';
    static readonly MOVEMENTS_POS = 'Pos';
    static readonly MOVEMENTS_ATM = 'Atm';
    static readonly AMOUNTS = 'Amounts';
    static readonly MOVEMENTS = 'Movements';
    static readonly UPDATE_LIMIT_MONTHLY= 'MEN';
    static readonly MOVEMENTS_MONTHLY = 'Mensual';
}

export enum HeadersTableStatus {
    Card = 'Tarjeta',
    Name = 'Nombre',
    Status = 'Estatus',
    Branch = 'Sucursal'
  }

  export enum ClassOperationBlock{
    MODAL_MY_CARDS = '.modal-my-cards',
    CLASS_D_NONE = 'd-none',
    MODAL_BACKDROP = '.modal-backdrop',
    MODAL_ACTIVATION_CARD= '.modal-activation-card',
    MODAL_CONFIRM_CANCEL_CARD = '.modal-cancel-card'
  }

  export enum InputNames {
    Nip = 'nip',
    ConfirmPin = 'confirmPin',
    CvvMyCards = 'cvvMyCards',
    Cvv = 'cvv',
    Panel = 'panel'
  }

  export enum InputModalMyCard {
    Block = 'block',
    Modify = 'modify',
    Cvv = 'cvv',
    Reason = 'reason',
    Codi = 'CODI'
  }

  export enum Inputs {
    Birthday = 'birthday',
    Percent = 'percent',
    SecondName = 'secondName',
    Rfc = 'rfc',
    Gender = 'gender',
    CivilStatus = 'civilStatus',
    Curp = 'curp'
  }

  export enum ValidInputs {
    Incorrect = 'incorrect'
  }

  export enum FormId {
    AdditionalTop = 'additional-top',
    TopHeader = 'top-header'
  }

  export enum CardDataInfo{
    SourceCard = 'SourceCard',
    idProductAccount = 'IdCuentaProducto',
    pendingStatus = 'pending',
    account = 'Account'
  }
