import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { map, tap } from 'rxjs';
import { AccountModel } from 'src/app/interface/AccountModel';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { AccountCardService } from 'src/app/services/account-card.service';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { AlertService } from 'src/app/services/alert.service';
import { EnterAmountErrorService } from 'src/app/services/enter-amount-error.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { NotifyDateService } from 'src/app/services/notify-date.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Char, ElementsHTML, Numbers, Position, Tab } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { Resources } from 'src/core/constants/Resources';
import { ButtonsSpacing } from 'src/app/models/ButtonsSpacing';
import { AccountsStatusService } from 'src/app/services/accounts-status.service';
import { ProductsService } from 'src/app/services/products.service';
import { CodeflexElementsHTML, CodeflexConstants, SubAccountsData } from 'src/core/constants/CodeflexConstants';
import { CodeflexStrings } from 'src/core/constants/CodeflexStrings';
import { CodeflexService } from 'src/app/services/codeflex.service';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';
import { ProductConstants } from 'src/core/constants/ProductConstants';
import { CodeflexLayoutService } from 'src/app/services/codeflex-layout.service';
import { NotifyRouteService } from 'src/app/services/notify-route.service';

@Component({
  selector: 'app-codeflex-tie',
  templateUrl: './codeflex-tie.component.html',
  styleUrls: ['./codeflex-tie.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeflexTieComponent implements OnInit {
  vm$ = this.responsiveService.observe.pipe(
    tap((breakpoints) => {
      if (breakpoints.mobile) this.contentMargin = ElementsHTML.ContentMarginBreakpoints;
      if (breakpoints.tablet) this.contentMargin = ElementsHTML.ContentMarginCodeflexTablet;
      if (breakpoints.desktop) this.contentMargin = ElementsHTML.ContentMarginBreakpoints;
    }),
    map((breakpoints) => ({ breakpoints }))
  );

  contentMargin: ElementsHTML = ElementsHTML.ContentMargin;
  title: string;
  subtitle: string = CodeflexStrings.SUBTITLE;
  account: AccountModel;
  customDate : Date;
  dateEvent: string = Strings.EMPTY;
  minDate: NgbDateStruct = CodeflexConstants.MIN_DATE;
  maxDate: NgbDateStruct = CodeflexService.maxDateCodeflex();
  minAmount: number = Numbers.One;
  typeAccount: string = Strings.EMPTY;
  chevronRightIcon: string = Resources.SHAPE_CONTINUE;
  buttonsSpacing: ButtonsSpacing = {
    mobile:  CodeflexElementsHTML.ButtonsSpacingMobile,
    desktop: CodeflexElementsHTML.ButtonsSpacingDesktop
  };
  card: ICardAccount = {
    image: Strings.EMPTY,
    cardNumber: Strings.EMPTY,
    clabe: Strings.EMPTY,
    balance: Strings.EMPTY,
    selected: false,
    cardType: Strings.EMPTY
  };
  originCard: ICardAccount;
  headSelect: string = CodeflexStrings.ToAccount;
  originAccountLabel: string = Strings.SELECT_ACCOUNT.FromYourAccount;
  labelSelectAction: string = CodeflexStrings.LabelSelectAction;
  selectAction: Array<string> = CodeflexStrings.CODEFLEX_ACTIONS;
  labelAmount: string;
  homePath: string = PATH.Home;
  date: NgbDateStruct = null;
  waitingTime: string = CodeflexStrings.WAITING_TIME(CodeflexConstants.CODEFLEX_DAYS_31);
  noBalanceMessage: string = Strings.EMPTY;
  isDateError: boolean =  false;
  isDeposit: boolean = false;
  questionMark: string = Resources.QUESTION_MARK_LIGHT_BLUE;
  toolTipIcon: string;
  headDate: string;
  exceptionFilter: string = CodeflexConstants.INVESTMENT_OPERATION_CODE;
  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly router: Router,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly notifyDateService: NotifyDateService,
    private readonly enterAmountErrorService: EnterAmountErrorService,
    private readonly accountCardService: AccountCardService,
    private readonly modalService: ModalService,
    private readonly accountInfoService: AccountInfoStatusService,
    private readonly alertService: AlertService,
    private readonly accountsStatusService: AccountsStatusService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly codeflexLayoutService: CodeflexLayoutService,
    private readonly productService: ProductsService,
    private readonly notifyRouteService: NotifyRouteService
  ) { }

  async ngOnInit() {
    try {
      if (this.accountInfoService.account.numeroCuenta === Strings.EMPTY) {
        this.router.navigate([PATH.Home]);
        return;
      }
      ProductConstants.ORIGIN_CONSULT_DATA = ProductConstants.ORIGIN_CONSULT.Button;
      this.enterAmountErrorService.amountErrorData = { isError: false, errorMsg: Strings.EMPTY };
      this.isDeposit = this.codeflexLayoutService.titleOperation === CodeflexStrings.CODEFLEX_ACTIONS[Numbers.Zero];
      this.labelAmount = this.codeflexLayoutService.titleOperation === CodeflexStrings.CODEFLEX_ACTIONS[Numbers.Zero] ?
        CodeflexStrings.LABEL_AMOUNT.Default(CodeflexStrings.LABEL_AMOUNT.Deposit) : CodeflexStrings.LABEL_AMOUNT.Default(CodeflexStrings.LABEL_AMOUNT.Withdrawal);
      this.customDate = new Date();
      if (!this.isDeposit) {
        const time = SubAccountsData.find(data => data.type === this.accountInfoService.account.tipoCuenta).daysApplication;
        this.waitingTime = CodeflexStrings.WAITING_TIME(time);
        this.customDate.setDate(this.customDate.getDate() + Number(time) + Numbers.One);
      } else if (this.codeflexLayoutService.updateAccounts) {
        const accounts = await this.productService.getAccounts({ reload: true });
        this.codeflexLayoutService.getEjeAccount();
        this.accountInfoService.account = accounts.filter(item => item.idCuentaProducto === this.accountCardService.accountCard.productId)[Position.Zero];
        this.codeflexLayoutService.shareAccount(false);
        this.codeflexLayoutService.updateAccounts = false;
      }
      this.account = this.accountInfoService.account;
      this.originCard =  {
        image: this.account.directorioImagen,
        cardNumber: this.account.numeroCuenta,
        clabe: this.account.cuentaClabe,
        balance: this.account.saldos.disponible.toString(),
        selected: false,
        cardType: this.account.descripcionCuenta
      };
      this.card = this.accountCardService.accountCard;
      this.typeAccount = this.accountInfoService.account.tipoCuenta;
      this.minDate = {
        year: this.customDate.getFullYear(),
        month: this.customDate.getMonth() + Numbers.One,
        day: this.customDate.getDate()
      };
      if(!this.notifyDateService.dateStructData){
        this.notifyDateService.dateStructData = this.minDate;
      }
      this.date = this.notifyDateService.dateStructData;
      this.title = this.isDeposit? `${CodeflexStrings.DEPOSIT_TITLE} ${this.card.cardType}`:
       `${this.codeflexLayoutService.titleOperation} ${this.account.descripcionCuenta}`;
      this.subtitle = `${CodeflexStrings.SUBTITLE} ${this.codeflexLayoutService.titleOperation.toLowerCase()}`;
      this.toolTipIcon = CodeflexStrings.DATE_TOOLTIP(this.codeflexLayoutService.titleOperation.toLowerCase());
      this.headDate = CodeflexStrings.HEAD_DATE(this.codeflexLayoutService.titleOperation.toLowerCase());
      this.noBalanceMessage = CodeflexStrings.NO_BALANCE_AVAILABLE(this.codeflexLayoutService.titleOperation.toLowerCase());

    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT
      }, {
        onClose: () => {
          this.resetStatus();
          this.router.navigate([PATH.Home]);
        },
        onSuccess: () => {
          this.resetStatus();
          this.router.navigate([PATH.Home]);
        }
      });
    }
    NavigationUtils.fixNavigation(this.changeDetector, [PATH.Codeflex]);
  }

  resetStatus(){
    this.notifyDateService.dateStructData = undefined;
    this.accountsStatusService.status = {value: false, isProductSelected: Strings.EMPTY, id: Numbers.Zero};
  }

  continue() {
    let amountError = false;
    let dateError = false;
    const amount = this.notifyAmountService.amount.amount;
    amountError = this.validateAmount(amount);

    if (this.dateEvent !== Strings.EMPTY && !this.isDeposit) {
      const [day, month, year] = this.dateEvent.split(Char.Slash);
      this.notifyDateService.dateStructData = {
        day: parseInt(day),
        month: parseInt(month),
        year: parseInt(year)
      };
      const currentDate = new Date();
      const moreDays = SubAccountsData.find(data => data.type === this.typeAccount).daysApplication;
      currentDate.setDate(currentDate.getDate() + Number(moreDays) + Numbers.One);
      currentDate.setHours(Numbers.Zero, Numbers.Zero, Numbers.Zero, Numbers.Zero);

      const notifyDate = new Date(this.notifyDateService.dateStruct.value.year,
        this.notifyDateService.dateStruct.value.month - Numbers.One, this.notifyDateService.dateStruct.value.day);
      notifyDate.setHours(Numbers.Zero, Numbers.Zero, Numbers.Zero, Numbers.Zero);
      const MaxDate = new Date(this.maxDate.year, this.maxDate.month - Numbers.One, this.maxDate.day);

      if (notifyDate < currentDate || notifyDate > MaxDate) {
        return;
      }
    }

    dateError = this.isDateError;
    this.notifyDateService.isErrorData = dateError;

    if (amountError || dateError) return;

    this.router.navigate([PATH.CodeflexConfirmWithdrawal]);
  }

  validateAmount(amount: string): boolean {
    const numericAmount = Number(Utils.transformAmount(amount));
    if(this.enterAmountErrorService.currentValue.isError){
      return true;
    }
    if (this.account.saldos.disponible === Numbers.Zero){
      this.enterAmountErrorService.amountErrorData = { isError: true, errorMsg: CodeflexStrings.NO_BALANCE_AVAILABLE(this.codeflexLayoutService.titleOperation.toLowerCase()) };
      return true;
    }
    if (numericAmount === Numbers.Zero) {
      this.enterAmountErrorService.amountErrorData = { isError: true, errorMsg: Strings.GENERIC_ERRORS.Required };
      return true;
    }
    return false;
  }

  @HostListener('window:popstate')
  return() {
    this.notifyDateService.dateStructData = undefined;
    this.codeflexLayoutService.isCodeflex= false;
    this.notifyRouteService.navigateToTab(Tab.Investment);
  }

  isInvalidDateError(isError: boolean){
    this.isDateError = isError;
  }

  ngOnDestroy() {
    this.modalService.close();
    ProductConstants.ORIGIN_CONSULT_DATA = ProductConstants.ORIGIN_CONSULT.BackHome;
  }
}
