import { PathModel } from 'src/app/models/PathModel';

export interface IGetCheckBooksRequest {
  estatus : Array<string>;
  numeroCuenta : string;
}

export class GetCheckBooksRequest {
  constructor(public data: IGetCheckBooksRequest) { }

  get endpoint(): PathModel {
      return PathModel.GetCheckBooks;
  }
}
