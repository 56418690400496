import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IWarrantyTransferInternational } from '../interface/IWarrantyTransferInternational';

@Injectable({
  providedIn: 'root'
})
export class ServiceWarrantyTransferInternationalService {

  private readonly warrantyTransferInternational: BehaviorSubject<IWarrantyTransferInternational> = new BehaviorSubject<IWarrantyTransferInternational>({
    warranty: false,
    noWarranty: false,
    warrantySave: ''
  });
  constructor() { }

  get warrantyTransferInternationalObservable() {
    return this.warrantyTransferInternational.asObservable();
  }

  set warrantyTransferInternationalData(data: IWarrantyTransferInternational) {
    this.warrantyTransferInternational.next(data);
  }
  get warrantyTransferInternationalBS(): BehaviorSubject<IWarrantyTransferInternational> {
    return this.warrantyTransferInternational;
  }
}
