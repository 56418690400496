import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IAmount } from 'src/app/interface/IAmount';
import { Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { EnterAmountErrorService } from './enter-amount-error.service';

@Injectable({
  providedIn: 'root'
})
export class NotifyAmountService {
  private readonly _amount: BehaviorSubject<IAmount> = new BehaviorSubject<IAmount>({ amount: Strings.EMPTY });
  private readonly _internationalAmount: BehaviorSubject<IAmount> = new BehaviorSubject<IAmount>({ internationalAmount: Numbers.Zero });

  constructor(private readonly amountErrorService: EnterAmountErrorService) { }

  get amount$() {
    return this._amount.asObservable();
  }

  get amount(): IAmount {
    return this._amount.getValue();
  }

  set amount(data: IAmount) {
    this._amount.next(data);
  }

  get internationalAmount$(){
    return this._internationalAmount?.asObservable();
  }

  get internationalAmount(): IAmount {
    return this._internationalAmount?.getValue();
  }

  set internationalAmount(data:IAmount){
    this._internationalAmount?.next(data);
  }

  clearAmount(): void {
    this.amount = { amount: Strings.EMPTY };
    this.amountErrorService.amountErrorData = { isError: false, errorMsg: Strings.EMPTY };
  }

  validate() {
    if(this.amountErrorService.currentValue.isError) return true;

    if(this._amount.getValue().amount === Strings.EMPTY){
      this.amountErrorService.amountErrorData = {
        isError: true,
        errorMsg: Strings.GENERIC_ERRORS.Required
      };
      return true;
    }

    this.amountErrorService.amountErrorData = {
      isError: false,
      errorMsg: Strings.EMPTY
    };
    return false;
  }
}
