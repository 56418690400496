<ng-container *ngIf="breakpoints$ | async as breakpoints">
  <div class="container">
    <div class="row" *ngIf="!data">
      <div class="col-12 d-flex justify-content-end">
        <div class="justify-content-center align-center">
          <label class="style-text" [ngClass]="breakpoints.mobile ? 'header-xs': 'header-s'">
            Ingresa los 9 dígitos del panel de firma de tu tarjeta de débito
          </label>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center pb-4">
        <img alt="Ayuda" *ngIf="breakpoints.desktop || breakpoints.tablet" [src]="panelDesktopIcon | cdnImage"
          width="auto" height="auto">
        <img alt="Ayuda" *ngIf="breakpoints.mobile" [src]="panelMobileIcon | cdnImage" width="auto" height="auto">
      </div>
      <div class="col-12 d-flex justify-content-center">
        <button class="done-btn" [ngClass]="breakpoints.mobile ? 'button-s': 'button-m'" (click)="close()"> Aceptar
        </button>
      </div>
    </div>

    <div class="row" *ngIf="data">
      <div class="col-12 d-flex">
        <div class="justify-content-center">
          <label
            class="style-text-cfe"
            [ngClass]="breakpoints.mobile ? 'header-xs': 'header-s'">
          Identifica tu NO. DE SERVICIO en tu recibo
          </label>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center pb-4">
        <img
        width="auto"
        height="auto"
          alt="Ayuda"
          *ngIf="breakpoints.desktop || breakpoints.tablet"
          [src]="panelDesktopIconCfe | cdnImage" class="modal-cfe">
          <div class="height-box" *ngIf="!breakpoints.mobile"></div>
        <img
          alt="Ayuda"
          *ngIf="breakpoints.mobile"
          [src]="panelDesktopIconCfeMobail | cdnImage" width="auto" height="auto" class="img-auto-cfe">
      </div>
      <div class="col-12 d-flex justify-content-center">
        <button
          class="btn-accept-cfe"
          [ngClass]="breakpoints.mobile ? 'button-s': 'button-m'"
          (click)="close()"> Aceptar </button>
      </div>
    </div>
  </div>
</ng-container>
