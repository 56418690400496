import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Check, DataCheckBook } from 'src/app/interface/DataCheckBook';
import { Strings } from 'src/core/constants/Strings';
import { Constants, Numbers } from 'src/core/constants/Constants';
import { ActivationCheckBooksRequest } from 'src/app/interface/dto/ActivationCheckBooksRequest';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { CheckBookConsultationService } from 'src/app/services/check-book-consultation.service';
import { StatusChecks } from 'src/core/constants/ChecksStrings';

@Injectable({
  providedIn: 'root'
})
export class CheckBookActivationService {

  private readonly defaultData = {
    active: false,
    state: Strings.EMPTY,
    numberCheckBook: Strings.EMPTY,
    id: Numbers.Zero
  };

  private readonly _activeCheckBook: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _checkActivation: BehaviorSubject<Check[]> = new BehaviorSubject<Check[]>([]);
  private readonly _selectedOperation = new BehaviorSubject<DataCheckBook>(this.defaultData);
  private readonly _listActiveCheckBook: BehaviorSubject<DataCheckBook[]> = new BehaviorSubject<DataCheckBook[]>([]);

  constructor(
    private readonly baseService: BaseServiceService,
    private readonly checkBookConsultationService: CheckBookConsultationService) {}

  get activeCheckBook$() {
    return this._listActiveCheckBook.asObservable();
  }

  get activeCheckBook() {
    return this._listActiveCheckBook.getValue();
  }

  set activeCheckBook(data: DataCheckBook[]) {
    this._listActiveCheckBook.next(data);
  }

  get selectedOperation$() {
    return this._selectedOperation.asObservable();
  }
  get selectedOperation() {
    return this._selectedOperation.getValue();
  }

  get selectedCheckbookObservable() {
    return this._checkActivation.asObservable();
  }

  get selectedCheckbookBS(): BehaviorSubject<Check[]> {
    return this._checkActivation;
  }

  get statusActive$() {
    return this._activeCheckBook.asObservable();
  }

  set statusActiveData(data: boolean) {
    this._activeCheckBook.next(data);
  }

  setActiveCheckBook(activeCheckBook: Check) {
    this._selectedOperation.next(activeCheckBook);
  }

  activationOperationScheduled(numberCheckBook: number): void {
    const checkBooks = this.activeCheckBook.filter(value => value.id !== numberCheckBook);
    this.activeCheckBook = checkBooks;
    const checkBook = this.checkBookConsultationService.checkBooks.find(item => item.id === numberCheckBook);
    if(checkBook){
      checkBook.state = StatusChecks.activated.toUpperCase();
    }
  }

  async activationCheckBooks(request: ActivationCheckBooksRequest): Promise<GenericResponse<string>>{
    const response = await this.baseService.genericPost<GenericResponse<string>>(request, {
      isSecure: true, addSession: true
    });
    if(!response && !response?.datos){
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response;
  }
}
