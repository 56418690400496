import { Constants, Sorts } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';

export class MovementsConstants {

  static readonly CARD_NUMBER = 'numeroTarjeta';
  static readonly MOVEMENT_APPLIED = 'Aplicado';
  static readonly CARD = 'Tarjeta';
  static readonly OPERATION_DATE = 'Fecha de operación';
  static readonly OPERATION_DATE_KEY = 'fechaOperacion';
  static readonly MOVEMENTS_CARD_DEBIT = [
    { key: 'descripcionConcepto', name: 'Tipo de operación' },
    { key: Constants.BENEFICIARY_ACCOUNT_NUMBER, name: 'Tarjeta', format: Constants.MOVEMENTS_FORMAT.Card },
    { key: this.OPERATION_DATE_KEY, name: this.OPERATION_DATE, format: null},
    { key: 'idTransaccion', name: 'Número de operación' },
    { key: Constants.DESCRIPTION_OPERATION, name: 'Descripción de operación' },
    { key: 'nombreBeneficiario', name: 'Nombre del ordenante' },
    { key: 'codigoRastreo', name: 'Clave de rastreo' },
    { key: 'rastreo', name: 'Clave de rastreo' },
    { key: 'estatus', name: 'Estado' },
    { key: 'clabeEmisor', name: 'Cuenta del banco emisor' },
    { key: 'fechaLiquidacion', name: 'Fecha de liquidación', format: Constants.MOVEMENTS_FORMAT.Date },
    { key: Constants.DESCRIPTION_OPERATION, name: 'Concepto' },
    { key: 'referenciaNumerica', name: 'Referencia numérica' },
    { key: Constants.TRANSFER_ACCOUNT_NUMBER, name: 'Tarjeta', format: Constants.MOVEMENTS_FORMAT.Card }
  ];

  static readonly  FILTER_TYPE = [
    {text: 'Todas las operaciones', value: Strings.EMPTY}, 
    {text: 'Cargos', value: Constants.CHARGE},
    {text: 'Abonos', value: Constants.PAYMENT},
  ];
  
  static readonly FILTER_ORDER = [
    {text: 'Monto de mayor a menor', value: Sorts.Descending},
    {text: 'Monto de menor a mayor', value: Sorts.Ascending},
    {text: 'Más recientes primero', value: 'recent'},
    {text: 'Más antiguas primero', value:'old'}
  ];


}