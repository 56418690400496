import { PathModel } from 'src/app/models/PathModel';

export interface IRSLocationRequest{
    method: string;
    latitude: number;
    longitude: number;
    filter: string;
    idSession: string;
}

export class RSLocationRequest{
    constructor(public data: IRSLocationRequest){}

    get endpoint():PathModel{
        return PathModel.RSLocations;
    }
}