<ng-container *ngIf="vm$ | async as vm">
  <div class="container-fluid">
      <div class="d-flex justify-content-end mt-2 padding-icon" *ngIf="showButtonClose">
          <img
            role="button"
            aria-label="Close"
            width="24" height="24"
            [src]="iconClose | cdnImage" alt="Close"
            class="close" (click)="closeModal()" ngbAutofocus>
      </div>
      <div id="voucher-reposition">
        <div class="d-flex flex-column justify-content-center align-items-center m-4 main-color-label">
          <img [src]="iconCheck | cdnImage" class="icon-check" width="56" height="56" alt="Icono" />
          <div class="pt-3 pb-2" [ngClass]="vm.breakpoints.mobile ? 'header-m':'header-l'">Reposición solicitada</div>
          <div class="body-m pb-2">Folio: {{invoice}}</div>
          <div class="pb-3 date">{{ completeDate }}h</div>
          <app-shared-buttons
            class="ignore-element" [hideMail]="true"
            [captureImage]="captureImage">
          </app-shared-buttons>
        </div>
        <div *ngIf="cardType!=undefined">
          <div class="default-color mt-3">
              <div class="mb-3 default-color adjust-position"
              [ngClass]="!vm.breakpoints.mobile ? 'header-s':'header-xs'">Cuenta asociada</div>
              <div class="container pb-2">
          <div class="d-flex flex-column justify-content-start align-text">
            <div class="d-flex flex-row gap-3 align-items-center">
              <div *ngIf="!vm.breakpoints.mobile">
                <img [src]="bankCard" alt="image" width="auto" height="auto" class="col-product-img" >
              </div>
              <div class="d-flex flex-column">
                <span class="body-s default-color" *ngIf="cardNumber!=undefined">{{cardNumber | maskAccount : 4}}</span>
                <span class="detail-s" *ngIf="cardType!=undefined">{{cardType}}</span>
              </div>
            </div>
            <hr class="line" />
          </div>
                  <div class="d-flex flex-column justify-content-start main-color-label">
                      <div class="mb-4"
                      [ngClass]="!vm.breakpoints.mobile ? 'header-s':'header-xs'">Información de la operación</div>
                      <label class="body-s">
                        <text-ellipsis [text]="address" [classes]="'main-color-label'" [lines]="2"></text-ellipsis>
                      </label>
                      <label class="detail-s pb-3">{{ messageReposition }}</label>
                      <hr class="line" />
                  </div>
              </div>
          </div>
        </div>
      </div>
      <section *ngIf="sourceInfo === infi && isCardCancellation"
        class="d-flex flex-column gap-3 pb-4 text-center container-fluid">
        <span class="detail-m">
          {{informationCredit.PlasticReminder}}
        </span>
        <span class="detail-m">
          {{informationCredit.CreditLine}}
        </span>
      </section>
      <div class="container-fluid ">
          <div class="row d-flex justify-content-center align-content-center">
              <button class="btn-form-confirm-addressee mb-3 button-m end-btn insurance-btn modals-btn no-border"
                  [ngClass]="vm.breakpoints.mobile ? 'btn-mobile':'btn-desktop'" (click)="navigate()">
                  Listo</button>
          </div>
          <div class="row d-flex justify-content-center align-content-center">
              <button class="btn-form-modify mb-3 button-m end-btn insurance-btn modals-btn no-border"
                  [ngClass]="vm.breakpoints.mobile ? 'btn-mobile':'btn-desktop'" (click)="goPage()">
                  Otra operación
              </button>
          </div>
      </div>
  </div>
</ng-container>
