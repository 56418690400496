import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISimpleValue } from '../interface/ISimpleValue';

@Injectable({
  providedIn: 'root'
})
export class DeleteAccountService {

  private readonly _deleteAccount: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({value: false});

  constructor() { }

  get deleteAccount$() {
    return this._deleteAccount.asObservable();
  }

  get deleteAccount(){
    return this._deleteAccount.getValue();
  }

  set deleteAccount(data: ISimpleValue) {
    this._deleteAccount.next(data);
  }
}
