<div class="content">
  <header class="d-flex justify-content-center">
    <img [src]="exclamationMark | cdnImage" alt="Atención" width="48" height="48" />
  </header>
  <main class="d-flex flex-column">
    <header class="header-m color-label-general text-align-center">
      Estás abandonando Inbursa Móvil
    </header>
    <span class="detail-m text-center mt-3">¿Deseas continuar?</span>
  </main>
  <footer class="d-flex flex-row justify-content-center gap-3">
    <button class="button-m outline-btn footer-btn" (click)="clickFn()" (keydown.enter)="clickFn()"> Sí </button>
    <button class="button-m colored-btn footer-btn" (click)="close()" (keydown.enter)="close()"> No </button>
  </footer>
</div>
