import { PathModel } from './../../models/PathModel';

interface ILoginOrqRequest {  
   tipoAutenticacion: string;
   nombreUsuario?: string;
   contrasena?: string;
   tokenFacepass?: string;
   ip: string;
   latitud: number;
   longitud: number;
   imgPruebaVida?: string;
   imgTemplate?: string;
   tipoFacePass?: string;
   validaActualizaDebito?: boolean;
   medioAcceso: string;
   guidFacePass?: string;
}

export class LoginOrqRequest{
   constructor(public data: ILoginOrqRequest){ }

   get endpoint(): PathModel{
      return PathModel.LoginOrq;
   }
}