import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { IExpressTransferData } from 'src/app/interface/IExpressTransferData';
import { Strings } from 'src/core/constants/Strings';
import { Constants } from 'src/core/constants/Constants';
import { MainTransferCardComponent } from 'src/app/transfers/components/main-transfer-card/main-transfer-card.component';

@Injectable({
  providedIn: 'root'
})
export class ExpressTransferService {
  expressTransfer: IExpressTransferData = { clabe: Strings.EMPTY, banco: Strings.EMPTY, destinatario: Strings.EMPTY, codigoBanco: Strings.EMPTY };
  expressDataArray: IExpressTransferData[] = [];
  selected: boolean = false;
  private readonly dateSelected$ = new BehaviorSubject<boolean>(false);
  expressDateFlow$ = this.dateSelected$.asObservable();

  private readonly expressTransfer$: BehaviorSubject<IExpressTransferData> = new BehaviorSubject<IExpressTransferData>({
    clabe: Strings.EMPTY,
    banco: Strings.EMPTY,
    destinatario: Strings.EMPTY,
    codigoBanco: Strings.EMPTY
  });
  private readonly _expressTransferForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _markDirty: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _isExpressTransfer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }

  get expressTransferObservable() {
    return this.expressTransfer$.asObservable();
  }

  get expressTransferData (): IExpressTransferData{
    return this.expressTransfer$.getValue();
  }

  set expressTransferData(data: IExpressTransferData) {
    this.expressTransfer$.next(data);
  }
  clearExpressTransferData() {
    if (this.expressTransfer$.value.clabe !== Strings.EMPTY) {
      this.expressTransfer = { clabe: Strings.EMPTY, banco: Strings.EMPTY, destinatario: Strings.EMPTY, codigoBanco: Strings.EMPTY };
      this.expressTransfer$.next(this.expressTransfer);
      this.isExpressTransfer = false;
      this.expressTransferForm = false;
      this.selected = false;
    }
  }

  setExpressDateFlow(data: boolean) {
    this.dateSelected$.next(data);
  }
  get expressTransferForm$(): Observable<boolean> {
    return this._expressTransferForm.asObservable();
  }

  set expressTransferForm(data: boolean) {
    this._expressTransferForm.next(data);
  }


  get markDirty$(): Observable<boolean> {
    return this._markDirty.asObservable();
  }

  set markDirty(data: boolean) {
    this._markDirty.next(data);
  }
  get isExpressTransfer$() {
    return this._isExpressTransfer.asObservable();
  }

  get isExpressTransfer(): boolean {
    return this._isExpressTransfer.getValue();
  }

  set isExpressTransfer(data: boolean) {
    this._isExpressTransfer.next(data);
  }
  
  changeRegexByExpress(mainTransferCard: MainTransferCardComponent, isExpress:boolean) {
    if(!isExpress){
     return null;
    }
    return this.expressTransferObservable.pipe(distinctUntilChanged((prevTypeBank, currentTypeBank) =>
      prevTypeBank.banco === currentTypeBank.banco)).subscribe(expressData => {
        if (expressData.banco && expressData.banco !== Strings.EMPTY) {
          mainTransferCard.isExpressTransferOwn = expressData.banco === Constants.INBURSA_DEFAULT_BANK;
          mainTransferCard.changeRegexByTransfer(Constants.FORM_CONTROLS.Spei);
        }
      });
  }
}
