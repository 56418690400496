import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IAccountsEvent } from 'src/app/interface/IAccountsEvent';
import { Numbers } from 'src/core/constants/Constants';

@Injectable({
  providedIn: 'root'
})
export class AccountsStatusService {
 private readonly _status: BehaviorSubject<IAccountsEvent> = new BehaviorSubject<IAccountsEvent>({value: false});
 accountNumberCompare: number = Numbers.Zero;

  constructor() { }

  get status$() {
    return this._status.asObservable();
  }

  get status() {
    return this._status.getValue();
  }

  set status(data: IAccountsEvent) {
    this._status.next(data);
  }

}
