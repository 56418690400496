<ng-container *ngIf="vm$ | async as vm">
  <div class="modal-content">

    <div class="d-flex justify-content-end close-button">
      <img role="button" width="auto" height="auto" aria-label="Close" [src]="xIcon | cdnImage" alt="Close"
        (click)="closeModal()" />
    </div>

    <div class="padd-body">
      <div id=" header-text">
        <div class="header-s line-height" [ngClass]="vm.breakpoints.mobile ? 'p-0 ml-select ':  '' ">
          Selecciona el recibo a pagar
        </div>
      </div>
      <div class="mt-3 search-and-refresh">
        <app-search-component class="align-search" #search ngbAutoFocus
        (dataFromSearch)="receiveDataFromSearch($event)" [removePadding]="true">
        </app-search-component>
        <div ngbDropdown dropdownMaxHeight class="d-inline-block mb-3 filter-mobile">
          <div class="position-relative" [ngClass]="filterEmpty ? 'd-flex' : ''">
            <button
            class="form-select-sm body-s filter-styles-generic filter-button-label"
            [ngClass]="{'form-select' : !selectedDate, 'align-text-filter' : !filterEmpty}"
            id="filterbyDate"
            [ngbTooltip]="selectedDate | customDate"
            role="button" ngbDropdownToggle>
            {{ (selectedDate | customDate | ellipsisText: !vm.breakpoints.mobile ? 4 : 20 ) || 'Filtrar' }}
            </button>
            <img *ngIf="filterEmpty" class="clear-filter" width="auto" height="auto" alt="clear"
            [src]="xIcon | cdnImage" (click)="selectDate(null)"/>
          </div>
          <div ngbDropdownMenu aria-labelledby="filterbyDate" id="movementsFilter">
            <div *ngIf="vm.pagination.length === 0" class="detail-m mx-3">Sin opciones</div>
            <button *ngFor="let date of filterDates" class="dropdown-item detail-m"
              ngbDropdownItem (click)="selectDate(date)">
              {{ date | customDate }}
            </button>
          </div>
        </div>
        <div class="position-relative mb-3 margin-right-img">
          <button
            class="refresh-button"
            role="button">
            <img class="align-refresh-icon" width="auto" height="auto" [src]="refresh | cdnImage" alt="refresh"
              (click)="refreshRegistries()"/>
          </button>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="card-sua card-content mb-3" *ngFor="let dataCard of vm.pagination" [class.hover-card]="true"
        (dblclick)="validateJustToPay(dataCard)">
          <div class="row detail-left">
            <div class="detail-s">Folio SUA</div>
            <div class="header-xs text-overflow">{{dataCard.folio}}</div>
            <div class="detail-s"> Archivo </div>
            <div class="pl-0 text-overflow" [ngClass]="vm.breakpoints.mobile ? 'body-extra-small':'body-s'">
              <span>{{dataCard.name | ellipsisText: !vm.breakpoints.mobile ? 22 : 15}}</span>
            </div>
            <div class="detail-s">Registro Patronal</div>
            <div class="pl-0 text-overflow" [ngClass]="vm.breakpoints.mobile ? 'body-extra-small':'body-s'"><span
                class="complete-view-label">{{dataCard.registry}}</span> </div>
          </div>
          <div class="row detail-right">
            <div
              class="header-xs d-flex justify-content-end text-overflow"
              [ngClass]="vm.breakpoints.mobile ? 'text-align':''" placement="top"
              [ngbTooltip]="dataCard.quantity | currency" tooltipClass="tooltip-sua">
              {{dataCard.quantity | currency | ellipsisText: !vm.breakpoints.mobile ? 13 : 9}}
            </div>
            <div class="row p-0">
              <div class="detail-s-highlight label-sua-card d-flex justify-content-end align-items-center p-0">
                <div [ngClass]="dataCard.status.class"></div>
                <div><span [ngClass]="vm.breakpoints.mobile ? 'text-align':''">{{dataCard.status.label}}</span></div>
              </div>
            </div>
            <div class="detail-s text-align-right complete-view-label">Último movimiento</div>
            <div class="detail-s text-align-right space-label-time text-overflow">
              {{dataCard.movement.date | customDate:'dd/Month/yyyy'}}<br>{{dataCard.movement.time}}
            </div>
            <ng-container *ngIf="dataCard.status.label == 'Validado'">
              <div class="d-flex justify-content-end" *ngIf="vm.breakpoints.desktop || vm.breakpoints.tablet">
                <button class="btn-pay m-top-btn-pay" (click)="goToPayment(dataCard)">
                  <img [src]="bankCardIcon | cdnImage" alt="" width="auto" height="auto">
                  <span class="button-s pay-text">Pagar </span>
                </button>
              </div>
            </ng-container>
          </div>

          <ng-container *ngIf="dataCard.status.label == 'Validado'">
            <div *ngIf="vm.breakpoints.mobile" [ngClass]="vm.breakpoints.mobile ? 'rows-grid': ''">
              <button class="btn-pay" (click)="goToPayment(dataCard)">
                <img [src]="bankCardIcon | cdnImage" alt="" width="auto" height="auto">
                <span class="button-s">Pagar </span>
              </button>
            </div>
          </ng-container>
        </div>
        <div class="padding-add d-flex align-items-center wrap" *ngIf="vm.pagination.length !== 0">
          <img [src]="plusIcon | cdnImage" width="auto" height="auto" alt="Plus icon" class="cursor-pointer"
            (click)="addNewFileSua()">
          <span class="body-s cursor-pointer text-new-payment p-new-payment" (click)="addNewFileSua()">
            Agregar un nuevo pago
          </span>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <app-pagination [showPadding]="false"></app-pagination>
      </div>
    </div>
  </div>
</ng-container>
