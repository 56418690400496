import { PathModel } from 'src/app/models/PathModel';

type TGetFacepassTransactionRequest = {
    nombreAplicacion: string;
};

export class GetFacepassTransactionRequest {
    constructor(public data: TGetFacepassTransactionRequest) { }

    get endpoint(): PathModel {
        return PathModel.GetFacepassTransaction;
    }
}