import { PathModel } from 'src/app/models/PathModel';

export type TGetPDFPromissoryNoteRequest = {
    NumeroCertificado: string,
    IdSession: string,
    NumeroCuenta: string
};

export class GetPDFPromissoryNoteRequest {
    constructor(public data: TGetPDFPromissoryNoteRequest) { }

    get endpoint(): PathModel {
        return PathModel.GetPDFPromissoryNote;
    }
}