import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Strings } from 'src/core/constants/Strings';
import { Numbers } from 'src/core/constants/Constants';
import { IInvestmentCompanies } from 'src/app/interface/IInvestmentCompanies';
import { ProductConstants } from 'src/core/constants/ProductConstants';
import { InvestmentAccountsData, Schedule } from 'src/app/interface/GetInvestmentAccountsModel';

@Injectable({
  providedIn: 'root',
})
export class InvestmentCompaniesInfoStatusService {
  private readonly emptyModel: InvestmentAccountsData = {
    codigoInversion: Strings.EMPTY,
    descripcion: Strings.EMPTY,
    saldoDisponible: Numbers.Zero,
    cuenta: Strings.EMPTY,
    clabe: Strings.EMPTY,
    saldoTitulos: Numbers.Zero,
    titulos: Numbers.Zero,
    precioTitulo: Numbers.Zero,
    idCuentaProducto: Numbers.Zero
  };

  private readonly _investmentCompaniesInfo: BehaviorSubject<InvestmentAccountsData> = new BehaviorSubject<InvestmentAccountsData>(this.emptyModel);
  private readonly _status: BehaviorSubject<IInvestmentCompanies> = new BehaviorSubject<IInvestmentCompanies>({ value: false });
  private readonly _sharesError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _selectError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _timeData: BehaviorSubject<Schedule> = new BehaviorSubject<Schedule>({
    inicio: Strings.EMPTY,
    fin: Strings.EMPTY,
    esHorarioValido: false
  });
   private readonly _variableInvestmentTimeData: BehaviorSubject<Schedule> = new BehaviorSubject<Schedule>({
    inicio: Strings.EMPTY,
    fin: Strings.EMPTY,
    esHorarioValido: false
  });

  get investmentCompaniesInfo$() {
    return this. _investmentCompaniesInfo.asObservable();
  }

  get investmentCompaniesInfo() {
    return this._investmentCompaniesInfo.getValue();
  }

  set investmentCompaniesInfo(data: InvestmentAccountsData) {
    this._investmentCompaniesInfo.next(data);
  }

  clearModel() {
    this._investmentCompaniesInfo.next(this.emptyModel);
  }

  get status$(): Observable<IInvestmentCompanies> {
    return this._status.asObservable();
  }

  get status(): IInvestmentCompanies {
    return this._status.getValue();
  }

  set status(data: IInvestmentCompanies) {
    this._status.next(data);
  }

  get sharesError$(): Observable<boolean> {
    return this._sharesError.asObservable();
  }

  get sharesError(): boolean {
    return this._sharesError.getValue();
  }

  set sharesError(data: boolean) {
    this._sharesError.next(data);
  }

  get selectError$(): Observable<boolean> {
    return this._selectError.asObservable();
  }

  get selectError(): boolean {
    return this._selectError.getValue();
  }

  set selectError(data: boolean) {
    this._selectError.next(data);
  }

  get investmentCompaniesType() {
    return this. _investmentCompaniesInfo.getValue().codigoInversion.includes(ProductConstants.INVESTMENT_CODE_DINBUR);
  }

  get timeData$(): Observable<Schedule> {
    return this._timeData.asObservable();
  }

  get timeData(): Schedule {
    return this._timeData.getValue();
  }

  set timeData(data: Schedule) {
    this._timeData.next(data);
  }

  get variableInvestmentTimeData$(): Observable<Schedule> {
    return this._variableInvestmentTimeData.asObservable();
  }

  get variableInvestmentTimeData(): Schedule {
    return this._variableInvestmentTimeData.getValue();
  }

  set variableInvestmentTimeData(data: Schedule) {
    this._variableInvestmentTimeData.next(data);
  }
}
