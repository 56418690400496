import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ITdcEvent } from 'src/app/interface/ITdcEvent';
import { Numbers } from 'src/core/constants/Constants';

@Injectable({
  providedIn: 'root'
})
export class CreditCardsStatusService {
  private readonly _status: BehaviorSubject<ITdcEvent> = new BehaviorSubject<ITdcEvent>({value: false});
  cardNumberCompare: number = Numbers.Zero;

  constructor() { }

  get status$() {
    return this._status.asObservable();
  }

  get status() {
    return this._status.getValue();
  }

  set status(data: ITdcEvent) {
    this._status.next(data);
  }
}
