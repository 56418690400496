import { Component, HostListener } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { ModalConfig } from 'src/app/interface/IModalWithdrawal';
import { ModalOptions } from 'src/app/interface/modal-options';
import { AforeDataService } from 'src/app/services/afore-data.service';
import { ModalWithdrawalService } from 'src/app/services/modal-withdrawal.service';
import { StateVoluntaryWithdrawalService } from 'src/app/services/state-voluntary-withdrawal.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Constants, Position } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { ModalEndProcessAlertComponent } from 'src/app/shared/components/modal-end-process-alert/modal-end-process-alert.component';
import { VoluntaryWithdrawalRequest } from 'src/app/interface/dto/TVoluntaryWithdrawalRequest';
import { Strings } from 'src/core/constants/Strings';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { Utils } from 'src/core/utils/utils';
import { AforeStrings } from 'src/core/constants/AforeStrings';
import { AlertService } from 'src/app/services/alert.service';
import { ModalVoluntaryWithdrawalComponent } from '../modal-voluntary-withdrawal/modal-voluntary-withdrawal.component';
import { ModalConstants } from 'src/core/constants/ModalConstants';

@Component({
  selector: 'app-modal-voluntary-withdrawal-info',
  templateUrl: './modal-voluntary-withdrawal-info.component.html',
  styleUrls: ['./modal-voluntary-withdrawal-info.component.css']
})
export class ModalVoluntaryWithdrawalInfoComponent {
  modalConfig: ModalConfig | null = null;
  removeOperation: string = Resources.CLOSE_CIRCLE_BLUE;
  statusEnrollment = false;
  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints }))
  );
  modalConfig$: Observable<SafeResourceUrl | null> | null = null;
  isiPhone: boolean = false;
  subAccountVoluntary: string = AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.SubAccountType[Position.Zero];
  subAccountInvestment: string = AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.SubAccountType[Position.Three];
  options: ModalOptions = {
    centered: true,
    size: Constants.MODAL_OPTIONS.SizeMd,
    modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAlertEndProcess
  };
  modalInstance: NgbModalRef;

  constructor(
    private readonly modalServiceWithdrawal: ModalWithdrawalService,
    private readonly modalService: ModalService,
    private readonly stateVoluntaryService: StateVoluntaryWithdrawalService,
    private readonly responsiveService: ResponsiveService,
    private readonly aforeDataService:AforeDataService,
    private readonly sanitizer: DomSanitizer,
    private readonly alertService: AlertService) { }

  ngOnInit(): void {
    this.modalServiceWithdrawal.modalConfig$.subscribe((config) => {
      this.modalConfig = config;
        this.modalConfig$ = of(config.htmlCDN).pipe(
          switchMap(url => {
            const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            return of(safeUrl);
          }),
          catchError(error => {
            return of(error);
          })
        );
    });
    this.isMobileIos();
  }

  close(){
    this.modalService.open(ModalEndProcessAlertComponent, this.options);
  }

  async showConfirm() {
    this.modalInstance?.close();
    await this.getVoluntaryWithdrawals();
  }

  cancel(){
    this.modalService.open(ModalEndProcessAlertComponent, this.options);
    this.stateVoluntaryService.successVoluntary = false;
    this.stateVoluntaryService.confirmVoluntary = false;
    this.stateVoluntaryService.isVoluntaryProcess = false;
  }

  continueToModalVoluntaryWithdrawalComponent() {
    const modalOptions: ModalOptions = {
      centered: false,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalVoluntaryWithdrawal
    };
    this.modalService.open(ModalVoluntaryWithdrawalComponent, modalOptions);
  }

  isMobileIos() {
    if (navigator.userAgent.match(Constants.USER_AGENT.MobileApple)) {
      this.isiPhone = true;
    } else {
      this.isiPhone = false;
    }
  }

  /**
   * Este método se encarga de realizar la solicitud de una operación de retiro de ahorro voluntario
   */
  async getVoluntaryWithdrawals() {
    try {
      const request: VoluntaryWithdrawalRequest = new VoluntaryWithdrawalRequest({
        numeroCuenta: this.aforeDataService.afore?.cuenta?.idCuenta,
        monto: this.stateVoluntaryService.dataVoluntaryWithdrawal?.withdrawalAmount,
        tipoSubCuenta: this.stateVoluntaryService.dataVoluntaryWithdrawal?.subAccount?.tipoSubCuenta,
        tipo: this.getTypeWithdrawal(),
        idOcupacion: parseInt(this.stateVoluntaryService.dataVoluntaryWithdrawal?.occupation)
      });
      await this.stateVoluntaryService.getVoluntaryWithdrawal(request);
      if (!this.stateVoluntaryService.voluntaryWithdrawal?.numeroSolicitud) {
        this.modalService.close();
        throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
      }
      this.setAforeWithdrawalValues();
      this.stateVoluntaryService.successVoluntary = false;
      this.stateVoluntaryService.confirmVoluntary = true;
      this.continueToModalVoluntaryWithdrawalComponent();
    } catch (error) {
      this.modalInstance?.close();
      this.alertError(Utils.getErrorMsg(error));
      this.stateVoluntaryService.emptyDataVoluntaryWithdrawal();
      this.stateVoluntaryService.successVoluntary = false;
      this.stateVoluntaryService.confirmVoluntary = false;
      this.stateVoluntaryService.isVoluntaryProcess = false;
    }
  }

  /**
   * Este método se encarga de establecer los valores de respuesta del servicio de solicitud de una operación de
   * retiro de ahorro voluntario en las propiedades del objeto dataVoluntaryWithdrawal del stateVoluntaryService.
  */
  setAforeWithdrawalValues() {
    this.stateVoluntaryService.dataVoluntaryWithdrawal.idOperation = this.stateVoluntaryService.voluntaryWithdrawal?.numeroSolicitud;
    this.stateVoluntaryService.dataVoluntaryWithdrawal.isrInterests = this.stateVoluntaryService.voluntaryWithdrawal?.isrIntereses.toString();
    this.stateVoluntaryService.dataVoluntaryWithdrawal.isrOtherIncome = this.stateVoluntaryService.voluntaryWithdrawal?.isrOtrosIngresos;
    this.stateVoluntaryService.dataVoluntaryWithdrawal.withdrawalAmount = this.stateVoluntaryService.voluntaryWithdrawal?.monto;
    this.stateVoluntaryService.dataVoluntaryWithdrawal.payOnLine = this.stateVoluntaryService.voluntaryWithdrawal?.pagoEnLinea;
    this.stateVoluntaryService.dataVoluntaryWithdrawal.consent = this.stateVoluntaryService.voluntaryWithdrawal?.consentimiento;
  }

  /**
   * Este método se encarga de devolver el tipo de retiro que se va a hacer de acuerdo al código del tipo de retiro del
   * objeto dataVoluntaryWithdrawal del stateVoluntaryService.
  */
  getTypeWithdrawal(): string {
    return this.stateVoluntaryService.dataVoluntaryWithdrawal?.typeWithdrawal ===
      AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.WithdrawalTypes[Position.Zero] ?
      AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.PartialWithdrawalCode :
      AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.TotalWithdrawalCode;
  }

  /**
   * Cierra la ventana modal y muestra una alerta con el mensaje y el código de error proporcionados.
   * @param error Un objeto que contiene el mensaje de error y el código de error.
  */
  alertError(error: { msg: string, code: number }) {
    this.modalService.close();
    const alertInstance = this.alertService.showPopupAlert({
      message: Utils.SERVICE_ERROR_MSG(error.msg, error.code),
      header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
      btnLabel: Strings.MSG_POPUP_ACCEPT,
      imgHead: Resources.CLOSE_ICON_RED,
      btnExit: false
    }, {
      onSuccess: () => alertInstance.dismiss(),
      onClose: () => alertInstance.dismiss()
    });
  }

  @HostListener('window:popstate')
  backNavigation() {
    this.modalInstance?.close();
  }
}
