import { PATH } from 'src/core/constants/Path';

export class NavigationConstants {
  static readonly HOME = 'inicio';
  static readonly NAVIGATION_PARAMETERS = {
    OtpCode: 'otpCode',
    VoluntarySavingContributions: 'voluntarySavingContributions',
    DomiciliationVoluntaryContributions: 'domiciliationVoluntaryContributions',
    PaymentGeneralContributionsByConcept: 'PaymentGeneralContributionsByConcept',
    RememberActiveCard:'rememberActiveCard'
  };

  static readonly MENU_OPTIONS_AFORE = {
    ProceduresClick: 'ProceduresClick',
    MoreInformationClick:'MoreInformationClick'
  };

  static readonly MENU_OPTIONS = {
    CreditCardPayment: 'CreditCardPaymentClick',
    CreditCardPaymentService:'CreditCardPaymentServiceClick',
    OperationCoDi: 'CoDiClick',
    OperationDiMo: 'DiMoClick',
    DigitalChecks: 'DigitalChecks',
    OperationPromotionSavings: 'SavingClick',
    AssistanceInsurance: 'AssistanceInsuranceClick',
    AdvancePayrollClick: 'AdvancePayrollClick',
    CreditPayrollClick: 'CreditPayrollClick',
    TransferDebt:'TransferDebtClick'
  };

  static readonly LAZY_PATHS = [
    PATH.Login,
    PATH.Home,
    PATH.Transfers,
    PATH.InternationalTransfers,
    PATH.TransferCTPatrimonial,
    PATH.NewAddressee,
    PATH.BeneficiaryData,
    PATH.GeneralInformation,
    PATH.AccessHistory,
    PATH.Control,
    PATH.FrequentlyQuestions,
    PATH.Portability,
    PATH.Services,
    PATH.AddService,
    PATH.Beneficiaries,
    PATH.Cancellations,
    PATH.CheckingAccountProtection,
    PATH.LoyaltyPoints,
    PATH.Clarification,
    PATH.Domiciliation,
    PATH.ProgrammedOperations,
    PATH.InvestmentFundsBuy,
    PATH.InvestmentFundsSale,
    PATH.CreditPayment,
    PATH.VoluntarySavingsContributions,
    PATH.DirectDebitAfore,
    PATH.InsuranceExpress,
    PATH.Additional,
    PATH.SchedulableServices,
    PATH.PaymentPolicies,
    PATH.NotAgendableServices,
    PATH.AddPortability,
    PATH.TransferExpress
  ];

  static readonly CREATE_NIP_PAGES = {
    Question: 'Question',
    CreateNIP: 'CreateNIP',
    Done: 'Done'
  };
}

export enum History {
  ScrollRestoration = 'scrollRestoration',
  Manual = 'manual'
}

export type CreateNipInfo = {
  showQuestion?: boolean;
  infoCard?: {
    imagecard: string,
    cardNumber: string,
    cardName: string
  };
};