<div class="files-content">
  <input type="file" #fileInput class="input-select-file" (change)="onFileSelected($event)" id="file" accept=".sua">
  <label for="file" class="hidde-element"></label>
</div>
<div class="col-12 mt-3 mb-3">
  <div class="position-relative">
    <img [src]="exportIcon | cdnImage" alt="upload" width="auto" height="auto" onkeypress="fileInput.click()"
      class="input-icon position-absolute top-50  translate-middle" (click)="fileInput.click()" />
    <label class="m-2 position-absolute detail-s-highlight pos-label-input label-input">
      <span class="small bg-white px-1">Subir archivo</span>
    </label>
    <input type="text" class="form-control inputs place-holder-main-transfer body-s" id="input-file"
      onkeypress="fileInput.click()" autocomplete="off" readonly required (click)="fileInput.click()"
      [(ngModel)]="fileName" [ngModelOptions]="{standalone: true}" /> <label for="input-file"
      class="hidde-element"></label>
  </div>
</div>

<ng-template #modalNotFile>
  <app-modal-exceptions [idModal]="idModal" [headerModal]="headerModal" [labelMain]="labelMain"
    [continue]="continue" [showRegistries]="showRegistries"></app-modal-exceptions>
</ng-template>
