import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IGMM } from '../interface/IGMM';
import { IBaseService } from './i-base-service.service';
import { GenericResponse } from '../interface/dto/GenericResponse';
import { GetDeductibleMedicalExpensesResponse } from '../interface/dto/GetDeductibleMedicalExpensesResponse';
import { GetDeductibleMedicalExpensesRequest } from '../interface/dto/GetDeductibleMedicalExpensesRequest';
import { PayDeductibleMedicalExpensesRequest } from '../interface/dto/PayDeductibleMedicalExpensesRequest';
import { Strings } from 'src/core/constants/Strings';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { Constants } from 'src/core/constants/Constants';

@Injectable({
  providedIn: 'root'
})

export class GmmInfoService {
  private readonly _medicalExpensesEmpty: IGMM = {
    firstName: Strings.EMPTY,
    secondName: Strings.EMPTY,
    lastName: Strings.EMPTY,
    secondLastName: Strings.EMPTY,
    claimNumber: Strings.EMPTY,
    deductible: Strings.EMPTY,
    amountCoinsurance: Strings.EMPTY,
    issuerCode: Strings.EMPTY,
    letterCode: Strings.EMPTY,
    letterNumber: Strings.EMPTY,
    completeName: Strings.EMPTY
  };

   private readonly _medicalExpenses: BehaviorSubject<IGMM> = new BehaviorSubject<IGMM>(this._medicalExpensesEmpty);

  constructor(
    private readonly baseService: IBaseService
  ) { }

  get medicalExpenses$(): Observable<IGMM> {
    return this._medicalExpenses.asObservable();
  }

  set medicalExpenses(data: IGMM) {
    this._medicalExpenses.next(data);
  }

  get medicalExpenses(): IGMM {
    return this._medicalExpenses.getValue();
  }

  clearGmmInfoService() {
    this.medicalExpenses = this._medicalExpensesEmpty;
  }

  async getDeductible (request: GetDeductibleMedicalExpensesRequest) : Promise<GetDeductibleMedicalExpensesResponse> {
    const response = await this.baseService.genericGet<GenericResponse<GetDeductibleMedicalExpensesResponse>>(request, {
      isSecure: true, addSession: true
    });

    if(!response || !response?.datos){
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response.datos;
  }

  async payMedicalExpenses (request: PayDeductibleMedicalExpensesRequest) : Promise<string> {
    const response = await this.baseService.genericPost<GenericResponse<string>>(request, {
      isSecure: true, addSession: true
    });

    if(!response || !response?.datos){
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response.datos;
  }

}
