import { Pipe, PipeTransform } from '@angular/core';
import { Char, Constants, Months, Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
@Pipe({
  name: Constants.PIPE_CUSTOM_DATE
})
export class DatePipes implements PipeTransform {
  months = Object.values(Months);
  transform(date: string, formatDate?: string) {
    if (date && date.length > Numbers.Zero) {
      if (formatDate) {
        return this.formatDate(date);
      }
      if (date.indexOf(Char.WhiteSpace) !== Constants.NOT_FOUND_ITEM) {
        date = date.split(Char.WhiteSpace)[Numbers.Zero];
      }
      if (date.indexOf(Char.MiddleDash) > Constants.NOT_FOUND_ITEM) {
        const [day, month] = date.split(Char.MiddleDash).reverse();
        return `${this.months[parseInt(month) - Numbers.One] ? this.months[parseInt(month) - Numbers.One] : Strings.EMPTY} ${day}`;
      } else {
        return this.titleDate(date);
      }
    }
    return date;
  }

  titleDate(date: string) {
    const [day, month, year] = date.split(Char.Slash);
    const presentYear = new Date();
    const getPresentYear = presentYear.getFullYear();
    if (year && parseInt(year) !== getPresentYear) {
      return `${this.months[parseInt(month) - Numbers.One] ? this.months[parseInt(month) - Numbers.One] : Strings.EMPTY} ${day}${Char.Comma} ${year}`;
    }
    return `${this.months[parseInt(month) - Numbers.One] ? this.months[parseInt(month) - Numbers.One] : Strings.EMPTY} ${day}`;
  }

  formatDate(date: string) {
    if (date.split(Char.Slash).length !== Numbers.Three) return date;
    const [day, month, year] = date.split(Char.Slash);
    if (isNaN(Number(day)) || isNaN(Number(month)) || isNaN(Number(year))) return date;
    return `${day}${Char.Slash}${this.months[parseInt(month) - Numbers.One].
      toLowerCase() ? this.months[parseInt(month) - Numbers.One].toLowerCase() : Strings.EMPTY}${Char.Slash}${year}`;
  }

}
