import { PathModel } from '../models/PathModel';

export interface ICancelarFacepassPLRequest {
    idDispositivo : string
}

export class CancelarFacepassPLRequest{
    constructor (public data: ICancelarFacepassPLRequest){ }

    get endpoint(): PathModel {
      return PathModel.CancelFacepass;
  }
}
