import { Component, Input } from '@angular/core';
import { Strings } from 'src/core/constants/Strings';

@Component({
  selector: 'app-card-frame',
  templateUrl: './card-frame.component.html',
  styleUrls: ['./card-frame.component.css']
})
export class CardFrameComponent {
  @Input() customStyle: Partial<CSSStyleDeclaration> = {
    borderRadius: '0.5rem',
    padding: '1rem',
    rowGap: '1rem'
  };
  @Input() customClass: string = Strings.EMPTY;
  @Input() showStyles: boolean = true;

  constructor() { }

}
