export class WebCardStrings {

  static readonly WebCard = {
    MimeFormat: /:(.*?);/,
    ImageFormat: '.png',
    ERROR: 'error',
    FORMATDATE:'yyyyMMddhhmmss',
    SAVE_IMAGE_CONTAINER: 'saveImageContainer',
    ClassHideButtons: 'ignoreElement',
    WebEmpty: '',
    WebCard: 'WebCard',
    WEBCARD: 'Tarjeta Web',
    TITLE: 'Solicitud Tarjeta Web',
    OPERATIONS_IN_PROGRESS: 'Operaciones en trámite',
    AVAILABLE_CREDIT_LINE: 'Línea de crédito disponible',
    TERMS: 'terms',
    MODAL_INFO_WEB: 'modal-info-web',
    WEB_CARD_DATA_CONFIRMATION_BTN: 'btn-generic btnGeneric confirm-data-btn',
    MODAL_WEB_CARD: {
      TITLE: '¿Deseas solicitar tu Tarjeta Web?',
      BTN_CONFIRM: 'Sí, solicitar',
      BTN_CANCEL: 'Cancelar'
    },
    MODAL_TERMS_AND_CONDITION: {
      Title: 'Cuenta Web INBURSA',
      Subtitle: 'Políticas de Uso (Términos y Condiciones)',
      Close_Btn_Properties: 'modal-term-and-conditions-close-btn',
      Btn_Properties: 'modals-btn terms-and-conditions-btn',
      DECLARATION: `Declaro haber leído y estar de acuerdo con las Políticas de Uso (Términos y condiciones)`,
      VALIDATION_MESSAGE: `Debes aceptar los términos y condiciones`
    },
    LABELS_WEB_CARD: {
      ApplicantInformation: 'Información del solicitante',
      CardUsageMessage: 'Podrás utilizar la tarjeta dentro de 2 días hábiles'
    },
    INFO_MESSAGE: 'La tarjeta que solicitaste no está activa. Podrás comenzar a usarla en 2 días hábiles. ¿Dudas? comunícate al 55 5447 8000 o al 800 90 90000.',
    WEB_FORM: {
      FIRST_NAME: {
        label: 'Primer Nombre',
        name: 'firstName'
      },
      SECOND_NAME: {
        label: 'Segundo Nombre',
        name: 'secondName'
      },
      LAST_NAME: {
        label: 'Apellido Paterno',
        name: 'lastName'
      },
      SECOND_LAST_NAME: {
        label: 'Apellido Materno',
        name: 'secondLastName'
      },
      GENDER: {
        label: 'Sexo',
        name: 'gender',
        options: {
          male: 'Masculino',
          female: 'Femenino'
        }
      },
      VALIDATION_TYPE: {
        label: 'Tipo de Validación',
        name: 'validationType',
        options: {
          None: {
            value: 'none',
            label: 'Seleccione una opción'
          },
          CardNumber: {
            value: 'cardNumber',
            label: 'Tarjeta'
          },
          SignaturePanel: {
            value: 'signaturePanel',
            label: 'Panel de Firma'
          }
        }
      },
      CARD_NUMBER: {
        label: 'Tarjeta',
        name: 'cardNumber',
        placeholder: '16 digitos',
        inputMode: 'numeric'
      },
      SIGNATURE_PANEL: {
        label: 'Panel de Firma',
        name: 'signaturePanel',
        placeholder: '---------',
        inputMode: 'numeric'
      }
    },
    CLASSES: {
      CONTINUE_PROPERTIES_CLASSES: 'btnGeneric btn-generic web-card-data-continue-btn',
      CONTENT_MARGIN_MOBILE: 'mx-3 my-4',
      CONTENT_MARGIN_NOT_MOBILE: 'mx-4 my-4',
      CUSTOM_CLASS: 'card-frame-web-card-data',
      NG_INVALID: 'ng-invalid',
      CENTER: 'center',
      BUTTON_PRIMARY: 'btn-generic confirm-data-btn',
      MODALS_BTN: 'modals-btn',
      MODALS_BTN_SECONDARY: 'modals-btn modal-web-card-secondary-btn'
    },
    WEB_CARD_RQEUEST_MODAL_OPEN_NAME: 'openWebCardModal',
    SUCCESSFUL_FOLIO: 'folio'

  };

  static readonly WEBINFORMATIVEMESSAGE: string = `
    Si tu tarjeta no cuenta con los 9 dígitos en el panel de firma, selecciona tarjeta en el tipo de validación, e ingresa los 16 dígitos de tu número de tarjeta.
  `;

  static readonly WEBTERMSANDCONDITIONS = `
    6.1 Mediante la Tarjeta de Compras por Internet, el CLIENTE podrá disponer parte o de la totalidad del
    saldo que exista en su cuenta de Depósito Bancario de Dinero a la Vista para realizar compras por medio de Internet
    exclusivamente, mediante una transferencia de fondos que el CLIENTE solicite al BANCO de acuerdo al procedimiento
    descrito en el apartado siguiente.<br><br>
    6.2 Cada disposición(en lo sucesivo una «TRANSFERENCIA»)se entenderá como una disposición por parte del CLIENTE de una
    parte o de la totalidad del saldo de su cuenta de Depósito Bancario de Dinero a la Vista, sujeto a los límites que en
    su caso establezca el BANCO, mismos que de tiempo en tiempo hará del conocimiento del CLIENTE, por lo que una vez que el
    CLIENTE realice alguna TRANSFERENCIA, el monto de la misma no generará intereses a favor del CLIENTE por el periodo en que
    dicha TRANSFERENCIA permanezca en la Tarjeta de Compras por Internet y sin utilizarse de conformidad con el procedimiento
    descrito en los numerales siguientes. La recepción o uso de la Tarjeta de Compras por Internet por parte del CLIENTE
    constituirá su conformidad respecto a las condiciones pactadas para el uso de la misma.<br><br>
    6.3 El CLIENTE podrá solicitar al BANCO una TRANSFERENCIA de fondos existentes en su cuenta de Depósito Bancario de Dinero
     a la Vista a la Tarjeta de Compras por Internet por MEDIOS TELEINFORMÁTICOS y previa verificación de fondos en la cuenta
     del CLIENTE se hará la TRANSFERENCIA solicitada. Las cantidades que el CLIENTE transfiera de su cuenta a la Tarjeta de Compras
     por Internet no podrá disponerlas en efectivo, sino únicamente podrá utilizarse para la compra y/o pago de bienes o servicios, a través de Internet.<br><br>
    6.4 Para que el CLIENTE pueda solicitar y/o en su caso efectuar TRANSFERENCIAS a la Tarjeta de Compras por Internet, será requisito
    indispensable que el CLIENTE tenga fondos suficientes en su cuenta y/o en su caso haya solicitado al BANCO la apertura de una línea de sobregiro de conformidad
    con los términos del apartado 11 del presente contrato, y el BANCO la hubiere autorizado.<br><br>
    6.5 El BANCO no asume ninguna responsabilidad cuando el CLIENTE no pueda efectuar operaciones por desperfectos, suspensión del
    servicio en el sitio de Internet del BANCO, y/o cualquier otro sitio en el cual el CLIENTE pretenda realizar operaciones, o bien
    por casos fortuitos o de fuerza mayor que impidan al CLIENTE efectuar operaciones en los sitios de Internet antes mencionados.<br><br>
    6.6 El CLIENTE podrá solicitar al BANCO que le expida una o más Tarjetas de Compras por Internet adicionales, mismas que estarán
    asociadas a la cuenta del CLIENTE, y de las cuales será responsable solidario respecto al mal uso en su caso que se haga de las mismas.<br>
  `;
}

export enum WebCardNumbers {
  MenusOne = -1,
  AsciiPoint = 8226
}
