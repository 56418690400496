import { PathModel } from '../models/PathModel';

export interface ITokenInfoGeneralRequest{
    Sesion : string;
}

export class TokenInfoGeneralRequest{
    constructor(public data: ITokenInfoGeneralRequest){}

    get endpoint(): PathModel {
      return PathModel.TokenOverview;
  }
}
