import { DateUtils } from 'src/core/utils/dateUtils';
import { Component, Input } from '@angular/core';
import { ScheduledTransferData } from 'src/app/services/scheduled-transfer.service';
import { TransferStrings } from 'src/core/constants/TransferStrings';
import { Numbers, Numbers as newNumbers} from 'src/core/constants/Numbers';
import { Strings } from 'src/core/constants/Strings';
import { Char } from 'src/core/constants/Constants';
@Component({
  selector: 'app-schedule-transfer-data',
  templateUrl: './schedule-transfer-data.component.html',
  styleUrls: ['./schedule-transfer-data.component.css']
})
export class ScheduleTransferDataComponent {
  @Input() scheduleData: ScheduledTransferData;
  @Input() isTransferComplete: boolean;
  @Input() isOwnTransfer: boolean;
  getTotalOfPeriodicity(): string{

    const durationObj  = TransferStrings.PERIODIC_TRANSFER_SELECTS_OPTIONS.DurationValuesObj.find(data=>data.duration===this.scheduleData.duration);
    if (durationObj) {
      const [day, month, year] = DateUtils.formattedDateWithMonthName(this.scheduleData.initialDate,Char.Slash).split(Char.Slash);
      const [dayEnd, monthEnd, yearEnd] = DateUtils.formattedDateWithMonthName(this.scheduleData.finalDate,Char.Slash).split(Char.Slash);
      const dateInit = new Date(Number(year), Number(month) - Numbers.One, Number(day));
      const dateEnd = new Date(Number(yearEnd), Number(monthEnd) - Numbers.One, Number(dayEnd));
      const differenceMilliseconds = dateEnd.getTime() - dateInit.getTime();
      const differenceOfDays = differenceMilliseconds / (newNumbers.OneThousand*newNumbers.ThreeThousandSixHundred*newNumbers.TwentyFour);
      const totalOfPeriodicity = Math.round(differenceOfDays /  durationObj.realDays);
      if(totalOfPeriodicity>Numbers.One){
        return `${totalOfPeriodicity} ${durationObj.descriptionPlural}`;
      }
      return `${totalOfPeriodicity} ${durationObj.duration}`;
    }
    return Strings.EMPTY;
  }


}
