import { Numbers } from 'src/core/constants/Numbers';
export class PaymentSATStrings {
  static readonly IMPORT = 'Importe';
  static readonly COMPENSATIONS = 'Compensaciones';
  static readonly UPDATED_PART = 'Parte actualizada';
  static readonly SURCHARGES = 'Recargos';
  static readonly FINES_FOR_CORRECTION = 'Multa por corrección';
  static readonly CURP = 'CURP';
  static readonly FIRST_NAME = 'Apellido Paterno';
  static readonly SECOND_NAME = 'Apellido Materno';
  static readonly NAME = 'Nombre';
  static readonly DEPENDENCY_CHAIN = 'Cadena de la dependencia';
  static readonly PERIOD = 'Período';
  static readonly EXERCISE = 'Ejercicio';
  static readonly CANCELLATION_OF_RECEIPTS = 'Consulta y cancelación de recibos';
  static readonly RFC='RFC';

  static readonly IMPFED = {
    Description:'Pago por Concepto'
  };

  static readonly CONFIRM_DATA = {
    Title: '¿Los datos capturados son correctos?',
    SubTitle: 'Confirma tus datos',
    BtnOK: 'Sí, son correctos'
  };
  static readonly PAYMENT_CONTRIBUTIONS_CONCEPT = {
    Form:{
      TitleVersion:'Versión AWT',
      Subtitle:'Pago de Derechos, Productos y Aprovechamientos',
      InformationConcept:' Máximo podrás capturar <b>5 conceptos</b> de pago por declaración',
      Buttons:{
        Continue: 'Continuar',
        WithoutRFC: 'Sin RFC',
        AddConcept: 'Agregar concepto',
        DeleteConcept: 'Borrar Concepto',
        ConfirmData: 'Confirmar Datos',
        DeleteAll:'Borrar todo'
      },
      Period:{
        NoPeriod: 'No aplica periodo',
        Monthly: 'Mensual',
        Bimonthly: 'Bimestral',
        Quarter: 'Trimestral',
        Quarterly: 'Cuatrimestral',
        Biannual: 'Semestral',
        TheExercise:'Del Ejercicio',
      },
      Imports:{
        DPA: 'DPA',
        IVA:'IVA ACTOS ACCIDENTALES',
        Import:this.IMPORT,
        UpdatedPart:this.UPDATED_PART,
        Surcharges:this.SURCHARGES,
        FinesForCorrection:this.FINES_FOR_CORRECTION,
        Compensations:this.COMPENSATIONS,
        AmountToPay:'Cantidad a pagar'
      },
      TextFields:{
        RFC: 'textfieldRFC',
        ConfirmRFC: 'textfieldConfirmarRFC',
        CURP: 'textfieldCURP',
        FirstName: 'textfieldPaterno',
        SecondName: 'textfieldMaterno',
        Name: 'textfieldNombre',
        RazonSocial: 'textfieldRazonSocial',
        DPAReferenceKey: 'textfieldClaveReferenciaDPA',
        DependencyChain: 'textfieldCadenaDependencia',
        Period: 'comboboxPeriodo',
        Exercise: 'comboboxEjercicio',
        Dependency: 'comboboxDependencia',
        Balance: 'textfieldSaldoFavor',
        Detail: 'listDetalle',
        TaxCard: 'textfieldNombreTarjetaTributaria',
        Import: 'textfieldACargo'
      },
      IDLabel:{
        RFC: 'LabelSAT',
        CURP: 'LabelCURP',
        FirstName: 'LabelPaterno',
        SecondName: 'LabelMaterno',
        Name: 'LabelNombre',
        RazonSocial: 'LabelDenominacion',
        Dependency: 'LabelDye',
        Exercise: 'LabelEjercicio',
        DPAReferenceKey: 'LabelCrDPA',
        Period: 'LabelPeriodo',
        DependencyChain: 'LabelCadenaDependencia',
        Balance: 'LabelSaldo',
        TaxCard: 'LabelTarjetaTributaria'
      },
      LabelName:{
        RFC: this.RFC,
        ConfirmRFC: 'Confirmar RFC',
        CURP: this.CURP,
        FirstName: this.FIRST_NAME,
        SecondName: this.SECOND_NAME,
        Name: this.NAME,
        RazonSocial: 'Denominación o razón social',
        Dependency: 'Dependencias y Entidades',
        Exercise: this.EXERCISE,
        DPAReferenceKey: 'Clave de Referencia de DPA',
        Period: this.PERIOD,
        DependencyChain: this.DEPENDENCY_CHAIN,
        Balance: 'Saldo a Favor',
        TaxCard: 'Nombre Tarjeta Tributaria',
        Detail: 'Detalle',
        Import: 'Importe'
      }
    },
    sharedText:{
      Title:'Pago de Contribuciones Federales por Concepto',
      TitleInformation: 'Información de la operación',
      TitleConcepts: 'Pago de derechos, productos y aprovechamientos',
      LabelOperation:{
        TotalAmount: 'Total efectivamente pagado',
        CURP:this.CURP,
        FirstName: this.FIRST_NAME,
        SecondName: this.SECOND_NAME,
        Name: this.NAME,
        RazonSocial:'Razon Social',
        Dependency:'Dependencia',
        Import:this.IMPORT,
        Amount:'Cantidad pagada',
        DPAReferenceKey:'Clave de Referencia del DPA',
        DependencyChain:this.DEPENDENCY_CHAIN,
        Period:this.PERIOD,
        Exercise:this.EXERCISE,
        UpdatedPart:this.UPDATED_PART,
        Surcharges:this.SURCHARGES,
        FinesForCorrection:this.FINES_FOR_CORRECTION,
        Compensations:this.COMPENSATIONS,
        RFC:this.RFC,
      },
      LabelNoApplyPeriod: 'No aplica',
    },
    Pagination:{
      HeaderHeight:218,
      FooterHeight:212,
      SpaceHeight:320,
    },
    Confirm:{
      Subtitle:'Confirmación de datos',
    },
    Complete:{
      LabelDigitalStamp:'Sello digital',
      LabelOriginalString:'Cadena original',
      LabelInvoice:'Folio: '
    },
    TextComparation:{
      Import:'Importe:',
      AmountToPay: 'Cantidad a pagar:',
      DPA:'Clave de referencia del DPA:',
      StringDependency: 'Cadena de la dependencia:',
      UpdatedPart:'Parte Actualizada:',
      Surcharges:'Recargos:',
      FinesForCorrection:'Multa por Corrección:',
      Compensations:'Compensaciones:',
      Period:'Período:',
      NoPeriod:'[No aplica período]',
      Separator: '---------------',
      SeparatorPeriod: 'del',
      Title: 'Derechos, Productos y Aprovechamientos',
      TitleIva: 'IVA actos accidentales'
    },
    OptionsComparation:{
      Option: 'option',
      Value: 'value',
      TextContent: 'textContent',
    },
    periodNumbers:{
      Zero: Numbers.Zero,
      One: Numbers.One,
      Two: Numbers.Two,
      Three: Numbers.Three,
      Four: Numbers.Four,
      Five: Numbers.Five,
    }
  };
  static readonly CANCELLATION_AND_CONSULTATION = {
    LabelCancellation:'Su recibo fue cancelado exitosamente',
    LabelDeclarations:'Declaraciones al día ',
    CancellationData:{
      Title:'Se cancelará recibo: ',
      Reference:'-Referencia: ',
      Transaction:'-Transacción: ',
      Amount:'-Monto: ',
      Status:'-Estado: '
    },
    Invoice: 'Folio: ',
    Cancelled: 'Reversada'
  };
  static readonly EXCEPTIONS ={
    DPA:'La longitud es de 9 caracteres',
    Dependency:'La longitud es de 14 caracteres',
    RFC: 'Completa este campo con un RFC válido',
    CompareRFC: 'Ambos campos deben coincidir',
    CURP: 'Completa este campo con un CURP válido',
    NameCompare: 'errorCompare',
    NoPayments: 'No cuentas con pagos al momento.'
  };
}
