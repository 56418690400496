import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { Constants, FormatDate, Source} from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/shared/modal.service';
import { PATH } from 'src/core/constants/Path';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { map, Observable } from 'rxjs';
import { Strings } from 'src/core/constants/Strings';
import { GetDataCardResponse } from 'src/app/interface/dto/GetDataCardResponse';
import { ICardNumberInfo } from 'src/app/interface/dto/ICardNumberInfo';
import { StateCardNumberInfoService } from 'src/app/services/state-card-number-info.service';
import { CardsAdminService } from 'src/app/services/cards-admin.service';
import { Utils } from 'src/core/utils/utils';
import { AlertService } from 'src/app/services/alert.service';
import { ControlCardService } from 'src/app/services/control-card.service';
import { ControlCardResources } from 'src/core/constants/ControlCardResources';
import { ControlCardConstants } from 'src/core/constants/ControlCardConstants';
import { AdditionalService } from 'src/app/services/additional-cards.service';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { TLocksCard } from 'src/app/interface/TLocksCard';
import { Generic } from 'src/types/Generics';
import { ControlCardStrings } from 'src/core/constants/ControlCardStrings';


@Component({
  selector: 'app-modal-replacement-requested',
  templateUrl: './modal-replacement-requested.component.html',
  styleUrls: ['./modal-replacement-requested.component.css']
})
export class ModalReplacementRequestedComponent implements OnInit, OnDestroy {
  isCardCancellation: boolean = false;
  cancellationByBranch: boolean = false;
  getDataCardsInfo: GetDataCardResponse;
  iconClose: string = Resources.CLOSE_CIRCLE_BLUE;
  iconCheck: string = Resources.CHECK_GREEN_FILL;
  completeDate: string;
  sentinelIp: string = Strings.EMPTY;
  bankCard: string = ControlCardResources.BANK_CONTROL_CARD_BLUE;
  bankCardMobile: string = ControlCardResources.BANK_CONTROL_CARD_BLUE;
  cardNumberInfo: ICardNumberInfo;
  cardNumberInfo$: Observable<ICardNumberInfo> = this.cardNumberInfoService.cardNumberInfo$;
  sourceInfo: string;
  pictureCard: string = Strings.EMPTY;
  invoice:string=ControlCardConstants.REPLACEMENT_NUMBER;
  finish : boolean = false;
  address: string = Strings.EMPTY;
  modalConent: string = ModalConstants.MODAL_OPTIONS.ModalConent;
  modalData: {
    folio: string,
    iconCard: string,
    numberCard: string,
    typeCard: string,
    sourceCard: string,
    cardLocks: TLocksCard
  };

  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => {
      return {breakpoints};
    })
  );

  cardNumber : string =Strings.EMPTY;
  cardType : string =Strings.EMPTY;
  @Input() showButtonClose: boolean = true;
  messageReposition: string = ControlCardConstants.MESSAGE_BRANCH_REPOSITION;
  captureImage: { name: string; element: HTMLElement; };
  informationCredit: Generic<typeof ControlCardStrings.CREDIT_CANCELLATION.Information> = ControlCardStrings.CREDIT_CANCELLATION.Information;
  infi: string = Source.Infinitus;
  constructor(
    public activeModal: NgbActiveModal,
    private readonly _router: Router,
    private readonly modalService: ModalService,
    private readonly responsiveService: ResponsiveService,
    private readonly cardNumberInfoService: StateCardNumberInfoService,
    private readonly cardsAdminService: CardsAdminService,
    private readonly alertService: AlertService,
    private readonly controlShowModal:ControlCardService,
    private readonly additionalCardService: AdditionalService
  ) { }

  ngOnDestroy(): void {
    this.cardNumberInfoService.isCardCancelling = false;
    this.cardNumberInfoService.refreshCards = true;
  }

  async ngOnInit(): Promise<void> {
    const date = new Date();
    this.completeDate = formatDate(date, FormatDate.DayMonthYearComplete, Constants.LOCALE_ES_MX);
    this.invoice = this.modalData?.folio;
    this.cardNumberInfo$.subscribe(async (data) => {
      this.cardNumberInfo = data;
    });
    this.sourceInfo = this.cardNumberInfo.CardDataInfo.SourceCard;
    await this.getDataCards();
    this.isCardCancellation = this.cardNumberInfoService.isCardCancelling;
    this.cancellationByBranch = this.cardNumberInfoService.repositionByBranch;
    if (this.isCardCancellation && !this.cancellationByBranch) {
      this.address = this.cardNumberInfoService.addressRepositionCard;
      this.messageReposition = ControlCardConstants.MESSAGE_ADDRESS_REPOSITION;
    } else {
      this.address = this.additionalCardService.addressAdditional;
    }
    this.captureImage = {
      name: this.invoice,
      element: document.getElementById(ControlCardConstants.CONTAINER_REPOSITION_SUCCESSFUL)
    };
  }

  private async getDataCards() {
    try {
      this.getDataCardsInfo = this.getCardsInfo();
      this.pictureCard = this.cardsAdminService.getPictureCard(this.getDataCardsInfo?.Card);
     if(this.getDataCardsInfo.Card.CardFrontImageUrl === null || !this.getDataCardsInfo.Card.CardFrontImageUrl){
        if (this.getDataCardsInfo.Card.Source === Source.Mf2) {
          this.pictureCard = Resources.ASSETS_GENERIC_DEBIT_CARD();
        }else{
          this.pictureCard = Resources.ASSETS_GENERIC_CREDIT_CARD();
        }
      }else if (
          this.getDataCardsInfo.Card.CardFrontImageUrl.indexOf(Resources.ASSETS_IMAGES_OSB)!== -1) {
            const img = this.getDataCardsInfo.Card.CardFrontImageUrl.replace(Resources.ASSETS_IMAGES_OSB, Strings.EMPTY);
            this.pictureCard = Resources.ASSETS_CREDIT_CARDS() + img;
      }
      const cardsInfo$: ICardNumberInfo = {
        CardDataInfo: { CardLocks: this.getDataCardsInfo.Card.Locks, CardNumber: this.getDataCardsInfo.Card.CardNumber, SourceCard: this.getDataCardsInfo.Card.Source }
      };
      this.cardNumberInfoService.cardNumberInfo = cardsInfo$;
      this.cardNumber = this.getDataCardsInfo.Card.CardNumber;
      this.cardType = this.getDataCardsInfo.Card.CardType;
      this.bankCard = this.modalData.iconCard;
    } catch (error) {
      this.modalService.close();
      this.finish = true;
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg,errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT
      });
    }
  }

  private getCardsInfo(): GetDataCardResponse {
    return {
      Card: {
        CardFrontImageUrl: this.modalData.iconCard,
        Source: this.modalData.sourceCard,
        CardType: this.modalData.typeCard,
        CardNumber: this.modalData.numberCard
      }
    } as GetDataCardResponse;
  }

  closeModal() {
    this.controlShowModal.isRenewModal = false;
    this.activeModal.dismiss();
  }

  goPage() {
    this.controlShowModal.isRenewModal =false;
    this.modalService.close();
  }
  navigate() {
    this.controlShowModal.isRenewModal =false;
    this._router.navigate([PATH.Home]);
  }

}
