<ng-container *ngIf="vm$ | async as vm">
  <div [ngClass]="modalConfig.style"
  [class.investment-iphone]="isiPhone && modalConfig.subAccount === subAccountInvestment">
    <div class="d-flex justify-content-end" ngbAutofocus>
      <img role="button" aria-label="Close" width="auto" height="auto"
      [src]="removeOperation | cdnImage" alt="Close"
        class="close" [ngClass]="modalConfig.subAccount === subAccountVoluntary ? 'mb-voluntary':'mb-close'"
        (click)="close()" autofocus #imgClose
        tabindex="0">
    </div>
    <iframe title="frame_voluntary" height="100%" width="100%" [src]="modalConfig$ | async"></iframe>
    <div class="d-flex justify-content-center mt-10">
      <button class="btns-modal-info btn-continue button-m" (click)="showConfirm()"> Continuar </button>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btns-modal-info btn-cancel button-m" (click)="cancel()"> Cancelar </button>
    </div>
  </div>
</ng-container>

