import { PathModel } from 'src/app/models/PathModel';

interface IGetContractInformationRequest {
  IdSession: string;
  NumeroContrato: string;
}

export class GetContractInformationRequest {
  constructor(public data: IGetContractInformationRequest) { }

  get endpoint(): PathModel {
    return PathModel.ConsultContractInformation;
  }
}
