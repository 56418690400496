import { Component, EventEmitter,HostListener,Input, OnInit, Output } from '@angular/core';
import { AforeDataService } from 'src/app/services/afore-data.service';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import { ModalService } from 'src/app/shared/modal.service';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalMyCardsComponent } from 'src/app/control-card/components/modal-my-cards/modal-my-cards.component';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ControlCardStrings } from 'src/core/constants/ControlCardStrings';
import { Constants } from 'src/core/constants/Constants';
import { ModalConfirmCheckbookDetailsComponent } from 'src/app/home/components/modal-confirm-checkbook-details/modal-confirm-checkbook-details.component';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { StateVoluntaryWithdrawalService } from 'src/app/services/state-voluntary-withdrawal.service';
import { BureauAlertsService } from 'src/app/services/bureau-alerts.service';
import { ControlCardService } from 'src/app/services/control-card.service';
import { Router } from '@angular/router';
import { PATH } from 'src/core/constants/Path';
import { AccountModifyService } from 'src/app/services/info-accounts-edit.service';
import { AforeService } from 'src/app/services/afore.service';
import { MapService } from 'src/app/services/map.service';
import { AccountCardService } from 'src/app/services/account-card.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';


@Component({
  selector: 'app-modal-end-process-alert',
  templateUrl: './modal-end-process-alert.component.html',
  styleUrls: ['./modal-end-process-alert.component.css']
})
export class ModalEndProcessAlertComponent implements OnInit {
  @Output() sendModalAnswer = new EventEmitter();
  @Output() acceptCancel = new EventEmitter();
  @Input() infoCard: string = ControlCardStrings.START_DATA;
  @Input() typeCard: string = Strings.EMPTY;
  @Input() isControlCard: boolean=false;
  @Input() returnModal:  {modalOptions: ModalOptions, instance:NgbModalRef};
  iconQuestion: string = Resources.CANCELLATION;
  title: string = Strings.AFORE.TitleProcessAlert;
  detail: string = Strings.AFORE.DetailProcessAlert;
  questionMark: string = Resources.CIRCLE_BLUE_QUESTION;
  btnSend: string = Strings.BUTTONS.Yes;
  btnCancel: string = Strings.BUTTONS.No;
  modalInstance: NgbModalRef;

  constructor(private readonly modalService: ModalService,
    private readonly aforeDataService: AforeDataService,
    private readonly dynamicFormService: DynamicFormService,
    private readonly router: Router,
    public activeModal: NgbActiveModal,
    private readonly stateVoluntaryService: StateVoluntaryWithdrawalService,
    private readonly bureauAlertsService: BureauAlertsService,
    private readonly accountModifyService: AccountModifyService,
    private readonly controlCard: ControlCardService,
    private readonly aforeService: AforeService,
    private readonly mapService: MapService,
    private readonly accountCardService: AccountCardService,
    private readonly notifyAmountService: NotifyAmountService
  ) { }

  ngOnInit(): void {
    this.typeCard= (this.typeCard === Strings.EMPTY ? ControlCardStrings.NOCARD:this.typeCard);
    this.btnSend = this.isControlCard ? ControlCardStrings.BUTTONS.Agree:Strings.BUTTONS.Yes;
    this.btnCancel = this.isControlCard ? Strings.BUTTONS.Cancel:Strings.BUTTONS.No;
    this.title = this.isControlCard ? ControlCardStrings.CONTROL_CARDS.TitleProcessCancelCard:Strings.AFORE.TitleProcessAlert;
    this.detail = this.isControlCard ? `${ControlCardStrings.CONTROL_CARDS.Card} ${this.typeCard}`:
    this.accountModifyService.modifyAccout.value ? Strings.EMPTY : Strings.AFORE.DetailProcessAlert;
  }

  endProcess() {
    this.aforeDataService.detailWPartialData = { value: false };
    this.aforeDataService.userApplicationData = { value: false };
    this.aforeDataService.startProcessData = { value: false };
    this.aforeDataService.endProcessData = { value: false };
    this.accountModifyService.modifyAccout = { value: false };
    this.aforeService.cleanData();
    this.modalService.close();
    this.accountCardService.clearCardAccount();
    this.notifyAmountService.amount = { amount: Strings.EMPTY };
    this.dynamicFormService.clearForm();
    this.modalService.close();
    if(this.controlCard.controlMapClose){
      this.router.navigate([PATH.Control]);
    }
    if(this.stateVoluntaryService.confirmVoluntary) {
      this.stateVoluntaryService.getVoluntaryWithdrawalReverse();
    }
    this.mapService.showMap = true;
  }

  returnProcess() {
    if (this.bureauAlertsService.leaveModalMap.value){
      this.activeModal.dismiss();
      const modalOptions: ModalOptions = {
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeMd,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalConfirmCheckbookDetails
      };
      this.modalService.open(ModalConfirmCheckbookDetailsComponent, modalOptions);
    }else if(this.returnModal){
      this.modalInstance?.close();
      this.returnModal.instance.update({
        ...this.returnModal.modalOptions
      });
    }
    else {
    this.modalInstance?.close();
    }
  }

  clickOk(){this.acceptCancel.emit(true);}

  closeModal() {
    this.modalService.modalInstance.close();
    const modalOptions: ModalOptions = {
      centered: true,
      size:Constants.MODAL_OPTIONS.SizeXl,
      modalDialogClass:ModalConstants.MODAL_OPTIONS.ModalMyCards
    };
    const ref= this.modalService.open(ModalMyCardsComponent,modalOptions);
    ref.componentInstance.cancelReopen=true;
  }

  @HostListener('window:popstate')
  backNavigation() {
    if(this.stateVoluntaryService.confirmVoluntary) {
      this.stateVoluntaryService.getVoluntaryWithdrawalReverse();
    }
    this.modalInstance.dismiss();
  }
}
