import {Component, Input, OnInit} from '@angular/core';
import {InputConfig} from 'src/app/interface/IInputConfig';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-input-template',
  templateUrl: './input-template.component.html',
  styleUrls: ['./input-template.component.css']
})
export class InputTemplateComponent implements OnInit {
  @Input() input: InputConfig;
  @Input() form: FormGroup;
  inputModel: InputConfig;
  formModel: FormGroup;
  @Input() errorMsg : string;
  @Input() enable : boolean = false;
  @Input() showError : boolean = true;
  @Input() hiddenErrorDisabled: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.inputModel = this.input;
    this.formModel = this.form;
  }

}