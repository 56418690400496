import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarResponsiveService {
  private readonly state: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  get stateObservable() {
    return this.state.asObservable();
  }

  set stateData(value: boolean) {
    this.state.next(value);
  }

  get subjectVal() {
    return this.state.getValue();
  }
}
