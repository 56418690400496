import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'basic-grid-template',
  templateUrl: './basic-grid-template.component.html',
  styleUrls: ['./basic-grid-template.component.css']
})
/**
 * @Description BasicGridTemplateComponent
 * Is a basic grid template with 2 columns in desktop
 * and tablet, and 1 column in mobile.
 * @Usage you put your 2 columns in the template and specify the grid-row or
 * grid-column in the parent css file if is necessary.
 * @Note: You can customize this template as you want with inputs
 */
export class BasicGridTemplateComponent implements OnInit {
  @Input() classes: string;

  constructor() { }

  ngOnInit(): void {
  }

}
