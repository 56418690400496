import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISimpleValue } from '../interface/ISimpleValue';
import { AccountInfoStatusService } from './account-info-status.service';
import { CreditCardInfoStatusService } from './credit-card-info-status.service';
import { NotifyDateService } from './notify-date.service';
import { AccountCardService } from './account-card.service';
import { ExpressTransferService } from './express-transfer.service';
import { InsurancePolicies } from './insurance-policies-status.service';
import { NotifyAmountService } from './notify-amount.service';
import { NotifyServicePaymentService } from './notify-service-payment.service';
import { InvestmentRegisterService } from './investment-register.service';
import { Strings } from 'src/core/constants/Strings';
import { InvestmentCompaniesInfoStatusService } from './investment-companies-info-status.service';
import { Numbers } from 'src/core/constants/Constants';
import { AccountsStatusService } from './accounts-status.service';
import { InvestmentService } from './investment.service';
import { CreditsStatusService } from './credits-status.service';
import { PortabilityCardService } from './portability-card.service';
import { InsurancePolicyInfoStatusService } from 'src/app/services/insurance-policy-info-status.service';

@Injectable({
  providedIn: 'root'
})
export class NotifyClearFormService {

  private readonly clearForm: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({value: false});

  constructor(
    private readonly accountInfoService: AccountInfoStatusService,
    private readonly creditCardInfoStatusService: CreditCardInfoStatusService,
    private readonly notifyDateService: NotifyDateService,
    private  readonly accountCardService: AccountCardService,
    private readonly expressTransferService: ExpressTransferService,
    private readonly insurancePolicies: InsurancePolicies,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly notifyServicePayment: NotifyServicePaymentService,
    private readonly investmentRegisterService: InvestmentRegisterService,
    private readonly accountsStatusService: AccountsStatusService,
    private readonly investmentCompaniesInfoStatusService:InvestmentCompaniesInfoStatusService,
    private readonly investmentService: InvestmentService,
    private readonly creditsStatusService: CreditsStatusService,
    private readonly portabilityCardService: PortabilityCardService,
    private readonly insurancePolicyInfoStatusService: InsurancePolicyInfoStatusService
    ) { }


  get clearFormObservable() {
    return this.clearForm.asObservable();
  }

  get clearFormValue(){
    return this.clearForm.value;
  }

  set clearFormData(data: ISimpleValue) {
    this.clearForm.next(data);
  }

  /**
   * @deprecated Use instead clearData
   */
  clearObservablesGeneral(){
    this.accountInfoService.clearAccount();
    this.creditCardInfoStatusService.clearModel();
    this.notifyDateService.date = { date: Strings.EMPTY, isError : false };
    this.accountCardService.clearCardAccount();
    this.expressTransferService.clearExpressTransferData();
    this.insurancePolicies.clearSelectedPolicies();
    this.notifyAmountService.amount ={ amount : Strings.EMPTY };
    this.notifyServicePayment.clearServicePayment();
    this.investmentRegisterService.clearInvestmentRegister();
    this.creditCardInfoStatusService.clearModel();
    this.accountsStatusService.status = {value: false, isProductSelected: Strings.EMPTY, id: Numbers.Zero};
    this.investmentCompaniesInfoStatusService.clearModel();
  }

  clearData() {
    this.notifyAmountService.amount ={ amount : Strings.EMPTY };
    this.notifyAmountService.internationalAmount = {internationalAmount: Numbers.Zero };
    this.accountInfoService.clearAccount();
    this.investmentService.status = { value: false };
    this.creditsStatusService.empty();
    this.portabilityCardService.stateData = true;
    this.insurancePolicyInfoStatusService.clearInsurancePolicy();
  }

}
