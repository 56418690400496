import { Injectable } from '@angular/core';
import { ModalMyCardsComponent } from 'src/app/control-card/components/modal-my-cards/modal-my-cards.component';
import { ModalMyCardsService } from 'src/app/control-card/components/modal-my-cards/service/modal-my-cards.service';
import { ModalOptions } from 'src/app/interface/modal-options';
import { MapService } from 'src/app/services/map.service';
import { ModalMyCardsExtensionThreeService } from 'src/app/services/modal-my-cards-three.extension.service';
import { Constants } from 'src/core/constants/Constants';
import { InputNames } from 'src/core/constants/ControlCardConstants';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { Numbers } from 'src/core/constants/Numbers';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';

@Injectable({
  providedIn: 'root'
})

export class ModalMyCardsNipService extends ModalMyCardsService {
  constructor(
    override readonly componentExtensionThree: ModalMyCardsExtensionThreeService,
    override readonly mapService: MapService) {
    super(componentExtensionThree, mapService);
  }

  validateNip(nip: string, confirmPin: string, $event?: Event, name?: string, context?: ModalMyCardsComponent) {
    context.focusNip($event, name);

    if (nip.length > Numbers.Zero) {
      context.adminCardForm.get(InputNames.Nip).markAsTouched();
    }

    if (confirmPin.length > Numbers.Zero) {
      context.adminCardForm.get(InputNames.ConfirmPin).markAsTouched();
    }

    context.errorConfirmNip = context.componentExtensionThree.nip !== context.componentExtensionThree.confirmNip;
  }

  async nipModificationService(context?: ModalMyCardsComponent) {
    try {
      context.closeOtpModal.close();
      context.nipModificationResponse = await context.cardsAdminService
        .nipModificationService(context.getDataCardsInfo.Card.CardNumber, context.getPinValue, context.otpValue);

      if (context.nipModificationResponse.error.no === Constants.MSG_SERVICE_RESPONSE_SUCCESSFUL.toString()) {
        context.nipValue = Strings.EMPTY;
        context.confirmedPinValue = Strings.EMPTY;
        context.modalService.close();
        const options: ModalOptions = {
          modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAlertClass,
          size: Constants.MODAL_OPTIONS.SizeMd,
          centered: true
        };

        context.modalService.open(context.modalCVVChange, options);
      }
    } catch (error) {
      context.closeOtpModal.close();
      context.nipValue = Strings.EMPTY;
      context.confirmedPinValue = Strings.EMPTY;
      context.modalService.close();
      const errorDetails = Utils.getErrorMsg(error);

      const alertInstance = context.alertService.showPopupAlert(
        {
          message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
          header: Strings.MSG_POPUP_TITLE,
          btnLabel: Strings.MSG_POPUP_ACCEPT,
          imgHead: Resources.CLOSE_ICON_RED,
          btnExit: false
        },
        {
          onClose: () => alertInstance.dismiss()
        });
    }
  }

  async getPin(context?: ModalMyCardsComponent) {
    context.nipValue = context.adminCardForm.get(InputNames.Nip).value;
    context.confirmedPinValue = context.adminCardForm.get(InputNames.ConfirmPin).value;
    if (context.selectModify === Strings.ACTIVE && context.nipValue === context.confirmedPinValue
      && (context.nipValue && context.confirmedPinValue) && (context.nipValue.length && context.confirmedPinValue.length === Constants.MAX_LENGTH_NIP)) {
      context.getPinValue = this.componentExtensionThree.confirmNip;
      this.hiddenModal(true);
      const modalOptions: ModalOptions = {
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeLg,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.OtpChecksConfirmation
      }; context.closeOtpModal = context.modalService.open(context.otpModal, modalOptions);
      context.componentExtensionTwo.backdropCustom();
    }
  }
}
