import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { tap, map } from 'rxjs';
import { ModalOptions, OptionsModalToken } from 'src/app/interface/modal-options';
import { AccountCardService } from 'src/app/services/account-card.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { NotifyRetirementTypeService } from 'src/app/services/notify-retirement-type.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { ElementsHTML, Numbers, Constants } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { CardModalComponent } from 'src/app/shared/components/card-modal/card-modal.component';
import { ModalService } from 'src/app/shared/modal.service';
import { RetirementType } from 'src/core/constants/AforeConstants';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalPartialSuccesfulComponent } from 'src/app/home/components/modal-partial-succesful/modal-partial-succesful.component';
import { AforeDataService } from 'src/app/services/afore-data.service';
import { ModalEndProcessAlertComponent } from 'src/app/shared/components/modal-end-process-alert/modal-end-process-alert.component';
import { StringsRetirement } from 'src/core/constants/StringsRetirement';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ButtonProperties } from 'src/app/interface/IButtonProperties';
import { Format } from 'src/core/constants/FormatDate';
import { AforeService } from 'src/app/services/afore.service';
import { TokenService } from 'src/app/services/token.service';
import { AforeStrings } from 'src/core/constants/AforeStrings';

@Component({
  selector: 'app-modal-partial-confirm',
  templateUrl: './modal-partial-confirm.component.html',
  styleUrls: ['./modal-partial-confirm.component.css']
})
export class ModalPartialConfirmComponent {
  title: string = StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.UnemploymentRetirementTwo;
  subtitle: string = Strings.MESSAGES.DataConfirmation;
  imgCard: string = Strings.EMPTY;
  nameCard: string = Strings.EMPTY;
  card: string = Strings.EMPTY;
  modality: string = Strings.EMPTY;
  salary: string = Strings.EMPTY;
  formatDateToDay: string = Strings.EMPTY;
  buttonClicked: boolean = false;
  contentMargin: string = ElementsHTML.ContentMargin;
  toAccount: string = Strings.FROM_YOUR_ACCOUNT;
  information: string = Strings.TRANSFER.Body;
  unemploymentRetirementPartial: string = StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.UnemploymentRetirementPartial;
  procedure: string = StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.Procedure;
  modalityLabel: string = StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.Modality;
  reportedSalary: string = StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.ReportedSalary;
  dateLabel: string = StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.StartDate;
  processLabel: string = StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.StartProcess;
  btnConfirm: string = Strings.BUTTONS.ConfirmAndContinue;
  btnModify: string = Strings.BUTTONS.Modify;
  iconClose = Resources.CLOSE_CIRCLE_BLUE;
  labelQuantity: string = AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.requiredAmount;
  optionsModalToken: OptionsModalToken;
  retirement: RetirementType;
  modal: NgbModalRef;
  closeOtpModal: NgbModalRef;
  modalInstance:NgbModalRef;
  @ViewChild('Otp') otpModal: TemplateRef<HTMLElement>;
  vm$ = this.responsiveService.observe.pipe(
    tap((breakpoints) =>
      breakpoints.mobile
        ? (this.contentMargin = ElementsHTML.ContentDeductible)
        : (this.contentMargin = ElementsHTML.ContentMarginBreakpoints)
    ),
    map((breakpoints) => ({ breakpoints }))
  );
  options: ModalOptions = {
    centered:true,
    size: Constants.MODAL_OPTIONS.SizeSm,
    modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAlertEndProcess
  };

  confirmProperties: ButtonProperties = {
    doAction: () => {
      this.continue();
    }
  };

  modifyProperties: ButtonProperties = {
    doAction: () => {
      this.back();
    }
  };

  constructor(private readonly accountCardService: AccountCardService, private readonly router: Router,
    private readonly modalService: ModalService,
    private readonly notifyRetirementType: NotifyRetirementTypeService,
    private readonly aforeDataService: AforeDataService,
    private readonly currencyPipe: CurrencyPipe,
    private readonly aforeService: AforeService,
    private readonly amountService: NotifyAmountService,
    private readonly notifyRetirementTypeService: NotifyRetirementTypeService,
    private readonly responsiveService: ResponsiveService,
    private readonly tokenService: TokenService,
    private readonly notifyAmountService: NotifyAmountService) {
    this.aforeService.calculations$.subscribe((data) => {
      this.amountService.amount = {amount:  this.currencyPipe.transform(data.monto.toString())};
      this.modality = `${this.modalityLabel} ${data.tipo}`;
      this.salary = `${this.reportedSalary} ${this.currencyPipe.transform(data.salario.toString())}`;
    });
  }

  ngOnInit(): void {
    this.retirement = this.notifyRetirementTypeService.retirementTypeBs.value;
    this.accountCardService.accountCard$.subscribe(
      (data) => {
        this.imgCard = data.image;
        this.nameCard = data.cardType;
        this.card = data.cardNumber.substr(data.cardNumber.length - Numbers.Four);
      }
    );
    this.getDateToday();
    if (!this.retirement) {
      this.router.navigate([PATH.Home]);
    }
  }

  getDateToday() {
    const n3 = Numbers.Three;
    const a = formatDate(new Date(), Format.DayMonthYearISO, Constants.LOCALE_ES_MX);
    this.formatDateToDay = a.replace(a.charAt(n3), a.charAt(n3).toLowerCase());
  }

  continue() {
    this.modalService.close();
    this.optionsModalToken = {
      backModal: ModalPartialConfirmComponent,
      backClass: {
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeXl,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalPartialConfirmComponent
      },
      nextModal: ModalPartialSuccesfulComponent,
      nextClass: {
        centered: true,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal
      }
    };
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.OtpChecksConfirmation
    };
    this.closeOtpModal = this.modalService.open(this.otpModal, modalOptions);
  }

  getOtp(dataForm: string){
    this.tokenService.otp = dataForm[Constants.OTP_POSITION];
  }

  back() {
    this.buttonClicked = true;
    if (this.notifyRetirementType.retirementTypeBs.value === RetirementType.unemployment) {
      this.aforeDataService.consultStatusPDFData = { value: false };
    }
    this.modalService.close();
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal
    };
    this.modalService.open(CardModalComponent, modalOptions);
    this.notifyRetirementType.setIsAfore = true;
  }

  unexpectedShutdown($event:boolean) {
    this.buttonClicked = $event;
  }

  close() {
    this.modalService.close();
    const OptionsConfirm = {
      size: Constants.MODAL_OPTIONS.SizeXl,
      centered: true,
       modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalPartialConfirmComponent
    };
    this.modal = this.modalService.open(ModalEndProcessAlertComponent, this.options);
    this.modal.result.then((e) => {
      this.modalService.open(ModalPartialConfirmComponent, OptionsConfirm);
    });
  }

  @HostListener('window:popstate')
  onBack() {
    this.modalInstance?.close();
    this.aforeService.aforeInformation = null;
    this.accountCardService.clearCardAccount();
    this.notifyAmountService.amount = { amount: Strings.EMPTY };
  }

}
