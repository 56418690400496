import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISimpleValue } from 'src/app/interface/ISimpleValue';

@Injectable({
  providedIn: 'root'
})
export class NotifyServicePaymentService {
  isServicePayment : ISimpleValue = {value: false};
  private readonly _isServicePayment: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({value: false});
  private readonly _isTypeOperation: BehaviorSubject<string> = new BehaviorSubject<string>('');
  constructor() { }

  get servicePaymentData$() {
    return this._isServicePayment.asObservable();
  }

  get servicePaymentData(){
    return this._isServicePayment.getValue();
  }

  set servicePaymentData(data: ISimpleValue) {
    this._isServicePayment.next(data);
  }

  get typeOperation$() {
    return this._isTypeOperation.asObservable();
  }

  get typeOperation() {
    return this._isTypeOperation.getValue();
  }

  set typeOperation(data: string) {
    this._isTypeOperation.next(data);
  }

  clearServicePayment() {
    this.isServicePayment = {value: false};
    this._isServicePayment.next(this.isServicePayment);
  }
}
