<ng-container *ngIf="vm$ | async as vm">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="d-flex flex-row pl-2 justify-content-center header-l main-color-label"
          [ngClass]="{'pt-2' : vm.accountModify.type === nonAgendableType
          || vm.accountModify.type === accountAddress}">
          Modificar cuenta
        </div>
        <div class="d-flex flex-row pt-2 pl-2 justify-content-center subtitle-modal body-m secondary-color"
          [ngClass]="vm.accountModify.type === nonAgendableType
          || vm.accountModify.type === accountAddress ? 'pt-0' : 'pt-2'">
          Confirma los datos
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isServiceAgenda(vm.accountModify.type); else internationals">
    <div class="row" *ngIf="vm.accountModify.type != nonAgendableType">
      <div>
        <div id="date-application">
          <label class="body-m header-xs col-12 mt-3"
          [ngClass]="{
            'mb-3': vm.accountModify.type !== ownAccounts && vm.accountModify.type !== accountAddress,
            'mb-0': vm.accountModify.type === ownAccounts || vm.accountModify.type === accountAddress
          }"
          >Datos del destinatario</label>
          <div>
            <label class="body-s main-color-label">{{vm.accountModify.account}}</label>
            <label class="detail-s col-12">{{accountLabel}}</label>
            <hr class="my-2">
          </div>
          <div>
            <label class="body-s mb-1 main-color-label">{{vm.accountModify.name}}</label>
            <label class="detail-s col-12">Nombre del servicio</label>
            <hr class="my-2">
          </div>
        </div>
      </div>
      <div>
        <div id="date-application">
          <div>
            <label class="body-s main-color-label">{{vm.accountModify.maximumLimit|currency}}</label>
            <label class="detail-s col-12"> Límite diario máximo </label>
            <hr class="my-2">
          </div>
        </div>
        <div id="date-application" *ngIf="vm.accountModify.alias && vm.accountModify.alias.length > 0">
          <div>
            <label class="word-wrap body-s main-color-label">{{vm.accountModify.alias}}</label>
            <label class="detail-s col-12"> Alias </label>
            <hr class="my-2">
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="vm.accountModify.type == nonAgendableType">
      <div>
        <div id="date-application">
          <label class="body-m header-xs col-12 nonagendable-margin"> Datos del destinatario </label>
          <div>
            <label class="body-s main-color-label">{{vm.accountModify.name}}</label>
            <label class="detail-s mt-1 col-12">Nombre del servicio</label>
            <hr class="my-2">
          </div>
        </div>
        <div id="date-application">
          <label class="body-m header-xs col-12 nonagendable-margin"> Límite </label>
          <div>
            <label class="body-s main-color-label">{{formatLimit|currency }}</label>
            <label class="detail-s col-12"> Límite diario máximo </label>
            <hr class="my-2">
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #internationals>
    <div>
      <div>
        <div id="date-application">
          <label class="body-m header-xs col-12 style-height-zero"
          [ngClass]="{
            'mt-4 m-btn': vm.accountModify.type !== 1 && vm.accountModify.type !== 4}">Datos del destinatario</label>
          <div>
            <label class="body-s main-color-label">
              {{vm.accountModify.type === brokerageHouse ? vm.accountModify.contract : vm.accountModify.account}}
            </label>
            <label class="detail-s col-12">{{labelCard}}</label>
            <hr class="my-2">
          </div>
          <div *ngIf="vm.accountModify.alias && vm.accountModify.alias.length > 0">
            <label class="body-s word-wrap main-color-label">{{vm.accountModify.alias}}</label>
            <label class="detail-s col-12"> Alias </label>
            <hr class="my-2">
          </div>
        </div>
      </div>
      <div>
        <div id="date-application">
          <label [ngClass]="{'mt-none':!vm.breakpoints.desktop}"
          class="body-m header-xs mt-2 m-btn col-12 style-height-zero"> Límite
        </label>
          <div>

            <label class="body-s main-color-label">{{vm.accountModify.maximumLimit|currency}}</label>
            <label class="detail-s col-12"> Límite diario </label>
            <hr class="my-2">
          </div>
        </div>
        <div id="date-application" *ngIf="vm.accountModify.email && vm.accountModify.email.length > 0">
          <label class="body-m header-xs mt-2 m-btn col-12 style-custom-contact">
            Contacto para notificaciones
          </label>
          <div class="word-wrap">
            <label class="body-s main-color-label">{{vm.accountModify.email}}</label>
            <label class="detail-s col-12"> Email </label>
            <hr class="my-2">
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
