import { AssetsType } from '../../core/constants/Constants';
import { ServiceOptions } from './base-service.service';

export abstract class IBaseService{

  /**
   * Obtiene URL de recursos
   * @param type Tipo de recurso
   */
  public abstract getUrlAssets(type: AssetsType): string;

  /**
   * Realiza petición POST
   * @param request Datos para consumo
   * @param config Configuración de consumo
   */
  public abstract genericPost<T>(request: any, config: ServiceOptions): Promise<T>;

  /**
   * Realiza petición GET
   * @param request Datos para consumo
   * @param config Configuración de consumo
   */
  public abstract genericGet<T>(request: any, config: ServiceOptions): Promise<T>;

  /**
   * Realiza petición GET
   * @param request Datos para consumo
   * @param config Configuración de consumo
   */
  public abstract genericPut<T>(request: any, config: ServiceOptions): Promise<T>;
}
