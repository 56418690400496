import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { Constants } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Utils } from 'src/core/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class HomeGuard  {
  private readonly partialGuard = [ PATH.LoginToken, PATH.ValidateToken, PATH.LoginPassword, PATH.UserLogin ];

  constructor(private readonly storageService: StorageService, 
      private readonly router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let isError = false;
    let isValidNavigation = false;
    if(this.router.getCurrentNavigation()?.extras?.state){
         isError = this.router.getCurrentNavigation()?.extras?.state[Constants.NAVIGATION_PARAMETERS.Alert] != null; 
         isValidNavigation= this.router.getCurrentNavigation()?.extras?.state[Constants.NAVIGATION_PARAMETERS.ValidNavigation];
    }
      if (Utils.validateNavigationGuard(state.url, this.partialGuard,this.storageService.getUser()?.personId,this.storageService.getSession(), false)) {
          if (this.storageService.getUser()?.personId == null && this.storageService.getSession(true) != null && !isError && !isValidNavigation) {
              this.router.navigate([PATH.Login]);
              return false;
          }
          return true;
      }
      this.router.navigate([PATH.Home]);
      return false;
  }

}