export const Regex = {
  ValidateConcept: /^([a-z]*[A-Z]*[áéíóúÁÉÍÓÚñÑ]*[\s]*[\d]*[!"#$%&'()*+,.:;?@\-_¿¡\\\/]*)+$/,
  DifferentFromConcept: /[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s\d!"#$%&'()*+,.:;?@_\\¿¡/-]+/g,
  RegexWithoutSymbols: /[^a-zA-ZñÑ0-9 ]+/g,
  DifferentFromPurposePayment: /[^.,/:'()+\-{?}0-9a-zA-Z ]+/,
  DenyNoAccents: /[^a-zA-Z0-9ñÑ ]+/g,
  TwoOrMoreSpaces: /\s{2,}/g,
  RegexSearchDirectDebitAfore: /[^a-zA-Z0-9ñÑ ./]+/g,
  SearchCodeflexActivity: /^( )?[^a-zA-Z0-9()*$./)]|[^a-zA-Z0-9 ()*$./)]+/g,
  DenyEmoji: /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF])+|(\u200D[\uFE0F\uD83C-\uDBFF\uDC00-\uDFFF]?)|([\uD800-\uDBFF][\uDC00-\uDFFF])/gu,
  RegexShares: /^[^1-9]|[^0-9]/g,
  RegexFormatNumber: /\B(?=(\d{3})+(?!\d))/g,
  AlphanumericAccents: /[^a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ ]|^\s+|\s(?=\s)|-/g,
  AlphabeticAccents: /[^a-zA-ZñÑáéíóúÁÉÍÓÚ ]|^\s+|\s(?=\s)|-/g,
  ReplaceDots: /(\.[0-9]+)\./g,
  DecimalsDot: /[^0-9.]|^\.+|\.(?=\.)|-/g,
  CaptureGroup: '$1',
  ValidateIP: /^(([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])(\.(?!$)|(?=$))){4}$/,
  DateFilter: /[/\s:]/
};
