<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="pagination$ | async as paginationObserver">
    <div #ModalContainer>
      <header #Header>
        <cross-button-modal-template [parameters]="btnCrossClose"></cross-button-modal-template>
        <div class="d-flex justify-content-start ms-3 flex-column mb-3">
          <div class="mb-2" *ngIf="paginationObserver.data.length !== zero || errorStatus && errorMessage.length > zero
            || dataEmpty">
            <img [src]="imageReturnBtn | cdnImage" alt="<" (click)="close()" width="auto" height="auto"
              class="cursor-pointer">
          </div>
          <div class="mb-1 header-m federal-tax-receipt-titles">
            {{title}}
          </div>
          <div class="body-s federal-tax-receipt-titles pe-3">
            {{subtitle}}
          </div>
        </div>
        <div class="container-options-list">
          <custom-select [label]="options[zero].optionsConfig.label" [options]="this.accountListOptions"
            [showError]="options[zero].optionsConfig.changeError()" [disabled]="progressCancelStatus"
            (isOptionSelected)="setChange($event,options[zero].optionsConfig)"
            [customValue]="options[zero].optionsConfig.change()"></custom-select>
          <custom-select [label]="options[one].optionsConfig.label" [options]="periodListOptions"
            [showError]="options[one].optionsConfig.changeError()" [externalReset]="resetCustomSelect"
            [disabled]="!!!periodListOptions.length || progressCancelStatus"
            (isOptionSelected)="setChange($event,options[one].optionsConfig)"
            [customValue]="options[one].optionsConfig.change()"></custom-select>
        </div>
      </header>
      <main #Container>
        <div *ngIf="paginationObserver.data.length !== zero" class="m-3 federal-tax-receipt-titles"
        [ngClass]="vm.mobile ? 'header-xs':'header-s'">
          {{labelDeclarations + today}}
        </div>
        <div class="container-table mx-3 mt-3 mb-0" *ngIf="paginationObserver.data.length !== zero">
          <div id="table-scroll" class="d-flex responsive-table scroll moz-scroll">
            <table class="table">
              <thead>
                <tr>
                  <th id="radio-button" [class.d-none]="hiddenColumn">
                  </th>
                  <th id="{{header.id}}"
                  class="text-nowrap text-center detail-m-highlight federal-tax-receipt-col-table"
                  *ngFor="let header of dynamicTitle">
                    {{header.title}}
                  </th>
                </tr>
              </thead>
              <tbody class="text-center text-nowrap">
                <tr *ngFor="let data of paginationObserver.data">
                  <td [class.d-none]="hiddenColumn">
                    <div [class.disabled]="validState(data)" [hidden]="validState(data)">
                      <label for="check">
                        <input id="check" type="radio" [checked]="selectedOption === data" (click)="changeStyle(data)"
                          [ngClass]="{'selected': selectedOption === data}">
                      </label>
                    </div>
                  </td>
                  <td [class.d-none]="hiddenColumn">
                    <div [hidden]="validState(data)">
                      <div class="d-flex justify-content-center">
                        <img [src]="imgDownload | cdnImage" alt="image" width="auto" height="auto"
                        class="cursor-pointer" (click)="federalTaxPaymentVoucher(data.idImpuestoFederal)">
                      </div>
                    </div>
                  </td>
                  <td class="detail-m">{{changeFormatDate(data.fecha) | customDate:'dd/Month/yyyy'}}</td>
                  <td class="detail-m">{{data.referencia}}</td>
                  <td class="detail-m">{{data.numeroOperacion}}</td>
                  <td class="detail-m">{{data.importe | currency}}</td>
                  <td class="detail-m">{{data.estatus}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="paginationObserver.data.length !== zero">
            <app-pagination></app-pagination>
          </div>
        </div>
        <ng-container *ngIf="errorStatus && errorMessage.length > zero">
          <div class="container-exception">
            <app-error-exception
              [showButtons]="false" [errorMessage]="errorMessage">
            </app-error-exception>
          </div>
        </ng-container>
        <ng-container *ngIf="dataEmpty">
          <div class="message-table-empty mx-3">
            <label class="title header-l color-label">{{messageEmpty}}</label>
          </div>
        </ng-container>
      </main>
      <footer #Footer class="mx-3" [ngClass]="paginationObserver.pagination.totalPages === one && 'mt-4'">
        <div class="d-flex justify-content-center primary-container py-3"
         *ngIf="paginationObserver.data.length === zero && !errorStatus && errorMessage.length === zero &&
          !dataEmpty">
          <app-template-primary-button class="btn-primary-federal-tax" [properties]="btnContinue" [showIcon]="true">
            {{labelButtonContinue}}
          </app-template-primary-button>
        </div>
        <div class="d-flex primary-container"
          [ngClass]="vm.mobile ? 'justify-content-center pt-0 pb-3': 'justify-content-end pt-0 pb-3'"
          *ngIf="paginationObserver.data.length !== zero && selectedOption">
          <app-template-primary-button class="btn-primary-federal-tax" [properties]="btnCancel" [showIcon]="false">
            {{labelButtonCancel}}
          </app-template-primary-button>
        </div>
      </footer>
    </div>
  </ng-container>
</ng-container>

