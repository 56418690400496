import { PathModel } from 'src/app/models/PathModel';
import { TToken } from 'src/app/interface/TToken';
import { PolicyReceiptsSettleModel } from 'src/app/interface/PolicyReceiptsSettleModel';

export type IPayPoliciesIFMRequest = {
    InputData: {
        IdSession : string,
        AmbSeg : string,
        ImporteDocumento : string,
        TipoTDC? : string,
        TipoCambio? : string,
        CuentaCargo : string,
        CuentaProductoID : string,
        NumeroTarjeta? : string,
        NumeroMeses? : string,
        pTarjerenofecha?:string,
        ReciboLiquidarList: PolicyReceiptsSettleModel[]
    };
    CustInfo: {
        CustID : string,
        Branch? : string,
        Language? : string,
        Organization? : string,
    };
    usaSesion? : string,
    IPServer? : string,
    MedioAcceso? : string,
    Token? : TToken
}

export class PayPoliciesIFMRequest{
    constructor(public data: IPayPoliciesIFMRequest){}

    get endpoint(): PathModel{
        return PathModel.PayPoliciesIFM; 
    }
}