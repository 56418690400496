import { PathModel } from 'src/app/models/PathModel';

interface IPaySatVoucherRequest{
  IdSesion: string;
  LineaCaptura: string;
  IdCuentaProducto: number;
  NumeroCuenta: string;
  IdTransaccion: string;
  FechaHora: string;
  Monto: string;
}

export class PaySatVoucherRequest{
  constructor(public data: IPaySatVoucherRequest){}

  get endpoint(): PathModel{
    return PathModel.GetVoucherSat;
  }
}
