import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebCardLayoutService {

  private readonly _isWebCard: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly _formTerms: BehaviorSubject<FormGroup> = new BehaviorSubject<FormGroup>(null);

  constructor() { }

  get isWebCard$(): Observable<boolean> {
    return this._isWebCard.asObservable();
  }

  set isWebCard(isWebCard: boolean) {
    this._isWebCard.next(isWebCard);
  }

  get formTerms$(): Observable<FormGroup> {
    return this._formTerms.asObservable();
  }

  set formTermsData(formTerms: FormGroup) {
    this._formTerms.next(formTerms);
  }

  get formTermsBs(): BehaviorSubject<FormGroup> {
    return this._formTerms;
  }
}
