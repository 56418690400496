import { Injectable } from '@angular/core';
import { ConsultInsurancePolicyCoverRequest } from 'src/app/interface/dto/TConsultInsurancePolicyCoverRequest';
import { TConsultInsurancePolicyCoverResponse } from 'src/app/interface/dto/TConsultInsurancePolicyCoverResponse';
import { GetClausulesRequest } from 'src/app/interface/Get-clausules-gmm.request';
import { GetClausulesResponse } from 'src/app/interface/Get-clausules-gmm.response';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { InsurancePolicyInfoStatusService } from 'src/app/services/insurance-policy-info-status.service';
import { StorageService } from 'src/app/services/storage.service';
import { InputTypes } from 'src/core/constants/Constants';
import { ConsultInsuranceCfdiRequest } from 'src/app/interface/dto/TConsultInsuranceCfdiRequest';
import { UserAccessService } from 'src/app/services/user-access.service';
import { TConsultInsuranceCfdiResponse } from 'src/app/interface/dto/TConsultInsuranceCfdiResponse';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
  providedIn: 'root'
})
export class InsurancePolicyCoverService {

  constructor(
    private readonly stateIInsurancePolicyPlInfo: InsurancePolicyInfoStatusService,
    private readonly baseService: IBaseService,
    private readonly storage: StorageService,
    private readonly generalInformation: UserAccessService
  ) { }

  async consultInsurancePolicyCover(): Promise<string>{
    const currentPolicy = this.stateIInsurancePolicyPlInfo.insurancePolicy;
    const request = new ConsultInsurancePolicyCoverRequest({
      Emisor: currentPolicy.Emisor,
      Carpeta: currentPolicy.Carpeta,
      Poliza: currentPolicy.Cis,
      Ramo: currentPolicy.Ramo?.toUpperCase(),
      IdSession: this.storage.getSession()
    });
    const response = await this.baseService.genericPost<TConsultInsurancePolicyCoverResponse>(request, {isSOA: true});
    return response.PolizaB64;
  }

  async consultClausulesPolicy(): Promise<string>{
    const currentPolicy = this.stateIInsurancePolicyPlInfo.insurancePolicy;
    const request = new GetClausulesRequest({ 
      codigoEmisor: currentPolicy.Emisor,
      numeroCarpeta: currentPolicy.Carpeta,
      ramo: currentPolicy.Ramo,
      codigoReporte: InputTypes.clausules  });
    const response = await this.baseService.genericPost<GetClausulesResponse>(request, {isSOA: true});
    return response.datos.pdfBase64;
  }

  async downloadCfdi():Promise<TConsultInsuranceCfdiResponse>{
    const currentPolicy = this.stateIInsurancePolicyPlInfo.insurancePolicy;
    await this.generalInformation.getUserInformation( {reload: true });
    const generalInfo = this.generalInformation.userInformation;
    const request = new ConsultInsuranceCfdiRequest({
      idSesion: this.storage.getSession(),
      emisor: currentPolicy.Emisor,
      nivelCobro: currentPolicy.NivelCobro,
      ramo: currentPolicy.Ramo?.toLocaleUpperCase(),
      numeroDocumento: currentPolicy.Cis,
      correo: !generalInfo.email ? Strings.EMPTY : generalInfo.email
    });
    return this.baseService.genericPost<TConsultInsuranceCfdiResponse>(request, {isSOA: true});
  }

}
