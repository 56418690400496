import { Resources } from 'src/core/constants/Resources';

export class AforeResources {
  static readonly IMPORT_WHITE_AFORE = 'import-white.svg';
  static readonly AFORE_ACCOUNT_STATEMENT = 'EdoCuenta_Inbursaabril2023.pdf';
  static readonly AFORE_DETAILS_MOVEMENTS = 'detalle_movimientos.pdf';
  static readonly AFORE_BALANCE_CERTIFICATION = 'Certificacion_Saldos.pdf';
  static readonly IMPORT_BLUE = 'import-blue.svg';
  static readonly EXPORT_B = 'export_b.svg';
  static readonly CALENDAR = 'calendar.svg';
  //PDF's AFORE
  static readonly CDN_URL_PDF = (value: number | string) => `${Resources.PATH_CDN}/images/portal/PDF/${value}`;

  static readonly PROOF_OF_UNEMPLOYMENT ='Constancia_desempleo.pdf';
  static readonly RETIREMENT_UNEMPLOYMENT='Solicitud_Retiro_Desempleo.pdf';
  static readonly RETIREMENT_MARRIAGE = 'Solicitud_retiro_matrimonio.pdf';
}
