import { Validators } from '@angular/forms';
import { Char, FormValidations, Regex } from 'src/core/constants/Constants';
import { FormRegex } from 'src/core/constants/FormConstants';
import { LocalTaxesConstants } from 'src/core/constants/LocalTaxesConstants';
import { Strings } from 'src/core/constants/Strings';

export class LocalTaxesDynamicValidatorService {
  static readonly _basicValidation = {
    name: FormValidations.Required,
    validator: Validators.required,
    message: Strings.GENERIC_ERRORS.Required
  };
  static readonly validAmounts=(min:number)=>({
    name: FormValidations.Min,
    validator: Validators.min(min),
    message:Strings.MIN_AMOUNT_MSG(`${Char.CurrencySymbol}${min}`)
  });
  static readonly minLengthValidation = (minLength: number) => ({
    name: FormValidations.MinLength,
    validator: Validators.minLength(minLength),
    message: Strings.MINIMUM_CHARACTERS(minLength)
  });
  static readonly maxLengthValidation = (maxLength: number) => ({
    name: LocalTaxesConstants.FormValidations.MaxLength,
    validator: Validators.maxLength(maxLength),
    message: Strings.GENERIC_ERRORS.MaxLengthError(maxLength)
  });
  static readonly validCURP = {
    name: FormValidations.Pattern,
    validator: Validators.pattern(Regex.CURP),
    message: LocalTaxesConstants.ERROR_CURP
  };
  static readonly validRFC = {
    name: FormValidations.Pattern,
    validator: Validators.pattern(FormRegex.ValidateNoGenericRFCWithoutHomoclave),
    message: LocalTaxesConstants.ERROR_RFC
  };
}
