import { PathModel } from 'src/app/models/PathModel';

type IGetBrokerageHouseAgendasRequest = {
  IdSession: string
};

export class GetBrokerageHouseAgendasRequest {
  constructor(public data: IGetBrokerageHouseAgendasRequest){ }

  get endpoint(): PathModel {
      return PathModel.GetBrokerageHouseAgendas;
  }
}
