import { AfterViewInit, Directive, Input } from '@angular/core';
import { Numbers } from 'src/core/constants/Constants';

@Directive({
  selector: '[scrollTop]'
})
export class ScrollToDirective implements AfterViewInit {

  constructor() { }

  @Input() time: number = null;
  @Input() isScrollTop: boolean = true;

  baseTime: number = Numbers.OneHundred;

  ngAfterViewInit(): void {
    if (this.isScrollTop){
      if (this.time === null) {
        setTimeout(() => { window.scrollTo(Numbers.Zero, Numbers.Zero); }, this.baseTime);
      } else {
        setTimeout(() => { window.scrollTo(Numbers.Zero, Numbers.Zero); }, this.time);
      }
    }
  }
}
