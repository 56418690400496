import { PathModel } from 'src/app/models/PathModel';

interface IGetDepositRequest {
  idCuentaProductoOrigen     : number;
  idCuentaProductoDestino    : number;
  monto                      : number;
}

export class GetDepositRequest{
  constructor (public data: IGetDepositRequest){}

  get endpoint(): PathModel {
    return PathModel.DepositCodeFlex;
  }
}
