import { Component, HostListener, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Resources } from 'src/core/constants/Resources';

@Component({
  selector: 'app-modal-account-statement',
  templateUrl: './modal-account-statement.component.html',
  styleUrls: ['./modal-account-statement.component.css']
})
export class ModalAccountStatementComponent implements OnInit {
  showModal: boolean;
  modalInstance: NgbModalRef;
  frameClose: string = Resources.CLOSE_CIRCLE_BLUE;

  vm$ = this.responsiveService.observe.pipe(
    tap((breakpoints) => {
      if (!breakpoints.mobile) {
        this.close();
      }
    })
    );

  constructor(private readonly responsiveService: ResponsiveService
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.modalInstance?.close();
  }

  @HostListener('window:popstate')
  onBack() {
    this.close();
  }
}
