import { ChangeDetectionStrategy, Input, Component } from '@angular/core';
import { ClassHTML } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';

@Component({
  selector: 'app-info-label',
  templateUrl: './info-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoLabelComponent {

  @Input() head: string | number = Strings.EMPTY;
  @Input() body: string = Strings.EMPTY;
  @Input() showHr? = true;
  @Input() classes? = 'pt-3 ps-3';
  @Input() margin? = 'm-2';
  @Input() headClass? = 'body-s color-details-s';
  @Input() bodyClass? = 'detail-s color-details-s';
  @Input() specialFont: string = Strings.EMPTY;
  @Input() tooltip: boolean = false;

  styles: Partial<CSSStyleDeclaration> = {
    display: ClassHTML.WebkitBox,
    webkitBoxOrient: ClassHTML.BoxOrientVertical,
    overflowWrap : ClassHTML.AnyWhere
  };

  constructor() { }

}
