export class BureauAlertsStrings {
    static readonly BUREAU_ALERTS = {
        BureauAlerts: {
            Otp: 'otp',
            Email1Vc: 'email1Vc',
            Title: 'Alertas Buró',
            ParagraphOne: 'Recuerda, para clientes Inbursa como tú, la contratación del servicio Alertas Buró no genera ninguna comisión.',
            Items: ['Las Notificaciones Alértame las recibirás en tu correo electrónico:'],
            LabelChangeEmail: 'Cambiar correo electrónico',
            LabelEmail: 'Correo',
            Required: 'Requerido',
            InvalidEmail: 'Email no válido.',
            DifferentData: 'Los datos no son iguales.',
            Hire: 'Contratar',
            Continue: 'Continuar'
        },
        FreeBureauAlert: {
            Title: 'Alertas Buró ¡Sin costo!',
            ParagraphOne: 'Agradecemos y reconocemos tu confianza al permitirnos ser tu Institución' +
                ' Financiera, es por ello que te ofrecemos <strong>SIN COSTO</strong> el servicio <strong>Alertas Buró</strong>, que' +
                ' brinda Buró de Crédito con el fin de mantenerte informado de tu situación crediticia y combatir el robo de identidad:',
            ParagraphTwo: '<strong>Notificaciones Alértame</strong>, alertas ilimitadas de tu historial crediticio por 1 año',
            Items: [
                'Créditos nuevos y eliminados',
                'Nuevos domicilios, particular o de trabajo',
                'Consultas a tu historial',
                'Cambios en tus datos generales',
                'Créditos en situación especial',
                'Créditos en aclaración',
                'Aclaraciones resueltas'],
            ParagraphThree: '<strong>4 Reportes Mi Buró</strong> con toda tu información de Buró de Crédito',
            ParagraphFour: '<strong>4 Mi Score</strong>, con la puntuación del manejo de tus créditos',
            Authorization: 'Autorizo a Buró de Crédito consultar mi historial crediticio de acuerdo con los artículos 13 y 28 de' +
                ' la Ley para Regular las Sociedades de Información Crediticia. Declaro que conozco la naturaleza y ' +
                ' alcance de la información que se solicitará.',
            BureauUrl: 'www.burodecredito.com.mx',
            HrefBureau: 'https://www.burodecredito.com.mx/',
            ContractBureau: 'Contrato Alertas Buró',
            Hire: 'Contratar',
            Cancel: 'Cancelar'
        },
        SuccessfulRequest: {
            Title: 'Solicitud exitosa',
            LabelInvoice: 'Folio:',
            Info: 'En solo unos días el servicio estará activo y Buró de Crédito te enviará vía correo ' +
                'electrónico el Usuario y Contraseña para ingresar al Portal Buró',
            Message: 'Gracias por tu preferencia',
            HrefBureau: 'https://www.burodecredito.com.mx/',
            BureauUrl: 'https://www.burodecredito.com.mx',
            Ready: 'Listo'
        }
    };
}