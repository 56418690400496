import { ICardNumberInfo } from 'src/app/interface/dto/ICardNumberInfo';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ControlCardConstants } from 'src/core/constants/ControlCardConstants';
import { Strings } from 'src/core/constants/Strings';
import { CardCancellationRequest } from 'src/app/interface/dto/CancelationCardRequest';
import { ProductUtils } from 'src/core/utils/ProductUtils';
import { StorageService } from 'src/app/services/storage.service';
import { CardCancellationResponse } from 'src/app/interface/dto/CancelationCardResponse';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { CardRepositionRequest } from 'src/app/interface/dto/RepositionCardRequest';
import { RepositionCardResponse } from 'src/app/interface/dto/RepositionCardResponse';
import { UserInfoRequest } from 'src/app/interface/dto/UserInfoRequest';
import { UserInfoResponse } from 'src/app/interface/dto/UserInfoResponse';
import { BiometricDataRequest } from 'src/app/interface/dto/BiometricDataRequest';
import { BiometricDataResponse } from 'src/app/interface/dto/BiometricDataResponse';

@Injectable({
  providedIn: 'root'
})

export class StateCardNumberInfoService {
  private _addressRepositionCard: string;
  private _isCardCancelling: boolean;
  private _repositionByBranch: boolean;
  private _reasonCancellation: string;
  private _cardAccount: string;
  private _idBranch: string;
  private readonly _refreshCards = new BehaviorSubject<boolean>(false);
  private readonly _cardNumber: BehaviorSubject<ICardNumberInfo> = new BehaviorSubject<ICardNumberInfo>({
    CardDataInfo: {
      CardNumber: Strings.EMPTY,
      SourceCard: Strings.EMPTY,
      CardLocks: {},
      NipInfo: Strings.EMPTY,
      img: Strings.EMPTY,
      type: Strings.EMPTY,
      account: Strings.EMPTY
    }
  });

  constructor(
    private readonly storage: StorageService,
    private readonly baseService: IBaseService,
    private readonly storageService: StorageService) { }

  get cardNumberInfo$() {
    return this._cardNumber.asObservable();
  }

  get cardNumberInfo() {
    return this._cardNumber.getValue();
  }

  set cardNumberInfo(data: ICardNumberInfo) {
    this._cardNumber.next(data);
  }

  get refreshCards$() {
    return this._refreshCards.asObservable();
  }

  get refreshCards() {
    return this._refreshCards.getValue();
  }

  set refreshCards(data: boolean) {
    this._refreshCards.next(data);
  }

  get idBranch(): string {
    return this._idBranch;
  }

  set idBranch(data: string) {
    this._idBranch = data;
  }

  get cardAccount(): string {
    return this._cardAccount;
  }

  set cardAccount(data: string) {
    this._cardAccount = data;
  }

  get reasonCancellation(): string {
    return this._reasonCancellation;
  }

  set reasonCancellation(data: string) {
    this._reasonCancellation = data;
  }

  get isCardCancelling(): boolean {
    return this._isCardCancelling;
  }

  set isCardCancelling(data: boolean) {
    this._isCardCancelling = data;
  }

  get repositionByBranch(): boolean {
    return this._repositionByBranch;
  }

  set repositionByBranch(data: boolean) {
    this._repositionByBranch = data;
  }

  get addressRepositionCard(): string {
    return this._addressRepositionCard;
  }

  set addressRepositionCard(data: string) {
    this._addressRepositionCard = data;
  }

  async confirmCancelationCard(otp: string): Promise<CardCancellationResponse> {
    const selectedCard = this.cardNumberInfo;
    const isDebitCard = this.cardNumberInfo.CardDataInfo.SourceCard === ControlCardConstants.IDENTIFIER_DEBIT;
    const cancelCardRequest = new CardCancellationRequest({
      CancelarTarjeta: true,
      MotivoCancelacion: !isDebitCard ?
        ProductUtils.getSourceCancellation(this.reasonCancellation) : this.reasonCancellation,
      IdSession: this.storage.getSession(),
      Tarjeta: selectedCard.CardDataInfo.CardNumber,
      Cuenta: this.cardAccount,
      Sucursal: ControlCardConstants.BRANCH_OFFICE,
      Source: selectedCard.CardDataInfo.SourceCard,
      Otp: otp
    });
    return this.baseService.genericPost<CardCancellationResponse>(cancelCardRequest, { isSOA: true });
  }

  async repositionCardRequest(reasonCancellation: string, isBranch: boolean): Promise<RepositionCardResponse> {
    const geolocation = this.storage.getGeolocation();
    const selectedCard = this.cardNumberInfo;
    const cardRepositionRequest = new CardRepositionRequest({
      Account: this.cardAccount,
      CardNumber: selectedCard.CardDataInfo.CardNumber,
      Changes: {
        C_Paremeter: []
      },
      CustomerId: Strings.EMPTY,
      Delivery: ControlCardConstants.DEFAULT_DELIVERY,
      IdCuentaProducto: Strings.EMPTY,
      IdsPersona: [],
      NewState: ProductUtils.getSourceCancellation(this.reasonCancellation),
      NickName: Strings.EMPTY,
      Source: selectedCard.CardDataInfo.SourceCard,
      pEnvio: parseInt(reasonCancellation),
      pSucursal: parseInt(isBranch ? this.idBranch : ControlCardConstants.BRANCH_OFFICE)
    });
    return this.baseService.genericPost<RepositionCardResponse>(cardRepositionRequest, {
      isSOA: true, urlOptions: [
        geolocation.latitude,
        geolocation.longitude,
        this.storageService.getSession()
      ]
    });
  }

  async repositionCardByAdressRequest(): Promise<UserInfoResponse> {
    const cardRepositionRequest = new UserInfoRequest({
      IdSession: this.storageService.getSession()
    });
    return this.baseService.genericPost<UserInfoResponse>(cardRepositionRequest, { isSOA: true });
  }

  async getBiometricData(): Promise<BiometricDataResponse> {
    const cardRepositionRequest = new BiometricDataRequest({
      IdSession: this.storageService.getSession()
    });
    return this.baseService.genericPost<BiometricDataResponse>(cardRepositionRequest, { isSOA: true });
  }
}
