import { PathModel } from 'src/app/models/PathModel';

export interface IGetIFMPromotionCDMXRequest{
  idSesion: string;
}

export class GetIFMPromotionCDMXRequest{
  constructor(public data: IGetIFMPromotionCDMXRequest){}
  get endpoint():PathModel{
    return PathModel.GetIFMPromotionCDMX;
  }
}
