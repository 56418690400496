<ng-container *ngIf="vm$ | async as vm">
  <div id="modal-request-confirmation">
    <div #ModalContainer></div>
    <div class="container-fluid p-3" id="successful-operation">
      <div class="d-flex justify-content-end" *ngIf="showButtonClose">
        <img role="button" aria-label="Close" width="24" height="24" [src]="iconClose | cdnImage" alt="Close"
          class="close" (click)="modify(ModifyReception)" ngbAutofocus (keydown.enter)="$event.preventDefault()">
      </div>
      <ng-container *ngIf="renovation">
        <div class="d-flex flex-column justify-content-center align-items-center"
          [ngClass]="{'m-4':!vm.breakpoints.mobile}">
          <img [src]="iconCheck | cdnImage" class="icon-check" width="56" height="56" alt="icon-check" />
          <div class="pt-3 pb-2" [ngClass]="vm.breakpoints.mobile ? 'header-m':'header-l'">Renovación Solicitada</div>
          <div class="pb-3 date">{{ completeDate }}h</div>
          <app-shared-buttons [fileName]="'Renovación'" [hideDownload]="false" [download]="true" [hideMail]="true">
          </app-shared-buttons>
        </div>
        <div *ngIf="cardType!=undefined">
          <div class="default-color mt-3">
            <div class="pb-2 header-s default-color"
            [ngClass]="!vm.breakpoints.mobile ? 'header-s':'header-xs'">Desde tu cuenta</div>
            <div class="d-flex flex-row gap-3 align-items-center">
              <div *ngIf="!vm.breakpoints.mobile">
                <img [src]="bankCard" alt="image" width="auto" height="auto" class="col-product-img">
              </div>
              <div class="d-flex flex-column" *ngIf="cardNumber!=undefined">
                <span class="body-s default-color">{{cardNumber | maskAccount : 4}}</span>
                <span class="detail-s">{{cardType}}</span>
              </div>
            </div>
            <div class="custom-container pb-2">
              <hr class="line" />
              <div class="d-flex flex-column justify-content-start align-text">
                <div class="header-s mb-4 default-color"
                [ngClass]="!vm.breakpoints.mobile ? 'header-s':'header-xs'">Información de la operación</div>
                <label class="body-s default-color" *ngIf="cardType!=undefined">
                  <text-ellipsis [text]="address" [classes]="'main-color-label'" [lines]="2"></text-ellipsis>
                </label>
                <label class="detail-s pb-3">Sucursal para recoger tarjeta</label>
                <hr class="line" />
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row d-flex justify-content-center align-content-center">
            <button class="btn-form-confirm-addressee mb-3 button-m end-btn modals-btn surance-btn btn-adjust"
              [ngClass]="{'btn-mobile':vm.breakpoints.mobile,
            'btn-desktop':vm.breakpoints.desktop || vm.breakpoints.tablet}" (click)="navigate()"
            (keydown.enter)="$event.preventDefault()">
              Listo</button>
          </div>
          <div class="row d-flex justify-content-center align-content-center">
            <button class="btn-form-modify button-m end-btn modals-btn insurance-btn"
            [ngClass]="{'btn-mobile':vm.breakpoints.mobile,
            'btn-desktop':vm.breakpoints.desktop || vm.breakpoints.tablet}" (click)="close()"
            (keydown.enter)="$event.preventDefault()">
              Otra operación
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="reception">
        <div *ngIf="!confirmRequestAditional"
          class="d-flex flex-column justify-content-center align-items-center mb-4"
          [ngClass]="vm.breakpoints.mobile ? 'text-center':''">
          <div class="pt-3 pb-2 default-color" [ngClass]="vm.breakpoints.mobile ? 'header-m':'header-l'">Confirmación de
            solicitud</div>
          <div class="pb-3 body-m text-secondary-700">Confirma los datos</div>
        </div>
        <div *ngIf="confirmRequestAditional" class="d-flex flex-column justify-content-center align-items-center m-4">
          <img [src]="iconCheck | cdnImage" class="icon-check" width="56" height="56" alt="iconcheck" />
          <div class="pt-3 pb-2 default-color" [ngClass]="vm.breakpoints.mobile ? 'header-m text-center':'header-l'">
            Solicitud {{isCreditCard? 'TDC' : 'TDD' }} adicional</div>
          <div class="body-m pb-2 default-color">Folio: {{invoice}}</div>
          <div class="pb-3 date default-color">{{ completeDate }}h</div>
          <app-shared-buttons
            class="ignore-element"
            [captureImage]="captureImage"
            [shareCapture]="captureImage"
            [hideMail]="true"
            [webCard]="true"
          ></app-shared-buttons>
        </div>
        <div class="d-flex justify-content-between" [ngClass]="vm.breakpoints.mobile ? 'flex-column':'gap-3'">
          <main [ngClass]="!vm.breakpoints.mobile ? 'w-50':'w-100'">
            <div class="pb-2 default-color"
            [ngClass]="!vm.breakpoints.mobile ? 'header-s':'header-xs'">{{yourAccount}}</div>
            <div class="d-flex flex-row gap-3 align-items-center">
              <div *ngIf="!vm.breakpoints.mobile">
                <img [src]="bankCard" alt="image" width="auto" height="auto"
                class="col-product-img" >
              </div>
              <div class="d-flex flex-column">
                <span class="body-s default-color">{{cardNumber | maskAccount : 4}}</span>
                <span class="detail-s">{{cardType}}</span>
              </div>
            </div>
            <hr class="line" />
            <div class="d-flex flex-column justify-content-start">
              <div class="pt-2s pb-2 default-color "
              [ngClass]="!vm.breakpoints.mobile ? 'header-s':'header-xs'">
                Información de beneficiario</div>
              <label class="body-s text-line-break">
                <text-ellipsis [text]="beneficiary" [classes]="'main-color-label'" [lines]="2"></text-ellipsis>
              </label>
              <label class="detail-s pb-1"> Beneficiario</label>
              <hr class="line" />
            </div>
          </main>

          <main [ngClass]="!vm.breakpoints.mobile ? 'w-50':'w-100'">
            <div class="d-flex flex-column justify-content-start">
              <div class="mb-2 default-color"
              [ngClass]="!vm.breakpoints.mobile ? 'header-s':'header-xs'">Información de la operación</div>
              <label class="body-s default-color">{{rfc}}</label>
              <label class="detail-s pb-2"> RFC</label>
              <hr class="line" />
              <ng-container *ngIf="!curpAdditionalCreditCard">
                <label class="body-s default-color">{{curp}}</label>
                <label class="detail-s pb-2"> CURP</label>
                <hr class="line" />
              </ng-container>
              <label class="body-s default-color">{{gender}}</label>
              <label class="detail-s pb-2"> Sexo</label>
              <hr class="line" />
              <label class="body-s default-color">{{civilStatus}}</label>
              <label class="detail-s pb-2">Estado civil</label>
              <hr class="line" />
              <label class="body-s default-color">{{date}}</label>
              <label class="detail-s pb-2">Fecha de nacimiento</label>
              <hr class="line" />
              <div class="d-flex flex-column" *ngIf="isCreditCard">
                <label class="body-s default-color" >{{percent}} %</label>
                <label class="detail-s pb-2">Porcentaje de línea de crédito</label>
                <hr class="line" />
              </div>
              <text-ellipsis class="body-s default-color" [text]="address" [classes]="'main-color-label'" [lines]="2">
              </text-ellipsis>
              <label class="detail-s pb-2">Recepción de tarjeta</label>
              <hr class="line" />
            </div>
          </main>

        </div>

        <div *ngIf="!confirmRequestAditional" class="container-fluid"
          [ngClass]="vm.breakpoints.mobile ? '':''">
          <div class="row d-flex justify-content-center align-content-center">
            <button class="btn-form-confirm-addressee mb-3 button-m end-btn insurance-btn modals-btn no-border"
              [ngClass]="{'btn-mobile':vm.breakpoints.mobile,
            'btn-desktop':vm.breakpoints.desktop || vm.breakpoints.tablet}" (click)="confirm()"
            (keydown.enter)="$event.preventDefault()">
              Confirmar y continuar
              <img [src]="chevronRight | cdnImage" width="auto" height="auto" class="origin-c" alt=">">
            </button>
          </div>
          <div class="row d-flex justify-content-center align-content-center">
            <button class="btn-form-modify button-m end-btn insurance-btn modals-btn no-border"
            [ngClass]="{'btn-mobile':vm.breakpoints.mobile,
            'btn-desktop':vm.breakpoints.desktop || vm.breakpoints.tablet}" (click)="modify(ModifyReception)"
            (keydown.enter)="$event.preventDefault()">
              Modificar
            </button>
          </div>
        </div>

        <div *ngIf="confirmRequestAditional" class="container-fluid" [ngClass]="vm.breakpoints.mobile ? '':''">
          <div class="row d-flex justify-content-center align-content-center pb-3 text-center">
            <div class="d-flex flex-column">
              <label class="body-s default-color">Le notificaremos vía WhatsApp y SMS cuando la tarjeta
                llegue a su destino.</label>
            </div>
          </div>
          <div class="row d-flex justify-content-center align-content-center ignore-element">
            <button class="btn-form-confirm-addressee mb-3 button-m end-btn insurance-btn modals-btn no-border"
              [ngClass]="{'btn-mobile':vm.breakpoints.mobile,
            'btn-desktop':vm.breakpoints.desktop || vm.breakpoints.tablet}" (click)="navigate()"
            (keydown.enter)="$event.preventDefault()">
              Listo</button>
          </div>
          <div class="row d-flex justify-content-center align-content-center ignore-element">
            <button class="btn-form-modify button-m end-btn insurance-btn modals-btn no-border"
            [ngClass]="{'btn-mobile':vm.breakpoints.mobile,
            'btn-desktop':vm.breakpoints.desktop || vm.breakpoints.tablet}" (click)="close()"
            (keydown.enter)="$event.preventDefault()">
              Otra operación
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #ModifyReception>
  <app-modal-my-cards [modifyReception]="true"></app-modal-my-cards>
</ng-template>
