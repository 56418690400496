import { ExchangeRateService, IExchange } from 'src/app/services/exchange-rate.service';
import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, SubscriptionLike, combineLatestWith, BehaviorSubject } from 'rxjs';
import { IAddresseeAccount } from 'src/app/interface/IAddresseeAccount';
import { IAmount } from 'src/app/interface/IAmount';
import { ISimpleValue } from 'src/app/interface/ISimpleValue';
import { CardAddresseeService } from 'src/app/services/card-addressee.service';
import { EnterAmountErrorService } from 'src/app/services/enter-amount-error.service';
import { FacePassLoginService } from 'src/app/services/face-pass-login.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { NotifyClearFormService } from 'src/app/services/notify-clear-form.service';
import { IExpressTransferData } from 'src/app/interface/IExpressTransferData';
import { ExpressTransferService } from 'src/app/services/express-transfer.service';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { ServicePaymentTypeService } from 'src/app/services/service-payment-type.service';
import { Char, CoinType, Constants, Length, Numbers, Regex } from 'src/core/constants/Constants';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Strings } from 'src/core/constants/Strings';
import { PATH } from 'src/core/constants/Path';
import { Utils } from 'src/core/utils/utils';
import { InsurancePolicies } from 'src/app/services/insurance-policies-status.service';
import { CheckBoxService } from 'src/app/services/checks-value-service';
import { PaymentSATStrings } from 'src/core/constants/PaymentSATStrings';

@Component({
  selector: 'app-enter-amount',
  templateUrl: './enter-amount.component.html',
  styleUrls: ['./enter-amount.component.css']
})
export class EnterAmountComponent implements OnInit, OnDestroy, OnChanges {
  @Input() label: string = Strings.EMPTY;
  @Input() maxAmount: number;
  @Input() minAmount: number;
  @Input() amountMessage: string;
  @Input() maxAmountMessage: string;
  @Input() allowNegative: boolean;
  @Input() allowZero: boolean;
  @Input() amountInsurance: number;
  @Input() styleInsurance?: string;
  @Input() amountSua: string;
  @Input() isInternational?: boolean = false;
  @Input() internationalBadge: string = Strings.EMPTY;
  convertedAmount = new BehaviorSubject<{ MXP: number }>({ MXP: Numbers.Zero });
  @Input() disabledAmount: boolean = false;
  @Input() classLabel?: boolean = false;
  @Input() customCurrency?: boolean = false;
  @Input() placeDots: boolean = false;
  @Input() noBalance: boolean = false;
  @Input() checkRadioStatus: boolean = true;
  @Input() noBalanceMessage: string = Strings.EMPTY;
  @Input() tabIndex: string = Strings.EMPTY;
  amount!: string;
  type: string;
  amount$: Observable<IAmount>;
  amountSL: SubscriptionLike;
  amountInsuranceSL: SubscriptionLike;
  amountSubscribe: SubscriptionLike;
  cardAddresseeServiceSL: SubscriptionLike;
  exchangeRateServiceSL: SubscriptionLike;
  clearForm$: Observable<ISimpleValue>;
  clearFormSL: SubscriptionLike;
  selectedAddressee$: Observable<IAddresseeAccount>;
  changeAmountColor: boolean = false;
  isFacePassLogin: boolean = false;
  expressTransferData$: Observable<IExpressTransferData>;
  isExpress: boolean = false;
  idService: string;
  isPayCredit: boolean = false;
  fontSize: string = Constants.CLASS_STYLE_ENTER_AMOUNT;
  largeAmount: boolean = true;
  convertedAmountMXP: number = Numbers.Zero;
  convertedAmountUSD: number = Numbers.Zero;
  marketExchangeUSD: number;
  serviceUp: boolean = true;
  marketExchangeMXP: number;
  coinModeResponsiveMobile: boolean = false;
  currentBalance: number;
  regexNumberPoint: RegExp = Regex.OnlyNumbersDecimals;
  badge: string;
  isUSD: boolean;
  auxAmountInternational: number;
  isInvestment: boolean;
  investmentAmount: number = Numbers.Zero;
  getErrorValue: boolean;
  currentAmount: string = Strings.EMPTY;
  internationalAmount: number = Numbers.Zero;
  amountInternational: string;
  tooltipAmount: number = Numbers.One;
  @Input() decimalsAmount: number = Constants.DECIMALS_AMOUNT;
  @Input() decimals: number = Constants.DECIMALS_AMOUNT;
  @Input() maxLength: number = Constants.MAXIMUM_LENGTH_AMOUNT;
  @Input() maxDigits?: number = null;
  onlyNumbers: RegExp = Regex.DifferentFromDecimals;
  minAmountEuroRound: number;
  hiddenPlaceholder: boolean = false;
  isCreditCardPayment: boolean = false;
  badgePolicy: string = Strings.EMPTY;
  @Input() fullWidth: boolean=false;
  @Input() isInvestmentTitle: boolean = false;
  @Input() isPaymentSAT: boolean = false;
  @Input() isDomiciliation: boolean = false;

  private readonly minAmountLength = Length.Three;
  private readonly fontSizes = {
    small: Constants.FONT_SIZE.Small,
    normal: Constants.FONT_SIZE.Normal
  } as const;
  @Input() quantityLabel: string = Strings.TRANSFER.Quantity;
  readonly currencyLabel = CoinType.EUR;
  errorMessage: string = Strings.EMPTY;

  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(this.amountErrorService.amountErrorObservable,
      this.notifyAmountService.internationalAmount$),
    map(([breakpoints, amountError, amount]) => {
      this.errorMessage = amountError.errorMsg;
      this.changeAmountColor = amountError.isError;
      return { breakpoints, amountError, amount };
    })
  );

  constructor(
    private readonly notifyAmountService: NotifyAmountService,
    private readonly amountErrorService: EnterAmountErrorService,
    readonly notifyClearFormService: NotifyClearFormService,
    private readonly cardAddresseeService: CardAddresseeService,
    private readonly router: Router,
    private readonly facePassLoginService: FacePassLoginService,
    readonly expressTransferService: ExpressTransferService,
    private readonly stateServicePayIdService: StateServicePayIdService,
    private readonly responsiveService: ResponsiveService,
    private readonly servicePaymentTypeService: ServicePaymentTypeService,
    private readonly exchangeRateService: ExchangeRateService,
    private readonly insurancePolicies:InsurancePolicies,
    private readonly checkBoxService: CheckBoxService
  ) {
    this.amount$ = notifyAmountService.amount$;
    this.clearForm$ = notifyClearFormService.clearFormObservable;
    this.selectedAddressee$ = cardAddresseeService.cardAddressee$;
    this.expressTransferData$ = expressTransferService.expressTransferObservable;
    this.servicePaymentTypeService.paymentTypeService$.subscribe(data => {
      this.currentBalance = data.options.currentBalance;
    });
  }

  ngOnChanges(): void {
    if (this.errorMessage === Strings.EMPTY) {
      this.amountErrorService.amountErrorData = { isError: false, errorMsg: Strings.EMPTY };
    }
  }

  async ngOnInit() {
    this.exchangeRateServiceSL = this.exchangeRateService.exchange$.subscribe(data => {
      this.exchangeRateData(data);
    });
    this.cardAddresseeServiceSL = this.cardAddresseeService.cardAddressee$.subscribe(data => {
      this.cardAddresseeData(data);
    });

    this.stateServicePayIdService.service$.subscribe(data => {
      this.idService = data.id;
      this.isPayCredit = this.idService === Constants.SERVICE_CODE.CreditPayment;
      this.isCreditCardPayment = data.params?.esTDC;
    });
    if (this.checkRadioStatus) {
      this.checkStatus();
    }
    if (this.isPayCredit || this.isCreditCardPayment) {
      this.disabledAmount = true;
      this.amountSL = this.amount$.subscribe(value => {
        this.amountSLData(value);
      });

      this.notifyAmountService.amount = {
        amount: this.amount
      };

    } else if (this.isInternational) {
      this.amountInsuranceSL = this.amount$.subscribe(e => {
        this.amount = e.amount;
        this.convertedAmountMXP = e.internationalAmount;
      });
      this.convertedAmountMXP = this.notifyAmountService.amount.internationalAmount;
      this.amountErrorService.amountErrorData = { isError: false, errorMsg: Strings.EMPTY };
    } else {
      if (parseFloat(this.amountSua) > Length.Empty) {
        this.amount = this.amountSua;
      } else {
        this.amountInsuranceSL = this.amount$.subscribe(value => {
          this.amount = value.amount;
        });
      }
    }

    this.isFacePassLogin = this.facePassLoginService.isFacePassLogin.value;

    if (!this.isInternational) {
      this.clearFormSL = this.clearForm$.subscribe(clear => {
        if (clear.value) {
          this.notifyAmountService.amount = { amount: Strings.EMPTY };
        }
      });
    }

    this.expressTransferData$.subscribe(value => {
      if (value.clabe) {
        this.isExpress = true;
      } else {
        this.isExpress = false;
      }
    });

    this.getBadge();

    this.disabledValidation();
    this.forceEnabled();
  }

  getBadge() {
    if (this.insurancePolicies.currencySymbol) {
      this.badge = this.insurancePolicies.currencySymbol;
    }
  }

  amountSLData(value: IAmount) {
    this.amount = `${value.amount}${Strings.EMPTY}`;
    if (value.type) {
      this.type = value.type;
    }
    if (this.type === Strings.CREDIT_PAYMENT.OtherAmount || this.type === Strings.CREDIT_PAYMENT.ProgrammedPayment) {
      this.disabledAmount = false;
    } else {
      this.disabledAmount = true;
      this.changeAmountColor = false;
      this.amountErrorService.amountErrorData = { isError: false, errorMsg: Strings.EMPTY };
    }
  }

  exchangeRateData(data: IExchange) {
    this.marketExchangeMXP = data.exchangeRateMXP;
    this.marketExchangeUSD = data.exchangeRateUSD;
    if (this.marketExchangeUSD === Numbers.Zero) {
      this.convertedAmountMXP = Numbers.Zero;
    } else if (this.marketExchangeUSD !== Numbers.Zero && this.convertedAmountMXP === undefined) {
      this.convertedAmountMXP = Numbers.Zero;
    }
  }

  cardAddresseeData(data: IAddresseeAccount) {
    this.badge = data.badge;
    this.isUSD = this.badge === CoinType.USD;
  }

  ngOnDestroy(): void {
    this.amountSubscribe?.unsubscribe();
    this.amountSL?.unsubscribe();
    this.clearFormSL?.unsubscribe();
    this.amountInsuranceSL?.unsubscribe();
    this.cardAddresseeServiceSL?.unsubscribe();
    this.exchangeRateServiceSL?.unsubscribe();
  }

  currencyConversion(event?: string) {
    this.amountInternational = event;
    if (this.badge === CoinType.EUR) {
      this.convertedAmountUSD = this.marketExchangeUSD * parseFloat(this.amountInternational);
      this.convertedAmountMXP = this.convertedAmountUSD * this.marketExchangeMXP;
      const minAmountEuro = this.minAmount / this.marketExchangeUSD;
      this.minAmountEuroRound = Math.ceil(minAmountEuro);
    } else if (this.badge === CoinType.USD) {
      this.convertedAmountMXP = this.marketExchangeMXP * parseFloat(this.amountInternational);
    }

    this.validateMinMaxAmountInternational(this.amountInternational);

    this.notifyAmountService.internationalAmount = {
      internationalAmount: this.convertedAmountMXP
    };
  }

  get exchangeRate() {
    return this.isUSD? this.marketExchangeMXP: (this.marketExchangeUSD * this.marketExchangeMXP);
  }

  public isValidAmount() {
    let currentAmount = this.notifyAmountService.amount.amount;
    currentAmount = Utils.transformAmount(currentAmount);
    if (this.maxAmount != null && parseFloat(currentAmount) > this.maxAmount && this.maxAmountMessage) {
      this.showErrorMessage(this.maxAmountMessage);
      return false;
    }
    if (this.minAmount != null && parseFloat(currentAmount) < this.minAmount && this.amountMessage) {
      this.showErrorMessage(this.amountMessage);
      return false;
    }
    return this.setValidAmount(currentAmount);
  }

  setValidAmount(currentAmount: string) {
    if(!this.validateBalance(currentAmount)){
      return false;
    }
    if (this.maxAmount != null && parseFloat(currentAmount) > this.maxAmount) {
      this.showErrorMessage(Strings.MAX_AMOUNT_ERROR_MSG(new CurrencyPipe(Constants.LOCALE_EN_US).transform(this.maxAmount)));
      return false;
    }
    if (parseFloat(currentAmount) < this.minAmount) {
      this.showErrorMessage(Strings.MIN_AMOUNT_MSG(new CurrencyPipe(Constants.LOCALE_EN_US).transform(this.minAmount)));
      return false;
    }
    if (!this.allowZero && parseFloat(currentAmount) === Constants.AMOUNT_ZERO) {
      this.showErrorMessage(Strings.AMOUNT_ERRORS.ZeroAmount);
      return false;
    }
    if (!this.allowNegative && parseFloat(currentAmount) < Constants.AMOUNT_ZERO) {
      this.showErrorMessage(Strings.AMOUNT_ERRORS.ZeroAmount);
      return false;
    }
    this.changeAmountColor = false;
    this.amountErrorService.amountErrorData = { isError: false, errorMsg: Strings.EMPTY };
    return true;
  }

  validateBalance(currentAmount: string){
    if(this.noBalance && this.maxAmount === Numbers.Zero){
      this.showErrorMessage(this.noBalanceMessage);
      return false;
    }
    if(currentAmount === Strings.EMPTY && this.amountErrorService.currentValue.isError){
      this.showErrorMessage(Strings.GENERIC_ERRORS.Required);
      return false;
    }
    return true;
  }

  private showErrorMessage(message: string) {
    this.changeAmountColor = true;
    this.amountErrorService.amountErrorData = { isError: true, errorMsg: message };
  }

  changeStyle() {
    this.largeAmount = this.amount.length >= this.minAmountLength;
  }

  validEntry(data: string) {
    const minLength = Length.Four;
    if (this.router.url === PATH.InternationalTransfers) {
      if (data.length >= minLength) {
        this.fontSize = this.fontSizes.small.toString();
      } else {
        this.fontSize = Constants.CLASS_STYLE_ENTER_AMOUNT;
      }
    }
  }

  coinResponsive(data: string) {
    if (this.router.url === PATH.InternationalTransfers) {
      if (data.length > Length.One) {
        this.fontSize = this.fontSizes.small.toString();
        this.coinModeResponsiveMobile = true;
      } else {
        this.fontSize = Constants.CLASS_STYLE_ENTER_AMOUNT;
      }
    }
  }
  validateMinMaxAmountInternational(currentAmount: string): boolean {
    if (this.badge === CoinType.EUR && this.minAmountEuroRound != null && parseFloat(currentAmount) < this.minAmountEuroRound) {
      this.showErrorMessage(Strings.MIN_AMOUNT_MSG(new CurrencyPipe(Constants.LOCALE_EN_US, Char.CurrencyEuro).transform(this.minAmountEuroRound)));
      return false;
    } else {
      this.changeAmountColor = false;
      this.amountErrorService.amountErrorData = { isError: false, errorMsg: Strings.EMPTY };
      if (this.badge === CoinType.USD) {
        this.setValidAmount(currentAmount);
      }
      return true;
    }
  }
  checkStatus(){
    const checkStatus = this.checkBoxService.getCheckboxState();
    if(checkStatus){
      this.disabledAmount = true;
    }
    if(this.idService){
      this.disabledAmount = this.idService === Constants.SERVICE_CODE.Psegnal;
    }
  }

  disabledValidation() {
    if(this.isInvestmentTitle || this.isPaymentSAT){
      this.disabledAmount = true;
    }

    if(this.isPaymentSAT){
      this.quantityLabel=PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.sharedText.LabelOperation.TotalAmount;
    }
  }

  forceEnabled(){
    if(this.isDomiciliation) this.disabledAmount=false;
  }

}
