import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class FileDataService {

  private readonly fileData: BehaviorSubject<File> = new BehaviorSubject<File>(null);

  get data$(){
    return this.fileData.asObservable();
  }

  get data(){
    return this.fileData.getValue();
  }

  set data(file: File) {
    this.fileData.next(file);
  }

  clearData() {
    this.data = null;
  }

}
