import { PathModel } from 'src/app/models/PathModel';

export interface IDirectDebitAforeConfirmationRequest {
  operacion:boolean;
  folio: string;
}

export class DirectDebitAforeConfirmationRequest {
    constructor(public data: IDirectDebitAforeConfirmationRequest){ }

    public get endpoint(): PathModel {
        return PathModel.DirectDebitAforeConfirmation;
    }
}
