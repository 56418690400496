import { Pipe, PipeTransform } from '@angular/core';
import { Resources } from 'src/core/constants/Resources';

@Pipe({
  name: 'cdnImage'
})
export class CdnImagePipe implements PipeTransform {
  constructor() { }

  transform(value: string, isBanner: boolean = false,folderImages:string='/images',folderPortal:string='portal'): string {
    return !isBanner ? Resources.CDN_URL(value,folderImages,folderPortal): Resources.CDN_BANNER_URL(value);
  }

}
