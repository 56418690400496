import { PATH } from 'src/core/constants/Path';
import { services } from 'src/environments/environment';
import { Resources } from 'src/core/constants/Resources';

export class AppConstants {
  static readonly APP_DATA = 'data';
  static readonly ANDROID_CONTEXT = 'AndroidContext';
  static readonly ACCESS_METHOD_APP = 'NAPPM';
  static readonly IOS_CONTEXT = 'webkit';
  static readonly LISTENER_APP = 'PortalAppNavigation';
  static readonly ROUTE = 'target';
  static readonly SAVE_PRODUCTS_MY_ACCOUNTS = 'SaveProductsMyAccounts';
  static readonly CDN_NEWS_CAROUSEL = () => `${Resources.PATH_CDN}/json/Noticias.json`;
  static readonly CDN_PROMOTIONS = () => `${Resources.PATH_CDN}/json/PromocionesTarjetas.json`;
  static readonly NAVIGATION_APP: Array<string> = [PATH.TransferData,PATH.TransferExpress,PATH.Transfers, PATH.CreditPayment, PATH.Control, PATH.GeneralInformation,
    PATH.PaymentPolicies, PATH.Investment, PATH.Services, PATH.InternationalTransfers, PATH.FrequentlyQuestions,
    PATH.LoyaltyPoints, PATH.Portability
  ];

  static readonly APLICATION_CODE = {
    Bank: 'IWPP',
    BankLabel: 'Banco',
    CreditCard: 'TCINF',
    CreditCardLabel: 'TDC',
    Aditional: 'ADICIONAL',
    AditionalLabel: 'Adicional',
    Insurance: 'SEGUROS',
    InsuranceLabel: 'Seguros'
  };
}

export enum AppMethod {
  GetUserInformation = 'PortalGetUserInformation',
  SendNativeScreen = 'SendNativeScreen',
  GoToTransfer = 'PortalGetAccountInformation',
  GetToken = 'PortalGetTokenWidget',
  GetPDF = 'PortalPdfDownload',
  GetPolicy = 'PortalGetInsuranceInformation',
  GetCredit = 'PortalGetCreditInformation',
  ShowSpinner = 'PortalShowSpinner',
  SaveInfoStorage = 'PortalSaveInfoStorage',
  GetScanner = 'PortalGetScanner',
  GetInfoPlatform = 'PortalGetInfoPlatform',
  UpdateSession = 'UpdateSession',
  GetPhoneNumber = 'PortalGetPhoneNumber',
  PortalTokenRegistration='PortalTokenRegistration',
  PortalRequestDigitalCard = 'PortalRequestDigitalCard',
  PortalGetValueStorage = 'PortalGetValueStorage',
  PortalEnableActivation='PortalEnableActivation',
  PortalRemoveDevice='PortalRemoveDevice',
  PortalFacepassRegistration = 'PortalFacepassRegistration',
  ChangeIcon='PortalChangeIcon',
  PortalSuccess = 'PortalSuccessTransaction',
  GetScreenShot = 'GetScreenShot',
  PromotionViewer = 'PromotionViewer'
}

export enum AppScreen {
  Login = 'NewHomePage',
  Home = 'MyAccountPage',
  MapPage = 'MapPage',
  AboutPage = 'AboutPage',
  ContactPage = 'ContactPage',
  CoDiSelectCardPage = 'CoDiSelectCardPage',
  DiMoValidation = 'DomiValidation',
  SavingsPromotionPage = 'SavingsPromotionsPage',
  ContractN4Screen = 'ContractN4',
  CustomizeAppPage = 'CustomizeAppPage',
  DigitalChecks = 'DigitalChecks',
  ShowPDF = 'ShowPDF',
  NewsCorouselModal ='NewsCarouselView',
  InsurancePage = 'InsurancePage',
  pwaShowAviso = 'PWAShowAviso',
  TransferDebt ='TraspasaDeuda'
}
export class RateApp {
  static readonly TRANSFERS_RATE : Array<string> = [
    services.CreditPayment,
    services.DepositAppliesPromissoryNote,
    services.ActiveCard,
    services.CardReposition,
    services.CheckCreditCard,
    services.PayService,
    services.NationalTransfer,
    services.TransferOwnAccounts,
    services.TransferThirdParty,
    services.AddCreditOtherBank,
    services.AddNationalAgenda,
    services.AddThirdPartyAgenda,
    services.ModifyAgentService,
    services.ModifyAgentServiceNonAgendable,
    services.ModifyOthersBanks,
    services.ModifyInternationalAgendas,
    services.NewInternationalAddress,
    services.AddPayService,
    services.InternationalTransfer,
    services.CancelOthersBanks,
    services.CancelThirdAgenda,
    services.ModifyThirdAgenda,
    services.ModifyOthersBanks,
    services.UpdateLockLimitsCard,
    services.SaveClarification,
    services.CreateAdditionalUser,
    services.RedemptionPoints,
    services.EditUserEmail,
    services.EditUserRFC,
    services.EditUserTaxData,
    services.AssociateFundingAccount,
    services.UpdateFundingAccount
  ];
}
