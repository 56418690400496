import { Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { ClassHTML, Keys, Numbers } from 'src/core/constants/Constants';
import { ToastService } from '../services/toast.service';
import { Strings } from 'src/core/constants/Strings';
import { ResponsiveService } from '../shared/responsive.service';
import { SubscriptionLike } from 'rxjs';

@Directive({
  selector: 'input[listenMayus]',
})
export class MayusDirective implements OnDestroy {
  sub: SubscriptionLike;
  positionRight: number = Numbers.Zero;
  capsLock: boolean = false;

  constructor(
    private readonly toastService: ToastService,
    private readonly elementRef: ElementRef,
    private readonly responsiveService: ResponsiveService
  ) { }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.hideToast();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const isMayus = event.getModifierState(Keys.CapsLock);
    const { x, y, width, height } = this.elementRef.nativeElement.getBoundingClientRect();
    this.positionRight = x - width;
    const top = Number(y) + (height / Numbers.Two);

    this.sub = this.responsiveService.observe.subscribe(breakpoints => {
      if(breakpoints.tablet || breakpoints.mobile){
        this.positionRight = Numbers.Zero;
      }
    });

    const styles: Partial<CSSStyleDeclaration> =  {
      top: `${top}px`,
      right: `${this.positionRight}px`,
      position: ClassHTML.Relative
    };

    if(isMayus && !this.capsLock){
      this.toastService.show(Strings.CAPS_LOCK_ON_MESSAGE, {
        autohide: false,
        classname: ClassHTML.CardInfo,
        styles
      });
      this.capsLock = true;
    }else if(!isMayus && event.key === Keys.CapsLock){
      this.toastService.show(Strings.CAPS_LOCK_OFF_MESSAGE, {
        classname: ClassHTML.CardInfo,
        styles
      });
      this.capsLock = false;
    }
  }

  @HostListener('blur', ['$event'])
  hideToast() {
    this.toastService.clear();
    this.capsLock = false;
  }
}
