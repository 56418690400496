<content-wrapper *ngIf="vm$ | async as vm" [contentMargin]="contentMargin" [useFlex]="!vm.breakpoints.mobile">
  <div content class="width-content">
    <app-header-transfer-card [title]="title" [subtitle]="subtitle" [showSubtitle]="true"
    [returnFn]="goBack.bind(this)"></app-header-transfer-card>
    <div class="main-content margin-card">
      <div>
        <app-enter-amount [label]="enterAmountLabel" [minAmount]="minAmount"
          [amountMessage]="amountMessage" [decimals]="decimalsAmount"
          [maxDigits]="maxDigits" [maxLength]="maxLengthAmount"></app-enter-amount>
        <app-select-account [styleCard]="1" headSelAccount="Desde tu cuenta"></app-select-account>
        <app-type-investment (sendTypeToInvestment)="receiveDates($event)" [withOutType]="alertDataType"
          [withOutDate]="alertDataDate" [viewAllDays]="false" [selectedTypeBack]="selectedTypeBack"
          [selectedDateBack]="selectedDateBack" [investmentCode]="investmentCode" [title]="titleComponentType">
        </app-type-investment>
      </div>
      <div>
        <app-investment-date [dateToDay]="formatDateToDay" [dueDate]="formatDueDate" [dateToDayBack]="limitDateBack"
          [dueDateBack]="dueDateBack"></app-investment-date>
        <app-investment-information (sendInvestmentInformation)="receiveInformation($event)"
          [withOutRenewal]="alertDataRenewal" [withOutSelect]="alertService" [disabledRenewal]="disabledRenewal"
          [interestRateBack]="interestRateBack" [netReturnBack]="netReturnBack"
          [toTheExpirationBack]="toTheExpirationBack"></app-investment-information>
      </div>
    </div>
    <div class="btn-container">
      <button class="mb-3 button-m mt-4 colored-btn btn-generic border-btn-ok" (click)="confirmData()">
        Continuar
        <img alt="" [src]=" chevronRight | cdnImage " width="auto" height="auto">
      </button>
    </div>
  </div>
</content-wrapper>
