import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Char, Constants, Numbers } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { UserInfoRequest } from 'src/app/interface/dto/UserInfoRequest';
import { UserDate, UserInfoResponse } from 'src/app/interface/dto/UserInfoResponse';
import { FacepassInfoGeneralRequest } from 'src/app/interface/FacepassInfoGeneralRequest';
import { FacepassInfoGeneralResponse, IFacepassDevice } from 'src/app/interface/FacepassInfoGeneralResponse';
import { CancelarFacepassPLRequest } from 'src/app/interface/ICancelarFacepassPLRequest';
import { ICancelarFacepassPLResponse } from 'src/app/interface/ICancelarFacepassPLResponse';
import { TokenInfoGeneralRequest } from 'src/app/interface/TokenInfoGeneralRequest';
import { CancelarTokenPLRequest } from 'src/app/interface/ICancelarTokenPLRequest';
import { ICancelarTokenPLResponse } from 'src/app/interface/ICancelarTokenPLResponse';
import { IToken, TokenInfoGeneralResponse } from 'src/app/interface/TokenInfoGeneralResponse';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { StorageService } from 'src/app/services/storage.service';
import { Strings } from 'src/core/constants/Strings';
import { CdnImagePipe } from 'src/app/pipes/cdn-image.pipe';
import { Utils } from 'src/core/utils/utils';
import { GeneralInformationStrings } from 'src/core/constants/GeneralInformationStrings';
import { ImageUtils } from 'src/core/utils/imageUtils';
import { SentinelService } from 'src/app/services/sentinel.service';
import { AddressConstants } from 'src/core/constants/AddressConstants';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { ModifyEmailRequest } from 'src/app/interface/dto/ModifyEmailRequest';
import { ModifyRFCRequest } from 'src/app/interface/dto/ModifyRFCRequest';
import { ModifyTaxDataRequest } from 'src/app/interface/dto/ModifyTaxDataRequest';
import { ErrorTError } from 'src/app/interface/TError';
import { AppUtils } from 'src/core/utils/AppUtils';
import { IUserProductsConfig } from 'src/app/interface/IUserProductsConfig';

interface UserAccessData {
  names?: string;
  paternalSurname?: string;
  maternalSurname?: string;
  phone?: string;
  email?: string;
  address?: string;
  users?: UserDate[];
  DatosFiscales?: TaxData;
  InfoModificable?:IsInfoModify;
  rfc?:string;
  errorMessage?: string;
  errorService?: boolean;
}
interface TaxData {
  CodigoPostalFiscal:string;
  NombreFiscal:string;
  RegimenFiscales:[TaxRegime];
}

interface TaxRegime{
  CodigoRegimen: number;
  Orden: number;
  RegimenFiscal:string;
}
interface IsInfoModify{
  Email:boolean;
  Celular:boolean;
  Domicilio:boolean;
}
@Injectable({
  providedIn: 'root',
})
export class UserAccessService {
  private readonly _tokens: BehaviorSubject<IToken[]> = new BehaviorSubject<IToken[]>(null);
  private readonly _facePass: BehaviorSubject<IFacepassDevice[]> = new BehaviorSubject<IFacepassDevice[]>(null);
  private readonly _userInformation: BehaviorSubject<UserAccessData> = new BehaviorSubject<UserAccessData>(null);
  private readonly _messageErrorFacePass: BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);
  private readonly _messageErrorTokens: BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);
  private readonly isApp = AppUtils.platform.isApp;
  constructor(
    private readonly storage: StorageService,
    private readonly baseService: IBaseService,
    private readonly sentinelService: SentinelService
  ) { }

  get tokens$() {
    this.tokenInformationGeneral();
    return this._tokens.asObservable();
  }

  get tokens(){
    return this._tokens.value;
  }
  set tokens(data: IToken[]) {
    this._tokens.next(data);
  }

  get facePass$() {
    this.facePassInformationGeneral();
    return this._facePass.asObservable();
  }

  get facePass(){
    return this._facePass.value;
  }
  set facePass(data: IFacepassDevice[]) {
    this._facePass.next(data);
  }

  get userInformation$() {
    return this._userInformation.asObservable();
  }

  get userInformation() {
    return this._userInformation.getValue();
  }
  set userInformation(userData:UserAccessData){
    this._userInformation.next(userData);
  }
  get messageErrorFacePass$() {
    return this._messageErrorFacePass.asObservable();
  }

  get messageErrorFacePass() {
    return this._messageErrorFacePass.getValue();
  }

  set messageErrorFacePass(data: string) {
    this._messageErrorFacePass.next(data);
  }

  get messageErrorTokens$() {
    return this._messageErrorTokens.asObservable();
  }

  get messageErrorTokens() {
    return this._messageErrorTokens.getValue();
  }

  set messageErrorTokens(data: string) {
    this._messageErrorTokens.next(data);
  }

  clearUserInformation() {
    this._userInformation.next(null);
    this._facePass.next(null);
    this._tokens.next(null);
    this._messageErrorFacePass.next(Strings.EMPTY);
    this._messageErrorTokens.next(Strings.EMPTY);
  }

  async getUserInformation(config: IUserProductsConfig = { reload: true }) {
    try {
     if(config?.reload && !this.userInformation){
      const request: UserInfoRequest = new UserInfoRequest({
        IdSession: this.storage.getSession()
      });
      const response = await this.baseService.genericPost<UserInfoResponse>(request, { isSOA: true });
      response.Users.forEach(user => {
        if (user !== null && user.Cards) {
          user.Cards.forEach(item => {
            item.ImageUrl = ImageUtils.getAdditionalImage(item.ImageUrl);
          });
        }
      });
      this._userInformation.next({
        names: response.GeneralData.Names,
        paternalSurname: response.GeneralData.PaternalSurname,
        maternalSurname: response.GeneralData.MaternalSurname,
        phone: response.GeneralData.Phone,
        email: response.GeneralData.Mail,
        DatosFiscales: response.GeneralData.DatosFiscales,
        rfc: response.GeneralData.Rfc,
        InfoModificable:response.GeneralData.InfoModificable,
        address: this.dataAddress(response.GeneralData.Direccion.Calle, response.GeneralData.Direccion.Colonia,
          response.GeneralData.Direccion.MunicipioAlcaldia, response.GeneralData.Direccion.CodigoPostal, response.GeneralData.Direccion.Ciudad).gfiCapitalize(),
        users: response.Users
      } as UserAccessData);}

    } catch (error) {
      if (error.status) {
        const getErrorMessage = Utils.getErrorMsg(error);
        this._userInformation.next({
          errorMessage: Utils.SERVICE_ERROR_MSG(getErrorMessage.msg, getErrorMessage.code),
          errorService: true
        });
      } else {
        this._userInformation.next({
          errorMessage: GeneralInformationStrings.GeneralError,
          errorService: true
        });
      }
    }
  }

  async tokenInformationGeneral() {
    try {
      const request: TokenInfoGeneralRequest = new TokenInfoGeneralRequest({
        Sesion: this.storage.getSession()
      });
      const response = await this.baseService.genericPost<TokenInfoGeneralResponse>(request, { isSOA: true });
      const data = response.Tokens.Token.map(item => {
        return {
          ...item,
          Estatus: item.Estatus === Constants.ACTIVE_TOKEN_STATUS ? Constants.INFO_DEVICE_TXT_STATUS : item.Estatus,
          deviceInfoImg: new CdnImagePipe().transform(Resources.MOBILE)
        };
      });
      this.tokens = data;

    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      Utils.printLogError(error);
      this.messageErrorTokens = errorDetails.msg;
    }
  }

 async facePassInformationGeneral() {
      try {
        const request: FacepassInfoGeneralRequest = new FacepassInfoGeneralRequest({
          Sesion: this.storage.getSession(),
          IC: null,
        });
        const response = await this.baseService.genericPost<FacepassInfoGeneralResponse>(request, {
          isSOA: true,
        });
        const data = response.consultaDispositivosResult.DatosSalida.Dispositivo.map(item => {
          return {
            ...item,
            estadoFacephi: item.estadoFacephi === Constants.FACEPASS_ACTIVE_STATUS ? Constants.INFO_DEVICE_TXT_STATUS : item.estadoFacephi,
            deviceInfoImg: new CdnImagePipe().transform(Resources.MOBILE),
            versionSO: this.getOSVersion(item.SO, item.versionSO)
          };

        });
        this.facePass = data;
      } catch (error) {
        const errorDetails = Utils.getErrorMsg(error);
        Utils.printLogError(error);
        this.messageErrorFacePass = errorDetails.msg;
      }
  }

  private dataAddress(street: string, colony: string, town: string, zipCode: string, city: string) {
    let address: string;
    if (!street && !colony && !town && !zipCode && !city) {
      address = Strings.EMPTY;
    } else {
      address = this.validationAddress(street, colony, town, zipCode, city);
    }
    return address;
  }

  validationAddress(street: string, colony: string, town: string, zipCode: string, city: string) {
    const getStreet = street ?? Strings.EMPTY;
    const getColony = colony ?? Strings.EMPTY;
    const getTown = town ?? Strings.EMPTY;
    const getZipCode = zipCode ?? Strings.EMPTY;
    const getCity = city ?? Strings.EMPTY;
    return `${getStreet}${getStreet ? Char.Comma : Strings.EMPTY}${getColony} ${getTown.trim()}
      ${getTown ? Char.Comma : Strings.EMPTY}${getZipCode ? AddressConstants.POSTAL_CODE : Strings.EMPTY} ${getZipCode.trim()}
      ${getZipCode ? Char.Comma : Strings.EMPTY} ${getCity}`;
  }

  private getOSVersion(itemSO: string, versionSO: number | string) {
    if (itemSO === Constants.INFO_DEVICE_ANDROID) return `${itemSO} ${versionSO}`;
    if (itemSO === Constants.INFO_DEVICE_IOS) return `${Constants.INFO_DEVICE_IOS} ${versionSO}`;
    return Strings.EMPTY;
  }

  async cancelToken(tokenType: string, serialNumber: string) {
    const request: CancelarTokenPLRequest = new CancelarTokenPLRequest({
      Sesion: this.storage.getSession(),
      TipoToken: tokenType,
      NumeroSerie: serialNumber,
      MedioAcceso: Constants.ACCESS_METHOD_PORTAL,
      OrigenConsulta: Strings.EMPTY,
      IpCliente: this.sentinelService.sentinel.ip
    });
    await this.baseService.genericPost<ICancelarTokenPLResponse>(request, {
      isSOA: true,
    });
    this.tokens = this._tokens.getValue().filter(token => token.SerialNumber !== serialNumber);
  }

  async cancelFacePass(deviceId: string) {
    const request: CancelarFacepassPLRequest = new CancelarFacepassPLRequest({
      idDispositivo: deviceId,
    });
    await this.baseService.genericPost<ICancelarFacepassPLResponse>(request, {
      isSOA: true,
    });
    this.facePass = this._facePass.getValue().filter(facePass => facePass.idDispositivo !== deviceId);
  }

  // METODOS POST PARA EDITAR LA INFORMACION
  async postEmailEdit(newEmail: string, lastEmail: string) {
    const request: ModifyEmailRequest = new ModifyEmailRequest({
      IdSession: this.storage.getSession(),
      TipoOperacion: GeneralInformationStrings.KEY_EMAIL.toLocaleUpperCase(),
      Localizador: { Nuevo: newEmail, Anterior: lastEmail },
      OtpSms: null
    });
    const response = await this.baseService.genericPost<ErrorTError>(request, { isSOA: true });
    return this.evaluateResponse(response);
  }
  async postTaxCodePostalEdit(newPostalCode: string, nameTax: string) {
    const request: ModifyTaxDataRequest = new ModifyTaxDataRequest({
      IdSession: this.storage.getSession(),
      CodigoUsuario: this.isApp ? GeneralInformationStrings.KEY_APP_MOVIL : Constants.ACCESS_METHOD_PORTAL,
      NombreFiscal: nameTax,
      CodigoPostalFiscal: newPostalCode
    });
    const response = await this.baseService.genericPost<ErrorTError>(request, { isSOA: true });
    return this.evaluateResponse(response);
  }
  async postRFCEdit(newRFC: string) {
    const request: ModifyRFCRequest = new ModifyRFCRequest({
      IdSession: this.storage.getSession(),
      RFC: newRFC
    });
    const response = await this.baseService.genericPost<ErrorTError>(request, { isSOA: true });
    return this.evaluateResponse(response);
  }
  async postRegimeTaxEdit(newRegime: string, regimes: string[]) {
    const request: ModifyTaxDataRequest = new ModifyTaxDataRequest({
      IdSession: this.storage.getSession(),
      CodigoUsuario: this.isApp ? GeneralInformationStrings.KEY_APP_MOVIL : Constants.ACCESS_METHOD_PORTAL,
      RegimenFiscales: regimes,
      RegimenModificado: newRegime
    });
    const response = await this.baseService.genericPost<ErrorTError>(request, { isSOA: true });
    return this.evaluateResponse(response);
  }
  evaluateResponse(response: ErrorTError) {
    if (Number(response.Error.No) !== Numbers.Zero) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    } else {
      return true;
    }
  }

}
