import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { DataItem } from 'src/app/interface/DataItem';
import { ConsultPerformanceRequest } from 'src/app/interface/dto/ConsultPerformanceRequest';
import { ConsultPerformanceResponse } from 'src/app/interface/dto/ConsultPerformanceResponse';
import { AccountCardService } from 'src/app/services/account-card.service';
import { AlertService } from 'src/app/services/alert.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { IInvestments, InvestmentRegisterService } from 'src/app/services/investment-register.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { StorageService } from 'src/app/services/storage.service';
import { ModalService } from 'src/app/shared/modal.service';
import { Char, Constants, Numbers, Regex } from 'src/core/constants/Constants';
import { InvestmentsConstants } from 'src/core/constants/InvesmentsConstants';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { InvestmentMaturityInfoComponent } from 'src/app/component/investment-maturity-info/investment-maturity-info.component';
import { ModalOptions } from 'src/app/interface/modal-options';

@Component({
  selector: 'app-investment-information',
  templateUrl: './investment-information.component.html',
  styleUrls: ['./investment-information.component.css']
})
export class InvestmentInformationComponent implements OnInit, OnDestroy {
  @Output() sendInvestmentInformation = new EventEmitter();
  @Input() withOutRenewal: boolean;
  @Input() withOutSelect: boolean;
  @Input() disabledRenewal: boolean;
  @Input() interestRateBack: string;
  @Input() netReturnBack: string;
  @Input() toTheExpirationBack: string;
  informationLabel: string = Strings.INFORMATION_LABEL;
  toTheExpiration: string = Strings.TO_THE_EXPIRATION;
  netReturnValue: string = Strings.EMPTY;
  interestRateValue: string = Strings.EMPTY;
  expirationAmountValue: string = Strings.EMPTY;
  toTheExpirationValue: string = Strings.EMPTY;
  informationInvestment: Array<{ detail: string, value: string }> = null;
  instructionAtDueDate: DataItem[];
  sendDataInvestment: Array<{}>;
  selectedType: string = Strings.EMPTY;
  selectedDate: string = Strings.EMPTY;
  amount: string = Strings.EMPTY;
  number: string = Strings.EMPTY;
  subAccountCode: string = Strings.EMPTY;
  expirationInstruction: string = Strings.EMPTY;
  expirationInstructionRegister: string = Strings.EMPTY;
  selectedDeadline: string = Strings.EMPTY;
  startDate: string = Strings.EMPTY;
  endDate: string = Strings.EMPTY;
  grossYield: string = Strings.EMPTY;
  rate: string = Strings.EMPTY;
  ruleDescription: string = Strings.EMPTY;
  rulePerformance: string = Strings.EMPTY;
  elementsDueDateBack: string = Strings.EMPTY;
  rateSave: string = Strings.EMPTY;
  grossYieldSave: string = Strings.EMPTY;
  expirationSave: string = Strings.EMPTY;
  netYield: string = Strings.NET_YIELD;
  interestRate: string = Strings.INTEREST_RATE;
  changeOptionInvestment: boolean;
  serviceOut: boolean;
  amountFormat: number;
  investmentServiceSL: SubscriptionLike;
  accountNumberServiceSL: SubscriptionLike;
  investmentDataServiceSL: SubscriptionLike;
  infoImage: string = Resources.CIRCLE_INFO_LIGHT_BLUE;

  constructor(private readonly investmentRegisterService: InvestmentRegisterService,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly storageService: StorageService,
    private readonly baseService: IBaseService,
    private readonly alertService: AlertService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly modalService: ModalService,
    public accountCardService: AccountCardService) { }

  async ngOnInit() {
    if (this.serviceOut) {
      this.changeOptionInvestment = true;
    }
    this.rateSave = this.interestRateBack;
    this.grossYieldSave = this.netReturnBack;
    this.expirationSave = this.toTheExpirationBack;
    this.notifyAmountService.amount$.subscribe(data => {
      this.changeOptionInvestment = true;
      this.amount = data.amount;
      const amount = data.amount.replace(Regex.Reformat, Strings.EMPTY);
      this.checkAmountAndUpdate(amount);
      this.sendInvestmentInformation.emit(this.sendDataInvestment);
      this.toTheExpirationValue = Strings.EMPTY;
      this.informationInvestment = [];
      const grossYieldFormat = Strings.EMPTY;
      const rateFormat = Strings.EMPTY;
      this.informationInvestment = new Array();
      if (grossYieldFormat === Strings.EMPTY && rateFormat === Strings.EMPTY) {
        this.grossYield = Constants.ZERO_CURRENCY.toString();
        this.rate = Constants.AMOUNT_ZERO.toString();
      }
      const grossYieldFormatDelete = Char.CurrencySymbol + this.grossYield.substring(Numbers.Zero, Constants.MAX_GROSS_YIELD);
      const rateFormatDelete = this.rate + Char.PercentSymbol;
      const labelsInvestment = [this.netYield, this.interestRate];
      const valueData = [grossYieldFormatDelete, rateFormatDelete];
      for (let i = Numbers.Zero; i < labelsInvestment.length; i++) {
        this.informationInvestment.push(
          {
            detail: labelsInvestment[i],
            value: valueData[i]
          });
      }
      this.elementsDueDateBack = Strings.EMPTY;
      this.interestRateBack = Strings.EMPTY;
      this.netReturnBack = Strings.EMPTY;
      this.toTheExpirationBack = Strings.EMPTY;
    });
    this.interestRateBack = this.rateSave;
    this.netReturnBack = this.grossYieldSave;
    this.toTheExpirationBack = this.expirationSave;
    this.accountNumberServiceSL = this.investmentRegisterService.accountNumber$.subscribe(accountNumber => {
      this.number = accountNumber.NumeroCuenta;
    });
    this.investmentServiceSL =  this.investmentRegisterService.investment$.subscribe(investment => {
      investment.ListaSubCuentas?.forEach(data => {
        this.subAccountCode = data.Codigo;
        this.changeOptionInvestment = true;
      });
    });
   this.investmentDataServiceSL = this.investmentRegisterService.investmentData$.subscribe(investmentData => {
      this.instructionAtDueDate = this.validSelectData(investmentData);
      this.elementsDueDateBack = investmentData.expirationInstructionOption;
      this.selectedType = investmentData.subAccountCode;
      this.selectedDeadline = investmentData.selectedDeadline;
      this.sendInformation(this.selectedDeadline);
      this.changeOptionInvestment = investmentData.changeOptionInvestment;
      this.toTheExpirationValue = Strings.EMPTY;
      if (this.changeOptionInvestment === true) {
        this.toTheExpirationValue = Strings.EMPTY;
        this.informationInvestment = [];
        const grossYieldFormat = Strings.EMPTY;
        const rateFormat = Strings.EMPTY;
        this.informationInvestment = new Array();
        if (grossYieldFormat === Strings.EMPTY && rateFormat === Strings.EMPTY) {
          this.grossYield = Constants.ZERO_CURRENCY.toString();
          this.rate = Constants.AMOUNT_ZERO.toString();
        }
        const grossYieldFormatDelete = Char.CurrencySymbol + this.grossYield.substring(Numbers.Zero, Constants.MAX_GROSS_YIELD);
        const rateFormatDelete = this.rate + Char.PercentSymbol;
        const labelsInvestment = [this.netYield, this.interestRate];
        const valueData = [grossYieldFormatDelete, rateFormatDelete];
        for (let i = Numbers.Zero; i < labelsInvestment.length; i++) {
          this.informationInvestment.push(
            {
              detail: labelsInvestment[i],
              value: valueData[i]
            });
        }
        this.elementsDueDateBack = Strings.EMPTY;
      }
    });
    this.getComponent();
  }

  checkAmountAndUpdate(amount: string) {
    if(parseFloat(amount) < InvestmentsConstants.UPPER_LIMIT){
      this.sendDataInvestment = [];
    }else{
      this.sendDataInvestment = [];
      this.updateSendDataInvestment();
    }
  }


  ngOnDestroy(): void {
    this.investmentServiceSL?.unsubscribe();
    this.accountNumberServiceSL?.unsubscribe();
    this.investmentDataServiceSL?.unsubscribe();
  }

  validSelectData(investments: IInvestments) {
    if (investments.selectedDeadline === InvestmentsConstants.DAYS) {
      return [
        { name: Strings.SETTLEMENT_AT_MATURITY }
      ];
    }
    return [
      { name: Strings.SETTLEMENT_AT_MATURITY },
      { name: Strings.BALANCE_ONLY_RENEWAL },
      { name: Strings.RENEWAL_AT_EXPIRATION }
    ];
  }

  updateSendDataInvestment() {
    this.sendDataInvestment.push(
      {
        netReturn: this.grossYield,
        interestRate: this.rate,
        expirationAmount: this.amountFormat,
        toTheExpirationValue: this.toTheExpirationValue,
        serviceOut: this.withOutSelect
      }
    );
  }

  getComponent() {
    if (this.interestRateBack !== Strings.EMPTY && this.netReturnBack !== Strings.EMPTY) {
      this.grossYield = this.netReturnBack;
      this.rate = this.interestRateBack;
      this.sendDataInvestment = [];
      this.sendDataInvestment.push(
        {
          netReturn: this.netReturnBack,
          interestRate: this.interestRateBack,
          expirationAmount: Strings.EMPTY,
          toTheExpirationValue: this.elementsDueDateBack
        });
      this.sendInvestmentInformation.emit(this.sendDataInvestment);
    }
    this.toTheExpirationBack = this.expirationSave;
    if (!this.grossYield) { this.grossYield = Constants.ZERO_CURRENCY.toString(); }
    if (!this.rate) { this.rate = Constants.AMOUNT_ZERO.toString(); }
    const grossYieldFormat = Char.CurrencySymbol + this.grossYield.substring(0, Constants.MAX_GROSS_YIELD);
    const rateFormat = this.rate + Char.PercentSymbol;
    this.informationInvestment = new Array();
    const labelsInvestment = [this.netYield, this.interestRate];
    const valueData = [grossYieldFormat, rateFormat];
    for (let i = Numbers.Zero; i < labelsInvestment.length; i++) {
      this.informationInvestment.push(
        {
          detail: labelsInvestment[i],
          value: valueData[i]
        });
    }
    this.changeDetectorRef.detectChanges();
  }

  renewal(data: string) {
    this.elementsDueDateBack = data;
    if (this.interestRateBack !== Strings.EMPTY && this.netReturnBack !== Strings.EMPTY) {
      this.netReturnBack = Strings.EMPTY;
      this.interestRateBack = Strings.EMPTY;
    }
    this.changeOptionInvestment = false;
    this.withOutRenewal = false;
    this.withOutSelect = false;
    if (data !== Strings.EMPTY ) {
      this.toTheExpirationValue = data;
      if (this.toTheExpirationValue === Strings.SETTLEMENT_AT_MATURITY) {
        this.expirationInstruction = Constants.TRANSFER_PERFORMANCE;
        this.expirationInstructionRegister = Constants.TRANSFER_REGISTER;
      } else if (this.toTheExpirationValue === Strings.BALANCE_ONLY_RENEWAL) {
        this.expirationInstruction = Constants.RENEW_BALANCE_PERFORMANCE;
        this.expirationInstructionRegister = Constants.RENEW_BALANCE_REGISTER;
      } else {
        this.expirationInstruction = Constants.RENEW_PERFORMANCE;
        this.expirationInstructionRegister = Constants.RENEW_REGISTER;
      }
      this.getConsultPerformance();
    } else {
      this.withOutRenewal = true;
      this.withOutSelect = true;
    }
  }

  sendInformation(selectedDeadline?: string ) {


    this.sendDataInvestment = [];
    if(selectedDeadline  === Strings.EMPTY){
      this.sendDataInvestment = [];
    }else{
      this.sendDataInvestment.push(
        {
          netReturn: this.grossYield,
          interestRate: this.rate,
          expirationAmount: this.amountFormat,
          toTheExpirationValue: this.toTheExpirationValue
        });
        if(this.elementsDueDateBack === Strings.EMPTY){
          this.withOutRenewal = true;
          this.withOutSelect = true;
        }
    }

    this.sendInvestmentInformation.emit(this.sendDataInvestment);
  }

  async getConsultPerformance() {
    const aux = this.amount.replace(Regex.MatchComma, Strings.EMPTY).replace(Regex.MatchCurrencySymbol, Strings.EMPTY);
    const request = new ConsultPerformanceRequest({
      CodigoSubCuenta: this.selectedType,
      Plazo: this.selectedDeadline,
      Monto: Number(aux),
      IdSession: this.storageService.getSession(),
      Cuenta: this.number || this.accountCardService.selectedAccountCard.originAccount,
      InstruccionVencimiento: this.expirationInstruction
    });

    try {
      const response =
        await this.baseService.genericPost<ConsultPerformanceResponse>(request, {
          isSOA: true,
        });
      response.ListaCalculosRendimientoxPlazo.forEach(performanceData => {
        this.startDate = performanceData.FechaInicio;
        this.endDate = performanceData.FechaFin;
        this.grossYield = performanceData.RendimientoBruto.toString();
        this.rate = performanceData.Tasa.toString();
        this.ruleDescription = performanceData.DescripcionRegla;
        this.rulePerformance = performanceData.ReglaRendimiento;
      });
      this.getComponent();
      this.sendInformation();
      this.investmentRegisterService.investmentData = {
        startDate: this.startDate,
        endDate: this.endDate,
        grossYield: parseFloat(this.grossYield),
        rate: parseFloat(this.rate),
        ruleDescription: this.ruleDescription,
        selectedDeadline: this.selectedDeadline,
        subAccountCode: this.selectedType,
        expirationInstruction: this.expirationInstruction,
        expirationInstructionRegister: this.expirationInstructionRegister,
        expirationInstructionOption: this.toTheExpirationValue,
        rulePerformance: this.rulePerformance
      };
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        imgHead: Resources.CLOSE_ICON_RED,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        btnExit: false
      });
      if(error){
        this.sendDataInvestment = [];
      }else{
      this.updateSendDataInvestment();
      }
    this.sendInvestmentInformation.emit(this.sendDataInvestment);
      this.withOutSelect = true;
    }
  }

  openInfoInvestment() {
    const modalOptions: ModalOptions = {
      centered: true,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.IsCentered
    };
    this.modalService.open(InvestmentMaturityInfoComponent, modalOptions);
  }
}
