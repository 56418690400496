import { PathModel } from 'src/app/models/PathModel';

export interface ICardCancellationRequest {
  CancelarTarjeta : boolean;
  MotivoCancelacion : string;
  IdSession : string;
  Tarjeta : string;
  Cuenta : string;
  Sucursal : string;
  Source : string;
  Otp : string;
}

export class CardCancellationRequest {
  constructor(public data: ICardCancellationRequest) { }

  get endpoint(): PathModel {
      return PathModel.CardCancellation;
  }
}
