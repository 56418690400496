import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { tap } from 'rxjs';
import { InsurancePolicies } from 'src/app/services/insurance-policies-status.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { Char, Constants } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';

@Component({
  selector: 'show-amount',
  templateUrl: './show-amount.component.html',
  styleUrls: ['./show-amount.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowAmountComponent {
  @Input() color?: string = Strings.EMPTY;
  @Input() label?: string = Strings.PROPIETARY.AmountProtection;
  @Input() showLabel?: boolean = true;
  @Input() centeredLabel?: boolean = false;
  @Input() useBreak?: boolean = false;
  @Input() maxWidth?: boolean = false;
  @Input() textCenter?: boolean = false;

  amountClass: string = Strings.EMPTY;
  customCurrency: boolean = false;
  currency: string = Strings.EMPTY;

  vm$ = this.notifyAmountService.amount$.pipe(
    tap(({ amount }) => {
      this.amountClass =
        amount.length > Constants.MIN_AMOUNT_LENGTH
          ? Strings.AMOUNT
          : `${Strings.AMOUNT}${Char.MiddleDash}${amount.length}`;
    })
  );

  constructor(
    private readonly notifyAmountService: NotifyAmountService,
    private readonly insurancePoliciesService: InsurancePolicies
  ) {}

  ngOnInit(): void {
    this.getBadge();
  }

  getBadge() {
    if (this.insurancePoliciesService.currencySymbol) {
      this.customCurrency = true;
      this.currency = this.insurancePoliciesService.currencySymbol;
    }
  }
}
