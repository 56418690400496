import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { VoluntaryValidationsRequest } from 'src/app/interface/dto/TVoluntaryValidationsRequest';
import { TVoluntaryValidationsResponse } from 'src/app/interface/dto/TVoluntaryValidationsResponse';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { VoluntaryWithdrawalRequest } from 'src/app/interface/dto/TVoluntaryWithdrawalRequest';
import { TVoluntaryWithdrawalResponse } from 'src/app/interface/dto/TVoluntaryWithdrawalResponse';
import { TAforeAssociationResponse } from 'src/app/interface/dto/AforeAssociationResponse';
import { AforeAssociationRequest } from 'src/app/interface/dto/AforeAssociationRequest';
import { VoluntaryWithdrawalConfirmationRequest } from 'src/app/interface/dto/TVoluntaryWithdrawalConfirmationRequest';
import { VoluntaryWithdrawalReverseRequest } from 'src/app/interface/dto/TVoluntaryWithdrawalReverseRequest';
import { AforeVoluntaryWithdrawalModel } from 'src/app/interface/AforeVoluntaryWithdrawalModel';
import { VoluntaryAccountAssociationRequest } from 'src/app/interface/dto/TVoluntaryAccountAssociationRequest';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { Utils } from 'src/core/utils/utils';
import { ModalService } from 'src/app/shared/modal.service';
import { AlertService } from 'src/app/services/alert.service';
import { Resources } from 'src/core/constants/Resources';

@Injectable({
  providedIn: 'root'
})
export class StateVoluntaryWithdrawalService {

  private readonly _aforeAssociation: BehaviorSubject<TAforeAssociationResponse> =
    new BehaviorSubject<TAforeAssociationResponse>({
      clienteInbursaAfore: Numbers.Zero,
      idClienteAforeAsociado: Numbers.Zero
    });
  private readonly _voluntaryValidations: BehaviorSubject<TVoluntaryValidationsResponse> =
    new BehaviorSubject<TVoluntaryValidationsResponse>({
      expedientes: {
        biometrico: false,
        identidad: false
      },
      cuentaClabe: Strings.EMPTY,
      rfc: Strings.EMPTY,
      esRfcValido: false,
      detalleSubCuentasResponseVO: []
    });
  private readonly _voluntaryWithdrawal: BehaviorSubject<TVoluntaryWithdrawalResponse> =
    new BehaviorSubject<TVoluntaryWithdrawalResponse>({
      numeroSolicitud: Numbers.Zero,
      isrIntereses: Numbers.Zero,
      isrOtrosIngresos: Numbers.Zero,
      monto: Numbers.Zero,
      pagoEnLinea: false,
      consentimiento: Strings.EMPTY
    });
  private readonly _voluntaryWithdrawalConfirmation: BehaviorSubject<string> =
    new BehaviorSubject<string>(Strings.EMPTY);
  private readonly _dataWithdrawalVoluntary: BehaviorSubject<AforeVoluntaryWithdrawalModel> =
    new BehaviorSubject<AforeVoluntaryWithdrawalModel>({
      subAccount: {
        cadenaDerecho: Strings.EMPTY,
        descripcion: Strings.EMPTY,
        tipoSubCuenta: Strings.EMPTY,
        saldo: Numbers.Zero
      },
      clabe: Strings.EMPTY,
      occupation: Strings.EMPTY,
      typeWithdrawal: Strings.EMPTY,
      isrInterests: Strings.EMPTY,
      idOperation: Numbers.Zero,
      isrOtherIncome: Numbers.Zero,
      withdrawalAmount: Numbers.Zero,
      payOnLine: true,
      consent: Strings.EMPTY,
      otp: Strings.EMPTY,
      aforeInbursaClient: Numbers.Zero
    });

  private _confirmVoluntary: boolean = false;
  private _successVoluntary: boolean = false;
  private _isVoluntaryProcess: boolean = false;

  constructor(
    private readonly baseService: IBaseService,
    private readonly alertService: AlertService,
    private readonly modalService: ModalService
  ) { }

  /**
   * Este método se encarga de consultar la información de la asociación de Afore del cliente.
  */
  async getAforeAssociation() {
    const response =  await this.baseService.genericPost<GenericResponse<TAforeAssociationResponse>>(new AforeAssociationRequest, { isSecure: true, addSession: true });
    this.aforeAssociation = response.datos;
  }

  /**
   * Este método se encarga de consultar la información de subcuentas para retiro de ahorro voluntario de Afore.
   * @param request Un objeto de tipo VoluntaryValidationsRequest que representa el clienteInbursaAfore
   * @returns Un objeto de tipo TVoluntaryValidationsResponse que representa la respuesta del consumo del servicio
  */
  async getVoluntaryValidations(request: VoluntaryValidationsRequest): Promise<void> {
    const response = await this.baseService.genericPost<GenericResponse<TVoluntaryValidationsResponse>>(request, { isSecure: true, addSession: true });
    this.voluntaryValidations = response.datos;
    this.voluntaryValidations.detalleSubCuentasResponseVO.forEach(subAccount =>
      subAccount.descripcion = subAccount?.descripcion?.gfiCapitalize());
  }

  /**
   * Este método se encarga de realizar la solicitud  retiro de ahorro voluntario de Afore.
   * @param request Un objeto de tipo VoluntaryWithdrawalRequest que representa la información para realizar la solicitud de retiro
   * @returns Un objeto de tipo GenericResponse que representa la respuesta del consumo del servicio
  */
  async getVoluntaryWithdrawal(request: VoluntaryWithdrawalRequest): Promise<void> {
    const response = await this.baseService.genericPost<GenericResponse<TVoluntaryWithdrawalResponse>>(request, { isSecure: true, addSession: true });
    this.voluntaryWithdrawal = response.datos;
  }

  /**
   * Este método se encarga de realizar la confirmación de la solicitud retiro de ahorro voluntario de Afore.
   * @param request Un objeto de tipo VoluntaryWithdrawalConfirmationRequest que representa la información para realizar
   * la confirmación de la solicitud de retiro
   * @returns Un objeto de tipo GenericResponse que representa la respuesta del consumo del servicio
  */
  async getVoluntaryWithdrawalConfirmation(request: VoluntaryWithdrawalConfirmationRequest): Promise<void> {
    const response = await this.baseService.genericPost<GenericResponse<string>>(request, { isSecure: true, addSession: true });
    this.voluntaryWithdrawalConfirmation = response.datos;
  }

  /**
   * Este método se encarga de realizar la cancelación de la solicitud retiro de ahorro voluntario de Afore.
   * @param request Un objeto de tipo VoluntaryWithdrawalReverseRequest que representa el folio de la solicitud para
   * realizar la cancelación de la misma
   * @returns Un objeto de tipo GenericResponse que representa la respuesta del consumo del servicio
  */
  async getVoluntaryWithdrawalReverse() {
    try {
      const request = new VoluntaryWithdrawalReverseRequest({
        numeroSolicitud: this.dataVoluntaryWithdrawal?.idOperation?.toString()
      });
      await this.baseService.genericPost<GenericResponse<string>>(request, { isSecure: true, addSession: true });
    } catch (error) {
      this.alertError(Utils.getErrorMsg(error));
      this.emptyDataVoluntaryWithdrawal();
      this.successVoluntary = false;
      this.confirmVoluntary = false;
      this.isVoluntaryProcess = false;
    }
    this.successVoluntary = false;
    this.confirmVoluntary = false;
  }

  /**
   * Este método se encarga de realizar la asociación de una cuenta clabe a la cuenta de Afore.
   * @param request Un objeto de tipo VoluntaryAccountAssociationRequest que representa el idCuentaProducto de la cuenta
   * que se pretente asociar
   * @returns Un objeto de tipo GenericResponse que representa la respuesta del consumo del servicio
  */
  async aforeVoluntaryAccountAssociation(request: VoluntaryAccountAssociationRequest): Promise<GenericResponse<string>> {
    return this.baseService.genericPost<GenericResponse<string>>(request, { isSecure: true, addSession: true });
  }

  /**
   * Cierra la ventana modal y muestra una alerta con el mensaje y el código de error proporcionados.
   * @param error Un objeto que contiene el mensaje de error y el código de error.
  */
  alertError(error: { msg: string, code: number }) {
    this.modalService.close();
    const alertInstance = this.alertService.showPopupAlert({
      message: Utils.SERVICE_ERROR_MSG(error.msg, error.code),
      header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
      btnLabel: Strings.MSG_POPUP_ACCEPT,
      imgHead: Resources.CLOSE_ICON_RED,
      btnExit: false
    }, {
      onSuccess: () => alertInstance.dismiss(),
      onClose: () => alertInstance.dismiss()
    });
  }

  /**
   * Este método se encarga de limpiar los valores del observable dataVoluntaryWithdrawal
  */
  emptyDataVoluntaryWithdrawal() {
    this.dataVoluntaryWithdrawal = {
      subAccount: {
        cadenaDerecho: Strings.EMPTY,
        descripcion: Strings.EMPTY,
        tipoSubCuenta: Strings.EMPTY,
        saldo: Numbers.Zero
      },
      clabe: Strings.EMPTY,
      occupation: Strings.EMPTY,
      typeWithdrawal: Strings.EMPTY,
      isrInterests: Strings.EMPTY,
      idOperation: Numbers.Zero,
      isrOtherIncome: Numbers.Zero,
      withdrawalAmount: Numbers.Zero,
      payOnLine: true,
      consent: Strings.EMPTY,
      otp: Strings.EMPTY,
      aforeInbursaClient: Numbers.Zero
    };
  }

  /**
   * Este método se encarga de restablecer los valores de los indicadores del proceso de retiro de ahorro voluntario
  */
  resetVoluntaryWithdrawalProcessValues() {
    this.confirmVoluntary = false;
    this.successVoluntary = false;
    this.isVoluntaryProcess = false;
  }

  //#region Getters and Setters

  get confirmVoluntary(): boolean {
    return this._confirmVoluntary;
  }

  set confirmVoluntary(value: boolean) {
    this._confirmVoluntary = value;
  }

  get successVoluntary(): boolean {
    return this._successVoluntary;
  }

  set successVoluntary(value: boolean) {
    this._successVoluntary = value;
  }

  get isVoluntaryProcess(): boolean {
    return this._isVoluntaryProcess;
  }

  set isVoluntaryProcess(value: boolean) {
    this._isVoluntaryProcess = value;
  }

  public get aforeAssociation$(): Observable<TAforeAssociationResponse> {
    return this._aforeAssociation.asObservable();
  }

  public set aforeAssociation(aforeAssociation: TAforeAssociationResponse) {
    this._aforeAssociation.next(aforeAssociation);
  }

  public get aforeAssociation(): TAforeAssociationResponse {
    return this._aforeAssociation.getValue();
  }

  public get voluntaryValidations$(): Observable<TVoluntaryValidationsResponse> {
    return this._voluntaryValidations.asObservable();
  }

  public set voluntaryValidations(voluntaryValidations: TVoluntaryValidationsResponse) {
    this._voluntaryValidations.next(voluntaryValidations);
  }

  public get voluntaryValidations(): TVoluntaryValidationsResponse {
    return this._voluntaryValidations.getValue();
  }

  public get voluntaryWithdrawal$(): Observable<TVoluntaryWithdrawalResponse> {
    return this._voluntaryWithdrawal.asObservable();
  }

  public set voluntaryWithdrawal(voluntaryWithdrawal: TVoluntaryWithdrawalResponse) {
    this._voluntaryWithdrawal.next(voluntaryWithdrawal);
  }

  public get voluntaryWithdrawal(): TVoluntaryWithdrawalResponse {
    return this._voluntaryWithdrawal.getValue();
  }

  public get dataVoluntaryWithdrawal$(): Observable<AforeVoluntaryWithdrawalModel> {
    return this._dataWithdrawalVoluntary.asObservable();
  }

  public set dataVoluntaryWithdrawal(dataVoluntaryWithdrawal: AforeVoluntaryWithdrawalModel) {
    this._dataWithdrawalVoluntary.next(dataVoluntaryWithdrawal);
  }

  public get dataVoluntaryWithdrawal(): AforeVoluntaryWithdrawalModel {
    return this._dataWithdrawalVoluntary.getValue();
  }

  public get voluntaryWithdrawalConfirmation$(): Observable<string> {
    return this._voluntaryWithdrawalConfirmation.asObservable();
  }

  public set voluntaryWithdrawalConfirmation(voluntaryWithdrawalConfirmation: string) {
    this._voluntaryWithdrawalConfirmation.next(voluntaryWithdrawalConfirmation);
  }

  public get voluntaryWithdrawalConfirmation(): string {
    return this._voluntaryWithdrawalConfirmation.getValue();
  }

  //#endregion
}
