import { Component, Input, OnInit } from '@angular/core';
import { ModalOptions } from 'src/app/interface/modal-options';
import { FileDataService } from 'src/app/services/file-data.service';
import { ModalService } from 'src/app/shared/modal.service';
import { Constants, Numbers } from 'src/core/constants/Constants';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { AlertService } from 'src/app/services/alert.service';
import { Utils } from 'src/core/utils/utils';
import { ModalPaymentReceiptComponent } from 'src/app/component/modal-payment-receipt/modal-payment-receipt.component';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { map } from 'rxjs';
import { SuaService } from 'src/app/services/sua.service';

@Component({
  selector: 'app-modal-load-files',
  templateUrl: './modal-load-files.component.html',
  styleUrls: ['./modal-load-files.component.css']
})
export class ModalLoadFilesComponent implements OnInit {
  @Input() showRegistries: boolean = true;
  iconClose: string = Resources.CLOSE_CIRCLE_BLUE;
  shapeContinue: string = Resources.SHAPE_CONTINUE;
  sizeFile: number = Constants.SUA_FILE_SIZE;
  digitsSizeFile: number = Numbers.Three;
  disabledButton: boolean = true;

  vm$ = this.responsiveService.observe.pipe(
    map(breakpoints => ({ breakpoints }))
  );

  constructor(private readonly modalService: ModalService,
    private readonly fileDataService: FileDataService,
    private readonly alertService: AlertService,
    private readonly responsiveService: ResponsiveService,
    private readonly suaService: SuaService) { }

  ngOnInit(): void {}

  async sendFile() {
    try {
      const fileSizeMB = Number((this.fileDataService.data.size / (this.sizeFile * this.sizeFile)).toFixed(this.digitsSizeFile));
      await this.suaService.uploadFile(fileSizeMB);
    } catch (error) {
      this.modalService.close();
      const errorDetail = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetail.msg,errorDetail.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      },
      {
        onSuccess: () => this.modalService.close()
      });
    }
  }

  enableButton(event: boolean) {
    this.disabledButton = event;
  }

  closeModalLoad() {
    this.modalService.close();
    if(this.showRegistries) {
      const modalOptions: ModalOptions = {
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalPaymentReceipt,
        centered: true
      };
      this.modalService.open(ModalPaymentReceiptComponent, modalOptions);
    }
  }
}
