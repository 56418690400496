import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortabilityCardService {

  private readonly state$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() { }

  get stateObservable() {
    return this.state$.asObservable();
  }

  set stateData(data: boolean) {
    this.state$.next(data);
  }

  get stateData() {
    return this.state$.getValue();
  }
}
