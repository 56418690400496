import { PathModel } from 'src/app/models/PathModel';

export interface IRegisterCvvRequest{
        CVV2 ?: string,
        CustomerId : string, 
        IdPersona : number,
        ExpirationDate : string,
        CardNumber : string,
        Registered : boolean,
        Source : string,
        DCVV : string, 
        DCVVTime ?: number,
        Account ?: string,
        CardFrontImageUrl ?: string,
        CardHolder ?: string,
        CardLimitATM ?: string,
        CardLimitPOS ?: string,
        CardType ?: string,
        ChipPin ?: string,
        ClavbeWebCard ?: string,
        CreditMovements ?: {
          BalanceDay ?: number,
          BalancePoints ?: number,
          BalanceQuotas ?: number,
          ChargesApplied ?: [ {
                Ammount ?: number,
                ChargeDate ?: string,
                Coin ?: string,
                Description ?: string,
                OperationId ?: number
          } ],
          ChargesApply ?: [ {
            Ammount ?: number,
            ChargeDate ?: string,
            Coin ?: string,
            Description ?: string,
            OperationId ?: number
          } ],
          CreditAvailable ?: number,
          CreditLimit ?: number,
          InterestRate ?: number,
          MinimumPayment ?: number,
          PayDayLimit ?: string,
          PendingTransactions ?: number,
          TotalBalance ?: number,
          TotalPayment ?: number
        },
        DebitMovements ?: {
          ListaMovimientosDebito ?: [ {
            Fecha ?: string,
            Monto ?: string,
            NumeroReferencia ?: string,
            Saldo ?: string,
            TipoServicio ?: string
          } ],
          Resumen ?: {
            CuentaProductoID ?: string,
            DepositosPendientes ?: string,
            LineaDeCreditoDisponible ?: string,
            SaldoActual ?: string,
            SaldoRetenido ?: string,
            Saldodisponible ?: string
          }
        },
        DueDay ?: string,
        EspecificLimits ?: {
          ATMAmount ?: number,
          ATMMovements ?: number,
          CommerceAmount ?: number,
          CommerceMovements ?: number,
          InternetAmount ?: number
        },
        EspecificLocks ?: {
          ATM ?: boolean,
          INT ?: boolean,
          OPI ?: boolean,
          POS ?: boolean,
          TEL ?: boolean
        },
        Holder ?: boolean,
        IdCuentaProducto ?: string,
        IdLocalizador ?: string,
        Lock ?: boolean,
        LockString ?: string,
        NickName ?: string,
        Solinume ?: string,
        StateCard ?: string,
        VisualizacionBotones ?: {
          Bloqueos ?: string,
          Cancelacion ?: string,
          Dcvv ?: string,
          Descripcion ?: string,
          Estatus ?: string,
          Limites ?: string,
          Origen ?: string,
          Personaliza ?: string,
          SaldosMovimientos ?: string,
          Visualizar ?: string
        },
        VisualizacionBotonesBloqueado ?: {
          Bloqueos ?: string,
          Cancelacion ?: string,
          Dcvv ?: string,
          Descripcion ?: string,
          Estatus ?: string,
          Limites ?: string,
          Origen ?: string,
          Personaliza ?: string,
          SaldosMovimientos ?: string,
          Visualizar ?: string
        },
        cvvWebCard ?: string
}

export class RegisterCvvRequest{
    constructor(public data: IRegisterCvvRequest){}

    get endpoint(): PathModel{
        return PathModel.RegisterCVV;
    }
}