export enum StringsChecks {
  check = 'Chequera',
  checkbookRequest = 'Solicitud de chequera',
  notCheckbooksAvailable = 'No cuentas con chequeras disponibles',
  checkbookInquiry = 'Consulta de chequera',
  checkInquiry = 'Consulta de cheques',
  activation = 'Activación',
  operationTypeSelection = 'Selecciona el tipo de operación',
  requestConfirmation = 'Confirmación de solicitud',
  dataConfirmation = 'Confirma los datos de la operación',
  associatedAccount = 'Cuenta asociada',
  chargeToAccount = 'Con cargo a tu cuenta',
  operationInformation = 'Información de la operación',
  checkbookPickupLocation = 'Sucursal para recoger chequera',
  checkDetail = 'Detalle de cheque',
  checkBookDetail = 'Detalle de chequera',
  bankName = 'Nombre de banco',
  returnDateLbl = 'Fecha de devolución',
  branchSelection = 'Selección de sucursal',
  nearbyBranchPickupPrompt = 'Selecciona una sucursal cercana para recoger tu chequera',
  checkBookType = 'Tipo de chequera',
  checkBookNumber = 'No. de chequera',
  status = 'Estatus',
  select = 'Seleccionar',
  operationInfo = 'Información de operación',
  checkbookSerialNumber = 'Número de chequera',
  checkSerialNumber = 'Número de cheque',
  checkSerialNum = 'Núm. de cheque',
  protectedChecks = 'Protegidos',
  inquiry = 'Consulta de cheques',
  notChecksAvailable = 'No cuentas con cheques disponibles',
  notCheckbooksActivation = 'No cuentas con chequeras disponibles para activación',
  activationConfirmation = 'Confirmación de activación',
  activationCheckBook = 'Activación de chequera',
  reference = 'Folio:',
  referenceNumber = '7658909',
  protectionCheck = 'Protección por cheques',
  protectionAccount = 'Protección por cuenta',
  cancelCheck = 'Cancelación por cheques',
  cancelCheckBooks = 'Cancelación por chequera',
  currency = 'Moneda',
  StylesChecks = 'StylesChecks',
  buttonProtection = 'btnProtection',
  buttonCancel = 'btnCancel',
  protectedCheck = 'CHEQUE PROTEGIDO',
  digitalChecks = 'Cheques digitales'
}

export enum StylesChecks {
  styleSearchPadding = '0.1875em 0.8em',
  styleSearchMargin = '-0.1875em 0em',
  backgroundCheckmarkBlue = 'var(--primary-1000)',
  backgroundCheckmarkWhite = 'var(--White)',
  modalBackdrop = 'modal-backdrop',
  backdropColor = 'backdrop-color',
  saveImageContainer = 'saveImageContainer',
  disabled = 'disabled'
}

export enum StatusChecks {
  requested = 'Solicitada',
  canceled =  'Cancelada',
  active = 'Activada',
  printed = 'Impresa',
  delivered = 'Entregada',
  checkActive = 'Activo',
  checkSettled = 'Liquidado',
  checkCanceled = 'Cancelado',
  checkProtected = 'Protegido',
  checkReturned = 'Devuelto',
  checkStatus = 'Robo',
  checkStatusTwo = 'Extravio',
  activated = 'Activada',
  received = 'Recibida',
  pending = 'Pendiente'
}

export enum CheckProtection {
  ConfirmProtectionAccount= 'Protección por cuenta',
  LabelAmountProtection = 'Monto protección',
  amount = '24884'
}
export enum CheckCancel {
  Title = 'Confirma la cancelación',
  Subtitle = 'Confirma los datos de la operación'
}
export enum IdentifyCheck {
  modalTransaction = 'modal-transaction'
}

export enum Filters {
  numberCheckBook = 'numberCheckBook',
  state = 'state',
  checkNumber = 'checkNumber'
}

export enum SubtitleCheck {
  dataOperation = 'Ingresa los datos',
  confirmData = 'Confirma los datos',
  dataSubtitle = 'Ingresa los datos'
}

export enum TitleChecks {
  checksProtectionTitle = 'Protección por cheques',
  checkInformation = 'Información de cheque',
  checksSuccess = 'Protección de cheques',
  protection = 'Protección',
  checksCancellation = 'Cancelación de cheques',
  cancellation = 'Cancelación',
  informationChecks = 'Información de la chequera',
  checkCancellation = 'Cancelación de cheque',
  amountCancellation = 'Monto por cancelación'
}

export enum ClassHTML {
  CheckbookActivationSuccessful = 'checkbook-activation-successful'
}

export enum CheckElementsHTML{
  SuccessfulOperation = 'successful-cancellation',
  SuccessActivationCard= 'successDataCardActivation'
}
