import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { INewCredit } from 'src/app/interface/dto/NewCredit';

@Injectable({
  providedIn: 'root'
})
export class AddCreditService {

  private readonly defaultData: INewCredit ={
    bank: Strings.EMPTY,
    typeCredit: Strings.EMPTY,
    creditNumber: Numbers.Zero,
    rfc: Strings.EMPTY,
    creditHolder: Strings.EMPTY,
    amountLimit: Numbers.Zero,
    signaturePanel: Numbers.Zero,
    serviceCode: Strings.EMPTY,
    bankCode: Strings.EMPTY
  };

  private readonly _addCredit: BehaviorSubject<INewCredit> = new BehaviorSubject<INewCredit>({
    bank: Strings.EMPTY,
    typeCredit: Strings.EMPTY,
    creditNumber: Numbers.Zero,
    rfc: Strings.EMPTY,
    creditHolder: Strings.EMPTY,
    amountLimit: Numbers.Zero,
    signaturePanel: Numbers.Zero,
    serviceCode: Strings.EMPTY,
    bankCode: Strings.EMPTY
  });

  constructor() { }

  get addCreditObservable() {
    return this._addCredit.asObservable();
  }

  get addCreditValue(): INewCredit {
    return this._addCredit.getValue();
  }

  set addCreditReturnData(data: INewCredit) {
    this._addCredit.next(data);
  }

  clearData() {
    this.addCreditReturnData = this.defaultData;
  }
}
