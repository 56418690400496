import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalService } from 'src/app/shared/modal.service';
import { Char, Constants, Length, Position } from 'src/core/constants/Constants';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { SuaConstants } from 'src/core/constants/SuaConstants';
import { FileDataService } from 'src/app/services/file-data.service';
import { Utils } from 'src/core/utils/utils';
import { AlertService } from 'src/app/services/alert.service';
import { ServiceStrings } from 'src/core/constants/ServiceStrings';
@Component({
  selector: 'app-load-files',
  templateUrl: './load-files.component.html',
  styleUrls: ['./load-files.component.css']
})
export class LoadFilesComponent implements OnInit {
  @ViewChild(Constants.MODAL_OPTIONS.NotFileId, { static: true }) modalNotFile: ElementRef;
  @Output() disabledButtonEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() showRegistries: boolean = true;
  fileName: string = Strings.EMPTY;
  exportIcon: string = Resources.EXPORT_BLUE;
  idModal: string = Constants.MODAL_OPTIONS.NotFileId;
  headerModal: string = Strings.LOAD_FILE.HeaderModal;
  labelMain: string = ServiceStrings.FILE_SUA;
  continue: string = Constants.DEFAULT_BTN_LABEL;

  constructor(private readonly modalService: ModalService,
    private readonly fileDataService: FileDataService,
    private readonly alertService: AlertService
  ) { }

  ngOnInit(): void {}

  onFileSelected(event: Event): void {
    try {
      if((event.target as HTMLInputElement).files.length === Length.Empty){
        return;
      }else{
        const file = (event.target as HTMLInputElement).files[Position.Zero];
        const allowedExtensions = [SuaConstants.FILE_EXTENSION];
        const fileExtension = file.name.substring(file.name.lastIndexOf(Char.Dot));
        if (allowedExtensions.indexOf(fileExtension) === Constants.NOT_FOUND_ITEM) {
          this.modalService.close();
          const modalOptions: ModalOptions = {
            modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalNotFile
          };
          this.modalService.open(this.modalNotFile, modalOptions);
          return;
        }
        this.fileName = file.name;
        this.fileDataService.data = file;
        this.disabledButtonEmitter.emit(false);
      }
    } catch (error) {
      this.modalService.close();
      const errorDetail = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetail.msg,errorDetail.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      },
      {
        onSuccess: () => this.modalService.close()
      });
    }
  }
}
