import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { StateQrService } from 'src/app/services/state-qr.service';
import { IQrEvent } from 'src/app/interface/IQrEvent';
import { AlertService } from 'src/app/services/alert.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ClassHTML, Constants } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { PATH } from 'src/core/constants/Path';
import { ModalConstants } from 'src/core/constants/ModalConstants';

@Component({
  selector: 'app-footer-card-login-component',
  templateUrl: './footer-card-login-component.component.html',
  styleUrls: ['./footer-card-login-component.component.css']
})
export class FooterCardLoginComponentComponent implements OnInit {
  eventQr$: Observable<IQrEvent>;
  isLoginWithPass: boolean = false;
  @ViewChild('content', { static: true }) content: ElementRef;
  initialAlert = {
    type: Strings.EMPTY, class: ClassHTML.DefaultAlertClass,
    iconHeader: Resources.SHAPE_WARNING,
    message: Strings.ALERTS.LoginAlert,
    icon: Resources.CANCEL
  };

  constructor(private readonly stateQrService: StateQrService,
    private readonly _router: Router,
    private readonly alertService: AlertService,
    private readonly modalService: ModalService) {
    this.eventQr$ = stateQrService.stateQrObservable;
  }

  ngOnInit(): void {
    const url = this._router.url;
    if (url === PATH.UserLogin) {
      this.isLoginWithPass = true;
      return;
    }
  }

  navigateToLogin() {
    if (navigator.geolocation) {
      this.stateQrService.stateQrData = { value: false };
      this._router.navigate([PATH.UserLogin]);
    } else {
      this.modalService.open(this.content, { windowClass: Constants.MODAL_OPTIONS.ContainerPopup, modalDialogClass: ModalConstants.MODAL_OPTIONS.IsCentered});
    }
  }

  navigateToLoginFacepass() {
    this.stateQrService.stateQrData = { value: true };
    this.alertService.alert = this.initialAlert;
    this._router.navigate([PATH.Login]);
  }

  closeModal() {
    this.modalService.close();
  }

  ngOnDestroy() {
    this.modalService.close();
  }
}
