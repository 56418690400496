import { Strings } from 'src/core/constants/Strings';

export class DirectDebitAforeStrings {
    static readonly TITLE = 'Domiciliación Aportaciones Voluntarias';
    static readonly SUBTITLE = 'Visualiza las operaciones domiciliadas';
    static readonly ACCOUNT_NAME = 'Inbursa';
    static readonly DefaultButtonClass = 'modals-btn';
    static readonly MarginDefaultClass = 'mb-5 mx-2';
    static readonly CONFIRM_OPERATION_DATA = 'Confirma los datos de la operación';
    static readonly VOLUNTARY_SAVINGS_FILTERS = {
        Identifier: 'identifier',
        Amount: 'amount',
        Periodicity: 'periodicity',
        TypeOfContribution: 'typeOfContribution',
        PeriodOfValidity: 'periodOfValidity',
        Status: 'status'
    };
    static readonly END_DATE_LABEL = 'Fecha fin';
    static readonly DATE_LABEL = 'Fecha de pago';

  static readonly OPERATIONS = {
    procedures: 'Trámites',
    moreInformation: 'Más información'
  };

  static readonly STYLES = {
    RandomString: '111111',
    BtnClasses: {
      Primary: {
        Form: 'btn-generic btn-voluntary',
        Confirm: 'btn-generic btn-confirm-voluntary',
        Complete: 'btn-generic btn-complete-voluntary'
      },
      Secondary: {
        Confirm: 'btn-generic btn-confirm-voluntary',
        Complete: 'btn-generic btn-complete-voluntary'
      }
    },
    Periodicity: 'periodicity',
    Weekly: 'weekly',
    TypeOfContribution: {
      name: 'typeOfContribution',
      value: 'retirement'
    },
    Header: {
      title: this.TITLE,
      subtitle: Strings.PAGE_HEADER.Subtitle
    },
    HeaderConfirm: {
      title: this.TITLE,
      subtitle: this.CONFIRM_OPERATION_DATA
    },
    Amount: {
      label: '¿Cuánto quieres ahorrar?'
    },
    Cancel: {
      title: '¿Estás seguro de que quieres  cancelar la domiciliación?',

    },
    CancelVoluntarySavings: {
      BtnCancelNo: {
        Classes: 'modals-btn h-100 btn-not-cancel'
      },
      BtnCancelYes: {
        Classes: 'modals-btn h-100 btn-yes-cancel'
      },
      BtnDoneProperties: {
        Classes: 'modals-btn btn-done-properties'
      },
      BtnCompleteOtherOperationProperties: {
        Classes: 'modals-btn btn-done-properties'
      },
      BtnAcceptProperties: {
        Classes: 'modals-btn btn-accept-voluntary'
      }
    }
  };
    static readonly DEDUCTIBILITY_MSG = (amount: string) =>
    `Estimado usuario, le restan ${amount} para alcanzar su límite de deducibilidad de acuerdo al ejercicio fiscal actual.`;

    static readonly LINKS = {
      LinkPagePortal: 'https://www.inbursa.com/portal/?page=Document/doc_view_section.asp&id_document=8495&id_category=34',
      LinkPageDownloadApp: 'https://onelink.to/afore'
    };
    static readonly ALERT_CONFIG_DOWNLOAD = {
      Message: 'Para consultar información detallada de tu cuenta de ahorro para el retiro',
      Header: 'Descarga la app Afore Móvil',
      BtnCancelLabel: 'Más tarde',
      StyleBtnAccept: 'button-m'
    };

}
