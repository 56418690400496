import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { ServiceCardService } from 'src/app/services/service-card.service';
import { NavigationConstants } from 'src/core/constants/NavigationConstants';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { LoaderService } from 'src/app/services/loader.service';
import { AppNavigation } from 'src/app/models/AppNavigation';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { Constants } from 'src/core/constants/Constants';
import { AlertService } from 'src/app/services/alert.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalPwaPayrollCreditComponent } from 'src/app/component/modal-pwa-payroll-credit/modal-pwa-payroll-credit.component';
import { Strings } from 'src/core/constants/Strings';
import { Resources } from 'src/core/constants/Resources';
import { ModalLeavePageAppComponent } from 'src/app/shared/components/modal-leave-page-app/modal-leave-page-app.component';
import { DirectDebitAforeStrings } from 'src/core/constants/DirectDebitAforeStrings';
import { ServiceService } from 'src/app/services/services.service';
import { AppBridge } from 'src/core/utils/AppBridge';
import { InsurancesBridgeService } from 'src/app/services/servicesApp/insurances/insurances-bridge-service.service';
import { CreditCardBridgeService } from 'src/app/services/servicesApp/credit-card/credit-card-bridge.service';


@Injectable({
  providedIn: 'root'
})
export class PaymentCardActionService {
  @ViewChild('confirmCancelAccount') confirmCancelAccount: ElementRef<HTMLInputElement>;
  idModalConfirm: string = Strings.MODAL_MODIFY_ACCOUNT.ConfirmId;
  link: string = Strings.EMPTY;

  accountOperations = { transactionType: Strings.EMPTY };

  constructor(
    private readonly serviceCards: ServiceCardService,
    private readonly accountInfoStatusService:AccountInfoStatusService,
    private readonly modalService: ModalService,
    private readonly loaderService: LoaderService,
    private readonly alertService: AlertService,
    private readonly serviceService:ServiceService,
    private readonly insuranceBridgeService : InsurancesBridgeService,
    private readonly creditCardBridge: CreditCardBridgeService

  ) { }

  onClickActions = {
    [NavigationConstants.MENU_OPTIONS.CreditCardPayment]: () => this.serviceCards.goToPayCreditCard(true),
    [NavigationConstants.MENU_OPTIONS.CreditCardPaymentService]: () => this.serviceService.getInfoServiceCreditCard(),
    [NavigationConstants.MENU_OPTIONS.OperationCoDi]: () => AppBridge.invoke<string>(AppMethod.SendNativeScreen, { Data: AppScreen.CoDiSelectCardPage }),
    [NavigationConstants.MENU_OPTIONS.OperationDiMo]: () => AppBridge.invoke<string>(AppMethod.SendNativeScreen, { Data: AppScreen.DiMoValidation }),
    [NavigationConstants.MENU_OPTIONS.DigitalChecks]: () => AppBridge.invoke<string>(AppMethod.SendNativeScreen, { Data: AppScreen.DigitalChecks }),
    [NavigationConstants.MENU_OPTIONS_AFORE.ProceduresClick]: () => this.leave(NavigationConstants.MENU_OPTIONS_AFORE.ProceduresClick),
    [NavigationConstants.MENU_OPTIONS_AFORE.MoreInformationClick]: () => this.leave(NavigationConstants.MENU_OPTIONS_AFORE.MoreInformationClick),
    [NavigationConstants.MENU_OPTIONS.AssistanceInsurance]: () => this.insuranceBridgeService.navAssistanceInsurance(),
    [NavigationConstants.MENU_OPTIONS.AdvancePayrollClick]: () => this.openCreditPayroll(Strings.SERVICES_CONTACT.HeaderPayrollAdvance),
    [NavigationConstants.MENU_OPTIONS.CreditPayrollClick]: () => this.openCreditPayroll(Strings.SERVICES_CONTACT.HeaderPayrollCredit),
    [NavigationConstants.MENU_OPTIONS.TransferDebt]: () => this.creditCardBridge.navTransferDebt(),
    [NavigationConstants.MENU_OPTIONS.OperationPromotionSavings]: () => this.goToPromotionSavings()
  };

  goToPromotionSavings(){
    const navigationParams: AppNavigation = {
      Data: AppScreen.SavingsPromotionPage,
      DataParams:JSON.stringify({
      idCuentaProducto:this.accountInfoStatusService.account?.idCuentaProducto,
      numeroCuenta: this.accountInfoStatusService.account?.numeroCuenta,
      tipoCuenta: this.accountInfoStatusService.account?.tipoCuenta,
      saldoDisponible: this.accountInfoStatusService.account?.saldos.disponible,
      esNuevoAhorro:this.accountInfoStatusService.account?.Alcancias
      })
    };
    return AppBridge.invoke<boolean>(AppMethod.SendNativeScreen, navigationParams);
  }
    openCreditPayroll(typeMovement: string) {
    this.loaderService.showLoader();
    const modalOptions: ModalOptions = {
      centered: true,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal
    };
    this.modalService.open(ModalPwaPayrollCreditComponent, modalOptions, { transactionType: typeMovement });
  }

  leave(paramClick: string) {
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeMd,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalLeavePage
    };
    const ref = this.modalService.open(ModalLeavePageAppComponent, modalOptions);
    if (paramClick === NavigationConstants.MENU_OPTIONS_AFORE.ProceduresClick) {
      this.link = DirectDebitAforeStrings.LINKS.LinkPagePortal;
      ref.componentInstance.clickFn = this.redirectPage.bind(this);
    } else {
      this.link = DirectDebitAforeStrings.LINKS.LinkPageDownloadApp;
      ref.componentInstance.clickFn = this.questionDownloadAppAfore.bind(this);
    }
  }

  questionDownloadAppAfore() {
    this.modalService.close();
    this.alertService.showPopupAlert({
      message: DirectDebitAforeStrings.ALERT_CONFIG_DOWNLOAD.Message,
      header: DirectDebitAforeStrings.ALERT_CONFIG_DOWNLOAD.Header,
      imgHead: Resources.IMPORT,
      btnExit: false,
      btnCancelLabel: DirectDebitAforeStrings.ALERT_CONFIG_DOWNLOAD.BtnCancelLabel,
      styleBtnAccept: DirectDebitAforeStrings.ALERT_CONFIG_DOWNLOAD.StyleBtnAccept
    }, {
      onSuccess: () => this.redirectPage(),
      onCancel: () => this.modalService.close()
    });
  }

  redirectPage() {
    this.modalService.close();
    window.open(this.link, Strings.TARGET_BLANK);
  }
}
