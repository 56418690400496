import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NoAgendableService, NoAgendableServiceResponse } from 'src/app/interface/dto/NoAgendableServiceResponse';
import { GetConsultSipareResponse } from 'src/app/interface/dto/GetConsultSipareResponse';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { GetConsultSipareRequest} from 'src/app/interface/dto/GetConsultSipareRequest';
import { Constants, FormatDate, Numbers } from 'src/core/constants/Constants';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { Strings } from 'src/core/constants/Strings';
import { PaginationModel } from 'src/app/models/PaginationModel';
import { HttpStatusCode } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { NoAgendableServiceRequest } from 'src/app/interface/dto/NoAgendableServiceRequest';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ModalFederalTaxReceiptFilterComponent } from 'src/app/no-schedulable-services/components/modal-federal-tax-receipt-filter/modal-federal-tax-receipt-filter.component';
import { PreviousPaymentsModalComponent } from 'src/app/services-module/components/previous-payments-modal/previous-payments-modal.component';
import { ModalService } from 'src/app/shared/modal.service';
import { AccountModifyService } from 'src/app/services/info-accounts-edit.service';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { Router } from '@angular/router';
import { PATH } from 'src/core/constants/Path';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  private readonly _data: BehaviorSubject<NoAgendableServiceResponse> =
    new BehaviorSubject<NoAgendableServiceResponse>({
      serviciosNoAgendables: []
    });

  private readonly _hiddenResultNonScheduledServices: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly _dataBackup: BehaviorSubject<NoAgendableService[]> =
    new BehaviorSubject<NoAgendableService[]>([]);

  get hiddenResultNonScheduledServices$() {
    return this._hiddenResultNonScheduledServices.asObservable();
  }
  get hiddenResultNonScheduledServices(): boolean {
    return this._hiddenResultNonScheduledServices.getValue();
  }
  set hiddenResultNonScheduledServices(data: boolean) {
    this._hiddenResultNonScheduledServices.next(data);
  }

  constructor(private readonly baseService: IBaseService,
    private readonly storageService: StorageService,
    private readonly modalService: ModalService,
    private readonly accountModifyService: AccountModifyService,
    private readonly stateServicePayIdService:StateServicePayIdService,
    private readonly router: Router) {}

  get data$() {
    return this._data.asObservable();
  }

  get data(): NoAgendableServiceResponse {
    return this._data.getValue();
  }

  set data(data: NoAgendableServiceResponse) {
    this._data.next(data);
  }

  public get dataBackup(): NoAgendableService[] {
    return this._dataBackup.getValue();
  }

  private set dataBackup(data: NoAgendableService[]) {
    this._dataBackup.next(data);
  }

  async getSiparePayments(currentPage: number): Promise<PaginationModel> {
    const request = new GetConsultSipareRequest({
      numeroPagina: currentPage,
      numeroRegistros: Constants.PAGINATION_SIZE
    });
    const response =
      await this.baseService.genericGet<GetConsultSipareResponse>(
        request,
        { isSecure: true, addSession: true }
      );
    if (!response || !response.datos || !response.datos.datos) {
      throw new BusinessError(Strings.WITHOUT_MOVEMENTS_MSG, HttpStatusCode.NoContent);
    }
    const transactions = response.datos.datos.map(function (data) {
      data.fechaPago = formatDate(data.fechaPago, FormatDate.FormatNewDate, Constants.LOCALE_ES_MX);
      return data;
    });

    return {
      data: transactions,
      totalPages: response.datos.numTotalPaginas
    };
  }

  async getNonScheduledServices() {
    const request = new NoAgendableServiceRequest({
      idSesion: this.storageService.getSession()
    });

    const response =
      await this.baseService.genericPost<NoAgendableServiceResponse>(request, {
        isSOA: true
      });
    if (!response?.serviciosNoAgendables?.length) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
    }

    response.serviciosNoAgendables = response.serviciosNoAgendables.filter(
      (service) => ![Constants.SERVICE_CODE.Pagvesem, Constants.SERVICE_CODE.Pagvesfv].includes(service.codigoServicio)
    );

    response.serviciosNoAgendables = this.removeService(Constants.SERVICE_CODE.CiaLuz, response.serviciosNoAgendables);

    response.serviciosNoAgendables.push(
      {
        codigoServicio: Constants.SERVICE_CODE.Paggm,
        descripcionServicio: Strings.GMM_MESSAGE.Description,
        idAgenda: Numbers.Zero,
        limiteDiario: Numbers.Zero,
        fechaCaptura: null,
        fechaUltimaModificacion: null,
        limiteDiarioAcumulado: Numbers.Zero,
        fechaPrimeraTransaccion: null,
        fechaUltimaModReal: null,
        esModificable: false,
        esOperable: false,
        tiempoEspera: Numbers.Zero
      },
      {
        codigoServicio: Constants.SERVICE_CODE.ExternalTrade,
        descripcionServicio: Strings.PROPIETARY.ComextText,
        idAgenda: Numbers.Zero,
        limiteDiario: Numbers.Zero,
        fechaCaptura: null,
        fechaUltimaModificacion: null,
        limiteDiarioAcumulado: Numbers.Zero,
        fechaPrimeraTransaccion: null,
        fechaUltimaModReal: null,
        esModificable: false,
        esOperable: false,
        tiempoEspera: Numbers.Zero
      }
    );

    const servicesToRemove = [
      Constants.SERVICE_CODE.ExternalTrade,
      Constants.SERVICE_CODE.SearsPayment
    ];

    if (!environment.beta) {
      response.serviciosNoAgendables = response.serviciosNoAgendables
        .filter(service => !servicesToRemove.includes(service.codigoServicio));
    }
    if(environment.hiddenComponents.paymentSat) {
      response.serviciosNoAgendables = response.serviciosNoAgendables
        .filter(service => service.codigoServicio !== Constants.SERVICE_CODE.Impfedr);
    }

    if(environment.hiddenComponents.federalTaxPaymentByConcept){
      response.serviciosNoAgendables = response.serviciosNoAgendables
      .filter(service => service.codigoServicio !== Constants.SERVICE_CODE.Impfed);
    }

    response.serviciosNoAgendables.sort((a, b) => a.descripcionServicio.localeCompare(b.descripcionServicio));

    this.data = response;
    this.dataBackup = [...response.serviciosNoAgendables];
  }

  private removeService(id: string, services: NoAgendableService[]): NoAgendableService[] {
    return services.filter((service: NoAgendableService) => service.codigoServicio !== id);
  }

  navigationHistory(){
    if(this.accountModifyService.account.codeServices === Constants.SERVICE_CODE.Impfed){
      const modalOptions: ModalOptions = {
        centered: true,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal
      };
      this.modalService.open(ModalFederalTaxReceiptFilterComponent, modalOptions);
    }else{
      const modalClass = this.accountModifyService.account.codeServices === Constants.SERVICE_CODE.Impfedr ?
      ModalConstants.MODAL_OPTIONS.HiddenModal:ModalConstants.MODAL_OPTIONS.ModalServiceMovement;
     const modalOptions: ModalOptions = {
       centered: true,
       size: Constants.MODAL_OPTIONS.SizeMd,
       modalDialogClass: modalClass
     };
     this.modalService.open(PreviousPaymentsModalComponent, modalOptions);
    }
   }

   async getInfoServiceCreditCard(){
    if (this.stateServicePayIdService.onlyPaymentCreditCard()) {
      await this.getNonScheduledServices();
      const service = this.data?.serviciosNoAgendables?.find(item => item.codigoServicio === Constants.SERVICE_CODE.Imploc);
      this.stateServicePayIdService.service = {
        id: service?.codigoServicio,
        params: { dailyLimit:service?.limiteDiario }
      };
      this.router.navigate([PATH.NotAgendableServices]);
    }
  }
}
