import { Component } from '@angular/core';
import { ModalService } from '../../shared/modal.service';
import { Numbers } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';

@Component({
  selector: 'app-user-password',
  templateUrl: './user-password.component.html',
  styleUrls: ['./user-password.component.css'],
})
export class UserPasswordComponent {
  closeButton: string = Resources.CLOSE_CIRCLE_BLUE;
  page: string = Numbers.Two.toString();

  constructor(
    private readonly modalService: ModalService
  ) { }

  ngOnDestroy() {
    this.modalService.close();
  }
}
