<ng-container *ngIf="(vm$ | async) as vm">
  <div *ngIf="vm.breakpoints.mobile">
    <div ngbDropdown (openChange)="clearNotifications($event)" autoClose="outside" class="centered-dropdown"
      #myDropdownNotifications="ngbDropdown">
      <div ngbDropdownMenu class="custom-dropdown-menu-mobile notification">
        <div class="container-dropdown">
          <div class="notification-header d-flex">
            <div class="row-header">
              <div class="message-header">
                <span class="col-notifications">{{messageNotifications}}</span>
                <ng-container *ngIf="vm.notifications?.totalMsj>0">
                  <div class="badge-header-text">
                    <span class="message">
                      {{vm.notifications?.totalMsj}} {{messageNew + (vm.notifications.totalMsj>1?'s':'')}}
                    </span>
                  </div>
                </ng-container>
              </div>
              <ng-container *ngIf="vm.infoNotifications?.length>0">
                <a (click)="showModalDeleteNotification()" id="button-trash"
                (keydown.enter)="$event.preventDefault()">
                  <img [src]="trashIcon | cdnImage" alt="Trash" role="button" width="auto" height="auto" id="bellBtn"/>
                </a>
              </ng-container>
            </div>
          </div>
          <div class="notification-body-mobile">
            <ng-container *ngIf="!vm.isError else errorGetNotifications">
              <app-item-notification *ngFor="let notification of vm.infoNotifications"
                [notification]="notification" (click)="onNotificationClick(notification)"
                (keydown.enter)="$event.preventDefault()">
              </app-item-notification>
            </ng-container>
            <ng-template #errorGetNotifications>
              <app-error-exception class="center-modal-error" [titleError]="vm.errorNotifications"
                [showButtons]="false" [autoWidth]="true" [showIcon]="vm.showIconError">
              </app-error-exception>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div (click)="myDropdownNotifications.toggle(); toggleFunction(myDropdownNotifications.isOpen());
      this.notificationClicked.emit(false);" (keydown.enter)="$event.preventDefault()"
      class="button-initial position-relative">
      <img [src]="bellIcon | cdnImage" alt="Notificacion" role="button" width="auto" height="auto" />
      <div class="badge-header" *ngIf="vm.showNotifications">
        <span class="detail-s">
          {{ vm.notifications?.totalMsj }}
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="!vm.breakpoints.mobile">
    <div ngbDropdown (openChange)="clearNotifications($event)" autoClose="outside" placement="bottom"
      #myDropdownNotifications="ngbDropdown" class="clickable">
      <div ngbDropdownToggle class="d-flex flex-row">
        <img [src]="bellIcon | cdnImage" alt="Notificacion" role="button" width="auto" height="auto" id="bellBtn"/>
        <div class="badge-header" *ngIf="vm.showNotifications">
          <span class="detail-s">
            {{ vm.notifications?.totalMsj }}
          </span>
        </div>
      </div>
      <div ngbDropdownMenu class="custom-location" [ngClass]="{'custom-dropdown-menu-tablet' : vm.breakpoints.tablet,
       'custom-dropdown-menu' : vm.breakpoints.desktop}">
        <div class="notification-header d-flex">
          <div class="row-header">
            <div class="message-header">
              <span class="col-notifications">{{messageNotifications}}</span>
              <ng-container *ngIf="vm.notifications?.totalMsj>0">
                <div class="badge-header-text">
                  <span>{{vm.notifications?.totalMsj}} {{messageNew + (vm.notifications.totalMsj>1?'s':'')}}</span>
                </div>
              </ng-container>
            </div>
            <ng-container *ngIf="vm.infoNotifications?.length>0">
              <a (click)="showModalDeleteNotification()" id="button-trash"
              (keydown.enter)="$event.preventDefault()">
                <img [src]="trashIcon | cdnImage" alt="Trash" role="button" width="auto" height="auto" />
              </a>
            </ng-container>
          </div>
        </div>
        <div class="notification-body">
          <ng-container *ngIf="!vm.isError else errorGetNotifications">
            <app-item-notification *ngFor="let notification of vm.infoNotifications"
              [notification]="notification" (click)="onNotificationClick(notification)"
              (keydown.enter)="$event.preventDefault()">
            </app-item-notification>
          </ng-container>
          <ng-template #errorGetNotifications>
            <app-error-exception class="center-modal-error" [titleError]="vm.errorNotifications"
              [showButtons]="false" [autoWidth]="true" [showIcon]="vm.showIconError" [closeModal]="false">
            </app-error-exception>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container>
  <ng-template #deleteNotificationModal>
    <app-modal-exceptions [idModal]="idModal" [headerModal]="headerModal" [continue]="continue"
      (modalCall)="closeModal($event)" [isNotifications]="true">
    </app-modal-exceptions>
  </ng-template>
</ng-container>
