import { Injectable } from '@angular/core';
import { IBaseService } from './i-base-service.service';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { Constants, RetirementType } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { AforePartialValidateInformationRequest } from 'src/app/interface/dto/AforePartialValidateInformationRequest';
import { TAforePartialValidateInformationResponse } from 'src/app/interface/dto/AforePartialValidateInformationResponse';
import { TAforePartialWithdrawalsFoliosResponse } from 'src/app/interface/dto/AforePartialWithdrawalsFoliosResponse';
import { AforePartialWithdrawalsFoliosRequest } from 'src/app/interface/dto/AforePartialWithdrawalsFoliosRequest';
import { AforePartialWithdrawalsCertificatesRequest } from 'src/app/interface/dto/AforePartialWithdrawalCertificatesRequest';
import { TAforePartialWithdrawalsCertificatesResponse } from 'src/app/interface/dto/AforePartialWithdrawalCertificatesResponse';
import { TAforePartialWithdrawalRegistrationResponse } from 'src/app/interface/dto/AforePartialWithdrawalRegistrationResponse';
import { AforePartialWithdrawalRegistrationRequest } from 'src/app/interface/dto/AforePartialWithdrawalRegistrationRequest';
import { AforePartialWithdrawalCalculationsRequest } from 'src/app/interface/dto/AforePartialWithdrawalsCalculationsRequest';
import { TAforePartialWithdrawalsCalculationsResponse } from 'src/app/interface/dto/AforePartialWithdrawalsCalculationsResponse';
import { AforePartialWithdrawalSendMarriageCertificateRequest } from 'src/app/interface/dto/AforePartialWithdrawalSendMarriageCertificateRequest';
import { TAforePartialWithdrawalSendMarriageCertificateResponse } from 'src/app/interface/dto/AforePartialWithdrawalSendMarriageCertificateResponse';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AforeService {
  private readonly _aforeInformation: BehaviorSubject<TAforePartialValidateInformationResponse> = new BehaviorSubject<TAforePartialValidateInformationResponse>(null);
  private readonly _foliosInformation: BehaviorSubject<TAforePartialWithdrawalsFoliosResponse> = new BehaviorSubject<TAforePartialWithdrawalsFoliosResponse>(null);
  private readonly withdrawal: BehaviorSubject<string | RetirementType> = new BehaviorSubject<string | RetirementType>(null);
  private readonly _certificate: BehaviorSubject<TAforePartialWithdrawalsCertificatesResponse> = new BehaviorSubject<TAforePartialWithdrawalsCertificatesResponse>(null);
  private readonly _calculation: BehaviorSubject<TAforePartialWithdrawalsCalculationsResponse> = new BehaviorSubject<TAforePartialWithdrawalsCalculationsResponse>(null);
  private readonly _errorAforeService: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _pdfData: string | ArrayBuffer;
  private _formatFile: string;

  constructor(
    private readonly baseService: IBaseService
  ) { }

  get pdfData(){
    return this._pdfData;
  }

  private set pdfData(data:string | ArrayBuffer){
    this._pdfData = data;
  }

  sendPdfData(data:string | ArrayBuffer){
    this.pdfData = data;
  }

  get formatFile(){
    return this._formatFile;
  }

  private set formatFile(data:string){
    this._formatFile = data;
  }

  sendFormatFile(data:string){
    this._formatFile = data;
  }

  public get aforeInformation$(): Observable<TAforePartialValidateInformationResponse> {
    return this._aforeInformation.asObservable();
  }

  public set aforeInformation(aforeInformation: TAforePartialValidateInformationResponse) {
    this._aforeInformation.next(aforeInformation);
  }

  public get aforeInformation(): TAforePartialValidateInformationResponse {
    return this._aforeInformation.getValue();
  }

  public get aforeFolio$(): Observable<TAforePartialWithdrawalsFoliosResponse> {
    return this._foliosInformation.asObservable();
  }

  public set aforeFolio(aforeFolios: TAforePartialWithdrawalsFoliosResponse) {
    this._foliosInformation.next(aforeFolios);
  }

  public get aforeFolio(): TAforePartialWithdrawalsFoliosResponse {
    return this._foliosInformation.getValue();
  }

  public get withdrawals$(): Observable<string | RetirementType> {
    return this.withdrawal.asObservable();
  }

  public set withdrawals(afore: string | RetirementType) {
    this.withdrawal.next(afore);
  }

  public get withdrawals(): string | RetirementType {
    return this.withdrawal.getValue();
  }

  get certificates$() {
    return this._certificate.asObservable();
  }

  get certificates() {
    return this._certificate.getValue();
  }

  set certificates(data: TAforePartialWithdrawalsCertificatesResponse) {
    this._certificate.next(data);
  }

  get calculations$() {
    return this._calculation.asObservable();
  }

  get calculations() {
    return this._calculation.getValue();
  }

  set calculations(data: TAforePartialWithdrawalsCalculationsResponse) {
    this._calculation.next(data);
  }

    public get errorAforeService$(): Observable<boolean> {
    return this._errorAforeService.asObservable();
  }

  public set errorAforeService(error: boolean) {
    this._errorAforeService.next(error);
  }

  public get errorAforeService(): boolean {
    return this._errorAforeService.getValue();
  }

    /**
   * Servicio para mostrar información de expediente.
   */
  async getAforeValidateInformation(aforeIdNumber:number):Promise<TAforePartialValidateInformationResponse>{
    const response =  await this.baseService.genericPost<GenericResponse<TAforePartialValidateInformationResponse>>
    (new AforePartialValidateInformationRequest({clienteInbursaAfore:aforeIdNumber}),{isSecure:true,addSession:true});
    if (!response && !response.datos) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response.datos;
  }
    /**
   * Servicio para obtener la generación de folios para retiro.
   */
    async getAforeFolios(nss:string,type:string,otp:string):Promise<TAforePartialWithdrawalsFoliosResponse>{
      const response = await this.baseService.genericPost<GenericResponse<TAforePartialWithdrawalsFoliosResponse>>
      (new AforePartialWithdrawalsFoliosRequest({nss,tipo:type,otp}) ,{isSecure:true,addSession:true});
      if (!response && !response.datos) {
        throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
      }
      return response.datos;
    }
    /**
   * Servicio para solicitar certificado.
   */
  async getAforeCertificates(request:AforePartialWithdrawalsCertificatesRequest):Promise<TAforePartialWithdrawalsCertificatesResponse>{
    const response = await this.baseService.genericPost<GenericResponse<TAforePartialWithdrawalsCertificatesResponse>>(request,{isSecure:true,addSession:true});
    if (!response && !response.datos) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response.datos;
  }
     /**
   * Servicio para la solicitud de retiro
   */
  async getAforeRegistration(request:AforePartialWithdrawalRegistrationRequest):Promise<TAforePartialWithdrawalRegistrationResponse>{
    const response = await this.baseService.genericPost<GenericResponse<TAforePartialWithdrawalRegistrationResponse>>(request,{isSecure:true,addSession:true});
    if (!response && !response.datos) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response.datos;
  }
   /**
   * Servicio para enviar archivo acta de matrimonio
   */
  async sendAforeMarriageCertificate(request:AforePartialWithdrawalSendMarriageCertificateRequest):Promise<TAforePartialWithdrawalSendMarriageCertificateResponse>{
    const response = await this.baseService.genericPost<GenericResponse<TAforePartialWithdrawalSendMarriageCertificateResponse>>(request,{isSecure:true,addSession:true});
    return response.datos;
  }
   /**
   * Servicio para la generacion de calculos de retiro por desempleo
   */
   async getAforeCalculations(request: AforePartialWithdrawalCalculationsRequest): Promise<TAforePartialWithdrawalsCalculationsResponse[]> {
    const response = await this.baseService.genericPost<GenericResponse<TAforePartialWithdrawalsCalculationsResponse[]>>(request, { isSecure: true, addSession: true });
    if (!response && !response.datos) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response.datos;
  }
  /**
* Método encargado de obtener los datos para convertirlo a PDF
* @param request un objeto que representa los datos para mostrar el comprobante
* @returns un string base64 que nos permitira hacer la conversión a PDF
*/
  async getAforeVoucher<T>(request: T){
    const response = await this.baseService.genericPost<GenericResponse<string>>(request, { isSecure: true, addSession: true });
    if (!response && !response.datos) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response.datos;
  }

  cleanData(){
    this.aforeInformation = null;
    this.aforeFolio = null;
    this.certificates = null;
    this.calculations = null;
    this.pdfData = null;
    this.formatFile = null;
  }
}
