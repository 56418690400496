import * as CryptoJS from 'crypto-js';
import { AbstractControl, Validators, FormControl, ValidationErrors, FormGroup } from '@angular/forms';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { HttpStatusCode } from '@angular/common/http';
import {
  AssetsType, AccountType, Char, Constants,
  ElementsHTML, FileFormats, HtmlEvent, Length, Numbers,
  Position, Regex, Sorts, PositionDate, Format, Tab, Months, FacepassStatusCode, FormatDate
} from 'src/core/constants/Constants';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { AccountModel } from 'src/app/interface/AccountModel';
import { Strings } from 'src/core/constants/Strings';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { Resources } from 'src/core/constants/Resources';
import { CurrencyPipe, formatDate } from '@angular/common';
import { IAforePLResponse } from 'src/app/interface/IAforePLResponse';
import { AppNavigation } from 'src/app/models/AppNavigation';
import { AppBridge } from 'src/core/utils/AppBridge';
import { PATH } from 'src/core/constants/Path';
import { Router } from '@angular/router';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalLeavePageComponent } from 'src/app/shared/components/modal-leave-page/modal-leave-page.component';
import { ModalTransactionsComponent } from 'src/app/home/components/modal-transactions/modal-transactions.component';
import { ModalAlertLimitComponent } from 'src/app/ct-patrimonial-transfers/components/modal-alert-limit/modal-alert-limit.component';
import { ModalVoluntarySavingsTypeComponent } from 'src/app/afore/voluntary-savings-contributions/components/modal-voluntary-savings-type/modal-voluntary-savings-type.component';
import { ModalWebCardComponent } from 'src/app/component/modal-web-card/modal-web-card.component';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { SystemId } from 'src/core/constants/SystemId';
import { NotifyRouteService } from 'src/app/services/notify-route.service';
import { AppUtils } from 'src/core/utils/AppUtils';
import { FormRegex } from 'src/core/constants/FormConstants';
import { AddressConstants } from 'src/core/constants/AddressConstants';
import { BrokerageHouseStrings } from 'src/core/constants/BrokerageHouseStrings';


export class Utils {
  /**
   * Imprime en consola en formato de información
   * Se sugiere el uso al imprimir información (No Errores)
   * @param description Descripcion del mensaje
   */
  static printLogInfo(description: string): void { if (!Constants.IS_PRODUCTION) { console.info(description); } }

  /**
   * Imprime en consola en formato de error
   * Se sugiere el uso en catch y mensajes de errores
   * @param description
   */
  static printLogError(description: string): void { if (!Constants.IS_PRODUCTION) { console.debug(description); } }

  /**
   * Extrae información de error
   * @param error Objeto de error
   */
  static getErrorMsg(error: any): { msg: string, code: number } {
    const msErrorCodes = [
      HttpStatusCode.BadRequest,
      HttpStatusCode.Unauthorized,
      HttpStatusCode.Forbidden,
      HttpStatusCode.UnprocessableEntity,
      HttpStatusCode.InternalServerError,
      FacepassStatusCode.Waiting,
      FacepassStatusCode.Validating
    ];

    if (error instanceof BusinessError) {
      return { msg: error.description, code: error.errorNo ?? Constants.CATCH_CODE };
    }
    else if (error?.error?.codigoError && msErrorCodes.includes(error.error.codigoError) && error.error.descripcionError) {
      return { msg: error.error.descripcionError, code: error.error.codigoError };
    }
    else if (error?.No && (error?.Description || error.Descripcion)) {
      return { msg: error.Description ?? error.Descripcion, code: error.No };
    }
    else {
      return { msg: Strings.SERVICES.UnexpectedMsg, code: Constants.UNEXPECTED_CODE };
    }
  }

  /**
   * Obtiene ubicación de dispositivo
   */
  static getGeolocation(): Promise<{ latitude: number, longitude: number }> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(loc => {
          resolve({
            latitude: loc.coords.latitude,
            longitude: loc.coords.longitude
          });
        }, reject);
      }
      else {
        reject();
      }
    });
  }

  static encryptAES(value: string, key: string): string {
    return CryptoJS.AES.encrypt(value, key).toString();
  }

  static decryptAES(value: string, key: string): string {
    try {
      return CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8);
    } catch (error) {
      this.printLogError(error);
      sessionStorage.clear();
    }
    return Strings.EMPTY;
  }

  static downloadAccountStatusPdf(pdf: string) {
    const sourcePDF = `${FileFormats.CreatePDF}${pdf}`;
    const downloadLink = document.createElement(ElementsHTML.LabelA);
    const namePDF = Constants.NAME_PDF_ACCOUNT_STATEMENTS;
    downloadLink.href = sourcePDF;
    downloadLink.download = namePDF;
    downloadLink.click();
  }

  /**
   * Abre PDF en nueva ventana
   * @param source Documento (base64 o URL)
   * @param isBase64 Indica si es Base64
   */
  static openPdf(source: string, isBase64 = true) {
    const src = isBase64 ? `${FileFormats.CreatePDF}${source}` : source;
    const iframe = Resources.GET_FRAME_IMAGE(src);
    const viewer = window.open();
    viewer.document.open();
    viewer.document.write(iframe);
    viewer.document.close();
  }
  static openFile(url: string, name: string) {
    const temp = document.createElement(ElementsHTML.LabelA);
    temp.href = url;
    temp.target = Constants.ANCHOR_TARGET;
    temp.download = name;
    document.body.appendChild(temp);
    temp.click();
    document.body.removeChild(temp);
  }
  static removeSpaces(data: string) {
    data = data.replace(Regex.Space, Strings.EMPTY).trim();
    return data;
  }

  /**
   * Elimina formato moneda a monto Ej. $0.00 -> 0.00
   * @param dato Monto a convertir
   */
  static transformAmount(data: string) {
    data = data.replace(Regex.DifferentFromNumbersUtils, Strings.EMPTY);
    return data;
  }

  static getTypeAccount(number: string) {
    switch (number.length) {
      case Constants.CLABE_LENGTH:
        return Constants.KEY_ACCOUNT;
      case Constants.MIN_CARD_LENGTH:
        return Constants.CARD;
      case Constants.CARD_AMEX_LENGTH:
        return Constants.CARD;
      default:
        return Strings.ERROR_GET_TYPE_ACCOUNT;
    }
  }


  static formatDate(date: Date = null) {
    if (date == null) {
      date = new Date();
    }

    const yyyy = date.getFullYear();
    let mm: string = (date.getMonth() + Numbers.One).toString();
    let dd: string = date.getDate().toString();

    if (dd.length < Length.Two) dd = `${Position.Zero}` + dd;
    if (mm.length < Length.Two) mm = `${Position.Zero}` + mm;

    return `${dd}${Char.Slash}${mm}${Char.Slash}${yyyy}`;
  }

  static listMajorToMinor(list: ICardAccount[], filter: string) {
    return [...list].sort(function (a, b) {
      if (a[filter] > b[filter]) {
        return Constants.NUMBERS_DATE.One;
      }
      if (a[filter] < b[filter]) {
        return -Constants.NUMBERS_DATE.One;
      }
      return Constants.NUMBERS_DATE.Zero;
    });
  }

  /**
   * Formatea URL para imágen de cuentas
   * @param cardImage Url de imágen
   */
  static getUrlCardImage(cardImage?: string): string {
    return cardImage ? cardImage.replace(Resources.GET_URL_IMAGE, Strings.EMPTY) : cardImage;
  }

  /**
   * Realiza scroll horizontal dependiendo del id del elemento
   * @param idHtml Id del elemento a realizar scroll
   */
  static enableSlide(idHtml: string) {
    const productCardSlider: HTMLDivElement | null = document.querySelector(Char.Cat + `${idHtml} `);
    let mouseDown = false;
    let startX: number, scrollLeft: number;

    if(productCardSlider){
      productCardSlider.addEventListener(HtmlEvent.MouseMove, e => {
        e.preventDefault();
        if (!mouseDown) return;
        const x = e.pageX - productCardSlider.offsetLeft;
        const scroll = x - startX;
        productCardSlider.scrollLeft = scrollLeft - scroll;
      });
      productCardSlider.addEventListener(HtmlEvent.MouseDown, e => {
        mouseDown = true;
        startX = e.pageX - productCardSlider.offsetLeft;
        scrollLeft = productCardSlider.scrollLeft;
      }, false);
      productCardSlider.addEventListener(HtmlEvent.MouseUp, () => mouseDown = false, false);
      productCardSlider.addEventListener(HtmlEvent.MouseLeave, () => mouseDown = false, false);
    }
  }

  /**
   * Pone en pausa la ejecución de una tarea
   * @param time Milisegundos a suspender tarea
   */
  static async wait(time: number) {
    await new Promise((resolve) => setTimeout(resolve, time));
  }

  static getUrlAssets(value: string, baseService: IBaseService): string {
    return `${baseService.getUrlAssets(AssetsType.ServicesNa)}${value}.${FileFormats.Png}`;
  }

  /**
   * Validate email list separated by comma(,)
   */
  static validateEmailList(): ValidationErrors | null {
    return (control: AbstractControl) => {
      return control.value && String(control.value).indexOf(Char.Comma) > -Numbers.One ?
        String(control.value)
          .gfiReplaceAll(Char.WhiteSpace, Strings.EMPTY)
          .split(Char.Comma)
          .map(email => email ? Validators.email(new FormControl(email, Validators.email)) : { email: true })
          .find(val => val) :
        Validators.email(control);
    };
  }

  static getImageSource(image: string) {
    if (!image) return image;
    if (image.indexOf(Char.Pipe) < Numbers.Zero) {
      return image;
    }
    const [, mime, src] = image.split(Char.Pipe);
    return Constants.CREATE_PDF(Constants.IMAGE_DATA, mime, FileFormats.Base64Image, src);
  }

  static resizeImage(imageSource: string): Promise<string> {
    return new Promise(resolve => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement(ElementsHTML.Canvas);
        const ctx = canvas.getContext(ElementsHTML.Canvas2d);
        ctx.drawImage(img, Position.Zero, Position.Zero, img.width, img.height);
        resolve(canvas.toDataURL());
      };
      img.src = imageSource;
    });
  }

  static maskNumber(cardNumber: string) {
    const removeDigits = Constants.DIGITS_REMOVED;
    const accountNumber = cardNumber.replace(Regex.MatchFourDigits, Char.Asterisk);
    return accountNumber.substr(accountNumber.length - removeDigits);
  }

  static currencyFormat(inputName: string, formGroup: FormGroup) {
    const amount = formGroup.get(inputName).value;
    const val = amount.replace(Char.CurrencySymbol, Strings.EMPTY);
    const str = parseFloat(val).toLocaleString(Constants.LOCALE_EN_US, {
      maximumFractionDigits: Constants.TOTAL_DECIMALS, minimumFractionDigits: Constants.TOTAL_DECIMALS
    }).replace(Regex.Slash, Char.Dot).replace(Regex.MatchComma, Char.Comma);

    formGroup.patchValue({
      [inputName]: isNaN(parseFloat(str)) ? Strings.EMPTY : `${Char.CurrencySymbol}${str}`
    });
  }

  static changeFormatDate(valueDate: string) {
    const dateSplit = valueDate.split(Char.MiddleDash);
    const year = dateSplit[Position.Zero];
    const month = dateSplit[Position.One];
    const day = dateSplit[Position.Two];
    const newDate = new Date(`${month}${Char.MiddleDash}${day}${Char.MiddleDash}${year}`);
    const newYear = newDate.getFullYear();
    const newMonth = newDate.getMonth() + Numbers.One;
    const newDay = newDate.getDate();
    const newMonthAux = (newMonth < Numbers.Ten) ? `${Numbers.Zero}${newMonth.toString()}` : newMonth.toString();
    const newDayAux = (newDay < Numbers.Ten) ? `${Numbers.Zero}${newDay.toString()}` : newDay.toString();
    return `${newMonthAux}${Char.MiddleDash}${newDayAux}${Char.MiddleDash}${newYear.toString()}`;
  }

  static getElementPadding(element: HTMLElement | Element) {
    const units = Regex.Units;
    const top = window.getComputedStyle(element, null)[Constants.PADDING_TOP];
    const bottom = window.getComputedStyle(element, null)[Constants.PADDING_BOTTOM];
    return parseInt(top.replace(units, Strings.EMPTY)) + parseInt(bottom.replace(units, Strings.EMPTY));
  }

  static getElementMargin(element: HTMLElement | Element) {
    const units = Regex.Units;
    const top = window.getComputedStyle(element, null)[Constants.MARGIN_TOP];
    const bottom = window.getComputedStyle(element, null)[Constants.MARGIN_BOTTOM];
    return parseInt(top.replace(units, Strings.EMPTY)) + parseInt(bottom.replace(units, Strings.EMPTY));
  }


  static getModalMargin(element: HTMLElement | Element) {
    return this.getElementMargin(element) * Numbers.Two;
  }
  static resetScroll() {
    window.scroll(Numbers.Zero, Numbers.Zero);
  }

  static downloadFile(pdf: string, fileName: string) {
    const sourcePDF = `${FileFormats.CreatePDF}${pdf}`;
    const downloadLink = document.createElement(ElementsHTML.LabelA);
    const namePDF = `${fileName}${FileFormats.Pdf}`;
    downloadLink.href = sourcePDF;
    downloadLink.download = namePDF;
    downloadLink.click();
  }

  static shareFile(pdf: string, fileName: string) {
    pdf = `${FileFormats.CreatePDF}${pdf}`;
    fetch(pdf)
      .then(response => response.blob())
      .then(blob => {
        const file = new File([blob], `${fileName}${FileFormats.Pdf}`, { type: FileFormats.TypePDF });
        if (navigator.canShare) {
          navigator.share({
            files: [file]
          }).then(() => { });
        }
      });
  }



  static convertStringToDate(date: string, separator = Char.Slash) {
    const [day, month, year] = date.split(separator);
    return new Date(parseInt(year), parseInt(month) - Numbers.One, parseInt(day));
  }

  static orderByDate(firstElement: string, secondElement: string, ascendant = true) {
    const firstDate = Utils.convertStringToDate(firstElement);
    const secondDate = Utils.convertStringToDate(secondElement);
    if (firstDate === secondDate) {
      return Numbers.Zero;
    }
    else if (firstDate < secondDate) {
      return ascendant ? -Numbers.One : Numbers.One;
    }
    else {
      return ascendant ? Numbers.One : -Numbers.One;
    }
  }

  static convertInvestmentAccount(accountProductId: number, account: string, completeEnd: boolean = false) {
    let size = account.length + accountProductId.toString().length;
    let spaces = Strings.EMPTY;
    for (; size < Constants.INVESTMENT_ACCOUNT_SIZE; size++) {
      spaces += Numbers.Zero;
    }
    if (completeEnd) {
      return account + accountProductId.toString() + spaces;
    }
    return account + spaces + accountProductId.toString();
  }

  static sort(data: [], { sortBy, sortOrder }) {
    return data.sort((a: string[], b: string[]) => {
      a[sortBy] = a[sortBy] === null ? Strings.EMPTY : a[sortBy];
      b[sortBy] = b[sortBy] === null ? Strings.EMPTY : b[sortBy];
      if (sortOrder === Sorts.Ascending) {
        return a[sortBy] > b[sortBy] ? Numbers.One : Constants.NOT_FOUND_ITEM;
      } else {
        return a[sortBy] < b[sortBy] ? Numbers.One : Constants.NOT_FOUND_ITEM;
      }
    });
  }

  static sortDate(data: [], { sortBy, sortOrder }) {
    return data.sort((a: string[], b: string[]) => {
      const first = a[sortBy] === null ? null : Utils.convertStringToDate(a[sortBy]);
      const second = b[sortBy] === null ? null : Utils.convertStringToDate(b[sortBy]);

      if (sortOrder === Sorts.Ascending) {
        return first > second ? Numbers.One : -Numbers.One;
      } else {
        return first < second ? Numbers.One : -Numbers.One;
      }
    });
  }

  static gfiToRoute(path: string, deletePrefix: boolean = false) {
    if (path === null || path.length === Length.Empty || path.charAt(Position.Zero) !== Char.Slash) {
      return path;
    }
    const arrayRoute = path.split(Char.Slash);
    if (deletePrefix && arrayRoute.length > Length.Empty) {
      return arrayRoute[arrayRoute.length - Numbers.One];
    }
    return path.slice(Position.One);
  }

  static readonly SERVICE_ERROR_MSG = (errorMessage: string, errorCode: number) => `${errorMessage} ${Char.OpeningParenthesis}${errorCode}${Char.ClosingParenthesis}`;

  static validateEmailMatch(email: string, confirmationEmail: string) {
    let hasEmailError = false;
    let hasConfirmEmailError = false;
    let matchError = false;

    if (email.length !== Length.Empty || confirmationEmail.length !== Length.Empty) {
      const validEmail1 = email.match(Regex.Email);
      const validEmail2 = confirmationEmail.match(Regex.Email);

      if (validEmail1 == null) {
        hasEmailError = true;
      } else if (validEmail2 == null) {
        hasConfirmEmailError = true;
      }
      else if (email !== confirmationEmail) {
        matchError = true;
      }
    }
    return { hasEmailError, hasConfirmEmailError, matchError };
  }


  static groupAccount(value: string) {
    if (!value || value.length < Constants.GROUP_ACCOUNT) {
      return value;
    }
    return value
      .replace(Regex.Space, Strings.EMPTY)
      .replace(Regex.MatchFourCharacters, Regex.SplitClabe)
      .trim();
  }

  static formatClaimNumber(value: string) {
    if (value.length < Numbers.Five) {
      return value;
    }
    return value
      .replace(Regex.MatchMiddleDash, Strings.EMPTY)
      .replace(Regex.MatchFiveCharacters, Regex.SplitMiddleDash);
  }

  static dateFormat(originDate: string, format: string) {
    const [day, month, year] = originDate.split(Char.Slash);
    const date = new Date(parseInt(year), parseInt(month) - PositionDate.One, parseInt(day));
    return formatDate(date, format, Constants.LOCALE_ES_MX);
  }

  static dateMovements(monthMovements: number, addDays = Numbers.Zero) {
    const padStart = Numbers.Two;
    const date = new Date();
    date.setMonth(date.getMonth() + monthMovements);
    const year = date.getFullYear();
    const month = (date.getMonth() + Numbers.One);
    const day = date.getDate() + addDays;
    return `${(day).toString().padStart(
      padStart, Numbers.Zero.toString())}${Char.Slash}${(month).toString().padStart(
        padStart, Numbers.Zero.toString())}${Char.Slash}${(year).toString().padStart(padStart, Numbers.Zero.toString())}`;
  }

  static getAccountParams(account?: AccountModel, afore?: IAforePLResponse) {
    if (afore?.cuenta && afore.cuenta.idCuenta) {
      return { id: SystemId.Afore, account: afore.cuenta.idCuenta };
    } else {
      switch (account.tipoCuenta) {
        case AccountType.INBUMEX1:
        case AccountType.INBUMEX2:
        case AccountType.INBUMEX3:
          return { id: SystemId.Investment, account: Utils.convertInvestmentAccount(account.idCuentaProducto, account.numeroCuenta) };
        default:
          return { id: SystemId.Checks, account: account.numeroCuenta };
      }
    }
  }

  static sortAmount(data: number[], { sortBy, sortOrder }) {
    const dataSort = sortBy;
    const dataOrder = sortOrder;
    return data.sort((a, b) => {
      a[dataSort] = a[dataSort] == null ? Strings.EMPTY : a[dataSort];
      b[dataOrder] = b[dataOrder] == null ? Strings.EMPTY : b[dataOrder];
      if (sortOrder === Sorts.Ascending) {
        return a[sortBy] - b[sortBy];
      } else {
        return b[sortBy] - a[sortBy];
      }
    });
  }

  static changeAmountSize(amount: string) {
    const lengthMinL: number = Numbers.Four;
    const lengthMaxL: number = Numbers.Eleven;
    const lengthMinM: number = Numbers.Twelve;
    const lengthMaxM: number = Numbers.Fifteen;
    const smallSize: number = Numbers.Eight;

    let changeFontL = true;
    let changeFontM = false;
    let changeFontS = false;
    let smallAmount = false;

    if (amount.length >= lengthMinL && amount.length <= lengthMaxL) {
      changeFontL = true;
      changeFontM = false;
      changeFontS = false;
    }

    if (amount.length >= lengthMinM && amount.length <= lengthMaxM) {
      changeFontL = false;
      changeFontM = true;
      changeFontS = false;
    }

    if (amount.length > lengthMaxM) {
      changeFontL = false;
      changeFontM = false;
      changeFontS = true;
    }

    if (lengthMinL <= smallSize) {
      smallAmount = true;
    }

    return { changeFontL, changeFontM, changeFontS, smallAmount };

  }

  static restrictOnlyNumbers(keyEvent: KeyboardEvent) {
    return Regex.OnlyNumbers.test(keyEvent.key);
  }

  static restrictAlphanumeric(keyEvent: KeyboardEvent) {
    return Regex.ValidateLettersNumbers.test(keyEvent.key);
  }

  static formatValue(value: string, format: Format): string {
    switch (format) {
      case Format.Currency:
        return new CurrencyPipe(Constants.LOCALE_EN_US).transform(value);
      case Format.Date:
        return (value.length > Numbers.Ten ? value.substring(Numbers.Zero, Numbers.Ten) : value).gfiReplaceAll(Char.MiddleDash, Char.Slash);
      case Format.Card:
        return Strings.HIDDEN_CARD_DIGITS.FourDigits + (value.length > Constants.REMOVE_DIGITS_TO_MASK_ACCOUNT ? value.substr(
          value.length - Constants.REMOVE_DIGITS_TO_MASK_ACCOUNT) : value);
      case Format.TransactionsDate:
        return Utils.dateFormat(value, FormatDate.FormatDateTransactionsCard);
      default:
        return value;
    }
  }

  static calculateAmountWidth(size: number, currentWidth: number) {
    if (size <= Constants.AMOUNT_WIDTH.xs.size) {
      return Constants.AMOUNT_WIDTH.xs.width;
    }
    else if (size >= Constants.AMOUNT_WIDTH.sm.size) {
      return Constants.AMOUNT_WIDTH.sm.width;
    }
    else if (size >= Constants.AMOUNT_WIDTH.md.size) {
      return Constants.AMOUNT_WIDTH.md.width;
    }
    else if (size >= Constants.AMOUNT_WIDTH.l.size) {
      return Constants.AMOUNT_WIDTH.l.width;
    }
    else if (size >= Constants.AMOUNT_WIDTH.xl.size) {
      return Constants.AMOUNT_WIDTH.xl.width;
    }
    else if (size >= Constants.AMOUNT_WIDTH.xxl.size) {
      return Constants.AMOUNT_WIDTH.xxl.width;
    }
    else {
      return currentWidth;
    }
  }

  static async navigationError(router: Router, navigation?: { tab: Tab, notifyRouteService: NotifyRouteService }) {
    if (AppUtils.platform.isApp && AppUtils.platform.isXamarin) {
      const navigationParams: AppNavigation = {
        Data: AppScreen.Home
      };
      await AppBridge.invoke<string>(AppMethod.SendNativeScreen, navigationParams);
    } else {
      if (navigation?.tab) {
        navigation.notifyRouteService.navigateToTab(navigation.tab);
      } else {
        router.navigate([PATH.Home]);
      }
    }
  }

  static convertDateToText(date: string) {
    const dateSplit = date.split(Char.Slash);
    const day = dateSplit[Position.Zero];
    const month = dateSplit[Position.One];
    const year = dateSplit[Position.Two];
    const months: string[] = Object.values(Months);
    const monthNumber = parseInt(month);
    const newMonth = months[monthNumber - Numbers.One];
    return `${day} ${Strings.OF} ${newMonth} ${year}${Char.Comma}`;
  }

  static getAccountImage(image: string) {
    if (!image || image.indexOf(Resources.NULL_IMAGE_URL) !== Constants.NOT_FOUND_ITEM) {
      return Resources.ASSETS_GENERIC_DEBIT_CARD();
    } else {
      return `${Resources.getAssetPath(image, true)}`;
    }
  }

  static getDateNumberDaysAgo(date: Date, days: number): Date {
    date.setDate(date.getDate() - days);
    return date;
  }

  static isDateStringValid(dateString: string, separator = Char.Slash) {
    const date = Utils.convertStringToDate(dateString, separator);
    if (isNaN(date.getTime())) {
      return false;
    }

    const [day, month, year] = dateString.split(separator);
    const originalDate = {
      day: parseInt(day),
      month: parseInt(month),
      year: parseInt(year)
    };

    if (String(originalDate.year).length !== String(date.getFullYear()).length) {
      return false;
    }

    return originalDate.year === date.getFullYear() && originalDate.month === date.getMonth() + Numbers.One &&
      originalDate.day === date.getDate();
  }

  static removeAccents(str: string) {
    return str.normalize(Constants.CODE_NFD)
      .replace(Regex.MatchAccent, Regex.SplitClabe.trim())
      .normalize();
  }

  static removeOneLink(str: string) {
    const regex = new RegExp(Object.values(Strings.FAQ_TEXT_TO_REMOVE).join(Char.Pipe));
    return str.replace(regex, Strings.EMPTY);
  }

  static confirmLeavePage(link: string, modalService: ModalService) {
    const modalOptions: ModalOptions = {
      size: Constants.MODAL_OPTIONS.SizeMd,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalLeavePage,
      centered: true
    };

    const ref = modalService.open(ModalLeavePageComponent, modalOptions);
    ref.componentInstance.href = link;
  }

  static showModalTransactions(modalService: ModalService, customModal:boolean = false) {
    modalService.close();
    const modalOptions: ModalOptions = {
      windowClass: Constants.MODAL_OPTIONS.NoBorder,
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeMd,
      modalDialogClass: customModal ?
      ModalConstants.MODAL_OPTIONS.ModalMovementsCard : ModalConstants.MODAL_OPTIONS.ModalAccountInformation
    };
    modalService.open(ModalTransactionsComponent, modalOptions, customModal);
  }

  static showWebCardmodal(modalService: ModalService, modalDialogClass: string = ModalConstants.MODAL_OPTIONS.ModalWebCard) {
    const modalOptions: ModalOptions = {
      centered: true,
      size: Strings.EMPTY,
      modalDialogClass
    };
    modalService.open(ModalWebCardComponent, modalOptions);
  }

  static imageCDN(file: string, card: string) {
    let showImgCDN = false;
    let value = false;
    if (!file.includes(Char.Slash)) { showImgCDN = true; }
    switch (card) {
      case Strings.INVESTMENT_FUNDS.FundsSale.ConfirmProcess.CaseAccount:
        value = showImgCDN;
        break;
      case Strings.INVESTMENT_FUNDS.FundsSale.ConfirmProcess.CaseAddressee:
        value = showImgCDN;
        break;
      default:
    }
    return value;
  }

  static validateNavigationGuard(url: string, partialGuard: string[], personId: number, session: string, isSessionGuard: boolean) {
    if (partialGuard.includes(url) && isSessionGuard) {
      return session != null;
    } else if (partialGuard.includes(url) && !isSessionGuard) {
      return personId == null;
    }
    return personId != null && session != null;
  }

  static openModalLimitExceeded(modalService: ModalService) {
    const options: ModalOptions = {
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalCtAlertLimitClass,
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeMd
    };

    modalService.open(ModalAlertLimitComponent, options);
  }

  static validateExpressThirdTrasfer(isExpress: boolean, bank: string, isThirdTransfer: boolean) {
    if (!isExpress) return isThirdTransfer;
    return !bank.toLowerCase().includes(Constants.INBURSA_BANK.toLowerCase());
  }

  static showModalVoluntarySavingsContributions(modalService: ModalService) {
    const modalOptions: ModalOptions = {
      size: Constants.MODAL_OPTIONS.SizeXl,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalVoluntarySavingsType
    };

    modalService.open(ModalVoluntarySavingsTypeComponent, modalOptions);
  }

  static sortAccounts(accountsType: string, category: string) {
    if (accountsType === AccountType.NOM) {
      return Position.Zero;
    } else if (category === AccountType.Eje && accountsType !== AccountType.PAT && accountsType !== AccountType.GroceryCoupon && accountsType !== AccountType.ClaroPay) {
      return Position.One;
    } else if (accountsType === AccountType.PAT) {
      return Position.Two;
    } else if (accountsType === AccountType.ClaroPay) {
      return Position.Four;
    } else if (accountsType === AccountType.GroceryCoupon) {
      return Position.Five;
    } else {
      return Position.Three;
    }
  }

  static extractLabel(object: { value: string, label: string }[], value: string) {
    if (!value || value.trim() === Strings.EMPTY) {
      return null;
    }
    const item = object.find(currentItem => currentItem.value.toLowerCase() === value.toLowerCase());
    return item ? item.label : null;
  }

  static extractValue(object: {value: string, label: string} [],label: string){
    return object.find( item => item.label.toLowerCase() === label.toLowerCase()).value;
  }

  static normalizeString(str: string): string {
    if (!str) return Strings.EMPTY;
    const accentsMap: { [key: string]: string } = AddressConstants.NORMALIZE_CHARACTERS;
    return str.replace(FormRegex.NormalizeString, match => accentsMap[match] || match);
  }

  static contractPositions(value: string){
    return value.padStart(value.length + Numbers.Five, BrokerageHouseStrings.ZERO_CONTRACT);
  }

}
