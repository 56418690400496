import { PathModel } from '../../models/PathModel';

interface IGetCancellationWithdrawalsRequest{
  folio : number;
}

export class GetCancellationWithdrawalsRequest {
  constructor (public data: IGetCancellationWithdrawalsRequest){}

  get endpoint(): PathModel{
    return PathModel.CancellationWithdrawalsCodeFlex;
  }
}
