import { HostListener,ElementRef,Directive, Input } from '@angular/core';
import { Char, FileFormats, Keys, Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';

@Directive({
  selector: '[appValidateInputRegex]'
})
export class ValidateInputRegexDirective {
  pasteText: string = Strings.EMPTY;
  flagIme: boolean = false;
  flagBackspace: boolean = false;
  flagPaste: boolean = false;
  @Input() appValidateInputRegex: RegExp;
  constructor(private readonly element: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onInputKeyDown(event: KeyboardEvent) {
    if (event.key === Keys.Backspace) {
      this.flagBackspace = true;
      this.flagPaste = false;
    } else {
      this.flagBackspace = false;
    }
    this.flagIme = false;
    const regex =this.appValidateInputRegex;
    if (!(event.ctrlKey && event.key === Keys.V) && (event.key !== Keys.Backspace)) {
      if ((event.key !== Keys.Unidentified)) {
        if (!regex.test(event.key)) {
          event.preventDefault();
        }
      }
      else {
        this.flagIme = true;
      }
    }

  }

  @HostListener('input', ['$event'])
  onChange(event: Event): void {
    if (this.flagIme && !this.flagBackspace) {
      const regex = this.appValidateInputRegex;
      const initValue = this.element.nativeElement.value;
      if (!initValue) {
        return;
      }
      if (regex.test(initValue)) {
        return;
      }
      if (this.flagPaste) {
        const valueInput = this.element.nativeElement.value;
        const arrayPaste = this.pasteText.split(Strings.EMPTY);
        const arrayValue = valueInput.split(Strings.EMPTY);
        const valueValidate = arrayValue.filter(function (val) {
          return arrayPaste.indexOf(val) === -Numbers.One;
        });
        const valueAdd = this.getElementArray(arrayValue, arrayPaste);
        const valueConvert = this.setValueValidate(valueValidate, valueAdd);
        if (!(regex.test(valueConvert.toString()))) {
          this.element.nativeElement.value = this.element.nativeElement.value.slice(Numbers.Zero, -Numbers.One);
        }

      } else {
        if (!regex.test(this.element.nativeElement.value)) {
          this.element.nativeElement.value = this.element.nativeElement.value.slice(Numbers.Zero, -Numbers.One);
          event.preventDefault();
        }
      }
    }
  }
  getElementArray(arrayValue: string, arrayPaste: string []) {
    let valueAdd = Strings.EMPTY;
    if (arrayValue.length > arrayPaste.length) {
      for (let i = Numbers.Zero; i < arrayPaste.length; i++) {
        if (arrayPaste[i] !== arrayValue[i]) {
          valueAdd = arrayValue[i];
          break;
        }
      }
      if (valueAdd === Strings.EMPTY || valueAdd === undefined) {
        valueAdd = arrayValue[arrayValue.length - Numbers.One].toString();
      }
    }
    return valueAdd;
  }
  setValueValidate(valueValidate: string, valueAdd: string) {
    let valueConvert: string;
    if (valueValidate === Strings.EMPTY || valueValidate === undefined) {
      valueConvert = valueValidate.toString();
    } else {
      valueConvert = valueAdd;
    }
    if (valueConvert.includes(Char.Comma)) {
      valueConvert = valueConvert.replace(Char.Comma, Strings.EMPTY);
    }
    return valueConvert;
  }
  @HostListener('paste', ['$event']) blockPaste(block: ClipboardEvent) {
    this.flagPaste = true;
    this.pasteText = block.clipboardData.getData(FileFormats.TextPlain);
  }
}



