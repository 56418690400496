import { Injectable } from '@angular/core';
import { WebCardNumbers } from 'src/core/constants/WebCardStrings';
import { FormGroup } from '@angular/forms';
import { Strings } from 'src/core/constants/Strings';
import { Numbers, Position, Regex } from 'src/core/constants/Constants';

@Injectable({
  providedIn: 'root'
})
export class NipInputPasswordService {
  signature: string = Strings.EMPTY;
  maskedValue: string = Strings.EMPTY;
  previousLength: number = Numbers.Zero;
  wasAllSelected: boolean = false;
  isSelected: boolean = false;
  selectedStart: number;
  selectedEnd: number;
  nip:string = Strings.EMPTY;

  handleSelect($event: Event, inputName?: string) {
    const input = $event.target as HTMLInputElement;
    if (input.selectionStart !== input.selectionEnd) {
      this.isSelected = true;
      this.wasAllSelected = input.selectionStart === Position.Zero && input.selectionEnd === input.value.length;
      this.selectedStart = input.selectionStart;
      this.selectedEnd = input.selectionEnd;
    }
  }
  onlyNumbers($event: Event, inputName: string, formGroup: FormGroup, maxLength?: number) {
    const input = $event.target as HTMLInputElement;
    const cursorPosition = input.selectionStart || Numbers.Zero;
    let lastChar: string;
    if (this.isSelected) {
      this.valueSignatureSelected(cursorPosition, input.value);
      const diff = this.maskedValue.length - input.value.length;
      const start = input.selectionStart;
      this.signature = this.signature.slice(Position.Zero, start) + this.signature.slice(start + diff);
      lastChar = this.signature;
    } else {
      lastChar = input.value.gfiReplaceAll(String.fromCharCode(WebCardNumbers.AsciiPoint), Strings.EMPTY);
    }
    if (this.isSelected) {
      this.signature = Strings.EMPTY;
      this.maskedValue = String.fromCharCode(WebCardNumbers.AsciiPoint).repeat(this.signature.length);
      if (Regex.OneMoreNumbers.test(lastChar)) {
        this.signature = lastChar;
        this.maskedValue = String.fromCharCode(WebCardNumbers.AsciiPoint).repeat(this.signature.length);
      }
    } else if (input.value.length < this.maskedValue.length) {
      const diff = this.maskedValue.length - input.value.length;
      const start = input.selectionStart;
      this.signature = this.signature.slice(Position.Zero, start) + this.signature.slice(start + diff);
      this.maskedValue = String.fromCharCode(WebCardNumbers.AsciiPoint).repeat(this.signature.length);
    } else if (Regex.OneMoreNumbers.test(lastChar)) {
      if (cursorPosition <= this.signature.length) {
        if (this.signature.length !== maxLength) {
          this.signature = this.signature.slice(Position.Zero, cursorPosition - Position.One) + lastChar + this.signature.slice(cursorPosition - Position.One);
        }
      } else {this.signature += lastChar;}

      this.maskedValue = String.fromCharCode(WebCardNumbers.AsciiPoint).repeat(this.signature.length);
    } else {
      input.value = input.value.slice(Position.Zero, WebCardNumbers.MenusOne);
    }
    input.value = this.maskedValue;
    this.nip = this.signature;
    input.setSelectionRange(cursorPosition, cursorPosition);
    this.wasAllSelected = false;
    this.isSelected = false;
    formGroup.get(inputName).setValue(this.maskedValue, { emitEvent: false });
  }
  resetData(){
    this.signature = Strings.EMPTY;
    this.maskedValue = Strings.EMPTY;
    this.previousLength = Numbers.Zero;
    this.wasAllSelected = false;
    this.isSelected = false;
    this.selectedStart= Numbers.Zero;
    this.selectedEnd= Numbers.Zero;
    this.nip = Strings.EMPTY;
  }
  valueSignatureSelected(cursorPosition: number, value: string) {
    this.signature = this.wasAllSelected && cursorPosition === Numbers.One ? value : this.signature;
  }
}
