import { Injectable, signal } from '@angular/core';
import { Numbers } from 'src/core/constants/Constants';

@Injectable({
  providedIn: 'root'
})
export class AssociatedCardsService {
  private readonly MOCK: number[] = Array(Numbers.Six).fill('1234567890123456');

  private readonly _associatedCards = signal<number[]>(this.MOCK);

  constructor() {}

  get associatedCards() {
    return this._associatedCards();
  }

  set associatedCards(value: number[]) {
    this._associatedCards.set(value);
  }
}
