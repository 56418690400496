import { Injectable } from '@angular/core';
import { AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { AppBridge } from 'src/core/utils/AppBridge';
import { InsurancePolicyInfoStatusService } from 'src/app/services/insurance-policy-info-status.service';
import { AppAssistanceInsModel } from 'src/app/models/AppPolicyModel';
import { AppNavigation } from 'src/app/models/AppNavigation';


@Injectable({
  providedIn: 'root'
})
export class InsurancesBridgeService {


  constructor(private readonly insurancePolicyInfoStatusService : InsurancePolicyInfoStatusService) { }

  getInfoAssistanceIns(): AppAssistanceInsModel{
  return {
      Emisor: this.insurancePolicyInfoStatusService.insurancePolicy.Emisor,
      Poliza: this.insurancePolicyInfoStatusService.insurancePolicy.Poliza,
      Cis: this.insurancePolicyInfoStatusService.insurancePolicy.Cis,
      Familia: this.insurancePolicyInfoStatusService.insurancePolicy.Familia,
      Carpeta: this.insurancePolicyInfoStatusService.insurancePolicy.Carpeta,
      ObjetoAsegurado: this.insurancePolicyInfoStatusService.insurancePolicy.ObjetoAsegurado,
      Ramo: this.insurancePolicyInfoStatusService.insurancePolicy.Ramo
    };
  }
  navAssistanceInsurance(){
    const navigationParams: AppNavigation = {
      Data: AppScreen.InsurancePage,
      DataParams: JSON.stringify(this.getInfoAssistanceIns())
    };
    AppBridge.invoke<string>(AppMethod.SendNativeScreen,navigationParams);
  }
}