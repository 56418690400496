import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, WritableSignal, signal} from '@angular/core';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { BehaviorSubject, combineLatestWith, map, tap } from 'rxjs';
import { InputConfig } from 'src/app/interface/IInputConfig';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalHelpSignaturePanelComponent } from 'src/app/component/modal-help-signature-panel/modal-help-signature-panel.component';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import { InputTypes, Regex, ScrollOptions } from 'src/core/constants/Constants';
import { ButtonProperties } from 'src/app/interface/IButtonProperties';
import { ModalTermsAndConditionsComponent } from 'src/app/shared/components/modal-terms-and-conditions/modal-terms-and-conditions.component';
import { Router } from '@angular/router';
import { PATH } from 'src/core/constants/Path';
import { WebCardFormConfig, WebRegex } from 'src/app/models/WebCardForm';
import { Strings } from 'src/core/constants/Strings';
import { WebCardLayoutService } from 'src/app/services/web-card-layout.service';
import { FormUtils } from 'src/core/utils/FormUtils';
import { WebCardStrings } from 'src/core/constants/WebCardStrings';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { WebCardActiveService } from 'src/app/services/web-card-active.service';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';
import { ModalConstants } from 'src/core/constants/ModalConstants';

@Component({
  selector: 'app-web-card-data',
  templateUrl: './web-card-data.component.html',
  styleUrls: ['./web-card-data.component.css']
})
export class WebCardDataComponent implements OnInit, OnDestroy {
  @ViewChildren('inputs') inputs: QueryList<ElementRef>;
  private readonly accountData: BehaviorSubject<ICardAccount> = new BehaviorSubject<ICardAccount>(null);

  denyOnlyLettersNoAccentsNoSpace: RegExp = WebRegex.DenyOnlyLettersNoAccentsNoSpace;
  pathHome: string = PATH.Home;
  continueButton: string = Strings.CONTINUE_BUTTON;
  messageSelect: string = Strings.MESSAGES.Select;
  validationSelected = [Strings.FORM_MODIFY.Controls.CardNumber, Strings.FORM_MODIFY.Controls.SignaturePanel];
  subtitle: string = Strings.PAGE_HEADER.Subtitle;
  webCardTerms: string = WebCardStrings.WebCard.TERMS;
  declaration: string = WebCardStrings.WebCard.MODAL_TERMS_AND_CONDITION.DECLARATION;
  validationMessage: string =  WebCardStrings.WebCard.MODAL_TERMS_AND_CONDITION.VALIDATION_MESSAGE;
  contentMargin: string = WebCardStrings.WebCard.CLASSES.CONTENT_MARGIN_NOT_MOBILE;
  customClass: string = WebCardStrings.WebCard.CLASSES.CUSTOM_CLASS;
  title: string = WebCardStrings.WebCard.TITLE;
  inputTypes: string[] = [InputTypes.Text, InputTypes.Password, InputTypes.Phone];
  inputValidationType: InputConfig = null;
  webcardDataForm: FormGroup;
  formTerms: FormGroup;
  clearForm: boolean = true;
  sourceAccount: string = Strings.FROM_YOUR_ACCOUNT;
  applicantInformation: string = WebCardStrings.WebCard.LABELS_WEB_CARD.ApplicantInformation;
  mappedOptions: string[] = [];
  genderLabel: string = WebCardStrings.WebCard.WEB_FORM.GENDER.label;
  gender: string = WebCardStrings.WebCard.WEB_FORM.GENDER.name;
  isChargeError: WritableSignal<boolean> = signal<boolean>(false);
  charge: WritableSignal<string> = signal<string>(Strings.EMPTY);
  disableDeductibleSelect: WritableSignal<boolean> = signal<boolean>(false);

  vm$ = this.responsiveService.observe.pipe(
      tap((breakpoints) => {
        if (breakpoints.mobile) {
          this.contentMargin = WebCardStrings.WebCard.CLASSES.CONTENT_MARGIN_MOBILE;
        }else{
          this.contentMargin = WebCardStrings.WebCard.CLASSES.CONTENT_MARGIN_NOT_MOBILE;
        }
      }),
      combineLatestWith(this.accountData.asObservable()),
      map(([breakpoints, accountCard]) => ({ breakpoints, accountCard}))
  );

  continueProperties: ButtonProperties = {
    classes: WebCardStrings.WebCard.CLASSES.CONTINUE_PROPERTIES_CLASSES,
    doAction: () => {
      this.validateForm();
    }
  };

  inputConfig = WebCardFormConfig.map((input) => {
    if (input.name === Strings.VALIDATION_TYPE) {
      input.fnGeneric = this.validationTypeSelected.bind(this);
    }
    if(input.name === Strings.FORM_MODIFY.Controls.SignaturePanel){
      input.image.clickFunction = this.showModalHelpSignaturePanel.bind(this);
    }
    if (input.options) {
      this.mappedOptions = input.options.map((option) => {
        return option.value;
      });
    }
    return input;
  });

  constructor(
      private readonly modalService: ModalService,
      private readonly accountInfoStatusService: AccountInfoStatusService,
      private readonly dynamicFormService: DynamicFormService,
      private readonly formBuilder: FormBuilder,
      private readonly responsiveService: ResponsiveService,
      private readonly formTermService: WebCardLayoutService,
      private readonly router: Router,
      private readonly webCardActiveService: WebCardActiveService,
      private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    NavigationUtils.fixNavigation(this.changeDetector,[PATH.WebCardRequest]);
    this.initComponent();
  }

  private async initComponent() {
    if(!this.accountInfoStatusService.account?.numeroCuenta){
      this.router.navigate([PATH.Home]);
      return;
    }
    if (this.dynamicFormService.formBS.value) {
      this.webcardDataForm = this.dynamicFormService.formBS.value;
      this.charge.set(this.webcardDataForm.controls[this.gender].value ?? Strings.EMPTY);
      this.formTerms = this.formTermService.formTermsBs.value;
      this.formTerms.controls[this.webCardTerms].setValue(false);
      this.inputValidationType = this.inputConfig.find(
          (input) => input.name === this.webcardDataForm.controls[Strings.VALIDATION_TYPE]?.value
      );
    }
    else{
      this.buildForm();
    }

    await this.setWebAccount();
  }

  ngOnDestroy(): void {}

  showModalHelpSignaturePanel() {
    const modalOptions: ModalOptions = {
      size: Strings.EMPTY,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalHelpSignaturePanel
    };
    this.modalService.open(ModalHelpSignaturePanelComponent, modalOptions);
  }

  validateForm(){
    this.webcardDataForm.markAllAsTouched();
      this.webcardDataForm.updateValueAndValidity();
      this.formTerms.markAllAsTouched();
      this.validateCharge();
      if (this.webcardDataForm.valid && this.formTerms.valid &&  this.formTerms.controls[WebCardStrings.WebCard.TERMS].value === true) {
        this.dynamicFormService.formData = this.webcardDataForm;
        this.formTermService.formTermsData = this.formTerms;
        this.clearForm = false;
        this.router.navigate([PATH.WebCardRequestConfirmation]);
      }else{
        this.formTerms.controls[WebCardStrings.WebCard.TERMS].value === false && this.formTerms.controls[WebCardStrings.WebCard.TERMS].setValue(null);
        this.formTerms.get(WebCardStrings.WebCard.TERMS).setErrors({ required: true });
        this.formTerms.get(WebCardStrings.WebCard.TERMS).updateValueAndValidity();
        this.focusAndScrollToFirstInvalidInput();
      }
  }

  validateString(event: Event, name: string) {
    const secondLastName = WebCardStrings.WebCard.WEB_FORM.SECOND_LAST_NAME.name;
    const lastName = WebCardStrings.WebCard.WEB_FORM.LAST_NAME.name;
    if (!!!(event.target as HTMLInputElement).value.match(Regex.OnlyLettersAccents)) {
      this.webcardDataForm.controls[name].setValue(Strings.EMPTY);
    }
    if(name === lastName){
      this.webcardDataForm.controls[secondLastName].clearValidators();
      this.webcardDataForm.controls[secondLastName].updateValueAndValidity();
    }
    if(name === secondLastName){
      this.webcardDataForm.controls[lastName].clearValidators();
      this.webcardDataForm.controls[lastName].updateValueAndValidity();
    }
    if(this.webcardDataForm.controls[lastName].value === Strings.EMPTY &&
       this.webcardDataForm.controls[secondLastName].value === Strings.EMPTY){
       this.webcardDataForm.controls[lastName].setValidators(Validators.required);
       this.webcardDataForm.controls[secondLastName].setValidators(Validators.required);
       this.webcardDataForm.controls[lastName].updateValueAndValidity();
       this.webcardDataForm.controls[secondLastName].updateValueAndValidity();
    }
  }

  validateCharge(): boolean {
    this.isChargeError.set(this.charge() === Strings.EMPTY);
    return this.isChargeError();
  }

  validationTypeSelected(event: Event) {
    this.inputValidationType = this.inputConfig.find(
        (input) =>
            input.name === this.webcardDataForm.controls[Strings.VALIDATION_TYPE].value
    );

    this.webcardDataForm.patchValue({
      cardNumber: Strings.EMPTY,
      signaturePanel: Strings.EMPTY
    });

    this.validationSelected.forEach((element) => {
      this.webcardDataForm.get(element).setErrors(null);
      this.webcardDataForm.get(element).markAsUntouched();
      this.webcardDataForm.get(element).updateValueAndValidity();
    });
  }

  inputValue($event: Event, inputName: string) {
    this.webcardDataForm.controls[inputName].setValue($event.target[Strings.VALUE]);
    this.webcardDataForm.controls[inputName].markAsUntouched();
  }

  onBlur(inputName: string) {
    this.webcardDataForm.controls[inputName].markAsUntouched();
  }

  modalTermsAndConditions() {
    const modalOptions: ModalOptions = {
      size: Strings.EMPTY,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalTermsAndConditions
    };
    this.modalService.open(ModalTermsAndConditionsComponent, modalOptions);
  }

  buildForm() {
    this.webcardDataForm = this.dynamicFormService.toFormGroup(
        this.inputConfig
    );

    this.formTerms = this.formBuilder.group({
      terms: [Strings.EMPTY, Validators.required]
    });
  }

  setCharge(publicCharge: string, input: string) {
    this.webcardDataForm.get(input).setValue(publicCharge);
    this.charge.set(publicCharge);
    this.isChargeError.set(false);
  }

  onlyNumbers($event: Event, inputName: string,formGroup: FormGroup) {
    FormUtils.onlyNumbersInput($event, inputName, formGroup);
  }

  focusAndScrollToFirstInvalidInput() {
    const invalidInput = this.inputs.find(input => input.nativeElement.classList.contains(WebCardStrings.WebCard.CLASSES.NG_INVALID));
    if (invalidInput) {
      if (invalidInput.nativeElement.tagName !== InputTypes.Select){
        invalidInput.nativeElement.focus();
      }
      invalidInput.nativeElement.scrollIntoView({ behavior: ScrollOptions.Smooth, block: WebCardStrings.WebCard.CLASSES.CENTER });
    }
  }

  removeSpecialCharacters(name: string) {
    const specialCharacters = Regex.DenyOnlyLettersNoAccents;
    this.webcardDataForm.controls[name].setValue(this.webcardDataForm.controls[name].value.replace(specialCharacters, Strings.EMPTY));
    this.webcardDataForm.controls[name].markAsUntouched();
  }

  async setWebAccount() {
    const account = await this.webCardActiveService.getWebAccount();
    this.accountData.next({
      image: account.directorioImagen,
      clabe: account.cuentaClabe,
      cardNumber: account.numeroCuenta,
      balance: account.saldos?.total?.toString(),
      cardType: account.tipoCuenta
    });
  }
}
