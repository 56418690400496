import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { PATH } from 'src/core/constants/Path';
import { AppUtils } from 'src/core/utils/AppUtils';
import { Utils } from 'src/core/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class SessionGuard  {
  private readonly partialGuard = [ PATH.LoginToken, PATH.ValidateToken, PATH.LoginPassword ];

  constructor(private readonly storageService: StorageService,
    private readonly router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {

    if (Utils.validateNavigationGuard(state.url, this.partialGuard,this.storageService.getUser()?.personId,this.storageService.getSession(true), true)){
      return true;
    }
    if(AppUtils.platform.isApp && state.url === PATH.FrequentlyQuestions){
      return true;
    }
    this.router.navigate([PATH.Login]);
    return false;
  }
}
