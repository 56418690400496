<ng-container *ngIf="vm$ | async as vm">
  <div *ngIf="vm.breakpoints.mobile && showNotifyInvestment && !vm.isCodeflex"
    class="d-flex flex-row-reverse direction-end margin-modal" ngbAutofocus>
    <img role="button" aria-label="Close" width="24" height="24" alt="Close" [src]="frameClose | cdnImage"
      (click)="close()" (keydown.enter)="close()">
  </div>
  <div *ngIf="vm.insuranceEvent; else accountStatus">
    <div class=" drop-shadow-m card-info-promo card-info-promo-insurances d-flex justify-content-between
     align-items-center " [ngClass]="!vm.sidebar?'card-resolution':''">
      <div class="d-flex flex-column">
        <span class="detail-m-highlight">
          Vence
        </span>
        <span class="detail-m">
          {{ vm.insurance.Vencimiento | customDate:'dd/Month/yyyy'}}
        </span>
      </div>
      <button class="colored-btn insurances-btn-color button-s"
        (keydown.enter)="$event.preventDefault()" (click)="modalInsurance()">
        Pagar
      </button>
    </div>
  </div>
  <ng-template #accountStatus>
    <div
      class="card-info-promo h-100"
      [class.drop-shadow-m]="!isModal && !showNotifyInvestment
      || (!isModal && showNotifyInvestment && !vm.breakpoints.mobile)"
      [class.p-3]="vm.customBreakpoint"
      [class.p-4]="!vm.customBreakpoint && !isModal"
      [class.pt-3]="isModal"
      *ngIf="vm.canLoad || showNotifyInvestment"
      [ngClass]="{'investment-card' : (showNotifyInvestment && !vm.isCodeflex), 'w-afore-card': aforeModule
      && !vm.breakpoints.mobile}">
      <p
        [class.custom-mb]="vm.customBreakpoint"
        [class.mb-1]="isModal"
        [class.mb-investment]="showNotifyInvestment && !vm.isCodeflex"
        class="header-xs">
        {{labelTitle}}
      </p>
      <span *ngIf="isModal" class="detail-m .secondary-color">Selecciona el periodo a consultar</span>
      <div
        [class.mt-3]="isModal"
        [ngClass]="{
        'flex-desktop': (!showNotifyInvestment || vm.isCodeflex) || (showNotifyInvestment && !vm.isCodeflex)
        && optionElement === 'investment',
        'flex-desktop-account': (showNotifyInvestment && !vm.isCodeflex) && optionElement === 'account',
        'investment-flex gap-investment':
        (showNotifyInvestment && !vm.isCodeflex) && optionElement === 'investment' && vm.breakpoints.tablet,
        'display-afore': aforeModule,
        'flex-desktop-investment' : (showNotifyInvestment && !vm.isCodeflex) && !vm.breakpoints.mobile}">
        <div class="select-container position-relative"
        [ngClass]="aforeModule ? 'w-50-afore'
        : (showNotifyInvestment && !vm.isCodeflex) ||  isInvestmentCompanies ? 'select-container-investment' : ''">

          <ng-container *ngIf="isModal || (vm.isCodeflex && vm.breakpoints.mobile)">
            <div class="position-relative">
              <div ngbDropdown #mobileYearDropdown="ngbDropdown">
                <div id="dropdownMenu" ngbDropdownToggle class="cursor-pointer form-select mobile-dropdown-border">
                  <div class="position-relative w-100">
                    <span>{{ selectedMobilePeriod.year == '' ? 'Selecciona' : selectedMobilePeriod.year }}</span>
                  </div>
                </div>
                <div ngbDropdownMenu class="dropdown-item-container">
                  <ng-container *ngIf="mobilePeriods?.length > 0 else noYears"></ng-container>
                  <ng-container *ngFor="let period of periodYears">
                    <div ngbDropdownItem id="userMenuItem"
                      (keydown.enter)="$event.preventDefault()" (click)="mobileOnChange(period)">
                      <span class="detail-m-highlight">
                        {{ period }}
                      </span>
                    </div>
                  </ng-container>
                </div>
                <ng-template #noYears>
                  <div ngbDropdownItem id="userMenuItem" class="no-margin-paragraph">
                    <p class="detail-m-highlight no-margin-paragraph" [innerHTML]="vm.noYears">
                    </p>
                  </div>
                </ng-template>
              </div>
              <span class="detail-m position-absolute mobile-dropdown-year-label"> Año </span>
            </div>
            <div class="position-relative mt-3 pt-2">
              <div ngbDropdown #mobileMonthDropdown="ngbDropdown">
                <div id="dropdownMenu" ngbDropdownToggle class="cursor-pointer form-select mobile-dropdown-border">
                  <div class="position-relative w-100">
                    <span>{{ selectedMobilePeriod.month == '' ? 'Selecciona' : selectedMobilePeriod.month }}</span>
                  </div>
                </div>
                <div ngbDropdownMenu class="dropdown-item-container"
                *ngIf="selectedMobilePeriod.year || showmessageError">
                  <ng-container *ngIf="mobilePeriods?.length > 0 else noMonths"></ng-container>
                  <ng-container *ngFor="let period of mobileMonthPeriods">
                    <div ngbDropdownItem id="userMenuItem"
                      (keydown.enter)="$event.preventDefault()" (click)="selectedMobilePeriod = period;
                      monthsFilter = period.idPeriod.toString()">
                      <span class="detail-m-highlight">
                        {{ period.month }}
                      </span>
                    </div>
                  </ng-container>
                </div>
              </div>
              <span class="detail-m position-absolute mobile-dropdown-month-label"> Mes </span>
            </div>
            <ng-template #noMonths>
              <div ngbDropdownItem id="userMenuItem" class="no-margin-paragraph">
                <p class="detail-m-highlight no-margin-paragraph" [innerHTML]="vm.noMonths">
                </p>
              </div>
            </ng-template>
          </ng-container>
        <ng-container *ngIf="!isModal">
          <ng-container *ngIf=" (!showNotifyInvestment || vm.isCodeflex) && accountStatementPDF | async as periods">
            <div ngbDropdown #myDropdownPeriods="ngbDropdown">
            <div id="dropdownMenu" ngbDropdownToggle class="cursor-pointer form-select"
              (keydown.enter)="$event.preventDefault()" (click)="consultPeriods()">
                <div class="position-relative w-100">
                  <span class="">{{ monthsFilter ? formatDate(selectPeriod) : 'Selecciona un periodo'}}</span>
                </div>
              </div>
              <div ngbDropdownMenu class="dropdown-item-container">
                <ng-container *ngIf="periods?.ListPeriodos?.Periodo?.length > 0 else withoutPeriods">
                  <ng-container *ngFor="let period of periods?.ListPeriodos?.Periodo">
                    <div ngbDropdownItem id="userMenuItem" (keydown.enter)="$event.preventDefault()"
                    (click)="onChange(period.idPeriodo.toString())">
                      <p class="detail-m-highlight m-0">
                        {{ formatDate(period?.finPeriodo) }}
                      </p>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #withoutPeriods>
                  <div ngbDropdownItem id="userMenuItem" class="no-margin-paragraph">
                    <p class="detail-m-highlight no-margin-paragraph" [innerHTML]="vm.withoutPeriodsMessage">
                    </p>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="showNotifyInvestment && !vm.isCodeflex">
            <div ngbDropdown #myDropdownCuentas="ngbDropdown">
            <div id="floating-select" ngbDropdownToggle class="cursor-pointer form-select
             select-investment text-overflow-ellipsis width-select text-overflow-ellipsis-h-100"
             [ngClass]="vm.breakpoints.tablet ? 'width-tablet-select' : ''"
             (keydown.enter)="myDropdownCuentas.toggle()"
             [tabIndex]="0">
                <div class="position-relative overflow-investment">
                  <span class="">
                    {{ selectedCard ? selectedAccountNumber+ ' ' + descriptionAccount: 'Selecciona una cuenta'}}</span>
                </div>
              </div>
              <div ngbDropdownMenu class="dropdown-item-container">
                  <ng-container *ngFor="let account of vm.investment?.CheckingAccountModel">
                    <div ngbDropdownItem id="userMenuItem"
                     (click)="selectedCardInvestment( account.idCuentaProducto.toString())"
                     (keydown.enter)="selectedCardInvestment( account.idCuentaProducto.toString());
                     myDropdownCuentas.toggle()">
                      <p class="m-0 text-wrap">
                        {{ account.numeroCuenta + ' ' + account.descripcionCuenta }}
                      </p>
                    </div>
                  </ng-container>

              </div>
            </div>
            <label for="floating-select" class="detail-m position-absolute box-label">{{titleBox}}</label>
          </ng-container>

        </ng-container>


        </div>

        <div class="d-flex justify-content-center align-items-center " [ngClass]="{
          'btn-padding': (!showNotifyInvestment || vm.isCodeflex) && accountStatementPDF,
          'w-50-afore pad-afore mt-afore': aforeModule,
          'btn-padding-investment': (isInvestmentCompanies || (showNotifyInvestment && !vm.isCodeflex))
          && vm.breakpoints.mobile,
          'btn-padding-investment-account': (isInvestmentCompanies ||
          (showNotifyInvestment && !vm.isCodeflex)) && vm.breakpoints.desktop
           && optionElement === 'account'}">
          <button class="colored-btn border-btn-ok button-m"
            [disabled]="isModal?(vm.breakpoints.mobile? !selectedMobilePeriod.idPeriod: btnDisabled):btnDisabled"
            [class.disabled-btn]="
              isModal?(vm.breakpoints.mobile? !selectedMobilePeriod.idPeriod: btnDisabled):btnDisabled"
            [ngClass]="{'width-tablet-investments':vm.breakpoints.tablet && !isInvestmentCompanies,
            'width-investments-companies-btn': isInvestmentCompanies}" (keydown.enter)="$event.preventDefault()"
            (click)="getPeriodsPDF()">
            {{labelButton}}
          </button>
        </div>

      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #viewModal>
  <app-modal-payment-insurance-policies (modalPayment)="actionModal($event)"></app-modal-payment-insurance-policies>
</ng-template>
