import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { Resources } from 'src/core/constants/Resources';

@Component({
  selector: 'app-loader',
  template: `
    <div id="spinner" class="mask" *ngIf="isLoading$ | async">
      <div class="components centered-spinner">
        <img class="loader" [src]="spinner | cdnImage" width="48px" height="48px" title="load"/>
      </div>
    </div>
  `,
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {
  isLoading$: Observable<boolean> = this.loaderService.isLoading;

  spinner: string = Resources.VECTOR;

  constructor(private readonly loaderService: LoaderService) { }
}
