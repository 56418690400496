export class IconResources {
  static readonly CHEVRON_RIGHT_CR_G = 'chevron_right_cr_fr_g.svg';
  static readonly ICON_CODI = 'codi.svg';
  static readonly ICON_DIMO = 'dimo.svg';
  static readonly FILE_DOWNLOAD_OU_LC = 'Condiciones.svg';
  static readonly COIN_01 = 'Retiro.svg';
  static readonly FILE_DOWNLOAD_IN_LC_CONTRAST = 'File_Download_in-lc.png';
  static readonly WARNING_BLUE = 'warning_blue.svg';
  static readonly FILE_TEXT_01 = 'file _text_01.svg';
  static readonly ARROW_UP_GRAPH_BLUE = 'arrow_up_graph_blue.svg';
  static readonly BAR_GRAPH_BLUE = 'bar_graph_blue.svg';
  static readonly ICON_TOKEN = 'token_icon.svg';
  static readonly ICON_PLUS_CIRCLE_LIGTH_BLUE = 'plus_circle_ligth_blue.svg';
  static readonly ICON_DELETE_DEVICE = 'delete_device.svg';
  static readonly ICON_ACTIVE_DEVICE = 'active_device.svg';
  static readonly ICON_ADD_NEW_IMAGE = 'add_image.svg';
  static readonly LAPTOP = 'Laptop.svg';
  static readonly MAIL_STATEMENT = 'Mail.svg';
  static readonly WITHDRAWAL_OPTION= 'bank_card eject_dark.svg';
  static readonly WITHDRAWAL_OPTION_WHITE= 'bank_card_eject_contrast.svg';
  static readonly DEPOSIT_OPTION_WHITE='bank_card_insert_white.svg';
  static readonly ICON_REQUEST_ASSISTANCE_INSURANCE = 'warning_blue.svg';
  static readonly ICON_CARD_DOWN_ARROW = 'icon_card_down_arrow.svg';
  static readonly ICON_CARD_DOWN_ARROW_WHITE = 'icon_card_down_arrow_white.svg';
  static readonly CANCEL_GRAY = 'gray_close_icon.svg';
  static readonly CHEVRON_DOWN = 'chevron_down_gray.svg';
  static readonly BANK_CARD_SEARCH_WHITE = 'bank_card_search_white.svg';
  static readonly BANK_CARD_LOCKED_ALL_WHITE = 'bank_card_locked_white.svg';
}