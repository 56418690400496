
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PermittedOperations {
  private readonly _dataDefault = { codi: false, dimo: false };
  private readonly _operation: BehaviorSubject<{ codi: boolean, dimo: boolean }> = new BehaviorSubject<{ codi: boolean, dimo: boolean }>(this._dataDefault);

  constructor() { }

  get operation$() {
    return this._operation.asObservable();
  }

  set operation(data: { codi: boolean, dimo: boolean }) {
    this._operation.next(data);
  }

  get operation() {
    return this._operation.getValue();
  }

  clearData() {
    this.operation = this._dataDefault;
  }

}
