import { Injectable, Injector, WritableSignal, signal } from '@angular/core';
import { GetBrokerageHouseThirdPartiesRequest } from 'src/app/interface/dto/GetBrokerageHouseThirdPartiesRequest';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { StorageService } from 'src/app/services/storage.service';
import { GetBrokerageHouseThirdPartiesResponse } from 'src/app/interface/dto/GetBrokerageHouseThirdPartiesResponse';
import { AgendaType } from 'src/core/constants/AddressConstants';
import { TransfersResources } from 'src/core/constants/TransferResources';
import { RegistrationBrokerageHouseRequest } from 'src/app/interface/dto/RegistrationBrokerageHouseRequest';
import { RegistrationBrokerageHouseResponse } from 'src/app/interface/dto/RegistrationBrokerageHouseResponse';
import { Numbers } from 'src/core/constants/Numbers';
import { BrokerageHouseStrings } from 'src/core/constants/BrokerageHouseStrings';
import { GetContractInformationRequest } from 'src/app/interface/dto/GetContractInformationRequest';
import { GetContractInformationResponse } from 'src/app/interface/dto/GetContractInformationResponse';
import { Constants, Format } from 'src/core/constants/Constants';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { Strings } from 'src/core/constants/Strings';
import { CancelBrokerageHouseThirdPartiesRequest } from 'src/app/interface/dto/CancelBrokerageHouseThirdPartiesRequest';
import { CancelBrokerageHouseThirdPartiesResponse } from 'src/app/interface/dto/CancelBrokerageHouseThirdPartiesResponse';
import { IModifyBrokerageHouseResponse } from 'src/app/interface/dto/ModifyBrokerageHouseResponse';
import { ModifyBrokerageHouseRequest } from 'src/app/interface/dto/ModifyBrokerageHouseRequest';
import { Utils } from 'src/core/utils/utils';
import { AccountModifyService } from 'src/app/services/info-accounts-edit.service';
import { CardAddresseeService } from 'src/app/services/card-addressee.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { EnterAmountErrorService } from 'src/app/services/enter-amount-error.service';
import { AlertService } from 'src/app/services/alert.service';
import { Resources } from 'src/core/constants/Resources';
import { ModalService } from 'src/app/shared/modal.service';

export type AddresseeBrokerageHouse = {
  contractNumber: string,
  name: string,
  alias: string,
  limit: string | number,
  email: string,
  emailConfirm: string,
  signaturePanel?: string
};

@Injectable({
  providedIn: 'root'
})
export class BrokerageHouseService {

  private readonly isBrokerage: WritableSignal<boolean> = signal(false);
  private readonly isContractValid: WritableSignal<boolean> = signal(true);
  private addresseeBrokerageHouse : AddresseeBrokerageHouse = null;
  private clabe : string = null;


  constructor(
    private readonly injector: Injector
  ) { }

  get baseService() {
    return this.injector.get(IBaseService);
  }

  get storage() {
    return this.injector.get(StorageService);
  }

  get cardAddresseeService() {
    return this.injector.get(CardAddresseeService);
  }

  get accountModifyService() {
    return this.injector.get(AccountModifyService);
  }

  get notifyAmountService() {
    return this.injector.get(NotifyAmountService);
  }

  get enterAmountErrorService() {
    return this.injector.get(EnterAmountErrorService);
  }

  get modalService() {
    return this.injector.get(ModalService);
  }

  get alertService() {
    return this.injector.get(AlertService);
  }

  get addresseeData(): AddresseeBrokerageHouse {
    return this.addresseeBrokerageHouse;
  }

  set addresseeData(data: AddresseeBrokerageHouse) {
    this.addresseeBrokerageHouse = data;
  }

  get getClabe(): string {
    return this.clabe;
  }

  set setClabe(clabe: string) {
    this.clabe = clabe;
  }

  get getIsBrokerage(): boolean {
    return this.isBrokerage();
  }

  set setIsBrokerage(data: boolean) {
    this.isBrokerage.update(()=>data);
  }

  get getIsContractValid(): boolean {
    return this.isContractValid();
  }

  set setIsContractValid(data: boolean) {
    this.isContractValid.update(()=>data);
  }

  clearAdresse(){
    this.addresseeBrokerageHouse = null;
    this.clabe = null;
    this.setIsContractValid = true;
  }

  async registrationBrokerageHouse(request: RegistrationBrokerageHouseRequest) {
    try {
      const response = await this.baseService.genericPost<RegistrationBrokerageHouseResponse>(request, { isSOA: true });

      if (!response || !response.idAgenda) {
        throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
      }
      return Strings.EMPTY;
    } catch (error) {
      const errorDetail = Utils.getErrorMsg(error);
      return Utils.SERVICE_ERROR_MSG(errorDetail.msg, errorDetail.code);
    }
  }

  async cancelBrokerageHouse(request: CancelBrokerageHouseThirdPartiesRequest) {
    const response = await this.baseService.genericPost<CancelBrokerageHouseThirdPartiesResponse>(request, { isSOA: true });
    if (parseInt(String(response.error.no)) === Constants.MSG_SERVICE_RESPONSE_SUCCESSFUL
    || parseInt(String(response.error.No)) === Constants.MSG_SERVICE_RESPONSE_SUCCESSFUL ) {

      await this.cardAddresseeService.getInitialData();
      const selectedAddressee = this.cardAddresseeService.selectedCardAddressee;
      const addresseeToCancel = this.accountModifyService.account;
      if (selectedAddressee.idAgenda === addresseeToCancel.idAgent) {
        this.cardAddresseeService.clearCardAddressee();
      }
    }
  }

  async modifyBrokerageHouse(request: ModifyBrokerageHouseRequest) {
    let isError = false;
    try {
      const addresseeToModify = this.accountModifyService.account;
      await this.baseService.genericPost<IModifyBrokerageHouseResponse>(request, { isSOA: true });
      await this.cardAddresseeService.getThirdPartyAccounts();
      const selectedAddressee = this.cardAddresseeService.selectedCardAddressee;
      const amount = parseFloat(Utils.transformAmount(this.notifyAmountService.amount.amount));
      if (selectedAddressee.idAgenda === addresseeToModify.idAgent) {
        selectedAddressee.limite_diario = request.data.nuevoLimite.toString();
        selectedAddressee.nombre_alias = request.data.alias;
        selectedAddressee.email = request.data.eMail;
        this.cardAddresseeService.cardAddressee = selectedAddressee;
        const dailyLimit = parseFloat(selectedAddressee.limite_diario);
        if(amount > dailyLimit){
          this.enterAmountErrorService.amountErrorData = {
            isError: true, errorMsg: Strings.MAX_AMOUNT_ERROR_MSG(Utils.formatValue(dailyLimit.toString(), Format.Currency))};
        } else {
          this.enterAmountErrorService.amountErrorData = { isError: false, errorMsg: Strings.EMPTY };
        }
      }

    } catch (error) {
      isError = true;
      const errorDetails = Utils.getErrorMsg(error);
      this.modalService.close();
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      }, {
        onSuccess: () => this.modalService.close(),
        onClose: () => this.modalService.close()
      });
    }
    return {isError};
  }

  async getBrokerageHouseAgendasThirdParties() {
    const request = new GetBrokerageHouseThirdPartiesRequest({
      idSession: this.storage.getSession()
    });
    const accounts = [];
    const response = await this.baseService.genericPost<GetBrokerageHouseThirdPartiesResponse>(
      request,
      {
        isSOA: true
      }
    );

    if (response?.consultarAgendasCasaBolsa?.length) {
      for (const item of response.consultarAgendasCasaBolsa) {
        const getClabe = await this.setClabeContract(item.contratoCB);
        accounts.push({
          idAgenda: item.idAgenda,
          profile_image: TransfersResources.USER,
          nombre: item.titular,
          nombre_alias: item.alias,
          limite_diario: item.limiteDiario.toString(),
          limiteDiarioAcumulado: item.limiteDiarioAcumulado.toString(),
          type: AgendaType.BrokerageHouse,
          contrato: item.contratoCB,
          email: item.email,
          clabe: getClabe,
          banco: Constants.INBURSA_BANK
        });
      }
      accounts.sort((a, b) => a.contrato?.localeCompare(b.contrato));
    }
    return accounts;
  }

  async setClabeContract(contract: string){
    const response = await this.getClabeContract(contract);
    return response.cuenta;
  }

  async getClabeContract(value: string) {
    if(value.length < Numbers.Eleven)value = value.padStart(value.length + Numbers.Five, BrokerageHouseStrings.ZERO_CONTRACT);

    const request = new GetContractInformationRequest({
      IdSession: this.storage.getSession(),
      NumeroContrato: value
    });

    return this.baseService.genericPost<GetContractInformationResponse>(request,{isSOA: true});
  }
}
