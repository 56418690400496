import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../shared/modal.service';

@Component({
  selector: 'app-federal-taxes-payment',
  templateUrl: './federal-taxes-payment.component.html',
  styleUrls: ['./federal-taxes-payment.component.css']
})
export class FederalTaxesPaymentComponent implements OnInit {

  title: string;
  subtitle: string;
  ligaInformacion: string;

  constructor(private readonly modalService: ModalService) { }

  ngOnInit(): void {
    this.title = 'Pago de Impuestos Federales a través de la Banca Mexicana.';
    this.subtitle = 'PAGO DE DERECHOS, PRODUCTOS Y APROVECHAMIENTOS.';
    this.ligaInformacion = '';
  }
  ngOnDestroy() {
    this.modalService.close();
  }
}
