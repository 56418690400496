import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISaveProfile } from 'src/app/interface/ISaveProfile';
import { IBaseService } from './i-base-service.service';
import { StorageService } from './storage.service';
import { UserImageRequest } from '../interface/dto/UserImageRequest';
import { Constants, UserImageType } from 'src/core/constants/Constants';
import { UserImageResponse } from '../interface/dto/UserImageResponse';
import { Utils } from 'src/core/utils/utils';
import { SaveUserImageRequest } from '../interface/dto/SaveUserImageRequest';
import { SaveUserImageResponse } from '../interface/dto/SaveUserImageResponse';
import { UpdateUserImageRequest } from '../interface/dto/UpdateUserImageRequest';
import { UpdateUserImageResponse } from '../interface/dto/UpdateUserImageResponse';
import { Router } from '@angular/router';
import { Strings } from 'src/core/constants/Strings';
import { DeleteUserImageRequest } from '../interface/dto/DeleteUserImageRequest';
import { DeleteUserImageResponse } from '../interface/dto/DeleteUserImageResponse';

@Injectable({
  providedIn: 'root'
})
export class StateProfileService {
  private readonly _profile: BehaviorSubject<ISaveProfile> = new BehaviorSubject<ISaveProfile>(null);

  constructor(
    private readonly baseService: IBaseService,
    private readonly storageService: StorageService,
    private readonly router: Router
  ) { }

  async reloadImages(load: {profile? :boolean, thumbnail?: boolean} = {profile: true, thumbnail: true}) {
    const images = this.profile || { image: Strings.EMPTY, thumbnail: Strings.EMPTY };

    if (load.profile) {
      images.image = await this.getImage(UserImageType.Profile);
    }

    if (load.thumbnail) {
      images.thumbnail = await this.getImage(UserImageType.Thumbnail);
    }

    this.profile = images;
  }

  async saveImage(image: string, type: UserImageType) {
    const request = new SaveUserImageRequest({
      idUsuario: this.storageService.getUser().userId,
      imagen: image,
      tipo: type
    });

    await this.baseService.genericPost<SaveUserImageResponse>(request, { isSecure: true });
  }

  async updateImage(image: string, type: UserImageType) {
    const request = new UpdateUserImageRequest({
      idUsuario: this.storageService.getUser().userId,
      imagen: image,
      tipo: type
    });
    await this.baseService.genericPost<UpdateUserImageResponse>(request, { isSecure: true });
  }

  private async getImage(type: UserImageType) {
    if (!this.storageService.getUser()?.userId) {
      return Strings.EMPTY;
    }

    try {
      return (await this.baseService.genericPost<UserImageResponse>(new UserImageRequest({
        idUsuario: this.storageService.getUser().userId,
        tipo: type
      }), { isSecure: true }))?.datos?.imagen || Strings.EMPTY;
    }
    catch (error) {
      Utils.printLogError(error);
      Utils.navigationError(this.router);
      return Constants.IMAGE_DATA;
    }
  }

  async deleteImage(type: UserImageType) {
    const request = new DeleteUserImageRequest({
      idUsuario: this.storageService.getUser().userId,
      tipo: type
    });
    await this.baseService.genericPost<DeleteUserImageResponse>(request, { isSecure: true });
  }

  //#region GETTERS AND SETTERS

  get profile$() {
    return this._profile.asObservable();
  }

  get profile() {
    return this._profile.getValue();
  }

  set profile(data: ISaveProfile) {
    this._profile.next(data);
  }

  //#endregion

}
