import { PathModel } from 'src/app/models/PathModel';

export type IAforeMovements = {
  numeroCuenta: string,
  fechaInicial: string,
  fechaFinal: string
};

export class AforeMovementsRequest{
  constructor (public data: IAforeMovements) {}

  get endpoint():PathModel{
    return PathModel.AforeMovements;
  }
}
