import { Injectable, TemplateRef } from '@angular/core';
import { Constants, Numbers, Regex, Source } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ControlCardStrings } from 'src/core/constants/ControlCardStrings';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ModalMapComponent } from 'src/app/shared/components/modal-map/modal-map.component';
import { ModalRequestConfirmationComponent } from 'src/app/control-card/components/modal-request-confirmation/modal-request-confirmation.component';
import { ModalBranchesComponent } from 'src/app/component/modal-branches/modal-branches.component';
import { ModalAlertsComponent } from 'src/app/shared/components/modal-alerts/modal-alerts.component';
import { ModalEndProcessAlertComponent } from 'src/app/shared/components/modal-end-process-alert/modal-end-process-alert.component';
import { ModalMyCardsComponent } from 'src/app/control-card/components/modal-my-cards/modal-my-cards.component';
import { ModalMyCardsExtensionThreeService } from 'src/app/services/modal-my-cards-three.extension.service';
import { Utils } from 'src/core/utils/utils';
import { ModalMyCardsService } from 'src/app/control-card/components/modal-my-cards/service/modal-my-cards.service';
import { MapService } from 'src/app/services/map.service';
import { InputNames } from 'src/core/constants/ControlCardConstants';
import { ControlCardService } from 'src/app/services/control-card.service';
import { Regex as RegexConstants } from 'src/core/constants/RegexConstants';

@Injectable({
  providedIn: 'root'
})
export class ModalMyCardsExtensionTwoService {

  constructor(
    readonly componentExtensionThree:ModalMyCardsExtensionThreeService,
    public modalMyCardsService: ModalMyCardsService,
    private readonly mapService: MapService,
    private readonly controlCardService: ControlCardService
    ){}

  getInputAtmValue(context: ModalMyCardsComponent, val?: Event) {
    if ((val.target as HTMLInputElement).value !== Strings.EMPTY) {
      const value = context.atmInformation;
      const amount = Number(Utils.transformAmount((val.target as HTMLInputElement).value.replace(RegexConstants.ReplaceDots, RegexConstants.CaptureGroup))) ?? Numbers.Zero;
      context.atmInformation = isNaN(amount) ? Numbers.Zero : amount;
      if (Regex.AllDigits.test(value.toString())) {
        context.componentExtensionThree.hasAnyError(context);
        context.isContinueLockValid = context.atmInformation === Numbers.Zero;
        context.validateContinueLock(context.atmInformation);
        context.showErrorLoad = true;
      }
    }
  }

  getInputMonthlyValue(context: ModalMyCardsComponent, val?: Event) {
    if ((val.target as HTMLInputElement).value !== Strings.EMPTY) {
      const value = this.controlCardService.dataControls.Monthly.Amount;
      const amount = Number(Utils.transformAmount((val.target as HTMLInputElement).value.replace(RegexConstants.ReplaceDots, RegexConstants.CaptureGroup))) ?? Numbers.Zero;
      this.controlCardService.dataControls.Monthly.Amount = isNaN(amount) ? Numbers.Zero : amount;
      if (Regex.AllDigits.test(value.toString())) {
        context.componentExtensionThree.hasAnyError(context);
        context.isContinueLockValid = this.controlCardService.dataControls.Monthly.Amount !== Numbers.Zero;
        context.showErrorLoad = true;
      }
    }
  }
  getInputMovementsAtmValue(val: Event, context: ModalMyCardsComponent) {
    context.atmValue =  Number((val.target as HTMLInputElement).value);
    context.isContinueLockValid = context.atmValue !== Numbers.Zero;
    context.zeroAtm = context.atmValue === Numbers.Zero;
    context.showErrorLoad = true;
  }
  getInputCommerceValue(val: Event, context: ModalMyCardsComponent) {
    if ((val.target as HTMLInputElement).value !== Strings.EMPTY) {
      const value = context.commerceInformation;
      const amount = Number(Utils.transformAmount((val.target as HTMLInputElement).value.replace(RegexConstants.ReplaceDots, RegexConstants.CaptureGroup))) ?? Numbers.Zero;
      context.commerceInformation = isNaN(amount) ? Numbers.Zero : amount;
      if (Regex.AllDigits.test(value.toString())) {
        context.componentExtensionThree.hasAnyError(context);
        context.isContinueLockValid = context.commerceInformation !== context.getLocksLimitsInfo.Limits.Movements.Pos &&
          context.commerceInformation !== Numbers.Zero;
        context.showErrorLoad = true;
      }
    }
  }
  getInputMovementsCommerce(val: Event, context: ModalMyCardsComponent) {
    context.commerceValue =  Number(Utils.transformAmount((val.target as HTMLInputElement).value));
    context.isContinueLockValid =  context.commerceValue !== Numbers.Zero;
    context.zeroPos =  context.commerceValue === Numbers.Zero;
    context.validateContinueLock( context.commerceValue);
    context.showErrorLoad = true;
  }
  getInputOnlineValue(val: Event, context: ModalMyCardsComponent) {
    if ((val.target as HTMLInputElement).value !== Strings.EMPTY) {
      const value = context.onlineInformation;
      const amount = Number(Utils.transformAmount((val.target as HTMLInputElement).value.replace(RegexConstants.ReplaceDots, RegexConstants.CaptureGroup))) ?? Numbers.Zero;
      context.onlineInformation = isNaN(amount) ? Numbers.Zero : amount;
      if (Regex.AllDigits.test(value.toString())) {
        context.componentExtensionThree.hasAnyError(context);
        context.isContinueLockValid = context.onlineInformation !== Numbers.Zero;
        context.showErrorLoad = true;
      }
    }
  }
  getInputMovementsOnline(val: Event, context: ModalMyCardsComponent) {
    context.phoneValue = Number((val.target as HTMLInputElement).value);
    context.zeroInt = context.validateZeroMovements(context.phoneValue);
    if (context.phoneValue === Numbers.Zero) {
      context.isContinueLockValid = false;
    } else {
      context.isContinueLockValid = true;
    }
    context.showErrorLoad = true;
  }
  getInputMovementsForeing(val: Event, context: ModalMyCardsComponent) {
    context.foreignValue = Number((val.target as HTMLInputElement).value);
    if (context.foreignValue === Numbers.Zero) {
      context.isContinueLockValid = false;
    } else {
      context.isContinueLockValid = true;
    }
    context.showErrorLoad = true;
  }
  getInputMovementsCodi(val: Event, context: ModalMyCardsComponent) {
    context.codiValue = Number((val.target as HTMLInputElement).value);
    if (context.codiValue === Numbers.Zero) {
      context.isContinueLockValid = false;
    } else {
      context.isContinueLockValid = true;
    }
    context.showErrorLoad = true;
  }
  getInputCodiValue(val: Event, context: ModalMyCardsComponent) {
    if ((val.target as HTMLInputElement).value !== Strings.EMPTY) {
      const value = context.codiInformation;
      const amount = Number(Utils.transformAmount((val.target as HTMLInputElement).value.replace(RegexConstants.ReplaceDots, RegexConstants.CaptureGroup))) ?? Numbers.Zero;
      context.codiInformation = isNaN(amount) ? Numbers.Zero : amount;
      if (Regex.AllDigits.test(value.toString())) {
        context.isContinueLockValid = context.codiInformation !== context.getLocksLimitsInfo.Limits.Amounts.Codi;
        context.showErrorLoad = true;
      }
    }
  }
  getInputForeignValue(val: Event, context: ModalMyCardsComponent) {
    if ((val.target as HTMLInputElement).value !== Strings.EMPTY) {
      const value = context.foreinInformation;
      const amount = Number(Utils.transformAmount((val.target as HTMLInputElement).value.replace(RegexConstants.ReplaceDots, RegexConstants.CaptureGroup))) ?? Numbers.Zero;
      context.foreinInformation = isNaN(amount) ? Numbers.Zero : amount;
      if (Regex.AllDigits.test(value.toString())) {
        context.isContinueLockValid = context.foreinInformation !== context.getLocksLimitsInfo.Limits.Amounts.Pos;
        context.showErrorLoad = true;
      }
    }
  }

  validateInputNumber(event: Event, context?: ModalMyCardsComponent) {
    const stringLetter = (event.target as HTMLInputElement).value;
    const regex = Regex.OnlyNumbers;
    if (!regex.test((event.target as HTMLInputElement).value)) {
      (event.target as HTMLInputElement).value = stringLetter.slice(Numbers.Zero, Constants.NOT_FOUND_ITEM);
    }
  }
  async getOtp($event: Event, context: ModalMyCardsComponent) {
    context.otpValue = $event[Constants.OTP_POSITION];
    if (context.otpValue && context.selectedCvvTab) { context.registerCvvService(context.cardCvv); }
    if (context.otpValue && context.selectedModifyTab) { context.nipModificationService(); }
  }
  validateNip(nip: string, confirmPin: string, context: ModalMyCardsComponent) {
    context.modifystate = false;
    context.errorConfirmNip = nip !== confirmPin;
  }
  async getPin(context: ModalMyCardsComponent) {
    context.nipValue = context.adminCardForm.get(InputNames.Nip).value;
    context.confirmedPinValue = context.adminCardForm.get(InputNames.ConfirmPin).value;
    if (context.selectModify === Strings.ACTIVE && context.nipValue === context.confirmedPinValue
      && (context.nipValue && context.confirmedPinValue) && (context.nipValue.length && context.confirmedPinValue.length === Constants.MAX_LENGTH_NIP)) {
      context.getPinValue = context.confirmedPinValue;
      const modalOptions: ModalOptions = {
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeLg,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.OtpChecksConfirmation
      }; context.closeOtpModal = context.modalService.open(context.otpModal, modalOptions);
    }
  }

  getRegisterCvv(context: ModalMyCardsComponent) {
    if (context.selectCVV === Strings.ACTIVE && context.cardCvv.length === Constants.MAX_LENGTH_CVV) {
      const modalOptions: ModalOptions = {
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeLg,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.OtpChecksConfirmation
      };
      context.closeOtpModal = context.modalService.open(context.otpModal, modalOptions);
      context.componentExtensionTwo.backdropCustom();
    }
  }

  getDynamicCvvService(context: ModalMyCardsComponent) {
    context.activeCvv = false;
    context.dynamicCvvService();
  }

  processCvv(context: ModalMyCardsComponent) {
    if (context.getDataCardsInfo.Card.Registered) {
      context.getDynamicCvvService();
      context.hasCVV = true;
    } else { context.hasCVV = false; }
  }

  renovationOption(type: string, context: ModalMyCardsComponent) {
    context.modalService.close();
    if (type === Source.Mf2) {
      context.titleModalBranch = ControlCardStrings.TITLE_RENEWAL_TDD;
    } else {
      context.titleModalBranch = ControlCardStrings.TITLE_RENEWAL;
    }
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.MapModalChecks,
      windowClass:Constants.MODAL_OPTIONS.NoBorder
    };
    const ref = context.modalService.open(ModalMapComponent, modalOptions);
    this.backdropCustom();
    ref.componentInstance.footerTemplate = context.modalMapFooter;
    ref.componentInstance.customComponentTemplate = context.modalMapSelect;
    ref.componentInstance.context = context;
    ref.componentInstance.title = context.titleModalBranch;
    ref.componentInstance.subtitle = ControlCardStrings.MAP_SUBTITLE;
    ref.componentInstance.isAfore = true;
    ref.componentInstance.isCustomTemplate = true;
    ref.componentInstance.isControlCard = true;
  }

  continuetoRenovation(context: ModalMyCardsComponent) {
    context.clickedButton = true;
    context.mapForm.markAllAsTouched();
    if (context.clickedButton && context.mapForm.valid) {
      context.modalService.close();
      context.controlCardService.isRequesModal = true;
      const modalOptions: ModalConstants = {
        centered: true,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalRequestedRenewal,
        size: Constants.MODAL_OPTIONS.SizeLg
      };
      context.reception = false;
      context.renovation = true;
      const ref = context.modalService.open(ModalRequestConfirmationComponent, modalOptions);
      ref.componentInstance.showButtonClose = false;
      this.backdropCustom();

    } else { context.focusError(); }
  }

  receiveDataFromSearch(searchFilter: string, context: ModalMyCardsComponent) {
    this.mapService.filterDirections(searchFilter);
  }

  continueProcessCard(content: TemplateRef<ModalRequestConfirmationComponent>, context: ModalMyCardsComponent) {
    context.modalService.close();
    let modalOptions: ModalOptions = {};
    if (context.reception) {
      context.controlCardService.isResponseModal = true;
      modalOptions = {
        centered: true,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalSizeConfirmation,
        size: Constants.MODAL_OPTIONS.SizeXl
      };
    } else {
      context.controlCardService.isRequesModal = true;
      modalOptions = {
        centered: true,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalRequestedRenewal,
        size: Constants.MODAL_OPTIONS.SizeLg
      };
    }
    context.modalService.open(content, modalOptions);
  }

  continueRenovation(content: TemplateRef<ModalBranchesComponent>, context: ModalMyCardsComponent) {
    context.modalService.close();
    const modalOptions: ModalOptions = {
      centered: true,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalBranchesAdminCards,
      size: Constants.MODAL_OPTIONS.SizeXl,
      windowClass:Constants.MODAL_OPTIONS.NoBorder
    };
    context.modalService.open(content, modalOptions);
  }

  closeAlert(context: ModalMyCardsComponent) {
    context.modalService.modalInstance.close();
    context.selectCvvTab(true, null);
    context.buttonModalCard = Strings.CONTINUE_BUTTON;
    context.showDcvv = false;
    context.lblDcvv = context.registerCvvResponse.DCVV;
    context.hideCvvForm = false;
    context.selectedCvvTab = false;
    context.selectedCvvContinueTab = true;
    context.activeCvv = true;
    context.hideButton = true;
    context.hiddenModal(false);
  }

  renovationCard(content: TemplateRef<ModalBranchesComponent>, type: string, context: ModalMyCardsComponent) {
    context.replacement = false;
    context.renovation = true;
    context.reception = false;
    context.showSucursal = false;
    if (type === Source.Mf2) {
      context.titleModalBranch = ControlCardStrings.TITLE_RENEWAL_TDD;
    } else {
      context.titleModalBranch = ControlCardStrings.TITLE_RENEWAL;
    }
    context.continueRenovation(content);
  }

  cardCancellationModal(context:ModalMyCardsComponent, content?: TemplateRef<ModalAlertsComponent>) {
    context.modalService.close();
    const modalOptions: ModalOptions = {
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalCancelContinue,
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeMd
    };
    context.modalService.open(content, modalOptions);
  }

  cardQuestionModal(content: TemplateRef<ModalEndProcessAlertComponent>, context: ModalMyCardsComponent) {
    context.cancelstate = true;
    context.cancelForm.markAllAsTouched();
    if (context.cancelForm.valid) {
      context.controlCardService.cancelOption = context.cancelForm.get(ControlCardStrings.REASON).value;
      context.modalService.close();
      const modalOptions: ModalOptions = {
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalCancel,
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeSm
      };
      context.modalService.open(content, modalOptions);
    }
  }

  cardCVVDinamycModal(content: TemplateRef<ModalEndProcessAlertComponent>, context: ModalMyCardsComponent) {
    context.modalService.close();
    const modalOptionscvv: ModalOptions = {
      modalDialogClass: Constants.MODAL_OPTIONS.AriaLabel,
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeSm
    };
    context.modalService.open(content, modalOptionscvv);
  }

  async initComponent(context: ModalMyCardsComponent) {
    this.modalMyCardsService.initComponent(context);
  }

  backdropCustom(){
    this.modalMyCardsService.backdropCustom();
  }
}
