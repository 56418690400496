import { PathModel } from 'src/app/models/PathModel';

type TDepositAppliesPromissoryNoteRequest = {
    TipoApertura: string,
    DescReglaRendimiento: string,
    AbreviacionMoneda: string,
    Plazo: number,
    NumeroCuentaOrigen: string,
    FrecuenciaPago: null,
    IdCuentaProductoDestino: number,
    Otp: string,
    Origen: string,
    Periodo: string,
    CodigoReglaRendimiento: string,
    Contrato: null,
    Monto: number,
    FechaInicioPeriodo: string,
    IdSession: string,
    FechaVencimiento: string,
    IdCuentaProductoOrigen: number,
    TipoPago: string,
    NumeroCuentaDestino: string
};

export class DepositAppliesPromissoryNoteRequest {
    constructor(public data: TDepositAppliesPromissoryNoteRequest) { }

    get endpoint(): PathModel {
        return PathModel.DepositAppliesPromissoryNote;
    }
}
