import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IPartialWithdrawals, IPartialWithdrawalToUnemployment } from '../interface/dto/IPartialWithdrawals';
import { Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
  providedIn: 'root'
})

export class PartialWithdrawals {
  private readonly dataPartial: BehaviorSubject<IPartialWithdrawals> = new BehaviorSubject<IPartialWithdrawals>({
    account : Numbers.Zero,
    curp : Strings.EMPTY,
    nss : Numbers.Zero,
    origin : Strings.EMPTY,
    rfc : Strings.EMPTY,
    product : Strings.EMPTY});

  private readonly dataPartialUnemploymnet: BehaviorSubject<IPartialWithdrawalToUnemployment> = new BehaviorSubject<IPartialWithdrawalToUnemployment>({
    modality : Strings.EMPTY,
    reportedSalary : Strings.EMPTY,
    amount : Strings.EMPTY});

  constructor() {}

  get detailPartialObservable(): Observable<IPartialWithdrawals> {
    return this.dataPartial.asObservable();
  }

  get detailPartialBS(): BehaviorSubject<IPartialWithdrawals> {
    return this.dataPartial;
  }

  set detailPartialData(data: IPartialWithdrawals){
    this.dataPartial.next(data);
  }

  get detailUnemploymentObservable(): Observable<IPartialWithdrawalToUnemployment> {
    return this.dataPartialUnemploymnet.asObservable();
  }

  get detailUnemploymentBS(): BehaviorSubject<IPartialWithdrawalToUnemployment> {
    return this.dataPartialUnemploymnet;
  }

  set detailUnemploymentData(data: IPartialWithdrawalToUnemployment){
    this.dataPartialUnemploymnet.next(data);
  }
}
