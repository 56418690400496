import { PathModel } from 'src/app/models/PathModel';

type TModifyNonAgendableRequest = {
  sesion: string,
  nuevoLimiteDiario: number,
  codigoServicio: string,
  nombreAplicacion: string,
  token?: null,
  traza?: null,
  tipoAcceso: string,
  canal: string,
  ip: string,
  Token: {
    OTP: string,
    HAC?: null,
    SerialNumber?: null
  },
  Latitud: number,
  Longitud: number,
  IdAdicional?: null,
  IdPersonaTitular: number
};

export class ModifyNonAgendableRequest {
  constructor(public data: TModifyNonAgendableRequest) { }

  get endpoint(): PathModel {
    return PathModel.ModifyAgentServiceNonAgendable;
  }
}
