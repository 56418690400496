import { Component, HostListener, OnInit } from '@angular/core';
import { combineLatestWith, map } from 'rxjs';
import { Constants } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { ModalOptions } from 'src/app/interface/modal-options';
import { CheckBookActivationService } from 'src/app/services/check-book-activation.service';
import { DataCheckbookService } from 'src/app/services/data-checkbook.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { OtpCardReplacementComponent } from 'src/app/shared/components/otp-card-replacement/otp-card-replacement.component';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { StringsChecks } from 'src/core/constants/ChecksStrings';
import { Strings } from 'src/core/constants/Strings';
import { PATH } from 'src/core/constants/Path';
import { Router } from '@angular/router';
import { CheckBoxService } from 'src/app/services/checks-value-service';
import { ModalTransactionsComponent } from 'src/app/home/components/modal-transactions/modal-transactions.component';
import { TabsService } from 'src/app/services/tabs.service';
import { HomeChecksService } from 'src/app/services/home-checks.service';
import { ServiceCheckTabIdService } from 'src/app/services/service-check-tab-id.service';
import { OtherBanksChecksStrings } from 'src/core/constants/OtherBanksChecksStrings';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-checks-confirmation',
  templateUrl: './modal-checks-confirmation.component.html',
  styleUrls: ['./modal-checks-confirmation.component.css']
})
export class ModalChecksConfirmationComponent implements OnInit {
  chevronRight: string = Resources.BTN_CHEVRON_RIGHT;
  completeDate!: string;
  checkDataActivation: string;
  isApple: boolean = false;
  widthTablet: number = Constants.RESOLUTIONS.IpadMinWidth;
  activationConfirmation: string = StringsChecks.activationConfirmation;
  dataConfirmation: string = StringsChecks.dataConfirmation;
  operationInformation: string = StringsChecks.operationInformation;
  confirmAndContinue: string = Strings.BUTTONS.ConfirmAndContinue;
  modify: string = Strings.BUTTONS.Modify;
  modalInstance: NgbModalRef;
  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(this.checkActivationService.selectedCheckbookObservable),
    map(([breakpoints]) => ({ breakpoints }))
  );
  closeCircleBlue: string = Resources.CLOSE_CIRCLE_BLUE;

  constructor(private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly dataCheckBookService: DataCheckbookService,
    private readonly checkActivationService: CheckBookActivationService,
    private readonly activationCheckBook: CheckBookActivationService,
    private readonly router: Router,
    private readonly checkBoxService: CheckBoxService,
    private readonly tabsService: TabsService,
    private readonly homeChecksService: HomeChecksService,
    private readonly checkTabIdService: ServiceCheckTabIdService,
  ) { }

  ngOnInit(): void {
    this.isTabletMobile();
    this.dataCheckBookService.statusConfirm = true;
    this.checkDataActivation = this.checkActivationService.selectedOperation.numberCheckBook;
  }

  close(isMobile: boolean) {
    this.modalService.close();
    if (isMobile) {
      this.tabsService.tabCustom = OtherBanksChecksStrings.MY_CHECKS;
      this.homeChecksService.tabToSelect = StringsChecks.activation;
      this.checkTabIdService.ICheckTabIdData = { id: Constants.ACTIVATION };
      this.checkBoxService.setCheckHome(true);
      this.router.navigate([PATH.Home]).then(() => {
        const modalOptions: ModalOptions = {
          centered: true,
          size: Constants.MODAL_OPTIONS.SizeMd,
          windowClass: Constants.MODAL_OPTIONS.NoBorder,
          modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAccountInformation
        };
        this.modalService.open(ModalTransactionsComponent, modalOptions);
        this.tabsService.tabChanged = true;
        return;
      });
    }
    this.dataCheckBookService.statusConfirmOTP = false;
    this.activationCheckBook.statusActiveData = false;
    this.dataCheckBookService.statusConfirm = false;
  }

  continue() {
    this.dataCheckBookService.statusConfirmOTP = true;
    this.activationCheckBook.statusActiveData = true;
    this.modalService.close();
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.OtpChecksConfirmation
    };
    const modalRef = this.modalService.open(OtpCardReplacementComponent, modalOptions);
    modalRef.componentInstance.token = modalRef;
  }

  isTabletMobile() {
    const agent = window.navigator.userAgent.toLowerCase();
    if ((window.screen.width <= this.widthTablet && agent.match(Constants.USER_AGENT.Mac)) || (agent.match(Constants.USER_AGENT.Mobile))) {
      this.isApple = true;
    } else {
      this.isApple = false;
    }
  }

  @HostListener('window:popstate')
  closeModalAction() {
    this.modalInstance?.close();
  }
}
