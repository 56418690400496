import { PathModel } from 'src/app/models/PathModel';
import { TBaseTransferRequest } from 'src/app/interface/TransferData';

export type TScheduleTransferOtherBankRequest = TBaseTransferRequest & {
    idCuentaProducto: number,
    banco: string,
    numeroCuentaExterna: string,
    referenciaAlfanumerica?: string,
    referenciaNumerica?: number
  };
export class ScheduleTransferOtherBankRequest {
    constructor(public data: TScheduleTransferOtherBankRequest) { }

    get endpoint(): PathModel {
        return PathModel.ScheduleTransferOtherBankRequest;
    }
}