import { PathModel } from '../../models/PathModel';

interface IGetCreditAmortizationRequest {
    IdSession : string,
    NumCredito : string
}

export class GetCreditAmortizationRequest {
    constructor(public data: IGetCreditAmortizationRequest){ }

    get endpoint(): PathModel {
        return PathModel.GetCreditAmortization;
    }
}
