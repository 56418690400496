import { PathModel } from 'src/app/models/PathModel';

export type IMonthlyPromotionsRequest = {
    idSession : string,
    medioAcceso : string,
    codigoEmisor : string,
    numeroPoliza : string,
    ramo : string,
    producto : string,
};

export class MonthlyPromotionsRequest{
    constructor(public data: IMonthlyPromotionsRequest){}

    get endpoint(): PathModel {
        return PathModel.MonthlyPromotions;
    }
}