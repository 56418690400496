import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  ElementRef
} from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs';
import { ModalOptions, OptionsModalToken } from 'src/app/interface/modal-options';
import { IAlertInfo } from 'src/app/models/AlertInfo';
import { NotifyRetirementTypeService } from 'src/app/services/notify-retirement-type.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { AforeConstants, RetirementType } from 'src/core/constants/AforeConstants';
import { Char, Constants, Position } from 'src/core/constants/Constants';
import { ControlCardConstants } from 'src/core/constants/ControlCardConstants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { CardModalComponent } from 'src/app/shared/components/card-modal/card-modal.component';
import { ModalEndProcessAlertComponent } from 'src/app/shared/components/modal-end-process-alert/modal-end-process-alert.component';
import { ModalUtils } from 'src/core/utils/ModalUtils';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { AforeService } from 'src/app/services/afore.service';
import { ClassHTML } from 'src/core/constants/HTMLConstants';

@Component({
  selector: 'app-modal-alerts',
  templateUrl: './modal-alerts.component.html',
  styleUrls: ['./modal-alerts.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAlertsComponent implements OnInit {
  closeIcon: string = Resources.CLOSE_CIRCLE_BLUE;
  @Input() alertInfo: IAlertInfo;
  @Input() hasAction:boolean = false;
  @Input() hasCancel:boolean = false;
  @Input() isControlCard:boolean = false;
  @Input() isControlCardButton:boolean = false;
  @Output() modalCall:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() continueTransfer:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() continueReplacement:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() getCertificates:EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Input() onCancel: () => void;
  @Input() onSuccess: () => void;
  @Input() onClose: () => void;
  @Input() btnExit:boolean = true;
  @Input() horizontalButtons:boolean = true;
  @Input() exceptionalClass: string = Strings.EMPTY;
  @Input() closeButtonClass: string = ClassHTML.PaddingThree;
  @Input() addPadding: boolean = false;
  @Input() customStyleAlert: boolean = false;
  @Input() aditionalLabel: boolean;
  @Input() customBackdrop: boolean = true;
  @Input() showButtonClose: boolean = true;
  @Input() useXs: boolean = true;
  @Input() textAfore: boolean = false;
  @Input() useS: boolean = false;
  @Input() marginButton: boolean = true;
  @Input() marginDiv: boolean = true;
  @Input() justifyText: boolean = true;
  @Input() modalRef: NgbModalRef;
  modalSuccess: boolean = false;
  modalError: boolean = false;
  modalTotalNotify: boolean = false;
  statusRequest: boolean = true; //Variable that defines whether the worker is active
  modalInstance: NgbModalRef;
  isDeviceM:boolean;
  isDeviceMT:boolean;
  buttonClicked: boolean = false;
  styleBtnAccept: string = ClassHTML.ButtonClassDefaultAlertModal;
  readonly btnLabel = Strings.MSG_POPUP_ACCEPT;
  readonly btnCancelLabel = Constants.CANCEL_BUTTON;
  protected readonly RetirementType = RetirementType;
  chevronright: string = Resources.SHAPE_CONTINUE;
  @ViewChild(Strings.LABEL_ADD_PRODUCT.OTP) otpModal: ElementRef<HTMLInputElement>;
  closeOtpModal: NgbModalRef;
  modal : NgbModalRef;
  optionsModalToken: OptionsModalToken;
  options: ModalOptions = {
    centered:true,
    size: Constants.MODAL_OPTIONS.SizeSm,
    modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAlertEndProcess
  };

  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints }))
  );
  constructor(
    private readonly modalService: ModalService,
    private readonly responsiveService: ResponsiveService,
    public readonly notifyRetirementType: NotifyRetirementTypeService,
    private readonly aforeService: AforeService
  ) { }

  patrimonialHead: string = Strings.EMPTY;
  patrimonialFooter: string = Strings.EMPTY;
  titlePatrimonial: boolean = false;
  showclose: boolean = false;
  showpatrimonial: boolean = false;
  labelAdd: string = Strings.EMPTY;
  styleTitle:string = ControlCardConstants.PATRIMONIAL_STYLE_ONE;

  styleclose:string = ControlCardConstants.STYLE_CLOSE_ONE;
  styleImg = {};
  styleMain = {};
  styleCancel = {};
  styleLabelAdd = {};

  ngOnInit(): void {
    if (this.aforeService.withdrawals) {
      this.getCertificates.emit(true);
    }
    if (this.alertInfo.btnExit === false) {
      this.btnExit = false;
    } else {
      this.btnExit = true;
    }
    if(this.alertInfo.isHorizontalButtons){
      this.horizontalButtons = false;
    } else {
      this.horizontalButtons = true;
      this.showpatrimonial = false;
      this.alertInfo.hiddenBtnAccept = false;
    }
    if (this.alertInfo.message.includes(Char.Pipe)) {
      const words = this.alertInfo.message.split(Char.Pipe);
      this.patrimonialHead = words[Position.Zero];
      this.patrimonialFooter = words[Position.One];

      if (words[Position.Two] === Strings.CT_PATRIMONIAL_TRANSFER_FORM.Key) {

        this.showpatrimonial = true;
        this.styleTitle = AforeConstants.MODAL_OPTIONS.PatrimonialStyleTwo;
        this.styleclose = AforeConstants.MODAL_OPTIONS.StyleCloseTwo;
      }
      else {
        this.showpatrimonial = false;
      }
    }
    this.styleBtnAccept = (this.alertInfo.styleBtnAccept) ? this.alertInfo.styleBtnAccept : this.styleBtnAccept;
  }

  action() {
    if (this.aforeService.errorAforeService) {
      this.buttonClicked = true;
      const errorAlerErrorAfore = this.notifyRetirementType.getAlertAforeError();
      if(this.notifyRetirementType.retirementTypeBs.value === this.RetirementType.marriage && this.statusRequest && !errorAlerErrorAfore){
        this.optionsModalToken = {
          nextModal: CardModalComponent,
          nextClass: {
            centered:true,
             modalDialogClass: ModalConstants.MODAL_OPTIONS.CardModalComponent
          }
        };
        this.modalService.close();
        this.notifyRetirementType.setIsAfore = true;
        const modalOptions: ModalOptions = {
          centered: true,
          size: Constants.MODAL_OPTIONS.SizeLg,
           modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal
        };
        this.modalService.open(CardModalComponent, modalOptions);
      }
      if (this.hasAction) {
        this.modalCall.emit(true);
        this.continueReplacement.emit(true);
        if ( !this.exceptionalClass ){
          this.continueReplacement.emit(true);
        this.onSuccess();
        }
      } else {
        this.modalInstance?.dismiss();
      }
    }else{
      this.aforeService.aforeInformation = null;
      this.modalService.close();
    }
  }

  cancelAction() {
    this.buttonClicked = true;
    if (this.hasCancel) {
      this.modalCall.emit(true);
      this.onCancel();
    } else {
      this.modalService.close();
    }
  }

  closeModal() {
    if (this.onClose) {
      this.continueReplacement.emit(true);
      this.onClose();
    } else {
      this.continueTransfer.emit();
      this.modalInstance?.dismiss();
    }
    if(this.notifyRetirementType.retirementTypeBs.value){
      ModalUtils.showModal(this.modalService, ModalEndProcessAlertComponent, this.options,ModalConstants.MODAL_OPTIONS.ModalConent, ModalConstants.MODAL_OPTIONS.ModalAlerts);
    }

  }

  @HostListener('window:popstate')
  onBack(){
    this.modalInstance?.close();
    this.modalRef?.close();
    this.aforeService.aforeInformation = null;
  }
}
