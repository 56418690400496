import { Component } from '@angular/core';
import { map } from 'rxjs';
import { ToggleAccountBalanceService } from 'src/app/services/toggle-account-balance.service';
import { Resources } from 'src/core/constants/Resources';

@Component({
  selector: 'app-toggle-account-balance',
  templateUrl: './toggle-account-balance.component.html'
})
export class ToggleAccountBalanceComponent {
  isShowAccountBalance: boolean = false;
  eye: string = Resources.EYE_LIGHT;
  eyeOff: string = Resources.EYE_OFF_LIGHT;

  vm$ = this.statusVisibilityService?.showAccountBalance$.pipe(
    map((showAccountBalance) => ({ showAccountBalance }))
  );

  constructor(
    private readonly statusVisibilityService: ToggleAccountBalanceService
  ) {}

  async ngOnInit(){
    await this.getStatusVisibilityService();
  }

  async toggleShowAccountBalance() {
    await this.statusVisibilityService.updateAccountBalanceStatusVisibility(!this.statusVisibilityService.showAccountBalance);
  }

  async getStatusVisibilityService(){
    await this.statusVisibilityService.getAccountBalanceStatusVisibility();
    this.isShowAccountBalance = true;
  }
}
