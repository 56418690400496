import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { Constants, Numbers, Position } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { AccountModel } from 'src/app/interface/AccountModel';
import { Utils } from 'src/core/utils/utils';
import { TransferStrings } from 'src/core/constants/TransferStrings';

@Injectable({
  providedIn: 'root'
})
export class AccountCardService {
  /**
   * @deprecated The variable should not be used, use instead accountCard
   */
  selectedAccountCard: ICardAccount = {
    image: Strings.EMPTY,
    cardNumber: Strings.EMPTY,
    clabe: Strings.EMPTY,
    balance: Strings.EMPTY,
    selected: false,
    productId: Numbers.Zero,
    originAccount: Strings.EMPTY,
    accountType: Strings.EMPTY,
    source: Strings.EMPTY,
    expirationDate: Strings.EMPTY,
    account: Strings.EMPTY,
    category: Strings.EMPTY
  };
  accountCardList: ICardAccount[] = [];
  selected = false;

  private readonly _accountCard: BehaviorSubject<ICardAccount> =
    new BehaviorSubject<ICardAccount>({
      image: Strings.EMPTY,
      cardNumber: Strings.EMPTY,
      clabe: Strings.EMPTY,
      balance: Strings.EMPTY,
      selected: false,
      productId: Numbers.Zero,
      originAccount: Strings.EMPTY,
      accountType: Strings.EMPTY,
      source: Strings.EMPTY,
      expirationDate: Strings.EMPTY,
      account: Strings.EMPTY,
      category: Strings.EMPTY
    });

  private readonly _mainAccountCodeflex: BehaviorSubject<AccountModel> = new BehaviorSubject<AccountModel>(null);

  private readonly onDestroySubject = new Subject<void>();

  private readonly _modalAccountOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  get accountCard$() {
    return this._accountCard.asObservable();
  }

  get accountCard() {
    return this._accountCard.getValue();
  }

  set accountCard(data: ICardAccount) {
    this._accountCard.next(data);
  }

  get mainAccountCodeflex$(){
    return this._mainAccountCodeflex.asObservable();
  }

  get mainAccountCodeflex(){
    return this._mainAccountCodeflex.getValue();
  }

  get onDestroy$(){
    return this.onDestroySubject.asObservable();
  }

  set mainAccountCodeflex(data: AccountModel){
    this._mainAccountCodeflex.next(data);
  }

  clearCardAccount() {
    this.selectedAccountCard = {
      image: Strings.EMPTY,
      cardNumber: Strings.EMPTY,
      clabe: Strings.EMPTY,
      balance: Strings.EMPTY,
      selected: false,
      productId: Numbers.Zero,
      originAccount:Strings.EMPTY,
      currency: Strings.EMPTY,
      source: Strings.EMPTY,
      expirationDate: Strings.EMPTY,
      account: Strings.EMPTY,
      category: Strings.EMPTY
    };
    this._accountCard.next(this.selectedAccountCard);
    this.selected = false;
  }

  clearArrayAccountCardAccount() {
    this.mainAccountCodeflex = null;
  }

  emitDestroyEvent(){
    this.onDestroySubject.next();
  }

  get getModalAccountOpen() {
    return this._modalAccountOpen.getValue();
  }

  set setModalAccountOpen(value: boolean) {
    this._modalAccountOpen.next(value);
  }

  resetSelectedAccount(accounts: AccountModel[], selectedAccountId: number ){
    let accountIndex = Constants.CARD_SELECTED_DEFAULT;
    if(accounts){
      accountIndex = accounts.findIndex(item => item.idCuentaProducto === selectedAccountId);
      accountIndex= accountIndex === Constants.NOT_FOUND_ITEM ? Position.Zero: accountIndex;
    }
    return accountIndex;
  }

  descriptionInfoAccount(data: string) {
    data = Utils.removeSpaces(data);
    let typeDataString: string = TransferStrings.TYPE_IDENTIFIER_ACCOUNT.Account.Name;
    if (data.length === TransferStrings.TYPE_IDENTIFIER_ACCOUNT.CLABE.Length) {
      typeDataString = TransferStrings.TYPE_IDENTIFIER_ACCOUNT.CLABE.Name;
    } else if (data.length === TransferStrings.TYPE_IDENTIFIER_ACCOUNT.Card.Length) {
      typeDataString = TransferStrings.TYPE_IDENTIFIER_ACCOUNT.Card.Name;
    } else if (data.length === TransferStrings.TYPE_IDENTIFIER_ACCOUNT.Account.Length) {
      typeDataString = TransferStrings.TYPE_IDENTIFIER_ACCOUNT.Account.Name;
    }
    return typeDataString;
  }

}
