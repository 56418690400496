import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { DomiciliationResources } from 'src/core/constants/DomiciliationResources';
import { Resources } from 'src/core/constants/Resources';

@Component({
  selector: 'app-modal-help-signature-panel',
  templateUrl: './modal-help-signature-panel.component.html',
  styleUrls: ['./modal-help-signature-panel.component.css']
})
export class ModalHelpSignaturePanelComponent {
  breakpoints$ = this.responsiveService.observe;
  panelDesktopIcon: string = Resources.CARD_PANEL_DESKTOP;
  panelMobileIcon: string = Resources.CARD_PANEL_MOBILE;
  closeIcon: string = Resources.CLOSE_CIRCLE_BLUE;
  panelDesktopIconCfe: string = DomiciliationResources.MODAL_CFE;
  panelDesktopIconCfeMobail: string = DomiciliationResources.MODAL_CFE_MOBAIL;
  modalInstance: NgbModalRef;
  @Input () data: boolean = false;
  @Output() stateModal:EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private readonly modalService: ModalService,
    private readonly responsiveService: ResponsiveService
  ) {}

  close() {
    this.modalInstance.close();
    this.stateModal.emit(false);
  }

  ngOnDestroy() {
    this.modalInstance.close();
  }
}