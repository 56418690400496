import { PathModel } from 'src/app/models/PathModel';
import { TToken } from '../TToken';

export interface IModifyLimitCTSRequest{
    IdSession: string;
    Limite: number;
    NuevoLimite: number;
    CuentaCT: string;
    CuentaCTS: string; 
    Token?: TToken;
    TipoAcceso: string;
    IdentificadorCuenta?: string;
    MedioAcceso: string;
    OrigenConsulta?: string;
    IpCliente: string;
}

export class ModifyLimitCTSRequest{
    constructor(public data: IModifyLimitCTSRequest){}
    get endpoint(): PathModel{
        return PathModel.ModifyLimitCTS;
    }
}