<ng-container *ngIf="vm$ | async as vm">
  <content-wrapper [contentMargin]="contentMargin" [useFlex]="true">
    <div content class="token-container mt-3">
      <div>
        <app-header-transfer-card
          [title]="title"
          [subtitle]="subtitle"
          [dataForm]="dataForm"
          [dataInternational]="dataInternational"
          [urlLocation]="beforeView"
          [showSubtitle]="true"
        >
        </app-header-transfer-card>
        <ng-container *ngIf="!isApp">
          <div class="mt-4 form-code-security-alert mb-4" *ngIf="alert">
            <div>
              <span class="text-white detail-m">
                Utiliza el token móvil de tu app Inbursa Móvil para realizar esta
                validación
              </span>
            </div>
            <a role="button" (click)="closeAlert()" (keydown.enter)="closeAlert()">
              <img
                alt="Cerrar alerta"
                [src]="closeWhiteCircle | cdnImage"
                width="auto"
                height="auto"
              />
            </a>
          </div>
        </ng-container>
      </div>
      <div>
        <div>
          <div class="d-flex align-items-center gap-1" [class.pt-2]="!alert">
            <label class="button-s text-secondary-800">{{ head }}</label>
            <img
              *ngIf="!isApp"
              class="cursor-pointer icon-image-security"
              tooltipClass="my-custom-class"
              [src]="circleBlueQuestion | cdnImage"
              (click)="modalHelp()" (keydown.enter)="$event.preventDefault()"
              alt="Ayuda"
              width="20"
              height="20"
            />
          </div>
          <div class="mt-2 d-flex justify-content-center input-border">
            <input
              autofocus
              type="text"
              required
              id="t1"
              name="t1"
              #token
              onpaste="return false"
              class="w-100 input-token-code px-3 form-control body-s"
              maxlength="12"
              cleanInput
              [cleanRegex]="regex"
              autocomplete="off"
              (keyup)="token.value = token.value.toUpperCase()"
              [class.border-error]="setError && isContinue"
              (input)="validateTokenSeries(token.value)"
            />
          </div>

          <div *ngIf="errorToken && isContinue" class="mt-2">
            <span class="label-error">
              {{ errorMsg }}
            </span>
          </div>

          <div class="btn-container mt-4 d-flex justify-content-center">
            <button
              [class.w-100]="vm.breakpoints.mobile"
              [class.button-m]="!vm.breakpoints.mobile"
              [class.button-s]="vm.breakpoints.mobile"
              class="colored-btn btn-generic"
              (click)="showConfirmAddress(token.value)"
              (keydown.enter)="$event.preventDefault()"
            >
              Continuar
              <img
                alt="Continuar"
                [src]="btnChevronRight | cdnImage"
                width="auto"
                height="auto"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </content-wrapper>
</ng-container>
