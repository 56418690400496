import { PathModel } from 'src/app/models/PathModel';

type TCancelAgendaRequest ={
    idAgenda : number,
    idSesion : string,
    tipoAgenda : string,
    Token : {
      OTP ?: string,
      HAC ?: null
    },
    tipoAcceso : string,
    nombreAplicacion : string,
    token ?: null,
    traza ?: null,
    IdAdicional ?: null,
    IdPersonaTitular ?: null
};
export class CancelAgendaRequest{
  constructor(public data: TCancelAgendaRequest){}

  get endpoint(): PathModel {
    return PathModel.CancelAgendasService;
}
}
