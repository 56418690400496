<div class="main-container">
    <span class="label-title">Opciones de Liquidación y Renovación de tu Inversión</span>
    <span class="label-info">
        Conoce las opciones que tienes para gestionar tu inversión una vez que se cumpla el plazo al vencimiento
    </span>
    <div class="column-flex">
        <span class="label-info-title">Liquidación al vencimiento</span>
        <span class="label-info">Recibir su Capital invertido más el Interés al final del vencimiento.</span>
    </div>
    <div class="column-flex">
        <span class="label-info-title">Renovación solo del saldo</span>
        <span class="label-info">Renovar automáticamente sólo el Capital con el mismo plazo y la misma opción.</span>
    </div>
    <div class="column-flex">
        <span class="label-info-title">Renovación al vencimiento</span>
        <span class="label-info">
            Renovar automáticamente tanto el Capital invertido como el interés generado con el mismo plazo.
        </span>
    </div>    
    <button class="primary-btn" (click)="close()" (keydown.enter)="close()"> Cerrar </button>
</div>