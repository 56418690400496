<ng-container *ngIf="vm$ | async as vm">
  <div [ngClass]="shortAnswer ? 'height-auto' : ''">
    <div class="no-border">
      <div class="d-flex justify-content-end pt-3 pe-3">
        <img role="button" aria-label="Close" width="auto" height="auto" [src]="closeCircle | cdnImage" alt="Close"
          class="close" (click)="close()" *ngIf="!isSua">
      </div>

      <div class="row mb-2 mt-3">
        <div class="col-12 d-flex justify-content-center">
          <img [src]="check | cdnImage" class="icon-check" alt="" width="auto" height="auto">
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-center p-0">
          <span class="" [ngClass]="vm.breakpoints.mobile ? 'header-m' : 'header-l'">Solicitud enviada a SUA</span>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12 d-flex justify-content-center p-0">
          <span class="body-m">Espera respuesta pronto</span>
        </div>
      </div>

      <div class="margin-bottom-body">
        <div class="container-info-files">
          <div class="column-1">
            <div class="row" [ngClass]="!vm.breakpoints.mobile ? 'mb-2' : 'mb-0'">
              <div class="header-xs">Archivo suministrado</div>
            </div>

            <div class="row" [ngClass]="!vm.breakpoints.mobile ? 'mb-3' : ''">
              <div class="row align">
                <div
                  [ngClass]="vm.breakpoints.mobile ? 'justify-content-start col-2' : 'col-2 justify-content-center'">
                  <img [src]="fileIcon | cdnImage" alt="file" class="image-size" width="auto" height="auto">
                </div>
                <div class="col-8 padding-file">
                  <div class=" body-s w-100 text-name">{{ fileData.nombreArchivo }} </div>
                  <div class=" detail-m">{{ fileData.size }} MB</div>
                </div>
              </div>
            </div>

            <div class="row mb-2" *ngIf="!shortAnswer">
              <div class="header-xs ">Información de la operación</div>
            </div>

            <div *ngIf="!shortAnswer">
              <label class="body-s">{{ fileData.size }} MB</label>
              <label class="detail-s col-12">Tamaño total</label>
              <hr class="my-2">
            </div>

            <div *ngIf="!shortAnswer">
              <label class="body-s">{{ fileData.monto | currency}}</label>
              <label class="detail-s col-12">Monto total</label>
              <hr class="my-2">
            </div>

            <div *ngIf="!shortAnswer">
              <label class="body-s text-overflow">{{ fileData.registroPatronal }}</label>
              <label class="detail-s col-12">Registro Patronal</label>
              <hr class="mt-2 mb-0">
            </div>

          </div>
          <div class="column-2" *ngIf="shortAnswer">
            <div class="row mb-2">
              <div class="header-xs ">Información de la operación</div>
            </div>

            <div>
              <label class="body-s">{{ fileData.size }} MB</label>
              <label class="detail-s col-12">Tamaño total</label>
              <hr class="my-2">
            </div>

            <div>
              <label class="body-s">{{ fileData.estatus }}</label>
              <label class="detail-s col-12"> Estatus </label>
              <hr class="my-2">
            </div>
          </div>

          <div class="column-2" *ngIf="!shortAnswer">
            <div>
              <label class="body-s">{{ fileData.folioSua }}</label>
              <label class="detail-s col-12">Folio SUA</label>
              <hr class="my-2">
            </div>

            <div>
              <label class="body-s">{{ fileData.periodo }}</label>
              <label class="detail-s col-12">Periodo de pago</label>
              <hr class="my-2">
            </div>

            <div>
              <label class="body-s">{{ fileData.rfc }}</label>
              <label class="detail-s col-12"> RFC </label>
              <hr class="my-2">
            </div>

            <div>
              <label class="body-s">{{ fileData.estatus }}</label>
              <label class="detail-s col-12"> Estatus </label>
              <hr class="my-2">
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <button (click)="close()" class="btn-sent mb-3 button-m"> Listo
          </button>
        </div>
        <div class="col-12 d-flex justify-content-center">
          <button (click)="otherOperation()" class="btn-sent-cancel button-m">
            Otra operación
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
