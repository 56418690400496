import { PathModel } from 'src/app/models/PathModel';

interface ICancelFederalTaxPaymentRequest {
  idImpuestoFederal: number;
}

export class CancelFederalTaxPaymentRequest {
  constructor(public data: ICancelFederalTaxPaymentRequest) { }

  get endpoint(): PathModel {
    return PathModel.CancelFederalTaxPayment;
  }
}
