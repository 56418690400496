import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IDataDelete } from '../interface/dto/IDataDelete';

@Injectable({
  providedIn: 'root'
})
export class DataDeleteAgenda {
 private readonly _status: BehaviorSubject<IDataDelete> = new BehaviorSubject<IDataDelete>({message: ''});

  constructor() { }

  get status$() {
    return this._status.asObservable();
  }

  get status() {
    return this._status.getValue();
  }

  set status(data: IDataDelete) {
    this._status.next(data);
  }
}