import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { map } from 'rxjs';
import { ResponsiveService } from '../../responsive.service';
import { Strings } from 'src/core/constants/Strings';

@Component({
  selector: 'selected-card',
  templateUrl: './selected-card.component.html',
  styleUrls: ['./selected-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedCardComponent {
  @Input() cardClasses: string = 'card-information';
  @Input() cardId: string = 'card-information';
  @Input() cardImage: string;
  @Input() cardImageClass: string = 'card-img-confirm';
  @Input() cardName: string;
  @Input() cardNumber: string;
  @Input() label: string = Strings.MESSAGES.LabelAccount;
  @Input() showLabel: boolean = true;
  @Input() classes: string = Strings.EMPTY;
  @Input() showImage: boolean = false;
  @Input() dividerClass: string = 'm-2';
  @Input() dividerMargin: boolean = true;
  @Input() bodyClass: string = Strings.EMPTY;
  @Input() headClass: string = Strings.EMPTY;


  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints }))
  );

  constructor(private readonly responsiveService: ResponsiveService) { }
}
