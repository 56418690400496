import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ConfigurationApp } from 'src/app/services/servicesApp/configuration-app.service';
import { AppUtils } from 'src/core/utils/AppUtils';

@Injectable({
  providedIn: 'root'
})
export class ExternalNavigateApp {

  constructor(
    private readonly router: Router,
    private readonly configurationApp: ConfigurationApp) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (AppUtils.platform.isApp && this.configurationApp.configurationApp?.isExternal) {
      this.router.navigate([this.configurationApp.configurationApp?.target]);
      return false;
    }
    return true;
  }

}
