import { PathModel } from 'src/app/models/PathModel';

export interface IGetDataCardRequest{
        CardNumber: string,
        IdSession: string,
        IsAditional: boolean,
        Source:string
}

export class GetDataCardRequest{
    constructor(public data: IGetDataCardRequest){}

    get endpoint(): PathModel{
        return PathModel.GetDataCard;
    }
}

