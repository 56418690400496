<ng-container *ngIf="vm$ | async as vm" #ModalCards>
  <div class="mt">
    <div class="pb" [ngClass]="additionalCard ? 'card-credit-control mt-1' : 'card-credit-add drop-shadow-m mt-3'">
      <div class="row">
        <div class="col-12"  [ngClass]="!vm.breakpoints.mobile ? 'ps-3':'ps-adjust'">
          <label class="body-s subtitle" [ngClass]="additionalCard ? 'label-color-tittle': ''">{{head}}</label>
        </div>
      </div>
      <div class="row" [ngClass]="additionalCard ? '': 'mt-2'" *ngIf="locksCardInfo">
        <div class="col-lg-6 col-md-6 col-xs-12"
        *ngIf="isdebit === debitName && !isAdditional && !hiddenMonthlyLimitDebit">
         <div class="mt-4 justify-content-start d-flex">
            <div class="margin-14">
              <label for="monthlyOperation" class="switch">
                <input id="monthlyOperation" type="checkbox" [checked]="!vm.dataControls.Monthly.Lock" #monthlyOperation
                  (change)="updateLocks(!monthlyOperation.checked,'MEN', $event)">
                <span class="slider round "></span>
              </label>
            </div>
            <div class="margin-20">
              <label class="font-des">Límite mensual</label>
            </div>
          </div>
        </div>
         <div class="col-lg-6 col-md-6 col-xs-12"[ngClass]="{'order-5': vm.breakpoints.mobile}"
         *ngIf="dataCard?.Card?.CardType !== groceryVoucherCard
         && dataCard?.Card?.CardType !== walmartGroceryVoucherCard &&
         dataCard?.Card?.CardType !== webCard && this.additionalCard">
           <div class=" mt-4 justify-content-start d-flex">
             <div class="margin-14">
               <label for="foreign" class="switch">
                 <input id="foreign" type="checkbox" [checked]="!locksCardInfo.Opi" #OpiLock
                   (change)="updateLocks(!OpiLock.checked,'OPI',$event)">
                 <span class="slider round "></span>
               </label>
             </div>
             <div class="margin-20">
               <label class="font-des">{{titleShopping}}</label>
             </div>
           </div>
         </div>
        <div class="col-lg-6 col-md-6 col-xs-12">
          <div class="mt-4 justify-content-start d-flex">
            <div class="margin-14">
              <label for="online" class="switch">
                <input id="online" type="checkbox" [checked]="!locksCardInfo.Int" #IntLock
                  (change)="updateLocks(!IntLock.checked,'INT', $event)">
                <span class="slider round "></span>
              </label>
            </div>
            <div class="margin-20">
              <label class="font-des">Compras en línea y telefónicas</label>
            </div>
          </div>
        </div>
         <div class="col-lg-6 col-md-6 col-xs-12" *ngIf="dataCard?.Card?.CardType !== webCard">
          <div class="mt-4 justify-content-start d-flex">
            <div class="margin-14">
              <label for="stores" class="switch">
                <input id="stores" type="checkbox" [checked]="!locksCardInfo.Pos" #PosLock
                  (change)="updateLocks(!PosLock.checked,'POS', $event)">
                <span class="slider round "></span>
              </label>
            </div>
            <div class="margin-20">
              <label class="font-des">Compras en comercios</label>
            </div>
          </div>
        </div>
         <div class="col-lg-6 col-md-6 col-xs-12 adjust-margin" *ngIf="showCodi && dataCard?.Card?.CardType
         !== groceryVoucherCard">
           <div class="mt-4 justify-content-start d-flex">
             <div class="margin-14">
               <label for="codiOperation" class="switch">
                 <input type="checkbox" id="codiOperation" #Codi  [checked]="!locksCardInfo.Codi"
                 (change)="updateLocks(!Codi.checked,'CODI', $event)">
                 <span class="slider round"></span>
               </label>
             </div>
             <div class="margin-20">
               <label class="font-des">{{titleCodi}}</label>
             </div>
           </div>
         </div>
        <div class="col-lg-6 col-md-6 col-xs-12" *ngIf="dataCard?.Card?.CardType !== groceryVoucherCard
        && dataCard?.Card?.CardType !== walmartGroceryVoucherCard
          && dataCard?.Card?.CardType !== webCard">
         <div class="mt-4 justify-content-start d-flex">
            <div class="margin-14">
              <label for="atmOperation" class="switch">
                <input id="atmOperation" type="checkbox" [checked]="!locksCardInfo.Atm" #AtmLock
                  (change)="updateLocks(!AtmLock.checked,'ATM', $event)">
                <span class="slider round "></span>
              </label>
            </div>
            <div class="margin-20">
              <label class="font-des">Cajeros automáticos</label>
            </div>
          </div>
        </div>
       </div>
    </div>
  </div>
</ng-container>
