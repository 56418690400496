import { PathModel } from 'src/app/models/PathModel';

type TModifyOthersBanksRequest ={
  idSesion : string,
  alias : string,
  limiteDiario : string,
  email : string,
  idAgenda : number,
  titular : string,
  tipoAcceso : string,
  canal : string,
  ip : string,
  Token : {
    OTP : string
  },
  nombreAplicacion : string,
  Latitud : number,
  Longitud : number,
  IdPersonaTitular? : number,
  IsAditional : boolean,
  NumeroCuenta : string,
  MedioAcceso: string,
  OrigenConsulta?: string
};

export class ModifyOthersBanks {
  constructor(public data: TModifyOthersBanksRequest){ }

  get endpoint(): PathModel {
    return PathModel.ModifyOthersBanks;
}
}
