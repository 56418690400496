import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISimpleValue } from '../interface/ISimpleValue';

@Injectable({
  providedIn: 'root'
})
export class SelectAddresseeErrorService {

  private readonly addresseeError: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({value: false});

  constructor() { }

  get addresseeErrorObservable() {
    return this.addresseeError.asObservable();
  }

  set addresseeErrorData(data: ISimpleValue) {
    this.addresseeError.next(data);
  }
}
