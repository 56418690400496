import { PathModel } from 'src/app/models/PathModel';

export type ICheckbookRequest = {
    idOficinaEntrega: number,
    numeroCuenta: string,
    otp: string
};

export class CheckbookRequest {
    constructor(public data: ICheckbookRequest) { }

    get endpoint(): PathModel {
        return PathModel.CheckbookRequest;
    }
}
