import { PathModel } from 'src/app/models/PathModel';
import { TBaseTransferRequest } from 'src/app/interface/TransferData';

export type TScheduleTransferRequest = TBaseTransferRequest & {
  numeroCuentaDestino: string,
  idCuentaProductoOrigen: number,
  idCuentaProductoDestino?: number
};

export class ScheduleTransferRequest {
    constructor(public data: TScheduleTransferRequest) { }

    get endpoint(): PathModel {
        return PathModel.ScheduleTransferRequest;
    }
}