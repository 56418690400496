import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, WritableSignal, signal } from '@angular/core';
import { map, tap } from 'rxjs';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { CurrencyPipe, formatDate } from '@angular/common';
import { PatrimonialStatusService } from 'src/app/services/patrimonial-status.service';
import { Utils } from 'src/core/utils/utils';
import { AlertService } from 'src/app/services/alert.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { StorageService } from 'src/app/services/storage.service';
import { Char, Constants, Format, FormatDate, HtmlEvent, Language, Numbers, Position, Regex } from 'src/core/constants/Constants';
import { ModifyLimitCTSRequest } from 'src/app/interface/dto/IModifyLimitCTSRequest';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { IModifyLimitCTSResponse } from 'src/app/interface/dto/IModifyLimitCTSResponse';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { Strings } from 'src/core/constants/Strings';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { SentinelService } from 'src/app/services/sentinel.service';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalAccountInformationComponent } from 'src/app/home/components/modal-account-information/modal-account-information.component';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { CardAddresseeService } from 'src/app/services/card-addressee.service';

@Component({
  selector: 'app-modal-modify-limit',
  templateUrl: './modal-modify-limit.component.html',
  styleUrls: ['./modal-modify-limit.component.css']
})
export class ModalModifyLimitComponent implements OnInit {

  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints })),
    tap(vm => {
      if (vm.breakpoints.mobile) {
        this.isMobile = true;
      }
    })
  );

  @Input() dailyLimitHome: string = Strings.EMPTY;
  @Input() accountSelected: string = Strings.ACCOUNT_LIMIT_LABEL;
  @Output() resultLimit = new EventEmitter();
  @Output() cEditLimit = new EventEmitter<boolean>();
  @Output() successEditLimit = new EventEmitter<boolean>();

  dailyLimit: WritableSignal<string> = signal<string>(Strings.EMPTY);
  errorLimit: boolean = false;
  editLimit: boolean = true;
  confirmEditLimit: boolean = false;
  notifyEdit: boolean = false;
  labelAmount: string = Strings.EMPTY;
  head: string = Strings.EMPTY;
  body: string = Strings.EMPTY;
  completeDate!: string;
  newLimit:number;
  modifyLimitCTSResponse: IModifyLimitCTSResponse;
  hideDownload:boolean = false;
  hideFolio: boolean = false;
  errorMsg: string = Strings.MAX_AMOUNT_ERROR_MSG(new CurrencyPipe(Constants.LOCALE_EN_US).transform(Constants.MAX_LIMIT_AMOUNT_CT));
  isMaxAmount: boolean = false;
  isMobile: boolean = false;
  disabledButton: boolean = true;

  closeIcon: string = Resources.CLOSE_CIRCLE_BLUE;
  checkIcon: string = Resources.CHECK;
  chevronImage: string = Resources.BTN_CHEVRON_RIGHT;

  @Input() modalInstance: NgbModalRef;

  constructor(
    private readonly modalService: ModalService,
    private readonly responsiveService: ResponsiveService,
    private readonly patrimonialService:PatrimonialStatusService,
    private readonly alertService: AlertService,
    private readonly baseService: IBaseService,
    private readonly storageService: StorageService,
    private readonly notifyAmount: NotifyAmountService,
    private readonly modalServices: NgbModal,
    private readonly router: Router,
    private readonly accountInfoService: AccountInfoStatusService,
    private readonly sentinelService: SentinelService,
    private readonly cardAddresseeService: CardAddresseeService
  ) { }

  ngOnInit(){
    this.head = Strings.SUCCESSFUL_MODIFICATION;
    const date = new Date();
    this.completeDate = formatDate(date, FormatDate.DayMonthYearComplete, Constants.LOCALE_ES_MX);
    this.dailyLimit.set(this.patrimonialService.accountLimit.getLimit > Numbers.Zero ?
      Utils.formatValue(this.patrimonialService.accountLimit.getLimit.toString(), Format.Currency) : null);
    this.confirmEditLimit = this.dailyLimitHome !== Strings.EMPTY;
    this.labelAmount = this.dailyLimitHome !== Strings.EMPTY ? this.dailyLimitHome : Strings.EMPTY;
    this.editLimit = this.dailyLimitHome === Strings.EMPTY;
    if(this.patrimonialService.isCtLimitExceededTransaction){
      this.isCtLimitExceeded();
    }
  }

  isCtLimitExceeded() {
    const amount = parseFloat(Utils.transformAmount(this.notifyAmount.amount.amount));
    const accumulatedLimit = this.patrimonialService.accountLimit.accumulatedLimit;
    this.dailyLimit.set((amount + accumulatedLimit).toString());
    this.disabledButton= false;
  }

  changeFormat(limit: string) {
    this.getNewLimit(limit);
    const aux = Utils.transformAmount(limit);
    let format = parseFloat(aux).toLocaleString(Language.EnglishUS, {
      maximumFractionDigits: Position.Two, minimumFractionDigits: Position.Two
    });
    format = format.replace(Regex.Slash, Char.Dot).replace(Regex.MatchComma, Char.Comma);
    format = isNaN(parseFloat(format)) ? Strings.EMPTY : `${Char.CurrencySymbol}${format}`;
    this.dailyLimit.set(format);
    return format;
  }

  private getNewLimit(value?:string){
    this.newLimit = parseFloat(Utils.transformAmount(value));
    this.patrimonialService.newLimit = this.newLimit;
  }

  changeLimitError(limit: string) {
    const element = document.getElementById(Constants.INPUT_LIMIT_ID);
    element?.addEventListener(HtmlEvent.Input, event => {
      const target = event.target as HTMLTextAreaElement;
      target.value = isNaN(parseFloat(target.value)) ? Strings.EMPTY : target.value.replace(Regex.DifferentFromNumbersDecimals, Strings.EMPTY);
    });
    this.disabledButton = Number(Utils.transformAmount(limit))=== this.patrimonialService.accountLimit.getLimit;
    this.errorLimit = false;
    this.isMaxAmount = false;
  }

  async closeModal(eventEmitter?:ElementRef| boolean) {
    if(this.patrimonialService.isCtLimitExceededTransaction){
      this.modalService.close();
      Utils.openModalLimitExceeded(this.modalService);
      return;
    }
    if(this.router.url === PATH.Transfers && eventEmitter){
      this.modalService.close();
      await this.cardAddresseeService.getOwnAccounts();
    }

    if (eventEmitter && this.dailyLimitHome) {
      this.resultLimit.emit();
      this.dailyLimitHome = Strings.EMPTY;
    } else {
      this.cEditLimit.emit(this.notifyEdit);
    }
    this.modalService.close();
    this.patrimonialService.isCtLimitExceededTransaction = false;
    if(this.isMobile && this.router.url === PATH.Home && eventEmitter){
      this.openModal({consultLimit: false});
    }
  }

  action(limit: string) {
    if (!limit || limit === Char.WhiteSpace) {
      this.errorLimit = true;
    }
    else {
      const limitValue = Utils.transformAmount(limit);
      if (isNaN(parseFloat(limitValue)) || parseFloat(limitValue) <= Constants.AMOUNT_ZERO){
        this.errorLimit = true;
      } else {
        if (parseFloat(limitValue) > Constants.MAX_LIMIT_AMOUNT_CT) {
          this.isMaxAmount = true;
        } else {
          this.isMaxAmount = false;
          this.editLimit = false;
          this.confirmEditLimit = true;
          this.notifyEdit = false;
          this.labelAmount = limit;
        }
      }
    }
  }

  modify() {
    if (this.dailyLimitHome === Strings.EMPTY) {
      this.editLimit = true;
      this.confirmEditLimit = false;
      this.notifyEdit = false;
    } else {
      this.closeModal();
      if (this.isMobile && this.router.url === PATH.Home) {
        const modalData = {consultLimit: true, newLimit: this.labelAmount};
        this.openModal(modalData);
      }
    }
  }

  public async modifyLimitCTS(){
    try {
      const request = new ModifyLimitCTSRequest({
        IdSession: this.storageService.getSession(),
        CuentaCT: this.patrimonialService.transferData.no_tarjetaCT,
        CuentaCTS: this.patrimonialService.transferData.noTarjetaCTS,
        Limite: this.patrimonialService.accountLimit.getLimit,
        NuevoLimite: this.patrimonialService.newLimit,
        TipoAcceso: Constants.ACCESS_TOKEN_TYPE,
        IdentificadorCuenta: this.patrimonialService.transferData.noTarjetaCTS,
        MedioAcceso: Constants.ACCESS_METHOD_PORTAL,
        OrigenConsulta: Strings.EMPTY,
        IpCliente: this.sentinelService.sentinel.ip
      });
      const response = await this.baseService.genericPost<IModifyLimitCTSResponse>(request,{isSOA:true});
      if (response.Error.No === Constants.MSG_SERVICE_RESPONSE_SUCCESSFUL.toString()) {
        this.notifyEdit = true;
        this.confirmEditLimit = false;
        this.patrimonialService.accountLimit.getLimit = response.NuevoLimite;

        if (!this.editLimit){
          this.successEditLimit.emit(true);
        }
      }
    } catch (error) {
      this.confirmEditLimit = false;
      this.modalService.close();
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg,errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      }, {
        onSuccess: () => { this.errorModifyLimit(); }
      });
      if (!this.editLimit){
        this.successEditLimit.emit(false);
      }
    } finally {
      this.patrimonialService.isCtLimitExceededTransaction = false;
    }
  }

  errorModifyLimit() {
    this.modalServices.dismissAll();
    this.router.navigate([PATH.Home]);
    this.notifyAmount.amount = { amount: Strings.EMPTY };
    if (this.editLimit) {
      this.accountInfoService.clearAccount();
    }
  }

  openModal(modalData:{consultLimit: boolean, newLimit?: string } ){
    const modalOptions: ModalOptions = {
      centered: false,
      size: Constants.MODAL_OPTIONS.SizeMd,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal
    };
    this.modalService.open(ModalAccountInformationComponent, modalOptions, modalData);
  }

  @HostListener('window:popstate')
  onBack() {
    this.modalInstance.close();
  }

}
