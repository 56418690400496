export class CodeflexStrings {
  static readonly CODEFLEX_BUTTONS = {
    Manage: 'Administrar'
  };

  static readonly WAITING_TIME = (days: number) => `El depósito se verá reflejado en tu cuenta, después de ${days} días`;
  static readonly NO_BALANCE_AVAILABLE = (type: string) => `Saldo insuficiente para realizar el ${type}`;
  static readonly SUBTITLE= 'Ingresa los datos del ';
  static readonly LabelSelectAction = 'Selecciona acción';
  static readonly ToAccount = 'A tu cuenta';
  static readonly CODEFLEX_CONFIRM= 'Confirma los datos del';
  static readonly DEPOSIT_TITLE = 'Depósito a';
  static readonly CODEFLEX_ACTIONS = ['Depósito','Retiro'];
  static readonly DEPOSIT_DATE = 'Fecha';
  static readonly LABEL_AMOUNT= {
    Deposit: 'depositar',
    Withdrawal: 'retirar',
    Default: (type: string) => `¿Cuánto vas a ${type}?`
  };
  static readonly DATE_TOOLTIP = (type: string) => `Fecha que tardará en verse reflejado el ${type} en tu cuenta de cheques`;
  static readonly HEAD_DATE = (type: string) => `Fecha de ${type}`;
  static readonly messages = {
    Deposit: 'Los recursos solicitados se acreditarán en la cuenta asociada en la fecha señalada de acuerdo al término de los plazos de inversión',
    Withdrawal: (type: string) => `El depósito se verá reflejado de manera inmediata en la cuenta ${type}`
  };

  static readonly responsiveOptions ={
    EjeAccountTitle : 'Asociada a',
    BalanceTittle: 'Saldo disponible',
    TotalBalanceTittle: 'Saldo actual',
    InvestmentDepositTitle: 'Depósito de rendimiento',
    InvestmentDepositValue: 'Último día de mes'
  };
}
