import { Injectable } from '@angular/core';
import { AforeMovementsRequest } from 'src/app/interface/dto/AforeMovementsRequest';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
  providedIn: 'root'
})
export class AforeDetailMovementsService {

  constructor(
    private readonly baseService: IBaseService
  ) { }

  async aforeMovements(request: AforeMovementsRequest): Promise<string>{
    const response = await this.baseService.genericPost<GenericResponse<string>> (request, {isSecure: true, addSession: true});
    if (response?.datos) {
      return response.datos;
    } else {
      return Strings.EMPTY;
    }
  }
}
