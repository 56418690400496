import { ClassHTML } from 'src/core/constants/Constants';
export class StylesConstants {
  static readonly SECONDARY_BACKGROUND = 'var(--secondary-1500)';
  static readonly SECONDARY_COLOR = 'var(--secondary-300)';
  static readonly PRIMARY_BACKGROUND = 'var(--primary-1000)';
  static readonly WHITE_COLOR = 'var(--White)';
  static readonly HEX_WHITE_COLOR = '#FFF';

  static readonly CARD_SERVICE = {
    EditImgStyleIpad: 'image-ipad-edit',
    EditSpanStyleIpad: 'span-ipad-edit',
    DeleteSpanStyleIpad: 'span-ipad-delete',
    DeleteImgStyleIpad: 'image-ipad-delete',
    EditImgStyle: 'image-apple-edit',
    EditSpanStyle: 'span-edit',
    DeleteSpanStyle: 'span-delete',
    DeleteImgStyle: 'image-apple-delete'
  };

   static readonly OFFER_SWIPER_STYLES =  `@media screen and (min-width: 1024px) {
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom, .swiper-pagination-fraction {
      top: 94%;
    }
  }`;

  static readonly URL_STYLE = (url: string) => `url('${url}')`;
  static readonly IPHONE_MIN_WIDTH = 375;
  static readonly IPHONE_MAX_WIDTH= 667;

  static readonly FOUNDING_CARD = {
    PaddingBody: 'text-body-padding',
    PaddingTitle: 'text-title-padding',
    ModalStyle: 'custom-modal-style'
  };

  static readonly CAROUSEL_NEWS = {
    SwiperProperties: ` .swiper-horizontal > .swiper-pagination-bullets,
          .swiper-pagination-bullets.swiper-pagination-horizontal,
          .swiper-pagination-custom, .swiper-pagination-fraction {
            top: 85%;
          }`,
    NextElement:'.swiper-button-next',
    PreviousElement: '.swiper-button-prev',
    SwiperView: 'auto',
    idSwiperContainer: 'swiperContainer'
  };

  static readonly ID_ICON_SIDEBAR = '.toggle-header-icon';
  static readonly ID_COMPONENT_SIDEBAR = 'app-sidebar';
  static readonly OVERFLOW = 'overflow';
  static readonly EVENT_TOUCH_START = 'touchstart';
  static readonly EVENT_TOUCH_END = 'touchend';
  static readonly CONTAINER_TOUCH = '#container-touch';
  static readonly SCROLL = {
    BehaviorAuto: 'auto',
    BlockNearest: 'nearest',
    InlineEnd: 'end'
  };

  static readonly TEXT_ELLIPSIS_STYLE: Partial<CSSStyleDeclaration> = {
    display: ClassHTML.WebkitBox,
    webkitBoxOrient: ClassHTML.BoxOrientVertical,
    overflowWrap : ClassHTML.AnyWhere
  };
}