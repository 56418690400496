export enum PathModel {
    LoginOrq,
    ValidateOTP,
    KeyValidateMF2,
    GetCheckAccounts,
    StockProducts,
    CustomerCards,
    PrintStatus,
    SetPrintStatus,
    StatementsAccount,
    StatementsAccountPDF,
    DebitCardMovements,
    ValidateBank,
    AddNationalAgenda,
    NationalTransfer,
    TransactionReceiptPDF,
    TransferThirdParty,
    PayService,
    ConsultAgenda,
    UserInfo,
    InsurancePolicy,
    InsurancePolicyInfo,
    AccessConsultation,
    Afore,
    GetMovementDetail,
    TokenOverview,
    FacepassOverview,
    PaymentScheduledServices,
    PaymentNoScheduledServices,
    AddThirdPartyAgenda,
    GetThirdAgendas,
    CancelToken,
    CancelFacepass,
    TransferOwnAccounts,
    SendMessage,
    GetDataCard,
    ValidateCaptureLine,
    PinModification,
    GetLocksAndLimits,
    UpdateLockLimitsCard,
    LockGeneralCard,
    ValidPayEdoMex,
    PayEdoMex,
    ReceiptEdoMex,
    LocalTaxesCDMX,
    ReceiptCDMX,
    GetCredits,
    GetCreditAmortization,
    CreditDetails,
    GetInternationalAgendas,
    PayPolicies,
    ServiceDetails,
    InsuranceReceipts,
    ModifyAgentService,
    ModifyAgentServiceNonAgendable,
    ModifyInternationalAgendas,
    ModifyOthersBanks,
    CancelInternationalAgendas,
    CancelAgendasService,
    UserImage,
    SaveUserImage,
    UpdateUserImage,
    GetUserAccounts,
    CancelOthersBanks,
    CreditPayment,
    NewInternationalAddress,
    VerifyBIC,
    RegisterCVV,
    DynamicCVV,
    VerifyAccounts,
    AddPayService,
    GetExchangeRate,
    InternationalTransfer,
    ConsultSubAccounts,
    ConsultPerformance,
    ModifyThirdAgenda,
    CancelThirdAgenda,
    DepositAppliesPromissoryNote,
    GetPDFPromissoryNote,
    GetFacepassTransaction,
    ConsultPDFCreditCard,
    CheckAccountLimitCT,
    GetBeneficiaries,
    PortabilityPayroll,
    InvestmentModification,
    GetPortability,
    ModifyLimitCTS,
    CheckCreditCard,
    ConsultInvestments,
    CancelPortability,
    CreditCardMovements,
    ConsultRetained,
    ConsultPoints,
    ConsultRedemptions,
    SipareCaptureLine,
    GetSiparePayments,
    SipareServicePayment,
    GetSipareReceipt,
    RedemptionMovements,
    RedemptionPoints,
    ConsultPrograms,
    RegisterAccountPoints,
    ConsultDeductibleMedicalExpenses,
    PayDeductibleMedicalExpenses,
    PendingWithdrawalsCodeFlex,
    CancellationWithdrawalsCodeFlex,
    WithdrawalsCodeFlex,
    TotalNotifications,
    NotificationInformation,
    UpdateNotifications,
    GetScheduledPayments,
    CancelScheduledPayment,
    ConsultInsurancePolicyCover,
    ClarificationsFiles,
    ClarificationFileDetails,
    ValidateClaimNumber,
    PayAdditionalContributions,
    EquivalentPoints,
    DeleteUserImage,
    InvestmentPurchase,
    InvestmentSale,
    ClaimPayment,
    SendMessageMS,
    GetInvestmentAccounts,
    GetTransactionHistory,
    BureauAlertsEmail,
    BureauAlerts,
    MonthlyPromotions,
    AforeMovements,
    GetTransactionHistoryXls,
    RSLocations,
    PayPoliciesIFM,
    WebCardApplication,
    ValidateUserCreation,
    CreateAdditionalUser,
    GetAdditionalCards,
    UpdateAdditionalCards,
    GetAdditionalCardsByUser,
    GetAdditionalLocksNLimits,
    UpdateAdditionalCard,
    GetAdditionalSession,
    AddProductAfore,
    ValidateProductAfore,
    PayrollCreditAdvance,
    AforeAssociation,
    AforeVoluntaryValidations,
    AforeVoluntaryWithdrawal,
    AforeVoluntaryWithdrawalReverse,
    AforeVoluntaryWithdrawalConfirmation,
    AforeVoluntaryAccountAssociation,
    AforeDeductibility,
    AforeContribution,
    AforeConfirmationContribution,
    GetAforeBalancesCertificate,
    VoucherAforeVoluntaryContributions,
    VoucherAforeVoluntaryWithdrawal,
    GetVariableIncomeInvestment,
    GetDirectDebitCard,
    GetDirectDebitServicesTDC,
    GetCardsDirectDebitService,
    GetCausesClarification,
    SaveClarification,
    GetListDirectDebitService,
    BalancesPayrollCredit,
    AddDirectDebitServiceDebitCard,
    AddDirectDebitServiceCreditCard,
    AddDirectDebitServiceCreditCardOwn,
    UpdateDirectDebitCardOwn,
    CancelDirectDebitServiceDebitCard,
    CancelDirectDebitServiceCreditCard,
    UpdateDirectDebitCard,
    GetIFMPromotionCDMX,
    GetDirectDebitServicesTDCOwn,
    CancelDirectDebitServiceOwnCreditCard,
    AddCreditOtherBank,
    VariableInvestmentFunds,
    CheckbookRequest,
    VariableInvestmentSale,
    FederalTaxPayment,
    FederalTaxPaymentVoucher,
    GetFederalTaxPayment,
    CancelFederalTaxPayment,
    ConsultSatPayments,
    PaySatCaptureLine,
    GetVoucherSat,
    GetChecks,
    ProtectedChecks,
    GetChecksOtherBanksRequest,
    CancelCheckbooks,
    CancelChecks,
    GetCheckBooks,
    ActivationCheckBooks,
    ProtectedChecksAmounts,
    GetImageOtherBanksRequest,
    CatalogAvailableBanks,
    AforePartialWithdrawalFolios,
    AforePartialValidateInformation,
    AforePartialWithdrawalCertificates,
    AforePartialWithdrawalRegistration,
    AforePartialWithdrawalCalculations,
    VoucherAforePartialWithdrawalUnemployment,
    AforePartialWithdrawalSendMarriageCertificate,
    AforePartialWithdrawalMarriageCertificate,
    Mock,
    SuaUploadFile,
    SuaConsultRegistries,
    SuaPayment,
    SuaGetReceipt,
    CardActivation,
    ActiveCard,
    CardCancellation,
    CardReposition,
    GetDirectDebitAfore,
    DirectDebitAfore,
    DirectDebitAforeConfirmation,
    DirectDebitAforeVoucher,
    ScheduleTransferRequest,
    ScheduleTransferOtherBankRequest,
    AdditionalCreditCard,
    BiometricData,
    ValidateTranscript,
    GetBrokerageHouseAgendas,
    BrokerageHouseTransfers,
    DepositCodeFlex,
    getClausules,
    GetAccountBalanceStatusVisibility,
    UploadDocumentClarification,
    UpdateAccountBalanceStatusVisibility,
    GetPromotionsRewards,
    AssociateFundingAccount,
    UpdateFundingAccount,
    ModifyDebitAccountAlias,
    ModifyCreditCardAlias,
    ModifyInsuranceAccountAlias,
    ModifyCreditLimit,
    CheckPromotions,
    DisassociateFundingAccount,
    EditUserTaxData,
    GetTaxRegimes,
    EditUserEmail,
    EditUserRFC,
    DownloadCfdi,
    GetBrokerageHouseThirdParties,
    RegistrationBrokerageHouse,
    ConsultContractInformation
  } 

export type PathModelLegacy = {
    CdnServices: string,
    CdnAccounts: string,
    Logout: string,
    ReactiveSession: string,
    Environment: string,
    Version: string
};
