import { PathModel } from 'src/app/models/PathModel';

export interface IGetAforeBalancesCertificateRequest {
    numeroCuenta: string;
}

export class GetAforeBalancesCertificateRequest {
    constructor(public data: IGetAforeBalancesCertificateRequest) { }

    get endpoint(): PathModel {
        return PathModel.GetAforeBalancesCertificate;
    }
}