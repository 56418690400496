<div >
  <div class="icon-question">
    <div class="d-flex justify-content-center align-content-center" [ngClass]="isControlCard ? 'pt-4':''">
      <img class="info-icon" [src]=" questionMark | cdnImage" height="auto" width="auto" alt="Info">
    </div>
  </div>
  <div class="title-modal">
    <label class="header-l  d-flex justify-content-center mb-1 t-modal" [innerHTML]="title"></label>
  </div>
  <div class="detail-modal">
    <label class="body-m text-align-center d-modal d-flex justify-content-center"
    [ngClass]="isControlCard || detail === '' ? 'mb-0' : 'mb-4'" [innerHTML]="detail"></label>
    <div class="d-flex justify-content-center p-0" *ngIf="isControlCard">({{ infoCard | maskAccount: 4}})</div>
  </div>
    <ng-container *ngIf="!isControlCard">
  <div class="buttons">
    <div class="d-flex justify-content-center w-100 b-modal">
      <button class="outline-btn modals-btn buttons-max-width button-m"
      (click)="endProcess()" (keydown.enter)="$event.preventDefault()">
        {{btnSend}}
      </button>
      <button class="colored-btn modals-btn buttons-max-width"
      (click)="returnProcess()" (keydown.enter)="$event.preventDefault()">
        {{btnCancel}}
      </button>
    </div>
  </div>
    </ng-container>
    <ng-container *ngIf="isControlCard">
      <div class="flex-column mt-2 p-3">
        <button class="d-flex justify-content-center btn-save mb-3 modals-btn"
        (click)="clickOk()" (keydown.enter)="$event.preventDefault()"> {{btnSend}}</button>
        <button class="d-flex justify-content-center btn-cancel modals-btn"
        (click)="closeModal()" (keydown.enter)="$event.preventDefault()"> {{btnCancel}}</button>
        </div>
    </ng-container>
</div>
