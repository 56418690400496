import { ChangeDetectionStrategy, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { combineLatestWith, map, tap } from 'rxjs';
import { PaginationService } from 'src/app/services/pagination.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Resources } from 'src/core/constants/Resources';
import { Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent {
  @Input() startElement: HTMLElement;
  @Input() showPadding: boolean = true;
  @Input() showWithoutResultsPadding: boolean = true;
  @Input() withoutResultsClasses: string = Strings.EMPTY;
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;


  vm$ = this.paginationService.pagination.pipe(
    tap(pagination => this.setSelectPageNumber(pagination.currentPage)),
    combineLatestWith(this.responsiveService.observe),
    map(([pagination, breakpoints]) => {
      const showPagination = pagination.totalPages > Numbers.One;
      return ({ pagination, breakpoints, showPagination });
    }),
  );

  hasResults$ = this.paginationService.hasResults$;

  paginationForm = this.formBuilder.group({
    page: ['1', []],
  });
  FILLED: string = Resources.FILLED;
  ARROW_DOWN_GREY: string = Resources.ARROW_DOWN_GREY;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly paginationService: PaginationService,
    private readonly responsiveService: ResponsiveService
  ) {}

  focusStart() {
    if(this.startElement){
    this.startElement.scrollIntoView({behavior: 'smooth'});
    this.startElement.focus();
    }
  }

  keyPressEnter(option: number) {
    this.goToPage(option);
    this.dropdowns?.forEach(x => x.close());
  }

  goToPage(option: number) {
    if(this.paginationForm.value.page && Number(this.paginationForm.value.page) === option) return;
    this.paginationService.goToPage(option);
    this.focusStart();
  }

  nextPage() {
    this.paginationService.nextPage();
    this.focusStart();
  }

  previousPage() {
    this.paginationService.previousPage();
    this.focusStart();
  }

  setSelectPageNumber(currentPage: number) {
    this.paginationForm.controls.page.setValue(currentPage.toString(), {
      emitEvent: false,
    });
  }

  hasPreviousPage() {
    return this.paginationService.hasPreviousPage;
  }

  hasNextPage() {
    return this.paginationService.hasNextPage;
  }
}
