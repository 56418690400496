<ng-container *ngIf="breakpoints$ | async as breakpoints">
    <div class="d-flex justify-content-center ">
        <div id="container-bureau-alert" class="modal-bureau-alert"
            [ngClass]="{'modal-bureau-alert-mobile':(breakpoints.mobile  || isIphone)}">
            <div class="col-12">
                <div class="d-flex flex-row-reverse direction-end" ngbAutofocus>
                    <img role="button" aria-label="Close" width="24px" height="24px" alt="Close"
                        [src]="removeIcon | cdnImage" (click)="close()">
                </div>
                <div class="title-padding" [ngClass]="{'title-padding-iphone':isIphone}"></div>
                <div id="title-modal" class="d-flex justify-content-center">
                    <label id="title-current" [ngClass]="{'header-l ':(breakpoints.desktop || breakpoints.tablet)
                    && !isIphone,'header-s':breakpoints.mobile || isIphone}" class="color-label">{{title}}</label>
                </div>
                <ng-container>
                    <div class="name-padding" [ngClass]="{'name-padding-iphone':isIphone}"></div>
                    <div class="d-flex"><label class="body-s color-label">{{clientName}}</label></div>
                    <div class="paragraph-one"></div>
                    <div class="d-flex">
                        <label class="body-s color-label" [innerHTML]="paragraphOne"></label>
                    </div>
                    <div class="list-space"></div>
                    <div class="d-flex">
                        <ul class="m-0">
                            <li *ngFor="let item of items">
                                <p class="body-s color-label">{{item}}</p>
                            </li>
                        </ul>
                    </div>

                    <div *ngIf="!emailInputs">
                        <div class="d-flex email-padding"><label class="body-s color-label">{{modalData}}</label></div>
                        <div [ngClass]="{'email-margin':breakpoints.mobile}"></div>
                        <div class="d-flex email-padding">
                            <a role="button" (click)="emailChange()">
                                <p class="detail-s-highlight-hypertext color-link m-0">{{labelChangeEmail}}</p>
                            </a>
                        </div>
                    </div>

                    <div *ngIf="emailInputs" class="row col-12 email-grid" [ngClass]="{'email-grid-iphone':isIphone}">
                      
                        <div class="email-padding" [ngClass]="{'email-padding-iphone':isIphone}">
                            <form [formGroup]="formGroup">
                            <label for="email"
                              class="ms-2 position-absolute pos-label-input bg-white  px-1 detail-s-highlight
                            color-label">{{labelEmail}}</label>
                            <input id="email" #email1Vc type="text" formControlName="email"
                                class="form-control col-12 inputs body-s color-input input-email"
                                [ngClass]="{'input-email-desktop':(breakpoints.desktop || breakpoints.tablet)
                                && !isIphone,'input-email-mobile': breakpoints.mobile, 'input-iphone':isIphone}"
                                placeholder="{{labelEmail}}"
                                [(ngModel)]="email1" 
                                [ngClass]="(email.invalid && email.touched || errorEmail) 
                                && (email.hasError('required') || errorEmail ) ? 'border-red' : ''"
                                autocomplete="off" cleanInput [cleanRegex]="regexEmail" 
                                [cleanFormControl]="formGroup.controls['email']"
                                maxlength="50" (input)="validateDiffEmail(email1Vc.value,email2Vc.value)"/>
                                <div *ngIf="(email.invalid &&
                                (email.dirty || email.touched )) || errorEmail">
                            <div *ngIf="email.hasError('required')"
                                 [ngClass]="email.hasError('required') ? 'label-red' : ''" class="detail-s">
                                {{required}}</div>
                            <div *ngIf="(email.hasError('email') || errorEmail) && !email.hasError('required')"
                                 [ngClass]="email.hasError('email') || errorEmail ? 'label-red' : ''" class="detail-s">
                                {{invalidEmail}}</div>
                              </div>
                            </form>
                        </div>
                      
                      <div class="new-email-padding" [ngClass]="{'new-email-padding-iphone':isIphone}">
                          <label for="email-confirm"
                            class="ms-2 position-absolute pos-label-input bg-white  px-1 detail-s-highlight
                          color-label"> Confirmar correo</label>
                          <input #email2Vc type="text" id="email-confirm"
                              class="form-control col-12 inputs body-s color-input input-email"
                              [ngClass]="{'input-email-desktop':(breakpoints.desktop || breakpoints.tablet)
                              && !isIphone,'input-email-mobile': breakpoints.mobile, 'input-iphone':isIphone}"
                              placeholder="{{labelEmail}}" [(ngModel)]="email2"
                              [ngClass]="errorDifferent || required2 ? 'border-red ' : ''" 
                              (input)="changeEmailError()"
                              autocomplete="off" cleanInput [cleanRegex]="regexEmail" 
                              (input)="validateDiffEmail(email1Vc.value,email2Vc.value)"
                              maxlength="50">
                          <div *ngIf="required2 " [ngClass]="required2 ? 'label-red' : ''" class="detail-s">
                              {{required}}</div>
                          <div *ngIf="errorDifferent && !required2" 
                          [ngClass]="errorDifferent ? 'label-red' : ''" class="detail-s">
                              {{differentData}}</div>
                      </div>
                    </div>

                    <div class="name-padding"></div>
                    <div class="justify-content-center buttons">
                        <div class="buttons-padding">
                            <button [ngClass]="{'button-m':(breakpoints.desktop || breakpoints.tablet) && !isIphone,
                            'button-s':breakpoints.mobile || isIphone, 'btn-iphone':isIphone}"
                                class="btn-size colored-btn" (click)="continue()"> {{acceptLabel}}</button>
                        </div>
                        <div>
                            <button [ngClass]="{'button-m':(breakpoints.desktop || breakpoints.tablet) && !isIphone,
                            'button-s':breakpoints.mobile || isIphone, 'btn-iphone':isIphone}"
                                class="btn-size btn-cancel" (click)="close()"> Cancelar</button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
