<span
  [ngStyle]="styles"
  [ngClass]="classes"
  class="text-overflow-ellipsis"
  placement="top-start"
  [ngbTooltip]="text?.toString()"
  #tooltip="ngbTooltip"
  triggers="manual" 
  (mouseenter)="tooltip.open()" (mouseleave)="tooltip.close()"
  (click)="toggleTooltip(tooltip)"
  (keydown.enter)="$event.preventDefault()">
  {{ text }}
</span>
