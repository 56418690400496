import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISimpleValue } from 'src/app/interface/ISimpleValue';
import { PATH } from 'src/core/constants/Path';
import { Router } from '@angular/router';
import { Tab } from 'src/core/constants/Constants';

@Injectable({
  providedIn: 'root'
})
export class NotifyRouteService {
  private readonly switchRoute: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>(null);
  private _valueTab: Tab = null;

  constructor(private readonly router:Router) { }

  navigateToTab(tab: Tab) {
    this.valueTab = tab;
    this.router.navigate([PATH.Home]);
  }

  get switchRouteObservable() {
    return this.switchRoute.asObservable();
  }

  set switchRouteData(data: ISimpleValue) {
    this.switchRoute.next(data);
  }

  get valueTab(): Tab {
    return this._valueTab;
  }

  set valueTab(value: Tab) {
    this._valueTab = value;
  }
}
