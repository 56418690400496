import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit, TemplateRef, ViewChild, WritableSignal } from '@angular/core';
import { SubscriptionLike, map } from 'rxjs';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { ModalOptions } from 'src/app/interface/modal-options';
import { AforeDataService } from 'src/app/services/afore-data.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalChecksRequestConfirmDataComponent } from 'src/app/component/modal-checks-request-confirmdata/modal-checks-request-confirmdata.component';
import { ClassHTML, Constants, InputTypes } from 'src/core/constants/Constants';
import { ModalMapComponent } from 'src/app/shared/components/modal-map/modal-map.component';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { Directions } from 'src/app/services/data-checkbook.service';
import { FormRegex } from 'src/core/constants/FormConstants';
import { MapService } from 'src/app/services/map.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AforeStrings } from 'src/core/constants/AforeStrings';


@Component({
  selector: 'app-modal-branches',
  templateUrl: './modal-branches.component.html',
  styleUrls: ['./modal-branches.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalBranchesComponent implements OnInit {
  @ViewChild('ModalMapFooter') modalMapFooter: TemplateRef<HTMLElement>;
  @ViewChild('ModalMap') ModalMap: TemplateRef<HTMLElement>;
  @Input() titleBranches: string = Strings.MODAL_BRANCHES.Title;
  @Input() subtitleBranches: string = Strings.MODAL_BRANCHES.Subtitle;
  @Input() viewMap: boolean = false;
  @Input() branchFinder: boolean = false;
  @Input() textButton: string = Strings.MODAL_BRANCHES.ButtonText;
  @Input() classModal: string = ClassHTML.ModalBranches;
  @Input() classBranch: string = ClassHTML.CardBranch;
  @Input() classMap: string = ClassHTML.ScrollMap;
  @Input() classBtnContinue: string = ClassHTML.SendButton;
  @Input() styleIpad: string = Strings.EMPTY;
  @Input() classSubtitle: string = ClassHTML.Subtitle;
  @Input() classSearch: string = Strings.EMPTY;
  @Input() fontTitle: string = ClassHTML.LabelAfore;
  titleError: string = Strings.MODAL_BRANCHES.ErrorTitle;
  placeholderDT: string = Strings.MODAL_BRANCHES.SearchPlaceholder;
  @Input() placeholderM: string = Strings.PLACEHOLDER_SEARCH_CP;
  enrollment: boolean = false;
  isDeviceM: boolean = false;
  buttonClickedAlert: boolean = false;
  buttonClickedModal: boolean = false;
  removeOperation: string = Resources.CLOSE_CIRCLE_BLUE;
  closeRedIcon: string = Resources.CLOSE_ICON_RED;
  checkMini: string = Resources.CHECK_MINI;
  cancelMini: string = Resources.CANCEL_MINI;
  chevronRight: string = Resources.BTN_CHEVRON_RIGHT;
  dataServiceInfoSL: SubscriptionLike;
  directions: WritableSignal<Array<Directions>> = this.mapService.directionsSignal;
  stringLocation = FormRegex.Locations;
  modalData: {biometric: boolean;identity: boolean;};
  statusInfo: string = Strings.EMPTY;
  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints }))
  );
  withResults: WritableSignal<boolean> = this.mapService.withResultsSginal;
  withoutResults: WritableSignal<boolean> = this.mapService.withoutResultsSignal;
  directionCopy: WritableSignal<Array<Directions>> = this.mapService.directionCopySignal;
  modalInstance: NgbModalRef;

  constructor(private readonly modalService: ModalService,
    private readonly dataServiceInfo: AforeDataService,
    private readonly responsiveService: ResponsiveService,
    private readonly mapService: MapService
  ) { }

  async ngOnInit() {
    this.statusInfo = this.modalData.biometric && !this.modalData.identity?  Strings.MODAL_BRANCH.Proceedings :
    !this.modalData.biometric && this.modalData.identity? Strings.MODAL_BRANCH.Enrollment : AforeStrings.ERROR_STATUS.ErrorStatus;
    this.dataServiceInfoSL= this.dataServiceInfo.userEnrollmentObservable.subscribe(
      (showStatus) => {
        this.enrollment = showStatus.value;
      }
    );
    this.branchFinder ? await this.send() : this.viewMap = this.enrollment;
  }

  close(): void {
    this.buttonClickedModal = true;
    this.dataServiceInfo.detailWPartialData = { value: false };
    this.dataServiceInfo.userApplicationData = { value: false };
    this.dataServiceInfo.startProcessData = { value: false };
    this.modalService.close();
  }
  onConfirm() {
    if (this.branchFinder) {
      this.modalService.close();
      const modalOptions: ModalOptions = {
        centered: false,
        size: Constants.MODAL_OPTIONS.SizeLg,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.GenericClass
      };
      this.modalService.open(ModalChecksRequestConfirmDataComponent, modalOptions);
    } else {
      this.close();
    }
  }

  receiveDataFromSearch(searchFilter: string) {
    this.mapService.filterDirections(searchFilter);
  }

  async send() {
    this.modalService.modalInstance.update({
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeXl,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal
    });
    this.buttonClickedAlert = true;
    document.getElementById(InputTypes.ContainerBranch).className = this.classModal;
    document.getElementById(InputTypes.ModalTop).className = ClassHTML.EndForce;
    this.close();
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.MapModal,
      windowClass:Constants.MODAL_OPTIONS.NoBorder
    };
    const ref = this.modalService.open(ModalMapComponent, modalOptions);
    ref.componentInstance.isCustomTemplate = true;
    ref.componentInstance.customComponentTemplate = this.modalMapFooter;
    ref.componentInstance.footerTemplate = this.ModalMap;
    ref.componentInstance.context = this;
    ref.componentInstance.title = this.titleBranches;
    ref.componentInstance.subtitle = this.subtitleBranches;
  }

  setLocation(event: number){
    this.mapService.setLocation(event);
  }

  closeMapModal(): void {
    this.modalService.close();
  }

  ngOnDestroy() {
    if (!this.buttonClickedModal || !this.buttonClickedAlert) {
      this.dataServiceInfo.detailWPartialData = { value: false };
      this.dataServiceInfo.startProcessData = { value: false };
    }
    this.dataServiceInfoSL?.unsubscribe();
  }

  @HostListener('window:popstate')
  onBack() {
    if (this.modalData) {
      this.modalInstance?.close();
    }
  }
}
