<ng-container *ngIf="breakpoints$ | async as breakpoints" scrollToTop>
    <div class="d-flex justify-content-center ">
        <div id="container-free-bureau" class="modal-free-bureau"
            [ngClass]="{'modal-free-bureau-mobile':breakpoints.mobile, 'bureau-mobile-iphone':isIphone}">
            <div class="col-12">
                <div class="d-flex flex-row-reverse direction-end" ngbAutofocus>
                    <img role="button" aria-label="Close" class="icon-close" width="auto" height="auto" alt="Close"
                        [src]="removeIcon | cdnImage" (click)="close()">
                </div>
                <div class="title-padding"></div>
                <div id="title-modal" class="d-flex justify-content-center color-label">
                    <label id="title-current" [ngClass]="{'header-l ':(breakpoints.desktop || breakpoints.tablet)
                    && !isIphone, 'header-s':(breakpoints.mobile || isIphone)}">{{title}}</label>
                </div>
                <ng-container>
                    <div class="name-padding" [ngClass]="{'name-padding-iphone':isIphone}"></div>
                    <div class="d-flex"><label class="body-s color-label">{{clientName}}</label></div>
                    <div class="paragraph-one"></div>
                    <div class="d-flex">
                        <label class="body-s color-label pone-width" [ngClass]="{'pone-width-iphone':isIphone}"
                            [innerHTML]="paragraphOne"></label>
                    </div>
                    <div class="paragraph-one"></div>
                    <div class="d-flex">
                        <label class="body-s color-label" [innerHTML]="paragraphTwo"></label>
                    </div>
                    <div class="list-space"></div>
                    <div class="d-flex">
                        <ul class="m-0">
                            <li *ngFor="let item of items">
                                <p class="body-s custom-p color-label">{{item}}</p>
                            </li>
                        </ul>
                    </div>
                    <div class="paragraph-three"></div>
                    <div class="d-flex">
                        <label class="body-s color-label points-four" [ngClass]="{'points-four-iphone':isIphone}"
                            [innerHTML]="paragraphThree"></label>
                    </div>
                    <div class="paragraph-four"></div>
                    <div class="d-flex">
                        <label class="body-s color-label points-four" [ngClass]="{'points-four-iphone':isIphone}"
                            [innerHTML]="paragraphFour"></label>
                    </div>
                    <div class="check-paragraph"></div>

                    <div class="autorization">
                        <div class="custom-check">
                            <label for="check-mark" class="container" id="c1">
                                <input id="check-mark" type="checkbox" (change)="onCheckboxChange($event)" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="custom-mesaje">
                            <div class="d-flex">
                                <label class="detail-s-highlight autorization-text"
                                    [ngClass]="{'autorization-text-iphone':isIphone}"
                                    [innerHTML]="authorization"></label>
                            </div>
                        </div>
                    </div>
                    <div class="link-space"></div>
                    <div class="redirection" [ngClass]="{'redirection-ipad':isiPad, 'redirection-iphone': isIphone}">
                        <div><a (click)="confirmLeavePage(hrefBureau)" type="button" target="_blank">
                                <span class="detail-s-highlight-hypertext color-link">{{bureauUrl}}</span>
                            </a>
                        </div>
                        <div class="mobile-p"><a href="{{ wfContract | cdnImage }}" target="_blank">
                                <span class="detail-s-highlight-hypertext color-link">{{contractBureau}}</span>
                            </a>
                        </div>
                    </div>
                    <div class="button-padding"></div>
                    <div class="justify-content-center buttons">
                        <div class="link-space">
                            <button [ngClass]="{'is-not-checked':!isChecked,
                            'button-m':((breakpoints.desktop || breakpoints.tablet) && !isIphone),
                            'button-s': breakpoints.mobile || isIphone, 'btn-iphone':isIphone}"
                                class="btn-size colored-btn" (click)="continue()">{{hire}}</button>
                        </div>
                        <div>
                            <button [ngClass]="{'button-m':(breakpoints.desktop || breakpoints.tablet) && !isIphone,
                            'button-s':breakpoints.mobile || isIphone, 'btn-iphone':isIphone}"
                                class="btn-size btn-cancel" (click)="close()">{{cancel}}</button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
