import { PathModel } from '../models/PathModel';

interface ICheckCreditCardRequest {
    IdSession: string;
    MedioAcceso: string;
    OrigenConsulta?: string;
    IpCliente: string;
}

export class CheckCreditCardRequest{
    constructor(public data: ICheckCreditCardRequest){}

    get endpoint(): PathModel{
        return PathModel.CheckCreditCard;
    }
}