import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentServiceCardStatusService {

  private readonly _checkStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _isLineCaptureModified: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  get checkStatus$() {
    return this._checkStatus.asObservable();
  }

  get checkStatus(){
    return this._checkStatus.getValue();
  }

  set checkStatus(data: boolean) {
    this._checkStatus.next(data);
  }

  get status$() {
    return this._status.asObservable();
  }

  get status(){
    return this._status.getValue();
  }

  set status(data: boolean) {
    this._status.next(data);
  }

  get isLineCaptureModified$() {
    return this._isLineCaptureModified.asObservable();
  }

  get isLineCaptureModified(){
    return this._isLineCaptureModified.getValue();
  }

  set isLineCaptureModified(data: boolean) {
    this._isLineCaptureModified.next(data);
  }

}
