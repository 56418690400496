export class GeneralInformationStrings {
  static readonly EmailError = 'Tu cuenta no cuenta con email asociado';
  static readonly PhoneError = 'Tu cuenta no cuenta con un número de celular asociado';
  static readonly AddressError = 'No cuentas con una dirección asociada';
  static readonly GeneralError = 'Lo sentimos, tuvimos un inconveniente al recuperar tus datos. Inténtalo más tarde';
  static readonly TitleGeneral = {
    TitleToken: 'Token Móvil',
    SubtitleToken: 'Puedes asociar hasta dos dispositivos',
    ErrorToken: 'No cuentas con un Token asociado',
    TitleFacepass: 'Facepass',
    SubtitleFacepass: 'Puedes asociar hasta dos dispositivos',
    ErrorFacepass: 'No cuentas con dispositivos asociados'
    };
  static readonly OperationsInProcess = 'Operaciones en trámite';
  static readonly AvailableLineOfCredit = 'Línea de crédito disponible';
  static readonly GENERAL_INFORMATION = 'Información general';
  static readonly TEXT_ACTIVE_TOKEN = 'Activa tu Token Móvil hasta en dos dispositivos:';
  static readonly TEXT_ALL_DEVICES_ACTIVE = 'Los siguientes dispositivos se encuentran asociados:';
  static readonly CODE_POSTAL_TAX = 'CPF';
  static readonly KEY_EMAIL = 'email';
  static readonly KEY_PHONE = 'phone';
  static readonly KEY_ADDRESS = 'address';
  static readonly KEY_TAX_DATA = 'DatosFiscales';
  static readonly KEY_RFC = 'rfc';
  static readonly KEY_RFC_OBJ_SERVICE = 'Rfc';
  static readonly KEY_POSTAL_CODE_TAX = 'CodigoPostalFiscal';
  static readonly KEY_TAX_REGIMES = 'RegimenFiscales';
  static readonly KEY_TAX_REGIME = 'RegimenFiscal';
  static readonly KEY_IMAGE_PROFILE = 'thumbnail';
  static readonly KEY_IMAGE_SECURITY = 'securityImage';
  static readonly KEY_QUERY_IMAGE_SECURITY = 'queryError';
  static readonly KEY_APP_MOVIL = 'APP';
  static readonly EMAIL = 'Email';
  static readonly NUMBER_PHONE = 'Teléfono celular';
  static readonly ADDRESS = 'Dirección';
  static readonly CANCELLED_STATUS = 'Cancelled';
  static readonly ACTIVE_STATUS_TOKEN = 'Active';
  static readonly ACTIVE_STATUS_FACEPASS = 'Activo';
  static readonly CONTENT_TYPE_GENERAL_INFO = 'GeneralInfo';
  static readonly HEADER_INDICATION_EDIT_MODAL = 'Actualización de datos';
  static readonly MESSAGE_INDICATION_EDIT_MODAL = 'Por tu seguridad, ingresa con Facepass a tu Banca, para solicitar la actualización de tus datos';
  static readonly MESSAGE_INDICATION_EDIT_ADDRESS = '<hr><p class="mb-0">Por seguridad, es necesario acudir a sucursal para la actualización de tu domicilio.</p>'
  + '<br> <p class="text-center mb-1">Considera que te solicitaremos:</p>' + 
  '<ul class="pe-4"><li class="text-start">Identificación oficial vigente.</li>'+ 
  '<li class="text-start">Comprobante de telefonía* con una antigüedad no mayor a 3 meses.</li></ul>'+
  '<p class="text-ligth-grey text-start"> * Fija o móvil, de esta última siempre y cuando seas el titular</p>';
  static readonly TYPE_TOKEN_MOBILE = 'MOVIL';
  static readonly TYPE_TOKEN_PHYSICAL = 'FISICO';
  static readonly FACEPASS = 'facepass';
  static readonly MESSAGE_FACEPASS_SUCCESS = '<p class="head-message">Modificación exitosa</p>'+
  '<p class="message" >Facepass ha sido desactivado en este dispositivo a partir de ahora. </p>';
  static readonly MESSAGE_TOKEN_SUCCESS = '<p class="head-message">Modificación exitosa</p>'+
  '<p class="message">El Token Móvil ha sido cancelado.</p>';
  static readonly MESSAGE_INDICATION_EDIT_EMAIL = '<p class="mb-0 message">Recientemente solicitaste la actualización de tus datos.'+
  'Por seguridad, podrás solicitar un nuevo cambio después de 90 días a partir de la última solicitud.';
  static readonly IMAGE_DATA_TEMPLATE = 'imageData';
  static readonly MESSAGE_ATENTION_CENTER = '<p class="text-center">Para continuar con tu operación <br>comunicate a nuestro Centro de atención telefónica <br> 8000 90 900000<p>';
  static readonly CLASS_IMG_ICON_INFO_MODIFY = 'icon-info-modify';
  static readonly CLASS_IMG_ICON_CENTER_ATTENTION = 'icon-center-attention';
}
