<ng-container *ngIf="vm$ | async as vm">
    <div id="container-modal-branches">
        <div id="src-modal-top" class="col-12 center-force m-b-28">
            <form>
              <input type="button" id="icon-error" class="input-btn">
              <img class="icon-error" [src]="closeRedIcon | cdnImage" alt="close" width="auto" height="auto">
            </form>
        </div>

        <div id="header-error" class="col-12 center-force">
            <label class="header-s">{{titleError}}</label>
            <div id="details-one">
                <img id="check" alt="check" width="auto" height="auto"
                [src]="(modalData.identity? checkMini: cancelMini)| cdnImage"
                [class]="modalData.identity? 'check-mini': 'cancel-mini'">
                <label class="header-xs m-b-6 m-icons justify-content-center">Expediente de Identidad</label>
            </div>
            <div id="details-two">
                <img id="fail" alt="check" width="auto" height="auto"
                [src]="(modalData.biometric? checkMini: cancelMini) | cdnImage"
                [class]="modalData.biometric? 'check-mini': 'cancel-mini'">
                <label class="header-xs m-icons justify-content-center">Enrolamiento biométrico</label>
            </div>
          </div>
            <div class="col-12 center-force">
                <div id="conditions" class="d-conditions m-b-24">
                    <label class="detail-m color-black-light text-left m-global">
                        Estimado Trabajador, usted no cuenta con “{{statusInfo}}”,
                        favor de acudir a la <strong>
                            Sucursal</strong> más cercana que brinde
                        <strong>Servicios de Afore</strong> a conformarlo, llevando consigo los siguientes
                        documentos:</label>
                    <ul>
                        <li class="detail-m color-black-light m-global">
                            Identificación oficial vigente (INE, IFE, Pasaporte, Documento Migratorio)</li>
                        <li class="detail-m color-black-light m-global">
                            Comprobante de domicilio vigente 3 meses (Agua, Luz, Predial, EDC Bancario, TV Paga)</li>
                    </ul>

                    <label class="detail-m color-black-light">Consulta las sucursales que brindan servicios
                        <strong>
                            AFORE</strong>
                        :</label>
                    <div id="btn-title" class="col-12 m-t-23 pb-2">
                        <div class="d-flex justify-content-center align-content-center">
                            <button class="btn-send button-m modals-btn" (click)="send()">
                                {{titleBranches}}</button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    </ng-container>
    <ng-template #ModalMap>
      <ng-container *ngIf="vm$ | async as vm">
        <div class="d-flex justify-content-center align-items-center mt-3 map-aling">
          <button
            [class.button-s]="vm.breakpoints.mobile"
            [class.button-m]="!vm.breakpoints.mobile"
            class="colored-btn btn-send modals-btn"
            (click)="closeMapModal()"
          >
            Listo
          </button>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #ModalMapFooter>
      <ng-container *ngIf="vm$ | async as vm">
        <div>
          <p class="text-secondary-800 header-l" id="top-header" #Map >
            {{titleBranches}}
          </p>
        </div>
        <p class="body-m secondary-color">
          {{subtitleBranches}}
        </p>
        <div class="gap-3 map-toolbar" id="toolsContainer">
          <div class="ms-2 px-2 spacing-select w-100">
          </div>
            <div class="search-container" *ngIf="!withoutResults()">
              <app-search-component
                classSearch="input-group p-0"
                [placeholder]="
                  vm.breakpoints.mobile
                    ? 'Buscar por C.P. '
                    : 'Buscar por C.P. o dirección' "
                    [searchCustom]="false"
                    [onlyNumber]="false"
                    [idSearch]="'searchbranch'"
                    [classCheckbookInput]="vm.breakpoints.mobile ? 'search-custom-class':'search-custom-desktop-class'"
                    (dataFromSearch)="receiveDataFromSearch($event)"
                    [regex]="stringLocation"
              ></app-search-component>
          </div>
        </div>
        <div class="mb-3">
          <ng-container *ngIf="withResults(); else WithoutResults">
            <app-card-branches [directions]="directionCopy" (index)="setLocation($event)"></app-card-branches>
          </ng-container>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #WithoutResults>
      <div class="header-m d-flex text-center justify-content-center">
        Sin resultados<br>No se encontraron resultados para tu búsqueda
      </div>
    </ng-template>
