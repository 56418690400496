import { PathModel } from 'src/app/models/PathModel';

export type TVoluntaryWithdrawalConfirmationRequest = {
    numeroSolicitud: number,
    otp: string
};

export class VoluntaryWithdrawalConfirmationRequest {
    constructor(public data: TVoluntaryWithdrawalConfirmationRequest) { }

    get endpoint(): PathModel {
        return PathModel.AforeVoluntaryWithdrawalConfirmation;
    }

}