import { PathModel } from 'src/app/models/PathModel';

type IModifyCreditCardAliasRequest = {
  CardNumber: string;
  NickName: string;
  Source: string;
};

export class ModifyCreditCardAliasRequest {
  constructor(public data: IModifyCreditCardAliasRequest) { }

  get endpoint(): PathModel {
    return PathModel.ModifyCreditCardAlias;
  }
}
