import { PathModel } from 'src/app/models/PathModel';

export interface IBalancesPayrollCreditRequest {
  idCuentaProducto: number;
  cuenta: string;
  origen: string;
}
export class BalancesPayrollCreditRequest{
    constructor(public data: IBalancesPayrollCreditRequest){}

    get endpoint(): PathModel{
        return PathModel.BalancesPayrollCredit;
    }
}
