import { PathModel } from 'src/app/models/PathModel';

export type TTotalNotificationsRequest = {
    IdSesion: string,
    nombreAplicacion: string
};

export class TotalNotificationsRequest {
    constructor(public data: TTotalNotificationsRequest){ }

    get endpoint(): PathModel {
        return PathModel.TotalNotifications;
    }
    
}