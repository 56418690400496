import { PathModel } from 'src/app/models/PathModel';

interface ICreditCardMovementsRequest {
    CardNumber: string;
    IdSession: string;
    Source: string;
    IsAditional: boolean;
}
export class CreditCardMovementsRequest {
    constructor(public data: ICreditCardMovementsRequest) { }
    get endpoint(): PathModel {
        return PathModel.CreditCardMovements;
    }
}
