import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  Renderer2
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { combineLatestWith, filter, map } from 'rxjs';
import { Alert } from 'src/app/interface/Alert';
import { AlertService } from 'src/app/services/alert.service';
import { NotifyRouteService } from 'src/app/services/notify-route.service';
import { SidebarResponsiveService } from 'src/app/services/sidebar-responsive.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { SidebarService } from 'src/app/shared/sidebar.service';
import { Char, ClassHTML, ElementsHTML, Numbers, Position} from 'src/core/constants/Constants';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { StylesConstants } from 'src/core/constants/StylesConstants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @ViewChild('alertContainer') alertContainer: ElementRef<HTMLInputElement>;
  @ViewChild('searchInput') searchInput: ElementRef;
  @Output() show: EventEmitter<boolean> = new EventEmitter<boolean>();
  isCustomAlert: boolean = false;
  sideBar: boolean = false;
  isDesktop: boolean = true;
  alert: Alert = {
    type: Strings.EMPTY,
    class: ClassHTML.AlertClass,
    iconHeader: Resources.SHAPE_WARNING,
    message: Strings.ALERTS.LoginAlert,
    icon: Resources.CANCEL
  };
  search: string = Resources.SEARCH;
  showSearches: boolean = false;
  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(
      this.notifyRouteService.switchRouteObservable,
      this.alertService.isClose$,
      this.sidebarService.stateObservable
    ),
    map(([breakpoints, switchRoute, alertClose, isSidebarClosed]) => {
      this.isDesktop = breakpoints.desktop;
      if (switchRoute && !this.isCustomAlert) {
        this.isCustomAlert = true;
        this.alertService.alert = this.alert;
      }
      if (this.alertContainer && breakpoints.desktop) {
        this.alertContainer.nativeElement.scrollIntoView();
      } else if (this.alertContainer) {
        window.scrollTo(Numbers.Zero, Numbers.Zero);
      }
      return { breakpoints, switchRoute: switchRoute?.value, alertClose, isSidebarClosed };
    })
  );

  logo: string = Resources.LOGO;
  toggleMobile: string = Resources.ALIGN_HORIZONTAL_LEFT_DARK;
  toggleDesktop: string = Resources.ALIGN_HORIZONTAL_LEFT_LIGH;
  selectedOptionIndex: number = Position.Last;
  showAppSearch: boolean = false;
  isSelectedOption: boolean = false;
  showSearchCleaner: boolean = false;
  constructor(
    private readonly router: Router,
    private readonly notifyRouteService: NotifyRouteService,
    private readonly alertService: AlertService,
    private readonly sidebarService: SidebarService,
    private readonly responsiveService: ResponsiveService,
    private readonly sidebarResponsiveService: SidebarResponsiveService,
    private readonly renderer: Renderer2
  ) { }

  ngOnInit(): void {
    const root = document.documentElement;
    root.style.setProperty(ModalConstants.OFFSSET.Name, ModalConstants.OFFSSET.SidebarOpen);
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const actualRoute = event.url;
        this.notifyRouteService.switchRouteData = {
          value:
            actualRoute.includes(PATH.Login) || actualRoute === Char.Slash,
        };
      });
    }


  togggleAppSearch(event: boolean){
    this.showAppSearch = event;
    this.showSearches = this.showAppSearch ? this.showSearches : false;
    this.show.emit(this.showAppSearch);
  }

  toggleMenu() {
    if (this.isDesktop) {
      document.body.classList.toggle(ClassHTML.SidebarToggled);
      this.sideBar = !this.sideBar;
      this.sidebarService.stateData = this.sideBar;
      const root = document.documentElement;
      root.style.setProperty(ModalConstants.OFFSSET.Name, this.sideBar ? ModalConstants.OFFSSET.SidebarClose : ModalConstants.OFFSSET.SidebarOpen);
    } else {
      this.sidebarResponsiveService.stateData = !this.sidebarResponsiveService.subjectVal;
      if(this.sidebarResponsiveService.stateData){
        this.renderer.removeStyle(document.body, StylesConstants.OVERFLOW);
      }else{
        this.renderer.setStyle(document.body, StylesConstants.OVERFLOW, ElementsHTML.Hidden);
      }
    }
  }
}
