import { PathModel } from 'src/app/models/PathModel';

interface IInsurancePolicyPlRequest {
    idSession: string;
    medioAcceso: string;
    origenConsulta: string;
    ipCliente: string;
}

export class InsurancePolicyPlRequest{
    constructor(public data: IInsurancePolicyPlRequest){ }

    get endpoint(): PathModel {
      return PathModel.InsurancePolicy;
  }
}
