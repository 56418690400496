import { PathModel } from 'src/app/models/PathModel';

export interface IUserRFCRequest {
    IdSession: string;
    RFC:string;
}
export class ModifyRFCRequest {
    constructor(public data: IUserRFCRequest) { }
    get endpoint(): PathModel {
        return PathModel.EditUserRFC;
    }
}
