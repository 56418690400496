<ng-container *ngIf="vm$ | async as vm">
  <content-wrapper [useFlex]="!vm.breakpoints.mobile">
    <div content class="margin-transfer no-space-top">
      <div>
        <app-header-transfer-card class="header-transfer" [title]="title" [subtitle]="subtitle"
          [returnFn]="returnTabInsurance">
        </app-header-transfer-card>
        <div class="pt-2" *ngIf="vm.breakpoints.mobile">
          <label for="" class="subtitle-transfer-card body-s">{{subtitle}}</label>
        </div>
      </div>
      <div *ngIf="dataError" focusError></div>
      <div id="form-container">
        <div class="w-column">
          <app-enter-amount [label]="enterAmountLabel" [maxAmount]="maxAmount"></app-enter-amount>
          <div>
            <app-select-account></app-select-account>
          </div>
        </div>
        <div class="w-column" id="operation">
          <form>
            <label class="header-xs" [ngClass]="vm.breakpoints.mobile ? 'my-2' : 'my-3'">
              {{ titleOperation }}
            </label>
            <div id="display-form" class="drop-shadow-m" [class.mt-2]="vm.breakpoints.mobile">
              <div id="type-input">
                <label for="" class="m-2 position-absolute detail-s-highlight label-title">
                  <span class="detail-s-highlight bg-white px-1 black-light">
                    {{ operationType }}
                  </span>
                </label>
                <input id="" type="text" class="form-control inputs body-s input-detail properties-disabled"
                  onpaste="return false" [value]="typeContribution" [disabled]="true" />
                <div *ngIf="false" class="detail-m validation-error"></div>
              </div>
              <div>
                <label for="" class="m-2 position-absolute detail-s-highlight label-title">
                  <span class="detail-s-highlight bg-white px-1 black-light">
                    {{ textDetail }}
                  </span>
                </label>
                <input id="" type="text" class="form-control inputs body-s input-detail properties-disabled"
                  onpaste="return false" [disabled]="true" [value]="policy" />
              </div>
            </div>
          </form>
          <div>
            <label class="detail-m info-color text-align-justify">
              {{ info }}
            </label>
          </div>
        </div>
      </div>
      <div class="btn-container pt-40 d-flex justify-content-center">
        <button class="button-m colored-btn btn-generic" (click)="next()" (keydown.enter)="$event.preventDefault()">
          Continuar
          <img [src]="chevronRight | cdnImage" alt=">" width="auto" height="auto"
            [ngClass]="vm.breakpoints.mobile ? 'img-next' : ''" />
        </button>
      </div>
    </div>
  </content-wrapper>
</ng-container>
