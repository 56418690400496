import { PathModel } from 'src/app/models/PathModel';

interface IGetDirectDebitCreditCardOwnRequest {
  numeroCuenta: string;
  origen: string;
}

export class GetDirectDebitCreditCardOwnRequest {
  constructor(public data: IGetDirectDebitCreditCardOwnRequest) { }

  get endpoint(): PathModel {
    return PathModel.GetDirectDebitServicesTDCOwn;
  }
}
