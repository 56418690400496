import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

enum Breakpoints {
  'ExtraSmall' = '(max-width: 659.98px)',
  'Small' = '(min-width: 660px) and (max-width: 767.98px)',
  'Medium' = '(min-width: 768px) and (max-width: 1349px)',
  'Large' = '(min-width: 1350px) and (max-width: 1600px)',
  'ExtraLarge' = '(min-width: 1601px) and (max-width: 1710px)'
}

export interface CustomBreakpointState {
  extraMobile: boolean,
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
  desktopLarge: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CustomResponsiveService {

  private readonly observer$ = this.breakpointObserver.observe([
    Breakpoints.ExtraSmall,
    Breakpoints.Small,
    Breakpoints.Medium,
    Breakpoints.Large,
    Breakpoints.ExtraLarge
  ]);

  constructor(private readonly breakpointObserver: BreakpointObserver) { }

  get observe() {
    return this.observer$.pipe(
      map(result => result.breakpoints),
      map(
        breakpoints =>
          ({
            extraMobile: breakpoints[Breakpoints.ExtraSmall],
            mobile: breakpoints[Breakpoints.Small],
            tablet:
              breakpoints[Breakpoints.Medium],
            desktop:
              breakpoints[Breakpoints.Large],
            desktopLarge:
              breakpoints[Breakpoints.ExtraLarge]
          } as CustomBreakpointState)
      )
    );
  }

  customObserve(breakpoints: string){
    return this.breakpointObserver.observe(breakpoints);
  }
}
