import { Strings } from 'src/core/constants/Strings';
import { AforeStrings } from 'src/core/constants/AforeStrings';
export enum RetirementType {
  partialGeneral = 0,
  unemployment = 'unemployment',
  marriage = 'marriage',
  total = 'total',
  retirementAge='retirementAge',
  retirementPension='retirementPension'
}

export class AforeConstants {
  static readonly MODAL_OPTIONS = {
    CrossButtonModalRetirementUnemployement: 'cross-btn-modal-retirement-unemployement',
    CrossBtnTotal: 'cross-btn-total',
    ModalDirectDebitModifyClass: 'modal-modification-domiciliation',
    PatrimonialStyleOne : 'patrimonial-style-one',
    PatrimonialStyleTwo:'patrimonial-style-two',
    StyleCloseOne : 'style-close-one',
    StyleCloseTwo :'style-close-two',
    ModalVoluntaryRetirementComponent: 'modal-voluntary'
    };

  static readonly AFORE_OPTIONS = {
    unemployment: 'DESEMPLEO',
    marriage: 'MATRIMONIO'
  };

  static readonly AFORE_FILES = {
    PDF: 'pdf',
    TIF: 'tif',
    TIFF: 'tiff',
    JPG: 'jpg',
    JPEG: 'jpeg'
  };


  static readonly TWENTY = 20;
  static readonly PERSONAL_REGISTRY = 0;
  static readonly FAMILY_REGISTRY = 1;
  static readonly NUMBERS_LETTERS_ONE_SPACE = /^\s+|\s(?=\s)|[^a-zA-Z0-9\s]+/g;
    static readonly CALENDAR = 'calendar';
    static readonly requiredMessage = Strings.GENERIC_ERRORS.Required;

    static readonly MarriagePlace  = [
      {
        value: AforeStrings.ESTATES_AFORE.AGUASCALIENTES,
        label: AforeStrings.ESTATES_AFORE.AGUASCALIENTES
      },
      {
        value: AforeStrings.ESTATES_AFORE.BAJA_CALIFORNIA,
        label:AforeStrings.ESTATES_AFORE.BAJA_CALIFORNIA
      },
      {
        value: AforeStrings.ESTATES_AFORE.BAJA_CALIFORNIA_SUR,
        label:AforeStrings.ESTATES_AFORE.BAJA_CALIFORNIA_SUR
      },
      {
        value: AforeStrings.ESTATES_AFORE.CAMPECHE,
        label:AforeStrings.ESTATES_AFORE.CAMPECHE
      },
      {
        value: AforeStrings.ESTATES_AFORE.COAHUILA,
        label:AforeStrings.ESTATES_AFORE.COAHUILA
      },
      {
        value: AforeStrings.ESTATES_AFORE.COLIMA,
        label:AforeStrings.ESTATES_AFORE.COLIMA
      },
      {
        value: AforeStrings.ESTATES_AFORE.CHIAPAS,
        label:AforeStrings.ESTATES_AFORE.CHIAPAS
      },
      {
        value: AforeStrings.ESTATES_AFORE.CHIHUAHUA,
        label:AforeStrings.ESTATES_AFORE.CHIHUAHUA
      },
      {
        value: AforeStrings.ESTATES_AFORE.CIUDAD_DE_MEXICO,
        label:AforeStrings.ESTATES_AFORE.CIUDAD_DE_MEXICO
      },
      {
        value: AforeStrings.ESTATES_AFORE.DURANGO,
        label:AforeStrings.ESTATES_AFORE.DURANGO
      },
      {
        value: AforeStrings.ESTATES_AFORE.GUANAJUATO,
        label:AforeStrings.ESTATES_AFORE.GUANAJUATO
      },
      {
        value: AforeStrings.ESTATES_AFORE.GUERRERO,
        label:AforeStrings.ESTATES_AFORE.GUERRERO
      },
      {
        value: AforeStrings.ESTATES_AFORE.HIDALGO,
        label:AforeStrings.ESTATES_AFORE.HIDALGO
      },
      {
        value: AforeStrings.ESTATES_AFORE.JALISCO,
        label:AforeStrings.ESTATES_AFORE.JALISCO
      },
      {
        value: AforeStrings.ESTATES_AFORE.MEXICO,
        label:AforeStrings.ESTATES_AFORE.MEXICO
      },
      {
        value: AforeStrings.ESTATES_AFORE.MICHOACAN,
        label:AforeStrings.ESTATES_AFORE.MICHOACAN
      },
      {
        value: AforeStrings.ESTATES_AFORE.MORELOS,
        label:AforeStrings.ESTATES_AFORE.MORELOS
      },
      {
        value: AforeStrings.ESTATES_AFORE.NAYARIT,
        label:AforeStrings.ESTATES_AFORE.NAYARIT
      },
      {
        value: AforeStrings.ESTATES_AFORE.NUEVO_LEON,
        label:AforeStrings.ESTATES_AFORE.NUEVO_LEON
      },
      {
        value: AforeStrings.ESTATES_AFORE.OAXACA,
        label:AforeStrings.ESTATES_AFORE.OAXACA
      },
      {
        value: AforeStrings.ESTATES_AFORE.PUEBLA,
        label:AforeStrings.ESTATES_AFORE.PUEBLA
      },
      {
        value: AforeStrings.ESTATES_AFORE.QUERETARO,
        label:AforeStrings.ESTATES_AFORE.QUERETARO
      },
      {
        value: AforeStrings.ESTATES_AFORE.QUINTANA_ROO,
        label:AforeStrings.ESTATES_AFORE.QUINTANA_ROO
      },
      {
        value: AforeStrings.ESTATES_AFORE.SANLUIS_POTOSI,
        label:AforeStrings.ESTATES_AFORE.SANLUIS_POTOSI
      },
      {
        value: AforeStrings.ESTATES_AFORE.SINALOA,
        label:AforeStrings.ESTATES_AFORE.SINALOA
      },
      {
        value: AforeStrings.ESTATES_AFORE.SONORA,
        label:AforeStrings.ESTATES_AFORE.SONORA
      },
      {
        value: AforeStrings.ESTATES_AFORE.TABASCO,
        label:AforeStrings.ESTATES_AFORE.TABASCO
      },
      {
        value: AforeStrings.ESTATES_AFORE.TAMAULIPAS,
        label:AforeStrings.ESTATES_AFORE.TAMAULIPAS
      },
      {
        value: AforeStrings.ESTATES_AFORE.TLAXCALA,
        label:AforeStrings.ESTATES_AFORE.TLAXCALA
      },
      {
        value: AforeStrings.ESTATES_AFORE.VERACRUZ,
        label:AforeStrings.ESTATES_AFORE.VERACRUZ
      },
      {
        value: AforeStrings.ESTATES_AFORE.YUCATAN,
        label:AforeStrings.ESTATES_AFORE.YUCATAN
      },
      {
        value: AforeStrings.ESTATES_AFORE.ZACATECAS,
        label:AforeStrings.ESTATES_AFORE.ZACATECAS
      }
    ];
}

export enum Letters {
  A = 'A',
  B = 'B'
}
