import { PathModel } from 'src/app/models/PathModel';

interface SuaPayment {
  idSuaPago : number;
  idCuentaProducto: number;
  numeroCuenta: string;
  otp: string;
}

export class SuaPaymentRequest {
  constructor(public data: SuaPayment) {}

  get endpoint(): PathModel {
    return PathModel.SuaPayment;
  }
}
