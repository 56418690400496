import { PathModel } from 'src/app/models/PathModel';

type TInsurancePolicyInfo = {
    IdSession: string;
    Emisor: string;
    Carpeta: string;
    Cis: string;
    Ramo: string;
};

export class InsurancePolicyInfoRequest {
    constructor(public data: TInsurancePolicyInfo){ }

    get endpoint(): PathModel {
      return PathModel.InsurancePolicyInfo;
  }
}
