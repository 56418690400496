import { Char, InputTypes, Numbers, Regex } from 'src/core/constants/Constants';
import { InputConfig } from 'src/app/interface/IInputConfig';
import { Strings } from 'src/core/constants/Strings';
import { LocalTaxesConstants } from 'src/core/constants/LocalTaxesConstants';
import { FormRegex } from 'src/core/constants/FormConstants';
import { LocalTaxesDynamicValidatorService } from './local-taxes-dynamic-validations.service';

export class LocalTaxesDynamicService {
    static readonly _car: InputConfig[] =[
        {
          type: InputTypes.Text,
          label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TRANSAC_CAR.INPUTS.MODEL_INPUT.label,
          name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TRANSAC_CAR.INPUTS.MODEL_INPUT.name,
          placeholder: Strings.EMPTY,
          value: Strings.EMPTY,
          readonly:true
        },
        {
          type: InputTypes.Text,
          label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TRANSAC_CAR.INPUTS.LICENSES_PLATES.label,
          name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TRANSAC_CAR.INPUTS.LICENSES_PLATES.name,
          placeholder: Strings.EMPTY,
          value: Strings.EMPTY,
          readonly:true
        },
        {
          type: InputTypes.Text,
          label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TRANSAC_CAR.INPUTS.BRAND.label,
          name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TRANSAC_CAR.INPUTS.BRAND.name,
          placeholder: Strings.EMPTY,
          value: Strings.EMPTY,
          readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TRANSAC_CAR.INPUTS.ORIGIN.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TRANSAC_CAR.INPUTS.ORIGIN.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true,
            hide:true
          }
    ];
    static readonly _pollutingVehicles: InputConfig[] =[
      {
        type: InputTypes.Text,
        label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.POLLUTING_VEHICLES.INPUTS.INFRINGEMENT_FOLIO.label,
        name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.POLLUTING_VEHICLES.INPUTS.INFRINGEMENT_FOLIO.name,
        placeholder: Strings.EMPTY,
        value: Strings.EMPTY,
        readonly:true
      },
      {
        type: InputTypes.Text,
        label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.POLLUTING_VEHICLES.INPUTS.INFRINGEMENT_YEAR.label,
        name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.POLLUTING_VEHICLES.INPUTS.INFRINGEMENT_YEAR.name,
        placeholder: Strings.EMPTY,
        value: Strings.EMPTY,
        readonly:true
      }
  ];
    static readonly _tenure: InputConfig[] =[
        {
          type: InputTypes.Text,
          label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.REFERENCE.label,
          name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.REFERENCE.name,
          placeholder: Strings.EMPTY,
          value: Strings.EMPTY,
          readonly:true
        },
        {
          type: InputTypes.Text,
          label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.MODEL.label,
          name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.MODEL.name,
          placeholder: Strings.EMPTY,
          value: Strings.EMPTY,
          readonly:true
        },
        {
          type: InputTypes.Text,
          label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.FISCAL.label,
          name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.FISCAL.name,
          placeholder: Strings.EMPTY,
          value: Strings.EMPTY,
          readonly:true
        },
        {
          type: InputTypes.Text,
          label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.BRAND.label,
          name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.BRAND.name,
          placeholder: Strings.EMPTY,
          value: Strings.EMPTY,
          regex:FormRegex.OnlyLettersAndSpaces,
          validations: [LocalTaxesDynamicValidatorService._basicValidation,
            LocalTaxesDynamicValidatorService.maxLengthValidation(
                LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.BRAND.maxlength)],
          maxlength:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.BRAND.maxlength
        },
        {
          type: LocalTaxesConstants.INPUT_TYPES.Money,
          label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.INVOICE.label,
          name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.INVOICE.name,
          placeholder: Strings.EMPTY,
          value:`${Char.CurrencySymbol}${Numbers.Zero.toString()}`,
          regex:Regex.ValidateAmount,
          validations: [LocalTaxesDynamicValidatorService._basicValidation,
            LocalTaxesDynamicValidatorService.validAmounts(
              LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.INVOICE.min
            ),
            LocalTaxesDynamicValidatorService.maxLengthValidation(
                LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.INVOICE.maxlength)],
          maxlength:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.INVOICE.maxlength,
          min:Numbers.One
        },
        {
          type: InputTypes.Text,
          label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.SUBBRAND.label,
          name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.SUBBRAND.name,
          placeholder: Strings.EMPTY,
          value: Strings.EMPTY,
          regex:FormRegex.AlphaNumeric,
          validations: [LocalTaxesDynamicValidatorService._basicValidation,LocalTaxesDynamicValidatorService.maxLengthValidation(
            LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.SUBBRAND.maxlength)],
          maxlength:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.SUBBRAND.maxlength
        },
        {
          type: InputTypes.Number,
          label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.CYLINDERS.label,
          name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.CYLINDERS.name,
          placeholder: Strings.EMPTY,
          value: Strings.EMPTY,
          regex:Regex.DifferentFromNumbers,
          validations: [LocalTaxesDynamicValidatorService._basicValidation,
            LocalTaxesDynamicValidatorService.maxLengthValidation(
                LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.CYLINDERS.maxlength)],
          maxlength:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TENURE.INPUTS.CYLINDERS.maxlength
        }
    ];
    static readonly _guardianship: InputConfig[] = [
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.GUARDIANSHIP.INPUTS.INVOICE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.GUARDIANSHIP.INPUTS.INVOICE.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        }
    ];
    static readonly _license: InputConfig[] = [
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSE.INPUTS.TYPE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSE.INPUTS.TYPE.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSE.INPUTS.PERIOD.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSE.INPUTS.PERIOD.label,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSE.INPUTS.REFERENCE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSE.INPUTS.REFERENCE.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
    ];
    static readonly _verification: InputConfig[] = [
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VERIFICATION_FINES.INPUTS.BRAND.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VERIFICATION_FINES.INPUTS.BRAND.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VERIFICATION_FINES.INPUTS.PLACA.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VERIFICATION_FINES.INPUTS.PLACA.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VERIFICATION_FINES.INPUTS.MODEL.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VERIFICATION_FINES.INPUTS.MODEL.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        }
    ];
    static readonly _current: InputConfig[] = [
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.RFC.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.RFC.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.MONTH.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.MONTH.label,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.PERIOD.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.PERIOD.label,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Select,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.DECLARATION_TYPE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.DECLARATION_TYPE.name,
            options:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.DECLARATION_TYPE.options,
            placeholder: 'Selecciona',
            value: Strings.EMPTY,
            validations:[LocalTaxesDynamicValidatorService._basicValidation ]
        },
        {
            type: InputTypes.Number,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.NUMBER_WORKERS.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.NUMBER_WORKERS.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            regex:Regex.DifferentFromNumbers,
            validations: [LocalTaxesDynamicValidatorService._basicValidation,
            LocalTaxesDynamicValidatorService.maxLengthValidation(
                LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.NUMBER_WORKERS.maxlength)],
            maxlength:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.NUMBER_WORKERS.maxlength
        },
        {
            type: InputTypes.Number,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.REMUNERATION_PAIDS.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.REMUNERATION_PAIDS.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            regex:Regex.DifferentFromNumbers,
            validations: [LocalTaxesDynamicValidatorService._basicValidation,
            LocalTaxesDynamicValidatorService.maxLengthValidation(
                LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.REMUNERATION_PAIDS.maxlength)],
            maxlength:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.CURRENT_PAYROLLS.INPUTS.REMUNERATION_PAIDS.maxlength

        }
    ];
    static readonly _duty: InputConfig[] = [
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.DUTY.INPUTS.INVOICE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.DUTY.INPUTS.INVOICE.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        }
    ];
    static readonly _notaries: InputConfig[] = [
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.ARCHIVE_NOTARIES.INPUTS.INVOICE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.ARCHIVE_NOTARIES.INPUTS.INVOICE.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.ARCHIVE_NOTARIES.INPUTS.FORMALITIES.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.ARCHIVE_NOTARIES.INPUTS.FORMALITIES.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.ARCHIVE_NOTARIES.INPUTS.NUMBER_DEED.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.ARCHIVE_NOTARIES.INPUTS.NUMBER_DEED.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            regex:Regex.DifferentFromNumbers,
            validations: [LocalTaxesDynamicValidatorService._basicValidation,
            LocalTaxesDynamicValidatorService.minLengthValidation(
                LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.ARCHIVE_NOTARIES.INPUTS.NUMBER_DEED.minlength)],
            maxlength:LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.ARCHIVE_NOTARIES.INPUTS.NUMBER_DEED.maxLength
        }
    ];
    static readonly _rectification: InputConfig[] = [
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.RECTIFICATION_MINUTES.INPUTS.INVOICE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.RECTIFICATION_MINUTES.INPUTS.INVOICE.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
          }
    ];
    static readonly _copy: InputConfig[] = [
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.COPIES_DOCUMENT.INPUTS.INVOICE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.COPIES_DOCUMENT.INPUTS.INVOICE.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.COPIES_DOCUMENT.INPUTS.QUANTITY.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.COPIES_DOCUMENT.INPUTS.QUANTITY.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.COPIES_DOCUMENT.INPUTS.UNIT_PRICE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.COPIES_DOCUMENT.INPUTS.UNIT_PRICE.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        }
    ];
    static readonly _divorce: InputConfig[] = [
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.DIVORCE.INPUTS.INVOICE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.DIVORCE.INPUTS.INVOICE.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        }
    ];
    static readonly _motorcycle: InputConfig[] = [
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.MOTORCYCLE.INPUTS.BRAND.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.MOTORCYCLE.INPUTS.BRAND.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.MOTORCYCLE.INPUTS.REFERENCE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.MOTORCYCLE.INPUTS.REFERENCE.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.MOTORCYCLE.INPUTS.MODEL_INPUT.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.MOTORCYCLE.INPUTS.MODEL_INPUT.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        }
    ];
    static readonly _licenses: InputConfig[] = [
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSES.INPUTS.TYPE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSES.INPUTS.TYPE.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSES.INPUTS.PERIOD.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSES.INPUTS.PERIOD.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSES.INPUTS.REFERENCE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.LICENSES.INPUTS.REFERENCE.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        }
    ];
    static readonly _violations: InputConfig[] = [
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VIOLATIONS.INPUTS.INVOICE_INFRINGMENT.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.VIOLATIONS.INPUTS.INVOICE_INFRINGMENT.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        }
    ];
    static readonly _immobilizer: InputConfig[] = [
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.IMMOBILIZER.INPUTS.INVOICE_INFRINGMENT.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.IMMOBILIZER.INPUTS.INVOICE_INFRINGMENT.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        }
    ];
    static readonly _hologram: InputConfig[] = [
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.HOLOGRAMS.INPUTS.INVOICE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.HOLOGRAMS.INPUTS.INVOICE.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.HOLOGRAMS.INPUTS.VEHICLE_CENTER.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.HOLOGRAMS.INPUTS.VEHICLE_CENTER.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.HOLOGRAMS.INPUTS.HOLOGRAM_TYPE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.HOLOGRAMS.INPUTS.HOLOGRAM_TYPE.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.HOLOGRAMS.INPUTS.QUANTITY.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.HOLOGRAMS.INPUTS.QUANTITY.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        }
    ];
    static readonly _treasury: InputConfig[] = [
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TREASURY.INPUTS.TAX_ADMINISTRATION.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TREASURY.INPUTS.TAX_ADMINISTRATION.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TREASURY.INPUTS.REFERENCE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TREASURY.INPUTS.REFERENCE.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TREASURY.INPUTS.INVOICE.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TREASURY.INPUTS.INVOICE.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        },
        {
            type: InputTypes.Text,
            label: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TREASURY.INPUTS.CONCEPT.label,
            name: LocalTaxesConstants.LOCAL_TAXES_FORMS.DYNAMIC_FORM.TREASURY.INPUTS.CONCEPT.name,
            placeholder: Strings.EMPTY,
            value: Strings.EMPTY,
            readonly:true
        }
    ];
}
