export class TransactionConstants {
  static readonly WALMART_CREDIT_CARD_STATEMENT = [
    'Inbursa Walmart',
    'Inbursa Sams Club',
    'SAMS CLUB NEGOCIOS',
    'Inbursa Bodega Aurrera',
    'CREDIBODEGA'
  ];
}

export enum FileExtensions {
  Xls = '.xlsx'
}

export enum MimeTypes{
  Excel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}
