import { PathModel } from 'src/app/models/PathModel';

export interface IFederalTaxPaymentVoucherRequest {
  idImpuestoFederal: number;
  idSesion: string;
}

export class FederalTaxPaymentVoucherRequest{
  constructor(public data: IFederalTaxPaymentVoucherRequest){}

  get endpoint(): PathModel{
      return PathModel.FederalTaxPaymentVoucher;
  }
}
