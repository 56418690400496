<div *ngIf="!showWebCard" >
  <div class="d-flex justify-content-center py-4">
    <img [src]="src | cdnImage" [alt]="alt" class="img-card " height="auto" width="auto">
  </div>
  <div id="title-modal" class="header-s text-center">
    {{Strings.WebCard.MODAL_WEB_CARD.TITLE}}
  </div>
  <div id="button-container">
    <app-template-primary-button [properties]="primaryProperties">
      {{Strings.WebCard.MODAL_WEB_CARD.BTN_CONFIRM}}
    </app-template-primary-button>
    <app-template-secondary-button [properties]="secondaryProperties">
      {{Strings.WebCard.MODAL_WEB_CARD.BTN_CANCEL}}
    </app-template-secondary-button>
  </div>
</div>

<ng-container *ngIf="vm$ | async as vm">
  <div *ngIf="showWebCard" >
    <div class="d-flex justify-content-end mt-10 mb-26">
      <img role="button" aria-label="Close" width="auto" height="auto"
      [src]="closeCircle | cdnImage" alt="Close"
      class="button-close-web" (click)="close()">
    </div>
    <div class="mb-26"
    [ngClass]="vm.breakpoints.mobile ? 'header-xs' : ' header-s m-title'">
      {{ infoWeb }}
    </div>
    <div class="container-card-web">
      <div class="container-img">
        <img class="img-size" [src]="imgBankCard | cdnImage" alt="card" width="auto" height="auto">
      </div>
      <div class="cvv-info body-s">
        {{ cvv }}
      </div>
      <div class="date-info detail-s mb-8">
        {{ dueDate }}
      </div>
    </div>
    <hr class="mb-26 hr-color"
    [ngClass]="vm.breakpoints.mobile ? 'mt-0' : ' m-title'">
    <div class="d-flex justify-content-center">
      <button class="btn-web-modal button-m " (click)="close()">
        {{ accept }}
      </button>
    </div>
  </div>
</ng-container>

