import { PathModel } from 'src/app/models/PathModel';

type IModifyDebitAccountAliasRequest = {
    Sesion: string,
    Alias: string,
    CuentaProducto: number
};

export class ModifyDebitAccountAliasRequest {
  constructor(public data: IModifyDebitAccountAliasRequest) { }

  get endpoint(): PathModel {
    return PathModel.ModifyDebitAccountAlias;
  }
}
