import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalService } from 'src/app/shared/modal.service';
import { DetailPreviousPaymentsModalComponent } from 'src/app/services-module/components/detail-previous-payments-modal/detail-previous-payments-modal.component';
import { ServiceService } from 'src/app/services/services.service';
import { Resources } from 'src/core/constants/Resources';
import { Char, ClassHTML, Constants, DataType, FormatDate, Position } from 'src/core/constants/Constants';
import { ITransactionSipare } from 'src/app/interface/ITransactionSipare';
import { Utils } from 'src/core/utils/utils';
import { map,combineLatestWith } from 'rxjs';
import { PaginationService } from 'src/app/services/pagination.service';
import { HttpStatusCode } from '@angular/common/http';
import { Strings } from 'src/core/constants/Strings';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { PATH } from 'src/core/constants/Path';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DetailPreviousReferencedPaymentSatComponent }
  from 'src/app/no-schedulable-services/components/detail-previous-referenced-payment-sat/detail-previous-referenced-payment-sat.component';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { SatReferencedPaymentStrings } from 'src/core/constants/SatReferencedPaymentStrings';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import { FormGroup } from '@angular/forms';
import { InputConfig } from 'src/app/interface/IInputConfig';
import { ReferencedPaymentSATService } from 'src/app/services/referenced-payment-sat.service';
import { formatDate } from '@angular/common';
import { ProductsService } from 'src/app/services/products.service';
import { AccountModel } from 'src/app/interface/AccountModel';
import { IReferencedPaymentInformationSAT } from 'src/app/interface/ConsultSatPaymentsResponse';
import { AlertService } from 'src/app/services/alert.service';
import { Numbers } from 'src/core/constants/Numbers';
import { MaskAccountPipe } from 'src/app/pipes/mask-account.pipe';
import { AccountModifyService } from 'src/app/services/info-accounts-edit.service';
@Component({
  selector: 'app-previous-payments-modal',
  templateUrl: './previous-payments-modal.component.html',
  styleUrls: ['./previous-payments-modal.component.css'],
  providers: [PaginationService]
})
export class PreviousPaymentsModalComponent implements OnInit, OnDestroy, AfterViewInit {
  minDateTwo: NgbDateStruct | null;
  minDate: NgbDateStruct = Constants.MIN_DATE_PORTABILITY;
  closeIcon: string = Resources.CLOSE_CIRCLE_BLUE;
  frame: string = Resources.FRAME;
  dataSipare: Array<ITransactionSipare>;
  errorDetails: { msg: string; code: number; };
  showIcon: boolean = true;
  headTitle: string = Strings.MODAL_EXCEPTIONS_POINTS.Service.Header;
  today: string = Utils.dateFormat( Utils.formatDate(), FormatDate.SimpleDay);
  initialPositionDate: number = Numbers.Zero;
  finalPositionDate: number = Constants.LENGTH_BASIC_DATE;
  isReferencedPayment: boolean = false;
  referencedPaymentSAT: IReferencedPaymentInformationSAT[];
  data: Array<IReferencedPaymentInformationSAT> = [];
  searchValue: string = Strings.EMPTY;
  @ViewChild('Header') private readonly header!: ElementRef;
  @ViewChild('Footer') private readonly footer!: ElementRef;
  elementsPerPage: number = Numbers.Five;
  formAccounts: FormGroup;
  formAccountsConfig: InputConfig[] = this.paymentSatService.accountsFormConfig;
  currentAccountSelected: string = Strings.EMPTY;
  accountList: Array<AccountModel> = null;
  errorList: string = Strings.EMPTY;
  currentYear = new Date().getFullYear();
  showSearch: boolean = false;
  styles: Partial<CSSStyleDeclaration> = {
    display: ClassHTML.WebkitBox,
    webkitBoxOrient: ClassHTML.BoxOrientVertical,
    overflowWrap : ClassHTML.AnyWhere
  };

  constructor(
    private readonly modalService: ModalService,
    private readonly sipareService: ServiceService,
    private readonly paginationService: PaginationService,
    private readonly responsiveService: ResponsiveService,
    private readonly router: Router,
    private readonly dynamicFormService: DynamicFormService,
    private readonly paymentSatService: ReferencedPaymentSATService,
    private readonly productsServices: ProductsService,
    private readonly alertService: AlertService,
    private readonly accountModifyService: AccountModifyService
    ) {
  }

  listReferenced$ = this.paginationService.pagination.pipe(
    map(pagination => {
      return pagination.data;})
  );

  search(data, { currentSearchFilter }) {
    const filteredData = data.filter(dataFilter => {
      return Object.keys(dataFilter).filter(key =>
        ![SatReferencedPaymentStrings.SAT_REFERENCES_PAYMENT.ApplicationDate].includes(key)).some(value => {
        return typeof dataFilter[value] === DataType.String && dataFilter[value].toLowerCase().includes(currentSearchFilter.toLowerCase())
           || typeof dataFilter[value] === DataType.Number && dataFilter[value].toString().includes(currentSearchFilter);
      });
    });
   return [...filteredData];
  }

  receiveDataFromSearch(searchValue) {
    this.searchValue = searchValue;
    this.paymentSatService.filters = { filterSearch: this.searchValue, accountNumber: this.currentAccountSelected };
    this.paginationService.search(this.search, { currentSearchFilter: this.searchValue });
  }

  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(
      this.paginationService.pagination
    ),
    map(([breakpoints, pagination]) => ({
      breakpoints,
      pagination: pagination.data as Array<ITransactionSipare>
    }))
  );

  hiddenSearch(valueFilter: string): void {
    this.searchValue = valueFilter;
  }

  async ngOnInit() {
    if ((this.router.url === PATH.NoSchedulableServices || (this.accountModifyService.account.codeServices &&
      this.accountModifyService.account.codeServices === Constants.SERVICE_CODE.Impfedr))) {
      await this.getAccountList();
    }else{
      this.minDateTwo = this.minDate;
      await this.transactionSipare();
    }
    if(this.paymentSatService.accountSelected !== Strings.EMPTY && this.paymentSatService.description !== Strings.EMPTY
      && this.paymentSatService.idSelected !== Numbers.Zero){
        this.selectOption(this.paymentSatService.accountSelected, this.paymentSatService.idSelected, this.paymentSatService.description);
    }
  }

  async getAccountList(){
    try {
      this.isReferencedPayment = true;
      this.accountList = await this.productsServices.getAccounts();
      this.modalService.modalInstance.update({
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeLg,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalPreviousPaymentSat
      });
      this.formAccounts = this.dynamicFormService.toFormGroup(this.formAccountsConfig);
      if (this.paymentSatService.filters.accountNumber) {
        this.currentAccountSelected = this.paymentSatService.filters.accountNumber;
        this.formAccounts.controls[SatReferencedPaymentStrings.FORM_CONTROLS.AccountNumberName].setValue(this.currentAccountSelected);
      }
    } catch (error) {
      this.showPopupAlert(Strings.MODAL_EXCEPTIONS_POINTS.Service.Message);
    }
  }

  ngAfterViewInit(): void {
    this.resizeTablePage();
    if (this.paymentSatService.filters.filterSearch) {
      this.searchValue = this.paymentSatService.filters.filterSearch;
      this.receiveDataFromSearch(this.searchValue);
    }
  }

  close() {
    if(this.isReferencedPayment){
      this.paymentSatService.cleanFilters();
    }
    this.modalService.close();
    this.paymentSatService.clearSelected();
  }

  async transactionSipare(){
    try {
      const payments =  await this.sipareService.getSiparePayments(Numbers.Zero);
      this.paginationService.setRemoteData(payments, (page) => this.sipareService.getSiparePayments(page));
    } catch (error) {
       this.errorDetails = Utils.getErrorMsg(error);
       this.showIcon = this.errorDetails.code !== HttpStatusCode.NoContent;
    }
  }

  detailPay(dataSipare: ITransactionSipare) {
    this.modalService.close();
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeSm,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.OtherServices,
    };
    dataSipare.fechaPago = formatDate(new Date(dataSipare.fechaPago), FormatDate.DayMonthYearComplete, Constants.LOCALE_ES_MX);
    this.modalService.open(DetailPreviousPaymentsModalComponent, modalOptions,dataSipare);
  }

  detailPreviousSat(data: IReferencedPaymentInformationSAT){
    this.modalService.close();
    this.paymentSatService.setArray = data;
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalDetailPreviousPaymentSat
    };
    this.modalService.open(DetailPreviousReferencedPaymentSatComponent, modalOptions);
  }

  ngOnDestroy() {
    this.modalService.close();
    this.paginationService.clear();
  }

  @HostListener('window:resize', ['$event'])
  private resizeTablePage() {
    const cardHeight = SatReferencedPaymentStrings.SAT_REFERENCES_PAYMENT.DetailSize;
    const windowHeight = window.innerHeight;
    const headerHeight = this.header.nativeElement.offsetHeight;
    const footerHeight = this.footer.nativeElement.offsetHeight;
    const availableHeight = windowHeight - headerHeight - footerHeight;
    let elementsPerPage = (Math.floor(availableHeight / cardHeight));
    elementsPerPage = elementsPerPage > this.elementsPerPage ? elementsPerPage : Numbers.Five;
    this.paginationService.setPageSize(elementsPerPage);
    if(this.searchValue){
      this.receiveDataFromSearch(this.searchValue);
    }
  }

  async selectOption(account: string, idAccount: number, description: string) {
    try {
      this.errorList = Strings.EMPTY;
      this.referencedPaymentSAT = await this.paymentSatService.getSatPayments(account, idAccount);
      this.showSearch = true;
      this.dataCollection();
      this.paginationService.setInitialData(this.referencedPaymentSAT, this.elementsPerPage);
      this.currentAccountSelected = `${description} ${new MaskAccountPipe().transform(account, Numbers.Four, false)}`;
      this.paymentSatService.filters = { filterSearch: this.searchValue, accountNumber: this.currentAccountSelected };
      this.formAccounts.controls[SatReferencedPaymentStrings.FORM_CONTROLS.AccountNumberName].setValue(description);
      this.paymentSatService.accountSelected = account;
      this.paymentSatService.idSelected = idAccount;
      this.paymentSatService.description = description;
    } catch (error) {
      this.currentAccountSelected = `${description} ${new MaskAccountPipe().transform(account, Numbers.Four, false)}`;
      this.errorDetailsModalMsg(error);
    }
  }

  errorDetailsModalMsg(error: string ) {
    const errorDetails = Utils.getErrorMsg(error);
    if (errorDetails.code.toString() === SatReferencedPaymentStrings.ERROR_CODE) {
      this.errorList = errorDetails.msg;
      this.showIcon = false;
    } else {
      this.showPopupAlert(Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code));
    }
  }

  showPopupAlert(msg: string){
    this.modalService.close();
    this.paymentSatService.clearSelected();
    this.paymentSatService.cleanFilters();
    this.alertService.showPopupAlert({
      message: msg,
      header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
      imgHead: Resources.CLOSE_ICON_RED,
      btnLabel: Strings.MSG_POPUP_ACCEPT,
      btnExit: false
    });
  }

  public async getLabelOption (optionValue: string) {
    return Utils.extractLabel(this.formAccountsConfig[Numbers.Zero].options , optionValue);
  }

  changeSelect(inputName: string){
    this.formAccounts.get(inputName).markAsTouched();
  }

  dataCollection(){
    this.referencedPaymentSAT.sort((a , b) => {
      if ((a.FechaAplicacion+a.HoraAplicacion) < (b.FechaAplicacion+b.HoraAplicacion)) {
          return Numbers.One;
      }
      if ((a.FechaAplicacion+a.HoraAplicacion) > (b.FechaAplicacion+b.HoraAplicacion)) {
          return Constants.NOT_FOUND_ITEM;
      }
      return Numbers.Zero;
  });

  const currentYear = new Date().getFullYear();
  let yearTemp = Numbers.Zero;
  this.referencedPaymentSAT.forEach(item => {
    if(item.FechaAplicacion) {
      const movementYear =  Number(item.FechaAplicacion.split(Char.MiddleDash)[Position.Zero]);
      if(movementYear !== currentYear && yearTemp !== movementYear) {
        yearTemp = movementYear;
        item.showYear = true;
      }
    }
  });
  }

}
