import { Component, Output, EventEmitter } from '@angular/core';
import { SessionManagerService } from 'src/app/services/session-manager.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/shared/modal.service';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { ProductsService } from 'src/app/services/products.service';
import { Tab } from 'src/core/constants/Constants';

@Component({
  selector: 'app-modal-logout',
  templateUrl: './modal-logout.component.html',
  styleUrls: ['./modal-logout.component.css'],
})
export class ModalLogoutComponent {
  @Output() showConfirmData = new EventEmitter();
  modalInstance: NgbModalRef;
  btnLabelCancel = Strings.BUTTONS.Cancel;
  btnLabelContinue = Strings.CONTINUE_BUTTON;
  btnLabelCloseSession = Strings.BUTTONS.CloseSession;
  btnLabelGoOut = Strings.BUTTONS.CloseSession;
  closeCircleBlue: string = Resources.CLOSE_CIRCLE_BLUE;
  exclamationMarkBlue: string = Resources.EXCLAMATION_MARK_BLUE;
  inactivity: boolean = true;

  constructor(
    private readonly modalService: ModalService,
    private readonly sessionManager: SessionManagerService,
    private readonly productsService: ProductsService
  ) { }

  close() {
    this.modalInstance?.close();
    if(this.productsService.isCatchOnTabs) {
      this.productsService.currentTab = Tab.Account;
    }
  }

  actionSessionUser(action: boolean) {
    if(action){
      this.modalService.close(action);
    }else{
      this.modalInstance?.close();
      this.restart();
    }
  }

  redirect() {
    this.sessionManager.closeSession({ redirect: true });
  }

  restart() {
    this.close();
    this.sessionManager.restartInactivityTracking();
  }
}
