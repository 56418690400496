import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from '../../shared/modal.service';
import { Constants } from 'src/core/constants/Constants';
@Component({
  selector: 'app-modal-credit-bureau-inquiry',
  templateUrl: './modal-credit-bureau-inquiry.component.html',
  styleUrls: ['./modal-credit-bureau-inquiry.component.css']
})
export class ModalCreditBureauInquiryComponent implements OnInit {
  @Output() sendModalAnswer = new EventEmitter();
  @Input() conditionsType: string;
  textConditions: boolean = false;
  textConsult: boolean = false;
  readonly urlIframe = Constants.URL_CREDIT_HELP;
  constructor(private readonly modalService: ModalService) { }

  ngOnInit(): void {
    if (this.conditionsType === Constants.CONDITIONS) {
      this.textConditions = true;
    }
    if (this.conditionsType === Constants.CONSULT) {
      this.textConsult = true;
    }
  }

  onlyClose() {
    this.modalService.close(Constants.CLOSE_MODAL_ACTION);
  }

  acceptConsult(condition: string) {
    this.sendModalAnswer.emit(condition);
    this.modalService.close(condition);
  }

}
