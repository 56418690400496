import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { InputConfig } from 'src/app/interface/IInputConfig';
import { Regex, Char, Numbers, Constants, Language, InputTypes, FormValidations, Keys, Position } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { AforeConstants } from 'src/core/constants/AforeConstants';

export class FormUtils {
  static onlyTextInput($event: Event, name: string, formGroup: FormGroup) {
    let data = $event ? ($event.target as HTMLInputElement).value : formGroup.get(name).value;
    const regex = Regex.OnlyText;
    const regexSpace=Regex.ValidateSpace;
    data = data.trimStart();
    data = data.replace(regex, Strings.EMPTY);
    data = data.replace(regexSpace, Char.WhiteSpace);
    formGroup.patchValue({
      [name]: data
    });
  }

  static reformatToEditInput($event: Event, name: string, formGroup: FormGroup) {
    const target = $event.target as HTMLInputElement;
    const value = target.value;

    if (target.value.includes(Char.CurrencySymbol)) {
      const start = target.selectionStart - Numbers.One;
      const end = target.selectionEnd - Numbers.One;

      const index = value.lastIndexOf(Char.Comma);

      let count = Number(Numbers.Zero);

      target.value.split(Strings.EMPTY).forEach((el: string) => {
        if (el === Char.Comma) count++;
      });

      const regex = Regex.Reformat;

      const formated = target.value.replace(regex, Strings.EMPTY);

      formGroup?.patchValue({
        [name]: formated
      });

      const spacesToRemove = count === Numbers.One ? Numbers.One : count;

      if (index === Constants.NOT_FOUND_ITEM) {
        target.setSelectionRange(start, end);
      } else {
        if (start > index) {
          target.setSelectionRange(start - spacesToRemove, end - spacesToRemove);
        } else {
          target.setSelectionRange(start, end);
        }
      }
    }
  }

  static currencyInput($event: Event, name: string, formGroup: FormGroup) {
    let data = ($event.target as HTMLInputElement).value;

    const regex = Regex.DifferentFromDecimals;
    data = data.replace(regex, Strings.EMPTY);

    let ocurrences = Numbers.Zero;

    data.split(Strings.EMPTY).forEach(el => {
      if (el === Char.Dot) ocurrences++;
    });

    if (ocurrences !== Numbers.Zero) {
      let dotIndex = Numbers.Zero;
      const splited = data.split(Strings.EMPTY);

      for (let index = Numbers.Zero; index < splited.length; index++) {
        if (splited[index] === Char.Dot) {
          dotIndex = index;
          break;
        }
      }

      const firtsBeforeDots = data.substring(Numbers.Zero, dotIndex + Numbers.One);
      const afterDots = data.substring(dotIndex + Numbers.One).replace(Char.Dot, Strings.EMPTY);

      data = `${firtsBeforeDots}${afterDots}`;
    }

    formGroup.patchValue({
      [name]: data
    });
  }

  static currencyInputWhitDecimals($event: Event, name: string, formGroup: FormGroup) {
    let data = ($event.target as HTMLInputElement).value;

    const regex = Regex.DifferentFromNumbersDecimals;
    data = data.replace(regex, Strings.EMPTY);

    let ocurrences = Numbers.Zero;

    data.split(Strings.EMPTY).forEach(el => {
      if (el === Char.Dot) ocurrences++;
    });

    if (ocurrences !== Numbers.Zero) {
      let dotIndex = Numbers.Zero;
      const splited = data.split(Strings.EMPTY);

      for (let index = Numbers.Zero; index < splited.length; index++) {
        if (splited[index] === Char.Dot) {
          dotIndex = index;
          break;
        }
      }

      const firtsBeforeDots = data.substring(Numbers.Zero, dotIndex + Numbers.One);
      let afterDots = data.substring(dotIndex + Numbers.One).replace(Char.Dot, Strings.EMPTY);

      afterDots = afterDots.substring(Numbers.Zero, Numbers.Two);

      data = `${firtsBeforeDots}${afterDots}`;
    }

    formGroup.patchValue({
      [name]: data
    });
  }

  static parseAmount(amount: number): string {
    return parseFloat(`${amount}`).toLocaleString(Language.EnglishUS, {
      maximumFractionDigits: Constants.TOTAL_DECIMALS, minimumFractionDigits: Constants.TOTAL_DECIMALS
    }).replace(Regex.Slash, Char.Dot).replace(Regex.MatchComma, Char.Comma);
  }

  static onlyNumbersInput($event: Event, name: string, formGroup: FormGroup) {
    let data = $event ? ($event.target as HTMLInputElement).value : formGroup.get(name).value;
    const regex = Regex.DifferentFromDigits;
    data = data.replace(regex, Strings.EMPTY);
    const regexExtra = Regex.OnlyLetters;
    data = data.replace(regexExtra, Strings.EMPTY);

    formGroup.patchValue({
      [name]: data
    });
  }

  static alphaNumericInput($event: Event, inputName: string, formGroup: FormGroup, regexp?: RegExp) {
    let data = $event ? ($event.target as HTMLInputElement).value : formGroup.get(inputName).value;
    let regex = regexp;
    if (regexp === undefined) {
      regex = Regex.DifferentFromAlphanumeric;
    }
    data = data.trimStart();
    data = data.replace(regex, (match: string) =>{
      if(match === Char.WhiteSpace){
        return match;
      } else {
        return Char.WhiteSpace;
      }
    });
    formGroup.patchValue({
      [inputName]: data
    });
  }

  static alphaNumericOneSpaceInput($event: Event, name: string, formGroup: FormGroup){
    let data = $event ? ($event.target as HTMLInputElement).value : formGroup.get(name).value;
    const regex = AforeConstants.NUMBERS_LETTERS_ONE_SPACE;
    data = data.replace(regex, Strings.EMPTY);
    data = data.trimStart();
    formGroup.patchValue({
      [name]: data
    });
  }

  static getServiceNameConfig(value: string, validationMessage?: string, skipValidations = false): InputConfig {
    const config: InputConfig = {
      type: InputTypes.Text,
      label: Strings.ADD_SERVICE.ServiceText,
      name: Strings.ADD_SERVICE.ServiceName,
      value,
      placeholder: Strings.ADD_SERVICE.ServiceText,
      validations: [],
      readonly: true
    };

    if (!skipValidations) {
      const validation: { name: string, validator: ValidatorFn, message?: string } = {
        name: FormValidations.Required,
        validator: Validators.required
      };

      if (validationMessage) {
        validation.message = validationMessage;
      }

      config.validations.push(validation);
    }

    return config;
  }
  static onKeyPress(event: KeyboardEvent) {
    const charCode = event.key;
    if(charCode === Char.WhiteSpace) {
      event.preventDefault();
      return false;
    }
    if (!isNaN(Number(charCode)) || event.ctrlKey || event.metaKey || event.key === Keys.Backspace || event.key === Keys.Delete) {
        return true;
    } else {
        event.preventDefault();
        return false;
    }
  }
/**
 * La función `matchInputs` devuelve una función de validador que verifica si dos valores de entrada coinciden entre sí.
 * @param {string} matchTo - El parámetro `matchTo` en la función `matchInputs` es una cadena que representa el nombre del 
 * control del formulario con el que el valor del control actual debe coincidir.
 * @returns Se devuelve una función de tipo ValidatorFn. Esta función compara el valor del control actual con 
 * el valor de otro control especificado por el parámetro `matchTo`. Si los valores no coinciden, devuelve un objeto 
 * con la clave `isMatchInputs` establecida en true, indicando un error de validación. De lo contrario, devuelve null.
 */
  static matchInputs(matchTo: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const inputMatchTo = control.parent?.get(matchTo);
      if (!control.value || !inputMatchTo?.value) {
        return null;
      }
      return control.value.toLocaleLowerCase() !== inputMatchTo.value.toLocaleLowerCase()
        ? { isMatchInputs: true }
        : null;
    };
  }
/**
 * La función `getErrorMessage` recupera mensajes de error basados en el nombre del control, tipo de dato, grupo de formularios y 
 * los errores del objeto proporcionado.
 * @param {string} controlName - El parámetro `controlName` es una cadena que representa el nombre del control del formulario 
 * para el que deseas recuperar el mensaje de error.
 * @param {string} dataType - El parámetro `dataType` en la función `getErrorMessage` es una cadena que representa 
 * el tipo de dato que se está validando. Se utiliza para recuperar el mensaje de error específico para un tipo de dato particular 
 * del objeto `objectErrors`.
 * @param {FormGroup} formGroup - El parámetro `formGroup` en la función `getErrorMessage` es de tipo `FormGroup` y 
 * representa un grupo de controles de formulario en Angular. Se utiliza para acceder al control del formulario especificado 
 * por el parámetro `controlName` dentro de la función.
 * @param {object} objectErrors - El parámetro `objectErrors` es un objeto que contiene mensajes de error para diferentes tipos de datos. 
 * Probablemente tiene una estructura donde las claves son tipos de datos segun el input y 
 * los valores son objetos que contienen mensajes de error para cada clave de error relacionada con ese tipo de dato.
 * @returns un mensaje de error basado en el nombre del control, tipo de dato, grupo de formulario y 
 * los errores del objeto proporcionado como parámetros. Si el control tiene errores, recupera la clave de error y 
 * devuelve el mensaje de error correspondiente del objeto `objectErrors` basado en el tipo de dato y la clave de error. 
 * Si no hay errores, devuelve una cadena vacía.
 */
  static getErrorMessage(controlName: string, dataType: string, formGroup:FormGroup, objectErrors:object): string {
    const control = formGroup.get(controlName);
    if (control && control.errors) {
      const errorKey = Object.keys(control.errors)[Position.Zero];
      return objectErrors[dataType][errorKey];
    }
    return Strings.EMPTY;
  }
}
