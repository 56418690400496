import { Component, Input, OnInit } from '@angular/core';
import { ResponsiveService } from '../../responsive.service';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { Constants } from 'src/core/constants/Constants';

@Component({
  selector: 'app-download-app-component',
  templateUrl: './download-app-component.component.html',
  styleUrls: ['./download-app-component.component.css'],
})
export class DownloadAppComponentComponent implements OnInit {
  breakpoints$ = this.responsiveService.observe;
  isDeviceMT = false;
  widthscreen: number = Constants.RESOLUTIONS.IpadMinWidth;
  @Input() page?:string=Strings.EMPTY;

  inbursa: string = Resources.MOBILE_INBURSA;
  qr: string = Resources.ONE_LINK_TO_INBURSA;
  googlePlay: string = Resources.GOOGLE_PLAY;
  appStore: string = Resources.APP_STORE;
  appGallery: string = Resources.APP_GALERY;

  constructor(private readonly responsiveService: ResponsiveService) { }

  ngOnInit(): void {
    this.isTabletMobile();
  }

  isTabletMobile() {
    const agent = window.navigator.userAgent.toLowerCase();
    if (
      (window.screen.width <= this.widthscreen && agent.match(Constants.USER_AGENT.MacOption))
      || (agent.match(Constants.USER_AGENT.Mobile))) {
      this.isDeviceMT = true;
    } else {
      this.isDeviceMT = false;
    }

  }
}
