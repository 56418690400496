import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { HelpStrings } from 'src/core/constants/HelpStrings';
import { Resources } from 'src/core/constants/Resources';

@Component({
  selector: 'app-modal-help-token',
  templateUrl: './modal-help-token.component.html',
  styleUrls: ['./modal-help-token.component.css']
})
export class ModalHelpTokenComponent {
  @Input() headTit1 = HelpStrings.INSTRUCTIONS.OpenAppInstruction;
  @Input() headTit2 = HelpStrings.INSTRUCTIONS.TokenViewInstruction;
  @Input() modalId: NgbModalRef;
  @Output() onCaptureMessage = new EventEmitter<boolean>();

  vm$ = this.responsiveService.observe;

  modalInstance: NgbModalRef;
  modalHelpToken1: string = Resources.MODAL_HELP_TOKEN1;
  modalHelpToken2: string = Resources.MODAL_HELP_TOKEN2;
  removeCrFr: string = Resources.CLOSE_CIRCLE_BLUE;

  constructor(
    private readonly responsiveService: ResponsiveService
  ) {}

  close() {
    this.onCaptureMessage.emit();
    this.modalInstance?.close();
    this.modalId?.close();
  }

  @HostListener('window:popstate')
  onBack() {
    this.close();
  }
}
