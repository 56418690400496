import { PathModel } from 'src/app/models/PathModel';
export interface IGetConsultSipareRequest {
  numeroPagina: number;
  numeroRegistros: number;
  }
  export class GetConsultSipareRequest {
  constructor(public data: IGetConsultSipareRequest) { }

  get endpoint(): PathModel {
  return PathModel.GetSiparePayments;
  }
}
