<ng-container *ngIf="vm$ | async as vm">
  <div  [ngClass]="{'pt-2':isCreditPayment ,'pt-3':!isCreditPayment && !ownCreditCard && paddingCredit}">
      <label for="account"
        class="title-select-account header-xs ps-0"
        [ngClass]="(idService != '' || showPadding) && !ownCreditCard ? 'p-2':'account-mobile'"
      >
        {{headSelAccount}}
      </label>
      <div id="account-selected" [ngClass]="!vm.breakpoints.desktop ? 'my-2':'my-3'">
          <div class="my-2" *ngIf="this.cardAccountService.selected">
              <app-card-account
                [styleCard]="styleCard"
                [card]="getCardSelected"
                [boxShadow]="this.cardAccountService.selected"
                [style]="style"
                [isModal]="isModal"
                [maskClabe]="maskClabe"
              ></app-card-account>
          </div>
      </div>
      <a *ngIf="enableAccountChange" class="link-select-account detail-m-highlight-hypertext" role="button"
        [tabindex]="0" (keypress)="showCardModalComponent(myModal)"
        (click)="showCardModalComponent(myModal)">{{this.cardAccountService.selected ? changeSelAccount :
        bodySelAccount}}</a>
        <span *ngIf="vm.error && !this.cardAccountService.selected" class="label-error-account">Debes seleccionar una
          cuenta</span>
      <ng-template #myModal let-modal>
        <app-card-modal
        (cardSelect)="cardInformation($event)"
        (savedAccount)="savedAccountEmit()"
        [modal]="modal" [portability]="portability"
        [modalData]="accountOperations"
        [getCreditCards]="getCreditCards"
        [onlyCreditCards]="onlyCreditCards"
        [filterCreditCards]="filterCreditCards"
        [filter]="filter"
        [skipSubAccounts]="skipSubAccounts"
        [filterSkip]="filterSkip"
        [filterAmexAccount]="filterAmexAccount"
        [codeAllowOperations]="codeAllowOperations">
        </app-card-modal>
      </ng-template>
  </div>
</ng-container>
