<ng-container *ngIf="vm$ | async as vm">
    <div class="previous-modal  drop-shadow-m" *ngIf="!isReferencedPayment else ReferencedSAT ">
      <div class="d-flex justify-content-end ">
        <img role="button" class="close" aria-label="Close" (click)="close()" height="24" width="24"
          [src]="closeIcon | cdnImage" alt="Close" />
      </div>
      <div class="search">
        <div id="title" class="col-4">
          <p class="header-s">Detalles de Pago</p>
        </div>
        <div class="col-8 filt">
          <div class="col p-0">
            <app-search-component></app-search-component>
          </div>
          <div class="col p-0 mt-2">
            <label for="filter-by-date" [hidden]="true"></label>
            <select class="form-select select filter-component input-group" #titulo id="filter-by-date" role="button">
              <option value="0" class="center-filter"> Filtrar </option>
              <option value="1" selected class="center-filter"> SIPARE </option>
            </select>
          </div>
        </div>
      </div>
      <div *ngIf="!errorDetails else errorComponent">
        <div class="list-previous-payment">
          <div *ngFor="let transaction of vm.pagination">
            <div class="pay">
              <p class="detail-m text-primary-1000">
                {{transaction.fechaPago?.substring(initialPositionDate,finalPositionDate)
                == today ? 'Hoy': transaction.fechaPago | customDate}}</p>
              <div class="detail">
                <div>
                  <img [src]="frame | cdnImage" alt=">" height="24" width="24" (click)="detailPay(transaction)">
                </div>
                <div class="w-100">
                  <div class="detail-m-highlight mb-1">
                    Pago Sipare
                  </div>
                  <div class="detail-m" *ngIf="!vm.breakpoints.mobile">
                    Línea de Captura:
                    <text-ellipsis [text]="transaction.lineaCaptura" [styles]="styles"></text-ellipsis>
                  </div>
                  <div class="detail-m" *ngIf="vm.breakpoints.mobile">
                    Línea de Captura:
                    <text-ellipsis [text]="transaction.lineaCaptura" [styles]="styles"></text-ellipsis>
                  </div>
                </div>
                <div>
                  <text-ellipsis [text]="transaction.total | currency" classes="text-amount"
                    [styles]="styles"></text-ellipsis>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-pagination></app-pagination>
      </div>
    </div>
    <ng-template #ReferencedSAT>
      <div *ngIf="accountList" class="previous-modal-sat">
        <div #Header>
          <div class="flex-column">
            <div class="d-flex justify-content-end" *ngIf="errorList">
              <img role="button" class="close" aria-label="Close" (click)="close()" height="24" width="24"
                [src]="closeIcon | cdnImage" alt="Close" />
            </div>
            <div id="title">
              <span class="header-s"> Recibos </span>
            </div>
            <form [formGroup]="formAccounts" class="margin-form" *ngIf="accountList">
              <div *ngFor="let input of formAccountsConfig" class="my-3 mx-0">
                <app-input-template [input]="input" [form]="formAccounts" [id]="input.name">
                  <div ngbDropdown>
                    <div id="DropdownMenu" ngbDropdownToggle class="custom-select">
                      <div class="form-select cursor-pointer form-control inputs body-s d-flex align-center"
                        [ngClass]="formAccounts.get(input.name).errors
                          && formAccounts.get(input.name).touched && !currentAccountSelected ? 'border-red':''"
                        [tabIndex]="0"
                        (change)="changeSelect(input.name)">
                        <span class="p-0 m-0 cut-text option-account-selected"
                        [ngbTooltip]="currentAccountSelected">
                          <div class="text-overflow-ellipsis">
                            {{ currentAccountSelected || 'Selecciona' }}
                          </div>
                        </span>
                      </div>
                    </div>
                    <div ngbDropdownMenu class="dropdown-container">
                      <ng-container *ngFor="let option of accountList">
                        <div class="cursor-pointer dropdown-item m-0 option-account" ngbDropdownItem
                          (click)="selectOption(option.numeroCuenta,option.idCuentaProducto,
                           option.descripcionCuenta)">
                          {{option.descripcionCuenta}} {{(option.numeroCuenta | maskAccount:4)}} </div>
                      </ng-container>
                    </div>
                  </div>
                </app-input-template>
              </div>
            </form>
          </div>
        </div>
        <div class="w-100" #Content id="main-content">
          <ng-container *ngIf="currentAccountSelected">
            <app-search-component *ngIf="!errorList && showSearch" (sendData)="receiveDataFromSearch($event)"
                [valueFilter]="searchValue" [realValue]="true"
                (dataFromSearch)="receiveDataFromSearch($event)" class="serch"></app-search-component>
            <div class="list-referenced-payment w-100">
              <ng-container *ngIf="listReferenced$ | async as listReferenced">
                <div *ngIf="!errorList" >
                  <div *ngFor="let referenced of listReferenced" class="pay mb-3">
                    <p *ngIf="referenced.FechaAplicacion && referenced.showYear"
                    class="button-m text-primary-1000 mb-1">
                      {{referenced.FechaAplicacion.slice(0,4)}}
                    </p>
                    <p class="detail-m referenced-date">
                      {{referenced.FechaAplicacion?.substring(initialPositionDate,finalPositionDate)
                        == today ? 'Hoy': referenced.FechaAplicacion | customDate}}</p>
                    <div class="detail">
                      <div *ngIf="!vm.breakpoints.mobile">
                        <img [src]="frame | cdnImage" alt=">" height="24" width="24"
                          (click)="detailPreviousSat(referenced)" class="cursor-pointer">
                      </div>
                      <div class="w-100">
                        <div class="detail-m-highlight" *ngIf="!vm.breakpoints.mobile">
                          Línea de Captura: <br> {{referenced.LineaCaptura}}
                        </div>
                        <div class="detail-m-highlight text-underline color-link cursor-pointer"
                          (click)="detailPreviousSat(referenced)" *ngIf="vm.breakpoints.mobile">
                          Línea de Captura: <br> {{referenced.LineaCaptura }}
                        </div>
                        <div [ngClass]="vm.breakpoints.mobile ? 'detail-m-highlight' : 'detail-m'">
                          Folio:{{referenced.IdTransaccion}}
                        </div>
                      </div>
                      <div>
                        <text-ellipsis [text]="referenced.Monto | currency"  classes="text-amount"
                          [styles]="styles"></text-ellipsis>
                      </div>
                    </div>
                  </div>
                </div>
                </ng-container>
              <div *ngIf="errorList">
                <app-error-exception class="center-modal-error" [closeModal]="false"
                [titleError]="errorList" [showIcon]="showIcon">
                </app-error-exception>
              </div>
            </div>
          </ng-container>
        </div>
        <div #Footer>
          <ng-container *ngIf="!errorList &&  currentAccountSelected">
            <app-pagination [startElement]="Content"></app-pagination>
          </ng-container>
          <div class="justify-content-center d-flex" *ngIf="!errorList">
            <button class="colored-btn btn-card-modal button-m modals-btn"
            [class.w-100]="vm.breakpoints.mobile" (click)="close()"> Cerrar </button>
          </div>
        </div>
      </div>
    </ng-template>


    <ng-template #errorComponent>
      <app-error-exception class="center-modal-error" [errorMessage]="showIcon? errorDetails.msg: ''"
        [titleError]="!showIcon ? errorDetails.msg: headTitle" [showIcon]="showIcon"></app-error-exception>
    </ng-template>
  </ng-container>
