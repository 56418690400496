import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISimpleValue } from '../interface/ISimpleValue';
import { AccountCardService } from './account-card.service';
import { Length } from 'src/core/constants/Constants';

@Injectable({
  providedIn: 'root'
})
export class SelectAccountErrorService {

  private readonly accountError: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({value: false});

  constructor(private readonly accountCardService: AccountCardService) { }

  get accountErrorObservable() {
    return this.accountError.asObservable();
  }

  get accountErrorData(){
    return this.accountError.getValue();
  }

  set accountErrorData(data: ISimpleValue) {
    this.accountError.next(data);
  }

  validate() {
    const accountCard = this.accountCardService.accountCard;
    const isError = accountCard.clabe.length <= Length.Empty;

    this.accountErrorData = { value: isError };

    return isError;
  }
}
