import { IServicePayId } from './../interface/IServicePayId';
import { ISimpleValue } from 'src/app/interface/ISimpleValue';
import { SessionStorage } from './../models/storage/SessionStorage';
import { Utils } from '../../core/utils/utils';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenStorage } from '../models/storage/TokenStorage';
import { UserStorage } from '../models/storage/UserStorage';
import { GeolocationStorage } from '../models/storage/GeolocationStorage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private readonly defaultSecret = environment.storageKey;

  constructor() { }

  saveSession(session: string, jwt: string){
    this.save(StorageKeys.SESSION, {session, jwt}, true, this.defaultSecret);
  }

  getSession(jwtKey = false): string {
    const { session, jwt } = this.get(StorageKeys.SESSION, true, this.defaultSecret) ||
      { session: null, jwt: null };
    return jwtKey ? jwt : session;
  }
  
  saveUser(user: UserStorage){
    this.save(StorageKeys.USER, user);
  }

  getUser(): UserStorage{
    return this.get(StorageKeys.USER);
  }

  saveToken(value: TokenStorage){
    this.save(StorageKeys.TOKEN, value);
  }

  getToken(): TokenStorage{
    const value = this.get(StorageKeys.TOKEN);
    this.remove(StorageKeys.TOKEN);
    return value;
  }

  saveSessionManager(value: SessionStorage): void{
    this.save(StorageKeys.SESSION_MANAGER, value);
  }

  getSessionManager(): SessionStorage{
    return this.get(StorageKeys.SESSION_MANAGER);
  }

  removeSessionManager(): void{
    this.remove(StorageKeys.SESSION_MANAGER);
  }

  removeAll(): void{
    sessionStorage.clear();
  }

  saveIdService(service: IServicePayId){
    this.save(StorageKeys.ID_SERVICE, service);
  }

  getIdService(): IServicePayId{
    return this.get(StorageKeys.ID_SERVICE);
  }

  saveLoginFacepass(value: ISimpleValue){
    this.save(StorageKeys.LOGIN_FACEPASS, value);
  }

  getLoginFacepass(): ISimpleValue {
    return this.get(StorageKeys.LOGIN_FACEPASS);
  }

  saveGeolocation(geolocation: GeolocationStorage){
    this.save(StorageKeys.GEOLOCATION, geolocation, true, this.defaultSecret);
  }

  getGeolocation(): GeolocationStorage{
    return this.get(StorageKeys.GEOLOCATION, true, this.defaultSecret);
  }

  savePortabilityOption(selected: boolean){
    this.save(StorageKeys.PORTABILITY, selected);
  }

  getPortabilityOption(): boolean{
    return this.get(StorageKeys.PORTABILITY);
  }

  removePortabilityOption(): void{
    this.remove(StorageKeys.PORTABILITY);
  }

  savePlayAudio(playAudio: boolean){
    this.save(StorageKeys.PLAY_AUDIO, playAudio, true, this.defaultSecret);
  }

  getPlayAudio(): boolean{
    return this.get(StorageKeys.PLAY_AUDIO, true, this.defaultSecret);
  }

  //#region  PRIVATE METHODS

  private save(key: StorageKeys, value: any, encrypted: boolean = true, secret?: string) {
    value = JSON.stringify(value);
    if (encrypted) {
      if (!secret) {
        secret = this.getSession();
      }
      if (!secret) {
        return;
      }
      value = Utils.encryptAES(value, secret);
    }
    sessionStorage.setItem(key.toString(), value);
  }

  private get(key: StorageKeys, encrypted: boolean = true, secret?: string): any{
    let value = sessionStorage.getItem(key.toString());
    if(encrypted && value){
      if(!secret){
        secret = this.getSession();
      }
      if(!secret) {
        return value;
      }
      value = Utils.decryptAES(value, secret);
    }

    if(value){
      value = JSON.parse(value);
    }

    return value;
  }

  private remove(key: StorageKeys){
    sessionStorage.removeItem(key);
  }

  //#endregion

}

enum StorageKeys {
  SESSION = 'SS0',
  USER = 'SS1',
  TOKEN = 'SS2',
  SESSION_MANAGER = 'SS3',
  SESSION_TIME = 'SS4',
  ID_SERVICE = 'SS',
  LOGIN_FACEPASS = 'OSS1',
  GEOLOCATION = 'SS5',
  PORTABILITY = 'P0',
  PLAY_AUDIO = 'PA'
}


type WaitTimeLogin = {
  canLogin : boolean
};
