import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/modal.service';
import { Constants} from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {

  connectIcon: string = Resources.CONECT;
  onlineBankApp: boolean = true;
  onlineBank: boolean = false;
  @Input() dataError: number;
  constructor(private readonly modalService: ModalService) { }

  ngOnInit(): void {
    if (this.dataError === Constants.ONLINE_BANK_APP_ID) {
      this.onlineBankApp = true;
      this.onlineBank = false;
    }
    else if (this.dataError === Constants.ONLINE_BANK_ID) {
      this.onlineBankApp = false;
      this.onlineBank = true;
    }
  }

  ngOnDestroy() {
    this.modalService.close();
  }
}
