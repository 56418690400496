import { PathModel } from 'src/app/models/PathModel';

interface AdditionalCreditCard {
  idSesion        : string;
  soliNume        : string;
  primerNombre    : string;
  segundoNombre  ?: string;
  apellidoMaterno?: string;
  apellidoPaterno : string;
  rfc             : string;
  curp            : string;
  estadoCivil     : string;
  sexo            : string;
  idSucursal      : number;
  porcentaje      : number;
  fechaNacimiento : string;
}

export class AdditionalCreditCardRequest {
  constructor(public data: AdditionalCreditCard) {}

  get endpoint(): PathModel {
    return PathModel.AdditionalCreditCard;
  }
}
