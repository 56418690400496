<ng-container *ngIf="vm$ | async as vm">
  <label
    class="w-100 text-center wrap"
    [ngClass]="{
      'header-l': !vm.breakpoints.mobile,
      'header-m': vm.breakpoints.mobile
    }"
    >{{ title }}</label
  >
</ng-container>
