import { ElementRef, Injectable, TemplateRef, WritableSignal, signal } from '@angular/core';
export interface ToastOptions {
  delay?: number,
  classname?: string
  styles?: Partial<CSSStyleDeclaration>,
  autohide?: boolean
}

export interface Toast extends ToastOptions {
  textOrTpl: string | TemplateRef<ElementRef>
}

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: WritableSignal<Toast[]> = signal<Toast[]>([]);
  show(textOrTpl: string | TemplateRef<ElementRef>, options: ToastOptions = {}) {
    this.clear();
    this.toasts.update(t => [...t, { textOrTpl, ...options }]);
  }

  remove(toast: Toast) {
    this.toasts.update(t => t.filter((t) => t !== toast));
  }

  clear() {
    this.toasts.update(() => []);
  }
}
