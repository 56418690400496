export class LocalTaxesConstants {

  static readonly FORM_CONTROLS = {
    CaptureLineLabel: 'Línea de captura',
    CaptureLineName: 'captureLine',
    ConceptLabel: 'Concepto',
    ConceptName: 'concept',
    ModelFolioLabel: 'Modelo o folio',
    ModelFolioName: 'modelFolio',
    FiscalLabel: 'Ejercicio fiscal',
    FiscalName: 'fiscalYear',
    FiscalExerciseLabel: 'Ejercicio',
    FiscalExerciseName: 'exercise',
    BimesterLabel: 'Bimestre',
    BimesterName: 'bimester',
    WaterAccountNumberLabel: 'Número de cuenta de agua',
    WaterAccountNumberName: 'waterAccountNumber',
    PaymentTypeLabel: 'Tipo de pago',
    PaymentTypeName: 'paymentType',
    BrandLabel: 'Marca',
    BrandName: 'brand',
    BillValueLabel: 'Valor factura',
    BillValueName: 'billValue',
    SubBrandLabel: 'Submarca',
    SubBrandName: 'subBrand',
    NumCilindersLabel: 'Número cilindros',
    NumCilindersName: 'numCilinders',
    AccountLabel: 'Cuenta',
    AccountName: 'account',
    Model: 'Modelo'
  };
  static readonly Numbers = {
    OneThousandTwentyFour:1024,
    OneThousandTwoHundred:1200
  };

  static readonly WATER_SUPPLY_ACCOUNT_PLACEHOLDER = '--- ---- ---- ----';

  static readonly TDC_PAYMENT = {
    Msi0: {label: 'En una exhibición', value : '0'},
    Msi3: {label: '3 MSI (Pago mensual - $600.00)', value : '1'},
    Msi6: {label: '6 MSI (Pago mensual - $300.00)', value : '2'},
    Msi9: {label: '9 MSI (Pago mensual - $200.00)', value : '3'}
  };

  static readonly MESSAGES = {
    InvalidWaterAccount: 'Número de cuenta de agua debe ser entre 2 y 16 caracteres',
    PaymentConcepts: 'Consulta los conceptos que puedes pagar',
    LineCapture: 'Línea de captura debe ser entre 16 y 27 caracteres'
  };

  static readonly LOCAL_TAX_CONCEPTS = {
    WaterSupply: 'Derechos por suministro de agua',
    PropertyTax: 'Impuesto predial',
    VehicleTax: 'Impuesto sobre tenencia'
  };

  static readonly ACCOUNT_TYPE = {
    Tdc: ['BLACK', 'WALMART', 'SANBORNS'],
  };

  static readonly WATER_SUPPLY_BANK = {
    Bank: 'Banco',
    BankName: 'Inbursa',
    Account: 'Cuenta'
  };

  static readonly CLASSES = {
    ContentMargin: 'mb-5 mx-3',
    ContentRedesignMargin: 'mb-5 mx-5',
    ContentMarginMobile: 'mb-3 mx-3',
    ContentMarginMobileForm: 'mb-3 mx-0'
  };

  static readonly PAYMENT_TAXES = {
    PayServiceTitle: 'Pagar servicio',
    ConfirmPay: 'Confirmación del pago'
  };

  static readonly LOCAL_TAXES_FORMS = {
    DYNAMIC_FORM:{
      TRANSAC_CAR:{
        ID:'36',
        CODE_RANGE:{
          IS_RANGE:true,
          START:'36',
          END:'45',
          LIST:[]
        },
        NAME:'tram autos',
        INPUTS:{
          MODEL_INPUT:{
            label: 'Modelo',
            name: 'Modelo',
          },
          LICENSES_PLATES:{
            label: 'Referencia',
            name: 'Referencia',
          },
          BRAND:{
            label: 'Marca',
            name: 'Marca',
          },
          ORIGIN:{
            label:'Origen Tramite',
            name:'OrigenTramite'
          }
        }
      },
      TENURE:{
        ID:'84',
        CODE_RANGE:{
          IS_RANGE:false,
          START:'',
          END:'',
          LIST:['84']
        },
        NAME:'Tenencia',
        INPUTS:{
          REFERENCE:{
            label: 'Referencia',
            name: 'Referencia',
            hide:false
          },
          MODEL:{
            label: 'Modelo o folios',
            name: 'ModeloFolio',
            hide:false
          },
          FISCAL:{
            label: 'Ejercicio fiscal',
            name: 'EjercicioFiscal',
            hide:false
          },
          BRAND:{
            label: 'Marca',
            name: 'Marca',
            hide:false,
            maxlength:25
          },
          INVOICE:{
            label: 'Valor factura',
            name: 'ValorFactura',
            hide:false,
            maxlength:25,
            min:1
          },
          SUBBRAND:{
            label: 'Submarca',
            name: 'Submarca',
            hide:false,
            maxlength:25
          },
          CYLINDERS:{
            label: 'Número de cilindros',
            name: 'Cilindros',
            hide:false,
            maxlength:5
          }

        }

      },
      GUARDIANSHIP:{
        ID:'71',
        CODE_RANGE:{
          IS_RANGE:true,
          START:'57',
          END:'76',
          LIST:[]
        },
        NAME:'Judic Tutela',
        INPUTS:{
          INVOICE:{
            label: 'Folio',
            name: 'Folio',
          }
        }
      },
      LICENSE:{
        ID:'01',
        CODE_RANGE:{
          IS_RANGE:false,
          START:'',
          END:'',
          LIST:['01','02','03','04','05','07','09','11','13','14']
        },
        NAME:'Lic A 3 Años',
        INPUTS:{
          TYPE:{
            label: 'Tipo',
            name: 'Tipo'
          },
          PERIOD:{
            label: 'Periodo',
            name: 'Periodo'
          },
          REFERENCE:{
            label: 'Referencia',
            name: 'Referencia'
          }
        }
      },
      VERIFICATION_FINES:{
        ID:'50',
        CODE_RANGE:{
          IS_RANGE:false,
          START:'',
          END:'',
          LIST:['50']
        },
        NAME:'Multa Verific',
        INPUTS:{
          PLACA:{
            label: 'Placa',
            name: 'Placa'

          },
          MODEL:{
            label: 'Modelo',
            name: 'Modelo'

          },
          BRAND:{
            label: 'Marca',
            name: 'Marca'

          }
        }
      },
      CURRENT_PAYROLLS:{
        ID:'88',
        CODE_RANGE:{
          IS_RANGE:false,
          START:'',
          END:'',
          LIST:['88','96']
        },
        NAME:'Nóminas Vig ',
        INPUTS:{
          RFC:{
            label: 'RFC',
            name: 'Rfc'
          },
          MONTH:{
            label: 'Mes',
            name: 'Mes'
          },
          PERIOD:{
            label: 'Ejercicio',
            name: 'Ejercicio'
          },
          DECLARATION_TYPE:{
            label: 'Tipo de declaraión',
            name: 'TipoDeclaracion',
            options:[
              {
                label:'Normal',
                value:'0'
              },
              {
                label:'Complementaria',
                value:'1'
              },
              {
                label:'Corrección',
                value:'2'
              }
          ]
          },
          NUMBER_WORKERS:{
            label: 'Número de trabajadores',
            name: 'numeroTrabajadores',
            maxlength:10
          },
          REMUNERATION_PAIDS:{
            label: 'Remuneraciones pagadas',
            name: 'RemuneracionesPagadas',
            maxlength:10
          }
        }
      },
      DUTY:{
        ID:'94',
        CODE_RANGE:{
          IS_RANGE:false,
          START:'',
          END:'',
          LIST:['94']
        },
        NAME:'Derechos varios',
        INPUTS:{
          INVOICE:{
            label: 'Folio',
            name: 'Folio'
          },
          FORMALITIES:{
            label: 'Tramite',
            name: 'Tramite'
          },
          NUMBER_DEED:{
            label: 'Número de escritura',
            name: 'NumeroEscritura',
            minlength:8,
            maxLength:8
          }
        }
      },
      ARCHIVE_NOTARIES:{
        ID:'93',
        CODE_RANGE:{
          IS_RANGE:false,
          START:'',
          END:'',
          LIST:['93']
        },
        NAME:'Derechos AGN DGJEL',
        INPUTS:{
          INVOICE:{
            label: 'Folio',
            name: 'Folio'
          },
          FORMALITIES:{
            label: 'Trámite',
            name: 'Tramite'
          },
          NUMBER_DEED:{
            label: 'Número de escritura',
            name: 'NumeroEscritura',
            minlength:8,
            maxLength:8
          }
        }
      },
      RECTIFICATION_MINUTES:{
        ID:'70',
        CODE_RANGE:{
          IS_RANGE:true,
          START:'57',
          END:'76',
          LIST:[]
        },
        NAME:'Rectific Acta',
        INPUTS:{
          INVOICE:{
            label: 'Folio',
            name: 'Folio'
          },
        }
      },
      COPIES_DOCUMENT:{
        ID:'54',
        CODE_RANGE:{
          IS_RANGE:false,
          START:'',
          END:'',
          LIST:['54']
        },
        NAME:'Copia de Actas',
        INPUTS:{
          INVOICE:{
            label: 'Folio',
            name: 'Folio'
          },
          QUANTITY:{
            label: 'Cantidad',
            name: 'Cantidad'
          },
          UNIT_PRICE:{
            label: 'Precio unitario',
            name: 'PrecioUnitario'
          }
        }
      },
      DIVORCE:{
        ID:'69',
        CODE_RANGE:{
          IS_RANGE:true,
          START:'57',
          END:'76',
          LIST:['']
        },
        NAME:'Judic Divorcio',
        INPUTS:{
          INVOICE:{
            label: 'Folio',
            name: 'Folio'
          }
        }
      },
      MOTORCYCLE:{
        ID:'40',
        CODE_RANGE:{
          IS_RANGE:true,
          START:'36',
          END:'45',
          LIST:['']
        },
        NAME:'Tram de Motos ',
        INPUTS:{
          MODEL_INPUT:{
            label: 'Modelo',
            name: 'Modelo',
          },
          REFERENCE:{
            label: 'Referencia',
            name: 'Referencia',
          },
          BRAND:{
            label: 'Marca',
            name: 'Marca',
          }
        }
      },
      LICENSES:{
        ID:'04',
        CODE_RANGE:{
          IS_RANGE:false,
          START:'',
          END:'',
          LIST:['04']
        },
        NAME:'Lic A2 particulares',
        INPUTS:{
          TYPE:{
            label: 'Tipo',
            name: 'Tipo',
          },
          PERIOD:{
            label: 'Periodos',
            name: 'Periodo',
          },
          REFERENCE:{
            label: 'Referencia',
            name: 'Referencia',
          }
        }
      },
      VIOLATIONS:{
        ID:'49',
        CODE_RANGE:{
          IS_RANGE:false,
          START:'',
          END:'',
          LIST:['49']
        },
        NAME:'Infracciones',
        INPUTS:{
          INVOICE_INFRINGMENT:{
            label: 'Folio infracción',
            name: 'FolioInfraccion'
        },
        AMOUNT:{
          label: 'Importe',
          name: 'Importe'
        }
      }
      },
      IMMOBILIZER:{
        ID:'47',
        CODE_RANGE:{
          IS_RANGE:false,
          START:'',
          END:'',
          LIST:['47']
        },
        NAME:'Ret Can Inm',
        INPUTS:{
          INVOICE_INFRINGMENT:{
            label: 'Folio infracción',
            name: 'FolioInfraccion'
        },
        AMOUNT:{
          label: 'Importe',
          name: 'Importe'
        }
        }
      },
      POLLUTING_VEHICLES:{
        ID:'51',
        CODE_RANGE:{
          IS_RANGE:false,
          START:'',
          END:'',
          LIST:['51']
        },
        NAME:'Multa por vehiculos contaminantes',
        INPUTS:{
          INFRINGEMENT_FOLIO:{
            label: 'Folio Infraccion',
            name: 'FolioInfraccion'
          },
          INFRINGEMENT_YEAR:{
            label: 'Año Infraccion',
            name: 'AnioInfraccion'
          }
        }
      },
      HOLOGRAMS:{
        ID:'52',
        CODE_RANGE:{
          IS_RANGE:false,
          START:'',
          END:'',
          LIST:['52']
        },
        NAME:'Venta Hologram',
        INPUTS:{
          INVOICE:{
            label: 'Folio',
            name: 'Folio'
          },
          VEHICLE_CENTER:{
            label: 'Clave Verificentro',
            name: 'ClaveVerificentro'
          },
          HOLOGRAM_TYPE:{
            label: 'Tipo Holograma',
            name: 'TipoHolograma'
          },
          QUANTITY:{
            label: 'Cantidad',
            name: 'Cantidad'
          }
        }
      },
      TREASURY:{
        ID:'77',
        CODE_RANGE:{
          IS_RANGE:false,
          START:'',
          END:'',
          LIST:['77']
        },
        NAME:'PAGOS DE TESORERÍA',
        INPUTS:{
          TAX_ADMINISTRATION:{
            label: 'Administración Tributaria',
            name: 'AdministracionTributaria'
          },
          REFERENCE:{
            label: 'Referencia',
            name: 'Referencia'
          },
          INVOICE:{
            label: 'Folio',
            name: 'Folio'
          },
          CONCEPT:{
            label: 'Concepto',
            name: 'Concepto'
          }
        }
      }
    }
  };
  static readonly ERROR_RFC = 'Completa este campo con un RFC válido';
  static readonly ERROR_CURP = 'Completa este campo con un CURP válido';
  static readonly NOT_FOUND_DATA = 'Nombre No encontrado';
  static readonly LINE_CAPTURE_TITLE = 'Línea de Captura';
  static readonly JUSTIFY_TEXT = 'justify-text';
  static readonly TYPE_PERSON = {
    PERSON:'F'
  };
  static readonly FormValidations =  {
    Required : 'required',
    Max : 'max',
    Min : 'min',
    Pattern : 'pattern',
    MinLength : 'minlength',
    MaxLength : 'maxlength'
  };
  static readonly AlertsMessage= `Puedes realizar el pago de <strong> Impuestos Locales </strong> con tu Cuenta de Débito
  o tu Tarjeta de Crédito. Si el pago es con <strong>Tarjeta de Crédito</strong>,
  tienes disponible el pago en una sola exhibición o <strong>meses sin intereses</strong>`;

  static readonly INPUT_TYPES = {
    Money: 'Money'
  };

  static readonly IMPLOC_NAME = {
    processSource: 'OrigenTramite'
  };
}
