<ng-container *ngIf="vm$ | async as vm">
  <input class="d-none">
  <div class="padding-container pt-3">
    <header>
      <div class="d-flex flex-row-reverse">
        <img id="icon-modal" role="button" (click)="close()" [src]="iconClose | cdnImage" class="close-icon"
          width="auto" height="auto" alt="icon-modal">
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center gap-2 pt-3">
        <div class="header-l text-center text-secondary-800">{{title}}</div>
        <div class="body-m text-secondary-700">{{subtitle}}</div>
      </div>
    </header>
    <main class="d-flex justify-content-between pt-3" [ngClass]="vm.breakpoints.mobile ? 'flex-column':'gap-4'">
        <section class="w-100">
          <div>
            <show-amount [label]="labelQuantity" color="var(--primary-1000)"></show-amount>
          </div>
          <div class="pt-3">
            <label class="header-xs pb-3">{{toAccount}}</label>
            <div class="d-flex flex-row gap-3 align-items-center">
              <div>
                <img [src]="imgCard.match('\/') ? imgCard : (imgCard | cdnImage)" alt="image"
                width="auto" height="auto" class="card-img-afore">
              </div>
              <div class="d-flex flex-column">
                <span class="body-s default-color">{{card | maskAccount : 4}}</span>
                <span class="detail-s">{{nameCard}}</span>
              </div>
            </div>
            <hr class="margin-hr">
          </div>
        </section>
        <section class="w-100">
          <div [class.w-100]="vm.breakpoints.mobile">
            <label class="header-xs main-color-label label-info pb-2 pt-2">{{information}}</label>
            <app-info-label
              [head]="unemploymentRetirementPartial" [body]="procedure" bodyClass="detail-s"
              headClass="body-s mb-2 text-secondary-800" [classes]="''" margin="mt-2">
            </app-info-label>
            <app-info-label
              [head]="modality" [body]="salary"
              bodyClass="body-s default-color"
              headClass="header-xs mb-2" [classes]="''" margin="mt-2">
            </app-info-label>
            <label class="header-xs main-color-label mb-2">{{dateLabel}}</label>
            <app-info-label
              [head]="formatDateToDay" [body]="processLabel" bodyClass="detail-s"
              headClass="body-s mb-2 default-color" [classes]="''" margin="mt-2">
            </app-info-label>
          </div>
        </section>
    </main>
    <footer class="pb-3">
      <div class="footer-buttons">
        <div class="btn-payment">
          <app-template-primary-button [properties]="confirmProperties" [showIcon]="true">
            {{btnConfirm}}
          </app-template-primary-button>
        </div>
        <div class="btn-payment" >
          <app-template-secondary-button [properties]="modifyProperties">
            {{btnModify}}
          </app-template-secondary-button>
        </div>
      </div>
    </footer>
  </div>
</ng-container>
<ng-template #Otp>
  <app-otp-card-replacement [optionsToken]="optionsModalToken" (confirmData)="getOtp($event)"
  [token]="closeOtpModal">
  </app-otp-card-replacement>
</ng-template>
