import { Strings } from 'src/core/constants/Strings';

export class InversionsStrings {
  static readonly MINIMUM_INVESTMENT_MESSAGE = 'La inversión debe ser mayor o igual a $30,000';
}

export class InvestmentStrings {
  static readonly INVESTMENT_FUNDS = {
    Subtitle: Strings.PAGE_HEADER.Subtitle,
    Investment: 'COMPRA',
    InvestmentCc: 'Compra',
    EnterAmountLabel: '¿Cuánto vas a comprar?',
    ModalException: {
      IdModal: 'addProductError',
      HeaderModal: 'Error',
      LabelMain: 'Su operación no pudo ser realizada. El monto ingresado es menor al precio del título',
      BuyHeaderModalDinbur: 'Solo se pueden realizar compras por monto fuera de horario de operación',
      BuyLabelMainDinbur: 'No hay monto suficiente para realizar la compra',
      SaleHeaderModalDinbur: 'Solo se pueden realizar ventas por títulos fuera de horario de operación',
      SaleLabelMainDinbur: 'No hay monto suficiente para realizar la venta'
    },
    FundsSale: {
      Title: 'Venta fondos de inversión',
      Subtitle: 'Ingresa los datos de la operación',
      TitleTypeInvestment: 'Tipo de operación',
      TypeInvestment: 'VENTA',
      TypeInvestmentCc: 'Venta',
      LabelInvAmount: 'Comisión de corretaje 1% más IVA sobre el monto operado',
      EnterAmountLabel: '¿Cuánto vas a vender?',
      ConditionState: 'modify',
      isModify: 'isModify',
      ComparisonLabelAmount: 'Monto',
      ComparisonLabelTitle: 'Títulos',
      ComparisonLabelSelect: 'Selecciona',
      AccountDestination: 'Cuenta destino',
      LabelInvTitlesDinbur:`El precio unitario del título del fondo de inversión es
      el último precio disponible y esta sujeto a cambio.
      Cuando la transacción se realice después de
      las 13:06 hrs, solo podrá ser realizada ingresando la
      cantidad de títulos y será completada al día siguiente`,
      LabelInvAmountDinbur:`El precio unitario del título del fondo de inversión es
      el último precio disponible y está sujeto a cambio.<br>
      Cuando la transacción se realice después de
      las 13:06 hrs, solo podrá ser realizada ingresando
      el monto y será completada el siguiente día.`,
      ConfirmProcess: {
        LabelUnitPrice:'Precio unitario de título',
        LabelNumberTitles: 'Número de títulos',
        LabelTitlePrice:'Precio título',
        LabelQuantity: 'Cantidad de títulos'
      },
      CompleteProcess: {
        CompleteFee: (end: string) => `Tu transacción fue realizada después de las ${end} horas, por lo que se aplicará el siguiente día hábil`,
        CompleteFeeDesk: (end: string) =>  `Tu transacción fue realizada después de las <br> ${end} horas, por lo que se aplicará el siguiente día <br> hábil`
      }
    },
    FundsConfirm: {
      Subtitle: Strings.INVESTMENT.ConfirmOperation,
      LabelInfo: Strings.TRANSFER.Body,
      SecondFee: 'Después de este horario se considerarán al siguiente día hábil.',
      ThirdFee: 'Comisión de corretaje 1% mas IVA sobre el monto operado.',
      FourFee:'Esta operación se realizara bajo el servicio de ejecución de operaciones.',
      FiveFee:'El monto de la operación se aplicará considerando el precio del título obtenido el dia de hoy una vez se conozca, por lo tanto el monto está sujeto a cambio.',
      SevenFee: 'La operación se realizará bajo el servicio de comercialización',
      EighthFee:'Esta operación se realizará bajo el servicio de comercialización',
    },
    FormInvestment: {
      TitleValue: 'Valor de título',
    },
  };
  static readonly HOURS_OPERATION_DINBUR = {
    Title: (start: string, end: string) => `Horario de operación de ${start} a ${end} en días hábiles`,
    Subtitle: `Las órdenes recibidas después de dicho
    horario se considerarán solicitadas
    al siguiente día hábil`,
    SubtitleFirstPart: `Las órdenes recibidas después de dicho
    horario se considerarán solicitadas`,
    SubtitleSecondPart: `al siguiente día hábil`,
  };

  static readonly HOURS_OPERATION = {
    Title: (start: string, end: string) => `Horario de operación de ${start} a ${end}`,
    Subtitle: 'Después de este horario se considerarán <br> al siguiente día hábil',
    Text: `Las órdenes recibidas después de dicho horario se considerarán solicitadas
      el siguiente día hábil, todas las órdenes se liquidarán al precio determinado
      el día de la operación y cuyo registro aparecerá el día hábil siguiente en
      los movimientos de su Fondo de inv. La liquidación de la operación se realiza
      48 horas hábiles después del día de la ejecución`
  };

  static readonly CURRENCY_FORMAT_BASE = '1.2-';
  static readonly SHARES_ERROR_MSG = 'La cantidad de títulos debe ser mayor a 0.';
  static readonly CAPTURE_NAME_DATE = 'yyyyMMddhhmmss';
  static readonly CONTENT_INVESTMENT_SALE = 'container-funds-sale';
  static readonly CONTENT_INVESTMENT_PURCHASE = 'content-main-funds';
}

