import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, map } from 'rxjs';
import { ButtonProperties } from 'src/app/interface/IButtonProperties';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Resources } from 'src/core/constants/Resources';
import { ModalFederalTaxReceiptFilterComponent } from 'src/app/no-schedulable-services/components/modal-federal-tax-receipt-filter/modal-federal-tax-receipt-filter.component';
import { ModalUtils } from 'src/core/utils/ModalUtils';
import { PATH } from 'src/core/constants/Path';
import { Router } from '@angular/router';
import { ServiceConstants } from 'src/core/constants/ServiceConstants';
import { formatDate } from '@angular/common';
import { Constants, FormatDate } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { FederalTaxReceiptService } from 'src/app/services/federal-tax-receipt-filter.service';
import { PaymentSATStrings } from 'src/core/constants/PaymentSATStrings';
import { Generic } from 'src/types/Generics';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { CancelFederalTaxPaymentRequest } from 'src/app/interface/dto/CancelFederalTaxPaymentRequest';
import { Utils } from 'src/core/utils/utils';
import { AlertService } from 'src/app/services/alert.service';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { GetFederalTaxPaymentResponse } from 'src/app/interface/dto/GetFederalTaxPaymentResponse';

@Component({
  selector: 'app-modal-cancel-federal-tax-receipt',
  templateUrl: './modal-cancel-federal-tax-receipt.component.html',
  styleUrls: ['./modal-cancel-federal-tax-receipt.component.css']
})
export class ModalCancelFederalTaxReceiptComponent implements AfterViewInit{
  @ViewChild(ServiceConstants.ModalCancelFederalTaxReceipt.ConfirmTemplateRef) confirm: TemplateRef<ModalCancelFederalTaxReceiptComponent>;
  @ViewChild(ServiceConstants.ModalCancelFederalTaxReceipt.CompleteTemplateRef) complete: TemplateRef<ModalCancelFederalTaxReceiptComponent>;

  currentTemplate$: BehaviorSubject<TemplateRef<ModalCancelFederalTaxReceiptComponent>> = new BehaviorSubject<TemplateRef<ModalCancelFederalTaxReceiptComponent> | null>(null);

  modal: NgbModalRef;
  data: GetFederalTaxPaymentResponse;
  questionMark: string = Resources.CIRCLE_BLUE_QUESTION;
  check: string = Resources.GREEN_CHECK_FILL;
  title = {
    confirm: ServiceConstants.ModalCancelFederalTaxReceipt.Title.Confirm,
    complete: ServiceConstants.ModalCancelFederalTaxReceipt.Title.Complete
  };
  accountSelected: string = Strings.EMPTY;
  periodSelected: string = Strings.EMPTY;
  openModalService: boolean = false;
  labelButtonYes: string = Strings.BUTTONS.Yes;
  labelButtonNo: string = Strings.BUTTONS.No;
  labelButtonAccept: string = Strings.BUTTONS.Accept;
  labelCancellation: string= PaymentSATStrings.CANCELLATION_AND_CONSULTATION.LabelCancellation;
  modalLabels: Generic<typeof PaymentSATStrings.CANCELLATION_AND_CONSULTATION.CancellationData> = PaymentSATStrings.CANCELLATION_AND_CONSULTATION.CancellationData;
  labelInvoice: string = PaymentSATStrings.CANCELLATION_AND_CONSULTATION.Invoice;
  invoice : number | string;

  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => {
      return breakpoints;
    })
  );
  primaryProperties: ButtonProperties = {
    doAction: () => {
      this.confirmCancellation();
    },
    classes: ServiceConstants.ModalCancelFederalTaxReceipt.BtnPrimaryProperties.ClassHTML
  };

  secondaryProperties: ButtonProperties = {
    doAction: () => {
      this.modalService.modalRef.dismiss();
      const modalOptions = {
        centered: true,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal
      };
      const ref = this.modalService.open(ModalFederalTaxReceiptFilterComponent, modalOptions);
      ref.componentInstance.openModalService = this.openModalService;
      ref.componentInstance.editProcess = true;
    },
    classes: ServiceConstants.ModalCancelFederalTaxReceipt.BtnSecondaryProperties.ClassHTML
  };

  acceptButton: ButtonProperties = {
    doAction: () => {
      this.router.navigate([PATH.Services]);
    },
    classes: ServiceConstants.ModalCancelFederalTaxReceipt.BtnAcceptButton.ClassHTML
  };
  completeDate: string = formatDate(new Date, FormatDate.DayMonthYearComplete, Constants.LOCALE_ES_MX);

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly router: Router,
    private readonly federalTaxReceiptService: FederalTaxReceiptService,
    private readonly alertService: AlertService
  ){}

  ngAfterViewInit(): void {
    this.currentTemplate$.next(this.confirm);
    this.periodSelected = this.federalTaxReceiptService.periodSelected;
    this.accountSelected = this.federalTaxReceiptService.accountSelected;
  }

  async confirmCancellation(){
    try {
      this.modalService.modalInstance.update({
        centered:true,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal
      });
      const request = new CancelFederalTaxPaymentRequest({idImpuestoFederal: this.data.idImpuestoFederal});
      const response = await this.federalTaxReceiptService.cancelFederalTaxPayment(request);
      if (!response || !response.datos) {
        throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
      }
      this.invoice=response.datos;
      this.federalTaxReceiptService.clearSelected();
      this.currentTemplate$.next(this.complete);
      this.modalService.modalInstance.update({
        centered:true,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalCancelFederalTaxReceipt
      });
      ModalUtils.changeClassToModal(
        ModalConstants.MODAL_OPTIONS.ModalCancelFederalTaxReceipt,
        ModalConstants.MODAL_OPTIONS.ModalCancelFederalTaxReceiptComplete);
    } catch (error) {
      this.federalTaxReceiptService.clearSelected();
      this.modalService.close();
      const errorDetail = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetail.msg, errorDetail.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      }, {
        onSuccess: () => this.modalService.close()
      });
    }
  }
}
