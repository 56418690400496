import { AppConstants, AppMethod } from 'src/core/constants/AppConstants';
import { DataType } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { AppUtils } from 'src/core/utils/AppUtils';

export class AppBridge {

    static invoke<T>(name: string, parameters?: string | object): Promise<{ success: boolean, data: T }> {
        return new Promise((resolve, reject) => {
            try {
                if(!AppUtils.platform.isApp) {
                    throw new Error(Strings.SERVICES.UnexpectedMsg);
                }

                this.invokeNative(name, parameters);

                const response = (event: Event) => {
                    window.removeEventListener(name, response);
                    resolve({ success: true, data: this.parseData(event[AppConstants.APP_DATA]) });
                };
                if(name === AppMethod.SendNativeScreen){
                    window.removeAllListeners(AppConstants.LISTENER_APP);
                }
                window.addEventListener(name, response);
            }
            catch (error) {
                reject({ success: false });
            }
        });
    }

    private static invokeNative(name: string, parameters?: string | object) {
        const platform = AppUtils.platform;

        if (parameters) {
            if(typeof parameters === DataType.Object){
                parameters = JSON.stringify(parameters);
            }

            if (platform.android) {
                platform.android[name](parameters);
            }
            else {
                platform.iOS[name].postMessage(parameters);
            }

        }
        else {
            if (platform.android) {
                platform.android[name]();
            }
            else {
                platform.iOS[name].postMessage(Strings.EMPTY);
            }
        }
    }

    private static parseData(data: string) {
        try{
            return JSON.parse(data);
        }
        catch(error) {
            return data;
        }
    }
}
