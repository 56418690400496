import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CreditCardModel } from 'src/app/interface/CreditCardModel';
import { Strings } from 'src/core/constants/Strings';
import { Numbers } from 'src/core/constants/Constants';
import { Balances } from 'src/app/interface/dto/CreditCardMovementsResponse';

@Injectable({
  providedIn: 'root'
})
export class CreditCardInfoStatusService {
  private readonly emptyModel: CreditCardModel = {
    alias: Strings.EMPTY,
    beneficio: false,
    cuentaClabe: Strings.EMPTY,
    descripcionCuenta: Strings.EMPTY,
    directorioImagen: Strings.EMPTY,
    numeroCuenta: Strings.EMPTY,
    numeroPersona: Strings.EMPTY,
    numeroTarjeta: Numbers.Zero,
    saldos: {
      cobertura: Numbers.Zero,
      deudor: Numbers.Zero,
      disponible: Numbers.Zero,
      retenido: Numbers.Zero,
      sbc: Numbers.Zero,
      total: Numbers.Zero
    },
    tipoCuenta: Strings.EMPTY,
    adicional: false,
    bloqueo: false,
    expiracion: Strings.EMPTY,
    traspasoDeuda: false
  };

  private readonly _balances: BehaviorSubject<Balances> = new BehaviorSubject<Balances>({
    CargosCuotas: Numbers.Zero,
    CreditoDisponible: Numbers.Zero,
    Cuenta: Strings.EMPTY,
    CuentaAnteSaldoCierre: Numbers.Zero,
    CuentaAnteSaldoCierreAlDia: Numbers.Zero,
    FechaCorte: Strings.EMPTY,
    FechaLimiPago: Strings.EMPTY,
    IdPersona: Numbers.Zero,
    LimiteCredito: Numbers.Zero,
    PagoMinimo: Numbers.Zero,
    Personume: Numbers.Zero,
    Puntos: Numbers.Zero,
    SaldoDia: Numbers.Zero,
    SaldoTotal: Numbers.Zero,
    SaldoTramite: Numbers.Zero,
    Tasa: Strings.EMPTY,
    PagoMinimoMSI: Numbers.Zero,
    LimiteMensual: Numbers.Zero,
    EstadoCodiCuenta: Strings.EMPTY,
    EstadoDescri: Strings.EMPTY
  });

  private readonly _creditCard: BehaviorSubject<CreditCardModel> =
    new BehaviorSubject<CreditCardModel>(this.emptyModel);

  get creditCard$() {
    return this._creditCard.asObservable();
  }

  get creditCard() {
    return this._creditCard.getValue();
  }

  set creditCard(data: CreditCardModel) {
    this._creditCard.next(data);
  }

  clearModel() {
    this._creditCard.next(this.emptyModel);
  }

  get balances$() {
    return this._balances.asObservable();
  }

  set balances(data: Balances) {
    this._balances.next(data);
  }

  get balances() {
    return this._balances.getValue();
  }
}
