<div
  class="detail-m-highlight title-footer-card-login
        text-center link font-size--02 cursor-pointer
        justify-content-center align-items-center"
>
  <a *ngIf="isLoginWithPass; else defaultText">
    <a for="title-footer-card-login" (click)="navigateToLoginFacepass()"
      >Ingresa con Facepass</a
    >
  </a>
  <ng-template #defaultText>
    <a target="blank" for="title-footer-card-login" (click)="navigateToLogin()">
      Ingresa con usuario y contraseña</a>
  </ng-template>

  <ng-template #content>
    <app-location-modal (modalCall)="closeModal()"></app-location-modal>
  </ng-template>

</div>
