import { Injectable } from '@angular/core';
import { ModalMyCardsComponent } from 'src/app/control-card/components/modal-my-cards/modal-my-cards.component';
import { ModalMyCardsService } from 'src/app/control-card/components/modal-my-cards/service/modal-my-cards.service';
import { ControlCardService } from 'src/app/services/control-card.service';
import { MapService } from 'src/app/services/map.service';
import { ModalMyCardsExtensionThreeService } from 'src/app/services/modal-my-cards-three.extension.service';
import { Numbers, ScrollOptions } from 'src/core/constants/Constants';
import { InputModalMyCard, InputNames } from 'src/core/constants/ControlCardConstants';
import { ControlCardStrings } from 'src/core/constants/ControlCardStrings';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class ModalMyCardsTabsService extends ModalMyCardsService {

  constructor(
    override readonly componentExtensionThree: ModalMyCardsExtensionThreeService,
    override readonly mapService: MapService,
    private readonly controlCardService: ControlCardService) {
    super(componentExtensionThree, mapService);
  }

  selectCancelTab(context: ModalMyCardsComponent) {
    if (context.selectCancel === Strings.ACTIVE) {
      context.cardCancellationModal();
    }
  }

  tabsFunction(event: string, context: ModalMyCardsComponent) {
    switch (event) {
      case Strings.LABELS_MANAGE_CARDS.Lock:
        context.selectedLockTab = true;
        context.selectedModifyTab = false;
        context.selectedCvvTab = false;
        context.selectLock = Strings.ACTIVE;
        context.selectModify = Strings.EMPTY;
        context.selectCVV = Strings.EMPTY;
        context.selectCancel = Strings.EMPTY;
        context.blockade = true;
        context.modify = false;
        context.cvv = false;
        context.cancel = false;
        context.buttonModalCard = Strings.CONTINUE_BUTTON;
        context.timeCvv = false;
        context.hideCvvForm = true;
        context.activeCvv = true;
        context.hideButton = true;
        context.showDcvv = true;
        context.atmInformation = context.getLocksLimitsInfo.Limits.Amounts.Atm;
        context.atmValue = context.getLocksLimitsInfo.Limits.Movements.Atm;
        context.commerceInformation = context.getLocksLimitsInfo.Limits.Amounts.Pos;
        context.commerceValue = context.getLocksLimitsInfo.Limits.Movements.Pos;
        context.onlineInformation = context.getLocksLimitsInfo.Limits.Amounts.Int;
        context.locksAtm = context.getLocksLimitsInfo.Locks.Atm;
        context.locksCommerce = context.getLocksLimitsInfo.Locks.Pos;
        context.locksInt = context.getLocksLimitsInfo.Locks.Int;
        this.controlCardService.dataControls.Monthly.Amount = this.controlCardService.dataControls.Monthly.OriginAmount;
        context.isContinueLockValid = false;
        context.additional = false;
        context.status = false;
        context.dinamycCVV = false;
        context.selectedCvvContinueTab = false;
        context.activeOption = true;
        context.flagNotChanges = true;
        context.arrayValuesLimits = JSON.parse(JSON.stringify(context.getLocksLimitsInfo.Limits));
        this.backdropCustom();
        break;
      case Strings.LABELS_MANAGE_CARDS.Modify:
        context.modifystate = false;
        context.selectedModifyTab = true;
        context.selectedLockTab = false;
        context.selectedCvvTab = false;
        context.selectModify = Strings.ACTIVE;
        context.selectLock = Strings.EMPTY;
        context.selectCVV = Strings.EMPTY;
        context.selectCancel = Strings.EMPTY;
        context.blockade = false;
        context.modify = true;
        context.cvv = false;
        context.cancel = false;
        context.buttonModalCard = Strings.CONTINUE_BUTTON;
        context.timeCvv = false;
        context.hideCvvForm = true;
        context.activeCvv = true;
        context.hideButton = true;
        context.showDcvv = true;
        context.additional = false;
        context.status = false;
        context.dinamycCVV = false;
        context.selectedCvvContinueTab = false;
        context.activeOption = false;
        context.adminCardForm.get(InputNames.Nip).setValue(Strings.EMPTY);
        context.adminCardForm.get(InputNames.ConfirmPin).setValue(Strings.EMPTY);
        context.adminCardForm.markAsPristine();
        context.adminCardForm.markAsUntouched();
        context.modifystate = false;
        break;
      case Strings.LABELS_MANAGE_CARDS.DynamicCVV:
        context.showicon();
        context.helpIcon = Resources.HELP_ICON;
        context.cardCvv = Strings.EMPTY;
        context.dinamycCVV = true;
        context.selectedCvvTab = true;
        context.selectedLockTab = false;
        context.selectedModifyTab = false;
        context.selectCVV = Strings.ACTIVE;
        context.selectLock = Strings.EMPTY;
        context.selectModify = Strings.EMPTY;
        context.selectCancel = Strings.EMPTY;
        context.blockade = false;
        context.modify = false;
        context.activeCvv = true;
        context.cvv = true;
        context.cancel = false;
        context.buttonModalCard = Strings.REGISTER_BUTTON;
        context.hideButton = true;
        context.additional = false;
        context.status = false;
        context.processCvv();
        context.hideCvvForm = true;
        context.selectedCvvContinueTab = false;
        context.selectedCvvTab = true;
        context.activeOption = false;
        context.cvvstate = false;
        context.cvvCardForm.get(InputNames.CvvMyCards).markAsUntouched();
        if (context.lblDcvv === Strings.EMPTY) {
          context.selectedCvvTab = true;
          context.timeCvv = false;
          context.hideCvvForm = true;
          context.showDcvv = true;
        } else {
          context.selectedCvvTab = false;
          context.timeCvv = true;
          context.hideCvvForm = false;
          context.showDcvv = false;
        }
        break;
      case Strings.LABELS_MANAGE_CARDS.CancelCard:
        context.cancelReopen = false;
        context.selectCancel = Strings.ACTIVE;
        context.selectLock = Strings.EMPTY;
        context.selectModify = Strings.EMPTY;
        context.selectCVV = Strings.EMPTY;
        context.blockade = false;
        context.modify = false;
        context.cvv = false;
        context.cancel = true;
        context.buttonModalCard = Strings.CONTINUE_BUTTON;
        context.timeCvv = false;
        context.hideCvvForm = true;
        context.activeCvv = true;
        context.hideButton = false;
        context.showDcvv = true;
        context.additional = false;
        context.status = false;
        context.replacement = true;
        context.renovation = false;
        context.reception = false;
        context.showSucursal = false;
        context.titleModalBranch = ControlCardStrings.TITLE_REPLACEMENT;
        context.dinamycCVV = false;
        context.selectedCvvContinueTab = false;
        context.activeOption = false;
        context.cancelForm?.get(InputModalMyCard.Reason).setValue(Strings.EMPTY);
        context.cancelstate = false;
        break;
      case ControlCardStrings.LABELS_MANAGE_CARDS.Additional:
        context.notifyDate.isErrorData = false;
        context.notifyDate.dateStructData = null;
        context.notifyDate.isExternalErrorData = false;
        context.activeCvv = false;
        context.hideButton = true;
        context.blockade = false;
        context.modify = false;
        context.cvv = false;
        context.cancel = false;
        context.additional = true;
        context.status = false;
        context.buttonModalCard = Strings.CONTINUE_BUTTON;
        context.selectedLockTab = false;
        context.replacement = false;
        context.renovation = false;
        context.reception = true;
        context.showSucursal = true;
        context.titleModalBranch = ControlCardStrings.TITLE_RECEPTION;
        context.dinamycCVV = false;
        context.hideCvvForm = true;
        context.selectedCvvContinueTab = false;
        context.selectedModifyTab = false;
        context.selectedCvvTab = false;
        context.activeOption = false;
        context.state = false;
        break;
      case ControlCardStrings.LABELS_MANAGE_CARDS.Status:
        context.blockade = false;
        context.modify = false;
        context.cvv = false;
        context.cancel = false;
        context.additional = false;
        context.status = true;
        context.buttonModalCard = ControlCardStrings.OTHER_BUTTON;
        context.selectedLockTab = false;
        context.activeCvv = false;
        context.hideButton = true;
        context.dinamycCVV = false;
        context.hideCvvForm = true;
        context.selectedCvvContinueTab = false;
        context.activeOption = false;
        setTimeout(() => {
          Utils.enableSlide(ScrollOptions.TableScroll);
        }, Numbers.Fifty);
        break;
    }
    if (context.selectModify !== Strings.ACTIVE) { context.showRequiredForm = false; }
  }
}
