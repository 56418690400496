<ng-container *ngIf="inactivity; else NoInactivity">
  <div class="space-modal-logout">
    <div class="text-center">
      <img [src]="exclamationMarkBlue | cdnImage" alt="exclamation" width="auto" height="auto" class="img-space-logout">
    </div>
    <div
      class="d-flex justify-content-center align-items-start flex-column px-4 pt-4">
      <p class="header-xs-new w-100 text-center text-secondary-1200">Sesión inactiva</p>
      <p class="detail-m w-100 text-center">Tu sesión está por finalizar <br/> ¿Necesitas más tiempo?</p>
    </div>
    <div class="d-flex flex-column align-items-center">
      <button class="button-m colored-btn colored-btn-logout mb-3" (click)="actionSessionUser(false)"
      (keydown.enter)="$event.preventDefault()">{{ btnLabelContinue }}</button>
      <button class="button-m outline-btn outline-btn-logout" (click)="actionSessionUser(true)"
      (keydown.enter)="$event.preventDefault()">{{ btnLabelGoOut }}</button>
    </div>
  </div>
</ng-container>

<ng-template #NoInactivity>
  <div
    class="d-flex justify-content-center align-items-start flex-column px-4 pt-4">
    <p class="header-l w-100 text-center">
      Tu sesión con el portal<br />financiero Inbursa<br />
      terminará
    </p>
    <p class="body-m w-100 text-center">¿Deseas continuar?</p>
  </div>
  <hr class="mx-2 mt-3 mb-4" />
  <div class="d-flex flex-column align-items-center">
    <button class="button-m colored-btn mb-2" (click)="actionSessionUser(true)"
    (keydown.enter)="$event.preventDefault()">{{btnLabelCloseSession}}</button>
    <button class="button-m outline-btn mt-1 mb-3" (click)="actionSessionUser(false)"
    (keydown.enter)="$event.preventDefault()">{{ btnLabelCancel }}</button>
  </div>
</ng-template>

