import { Injectable, WritableSignal, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccountType, Constants, Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { ITransferData } from 'src/app/interface/ITransferData';
import { CheckAccountLimitCTRequest } from 'src/app/interface/dto/ICheckAccountLimitCTRequest';
import { StorageService } from 'src/app/services/storage.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { CheckAccountLimitCTResponse } from 'src/app/interface/dto/ICheckAccountLimitCTResponse';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { PATH } from 'src/core/constants/Path';
import { Utils } from 'src/core/utils/utils';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { Resources } from 'src/core/constants/Resources';
import { AccountModel } from '../interface/AccountModel';
import { AccountTypeMax } from 'src/core/constants/ProductConstants';

@Injectable({
  providedIn: 'root'
})
export class PatrimonialStatusService {

  private readonly accountLimitClean: IAccountLimit = {
    getLimit:Numbers.Zero,
    productCardAxis:Strings.EMPTY,
    accountAxis :Strings.EMPTY,
    productCardCTS:Strings.EMPTY,
    accountCTS:Strings.EMPTY,
    accumulatedLimit: Numbers.Zero
  };

  private readonly _status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _newLimit: BehaviorSubject<number> = new BehaviorSubject<number>(Numbers.Zero);
  private readonly _accountLimit: BehaviorSubject<IAccountLimit> = new BehaviorSubject<IAccountLimit>(this.accountLimitClean);
  private readonly _transferData: BehaviorSubject<ITransferData> = new BehaviorSubject<ITransferData>({
    cuentaDestino: Strings.EMPTY,
    cuentaOrigen: Strings.EMPTY,
    idCuentaProductoOrigen: Numbers.Zero,
    idCuentaProductoDestino: Numbers.Zero,
    clabeCT: Strings.EMPTY,
    imagenCT: Strings.EMPTY,
    no_tarjetaCT: Strings.EMPTY,
    saldoCT: Strings.EMPTY,
    seleccionadoCT: false,
    tipoTarjeta: Strings.EMPTY,
    imagenCTS: Strings.EMPTY,
    tipoTarjetaCTS: Strings.EMPTY,
    noTarjetaCTS: Strings.EMPTY,
    saldoCTS: Strings.EMPTY,
    clabeCTS: Strings.EMPTY
    });

  private readonly _isFutureTransaction: WritableSignal<boolean> = signal<boolean>(false);
  private readonly _isCtLimitExceededTransaction: WritableSignal<boolean> = signal<boolean>(false);
  private readonly _isPatrimonialMax: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private readonly storageService: StorageService,
              private readonly baseService: IBaseService,
              private readonly router: Router,
              private readonly alertService: AlertService,
              private readonly accountInfoService: AccountInfoStatusService) { }

  get isFutureTransaction() {
    return this._isFutureTransaction();
  }

  set isFutureTransaction(data: boolean) {
    this._isFutureTransaction.set(data);
  }

  get isCtLimitExceededTransaction() {
    return this._isCtLimitExceededTransaction();
  }

  set isCtLimitExceededTransaction(data: boolean) {
    this._isCtLimitExceededTransaction.set(data);
  }

  get status$() {
    return this._status.asObservable();
  }

  set status(data: boolean) {
    this._status.next(data);
  }

  set newLimit(data: number) {
    this._newLimit.next(data);
  }

  get newLimit() {
    return this._newLimit.getValue();
  }

  get newLimit$() {
    return this._newLimit.asObservable();
  }

  get accountLimit$() {
    return this._accountLimit.asObservable();
  }

  set accountLimit(data: IAccountLimit) {
    this._accountLimit.next(data);
  }

  get accountLimit() {
    return this._accountLimit.getValue();
  }

  get transferData$() {
    return this._transferData.asObservable();
  }

  set transferData(data: ITransferData) {
    this._transferData.next(data);
  }

  get transferData() {
    return this._transferData.getValue();
  }

  clearAccountLimitClean() {
    this._accountLimit.next(this.accountLimitClean);
  }

  async checkAccountLimitCT(account: string, router: string = PATH.Transfers): Promise<CheckAccountLimitCTResponse>{
    const request = new CheckAccountLimitCTRequest({
      IdSession: this.storageService.getSession(),
      IdentificadorCuenta: account
    });
    const checkAccountLimitCTResponse = await this.baseService.genericPost<CheckAccountLimitCTResponse>(request, { isSOA: true });
    if(!checkAccountLimitCTResponse || !checkAccountLimitCTResponse.CTS){
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
    }
    this.accountLimit = {
      getLimit: checkAccountLimitCTResponse.Limite,
      productCardAxis: checkAccountLimitCTResponse.CT.IdCuentaProducto,
      accountAxis: checkAccountLimitCTResponse.CT.NumeroCuenta,
      productCardCTS: checkAccountLimitCTResponse.CTS.IdCuentaProducto,
      accountCTS: checkAccountLimitCTResponse.CTS.NumeroCuenta,
      router,
      accumulatedLimit: checkAccountLimitCTResponse.LimiteAcumulado
    };
    return checkAccountLimitCTResponse;
  }

  get statusPatrimonialMax$(): Observable<boolean> {
    return this._isPatrimonialMax.asObservable();
  }

  set statusPatrimonialMax(_isPatrimonialMax: boolean) {
    this._isPatrimonialMax.next(_isPatrimonialMax);
  }

  async getLimitCT(router: string) {
    try {
      if (this.accountLimit.productCardAxis === Strings.EMPTY || this.accountLimit.productCardCTS.toString() !== this.accountInfoService.account.idCuentaProducto.toString()) {
        await this.checkAccountLimitCT(this.accountInfoService.account.numeroCuenta, router);
      }
      this.router.navigate([PATH.TransferCTPatrimonial]);
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      });
    }
  }
  async evaluateLimit(product: AccountModel){
    try {
     return (product.tipoCuenta === AccountType.PAT || product.tipoCuenta === AccountTypeMax.PATMX )?
     (await this.checkAccountLimitCT(product.numeroCuenta)).Limite: null;
    } catch (error) {
    Utils.printLogError(error);
    return null;
    }
  }
}

interface IAccountLimit {
  getLimit?: number;
  productCardAxis?: string;
  accountAxis?: string;
  productCardCTS?: string;
  accountCTS: string;
  router?: string;
  accumulatedLimit?:number
}
