import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatestWith, map } from 'rxjs';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { NotifyClearFormService } from 'src/app/services/notify-clear-form.service';
import { CardAddresseeService } from 'src/app/services/card-addressee.service';
import { AccountCardService } from 'src/app/services/account-card.service';
import { NotifyDateService } from 'src/app/services/notify-date.service';
import { PortabilityService } from 'src/app/services/portability.service';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { ServiceCardService } from 'src/app/services/service-card.service';
import { SelectAccountErrorService } from 'src/app/services/select-account-error.service';
import { ExpressTransferService } from 'src/app/services/express-transfer.service';
import { InsurancePolicies } from 'src/app/services/insurance-policies-status.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { ExpressTransferExtraDataService } from 'src/app/services/express-transfer-extra-data.service';
import { Strings } from 'src/core/constants/Strings';
import { ClassHTML, Numbers } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { CodeflexLayoutService } from 'src/app/services/codeflex-layout.service';
import { ServicePaymentTypeService } from 'src/app/services/service-payment-type.service';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import { WebCardLayoutService } from 'src/app/services/web-card-layout.service';
import { CreditCardsStatusService } from 'src/app/services/credit-cards-status.service';
import { InvestmentCompaniesInfoStatusService } from 'src/app/services/investment-companies-info-status.service';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { VoluntarySavingsContributionsService } from 'src/app/services/voluntary-savings-contributions.service';
import { LocalTaxesService } from 'src/app/services/local-taxes.service';
import { AddCreditService } from 'src/app/services/add-credit.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private readonly state: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly notifyClearFormService: NotifyClearFormService,
    public readonly accountCardService: AccountCardService,
    public readonly cardAddresseeService: CardAddresseeService,
    private readonly portabilityService: PortabilityService,
    private readonly notifyDateService: NotifyDateService,
    private readonly statePayIdService: StateServicePayIdService,
    private readonly serviceCards: ServiceCardService,
    private readonly selectAccountErrorService: SelectAccountErrorService,
    private readonly expressTransferService: ExpressTransferService,
    private readonly insurancePolicies: InsurancePolicies,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly expressTransferExtraDataService: ExpressTransferExtraDataService,
    private readonly servicePaymentTypeService: ServicePaymentTypeService,
    private readonly codeflexLayoutService: CodeflexLayoutService,
    private readonly dynamicFormService: DynamicFormService,
    private readonly formTermService: WebCardLayoutService,
    private readonly creditCardsStatusService: CreditCardsStatusService,
    private readonly investmentCompaniesStatusService: InvestmentCompaniesInfoStatusService,
    private readonly accountInfoService: AccountInfoStatusService,
    private readonly voluntarySavingsContributionsService: VoluntarySavingsContributionsService,
    private readonly localTaxesService: LocalTaxesService,
    private readonly addCreditService: AddCreditService
    ){ }

  get stateObservable() {
    return this.state.asObservable().pipe(
      combineLatestWith(this.responsiveService.observe),
      map(([sidebar, breakpoints]) => {
        if(!breakpoints.desktop) return false;
        return sidebar;
      })
    );
  }

  set stateData(value: boolean) {
    this.state.next(value);
  }
  clearData(currentRoute: string){
    this.localTaxesService.deferredPayment = Strings.EMPTY;
    this.localTaxesService.getIFMPromotionCDMXResponse = null;
    this.notifyClearFormService.clearObservablesGeneral();
    this.notifyDateService.dateStructData = undefined;
    this.cardAddresseeService.clearCardAddressee();
    this.expressTransferService.clearExpressTransferData();
    this.insurancePolicies.clearSelectedPolicies();
    this.notifyAmountService.amount = { amount: Strings.EMPTY };
    this.notifyAmountService.internationalAmount = {internationalAmount: Numbers.Zero };
    this.statePayIdService.clearService();
    this.serviceCards.clearServiceCard();
    this.selectAccountErrorService.accountErrorData = { value: false };
    this.expressTransferExtraDataService.clearData();
    this.codeflexLayoutService.isCodeflex = false;
    this.dynamicFormService.clearForm();
    this.formTermService.formTermsBs.next(null);
    this.expressTransferService.isExpressTransfer = false;
    this.investmentCompaniesStatusService.status={value:false};
    this.insurancePolicies.currencySymbol = Strings.EMPTY;
    if (currentRoute !== PATH.Portability) {
      this.accountCardService.clearCardAccount();
      this.portabilityService.clearPortabilityForm();
    }
    this.servicePaymentTypeService.clearPaymentType();
    this.voluntarySavingsContributionsService.clearData();
    this.voluntarySavingsContributionsService.clearDataAnotherOperation();
    this.addCreditService.clearData();
  }

  cleanOperation(url?: string){
    if (url !== PATH.Home) {
      this.accountInfoService.clearAccount();
      this.creditCardsStatusService.status = { value: false };
      this.investmentCompaniesStatusService.status={value:false};
    }
    this.notifyDateService.dateStructData = undefined;
    this.accountCardService.clearCardAccount();
    this.cardAddresseeService.clearCardAddressee();
    this.insurancePolicies.currencySymbol = Strings.EMPTY;
    this.dynamicFormService.clearForm();
    this.formTermService.formTermsBs.next(null);
    this.voluntarySavingsContributionsService.clearData();
    this.voluntarySavingsContributionsService.clearDataAnotherOperation();
    this.addCreditService.clearData();
  }

  resetState(){
    document.body.classList.remove(ClassHTML.SidebarToggled);
    this.stateData = false;
  }
}
