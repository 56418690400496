import { PathModel } from '../models/PathModel';

export interface IFacepassInfoGeneralRequest{
    Sesion: string,
    IC : null
}

export class FacepassInfoGeneralRequest{
    constructor(public data: IFacepassInfoGeneralRequest){}

    get endpoint(): PathModel {
      return PathModel.FacepassOverview;
  }
}
