import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalEndProcessAlertComponent } from 'src/app/shared/components/modal-end-process-alert/modal-end-process-alert.component';
import { ModalService } from 'src/app/shared/modal.service';
import { Constants } from 'src/core/constants/Constants';
import { ModalConstants } from 'src/core/constants/ModalConstants';
export class LeaveModalUtils {
  static confirmLeavePage(modalService: ModalService) {
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeSm,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAlertEndProcess
    };
    modalService.open(ModalEndProcessAlertComponent, modalOptions);
  }
}
