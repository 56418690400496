import { PathModel } from 'src/app/models/PathModel';

export type IClaimPaymentRequest = {
    cuenta: string;
    idCuentaProducto: number;
    monto: number;
    idOperacion: string;
    banderaPagoSeguros: boolean;
    otp?: string;
};

export class ClaimPaymentRequest {
    constructor(public data: IClaimPaymentRequest) { }
    get endpoint(): PathModel {
        return PathModel.ClaimPayment;
    }
}
