export class ProductStrings {
  static readonly MONTH_PERIODS_NOT_AVAILABLE = 'No cuentas con mes para consultar';
  static readonly YEAR_PERIODS_NOT_AVAILABLE = 'No cuentas con año para consultar';
  static readonly CONCEPT_RETAINED_DETAIL = 'Detalle de saldo retenido';
  static readonly UPDATE_ALIAS = 'Se ha actualizado el alias correctamente.';
  static readonly BALANCE = 'Saldo';
  static readonly BALANCE_CERTIFICATION = 'Certificación de Saldos';
  static readonly PAY_INPUT = 'Pagar';

  static readonly TYPE_ACCOUNT = {
    InbursaTelcelOro: 'Inbursa Telcel Oro',
    InbursaDigital: 'digital',
    Inburcan: 'inburcan',
    InbursaOro:'Inbursa Oro',
    Optima:'Inbursa Óptima',
    GroceryVoucher:'vale'
  };

  static readonly MODAL_DELETE_NOTIFICATION = {
    Id: 'deleteNotificationModal',
    Header: '¿Quieres eliminar esta(s) notificación(es)?',
    Continue: 'Sí',
    ConfirmId: 'confirmDeleteNotification'
  };

    static readonly STATEMENT_ACCOUNT = {
    Digital: 'digital',
    Print: 'impreso'
  };

  static readonly VIRTUAL_CARD = {
    headerRememberActivate: '<p class="header-s text-center mb-0">Activa tu tarjeta digital</p>',
    messageRememberActivate: '<p class="px-3">Tu tarjeta digital ya está lista, Activala ahora y comienza a usar tu cuenta de inmediato.</p>',
    buttonActivate: 'Activar',
    buttonRememberLater: 'Recordar más tarde',
    imgClassRememberLater: 'icon-remember-later',
    buttonActivateNip: 'Crear NIP'
  };

  static readonly CLASS_BTN_EDIT_STYLE = {
    background: 'var(--secondary-1500)',
    borderRadius: 'var(--border-radius-s)',
    border: 'none',
    color: 'var(--secondary-300)'
  };

  static readonly ID_ELEMENT = {
    aleredit: 'aleredit',
    limite: 'limite'
  };
  static readonly PORTABILITY_LABEL = {
    Payroll: 'Portabilidad de nómina',
    Transfer: 'Transferencia de nómina',
    Confirm: 'Confirmación de los datos',
    Data: 'Portabilidad',
    Register: 'Alta portabilidad',
    Maximum: 'Mínimo 16 dígitos máximo 18 dígitos',
    NotFound: 'No existen solicitudes de portabilidad',
    Send: 'Tarjeta o Cuenta CLABE emisora',
    Receiver: 'Tarjeta o Cuenta CLABE receptora',
    Inbursa: 'INBURSA',
    Efe: 'NOMINA EFE',
    Year: 1950,
    ReceavingAccountInformations : 'Información de la cuenta receptora',
    IssuingAccountInformations : 'Información de la cuenta emisora',
    EnterYourDateOfBirth: 'Ingresa tu fecha de nacimiento',
    SenderAccount: 'Cuenta emisora'
};
  static readonly ASSOCIATE_ACCOUNT = '¿Estás seguro de que quieres asociar esta cuenta para el fondeo de Claro Pay?';
  static readonly DEASSOCIATE_ACCOUNT = '¿Estás seguro de que quieres desasociar esta cuenta del fondeo de Claro Pay?';
  static readonly SUCCESS_ASSOCIATE = 'Cuenta de fondeo asociada';
  static readonly SUCCESS_DEASSOCIATE = 'Cuenta de fondeo desasociada';
  static readonly ASSOCIATE = 'asociado';
  static readonly DEASSOCIATE = 'desasociado';
  static readonly REQUEST_FOUNDING_ACCOUNT = 'No tienes cuenta para asociar a Claro Pay ¿Deseas contratar una cuenta Inbursa?';
  static readonly CONTRACT_ACCOUNT = 'Contratar una cuenta';
  static readonly FOUNDING_ACCOUNT = 'CuentaFondeo';
  static readonly OPTIONS_ACCOUNT = ['PAT', 'VALEDESP', 'WEB', 'CLARO'];
  static readonly CONFIRMATION_FOUNDING_TITLE = (name: string, account: string, clabe: string) =>
    `${name}, ${account} número de cuenta ${clabe}`;
  static readonly SUCCESS_FOUNDING_CONTENT = (messageTitle: string, description: string, account: string, clabe: string) =>
    `Se ha ${messageTitle} la cuenta: ${description}, ${account} número de cuenta ${clabe} como cuenta de fondeo para tu cuenta Claro Pay`
  static readonly CLARO_PAY_LINK = 'Vincular';
  static readonly CLARO_PAY_DELETE_ACCOUNT = 'Eliminar cuenta';
  static readonly TITLE_INVESTMENT = 'Fondos de Inversión';
  static readonly TITLE_FOUNDING_ACCOUNT = 'Modificar cuenta fondeo';
  static readonly TITLE_ADD_FOUNDING_ACCOUNT = 'Agregar cuenta fondeo';
}
