import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private readonly isLoading$ = new Subject<boolean>();
  private readonly resetRequests$ = new Subject<boolean>();
  private _loadingSpinner = false;

  constructor() { }

  get isLoading(){
    return this.isLoading$.asObservable();
  }

  showLoader() {
    document.body.classList.add('no-scroll');
    this.isLoading$.next(true);
    this._loadingSpinner = true;
  }

  hideLoader() {
    document.body.classList.remove('no-scroll');
    this.isLoading$.next(false);
    this._loadingSpinner = false;
  }

  get isLoadingSpinner() {
    return this._loadingSpinner;
  }

  set resetRequest(reset:boolean){
    this.resetRequests$.next(reset);
  }

  get resetRequest$(){
    return this.resetRequests$.asObservable();
  }
}
