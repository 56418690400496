import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { SubscriptionLike } from 'rxjs';
import { LocationService } from 'src/app/services/location.service';
import { MapService } from 'src/app/services/map.service';
import { Numbers } from 'src/core/constants/Constants';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html'
})

export class MapComponent implements OnInit {

  constructor(
    private readonly locationService: LocationService,
    private readonly mapService: MapService
  ) { }

  @ViewChild(MapInfoWindow) infoWindow?: MapInfoWindow;
  @ViewChild('map') map: GoogleMap;
  markerPositions: google.maps.LatLngLiteral[] = this.mapService.markerPositions;
  markerOptions: google.maps.MarkerOptions = this.mapService.markerOptions;
  @Input() height: number;
  @Input() width: number;
  @Output() selectedId = new EventEmitter();
  center: google.maps.LatLngLiteral;
  centersSL: SubscriptionLike;
  display: {
    lat: number,
    lng: number
  };
  zoom = Numbers.Twelve;

  ngOnInit(): void {
    this.centersSL = this.locationService.center$.subscribe((center) => {
      this.center = center;
      const panorama = this.map?.getStreetView();
      if (panorama?.getVisible()) {
      panorama.setPosition({ lat: center.lat, lng: center.lng });
      }
    });
  }

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = (event.latLng.toJSON());
  }
  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  addMarker(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.mapService.markerPositions.push(event.latLng.toJSON());
  }
  openInformationWindow(marker: MapMarker, selectedId: number) {
    if (this.infoWindow !== undefined) this.infoWindow.open(marker);
    this.selectedId.emit(selectedId);
  }

  ngOnDestroy() {
    this.centersSL?.unsubscribe();
  }
}
