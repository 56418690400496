import { PathModel } from 'src/app/models/PathModel';

type TModifyAgendaRequest ={
  idSesion : string,
  idAgenda : number,
  codigoServicio : string,
  alias : string,
  nuevoLimiteDiario : number,
  Token : {
    OTP : string,
    HAC ?: null,
    SerialNumber ?: null
  },
  tipoAcceso : string,
  canal : string,
  ip : string,
  traza ?: null,
  token ?: null,
  nombreAplicacion : string,
  Latitud : number,
  Longitud : number,
  IdAdicional? : null,
  IdPersonaTitular? : null,
  IsAditional? : false,
  NumeroCuenta : string,
  email : string,
  email2 ?: null,
  email3 ?: null,
  MedioAcceso: string,
  OrigenConsulta?: string
};

export class ModifyAgendaRequest{
  constructor (public data: TModifyAgendaRequest){}

  get endpoint(): PathModel{
    return PathModel.ModifyAgentService;
  }
}
