import { PathModel } from 'src/app/models/PathModel';

interface IGetBrokerageHouseThirdPartiesRequest {
  idSession: string;
  idPersonaAdicional?: number;
}

export class GetBrokerageHouseThirdPartiesRequest {
  constructor(public data: IGetBrokerageHouseThirdPartiesRequest) { }

  get endpoint(): PathModel {
    return PathModel.GetBrokerageHouseThirdParties;
  }
}




