import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccountNumberModel } from 'src/app/models/AccountNumberModel';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
  providedIn: 'root'
})
export class ServiceAccountNumberService {
  private readonly accountNumber: BehaviorSubject<AccountNumberModel> = new BehaviorSubject<AccountNumberModel>({ accountNumber: Strings.EMPTY, isError: false});

  constructor() { }

  get serviceAccountNumberObservable(): Observable<AccountNumberModel> {
    return this.accountNumber.asObservable();
  }

  set serviceAccountNumberData(data: AccountNumberModel) {
    this.accountNumber.next(data);
  }

  get serviceAccountNumberBS(): BehaviorSubject<AccountNumberModel> {
    return this.accountNumber;
  }
}
