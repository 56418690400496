import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Strings } from 'src/core/constants/Strings';
import { InputConfig } from 'src/app/interface/IInputConfig';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DynamicFormService {
  private readonly _form: BehaviorSubject<FormGroup> =
    new BehaviorSubject<FormGroup>(null);

  private readonly _inputConfig: BehaviorSubject<InputConfig[]> =
    new BehaviorSubject<InputConfig[]>(null);

  constructor() { }

  get formObservable(): Observable<FormGroup> {
    return this._form.asObservable();
  }

  get formBS(): BehaviorSubject<FormGroup> {
    return this._form;
  }

  set formData(data: FormGroup) {
    this._form.next(data);
  }

  get inputConfigObservable(): Observable<InputConfig[]> {
    return this._inputConfig.asObservable();
  }

  get inputConfigBS(): BehaviorSubject<InputConfig[]> {
    return this._inputConfig;
  }

  set inputConfigData(data: InputConfig[]) {
    this._inputConfig.next(data);
  }

  toFormGroup(inputs: InputConfig[]): FormGroup {
    const group: FormControl[] = [];
    inputs.forEach((input) => {
      const validations: ValidatorFn[] = this.validationsBuild(input);
      group[input.name] = new FormControl(Strings.EMPTY, validations);
    });
    return new FormGroup(group);
  }

  /**
   * @deprecated Use instead toFormGroup
   * este método establece variables de forma automatica y devuelve
   * BehaivorSubject.value pero esto podria provocar comportamientos
   * inesperados en el servicio.
   */
  createForm(formSpouseData: InputConfig[]): FormGroup {
    this.inputConfigData = formSpouseData;
    this.formData = this.toFormGroup(formSpouseData);
    return this.formBS.value;
  }

  clearForm(): void {
    this.inputConfigData = null;
    this.formData = null;
  }
  /*
  * @param input: InputConfig - Objeto con la configuracion de los campos del formulario que se desea agregar
  * @param index?: number - Indice donde se desea agregar el campo al formulario es opcional, cuando no se especifica se agrega al final del array
  */
  addFormControltoFormGroups(input: InputConfig, index?: number): void{
    const validations: ValidatorFn[] = this.validationsBuild(input);
    this.formBS.value.addControl(input.name, new FormControl(Strings.EMPTY, validations));
    if(!this.inputConfigBS.value.includes(input)){
      if (!!index) {
        this.inputConfigBS.value.splice(index, 0, input);
      }else{
        this.inputConfigBS.value.push(input);
      }
    }
  }

  /*
  * @param input: InputConfig - Objeto con la configuracion de los campos del formulario que se desea eliminar
  */
  removeFormControltoFormGroups(input: InputConfig): void{
    this.formBS.value.removeControl(input.name);
    this.inputConfigBS.value.forEach(
      (item, index) => {
        if (item.name === input.name) {
          this.inputConfigBS.value.splice(index, 1);
        }
      }
    );
  }

  private validationsBuild(input: InputConfig): ValidatorFn[]{
    const validations: ValidatorFn[] = [];
    input.validations?.forEach((validation) => {
      if (!!!validation.message) {
        validation.message = Strings.GENERIC_ERRORS[validation.name.gfiCapitalize()];
      }
      validations.push(validation.validator);
    });
    return validations;
  }
}
