import { PathModel } from '../../../../src/app/models/PathModel';

type TConsultCurrencyExchangeRequest = {
    Fecha: string,
    IdSession: string,
    Divisa?: string,
    MedioAcceso?: string

};
export class ConsultCurrencyExchangeRequest {
    constructor(public data: TConsultCurrencyExchangeRequest) { }

    get endpoint(): PathModel {
        return PathModel.GetExchangeRate;
    }
}
