import { PathModel } from 'src/app/models/PathModel';

interface IGetFederalTaxPaymentRequest {
  cuentaClabe: string;
  anio: string;
}

export class GetFederalTaxPaymentRequest {
  constructor(public data: IGetFederalTaxPaymentRequest) { }

  get endpoint(): PathModel {
    return PathModel.GetFederalTaxPayment;
  }
}
