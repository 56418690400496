import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { Resources } from 'src/core/constants/Resources';
import { EnterAmountErrorService } from 'src/app/services/enter-amount-error.service';
import { IAmount } from 'src/app/interface/IAmount';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { Utils } from 'src/core/utils/utils';
import { Constants, Length, Tab } from 'src/core/constants/Constants';
import { AccountCardService } from 'src/app/services/account-card.service';
import { SelectAccountErrorService } from 'src/app/services/select-account-error.service';
import { AdditionalContributionsService } from 'src/app/services/additional-contributions.service';
import { NotifyClearFormService } from 'src/app/services/notify-clear-form.service';
import { InsurancePolicyInfoStatusService } from 'src/app/services/insurance-policy-info-status.service';
import { map } from 'rxjs';
import { NotifyRouteService } from 'src/app/services/notify-route.service';

@Component({
  selector: 'app-additional-contributions',
  templateUrl: './additional-contributions.component.html',
  styleUrls: ['./additional-contributions.component.css']
})
export class AdditionalContributionsComponent implements OnInit {

  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints }))
  );

  title: string = Strings.ADDITIONAL_CONTRIBUTIONS.Title;
  subtitle: string = Strings.ADDITIONAL_CONTRIBUTIONS.SubTitle;
  enterAmountLabel: string = Strings.ADDITIONAL_CONTRIBUTIONS.EnterAmountLabel;
  typeContribution: string = Strings.ADDITIONAL_CONTRIBUTIONS.TypeContribution;
  titleOperation: string = Strings.ADDITIONAL_CONTRIBUTIONS.TitleOperation;
  textDetail: string = Strings.ADDITIONAL_CONTRIBUTIONS.TextDetail;
  operationType: string = Strings.ADDITIONAL_CONTRIBUTIONS.OperationType;
  policy: string = Strings.ADDITIONAL_CONTRIBUTIONS.Policy;
  info: string = Strings.ADDITIONAL_CONTRIBUTIONS.Info;
  chevronRight: string = Resources.BTN_CHEVRON_RIGHT;
  maxAmount: number = Constants.MAXIMUM_OPERATION_AMOUNT;
  nextProcess: boolean = false;
  dataError: boolean = false;
  cardError: boolean = false;

  constructor(private readonly responsiveService: ResponsiveService,
    private readonly router: Router,
    private readonly enterAmountErrorService: EnterAmountErrorService,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly accountCardService: AccountCardService,
    private readonly selectAccountErrorService: SelectAccountErrorService,
    private readonly additionalContributionsService: AdditionalContributionsService,
    private readonly notifyClearFormService: NotifyClearFormService,
    private readonly insurancePolicyInfoStatusService: InsurancePolicyInfoStatusService,
    private readonly notifyRouteService: NotifyRouteService) {
      if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras.state
      && this.router.getCurrentNavigation().extras.state[Strings.ADDITIONAL_CONTRIBUTIONS.Modify] === Strings.ADDITIONAL_CONTRIBUTIONS.ParameterOk) {
        this.notifyClearFormService.clearFormData = {value: false};
        this.policy = this.additionalContributionsService.additionalContributions.policyNumber;
      } else {
        this.ngOnDestroy();
      }
    }

  ngOnInit(): void {
    if (this.insurancePolicyInfoStatusService.insurancePolicy.Poliza !== Strings.EMPTY){
      this.policy = this.insurancePolicyInfoStatusService.insurancePolicy.Poliza;
    } else {
      this.notifyRouteService.navigateToTab(null);
    }
  }

  next() {
    this.dataError = !this.validateAmount();

    if (this.accountCardService.accountCard.clabe.length <= Length.Empty){
      this.cardError = true;
      this.selectAccountErrorService.accountErrorData = { value: true };
      return;
    }
    this.cardError = false;

    if (!this.dataError && !this.cardError) {
      this.additionalContributionsService.additionalContributionsData = {
        typeContribution: this.typeContribution,
        policyNumber: this.policy
      };
      this.nextProcess = true;
      this.router.navigate([PATH.AdditionalContributionsConfirm]);
    }
  }

  private validateAmount(): boolean {
    if (this.enterAmountErrorService.currentValue.isError) { return false; }
    const userAmount: IAmount = this.notifyAmountService.amount;
    const amount = Utils.transformAmount(userAmount.amount);
    const isError = !amount;
    this.enterAmountErrorService.amountErrorData = { isError, errorMsg: Strings.GENERIC_ERRORS.Required };
    return !isError;
  }

  returnTabInsurance() {
    this.notifyRouteService.navigateToTab(Tab.Insurance);
  }

  @HostListener('blur', ['$event'])
  @HostListener('input', ['$event'])
  @HostListener('touched', ['$event'])
  resetError() {
    this.dataError = false;
  }

  @HostListener('window:popstate')
  onBack(){
    this.returnTabInsurance();
  }

  ngOnDestroy() {
    if (!this.nextProcess) {
      this.notifyAmountService.amount = { amount: Strings.EMPTY };
      this.additionalContributionsService.clearData();
      this.accountCardService.clearCardAccount();
    }
  }

}
