import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs';
import { BrokerageHouseService } from 'src/app/services/brokerage-house.services';
import { FacePassLoginService } from 'src/app/services/face-pass-login.service';
import { NotifyServicePaymentService } from 'src/app/services/notify-service-payment.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Constants, ElementsHTML } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';

@Component({
  selector: 'app-enter-security-code-serial',
  templateUrl: './enter-security-code-serial.component.html',
  styleUrls: ['./enter-security-code-serial.component.css']
})
export class EnterSecurityCodeSerialComponent implements OnInit {
  contentMargin: string = ElementsHTML.ContentMargin;

  vm$ = this.responsiveService.observe.pipe(
    tap(breakpoints =>
      breakpoints.mobile
        ? (this.contentMargin = ElementsHTML.ContentMargin)
        : (this.contentMargin = ElementsHTML.ContentMarginBreakpoints)
    ), map((breakpoints) => ({ breakpoints }))
  );

  title: string = Strings.SECURITY_CODE.TitleToken;
  subtitle: string = Strings.PAGE_HEADER.Subtitle;
  nextView: string;
  beforeView: string;
  idOperation: string;
  head: string = Strings.SECURITY_CODE.TokenHead;
  dataInternational: Array<string>;
  isAddresseeInternational: boolean | null = null;
  serialNumber: string;
  dataForm: Array<string | boolean>;

  constructor(readonly notifyServicePayment: NotifyServicePaymentService,
    private readonly modalService: ModalService,
    private readonly router: Router,
    private readonly responsiveService: ResponsiveService,
    private readonly facePassLoginService: FacePassLoginService,
    private readonly brokerageHouse: BrokerageHouseService,
    private readonly changeDetector: ChangeDetectorRef) {
    if (this.router.getCurrentNavigation()?.extras?.state) {
      this.isAddresseeInternational = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.Next];
      this.idOperation = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.IdOperation];
      if (this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.DataInternational] !== Strings.EMPTY) {
        this.dataInternational = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.DataInternational];
      } else {
        this.dataInternational = [];
      }
      if(this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.SerialNumber] !== Strings.EMPTY){
        this.serialNumber = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.SerialNumber];
      }else{
        this.serialNumber = Strings.EMPTY;
      }
      if(this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.DataForm] !== Strings.EMPTY){
        this.dataForm = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.DataForm];
      }else{
        this.dataForm = [];
      }
    } else {
      this.router.navigate([PATH.Home]);
    }
  }

  ngOnInit(): void {
    if (this.idOperation === Constants.OPERATION_ID) {
      this.nextView = PATH.RegisteredService;
      this.beforeView = this.facePassLoginService.isFacePassLogin.value ? PATH.ConfirmServiceDetails : PATH.TokenNumber;
    }else if (this.isAddresseeInternational) {
      this.nextView = PATH.CompleteInternationalAgendaRegistration;
      this.beforeView = this.facePassLoginService.isFacePassLogin.value ? PATH.ConfirmNewInternationalAgenda : PATH.TokenNumber;
    }else{
      this.beforeView = this.facePassLoginService.isFacePassLogin.value ? PATH.NewAddressee : PATH.TokenNumber;
    }

    if(this.brokerageHouse.getIsBrokerage){
      this.nextView = PATH.CompleteBrokerageHouse;
      this.beforeView = this.facePassLoginService.isFacePassLogin.value ? PATH.ConfirmBrokerageHouse : PATH.TokenNumber;
      NavigationUtils.fixNavigation(this.changeDetector, [this.facePassLoginService.isFacePassLogin.value ? PATH.ConfirmBrokerageHouse : PATH.TokenNumber, PATH.SerialNumber]);
    }
  }

  ngOnDestroy() {
    this.modalService.close();
    if(this.brokerageHouse.getIsBrokerage &&
      !(this.router.url === PATH.TokenNumber || this.router.url === PATH.ConfirmBrokerageHouse || this.router.url === PATH.CompleteBrokerageHouse)){
      this.brokerageHouse.clearAdresse();
      this.brokerageHouse.setIsBrokerage = false;
    }
  }
}
