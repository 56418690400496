import { Constants } from 'src/core/constants/Constants';
import { StringsChecks } from 'src/core/constants/ChecksStrings';

export class ChecksConstants {
  static readonly CHECK_CANCEL = {
    Controls: {
      CheckNumber: 'checkNumber',
      HeirName: 'heirName',
      reasonCancellation: 'reason'
    },
    CheckCancel: 'CheckCancel',
    CheckbookCancel: 'CheckbookCancel',
    InsuranceCheckMock: '23459875',
    IdCheckbookTab: 'checkbook',
    CheckProtection: 'CheckProtection',
    CheckbookProtection: 'CheckbookProtection'
  };
  static readonly CHECK_PROTECTION = {
    Check: 'check',
    Controls: {
      NumCheckbook: 'numCheckbook'
    },
    MockNumCheckbook: '1-50'
  };
  static readonly MAX_DATE_CHECK_OTHER_BANK = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + Constants.MONTH.ONE,
    day: new Date().getDate()
  };
  static readonly NAME_VOUCHER = 'SolicitudChequera';
  static readonly ACCOUNTS_CHECKBOOK_VALIDATION = ['BLACK','CHEQ4100','CT','CTS','CTC','DORADA','EFE',
  'EFEAF','EFECB','EFED','EFEINB','EFEN','NOM','NWM','CTMAX'];
  static readonly CHECKBOOK = 'Chequera';
  static readonly SHOW_CHECKS = 'showCheckBook';

  //Los keys deben ir lowercase por que se validan para colocar la opcion en el select de cheques en móvil
  static readonly MOBILE_SELECT_OTPIONS = {
    application: StringsChecks.checkbookInquiry,
    consult: StringsChecks.checkInquiry,
    activation: StringsChecks.activation
  };

  static readonly LIMIT_AMOUNT = {
    MinAmount: 1,
    MaxAmount: 99999.99
  };

  static readonly CAPTURE_IMAGE_NAME = 'ProtecciónCheques';
  static readonly CLASS_SCREENSHOT = 'successful-operation';
  static readonly INPUT_BENEFICIARY_NAME = 'beneficiaryName';
  static readonly MAX_AMOUNT_MESSAGE ='El monto debe ser menor a $100,000.00';
}

export enum ElementsChecksHTML {
  BasicContentDesktopMargin = 'mb-4 mx-3',
  ContentMargin = 'mb-5 mx-4'
}
