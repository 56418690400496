<ng-container *ngIf="vm$ | async as vm">
  <section class="px-3 pt-3 pb-4">
    <div class="d-flex justify-content-end">
      <modal-close [image]="frameClose" [clickFn]="accountManagmentModal.bind(this)"></modal-close>
    </div>
    <header class="header-m py-3">Tarjetas asociadas</header>
    <app-search-component (dataFromSearch)="searchTerm($event)"></app-search-component>
    <main>
      <ng-container *ngFor="let option of (cards$ | async) ">
        <ng-container
          [ngTemplateOutlet]="Information"
          [ngTemplateOutletContext]="{information:option}"></ng-container>
      </ng-container>
    </main>
    <footer>
      <app-pagination></app-pagination>
    </footer>
  </section>
</ng-container>

<ng-template #Information let-information="information">
  <section class="information py-2 px-3">
    <div class="d-flex flex-column">
      <span class="detail-m-highlight">Número de tarjeta</span>
      <span class="detail-m">{{ information | numberFormat: 4 }}</span>
    </div>
    <img
      class="align-self-center"
      [src]="copyIcon | cdnImage"
      alt="Copiar" width="24" height="24"
      [cdkCopyToClipboard]="information">
  </section>
  <hr class="my-0 pb-2">
</ng-template>
