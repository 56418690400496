import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/shared/modal.service';
import { Char, Constants, InputTypes, Numbers, ScrollOptions } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { Resources } from 'src/core/constants/Resources';
import { environment } from 'src/environments/environment';
import { PATH } from 'src/core/constants/Path';
import { PayrollCreditConstants } from 'src/core/constants/PayrollCreditConstants';
import { StorageService } from 'src/app/services/storage.service';
import { AccountCardService } from 'src/app/services/account-card.service';
import { PayrollCreditModel } from 'src/app/interface/PayrollCreditModel';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalClasses, ModalConstants } from 'src/core/constants/ModalConstants';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { tap } from 'rxjs';
import { ClassHTML, HTMLConstants } from 'src/core/constants/HTMLConstants';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';
import { ModalOptions } from 'src/app/interface/modal-options';
import { AppMethod } from 'src/core/constants/AppConstants';
import { AppBridge } from 'src/core/utils/AppBridge';
import { AppUtils } from 'src/core/utils/AppUtils';

@Component({
  selector: 'app-pwa-modal-payroll-credit',
  templateUrl: './modal-pwa-payroll-credit.component.html',
  styleUrls: ['./modal-pwa-payroll-credit.component.css']
})
export class ModalPwaPayrollCreditComponent implements OnInit {
  @ViewChild('frame') frame: ElementRef;
  urlIframe: string = Strings.EMPTY;
  modalData: { transactionType: string };
  errorTitleMessagePWA: string = Strings.MODAL_EXCEPTIONS_POINTS.Service.Header;
  isToken: boolean = false;
  isCloseAlertToken: boolean = false;
  iFrameClass: string = PayrollCreditConstants.ClassElements.PayrollAdvanceFrame;
  isProcessSuccessPwa: boolean = false;
  iFrameLoad: boolean = false;
  iFrameLoadingInit: boolean = false;
  errorMessagePWA: string = Strings.SERVICES.UnexpectedMsg;
  homePage: string = PATH.Home;
  modalInstance: NgbModalRef;
  navigationPage: string = PayrollCreditConstants.NAME_OPTION.PageHomeIFrame;
  ipHostPWA = environment.hostPayrollCredit;
  formatResolution: Strings = Strings.EMPTY;
  isErrorCapture: boolean = false;
  closeIcon: string = Resources.CLOSE_CIRCLE_BLUE;
  isApp = AppUtils.platform.isApp;

  @ViewChild('modalToken', { static: true }) modalToken: ElementRef;
  modalHelpToken: NgbModalRef;

  vm$ = this.responsiveService.observe.pipe(
    tap((breakpoints) => {
      this.formatResolution = (breakpoints.desktop) ? PayrollCreditConstants.NAME_RESOLUTION.desktop
        : (breakpoints.mobile) ? PayrollCreditConstants.NAME_RESOLUTION.mobile : PayrollCreditConstants.NAME_RESOLUTION.tablet;
    }),
  );

  constructor(
    private readonly modalService: ModalService,
    private readonly storage: StorageService,
    private readonly accountCardService: AccountCardService,
    private readonly alertService: AlertService,
    private readonly loaderService: LoaderService,
    private readonly accountInfoService: AccountInfoStatusService,
    private readonly responsiveService: ResponsiveService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    if (this.modalData?.transactionType === PayrollCreditConstants.PAYROLL_CREDIT_ID) {
      this.urlIframe = `${environment.hostPayrollCredit}${PATH.PayrollCredit}`;
    } else {
      this.urlIframe = `${environment.hostPayrollCredit}${PATH.PayrollCreditAdvance}`;
    }
    if (AppUtils.platform.iOS && AppUtils.platform.isApp) {
      document.getElementById(HTMLConstants.ID_ELEMENTS.ElementWrapper).classList.add(ClassHTML.NoScrollModal);
    }
  }

  ngAfterViewInit() {
    const sendData: PayrollCreditModel = {
      accountInfo: {
        accountNumber: this.accountCardService.selectedAccountCard.cardNumber === Strings.EMPTY ?
          this.accountInfoService.account.numeroCuenta : this.accountCardService.selectedAccountCard.accountNumber,
        productId: this.accountCardService.selectedAccountCard.productId === Numbers.Zero ?
          this.accountInfoService.account.idCuentaProducto : this.accountCardService.selectedAccountCard.productId,
        renewal: false
      },
      userInfo: {
        session: this.storage.getSession(),
        jwt: this.storage.getSession(true),
        personId: this.storage.getUser().personId
      },
      config: {
        MedioAcceso: Constants.ACCESS_METHOD_PORTAL,
        isApp: this.isApp
      }
    };
    this.frame.nativeElement.onload = () => {
      this.frame.nativeElement.contentWindow?.postMessage({ type: PayrollCreditConstants.MESSAGE_NAME, data: sendData }, environment.hostPayrollCredit);
      this.iFrameLoad = true;
    };

    setTimeout(() => {
      if (!this.iFrameLoadingInit) {
        this.loaderService.hideLoader();
        this.modalInstance.update({ modalDialogClass: ModalConstants.MODAL_OPTIONS.PwaModal });
      }
    }, PayrollCreditConstants.PAYROLL_TIME_AWAIT);
  }

  close() {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    if(AppUtils.platform.isApp){
      AppBridge.invoke<boolean>(AppMethod.GetToken, Constants.FALSE);
    }

    if (AppUtils.platform.iOS && AppUtils.platform.isApp) {
      document.getElementById(HTMLConstants.ID_ELEMENTS.ElementWrapper).classList.remove(ClassHTML.NoScrollModal);
    }
    this.close();
  }

  onlyClose() {
    AppBridge.invoke<boolean>(AppMethod.GetToken, { Data: false });
    if (this.navigationPage === PayrollCreditConstants.NAME_OPTION.PageHomeIFrame || this.navigationPage === PayrollCreditConstants.NAME_OPTION.PayrollConfirm ||
      this.navigationPage === PayrollCreditConstants.NAME_OPTION.PayrollCreditConfirm) {
      this.close();
    } else {
      this.frame.nativeElement.contentWindow.postMessage({ type: PayrollCreditConstants.MESSAGE_TYPE.BackPage }, environment.hostPayrollCredit);
    }
  }

  alertError(error) {
    this.alertService.showPopupAlert({
      message: error ? error : Strings.SERVICES.UnexpectedMsg,
      header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
      btnLabel: Strings.MSG_POPUP_ACCEPT,
      imgHead: Resources.CLOSE_ICON_RED,
      btnExit: false
    }, {
      onSuccess: () => this.isErrorCapture ? this.updateModalDisplay(InputTypes.Block, false) : this.close()
    });
  }

  updateModalDisplay(display: string, add: boolean) {
    const container: HTMLElement = document.querySelector(`${Char.Dot}${PayrollCreditConstants.ClassElements.ModalContent}`);
    NavigationUtils.updateModalDisplay(container, display, HTMLConstants.MODAL_OPTIONS.HiddenModalUtils, add, false);
  }

  eventModalDisplay(event) {
    if (event.data.type === PayrollCreditConstants.MESSAGE_TYPE.ErrorCapture) {
      this.isErrorCapture = true;
      this.updateModalDisplay(InputTypes.None, true);
      this.alertError(event.data.data);
    } else if (event.data.type === PayrollCreditConstants.MESSAGE_TYPE.validAmount) {
      this.updateModalDisplay(InputTypes.Block, false);
    } else if (event.data.type.toString() === PayrollCreditConstants.MESSAGE_TYPE.validatingAmount.toString()) {
      this.updateModalDisplay(InputTypes.None, true);
    } else if (event.data.type === PayrollCreditConstants.MESSAGE_TYPE.showModalHelpToken) {
      this.updateModalDisplay(InputTypes.None, true);
      this.showModalHelpToken();
    }
  }

  eventCloseModal(event) {
    if (event.data.type === PayrollCreditConstants.MESSAGE_TYPE.CloseModal) {
      this.close();
    } else if (event.data.type === PayrollCreditConstants.MESSAGE_TYPE.NavigateHome) {
      this.close();
      this.router.navigate([PATH.Home]);
    } else if (event.data.type === PayrollCreditConstants.MESSAGE_TYPE.Error) {
      this.close();
      this.loaderService.hideLoader();
      this.alertError(event.data.data);
    } else if (event.data.type.toString() === PayrollCreditConstants.MESSAGE_TYPE.IFrameLoadingInit.toString()) {
      this.iFrameLoadingInit = event.data.data;
      this.errorMessagePWA = (this.iFrameLoad && this.iFrameLoadingInit) ? Strings.EMPTY : Strings.ERROR_SERVICE_GENERIC;
    } else if (event.data.type === PayrollCreditConstants.MESSAGE_TYPE.PageNavigation) {
      this.navigationPage = event.data.data;
    } else {
      this.eventModalDisplay(event);
    }
  }

  closeOption(event) {
    this.close();
  }

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    this.isErrorCapture = false;
    if (event.origin === environment.hostPayrollCredit) {
      if (event.data.type === PayrollCreditConstants.MESSAGE_TYPE.ShowSpinner) {
        this.loaderService.showLoader();
      } else if (event.data.type === PayrollCreditConstants.MESSAGE_TYPE.HideSpinner) {
        this.loaderService.hideLoader();
      } else if (event.data.type === PayrollCreditConstants.MESSAGE_TYPE.GetToken) {
        if (AppUtils.platform.isApp) {
          AppBridge.invoke<boolean>(AppMethod.GetToken, String(event.data.data));
        }
      } else if (event.data.type === PayrollCreditConstants.MESSAGE_TYPE.iFrameClass) {
        this.validateIFrameClass(event.data.data);
      } else if (event.data.type === PayrollCreditConstants.MESSAGE_TYPE.IsProcessSuccessPwa) {
        this.isProcessSuccessPwa = true;
      } else if (event.data.type === PayrollCreditConstants.MESSAGE_TYPE.DataLoadPWA) {
        this.classDefaultMOdal();
      } else if (event.data.type === PayrollCreditConstants.MESSAGE_TYPE.GenericBridgeOnMessage) {
        this.sendDataBridge(event.data);
      } else {
        this.eventCloseModal(event);
      }
    }
  }

  async sendDataBridge(data) {
    if (AppUtils.platform.isApp) {
      await AppBridge.invoke<string>(data.NameBridge, data.paramsNavigate);
    }
  }

  classDefaultMOdal() {
    const styleModal = (AppUtils.platform.isApp) ? ModalConstants.MODAL_OPTIONS.PwaModalApp : ModalConstants.MODAL_OPTIONS.PwaModal;
    this.modalInstance.update({
      modalDialogClass: styleModal
    });
  }

  validateIFrameClass(paramPwa) {
    if (this.iFrameClass !== paramPwa) {
      if (!this.iFrameClass.includes(paramPwa) && !paramPwa.includes(this.iFrameClass)) {
        document.querySelector(ModalClasses.Modal).scrollTo({ top: Numbers.Zero, behavior: ScrollOptions.Smooth });
      }
      if (paramPwa === PayrollCreditConstants.HTML_CLASS.payrollTermsClass && AppUtils.platform.isApp) {
        paramPwa = PayrollCreditConstants.HTML_CLASS.payrollTermsAappClass;
      }
      if(paramPwa===PayrollCreditConstants.HTML_CLASS.clauses && AppUtils.platform.isApp){
        paramPwa = PayrollCreditConstants.HTML_CLASS.clausesAppClass;
      }
      this.iFrameClass = paramPwa;
    }
    if (this.iFrameClass === PayrollCreditConstants.ClassElements.PayrollCreditCompleteFrame || this.iFrameClass === PayrollCreditConstants.NAME_OPTION.PayrollConfirm) {
      this.frame.nativeElement.contentWindow.postMessage({ type: PayrollCreditConstants.PORTAL_RESOLUTION, data: this.formatResolution }, environment.hostPayrollCredit);
    }
  }

  showModalHelpToken() {
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalHelpToken
    };
    this.modalHelpToken = this.modalService.open(this.modalToken, modalOptions);
    const container = document.getElementById(HTMLConstants.ID_MODAL.ModalTokenSecurity);
    NavigationUtils.navigateAndEnsureSingleModalDisplay(container, this.modalHelpToken, false);
  }

  closedModal() {
    this.updateModalDisplay(InputTypes.Block, false);
  }
}
