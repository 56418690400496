import { Injectable } from '@angular/core';
import { IInsurancePolicyInfo } from 'src/app/interface/IInsurancePolicyInfo';
import { InsurancePolicyInfoRequest } from 'src/app/interface/dto/InsurancePolicyInfoRequest';
import { InsurancePolicyInfoResponse } from 'src/app/interface/dto/InsurancePolicyInfoResponse';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { InsurancePolicyInfoStatusService } from 'src/app/services/insurance-policy-info-status.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { Char, Constants } from 'src/core/constants/Constants';
import { AppBridge } from 'src/core/utils/AppBridge';
import { AppUtils } from 'src/core/utils/AppUtils';

@Injectable({
  providedIn: 'root'
})
export class PolicyConditionsService {

  constructor(
    private readonly storage: StorageService,
    private readonly stateIInsurancePolicyPlInfo: InsurancePolicyInfoStatusService,
    private readonly baseService: IBaseService,
  ) { }

  async openPolicyConditions() {
    const currentPolicy: IInsurancePolicyInfo = this.stateIInsurancePolicyPlInfo.insurancePolicy;
    const request = new InsurancePolicyInfoRequest({
      IdSession: this.storage.getSession(),
      Carpeta: currentPolicy.Carpeta,
      Cis: currentPolicy.Cis,
      Emisor: currentPolicy.Emisor,
      Ramo: currentPolicy.Ramo
    });
    const response = await this.baseService.genericPost<InsurancePolicyInfoResponse>(request, { isSOA: true });
    if(AppUtils.platform.isApp){
      AppBridge.invoke(AppMethod.SendNativeScreen,{Data: AppScreen.pwaShowAviso,DataParams:response.Ruta});
    }else{
      const anchor: HTMLElement = document.createElement(Constants.ANCHOR);
      const filePath = (response?.Ruta).toString();
      (anchor as HTMLAnchorElement).href = filePath;
      (anchor as HTMLAnchorElement).target = Constants.ANCHOR_TARGET;
      (anchor as HTMLAnchorElement).download = filePath.substring(filePath.lastIndexOf(Char.Slash) + Constants.DOWNLOAD_INDEX);
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
  }
}
