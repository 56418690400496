import { SessionManagerService } from 'src/app/services/session-manager.service';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostListener,
  OnDestroy,
  ElementRef,
  ViewChild,
  Renderer2
} from '@angular/core';
import { NotifyRouteService } from 'src/app/services/notify-route.service';
import { combineLatestWith, filter, map, Observable, SubscriptionLike } from 'rxjs';
import { ISimpleValue } from 'src/app/interface/ISimpleValue';
import { NavigationEnd, Router } from '@angular/router';
import { BreakpointState, ResponsiveService } from 'src/app/shared/responsive.service';
import { ConfigService } from 'src/app/services/config.service';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalLogoutComponent } from 'src/app/shared/components/modal-logout/modal-logout.component';
import { SidebarResponsiveService } from 'src/app/services/sidebar-responsive.service';
import { ServiceTransferIdService } from 'src/app/services/service-transfer-id.service';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { GmmInfoService } from 'src/app/services/gmm-info.service';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { Browser, Char, Constants, ElementsHTML, Numbers } from 'src/core/constants/Constants';
import { SidebarService } from 'src/app/shared/sidebar.service';
import { ModalTechnicalRequirements } from 'src/app/component/modal-technical-requirements/modal-technical-requirements.component';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { PATH } from 'src/core/constants/Path';
import { InsurancePolicies } from 'src/app/services/insurance-policies-status.service';
import { PortabilityService } from 'src/app/services/portability.service';
import { environment } from 'src/environments/environment';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { StateQrService } from 'src/app/services/state-qr.service';
import { PatrimonialStatusService } from 'src/app/services/patrimonial-status.service';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { FileUtils } from 'src/core/utils/FileUtils';
import { PayrollDataService } from 'src/app/services/payroll-data.service';
import { ExpressInsuranceFrameResizer } from 'src/app/services/express-insurance-frame-resizer.service';
import { InsurancePlanConstants } from 'src/core/constants/InsurancePlanConstants';
import { ScheduledTransferService } from 'src/app/services/scheduled-transfer.service';
import { PathModelLegacy } from 'src/app/models/PathModel';
import { HomeOptionStrings } from 'src/core/constants/HomeOptionStrings';
import { AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { AppBridge } from 'src/core/utils/AppBridge';
import { PermittedOperations } from 'src/app/services/permitted-operations.service';
import { AppUtils } from 'src/core/utils/AppUtils';
import { StylesConstants } from 'src/core/constants/StylesConstants';
import { IconResources } from 'src/core/constants/IconResources';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild('Sidebar', { static: false }) Sidebar: ElementRef;
  showCardPromotional: string = Strings.EMPTY;
  inbursaOriginal: string = Resources.INBURSA;
  mobile: string = Resources.MOBILE_WHITE;
  phoneVoice: string = Resources.PHONE_WHITE;
  lock: string = Resources.LOCK_WHITE;
  monitorIcon: string = Resources.WHITE_MONITOR;
  locationIcon: string = Resources.LOCATION_WHITE;
  closeIcon: string = Resources.CROSS_WHITE;
  logoSidebar: string = Resources.LOGO_SIDEBAR;
  gridFourIcon: string = Resources.GRID_FOUR_WHITE;
  coinDollar: string = Resources.DOLLAR_WHITE;
  roadPortability: string = Resources.WHITE_ARROW_LEFT;
  shoppingBasket: string = Resources.SHOPPING_BASKET_WHITE;
  bankCardLockAllWhite: string = IconResources.BANK_CARD_LOCKED_ALL_WHITE;
  boxLeft: string = Resources.DOCUMENT_WHITE;
  shoppingTagw: string = Resources.SHOPPING_TAG_WHITE;
  fileEditIcon: string = Resources.FILE_EDIT_WHITE;
  logout: string = Resources.LOGOUT;
  removeIcon: string = Resources.REMOVE_CR_FR;
  imgCardPayroll: string = Resources.SIDEBAR_PROMO;
  isContract: boolean = false;

  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(
      this.sidebarResponsiveService.stateObservable,
      this.sidebarService.stateObservable,
      this.portabilityService.showPortability,
      this.loyaltyService.isAvailable$,
      this.payrollDataService.payrollAdvanceData$,
      this.payrollDataService.payrollCreditData$,
      this.permittedOperations.operation$
    ),
    map(([breakpoints, show, sideBar, showPortability, showLoyalty, payrollAdvanceData, payrollCreditData, permittedOperations]) => {
      setTimeout(() => {
        const heightSidebar = this.Sidebar?.nativeElement.offsetHeight;
        this.expressInsuranceFrameResizer.heightProductsExpress = {
          height: heightSidebar - InsurancePlanConstants.FIFTY
        };
      });
      const homeDimo = (!environment.hiddenComponents.homeDimo && permittedOperations.dimo);
      const homeCodi = (!environment.hiddenComponents.homeCodi && permittedOperations.codi);

      return {
        breakpoints,
        show,
        sideBar,
        showPortability,
        showLoyalty,
        payrollAdvanceData,
        payrollCreditData,
        homeDimo,
        homeCodi
      };
    })
  );

  imgShop: string = Resources.SHOP;

  switchRoute$: Observable<ISimpleValue>;
  link: string = Constants.EXTERNAL_LINKS.App;

  breakpoints$: Observable<BreakpointState> = this.responsiveService.observe;
  version: { [key: string]: string } | PathModelLegacy;
  versionNumber: string = Strings.EMPTY;
  environment: string = Strings.EMPTY;
  currentRoute: string | null;
  tooltipText: string | null = null;
  linkSecurityTips: string = Constants.EXTERNAL_LINKS.SecurityTips;
  hiringPath: string = PATH.HireService;
  loyaltyPointsPath: string = PATH.LoyaltyPoints;
  clarificationPath: string = PATH.Clarification;
  transfersPath: string = PATH.Transfers;
  loginPath: string = PATH.Login;
  homePath: string = PATH.Home;
  addPortabilityPath: string = PATH.AddPortability;
  servicesPath: string = PATH.Services;
  controlPath: string = PATH.Control;
  programmedOperationsPath: string = PATH.ProgrammedOperations;
  isPaymentPolicies: boolean = false;
  hiddenProgrammedOperations: boolean = environment.hiddenComponents.programmedOperations;
  hiddenClarifications: boolean = environment.hiddenComponents.clarifications;
  showHiring: boolean = (!environment.hiddenComponents.payrollAdvance || !environment.hiddenComponents.payrollCredit);
  expressPwa: boolean = !environment.hiddenComponents.expressLifePwa;
  policiesSubscription: SubscriptionLike;
  beta: boolean = environment.beta;
  codiSelectCardPage: string = AppScreen.CoDiSelectCardPage;
  dimoValidation: string = AppScreen.DiMoValidation;
  codiStringLabel: string = HomeOptionStrings.LABEL_OPTION_CODI;
  dimoStringLabel: string = HomeOptionStrings.LABEL_OPTION_DIMO;
  isApp: boolean = AppUtils.platform.isApp;
  constructor(
    private readonly sessionManager: SessionManagerService,
    private readonly responsiveService: ResponsiveService,
    private readonly configService: ConfigService,
    private readonly modalService: ModalService,
    private readonly sidebarResponsiveService: SidebarResponsiveService,
    private readonly portabilityService: PortabilityService,
    private readonly transferIdService: ServiceTransferIdService,
    private readonly statePayIdService: StateServicePayIdService,
    private readonly gmmInfoService: GmmInfoService,
    private readonly accountInfoService: AccountInfoStatusService,
    private readonly notifyRouteService: NotifyRouteService,
    private readonly sidebarService: SidebarService,
    private readonly insurancePolicies: InsurancePolicies,
    private readonly loyaltyService: LoyaltyService,
    private readonly stateQrService: StateQrService,
    private readonly patrimonialService: PatrimonialStatusService,
    private readonly router: Router,
    private readonly payrollDataService: PayrollDataService,
    private readonly expressInsuranceFrameResizer: ExpressInsuranceFrameResizer,
    private readonly scheduledTransferService: ScheduledTransferService,
    private readonly permittedOperations: PermittedOperations,
    private readonly renderer: Renderer2
  ) {
    this.switchRoute$ = notifyRouteService.switchRouteObservable;
    this.policiesSubscription = this.insurancePolicies.policiesSelected$.subscribe((data) =>{
      this.isPaymentPolicies = data?.SelectedPolicies?.length > Numbers.Zero;
    });
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event: NavigationEnd ) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const actualRoute = event.url;
        this.currentRoute = actualRoute;
        this.notifyRouteService.switchRouteData = {
          value:
            actualRoute.includes(PATH.Login) || actualRoute === Char.Slash
        };
        if(actualRoute === PATH.HireService || actualRoute === PATH.InsuranceExpress){
          this.frameHeight();
        }else{
          this.isContract= false;
        }
      });
    this.version = this.configService.getConfig();
    this.versionNumber = this.version.Version;
    this.environment = this.version.Environment;
    this.getBrowser();
  }

  async publicBridge(dataSend) {
    await AppBridge.invoke<string>(AppMethod.SendNativeScreen, { Data: dataSend });
  }

  hidePromotionalCard() {
    this.showCardPromotional = ElementsHTML.Hidden;
  }

  clearForm() {
    this.isContract = false;
    this.sidebarService.clearData(this.currentRoute);
    this.scheduledTransferService.clearScheduledTransfer();
  }

  clearFormConrtract() {
    this.sidebarService.clearData(this.currentRoute);
  }

  frameHeight(){
    this.isContract = true;
    setTimeout(() => {
      const heightSidebar = this.Sidebar?.nativeElement.offsetHeight;
      this.expressInsuranceFrameResizer.heightProductsExpress ={ height: heightSidebar};
    });
  }

  onCloseSession() {
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeMd,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.SessionCloseModal
    };
    const ref  = this.modalService
      .open(ModalLogoutComponent, modalOptions);
      ref.componentInstance.inactivity = false;
      ref.closed.subscribe(
        answer => this.onUserResponseSession(answer)
      );
  }
  async onUserResponseSession(answer: string) {
    if (answer) {
      await this.sessionManager.closeSession({ redirect: true });
      this.accountInfoService.clearAccount();
    }
  }

  close() {
    this.sidebarResponsiveService.stateData = false;
    this.renderer.removeStyle(document.body, StylesConstants.OVERFLOW);
  }

  reset() {
    this.transferIdService.stateTransferIdData = { id: Strings.EMPTY };
    this.statePayIdService.service = { id: Strings.EMPTY };
    this.gmmInfoService.medicalExpenses = {};
    if (this.currentRoute !== PATH.Portability) {
      this.portabilityService.clearPortabilityForm();
    }
  }

  getBrowser() {
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent.indexOf(Browser.Firefox) > Constants.NOT_FOUND_ITEM) {
      this.versionNumber = Strings.EMPTY;
      this.environment = Strings.EMPTY;
    }
  }
  @HostListener('window:resize', ['$event'])
  isTabletMobile() {
    this.close();
  }

  getPath() {
    this.sidebarService.cleanOperation(this.router.url);
  }

  goHome() {
    this.close();
    this.getPath();
    this.router.navigate([PATH.Home]);
  }

  validateShow(show: boolean, text: string, sideBar: boolean) {
    this.tooltipText = show && sideBar ? text : null;
  }
  async onShowRequirements() {
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeMd,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.TechnicalRequirements
    };
    this.modalService.open(ModalTechnicalRequirements, modalOptions);
  }

  ngOnDestroy() {
    this.policiesSubscription?.unsubscribe();
  }

  clearQR(){
    this.stateQrService.stateQrData = { value: false };
  }

  resetPatrimonialFutureTransaction(){
    this.patrimonialService.isFutureTransaction = false;
  }

  showGeolocationManual(breakpoints: BreakpointState) {
    window.open(FileUtils.showGeolocationManual(breakpoints));
  }

  clearframe() {
    this.isContract = false;
  }

  preventClose(event: Event){
    event.stopPropagation();
  }
}
