import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { IPayrollData, IselectData, IselectInfoData } from 'src/app/interface/IPayrollData';
import { ISimpleValue } from 'src/app/interface/ISimpleValue';
import { StorageService } from 'src/app/services/storage.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { TPayrollCreditAdvanceConsultResponse } from 'src/app/interface/dto/PayrollCreditAdvanceConsultResponse';
import { PayrollCreditAdvanceConsultRequest } from 'src/app/interface/dto/PayrollCreditAdvanceConsultRequest';
import { BalancesPayrollCreditRequest } from 'src/app/interface/dto/BalancesPayrollCreditRequest';
import { Constants } from 'src/core/constants/Constants';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { BalancesPayrollCredit } from 'src/app/interface/BalancesPayrollCredit';
import { DataPayrollModel } from 'src/app/interface/DataPayrollCreditModel';
import { Numbers } from 'src/core/constants/Numbers';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
  providedIn: 'root'
})

export class PayrollDataService {
  private readonly detailPayroll: BehaviorSubject<IPayrollData> = new BehaviorSubject<IPayrollData>(
    {
      account: Numbers.Zero,
      name : Strings.EMPTY,
      img : Strings.EMPTY
    });

  private readonly limitData: BehaviorSubject<IselectData> = new BehaviorSubject<IselectData>(
    {
      creditLimit : Strings.EMPTY
    });

  private readonly generalData: BehaviorSubject<IselectInfoData> = new BehaviorSubject<IselectInfoData>(
    {
      totalAmount : Strings.EMPTY,
      selectMonths : Strings.EMPTY,
      cat : Strings.EMPTY,
      rate : Strings.EMPTY,
      limitSelected : Strings.EMPTY
    });

  private readonly modifyDataPayroll: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly isProcessPayroll: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly _payrollCreditData: BehaviorSubject<DataPayrollModel> = new BehaviorSubject<DataPayrollModel>({payrollAccountExist: false, payrollExist: false});
  private readonly _payrollAdvanceData: BehaviorSubject<DataPayrollModel> = new BehaviorSubject<DataPayrollModel>({payrollAccountExist: false, payrollExist: false});
  private readonly _isPayrollCreditError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private readonly storage: StorageService,
    private readonly baseService: IBaseService
  ) { }

  public async payrollCreditAdvanceConsult(typeConsult: string) {
    const request = new PayrollCreditAdvanceConsultRequest({
      IdSession: this.storage.getSession(),
      TipoConsulta: typeConsult
    });
    return this.baseService.genericPost<TPayrollCreditAdvanceConsultResponse>(request, { isSOA: true });
  }

  get detailPayrollObservable(): Observable<IPayrollData> {
    return this.detailPayroll.asObservable();
  }

  get detailPayrollBS(): BehaviorSubject<IPayrollData> {
    return this.detailPayroll;
  }

  set detailPayrollData(data: IPayrollData){
    this.detailPayroll.next(data);
  }

  get detailDataPayrollObservable(): Observable<IselectData> {
    return this.limitData.asObservable();
  }

  get detailDataPayrollBS(): BehaviorSubject<IselectData> {
    return this.limitData;
  }

  set detailDataPayrollData(data: IselectData){
    this.limitData.next(data);
  }

  get detailGeneralPayrollObservable(): Observable<IselectInfoData> {
    return this.generalData.asObservable();
  }

  get detailGeneralDataPayrollBS(): BehaviorSubject<IselectInfoData> {
    return this.generalData;
  }

  set detailGeneralDataPayrollData(data: IselectInfoData){
    this.generalData.next(data);
  }

  get viewModifyObservable(): Observable<ISimpleValue> {
    return this.modifyDataPayroll.asObservable();
  }

  set goModifyData(data: ISimpleValue) {
    this.modifyDataPayroll.next(data);
  }

  get isProcessPayrollObservable(): Observable<ISimpleValue> {
    return this.isProcessPayroll.asObservable();
  }

  set isProcessPayrollData(data: ISimpleValue) {
    this.isProcessPayroll.next(data);
  }

  public get payrollCreditData$(): Observable<DataPayrollModel> {
    return this._payrollCreditData.asObservable();
  }

  public set payrollCreditData(data: DataPayrollModel) {
    this._payrollCreditData.next(data);
  }

  public get payrollCreditData() {
    return this._payrollCreditData.getValue();
  }

  public get payrollAdvanceData$(): Observable<DataPayrollModel> {
    return this._payrollAdvanceData.asObservable();
  }

  public set payrollAdvanceData(data: DataPayrollModel) {
    this._payrollAdvanceData.next(data);
  }

  public get payrollAdvanceData() {
    return this._payrollAdvanceData.getValue();
  }

  get isPayrollCreditError$(): Observable<boolean> {
    return this._isPayrollCreditError.asObservable();
  }

  set isPayrollCreditError(data: boolean) {
    this._isPayrollCreditError.next(data);
  }

  get isPayrollCreditError() {
    return this._isPayrollCreditError.getValue();
  }

  public getBalances(idAccountProduct: number, account: string): Promise<GenericResponse<BalancesPayrollCredit>> {

    const request: BalancesPayrollCreditRequest = new BalancesPayrollCreditRequest({
      idCuentaProducto: idAccountProduct,
      cuenta: account,
      origen: Constants.ACCESS_METHOD_PORTAL
    });

    return this.baseService.genericPost<GenericResponse<BalancesPayrollCredit>>(request, {addSession: true, isSecure: true});
  }

}
