<ng-container *ngIf="breakpoints$ | async as breakpoints">
  <div class="d-flex flex-column gap-3">
    <ng-container *ngIf="!isInternationalTransfer && !isCreditModal && !timeToApply && !isFundsSale && !isFundsBuys">
      <div class="detail-m text-align-justify">
        {{ getConditions(timeToApply)}}
      </div>
      <span class="detail-m">Comisión: {{ fee | currency }}</span>
    </ng-container>
    <div *ngIf="isInternationalTransfer">
      <div *ngIf="fromInternationalTransfer && !warranty">
        <ng-container>
          <div class="detail-m">Servicio estándar comisión $15 USD IVA incluido.</div>
        </ng-container>
      </div>
      <div *ngIf="fromInternationalTransfer && warranty">
        <ng-container>
          <div class="detail-m">Contrata Garantía de Pago por $35 USD IVA incluido.</div>
        </ng-container>
      </div>
      <div *ngIf="!fromInternationalTransfer">
        <div class="row">
          <div class="col-2 check-align">
            <div class="col-2 d-flex align-items-center ml-fee-info">
              <label class="radio-container" for="radio1">
                <input type="radio" [checked]="noWarrantyFlag" name="radio" id="radio1" value="noWarranty"
                  [ngModel]="warrantySave" #noWarranty (click)="warrantyTransferInternational(noWarranty.value)">
                <span class="checkmark"></span>
              </label>

            </div>
          </div>
          <div class="col-10">
            <div class="detail-s-highlight text-secondary-800">
              <strong>Servicio estándar comisión $15 USD IVA incluido.</strong><br>
              Banco Inbursa no garantiza el pago completo del importe ordenado hasta el banco beneficiario.
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-2 check-align">
            <div class="col-2 d-flex align-items-center ml-fee-info">
              <label class="radio-container" for="radio2">
                <input [checked]="warrantyFlag ? true:false" id="radio2" type="radio" name="radio" value="warranty"
                  [ngModel]="warrantySave" #warranty (click)="warrantyTransferInternational(warranty.value)">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="col-10">
            <div class="detail-s-highlight text-secondary-800">
              <span>
                <strong class="text-info-800">Contrata Garantía de Pago por $35 USD IVA incluido.</strong>
              </span><br>
              <span>
                Banco Inbursa garantiza el pago del importe total ordenado al banco beneficiario.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isCreditModal">
    <div id="condition-one" class="pb-1">
      <label for="conditions" class="radio-container">
        <input type="radio" id="conditions" name="conditions" value="conditions" #conditions
          (click)="validateSelection('conditions')" [checked]="modify || checkConditions ? true : false">
        <span id="span-condition" class="checkmark"></span>
      </label>
      <div id="mobile-div" class="col-12">
        <div class="detail-s-highlight label-alert">
          <a (click)="payrollConditions(conditions.value)" class="blue-light a-mobile">
            Acepto las condiciones y cláusulas del contrato de crédito en tu nómina</a>
        </div>
        <span *ngIf="alertCondition" class="label-error-account label-alert a-alert a-alert-mobile">
          Para continuar, necesitas leer y aceptar las condiciones, seleccionando la opción
          correspondiente en el formulario.</span>
      </div>
    </div>
    <div id="condition-two">
      <label for="credit-bureau" class="radio-container">
        <input type="radio" id="credit-bureau" name="consult" value="consult" #consult
          (click)="validateSelection('consult')" [checked]="modify || checkConsult ? true : false">
        <span class="checkmark"></span>
      </label>
      <div id="mobile-div" class="col-12">
        <div class="detail-s-highlight label-alert">
          <a (click)="payrollConditions(consult.value)" class="blue-light a-mobile">
            He leído y autorizo la consulta en Buró de crédito</a>
        </div>
        <span *ngIf="alertConsult" class="label-error-account label-alert a-alert a-alert-mobile">
          Para continuar, necesitas leer y aceptar las condiciones,
          seleccionando la opción correspondiente en el formulario.</span>
      </div>
    </div>
  </div>
  <div *ngIf="timeToApply" class="limit-conditions">
    <div class="detail-m" [ngClass]="classLabel">{{getConditions(timeToApply)}}</div>
    <span class="detail-m" [ngClass]="classLabel">Comisión: {{ fee | currency }}</span>
  </div>
</ng-container>
<ng-template #modalConditions>
  <app-modal-credit-bureau-inquiry (sendModalAnswer)="generalAnswer($event)" [conditionsType]="viewModal"
    class="w-100"></app-modal-credit-bureau-inquiry>
</ng-template>
