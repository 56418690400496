import { Injectable } from '@angular/core';
import { IAdditionalContributions } from '../interface/IAdditionalContributions';
import { BehaviorSubject } from 'rxjs';
import { Strings } from 'src/core/constants/Strings';
import { PayAdditionalContributionsRequest } from '../interface/dto/PayAdditionalContributionsRequest';
import { GenericResponse } from '../interface/dto/GenericResponse';
import { IBaseService } from './i-base-service.service';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { Constants } from 'src/core/constants/Constants';

@Injectable({
  providedIn: 'root'
})
export class AdditionalContributionsService {

  private readonly formClean: IAdditionalContributions = {
    typeContribution: Strings.EMPTY,
    policyNumber: Strings.EMPTY
  };

  private readonly dataAdditionalContribution: BehaviorSubject<IAdditionalContributions> = new BehaviorSubject<IAdditionalContributions>(
    {
      typeContribution: Strings.EMPTY,
      policyNumber: Strings.EMPTY
    }
  );

  constructor(
    private readonly baseService: IBaseService
  ) { }

  get additionalContributions$() {
    return this.dataAdditionalContribution.asObservable();
  }

  get additionalContributions() {
    return this.dataAdditionalContribution.getValue();
  }

  set additionalContributionsData(data: IAdditionalContributions) {
    this.dataAdditionalContribution.next(data);
  }

  clearData() {
    this.dataAdditionalContribution.next(this.formClean);
  }

  async payAdditionalContributions (request: PayAdditionalContributionsRequest) : Promise<string> {
    const response = await this.baseService.genericPost<GenericResponse<string>>(request, {
      isSecure: true, addSession: true
    });

    if(!response || !response?.datos){
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response.datos;
  }

}
