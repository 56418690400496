import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatestWith, map } from 'rxjs';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Constants, FormatDate, Numbers, Tab } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { formatDate } from '@angular/common';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { AccountCardService } from 'src/app/services/account-card.service';
import { IAdditionalContributions } from 'src/app/interface/IAdditionalContributions';
import { AdditionalContributionsService } from 'src/app/services/additional-contributions.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { PATH } from 'src/core/constants/Path';
import { Router } from '@angular/router';
import { MaskAccountPipe } from 'src/app/pipes/mask-account.pipe';
import { PayAdditionalContributionsRequest } from 'src/app/interface/dto/PayAdditionalContributionsRequest';
import { InsurancePolicyInfoStatusService } from 'src/app/services/insurance-policy-info-status.service';
import { Utils } from 'src/core/utils/utils';
import { ITransactionVoucher } from 'src/app/interface/TransactionVoucherRequest';
import { StorageService } from 'src/app/services/storage.service';
import { NotifyRouteService } from 'src/app/services/notify-route.service';

@Component({
  selector: 'app-additional-contributions-complete',
  templateUrl: './additional-contributions-complete.component.html',
  styleUrls: ['./additional-contributions-complete.component.css']
})
export class AdditionalContributionsCompleteComponent implements OnInit {

  selectedCard!: ICardAccount;
  addContributionsData: IAdditionalContributions;
  sharedData: ITransactionVoucher;
  check: string = Resources.GREEN_CHECK_FILL;
  reference: string = Strings.EMPTY;
  dateSuccess: string = formatDate(new Date, FormatDate.DayMonthYearComplete, Constants.LOCALE_ES_MX);
  cardNumber: string = Strings.EMPTY;
  errorMessage: string = Strings.EMPTY;
  additionalContributionsPage: string = PATH.AdditionalContributions;
  homePage: string = PATH.Home;
  tab: Tab = Tab.Insurance;
  isComponentLoaded = new BehaviorSubject<boolean>(false);


  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(this.isComponentLoaded),
    map(([breakpoints, isComponentLoaded]) => ({ breakpoints, isComponentLoaded }))
  );

  constructor(private readonly responsiveService: ResponsiveService,
    private readonly accountCardService: AccountCardService,
    private readonly additionalContributionsService: AdditionalContributionsService,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly insurancePolicyInfoStatusService: InsurancePolicyInfoStatusService,
    private readonly storageService: StorageService,
    private readonly router: Router,
    private readonly notifyRouteService: NotifyRouteService) { }

  async ngOnInit() {
    this.initComponent();
  }

  async initComponent() {
    try {
      if (this.additionalContributionsService.additionalContributions.typeContribution && this.additionalContributionsService.additionalContributions.policyNumber) {
        this.selectedCard = this.accountCardService.accountCard;
        this.cardNumber = new MaskAccountPipe().transform(this.accountCardService.accountCard.cardNumber, Numbers.Four);
        this.addContributionsData = this.additionalContributionsService.additionalContributions;
        await this.payAdditionalContributions();
      } else {
        this.notifyRouteService.navigateToTab(this.tab);
      }
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.errorMessage = Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code);
    } finally {
      this.isComponentLoaded.next(true);
    }
  }

  private async payAdditionalContributions() {
    const request = this.getAdditionalContributionsRequest();
    this.reference = await this.additionalContributionsService.payAdditionalContributions(request);
    this.sharedData = {
      NumeroReferencia: Number(this.reference),
      IdSession: this.storageService.getSession(),
      NumeroCuenta: this.selectedCard.accountNumber
    };
  }

  private getAdditionalContributionsRequest() {
    return new PayAdditionalContributionsRequest({
      emisor: this.insurancePolicyInfoStatusService.insurancePolicy.Emisor,
      carpeta: this.insurancePolicyInfoStatusService.insurancePolicy.Carpeta,
      monto: Number(Utils.transformAmount(this.notifyAmountService.amount.amount)),
      idCuentaProducto: this.selectedCard.productId,
      numeroCuenta: this.selectedCard.accountNumber
    });
  }

  ngOnDestroy() {
    this.notifyAmountService.amount = { amount: Strings.EMPTY };
    this.additionalContributionsService.clearData();
    this.accountCardService.clearCardAccount();
  }

  goPage(route: string) {
    if (route === this.homePage)  {
      this.notifyRouteService.navigateToTab(this.tab);
    } else {
      this.router.navigate([route]);
    }
  }
}
