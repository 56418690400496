<ng-container *ngIf="vm$ | async as vm">
  <div *ngIf="hasResult()">
    <section
      [class.px-4]="!vm.breakpoints.mobile"
      [class.px-3]="vm.breakpoints.mobile"
      class="py-3"
    >
      <div class="d-flex justify-content-end" id="modal-map" *ngIf="!isCancellation">
        <modal-close
          [image]="frameClose"
          [clickFn]="close.bind(this)"
        ></modal-close>
      </div>
      <header>
        <ng-container *ngIf="isAfore">
          <ng-container *ngTemplateOutlet="AforeHeader"></ng-container>
        </ng-container>
        <ng-container *ngIf="isChecksRequest" #Search&Branchs>
            <ng-container>
                <p
                [class.header-l]="!isMobile"
                [class.header-s]="isMobile"
                [class.my-4]="!isMobile"
                [class.my-3]="isMobile"
                class="default-color" id="top-header" #Map
              >
              {{branchSelection}}
              </p>
              <p class="secondary-color header-xs">
             {{nearbyBranchPickupPrompt}}
              </p>
              <div class="gap-3 map-toolbar" id="toolsContainer">
                <div class="ms-2 px-2 spacing-select w-100">
                </div>
                <div class="search-container" *ngIf="!withoutResults()">
                  <app-search-component
                    classSearch="input-group p-0"
                    [placeholder]="
                      isMobile
                        ? 'Buscar por C.P. '
                        : 'Buscar por C.P. o dirección' "
                        [searchCustom]="false"
                        [onlyNumber]="false"
                        [idSearch]="'searchbranch'"
                        [classCheckbookInput]="isMobile ? 'search-custom-class':'search-custom-desktop-class'"
                        (dataFromSearch)="receiveDataFromSearchMap($event)"
                        [regex]="stringLocation"
                  ></app-search-component>
                </div>
              </div>
              <div class="mb-3">
                <ng-container *ngIf="withResults(); else WithoutResults">
                  <app-card-branches (index)="setLocation($event)"
                  [directions]="directionCopy"></app-card-branches>
                </ng-container>
              </div>
            </ng-container>
        </ng-container>
      </header>
      <div>
        <main #main class="w-100 h-100" *ngIf="showMap()">
          <app-map
            [height]="mapHeight()"
            [width]="mapWidth()"
            (selectedId)="setTab($event)"
          ></app-map>
        </main>
        <footer
          *ngIf="footerTemplate && !withoutResults(); else operationError"
        >
          <ng-container
            *ngTemplateOutlet="footerTemplate; context: context"
          ></ng-container>
        </footer>
      </div>
    </section>
  </div>
  <ng-template #AforeHeader>
    <ng-container *ngIf="!isCustomTemplate">
      <p
        [class.header-l]="!vm.breakpoints.mobile"
        [class.header-m]="vm.breakpoints.mobile"
        [class.my-4]="!vm.breakpoints.mobile"
        [class.my-3]="vm.breakpoints.mobile"
        class="default-color modal-titles"
      >
        {{ title }}
      </p>
      <p
        [class.header-s]="!vm.breakpoints.mobile"
        [class.header-xs]="vm.breakpoints.mobile"
        class="default-color modal-titles"
      >
        {{ subtitle }}
      </p>

      <div class="search-container">
        <app-search-component
          *ngIf="false"
          classSearch="input-group p-0"
          [placeholder]="
            vm.breakpoints.mobile
              ? 'Buscar por C.P. '
              : 'Buscar por C.P o dirección'
          "
        ></app-search-component>
      </div>
      <div class="mb-3">
        <ng-container *ngIf="directions().length != 0; else WithoutResults">
          <app-card-branches
            [directions]="directions"
            (index)="setLocation($event)"
          >
          </app-card-branches>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="isCustomTemplate">
      <ng-container
        *ngTemplateOutlet="customComponentTemplate; context: context"
      ></ng-container>
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #WithoutResults>
  <div class="header-m d-flex text-center justify-content-center">
    Sin resultados<br />No se encontraron resultados para tu búsqueda
  </div>
</ng-template>

<ng-template #operationError>
  <div *ngIf="!withoutResults">
    <app-error-exception
      [showButtons]="false"
      [errorMessage]="errorMessage()"
    ></app-error-exception>
  </div>
  <div class="d-flex justify-content-center align-items-center mt-3">
    <button [class]="classBtnContinue" (click)="close()">
      {{ textErrorButton }}
    </button>
  </div>
</ng-template>
