import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PATH } from 'src/core/constants/Path';
import { AppUtils } from 'src/core/utils/AppUtils';

@Injectable({
  providedIn: 'root'
})
export class MobileAppGuard  {

  constructor(private readonly router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!AppUtils.platform.isApp) {
      this.router.navigate([PATH.Login]);
    }

    return AppUtils.platform.isApp;
  }

}
