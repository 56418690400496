<ng-container *ngIf="vm$  | async as breakPoints">
    <div class="modal-checks primary-b" *ngIf="!showBranch" [ngClass]="{'modal-checks-mobile':breakPoints.mobile}">
        <div class="d-flex justify-content-end div-close">
            <img role="button" class="close" aria-label="Close" (click)="close()" [src]="removeOperation | cdnImage"
                alt="Close" width="24px" height="24px">
        </div>
        <div class="style-div-confirm-data">
            <header>
                <div class="d-flex justify-content-center span-confirmation">
                    <span [ngClass]="breakPoints.mobile ? 'header-m' : 'header-l'" class="default-color">Confirmación de
                        solicitud</span>
                </div>
                <div *ngIf="!breakPoints.mobile" class="d-flex justify-content-center div-data">
                    <span class="secondary-color body-s">Confirma
                        los datos de la operación</span>
                </div>
            </header>
            <main [class]="breakPoints.mobile?'':'px-3 mx-3'">
                <div class="d-flex justify-content-center div-mobile">
                    <div class="default-color pt-3 mt-3 ps-3">
                        <div class="mb-3 pb-3 header-s default-color">Cuenta asociada
                        </div>
                        <div>
                            <div class="ps-2">
                                <div>
                                    <label class="body-s default-color">{{accountNumber}}</label>
                                </div>
                                <div class="detail-s detail-s-color">
                                    Con cargo a tu cuenta
                                </div>
                            </div>
                        </div>
                        <hr class="mt-2" />
                    </div>
                    <ng-container *ngIf="breakPoints.mobile">
                        <div class="header-s default-color style-information-mobile">
                            Información de la operación
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!breakPoints.mobile">
                        <div class="header-s pt-2 mb-4 ps-4 default-color">
                            Información de la operación
                        </div>
                    </ng-container>
                    <div class="header-s  default-color ps-3">
                        <div [class]="breakPoints.mobile ? 'ps-2  ' : 'ps-2'">
                            <div class="body-s color-details">{{typeCheckBook}}</div>
                            <div class="detail-s color-details-s">Tipo de chequera</div>
                        </div>
                        <hr class=" mt-2" />
                    </div>
                    <div class="header-s  default-color ps-3">
                        <div [class]="breakPoints.mobile ? 'ps-2  ' : 'ps-2'">
                            <div class="body-s color-details">{{numberCheckBook}}</div>
                            <div class="detail-s color-details-s">Número de chequera</div>
                        </div>
                        <hr class=" mt-2" />
                    </div>
                    <div class="header-s  default-color ps-3">
                        <div [class]="breakPoints.mobile ? 'ps-2  ' : 'ps-2'">
                            <div class="body-s color-details div-branch">{{branch}}</div>
                            <div class="detail-s color-details-s">Sucursal para recoger chequera</div>
                        </div>
                        <hr class=" mt-2" />
                    </div>
                </div>

                <div class="d-flex justify-content-center" [ngClass]="breakPoints.mobile? 'pt-2':'pt-4'">
                    <button class="btn-ready-modal-check button-m border-btn-ok btn-modal-check"
                        [ngClass]="isApple?'style-apple-btn':'style-btn'" (click)="confirmContinue()">
                        Confirmar y continuar
                        <img *ngIf="!breakPoints.mobile" [src]="chevronRight | cdnImage" alt="" width="auto"
                            height="auto">
                    </button>
                </div>
                <div class="pt-2 d-flex justify-content-center">
                    <button class="btn-other-operation-modal-check button-m border-btn-other btn-modal-check mb-3"
                        (click)="modify()">
                        Modificar</button>
                </div>
            </main>
        </div>
    </div>
</ng-container>
