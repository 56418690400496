<div class="input-group" id="searchContainer" *ngIf=" vm$ | async as vm">
    <input id="searchInput" #searchInput name="buscar" [placeholder]="placeholder"
      class="form-control" type="text" autocomplete="off" aria-label="Username"
      aria-describedby="basic-addon1" [maxLength]="referenceLength"
      (input)="filterOperation(searchInput.value)" (keyup.enter)="navigate(searchInput.value)"
      cleanInput [cleanRegex]="regexSearch" (blur)="clearSearchList()"
      (keyup)="optionListDisplay($event, 'keyup'); handleKeydown($event)">      
    <label for="searchInput" class="input-group-text" [class.radius-button]="!vm.breakpoints.mobile">
      <img [src]="search|cdnImage" width="auto" height="auto" alt="icono de buscar"          
        (click)="navigate(searchInput.value)" (keydown.enter)="$event.preventDefault()"/></label>
      <span class="pe-3 ps-1 input-group-text radius-button iconSearch" 
        *ngIf="vm.breakpoints.mobile" (click)="closeSearchInput()" (keydown.enter)="$event.preventDefault()">
          <img [src]="close | cdnImage" width="auto" height="auto" alt="cerrar input" />
      </span>
    <div id="listSearch" class="card container-options moz-scroll" [hidden]="!showSearches"
    *ngIf="showModal"
      [ngStyle]="{'height': ( optionsSearch.length > 6 ) ?'250px':'auto'}">
      <div>
        <div
          *ngFor="let options of optionsSearch; let i = index"
          class="mt-1 selected-option detail-m-highlight"
          (click)="setOption(options.option)" (keydown.enter)="$event.preventDefault()"
          [class.selected]="i === selectedOptionIndex">
          {{options.option}}
        </div>
      </div>
      <div [innerHTML]="withoutResult" class="without-options detail-m-highlight mt-2"
      *ngIf="optionsSearch.length <= 0"> </div>
    </div>
  </div>
