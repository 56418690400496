import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { map, combineLatestWith, switchMap, tap, SubscriptionLike } from 'rxjs';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { AccountType, Char, ClassHTML, Constants, DownloadDoc, FormValidations, Format, InputTypes, Length, Numbers, Position } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { ModalAssociatedCardsComponent } from 'src/app/home/components/modal-associated-cards/modal-associated-cards.component';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { InputConfig } from 'src/app/interface/IInputConfig';
import { FormGroup, Validators } from '@angular/forms';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import { FormUtils } from 'src/core/utils/FormUtils';
import { AccountsStatusService } from 'src/app/services/accounts-status.service';
import { CreditCardsStatusService } from 'src/app/services/credit-cards-status.service';
import { CreditCardInfoStatusService } from 'src/app/services/credit-card-info-status.service';
import { CreditCardModelTDC } from 'src/app/interface/CreditCardList';
import { AccountModel } from 'src/app/interface/AccountModel';
import { ToastService } from 'src/app/services/toast.service';
import { PatrimonialStatusService } from 'src/app/services/patrimonial-status.service';
import { Utils } from 'src/core/utils/utils';
import { AlertService } from 'src/app/services/alert.service';
import { ProductConstants } from 'src/core/constants/ProductConstants';
import { ResponsiveConstants } from 'src/core/constants/ResponsiveConstants';
import { InsurancesStatusService } from 'src/app/services/insurances-status.service';
import { InsurancePolicyInfoStatusService } from 'src/app/services/insurance-policy-info-status.service';
import { IInsurancePolicyInfo } from 'src/app/interface/IInsurancePolicyInfo';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ModalUtils } from 'src/core/utils/ModalUtils';
import { Regex as ConstantsRegex } from 'src/core/constants/RegexConstants';
import { environment } from 'src/environments/environment';
import { StylesConstants } from 'src/core/constants/StylesConstants';
import { ModifyDailyLimitService } from 'src/app/services/modify-daily-limit.service';
import { AforeDataService } from 'src/app/services/afore-data.service';
import { IAforePLResponse } from 'src/app/interface/IAforePLResponse';
import { AdditionalStrings } from 'src/core/constants/AdditionalStrings';
import { AforeStrings } from 'src/core/constants/AforeStrings';
import { InsurancePolicies } from 'src/app/services/insurance-policies-status.service';
import { CreditsStatusService } from 'src/app/services/credits-status.service';
import { ICreditsData } from 'src/app/interface/ICreditsData';
import { CreditStrings } from 'src/core/constants/CreditStrings';
import { Router } from '@angular/router';
import { AccountCardService } from 'src/app/services/account-card.service';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { InvestmentRegisterService } from 'src/app/services/investment-register.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { PATH } from 'src/core/constants/Path';
import { StorageService } from 'src/app/services/storage.service';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { FileUtils } from 'src/core/utils/FileUtils';
import { FileNames } from 'src/core/constants/FileNames';
import { CreditDetailsMovementsService } from 'src/app/services/credit-details-movements.service';
import { DateUtils } from 'src/core/utils/dateUtils';
import { InsuranceConstants } from 'src/core/constants/InsurancesConstants';
import { InvestmentAccountsData } from 'src/app/interface/GetInvestmentAccountsModel';
import { InvestmentCompaniesInfoStatusService } from 'src/app/services/investment-companies-info-status.service';
import { IconResources } from 'src/core/constants/IconResources';
import { InsurancePolicyCoverService } from 'src/app/services/insurance-policy-cover.service';
import { PolicyConditionsService } from 'src/app/services/policy-conditions.service';
import { ProductStrings } from 'src/core/constants/ProductStrings';
import { ModalConsultationAforeComponent } from 'src/app/home/components/modal-consultation-afore/modal-consultation-afore.component';
import { PaymentCardActionService } from 'src/app/services/payment-card-action.service';
import { NavigationConstants } from 'src/core/constants/NavigationConstants';
import { GetAforeBalancesCertificateRequest } from 'src/app/interface/dto/GetAforeBalancesCertificateRequest';
import { AppBridge } from 'src/core/utils/AppBridge';
import { AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { AppNavigation } from 'src/app/models/AppNavigation';
import { ModalCardInfoInsuranceGmmComponent } from 'src/app/home/components/modal-card-info-insurance-gmm/modal-card-info-insurance-gmm.component';
import { AppUtils } from 'src/core/utils/AppUtils';
import { DatePipes } from 'src/app/pipes/date.pipe';
import { Format as FormatDate } from 'src/core/constants/FormatDate';
import { CodeflexLayoutService } from 'src/app/services/codeflex-layout.service';
import { CurrencyPipe } from '@angular/common';
import { CodeflexStrings } from 'src/core/constants/CodeflexStrings';
@Component({
  selector: 'app-modal-account-information',
  templateUrl: './modal-account-information.component.html',
  styleUrls: ['./modal-account-information.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAccountInformationComponent implements OnInit {
  modalInstance: NgbModalRef;
  frameClose: string = Resources.CLOSE_CIRCLE_BLUE;
  copyIcon: string = Resources.FILE_COPY;
  editIcon: string = Resources.EDIT;
  btnEditStyle: Partial<CSSStyleDeclaration> = Strings.BTN_EDIT_STYLES_BACK;
  chevronIcon: string = Resources.CHEVRON_RIGHT_LIGHT_BLUE;
  fileViewInIcon: string = Resources.SHOW_DOCUMENT;
  header: string = Strings.ACCOUNT_INFO_MESSAGE.Default;
  chevronRight: string = Resources.BTN_CHEVRON_RIGHT;
  currentLimit: string;
  closeModal: boolean;
  modalData: { consultLimit: boolean, newLimit?: string ,cardNumberInput?:string} = { consultLimit: true, newLimit: Strings.EMPTY,cardNumberInput:Strings.EMPTY };
  isCredits: boolean;
  infoIcon: string = Resources.CIRCLE_INFO_LIGHT_BLUE;
  validAlias: boolean = false;
  regexAlias: RegExp =  ConstantsRegex.AlphanumericAccents;
  associatedCards: boolean = environment.hiddenComponents.associatedCards;
  internationalTransfersLetter: boolean = environment.hiddenComponents.insurance.internationalTransfersLetter;
  amortization: string = FileNames.AMORTIZATION;
  creditCardAssociatedCards: string = CreditStrings.MODAL_ACCOUNT_INFORMATION.associatedCards;
  isApp = AppUtils.platform.isApp;
  styles: Partial<CSSStyleDeclaration> = {
    display: ClassHTML.WebkitBox,
    webkitBoxOrient: ClassHTML.BoxOrientVertical,
    overflowWrap : ClassHTML.AnyWhere
  };
  vm$ = this.responsiveService.observe.pipe(
    tap((breakpoints) => {
      if (!breakpoints.mobile) {
        this.modalService.close();
      }
    }),
    combineLatestWith(
      this.codeflexLayout.isCodeflex$,
      this.accountsStatusService.status$.pipe(
        combineLatestWith(this.accountInfoStatusService.account$),
        switchMap(async ([event, account]) => {
          if (account.tipoCuenta === AccountType.PAT) {
            this.currentLimit = this.patrimonialService.accountLimit.productCardCTS.toString()
              !== account.idCuentaProducto.toString() ? this.modalData.consultLimit ? await this.checkAccountLimitCT(account.numeroCuenta) : Numbers.Zero.toString() :
              this.patrimonialService.accountLimit.getLimit.toString();
          }
          return { value: event.value, account };
        }),
      ),
      this.creditCardsStatusService.status$.pipe(
        combineLatestWith(this.creditCardInfoService.creditCard$),
        map(([event, account]) => {
          return { value: event.value, account };
        })
      ),
      this.insuranceStatusService.status$.pipe(
        combineLatestWith(this.insurancePolicyInfoStatusService.insurancePolicy$),
        map(([event, accountInsurance]) => {
          return { value: event.value, accountInsurance };
        })
      ),
      this.insuranceStatusService.showOptionsInsurances$,
      this.aforeDataService.afore$.pipe(
        tap(async (value) => {
          this.isAfore = value.cuenta.idCuenta !== Strings.EMPTY;
        })
      ),
      this.creditsStatusService.status$,
      this.creditsStatusService.credit$,
      this.investmentCompaniesInfoStatusService.status$.pipe(
        combineLatestWith(this.investmentCompaniesInfoStatusService.investmentCompaniesInfo$),
        map(([event, account]) => {
          return { value: event.value, account };
        })
      ), this.creditCardInfoService.balances$.pipe(tap(() => {
        if (this.isCredit) {
          this.setAccountInformation();
        }
      })
      )
    ),
    switchMap(async ([breakpoints, isCodeflex, debit, credit, insurance, showOptionsInsurance, afore, creditsStatus, creditsInfo, investmentCompanies]) => {
      this.currentProduct = debit.value ? debit.account : credit.account;
      const isDebit = debit.value || isCodeflex;
      this.currentProduct = isDebit ? debit.account : credit.account;
      if(!isDebit){
        this.currentProduct.nickName = String(credit?.account?.nickName || credit?.account?.alias);
      }
      this.insuranceProduct = insurance.accountInsurance;
      this.isDebit = debit.value;
      this.isCredit = credit.value;
      this.isInsurance = insurance.value;
      this.showOptionsInsurance = showOptionsInsurance;
      this.afore = afore;
      this.isCredits = creditsStatus.value;
      this.creditsInfo = creditsInfo;
      this.investmentCompany = investmentCompanies.account;
      this.isInvestmentCompanies = investmentCompanies.value;
      if(!this.isDebit){
        this.currentProduct.nickName = String(credit?.account?.nickName || credit?.account?.alias);
      }
      this.setAccountInformation();
      return {
        breakpoints, isCodeflex, product: this.currentProduct, limitCT: this.currentLimit, insurance: this.insuranceProduct,
        isCredit: this.isCredit, isDebit: this.isDebit, isInsurance: this.isInsurance, isInsuranceLife: showOptionsInsurance,
        isCredits: this.isCredits, creditsInfo: this.creditsInfo, isInvestmentCompanies: this.isInvestmentCompanies
      };
    })
  );
  accountInformation: { title: string; value: string; format?: number, hideCopy?: boolean, hideElement?: boolean, tolTip?: {},iconConfig?: {}, currency?: boolean}[] = [];

  inputConfig: InputConfig[] = [
    {
      type: InputTypes.Text, label: Strings.EMPTY, name: ResponsiveConstants.MODAL_RESPONSIVE_ACCOUNT_INFORMATION.AliasInput,
      validations: [{ name: FormValidations.Required, validator: Validators.required, message: Strings.GENERIC_ERRORS.Required }]
    }
  ];
  formGroup: FormGroup = this.formService.toFormGroup(this.inputConfig);
  showAliasInput: boolean = false;
  showLimitInput: boolean = false;
  isCredit: boolean = false;
  isDebit: boolean = false;
  isInsurance: boolean = false;
  showOptionsInsurance: boolean = false;
  isAfore: boolean = false;
  isInvestmentCompanies: boolean = false;
  afore: IAforePLResponse;
  currentProduct: AccountModel | CreditCardModelTDC;
  currentPolicyInsurrance: IInsurancePolicyInfo;
  insuranceProduct: IInsurancePolicyInfo;
  investmentCompany: InvestmentAccountsData;
  aliasInput: InputConfig = this.inputConfig[Numbers.Zero];
  dataReceipt: string = Strings.EMPTY;
  selectedCard: string = Strings.EMPTY;
  selectedAccountNumber: string = Strings.EMPTY;
  descriptionAccount: string = Strings.EMPTY;
  clabe: string = Strings.EMPTY;
  balance: string = Strings.EMPTY;
  accountType: string = Strings.EMPTY;
  image: string = Strings.EMPTY;
  currency: string = Strings.EMPTY;
  idAccountProduct: number = Numbers.Zero;
  viewCardInsurance: boolean = false;
  btnDisabled: boolean = true;
  investmentAccount: Array<AccountModel> = [];
  creditsInfo: ICreditsData;
  insuranceHeader: string = InsuranceConstants.INSURANCES_HEADER;
  creditsHeader: string = CreditStrings.MODAL_ACCOUNT_INFORMATION.creditsHeader;
  responsiveServiceSL: SubscriptionLike;
  @ViewChild('viewModal', { static: true }) viewModal: ElementRef;
  fileDownloadIcon: string = IconResources.FILE_DOWNLOAD_IN_LC_CONTRAST;
  @Output() litsSelectedToSend = new EventEmitter();
  movementDetail: string = AforeStrings.LABELS_AFORE.MovementDetail;
  balanceCertification: string = ProductStrings.BALANCE_CERTIFICATION;
  payHeaderInput: string = ProductStrings.PAY_INPUT;
  hiddenPolicy: boolean = environment.hiddenComponents.aliasPolicy;
  Amortization: string = DownloadDoc.Amortization;
  titlePolicy: string = Strings.CONFIRM_DATA.Policy.toLowerCase();
  titleAccount: string = AdditionalStrings.ADDITIONAL_CARDS.Account.toLowerCase();
  consultCFDI: boolean = environment.hiddenComponents.insurance.consultCFDI;
  getInsurancePolicy: string = DownloadDoc.InsurancePolicyCover;
  getBalanceCertification: string = DownloadDoc.BalanceCertification;
  getClausulesPolicy: string = DownloadDoc.ClausulesPolicy;
  showButtonCardGmm: boolean = environment.hiddenComponents.showButtonCardGmm;
  showClausulesGmm: boolean = environment.hiddenComponents.showClausulesGmm;
  constructor(
    private readonly paymentCardActionService: PaymentCardActionService,
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly accountInfoStatusService: AccountInfoStatusService,
    private readonly formService: DynamicFormService,
    private readonly accountsStatusService: AccountsStatusService,
    private readonly creditCardsStatusService: CreditCardsStatusService,
    private readonly creditCardInfoService: CreditCardInfoStatusService,
    private readonly toastService: ToastService,
    private readonly modifyDailyLimitService: ModifyDailyLimitService,
    private readonly patrimonialService: PatrimonialStatusService,
    private readonly alertService: AlertService,
    private readonly insuranceStatusService: InsurancesStatusService,
    private readonly insurancePolicyInfoStatusService: InsurancePolicyInfoStatusService,
    private readonly aforeDataService: AforeDataService,
    private readonly insurancePolicies: InsurancePolicies,
    private readonly accountCardService: AccountCardService,
    private readonly creditsStatusService: CreditsStatusService,
    private readonly router: Router,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly stateServicePayIdService: StateServicePayIdService,
    private readonly investmentRegisterService: InvestmentRegisterService,
    private readonly storage: StorageService,
    private readonly creditDetailService: CreditDetailsMovementsService,
    private readonly investmentCompaniesInfoStatusService: InvestmentCompaniesInfoStatusService,
    private readonly insurancePolicyCoverService: InsurancePolicyCoverService,
    private readonly currencyPipe: CurrencyPipe,
    private readonly policyConditionsService: PolicyConditionsService,
    private readonly codeflexLayout: CodeflexLayoutService
  ) { }

  ngOnInit(): void {
    this.backColor(Strings.EMPTY);
    this.validAlias = false;
    if (this.modalData.newLimit && this.modalData.newLimit !== Strings.EMPTY) {
      this.toggleEditLimit();
    }
    this.responsiveServiceSL = this.responsiveService.observe.subscribe((breakpoint) => {
      if (!breakpoint.mobile) {
        this.close();
      }
    });
    this.getInfoBalanceCredit();
  }

  async getInfoBalanceCredit() {
    try {
      if (this.modalData.cardNumberInput) {
        const dataBalance = await this.creditDetailService.getInfoBalance(this.modalData.cardNumberInput);
      }
      this.updateModal();
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      const errorMsg = Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code);
      this.showError(errorMsg, Strings.MODAL_EXCEPTIONS_POINTS.Service.Header, false);
    }
  }

  updateModal() {
    this.modalService.modalInstance.update({
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAccountInformation
    });
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === Char.MiddleDash || event.key === Char.Dot) {
      event.preventDefault();
    }
  }

  changeAlias(eventAlias : string){
    const text = this.formGroup.get(ResponsiveConstants.MODAL_RESPONSIVE_ACCOUNT_INFORMATION.AliasInput)?.value
    || Strings.EMPTY;
    this.formGroup
      .get(ResponsiveConstants.MODAL_RESPONSIVE_ACCOUNT_INFORMATION.AliasInput)
      ?.setValue(
        text
          .replace(ConstantsRegex.AlphanumericAccents, Strings.EMPTY)
          .substr(Numbers.Zero, Numbers.Twelve)
      );
      if(!this.insuranceProduct.Alias){
        this.insuranceProduct.Alias = Strings.EMPTY;
      }
      if(this.currentProduct.nickName){
        this.disableButton(this.currentProduct?.nickName,eventAlias);
      }else if(this.currentProduct.alias){
        this.disableButton(this.currentProduct?.alias,eventAlias);
      }else{
        this.disableButton(this.insuranceProduct.Alias, eventAlias);
      } 
  }

  disableButton(name: string,alias: string){
    if (name !== alias && alias !== Strings.EMPTY) {
      this.disabledButton();
      this.validAlias = true;
    }else{
      this.validAlias = false;
      this.backColor(Strings.EMPTY);
    }
  }
        
  disabledButton() {
    this.btnEditStyle.background = StylesConstants.PRIMARY_BACKGROUND;
    this.btnEditStyle.color = StylesConstants.WHITE_COLOR;
  }

  backColor(event: string) {
    if (!event) {
      this.btnEditStyle.background = StylesConstants.SECONDARY_BACKGROUND;
      this.btnEditStyle.color = StylesConstants.SECONDARY_COLOR;
    }
  }

  async aliasSoureAccount(inputAlias: string) {
    if ((this.currentProduct as AccountModel).idCuentaProducto) {
      await this.modifyDailyLimitService.modifyDebitAccountAlias(inputAlias,(this.currentProduct as AccountModel)?.idCuentaProducto);
    }else if((this.currentProduct as CreditCardModelTDC).numeroTarjeta){
      await this.modifyDailyLimitService.modifyCreditCardAlias(inputAlias, (this.currentProduct as CreditCardModelTDC)?.numeroTarjeta.toString());
      this.currentProduct.nickName = inputAlias;
    }else{
      await this.modifyDailyLimitService.modifyInsuranceAccountAlias(inputAlias, this.insuranceProduct);
      this.insuranceProduct.Alias = inputAlias;
      
    }
    
  }

 async modifyAlias(){
    try {
      const inputAlias = this.formGroup.controls[ResponsiveConstants.MODAL_RESPONSIVE_ACCOUNT_INFORMATION.AliasInput].value;
      const modalOptionsHidden: ModalOptions = { modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal };
      if(this.validAlias){
      this.modalInstance?.update(modalOptionsHidden);
        await this.aliasSoureAccount(inputAlias);
        this.modalInstance?.close({ inputAlias });
        this.alertService.showPopupAlert({
          message: ProductStrings.UPDATE_ALIAS,
          header: Strings.OPERATION_SUCCESSFUL,
          imgHead: Resources.GREEN_CHECK_FILL,
          btnLabel: Strings.MSG_POPUP_ACCEPT,
          btnExit: false
        });
      }

    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        imgHead: Resources.CLOSE_ICON_RED,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        btnExit: false
      }, {
        onSuccess: () => {
          this.modalInstance.update({
            centered: false,
            size: Constants.MODAL_OPTIONS.SizeMd,
            modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAccountInformation
          });
        }
      });
    }
  }


  setAccountInformation() {
    if (this.isCredit) {
      this.getCreditAccountInfo();
    } else if (this.isDebit) {
      this.accountInformation = this.getCreditOrDebitAccountInfo();
    } else if (this.isInsurance) {
      this.accountInformation = this.getInsuranceAccountInfo();
    } else if (this.isCredits) {
      this.accountInformation = this.getCreditsAccountInfo();
    } else if (this.isInvestmentCompanies) {
      this.accountInformation = this.getInvestmentCompaniesAccountInfo();
    }else if (this.codeflexLayout.isCodeflex) {
      this.accountInformation = this.getCodeflexAccountInfo();
    } else {
      this.accountInformation = this.getDefaultAccountInfo();
    }
  }

  getCreditOrDebitAccountInfo() {
    return [
      {
        title: Strings.ADD_SERVICE.AccountLabel,
        value: this.currentProduct.numeroCuenta,
        format: Numbers.Four
      },
      {
        title: ResponsiveConstants.MODAL_RESPONSIVE_ACCOUNT_INFORMATION.Clabe,
        value: this.currentProduct.cuentaClabe,
        format: Numbers.Four
      },
      {
        title: ResponsiveConstants.MODAL_RESPONSIVE_ACCOUNT_INFORMATION.CardNumber,
        value: this.getCardNumber(),
        format: Numbers.Four
      },
      {
        title: ResponsiveConstants.MODAL_RESPONSIVE_ACCOUNT_INFORMATION.SwiftCode.Label,
        value: ResponsiveConstants.MODAL_RESPONSIVE_ACCOUNT_INFORMATION.SwiftCode.PlaceHolder
      }
    ];
  }

  getCreditAccountInfo() {
    const dataBalance = this.creditCardInfoService?.balances;
    if (dataBalance) {
      this.accountInformation = [
        { hideCopy: false, title: Strings.ADD_SERVICE.AccountLabel, value: this.currentProduct.numeroCuenta, format: Numbers.Four },
        { hideCopy: true, title: ResponsiveConstants.MODAL_RESPONSIVE_TDC.BalancePerDay, value: this.getFormatMoney(dataBalance?.SaldoDia) },
        {
          hideCopy: true, title: ResponsiveConstants.MODAL_RESPONSIVE_TDC.TotalBalance, value: this.getFormatMoney(dataBalance?.SaldoTotal),
          tolTip: {
            icon: Resources.CIRCLE_INFO_LIGHT_BLUE,
            tooltip: ResponsiveConstants.MODAL_RESPONSIVE_TDC_INFORMATION.TotalBtnToltip,
            alt: ResponsiveConstants.MODAL_RESPONSIVE_TDC_INFORMATION.AltBtnToltip
          }
        },
        { hideCopy: true, title: ResponsiveConstants.MODAL_RESPONSIVE_TDC.CutOffBalance, value: this.getFormatMoney(dataBalance?.CuentaAnteSaldoCierre) },
        { hideCopy: true, title: ResponsiveConstants.MODAL_RESPONSIVE_TDC.InstallmentBalance, value: this.getFormatMoney(dataBalance?.CargosCuotas) },
        { hideCopy: true, title: ResponsiveConstants.MODAL_RESPONSIVE_TDC.AvailableCredit, value: this.getFormatMoney(dataBalance?.CreditoDisponible) },
        { hideCopy: true, title: ResponsiveConstants.MODAL_RESPONSIVE_TDC.PaymentDueDate, value: this.getFormatDate(dataBalance?.FechaLimiPago) },
        { hideCopy: true, title: ResponsiveConstants.MODAL_RESPONSIVE_TDC.PaymentToAvoidInterest, value: this.getFormatMoney(dataBalance?.CuentaAnteSaldoCierreAlDia) },
        { hideCopy: true, title: ResponsiveConstants.MODAL_RESPONSIVE_TDC.MinimumPayment, value: this.getFormatMoney(dataBalance?.PagoMinimo) },
        { hideCopy: true, title: ResponsiveConstants.MODAL_RESPONSIVE_TDC.MinimumPaymentMSI, value: this.getFormatMoney(dataBalance?.PagoMinimoMSI) },
        { hideCopy: true, title: ResponsiveConstants.MODAL_RESPONSIVE_TDC.CutOffDate, value: this.getFormatDate(dataBalance?.FechaCorte) }
      ];
    }
  }
  getFormatDate(date: string) {
    const datePipe = new DatePipes();
    return datePipe.transform(date, FormatDate.FormatDayMonthYear)?.toUpperCase();
  }

  getFormatMoney(number: number) {
    return this.currencyPipe.transform(number, Char.CurrencySymbol).toString();
  }

  getInsuranceAccountInfo() {
    if (this.isInsurance && this.showOptionsInsurance) {
      return [
        {
          title: InsuranceConstants.POLICY_NUMBER,
          value: this.insuranceProduct.Poliza
        },
        {
          title: InsuranceConstants.ISSUER,
          value: this.insuranceProduct.Emisor,
          hideCopy: true
        },
        {
          title: InsuranceConstants.CIS,
          value: this.insuranceProduct.Cis
        },
        {
          title: InsuranceConstants.EXPIRATION_DATE,
          value: DateUtils.datePipe(this.insuranceProduct.Vencimiento),
          hideCopy: true
        }
      ];
    } else {
      return [
        {
          title: InsuranceConstants.POLICY_NUMBER,
          value: this.insuranceProduct.Poliza
        },
        {
          title: InsuranceConstants.INSURED_PRODUCT,
          value: this.insuranceProduct.ObjetoAsegurado?.SubMarca,
          hideCopy: true,
          hideElement: !this.insuranceProduct.ObjetoAsegurado?.SubMarca
        },
        {
          title: InsuranceConstants.ISSUER,
          value: this.insuranceProduct.Emisor,
          hideCopy: true
        },
        {
          title: InsuranceConstants.CIS,
          value: this.insuranceProduct.Cis
        },
        {
          title: InsuranceConstants.EXPIRATION_DATE,
          value: DateUtils.datePipe(this.insuranceProduct.Vencimiento),
          hideCopy: true
        }
      ];
    }
  }

  getCreditsAccountInfo() {
    return [
        { title: ResponsiveConstants.MODAL_RESPONSIVE_ACCOUNT_INFORMATION.AccountNumber, value: this.investmentCompany.cuenta },
        {
          title: CreditStrings.ADD_CREDIT.LabelNumberCredit,
          value: this.creditsInfo.NumeroCredito
        }
    ];
  }

  getInvestmentCompaniesAccountInfo() {
    return [
      {
        title: ResponsiveConstants.MODAL_RESPONSIVE_ACCOUNT_INFORMATION.AccountNumber,
        value: this.investmentCompany.cuenta
      },
      {
        title: ResponsiveConstants.MODAL_RESPONSIVE_ACCOUNT_INFORMATION.BalanceAvailable,
        value: Utils.formatValue(this.investmentCompany.saldoDisponible.toString(), Format.Currency),
        hideCopy: true
      },
      {
        title: ResponsiveConstants.MODAL_RESPONSIVE_ACCOUNT_INFORMATION.NumberSecurities,
        value: this.investmentCompany.titulos.toString(),
        hideCopy: true
      },
      {
        title: this.getInvestmentPriceTitle(),
        value: Utils.formatValue(this.investmentCompany.precioTitulo.toString(), Format.Currency),
        hideCopy: true
      }
    ];
  }

  getInvestmentPriceTitle() {
    return this.investmentCompany.codigoInversion === ProductConstants.INVESTMENT_CODE_DINBUR ?
      ResponsiveConstants.MODAL_RESPONSIVE_ACCOUNT_INFORMATION.PriceDay :
      ResponsiveConstants.MODAL_RESPONSIVE_ACCOUNT_INFORMATION.LastKnownPrice;
  }

  getCodeflexAccountInfo(){
    return [
      {
        title: Strings.ADD_SERVICE.AccountLabel,
        value: this.currentProduct.numeroCuenta,
        format: Numbers.Four
      },
      {
        title: CodeflexStrings.responsiveOptions.EjeAccountTitle,
        value: this.accountCardService.mainAccountCodeflex.descripcionCuenta
      },
      {
        title: CodeflexStrings.responsiveOptions.BalanceTittle,
        value: this.currentProduct.saldos.disponible.toString(),
        hideCopy: true,
        currency: true
      },
      {
        title: CodeflexStrings.responsiveOptions.TotalBalanceTittle,
        value: this.currentProduct.saldos.total.toString(),
        hideCopy: true,
        currency: true
      },
      {
        title: CodeflexStrings.responsiveOptions.InvestmentDepositTitle,
        value: CodeflexStrings.responsiveOptions.InvestmentDepositValue,
        hideCopy: true
      },
    ];
  }

   getDefaultAccountInfo() {
    return [
      {
        title: AdditionalStrings.ADDITIONAL_CARDS.Account,
        value: this.afore.cuenta.idCuenta
      },
      {
        title: AforeStrings.MODAL_ADD_PRODUCT.Nss,
        value: this.afore.cuenta.nss
          },
          {
            hideCopy: true, title: AforeStrings.MODAL_ADD_PRODUCT.ModalLeave, value: Strings.EMPTY, iconConfig: {
              icon: Resources.CHEVRON_RIGHT_BLUE,
              onClick: NavigationConstants.MENU_OPTIONS_AFORE.ProceduresClick
            }, hideElement:!AppUtils.platform.isApp
          },
          {
            hideCopy: true, title: AforeStrings.MODAL_ADD_PRODUCT.ModalDownloadAfore, value: Strings.EMPTY, iconConfig: {
              icon: Resources.CHEVRON_RIGHT_BLUE,
              onClick: NavigationConstants.MENU_OPTIONS_AFORE.MoreInformationClick
            }, hideElement:!AppUtils.platform.isApp
      }
    ];
  }

  getCardNumber() {
    if (ProductConstants.CARD_NUMBER_UPPER_CASE in this.currentProduct) return this.currentProduct.NumeroTarjeta?.toString();
    else if (ProductConstants.CARD_NUMBER_LOWER_CASE in this.currentProduct) return this.currentProduct.numeroTarjeta?.toString();
    else return Strings.EMPTY;
  }

  close() {
    this.modalInstance?.close();
  }

  showModalAssociatedCards() {
    this.close();
    const modalOptions: ModalOptions = {
      centered: false,
      size: Constants.MODAL_OPTIONS.SizeMd,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAccountInformation
    };
    this.modalService.open(ModalAssociatedCardsComponent, modalOptions);
  }

  async showModal() {
    this.close();
    if (this.accountInfoStatusService.account.saldos.retenido !== Length.Empty) {
      ModalUtils.showModalBalanceHeld(this.modalService);
    }
  }


  toggleEdit() {
    this.showAliasInput = !this.showAliasInput;
    if(this.showLimitInput){
      this.showLimitInput = false;
    }
    const nickName = ((this.currentProduct as AccountModel).idCuentaProducto)? this.currentProduct.alias : 
    (this.currentProduct as CreditCardModelTDC)?.numeroTarjeta? this.currentProduct.nickName: this.insuranceProduct.Alias;
    this.formGroup.get(ResponsiveConstants.MODAL_RESPONSIVE_ACCOUNT_INFORMATION.AliasInput).setValue(nickName);
  }

  toggleEditLimit() {
    this.showLimitInput = !this.showLimitInput;
    if(this.showAliasInput){
      this.showAliasInput = false;
    }
    if (!this.showLimitInput) {
      this.modalData.newLimit = Strings.EMPTY;
    }
  }

  setAlias() {
    FormUtils.alphaNumericInput(null, ResponsiveConstants.MODAL_RESPONSIVE_ACCOUNT_INFORMATION.AliasInput, this.formGroup);
  }

  onClipboardCopy(text: string) {
    this.toastService.show(Strings.COPIED_MSG(text), {
      classname: ClassHTML.CardInfo
    });
  }

  async checkAccountLimitCT(accountNumber: string) {
    try {
      const limitCtResponse = await this.patrimonialService.checkAccountLimitCT(accountNumber);
      return limitCtResponse.Limite.toString();
    } catch (error) {
      this.showError(error, Strings.MSG_POPUP_TITLE, true);
      return Numbers.Zero.toString();
    }
  }

  openModal() {
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeMd,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAccountInformation
    };
    this.modalService.open(ModalAccountInformationComponent, modalOptions, {
      consultLimit: false
    });
  }

  modalInsurance() {
    this.insurancePolicies.newPaymentInsurance?.reset();
    this.accountCardService.clearCardAccount();
    this.stateServicePayIdService.clearService();
    this.insurancePolicies.clearSelectedPolicies();
    this.modalService.close();
    this.modalService.open(this.viewModal, {
      centered: true,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalInsurance
    });
    this.litsSelectedToSend.emit(Strings.EMPTY);
  }

  actionModal($event: string) {
    const data = $event;
    if (data !== Constants.MODAL_OPTIONS.CloseModal) {
      this.selectedCardInvestment($event);
    }
    else {
      this.dataReceipt = null;
    }
    this.modalService.close();
  }

  selectedCardInvestment(data: string) {
    this.selectedCard = data;
    const result = this.investmentAccount.filter(productAccount => {
      return productAccount.idCuentaProducto.toString() === data;
    })[Position.Zero];
    this.selectedAccountNumber = result?.numeroCuenta;
    this.descriptionAccount = result?.descripcionCuenta;
    this.clabe = result?.cuentaClabe;
    this.balance = result?.saldos.total.toString();
    this.idAccountProduct = result?.idCuentaProducto;
    this.accountType = result?.tipoCuenta;
    this.image = result?.directorioImagen;
    this.currency = result?.currency;
    this.investmentRegisterService.accountNumber = {
      NumeroCuenta: this.selectedAccountNumber,
      IdCuentaProducto: parseInt(data),
      Descripcion: this.descriptionAccount,
      Saldo: {
        Moneda: this.currency
      }
    };
    this.selectedCard = this.selectedAccountNumber;
    this.litsSelectedToSend.emit(this.selectedCard);
    this.viewCardInsurance = true;
    this.btnDisabled = false;
  }

  @HostListener('window:popstate')
  onBack() {
    this.close();
  }

  async dowloadPdf(idData: string){
    try {
      switch (idData) {
        case this.Amortization: await this.getAmortization();
          break;
        case this.getInsurancePolicy: await this.openInsurancePolicyCover();
          break;
          case this.getBalanceCertification: await this.downloadBalanceCertification();
          break;
        case this.getClausulesPolicy: await this.downloadClausulesPolicy();
          break;
        default:
          break;
      }
    } catch (error) {
      this.showError(error, Strings.MODAL_EXCEPTIONS_POINTS.Service.Header, false);
    }
  }

  businessError(response: string){
    if(!response){
      throw new BusinessError(Strings.ERROR_SERVICE_GENERIC, Constants.NULL_CODE);
    }
  }

  async getAmortization() {
      const response = await this.creditDetailService.getAmortization(this.storage.getSession(), this.creditsStatusService.credit.NumeroCredito);
      this.businessError(response?.PdfBase64);
      FileUtils.downloadPdf(response.PdfBase64, this.amortization);
  }

  async payCredit() {
    const response = await this.creditDetailService.getCreditDetails(this.creditsStatusService.credit);
    if (typeof response === 'string') {
      this.showError(response, Strings.MODAL_EXCEPTIONS_POINTS.Service.Header, false);
    }
    else {
      this.router.navigate([PATH.CreditPayment]);
      this.accountCardService.clearCardAccount();
      this.notifyAmountService.amount = {
        amount: Strings.EMPTY
      };
    }
  }

  private showError(error: string, header: string, open: boolean) {
    this.modalService.close();
    const errorDetails = Utils.getErrorMsg(error);
    this.alertService.showPopupAlert({
      message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
      header,
      btnLabel: Strings.MSG_POPUP_ACCEPT,
      imgHead: Resources.CLOSE_ICON_RED,
      btnExit: false
    },
      {
        onSuccess: () => open ? this.openModal() : this.close()
      });
  }

  async openInsurancePolicyCover() {
      const response = this.insurancePolicyCoverService?.consultInsurancePolicyCover();
      this.businessError((await response)?.toString());
      FileUtils.downloadPdf(await response, FileNames.COVER);
  }

  async downloadCFDI() {
    try {
      const response = await this.insurancePolicyCoverService.downloadCfdi();
      if (response.base64) {
        FileUtils.downloadPdf(response.base64);
      } else {
        this.modalService.close();
        this.alertService.showPopupAlert({
          message: Strings.EMPTY,
          header:  response?.Error?.Description?.gfiCapitalize(Strings.EMPTY) || Strings.OPERATION_SUCCESSFUL,
          btnLabel: Strings.MSG_POPUP_ACCEPT,
          imgHead: Resources.GREEN_CHECK_FILL,
          btnExit: false
        });
      }
    } catch (error) {
      this.showError(error, Strings.MSG_POPUP_TITLE, true);
    }
  }

  async openPolicyConditions() {
    try {
      await this.policyConditionsService.openPolicyConditions();
    } catch (error) {
      this.showError(error, Strings.MSG_POPUP_TITLE, true);
    }
  }

  openModalMovementsDetail() {
    this.modalService.close();
    const selectedOption = AforeStrings.LABELS_AFORE.MovementType;
    const modalOptions: ModalOptions = {
      centered: true,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalConsultationAfore
    };
    this.modalService.open(ModalConsultationAforeComponent,modalOptions,{ selectedOption });
  }

  async downloadBalanceCertification() {
    try {
      const request = new GetAforeBalancesCertificateRequest({ numeroCuenta: this.aforeDataService.afore.cuenta.idCuenta });
      const response = await this.aforeDataService.getBalancesCertificate(request);
      FileUtils.downloadPdf(response, FileNames.GENERATION_AFORE_BALANCES);
    } catch (error) {
      this.showError(error, Strings.MODAL_EXCEPTIONS_POINTS.Service.Header, false);
    }
  }

  ngOnDestroy() {
    this.responsiveServiceSL?.unsubscribe();
  }

  async downloadClausulesPolicy(){
    const response = await this.insurancePolicyCoverService?.consultClausulesPolicy();
    this.businessError(response?.toString());
    const B64 = response?.toString();
     const navigationParams: AppNavigation ={
     Data: AppScreen.ShowPDF,
     DataParams: B64
     };
    await AppBridge.invoke<string>(AppMethod.SendNativeScreen, navigationParams);
  }

  sendCard() {
    this.close();
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeMd,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAccountInformation
    };

    this.modalService.open(ModalCardInfoInsuranceGmmComponent, modalOptions);
  }

  async onMenuOptionSelected(option) {
    this.modalService.close();
    if (option?.onClick) { this.paymentCardActionService.onClickActions[option.onClick]?.(); }
  }
}
