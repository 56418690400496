import { Injectable } from '@angular/core';
import { Strings } from 'src/core/constants/Strings';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { CancelFederalTaxPaymentRequest } from 'src/app/interface/dto/CancelFederalTaxPaymentRequest';
import { GetFederalTaxPaymentResponse } from 'src/app/interface/dto/GetFederalTaxPaymentResponse';
import { GetFederalTaxPaymentRequest } from 'src/app/interface/dto/GetFederalTaxPaymentRequest';
@Injectable({
    providedIn: 'root'
})
export class FederalTaxReceiptService {
  constructor(private readonly baseService: IBaseService){}
  private readonly _optionSelected:{account: string, period: string, value: string} = {account: Strings.EMPTY, period: Strings.EMPTY, value: Strings.EMPTY};

  get accountSelected(){
    return this._optionSelected.account;
  }

  set accountSelected(value: string) {
    this._optionSelected.account = value;
  }

  get periodSelected(){
    return this._optionSelected.period;
  }

  set periodSelected(value: string) {
    this._optionSelected.period = value;
  }

  get valueSelected(){
    return this._optionSelected.value;
  }

  set valueSelected(value: string) {
    this._optionSelected.value = value;
  }

  clearSelected(){
    this.accountSelected = Strings.EMPTY;
    this.periodSelected = Strings.EMPTY;
    this.valueSelected = Strings.EMPTY;
  }

  async cancelFederalTaxPayment(request: CancelFederalTaxPaymentRequest) {
    return this.baseService.genericPost<GenericResponse<number | string>>(request, {isSecure: true, addSession: true});
  }

  async consultFederalTaxPayments(request: GetFederalTaxPaymentRequest){
    return this.baseService.genericPost<GenericResponse<Array<GetFederalTaxPaymentResponse>>>(request, {
      isSecure: true, addSession: true
    });
  }
}
