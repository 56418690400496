import { StorageService } from 'src/app/services/storage.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISimpleValue } from '../interface/ISimpleValue';

@Injectable({
  providedIn: 'root'
})
export class FacePassLoginService {
  private readonly _isFacePassLogin: BehaviorSubject<ISimpleValue>;

  constructor(private readonly storageService: StorageService) {
    let savedValue = storageService.getLoginFacepass();
    if(!savedValue){
      savedValue = {value: false};
    }
    this._isFacePassLogin = new BehaviorSubject<ISimpleValue>(savedValue);
  }

  get isFacePassLogin$() {
    return this._isFacePassLogin.asObservable();
  }

  get isFacePassLogin(): ISimpleValue {
    return this._isFacePassLogin.getValue();
  }

  set isFacePassLogin(data: ISimpleValue) {
    this.storageService.saveLoginFacepass(data);
    this._isFacePassLogin.next(data);
  }
}
