<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="!vm.close && show">
    <div [class]="vm.alert.type">
      <div
        class="p-3"
        [class.align-items-start]="vm.breakpoints.mobile && flux!=investmentFunds"
        [class.align-items-center]="!vm.breakpoints.mobile || flux==investmentFunds"
        [ngClass]="vm.alert.class"
        role="alert"
      >
        <img [src]="vm.alert.iconHeader | cdnImage" alt="Información" width="auto" height="auto"/>
        <div
          [innerHTML]="vm.alert.message"
          [class.message-error]="vm.alert.type == 'error'"
          [class.alert-transfer-card]="vm.alert.type == 'alert-transfer-card'"
          [class.message-warning]="!(vm.alert.type == 'alert-transfer-card') && !(vm.alert.type == 'error') && 
          !(vm.alert.type == 'information')"
          [class.message-information]="vm.alert.type == 'information'"
          class="container-fluid"
        ></div>
        <a role="button" (click)="close()">
          <img
            [src]="vm.alert.icon | cdnImage"
            alt="Cerrar"
            [ngClass]="vm.breakpoints.mobile ? 'align-top' : ''" width="auto" height="auto"
            />
        </a>
      </div>
    </div>
  </ng-container>
</ng-container>
