import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISimpleValue } from 'src/app/interface/ISimpleValue';
import { HistoricalPaymentInformation } from 'src/app/interface/HistoricalPaymentInformation';

export interface DetailMovements {
  recipientAccount: number;
  holder: string;
  bank: string;
  keyIssuingBank: number;
  paymentConcept: string;
  trackingKey: string;
  amount: number;
  transferMethod: string;
}

@Injectable({
  providedIn: 'root'
})
export class HistoricalPaymentService {
  readonly mockHistorialAmount: string = '11942.1';
  readonly methodInbursa: string = 'Cajero Inbursa';
  readonly methodSPEI: string = 'Transferencia SPEI';
  readonly openModalDetail: BehaviorSubject<ISimpleValue> =
    new BehaviorSubject<ISimpleValue>({ value: false });
  readonly mockArrayDetailMovements: BehaviorSubject<DetailMovements[]> =
    new BehaviorSubject<DetailMovements[]>([
      {
        recipientAccount: 127180016659534808,
        holder: 'Franciso García Peréz',
        bank: 'AZTECA',
        keyIssuingBank: 98725263526356523,
        paymentConcept: 'Listo',
        trackingKey: '036APPM14072023543678663',
        amount: -10000,
        transferMethod: this.methodSPEI
      }
    ]);
  private readonly _historicalPayment: BehaviorSubject<
    HistoricalPaymentInformation[]
  > = new BehaviorSubject<HistoricalPaymentInformation[]>([
    {
      year: '2022',
      month: 'Agosto',
      day: '3',
      status: 'Pendiente de aplicar',
      amount: -312900,
      transferMethod: this.methodInbursa
    },
    {
      year: '2022',
      month: 'Agosto',
      day: '3',
      status: null,
      amount: -122,
      transferMethod: this.methodInbursa
    },
    {
      year: '2022',
      month: 'Mayo',
      day: '3',
      status: null,
      amount: -30009,
      transferMethod: this.methodInbursa
    },
    {
      year: '2022',
      month: 'Mayo',
      day: '2',
      status: null,
      amount: -10009,
      transferMethod: this.methodSPEI
    },
    {
      year: '2022',
      month: 'Febrero',
      day: '3',
      status: null,
      amount: -2009,
      transferMethod: this.methodSPEI
    },
    {
      year: '2022',
      month: 'Febrero',
      day: '2',
      status: null,
      amount: -1009,
      transferMethod: this.methodSPEI
    },
    {
      year: '2022',
      month: 'Diciembre',
      day: '2',
      status: null,
      amount: -30009,
      transferMethod: this.methodSPEI
    },
    {
      year: '2022',
      month: 'Noviembre',
      day: '21',
      status: '$3,110',
      amount: -2009,
      transferMethod: this.methodSPEI
    },
    {
      year: '2023',
      month: 'Noviembre',
      day: '21',
      status: '$3,110',
      amount: -1009,
      transferMethod: this.methodSPEI
    },
    {
      year: '2023',
      month: 'Noviembre',
      day: '22',
      status: '$3,110',
      amount: -3710,
      transferMethod: this.methodSPEI
    },
    {
      year: '2023',
      month: 'Octubre',
      day: '2',
      status: null,
      amount: -2009,
      transferMethod: this.methodSPEI
    },
    {
      year: '2023',
      month: 'Octubre',
      day: '10',
      status: null,
      amount: -37109,
      transferMethod: this.methodSPEI
    },
    {
      year: '2023',
      month: 'Octubre',
      day: '11',
      status: '$3710,9',
      amount: -109,
      transferMethod: this.methodSPEI
    },
    {
      year: '2023',
      month: 'Octubre',
      day: '12',
      status: null,
      amount: -2009,
      transferMethod: this.methodSPEI
    },
    {
      year: '2024',
      month: 'Febrero',
      day: '13',
      status: 'Pendiente de aplicar',
      amount: -509,
      transferMethod: this.methodInbursa
    },
    {
      year: '2024',
      month: 'Enero',
      day: '24',
      status: '$3710,9',
      amount: -1909,
      transferMethod: this.methodInbursa
    },
    {
      year: '2024',
      month: 'Enero',
      day: '30',
      status: null,
      amount: -3710,
      transferMethod: this.methodInbursa
    }
  ]);

  constructor() {}

  get historicalPayment$(): Observable<HistoricalPaymentInformation[]> {
    return this._historicalPayment.asObservable();
  }
  get historicalPayment(): HistoricalPaymentInformation[] {
    return this._historicalPayment.value;
  }

  set historiaclPayment(data: HistoricalPaymentInformation[]) {
    this._historicalPayment.next(data);
  }

  get detailMovements$(): Observable<DetailMovements[]> {
    return this.mockArrayDetailMovements.asObservable();
  }
  get detailMovementsData(): DetailMovements[] {
    return this.mockArrayDetailMovements.value;
  }
  get modalDetailMovements$() {
    return this.openModalDetail.getValue();
  }
  set modalDetailMovements(data: ISimpleValue) {
    this.openModalDetail.next(data);
  }

  groupByYear(
    historicalPayment: HistoricalPaymentInformation[]
  ): Map<string, { [key: string]: HistoricalPaymentInformation[] }> {
    return historicalPayment.reduce((acc, payment) => {
      const yearPayments = acc.get(payment.year) || {};
      const monthDayKey = `${payment.month}-${payment.day}`;
      const monthDayPayments = yearPayments[monthDayKey] || [];
      monthDayPayments.push(payment);
      yearPayments[monthDayKey] = monthDayPayments;
      acc.set(payment.year, yearPayments);
      return acc;
    }, new Map<string, { [key: string]: HistoricalPaymentInformation[] }>());
  }
}
