import { Char, Constants, Numbers } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { WebCardStrings } from 'src/core/constants/WebCardStrings';

export class ImageUtils {

  static getAdditionalImage(image:string){
    if (!image || image.indexOf(Resources.ASSETS_IMAGES_OSB) !== Constants.NOT_FOUND_ITEM) {
      const auxImage = image.replace(Resources.ASSETS_IMAGES_OSB,Strings.EMPTY);
        image = `${Resources.ASSETS_CREDIT_CARDS()}${auxImage}`;
    return image;
    }else{
      return `${Resources.PATH_CDN}${image}`;
    }
  }

  static imageUrlToBlob(data: string) {
    const array = data.split(Char.Comma);
    const mime = array[Numbers.Zero].match(WebCardStrings.WebCard.MimeFormat)[Numbers.One];
    const bstr = atob(array[Numbers.One]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n>0) {
      n--;
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
}
