import { Constants, Numbers } from 'src/core/constants/Constants';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { ServiceDetailsRequest } from 'src/app/interface/dto/ServiceDetailsRequest';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IServicePayId } from 'src/app/interface/IServicePayId';
import { StorageService } from 'src/app/services/storage.service';
import { ServiceDetailsResponse } from 'src/app/interface/dto/ServiceDetailsResponse';
import { Strings } from 'src/core/constants/Strings';
import { Resources } from 'src/core/constants/Resources';
import { ServiceConstants } from 'src/core/constants/ServiceConstants';
import { environment } from 'src/environments/environment';
import { CreditCardInfoStatusService } from 'src/app/services/credit-card-info-status.service';

@Injectable({
  providedIn: 'root',
})
export class StateServicePayIdService {
  private readonly serviceDetailList: Array<IServicePayId> = null;
  private readonly emptyService: IServicePayId = {
    id: Strings.EMPTY,
    imageUrl: Strings.EMPTY,
    maxLimit: Numbers.Zero,
    applyTime: Numbers.Zero,
    commission: Numbers.Zero,
    referenceLength: Numbers.Zero,
    showBarcodeScanner: false,
    description: Strings.EMPTY,
    params: {
      alias: Strings.EMPTY,
      dailyLimit: Numbers.Zero,
      cumulativeDailyLimit: Numbers.Zero,
      idAgenda: Numbers.Zero,
      name: Strings.EMPTY,
      reference: Strings.EMPTY,
      phoneNumber: Strings.EMPTY
    }
  };

  private readonly _service: BehaviorSubject<IServicePayId>;

  constructor(private readonly storageService: StorageService,
              private readonly baseService: IBaseService,
              private readonly creditCardInfoStatusService: CreditCardInfoStatusService) {
    this.serviceDetailList = [];
    let savedValue = storageService.getIdService();
    if (!savedValue) {
      savedValue = this.emptyService;
    }

    this._service = new BehaviorSubject<IServicePayId>(savedValue);
  }

  get service$(): Observable<IServicePayId> {
    return this._service.asObservable();
  }

  set service(data: IServicePayId) {
    this._service.next(data);
    this.storageService.saveIdService(data);
  }

  get service(): IServicePayId {
    return this._service.getValue();
  }

  clearService() {
    this.service = this.emptyService;
  }

  async getServiceDetail(service: IServicePayId): Promise<IServicePayId> {
    if(!service.id || service.id === Constants.SERVICE_CODE.Impfed || service.id === ServiceConstants.CREDIT_OTHER_BANKS_ID) {
      return service;
    }

    if (this.hasService(service.id)) {
      return this.updateService(this.serviceDetailList.find(details => details.id === service.id), service);
    }

    const request = await this.baseService.genericGet<ServiceDetailsResponse>(new ServiceDetailsRequest(), {
      isSecure: true, urlOptions: [service.id]
    });

    const serviceDetail = request?.datos;
    if (!request.datos) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
    }

    const { referenceLength, showBarcodeScanner } = Constants.NON_AGENDABLE_SERVICES_ID[service.id] || {
      referenceLength: null, showBarcodeScanner: null
    };

    service = {
      ...service, showBarcodeScanner, referenceLength,
      id: service.id,
      applyTime: serviceDetail.horaAplicacionPago,
      commission: serviceDetail.comision,
      imageUrl: `${this.updateImage(Resources.PATH_CDN)}${serviceDetail.rutaImagen}`,
      maxLimit: serviceDetail.montoMaximo,
      description: serviceDetail.descripcion
    };

    this.serviceDetailList.push(service);
    return service;
  }

  private updateImage(url: string){
    const { path } = Resources.getResourceConfig();
    return environment.replaceServiceImagePath ? url.replace(path, Strings.EMPTY) : url;
  }

  private updateService(savedService: IServicePayId, newValue: IServicePayId): IServicePayId {
    this.serviceDetailList.splice(this.serviceDetailList.indexOf(savedService), Numbers.One, {
      ...savedService, params: newValue.params
    });

    return this.serviceDetailList.find(details => details.id === savedService.id);
  }

  private hasService(serviceCode: string): boolean {
    return this.serviceDetailList.findIndex(details => details.id === serviceCode) > Constants.NOT_FOUND_ITEM;
  }

  onlyPaymentCreditCard(){
    return this.creditCardInfoStatusService.creditCard.tipoCuenta !== Strings.EMPTY;
  }

}
