<div class="d-flex justify-content-end pt-4 pe-4" ngbAutofocus>
</div>
<div class="pt-3 pb-4">
  <div class="header-l d-flex justify-content-center pb-4 text-center">Podrás pagar los siguientes conceptos</div>
  <div class="grid-container">
    <div class="first-column">
      <ul class="services">
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Tenencia y derechos vehiculares
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Trámites vehiculares
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Multas por Verificación Extemporánea
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Multas por vehículos contaminantes
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Impuesto Predial
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Impuestos por adquisición de inmuebles
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Impuesto sobre Nómina
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Impuesto sobre Loterías, Rifas, Sorteos y Concursos
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Facturas de la Policía Auxiliar
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Registro Civil (copias certificadas)
          </div>
        </div>
      </ul>
    </div>
    <div class="second-column">
      <ul class="services">
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Licencia de conducir
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Revista Vehicular
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Multas de tránsito
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Venta de hologramas (Sólo Verificentros)
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Derechos por suministro de agua
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Registro Público de la Propiedad
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Impuesto sobre Espectáculos Públicos
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Impuesto sobre hospedaje
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Facturas de la Policía Bancaria e Industrial
          </div>
        </div>
        <div class="d-flex">
          <div class="align-self-start">
            <img [src]="chevronRightConstrast | cdnImage" alt=">" height="24" width="24">
          </div>
          <div class="body-s">
            Registro Civil (nacimientos, matrimonio, divorcio, defunción)
          </div>
        </div>
      </ul>
    </div>
  </div>
  <div class="btn-container">
    <button (click)="close()" class="close-btn button-m">
      Cerrar
    </button>
  </div>
</div>
