import { Injectable } from '@angular/core';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { AccountCardService } from 'src/app/services/account-card.service';
import { DirectDebitAforeConstants } from 'src/core/constants/DirectDebitAforeConstants';
import { Numbers } from 'src/core/constants/Numbers';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { DirectDebitAforeRequest } from 'src/app/interface/dto/DirectDebitAforeRequest';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { Constants } from 'src/core/constants/Constants';
import { DirectDebitAforeConfirmationRequest } from 'src/app/interface/dto/DirectDebitAforeConfirmationRequest';
import { DirectDebitAforeVoucherRequest } from '../interface/dto/DirectDebitAforeVoucherRequest';

@Injectable({
  providedIn: 'root'
})
export class VoluntaryContributionsService {

  constructor(
    private readonly dynamicFormService: DynamicFormService,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly cardAccountService: AccountCardService,
    private readonly router: Router,
    private readonly baseService: IBaseService
  ) { }

  private readonly VOLUNTARY_CONTRIBUTIONS_PATHS = [
    PATH.ConfirmDirectDebitAfore,
    PATH.CompleteDirectDebitAfore,
    PATH.ConsultDirectDebitAfore];

  cleanData(): void {
    this.dynamicFormService.clearForm();
    this.cardAccountService.clearCardAccount();
    this.notifyAmountService.clearAmount();
  }

  checkNavigation(): Subscription {
    return this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && !this.VOLUNTARY_CONTRIBUTIONS_PATHS.includes(event.url)) {
        this.cleanData();
      }
    });
  }

  getDeductibleValue(optionValue: string) : string {
    const [yesOpt, noOpt]  = DirectDebitAforeConstants.FORM_CONFIG.Deducible.options;
    const [voluntaries, retirement, longTerm, longTermInvestment]  = DirectDebitAforeConstants.FORM_CONFIG.TypeOfContribution.options;
    let deductibleValue = Strings.EMPTY;
    switch(optionValue){
      case voluntaries.value:
        deductibleValue = noOpt.value;
        break;
      case longTerm.value:
        break;
      case longTermInvestment.value:
      case retirement.value:
        deductibleValue = yesOpt.value;
        break;
    }
    return deductibleValue;
  }

  isDeductibleValue(value: string): boolean {
    const [voluntaries, retirement, longTerm, longTermInvestment]  = DirectDebitAforeConstants.FORM_CONFIG.TypeOfContribution.options;
    let disable = false;
    switch(value){
      case voluntaries.value:
        disable = true;
        break;
      case longTerm.value:
        disable = false;
        break;
      case longTermInvestment.value:
      case retirement.value:
        disable = true;
        break;
    }
    return disable;
  }

  showDeductibleLimit(value: string): boolean {
    const [voluntaries, retirement, longTerm, longTermInvestment]  = DirectDebitAforeConstants.FORM_CONFIG.TypeOfContribution.options;
    let show = false;
    switch(value){
      case voluntaries.value:
      case longTerm.value:
        show = false;
        break;
      case longTermInvestment.value:
      case retirement.value:
        show = true;
        break;
    }
    return show;
  }

  showDeductibleLongTerm(contributionValue: string, deductibleValue: string): boolean {
    const yesOpt  = DirectDebitAforeConstants.FORM_CONFIG.Deducible.options[Numbers.Zero];
      const longTerm  = DirectDebitAforeConstants.FORM_CONFIG.TypeOfContribution.options[Numbers.Two];
    return yesOpt.value === deductibleValue && contributionValue === longTerm.value;
  }

  getOptionForInitialDay(periodicity: string): {value: string, label: string}[] {
    const [weekly, biweekly, monthly] = DirectDebitAforeConstants.FORM_CONFIG.Periodicity.options;
    if(weekly.value === periodicity) {
      return DirectDebitAforeConstants.FORM_CONFIG.StartDayForWeekly.options;
    }else if(biweekly.value === periodicity) {
      return this.createOptionsForInitialDay(Numbers.Fifteen);
    } else if(monthly.value === periodicity) {
      return this.createOptionsForInitialDay(Numbers.Thirty);
    } else {
      return [{label: Strings.EMPTY, value: Strings.EMPTY}];
    }
  }

  private createOptionsForInitialDay(n: number): {value: string, label: string}[] {
    return Array.from({ length: n }, (_, i) => ({ value: (i + Numbers.One).toString(), label: (i + Numbers.One).toString() }));
  }

  getDaysPeriodicity(periodicity: string): number {
    const [weekly, biweekly, monthly] = DirectDebitAforeConstants.FORM_CONFIG.Periodicity.options;
    if(periodicity === weekly.value){
      return Numbers.Seven;
    } else if(periodicity === biweekly.value){
      return Numbers.Fifteen;
    } else if(periodicity === monthly.value) {
      return Numbers.Thirty;
    } else {
      return Numbers.Zero;
    }
  }

  async directDebitAfore(request: DirectDebitAforeVoucherRequest | DirectDebitAforeRequest) {
    const response: GenericResponse<string> = await this.baseService.genericPost<GenericResponse<string>>(request, {
      addSession: true,
      isSecure: true
    });
    if (!response?.datos) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response.datos;
  }

  async directDebitAforeConfirmation(folio: string, operation: boolean) {
    const request= new DirectDebitAforeConfirmationRequest ({
      folio,
      operacion: operation
    });
    const response: GenericResponse<null> = await this.baseService.genericPost<GenericResponse<null>>(request, {
      addSession: true,
      isSecure: true
    });
    if (!response) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response;
  }
}
