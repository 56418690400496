import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TotalNotificationsRequest } from '../interface/dto/TTotalNotificationsRequest';
import { TTotalNotificationsResponse } from '../interface/dto/TTotalNotificationsResponse';
import { StorageService } from './storage.service';
import { IBaseService } from './i-base-service.service';
import { Utils } from 'src/core/utils/utils';
import { AlertService } from './alert.service';
import { Strings } from 'src/core/constants/Strings';
import { TNotificationsInformationResponse } from '../interface/dto/TNotificationsInformationResponse';
import { NotificationsInformationRequest } from '../interface/dto/TNotificationsInformationRequest';
import { TUpdateNotificationsResponse } from '../interface/dto/TUpdateNotificationsResponse';
import { UpdateNotificationsRequest } from '../interface/dto/TUpdateNotificationsRequest';
import { Constants, Length, Numbers } from 'src/core/constants/Constants';
import { NotificationModel } from '../interface/NotificationModel';
import { ServiceErrorsCodes } from 'src/core/constants/ServiceErrorsConstants';

@Injectable({
  providedIn: 'root'
})
export class NotificationInfoService {

  private readonly _totalNotifications: BehaviorSubject<TTotalNotificationsResponse> =
    new BehaviorSubject<TTotalNotificationsResponse>({
      totalMsj: Numbers.Zero,
      Error: null
    });
  private readonly _showNotifications: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readonly _notificationsInformation: BehaviorSubject<Array<NotificationModel>> =
    new BehaviorSubject<Array<NotificationModel>>([]);
  private readonly _isError: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readonly _updateNotifications: BehaviorSubject<TUpdateNotificationsResponse> =
    new BehaviorSubject<TUpdateNotificationsResponse>({
      Error: null
    });
  private readonly _errorNotifications: BehaviorSubject<string> =
    new BehaviorSubject<string>(Strings.EMPTY);
  private readonly _showIconError: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(
    private readonly storageService: StorageService,
    private readonly baseService: IBaseService,
    private readonly alertService: AlertService
  ) { }

  public async getTotalNotifications() {
    try {
      const request: TotalNotificationsRequest = new TotalNotificationsRequest({
        IdSesion: this.storageService.getSession(),
        nombreAplicacion: Constants.ACCESS_METHOD_PORTAL
      });
      const totalNumberNotifications = await this.baseService.genericPost<TTotalNotificationsResponse>(request, { isSOA: true });
      if (totalNumberNotifications) {
        this.totalNotifications = totalNumberNotifications;
        this.showNotifications = this.totalNotifications.totalMsj >= Numbers.One;
      } else {
        this.showNotifications = true;
        this.totalNotifications.totalMsj = Numbers.Zero;
      }
    } catch (error) {
      this.showNotifications = true;
      this.totalNotifications.totalMsj = Numbers.Zero;
    }
  }

  public async getNotificationsInformation() {
    try {
      this.isError = false;
      this.showIconError = false;
      this.errorNotifications = Strings.EMPTY;

      const request: NotificationsInformationRequest = new NotificationsInformationRequest({
        clienteInbursa: String(this.storageService.getUser().personId)
      });
      const notificationsInformation = await this.baseService.genericPost<TNotificationsInformationResponse>(request, { isSOA: true });
      if (notificationsInformation.Mensajes) {
        this.isError = false;
        this.notificationsInformation = notificationsInformation.Mensajes;
      } else {
        this.dataNoMessage();
      }
    } catch (error) {
      const dataResponseError = Utils.getErrorMsg(error);
      if (Number(dataResponseError.code) === ServiceErrorsCodes.WithoutNofications) {
        this.dataNoMessage();
        return;
      }
      this.isError = true;
      this.showIconError = true;
      this.errorNotifications = Strings.ERROR_SERVICE_GENERIC;
    }
  }

  public async updateNotification(deleteNotification: boolean) {
    let notificationsFiltered: Array<NotificationModel>;
    if (deleteNotification) {
      notificationsFiltered = this.notificationsInformation.filter(
        notification => notification.isSelected === true && notification.estatus === Constants.STATES_NOTIFICATIONS.Read);
      await this.updateNotificationsGeneric(notificationsFiltered, Constants.STATES_NOTIFICATIONS.Delete);
    } else if (this.totalNotifications.totalMsj > Length.Empty) {
      notificationsFiltered = this.notificationsInformation.filter(
        notification => notification.estatus === Constants.STATES_NOTIFICATIONS.Unread);
      await this.updateNotificationsGeneric(notificationsFiltered, Constants.STATES_NOTIFICATIONS.Read);
    }
  }

  public async updateNotificationsGeneric(
    notificationsFiltered: Array<NotificationModel>,
    statusNotifications: string) {
    const request = new UpdateNotificationsRequest({ listaMensajes: [] });
    try {
      if (notificationsFiltered.length > Length.Empty) {
        notificationsFiltered.forEach(element => {
          request.data.listaMensajes.push({
            idMensaje: element.idMensaje,
            estatus: statusNotifications
          });
        });
        const isNotificationUpdate = await this.baseService.genericPost<TUpdateNotificationsResponse>(request, { isSOA: true });
        this.updateNotifications = isNotificationUpdate;
      }
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT
      });
    }
  }
  dataNoMessage() {
    this.errorNotifications = Strings.WITHOUT_NOTIFICATIONS_MSG;
    this.isError = true;
    this.showIconError = false;
    this.notificationsInformation = [];
  }
  //#region GETTERS & SETTERS
  public get totalNotifications$(): Observable<TTotalNotificationsResponse> {
    return this._totalNotifications.asObservable();
  }

  public set totalNotifications(data: TTotalNotificationsResponse) {
    this._totalNotifications.next(data);
  }

  public get totalNotifications(): TTotalNotificationsResponse {
    return this._totalNotifications.getValue();
  }

  public get showNotifications$(): Observable<boolean> {
    return this._showNotifications.asObservable();
  }

  public set showNotifications(data: boolean) {
    this._showNotifications.next(data);
  }

  public get showNotifications() {
    return this._showNotifications.getValue();
  }

  public get notificationsInformation$(): Observable<Array<NotificationModel>> {
    return this._notificationsInformation.asObservable();
  }

  public set notificationsInformation(data: Array<NotificationModel>) {
    this._notificationsInformation.next(data);
  }

  public get notificationsInformation() {
    return this._notificationsInformation.getValue();
  }

  public get isError$(): Observable<boolean> {
    return this._isError.asObservable();
  }

  public set isError(data: boolean) {
    this._isError.next(data);
  }

  public get isError() {
    return this._isError.getValue();
  }

  public get updateNotifications$(): Observable<TUpdateNotificationsResponse> {
    return this._updateNotifications.asObservable();
  }

  public set updateNotifications(data: TUpdateNotificationsResponse) {
    this._updateNotifications.next(data);
  }

  public get updateNotifications() {
    return this._updateNotifications.getValue();
  }

  public get errorNotifications$(): Observable<string> {
    return this._errorNotifications.asObservable();
  }

  public set errorNotifications(data: string) {
    this._errorNotifications.next(data);
  }

  public get errorNotifications() {
    return this._errorNotifications.getValue();
  }

  public get showIconError$(): Observable<boolean> {
    return this._showIconError.asObservable();
  }

  public set showIconError(data: boolean) {
    this._showIconError.next(data);
  }

  public get showIconError() {
    return this._showIconError.getValue();
  }


  //#endregion
}
