import { PathModel } from 'src/app/models/PathModel';

type TPartialValidateInformationRequest = {
    clienteInbursaAfore: number;
};

export class AforePartialValidateInformationRequest {
    constructor(public data: TPartialValidateInformationRequest) { }

    get endpoint(): PathModel {
        return PathModel.AforePartialValidateInformation;
    }

}
