import { Injectable } from '@angular/core';
import { AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { AppBridge } from 'src/core/utils/AppBridge';
import { CreditCardInfoStatusService } from 'src/app/services/credit-card-info-status.service';

@Injectable({
  providedIn: 'root'
})
export class CreditCardBridgeService {

  constructor(private readonly creditCardInfoStatusService: CreditCardInfoStatusService) { }

  navTransferDebt(){
    const cardData = {
      Data: AppScreen.TransferDebt,
      DataParams: JSON.stringify({NumeroCuentaTDC:this.creditCardInfoStatusService.creditCard.numeroCuenta,
        NumeroCLABETDC:this.creditCardInfoStatusService.creditCard.cuentaClabe,
        NumeroTarjetaTDC: this.creditCardInfoStatusService.creditCard.numeroTarjeta
      })
    };
    AppBridge.invoke<string>(AppMethod.SendNativeScreen,cardData);
  }
}
