import { formatDate, CurrencyPipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { AccountCardService } from 'src/app/services/account-card.service';
import { PartialWithdrawals } from 'src/app/services/partial-withdrawals.service';
import { Numbers, Constants, FormatDate, ElementsHTML, Tab } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { combineLatestWith, map, tap } from 'rxjs';
import { NotifyRetirementTypeService } from 'src/app/services/notify-retirement-type.service';
import { ModalOptions } from 'src/app/interface/modal-options';
import { AforeStrings } from 'src/core/constants/AforeStrings';
import { AforeConstants, RetirementType } from 'src/core/constants/AforeConstants';
import { ModalService } from 'src/app/shared/modal.service';
import { StringsRetirement } from 'src/core/constants/StringsRetirement';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ButtonProperties } from 'src/app/interface/IButtonProperties';
import { Format } from 'src/core/constants/FormatDate';
import { AlertService } from 'src/app/services/alert.service';
import { TokenService } from 'src/app/services/token.service';
import { AforeService } from 'src/app/services/afore.service';
import { AforePartialWithdrawalRegistrationRequest } from 'src/app/interface/dto/AforePartialWithdrawalRegistrationRequest';
import { Utils } from 'src/core/utils/utils';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProductsService } from 'src/app/services/products.service';
import { AforeDataService } from 'src/app/services/afore-data.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
@Component({
  selector: 'app-modal-partial-succesful',
  templateUrl: './modal-partial-succesful.component.html',
  styleUrls: ['./modal-partial-succesful.component.css']
})
export class ModalPartialSuccesfulComponent implements OnInit {
  infoOperation: string = Strings.TRANSFER.Body;
  processTitle: string = AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Title;
  dataByOrigin: string = Strings.EMPTY;
  origin: string = Strings.EMPTY;
  imss: string = AforeStrings.MODAL_ADD_PRODUCT.Imss;
  nss: string = AforeStrings.MODAL_ADD_PRODUCT.Nss;
  curp: string = AforeStrings.MODAL_ADD_PRODUCT.Curp;
  completeDate: string = Strings.EMPTY;
  imgCard: string = Strings.EMPTY;
  nameCard: string = Strings.EMPTY;
  card: string = Strings.EMPTY;
  modality: string = Strings.EMPTY;
  salary: string = Strings.EMPTY;
  formatDateToDay: string = Strings.EMPTY;
  conditions: string = StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.Conditions;
  checkFill: string = Resources.GREEN_CHECK_FILL;
  completeProcess: string = StringsRetirement.AFORE.SuccessfulRequest;
  toAccount: string = Strings.FROM_YOUR_ACCOUNT;
  operationData: string = Strings.TRANSFER.Body;
  retirementPartial: string = StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.UnemploymentRetirementPartial;
  procedure: string = StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.Procedure;
  modalityLabel: string = StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.Modality;
  reportedSalary: string = StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.ReportedSalary;
  startDate: string = StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.StartDate;
  startProcess: string = StringsRetirement.AFORE_WITHDRAWAL_UNEMPLOYMENT.StartProcess;
  btnOk: string = Strings.BUTTONS.Ready;
  btnOtherOperation: string = Strings.BUTTONS.OtherOperation;
  amountTotal: string = AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.requiredAmount;
  contentMargin: string = ElementsHTML.ContentMargin;
  modalInstance: NgbModalRef;
  retirement: boolean;
  vm$ = this.responsiveService.observe.pipe(
    tap((breakpoints) =>
      breakpoints.mobile
        ? (this.contentMargin = ElementsHTML.ContentDeductible)
        : (this.contentMargin = ElementsHTML.ContentMarginBreakpoints)
    ),
    combineLatestWith(
      this.aforeService.calculations$,
      this.aforeService.certificates$
    ),
    map(([breakpoints, calculation, certificate]) => ({ breakpoints, calculation, certificate }))
  );

  options: ModalOptions = {
    centered: true,
    size: Constants.MODAL_OPTIONS.SizeMd,
    modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalPartialWithdrawals
  };

  completeProperties: ButtonProperties = {
    doAction: () => {
      this.goPage();
    }
  };

  otherOperationProperties: ButtonProperties = {
    doAction: () => {
      this.close();
    }
  };

  constructor(private readonly accountCardService: AccountCardService,
    private readonly responsiveService: ResponsiveService,
    private readonly partialWithdrawals: PartialWithdrawals,
    private readonly modalService: ModalService,
    public readonly cardAccountService: AccountCardService,
    private readonly notifyRetirementTypeService: NotifyRetirementTypeService,
    private readonly alertService: AlertService,
    private readonly tokenService: TokenService,
    private readonly aforeService: AforeService,
    private readonly currencyPipe: CurrencyPipe,
    public readonly dynamicFormService: DynamicFormService,
    private readonly productsService: ProductsService,
    private readonly aforeDataService: AforeDataService,
    private readonly notifyAmountService: NotifyAmountService
  ) { }

  async ngOnInit() {
    await this.initComponent();
    const noViewDigits = Numbers.Four;
    const date = new Date();
    this.completeDate = formatDate(
      date,
      FormatDate.DayMonthYearComplete,
      Constants.LOCALE_ES_MX
    );
    this.accountCardService.accountCard$.subscribe(
      (data) => {
        this.imgCard = data.image;
        this.nameCard = data.cardType;
        this.card = Strings.HIDDEN_CARD_DIGITS.ThreeDigits + data.cardNumber.substr(data.cardNumber.length - noViewDigits);
      }
    );
    this.getDateToday();
    this.partialWithdrawals.detailPartialObservable.subscribe((data) => {
      this.origin = data.origin;
      this.dataByOrigin = data.origin === AforeStrings.MODAL_ADD_PRODUCT.Imss ?
        data.nss.toString() : data.curp.toString();
    });
    this.aforeService.calculations$.subscribe(
      (data) => {
      this.modality = `${this.modalityLabel} ${data.tipo}`;
      this.salary = `${this.reportedSalary} ${this.currencyPipe.transform(data.salario.toString())}`;
      }
    );
    if (this.notifyRetirementTypeService.retirementTypeBs.value === RetirementType.unemployment) {
      this.retirement = true;
    }
  }

  getDateToday() {
    const n3 = Numbers.Three;
    const a = formatDate(new Date(), Format.DayMonthYearISO, Constants.LOCALE_ES_MX);
    this.formatDateToDay = a.replace(a.charAt(n3), a.charAt(n3).toLowerCase());
  }

  goPage() {
    this.modalService.close();
    this.notifyRetirementTypeService.retirementTypeData = null;
    this.aforeService.sendPdfData(null);
    this.productsService.currentTab = Tab.Account;
  }
  close() {
    this.modalService.close();
    this.aforeService.sendPdfData(null);
  }

  end() {
    this.notifyRetirementTypeService.retirementTypeData = null;
    this.aforeService.aforeInformation = null;
    this.aforeDataService.base64 = Strings.EMPTY;
    this.accountCardService.clearCardAccount();
    this.notifyAmountService.amount = { amount: Strings.EMPTY };
    this.tokenService.reset();
  }

  ngOnDestroy() {
    this.end();
  }

  async initComponent() {
    this.getApplications();
  }

  async getApplications() {
    try {
      const formData = this.dynamicFormService?.formBS?.getValue();
      const spouseGender = formData?.get(AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Gender)?.value === AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Masculine ? Numbers.One : Numbers.Two;
      const request = new AforePartialWithdrawalRegistrationRequest({
        nss: this.aforeService.aforeInformation.nss,
        folioTys: this.aforeService.aforeFolio.folioTys,
        folioAfore: this.aforeService.aforeFolio.folioAfore,
        numeroCuenta: this.accountCardService.accountCard.accountNumber,
        cuentaClabe: this.accountCardService.accountCard.clabe,
        numeroResolucion: this.aforeService.certificates.numeroResolucion,
        llaveNegocio: this.aforeService.aforeFolio.llaveNegocio,
        otp: this.tokenService.otpSignal,
        tipo: this.aforeService.withdrawals === RetirementType.unemployment ? AforeConstants.AFORE_OPTIONS.unemployment : AforeConstants.AFORE_OPTIONS.marriage
      });
      if (this.notifyRetirementTypeService.retirementTypeBs.value === RetirementType.marriage) {
        request.data.datosMatrimonio = {
            primerNombreConyuge:formData.get(AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Name)?.value,
            segundoNombreConyuge:formData.get(AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.SecondName)?.value ?
            formData.get(AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.SecondName)?.value:Strings.EMPTY,
            apellidoPaternoConyuge:formData?.get(AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Pattern)?.value,
            apellidoMaternoConyuge:formData?.get(AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.Maternal)?.value,
            generoConyuge: spouseGender,
            entidadFederativa:formData.get(AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.State)?.value,
            fecha: formData?.get(AforeStrings.AFORE_WITHDRAWAL_MARRIAGE.DateMarriage)?.value?.marriageDate
          };
      }
      await this.aforeService.getAforeRegistration(request);
      this.modalService.modalInstance.update({
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeXl,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalPartialSuccesfulComponent
      });
    } catch (error) {
      this.modalService.close();
      this.aforeService.aforeInformation = null;
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        imgHead: Resources.CLOSE_ICON_RED,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        btnExit: false
      });
    }
  }

  @HostListener('window:popstate')
  onBack() {
    this.modalInstance?.close();
    this.aforeService.aforeInformation = null;
  }

}
