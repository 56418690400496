import { PathModel } from 'src/app/models/PathModel';

export type TGetPDFSipareRequest = {
    idPagoSipare: number;
};

export class GetPDFSipareRequest {
  constructor(public data: TGetPDFSipareRequest) { }

  get endpoint(): PathModel {
      return PathModel.GetSipareReceipt;
  }
}
