export class PayrollCreditConstants {
  static readonly PAYROLL_TIME_AWAIT = 30000;
  static readonly PAYROLL_CREDIT_ID = 'Crédito de Nómina';
  static readonly PAYROLL_CREDIT_ADVANCE_ID = 'Adelanto de Nómina';
  static readonly PAYROLL_ERROR_MESSAGE_LOAD = 'Intentalo mas tarde';
  static readonly PAYROLL_CREDIT_TITLE = 'Crédito en tu nómina';
  static readonly MESSAGE_NAME = 'portalData';
  static readonly PORTAL_RESOLUTION = 'portalResolution';
  static readonly APPLY = 'Solicitar';
  static readonly MESSAGE_TYPE = {
    CloseModal: 'closeModal',
    ErrorCapture: 'errorCapture',
    Error: 'error',
    ShowSpinner: 'showSpinner',
    HideSpinner: 'hideSpinner',
    IsToken: 'isToken',
    IsTokenCloseAlert: 'isTokenCloseAlert',
    iFrameClass: 'iFrameClass',
    NavigateHome: 'navigateHome',
    IsProcessSuccessPwa: 'isProcessSuccessPwa',
    IFrameLoadingInit: 'IFrameLoadingInit',
    PageNavigation: 'pageNavigation',
    BackPage: 'backPage',
    validAmount: 'validAmount',
    validatingAmount: 'validatingAmount',
    showModalHelpToken: 'showModalHelpToken',
    GetToken : 'PortalGetTokenWidget',
    DataLoadPWA: 'DataLoadPWA',
    GenericBridgeOnMessage: 'GenericBridgeOnMessage'
  };

  static readonly NAME_OPTION = {
    PageHomeIFrame: 'pageHomeIFrame',
    PayrollConfirm: 'payroll-confirm',
    PayrollCreditConfirm: 'payroll-credit-confirm-frame'
  };
  static readonly ClassElements = {
    PayrollAdvanceFrame: 'payroll-advance-frame',
    PayrollCreditCompleteFrame: 'payroll-credit-complete-frame',
    ModalContent: 'modal-content'
  };
  static readonly MODAL_OPTIONS = {
    PayrollCredit: 'modal-payroll-credit',
    ConfirmPayroll: 'modal-confirm-payroll'
  };
  static readonly NAME_RESOLUTION = {
    tablet: 'tablet',
    desktop: 'desktop',
    mobile: 'mobile'
  };

  static readonly HTML_CLASS = {
    payrollTermsClass: 'payroll-terms',
    payrollTermsAappClass: 'payroll-terms-app',
    clauses:'clauses',
    clausesAppClass:'clauses-app'
  };
}
