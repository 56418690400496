import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExchangeRateService {
  private readonly _exchangeRate: BehaviorSubject<IExchange> = new BehaviorSubject<IExchange>({ exchangeRateUSD: 0, exchangeRateMXP: 0});
  private _signatureCurrency: string;

  constructor() { }

  get exchange$() {
    return this._exchangeRate.asObservable();
  }

  get exchange(): IExchange {
    return this._exchangeRate.getValue();
  }

  set exchange(data: IExchange) {
    this._exchangeRate.next(data);
  }

  get sigantureCurrency(){
    return this._signatureCurrency;
  }

  set sigantureCurrency(data:string){
    this._signatureCurrency = data;
  }
}

export interface IExchange {
    exchangeRateUSD?: number,
    exchangeRateMXP?:number
}
