import { AlertInfo } from 'src/app/models/AlertInfo';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeleteAccountService } from 'src/app/services/delete-account.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalLoadFilesComponent } from 'src/app/component/modal-load-files/modal-load-files.component';
import { Strings } from 'src/core/constants/Strings';
import { Constants, ElementsHTML, Numbers } from 'src/core/constants/Constants';
import { ModalServicesComponent } from 'src/app/services-module/components/modal-services/modal-services.component';
import { Resources } from 'src/core/constants/Resources';
import { ServiceConstants } from 'src/core/constants/ServiceConstants';
import { AforeStrings } from 'src/core/constants/AforeStrings';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { SuaConstants } from 'src/core/constants/SuaConstants';

@Component({
  selector: 'app-modal-exceptions',
  templateUrl: './modal-exceptions.component.html',
  styleUrls: ['./modal-exceptions.component.css']
})
export class ModalExceptionsComponent implements OnInit, AfterViewInit {
  remove: string = Resources.CLOSE_CIRCLE_BLUE;
  errorProduct: string = Resources.CLOSE_RED_MODAL;
  @Output() modalCall: EventEmitter<string> = new EventEmitter<string>();
  @Input() idModal: string = Strings.EMPTY;
  @Input() headerModal: string = Strings.EMPTY;
  @Input() labelMain: string = Strings.EMPTY;
  @Input() continue: string = Strings.EMPTY;
  @Input() btnPlus: string = Strings.EMPTY;
  @Input() serialNumber: string = Strings.EMPTY;
  @Input() idDevice: string = Strings.EMPTY;
  @Input() alertInformation: AlertInfo;
  @Input() investmentDinbur: boolean = false;
  @Input() hourOperation: boolean = false;
  @Input() showDescription: boolean = true;
  @Input() isNotifications: boolean = false;
  @Input() showRegistries: boolean = true;
  @Input() showServicesModal: boolean = true;
  modalInstance: NgbModalRef;
  line: boolean = false;
  newLine: boolean = false;
  additionalBtn: boolean = false;
  acceptButton: string = Strings.MSG_POPUP_ACCEPT;
  selectCancel: boolean = false;
  cardReplenishment: boolean = false;
  onCancel: () => void;
  onSuccess: () => void;
  deleteBtn: boolean;
  send: boolean = false;
  cancelCardModal: boolean = false;
  lblInfo: string = Strings.EMPTY;
  description: string = Strings.EMPTY;
  showBtnCancel: boolean = false;
  textBtnCancel: string = Strings.EMPTY;
  isSuaPayment: boolean = false;
  modalSuaVisible: boolean = false;
  btnCancelClass: string;
  btnAcceptClass: string;
  descriptionLength: number = Numbers.FortyFive;
  buttonAlign: string = ElementsHTML.ButtonAlign;
  isAddProduct: boolean = false;
  headerText: string = 'header-text';
  mainHeader: string = 'main-header';
  mainLabel: string = 'main-label';
  btnCorrect: string = 'btn-correct';
  showIconClose: boolean = true;
  cancelAccountModal: boolean = false;
  modifyDailyLimitModal: boolean = false;
  questionMarkCrIcon: string = Resources.CIRCLE_BLUE_QUESTION;
  errorIcon: string = Resources.CLOSE_RED_MODAL;
  addFile: string = Resources.PLUS_SQ_FR_TRANSPARENT;

  constructor(private readonly deleteAccountService: DeleteAccountService,
    private readonly modalService: ModalService) {
  }
  ngAfterViewInit(): void {
    let idModal = this.alertInformation ? this.alertInformation.idModal : this.idModal;
    if (this.idModal === Strings.EMPTY) {
      this.idModal = idModal;
      if (!this.idModal && this.alertInformation.title === Strings.MSG_POPUP_TITLE) {
        idModal = Constants.MODAL_OPTIONS.AdditionalAttention;
      }
    }
    this.initModal(idModal);
  }

  ngOnInit(): void {
    this.deleteAccountService.deleteAccount$.subscribe(data => { this.deleteBtn = data.value; });
  }

  initModal(idModal: string) {
    switch (idModal) {
      case Constants.MODAL_OPTIONS.CancelCardModal:
        this.cancelCard();
        break;
      case Constants.MODAL_OPTIONS.SelectedCancelCard:
        this.selectedCancelCard();
        break;
      case Constants.MODAL_OPTIONS.SelectedCardReplenishment:
        this.cardReplenishment = true;
        break;
      case Constants.MODAL_OPTIONS.CancelCard:
        this.cancelCardException();
        break;
      case Constants.MODAL_OPTIONS.Attention:
        this.attention();
        break;
      case Constants.MODAL_ID:
        this.noService();
        break;
      case Constants.MODAL_OPTIONS.DeleteFacepass:
        this.cancel();
        break;
      case Constants.MODAL_OPTIONS.CancelToken:
        this.cancel();
        break;
      default:
        this.description = this.alertInformation === undefined ? this.labelMain : this.alertInformation.description;
        this.initialModalCase(idModal);
        break;
    }
  }

  initialModalCase(idModal: string) {
    switch (idModal) {
      case Constants.MODAL_OPTIONS.SendEmail:
        this.sendEmail();
        break;
      case Constants.MODAL_OPTIONS.CancelAccount:
      case Constants.MODAL_OPTIONS.DeleteNotification:
        this.cancelAccount();
        break;
      case Constants.MODAL_OPTIONS.CancelAccountConfirm:
        this.confirmCancelAccount();
        break;
      case Constants.MODAL_OPTIONS.AdditionalAttention:
        this.additionalAttention();
        break;
      case Constants.MODAL_OPTIONS.SuaModal:
        this.suaPayment();
        break;
      case Constants.MODAL_OPTIONS.NotFileId:
        this.modalNotFile();
        break;
      case AforeStrings.MODAL_ADD_PRODUCT.ModalID:
        this.addProductError();
        break;
      default:
        this.initialModalValidation(idModal);
        break;
    }
  }

  initialModalValidation(idModal: string) {
    if (idModal === ServiceConstants.MODIFY_DAILY_LIMIT){
        this.modifyDailyLimit();
    }
  }

  cancelCard() {
    this.cancelCardModal = true;
    this.additionalBtn = true;
    this.btnPlus = Constants.CANCEL_BUTTON;
    document.getElementById('modal').className = 'modal-select-cancel';
    document.getElementById(this.headerText).className = 'header-text-cancel-card-modal';
    document.getElementById(this.mainHeader).className = 'label-general-header-cancel-card-modal';
    document.getElementById(this.mainLabel).className = 'label-general-main center-attention-cancel-card label-general-main-select-cancel';
    document.getElementById('div-btn').className = 'top-btn-cancel';
  }

  selectedCancelCard() {
    document.getElementById(this.headerText).className = 'header-text-cancel-card-modal';
    document.getElementById(this.mainHeader).className = 'label-general-header-select-cancel padding-attention';
    document.getElementById(this.mainLabel).className = 'label-general-main center-attention-cancel-card label-general-main-select-cancel label-main-card';
  }

  cancelCardException() {
    this.description = this.alertInformation === undefined ? this.labelMain : this.alertInformation.description;
    document.getElementById(this.mainHeader).innerHTML = this.headerModal;
    document.getElementById('div-btn').className = 'btn-align-cancel-card';
    document.getElementById(this.mainLabel).className = 'body-m label-general-main label-cancel-card';
  }

  attention() {
    this.lblInfo = this.alertInformation === undefined ? this.headerModal : this.alertInformation.title;
    this.additionalBtn = false;
    this.btnPlus = Constants.CANCEL_BUTTON;
    document.getElementById(this.mainLabel).innerHTML = this.labelMain;
    document.getElementById('div-btn').className = 'btn-align top-btn-additional';
    document.getElementById('modal').className = 'frame-attention';
    document.getElementById('btn-close').className = 'src-attention';
    this.remove = Resources.CLOSE_CIRCLE_BLUE;
  }

  noService() {
    this.line = true;
    this.lblInfo = this.alertInformation === undefined ? this.headerModal : this.alertInformation.title;
    this.description = this.alertInformation === undefined ? this.labelMain : this.alertInformation.description;
    document.getElementById('main').className = 'class-no-service';
    document.getElementById(this.mainLabel).innerHTML = this.labelMain;
    document.getElementById('btn-close').className = 'src-no-service';
    document.getElementById('modal').className = 'frame-no-service';
    document.getElementById(this.mainLabel).className = 'header-l font-size-token label-main-no-service';
    document.getElementById(this.btnCorrect).className = 'btn-general-accept btn-no-service';
    document.getElementById(this.headerText).className = this.headerText;
    document.getElementById('div-btn').className = 'btn-align-no-service';
    this.remove = Resources.CLOSE_SQUARE;
  }

  cancel() {
    this.description = this.alertInformation === undefined ? this.labelMain : this.alertInformation.description;
    document.getElementById(this.mainHeader).innerHTML = this.headerModal;
    document.getElementById(this.mainLabel).innerHTML = this.labelMain;
    document.getElementById(this.mainLabel).className = 'body-m delete-text';
    document.getElementById('div-btn').className = this.buttonAlign;
  }

  sendEmail() {
    this.description = this.alertInformation === undefined ? this.labelMain : this.alertInformation.description;
    document.getElementById(this.mainHeader).innerHTML = this.alertInformation.title;
    document.getElementById('div-btn').className = this.buttonAlign;
    document.getElementById(this.mainLabel).className = 'body-m label-general-main ';
    document.getElementById('div-btn').className = 'btn-align top-delete';
  }

  cancelAccount() {
    this.description = this.alertInformation === undefined ? this.labelMain : this.alertInformation.description;
    this.btnPlus = Constants.NOT;
    document.getElementById(this.mainHeader).innerHTML = this.headerModal;
    document.getElementById(this.mainLabel).innerHTML = this.labelMain;
    document.getElementById(this.mainHeader).className = 'header-l label-general-header header-international-header';
    document.getElementById('div-btn').className = this.isNotifications ? 'btn-align gap-btn-international' : 'btn-align gap-btn-international top-delete';
    document.getElementById(this.btnCorrect).className = 'btn-general-accept btn-international-modal btn-international btn-ok';
    document.getElementById('btn-international-modal').className = 'btn-general-accept btn-international-modal btn-international';
    if (this.showIconClose) {
      document.getElementById('btn-close').className = 'src-general-question';
      this.remove = this.isNotifications ? Resources.CIRCLE_BLUE_QUESTION : Resources.QUESTION_MARK_LIGHT_BLUE;
    }
  }

  confirmCancelAccount() {
    this.description = this.alertInformation === undefined ? this.labelMain : this.alertInformation.description;
    document.getElementById(this.mainLabel).innerHTML = this.labelMain;
    document.getElementById('btn-close').className = 'src-check-international';
    this.remove = Resources.CHECK;
  }

  additionalAttention() {
    this.description = this.alertInformation === undefined ? this.labelMain : this.alertInformation.description;
    document.getElementById(this.mainHeader).className = 'header-l padding-attention';
    document.getElementById('additional-class').className = 'd-flex flex-row col-12 pl-2 btn-align-no-service pb-3';
    document.getElementById(this.mainLabel).className = 'body-m label-general-main center-attention';
    if (this.alertInformation.description.length <= this.descriptionLength) {
      document.getElementById(this.mainLabel).className = 'body-m label-general-main';
    }
    document.getElementById(this.btnCorrect).className = 'btn-general-accept width-general btn-generic';
    document.getElementById('span-class').className = 'span-class';
  }

  suaPayment() {
    this.newLine = true;
    this.showBtnCancel = true;
    this.isSuaPayment = true;
    this.textBtnCancel = Constants.CANCEL_BUTTON;
    this.remove = Resources.CLOSE_CIRCLE_BLUE;
    this.description = this.alertInformation === undefined ? this.labelMain : this.alertInformation.description;
    document.getElementById('modal').className = 'no-border';
    document.getElementById('div-btn').className = SuaConstants.ALIGN_EXCEPTION_BUTTONS;
    document.getElementById('div-btn').classList.remove('top-general-btn');
    document.getElementById(this.headerText).classList.remove('pt-3');
    document.getElementById(this.mainHeader).className = 'header-sua';
    document.getElementById(this.btnCorrect).className = 'new-sua-payment-btn';
    document.getElementById('close-modal').className = SuaConstants.ACCEPT_BUTTON_EXCEPTION;
    document.getElementById('btn-close').className = SuaConstants.CLOSE_BUTTON_EXCEPTION;
  }

  modalNotFile() {
    this.description = this.alertInformation === undefined ? this.labelMain : this.alertInformation.description;
    document.getElementById(this.mainLabel).innerHTML = this.labelMain;
    document.getElementById(this.mainHeader).innerHTML = this.headerModal;
    document.getElementById('div-btn').className = 'btn-align top-btn-additional';
    document.getElementById('modal').className = 'no-border text-align-center';
    document.getElementById('btn-close').className = 'src-attention';
    document.getElementById('div-btn').className = 'mb-3 mt-2 d-flex justify-content-center text-center';
    document.getElementById(this.mainHeader).className = 'label-general-header header-l header-not-file';
  }

  addProductError() {
    document.getElementById(this.headerText).className = 'col-12 flex-column-style justify-content-center content-center pad-3';
    document.getElementById(this.mainLabel).innerHTML = this.labelMain;
    document.getElementById('modal').className = 'header-error-add-product';
    document.getElementById(this.mainHeader).className = 'header-s width-main-header';
    document.getElementById('div-add').className = 'p-28';
    document.getElementById(this.mainLabel).className = 'detail-m label-general-main width-main-label';
    document.getElementById('additional-class').className = 'div-with-main-label';
    document.getElementById(this.btnCorrect).className = 'button-m btn-ok-error-add-product btn-modal-generic';
    document.getElementById('div-btn').className = 'p-l-8';
    this.isAddProduct = true;
  }

  modifyDailyLimit() {
    this.description = this.alertInformation === undefined ? this.labelMain : this.alertInformation.description;
    this.btnPlus = Constants.CANCEL_BUTTON;
    this.modifyDailyLimitModal = true;
    document.getElementById(this.headerText).className = 'col-12 flex-column-style justify-content-center content-center pad-3 mt-3';
    document.getElementById(this.mainHeader).className = 'title header-s mt-4';
    document.getElementById('div-btn').className = 'btn-align gap-btn-limits';
    document.getElementById(this.btnCorrect).className = 'btn-general-accept btn-international-modal btn-limits btn-ok';
    document.getElementById('btn-international-modal').className = 'btn-general-accept btn-international-modal btn-limits';
  }

  closeModal(data?: string) {
    if (this.idModal === ServiceConstants.MODIFY_DAILY_LIMIT) {
      this.modalService.close();
    } else {
      if (this.idModal === Constants.MODAL_OPTIONS.SuaModal) {
        this.cancelModal();
      }
      if (this.idModal === Constants.MODAL_OPTIONS.DeleteNotification){
        this.modalCall.emit(data);
        this.modalService.close();
      }
      if (this.idModal === Constants.MODAL_OPTIONS.NotFileId) {
        this.suaLoadFileModal();
      } else {
        this.deleteAccountService.deleteAccount = { value: false };
        this.modalCall.emit(data);
        this.onCancel?.();
      }
    }
  }

  async accept() {
    switch (this.idModal) {
      case Constants.MODAL_OPTIONS.DeleteFacepass:
      case Constants.MODAL_OPTIONS.CancelToken:
      case Constants.MODAL_OPTIONS.CancelAccount:
      case Constants.MODAL_OPTIONS.DeleteNotification:
        this.closeModal(Constants.MODAL_OPTIONS.LabelConfirm);
        break;
      case Constants.MODAL_OPTIONS.CancelTokenConfirm:
      case Constants.MODAL_OPTIONS.DeleteFacepassConfirm:
      case Constants.MODAL_OPTIONS.ErrorModal:
      case Constants.MODAL_OPTIONS.CancelAccountConfirm:
        this.closeModal(Constants.MODAL_OPTIONS.CloseModal);
        break;
      default:
        this.acceptModal();
        break;
    }
    this.onSuccess?.();
  }

  acceptModal() {
    switch (this.idModal) {
      case AforeStrings.MODAL_ADD_PRODUCT.ModalID:
        this.modalService.close();
        break;
      case Constants.MODAL_OPTIONS.CancelAction:
        this.closeModal(Strings.MODAL_MODIFY_ACCOUNT.ConfirmId);
        break;
      case Constants.MODAL_OPTIONS.SuaModal:
      case Constants.MODAL_OPTIONS.NotFileId:
        this.suaLoadFileModal();
        break;
      case ServiceConstants.MODIFY_DAILY_LIMIT:
        this.dailyLimitModal();
        break;
    }
  }

  suaLoadFileModal() {
    this.modalService.close();
    const modalOptions: ModalOptions = {
      centered: true,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalSuaLoadFile
    };
    const modalRef = this.modalService.open(ModalLoadFilesComponent, modalOptions);
    modalRef.componentInstance.showRegistries = this.showRegistries;
  }

  cancelModal() {
    this.modalService.close();
    if (!this.modifyDailyLimitModal  && this.idModal !== Constants.MODAL_OPTIONS.SuaModal) {
      const modalOptions: ModalOptions = {
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeLg,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.OtherServices
      };
      this.modalService.open(ModalServicesComponent, modalOptions);

    }
  }

  dailyLimitModal() {
    this.modalCall.emit();
  }
}
