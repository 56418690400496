import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { Strings } from 'src/core/constants/Strings';
import { DataCheckBook } from 'src/app/interface/DataCheckBook';
import { Numbers } from 'src/core/constants/Numbers';
import { HttpStatusCode } from '@angular/common/http';
import { Utils } from 'src/core/utils/utils';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { GetCheckBooksRequest } from 'src/app/interface/dto/GetCheckBooksRequest';
import { GetCheckBooksResponse } from 'src/app/interface/dto/GetCheckBooksResponse';
import { Char, Constants } from 'src/core/constants/Constants';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { StatusChecks } from 'src/core/constants/ChecksStrings';


@Injectable({
  providedIn: 'root'
})

export class CheckBookConsultationService {

  private errorMessage: string = Strings.EMPTY;
  private errorCode: number;
  private readonly checkBookArray: BehaviorSubject<DataCheckBook[]> = new BehaviorSubject<DataCheckBook[]>([]);

  constructor(private readonly baseService: IBaseService,
    private readonly accountInfoService: AccountInfoStatusService) {}

  get checkBooks$(): Observable<DataCheckBook[]> {
    return this.checkBookArray.asObservable();
  }

  get checkBooks(): DataCheckBook[] {
    return this.checkBookArray.getValue();
  }

  set checkBooks(data: DataCheckBook[]) {
    this.checkBookArray.next(data);
  }

  get errorMessageData() {
    return this.errorMessage;
  }

  set errorMessageData(data: string) {
    this.errorMessage = data;
  }

  get errorCodeData() {
    return this.errorCode;
  }

  set errorCodeData(data: number) {
    this.errorCode = data;
  }

  clearCheckBooks() {
    this.checkBookArray.next([]);
    this.errorMessage = Strings.EMPTY;
    this.errorCode = null;
  }

  async initCheckBooks(){
    try {
       const response = await this.getCheckBooks(this.getCheckBooksRequest());
       const checkBooksData = [];
       response.forEach((checkBook) => {
          checkBooksData.push({
           id: checkBook.idChequera,
           numberCheckBook: (checkBook.folioInicial.toString()).concat(Char.MiddleDash).concat(checkBook.folioFinal.toString()),
           state: checkBook.estatusChequera,
           initialNumber: checkBook.folioInicial,
           active: true
         });
       });
       checkBooksData.sort((a, b) => (a.initialNumber < b.initialNumber ) ? Constants.NOT_FOUND_ITEM : Numbers.One);
       this.checkBookArray.next(checkBooksData);
     } catch(error){
       const errorDetails = Utils.getErrorMsg(error);
       this.errorMessage = errorDetails.msg;
       this.errorCode = errorDetails.code;
     }
  }

  async getCheckBooks(request: GetCheckBooksRequest): Promise<GetCheckBooksResponse>{
    const response = await this.baseService.genericPost<GenericResponse<GetCheckBooksResponse>>(request, {
      isSecure: true, addSession: true
    });

    if(!response && !response?.datos){
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, HttpStatusCode.NoContent);
    }
    return response.datos;
  }

  private getCheckBooksRequest() {
    return new GetCheckBooksRequest({
      estatus: [StatusChecks.requested.toUpperCase(),
      StatusChecks.canceled.toUpperCase(),
      StatusChecks.active.toUpperCase(),
      StatusChecks.printed.toUpperCase(),
      StatusChecks.delivered.toUpperCase()],
      numeroCuenta: this.accountInfoService.account.numeroCuenta
    });
  }

}
