<ng-container *ngIf="breakpoints$ | async as breakpoints">
  <div class="footer px-4" [ngClass]="breakpoints.tablet ? 'pt-3 pb-4': 'py-3'">
    <a
      [href]="noticePrivacy"
      [href]="noticePrivacy"
      target="_blank"
      class="detail-m-highlight"
      >Política de Privacidad</a
      >
      <a
      [href]="legalWarning"
      [href]="legalWarning"
      target="_blank"
      class="detail-m-highlight"
      >Condiciones de Uso</a
      >
      <a
      [href]="documentation"
      target="_blank"
      class="detail-m-highlight"
      >Política de Integridad Corporativa</a
    >
    <span class="textInbursa detail-m-highlight"
      >© {{ year }} Grupo Financiero Inbursa</span>

    <div class="d-flex flex-column align-items-center conditions"
    [class.pt-3]="breakpoints.tablet" [class.pb-2]="breakpoints.tablet">
      <span [class.text-center]="breakpoints.mobile" class="detail-m font-weight-bold secondary-color">
        Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (CONDUSEF)
      </span>
      <div [class.text-center]="breakpoints.mobile">
        <span class="detail-m secondary-color">Teléfono: (55) 5340 0999 / </span>
        <a
          (click)="confirmLeavePage(condusef)"
          class="detail-m-highlight primary-link cursor-pointer">
          www.condusef.gob.mx
        </a>
      </div>
    </div>
  </div>

</ng-container>
