import { PathModel } from 'src/app/models/PathModel';

export interface IPayAdditionalContributionsRequest {
    emisor: string;
    carpeta: string;
    monto: number;
    idCuentaProducto: number;
    numeroCuenta: string;
}

export class PayAdditionalContributionsRequest {
    constructor(public data: IPayAdditionalContributionsRequest) { }

    get endpoint(): PathModel {
        return PathModel.PayAdditionalContributions;
    }
}