import { PathModel } from 'src/app/models/PathModel';

export interface ICheckAccountLimitCTRequest{
    IdSession: string,
    IdentificadorCuenta: string
}
export class CheckAccountLimitCTRequest{
    constructor(public data: ICheckAccountLimitCTRequest){}

    get endpoint(): PathModel{
        return PathModel.CheckAccountLimitCT;
    }
}