export class LocationConstants {
  static readonly METHOD = 'nearLocations';
  static readonly MAX_HEIGHT_MAP = 411;
  static readonly BRANCH_ID = '1';
  static readonly PHONE_TITLE = 'Teléfono';
  static readonly ICON_HEIGHT = 30;
  static readonly ICON_WIDTH = 25;
  static readonly NAME = 'Sucursal';
  static readonly BRANCH_NUMBER = 'Num. de Sucursal';
  static readonly LOCATION_MODAL = {
    DesktopPDF: 'PDF/ManualGeolocalizacionDesktop.pdf',
    TabletPDF : 'PDF/ManualGeolocalizacionTablet.pdf',
    MobilePDF : 'PDF/ManualGeolocalizacionMovil.pdf'
  };
}
