import { PathModel } from 'src/app/models/PathModel';

interface IDeleteUserImageRequest{
    idUsuario: number;
    tipo: number;
}

export class DeleteUserImageRequest {
    constructor(public data: IDeleteUserImageRequest){ }

    public get endpoint(): PathModel {
        return PathModel.DeleteUserImage;
    }
}