import { Component, HostListener, OnInit } from '@angular/core';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { Constants } from 'src/core/constants/Constants';
import { ModalBureauAlertComponent } from 'src/app/component/modal-bureau-alert/modal-bureau-alert.component';
import { Utils } from 'src/core/utils/utils';
import { BureauAlertsService } from 'src/app/services/bureau-alerts.service';
import { AlertService } from 'src/app/services/alert.service';
import { StorageService } from 'src/app/services/storage.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BureauAlertsStrings } from 'src/core/constants/BureauAlertsStrings';
import { ModalConstants } from 'src/core/constants/ModalConstants';

@Component({
  selector: 'app-modal-free-bureau-alert',
  templateUrl: './modal-free-bureau-alert.component.html',
  styleUrls: ['./modal-free-bureau-alert.component.css']
})
export class ModalFreeBureauAlertComponent implements OnInit {
  breakpoints$ = this.responsiveService.observe;
  title: string = BureauAlertsStrings.BUREAU_ALERTS.FreeBureauAlert.Title;
  clientName: string;
  paragraphOne: string = BureauAlertsStrings.BUREAU_ALERTS.FreeBureauAlert.ParagraphOne;
  paragraphTwo: string = BureauAlertsStrings.BUREAU_ALERTS.FreeBureauAlert.ParagraphTwo;
  items: string[] = BureauAlertsStrings.BUREAU_ALERTS.FreeBureauAlert.Items;
  paragraphThree: string = BureauAlertsStrings.BUREAU_ALERTS.FreeBureauAlert.ParagraphThree;
  paragraphFour: string = BureauAlertsStrings.BUREAU_ALERTS.FreeBureauAlert.ParagraphFour;
  authorization: string = BureauAlertsStrings.BUREAU_ALERTS.FreeBureauAlert.Authorization;
  contractBureau: string = BureauAlertsStrings.BUREAU_ALERTS.FreeBureauAlert.ContractBureau;
  bureauUrl: string = BureauAlertsStrings.BUREAU_ALERTS.FreeBureauAlert.BureauUrl;
  hrefBureau: string = BureauAlertsStrings.BUREAU_ALERTS.FreeBureauAlert.HrefBureau;
  hire: string = BureauAlertsStrings.BUREAU_ALERTS.FreeBureauAlert.Hire;
  cancel: string = BureauAlertsStrings.BUREAU_ALERTS.FreeBureauAlert.Cancel;
  removeIcon: string = Resources.CLOSE_CIRCLE_BLUE;
  wfContract: string = Resources.wfContract;
  isChecked: boolean = false;
  isPhone: boolean = false;
  isiPad: boolean = false;
  isIphone: boolean = false;
  modalInstance: NgbModalRef;
  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly bureauAlertsService: BureauAlertsService,
    private readonly alertService: AlertService,
    private readonly storage: StorageService
  ) { }

  ngOnInit(): void {
    const dataUser = this.storage.getUser();
    this.clientName = `${dataUser.name} ${dataUser.lastName}`;
    this.isMobile();
    this.isTabletIos();
  }

  onCheckboxChange(event: Event) {
    this.isChecked = (event.target as HTMLInputElement).checked;
  }

  async continue() {
    if (this.isChecked) {
      this.close();
      try {
        const response = await this.bureauAlertsService.getEmail();
        this.bureauAlertsService.dataBureauAlert.oldEmail = response.datos;

        const modalOptions: ModalOptions = {
          centered: false,
          size: Constants.MODAL_OPTIONS.SizeSm,
          windowClass: ModalConstants.MODAL_OPTIONS.GenericWindowClass,
          modalDialogClass: ModalConstants.MODAL_OPTIONS.IsCentered
        };
        this.modalService.open(ModalBureauAlertComponent, modalOptions, response.datos);
      } catch (error) {
        const errorDetail = Utils.getErrorMsg(error);
        const alertInstance = this.alertService.showPopupAlert({
          message: Utils.SERVICE_ERROR_MSG(errorDetail.msg, errorDetail.code),
          header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
          btnLabel: Strings.MSG_POPUP_ACCEPT,
          imgHead: Resources.CLOSE_ICON_RED,
          btnExit: false
        }, {
          onSuccess: () => alertInstance.dismiss(),
          onClose: () => alertInstance.dismiss()
        });
      }
    }
  }

  close() {
    this.modalInstance?.close();
  }

  isMobile() {
    const agent = navigator.userAgent;
    const maxWidth = Constants.RESOLUTIONS.PhoneMaxWidth;
    const minWidth = Constants.RESOLUTIONS.PhoneMinWidth;
    if (((window.screen.width <= maxWidth) && (window.screen.width >= minWidth))
      && agent.match(Constants.USER_AGENT.MobileIos)) {
      this.isPhone = true;
      if (agent.match(Constants.USER_AGENT.Iphone)) {
        this.isIphone = true;
      } else {
        this.isIphone = false;
      }
    } else {
      this.isPhone = false;
      this.isIphone = false;
    }
  }

  isTabletIos() {
    const agent = navigator.userAgent;
    const iPadpMinWidth = Constants.RESOLUTIONS.IpadMinWidth;
    const iPadMaxWidth = Constants.RESOLUTIONS.TabletMinWidth;
    if (((window.screen.width <= iPadpMinWidth) && (window.screen.width >= iPadMaxWidth))
      && agent.match(Constants.USER_AGENT.TabletIos)) {
      this.isiPad = true;
    } else {
      this.isiPad = false;
    }
  }

  confirmLeavePage(link: string) {
    this.modalService.close();
    this.bureauAlertsService.leaveModal.value = true;
    Utils.confirmLeavePage(link, this.modalService);
  }

  @HostListener('window:popstate')
  onBack() {
    this.close();
  }
}
