import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Strings } from 'src/core/constants/Strings';
import { Char, Constants, Length, Numbers, Position } from 'src/core/constants/Constants';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { SUA } from 'src/app/interface/SUA';
import { SuaConsultRegistriesRequest } from 'src/app/interface/dto/SuaConsultRegistriesRequest';
import { SuaConstants } from 'src/core/constants/SuaConstants';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ModalPaymentReceiptComponent } from 'src/app/component/modal-payment-receipt/modal-payment-receipt.component';
import { ModalExceptionsComponent } from 'src/app/shared/components/modal-exceptions/modal-exceptions.component';
import { ModalService } from 'src/app/shared/modal.service';
import { SuaUploadFileRequest } from 'src/app/interface/dto/SuaUploadFileRequest';
import { ModalSentFilesComponent } from 'src/app/component/modal-sent-files/modal-sent-files.component';
import { FileDataService } from 'src/app/services/file-data.service';
import { SuaPaymentRequest } from 'src/app/interface/dto/SuaPaymentRequest';
import { AccountCardService } from 'src/app/services/account-card.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { SuaStatus } from 'src/app/interface/SuaStatus';
import { SuaPayment } from 'src/app/interface/SuaPayment';
import { SuaGetPDFRequest } from 'src/app/interface/dto/SuaGetPDFRequest';
import { StorageService } from 'src/app/services/storage.service';
import { SuaGetPDFResponse } from 'src/app/interface/dto/SuaGetPDFResponse';

@Injectable({
  providedIn: 'root'
})
export class SuaService {

  constructor(private readonly baseService: IBaseService,
    private readonly modalService: ModalService,
    private readonly fileDataService: FileDataService,
    private readonly accountCardService: AccountCardService,
    private readonly storageService: StorageService
  ) { }

  private readonly status : SuaStatus = {
    Autorizado: {
      label: Strings.PAYMENT_RECEIPT.Authorized,
      class: Strings.ACTIVE
    },
    Validado: {
      label: Strings.PAYMENT_RECEIPT.Validated,
      class: Strings.PAYMENT_RECEIPT.Pending
    },
    Rechazado: {
      label: Strings.PAYMENT_RECEIPT.Refused,
      class: Strings.PAYMENT_RECEIPT.RefusedClass
    },
  };

  private readonly defaultData: SuaPayment = {
    folio    : Strings.EMPTY,
    name     : Strings.EMPTY,
    registry : Strings.EMPTY,
    quantity : Strings.EMPTY,
    status   : {label: Strings.EMPTY, class: Strings.EMPTY},
    movement : {date: Strings.EMPTY, time: Strings.EMPTY},
    period   : Strings.EMPTY,
    rfc      : Strings.EMPTY,
    idSuaPago: null
  };

  private readonly _registries        : BehaviorSubject<SuaPayment[]> = new BehaviorSubject<SuaPayment[]>([]);
  private readonly _selectedRegistry  : BehaviorSubject<SuaPayment>   = new BehaviorSubject<SuaPayment>(this.defaultData);
  private readonly _uploadedFile      : BehaviorSubject<SUA>          = new BehaviorSubject<SUA>(null);

  get registries$(){
    return this._registries.asObservable();
  }

  get registries(): SuaPayment[] {
    return this._registries.getValue();
  }

  set registries(data: SuaPayment[]) {
    this._registries.next(data);
  }

  get selectedRegistry$(){
    return this._selectedRegistry.asObservable();
  }

  get selectedRegistry(): SuaPayment {
    return this._selectedRegistry.getValue();
  }

  set selectedRegistry(data: SuaPayment) {
    this._selectedRegistry.next(data);
  }

  get uploadedFile(): SUA {
    return this._uploadedFile.getValue();
  }

  set uploadedFile(file: SUA) {
    this._uploadedFile.next(file);
  }

  clearData() {
    this._registries.next([]);
  }

  clearSelected() {
    this._selectedRegistry.next(null);
  }

  async consultRegistries() {
    const request = new SuaConsultRegistriesRequest();
    const response = await this.baseService.genericGet<GenericResponse<SUA[]>>(request, { isSecure: true, addSession: true });
    const validStatus = [Strings.PAYMENT_RECEIPT.Authorized, Strings.PAYMENT_RECEIPT.Validated, Strings.PAYMENT_RECEIPT.Refused];
    if (!response) {
      return false;
    }
    this.clearSelected();
    this.clearData();
    response.datos.forEach(item => {
      if(validStatus.includes(item.estatus)){
        const [itemDate, itemTime] = item.fechaActualizacion ? item.fechaActualizacion.split(SuaConstants.LETTER_T_REGEX) : [Strings.EMPTY,Strings.EMPTY];
        const parts = itemDate !== Strings.EMPTY ? itemDate.split(Char.MiddleDash) : [];
        const formatTime = this.removeLetterT(itemTime);
        const dateFormatted = parts.length > Length.Empty ? parts[Position.Two].concat(Char.Slash, parts[Position.One], Char.Slash, parts[Position.Zero]) : Strings.EMPTY;
        this.registries.push({
          folio    : item.folioSua,
          name     : item.nombreArchivo,
          registry : item.registroPatronal ? item.registroPatronal.concat(Char.WhiteSpace, item.periodo) : item.periodo,
          quantity : item.monto.toString(),
          status   : this.status[item.estatus],
          movement : { date: dateFormatted, time: formatTime },
          period   : item.periodo,
          rfc      : item.rfc,
          idSuaPago: item.idSuaPago
        });
      }
    });
    this.registries.sort((a,b) => b.folio?.localeCompare(a.folio));
    return this.registries.length > Length.Empty;
  }

  removeLetterT(time: string){
    return time !== Strings.EMPTY ? time.substring(Numbers.One) : time;
  }

  async validateModalToDisplay(){
    const registries = await this.consultRegistries();
    if(registries) {
      const modalOptions: ModalOptions = {
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalPaymentReceipt
      };
      this.modalService.open(ModalPaymentReceiptComponent, modalOptions);
    } else {
      const modalOptionsSua: ModalOptions = {
        centered: true,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.SuaModalOptions
      };
      const exception = this.modalService.open(ModalExceptionsComponent, modalOptionsSua);
      exception.componentInstance.idModal = Constants.MODAL_OPTIONS.SuaModal;
      exception.componentInstance.headerModal = Strings.SERVICES.WithoutPayments;
      exception.componentInstance.labelMain = Strings.EMPTY;
      exception.componentInstance.continue = Strings.SERVICES.AddNewPayment;
      exception.componentInstance.showRegistries = false;
    }
  }

  async uploadFile(sizeFile: number) {
    const formData: FormData = new FormData();
    const file = this.fileDataService.data;
    formData.append(SuaConstants.FIELD_NAME_SUA, file, file.name);
    const request = new SuaUploadFileRequest(formData);
    const response = (await this.baseService.genericPost<GenericResponse<SUA>>(request, { isSecure: true, addSession: true })).datos;
    const convertedData = {
      size:               sizeFile,
      idSuaPago:          response.idSuaPago,
      nombreArchivo:      response.nombreArchivo,
      folioSua:           response.folioSua,
      registroPatronal:   response.registroPatronal,
      monto:              response.monto,
      estatus:            response.estatus,
      fechaActualizacion: response.fechaActualizacion,
      tamanio:            response.tamanio,
      rfc:                response.rfc,
      periodo:            response.periodo
    };
    this.uploadedFile = convertedData;
    this.modalService.close();
    const modalOptions: ModalOptions = {
      centered: true,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalSentFiles
    };
    this.modalService.open(ModalSentFilesComponent, modalOptions, true);
  }

  async suaPayment(otpCode: string) {
    const selectedCard = this.accountCardService.accountCard;
    const idPayment = this.selectedRegistry.idSuaPago;
    const request = new SuaPaymentRequest({
      idSuaPago       : idPayment,
      idCuentaProducto: selectedCard.productId,
      numeroCuenta    : selectedCard.accountNumber,
      otp             : otpCode
    });
    const response = await this.baseService.genericPost<GenericResponse<number>>(request, { isSecure: true, addSession: true });
    return response.datos.toString();
  }

  async getSuaPaymentVaucher(){
    const request = new SuaGetPDFRequest({
      idSesion: this.storageService.getSession(),
      idSuaPago: this.selectedRegistry.idSuaPago
    });
    return this.baseService.genericPost<SuaGetPDFResponse>(request, {isSOA: true});
  }
}
