import { Injectable, WritableSignal, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StatusChecks, StringsChecks } from 'src/core/constants/ChecksStrings';
import { Constants, Length } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { ValueOf } from 'src/types/Generics';
import { CheckBookConsultationService } from 'src/app/services/check-book-consultation.service';
import { ServiceCheckTabIdService } from 'src/app/services/service-check-tab-id.service';

export interface HomeChecksButtons {
  protection: boolean;
  cancelation: boolean;
}

type TabToSelect =  StringsChecks.checkbookInquiry |  StringsChecks.checkInquiry | StringsChecks.activation;

type PreviousOption = ValueOf<typeof Constants.APPLICATION> | ValueOf<typeof Constants.CONSULT>;

@Injectable({
  providedIn: 'root'
})
export class HomeChecksService {
  private readonly _show: BehaviorSubject<{
    protection: boolean;
    cancelation: boolean;
  }> = new BehaviorSubject<{ protection: boolean; cancelation: boolean }>({
    protection: false,
    cancelation: false
  });

  private readonly _fromOption: WritableSignal<PreviousOption> = signal<PreviousOption>(Strings.EMPTY);
  private readonly _tabToSelect: WritableSignal<TabToSelect> = signal<TabToSelect>(StringsChecks.checkbookInquiry);

  constructor (
    private readonly checkBookConsultationService: CheckBookConsultationService,
    private readonly serviceCheckTabIdService: ServiceCheckTabIdService
  ) {}

  get show$() {
    return this._show.asObservable();
  }

  set show(data: HomeChecksButtons) {
    this._show.next(data);
  }

  get tabToSelect() {
    return this._tabToSelect();
  }

  set tabToSelect(data: TabToSelect) {
    this._tabToSelect.update(() => data);
  }

  get fromOption() {
    return this._fromOption();
  }

  set fromOption(data: PreviousOption) {
    this._fromOption.update(() => data);
  }

  showTabs(){
    let showTabChecks = false;
    let showTabActivation = false;
    showTabChecks = this.checkBookConsultationService.checkBooks.filter(item => item.state.toLowerCase().
    includes(StatusChecks.activated.toLocaleLowerCase())).length > Length.Empty;
    showTabActivation =  this.checkBookConsultationService.checkBooks.filter(item => item.state.toLowerCase().
    includes(StatusChecks.delivered.toLocaleLowerCase())).length > Length.Empty;
    this.serviceCheckTabIdService.showTabs = {checks: showTabChecks, activation: showTabActivation};
  }

}
