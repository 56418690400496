import { PathModel } from 'src/app/models/PathModel';

type TSaveUserImageRequest = {
    idUsuario: number;
    imagen: string;
    tipo: number;
};


export class SaveUserImageRequest {
    constructor(public data: TSaveUserImageRequest){ }

    public get endpoint(): PathModel {
        return PathModel.SaveUserImage;
    }
}