import { Component, HostListener } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-investment-maturity-info',
  templateUrl: './investment-maturity-info.component.html',
  styleUrls: ['./investment-maturity-info.component.css']
})
export class InvestmentMaturityInfoComponent {
  modalInstance: NgbModalRef;
  
  close() {
    this.modalInstance?.close();
  }

  @HostListener('window:popstate')
  onBack() {
    this.modalInstance?.close();
  }
}