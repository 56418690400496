import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { ModalService } from 'src/app/shared/modal.service';
import { BreakpointState, ResponsiveService } from 'src/app/shared/responsive.service';
import { Constants } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { Resources } from 'src/core/constants/Resources';
import { EmailService } from 'src/app/services/email.service';
import { ControlCardService } from 'src/app/services/control-card.service';
import { ModalReplacementRequestedComponent } from 'src/app/control-card/components/modal-replacement-requested/modal-replacement-requested.component';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ModalRequestConfirmationComponent } from 'src/app/control-card/components/modal-request-confirmation/modal-request-confirmation.component';
import { FormRegex } from 'src/core/constants/FormConstants';

@Component({
  selector: 'app-modal-send-email',
  templateUrl: './modal-send-email.component.html',
  styleUrls: ['./modal-send-email.component.css']
})
export class ModalSendEmailComponent implements OnInit {
  modalInstance: NgbModalRef;
  emailDataForm!: FormGroup;
  modalData: { NumeroReferencia: number, NumeroCuenta: string, email:string };
  emailLength: number = Constants.MAXIMUM_LENGTH_EMAIL;
  commentsLength: number = Constants.LENGTH_EMAIL_MESSAGE;
  emailCopiesLength: number = Constants.LENGTH_EMAIL_COPIES;
  regexEmail: RegExp = FormRegex.DenyValidateEmailWithComma;
  regexComments: RegExp = FormRegex.DenyOnlyLettersAccentsNumbers;
  vm$: Observable<BreakpointState> = this.responsiveService.observe;
  iconClose: string = Resources.CLOSE_CIRCLE_BLUE;
  errorMsg: string = Strings.GENERIC_ERRORS.Required;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly alertService: AlertService,
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly emailService: EmailService,
    private readonly controlShowModal:ControlCardService
  ) {
    this.emailDataForm = this.formBuilder.group({
      email: [Strings.EMPTY,
      [Utils.validateEmailList(),
      Validators.required]],
      emailCopy: [Strings.EMPTY, [Utils.validateEmailList()]],
      emailMsg: [Strings.EMPTY, [
        Validators.required,
        Validators.maxLength(this.commentsLength)
      ]],
      copyToMe: [false],
    });
  }

  ngOnInit(): void {
    this.emailDataForm.controls[Constants.FORM_CONTROLS.Email].setValue(this.modalData?.email);
  }

  async onSubmitEmail() {
    if (!this.emailDataForm.valid) {
      this.emailDataForm.markAllAsTouched();
      return;
    }
    try {
      await this.emailService.sendEmail(
        {
          email: this.emailDataForm.controls[Constants.FORM_CONTROLS.Email]?.value,
          emailCopy: this.emailDataForm.controls[Constants.FORM_CONTROLS.EmailCopy]?.value,
          comments: this.emailDataForm.controls[Constants.FORM_CONTROLS.EmailMessage]?.value,
          copyToMe: this.emailDataForm.controls[Constants.FORM_CONTROLS.CopyToMe]?.value,
          accountNumber: this.modalData?.NumeroCuenta,
          referenceNumber: this.modalData?.NumeroReferencia
        }
      );
      this.modalInstance?.close();
      this.alertService.showPopupAlert({
        header: Strings.SEND_MAIL.EmailSuccess,
        message: Strings.SEND_MAIL.EmailSuccessDescription,
        btnLabel: Constants.POPUP_CONTINUE_BUTTON,
        btnExit: false
      }, { onSuccess: () => this.modalInstance?.dismiss() }, Strings.EMPTY, true);
    } catch (error) {
      const errorDetail = Utils.getErrorMsg(error);
      this.close();
      this.alertService.showPopupAlert({
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        message: Utils.SERVICE_ERROR_MSG(errorDetail.msg, errorDetail.code),
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      });
    }
  }

  close() {
    this.modalService.close();
  }
  exitSendEmail() {
   this.modalInstance?.dismiss();
    if(this.controlShowModal.isRenewModal){
      const modalOptions: ModalOptions = {
        centered: false,
        size: Constants.MODAL_OPTIONS.SizeMd,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalReplaceRequest
      };
      this.modalService.open(ModalReplacementRequestedComponent, modalOptions);
    }else if(this.controlShowModal.isRequesModal){
      const modalOptions:ModalOptions = {
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalRequestedRenewal,
        centered: true
      };
      this.modalService.open(ModalRequestConfirmationComponent, modalOptions);
    }else if(this.controlShowModal.isResponseModal){
      this.controlShowModal.isResponseModal=false;
      const modalOptions:ModalOptions= {
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalSizeConfirmation,
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeXl
      };
      this.modalService.open(ModalRequestConfirmationComponent, modalOptions);
    }
  }
}
