import { PathModel } from '../models/PathModel';

interface IStatementsAccountPDFRequest {
  Sesion: string;
  cuenta: string;
  idPeriodo: number;
  idSistema: number;
  Origen: number;
  ipUsuario: string;
  nombreOrigen: string;
  tipo: string;
  MedioAcceso: string;
  OrigenConsulta?: string;
}

export class StatementsAccountPDFRequest{
    constructor(public data:IStatementsAccountPDFRequest){}

    get endpoint(): PathModel {
      return PathModel.StatementsAccountPDF;
  }
}
