import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, combineLatestWith, map, tap } from 'rxjs';
import { DataConfirm } from 'src/app/interface/Investment';
import { DepositAppliesPromissoryNoteRequest } from 'src/app/interface/dto/DepositAppliesPromissoryNoteRequest';
import { DepositAppliesPromissoryNoteResponse } from 'src/app/interface/dto/DepositAppliesPromissoryNoteResponse';
import { AccountCardService } from 'src/app/services/account-card.service';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { InvestmentRegisterService } from 'src/app/services/investment-register.service';
import { InvestmentService } from 'src/app/services/investment.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { NotifyRouteService } from 'src/app/services/notify-route.service';
import { StorageService } from 'src/app/services/storage.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { CoinType, Constants, ElementsHTML, FormatDate, Numbers, Regex, Tab } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';
import { Utils } from 'src/core/utils/utils';


@Component({
  selector: 'app-complete-investment',
  templateUrl: './complete-investment.component.html',
  styleUrls: ['./complete-investment.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompleteInvestmentComponent implements OnInit {
  completeDate: string | number | Date;
  carDec: string = Strings.CONFIRM_DATA.CompleteInvestment;
  id: string = Strings.EMPTY;
  amount: string | number;
  dataConfirm: Array<DataConfirm> = new Array<DataConfirm>;
  investmentDetailsError: BehaviorSubject<{ error?: string, certificateNumber?: string }> = new BehaviorSubject<{ error?: string, certificateNumber?: string }>(null);
  ruleDescription: string = Strings.EMPTY;
  selectedDeadline: string = Strings.EMPTY;
  subAccountCode: string = Strings.EMPTY;
  startDate: string = Strings.EMPTY;
  endDate: string = Strings.EMPTY;
  numberAccount: string = Strings.EMPTY;
  idAccountProduct: number = Numbers.Zero;
  expirationInstruction: string = Strings.EMPTY;
  expirationInstructionRegister: string = Strings.EMPTY;
  otpCode: string = Strings.EMPTY;
  currency: string = Strings.EMPTY;
  description: string = Strings.EMPTY;
  rulePerformance: string = Strings.EMPTY;
  errorMsg: string;
  otherOperation: string = PATH.Home;
  certificateNumber: string = Strings.EMPTY;
  contentMargin: string = ElementsHTML.ContentMargin;
  homePath = PATH.Home;
  investmentPath = PATH.Investment;
  invesmentTab = Tab.Investment;

  vm$ = this.responsiveService.observe.pipe(
    tap(breakpoints =>
      breakpoints.mobile
        ? (this.contentMargin = ElementsHTML.ContentMargin)
        : (this.contentMargin = ElementsHTML.ContentMarginBreakpoints)
    ),
    combineLatestWith(this.investmentDetailsError.asObservable()),
    map(([breakpoints, investmentDetailsError]) => ({ breakpoints, investmentDetailsError }))
  );

  checkIcon: string = Resources.CHECK_CR_FR;

  constructor(
    readonly router: Router,
    readonly investmentRegisterService: InvestmentRegisterService,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly storage: StorageService,
    private readonly accountCardService: AccountCardService,
    private readonly baseService: BaseServiceService,
    private readonly notifyRouteService: NotifyRouteService,
    private readonly responsiveService: ResponsiveService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly investmentService: InvestmentService
  ) {
    if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras.state) {
          this.paramsValidation(router.getCurrentNavigation().extras);

    }
    const date = new Date();
    this.completeDate = formatDate(
      date,
      FormatDate.DayMonthYearComplete,
      Constants.LOCALE_ES_MX
    );
  }

  ngOnInit(): void {
    NavigationUtils.fixNavigation(this.changeDetector,[PATH.Investment, PATH.CompleteInvestmentRegistration]);
    const accountNumber = this.investmentRegisterService.accountNumber;
    this.numberAccount = accountNumber?.NumeroCuenta;
    this.idAccountProduct = accountNumber?.IdCuentaProducto;
    this.currency = accountNumber?.Saldo?.Moneda;
    this.description = accountNumber?.Descripcion;
    const investmentData = this.investmentRegisterService.investmentData;
      this.ruleDescription = investmentData?.ruleDescription;
      this.selectedDeadline = investmentData?.selectedDeadline;
      this.subAccountCode = investmentData?.subAccountCode;
      this.startDate = investmentData?.startDate ? formatDate(investmentData?.startDate, FormatDate.BasicFormat, Constants.LOCALE_ES_MX): Strings.EMPTY;
      this.endDate =investmentData?.endDate? formatDate(investmentData?.endDate, FormatDate.BasicFormat, Constants.LOCALE_ES_MX): Strings.EMPTY;
      this.expirationInstruction = investmentData?.expirationInstruction;
      this.expirationInstructionRegister = investmentData?.expirationInstructionRegister;
      this.rulePerformance = investmentData?.rulePerformance;
    this.registerInvestment();
    if (this.amount === undefined) {
      this.router.navigate([PATH.Home]);
    }
    if (this.amount !== Strings.EMPTY) {
      this.amount.toLocaleString(Constants.LOCALE_EN_US,
        {
          style: Constants.CURRENCY,
          currency: CoinType.USD,
          minimumFractionDigits: Constants.TOTAL_DECIMALS
        });

    } else {
      this.router.navigate([PATH.Investment]);
    }
  }

  @HostListener('window:popstate')
  goPage(path: string) {
    if(path){
      if(path === PATH.Home){
        this.router.navigate([path]);
      }else{
        this.notifyRouteService.navigateToTab(Tab.Investment);
      }
    }

    this.investmentRegisterService.investmentFormData = [];
    this.accountCardService.clearCardAccount();
    this.investmentRegisterService.clearInvestmentObservable();
    this.investmentRegisterService.clearInvestmentRegister();
    this.notifyAmountService.clearAmount();
  }

  private paramsValidation(params: NavigationExtras) {
    this.otpCode = params.state[Constants.NAVIGATION_PARAMETERS.OtpCode];
    this.dataConfirm = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.ListInvestment];
    this.amount = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.AmountInvestment];
  }

  async registerInvestment() {
    this.notifyAmountService.amount$.forEach(data => {
      this.amount = data.amount;
    });
    const amountString = this.amount.toString().replace(Regex.MatchComma, Strings.EMPTY).replace(Regex.MatchCurrencySymbol, Strings.EMPTY);
    const amount = Number.parseFloat(amountString);
    const request = new DepositAppliesPromissoryNoteRequest({
      TipoApertura: Constants.PROMISSORY_NOTE,
      DescReglaRendimiento: this.ruleDescription,
      AbreviacionMoneda: this.currency || this.accountCardService.selectedAccountCard.currency,
      Plazo: parseInt(this.selectedDeadline),
      NumeroCuentaOrigen: this.numberAccount ||  this.accountCardService.selectedAccountCard.accountNumber,
      FrecuenciaPago: null,
      IdCuentaProductoDestino: this.idAccountProduct || this.accountCardService.selectedAccountCard.productId,
      Otp: this.otpCode,
      Origen: Constants.ACCESS_METHOD_PORTAL,
      Periodo: this.selectedDeadline,
      CodigoReglaRendimiento: this.rulePerformance,
      Contrato: null,
      Monto: amount,
      FechaInicioPeriodo: this.startDate,
      IdSession: this.storage.getSession(),
      FechaVencimiento: this.endDate,
      IdCuentaProductoOrigen: this.idAccountProduct || this.accountCardService.selectedAccountCard.productId,
      TipoPago: this.expirationInstructionRegister,
      NumeroCuentaDestino: this.numberAccount || this.accountCardService.selectedAccountCard.accountNumber
    });
    try {
      const response =
        await this.baseService.genericPost<DepositAppliesPromissoryNoteResponse>(request, {
          isSOA: true,
        });
        this.id = response.NumeroCertificado;
      this.investmentRegisterService.investmentData = {
        certificateNumber: response.NumeroCertificado
      };
      this.investmentDetailsError.next({
        certificateNumber: response.NumeroCertificado
      });

    } catch (error) {
      Utils.printLogError(error);
      const errorDetail = Utils.getErrorMsg(error);
      this.errorMsg = Utils.SERVICE_ERROR_MSG(errorDetail.msg, errorDetail.code);
      this.investmentDetailsError.next({
        error: errorDetail.msg
      });
    }
    if (!this.investmentService.hasInvestment) {
      this.invesmentTab = null;
    }
  }
}
