import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { Constants } from 'src/core/constants/Constants';

@Directive({
  selector: 'input[autofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  constructor(private readonly elementRef: ElementRef<HTMLInputElement>) {}

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.focus({
      preventScroll: true
    });
    setTimeout(() => {
      window.scrollTo(Constants.AUTO_FOCUS_STARTUP, Constants.AUTO_FOCUS_STARTUP);}, Constants.AUTOFOCUS_TIMEOUT);
  }
}
