import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Strings } from 'src/core/constants/Strings';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @Input() label = Strings.EMPTY;
  @Input() classname = Strings.EMPTY;
  @Input() selected = false;

  constructor() {}
}
