<ng-container *ngIf="breakpoints$ | async as breakpoints">
    <div class="d-flex justify-content-center " *ngIf="hasResult">
        <div id="container-successful-request" class="modal-successful-request centered-modal"
            [ngClass]="{'modal-successful-request-mobile':breakpoints.mobile, 'modal-successful-iphone':isIphone}">
            <div class="col-12">
                <div class="d-flex flex-row-reverse direction-end close-padding" ngbAutofocus>
                    <img role="button" aria-label="Close" class="icon-close" width="auto" height="auto" alt="Close"
                        [src]="removeIcon | cdnImage" (click)="close()">
                </div>
            </div>
            <div class="col-12 successful-head d-flex justify-content-center">
                <div>
                    <img [src]=" checkIcon | cdnImage" class="check-icon" width="auto" height="auto" alt="Check">
                </div>
                <div id="title-modal" class="color-label">
                    <label id="title-current" class="header-m">{{title}}</label>
                </div>
                <div *ngIf="invoice">
                    <label class="body-m color-label">{{labelInvoice}}&nbsp;{{invoice}}</label>
                </div>
                <div class="text-align">
                    <label class="detail-m color-label date-width" [ngClass]="{'date-width-iphone':isIphone}"
                        [innerHTML]="date"></label>
                </div>
            </div>
            <div class="col-12 body justify-content-center text-align">
                <div class="color-label padd-tf">
                    <label class="info-width modal-text"
                    [ngClass]="{'body-m':(breakpoints.desktop || breakpoints.tablet) && !isIphone,
                    'body-s': breakpoints.mobile || isIphone,'info-width-iphone':isIphone}" [innerHTML]="info"></label>
                </div>
                <div class=" padd-et">
                    <a type="button" (click)="confirmLeavePage(hrefBureau)" target="_blank">
                        <span class="detail-m-highlight-hypertext color-link">{{bureauUrl}}</span>
                    </a>
                </div>
                <div>
                    <label class="color-label"
                    [ngClass]="{'body-m':(breakpoints.desktop || breakpoints.tablet) && !isIphone,
                    'body-s': breakpoints.mobile || isIphone}" [innerHTML]="message"></label>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-center">
                <button [ngClass]="{ 'button-m':(breakpoints.desktop || breakpoints.tablet) && !isIphone,
                    'button-s': (breakpoints.mobile || isIphone), 'btn-iphone':isIphone}" class="btn-size colored-btn"
                    (click)="close()"> {{ready}}</button>
            </div>

        </div>
    </div>
</ng-container>
