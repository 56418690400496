import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConsultSubAccountsResponse } from '../interface/dto/ConsultSubAccountsResponse';
import { CheckingAccountModel } from '../interface/ICheckingAccountsResponse';
import { Strings } from 'src/core/constants/Strings';
import { Numbers } from 'src/core/constants/Constants';
import { DataConfirm } from 'src/app/interface/Investment';


@Injectable({
  providedIn: 'root'
})
export class InvestmentRegisterService {
  private readonly _investmentRegister: BehaviorSubject<ConsultSubAccountsResponse> = new BehaviorSubject<ConsultSubAccountsResponse>({
    ListaSubCuentas: []
  });

  private readonly _accountNumber: BehaviorSubject<CheckingAccountModel> = new BehaviorSubject<CheckingAccountModel>({
    NumeroCuenta: Strings.EMPTY,
    IdCuentaProducto: Numbers.Zero
  });

  private readonly _investmentData: BehaviorSubject<IInvestments> = new BehaviorSubject<IInvestments>({
    startDate: Strings.EMPTY,
    endDate: Strings.EMPTY,
    grossYield: Numbers.Zero,
    rate: Numbers.Zero,
    selectedDeadline: Strings.EMPTY
  });

  private _investmentFormData: Array<DataConfirm>;

  get investmentFormData(): Array<DataConfirm> {
    return this._investmentFormData;
  }

  set investmentFormData(data: Array<DataConfirm>) {
    this._investmentFormData = data;
  }

  constructor() { }

  get investment$() {
    return this._investmentRegister.asObservable();
  }

  get investment(): ConsultSubAccountsResponse {
    return this._investmentRegister.getValue();
  }

  set investment(data: ConsultSubAccountsResponse) {
    this._investmentRegister.next(data);
  }

  get accountNumber$() {
    return this._accountNumber.asObservable();
  }

  get accountNumber(): CheckingAccountModel {
    return this._accountNumber.getValue();
  }

  set accountNumber(data: CheckingAccountModel) {
    this._accountNumber.next(data);
  }

  get investmentData$() {
    return this._investmentData.asObservable();
  }

  get investmentData(): IInvestments {
    return this._investmentData.getValue();
  }

  set investmentData(data: IInvestments) {
    this._investmentData.next(data);
  }

  clearInvestmentRegister() {
    this._investmentRegister.next({
      ListaSubCuentas: [{
        Codigo: Strings.EMPTY,
        Descripcion: Strings.EMPTY,
        Plazos: Strings.EMPTY
      }]
    });
    this._investmentData.next({
      startDate: Strings.EMPTY,
      endDate: Strings.EMPTY,
      grossYield: Numbers.Zero,
      rate: Numbers.Zero,
      selectedDeadline: Strings.EMPTY,
      ruleDescription: Strings.EMPTY,
      subAccountCode: Strings.EMPTY,
      expirationInstruction: Strings.EMPTY,
      expirationInstructionOption: Strings.EMPTY,
      isInvestments: true,
      rulePerformance: Strings.EMPTY,
      expirationInstructionRegister: Strings.EMPTY,
      investmentType: Strings.EMPTY,
      certificateNumber: Strings.EMPTY,
      typeDescription: Strings.EMPTY
    });
  }
  clearInvestmentObservable() {
    this._investmentRegister.next({
      ListaSubCuentas: []
    });
  }
}
export interface IInvestments {
  startDate?: string,
  endDate?: string,
  grossYield?: number,
  rate?: number,
  selectedDeadline?: string,
  ruleDescription?: string,
  subAccountCode?: string
  expirationInstruction?: string,
  expirationInstructionOption?: string,
  isInvestments?: boolean,
  rulePerformance?: string,
  expirationInstructionRegister?: string,
  investmentType?: string,
  certificateNumber?: string,
  typeDescription?: string,
  changeOptionInvestment?: boolean,
  disableRenewal?: boolean
}
