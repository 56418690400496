import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { TDeadlines } from 'src/app/interface/dto/ConsultSubAccountsResponse';
import { InvestmentRegisterService } from 'src/app/services/investment-register.service';
import { Char, Position } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';

@Component({
  selector: 'app-type-investment',
  templateUrl: './type-investment.component.html',
  styleUrls: ['./type-investment.component.css']
})
export class TypeInvestmentComponent implements OnInit {
  elementsType = [];
  elementsDate = [];
  result: TDeadlines [];
  @Output() sendTypeToInvestment = new EventEmitter();
  @Input() withOutType: boolean;
  @Input() withOutDate: boolean;
  @Input() viewAllDays: boolean;
  @Input() selectedDateBack: string = Strings.EMPTY;
  @Input() selectedTypeBack: string = Strings.EMPTY;
  @Input() investmentCode: string = Strings.EMPTY;
  @Input() title: string = Strings.EMPTY;
  typeInvestment = new Array<{}>;
  selectedType: string = Strings.EMPTY;
  selectedDate: string = Strings.EMPTY;
  selectedTypeDescription: string = Strings.EMPTY;
  number: string = Strings.EMPTY;
  days: string = Strings.DAYS_INVESTMENT;
  typeBack: string = Strings.EMPTY;
  dateBack: string = Strings.EMPTY;
  changeOptionInvestment: boolean;
  cardChange: boolean;
  disabledRenewal: boolean = true;
  investmentSL: SubscriptionLike;
  accountNumberSL: SubscriptionLike;

  vm$ = this.investmentRegisterService.investment$;
  constructor(private readonly investmentRegisterService: InvestmentRegisterService,
    private readonly changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.investmentSL = this.investmentRegisterService.investment$.subscribe(() => {
      this.elementsType = [];
      this.elementsDate = [];
    });

    this.accountNumberSL = this.investmentRegisterService.accountNumber$.subscribe(() => {
      this.typeInvestment = [];
      this.typeInvestment.push(
        {
          dataDescription: this.typeBack = Strings.EMPTY,
          dataType: this.selectedType = Strings.EMPTY,
          dataDate: this.selectedDate = Strings.EMPTY,
          disabledRenewal: this.disabledRenewal = true
        });
      this.sendTypeToInvestment.emit(this.typeInvestment);
    });

    if (this.selectedDateBack !== Strings.EMPTY && this.selectedTypeBack !== Strings.EMPTY) {
      this.typeBack = this.selectedTypeBack;
      this.dateBack = this.selectedDateBack;
      this.selectType(this.investmentCode);
      this.selectDate(this.dateBack);
      this.typeInvestment = [];
      this.typeInvestment.push(
        {
          dataDescription: this.typeBack,
          dataType: this.investmentCode,
          dataDate: this.dateBack
        });
      this.sendTypeToInvestment.emit(this.typeInvestment);
    }
    this.selectedDateBack = Strings.EMPTY;
    this.selectedTypeBack = Strings.EMPTY;
  }

  selectType(dataType: string) {
    this.typeInvestment = [];
    this.elementsDate = [];
    this.disabledRenewal = true;
    this.changeDetector.detectChanges();
    this.selectedType = dataType;
    this.changeOptionInvestment = true;
    if (this.selectedDateBack === Strings.EMPTY) {
      this.dateBack = Strings.EMPTY;
    }
    if (dataType === Strings.EMPTY) {
      this.withOutType = true;
      this.typeInvestment.push(
        {
          dataDescription: Strings.EMPTY,
          dataType: Strings.EMPTY,
          dataDate: this.selectedDate
        });
    } else {
      this.selectedDate = Strings.EMPTY;
      this.disabledRenewal = false;
      this.withOutType = false;
      this.result = this.investmentRegisterService.investment.ListaSubCuentas.filter(filter => {
        return filter.Codigo === dataType;
      });
      const getDeadlines = this.result[Position.Zero].Plazos.split(Char.Comma);
      getDeadlines.sort((a, b) => {
        const numA = Number(a);
        const numB = Number(b);
        return numA - numB;
      });
      this.elementsDate = getDeadlines;

      this.viewAllDays = true;
      this.selectedTypeDescription = this.result[Position.Zero].Descripcion;
      if (this.selectedDateBack !== Strings.EMPTY && this.selectedTypeBack !== Strings.EMPTY) {
        this.typeInvestment.push(
          {
            dataDescription: this.typeBack,
            dataType: this.investmentCode,
            dataDate: this.dateBack
          });
      } else {
        this.typeInvestment.push(
          {
            disabledRenewal: this.disabledRenewal,
            dataDescription: this.selectedTypeDescription,
            dataType: this.selectedType,
            dataDate: this.selectedDate
          });
      }
    }
  }

  selectDate(dataDate: string) {
    this.typeInvestment = [];
    this.selectedDate = dataDate;
    this.changeOptionInvestment = true;
    if (dataDate === Strings.EMPTY) {
      this.disabledRenewal = true;
      this.withOutDate = true;
      this.typeInvestment.push(
        {
          dataDescription: Strings.EMPTY,
          dataType: this.selectedType,
          dataDate: Strings.EMPTY,
          disabledRenewal: this.disabledRenewal
        });
    } else {
      this.disabledRenewal = false;
      this.withOutDate = false;
      if (dataDate !== Strings.EMPTY) {
        if (this.selectedDateBack !== Strings.EMPTY && this.selectedTypeBack !== Strings.EMPTY) {
          this.typeInvestment.push(
            {
              dataDescription: this.typeBack,
              dataType: this.investmentCode,
              dataDate: this.dateBack
            });
        } else {
          this.typeInvestment.push(
            {
              disabledRenewal: this.disabledRenewal,
              dataDescription: this.selectedTypeDescription,
              dataType: this.selectedType,
              dataDate: this.selectedDate
            });
        }
      }
    }
  }

  alertType() {
    this.withOutType = true;
  }

  sendTypes() {
    this.investmentRegisterService.investmentData = {
      subAccountCode: this.selectedType,
      selectedDeadline: this.selectedDate,
      changeOptionInvestment: this.changeOptionInvestment,
      disableRenewal: this.disabledRenewal
    };
    this.sendTypeToInvestment.emit(this.typeInvestment);
  }
}
