import { Validators } from '@angular/forms';
import { Constants, FormValidations, Numbers } from 'src/core/constants/Constants';
import { AforeStrings } from 'src/core/constants/AforeStrings';
import { Strings } from 'src/core/constants/Strings';

export class AforeFormValidations {
  static readonly FORM = {
    validations:  [
      {
        name: FormValidations.Required,
        validator: Validators.required,
        message: Strings.GENERIC_ERRORS.Required
      },
      {
        name: Constants.FORM_CONTROLS.MinimumLength,
        validator: Validators.minLength(Numbers.Two),
        message: AforeStrings.VOLUNTARY_SAVINGS_CONTRIBUTIONS.MarriageAlert
      }
    ]
  };
}
