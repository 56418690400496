import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CodeflexConstants } from 'src/core/constants/CodeflexConstants';
import { Char, Constants, Numbers } from 'src/core/constants/Constants';
import { ControlCardStrings } from 'src/core/constants/ControlCardStrings';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { EnterDateComponent } from 'src/app/shared/components/enter-date/enter-date.component';
import { FormatDate } from 'src/core/constants/FormatDate';

@Injectable({
  providedIn: 'root'
})
export class EnterDateExtensionService {

  constructor() { }

  isDateLater(date1: NgbDateStruct, date2: NgbDateStruct): boolean {
    if (date1?.year > date2?.year) {
      return true;
    } else if (date1?.year === date2?.year) {
      if (date1.month > date2.month) {
        return true;
      } else if (date1.month === date2.month) {
        return date1.day >= date2.day;
      }
    }
    return false;
  }

  hiddenError(context:EnterDateComponent){
    context.notifyDateService.isErrorData = false;
    context.showDateError = false;
    context.isDateError.emit(false);
  }

  validateAge(model: NgbDateStruct, context){
    try{
    if((typeof model !== 'object')){
      const formatDate = String(model).split(Char.Slash);
      model = {year: Number(formatDate[Numbers.Two]),month:Number(formatDate[Numbers.One]),day:Number(formatDate[Numbers.Zero])};
     }
     if(model.year!==Numbers.Zero && !Number.isNaN(model.year)){
       const res = this.calculateAge(model);
       if(res < Numbers.Eighteen || res > Numbers.NinetySix){
        context.notifyDateService.isErrorData = true;
        context.showDateError = true;
        context.isDateError.emit(true);
        if(res >= Numbers.Zero && res < Numbers.Eighteen){
          context.errorDate= ControlCardStrings.ERRORDATE;
        }else if(res >= Numbers.NinetySix){
          context.errorDate= ControlCardStrings.GENERIC_ERROR_DATE;
        }
        }else{
          context.notifyDateService.isErrorData = false;
          context.showDateError = false;
          context.isDateError.emit(false);
        }
     }else{
      context.showDateError = true;
      context.isDateError.emit(true);
     }
    }catch(error){
      Utils.printLogError(error);
      context.notifyDateService.isErrorData = true;
      context.showDateError = true;
      context.isDateError.emit(true);

    }
  }

  isValidDate(context:EnterDateComponent) { context.dateIsError = false; }

  idSipare(context:EnterDateComponent) {
    if (context.idService === Constants.SERVICE_CODE.Sipare) {
      context.head = Strings.DUE_DATE_LABEL;
    }
  }

  formatInputDate(context:EnterDateComponent) {
    const [day, month, year] = Utils.formatDate().split(Char.Slash);
    context.today = {
      year: parseInt(year),
      month: parseInt(month),
      day: parseInt(day)
    };
    context.dateForm = context.formBuilder.group({
      date: [
        {
          value: context.today,
          disabled: context.inputState
        },
        [Validators.required]
      ]
    });
    if(!context.currentAccount?.tipoCuenta?.toLowerCase()?.includes(CodeflexConstants.CODEFLEX_ROOT)){
      context.notifyDateService.dateStructData = context.today;
    }
    context.emittDate(context.notifyDateService.dateFormat);
  }
  calculateAge(birthday: NgbDateStruct): number {
    const today = new Date();
    let age = today.getFullYear() - birthday.year;
    const month = (today.getMonth() + Numbers.One) - birthday.month;
    const formatDate = new Date(birthday.year, birthday.month - Numbers.One, birthday.day);
    if (month < Numbers.Zero || (month === Numbers.Zero && today.getDate() < formatDate.getDate())) {
      age--;
    }
    return age;
  }
  validHasDate(model: NgbDateStruct, context:EnterDateComponent){
    if(model === undefined && context.showDateError && context.external){return true;}
    return false;
  }
  calculateCursorPosition($event: Event) {
    const inputName = $event.target as HTMLInputElement;
    let cursorPosition = inputName.selectionStart;
    cursorPosition = ($event[FormatDate.VALUE_DATA] === null
      ? cursorPosition : inputName.value.length > Numbers.Two && cursorPosition === Numbers.Six
      ? cursorPosition + Numbers.One : cursorPosition);
    if (cursorPosition > Numbers.Two && inputName.value.length > Numbers.Ten) {
      const valueInput = inputName.value;
      if (valueInput.split(Char.Slash).length > Numbers.Two) {
        cursorPosition = Numbers.Six + (valueInput.split(Char.Slash)[Numbers.Two].length - (valueInput.length - cursorPosition));
      }
    } else {
      cursorPosition = this.cursorPositionMonthNumber($event, cursorPosition);
    }
    return cursorPosition;
  }
  cursorPositionMonthNumber($event: Event, position) {
    const inputName = $event.target as HTMLInputElement;
    let cursorPosition = position;
    const valueInput = inputName.value;
    cursorPosition =
      $event[FormatDate.VALUE_DATA] !== null
      && cursorPosition > Numbers.Two && valueInput.split(Char.Slash)[Numbers.Zero].length ===  Numbers.Three
      && (valueInput.split(Char.Slash).length === Numbers.One || valueInput.split(Char.Slash).length === Numbers.Three)
      ? (parseInt(cursorPosition) + Numbers.One) : cursorPosition;
      return cursorPosition;
  }
  keypress($event:KeyboardEvent){
    const value = parseInt($event.key);
    if(!isNaN(value)){return;}
    $event.preventDefault();
  }
  validateErrorMsg(model: NgbDateStruct, context:EnterDateComponent){
    const date = model as unknown as string;
    if(date===Strings.EMPTY){
      context.errorDate=ControlCardStrings.GENERIC_ERROR_EMPTY_DATE;
    }
    if(date!==Strings.EMPTY && model !== undefined){
      context.errorDate=ControlCardStrings.GENERIC_ERROR_DATE;
    }
  }
}
