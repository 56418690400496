import { Injectable } from '@angular/core';
import { NipInputPasswordService } from 'src/app/services/nip-input-password.service';

@Injectable({
  providedIn: 'root'
})
export class FirstNipService extends NipInputPasswordService {
  constructor() {
    super();
  }
}
