import { Injectable } from '@angular/core';
import { NipInputPasswordService } from 'src/app/services/nip-input-password.service';

@Injectable({
  providedIn: 'root'
})
export class SecondNipService extends NipInputPasswordService {
  constructor() { 
    super();
  }
}