import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISimpleValue } from '../interface/ISimpleValue';
import { ClaimDeductible, DataDeductible } from '../interface/IDeductibleResponse';
import { Strings } from 'src/core/constants/Strings';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { ValidateClaimNumberResponse } from '../interface/dto/ValidateClaimNumberResponse';
import { ValidateClaimNumberRequest } from '../interface/dto/ValidateClaimNumberRequest';
import { Numbers, Regex } from 'src/core/constants/Constants';
import { ClaimPaymentRequest } from '../interface/dto/ClaimPaymentRequest';
import { ClaimPaymentResponse } from '../interface/dto/ClaimPaymentResponse';
import { ICardAccount } from '../interface/ICardAccount';
import { IAmount } from '../interface/IAmount';

@Injectable({
  providedIn: 'root'
})

export class DataDeductibleService {
  private readonly _confirmProcess: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly _completeProcess: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly _claimNumber: BehaviorSubject<ClaimDeductible> = new BehaviorSubject<ClaimDeductible>({ claimNumber: Strings.EMPTY });
  private readonly _deductibleInfo: BehaviorSubject<DataDeductible> = new BehaviorSubject<DataDeductible>({
    claimDate: Strings.EMPTY,
    status: Strings.EMPTY,
    beneficiaryName: Strings.EMPTY,
    vehicle: Strings.EMPTY,
    model: Numbers.Zero,
    policy: Strings.EMPTY
  });

  constructor(private readonly baseService: IBaseService) { }

  get confirmProcess$(): Observable<ISimpleValue> {
    return this._confirmProcess.asObservable();
  }

  get confirmProcess() {
    return this._confirmProcess.getValue();
  }

  set confirmProcess(data: ISimpleValue) {
    this._confirmProcess.next(data);
  }

  get completeProcess$(): Observable<ISimpleValue> {
    return this._completeProcess.asObservable();
  }

  get completeProcess() {
    return this._completeProcess.getValue();
  }
  
  set completeProcess(data: ISimpleValue) {
    this._completeProcess.next(data);
  }

  get claimNumber$(): Observable<ClaimDeductible> {
    return this._claimNumber.asObservable();
  }

  get claimNumber() {
    return this._claimNumber.getValue();
  }

  set claimNumber(data: ClaimDeductible) {
    this._claimNumber.next(data);
  }

  get deductibleInfo$(): Observable<DataDeductible> {
    return this._deductibleInfo.asObservable();
  }

  get deductibleInfo() {
    return this._deductibleInfo.getValue();
  }

  set deductibleInfoData(data: DataDeductible) {
    this._deductibleInfo.next(data);
  }

  clearDataClaim() {
    this.deductibleInfoData = {
      beneficiaryName: Strings.EMPTY,
      claimDate: Strings.EMPTY,
      model: Numbers.Zero,
      policy: Strings.EMPTY,
      status: Strings.EMPTY,
      vehicle: Strings.EMPTY
    };
    this.claimNumber = {
      claimNumber : Strings.EMPTY
    };
  }

  async validateClaimNumber(claimNumber: string) {
    const request = new ValidateClaimNumberRequest({numeroSiniestro: claimNumber });
    return this.baseService.genericGet<ValidateClaimNumberResponse>(request, {isSecure: true, addSession: true});
  }

  async claimPayment(selectedAccount: ICardAccount, amount: IAmount, token: string, claimNumber: string) {
    const request = new ClaimPaymentRequest({
      banderaPagoSeguros: false,
      cuenta: selectedAccount.accountNumber,
      idCuentaProducto: selectedAccount.productId,
      idOperacion: claimNumber.replace(Regex.MatchMiddleDash, Strings.EMPTY),
      monto: Number(amount.amount.replace(Regex.DifferentFromNumbersDecimals, Strings.EMPTY))
    });
    if (token) request.data.otp = token;
    return this.baseService.genericPost<ClaimPaymentResponse>(request, { isSecure: true, addSession: true });
  }
}
