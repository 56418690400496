import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ValidLineResponse } from '../interface/dto/ValidLineCaptureResponse';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
  providedIn: 'root'
})

export class LocalTaxesCardService {
  private readonly localTaxesCard: ValidLineResponse = {
    Concepto: Strings.EMPTY, 
    isError: false, 
    lineaCaptura: Strings.EMPTY, 
    Ejercicio: Strings.EMPTY, 
    Bimestre: Strings.EMPTY, 
    Cuenta: Strings.EMPTY, 
    Error: {
      Description: Strings.EMPTY,
      No: null
    }
  };

  private readonly _localTaxesCard: BehaviorSubject<ValidLineResponse> = new BehaviorSubject<ValidLineResponse>(this.localTaxesCard);

  get localCardTaxes$() {
    return this._localTaxesCard.asObservable();
  }

  set localCardTaxes(data: ValidLineResponse) {
    this._localTaxesCard.next(data);
  }

  get localCardTaxes(): ValidLineResponse {
    return this._localTaxesCard.getValue();
  }

  constructor() { }

  clearLocalCardTaxes(lineCapture?:string) {
    this.localTaxesCard.lineaCaptura = lineCapture;
    this._localTaxesCard.next(this.localTaxesCard);
  }

}
