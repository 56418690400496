import { Strings } from 'src/core/constants/Strings';

export class StringsRetirement {
static readonly AFORE = {
  TitleProcessAlertDocs: '¿Tus Documentos son Correctos?',
  ModalTitleWithdrawal: 'Validación de Expediente para solicitud de Retiro',
  TitleProcessAlert: '¿Estás seguro de que <br/>quieres cancelar <br/>la operación?',
  DetailProcessAlert: 'Se perderá el avance de tu trámite',
  Close: 'close',
  SelectFile: 'Debes seleccionar un archivo',
  H: ' h',
  SuccessfulRequest: 'Solicitud exitosa',
  ModalPartialWithdrawals: 'modal-partial-withdrawals',
  FormValidate: 'form-validate',
  HrValidate: 'hr-validate',
  StyleTs: 'style-ts',
  ModalVoluntaryContributions: 'modal-voluntary-contributions',
  ModalRetirementAdvanceAge: 'modal-retirement-advanced-age',
  ModalGenericClassNoBorder: 'modal-generic-class no-border',
  RequestSuccess: 'requestSuccess',
  RequestError: 'requestError',
  ModalSuccesRetirement: 'modal-succes-retirement',
  ModalErrorRetirement: 'modal-error-retirement',
  ModalErrorTotalRetirement: 'modal-error-total-retirement',
  ImgClose: 'imgClose',
  PatrimonialStyleOne: 'patrimonial-style-one',
  PatrimonialStyleTwo: 'patrimonial-style-two',
  StyleCloseOne: 'style-close-one',
  StyleCloseTwo: 'style-close-two',
  Retirement: 'retirement',
  RetirementPartial :{
    Title: Strings.PARTIAL_WITHDRAWALS_OPTION,
    Subtitle : 'Estimado trabajador, de acuerdo a los salarios notificados por el IMSS, usted podrá elegir la modalidad de pago que más le convenga',
    Unemployment: 'unemployment',
    LabelSelectOne : 'Elige uno:',
    UnemploymentRetirement :'Retiro por Desempleo',
    UnemploymentRetirementTwo :'Retiro por desempleo',
    UnemploymentRetirementPartial :'Retiro parcial por Desempleo',
    ListRequeriments: [
      'Tener por lo menos 46 días desempleado',
      'No haber efectuado este retiro en los últimos 5 años'
    ],
    Modality: 'Modalidad ',
    ModalityA: 'Modalidad A',
    ModalityB: 'Modalidad B',
    ModalityHeader: 'Se entrega en una sola exhibición',
    ContentA: 'Para cuentas individuales que tienen al menos tres años de haber sido abiertas y tienen un mínimo de doce' +
    ' bimestres de cotización al Instituto.<br/> Monto igual a 30 días de su último salario base de' +
    ' cotización, y de acuerdo con los límites establecidos en la ley del Seguro Social y demás normatividad aplicable.',
    ContentB: 'Para cuentas individuales que tienen cinco años o más de haber sido abiertas.<br/>' +
    ' Monto que resulte menor entre 90 días de su último salario base de cotización, o el 11.5% del saldo de la' +
    ' Subcuenta de Retiro, Cesantía en Edad Avanzada y Vejez.<br/><br/>En caso, de no tener derecho a la Modalidad B elegida' +
    ' acepto que se realice el trámite de retiro parcial por desempleo por la Modalidad A, en caso, de tener derecho a esta.',
    AllowedStatus: 'Estatus permitido',
    Requirements: 'Requisitos',
    Modalities: 'Modalidades',
    Application :'Su solicitud de Derecho para Retiro por Desempleo fue aceptada por el IMSS, favor de seleccionar el botón “Continuar” para seguir con el trámite',
    ErrorStatus : '<label class="text-header">Error de status</label>',
    ActiveWorker: 'El trabajador no se encuentra Inactivo',
    NonActiveWorker : 'El trabajador no cumple con las semanas cotizadas necesarias para el trámite',
    TitleModalModality: 'Solicitud de Certificado de Derecho para Retiro por Desempleo',
    TitleModalModalityMobile:'Solicitud de Certificado de <br> Derecho para Retiro <br> por Desempleo',
    RequestCertificate : 'Estimado Cliente: En esta pantalla se realizará una consulta al Instituto Mexicano del'+
    ' Seguro Social respecto a los requisitos previamente mencionados para emitir el Certificado '+
    ' del Derecho para el retiro solicitado. Si la consulta es exitosa se continuará con el trámite. '+
    'En caso de no cumplir con algún requisito la solicitud será rechazada y deberá aclararse'+
    ' la situación del trabajador con el IMSS.',
    SelectModality: 'Selecciona la modalidad del monto del retiro',
    ReportedSalaryA: '$258.58',
    TotalPaymentA: '$6,780.00',
    Tool: 'Se entrega en una sola exhibición. Para cuentas individuales que tienen al menos tres años de haber sido abiertas y tienen un mínimo de doce bimestres de cotización' +
      ' al Instituto. Monto igual a 30 días de su último salario base de cotización, y de acuerdo con los límites establecidos en la ley del Seguro Social ' +
      'y de más normatividad aplicable.',
    ReportedSalaryB: '$375.00',
    TotalPaymentB: '$11,950.00',
    ToolB: 'Se entrega en una sola exhibición. Para cuentas individuales que tienen cinco años o más de haber sido abiertas. ' +
    'Monto que resulte menor entre 90 días de su último salario base de cotización, o el 11.5% del saldo de la Subcuenta de Retiro, ' +
    'Cesantía en Edad Avanzada y Vejez. En caso, de no tener derecho a la Modalidad B elegida acepto que se realice el trámite de ' +
    'retiro parcial por desempleo por la Modalidad A, en caso, de tener derecho a esta.',
    ReportedSalary: 'Salario notificado: ',
    AmountToPay: 'Cantidad estimada a pagar:',
    TemplateA : 'Se entrega en una sola exhibición. <br> Para cuentas individuales que <br>tienen al menos tres años<br>de haber sido abiertas y tienen un<br>'+
    'mínimo de doce bimestres de <br>cotización al Instituto.<br>Monto igual a 30 días de su último <br>salario base de cotización, y de <br>'+
    'acuerdo con los límites<br>establecidos en <br>la ley del Seguro Social y demás<br>normatividad aplicable.',
    TemplateB: 'Se entrega en una sola exhibición. <br>Para cuentas individuales que tienen<br>cinco años o más de haber sido<br>abiertas.<br>'+
    'Monto que resulte menor entre 90 días<br>de su último salario base de<br>cotización, o el 11.5% del saldo de la<br>Subcuenta de Retiro, Cesantía en<br>'+
    'Edad Avanzada y Vejez.<br><br>En caso, de no tener derecho a la<br>Modalidad B elegida acepto que se<br>realice el trámite de retiro parcial por<br>'+
    'desempleo por la Modalidad A, en <br>caso, de tener derecho a esta.',
    ModalityAlert:'Debe seleccionar una modalidad',
    UnemploymentApplication: 'Solicitud de retiros por desempleo',
    Procedure: 'Trámite',
    StartDate:'Fecha de inicio de trámite',
    StartProcess:'Inicio de trámite',
    Conditions: 'Su solicitud será enviada a la Empresa Operadora y en caso de ser aceptada, le pagaremos a la cuenta referida en máximo 1 día hábil',
    ModalPartail: 'modal-partial',
    ModalViewUnemployement: 'modal-view-unemployment',
    TitlePartial: 'title-partial',
    HeaderMTitleModalConsult: 'header-m title-modal-consult',
    TitleModal: 'title-modal',
    JustifyTitleConsult: 'justify-title-consult',
    CloseIconMB8CloseConsult: 'close-icon m-b-8 close-consult',
    CrossBUttonModalRetirementUnemployement: 'crossButtonModalRetirementUnemployment'
  },
  RetirementMarriage:{
    DateSpouse:'Fecha de matrimonio',
    Title: 'Retiro parcial por Matrimonio' ,
    TitleFormMarriage: 'Adjuntar Acta de Matrimonio',
    SubtitleFormMarriage: 'Adjuntar una copia digitalizada del documento de la Resolución de Pensión con un peso mínimo de 256 KB.',
    Marriage: 'marriage',
    MarriageRetirement :'Retiro por Matrimonio',
    Application: 'Su solicitud de Derecho para Retiro por Matrimonio fue aceptada por el IMSS, favor de seleccionar el botón “Continuar” para seguir con el trámite',
    TitleModalModality: 'Solicitud de Certificado de Derecho para Retiro por Matrimonio',
    TitleModalModalityMobile:'Solicitud de Certificado de <br> Derecho para Retiro <br> por Matrimonio',
    MarriageApplication: 'Solicitud de retiros por matrimonio',
    TotalWeeks: 'Tener al menos 150 semanas de cotización',
    Conditions: 'No haber efectuado este retiro anteriormente',
    AddConditions: 'Tener una copia de tu Acta de Matrimonio en archivo con formato',
    Format: '(JPEG, TIFF y PDF)',
    Process: 'Asegurarte de capturar correctamente la fecha de matrimonio, ya que si no coincide con que indique el Acta, si rechazará el trámite.',
    DetailsSuccess: 'Servicio registrado exitosamente.',
    Details: 'Detalles acerca del Retiro por  Matrimonio',
    DetailsError: 'El archivo no se cargó correctamente',
    UMA: `El retiro por matrimonio equivale a 30 veces el valor de la UMA. Si continuas con este trámite, aceptas que se reducirá el monto de tu ahorro para el retiro,
     lo que puede impactar en tu pensión futura`,
    ErrorMessage: 'Asegúrese de que cumple con los requisitos de formato y tamaño antes de intentar nuevamente la carga.',
    MaxFile: 256000,
    Files: 'files',
    AlertSize: 'El tamaño del archivo es mayor al establecido.',
    FormatInvalid: 'El formato del archivo no es válido',
    File: 'file',
    Certificate: 'certificate',
    CertificateFileName: 'certificateFileName',
    Upload: 'upload',
    RequiredCertificate: 'Debe seleccionar un archivo',
    BirthCertificate: 'Adjuntar Acta de nacimiento digitalizada',
    PensionResolution: 'Adjuntar Resolución de pensión',
    ValidFormat: 'Formatos: .jpg, .tif y .pdf<br>Peso mínimo : 256KB hasta 1MB',
    MinimumWeight: 'Peso mínimo: 256KB hasta 1MB',
    DateMarriage: 'marriageDate',
    WeddingDate: 'Fecha de matrimonio',
    NameSpouse: 'Nombre (s) de Cónyuge',
    Name: 'spouseName',
    FirstName: 'Primer Apellido de Cónyuge',
    Pattern: 'spousePatternalSurname',
    LastName: 'Segundo Apellido de Cónyuge',
    Maternal: 'spouseMaternalSurname',
    GenderSpouse: 'Género del Cónyuge',
    Gender: 'spouseGender',
    Masculine:'Masculino',
    Feminine: 'Femenino',
    MarriageState: 'Entidad Federativa del matrimonio',
    State:'marriageState',
    DataSpouse: 'Fecha de Matrimonio',
    ConfirmInformation: 'Confirma tu información',
    Classes: {
      CloseButton: {
        ModalEndProcess: 'modal-alert-end-process',
        Classes: 'crossButtonModalMarriageCertificate marriage-requiremet-margin',
        Requirement: 'crossButtonModalRequirementsMarriage marriage-requiremet-margin'
      },
      ContinueButton: {
        Classes: 'button-marriage-certificate modals-btn',
        CardModalClass: 'modalretirementunemployment',
        ErrorModal: 'modal-validate-error',
        MarriageCertificateModal: 'modalmarriagecertificate'
      }
    },
    CrossButtonModalConfirmSpouseData: 'crossButtonModalConfirmSpouseData',
    ConfirmSpouseButtonModalBtn: 'confirm-spouse-button modals-btn',
    ModalConfirmSpouseData: 'modalconfirmspousedata',
    ModalSpouseData: 'modal-spuose-data',
    WebKitFillAvailable: '-webkit-fill-available',
    ButtonMargin: '1.625rem 1.125rem 0 0',
    ModalRequirementsMarriage: 'modal-requirements-marriage',
    ModalsBtnMarriageRequirementsButton: 'modals-btn marriage-requirement-button',
    ModalRetirementMarriage: 'modalretirementmarriage',
    ModalError: 'modalerror',
    CrossBUttonError: 'cross-button-error',
    RetirementUnemployementModalsBtnMarriageRequirementButton: 'retirementunemployment modals-btn marriage-requirement-button',
    ModalsBtn: 'modals-btn',
    SpouseButtonModalsBtnMarriageSpouseData: 'spuose-button modals-btn marriage-spouse-data',
    ButtonmSpoueseButtonModalsBtnMarriageSpouseData: 'button-m spuose-button modals-btn marriage-spouse-data',
    CrossButtonModalSpuse: 'crossButtonModalSpouse',
    NgbDpWeekDaySmall: 'ngb-dp-weekday small',
    NgbDpDay: 'ngb-dp-day',
    NgbDatePicker: 'ngb-datepicker',
    BodyM: 'body-m',
    ModalSpouse: 'modal-spouse',
    TransferDatepicker: 'transfer-datepicker',
  },
  RetirementTotal:{
  Header: `<label class="header-xs-new">${Strings.RITGH_REQUEST_REJECTED} </label>`,
    Total: 'total',
    Title: 'Retiro por Edad Avanzada',
    Alert: 'Usted cuenta con la edad requerida para solicitar el trámite de Retiro Total por Amparo a la Edad Avanzada.'+
    '<br> Esta modalidad de Retiro contemplar el pago de las subcuentas Sar 92 e INFONAVIT 92.',
    AgeAlert: 'Usted cuenta con la edad requerida para solicitar el trámite de Retiro Total por Amparo a la EdadAvanzada.'+
    '<br> Esta modalidad de Retiro contemplar el pago de las subcuentas Sar 92 e<br>INFONAVIT 92.',
    VALID_RESOLUTION: {
      HEADER: 'Sin registro de resolución de pensión',
      MESSAGE: 'No cuenta con una resolución de pensión registrada. Favor de acudir a su instituto emisor para solicitar más información',
    },
    VALID_AGE: {
      HEADER: 'Error',
      MESSAGE: 'No cuenta con 65 años cumplidos para un Retiro por Edad',
      TITLE: 'Validación de Derecho Cargado o Edad Avanzada',
      CONDITIONS: 'A continuación el Portal Bancario validará que usted cuente con una Resolución de Pensión o Negativa de Pensión registrada en Afore'+
      ' Inbursa; o bien que cumpla con los 65 años para el Retiro por Edad Avanzada.'
    },
    SpecificResolution: 'Usted cuenta con una resolución cargada con las siguientes especificaciones:',
    Type: '73 Tipo',
    Regime: 'Régimen',
    Zero: '00-',
    Benefit : 'Prestación',
    PensionPaid:'Pensión Pagada',
    IVType: 'IV - IV Tipo',
    InsuranceType: 'Tipo Seguro',
    TotalAmount: '$150.00',
    DepositAmount: 'Monto Depósito',
    TotalType: '02 - Retiro Total IMSS',
    WithdrawalType: 'Tipo Retiro',
    Date: '16/06/2023',
    ResolutionDate: 'Fecha Resolución',
    StartDate: 'Fecha Inicio de Pensión',
    CardNumber: '***2007',
    CardName: 'Nómina Efe',
    VeVe: 'Ve - Ve',
    Pension: 'Pensión',
    WithdrawalTotal: '022 - Retito Total IMSS',
    Conditions :'Te notificaremos del estatus de tu solicitud vía correo electrónico.<br><br> En caso de que tu solicitud sea exitosa,'+
    'el depósito se realizará en un máximo de 5 días hábiles.<br><br>Si tienes algún problema con esta solicitud comunícate con nosotros al:'+
    '<br><br>800 90 90000<br><br>',
    Classes: {
      ModalDialogClass: 'modal-alert-end-process'
    }
  },
  RefundsWeeks:{
    ModalTitleRefund: Strings.WEEK_REFUND_OPTION,
    TITLE: 'Reintegro Semanas de Cotización',
    HTML_TITLE: 'Información del Tipo de Pago',
    WEEKS_TO_PAYMENT: 'Semanas a Pagar',
    SUBTITLE: 'Ingresa los datos del Reintegro',
    RefundType: 'Parcial',
    Week: '12',
    Amount: '$4,797.24',
    AmountToPay: 'Monto Disponible a Pagar',
    Portrait: 'portrait',
    Landscape: 'landscape',
    refund_type: 'Tipo de Reintegro',
    LabelWeek: 'semana',
    LabelAmount: 'monto',
    LabelRefund: 'reintegro',
    CardList : {
      FirstCard: 'card1',
      FirstAmount: '$ 37,582.80',
      FirstCost: '399.77',
      SecondCard: 'card1',
      SecondAmount: '$ 42,124.80',
      SecondCost: '210.26',
      ThirdCard: 'card3',
    },
    OptionRight: 'Selecciona el Evento a reintegrar',
    DiscountedWeeks: 'Semanas Descontadas',
    WeekToTecover : 'Costo por semana <br> a Recuperar',
    TypeBeneficit :'Tipo de prestación',
    Withdrawal : 'Retiro',
    AmountPay : 'Monto Pagado',
    EventDate: 'Fecha del evento',
    ValueEventDate : '14/06/2023',
    Total : 'Total ',
    Weeks: ['1 semana', '2 semanas', '3 semanas', '4 semanas', '5 semanas', '6 semanas', '7 semanas',
              '8 semanas','9 semanas','10 semanas', '11 semanas','12 semanas'],
    WeeksToReintegrate: 'Semanas a Reintegrar',
    Alert :'No cuentas con retiros a los cuales aplicar el reintegro de semanas cotizadas',
    Reintegrate: 'Reintegro de Semanas de Cotización',
    OnlineReintegrate: 'Reintegro Semanas de Cotización en Línea',
    DataConfirm: 'Confirma los datos <br> de la operación',
    ModalAddProduct: 'modal-add-product'
  },
  VoluntaryContributions:{
    SubtitleFormVoluntary: 'Ingresa los siguientes datos',
    Total: '30000',
    ISR: '0.54',
    Alert: 'El monto de retiro debe ser menor o igual al de la subcuenta seleccionada',
    PublicSector: 'Sector Público',
    PrivateSector: 'Sector Privado',
    Account: '036180401303870491',
    SubAccount: [
        { option: 'Aportaciones Voluntafsdgdfs' },
        { option: 'Aportaciones Completamente De Retiro'},
        { option: 'Aportaciones Largo Plazo'},
        { option: 'Ahorro Voluntario Con Perspectiva Largo Plazo' }
      ],
    AmountMinimum: 'El monto debe ser mayor a $',
    PesosSign:'$ ',
    Withdrawal: 'Retiro de aportaciones <br> voluntarias',
    RetirementSubAccount: 'Subcuenta de retiro',
    TotalAmount: 'Cantidad Total de la Subcuenta',
    CodeAccount: 'Cuenta Clabe',
    Occupation:'Ocupación',
    WithdrawalType: 'Tipo de retiro',
    PercentOne: '0.54 %',
    RateISR: 'Intereses ISR',
    PercentTwo: '$ 0.00',
    OtherISR: 'ISR otros',
    OnLine: 'En Línea',
    DepositType: 'Tipo Depósito',
    AmountXXXL: 'amount-xxxl header-xxl',
    AmountXXXM: 'amount-xxxm header-xxl',
    AmountXXXS: 'amount-xxxs header-l',
    OtpCardReplaceCheckBook: 'otp-card-replacement-checkbook',
    ConfirmText: `<label>“Yo (Nombre del Trabajador)</label><br>
    <label>manifiesto que es mi voluntad</label><br>
    <label>realizar el trámite de retiro</label><br>
    <label>de Aportaciones Voluntarias,</label><br>
    <label>cuyo retiro se realizará de mi</label><br>
    <label>cuenta individual administrada</label><br>
    <label>por Afore Inbursa, para lo cual</label><br>
    <label>otorgo mi consentimiento”</label>`,
    ConfirmTextMbl: `<label>“Yo (Nombre del Trabajador)</label><br>
    <label>manifiesto que es mi </label><br>
    <label>voluntad realizar el trámite</label><br>
    <label>de retiro de Aportaciones Voluntarias,</label><br>
    <label>cuyo retiro se realizará de mi</label><br>
    <label>cuenta individual</label><br>
    <label> administrada</label><br>
    <label>por Afore Inbursa, para lo</label><br>
    <label>cual otorgo mi</label><br>
    <label>consentimiento”</label>`,
    TitleAgeError: 'Lo sentimos, no se puede realizar la operación',
    SubtitleAgeError: 'No se cumple el requisito\nde edad para el retiro.'
  }
};

static readonly AFORE_WITHDRAWAL_UNEMPLOYMENT = {
  Title: Strings.PARTIAL_WITHDRAWALS_OPTION,
  Subtitle: 'Estimado trabajador, de acuerdo a los salarios notificados por el IMSS, usted podrá elegir la modalidad de pago que más le convenga',
  Unemployment: 'unemployment',
  LabelSelectOne: 'Elige uno',
  UnemploymentRetirement: 'Retiro por Desempleo',
  UnemploymentRetirementTwo: 'Retiro por desempleo',
  UnemploymentRetirementPartial: 'Retiro parcial por Desempleo',
  ListRequeriments: [
    'Tener por lo menos 46 días desempleado',
    'No haber efectuado este retiro en los últimos 5 años'
  ],
  Modality: 'Modalidad ',
  ModalityA: 'Modalidad A',
  ModalityB: 'Modalidad B',
  ModalityHeader: 'Se entrega en una sola exhibición',
  ContentA: 'Para cuentas individuales que tienen al menos tres años de haber sido abiertas y tienen un mínimo de doce' +
    ' bimestres de cotización al Instituto.<br/> Monto igual a 30 días de su último salario base de' +
    ' cotización, y de acuerdo con los límites establecidos en la ley del Seguro Social y demás normatividad aplicable.',
  ContentB: 'Para cuentas individuales que tienen cinco años o más de haber sido abiertas.<br/>' +
    ' Monto que resulte menor entre 90 días de su último salario base de cotización, o el 11.5% del saldo de la' +
    ' Subcuenta de Retiro, Cesantía en Edad Avanzada y Vejez.<br/><br/>En caso, de no tener derecho a la Modalidad B elegida' +
    ' acepto que se realice el trámite de retiro parcial por desempleo por la Modalidad A, en caso, de tener derecho a esta.',
  AllowedStatus: 'Estatus permitido',
  Requirements: 'Requisitos',
  Modalities: 'Modalidades',
  Application: 'Su solicitud de Derecho para Retiro por Desempleo fue aceptada por el IMSS, favor de seleccionar el botón “Continuar” para seguir con el trámite',
  ActiveWorker: 'El trabajador no se encuentra Inactivo',
  NonActiveWorker: 'El trabajador no cumple con las semanas <br> cotizadas necesarias para el trámite',
  TitleModalModality: 'Solicitud de Certificado de Derecho para Retiro por Desempleo',
  TitleModalModalityMobile: 'Solicitud de Certificado de <br> Derecho para Retiro <br> por Desempleo',
  RequestCertificate: 'Estimado Cliente: En esta pantalla se realizará una consulta al Instituto Mexicano del' +
    ' Seguro Social respecto a los requisitos previamente mencionados para emitir el Certificado ' +
    ' del Derecho para el retiro solicitado. Si la consulta es exitosa se continuará con el trámite. ' +
    'En caso de no cumplir con algún requisito la solicitud será rechazada y deberá aclararse' +
    ' la situación del trabajador con el IMSS.',
  SelectModality: 'Selecciona la modalidad del monto del retiro',
  ReportedSalaryA: '$258.58',
  TotalPaymentA: '$6,780.00',
  Tool: 'Se entrega en una sola exhibición. Para cuentas individuales que tienen al menos tres años de haber sido abiertas y tienen un mínimo de doce bimestres de cotización'
    + ' al Instituto. Monto igual a 30 días de su último salario base de cotización, y de acuerdo con los límites establecidos en la ley del Seguro Social ' +
    'y de más normatividad aplicable.',
  ReportedSalaryB: '$375.00',
  TotalPaymentB: '$11,950.00',
  ToolB: 'Se entrega en una sola exhibición. Para cuentas individuales que tienen cinco años o más de haber sido abiertas. ' +
    'Monto que resulte menor entre 90 días de su último salario base de cotización, o el 11.5% del saldo de la Subcuenta de Retiro, ' +
    'Cesantía en Edad Avanzada y Vejez. En caso, de no tener derecho a la Modalidad B elegida acepto que se realice el trámite de ' +
    'retiro parcial por desempleo por la Modalidad A, en caso, de tener derecho a esta.',
  ReportedSalary: 'Salario notificado: ',
  AmountToPay: 'Cantidad estimada a pagar:',
  TemplateA: 'Se entrega en una sola exhibición. <br> Para cuentas individuales que <br>tienen al menos tres años<br>de haber sido abiertas y tienen un<br>' +
    'mínimo de doce bimestres de <br>cotización al Instituto.<br>Monto igual a 30 días de su último <br>salario base de cotización, y de <br>' +
    'acuerdo con los límites<br>establecidos en <br>la ley del Seguro Social y demás<br>normatividad aplicable.',
  TemplateB: 'Se entrega en una sola exhibición. <br>Para cuentas individuales que tienen<br>cinco años o más de haber sido<br>abiertas.<br>' +
    'Monto que resulte menor entre 90 días<br>de su último salario base de<br>cotización, o el 11.5% del saldo de la<br>Subcuenta de Retiro, Cesantía en<br>' +
    'Edad Avanzada y Vejez.<br><br>En caso, de no tener derecho a la<br>Modalidad B elegida acepto que se<br>realice el trámite de retiro parcial por<br>' +
    'desempleo por la Modalidad A, en <br>caso, de tener derecho a esta.',
  ModalityAlert: 'Debe seleccionar una modalidad',
  UnemploymentApplication: 'Solicitud de retiros parciales por desempleo',
  Procedure: 'Trámite',
  StartDate: 'Fecha de inicio de trámite',
  StartProcess: 'Inicio de trámite',
  Conditions: 'Su solicitud será enviada a la Empresa Operadora y en caso de ser aceptada, le pagaremos a la cuenta referida en máximo 1 día hábil'
};
}
