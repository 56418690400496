export class AdditionalStrings {
    static readonly ORIGIN_CARS = 'autos';
    static readonly MSG_DATE_INVALID =  'Fecha no válida';
    static readonly MSG_TELEPHONE_NUMBER_INVALID = 'Número telefónico no válido';

    static readonly ADDITIONAL_QR = {
        Filename: 'adicionalQR.png'
    };

    static readonly ADDITIONAL_CARDS = {
        CardNumber: 'NumeroTarjeta',
        Alias: 'Alias',
        Image: 'Imagen',
        Source: 'Source',
        Account: 'Cuenta',
        Holder: 'Titular',
        Lock: 'Lock',
        Cis: 'Cis',
        PersonId: 'IdPersona',
        Policy: 'Poliza',
        File: 'Carpeta',
        Transmitter: 'Emisor',
        Origin: 'Ramo'
    };

  static readonly BUTTONS = {
    Save: 'Guardar'
  };

  static readonly ADM_ADITIONAL = {
    LimitsAndMovements: 'Límite de montos y movimientos',
    ATM: 'Cajeros automáticos',
    MaxAmount: 'Monto máximo',
    Movements: 'Movimientos',
    CommerceAmount: 'Compras en comercios',
    OnlineAmount: 'Compras en línea y telefónicas'
  };

  static readonly SUCCESS_ADITIONAL = {
    Title: 'Los datos fueron guardados exitosamente',
    Subtitle: 'La invitación está lista',
    Button: 'Enviar invitación'
  };
}


