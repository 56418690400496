import { Numbers, Constants } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { CreditConstants } from 'src/core/constants/CreditConstants';
export class ServiceConstants {
  static readonly MODIFY_DAILY_LIMIT = 'modal-modify-daily-limit centered-modal';
  static readonly TOKEN_CONTAINER_CLASS = 'token-container';
  static readonly LEADING_SPACES_LETTERS_ACCENTS = /^\s+|\s(?=\s)|[^a-záéíóúA-ZÁÉÍÓÚñÑ\s]+/g;
  static readonly MAXLENGTH_LIMIT_SERVICES = 9;
  static readonly NON_AGENDABLE_TYPE = 5;
  static readonly LIMIT_TOOLTIP = 25;
  static readonly AGENDABLE_TYPE = 4;
  static readonly DECIMAL_FORMATTER = /^\d+(\.\d{1,2})?$/;
  static readonly BLOCK_PASTE = 'blockPaste';
  static readonly NAVIGATE_HOME = 'navigateHome';
  static readonly COLOR_LABELS = 'var(--secondary-800)';
  static readonly COLOR_AMOUNT = 'var(--primary-1000)';
  static readonly KEY_TAB = '\t';
  static readonly TYPE_SERVICE = 'OtrosBancos';
  static readonly CREDIT_KEY_WORD = 'credito';
  static readonly CAPITALIZE_CREDIT_KEY_WORD = 'CREDITOS';
  static readonly CREDIT_OTHER_BANKS_ID = 'creditOtherBank';
  static readonly ADD_CREDIT_NAME = 'Agenda de crédito';
  static readonly CONTAINER_CREDIT_SUCESSFUL = 'container-other-banks';
  static readonly MESSAGE_SERVICE = 'Pagos realizados después de las 6:00 p.m. serán procesados el siguiente día hábil.';
  static readonly SIGNSTURE_PANEL_INPUT = 'pass';
  static readonly CARD_HEIGHT = 165;
  static readonly CARD_WIDTH = 345;

  static readonly STYLE_CAROUSEL_DISPLAY = ` @media screen and (min-width: 1024px) {
    .swiper-pagination-bullets {display: none !important;}}`;
  static readonly STYLE_CAROUSEL_PAGINATION = ` .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom, .swiper-pagination-fraction {top: 90%;}`;
  static readonly SERVICES_WITHOUT_LIMIT = [
    Constants.SERVICE_CODE.Psegnal,
    Constants.SERVICE_CODE.Paggm
  ];

  static readonly TokenContainerClassAddressee = 'token-container-addressee';
  static readonly SERVICE_ID = {
    IMPFEDR: 20
  };

  static readonly CARD_MODAL_HEIGHT = 127;

  static readonly LOCAL_TAX_PATHS = [
    PATH.NotAgendableServices,
    PATH.ConfirmPayment,
    PATH.PaymentCompleted,
    PATH.SecurityCode
  ];

  static readonly SERVICES_HISTORY = [
    Constants.SERVICE_CODE.Sipare,
    Constants.SERVICE_CODE.Impfed,
    Constants.SERVICE_CODE.Impfedr
  ];

  static readonly CAPTURE_LINE_TYPES = {
    Agua: { key: 'Derechos por Suministro de Agua (vigente)', value: 'Pago de Agua', option: 'Derechos por Suministro de Agua (vencida)' },
    Predial: { key: 'Impuesto Predial (vigente)', value: 'Predial', option: 'Impuesto Predial (vencido)' },
    Tenencia: { key: 'Impuesto sobre Tenencia', value: 'Tenencia' }
  };

  static readonly FederalTaxReceiptFilterComponent = {
    ModalContainerElementRef: 'ModalContainer',
    Container: 'Container',
    Header: 'Header',
    Footer: 'Footer',
    Periods: 'periods',
    Title: 'Pago de impuestos',
    Subtitle: 'Selecciona la cuenta para realizar la búsqueda de declaraciones',
    Modal: {
      BtnContinue: {
        BtnClass: 'modals-btn btn-modal-federal-tax-receipt-filter',
        BtnClassImage: 'btn-img-class'
      },
      BtnCancel: {
        BtnClass: 'modals-btn btn-modal-federal-tax-receipt-filter-cancel',
      }
    },
    TabTitle: [
      { id: Numbers.One, title: 'Ver formato' },
      { id: Numbers.Two, title: 'Fecha' },
      { id: Numbers.Three, title: 'Referencia' },
      { id: Numbers.Four, title: 'Transacción' },
      { id: Numbers.Five, title: 'Monto' },
      { id: Numbers.Six, title: 'Estado' }
    ],
    OptionsConfigPeriodsLabel: 'Selecciona año'
  };

  static readonly ModalCancelFederalTaxReceipt = {
    ConfirmTemplateRef: 'Confirm',
    CompleteTemplateRef: 'Complete',
    Title: {
      Confirm: '¿Deseas cancelar tu recibo?',
      Complete: 'Cancelación de recibos por cuenta'
    },
    BtnPrimaryProperties: {
      ClassHTML: 'btn-primary-yes modals-btn'
    },
    BtnSecondaryProperties: {
      ClassHTML: 'btn-secondary-no modals-btn'
    },
    BtnAcceptButton: {
      ClassHTML: 'btn-modal-cancel-tax-receipt-accept modals-btn'
    }
  };

  static readonly USER_AGENT = {
    DeviceAndroiIos: /iPhone|Android|iPod|iPad|Macintosh/i
  };

  static readonly TYPE_CODE_SERVICE = {
    AMITELC: 'Número Telefónico',
    CONTELC: 'Número de contrato',
    IAVE: 'Número de tag',
    TELMEX: 'Número Telefónico',
    TCINB: 'Número de Tarjeta',
    SKY: Strings.ACCOUNT_NUMBER,
    SANBORN: Strings.ACCOUNT_NUMBER,
    PAGLIVER: Strings.ACCOUNT_NUMBER,
    MIXUP: 'Número de cuenta',
    CARNET: 'Número de Tarjeta',
    AMEX: 'Número de cuenta',
    PAGOPH: Strings.ACCOUNT_NUMBER,
    creditOtherBank: 'Número de crédito'
  };

  static readonly AGENDABLE_SERVICE_CODE = {
    Att: 'ATT',
    Unefon: 'UNEFON'
  };

  static serviceIdMap: { [key: string]: number };
  static {
    this.serviceIdMap = {
      [Constants.AGENDABLE_SERVICE_CODE.Tcinb]: 1,
      [Constants.SERVICE_CODE.Carnet]: 2,
      [Constants.AGENDABLE_SERVICE_CODE.Telmex]: 3,
      [Constants.AGENDABLE_SERVICE_CODE.Amex]: 4,
      [Constants.AGENDABLE_SERVICE_CODE.Contelc]: 5,
      [Constants.AGENDABLE_SERVICE_CODE.Pagliver]: 6,
      [Constants.AGENDABLE_SERVICE_CODE.Amitelc]: 7,
      [Constants.AGENDABLE_SERVICE_CODE.Pagoph]: 8,
      [Constants.AGENDABLE_SERVICE_CODE.Sanborns]: 9,
      [Constants.AGENDABLE_SERVICE_CODE.Sky]: 10,
      [Constants.AGENDABLE_SERVICE_CODE.Mixup]: 11,
      [Constants.AGENDABLE_SERVICE_CODE.Iave]: 12,
      [ServiceConstants.AGENDABLE_SERVICE_CODE.Att]: 13,
      [ServiceConstants.AGENDABLE_SERVICE_CODE.Unefon]: 14,
      [CreditConstants.AGENDABLE_SERVICE_CODE.creditOthersBanks]: 15
    };
  }

  static readonly AGENDABLE_SERVICE_ID = [
    this.AGENDABLE_SERVICE_CODE.Att,
    this.AGENDABLE_SERVICE_CODE.Unefon
  ];

  static readonly SERVICE_PAYMENT_TIME_TO_APPLY = (time: number) => time > 1 ?
    `El pago se verá reflejado después de ${time} horas, sin embargo, conservará la fecha en que fue realizado.` : time === 1 ?
      `El pago se verá reflejado después de ${time} hora, sin embargo, conservará la fecha en que fue realizado.` :
      `El pago se verá reflejado de inmediato, sin embargo, se conservará la fecha en que fue realizado.`;

  static readonly SERVICES_LABEL = {
    [Constants.SERVICE_CODE.Psegnal]: 'Deducible de autos',
    [Constants.SERVICE_CODE.SuaPayment]: 'SUA',
    [Constants.SERVICE_CODE.Paggm]: 'Gastos Médicos Mayores',
    [Constants.SERVICE_CODE.ExternalTrade]: 'Comercio Exterior',
    [Constants.SERVICE_CODE.Impfedr]: 'Pago referenciado',
    [Constants.SERVICE_CODE.Sipare]: 'Sipare'
  };

  static readonly ERROR_SIPARE = 'Línea de captura no válida.';
}