import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GetBureauAlertsEmailRequest } from 'src/app/interface/dto/GetBureauAlertsEmailRequest';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { BureauAlertsRequest } from 'src/app/interface/dto/BureauAlertsRequest';
import { Strings } from 'src/core/constants/Strings';
import { GetBureauAlertsRequest } from 'src/app/interface/dto/GetBureauAlertsRequest';
import { Utils } from 'src/core/utils/utils';
import { HttpStatusCode } from '@angular/common/http';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { ISimpleValue } from 'src/app/interface/ISimpleValue';
import { IModalOpenLeave } from 'src/app/interface/ModalOpenLeave';

@Injectable({
  providedIn: 'root'
})
export class BureauAlertsService {
  private readonly _dataBureauAlert: BehaviorSubject<{ email?: string, oldEmail?: string}> =
  new BehaviorSubject<{ email?: string, oldEmail?: string}>({ email: Strings.EMPTY,oldEmail: Strings.EMPTY });
  private readonly _showLeaveModal: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly _showLeaveModalMap: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly _showLeaveModalSuccess: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly _alertSuccess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _bureauAlertExists: BehaviorSubject<{value: boolean, isError: boolean}> = new BehaviorSubject<{value: boolean, isError: boolean}>
  ({value: null, isError: true});
  private readonly _showOpenModal: BehaviorSubject<IModalOpenLeave> = new BehaviorSubject<IModalOpenLeave>({
    modal: Strings.EMPTY
  });
  constructor(private readonly baseService: IBaseService) { }

  get dataBureauAlert$() {
    return this._dataBureauAlert.asObservable();
  }

  get dataBureauAlert() {
    return this._dataBureauAlert.getValue();
  }

  set dataBureauAlert(data: { email?: string, oldEmail?: string}) {
    this._dataBureauAlert.next(data);
  }

  get leaveModal() {
    return this._showLeaveModal.getValue();
  }

  set leaveModal(data: ISimpleValue) {
    this._showLeaveModal.next(data);
  }

  get leaveModalSuccess() {
    return this._showLeaveModalSuccess.getValue();
  }

  set leaveModalSuccess(data: ISimpleValue) {
    this._showLeaveModalSuccess.next(data);
  }

  get leaveModalMap() {
    return this._showLeaveModalMap.getValue();
  }

  set leaveModalMap(data: ISimpleValue) {
    this._showLeaveModalMap.next(data);
  }

  get alertSuccess$() {
    return this._alertSuccess.asObservable();
  }

  get alertSuccess() {
    return this._alertSuccess.getValue();
  }

  set alertSuccess(data: boolean) {
    this._alertSuccess.next(data);
  }

  get bureauAlertExists$() {
    return this._bureauAlertExists.asObservable();
  }

  get bureauAlertExists() {
    return this._bureauAlertExists.getValue();
  }

  set bureauAlertExists(data: {value: boolean, isError: boolean}) {
    this._bureauAlertExists.next(data);
  }

  getEmail() {
    return this.baseService.genericGet<GenericResponse<string>>(new GetBureauAlertsEmailRequest(), { isSecure: true, addSession: true });
  }

  bureauAlerts(type: string, token: string, email?: string) {
    const request = new BureauAlertsRequest({
      medioAceptacion: type,
      otp: token
    });
    if(email !== Strings.EMPTY) {
      request.data.correo = email;
    }
    return this.baseService.genericPost<GenericResponse<number | string> >(request, { isSecure: true, addSession: true });
  }

  async getBureauAlerts(): Promise<boolean> {
    try {
      if (this.bureauAlertExists.value === null || this.bureauAlertExists.isError === true) {
        const response = await this.baseService.genericGet<GenericResponse<number | string>>
          (new GetBureauAlertsRequest(), { isSecure: true, addSession: true });
        if (!response) {
          throw new BusinessError(Strings.WITHOUT_MOVEMENTS_MSG, HttpStatusCode.NoContent);
        }
        this.bureauAlertExists.value = true;
        this.bureauAlertExists.isError = false;
      }
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.bureauAlertExists.value = errorDetails.code !== HttpStatusCode.NoContent;
      this.bureauAlertExists.isError =  errorDetails.code !== HttpStatusCode.NoContent;
    }
    return this.bureauAlertExists.value;
  }

  get modalOpenLave() {
    return this._showOpenModal.getValue();
  }

  set modalOpenLave(data: IModalOpenLeave) {
    this._showOpenModal.next(data);
  }
}
