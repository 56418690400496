import { PathModel } from 'src/app/models/PathModel';

export interface IActivationCheckBooksRequest {
  idChequera: number;
  numeroCuenta: string;
  otp: string;
}

export class ActivationCheckBooksRequest {
  constructor(public data: IActivationCheckBooksRequest) { }

  get endpoint(): PathModel {
      return PathModel.ActivationCheckBooks;
  }
}
