import { PathModel } from '../models/PathModel';

interface IProductsPLRequest {
    IdSession: string;
}

export class ProductsPLRequest{
    constructor(public data: IProductsPLRequest){}

    get endpoint(): PathModel {
      return PathModel.StockProducts;
  }
}
