<div [ngClass]="investmentDinbur ?'content-investment':''">
  <div id="modal" class="content-general-exception">
    <div id="header">
      <div [ngClass]="isAddProduct ? 'no-add-product':''">
        <img *ngIf="showIconClose && !modifyDailyLimitModal && !investmentDinbur && !isSuaPayment" id="btn-close"
          alt="close" width="auto"
          height="auto" role="button" class="src-general-close" (click)="closeModal()" [src]="remove | cdnImage">
      </div>
      <div id="header-text" class="d-flex flex-row col-12 pt-3 pl-2 justify-content-start">
        <img *ngIf="cancelCardModal" [src]="alertInformation.imageHead" alt="question"
        width="auto" height="auto">
        <img *ngIf="isAddProduct && !investmentDinbur" id="icon-error"
          alt="icon" width="auto" height="auto" [src]="errorProduct | cdnImage">
        <img *ngIf="modifyDailyLimitModal" [src]="questionMarkCrIcon | cdnImage" alt="Question"
          width="auto" height="auto">
        <img *ngIf="investmentDinbur" [src]="errorIcon | cdnImage" alt="Error"
        width="auto" height="auto">
        <div id="div-add">
          <label id="main-header"
          [ngClass]="!investmentDinbur?'label-general-header header-l':
          hourOperation?'text-center header-s pad-investment pt-4':'text-center header-s pad-investment pt-4 px-1'">
            {{headerModal || alertInformation.title }}</label>
        </div>
      </div>
    </div>
    <hr class="hr-margin" *ngIf="newLine"/>
    <div id="main">
      <div id="additional-class" class="d-flex flex-row col-12 pl-2 justify-content-start" *ngIf="showDescription">
        <img *ngIf="alertInformation?.imageMain" [src]="alertInformation.imageMain" alt="alert"
        width="auto" height="auto">
        <label *ngIf="!modifyDailyLimitModal" id="main-label" class="label-general-main"
        [ngClass]="!investmentDinbur ?'body-m px-4':'body-s w-100 pb-2 px-4'">
          {{labelMain || alertInformation?.description }}
        </label>
        <label *ngIf="modifyDailyLimitModal" id="mainLabelModify" class="label-general-main mb-3 mx-3"
            [innerHTML]="labelMain">
        </label>
      </div>
      <div *ngIf="line" class="div-line div-pad">
        <a class="line-divider"></a>
      </div>
      <div id="div-btn" class="top-general-btn"
       [ngClass]="!investmentDinbur ? 'btn-align':'btn-align-investment'">
        <button id="btn-correct" class="btn-general-accept" (click)="accept()"
          [ngClass]="investmentDinbur ?
          hourOperation ?'accept-btn-investment' :'accept-btn-investment hours-btn'
          :'accept-btn'"><span
            id="span-class">{{continue || alertInformation.continue || acceptButton }}</span>
          <img *ngIf="isSuaPayment" class="add-file-image" [src]="addFile | cdnImage" alt="Add"
            width="auto" height="auto">
        </button>
        <button id="btn-international-modal" class="btn-general-accept accept-btn btn-additional"
          (click)="closeModal('cancelAccount')">
          <span>{{btnPlus}}</span></button>
      </div>
      <div *ngIf="additionalBtn" id="btn-attention" class="btn-align top-btn-additional">
        <button id="btn-correctA" class="btn-general-accept accept-btn btn-additional" (click)="closeModal()">
          <span>{{btnPlus}}</span></button>
      </div>

      <div id="div-cancel" class="d-flex justify-content-center"
        [ngClass]="showBtnCancel ? 'show-element' : 'hide-element'">
        <button id="close-modal" class="button-m"
          (click)="cancelModal()">{{textBtnCancel}}</button>
      </div>

    </div>
  </div>
</div>
