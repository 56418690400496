<div scrollTop [isScrollTop]="isScrollTop" [id]="classSize" class="container-fluid d-flex justify-content-between"
  [ngClass]="{'container-fix': autoWidth, 'custom-padding' : !padding}">
  <img *ngIf="showIcon" [ngClass]="{'src-close' : padding}" [src]="iconClose | cdnImage" width="auto" height="auto"
    alt="Cerrar">
  <div class="d-flex flex-row col-12 pl-2 center-modal-error" [ngClass]="paddingTopTitle ? 'pt-3' : ''">
    <label class="title header-l color-label">{{titleError}}</label>
  </div>
  <div class="d-flex flex-row pt-3 px-1 justify-content-center">
    <label class="color-label word-wrap word-adjust" *ngIf="errorMessage">{{ errorMessage }}</label>
  </div>
  <div class="d-flex justify-content-center align-items-center pt-5 mobile-contain"
    *ngIf="showButtons && showOtherOperation">
    <button class="colored-btn button-m operation btn-generic"
      (click)="clearForm()" (keydown.enter)="$event.preventDefault()">
      Otra operación
      <img [src]="chevronRightIcon | cdnImage" alt=">" width="auto" height="auto">
    </button>
  </div>

  <div class="d-flex justify-content-center align-items-center pt-3 mobile-contain"
    *ngIf="(showButtons || mainPage) && !isAppExternal">
    <button class=" button-m main btn-generic" [ngClass]="showOtherOperation?'outline-btn':'colored-btn'"
      (click)="goPage()" (keydown.enter)="$event.preventDefault()">
      Página principal
    </button>
  </div>
</div>
