import { PathModel } from 'src/app/models/PathModel';


export type IVariableInvestmentSaleRequest = {
  cuenta: string;
  idCuentaProducto: number;
  titulos: number;
};

export class VariableInvestmentSaleRequest{
  constructor(public data: IVariableInvestmentSaleRequest){}

  get endpoint(): PathModel{
    return PathModel.VariableInvestmentSale;
  }
}
