import { PathModel } from 'src/app/models/PathModel';

export type TUpdateNotificationsRequest = {
    listaMensajes: Array<{
        idMensaje: string,
        estatus: string
    }>
};

export class UpdateNotificationsRequest {
    constructor(public data: TUpdateNotificationsRequest) { }

    get endpoint(): PathModel {
        return PathModel.UpdateNotifications;
    }

}