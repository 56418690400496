import { PathModel } from 'src/app/models/PathModel';

type TAforePartialWithdrawalsUnemploymentRequest = {
  folio: string,
  modalidad: string,
  clienteInbursaAfore: number,
  cuentaClabe: string
};

export class AforePartialWithdrawalsUnemploymentRequest {
    constructor(public data: TAforePartialWithdrawalsUnemploymentRequest) { }

    get endpoint(): PathModel {
        return PathModel.VoucherAforePartialWithdrawalUnemployment;
    }

}
