import { Component, OnInit, Input, ViewChild, ElementRef, TemplateRef, WritableSignal, ChangeDetectorRef, HostListener } from '@angular/core';
import { formatDate } from '@angular/common';
import { Constants, FormatDate, Numbers, Char, InputTypes } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/shared/modal.service';
import { PATH } from 'src/core/constants/Path';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { map, Observable } from 'rxjs';
import { Strings } from 'src/core/constants/Strings';
import { GetDataCardResponse } from 'src/app/interface/dto/GetDataCardResponse';
import { ICardNumberInfo } from 'src/app/interface/dto/ICardNumberInfo';
import { StateCardNumberInfoService } from 'src/app/services/state-card-number-info.service';
import { Utils } from 'src/core/utils/utils';
import { AlertService } from 'src/app/services/alert.service';
import { ModalMyCardsComponent } from 'src/app/control-card/components/modal-my-cards/modal-my-cards.component';
import { ControlCardService } from 'src/app/services/control-card.service';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalSendEmailComponent } from 'src/app/component/modal-send-email/modal-send-email.component';
import { ITransactionVoucher } from 'src/app/interface/TransactionVoucherRequest';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { AdditionalService } from 'src/app/services/additional-cards.service';
import { ControlCardStrings } from 'src/core/constants/ControlCardStrings';
import { Format } from 'src/core/constants/FormatDate';
import { ModalUtils } from 'src/core/utils/ModalUtils';
import { ModalEndProcessAlertComponent } from 'src/app/shared/components/modal-end-process-alert/modal-end-process-alert.component';
import { ModalMyCardsExtensionTwoService } from 'src/app/services/modal-my-cards.extension-two.service';
import { ModalMyCardsService } from 'src/app/control-card/components/modal-my-cards/service/modal-my-cards.service';
import { DomiciliationStrings } from 'src/core/constants/DomiciliationStrings';
import { ScrollOptions } from 'src/core/constants/ScrollConstants';
import { FormGroup } from '@angular/forms';
import { InputConfig } from 'src/app/interface/IInputConfig';
import { MapService } from 'src/app/services/map.service';
import { Directions } from 'src/app/services/data-checkbook.service';
import { AdditionalConstants } from 'src/core/constants/AdditionalConstants';
import { environment } from 'src/environments/environment';
import { AdditionalStrings } from 'src/core/constants/AdditionalStrings';

@Component({
  selector: 'app-modal-request-confirmation',
  templateUrl: './modal-request-confirmation.component.html',
  styleUrls: ['./modal-request-confirmation.component.css']
})
export class ModalRequestConfirmationComponent implements OnInit {

  getDataCardsInfo: GetDataCardResponse;
  iconClose: string = Resources.CLOSE_CIRCLE_BLUE;
  iconCheck: string = Resources.CHECK_GREEN_FILL;
  completeDate: string;
  sentinelIp: string = Strings.EMPTY;
  bankCard: string = Strings.EMPTY;
  cardNumberInfo: ICardNumberInfo;
  cardNumberInfo$: Observable<ICardNumberInfo> = this.cardNumberInfoService.cardNumberInfo$;
  sourceInfo: string;
  pictureCard: string = Strings.EMPTY;
  confirmRequestAditional: boolean = false;
  finish: boolean = false;
  invoice: number = Numbers.Zero;
  chevronRight: string = Resources.SHAPE_CONTINUE;
  modalConent: string = ModalConstants.MODAL_OPTIONS.ModalConent;
  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => {
      const getModalContent = (): HTMLDivElement | null => {
        return document.querySelector(`.${ModalConstants.MODAL_OPTIONS.ModalRequestedRenewal.trim().split(Char.WhiteSpace)[Numbers.Zero]} .${this.modalConent}`)
          || document.querySelector(`.${ModalConstants.MODAL_OPTIONS.ModalSizeConfirmation.trim().split(Char.WhiteSpace)[Numbers.Zero]} .${this.modalConent}`);
      };
      const modal = getModalContent();
      modal.style.display = InputTypes.Block;
      return { breakpoints };
    })
  );
  cardNumber: string = Strings.EMPTY;
  cardType: string = Strings.EMPTY;
  @Input() renovation: boolean = false;
  @Input() reception: boolean = false;
  @Input() showButtonClose: boolean = true;
  @Input() data: ITransactionVoucher;
  @ViewChild('ModalContainer') modalContainer: ElementRef<HTMLInputElement>;
  @ViewChild('ModifyReception') modifyReception: TemplateRef<ModalMyCardsComponent>;
  beneficiary: string = Strings.EMPTY;
  rfc: string = Strings.EMPTY;
  curp: string = Strings.EMPTY;
  gender: string = Strings.EMPTY;
  civilStatus: string = Strings.EMPTY;
  date: string = Strings.EMPTY;
  percent: string = Strings.EMPTY;
  isEmail: boolean = false;
  yourAccount: string = Strings.SELECT_ACCOUNT.FromYourAccount;
  address: string = Strings.EMPTY;
  isCreditCard: boolean = false;
  typeProduct: Object = DomiciliationStrings.TYPE_PRODUCT;
  mapInstance: NgbModalRef;
  chevronLeft: string = Resources.BLUE_CHEVRON_LEFT;
  titleModalBranch: string = Strings.EMPTY;
  subtitle: string = ControlCardStrings.MAP_SUBTITLE;
  mapForm: FormGroup;
  branchInputConfig: InputConfig[] = this.controlCardService.branchConfig;
  extractLabel = Utils.extractLabel;
  clickedButton: boolean = false;
  directions: WritableSignal<Array<Directions>> = this.mapService.directionCopySignal;
  lblContinuar: string = ControlCardStrings.CONTINUE;
  captureImage: { name: string, element: HTMLElement };
  imageName: string = Strings.EMPTY;
  curpAdditionalCreditCard: boolean = environment.hiddenComponents.curpAdditionalCreditCard;
  modalInstance: NgbModalRef;
  constructor(
    public activeModal: NgbActiveModal,
    private readonly _router: Router,
    private readonly modalService: ModalService,
    private readonly responsiveService: ResponsiveService,
    private readonly cardNumberInfoService: StateCardNumberInfoService,
    private readonly additionalCardService: AdditionalService,
    private readonly alertService: AlertService,
    private readonly controlShowModal: ControlCardService,
    private readonly modalMyCardsExtensionTwoService: ModalMyCardsExtensionTwoService,
    public modalMyCardsService: ModalMyCardsService,
    private readonly controlCardService: ControlCardService,
    private readonly mapService: MapService,
    private readonly changeDetectorRef : ChangeDetectorRef
  ) { }

  async ngOnInit(): Promise<void> {
    const operationDate = new Date();
    this.completeDate = formatDate(operationDate, FormatDate.DayMonthYearComplete, Constants.LOCALE_ES_MX);

    if (this.controlShowModal.isResponseModal) {
      this.reception = this.controlShowModal.isResponseModal;
      this.confirmRequestAditional = false;
    } else if (this.controlShowModal.isRequesModal) {
      this.renovation = this.controlShowModal.isRequesModal;

    } else if (!this.controlShowModal.isResponseModal) {
      this.reception = true;
      this.confirmRequestAditional = true;
      this.controlShowModal.isResponseModal = true;
    }

    this.address = this.mapService.showMapSignal() ? this.additionalCardService.addressAdditional : this.additionalCardService.addressObtained;
    if (this.reception) {
      this.beneficiary = this.additionalCardService.concatName(this.additionalCardService.aditionalForm);
      this.rfc = this.additionalCardService.aditionalForm.value.rfc.trim();
      this.curp = this.additionalCardService.aditionalForm.value.curp.trim();
      this.gender = this.additionalCardService.aditionalForm.value.gender.trim();
      this.civilStatus = this.additionalCardService.aditionalForm.value.civilStatus.trim();
      const birthday = this.additionalCardService.aditionalForm.value.birthday;
      const auxDate = birthday.split(Char.Slash);
      const dateFormat = new Date(`${auxDate[Numbers.Two]}${Char.Slash}${auxDate[Numbers.One]}${Char.Slash}${auxDate[Numbers.Zero]}`);
      this.date = formatDate(dateFormat, Format.BasicFormatDayMonthYear, Constants.LOCALE_ES_MX);
      this.percent = (Number(this.additionalCardService.aditionalForm.value.percent)).toString();
    }
    const info = this.cardNumberInfoService.cardNumberInfo.CardDataInfo;
    this.bankCard     = info.img;
    this.cardNumber   = info.CardNumber;
    this.cardType     = info.type;
    this.isCreditCard = this.modalMyCardsService.isCreditCard(info.SourceCard);
  }

  closeModal() {
    const options: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeSm,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAlertEndProcess
    };
    ModalUtils.showModal(this.modalService, ModalEndProcessAlertComponent, options, this.modalConent, ModalConstants.MODAL_OPTIONS.RequestConfirmation);
    this.modalMyCardsExtensionTwoService.backdropCustom();
  }

  close() {
    this.controlShowModal.isResponseModal = false;
    this.controlShowModal.isRequesModal = false;
    this.modalService.close();
  }

  closeModalEmail() {
    this.closeModal();
    if (this.isEmail) {
      this.modalService.modalRef.dismiss();
      this.activeModal.close();
      this.activeModal.dismiss();
    }
    this.activeModal.dismiss();
  }

  closeRenew() {
    this.modalService.close();
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalSendEmail
    };
    this.modalService.open(ModalSendEmailComponent, modalOptions, this.data);
  }

  closeAditional() {
    this.controlShowModal.isResponseModal = true;
    this.modalService.close();
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalSendEmail
    };
    this.modalService.open(ModalSendEmailComponent, modalOptions, this.data);
  }

  navigate() {
    this.controlShowModal.isResponseModal = false;
    this.controlShowModal.isRequesModal = false;
    this._router.navigate([PATH.Home]);
  }

  async confirm() {
    await this.additionalCardRequest();
    if(this.invoice === Numbers.Zero) {
      return;
    }
    this.showButtonClose = false;
    this.confirmRequestAditional = true;
    this.modalContainer.nativeElement.scrollIntoView({ behavior: ScrollOptions.Smooth, block: ScrollOptions.Center });
    const date = new Date();
      this.imageName = formatDate(date,Format.YearMonthDayTime, Constants.LOCALE_ES_MX);
      this.captureImage = {
        name: this.imageName,
        element: document.getElementById(AdditionalConstants.CLASS_SCREENSHOT)
      };
      this.changeDetectorRef.detectChanges();
  }

  continueProcessCard(content: TemplateRef<ModalRequestConfirmationComponent>) {
    this.modalService.close();
    const modalOptions = {
      ariaLabelledBy: Constants.MODAL_OPTIONS.AriaLabel,
      centered: true,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalRequestConfirmation
    };
    this.modalService.open(content, modalOptions);
  }

  modify(content?: TemplateRef<ModalMyCardsComponent>) {
    this.modalService.modalInstance.close();
    const modalOptions = {
      centered: true,
      size:Constants.MODAL_OPTIONS.SizeXl,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalMyCards,
    };
    this.modalService.open(content, modalOptions);
  }

  async additionalCardRequest(){
    try {
      this.invoice = await this.additionalCardService.additionalCardRequest(this.cardNumberInfoService.cardNumberInfo.CardDataInfo.account);
    } catch (error) {
      this.modalService.close();
      const errorDetail = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetail.msg,errorDetail.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      },
      {
        onSuccess: () => this.close()
      });
    }
  }

  @HostListener('window:popstate')
  onBack(){
    this.modify(this.modifyReception);
  }
}
