import { AccountType, Constants } from 'src/core/constants/Constants';

export class CodeflexConstants {
  static readonly CODEFLEX_ROOT = 'codefl';
  static readonly CODEFLEX_DAYS_31 = 31;
  static readonly CODEFLEX_MONTH = 12;
  static readonly CODEFLEX_YEAR = 9999;
  static readonly CODEFLEX_ACCOUNT = (accountType: string, accountNumber: string) => `${accountType} (${accountNumber})`;
  static readonly MIN_DATE = { year: new Date().getFullYear(), month: new Date().getMonth() + Constants.MONTH.ONE, day: new Date().getDate() };
  static readonly INVESTMENT_OPERATION_CODE= 'ABONO_INTERES';
}

export enum ConversionsValue {
  HoursPerDay = 24,
  MinutesPerHour = 60,
  millisecondsPerSecond = 1000
}

export enum CodeflexFormatDate {
  HoursMinutesSeconds = 'HH:mm:ss'
}

export enum CodeflexElementsHTML{
  ButtonsSpacingMobile = 'pt-3 mt-2',
  ButtonsSpacingDesktop = 'pt-4 mt-3',
  ColoredButton = 'colored-btn',
  OutlineButton = 'outline-btn',
  SuccessfulOperation = 'successful-operation'
}

export const SubAccountsData = [
  {
    type: AccountType.CODEFLEX91,
    daysApplication: 91
  },
  {
    type: AccountType.CODEFLEX,
    daysApplication: 31
  },
  {
    type: 'CTATIIE',
    daysApplication: 31
  },
  {
    type: 'INVBLACK',
    daysApplication: 31
  },
  {
    type: 'INBUR60',
    daysApplication: 61
  }
];
