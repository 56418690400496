<div class="padd-modal-opt" [class]="showOtp?'class-div':''">
    <ng-container *ngIf="!showOtp">
        <div class="flex-otp">
            <label  class="label-modal-otp">Código Token</label>
        </div>
        <div>
            <app-form-code-security (showConfirmData)="getOtp($event)" [dataForm]="[]"> </app-form-code-security>
        </div>
    </ng-container>
    <ng-container *ngIf="showOtp">
        <div class="d-flex justify-content-end">
            <img role="button" class="close" (click)="close()" aria-label="Close" [src]="removeOperation | cdnImage"
                width="24px" height="24px" alt="Close" (keydown.enter)="$event.preventDefault()">
        </div>

        <div class="title-opt" [ngClass]="{'mb-4':isApp}">
            <label  class="main-label-modal-otp">Código token</label>
            <label  class="body-s secondary-color width-insert">Ingresa los datos de la
                operación</label>
        </div>
        <div>
            <app-form-code-security (showConfirmData)="getOtp($event)" btnClass="btn-checkbook"[nextView]="nextView"
                colorHead="color-default" btnContinueClass="div-m-btn" headerImageClass="measures-checkbook"
                divLabelToken="label-token-style" labelModal="padding-label-checkbook"
                classInput="input-token-code-check-book number-token-code text-center form-control input-otp"
                classAlert="alert-form-code-security-cb" [dataForm]="[]" ngClassAlert="margin-alert"
                  ngClassIcon="margin-icon-alert" styleIcon="icon-checkbook" [modalData]="modalData"
                  [autofocus]="autofocus" >
            </app-form-code-security>
        </div>
    </ng-container>
  </div>
