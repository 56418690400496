import { PathModel } from 'src/app/models/PathModel';

type TConsultSubAccountsRequest = {
    IdSession: string,
    Cuenta: string
};

export class ConsultSubAccountsRequest {
    constructor(public data: TConsultSubAccountsRequest) { }

    get endpoint(): PathModel {
        return PathModel.ConsultSubAccounts;
    }
}
