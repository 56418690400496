<ng-container *ngIf="vm$ | async as breakpoints">
  <div class="body-checks-success" id="checkbook-activation-successful" [hidden]="!showModalChecksActivation">
    <div class="col-sm-12 d-flex justify-content-end close"></div>
    <div class="col-sm-12">
      <div class="div-title">
        <div class="d-flex justify-content-center">
          <img [src]="checkFill |cdnImage" width="56px" height="56px" alt="Check">
        </div>
        <div class="d-flex justify-content-center pt-2">
          <span class="wrap main-color-label " [ngClass]="breakpoints.mobile ? 'header-m' : 'header-l'">
            {{activationCheckBooklbl}}
          </span>
        </div>
        <div class="pt-2 d-flex justify-content-center">
          <span class="body-m main-color-label">{{reference}} {{referenceNumber}} </span>
        </div>
        <div class="pt-2 d-flex justify-content-center detail-m main-color-label">
          {{completeDate}}h
        </div>
        <div class="my-4 d-flex justify-content-center align-items-center">
          <app-shared-buttons [captureImage]="captureImage" [shareCapture]="captureImage" [download]="true"
            [fileName]="'ActivaciónChequera'" class="ignore-element" [hideMail]="true"
            (onCaptureError)="closedModal($event)">
          </app-shared-buttons>
        </div>
      </div>

      <div>
        <div class="pb-2">
          <span class="header-xs main-color-label">
            {{operationInformation}}
          </span>
        </div>
        <div [ngClass]="breakpoints.mobile ? '' : 'checks-align'">
          <app-info-label headClass="body-s" classes="p-0"
           margin="mt-2 mb-2 margin-hr" [showHr]="true" head="{{checkDataActivation}}"
            bodyClass="detail-s" body="No. de chequera">
          </app-info-label>
        </div>
      </div>
      <div class="margin-list ignore-element">
        <div class="d-flex justify-content-center">
          <button class="button-m modal-check-done-btn modals-btn border-btn-ok"
           [ngClass]="breakpoints.mobile?'button-s':'button-m'"
            (click)="returnChecks()">
            {{ready}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
