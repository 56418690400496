import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Strings } from 'src/core/constants/Strings';
import { ICheckTabId } from 'src/app/interface/IcheckTabId';
import { ISimpleValue } from 'src/app/interface/ISimpleValue';


@Injectable({
  providedIn: 'root'
})
export class ServiceCheckTabIdService {

  private readonly stateCheckTabId: BehaviorSubject<ICheckTabId> = new BehaviorSubject<ICheckTabId>({id: Strings.EMPTY });
  private readonly _showBtnChecks: BehaviorSubject<ISimpleValue> = new BehaviorSubject<ISimpleValue>({ value: false });
  private readonly _showChecks: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _showTabs: BehaviorSubject<{checks: boolean, activation: boolean}> =
  new BehaviorSubject<{checks: boolean, activation: boolean}>({checks: false, activation: false});

  constructor() { }

  get ICheckTabIdObservable() {
    return this.stateCheckTabId.asObservable();
  }

  set ICheckTabIdData(data: ICheckTabId) {
    this.stateCheckTabId.next(data);
  }

  get ICheckTabIdServiceBS(): BehaviorSubject<ICheckTabId> {
    return this.stateCheckTabId;
  }

  get showBtnChecks() {
    return this._showBtnChecks.getValue();
  }

  set showBtnChecks(data: ISimpleValue) {
    this._showBtnChecks.next(data);
  }

  get showChecks$() {
    return this._showChecks;
  }

  get showChecks() {
    return this._showChecks.getValue();
  }

  set showChecks(data: boolean) {
    this._showChecks.next(data);
  }

  get showTabs$() {
    return this._showTabs;
  }

  get showTabs() {
    return this._showTabs.getValue();
  }

  set showTabs(data:{checks: boolean, activation: boolean}) {
    this._showTabs.next(data);
  }

  returnShowBtn () {
    return this._showBtnChecks;
  }
}
