import { PathModel } from 'src/app/models/PathModel';

export type TNotificationsInformationRequest = {
    idSession?: string,
    clienteInbursa: string,
    fechaInicio?: string,
    fechaFin?: string,
    estatus?: string,
    sistemaOrigen?: string
};

export class NotificationsInformationRequest {
    constructor(public data: TNotificationsInformationRequest){ }

    get endpoint(): PathModel {
        return PathModel.NotificationInformation;
    }
    
}