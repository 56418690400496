<ng-container *ngIf="vm$ | async as vm">
  <div #containerGeneral   *ngIf="(!successConfirmChecks && !modalData) ||
  ( successConfirmChecks&& modalData && successPost)">

    <div class="d-flex justify-content-end pe-3 pt-3" ngbAutofocus>
      <img role="button" aria-label="Close" width="auto" height="auto"
        [src]="imgClose | cdnImage" alt="Close"
        class="close-checkbook-details"
        autofocus
        (click)=" confirmLeavePage()"
        *ngIf="!successConfirmChecks"
        tabindex="0">
    </div>
    <div class="body-modal-checkbook-details">
      <div class="checks-info-title mt-3 mb-3" *ngIf="!successConfirmChecks">
        <div class="d-flex justify-content-center align-items-center default-color"
        [ngClass]="!isMobile ? 'header-l' : 'header-m'">
          {{requestConfirmation}}
        </div>
        <div class="d-flex justify-content-center align-items-center secondary-color mt-1"
        [ngClass]="!isMobile ? 'body-m' : 'body-s'">
          {{dataConfirmationLbl}}
        </div>
      </div>

      <div class="checks-info-title mt-3 mb-3" *ngIf="successConfirmChecks">
        <div class="checks-info-title">
          <img [src]="checkFill | cdnImage" alt=">"
          height="auto" width="auto" class="mb-2 check-success"/>
          <span class="default-color header-l"> {{checkbookRequest}}</span>
          <div class="detail-m mt-1"> {{completeDate}}h </div>
        </div>
        <div class="d-flex justify-content-center align-items-center mb-3 mt-3">
          <app-shared-buttons [hideMail]="true"  [captureImage]="captureImage"
          [shareCapture]="captureImage" class="ignore-element"></app-shared-buttons>
        </div>
      </div>

        <span class="header-s default-color">{{associatedAccount}}</span>
        <div class="d-flex flex-row gap-3 align-items-center mt-2">
          <div *ngIf="!isMobile">
            <img [src]="imageCard" alt="image" width="auto" height="auto"
               class="card-img-checks">
          </div>
          <div class="d-flex flex-column">
            <span class="body-s text-secondary-800">{{accountNumber | maskAccount : 4}} {{accountName}}</span>
            <span class="detail-s">{{chargeToAccount}}</span>
          </div>
        </div>
        <hr class="hr-color hr-checks">


        <span class="header-s default-color">{{operationInformation}}</span>
        <div class="dispute-info-data-detail d-flex">
          <text-ellipsis [text]="branchName" classes="body-s default-color"
                    [styles]="styles"></text-ellipsis>
          <span class="detail-s ml-data-container"> {{checkbookPickupLocation}} </span>
          <hr class="hr-color hr-checks">
        </div>

      <div *ngIf="!successConfirmChecks">
        <div class="d-flex" [ngClass]="!isMobile ? 'justify-content-center' : '' ">
          <button (click)="continue()"
            class="button-m btn-continue-check text-white modals-btn btn-close-check mt-3 mr-3"
            [ngClass]="isMobile ? 'w-100' : '' ">
            {{confirmAndContinue}}
            <img [src]="chevronRight | cdnImage" alt=">" height="24" width="24"/>
          </button>
        </div>

        <div class="d-flex" [ngClass]="!isMobile ? 'justify-content-center' : '' ">
          <button (click)="modifyLocation()"
            class="button-m btn-modify-check modals-btn btn-close-check mt-3"
            [ngClass]="isMobile ? 'w-100' : '' ">
            {{modify}}
          </button>
        </div>
      </div>
      <div *ngIf="successConfirmChecks" class="d-flex ignore-element
      "
      [ngClass]="!isMobile ? 'justify-content-center' : '' ">
        <button (click)="returnChecks()"
            class="button-m btn-continue-check modals-btn text-white btn-close-check mt-2 mr-3"
            [ngClass]="isMobile ? 'w-100' : '' ">
            {{ready}}
          </button>
      </div>
    </div>
  </div>

</ng-container>

<ng-template #ModalMapFooter>
  <ng-container *ngIf="vm$ | async as vm">
    <div class="d-flex justify-content-center align-items-center mt-3">
      <button
        [disabled]="!vm.resultBranches"
        [ngClass]="{'disabled-btn': !vm.resultBranches}"
        [class.button-s]="isMobile"
        [class.button-m]="!isMobile"
        class="colored-btn modals-btn modal-btn-map"
        (click)="showConfirmCheck()">
        {{continueBtn}}
        <img [src]="iconChevron | cdnImage" alt=">" width="auto" height="auto"/>
      </button>
    </div>
  </ng-container>
</ng-template>


