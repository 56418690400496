<ng-container *ngIf="breakpoints$ | async as breakpoints">
  <div class="d-flex justify-content-center align-items-center flex-row gap-3 margin-download">
    <img class="image-size" [src]="inbursa | cdnImage"
    alt="Inbursa Logo" width="auto" height="auto" />
    <img *ngIf="!isDeviceMT" [src]="qr | cdnImage" alt="Descarga inbursa movil"
      width="auto" height="auto" />
  </div>
  <div class="d-flex justify-content-center align-items-center flex-column">
    <div class="text pb-3">
      <span class="font-size--03">¿Eres nuevo?</span>
      <span class="font-size--03">Regístrate, descarga ahora Inbursa Móvil</span>
    </div>
    <div class="d-flex align-items-center gap-2 pb-4">
      <a [attr.href]="
          !isDeviceMT
            ? null
            : 'https://play.google.com/store/apps/details?id=com.inbursa.client&hl=es_MX&gl=US'
        " target="_blank" rel="noopener noreferrer">
        <img [src]="googlePlay | cdnImage" alt="Google Store" class="store-icon" width="auto"
          height="auto" />
      </a>
      <a [attr.href]="
          !isDeviceMT
            ? null
            : 'https://apps.apple.com/mx/app/inbursa-m%C3%B3vil/id389155495'
        " target="_blank" rel="noopener noreferrer">
        <img [src]="appStore | cdnImage " alt="App Store" class="store-icon" width="auto"
          height="auto" />
      </a>
      <a [attr.href]="
          !isDeviceMT
            ? null
            : 'https://appgallery.huawei.com/app/C101749567'
        " target="_blank" rel="noopener noreferrer">
        <img [src]="appGallery | cdnImage" alt="App Gallery" class="store-icon" width="auto"
          height="auto" />
      </a>
    </div>
  </div>
</ng-container>
