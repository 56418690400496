import { PathModel } from 'src/app/models/PathModel';

type TVoluntaryWithdrawalReverseRequest = {
    numeroSolicitud: string
};

export class VoluntaryWithdrawalReverseRequest {
    constructor(public data: TVoluntaryWithdrawalReverseRequest) { }

    get endpoint(): PathModel {
        return PathModel.AforeVoluntaryWithdrawalReverse;
    }

}