<ng-container *ngIf="vm$ | async as vm">
  <section class="px-3 pb-4 pt-2">
    <div class="d-flex justify-content-end">
      <modal-close [image]="frameClose" [clickFn]="close.bind(this)"></modal-close>
    </div>
    <header class="header-m py-3 color-label-general">
    {{isInsurance ? insuranceHeader : isCredits ? creditsHeader : header}}</header>
    <main>
      <section class="information-input py-2" *ngIf="(vm?.isInsurance && !hiddenPolicy) ||
      vm?.isCredit || vm?.isDebit"
      [ngClass]="{'px-3': !vm.isInsurance}">
        <div class="mobile-text-button">
          <div class="colum-flex">
          <span class="detail-m-highlight">Alias de
            {{ vm.isDebit || vm.isCredit ? 'la ' + titleAccount  : titlePolicy}}</span>
          <text-ellipsis *ngIf="!showAliasInput"
          [text]="vm.isDebit? vm.product?.alias: vm.isCredit? vm.product?.nickName: vm.insurance?.Alias"
          classes="detail-m"
          [styles]="styles"></text-ellipsis>
          </div>
          <img
          (keydown.enter)="toggleEdit()"
          [hidden]="false"
          class="align-self-center"
          [src]="editIcon | cdnImage"
          alt="Copiar" width="24" height="24"
          (click)="toggleEdit()">
        </div>
        <div class="mobile-text-button">
          <form *ngIf="showAliasInput" class="mt-2" [formGroup]="formGroup">
                <div class="mb-3">
                  <label for="{{aliasInput.name}}" hidden> </label>
                    <input
                    #modifyAlias
                      [formControlName]="aliasInput.name"
                      type="text" id="{{aliasInput.name}}"
                      autofocus
                      [maxLength]="12"
                      autocomplete="off"
                      (keydown)="onKeyDown($event)"
                      noEmojis
                      cleanInput [cleanRegex]="regexAlias"
                      [cleanFormControl]= "formGroup.controls[aliasInput.name]"
                      (input)="changeAlias(modifyAlias.value)"
                      (change)="backColor(modifyAlias.value)"
                      (focus)="changeAlias(modifyAlias.value)"
                      class="form-control inputs placeholderMainTransfer body-s"/>
                </div>
          </form>
          <div *ngIf="showAliasInput" class="width-btn">
            <div id="buttons">
              <div class="align-tab tooltip-align" *ngIf="!vm.breakpoints.mobile">
                <img class="w-10 end-0" role="button"
                [ngbTooltip]=""
                tooltipClass="tooltip-ra" [src]="infoIcon | cdnImage" width="auto" height="auto" alt="Información">
              </div>
              <div>
                <button *ngIf="vm.breakpoints.desktop" class="btn-edit btn-edit-text" [ngStyle]="btnEditStyle"
                (keydown.enter)="modifyAlias()" (click)="modifyAlias()"   > Modificar</button>
                <button *ngIf="!vm.breakpoints.desktop" class="btn-edit btn-edit-text" [ngStyle]="btnEditStyle"
                (keydown.enter)="modifyAlias()" (click)="modifyAlias()" > Modificar</button>
              </div>
            </div>
          </div>
        </div>
    </section>
      <hr class="my-0" *ngIf="(vm?.isInsurance && !hiddenPolicy) || vm?.isCredit || vm?.isDebit">
      <ng-container *ngFor="let option of accountInformation; let index = index">
        <ng-container
          *ngIf="option.value || option?.iconConfig"
          [ngTemplateOutlet]="Information"
          [ngTemplateOutletContext]="{information:option}"></ng-container>
      </ng-container>
      <ng-container *ngIf="!isInsurance && !isAfore && !isInvestmentCompanies">
        <section *ngIf="!isCredits && !isCredit && !vm.isCodeflex" class="information py-2 px-3 gap-2">
          <div class="d-flex flex-column">
            <span class="detail-m-highlight">Saldo retenido</span>
              <span class="detail-m"> {{vm.product?.saldos.retenido | currency}} </span>
          </div>
          <img *ngIf="vm.product?.saldos.retenido"
            class="align-self-center"
            [src]="chevronIcon | cdnImage"
            width="24" height="24"
            alt="Saldo retenido"
            (keydown.enter)="$event.preventDefault()"
            (keydown.space)="$event.preventDefault()"
            (click)="showModal()">
        </section>
        <hr *ngIf="!isCredits && !isCredit && !vm.isCodeflex" class="my-0">
        <section *ngIf="(!associatedCards && !vm.isCodeflex) || isCredits" class="information px-3"
        [ngClass]="isCredits ? 'py-2' : 'py-3'" >
          <div class="d-flex">
            <span class="detail-m-highlight">{{isCredits ? amortization : creditCardAssociatedCards}}</span>
          </div>
          <ng-container *ngIf="!isCredits; else imgViewIn">
            <img

            (click)="showModalAssociatedCards()"
            (keydown.enter)="showModalAssociatedCards()"
            (keydown.space)="showModalAssociatedCards()"
            class="align-self-center"
            [src]="chevronIcon | cdnImage"
            alt=">" width="24" height="24">
          </ng-container>
          <ng-template #imgViewIn>
            <img *ngIf="isCredits"
            (click)="dowloadPdf(Amortization)"
            (keydown.enter)="$event.preventDefault()"
            class="align-self-center"
            [src]="fileViewInIcon | cdnImage"
            alt="Ver" width="24" height="24">
          </ng-template>
        </section>
        <hr *ngIf="!associatedCards || isCredits" class="my-0">
      <section class="information-input py-2 px-3 gap-2" *ngIf="vm.product.tipoCuenta === 'PAT'">
          <div class="mobile-text-button">
            <div class="colum-flex">
              <span class="detail-m-highlight">Límite a Cta. Eje</span>
              <span *ngIf="!showLimitInput" class="detail-m">{{ vm.limitCT | currency }}</span>
            </div>
            <img class="align-edit-limit" [src]="editIcon | cdnImage" alt="Editar"
            width="24" height="24" (keydown.enter)="toggleEditLimit()" (click)="toggleEditLimit()">
          </div>
          <div class="mobile-text-button">
            <form *ngIf="showLimitInput" class="mt-2" >
              <app-account-balance-information-ct-patrimonial id="home-card-info-balance-tc"
              [limitValue]="vm.limitCT" [autofocus]= "true" [newLimit] = "modalData.newLimit">
            </app-account-balance-information-ct-patrimonial>
            </form>
          </div>
        </section>
      </ng-container>
      <ng-container *ngIf="isInsurance">
        <section class="information py-3" *ngIf="!showOptionsInsurance && !internationalTransfersLetter">
          <span class="detail-m-highlight">Carta trasferencias internacionales</span>
          <img
          class="align-self-center"
          [src]="chevronIcon | cdnImage"
          alt=">" width="24" height="24">
        </section>
        <hr *ngIf="!showOptionsInsurance && !internationalTransfersLetter" class="my-0">
        <section class="information py-3" *ngIf="insuranceProduct.Ramo== 'gmm' && showButtonCardGmm">
          <span class="detail-m-highlight"> Tarjetas </span>
          <img
          class="align-self-center"
          [src]="chevronIcon | cdnImage"
          alt=">" width="24" height="24" (click)="sendCard()"
          (keydown.enter)="$event.preventDefault()">
        </section>
        <hr class="my-0"  *ngIf="insuranceProduct.Ramo== 'gmm' && showButtonCardGmm">
        <section class="information py-2" *ngIf="insuranceProduct.Ramo== 'gmm' && showClausulesGmm">
          <div class="d-flex flex-column">
            <span class="detail-m-highlight">Consulta de Clausulado</span>
            <span class="detail-m">Archivo PDF</span>
          </div>
          <img
            class="align-self-center"
            [src]="fileDownloadIcon | cdnImage"
            alt="Descargar" width="24" height="24"
            (keydown.enter)="false"
            (click)="false"  (click)="dowloadPdf(getClausulesPolicy)">
        </section>
        <hr class="my-0" *ngIf="insuranceProduct.Ramo== 'gmm' && showClausulesGmm ">
        <hr *ngIf="!showOptionsInsurance && !internationalTransfersLetter" class="my-0">
        <section class="information py-2">
          <div class="d-flex flex-column">
            <span class="detail-m-highlight">Carátula de la póliza</span>
            <span class="detail-m">Archivo PDF</span>
          </div>
          <img
            class="align-self-center"
            [src]="fileDownloadIcon | cdnImage"
            alt="Descargar" width="24" height="24"
            (keydown.enter)="$event.preventDefault()"
            (click)="openInsurancePolicyCover()">
        </section>
        <hr class="my-0">
        <section *ngIf="isApp && vm.breakpoints.mobile && !consultCFDI" class="information py-2">
          <div class="d-flex flex-column">
            <span class="detail-m-highlight">Descarga de CFDI</span>
            <span class="detail-m">Archivo PDF y XML</span>
          </div>
          <img
            class="align-self-center"
            [src]="fileDownloadIcon | cdnImage"
            alt="Descargar" width="24" height="24"
            (keydown.enter)="$event.preventDefault()"
            (click)="downloadCFDI()">
        </section>
        <hr class="my-0">
        <section class="information py-2">
          <div class="d-flex flex-column">
            <span class="detail-m-highlight">Condiciones generales</span>
            <span class="detail-m">Archivo PDF</span>
          </div>
          <img
            class="align-self-center"
            [src]="fileDownloadIcon | cdnImage"
            alt="Descargar" width="24" height="24"
            (keydown.enter)="$event.preventDefault()"
            (click)="openPolicyConditions()">
        </section>
        <hr class="my-0">
      </ng-container>
      <ng-container *ngIf="isAfore">
        <section class="information py-3 px-3">
          <span class="detail-m-highlight">{{movementDetail}}</span>
          <img
          class="align-self-center"
          [src]="fileDownloadIcon | cdnImage"
          alt="Descargar" width="24" height="24"
          (keydown.enter)="$event.preventDefault()"
          (click)="openModalMovementsDetail()">
        </section>
        <hr class="my-0">
        <section class="information py-3 px-3">
          <span class="detail-m-highlight">{{balanceCertification}}</span>
          <img
          class="align-self-center"
          [src]="fileDownloadIcon | cdnImage"
          alt="Descargar" width="24" height="24"
          (keydown.enter)="$event.preventDefault()"
          (click)="downloadBalanceCertification()">
        </section>
        <hr class="my-0">
      </ng-container>
    </main>
    <footer class="d-flex flex-column justify-content-center" [ngClass]="isInsurance &&
    !showOptionsInsurance ? 'pt-3' : ''">
      <button *ngIf="isInsurance || isCredits"
      class="button-m colored-btn button-insurance mt-3"
      (keydown.enter)="isInsurance? modalInsurance() : payCredit()"
      (click)="isInsurance? modalInsurance() : payCredit()">
        <div class="d-flex gap-3 align-items-center">
          <span>{{payHeaderInput}}</span>
          <img [src]="chevronRight | cdnImage" alt=">" width="20" height="20">
        </div>
      </button>
    </footer>
  </section>
</ng-container>

<ng-template #Information let-information="information">
  <section *ngIf="!information.hideElement" class="information py-2" [ngClass]="isInsurance ? '':'px-3'">
    <div class="d-flex flex-column">
      <span class="detail-m-highlight">{{ information.title }}</span>
      <span class="detail-m">{{
        information.format
          ? (information.value | numberFormat : information.format)
          : information.currency ? (information.value | currency): information.value
      }}</span>
    </div>
    <img
      *ngIf="!information.hideCopy"
      class="align-self-center"
      [src]="copyIcon | cdnImage"
      alt="Copiar" width="24" height="24"
      [cdkCopyToClipboard]="information.value"
      (cdkCopyToClipboardCopied)="onClipboardCopy(information.title == 'Asociada a'?
       information.value:information.title)">
      <img  *ngIf="information.iconConfig"
      class="align-self-center"
      [src]="information?.iconConfig?.icon | cdnImage"
      (keydown.enter)="onMenuOptionSelected(information?.iconConfig)"
      (click)="onMenuOptionSelected(information?.iconConfig)"
      alt="Copiar" width="24" height="24">
      <img *ngIf="information.tolTip"
      width="auto"
      height="auto"
      class="align-self-center"
      role="button"
      [alt]="information?.tolTip?.alt"
      [ngbTooltip]="information?.tolTip?.tooltip"
      [src]="information?.tolTip?.icon | cdnImage"
    />
  </section>
  <hr *ngIf="!information.hideElement" class="my-0">
</ng-template>

<ng-template #viewModal>
  <app-modal-payment-insurance-policies (modalPayment)="actionModal($event)"></app-modal-payment-insurance-policies>
</ng-template>
