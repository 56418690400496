import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IHeightProducts {
  height: number
}

@Injectable({
  providedIn: 'root'
})
export class ExpressInsuranceFrameResizer{
  private readonly _heightProductsExpress: BehaviorSubject<IHeightProducts> = new BehaviorSubject<IHeightProducts>({ height: 0 });

  constructor() { }

  get heightProductsExpress$() {
    return this._heightProductsExpress.asObservable();
  }

  get heightProductsExpress() {
    return this._heightProductsExpress.getValue();
  }

  set heightProductsExpress(data: IHeightProducts) {
    this._heightProductsExpress.next(data);
  }

}
