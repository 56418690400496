import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ModalBalanceHeldComponent } from 'src/app/component/modal-balance-held/modal-balance-held.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Numbers } from 'src/core/constants/Numbers';
import { StylesAfore } from 'src/core/constants/AforeStrings';
import { TransferStrings } from 'src/core/constants/TransferStrings';
import { Constants } from 'src/core/constants/Constants';
import { ModalLeavePageComponent } from 'src/app/shared/components/modal-leave-page/modal-leave-page.component';
import { ClassHTML } from 'src/core/constants/HTMLConstants';
import { ModalAccountStatementComponent } from 'src/app/home/components/modal-account-statement/modal-account-statement.component';

export class ModalUtils {
  static changeClassToModal(currentClass: string, newClass: string) {
    const modalInstance = document.querySelector(`.${currentClass}`);
    modalInstance?.classList.remove(currentClass);
    modalInstance?.classList.add(newClass);
  }

  static showModalBalanceHeld(modalService: ModalService) {
    const modalOptions: ModalOptions = {
      centered: true,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal,
    };
    modalService.open(ModalBalanceHeldComponent, modalOptions);
  }

    static showModal (modalService:ModalService,nameModal:unknown,options:{},className:string,id?:string){
      let modal : NgbModalRef;
      const modalCont = document.getElementsByClassName(className);
      if (modalCont.length > Numbers.Zero) {
        modalCont[Numbers.Zero].classList.add(StylesAfore.displayNone);
        document.getElementById(id).style.display = ClassHTML.None;
        this.ngbModalWindow(true);
        modal = modalService.open(nameModal,options);
          modal.result.then((e) => {
            modalCont[Numbers.Zero].classList.remove(StylesAfore.displayNone);
            document.getElementById(id).style.display = ClassHTML.Block;
            this.ngbModalWindow(false);
        });
      } else {
        modalService.open(nameModal,options);
      }
    }
    static ngbModalWindow(hidde: boolean) {
      const modalBackdrop = document.getElementsByClassName(StylesAfore.modalBackdrop);
      if(hidde){
        modalBackdrop[Numbers.Zero].classList.add(StylesAfore.backdropTest);
      }else{
        modalBackdrop[Numbers.Zero].classList.remove(StylesAfore.backdropTest);
      }
    }

    static ngbModalWindowModify(hidde: boolean) {
      const modalBackdrop = document.getElementsByClassName(TransferStrings.BACKDROP);
      if(hidde){
        modalBackdrop[Numbers.Zero].classList.add(TransferStrings.BACKDROP_TEST);
      }else{modalBackdrop[Numbers.Zero].classList.remove(TransferStrings.BACKDROP_TEST);}
    }

    static confirmLeavePage(modalService: ModalService, link?: string) {
      const modalOptions: ModalOptions = {
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeMd,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalLeavePage
      };
      const ref = modalService.open(ModalLeavePageComponent, modalOptions);
      ref.componentInstance.href = link;
  }

  static showModalAccountStatement(modalService: ModalService) {
    modalService.close();
    const modalOptions: ModalOptions = {
      windowClass: Constants.MODAL_OPTIONS.NoBorder,
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeMd,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAccountInformation
    };
    modalService.open(ModalAccountStatementComponent, modalOptions);
  }
}
