<content-wrapper *ngIf="vm$ | async as vm" [contentMargin]="contentMargin" [useFlex]="!vm.breakpoints.mobile">
  <div content class="width-content">
    <div *ngIf="vm.investmentDetailsError">
    <div *ngIf="vm.investmentDetailsError.certificateNumber else operationError">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <img [src]="checkIcon | cdnImage" class="icon-check" alt="" width="auto" height="auto" />
      <div class="pt-3 header-l">Alta exitosa</div>
      <div class="pt-2 body-m label mt-2 mb-2 reference-style">Folio: {{ id }}</div>
      <show-amount [useBreak]="false" label=""></show-amount>
      <div class="pb-3 date">{{ completeDate }}h</div>
      <app-shared-buttons [download]="true" [hideMail]="true" [fileName]="'AltaInversión'"></app-shared-buttons>
    </div>
    <div class="main-content">
      <app-main-confirm-data [carDec]="carDec" [dataToConfirm]="dataConfirm" [specialFont]="'header-xs'">
      </app-main-confirm-data>
    </div>
    <div class="mt-3 pt-3">
      <div class="btn-container">
        <button class="colored-btn mb-3 button-m btn-generic" (click)="goPage(homePath)">
          Listo
        </button>
        <button class="outline-btn mb-3 button-m btn-generic" (click)="goPage(investmentPath)">
          Otra operación
        </button>
      </div>
    </div>
    </div>
  </div>
</div>
</content-wrapper>

<ng-template #operationError>
  <app-error-exception [tab]="invesmentTab" [otherOperation]="otherOperation" [showButtons]="true"
    [errorMessage]="errorMsg"></app-error-exception>
</ng-template>
