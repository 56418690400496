import { Constants} from 'src/core/constants/Constants';
import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IInsurancePolicyInfo } from 'src/app/interface/IInsurancePolicyInfo';
import { InsurancePolicyInfoStatusService } from 'src/app/services/insurance-policy-info-status.service';
import { Numbers } from 'src/core/constants/Numbers';
import { Resources } from 'src/core/constants/Resources';
import { AppMethod } from 'src/core/constants/AppConstants';
import { AppBridge } from 'src/core/utils/AppBridge';

@Component({
  selector: 'app-modal-card-info-insurance-gmm',
  templateUrl: './modal-card-info-insurance-gmm.component.html',
  styleUrls: ['./modal-card-info-insurance-gmm.component.css']
})
export class ModalCardInfoInsuranceGmmComponent implements OnInit {
  policy: IInsurancePolicyInfo;
  showShared = true;
  modalInstance: NgbModalRef;
  closeCircle: string = Resources.CLOSE_CIRCLE_BLUE;
  shareImage: string = Resources.SHARE;
  constructor(
    private readonly stateIInsurancePolicyPlInfo: InsurancePolicyInfoStatusService
  ) { }

  async ngOnInit() {
    this.policy = this.stateIInsurancePolicyPlInfo.insurancePolicy;
    const cardGMM = document.getElementById(Constants.CARDGMM);
    cardGMM.style.backgroundImage = `url('${Resources.PATH_CDN}${Constants.GMM_CARD_ROUTE}')`;
  }

  async screenShot() {
    this.showShared = false;
    setTimeout(() => {
      this.showShared = true;
    }, Numbers.ThreeThousandSixHundred);
    await AppBridge.invoke<void>(AppMethod.GetScreenShot, null);
  }

  closeModal() {
    this.modalInstance.dismiss();
  }
}
