<ng-container *ngIf="vm$ | async as vm">
  <div
  class="flex-column align-items-center margin-content-modal pt-5" [ngClass]="vm.breakpoints.mobile?'':'d-flex'">
    <div class="d-flex flex-column align-items-center gap-2">
      <div class="d-flex justify-content-center">
        <img [src]="src | cdnImage" [alt]="src" class="img-card" height="auto" width="auto">
      </div>
      <div [ngClass]="vm.breakpoints.mobile? 'header-xs':'header-s'" class=" text-center" [innerHTML]="serviceName">
      </div>
      <ng-container>
        <p class="body-m">
          Folio: {{localData.IdTransaccion}}
        </p>
      </ng-container>
      <ng-template #Subtitle>
        <p class="body-m">
          Información de la operación
        </p>
      </ng-template>
    </div>
    <div class="d-flex flex-column align-items-center" [ngClass]="vm.breakpoints.mobile?'gap-2':'gap-1'">
      <div class="d-flex justify-content-center mx-3">
        <div class="d-flex flex-column align-items-center">
          <span class="detail-m">
            {{dateReceived}} {{localData.HoraAplicacion}}h
          </span>
        </div>
      </div>
      <app-shared-buttons [hideMail]="true" (downloadFiles)="downloadPdf()"></app-shared-buttons>
    </div>
    <div class="d-flex w-100 mt-4" [ngClass]="vm.breakpoints.mobile ? 'flex-column' :''">
      <div class="d-flex flex-column mx-3" [ngClass]="!vm.breakpoints.mobile?'w-100':''">
        <div class="d-flex flex-column row-content-item">
          <p class="amount-detail" [ngClass]="vm.breakpoints.mobile?'header-xxl':'header-xxxl'">
            {{localData.Monto | currency}}
          </p>
          <p class="detail-m">Importe pagado</p>
        </div>
        <div class="d-flex flex-column row-content-item">
          <p class="header-s">Información de la operación</p>
          <p class="body-s text-break margin-text"> {{localData.LineaCaptura}}</p>
          <p class="detail-s margin-text">Línea de Captura</p>
        </div>
        <hr>
        <div class="d-flex flex-column row-content-item">
          <p class="body-s text-break margin-text"> {{localData.LlavePagoDepRefSAT}}</p>
          <p class="detail-s margin-text">Llave de Pago</p>
        </div>
        <hr>
        <div class="d-flex flex-column row-content-item">
          <p class="body-s text-break margin-text"> {{presentationMedium}}</p>
          <p class="detail-s margin-text">Medio de presentación</p>
        </div>
        <hr>
        <div class="d-flex flex-column  row-content-item">
          <p class="body-s text-break margin-text">{{paymentChannel}}</p>
          <p class="detail-s margin-text">Canal de Pago</p>
        </div>
        <hr>
        <div class="d-flex flex-column row-content-item">
          <p class="body-s text-break margin-text"> {{paymentMethod}}</p>
          <p class="detail-s margin-text">Forma de Pago</p>
        </div>
        <hr>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column mx-3 mb-4 gap-3 align-items-center">
    <button  class="colored-btn button-m modals-btn btn-card-modal"
    [class.w-100]="vm.breakpoints.mobile"
    (click)="previousPaymentClose()">
      Cerrar
    </button>
  </div>
</ng-container>
