import { Resources } from 'src/core/constants/Resources';

export class DomiciliationResources {
  static PATH_CDN = '';
  static readonly MODAL_CFE_MOBAIL = 'view_token_cfe.svg';
  static readonly MODAL_CFE = 'opciontoken1_1_cfe.png';
  static readonly QUESTION_MARK_LIGHT_RED = 'question_mark_cr-fr_red.svg';

  static readonly CDN_URL_DOMICILIATION = (value: string) => `${Resources.PATH_CDN}/images/portal/PDF/${value}`;

  static readonly DOMICILIATION_CDN = {
    Domiciliation: 'domi_tc_alta.pdf',
    DomiciliationModal: 'domiciliacion_baja.pdf'
  };
}
