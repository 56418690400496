import { PathModel } from 'src/app/models/PathModel';

export interface IUserInformationRequest{
  IdSession : string;
}

export class UserInfoRequest{
  constructor(public data:IUserInformationRequest){}

  get endpoint(): PathModel {
    return PathModel.UserInfo;
}
}
