import { PathModel } from './../../models/PathModel';
interface ISendMessagePLRequest {
  idSesion: string;
  idOperacion: number;
  comentarios: string;
  copia?: string;
  copiaRemitente: boolean;
  numeroCuenta: string;
  receptor: string;
}

export class SendMessagePLRequest {

  constructor(public data: ISendMessagePLRequest){ }

  get endpoint(): PathModel{
    return PathModel.SendMessageMS;
  }

}
