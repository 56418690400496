import { PathModel } from 'src/app/models/PathModel';

export interface IGetLocksAndLimits{
        CardNumber : string,
        Source : string,
        IdSession : string,
        IsAditional : boolean
}

export class GetLocksAndLimits{
    constructor(public data: IGetLocksAndLimits){}

    get endpoint():PathModel{
        return PathModel.GetLocksAndLimits;
    }
}