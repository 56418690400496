<ng-container *ngIf="vm$  | async as breakPoints">
  <div class="modal-checks" *ngIf="!showModal" [ngClass]="{'modal-checks-mobile':breakPoints.mobile}">
      <div class="d-flex justify-content-end div-close">
          <img role="button" class="close" ngbAutoFocus aria-label="Close" (click)="close()"
              [src]="removeOperation | cdnImage" alt="Close"  width="auto" height="auto">
      </div>

      <div class="d-flex justify-content-center default-color padding-check">
          <span [class]="breakPoints.mobile ? 'header-m' : 'header-l'">{{checkbookRequest}}</span>
      </div>

      <div class="row d-flex justify-content-center container-div" [ngClass]="!breakPoints.desktop?'mt-4':'mt-4'">
          <div class="div-selected select-container position-relative default-color margin-div">
              <input type="text" name="checkbook" disabled id="num-checkbook" class="check-number color-secondary-400"
                  value="1">
              <label for="num-checkbook" class="detail-s position-absolute">{{checkbookSerialNumber}}</label>
          </div>
          <div class="d-flex div-btn">
              <button class="border-btn-ok btn-continue" [ngClass]="breakPoints.mobile? 'button-s':'button-m '"
                  (click)="continue()">
                  {{continueBtn}}
              </button>
          </div>
      </div>
  </div>

</ng-container>

<ng-container *ngIf="showBranch">
  <app-modal-branches titleBranches="Selección de sucursal" [classModal]="'modal-checks drop-shadow-m'"
      classBranch="card-branch-checks" classBtnContinue="button-m btn-send-check btn-font"
      classSearch="input-checkbook input-group mb-3 buscar" classMap="scroll-map map-mobile" [branchFinder]="true"
      textButton="Continuar" classSubtitle="check-subtitle" [styleIpad]="isIpad?'font-size: 17px;':''"
      fontTitle="font-title" placeholderM="Buscar por C.P. o dirección"
      subtitleBranches="Selecciona una sucursal cercana para recoger tu chequera">
  </app-modal-branches>
</ng-container>
