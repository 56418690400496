import { PathModel } from 'src/app/models/PathModel';

type TPartialWithdrawalMarriageCertificateRequest = {
    folio: string,
    clienteInbursaAfore: number,
    cuentaClabe: string,
    anio: number,
    descripcionEntidadFederativa: string,
    conyuge: {
      nombre: string,
      segundoNombre?: string,
      apellidoPaterno: string,
      apellidoMaterno?: string
    }
};

export class AforePartialWithdrawalMarriageCertificateRequest {
    constructor(public data: TPartialWithdrawalMarriageCertificateRequest) { }

    get endpoint(): PathModel {
        return PathModel.AforePartialWithdrawalMarriageCertificate;
    }

}
