import { Char, Constants, Length, Position, Regex } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { FormatPhonePosition } from 'src/core/constants/ClarificationsConstants';
import { AddressConstants } from 'src/core/constants/AddressConstants';

export class StringUtils{

  /**
   * Formatea una CLABE interbancaria
   */
  static formatClabe(clabe : string): string{
    const segments = clabe.match(Regex.AnyString);
    if(segments){
      return segments.join(Char.WhiteSpace);
    }
    return clabe;
  }

  /**
   * Obtiene las iniciales de un texto
   */
  static getCapitalLetters(text:string) {
    let capitalLetters = Strings.EMPTY;
     const substrings = text.split(Char.WhiteSpace);
     for (let position = Position.Zero; position < substrings.length; position++){
       if (capitalLetters.length < Length.Two){
         if (substrings[position].charAt(Position.Zero)){
           capitalLetters = capitalLetters.concat(substrings[position].charAt(Position.Zero).toUpperCase());
         } else {
           continue;
         }
       } else {
         break;
       }
     }
     return capitalLetters;
  }

 /**
   * Formatea un número de teléfono
   */
  static formatPhone(phone: string): string {
    if (phone.length === Constants.MAX_PHONE_LENGTH) {
      return `${phone.slice(Position.Zero, Position.Three)} ${phone.slice(Position.Three, FormatPhonePosition.Six)} ${phone.slice(FormatPhonePosition.Six)}`;
    } else {
      return phone;
    }
  }

  /**
   * Convierte un texto a formato de título, capitalizando la primera letra de cada palabra
   * excepto aquellas que deben mantenerse en minúsculas, como ciertas preposiciones y
   * artículos
   */
  static capitalizeWords(text:string) {
    const lowerCaseWords = [Strings.OF];
    const keepUnchanged  = [AddressConstants.POSTAL_CODE];
    return text.toLowerCase().split(Char.WhiteSpace).map((word, index) => {
      if(keepUnchanged.includes(word.toUpperCase())) {
        return word.toUpperCase();
      }
      if(index === Position.Zero || !lowerCaseWords.includes(word)) {
        return word.charAt(Position.Zero).toUpperCase() + word.slice(Position.One);
      } else {
        return word;
      }
    }).join(Char.WhiteSpace);
  }

}
