<ng-template #content>
  <app-location-modal (modalCall)="closeModal()"></app-location-modal>
</ng-template>
<ng-container *ngIf="vm$ | async as vm">
  <div class="content">
    <div class="d-flex flex-column align-items-center px-0">
      <div [class.gap-4]="!vm.transaction" class="face-pass-container mt-4 container-height">
        <app-header-card-login-component></app-header-card-login-component>
        <label [class.mt-2]="vm.transaction" class="detail-m-highlight sub-title font-size--04 text-center width-label"
         for="lectorlbl">Utiliza el Lector QR desde
          la app Inbursa Móvil para ingresar con
          Facepass</label>
        <div [class.custom-gap]="!vm.transaction" class="d-flex flex-column align-items-center">
          <div *ngIf="vm.qrEvent else noQrEvent" class="mt-3 d-flex flex-column align-items-center gap-4">
            <qrcode *ngIf="vm.transaction" [margin]="0" [width]="230" [qrdata]="vm.transaction"></qrcode>
            <div
              (click)="showFacepassModal()"
              role="button"
              class="detail-m-highlight-hypertext text-info-800 text-underline mb-4 px-3 text-center">
              ¿Cómo usar el lector QR <br> para ingresar con Facepass?
            </div>
          </div>
          <ng-template #noQrEvent>
            <button class="button-m btn btn-border pills wrap" (click)="clickEventQr()">
              <img class="margin-img" [src]="facepassIcon | cdnImage"
              alt="facepass" width="auto" height="auto" />
              <span class="span-facepass titlebtnMainContainLogin">Ingresa con Facepass</span>
              <img class="chevron-img" [src]="chevronRightIcon | cdnImage"
              alt="chevron" width="auto" height="auto" />
            </button>
          </ng-template>
          <ng-container (click)="ngOnDestroy()">
            <app-footer-card-login-component></app-footer-card-login-component>
          </ng-container>

          <ng-container *ngIf="vm.qrEvent">
            <button id="cancelButton" class="outline-btn button-s mt-4 " (click)="close()">
              <span> Cancelar </span>
            </button>
          </ng-container>
        </div>
      </div>
      <div>
        <app-download-app-component [page]="page"></app-download-app-component>
      </div>
    </div>
    <div class="container-fluid px-0">
      <app-footer></app-footer>
    </div>
  </div>
</ng-container>
