import { ChangeDetectionStrategy, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { combineLatestWith, map } from 'rxjs';
import { HistoricalPaymentInformation } from 'src/app/interface/HistoricalPaymentInformation';
import { HistoricalPaymentService } from 'src/app/services/historical-payment.service';
import { AccountModifyService } from 'src/app/services/info-accounts-edit.service';
import { NotifyDateService } from 'src/app/services/notify-date.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { AforeStrings } from 'src/core/constants/AforeStrings';
import { Char, ClassHTML, Constants, Numbers, Position } from 'src/core/constants/Constants';
import { MonthNumber } from 'src/core/constants/FormatDate';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { TransferConstants } from 'src/core/constants/TransferConstants';
import { TransferStrings } from 'src/core/constants/TransferStrings';
import { Utils } from 'src/core/utils/utils';

@Component({
  selector: 'app-modal-transfer-history',
  templateUrl: './modal-transfer-history.component.html',
  styleUrls: ['./modal-transfer-history.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalTransferHistoryComponent {
  @ViewChild('ModalContainer') modalContainer: ElementRef;
  @ViewChild('Header') header: ElementRef;
  @ViewChild('Footer') footer: ElementRef;
  @ViewChild('Container') container: ElementRef;
  buttonLabel: string = Strings.BUTTONS.Close;
  amount: string = this.historicalPaymentService.mockHistorialAmount;
  historical$ = this.paginationService.pagination.pipe(
    map((pagination) => ({
      data: (pagination.data as HistoricalPaymentInformation[]),
    }))
  );
  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(this.accountModifyService.account$),
    map(([breakpoints, account]) => ({ breakpoints, account }))
  );
  maxDate = AforeStrings.MAX_DATE_DETAILS_AFORE;
  minDate: NgbDateStruct = Constants.MIN_DATE_PORTABILITY;
  minDateValidation: Date = new Date(Constants.MIN_DATE_PORTABILITY.year, Constants.MIN_DATE_PORTABILITY.month - Numbers.One, Constants.MIN_DATE_PORTABILITY.day);
  maxDateValidation: Date = new Date(AforeStrings.MAX_DATE_DETAILS_AFORE.year, AforeStrings.MAX_DATE_DETAILS_AFORE.month - Numbers.One, AforeStrings.MAX_DATE_DETAILS_AFORE.day);
  minDateTwo: NgbDateStruct | null;
  maxDateOne = AforeStrings.MAX_DATE_DETAILS_AFORE;
  showErrorDate: boolean = false;
  startDate: Date | null;
  endDate: Date | null;
  errorDateLabel: string = AforeStrings.VALIDATION_DATE;
  closeIcon: string = Resources.CLOSE_CIRCLE_BLUE;
  datesValid: boolean = true;
  dataIsEmpty: boolean = false;
  private readonly pageSize: number = Numbers.Two;
  year: string = new Date().getFullYear().toString();
  operation: string = TransferStrings.OPERATION;
  validStartDate: boolean = false;
  validEndDate: boolean = false;
  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly paginationService: PaginationService,
    private readonly historicalPaymentService: HistoricalPaymentService,
    private readonly modalService: ModalService,
    private readonly notifyDateService: NotifyDateService,
    private readonly accountModifyService: AccountModifyService
  ) { }

  ngOnInit(): void {
    this.setPaginationData(this.orderByYearMounthAndDay(this.historicalPaymentService.historicalPayment));
    this.minDateTwo = this.minDate;
    this.notifyDateService.isErrorData = false;
  }

  actionModalTransferHistory(action: boolean) {
    this.modalService.close(action);
  }

  close() {
    this.modalService.close();
  }

  getStartDate($event: string) {
    if ($event !== Strings.EMPTY && $event !== undefined && $event !== null) {
      this.startDate = new Date(Number($event[Numbers.Six] + $event[Position.Seven] + $event[Position.Eight]
        + $event[Numbers.Nine]), Number($event[Position.Three] + $event[Position.Four]) - Numbers.One,
        Number($event[Position.Zero] + $event[Position.One]));
      this.validateDate(Numbers.Zero);
      if (this.datesValid && !this.notifyDateService.isErrorBS.value) {
        this.setPaginationData(
          this.filterPaymentsByDateRange(this.historicalPaymentService.historicalPayment, this.startDate.toString(), (this.endDate ? this.endDate.toString() : ''))
        );
        this.minDateTwo = { year: this.startDate.getFullYear(), month: this.startDate.getMonth() + Numbers.One, day: this.startDate.getDate() };
      } else {
        this.setPaginationData(this.historicalPaymentService.historicalPayment);
        this.minDateTwo = this.minDate;
      }
    } else {
      this.startDate = null;
      this.showErrorDate = false;
      this.validStartDate = false;
    }
    this.setOperation(Numbers.Zero);
  }

  getEndDate($event: string) {
    if ($event !== Strings.EMPTY && $event !== undefined && $event !== null) {
      this.endDate = new Date(Number($event[Numbers.Six] + $event[Position.Seven] + $event[Position.Eight]
        + $event[Numbers.Nine]), Number($event[Position.Three] + $event[Position.Four]) - Numbers.One,
        Number($event[Position.Zero] + $event[Position.One]));
      this.validateDate(Numbers.One);
      if (this.datesValid && !this.notifyDateService.isErrorBS.value) {
        this.validateDate(Numbers.Zero);
        this.setPaginationData(
          this.filterPaymentsByDateRange(this.historicalPaymentService.historicalPayment, (this.startDate ? this.startDate.toString() : ''), this.endDate.toString())
        );
        this.maxDateOne = { year: this.endDate.getFullYear(), month: this.endDate.getMonth() + Numbers.One, day: this.endDate.getDate() };
      } else {
        this.setPaginationData(this.historicalPaymentService.historicalPayment);
        this.maxDateOne = { year: this.maxDateValidation.getFullYear(), month: this.maxDateValidation.getMonth() + Numbers.One, day: this.maxDateValidation.getDate() };
      }
    } else {
      this.endDate = null;
      this.showErrorDate = false;
      this.validEndDate = false;
    }
    this.setOperation(Numbers.One);
  }
  validateStartMinDate() {
    const minDate = new Date(this.minDate.year, (this.minDate.month - Numbers.One), this.minDate.day);
    if (this.startDate && (this.startDate.toDateString() !== Strings.DateValidation.INVALID_DATE)) {
      if ((this.endDate && this.endDate >= this.startDate && this.startDate >= minDate) || (!this.endDate && this.startDate >= minDate)) {
        this.showErrorDate = false;
        this.datesValid = true;
      } else if (this.startDate <= this.endDate && this.startDate <= minDate) {
        this.showErrorDate = false;
        this.datesValid = false;
      } else {
        this.showErrorDate = true;
        this.datesValid = false;
      }
    } else {
      this.datesValid = false;
    }
    this.validStartDate = this.datesValid;
    this.setOperation(Numbers.Zero);
  }

  validateEndMinDate() {
    const minDate = new Date(this.minDate.year, (this.minDate.month - Numbers.One), this.minDate.day);
    if (this.endDate && (this.endDate.toDateString() !== Strings.DateValidation.INVALID_DATE)) {
      this.processValidateEndMinDate(minDate);
    } else {
      this.datesValid = false;
      this.operation = TransferStrings.OPERATION;
    }
  }

  processValidateEndMinDate(minDate) {
    const maxDate = new Date(this.maxDate.year, (this.maxDate.month - Numbers.One), this.maxDate.day);
    if (this.auxProcessValidateEndMinDate(minDate, maxDate)) {
      this.showErrorDate = false;
      this.datesValid = true;
    } else if ((parseInt(this.endDate.toDateString().split(' ')[Numbers.Three])).toString().length < Numbers.Four) {
      this.showErrorDate = false;
      this.notifyDateService.isErrorData = true;
    } else if (!this.startDate && this.endDate >= minDate) {
      this.showErrorDate = false;
    } else if (this.startDate && this.endDate >= this.startDate && this.startDate <= minDate) {
      this.showErrorDate = false;
      this.datesValid = false;
    } else {
      this.showErrorDate = true;
      this.datesValid = false;
    }
    this.validEndDate = this.datesValid;
    this.setOperation(Numbers.One);
  }

  auxProcessValidateEndMinDate(minDate: Date, maxDate: Date) {
    return this.startDate && this.endDate >= this.startDate && this.endDate >= minDate && this.endDate <= maxDate;
  }

  validateDate(typeDate: number) {
    if (typeDate === Numbers.Zero) {
      this.validateStartMinDate();
    } else {
      this.validateEndMinDate();
    }
  }

  orderedHistory(historicalPayment: HistoricalPaymentInformation[]) {
    return Array.from(this.historicalPaymentService.groupByYear(historicalPayment).entries());
  }

  orderByMonthAndDay(data: { [key: string]: HistoricalPaymentInformation[] }) {
    return Object.entries(data);
  }

  orderByYearMounthAndDay(data: HistoricalPaymentInformation[]) {
    if (data.length > Numbers.Zero) {
      data.sort((a, b) => {
        const dateA = new Date(Number(a.year), MonthNumber[`${a.month.toLowerCase()}`] - 1, Number(a.day));
        const dateB = new Date(Number(b.year), MonthNumber[`${b.month.toLowerCase()}`] - 1, Number(b.day));
        return dateB.getTime() - dateA.getTime();
      });
      this.minDate = this.getMinDate(data);
    } else {
      this.dataIsEmpty = true;
      this.minDate = this.maxDate;
    }
    return data;
  }

  formatMonth(month: string) {
    return month.replace(Char.MiddleDash, Char.WhiteSpace);
  }

  filterPaymentsByDateRange(historicalPayments: HistoricalPaymentInformation[], startDate: string, endDate: string): HistoricalPaymentInformation[] {
    let historical = [];
    if ((startDate && endDate) && (new Date(startDate).getTime() <= new Date(endDate).getTime())) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      historical = historicalPayments.filter(payment => {
        const monthNumber = MonthNumber[`${payment.month.toLowerCase()}`];
        const paymentDate = new Date(parseInt(payment.year), (monthNumber-1), parseInt(payment.day));
        return paymentDate.getTime() >= start.getTime() && paymentDate.getTime() <= end.getTime();
      });
    } else {
      historical = historicalPayments;
    }
    this.dataIsEmpty = historical.length === Numbers.Zero;
    return historical;
  }

  getMinDate(historiaclPayments: HistoricalPaymentInformation[]) {
    const monthNumber = MonthNumber[`${historiaclPayments[historiaclPayments.length - 1].month.toLowerCase()}`];
    return {
      year: parseInt(historiaclPayments[historiaclPayments.length - 1].year),
      month: parseInt(monthNumber),
      day: parseInt(historiaclPayments[historiaclPayments.length - 1].day)
    };
  }

  transformDate(date: string) {
    let newDate = Strings.EMPTY;
    if (date.length === Numbers.One) {
      newDate = date.padStart(Numbers.Two, `${Numbers.Zero}`);
    }
    return newDate;
  }

  @HostListener('window:resize', ['$event'])
  calculate() {
    const cardHeight = Constants.CARD_MODAL_HEIGHT;
    const modalContainer = document.getElementsByClassName(ModalConstants.MODAL_OPTIONS.ModalTransferHistory).item(Position.Zero);
    const modalContainerHeight = modalContainer.clientHeight;
    const containerPadding = Utils.getElementPadding(this.modalContainer.nativeElement);
    const availableHeight = modalContainerHeight - this.header.nativeElement.offsetHeight - this.footer.nativeElement.offsetHeight - containerPadding;
    let elementsPerPage = (Math.floor(availableHeight / cardHeight)) - 1;
    elementsPerPage = elementsPerPage > Constants.PORTABILITY_INPUT_KEYUP ? elementsPerPage : Constants.PORTABILITY_INPUT_KEYUP;
    this.paginationService.setPageSize(elementsPerPage ?? this.pageSize);
    this.setMaxContent();
  }

  setMaxContent() {
    setTimeout(() => {
      this.modalContainer.nativeElement.style.maxHeight = ClassHTML.MaxContent;
    }, Numbers.OneHundred);
  }

  setPaginationData(data: HistoricalPaymentInformation[]) {
    this.paginationService.setInitialData<HistoricalPaymentInformation>(
      data, this.pageSize
    );
  }

  isValidDate(value: boolean) {
    this.notifyDateService.isErrorData = value;
  }

  setOperation(value: number) {
    this.operation = value === Numbers.Zero
    ? this.validStartDate && this.validEndDate ? Strings.EMPTY : TransferStrings.OPERATION :
    this.validEndDate ? Strings.EMPTY : TransferStrings.OPERATION;
  }

  resetError() {
    this.notifyDateService.isErrorData = false;
  }

  getAccountType(type: number) {
    return TransferConstants.ACCOUNT_TYPE[type].toLowerCase();
  }
}
