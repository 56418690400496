import { PathModel } from 'src/app/models/PathModel';

export interface ICardRepositionRequest {
  Account: string,
  CardNumber: string,
  Changes: {
    C_Paremeter: []
  },
  CustomerId: string,
  Delivery: string,
  IdCuentaProducto: string,
  IdsPersona: [],
  NewState: string,
  NickName: string,
  Source: string,
  pEnvio: number,
  pSucursal: number
}

export class CardRepositionRequest {
  constructor(public data: ICardRepositionRequest) { }

  get endpoint(): PathModel {
    return PathModel.CardReposition;
  }
}
