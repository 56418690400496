import { AccountModel } from 'src/app/interface/AccountModel';
import { AccountType, Constants } from 'src/core/constants/Constants';
import { Utils } from 'src/core/utils/utils';
import { CheckingAccountModel } from 'src/app/interface/ICheckingAccountsResponse';
import { SystemId } from 'src/core/constants/SystemId';
import { ProductConstants } from 'src/core/constants/ProductConstants';
import { FundData } from 'src/app/interface/dto/GetVariableIncomeInvestmentResponse';
import { Strings } from 'src/core/constants/Strings';
import { GetDirectDebitCreditCardResponse } from 'src/app/interface/dto/GetDirectDebitCreditCardResponse';
import { DirectDebitCard } from 'src/app/interface/dto/GetDirectDebitDebitCardResponse';
import { AccountsDirectDebitCreditCards } from 'src/app/interface/dto/GetAccountsDirectDebitCreditCardsResponse';
import { Numbers } from 'src/core/constants/Numbers';
import { MaskAccountPipe } from 'src/app/pipes/mask-account.pipe';
import { StringUtils } from 'src/core/utils/stringUtils';
import { DomiciliationStrings } from 'src/core/constants/DomiciliationStrings';
import { IDirectDebitCard } from 'src/app/interface/IDirectDebitCard';
import { InvestmentAccountsData } from 'src/app/interface/GetInvestmentAccountsModel';
import { ServiceConstants } from 'src/core/constants/ServiceConstants';
import { IUserProductsConfig } from 'src/app/interface/IUserProductsConfig';
import { ControlCardConstants } from 'src/core/constants/ControlCardConstants';
import { InvestmentsConstants } from 'src/core/constants/InvesmentsConstants';
import { AppConstants } from 'src/core/constants/AppConstants';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { AccountStatementComponent } from 'src/app/home/components/account-statement/account-statement.component';
import { ProductStrings } from 'src/core/constants/ProductStrings';
import { AgendaType } from 'src/core/constants/AddressConstants';


export class ProductUtils {
  static investmentAccountFilter(account: AccountModel) {
   return account.OperacionesPermitidas?.includes(InvestmentsConstants.FILTER_ACCOUNT);
  }

  static setOriginAccount(accountInfo:AccountModel){
    return {
      clabe: accountInfo.cuentaClabe,
      image: accountInfo.directorioImagen,
      cardNumber: accountInfo.numeroCuenta,
      productId: accountInfo.idCuentaProducto,
      balance: accountInfo.saldos.disponible.toString(),
      selected: true,
      cardType: accountInfo.descripcionCuenta,
      accountNumber: accountInfo.numeroCuenta,
      currency: accountInfo.currency,
      accountType: accountInfo.tipoCuenta,
      originAccount:accountInfo.numeroCuenta
    };
  }

  static convertToAccountModel(model: CheckingAccountModel): AccountModel {
    return {
      numeroCuenta: model.NumeroCuenta,
      idCuentaProducto: model.IdCuentaProducto,
      directorioImagen: model.CardFrontImageUrl,
      alias: model.Alias,
      cuentaClabe: model.CuentaClabe,
      tipoCuenta: model.TipoCuenta,
      descripcionCuenta: model.Descripcion,
      email: model.CorreoElectronico,
      saldos: {
        total: Number(model.Saldo.Monto),
        disponible: model.SaldoDisponible.Monto,
        retenido: model.SaldoRetenido.Monto,
        deudor: model.SaldoDeudor.Monto,
        sbc: model.SaldoSBC.Monto,
        cobertura: Number(model.SaldoCobertura)
      },
      NumeroTarjeta: model.NumeroTarjeta,
      currency: model.Saldo.Moneda,
      category: model.Categoria,
      bureauAlert: model.AplicaAlertaBuro,
      block: model.EstaBloqueda === Utils.removeAccents(Constants.VALUE_YES.toUpperCase()),
      ServiciosPermitidos: model.ServiciosPermitidos,
      OperacionesPermitidas: model.OperacionesPermitidas,
      Ahorro: model.Ahorro,
      Alcancias: model.Alcancias,
      payrollCredit : {
        payrollPortabilityFlag: model.CreditoNomina?.BndPortabilidadNomina,
        advanceAllowanceFlag: model.CreditoNomina?.BndPermiteAnticipo,
        payrollMovementsFlag: model.CreditoNomina?.BndMovimientosNomina
      },
      IdCuentaProductoFondeo: model.IdCuentaProductoFondeo,
      swiftCode: model.CodigoSwift,
      digitalChecks: model.ChequesDigitales,
      CuentaFondeo: model.CuentaFondeo
    };
  }
  static convertToAccount(model: FundData, numeroCuenta: string): InvestmentAccountsData{
    return {
      codigoInversion: model.codigo,
      descripcion: model.descripcion,
      saldoDisponible: model.saldoTitulos,
      cuenta: numeroCuenta,
      clabe: Strings.EMPTY,
      saldoTitulos: model.saldoTitulos,
      titulos: model.titulos,
      precioTitulo: model.precioTitulo,
      idCuentaProducto: model.idCuentaProducto
    };
  }

  static getInvestmentAccountType(accountType: string) {
    return accountType === ProductConstants.INVESTMENT_CODE_DINBUR ? SystemId.InvestmentCompanies : SystemId.Investment;
  }

  static addListService(services: Array<DirectDebitCard> | Array<GetDirectDebitCreditCardResponse>, isDirectDebitServiceDebit: boolean, description: string,
    number: string, idProduct?: string) {
    const listServices = [];
    const numberAccountMask = new MaskAccountPipe().transform(number, Numbers.Four);
    let isNumberPhone: boolean;
    services?.forEach(service => {
      if(isDirectDebitServiceDebit){
        isNumberPhone = service.codigoServicio.includes(DomiciliationStrings.TELMEX);
      }else{
        isNumberPhone = service.servicio.includes(DomiciliationStrings.TELMEX);
      }
      listServices.push({
        account: `${description} (${numberAccountMask})`,
        service: isDirectDebitServiceDebit ? service.codigoServicio : service.servicio,
        numberService: isNumberPhone ? StringUtils.formatPhone(service.numeroContrato) : service.numeroContrato,
        amount: isDirectDebitServiceDebit ? service.monto : Numbers.Zero,
        headline: isDirectDebitServiceDebit ? service.nombreTitular : service.alias,
        selected: false,
        email: isDirectDebitServiceDebit ? service.email : Strings.EMPTY,
        folioService: isDirectDebitServiceDebit ? service.folio.toString() : service.folio,
        nameAccount: description,
        card: numberAccountMask,
        isDirectDebitServiceOwnCreditCard: false,
        idProductAccount: idProduct
      });
    });
    return listServices;
  }

  static addService(service: AccountsDirectDebitCreditCards, number: string) {
    const listServices: Array<IDirectDebitCard> = [];
    const bankReference = new MaskAccountPipe().transform(service.cuentaDebito.referenciaBancaria, Numbers.Four);
    const numberAccountMask = new MaskAccountPipe().transform(service.cuentaDebito.referenciaBancaria, Numbers.Four);
    if(service !== null){
      listServices.push({
        account: service.cuentaDebito.tipoProduto ? `${service.cuentaDebito.tipoProduto} (${bankReference})` : `(${bankReference})`,
        service: service.cuentaDebito.tipoProduto ? `${service.cuentaDebito.tipoProduto} (${bankReference})` : `(${bankReference})`,
        numberService: service.tarjetaCredito.numeroTarjeta,
        amount: service?.monto?.toString(),
        headline: service.tarjetaCredito.nombreTitular,
        selected: false,
        nameAccount: service.cuentaDebito.tipoProduto,
        numberAccount: numberAccountMask,
        typePayment: service.tipo,
        expirationDate: service.fechaVencimiento,
        card: number,
        isDirectDebitServiceOwnCreditCard: true,
        idProductAccount: service.cuentaDebito.referenciaBancaria
      });
    }
    return listServices;
  }

  static transactionAccountFilter(account: AccountModel) {
    return !ProductConstants.EXCLUDE_TRANSACTION_ACCOUNT_TYPE.includes(account.tipoCuenta);
  }

  static getAccountLabel(codeType: AgendaType, serviceAgendaCode: number,codeServices : string,
    defaultLabel: string){
    if(codeType === serviceAgendaCode) {
      return ServiceConstants.TYPE_CODE_SERVICE[codeServices] || defaultLabel;
    }
    return defaultLabel;
  }

  static evaluateWebAccount(accountType: string, config: IUserProductsConfig){
    if(accountType && accountType === AccountType.WEB){
      return { forceOsb: true, reload: true, skipSubAccounts: true };
    }else{
      return config;
    }
  }
  static getSourceCancellation(SourceCard: string): string {
    let reasonCode: string;
    switch (SourceCard) {
      case ControlCardConstants.HEIST:
        reasonCode = ControlCardConstants.HEIST_CODE;
        break;
      case ControlCardConstants.LOSS:
        reasonCode = ControlCardConstants.LOSS_CODE;
        break;
      case ControlCardConstants.DAMAGED:
        reasonCode = ControlCardConstants.DAMAGED_CODE;
        break;
    }
    return reasonCode;
  }

  static addInvestmentModal(modalService: ModalService) {
    modalService.open(AccountStatementComponent, {centered: true, modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAddInvestment}, Strings.INVESTMENT.OptionElementOne);
  }

  static getEquivalentAplicationCode(user:string){
    let aplicationCode: string;
    if (user === AppConstants.APLICATION_CODE.Bank) {
      aplicationCode = AppConstants.APLICATION_CODE.BankLabel;
    }
    if (user === AppConstants.APLICATION_CODE.CreditCard) {
      aplicationCode = AppConstants.APLICATION_CODE.CreditCardLabel;
    }
    if (user === AppConstants.APLICATION_CODE.Insurance) {
      aplicationCode = AppConstants.APLICATION_CODE.InsuranceLabel;
    }
    if (user === AppConstants.APLICATION_CODE.Aditional) {
      aplicationCode = AppConstants.APLICATION_CODE.AditionalLabel;
    }
    return aplicationCode;
  }

  static isDigitalCard(cardType:string):boolean{
    return cardType?.toLowerCase().includes(ProductStrings.TYPE_ACCOUNT.InbursaDigital);
  }

  static isValidateCard(cardType:string, value: string):boolean{
    return cardType?.toLowerCase().includes(value);
  }
}
