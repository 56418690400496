import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { FacePassLoginService } from 'src/app/services/face-pass-login.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SentinelService } from 'src/app/services/sentinel.service';
import { SessionManagerService } from 'src/app/services/session-manager.service';
import { StorageService } from 'src/app/services/storage.service';
import { ModalService } from 'src/app/shared/modal.service';
import { Char, ClassHTML, Constants, InputTypes, Keys, Length } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { Utils } from 'src/core/utils/utils';
import { LoginOrqRequest } from 'src/app/interface/dto/LoginOrqRequest';
import { LoginOrqResponse } from 'src/app/interface/dto/LoginOrqResponse';
import { ModalConstants } from 'src/core/constants/ModalConstants';

@Component({
  selector: 'app-form-card-login',
  templateUrl: './form-card-login.component.html',
  styleUrls: ['./form-card-login.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormCardLoginComponent implements OnInit {
  @ViewChild('content', { static: true }) content: ElementRef;
  @ViewChild('usuario', { static: true }) user: ElementRef;
  @ViewChild('password') passwordLogin: ElementRef;
  isTouched:boolean =false;
  password: string = Strings.EMPTY;
  userError: boolean = false;
  passwordError: boolean = false;
  required: boolean = false;
  passwordRequired: boolean = false;
  isBusy: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  passwordMaxLength : number = Constants.PASSWORD_LENGTH;
  message:string = Strings.GENERIC_ERRORS.Required;
  alert: { class: ClassHTML, type: string, iconHeader: string, message: string, icon: string } = {
    class: ClassHTML.Login, type: Constants.ERROR_LOGIN, iconHeader: Resources.CLOSE_WHITE,
    message: Strings.LOGIN.InvalidPassword, icon: Resources.CLOSE_WHITE_CIRCLE
  };

  vm$ = this.isBusy.asObservable().pipe(
    map((isBusy) => ({ isBusy }))
  );


  constructor(private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly baseService: IBaseService,
    private readonly storage: StorageService,
    private readonly modalService: ModalService,
    private readonly sessionManagerService: SessionManagerService,
    private readonly facepassLoginService: FacePassLoginService,
    private readonly sentinelService: SentinelService,
    private readonly loaderService: LoaderService) {

    this.paramsValidation();
    this.sessionManagerService.closeSession().catch(Utils.printLogError);
  }

  ngOnInit(): void {
    if (this.alertService.alert?.type === Constants.ERROR_LOGIN) {
      this.alertService.showAlert(null);
    }
  }

  private paramsValidation() {
    const alertMessage = this.alertService.alertMessage;
    if (alertMessage) {
      this.alertService.alertMessage = null;
      setTimeout(() => {
        this.alert.message = alertMessage;
        this.alertService.showAlert(this.alert);
      });
    }
  }

  async onSubmitLogin(user?: string, password?: string) {
    if (this.alertService.alert?.type === Constants.ERROR_LOGIN) {
      this.alertService.showAlert(null);
    }

    this.userError = user.length === Length.Empty;
    this.passwordError = password.length === Length.Empty;
    this.required = user.length === Length.Empty;
    this.passwordRequired = password.length === Length.Empty;

    if (user.length !== Length.Empty && password.length !== Length.Empty) {
      try {
        this.isBusy.next(true);
        this.loaderService.showLoader();
        await this.onLogin(user, password);
        this.sessionManagerService.trackSession();
        this.router.navigate([PATH.LoginToken], {
          state: {
            validNavigation: true
          }
        });
      } catch (error) {
        Utils.printLogError(error);
        this.geolocationError(error);
        this.alertService.showAlert(this.alert);
        this.passwordLogin.nativeElement.value = Strings.EMPTY;
      } finally {
        this.loaderService.hideLoader();
        this.isBusy.next(false);
      }
    }
  }

  private geolocationError(error: GeolocationPositionError) {
    if (error instanceof GeolocationPositionError) {
      if (error.code === GeolocationPositionError.PERMISSION_DENIED) {
        this.alert.message = Strings.GEOLOCATION_PERMISSIONS_MSG;
      }
      else {
        this.alert.message = Strings.GEOLOCATION_ERROR_MSG;
      }
      this.openModal(this.content);
    }
    else {
      const errorDetail = Utils.getErrorMsg(error);
      this.alert.message = Utils.SERVICE_ERROR_MSG(errorDetail.msg, errorDetail.code);
    }
  }

  private async onLogin(user: string, userPassword: string) {
    const geoLocation = await Utils.getGeolocation();
    this.sessionManagerService.closeSession().catch(Utils.printLogError);
    const request = new LoginOrqRequest({
      nombreUsuario: user,
      contrasena: userPassword,
      ip: this.sentinelService.sentinel.ip,
      latitud: geoLocation.latitude,
      longitud: geoLocation.longitude,
      tipoAutenticacion: Constants.AUTHENTICATION_TYPE,
      medioAcceso: Constants.ACCESS_METHOD_PORTAL
    });

    const response = await this.baseService.genericPost<LoginOrqResponse>(request, { addUserAgent: true });

    if (!response?.datos || !response.datos.idSesion || !response.datos.nombre || !response.datos.apellidos) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
    }

    response.datos.apellidos = response.datos.apellidos.replace(Char.Pipe, Char.WhiteSpace);
    this.storage.saveGeolocation({ latitude: geoLocation.latitude, longitude: geoLocation.longitude });
    this.storage.saveSession(response.datos.idSesion, response.datos.tokenJwt);
    this.storage.saveUser({ name: response.datos.nombre, lastName:response.datos.apellidos, userName: 
      user, userId: response.datos.idUsuario, applicationCode:response.datos.codigoAplicacion});
    this.facepassLoginService.isFacePassLogin = { value: false };
  }

  openModal(content: ElementRef) {
    this.modalService.open(content, { windowClass: Constants.MODAL_OPTIONS.ContainerPopup, modalDialogClass: ModalConstants.MODAL_OPTIONS.IsCentered });
  }

  closeModal() {
    this.modalService.close();
  }

  userInputValue(user: string) {
    this.userError = user.length < Length.Empty;
    if (user.length === Length.Empty) {
      this.userError = false;
    }
    this.required = false;
  }

  passwordInputValue(password: string) {
    this.passwordError = password.length < Length.Empty;
    if (password.length === Length.Empty) {
      this.passwordError = false;
    }
    this.passwordRequired = false;
  }
  inputUser(event: KeyboardEvent) {
    if (event.key === Keys.Enter) {
      document.getElementById(InputTypes.Password).focus();
    }
  }

  ngOnDestroy() {
    this.modalService.close();
  }

  close() {
    this.router.navigate([PATH.Login]);
  }
}
