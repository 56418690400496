<label class="my-3 header-xs">{{titleComponent}}</label>
<div class="contain drop-shadow-m mb-3 position-relative">
  <form>
    <label class="m-2 position-absolute detail-s-highlight format-date">
      <span class="small bg-white px-1">{{limitDateLabel}}</span>
    </label>
    <input class="form-control inputs body-s" value="{{dateToDay | customDate:'dd/Month/yyyy'}}"
    readonly="true" placeholder="dd/mm/aaaa"/>
    <div class="mb-1"></div>
    <label class="m-2 position-absolute detail-s-highlight format-date">
      <span class="small bg-white px-1">{{dueDateLabel}}</span>
    </label>
    <input class="form-control inputs body-s" value="{{dueDate | customDate:'dd/Month/yyyy'}}"
    readonly="true" placeholder="dd/mm/aaaa"/>
  </form>
</div>
