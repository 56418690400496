import { PathModel } from 'src/app/models/PathModel';

type TGetInternationalAgendaRequest = {
    IdSession: string,
    Contrato: string
};

export class GetInternationalAgendasRequest {
    constructor(public data: TGetInternationalAgendaRequest){ }

    get endpoint(): PathModel {
        return PathModel.GetInternationalAgendas;
    }
}
