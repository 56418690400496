import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs';
import { ModalOptions } from 'src/app/interface/modal-options';
import { PaginationService } from 'src/app/services/pagination.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Constants, Numbers } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { ModalAccountInformationComponent } from 'src/app/home/components/modal-account-information/modal-account-information.component';
import { AssociatedCardsService } from 'src/app/services/associated-cards.service';
import { ModalConstants } from 'src/core/constants/ModalConstants';

@Component({
  selector: 'app-modal-associated-cards',
  templateUrl: './modal-associated-cards.component.html',
  styleUrls: ['./modal-associated-cards.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PaginationService]
})
export class ModalAssociatedCardsComponent implements OnInit {
  modalInstance: NgbModalRef;
  frameClose: string = Resources.CLOSE_CIRCLE_BLUE;
  copyIcon: string = Resources.FILE_COPY;
  associatedCards: number[] = this.associatedCardsService.associatedCards;

  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints }))
  );
  readonly PAGE_SIZE = Numbers.Five;

  cards$ = this.paginationService.pagination.pipe(
    map((pagination) => pagination.data)
  );

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly paginationService: PaginationService,
    private readonly modalService: ModalService,
    private readonly associatedCardsService: AssociatedCardsService
  ) { }

  ngOnInit(): void {
    this.paginationService.setInitialData(this.associatedCards, this.PAGE_SIZE);
  }

  close() {
    this.modalInstance?.close();
  }

  searchTerm(term: Event | string) {
    if (term !== Strings.EMPTY) {
      this.paginationService.search(this.search, term);
    } else {
      this.paginationService.setInitialData(this.associatedCards, this.PAGE_SIZE);
    }
  }

  search(data: number[], search: string) {
    search = search.toUpperCase();

    if (search === Strings.EMPTY) return data;

    return data.filter((cardNumber) =>
      cardNumber.toString().includes(search ?? Strings.EMPTY)
    );
  }

  accountManagmentModal() {
    this.close();
    const modalOptions: ModalOptions = {
      centered: false,
      size: Constants.MODAL_OPTIONS.SizeMd,
      modalDialogClass:ModalConstants.MODAL_OPTIONS.HiddenModal
    };

    this.modalService.open(ModalAccountInformationComponent, modalOptions);
  }

}
