import { PathModel } from 'src/app/models/PathModel';

type TModifyInternationalAgendaRequest = {
    IdSession: string,
    IdAgenda: number,
    TipoAgenda: string,
    TipoBeneficiario: string,
    TipoToken: string,
    Contrato: string,
    Email: string,
    Email2: string,
    Email3: string,
    Alias: string,
    LimiteDiario: string,
    TipoAcceso: string,
    Token: {
        OTP: string,
        HAC: string,
        NumeroDeSerie: string,
        Token: string,
        NombreAplicacion: string,
        Traza: string
    },
    MedioAcceso: string,
    OrigenConsulta?: string,
    IpCliente: string
};

export class ModifyInternationalAgendaRequest {
    constructor(public data: TModifyInternationalAgendaRequest){ }

    get endpoint(): PathModel {
      return PathModel.ModifyInternationalAgendas;
  }
}
