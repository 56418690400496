export class CreditStrings{
  static readonly VERIFIER_CODE = 'xxxxxxxxx';
  static readonly CreditOhtersBanks = 'Créditos de otros bancos';
  static readonly CONFIRMATION_DATA = 'Confirma los datos';
  static readonly ACCOUNT_MODIFICATION_HEADER = 'Modificación de cuenta';
  static readonly ALIAS_ACCOUNT = 'Alias de la cuenta';
  static readonly MAXIMUM_DAILY_LIMIT = 'Límite diario máximo';
  static readonly OPERATION_INFORMATION = 'Información de la operación';
  static readonly ADRESSEE_DETAILS = 'Datos del destinatario';
  static readonly INSTANCE_CONTINUE = 'Agregar un nuevo pago';
  static readonly BANK_AZTECA = 'AZTECA -';
  static readonly FILTER = 'Filter';
  static readonly ERROR_BANKS = 'No podemos recuperar los bancos por el momento. Favor de intentarlo más tarde.';

  static readonly MODAL_MODIFY_ACCOUNT = {
    InstanceContinue: 'Agregar un nuevo pago',
    SubtitleConfirm: 'Confirma los datos',
    ModeConfirmModifyAccount: 'modeConfirmModifyAccount',
    HeaderModifyAccountData: 'headerModifyAccountData',
    HeaderModifySuccessfulConfirmation: 'headerModifySuccessfulConfirmation',
    confirmModifyAccount:'confirmModifyAccount',
    ModeModifyAccount: 'modeModifyAccount'
  };

  static readonly ADD_CREDIT = {
    Title: 'Agregar crédito',
    Subtitle: 'Ingresa los datos del servicio',
    LabelTypeCredit: 'Tipo de crédito',
    LabelNumberCredit: 'Número de crédito',
    TextRequiredNumber: (length: number) => `Ingresa número de crédito de ${length} dígitos`,
    LabelHolderCredit: 'Titular del crédito',
    TitleSuccessfull: 'Crédito registrado exitosamente',
    SelectedService: 'Selecciona el servicio que quieres pagar',
    CreditTypeNameInput: 'credit',
    CreditNameInput: 'numberCredit',
    CreditNumberPlaceholder: '---- ---- ---- ----',
    PersonnelCreditPlaceholder: '---- ---- ---- ---- ----',
    Bank: 'Banco',
    Rfc: 'RFC'
  };

  static readonly REMOVE_SERVICE = {
    Title: '¿Estás seguro de que quieres cancelar este servicio registrado?',
    Subtitle: 'Se ha cancelado el servicio:'
  };

  static readonly TYPE_CREDITS = {
    Personnel: 'Crédito personal',
    Car: 'Crédito automotriz',
    Mortgage: 'Crédito hipotecario'
  };

  static readonly PAYMENT_CREDIT_OTHER_BANK = {
    Title: 'Pago de crédito',
    Subtitle: 'Confirmación del pago'
  };

  static readonly MODAL_MANAGE = {
    Manage: 'Administrar',
    SeeHistory: 'Ver historial',
    ModifyLimit: 'Modificar límite'
  };

  static readonly MODAL_MODIFY_LIMIT = {
    confirmData: 'Confirma los datos de la operación',
    confirmModifyLimit:'confirmModifyLimit'
  };

  static readonly VALIDATION_LIMIT = {
    valueZero: '0',
    valueZeroPoint:'0.0'
  };

  static readonly MODAL_ACCOUNT_INFORMATION = {
    creditsHeader: 'Información de tu crédito',
    associatedCards: 'Tarjetas asociadas'
  };

  static readonly MODAL_ACCOUNT_MOVEMENTS = {
    creditSummary: 'Resumen de crédito'
  };

  static readonly CREDITS_DETAILS_MOVEMENTS = {
    CurrentPayableBalanceLabelMobile: 'Saldo a liquidar <br> actual',
    PastDueInterestMobile: 'Intereses vencidos',
    PastDueInterestVatMobile: 'IVA intereses <br> vencidos',
    LateInterestVatMobile: 'IVA intereses <br> moratorios',
    LimitDateLabelMobile: 'Fecha límite de pago'
  };
}
