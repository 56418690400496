<div class="aligned">
  <div class="container">
    <div class="cont-img">
      <img
        class="adjustment"
        [src]="connectIcon | cdnImage"
        alt="MDN"
        width="auto"
        height="auto"
      />
    </div>

    <div class="alert">
      <div>
        <h1 class="text-primary-1000">Lo sentimos...</h1>
      </div>

      <br />
      <div class="centered">
        <ng-container *ngIf="onlineBankApp">
          <div>
            <p class="secondary-color">
              En este momento no es posible ingresar a
              <span class="bold-font"> Banca en Línea Personal</span>,
              estamos realizando las acciones necesarias para restablecer el
              servicio a la brevedad
            </p>
            <p class="secondary-color">Por favor, inténtalo mas tarde.</p>
          </div>
        </ng-container>

        <ng-container *ngIf="onlineBank">
          <div>
            <p class="secondary-color">
              En este momento no es posible ingresar a
              <span class="bold-font"> Banca en Línea Personal</span>,
              estamos realizando las acciones necesarias para restablecer el
              servicio a la brevedad. Por favor, inténtalo mas tarde.
            </p>
            <p class="secondary-color">
              Para tu comodidad continúa realizando las consultas y operaciones
              que requieras en tu app
              <span class="bold-font">Inbursa Móvil</span>
            </p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
