import { GetInternationalAgendaResponse } from 'src/app/interface/dto/GetInternationalAgendasResponse';
import { GetInternationalAgendasRequest } from 'src/app/interface/dto/GetInternationalAgendasReques';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccountType, Constants, Numbers, AddresseTabs, Length, Position } from 'src/core/constants/Constants';
import { Utils } from 'src/core/utils/utils';
import { GetThirdAgendasRequest } from 'src/app/interface/dto/GetThirdAgendasRequest';
import { GetThirdAgendasResponse } from 'src/app/interface/dto/GetThirdAgendasResponse';
import { CardAddresseeArray, IAddresseeAccount } from 'src/app/interface/IAddresseeAccount';
import { AccountCardService } from 'src/app/services/account-card.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { StorageService } from 'src/app/services/storage.service';
import { ICardAddresseeEvent } from 'src/app/interface/ICardAddresseeEvent';
import { SelectAddresseeErrorService } from 'src/app/services/select-addressee-error.service';
import { Strings } from 'src/core/constants/Strings';
import { IGetAddresseeResponse } from 'src/app/interface/IGetAddresseeResponse';
import { GetAddresseeRequest } from 'src/app/interface/IGetAddresseeRequest';
import { ProductsService } from 'src/app/services/products.service';
import { AccountModel } from 'src/app/interface/AccountModel';
import { IUserProductsConfig } from 'src/app/interface/IUserProductsConfig';
import { PatrimonialStatusService } from 'src/app/services/patrimonial-status.service';
import { ProductUtils } from 'src/core/utils/ProductUtils';
import { GetBrokerageHouseAgendasRequest } from 'src/app/interface/dto/GetBrokerageHouseAgendasRequest';
import { GetBrokerageHouseAgendasResponse } from 'src/app/interface/dto/GetBrokerageHouseAgendasResponse';
import { AddressConstants, AgendaType } from 'src/core/constants/AddressConstants';
import { AddresseStrings } from 'src/core/constants/AddresseStrings';
import { environment } from 'src/environments/environment';
import { BrokerageHouseService } from 'src/app/services/brokerage-house.services';
import { TransfersResources } from 'src/core/constants/TransferResources';
import { KeyAgendaType } from 'src/core/constants/TransferConstants';
@Injectable({
  providedIn: 'root',
})
export class CardAddresseeService {
  /**
   * @deprecated The variable should not be used, use instead cardAddressee
   */
  selectedCardAddressee: IAddresseeAccount = {
    profile_image: Strings.EMPTY,
    nombre: Strings.EMPTY,
    nombre_alias: Strings.EMPTY,
    banco: Strings.EMPTY,
    clabe: Strings.EMPTY,
    limite_diario: Strings.EMPTY,
    limiteDiarioAcumulado: Strings.EMPTY,
    seleccionado: false,
    idAgenda: Numbers.Zero,
    idCuentaProductoDestino: Numbers.Zero,
    cuentaDestino: Strings.EMPTY,
    tipoCuenta: Strings.EMPTY,
    isOwnTransfer: false,
    badge: Strings.EMPTY,
    email: Strings.EMPTY,
    accountNumber: Strings.EMPTY,
    tokenType: Strings.EMPTY,
    beneficiaryType: Strings.EMPTY,
    headline: Strings.EMPTY,
    type: null,
    code: Numbers.Zero,
    category: Strings.EMPTY,
    contrato: Strings.EMPTY
  };
  cardAddresseesList: CardAddresseeArray = {
    otherBanks: [],
    ownAccounts: [],
    thirdParty: [],
    internationals: [],
    brokerageHouse: []
  };
  initialAddresseeData: CardAddresseeArray = {
    otherBanks: [],
    ownAccounts: [],
    thirdParty: [],
    internationals: [],
    brokerageHouse: []
  };
  selected: boolean = false;
  private readonly _cardAddressee: BehaviorSubject<IAddresseeAccount> =
    new BehaviorSubject<IAddresseeAccount>({
      profile_image: Strings.EMPTY,
      nombre: Strings.EMPTY,
      nombre_alias: Strings.EMPTY,
      banco: Strings.EMPTY,
      clabe: Strings.EMPTY,
      limite_diario: Strings.EMPTY,
      limiteDiarioAcumulado: Strings.EMPTY,
      seleccionado: false,
      idAgenda: Numbers.Zero,
      idCuentaProductoDestino: Numbers.Zero,
      cuentaDestino: Strings.EMPTY,
      tipoCuenta: Strings.EMPTY,
      isOwnTransfer: false,
      badge: Strings.EMPTY,
      email: Strings.EMPTY,
      accountNumber: Strings.EMPTY,
      tokenType: Strings.EMPTY,
      beneficiaryType: Strings.EMPTY,
      agendaType: Strings.EMPTY,
      headline: Strings.EMPTY,
      type: Numbers.Zero,
      code: Numbers.Zero,
      contrato: Strings.EMPTY
    });

  private readonly _data: BehaviorSubject<ICardAddresseeEvent> =
    new BehaviorSubject<ICardAddresseeEvent>({
      recipients: {
        otherBanks: [],
        ownAccounts: [],
        thirdParty: [],
        internationals: [],
        brokerageHouse: []
      },
      errorMsg: Strings.EMPTY,
      errorMsgDischarge: Strings.EMPTY,
      errorService: [false, false, false, false, false],
      legend: [Strings.EMPTY, Strings.EMPTY, Strings.EMPTY, Strings.EMPTY, Strings.EMPTY]
    });


  private readonly _transferType: BehaviorSubject<number> = new BehaviorSubject<number>(AddresseTabs.Others);
  private readonly _cardProcess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _goTransfer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly baseService: IBaseService,
    private readonly storage: StorageService,
    private readonly accountCardService: AccountCardService,
    private readonly selectAddresseeError: SelectAddresseeErrorService,
    private readonly productsService: ProductsService,
    private readonly patrimonialService: PatrimonialStatusService,
    private readonly brokerageHouseService: BrokerageHouseService
  ) { }

  get transferType$() {
    return this._transferType.asObservable();
  }

  get transferType() {
    return this._transferType.getValue();
  }

  set transferType(data: number) {
    this._transferType.next(data);
  }

  get data$() {
    return this._data.asObservable();
  }

  get data() {
    return this._data.getValue();
  }

  set data(data: ICardAddresseeEvent) {
    this._data.next(data);
  }

  async getInitialData() {
    this.cardAddresseesList = {
      otherBanks: [],
      ownAccounts: [],
      thirdParty: [],
      internationals: [],
      brokerageHouse: []
    };
    await this.getTransferAddressees();
    await this.getThirdPartyAccounts();
    await this.getOwnAccounts();
    await this.getInternationals();

    if (this.showBrokerageHouse) {
      await this.getBrokerageHouseAgendas();
      if(!environment.hiddenComponents.thirdPartyBrokerageHouse) await this.getBrokerageHouseAgendasThirdParties();
    }

    this.cardAddresseesList = {
      otherBanks: this.data.recipients.otherBanks,
      ownAccounts: this.data.recipients.ownAccounts,
      thirdParty: this.data.recipients.thirdParty,
      internationals: this.data.recipients.internationals,
      brokerageHouse: this.data.recipients.brokerageHouse
    };
    this.data = {
      recipients: this.cardAddresseesList,
      errorMsg: this.data.errorMsg,
      errorMsgDischarge: this.data.errorMsgDischarge,
      errorService: this.data.errorService,
      legend: this.data.legend
    };

    this.initialAddresseeData = {
      otherBanks: [...this.data.recipients.otherBanks],
      ownAccounts: [...this.data.recipients.ownAccounts],
      thirdParty: [...this.data.recipients.thirdParty],
      internationals: [...this.data.recipients.internationals],
      brokerageHouse: [...this.data.recipients.brokerageHouse]
    };
  }

  private get showBrokerageHouse() {
    return !environment.hiddenComponents.brokerageHouse && !this.accountCardService.selectedAccountCard?.accountType ||
      (!AddressConstants.FILTER_CHECK_ACCOUNTS.some(type => this.accountCardService.selectedAccountCard.accountType === type) &&
        this.accountCardService.selectedAccountCard.category === AccountType.Eje
      );
  }

  async getThirdPartyAccounts() {
    await this.getThirdAddressees();
  }

  async getOwnAccounts() {
    await this.getOwnAddressees();
  }

  async getInternationals() {
    await this.getInternationalAgendas();
  }

  get cardAddressee$() {
    return this._cardAddressee.asObservable();
  }

  get cardAddressee() {
    return this._cardAddressee.getValue();
  }

  set cardAddressee(data: IAddresseeAccount) {
    this.selectAddresseeError.addresseeErrorData = { value: false };
    this._cardAddressee.next(data);
  }

  clearCardAddressee() {
    this.selectedCardAddressee = {
      profile_image: Strings.EMPTY,
      nombre: Strings.EMPTY,
      nombre_alias: Strings.EMPTY,
      banco: Strings.EMPTY,
      clabe: Strings.EMPTY,
      limite_diario: Strings.EMPTY,
      seleccionado: false,
      idAgenda: Numbers.Zero,
      idCuentaProductoDestino: Numbers.Zero,
      cuentaDestino: Strings.EMPTY,
      isOwnTransfer: false,
      badge: Strings.EMPTY,
      email: Strings.EMPTY,
      accountNumber: Strings.EMPTY,
      tokenType: Strings.EMPTY,
      beneficiaryType: Strings.EMPTY,
      agendaType: Strings.EMPTY,
      headline: Strings.EMPTY,
      type: null,
      code: Numbers.Zero,
      category: Strings.EMPTY,
      contrato: Strings.EMPTY
    };
    this.cardAddressee = {
      profile_image: Strings.EMPTY,
      nombre: Strings.EMPTY,
      nombre_alias: Strings.EMPTY,
      banco: Strings.EMPTY,
      clabe: Strings.EMPTY,
      limite_diario: Strings.EMPTY,
      limiteDiarioAcumulado: Strings.EMPTY,
      seleccionado: false,
      idAgenda: Numbers.Zero,
      idCuentaProductoDestino: Numbers.Zero,
      cuentaDestino: Strings.EMPTY,
      isOwnTransfer: false,
      badge: Strings.EMPTY,
      email: Strings.EMPTY,
      accountNumber: Strings.EMPTY,
      tokenType: Strings.EMPTY,
      beneficiaryType: Strings.EMPTY,
      agendaType: Strings.EMPTY,
      headline: Strings.EMPTY,
      type: Numbers.Zero,
      code: Numbers.Zero,
      contrato: Strings.EMPTY
    };
    this.selected = false;
  }

  async getTransferAddressees() {
    let addressees: IGetAddresseeResponse;
    const request: GetAddresseeRequest = new GetAddresseeRequest({
      idSesion: this.storage.getSession()
    });
    const accounts = [];
    try {
      addressees = await this.baseService.genericPost<IGetAddresseeResponse>(request, { isSOA: true });
      if (addressees && addressees.cuentas) {
        addressees.cuentas.forEach((item) => {
          accounts.push({
            profile_image: TransfersResources.USER,
            nombre: item.titular,
            email: item.eMail,
            nombre_alias: item.alias,
            banco: item.banco,
            clabe: item.numeroCuenta.toString(),
            limite_diario: item.limiteDiario.toString(),
            limiteDiarioAcumulado: item.limiteDiarioAcumulado.toString(),
            seleccionado: false,
            idAgenda: item.idAgenda,
            tiempoEspera: item.tiempoEspera,
            type: AgendaType.OthersBanks
          });
        });
      }
      accounts.sort((a,b) => a.nombre?.localeCompare(b.nombre));
      this.data = {
        recipients: {
          otherBanks: accounts,
          ownAccounts: [...this.data.recipients.ownAccounts],
          thirdParty: [...this.data.recipients.thirdParty],
          internationals: [...this.data.recipients.internationals],
          brokerageHouse: [...this.data.recipients.brokerageHouse]
        },
        errorMsg: this.data.errorMsg,
        errorMsgDischarge: this.data.errorMsgDischarge,
        errorService: this.data.errorService,
        legend: this.data.legend
      };
      this.initialAddresseeData = {...this.initialAddresseeData, otherBanks: accounts};
      this.validateAccountArray(AddresseTabs.Others);
    } catch (error) {
      const transferError = Utils.getErrorMsg(error);
      this.getErrorDetail(transferError, null, null, null, AddresseTabs.Others);
    }
  }

  private async getThirdAddressees() {
    const request = new GetThirdAgendasRequest({
      sesion: this.storage.getSession(),
      Idpersonatitular: this.storage.getUser().personId
    });
    const accounts = [];
    try {
      const response =
        await this.baseService.genericPost<GetThirdAgendasResponse>(request, { isSOA: true });
      if (response?.cuentas?.length) {
        response.cuentas.forEach((agenda) => {
          accounts.push({
            idAgenda: agenda.idAgenda,
            profile_image: TransfersResources.USER,
            nombre: agenda.nombrePropio,
            nombre_alias: agenda.alias,
            banco: agenda.banco,
            clabe: agenda.numeroCuenta,
            limite_diario: agenda.limite.toString(),
            limiteDiarioAcumulado: agenda.limiteDiarioAcumulado.toString(),
            seleccionado: false,
            tiempoEspera: agenda.tiempoEspera,
            type: AgendaType.ThirdParties,
            email: agenda.eMail
          });
        });
      }
      accounts.sort((a,b) => a.nombre?.localeCompare(b.nombre));
      this.data = {
        recipients: {
          otherBanks: [...this.data.recipients.otherBanks],
          ownAccounts: [...this.data.recipients.ownAccounts],
          thirdParty: accounts,
          internationals: [...this.data.recipients.internationals],
          brokerageHouse: [...this.data.recipients.brokerageHouse]
        },
        errorMsg: this.data.errorMsg,
        errorMsgDischarge: this.data.errorMsgDischarge,
        errorService: this.data.errorService,
        legend: this.data.legend
      };
      this.initialAddresseeData = {...this.initialAddresseeData, thirdParty: accounts};
      this.validateAccountArray(AddresseTabs.ThirdParty);
    } catch (error) {
      const thirdTransferError = Utils.getErrorMsg(error);
      this.getErrorDetail(null, thirdTransferError, null, null, AddresseTabs.ThirdParty);
    }
  }

  setLegend(condition: boolean, position: number){
    return condition ?  Strings.MSG_AGENDAS_TRANSFER : this.data.legend[position];
  }

  setEmptyLegend(position: number){
    const type = this.getType(position);
    if (this.data.recipients[type].length === Length.Empty) {
      this.data.legend[position] = Strings.MSG_AGENDAS_TRANSFER;
    }
    return this.data.legend;
  }

  setErrorService(position: number, set: boolean): boolean[] {
    const updatedConditions = [...this.data.errorService];
    updatedConditions[position] = set;
    return updatedConditions;
  }

  setErrorLegend(position: number, text: string): string[] {
    return this.data.legend.map((legend, index) =>
      position === index ? text : legend
    );
  }

  getType(position: number) {
    const type = Object.values(KeyAgendaType);
    return type[position];
  }

  private validateAccountArray(
    position: number
  ) {
      this.data = {
        recipients: {
          otherBanks: [...this.data.recipients.otherBanks],
          ownAccounts: [...this.data.recipients.ownAccounts],
          thirdParty: [...this.data.recipients.thirdParty],
          internationals: [...this.data.recipients.internationals],
          brokerageHouse: [...this.data.recipients.brokerageHouse]
        },
        errorMsg: Strings.MSG_AGENDAS_TRANSFER,
        errorMsgDischarge: Strings.EMPTY,
        errorService: this.setErrorService(position, false),
        legend: this.setEmptyLegend(position),
      };
  }

  private getErrorDetail(
    transferError?: { msg: string; code: number },
    thirdTransferError?: { msg: string; code: number },
    internationalTransferError?: { msg: string; code: number },
    brokerageHouseTransferError?: {msg: string; code: number},
    position?: number
  ) {
    if (
      transferError?.code.toString() === Constants.AGENDAS_TRANSFER_CODE ||
      thirdTransferError?.code.toString() === Constants.AGENDAS_THIRD_TRANSFER_CODE||
      internationalTransferError?.code.toString() === Constants.AGENDAS_INTERNATIONAL_TRANSFER_CODE ||
      brokerageHouseTransferError?.code.toString() === AddressConstants.WITHOUT_AGENDAS_BROKERAGE_HOUSE_CODE
    ) {
      this.data = {
        recipients: {
          otherBanks: [...this.data.recipients.otherBanks],
          ownAccounts: [...this.data.recipients.ownAccounts],
          thirdParty: [...this.data.recipients.thirdParty],
          internationals: [...this.data.recipients.internationals],
          brokerageHouse: [...this.data.recipients.brokerageHouse]
        },
        errorMsg: Strings.MSG_AGENDAS_TRANSFER,
        errorMsgDischarge: Strings.EMPTY,
        errorService: this.setErrorService(position, false),
        legend: [
          this.setLegend(transferError?.code.toString() === Constants.AGENDAS_TRANSFER_CODE, Position.Zero),
          this.data.legend[Position.One],
          this.setLegend(thirdTransferError?.code.toString() === Constants.AGENDAS_THIRD_TRANSFER_CODE, Position.Two),
          this.setLegend(internationalTransferError?.code.toString() === Constants.AGENDAS_INTERNATIONAL_TRANSFER_CODE, Position.Three),
          this.setLegend(brokerageHouseTransferError?.code.toString() === AddressConstants.WITHOUT_AGENDAS_BROKERAGE_HOUSE_CODE, Position.Four)
        ]
      };
    } else {
      let error =
        transferError &&
          transferError.code !== Constants.UNEXPECTED_CODE &&
          transferError.code !== Constants.CATCH_CODE &&
          transferError.code.toString() !== Constants.AGENDAS_TRANSFER_CODE
          ? transferError
          : thirdTransferError;
      if (!error) {
        error = { msg: Strings.SERVICES.UnexpectedMsg, code: Constants.NULL_CODE };
      }
      this.data = {
        recipients: {
          otherBanks: [...this.data.recipients.otherBanks],
          ownAccounts: [...this.data.recipients.ownAccounts],
          thirdParty: [...this.data.recipients.thirdParty],
          internationals: [...this.data.recipients.internationals],
          brokerageHouse: [...this.data.recipients.brokerageHouse]
        },
        errorMsg: Utils.SERVICE_ERROR_MSG(error.msg, error.code),
        errorService: this.setErrorService(position, true),
        legend: this.setErrorLegend(position, Utils.SERVICE_ERROR_MSG(error.msg, error.code))
      };
    }
  }

  private async getOwnAddressees() {
    const accounts = [];
    const accountType = this.accountCardService.accountCard?.accountType;
    let config: IUserProductsConfig =  {forceOsb: true, reload: true, filterCodeflexAccounts: true};
    try {
      config = ProductUtils.evaluateWebAccount(accountType, config);
      const userProducts = await this.productsService.getAccounts(config);
      for (const product of userProducts)  {
        if (this.accountCardService.accountCard?.productId !== product.idCuentaProducto &&
          product.tipoCuenta !== AccountType.GroceryCoupon && this.showWebAccount(product)) {
            accounts.push({
              profile_image: TransfersResources.USER,
              nombre: product.descripcionCuenta,
              nombre_alias: product.alias,
              banco: Constants.INBURSA_BANK,
              clabe: product.cuentaClabe.toString(),
              idCuentaProductoDestino: product.idCuentaProducto,
              cuentaDestino: product.numeroCuenta,
              seleccionado: false,
              estaBloqueada: product.block,
              type: AgendaType.OwnAccounts,
              tipoCuenta: product.tipoCuenta,
              category: product.category,
              limite_diario: await this.patrimonialService.evaluateLimit(product),
              saldo: product.saldos.disponible
            });
        }
      }
      this.data = {
        recipients: {
          otherBanks: [...this.data.recipients.otherBanks],
          ownAccounts: accounts,
          thirdParty: [...this.data.recipients.thirdParty],
          internationals: [...this.data.recipients.internationals],
          brokerageHouse: [...this.data.recipients.brokerageHouse]
        },
        errorMsg: this.data.errorMsg,
        errorMsgDischarge: this.data.errorMsgDischarge,
        errorService: this.data.errorService,
        legend: this.data.legend
      };
      this.initialAddresseeData = {...this.initialAddresseeData, ownAccounts: accounts};
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.getErrorDetail(null, null, null, null, AddresseTabs.OwnAccounts);
    }
  }

  private showWebAccount(product: AccountModel) {
    const accountType = this.accountCardService.accountCard?.accountType;
    if(!this.accountCardService.accountCard?.productId){
      return true;
    }else if(accountType !== AccountType.NOM && product.tipoCuenta === AccountType.WEB){
      return false;
    }else{
      return true;
    }
  }

  private async getInternationalAgendas() {
    const request = new GetInternationalAgendasRequest({
      IdSession: this.storage.getSession(),
      Contrato: Strings.EMPTY
    });
    const accounts = [];
    try {
      const response =
        await this.baseService.genericPost<GetInternationalAgendaResponse>(
          request,
          {
            isSOA: true
          }
        );
      if (response?.MulAgendaInternacionalListaVO.length) {
        response.MulAgendaInternacionalListaVO.forEach(
          (internationalAgendas) => {
            accounts.push({
              profile_image: TransfersResources.USER,
              nombre: internationalAgendas.Titular,
              nombre_alias: internationalAgendas.Alias,
              banco: internationalAgendas.NombreBanco,
              clabe: internationalAgendas.NumeroCuenta.toString(),
              limite_diario: internationalAgendas.LimiteDiario.toString(),
              limiteDiarioAcumulado: internationalAgendas.LimiteDiario.toString(),
              badge: internationalAgendas.Divisa,
              email: internationalAgendas.Email,
              accountNumber: internationalAgendas.NumeroCuenta.toString(),
              agendaType: Constants.INTERNATIONAL_AGENDAS,
              idAgenda: internationalAgendas.IdAgenda,
              tokenType: internationalAgendas.TipoToken,
              beneficiaryType: internationalAgendas.TipoBeneficiario,
              headline: internationalAgendas.Titular,
              type: AgendaType.International,
              personId: internationalAgendas.IdPersona
            });
          }
        );
      }
      accounts.sort((a,b) => a.nombre?.localeCompare(b.nombre));
      this.data = {
        recipients: {
          otherBanks: [...this.data.recipients.otherBanks],
          ownAccounts: [...this.data.recipients.ownAccounts],
          thirdParty: [...this.data.recipients.thirdParty],
          internationals: accounts,
          brokerageHouse: [...this.data.recipients.brokerageHouse]
        },
        errorMsg: this.data.errorMsg,
        errorMsgDischarge: this.data.errorMsgDischarge,
        errorService: this.data.errorService,
        legend: this.data.legend
      };
      this.initialAddresseeData = {...this.initialAddresseeData, internationals: accounts};
      this.validateAccountArray(AddresseTabs.International);
    } catch (error) {
      const internationalTransferError = Utils.getErrorMsg(error);
      this.getErrorDetail(null, null, internationalTransferError, null, AddresseTabs.International);
    }
  }

  private async getBrokerageHouseAgendas() {
    const request = new GetBrokerageHouseAgendasRequest({
      IdSession: this.storage.getSession()
    });
    const accounts = [];
    try {
      const response =
        await this.baseService.genericPost<GetBrokerageHouseAgendasResponse>(
          request,
          {
            isSOA: true
          }
        );
      if (response?.Contratos?.length) {
        response.Contratos.forEach(
          (item) => {
            accounts.push({
              type: AddressConstants.TAB_INDEX_BROKERAGE_HOUSE,
              contrato: item,
              profile_image: TransfersResources.USER,
              nombre: AddresseStrings.NAME_BROKERAGE_HOUSE
            });
          }
        );
      }

      accounts.sort((a, b) => a.contrato?.localeCompare(b.contrato));
      this.data = {
        recipients: {
          otherBanks: [...this.data.recipients.otherBanks],
          ownAccounts: [...this.data.recipients.ownAccounts],
          thirdParty: [...this.data.recipients.thirdParty],
          internationals: [...this.data.recipients.internationals],
          brokerageHouse: accounts
        },
        errorMsg: this.data.errorMsg,
        errorMsgDischarge: this.data.errorMsgDischarge,
        errorService: this.data.errorService,
        legend: this.data.legend
      };
      this.initialAddresseeData = {...this.initialAddresseeData, brokerageHouse: accounts};
      this.validateAccountArray(AddressConstants.TAB_INDEX_BROKERAGE_HOUSE);
    } catch (error) {
      const brokerageHouseTransferError = Utils.getErrorMsg(error);
      this.getErrorDetail(null, null, null, brokerageHouseTransferError, AddressConstants.TAB_INDEX_BROKERAGE_HOUSE);
    }
  }

  private async getBrokerageHouseAgendasThirdParties() {
    try {
      const accounts = await this.brokerageHouseService.getBrokerageHouseAgendasThirdParties();
      this.data = {
        recipients: {
          otherBanks: [...this.data.recipients.otherBanks],
          ownAccounts: [...this.data.recipients.ownAccounts],
          thirdParty: [...this.data.recipients.thirdParty],
          internationals: [...this.data.recipients.internationals],
          brokerageHouse: [
            ...this.data.recipients.brokerageHouse,
            ...accounts
          ],
        },
        errorMsg: this.data.errorMsg,
        errorMsgDischarge: this.data.errorMsgDischarge,
        errorService: this.data.errorService,
        legend: this.data.legend
      };
      this.initialAddresseeData = {...this.initialAddresseeData, brokerageHouse: accounts};
      this.validateAccountArray(AddressConstants.TAB_INDEX_BROKERAGE_HOUSE);
    } catch (error) {
      const brokerageHouseTransferError = Utils.getErrorMsg(error);
      this.getErrorDetail(null, null, null, brokerageHouseTransferError, AddressConstants.TAB_INDEX_BROKERAGE_HOUSE);
    }
  }

  removeAddressee(idAgenda: number) {
    const filteredData = {
      otherBanks: [...this.initialAddresseeData.otherBanks.filter(addresse => this.removeFilter(addresse, idAgenda))],
      ownAccounts: [...this.initialAddresseeData.ownAccounts.filter(addresse => this.removeFilter(addresse, idAgenda))],
      thirdParty: [...this.initialAddresseeData.thirdParty.filter(addresse => this.removeFilter(addresse, idAgenda))],
      internationals: [...this.initialAddresseeData.internationals.filter(addresse => this.removeFilter(addresse, idAgenda))],
      brokerageHouse: [...this.initialAddresseeData.brokerageHouse.filter(addresse => this.removeFilter(addresse, idAgenda))]
    };

    this.data = {
      recipients: filteredData,
      errorMsg: this.data.errorMsg,
      errorMsgDischarge: this.data.errorMsgDischarge,
      errorService: this.data.errorService,
      legend: this.data.legend
    };
    this.cardAddresseesList = filteredData;

    this.initialAddresseeData = filteredData;
  }

  clearData() {
    this.cardAddresseesList = {
      otherBanks: [],
      ownAccounts: [],
      thirdParty: [],
      internationals: [],
      brokerageHouse: []
    };
    this.initialAddresseeData = {
      otherBanks: [],
      ownAccounts: [],
      thirdParty: [],
      internationals: [],
      brokerageHouse: []
    };

    this.data = {
      recipients: {
        otherBanks: [],
        ownAccounts: [],
        thirdParty: [],
        internationals: [],
        brokerageHouse: []
      },
      errorMsg: Strings.EMPTY,
      errorMsgDischarge: Strings.EMPTY,
      errorService: [false, false, false, false, false],
      legend: [Strings.EMPTY, Strings.EMPTY, Strings.EMPTY, Strings.EMPTY, Strings.EMPTY]
    };
  }

  resetData() {
    this.cardAddresseesList = {
      otherBanks: [...this.initialAddresseeData.otherBanks],
      ownAccounts: [...this.initialAddresseeData.ownAccounts],
      thirdParty: [...this.initialAddresseeData.thirdParty],
      internationals: [...this.initialAddresseeData.internationals],
      brokerageHouse: [...this.initialAddresseeData.brokerageHouse]
    };
    this.data = {
      recipients: {
        otherBanks: [...this.initialAddresseeData.otherBanks],
        ownAccounts: [...this.initialAddresseeData.ownAccounts],
        thirdParty: [...this.initialAddresseeData.thirdParty],
        internationals: [...this.initialAddresseeData.internationals],
        brokerageHouse: [...this.initialAddresseeData.brokerageHouse]
      },
      errorMsg: this.data.errorMsg,
      errorMsgDischarge: this.data.errorMsgDischarge,
      errorService: this.data.errorService,
      legend: this.data.legend
    };
  }

  filter(value: string){
    if (value === Strings.EMPTY){
      this.cardAddresseesList = {
        otherBanks: [...this.initialAddresseeData.otherBanks],
        ownAccounts: [...this.initialAddresseeData.ownAccounts],
        thirdParty: [...this.initialAddresseeData.thirdParty],
        internationals: [...this.initialAddresseeData.internationals],
        brokerageHouse: [...this.initialAddresseeData.brokerageHouse]
      };
      this.data = {
        recipients : {
          otherBanks: [...this.initialAddresseeData.otherBanks],
          ownAccounts: [...this.initialAddresseeData.ownAccounts],
          thirdParty: [...this.initialAddresseeData.thirdParty],
          internationals: [...this.initialAddresseeData.internationals],
          brokerageHouse: [...this.initialAddresseeData.brokerageHouse]
        },
        errorMsg: this.data.errorMsg,
        errorMsgDischarge: this.data.errorMsgDischarge,
        errorService: this.data.errorService,
        legend: this.data.legend
      };
      return;
    }
    value = Utils.normalizeString(value.toLowerCase());

    const filteredData = {
      otherBanks: [...this.initialAddresseeData.otherBanks.filter(addresse => this.defaultFilter(addresse, value))],
      ownAccounts: [...this.initialAddresseeData.ownAccounts.filter(addresse => this.defaultFilter(addresse, value))],
      thirdParty: [...this.initialAddresseeData.thirdParty.filter(addresse => this.defaultFilter(addresse, value))],
      internationals: [...this.initialAddresseeData.internationals.filter(addresse => this.defaultFilter(addresse, value))],
      brokerageHouse: [...this.initialAddresseeData.brokerageHouse.filter(addresse => this.defaultFilter(addresse, value))]
    };

    this.data = {
      recipients: filteredData,
      errorMsg: this.data.errorMsg,
      errorMsgDischarge: this.data.errorMsgDischarge,
      errorService: this.data.errorService,
      legend: this.data.legend
    };
    this.cardAddresseesList = filteredData;

  }

  private defaultFilter(addresse: IAddresseeAccount, value: string){
    return (Utils.normalizeString(addresse.banco?.toLowerCase()).includes(value.toLowerCase())) ||
    (Utils.normalizeString(addresse.nombre?.toLowerCase()).includes(value.toLowerCase())) ||
    (addresse.clabe?.includes(value)) ||
    (addresse.limite_diario?.toString().includes(value)) ||
    (Utils.normalizeString(addresse.nombre_alias?.toLowerCase()).includes(value.toLowerCase())) ||
    (addresse.contrato?.includes(value));
  }

  private removeFilter(addresse: IAddresseeAccount, idAgenda: number){
    return addresse.idAgenda !== idAgenda;
  }

  get cardProcess() {
    return this._cardProcess.getValue();
  }

  set cardProcess(data: boolean) {
    this._cardProcess.next(data);
  }

  get goTransfer$() {
    return this._goTransfer.asObservable();
  }

  get goTransfer() {
    return this._goTransfer.getValue();
  }

  set goTransfer(data: boolean) {
    this._goTransfer.next(data);
  }
}
