import { PathModel } from 'src/app/models/PathModel';

type TConsultInsuranceCfdiRequest = {
  idSesion: string,
  emisor: string,
  nivelCobro: string,
  ramo: string,
  numeroDocumento: string,
  correo?: string,
};

export class ConsultInsuranceCfdiRequest {
  constructor (public data: TConsultInsuranceCfdiRequest){}
  get endpoint(): PathModel {
    return PathModel.DownloadCfdi;
  }
}
