<ng-container *ngIf="vm$ | async as vm">
  <div class="scroll-property moz-scroll" id="scrollProperty" [ngClass]="vm.breakpoints.mobile ? 'pb-2' : 'pb-1'">
    <ng-container *ngFor="let direction of directions(); let id = index">
      <div id="{{id}}" class="p-card" [ngClass]="id + 1 == directions().length ? 'pr-10' : ''">
        <div class="card-address drop-shadow-m"
          [ngClass]="selectedId().id == id ? 'border-active active-color' : ''">
          <div class="item" (click)="selectCard(id, direction.id)">
            <div>
              <label class="detail">
                <strong>Dirección: </strong>
                <span class="truncated-text" placement="top" *ngIf="vm">
                  {{direction.address | slice:0:sliceLength}}
                  <span [ngbTooltip]="direction.address" *ngIf="direction.address.length > sliceLength ">...</span>
                </span>
              </label>
            </div>
            <div>
              <label class="detail" [ngClass]="direction.phone.length > 20 ? 'detail-phone-sm' : '' ">
                <strong>Horarios: </strong>{{ direction.phone }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
