import { formatDate } from '@angular/common';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { map, tap } from 'rxjs';
import { ModalOptions } from 'src/app/interface/modal-options';
import { AforeDataService } from 'src/app/services/afore-data.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Constants, FormatDate, Regex, Position, Numbers } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { ModalEndProcessAlertComponent } from 'src/app/shared/components/modal-end-process-alert/modal-end-process-alert.component';
import { StateVoluntaryWithdrawalService } from 'src/app/services/state-voluntary-withdrawal.service';
import { ModalWithdrawalService } from 'src/app/services/modal-withdrawal.service';
import { Utils } from 'src/core/utils/utils';
import { AlertService } from 'src/app/services/alert.service';
import { TSubAccountDetails } from 'src/app/interface/dto/TVoluntaryValidationsResponse';
import { VoluntaryWithdrawalConfirmationRequest } from 'src/app/interface/dto/TVoluntaryWithdrawalConfirmationRequest';
import { AforeStrings } from 'src/core/constants/AforeStrings';
import { FileUtils } from 'src/core/utils/FileUtils';
import { AforeService } from 'src/app/services/afore.service';
import { AforeVoluntaryWithdrawalRequest } from 'src/app/interface/dto/AforeVoluntaryWithdrawalRequest';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { ModalConstants } from 'src/core/constants/ModalConstants';

@Component({
  selector: 'app-modal-voluntary-withdrawal',
  templateUrl: './modal-voluntary-withdrawal.component.html',
  styleUrls: ['./modal-voluntary-withdrawal.component.css']
})
export class ModalVoluntaryWithdrawalComponent {
  @ViewChild('imgClose') imgClose: ElementRef;
  @ViewChild('viewModal', { static: true }) viewModal: ElementRef;
  showConfirmData: boolean = false;
  changeView: boolean = false;
  subtitle: string = AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.SubtitleFormVoluntary;
  showSuccessView: boolean = false;
  totalAmount: string = Strings.EMPTY;
  withdrawalAmount: string = Strings.EMPTY;
  isrInterests: string = Strings.EMPTY;
  isrOtherIncome: string = Strings.EMPTY;
  showLabelError: boolean = false;
  labelError: string = AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.Alert;
  occupationLabel: string = AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.SectorNames[Position.Zero];
  occupationLabels: Array<string> = AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.SectorNames;
  occupationCode: string = AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.PublicSectorCode;
  showErrOccupation: boolean = false;
  labelErrorOccupation: string = AforeStrings.MODAL_ADD_PRODUCT.AlertDataProduct;
  clabe: string = Strings.EMPTY;
  completeDate: string = Strings.EMPTY;
  options: ModalOptions = {
    centered: true,
    size: Constants.MODAL_OPTIONS.SizeMd,
    modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAlertEndProcess
  };
  subAccountsTypes: Array<TSubAccountDetails> = [];
  subAccountsDescriptions: Array<string> = [];
  subAccount: TSubAccountDetails;
  withdrawal: string = AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.WithdrawalTypes[Position.Zero];
  shortAmount: boolean = false;
  removeOperation: string = Resources.CLOSE_CIRCLE_BLUE;
  checkFill: string = Resources.GREEN_CHECK_FILL;
  labelWithdrawal: string = AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.Withdrawal;
  labelSuccessfulWithdrawal: string = Strings.TRANSFER.SuccessfulWithdrawaL;
  labelIdOperation: string = Strings.EMPTY;
  labelWithdrawalSubAccount: string = AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.WithdrawalSubAccount;
  labelTotalAmount: string = AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.TotalAmount;
  labelCodeAccount: string = AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.CodeAccount;
  labelWithdrawalAmount: string = Strings.MESSAGES.WithdrawalAmount;
  labelOccupation: string = AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.Occupation;
  labelWithdrawalType: string = AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.WithdrawalType;
  withdrawalTypes: Array<string> = AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.WithdrawalTypes;
  buttonCancel: string = Strings.BUTTONS.Cancel;
  buttonContinue: string = Strings.BUTTONS.Continue;
  showModalAge: boolean = false;
  onlyNumbers: RegExp = Regex.DifferentFromDecimals;
  isTotalWithdrawal: boolean = false;
  hasResult: boolean = false;
  onFocusAmount: boolean = false;
  modalInstance: NgbModalRef;

  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints })),
    tap(({ breakpoints }) => {
      this.shortAmount = breakpoints.mobile;
    })
  );

  constructor(
    private readonly modalService: ModalService,
    private readonly responsiveService: ResponsiveService,
    private readonly stateVoluntaryService: StateVoluntaryWithdrawalService,
    private readonly aforeDataService: AforeDataService,
    private readonly modalWithdrawalService: ModalWithdrawalService,
    private readonly aforeService: AforeService,
    private readonly alertService: AlertService
  ) { }

  async ngOnInit() {
    this.showConfirmData = this.stateVoluntaryService.confirmVoluntary;
    this.showSuccessView = this.stateVoluntaryService.successVoluntary;
    this.hasResult = !this.showSuccessView;
    if (!this.stateVoluntaryService.confirmVoluntary && !this.stateVoluntaryService.successVoluntary) {
      this.subAccountsTypes = this.stateVoluntaryService.voluntaryValidations?.detalleSubCuentasResponseVO;
      this.subAccountsDescriptions = this.subAccountsTypes.map(subAccount => subAccount.descripcion);
      this.totalAmount = this.subAccountsTypes[Position.Zero]?.saldo?.toString();
      this.subAccount = this.subAccountsTypes[Position.Zero];
      this.clabe = this.stateVoluntaryService.voluntaryValidations?.cuentaClabe;
      this.setAforeWithdrawalValues();
    }
    this.completeDate = `${formatDate(new Date(), FormatDate.DayMonthYearComplete, Constants.LOCALE_ES_MX)}${Strings.AFORE.H}`;
    if (this.stateVoluntaryService.successVoluntary && !this.stateVoluntaryService.confirmVoluntary) {
      await this.getVoluntaryWithdrawalConfirmation();
    }
    if (!this.stateVoluntaryService.successVoluntary && this.stateVoluntaryService.confirmVoluntary) {
      this.getAforeWithdrawalValues();
      this.subtitle = Strings.INTERNATIONAL_BENEFICIARY.DataConfirmation;
      this.aforeDataService.detailWPartialData = { value: false };
    }
  }

  close(action: string) {
    if (action === Strings.AFORE.Close) {
      this.modalService.open(ModalEndProcessAlertComponent, this.options);
      return;
    }
    if (action === Constants.FORM_CONTROLS.Cancel) {
      this.modalInstance.dismiss();
    }
  }

  /**
   * Este método se encarga de validar la información ingresada por el usuario en el modal de retiro voluntario.
   */
  continueVoluntary() {
    const validate = this.validateAmount(this.withdrawalAmount);
    if (!validate) {
      this.validateAmountData();
      this.aforeDataService.detailWPartialData = { value: true };
      if (!this.showLabelError && !this.showErrOccupation && !this.showModalAge) {
        this.subtitle = Strings.INTERNATIONAL_BENEFICIARY.DataConfirmation;
        this.stateVoluntaryService.successVoluntary = true;
        this.stateVoluntaryService.isVoluntaryProcess = true;
        this.setAforeWithdrawalValues();
        const selectSubAccount = this.subAccountsTypes.find(subAccount => subAccount.descripcion === this.subAccount.descripcion);
        this.showModalInformation(selectSubAccount.tipoSubCuenta);
      }
    }
  }

  /**
   * Este método se encarga de establecer la informacion de acuerdo al tipo de subcuenta
   * de la que se va realizar el retiro.
   */
  showModalInformation(optionSubAccount: string) {
    this.modalInstance.dismiss();
    this.initModalData(optionSubAccount);
  }

  /**
   * Este método se encarga de abir el modal de informacion de acuerdo al tipo de subcuenta
   * de la que se va realizar el retiro
   */
  initModalData(subAccount: string) {
    const withdrawalInfo = this.modalWithdrawalService.subAccountTypeInfo.find(item => item.subAccount === subAccount);
    this.modalWithdrawalService.openModal(withdrawalInfo);
  }

  /**
   * Este método se encarga de validar la información ingresada por el usuario en el modal de retiro voluntario.
  */
  validateAmountData() {
    this.showLabelError = this.withdrawalAmount === Strings.EMPTY && !this.isTotalWithdrawal;
    this.labelError = AforeStrings.MODAL_ADD_PRODUCT.AlertDataProduct;
  }

  /**
   * Este método se encarga de validar el monto de retiro ingresado por el usuario.
   * @param amount Un string que representa el monto de retiro ingresado por el usuario.
   * @returns Un booleano que indica si se está mostrando la etiqueta de error.
   */
  validateAmount(amount: string) {
    const amountAux = Number(amount);
    const totalAmountAux = Number(this.totalAmount.replace(Regex.MatchComma, Strings.EMPTY).replace(Regex.MatchCurrencySymbol, Strings.EMPTY));
    if (amountAux <= Constants.AMOUNT_ZERO || amount === Strings.EMPTY || isNaN(amountAux)) {
      this.showLabelError = true;
      this.labelError = Strings.AMOUNT_ERRORS.ZeroAmount;
      this.withdrawalAmount = Strings.EMPTY;
    } else if (amountAux > totalAmountAux) {
      this.showLabelError = true;
      this.labelError = AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.Alert;
    } else {
      this.showLabelError = false;
      this.withdrawalAmount = this.isTotalWithdrawal ? totalAmountAux.toString() : amountAux.toString();
    }
    return this.showLabelError;
  }

  /**
   * Este método se encarga de cambiar la vista actual en el modal de retiro voluntario.
   * @param view Un string que representa la nueva vista a mostrar en el modal.
  */
  changeViewModal(event: boolean) {
    this.changeView = event;
    if (this.changeView) {
      this.showSuccessView = true;
      this.showConfirmData = true;
      this.subtitle = Strings.EMPTY;
    } else {
      this.getAforeWithdrawalValues();
      this.showSuccessView = false;
      this.showConfirmData = true;
    }
  }

  /**
   * Este método se encarga de cambiar la ocupación seleccionada y actualizar las propiedades relacionadas.
   * @param occupation Un string que representa la nueva ocupación seleccionada.
   * @param getDescription Un booleano que determina si se debe devolver la descripción de la ocupación.
  */
  changeOccupation(occupation: string, getDescription: boolean = false) {
    if (getDescription) {
      this.occupationLabel = occupation === AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.PublicSectorCode ?
        AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.SectorNames[Position.Zero] :
        AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.SectorNames[Position.One];
    } else {
      this.showErrOccupation = false;
      this.occupationCode = occupation === AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.SectorNames[Position.Zero] ?
        AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.PublicSectorCode :
        AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.PrivateSectorCode;
      this.occupationLabel = occupation;
    }
  }

  /**
   * Este método se encarga de cambiar la subcuenta seleccionada y actualizar las propiedades relacionadas.
   * @param subAccountTypeSelected Un string que representa la nueva subcuenta seleccionada.
  */
  changeSubAccount(subAccountSelected: string) {
    const auxSubAccount = this.subAccountsTypes.find(subAccount => subAccount.descripcion === subAccountSelected);
    this.subAccount = auxSubAccount;
    this.totalAmount = this.subAccount.saldo?.toString();
    this.changeWithdrawal(AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.WithdrawalTypes[Position.Zero]);
  }

  /**
   * Este método se encarga de cambiar el tipo de retiro y actualizar las propiedades relacionadas.
   * @param withdrawal Un string que representa el nuevo tipo de retiro.
  */
  changeWithdrawal(withdrawal: string) {
    this.withdrawal = withdrawal;
    this.isTotalWithdrawal = this.withdrawal === AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.WithdrawalTypes[Position.One];
    this.withdrawalAmount = this.isTotalWithdrawal ? this.totalAmount : Strings.EMPTY;
    this.showLabelError = this.isTotalWithdrawal ? false : this.showLabelError;
  }

  ngOnDestroy(): void {
    this.stateVoluntaryService.successVoluntary = false;
    this.aforeDataService.detailWPartialData = { value: false };
  }

  /**
   * Este método se encarga de confirmar la solicitud de una operación de retiro voluntario.
  */
  async getVoluntaryWithdrawalConfirmation() {
    try {
      const request: VoluntaryWithdrawalConfirmationRequest = new VoluntaryWithdrawalConfirmationRequest({
        numeroSolicitud: this.stateVoluntaryService.dataVoluntaryWithdrawal?.idOperation,
        otp: this.stateVoluntaryService.dataVoluntaryWithdrawal?.otp
      });
      await this.stateVoluntaryService.getVoluntaryWithdrawalConfirmation(request);
      if (!this.stateVoluntaryService.voluntaryWithdrawalConfirmation) {
        throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
      }
      this.stateVoluntaryService.dataVoluntaryWithdrawal.idTransaction = this.stateVoluntaryService.voluntaryWithdrawalConfirmation;
      this.getAforeWithdrawalValues();
      this.labelIdOperation = `${Strings.FOLIO}${this.stateVoluntaryService.dataVoluntaryWithdrawal.idTransaction}`;
      this.showSuccessView = true;
      this.showConfirmData = true;
      this.subtitle = Strings.EMPTY;
      this.hasResult = true;
    } catch (error) {
      await this.stateVoluntaryService.getVoluntaryWithdrawalReverse();
      this.alertError(Utils.getErrorMsg(error));
      this.stateVoluntaryService.emptyDataVoluntaryWithdrawal();
      this.stateVoluntaryService.isVoluntaryProcess = false;
    }
  }

  /**
   * Este método se encarga de establecer los valores relacionados con el retiro de Afore en el servicio
   * stateVoluntaryService a partir de las propiedades actuales de la clase ModalVoluntaryWithdrawal.
  */
  setAforeWithdrawalValues() {
    this.stateVoluntaryService.dataVoluntaryWithdrawal.subAccount = this.getSubAccount();
    this.stateVoluntaryService.dataVoluntaryWithdrawal.clabe = this.stateVoluntaryService.voluntaryValidations?.cuentaClabe;
    this.stateVoluntaryService.dataVoluntaryWithdrawal.occupation = this.occupationCode;
    this.stateVoluntaryService.dataVoluntaryWithdrawal.typeWithdrawal = this.withdrawal;
    this.stateVoluntaryService.dataVoluntaryWithdrawal.withdrawalAmount = parseFloat(this.withdrawalAmount);
  }

  /**
   * Este método se encarga de obtener los valores relacionados con el retiro de Afore del servicio
   * stateVoluntaryService y asignarlos a las propiedades correspondientes de la clase ModalVoluntaryWithdrawal.
  */
  getAforeWithdrawalValues() {
    this.subAccountsTypes = this.stateVoluntaryService.voluntaryValidations?.detalleSubCuentasResponseVO;
    this.subAccount = this.stateVoluntaryService.dataVoluntaryWithdrawal?.subAccount;
    this.changeSubAccount(this.subAccount.descripcion);
    this.clabe = this.stateVoluntaryService.dataVoluntaryWithdrawal?.clabe;
    this.withdrawal = this.stateVoluntaryService.dataVoluntaryWithdrawal?.typeWithdrawal;
    this.changeOccupation(this.stateVoluntaryService.dataVoluntaryWithdrawal?.occupation, true);
    this.withdrawalAmount = this.stateVoluntaryService.dataVoluntaryWithdrawal?.withdrawalAmount?.toString();
    this.isrInterests = this.stateVoluntaryService.dataVoluntaryWithdrawal?.isrInterests;
    this.isrOtherIncome = this.stateVoluntaryService.dataVoluntaryWithdrawal?.isrOtherIncome.toString();
  }

  /**
   * Este método se encarga de buscar y devolver la descripción o el tipo de una subcuenta específica
   * en función del parámetro proporcionado.
   * @param getDescription Un booleano que determina qué propiedad de la subcuenta se debe devolver.
   * @returns Retorna la descripción o el tipo de la subcuenta, dependiendo del valor de getDescription.
  */
  getSubAccount(): TSubAccountDetails {
    return this.subAccountsTypes.find(subAccount => subAccount.descripcion === this.subAccount.descripcion);
  }

  /**
   * Cierra la ventana modal y muestra una alerta con el mensaje y el código de error proporcionados.
   * @param error Un objeto que contiene el mensaje de error y el código de error.
  */
  alertError(error: { msg: string, code: number }) {
    this.modalService.close();
    const alertInstance = this.alertService.showPopupAlert({
      message: Utils.SERVICE_ERROR_MSG(error.msg, error.code),
      header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
      btnLabel: Strings.MSG_POPUP_ACCEPT,
      imgHead: Resources.CLOSE_ICON_RED,
      btnExit: false
    }, {
      onSuccess: () => alertInstance.dismiss(),
      onClose: () => alertInstance.dismiss()
    });
  }

  @HostListener('window:popstate')
  backNavigation() {
    if(this.stateVoluntaryService.confirmVoluntary) {
      this.stateVoluntaryService.getVoluntaryWithdrawalReverse();
    }
    this.close(Constants.FORM_CONTROLS.Cancel);
  }

   /**
   * Este método se encarga de realizar la descarga del comprobante de retiro de ahorro voluntario obteniendo los datos
   * desde el servicio de stateVoluntaryService y asignandolos AforeVoluntaryWithdrawalRequest
  */
  async downloadPdf() {
    try {
      const request = new AforeVoluntaryWithdrawalRequest({
        folio: this.stateVoluntaryService.voluntaryWithdrawalConfirmation,
        aportacion: this.subAccountsTypes.findIndex(subAccount => subAccount.descripcion === this.subAccount.descripcion) + Numbers.One,
        clienteInbursaAfore: this.stateVoluntaryService.aforeAssociation.clienteInbursaAfore,
        actividad: this.stateVoluntaryService.dataVoluntaryWithdrawal.occupation ===
          AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.PublicSectorCode ? Numbers.One : Numbers.Two,
        monto: this.stateVoluntaryService.dataVoluntaryWithdrawal.withdrawalAmount,
        tipoRetiro: this.stateVoluntaryService.dataVoluntaryWithdrawal.typeWithdrawal ===
        AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.PartialWithdrawalCode ? Numbers.Two : Numbers.One,
        cuentaClabe: this.stateVoluntaryService.voluntaryValidations.cuentaClabe
      });
      const response = await this.aforeService.getAforeVoucher<AforeVoluntaryWithdrawalRequest>(request);
      FileUtils.downloadPdf(response, this.completeDate );
    } catch (error) {
      this.alertError(error);
    }
  }
}
