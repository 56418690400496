<div class="position-relative"
     [class.input-error-border]="!!form.get(inputModel.name).errors &&
     !!form.get(inputModel.name).touched && showError">
  <img *ngIf="input?.image"
  class="input-icon cursor-pointer position-absolute top-50 translate-middle"
  [src]="input.image.src.includes('\/') ? input.image.src :
  ( input.imageError?.src && form.get(inputModel.name).touched  && form.get(inputModel.name).invalid ?
   (input.imageError?.src | cdnImage):
   (input.image.src | cdnImage))"
  (click)="input.image.clickFunction()"
  [alt]="input.image.alt" width="auto" height="auto">
  <label *ngIf="inputModel.label.length != 0" class="m-2 position-absolute pos-label-input label-input"
    [id]="inputModel.idLabel ?? ''">
    <span class="detail-s-highlight bg-white px-1"
    [class.disabled-btn] = "enable"
    [class.error]="!!form.get(inputModel.name).errors &&
     !!form.get(inputModel.name).touched && showError"
    >{{inputModel.label}}</span>
  </label>
  <ng-content></ng-content>
</div>
<div *ngFor="let validations of inputModel.validations">
  <div *ngIf="form.get(inputModel.name).touched  && form.get(inputModel.name).hasError(validations.name) && showError
    && (!hiddenErrorDisabled || (!enable && hiddenErrorDisabled))">
    <span class="detail-m">
      {{validations.message}}
    </span>
  </div>
</div>
