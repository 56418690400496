import { PathModel } from 'src/app/models/PathModel';

interface  ICancelBrokerageHouseThirdPartiesRequest{
  idSesion : string,
  idAgenda : number,
  TipoAgenda : string,
  nombreAplicacion : string,
  tipoAcceso : string,
  Token : {
    OTP : string
  }
}

export class CancelBrokerageHouseThirdPartiesRequest{
    constructor(public data: ICancelBrokerageHouseThirdPartiesRequest){}

    get endpoint(): PathModel {
      return PathModel.CancelOthersBanks;
  }
}
