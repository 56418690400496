import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductsService } from './products.service';
import { AccountType, Position } from 'src/core/constants/Constants';
 
@Injectable({
  providedIn: 'root'
})
export class WebCardActiveService {

  private readonly _webCardIsActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private webInfoCard: boolean = false;

  constructor(private readonly productsService: ProductsService) { }

  get webCardIsActive(): BehaviorSubject<boolean> {
    return this._webCardIsActive;
  }

  set webCardIsActiveData(value: boolean) {
    this._webCardIsActive.next(value);
  }

  setInfoWebCard(value: boolean): void {
    this.webInfoCard = value;
  }

  getInfoWebCard(): boolean {
    return this.webInfoCard;
  }

  async getWebAccount() {
    const accounts = await this.productsService.getAccounts({ reload: false });
    const webAccounts = accounts?.filter((account) => account.tipoCuenta === AccountType.WEB);
    return webAccounts?.length? webAccounts[Position.Zero]: null;
  }
}
