import { Injectable, WritableSignal, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
  providedIn: 'root'
})
export class TabsService {
  private readonly _tabChanged: WritableSignal<boolean> = signal<boolean>(false);
  private readonly _tabSelected: BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);
  private readonly _tabCustom: BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);

  get tabChanged(): boolean {
    return this._tabChanged();
  }

  set tabChanged(value: boolean) {
    this._tabChanged.update(() => value);
  }

  get tabSelected$(){
    return this._tabSelected.asObservable();
  }

  get tabSelected(){
    return this._tabSelected.value;
  }

  set tabSelected(data:string) {
    this._tabSelected.next(data);
  }

  get tabCustom$(){
    return this._tabCustom.asObservable();
  }

  get tabCustom(){
    return this._tabCustom.value;
  }

  set tabCustom(data:string) {
    this._tabCustom.next(data);
  }

}
