import { Constants} from 'src/core/constants/Constants';
import { DirectDebitAforeStrings } from 'src/core/constants/DirectDebitAforeStrings';
import { ModalConstants } from 'src/core/constants/ModalConstants';

export class DirectDebitAforeConstants {
    static readonly MAX_DATE = {
        year: 9999,
        month: new Date().getMonth() + Constants.MONTH.ONE,
        day: new Date().getDate()
    };

    static readonly FORM_CONTROLS_NAME ={
      endDate: 'endDate'
    };

    static readonly DECIMALS_AMOUNT= 2;
    static readonly MAX_LENGTH_AMOUNT= 11;

    static readonly EXTRA_SPACE_TABLE = 337;

    static readonly CUSTOM_FOLIO_RESPONSIVE ={
      customMobile: '(max-width: 327px)',
      customResponsive: '(max-width: 767.8px) and (min-width: 431px)',
      customDesktop: '(min-width: 1025px)'
    };

    static readonly DIRECT_DEBIT_AFORE_STATUS ={
      cancel: 'CANCELADA',
      accept: 'ACEPTADA'
    };

    static readonly TITLES_VOLUNTARY_SAVINGS = [
      {id: 1, title:'Folio'},
      {id: 2, title:'Monto'},
      {id: 3, title:'Fecha vigencia'},
      {id: 4, title:'Periodicidad'},
      {id: 5, title:'Tipo aportación'},
      {id: 6, title:'Estado'}
    ];

  static readonly DIRECT_DEBIT_AFORE_MODIFICATION_MOCK = {
    Confirm: {
      Title: 'Modificación de domiciliación',
      ConfirmTitle: 'Confirmación<br>de domiciliación'
    },
    name: 'accountOrigin',
    options: [
      {
        label: 'Nómina Efe ****3451 - Tarjeta Inbursa platino de lux ulta exclusiva',
        value: '1'
      },
      {
        label: 'Nómina Efe ****3452 - Tarjeta Inbursa platino de lux ulta exclusiva',
        value: '2'
      },
      {
        label: 'Nómina Efe ****3453 - Tarjeta Inbursa platino de lux ulta exclusiva',
        value: '3'
      },
      {
        label: 'Nómina Efe ****3454 - Tarjeta Inbursa platino de lux ulta exclusiva',
        value: '4'
      }
    ]
  };

  static readonly STYLES_BUTTONS = {
    BtnCancel: {
      Classes: 'btn-cancel btn-generic btn-voluntary-savings-cancel',
      ModalClass: ModalConstants.MODAL_OPTIONS.ModalCancelVoluntarySavingsCancel
    },
    ModalVoluntarySavingsClass: ModalConstants.MODAL_OPTIONS.ModalVoluntarySavingsClass,
    VoluntarySavingFrame: {
      padding: '1.5rem 1.5rem 0 1.5rem',
      borderRadius: '0.5rem',
      gap: '0'
    },
    ModalVoluntarySavingsModification: {
      FormButtons: {
        Continue: {
          ButtonClass: DirectDebitAforeStrings.DefaultButtonClass,
          ButtonLabel: 'Modificar y continuar'
        },
        Cancel: {
          ButtonClass: DirectDebitAforeStrings.DefaultButtonClass,
          ButtonLabel: 'Cancelar'
        }
      },
      ConfirmButtons: {
        Confirm: {
          ButtonClass: DirectDebitAforeStrings.DefaultButtonClass,
          ButtonLabel: 'Confirmar y continuar'
        },
        Cancel: {
          ButtonClass: DirectDebitAforeStrings.DefaultButtonClass,
          ButtonLabel: 'Modificar'
        }
      },
      CompleteButtons: {
        Done: {
          ButtonClass: DirectDebitAforeStrings.DefaultButtonClass,
          ButtonLabel: 'Listo'
        },
        Other: {
          ButtonClass: DirectDebitAforeStrings.DefaultButtonClass,
          ButtonLabel: 'Otra operación'
        }
      }
    },
    ConfirmVoluntaryContributions: 'mt-2-rem mb-5 px-3'
  };


  static readonly FORM_CONFIG = {
    TypeOfContribution: {
      name: 'typeOfContribution',
      label: 'Tipo de aportación',
      options: [
        {
          label: 'Aportaciones Voluntarias',
          value: 'voluntaries',
          id: 'AF07'
        },
        {
          label: 'Aportaciones Complementarias de Retiro',
          value: 'retirement',
          id: 'AF14'
        },
        {
          label: 'Aportaciones de Ahorro a Largo Plazo',
          value: 'longTerm',
          id: 'AF15'
        },
        {
          label: 'Aportaciones con Perspectiva de Inversión Largo Plazo',
          value: 'longTermInvestment',
          id: 'AF24'
        }
      ]
    },
    Deducible: {
      name: 'deducible',
      label: 'Deducible',
      options: [
        {
          label: 'Sí',
          value: '1'
        },
        {
          label: 'No',
          value: '0'
        }
      ]
    },
    Periodicity: {
      name: 'periodicity',
      label: 'Periodicidad de aportación',
      options: [
        {
          label: 'Semanal',
          value: 'weekly',
          id: 2
        },
        {
          label: 'Quincenal',
          value: 'biweekly',
          id: 3
        },
        {
          label: 'Mensual',
          value: 'monthly',
          id: 4
        }
      ]
    },
    StartDayForWeekly: {
      name: 'startDay',
      label: 'Día inicial',
      options: [
        {
          label: 'Lunes',
          value: 'monday',
          id: 1
        },
        {
          label: 'Martes',
          value: 'tuesday',
          id: 2
        },
        {
          label: 'Miércoles',
          value: 'wednesday',
          id: 3
        },
        {
          label: 'Jueves',
          value: 'thursday',
          id: 4
        },
        {
          label: 'Viernes',
          value: 'friday',
          id: 5
        }
      ]
    },
  };

    static readonly MOCK_CARD = {
      CardNumber: '****2007',
      CardDescription: 'Nómina Efe'
    };

    static readonly MAX_AMOUNT = 999999999.99;
    static readonly MIN_AMOUNT = 50;
    static readonly DATE_MSG = {
      EndDateAfterMinDate: 'La fecha de fin debe ser mayor a la fecha mínima permitida',
    };
}

export enum VoluntarySavingsTitleId {
  Identifier = 1,
  Amount = 2,
  Periodicity = 4,
  TypeOfContribution = 5,
  PeriodOfValidity = 3,
  Status = 6
}

export enum VoluntarySavingsModalType {
  Modify = 1,
  Confirm = 2,
  Complete = 3
}

export enum ModalType {
  Cancel = 'Cancel',
  Complete = 'Complete',
  Exception = 'Exception'
}
