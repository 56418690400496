<ng-template #content>
  <app-location-modal (modalCall)="closeModal()"></app-location-modal>
</ng-template>
<ng-container *ngIf="vm$ | async as vm">
<div>
  <form class=" container-fluid">
    <div>
      <ng-template #content>
        <app-location-modal (modalCall)="closeModal()"></app-location-modal>
      </ng-template>
      <label for="user" class="form-label header-xs pad mt-2 color-black"> Usuario </label>
      <input
        listenMayus
        [ngClass]="userError? 'border-red':''"
        (keydown)="inputUser($event)" type="text" class=" input mt-2"
        id="user" name="user" autofocus #user required autocomplete="off"
        (input)="userInputValue(user.value)">
      <div class="row  col-12">
        <span class="detail-m required" *ngIf="required"> {{message}}</span>
      </div>
      <label for="password" class="header-xs mt-3 color-black"> Contraseña </label>
      <app-show-input-value [input]="password" [inputTouched]="isTouched" [passwordError]="passwordError">
        <input listenMayus type="password" class=" input" [maxLength]="passwordMaxLength"
          id="password" name="password" #password required autocomplete="off"
          (focus)="isTouched=true"
          (blur)="isTouched=false"
          (input)=" passwordInputValue(password.value)">
      </app-show-input-value>
      <span class="detail-m required" *ngIf="passwordRequired"> {{message}} </span>
    </div>
    <app-footer-card-login-component></app-footer-card-login-component>
    <button id="button" class="btn-custom button-s mt-4 "
    (click)="onSubmitLogin(user.value, password.value)" [disabled]="vm.isBusy">
      <span class="btn-custom"> Continuar </span>
    </button>
    <button id="cancel-btn" class="outline-btn button-s mt-3 " (click)="close()">
      <span> Cancelar </span>
    </button>
  </form>
</div>
</ng-container>
