import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IOptionBank } from '../interface/IOptionBank';

@Injectable({
  providedIn: 'root'
})
export class ServiceOptionsBankService {

  private readonly optionBankService: BehaviorSubject<IOptionBank> = new BehaviorSubject<IOptionBank>({
    infoBank: '',
    purposePayment: '',
    isValid: false});

  constructor() { }

  get optionBankServiceObservable() {
    return this.optionBankService.asObservable();
  }

  set optionBankServiceData(data: IOptionBank) {
    this.optionBankService.next(data);
  }
  get optionBankServiceBS(): BehaviorSubject<IOptionBank> {
    return this.optionBankService;
  }

}
