<ng-container *ngIf="vm$ | async as vm">
  <div class="style-transaction d-flex justify-content-center align-items-center pt-2"
    *ngIf="hideDownload; else ShowingDownloadButton">
    <button class="pills" *ngIf="!vm.breakpoints.desktop"
      (click)="getTransactionPDF([true, false])" id="download">
      <img alt="shareLabel" height="20" width="20" [src]="shareImage | cdnImage" />
      <ng-container *ngIf="!vm.breakpoints.mobile">
        <label class="shared-label button-s" [innerHTML]="vm.breakpoints.desktop
            ? downloadLabel
            : shareLabel"></label>
      </ng-container>
    </button>
    <ng-container *ngIf="vm.breakpoints.desktop ">
      <button class="pills" (click)="showModalSendEmail()" id="email">
        <img [src]="mailImage | cdnImage" alt="Email" height="20" width="20" />
        <ng-container *ngIf="!vm.breakpoints.mobile">
          <label class="shared-label button-s" [innerHTML]="mailLabel"></label>
        </ng-container>
      </button>
    </ng-container>
  </div>

  <ng-template #ShowingDownloadButton>
    <div class="style-transaction d-flex justify-content-center align-items-center pt-2">
      <button class="pills" (click)="captureImage && vm.breakpoints.desktop? captureElements():
      shareCapture && !vm.breakpoints.desktop? shareElements():
      getTransactionPDF([true, false])" id="download">
        <img [attr.alt]="vm.breakpoints.desktop? downloadLabel: shareLabel" alt="shareLabel" height="20" width="20"
          [src]="vm.breakpoints.desktop? (fileDownloadingLc | cdnImage): (shareImage | cdnImage)" />
        <ng-container *ngIf="!vm.breakpoints.mobile">
          <label class="shared-label button-s" [innerHTML]="vm.breakpoints.desktop
              ? downloadLabel
              : shareLabel"></label>
        </ng-container>
      </button>
      <ng-container *ngIf="vm.breakpoints.desktop && !hideMail">
        <button class="pills" (click)="showModalSendEmail()" id="email">
          <img [src]="mailImage | cdnImage" alt="Email" height="20" width="20" />
          <ng-container *ngIf="!vm.breakpoints.mobile">
            <label class="shared-label button-s" [innerHTML]="mailLabel"></label>
          </ng-container>
        </button>
      </ng-container>
    </div>
  </ng-template>
</ng-container>
