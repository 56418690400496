import { Injectable, WritableSignal, signal } from '@angular/core';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private readonly _otp: WritableSignal<string> = signal<string>(Strings.EMPTY);

  constructor() {}

  get otpSignal() {
    return this._otp();
  }

  set otp(value: string) {
    this._otp.update(() => value);
  }

  reset() {
    this.otp = Strings.EMPTY;
  }
}
