import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Strings } from 'src/core/constants/Strings';
export interface AmountError {
  isError: boolean,
  errorMsg: string
}
@Injectable({
  providedIn: 'root'
})
export class EnterAmountErrorService {
  private readonly stateAmount: BehaviorSubject<AmountError> = new BehaviorSubject<AmountError>({
    isError: false,
    errorMsg: Strings.EMPTY
  });

  constructor() { }

  get amountErrorObservable() {
    return this.stateAmount.asObservable();
  }

  get currentValue(): AmountError {
    return this.stateAmount.getValue();
  }

  set amountErrorData(data: AmountError) {
    this.stateAmount.next(data);
  }
}
