<ng-container *ngIf="vm$ | async as vm">
  <div id="ModalContainerNonAgendableServices">
    <div class="d-flex justify-content-end pt-4 pe-4">
      <img height="24" width="24" role="button" class="close" aria-label="Close" (click)="close()"
        [src]="frameClose | cdnImage" alt="Close" />
    </div>
    <div class="pt-1 pb-4">
      <label for="searchInput" class="header-m">Otros servicios</label>
      <div class="d-flex align-items-center pt-3 pb-2 select-l">
        <div class="input-group">
          <input id="searchInput" #searchInput type="text" class="form-control" placeholder="Buscar"
            (input)="searchTerm(searchInput.value)" cleanInput [(ngModel)]="inputValue"
            [cleanRegex]="regexSearch"/>
          <app-reset-input-value [targetInput]="searchInput" (clickEmmiter)="searchTerm('')">
          </app-reset-input-value>
          <span class="input-group-text" id="basic-addon1">
            <img [src]="searchIcon | cdnImage" alt="Search" height="24" width="24" /></span>
        </div>
      </div>
      <div *ngIf="services$ | async as services" class="main-content pt-2 mb-2 justify-content-center">
        <div role="button" id="card" *ngFor="let service of services; let i = index"
          class="drop-shadow-m p-3 d-flex flex-column justify-content-between align-items-center"
          (click)="selectService(service, i)" [class.selected-card]="i === selectedService"
          [class.services-card-no-limit]="!serviceWithoutLimit(service.codigoServicio)">
          <div class="row margin">
            <div [ngClass]="{'col-10' :  vm.breakpoints.desktop, 'col-9' :  !vm.breakpoints.desktop}"
            class="padding-service">
              <div class="row">
                <div *ngIf="serviceWithoutImage(service.codigoServicio)" class="center-title-text">
                  <ng-container *ngIf="service.codigoServicio == serviceCodeSat; else AnotherSvc">
                    <div class="d-flex justify-content-center align-items-center">
                      <img width="auto" height="auto" [src]="satIcon | cdnImage"
                      [alt]="service.codigoServicio" class="service-image">
                      <span class="header-mm">{{ service.codigoServicio | serviceImage }}</span>
                    </div>
                  </ng-container>
                  <ng-template #AnotherSvc>
                    <div class="center-title-text"
                    [ngClass]="serviceWithoutLimit(service.codigoServicio)? '': 'name-service-content'">
                      <samp class="header-mm">{{service.codigoServicio | serviceImage}}</samp>
                    </div>
                  </ng-template>
                </div>
                <ng-container *ngIf="!serviceWithoutImage(service.codigoServicio)
                                    && (service.codigoServicio == serviceCodeFederalTax ||
                                    service.codigoServicio == serviceCodeSat); else AnotherService">
                  <div class="center-title-text d-flex justify-content-center align-items-center">
                    <img width="auto" height="auto" [src]="service.codigoServicio == serviceCodeFederalTax
                                        ? (service.codigoServicio | serviceImage) : (logoSat | cdnImage)"
                      [alt]="service.codigoServicio" class="service-image">
                    <span class="header-mm" *ngIf="service.codigoServicio == serviceCodeFederalTax">
                      {{labelPaymentSat}}</span>
                    <span class="header-mm" *ngIf="service.codigoServicio == serviceCodeSat">
                      {{labelPaymentReferenced}}</span>
                  </div>
                </ng-container>
                <ng-template #AnotherService>
                  <div class="img-content" *ngIf="!serviceWithoutImage(service.codigoServicio)">
                    <img width="auto" height="auto"
                      [src]="service.codigoServicio | serviceImage" [alt]="service.codigoServicio"
                      class="img-service" />
                  </div>
                </ng-template>
                <div class="col-12 justify-start pt-2" *ngIf="serviceWithoutLimit(service.codigoServicio)">
                  <label id="service-card-limit" class="detail-m only-line wrap">
                    Límite diario:&nbsp;</label>
                  <text-ellipsis [text]="(service.limiteDiario | currency)" class="detail-m" [styles]="styles"
                    [lines]="1"></text-ellipsis>
                </div>
                <div class="col-12 justify-start pt-2" *ngIf="serviceShowHistory(service.codigoServicio)">
                  <img id="service-file-image" width="auto" height="auto" [src]="fileBlue| cdnImage" [alt]="fileBlue" />
                  <a class="detail-s-highlight-hypertext link-class ps-2 cursor-pointer"
                  (click)="navigationHistory(service.codigoServicio); $event.stopPropagation();"> Ver Historial </a>
                </div>
                <div class="col-12 justify-start padding-top-btn"
                  *ngIf="service.codigoServicio != serviceCodePaggmm && service.codigoServicio != serviceCodePsegnal">
                  <button class="btn-delete btn-other-services"
                  *ngIf="service.codigoServicio != serviceCodePaggmm && service.codigoServicio != serviceCodePsegnal"
                  (click)="showModalModifyAccount(
                    service.descripcionServicio,
                    service.limiteDiario,
                    service.idAgenda,
                    service.codigoServicio,
                    service.tiempoEspera,
                    service.limiteDiario); $event.stopPropagation();">
                    <img [src]="editBlue | cdnImage" width="20px" height="20px" [alt]="editBlue" class="margin-img"/>
                    <span class="button-s font-size--03 span-delete bold-font"> Modificar </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="d-flex align-right-button align-items-center "
              [ngClass]="{'col-2' :  vm.breakpoints.desktop, 'col-3' :  !vm.breakpoints.desktop}">
              <ng-container>
                <img role="button" id="service-card-arrow" [src]="chevronRightBlueIcon | cdnImage"
                  alt="Arrow" height="24" width="24" />
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="px-5">
        <ng-container *ngIf="!vm.breakpoints.mobile">
          <app-pagination [showWithoutResultsPadding]="false" withoutResultsClasses="pb-4 px-3"></app-pagination>
        </ng-container>
        <div *ngIf="false" class="d-flex justify-content-end align-items-center pt-2">
          <button class="colored-btn button-m" (click)="select()">
            Seleccionar
            <img [src]="chevronRight | cdnImage" alt="Arrow" width="24" height="24" />
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
