import { PathModel } from 'src/app/models/PathModel';

type IVariableInvestmentFundsRequest = {
  cuenta: string;
  idCuentaProducto: number;
  cantidad: number;
  concepto: string;
};

export class VariableInvestmentFundsRequest{
  constructor (public data:IVariableInvestmentFundsRequest ){}

  get endpoint(): PathModel {
    return PathModel.VariableInvestmentFunds;
}
}
