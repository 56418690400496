import { PathModel } from 'src/app/models/PathModel';

interface IPaymentAgendableServicesRequest {
  idSesion : string;
  IdAdicional? : string;
  IdPersonaTitular? : string;
}

export class PaymentAgendableServicesRequest{
  constructor(public data: IPaymentAgendableServicesRequest){}

  get endpoint ():PathModel{
    return PathModel.PaymentScheduledServices;
  }
}

