<div class=" container " ngbAutofocus>  
  <ng-container *ngIf="vm$ | async as breakPoints">
    <div class="row mt-4" [ngClass]="breakPoints.tablet == true ? 'response-mobile-modal-help-token':''">
      <div [ngClass]="!breakPoints.mobile && !breakPoints.tablet ? 'col-6':''">
        <div class="row">
          <div class="col-2">
            <span class="bc-circle-help-token">
              <span class=" color-font" [ngClass]="breakPoints.mobile ? 'header-xs':'header-m'"> 1</span>
            </span>
          </div>
          <div class="col-10">
            <div class="d-flex justify-content-start">
                <samp class="title-one text-secondary-800" 
                [ngClass]="breakPoints.mobile ? 'header-xs':'header-s'">{{headTit1}}</samp>
            </div>
          </div>
        </div>
        <div class="row" [class.mt-5]="breakPoints.desktop" [class.mt-4]="!breakPoints.desktop">
          <div class="col-12 img-center">
            <img class="help-image" [src]="modalHelpToken1 | cdnImage" width="auto" height="auto" alt="Ayuda Token">
          </div>
        </div>
      </div>
      <div class="margin-card" [ngClass]="!breakPoints.mobile && !breakPoints.tablet ? 'col-6':''">
        <div class="row">
          <div class="col-2">
            <span class="bc-circle-help-token">
              <span class="color-font" [ngClass]="breakPoints.mobile ? 'header-xs':'header-m'"> 2</span>
            </span>
          </div>
          <div class="col-10">
            <div class="d-flex justify-content-start text-secondary-800">
              <samp [ngClass]="breakPoints.mobile ? 'header-xs':'header-s'">{{headTit2}}</samp>
            </div>
          </div>
        </div>
        <div class="row" [class.mt-5]="breakPoints.desktop" [class.mt-4]="!breakPoints.desktop">
          <div class="col-12 img-center">
            <img class="help-image" [src]="modalHelpToken2 | cdnImage" width="auto" height="auto" alt="Ayuda Token">
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="row mt-btn">
    <div class="col-sm-12 d-flex justify-content-center">
      <button class="btn-continue-help-token" (click)="close()">
        <span class="button-m color-font"> Aceptar</span>
      </button>
    </div>
  </div>
</div>
