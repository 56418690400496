<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngTemplateOutlet="currentTemplate$?.value ?? confirm "></ng-container>
</ng-container>
<ng-template #Confirm>
  <div class="modal-cancel-content">
    <div class="d-flex justify-content-center flex-column">
      <div class="d-flex justify-content-center mb-3 mt-3">
        <img [src]="questionMark | cdnImage" alt="image" width="56" height="56">
      </div>
      <div class="d-flex justify-content-center text-center content-title header-s color-labels">
        {{ title.confirm }}
      </div>
    </div>
    <div class="mt-2 text-center">
      <div class="detail-m color-labels">{{modalLabels.Title}}</div>
      <div class="detail-m color-labels">{{modalLabels.Reference + data.referencia}}</div>
      <div class="detail-m color-labels">{{modalLabels.Transaction + data.numeroOperacion}}</div>
      <div class="detail-m color-labels">{{modalLabels.Amount}} {{data.importe| currency}}</div>
      <div class="detail-m color-labels">{{modalLabels.Status + data.estatus}}</div>
    </div>
    <div class="d-flex justify-content-center gap-4 my-3">
      <app-template-primary-button class="template-yes" [properties]="primaryProperties">
        {{labelButtonYes}}
      </app-template-primary-button>
      <app-template-secondary-button class="template-no" [properties]="secondaryProperties">
        {{labelButtonNo}}
      </app-template-secondary-button>
    </div>
  </div>
</ng-template>
<ng-template #Complete>
  <ng-container *ngIf="vm$ | async as vm">
    <div class="px-3">
      <div class="d-flex justify-content-center flex-column">
        <div class="d-flex justify-content-center mb-3 mt-3">
          <img [src]="check | cdnImage" class="icon-check" alt="image" width="auto" height="auto">
        </div>
        <div class="text-center">
          <div class="header-m color-labels">
            {{ title.complete }}
          </div>
          <div class="body-m color-labels">{{labelInvoice}} {{invoice}}</div>
          <div class="detail-m color-labels">{{completeDate}}</div>
          <div class="my-2 color-labels" [ngClass]="vm.mobile ? 'body-xs':'body-s'">
            {{labelCancellation}}
          </div>
        </div>
        <div class="d-flex justify-content-center mt-2 mb-3">
          <app-template-primary-button class="template-cancel-ok" [properties]="acceptButton">
            {{labelButtonAccept}}
          </app-template-primary-button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
