import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICreditPayment } from '../interface/dto/ICreditPayment';

@Injectable({
  providedIn: 'root'
})
export class ServiceCreditPaymentService {

  private readonly creditPayment: BehaviorSubject<ICreditPayment> = new BehaviorSubject<ICreditPayment>({ creditPayment: '', isError: false});

  constructor() { }

  get serviceCreditPaymentObservable(): Observable<ICreditPayment> {
    return this.creditPayment.asObservable();
  }

  set serviceCreditPaymentData(data: ICreditPayment) {
    this.creditPayment.next(data);
  }

  get serviceCreditPaymentBS(): BehaviorSubject<ICreditPayment> {
    return this.creditPayment;
  }
}
