import { Component, Input } from '@angular/core';
import { InputTypes } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';

@Component({
  selector: 'app-show-input-value',
  templateUrl: './show-input-value.component.html',
  styleUrls: ['./show-input-value.component.css']
})
export class ShowInputValueComponent {
  @Input() input: HTMLInputElement;
  @Input() inputTouched: boolean;
  @Input() passwordError:boolean;
  show: boolean = false;
  eye: string = Resources.EYE_BLUE;
  eyeOff: string = Resources.EYE_BLUE_OFF;

  constructor() {}

  showValue() {
    this.show = !this.show;
    this.input.type = this.show ? InputTypes.Text : InputTypes.Password;
  }
}
