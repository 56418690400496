import { Component, OnInit } from '@angular/core';
import { Resources } from 'src/core/constants/Resources';
import { Char, Constants } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { DataCheckbookService } from 'src/app/services/data-checkbook.service';
import { ModalOptions } from 'src/app/interface/modal-options';
import { OtpCardReplacementComponent } from 'src/app/shared/components/otp-card-replacement/otp-card-replacement.component';
import { ModalChecksRequestDataComponent } from 'src/app/component/modal-checks-request-data/modal-checks-request-data.component';

@Component({
  selector: 'app-modal-checks-request-confirmdata',
  templateUrl: './modal-checks-request-confirmdata.component.html',
  styleUrls: ['./modal-checks-request-confirmdata.component.css']
})
export class ModalChecksRequestConfirmDataComponent implements OnInit {
  removeOperation: string = Resources.CLOSE_CIRCLE_BLUE;
  chevronRight: string = Resources.BTN_CHEVRON_RIGHT;
  breakpoints$ = this.responsiveService.observe;
  completeDate!: string;
  widthTablet: number = Constants.RESOLUTIONS.IpadMinWidth;
  vm$ = this.responsiveService.observe;
  showBranch: boolean = false;
  accountName: string = Strings.EMPTY;
  accountNumber: string = Strings.EMPTY;
  numberCheckBook: string = Strings.EMPTY;
  typeCheckBook: string = Strings.EMPTY;
  branch: string = Strings.EMPTY;
  isApple: boolean = false;
  constructor(private readonly modalService: ModalService,
    private readonly responsiveService: ResponsiveService,
    private readonly dataCheckBookService: DataCheckbookService,
    private readonly accountInfoStatusService: AccountInfoStatusService) { }

  ngOnInit(): void {
    this.isTabletMobile();
    this.accountNumber = this.accountInfoStatusService.account.numeroCuenta;
    this.accountName = this.accountInfoStatusService.account.descripcionCuenta;
    this.branch = this.dataCheckBookService.directionsData.name;
    this.dataCheckBookService.branchs.subscribe((value) => {
      this.accountNumber = `${value.accountNumber} ${value.accountName}`;
      this.accountName = value.accountName;
      this.numberCheckBook = `${value.numberCheckBook}${Char.MiddleDash} ${value.typeCheckBook}`;
      this.typeCheckBook = value.typeCheckBook;
    });
    this.dataCheckBookService.data.subscribe((value) => {
      this.branch = value.address;
    });
  }

  close() { this.modalService.close(); }
  confirmContinue() {
    this.dataCheckBookService.statusConfirmOTP = true;
    this.modalService.close();
    const modalOptions: ModalOptions = {
      centered: false,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.OtpChecksConfirmation
    };
    this.modalService.open(OtpCardReplacementComponent, modalOptions);
  }

  modify() {
    this.modalService.close();
    const modalOptions: ModalOptions = {
      centered: false,
      size: Constants.MODAL_OPTIONS.SizeSm,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.GenericClass
    };
    this.modalService.open(ModalChecksRequestDataComponent, modalOptions);
  }
  isTabletMobile() {
    const agent = window.navigator.userAgent.toLowerCase();
    if ((window.screen.width <= this.widthTablet && agent.match(Constants.USER_AGENT.Mac)) || (agent.match(Constants.USER_AGENT.Mobile))) {
      this.isApple = true;
    } else {
      this.isApple = false;
    }
  }
}
