<ng-container *ngIf="vm$ | async as vm">
  <input type="text" hidden>
  <section class="p-4">
    <header
      [class.header-xs]="vm.breakpoints.mobile"
      [class.header-s]="!vm.breakpoints.mobile"
    >
      Condiciones de Aportaciones de Ahorro Voluntario
    </header>
    <main class="mt-4" [class.pt-2]="vm.breakpoints.desktop">
      <p
        [class.header-s]="vm.breakpoints.mobile"
        [class.header-m]="!vm.breakpoints.mobile"
        class="text-info-800 text-center"
      >
        Tipo de Ahorro Voluntario
      </p>
      <div class="divider w-100"></div>
      <div class="scrollable moz-scroll">
        <table class="w-100">
          <caption></caption>
          <tr class="d-flex">
            <th id="0" class="text-secondary-600 detail-m-highlight"></th>
            <th id="1" class="text-secondary-600 detail-m-highlight text-center">
              Aportaciones Voluntarias no Deducibles (AV)*
            </th>
            <th id="2" class="text-secondary-600 detail-m-highlight text-center">
              Aportaciones de Ahorro a Largo Plazo (ALP)
            </th>
            <th id="3" class="text-secondary-600 detail-m-highlight text-center">
              Aportaciones Voluntarias Deducibles (AVLP)**
            </th>
            <th id="4" class="text-secondary-600 detail-m-highlight text-center">
              Aportaciones Complementarias (ACR)
            </th>
          </tr>
          <tbody>
            <tr class="d-flex" *ngFor="let data of tableData; let i = index">
              <td
                [class.justify-content-start]="i === tableData.length - 1"
                class="detail-m-highlight text-secondary-800"
              >
                {{ data[0] }}
              </td>
              <td
                [class.text-center]="i === tableData.length - 1"
                class="detail-m-highlight text-info-800"
              >
                {{ data[1] }}
              </td>
              <td
                [class.text-center]="i === tableData.length - 1"
                class="detail-m text-secondary-800"
              >
                {{ data[2] }}
              </td>
              <td
                [class.text-center]="i === tableData.length - 1"
                class="detail-m text-secondary-800"
              >
                {{ data[3] }}
              </td>
              <td
                [class.text-center]="i === tableData.length - 1"
                class="detail-m text-secondary-800"
              >
                {{ data[4] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
    <footer class="d-flex justify-content-center mt-3">
      <button class="modals-btn colored-btn button-s" (click)="close()">
        Cerrar
      </button>
    </footer>
  </section>
</ng-container>
