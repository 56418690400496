import { PathModel } from 'src/app/models/PathModel';

export type TSuaGetPDFRequest = {
  idSesion: string,
  idSuaPago: number
};

export class SuaGetPDFRequest {
  constructor(public data: TSuaGetPDFRequest) {}

  get endpoint(): PathModel {
    return PathModel.SuaGetReceipt;
  }
}
