export class FormatDate {
  static readonly VALUE_DATA = 'data';
}
export enum Format {
  FormatDayMonthYear = 'dd/Month/yyyy',
  BasicFormatDayMonthYear = 'dd/MMMM/yyyy',
  DayMonthYearCompleteH = "dd MMMM y, HH:mm:ss'h'",
  DayMonthYearISO = 'dd/MMMM/YYYY',
  YearMonthDayTime= 'yyyyMMddhhmmss'
}

export enum MonthNumber {
  enero = 1,
  febrero = 2,
  marzo = 3,
  abril = 4,
  mayo = 5,
  junio = 6,
  julio = 7,
  agosto = 8,
  septiembre = 9,
  octubre = 10,
  noviembre = 11,
  diciembre = 12
}
