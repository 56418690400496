export class DomiciliationStrings {
  static readonly ASSOCIATED_CARD_TOOLTIP = 'Tarjeta asociada a la cuenta elegida';
  static readonly ACCOUNT = 'account';
  static readonly DOMITELM_TELMEX = 'DOMITELM - TELMEX';
  static readonly TITLE = 'Domiciliación';
  static readonly SUBTITLE = 'Visualiza las operaciones domiciliadas';
  static readonly STYLES = {
    VoluntarySavingFrame: {
      padding: '-0.5rem 1.5rem 0 1.5rem',
      borderRadius: '0.5rem',
      gap: '0',
    },
    saveImageContainer: 'saveImageContainer'
  };

  static readonly DOMICILIATION_REGISTER = {
      Title: 'Alta de domiciliación',
      SubTitle: 'Confirmación de la domiciliación',
      Amount: 'Monto máximo',
      ServiceLabel: 'Servicio',
      serviceOwner: 'Titular del servicio',
      whichCardToPay: '¿Qué tarjeta quieres pagar?',
      selectOwnCard: 'Selecciona tu tarjeta',
      enterCardNumber: 'Ingresa el número de tarjeta',
      domiciliationType: 'Tipo de Domiciliación',
      paymentOptions: ['Pago Mínimo', 'Pago Total', 'Monto Fijo'],
      authorizationExpiryDate: 'Esta autorización tiene fecha de vencimiento',
      expiryDate: 'Fecha de vencimiento',
      domiciliationDurationTypes: ['Indeterminada', 'Fija'],
      enterDate: 'Introduzca la fecha',
      FromYourAccount: 'Desde tu cuenta',
      TermsConditions: 'Términos y condiciones de la domiciliación del pago del servicio CFE mediante cargo automático a tu cuenta.',
      TermsConditionsStart: 'Términos y condiciones de la domiciliación del pago del servicio () cargo automático a tu cuenta.',
      TermsConditionsTelcel: 'Términos y condiciones de la domiciliación del pago del servicio telefónico mediante cargo automático a tu cuenta.',
      MainTermsConditions: 'Estoy enterado de que en cualquier momento podré pedir al banco que me lleva la cuenta, que cancele sin costo'+
      ' a mi cargo la domiciliación solicitada.',
      ServiceCfe: 'DOMICFE - CFE',
      ServiceTelcel: 'DOMITELC - TELCEL',
      ServiceTDC: 'TARJETA DE CRÉDITO',
      isCreditCardPayment: 'isCreditCardPayment',
      domiciliationcompleted: 'domiciliationcompleted',
      nameFirstCheckbox: 'checkboxValue',
      nameSecondCheckbox: 'checkboxValueOne',
      SelectTypeAccount: ['Propia', 'De un tercero'],
      ConditionsTelephone: `
          <strong>El Cliente en este acto en términos del segundo párrafo del artículo 57 de la Ley de
          Instituciones de Crédito vigente, instruye y autoriza expresamente al Banco, se sirva pagar
          con cargo a la cuenta de cheques cuyo número se señala en el anverso de este documento a
          Teléfonos de México, S.A. de C.V. ("Telmex") el importe correspondiente a los montos facturados
          en el recibo telefónico del número telefónico señalado en la parte posterior de este instrumento,
          de conformidad con lo siguiente (la "Autorización"). </strong><br></br>
          - En caso de que el CLIENTE entregue al BANCO su Autorización entre los días 1ero. y 23 del mes "n",
          el cargo automático en la cuenta del CLIENTE se verá reflejado en el período del día 8 al 23 del mes "
          n+1" y la aplicación del importe de su Recibo Telefónico y leyenda "IMPORTE ENVIADO A COBRO EN SU BANCO"
          se reflejará en el Recibo Telefónico mes "n+2". EL CLIENTE pagará en la forma tradicional el Recibo
          Telefónico del mes "n" si entregó la autorización en el período referido en el párrafo anterior. <br></br>
          - En caso de que el CLIENTE entregue al BANCO la Autorización entre los días 24 y 31 del mes, el cargo
          automático en la cuenta del CLIENTE se verá reflejado en el período del 8 al 23 del mes "n+2" y la
          aplicación del importe de su Recibo Telefónico y leyenda "IMPORTE ENVIADO A COBRO EN SU BANCO" se
          reflejará en el Recibo Telefónico del mes "n+3". EL CLIENTE pagará en la forma tradicional los recibos
          telefónicos del mes "n" y "n+1" si entregó la autorización en el período referido en el párrafo anterior. <br></br>
          - En caso de que la autorización se realice en sábado, domingo ó días festivos, se debe considerar
          el día hábil inmediato para establecer la fecha del próximo cargo. REQUISITOS PARA REALIZAR EL CARGO
          AUTOMÁTICO. - Es necesario tener fondos suficientes en la cuenta para cubrir el pago total del servicio
          telefónico de Telmex en el momento que el Banco realice el cargo. <br></br>
          - El cargo se aplicará el día 08 de cada mes las primeras horas del día, si por algún motivo ese día no
          hay saldo suficiente en la cuenta se seguirán realizando intentos de cargo diariamente en el mismo horario
          hasta completar 10 días hábiles. El cargo se realizará únicamente en una ocasión. <br></br>
          - En caso de que en el último intento de aplicación de cargo, es decir, el último día hábil, la cuenta no
          tuviese fondos suficientes para cubrir el IMPORTE TOTAL del recibo telefónico, el Banco no tendrá
          responsabilidad alguna para cubrir el pago, por lo que el Cliente tendrá que pagar su recibo en la forma
          tradicional a más tardar el día 25 de ese mes. Si el día 25 es sábado, domingo ó día festivo el Cliente
          deberá pagar el día inmediato anterior. El siguiente recibo se cargará a su cuenta de forma automática a
          menos que el importe a pagar sea mayor al límite máximo establecido. <br></br>
          - En caso de que el Cliente no cuente con fondos suficientes en su cuenta para el pago del recibo
          telefónico durante 3 meses consecutivos, la Autorización se tendrá por revocada automáticamente y sin
          responsabilidad para el BANCO. CARGOS OBJETADOS. <br></br>
          - El Cliente cuenta con período de 90 días naturales a partir de la fecha en que se haya cargado en su
          cuenta el pago de Telmex para objetar cualquier cargo, lo cual deberá hacerse en cualquier sucursal del
          Banco, en cuyo caso éste último en términos del Art. 57de la Ley de Instituciones de Crédito vigente le
          abonará en su cuenta la totalidad del cargo objetado a más tardar el día hábil bancario inmediato siguiente
          aquel en que el Cliente hubiese efectuado la objeción y/o rechazo del cargo; en este caso el pago a
          Telmex será anulado. COMISIONES. <br></br>
          - El Cliente no pagará comisión alguna por concepto de pago de cada recibo cargado automáticamente a
          su cuenta, ni por los intentos de cargo no exitosos. No obstante lo anterior, el Banco podrá cobrar
          una comisión por éste concepto previo aviso en términos del Contrato celebrado con el Banco. COMPROBANTE
          FISCAL. <br></br>
          - El comprobante fiscal será el recibo telefónico y el estado de cuenta donde se refleje el cargo.
          LíMITE MÁXIMO A PAGAR. El Cliente en este acto señala como límite de importe máximo a cargar en su
          cuenta el importe señalado en el anverso del presente documento. <br></br>
          - En caso de que el importe del cargo indicado por TELMEX sea mayor al límite del importe máximo
          establecido por el Cliente, el Banco no realizará ningún cargo automático, por lo que el Cliente
          tendrá que realizar el PAGO TOTAL de su recibo telefónico en la forma tradicional a más tardar el
          día 25 de ese mes. Si el día 25 es sábado, domingo ó día festivo, se deberá pagar el día inmediato
          anterior. El siguiente recibo se cargará a la cuenta del Cliente de forma automática a menos que el
          importe a pagar sea mayor al límite máximo establecido. <br></br>
          - Los cambios en el límite máximo de cargo se pueden realizar en las sucursales del Banco. CAUSAS
          DE RECHAZO.
    `,
      ConditionsCfe: `
          <strong>El Cliente en este acto en términos del segundo párrafo del artículo 57 de la Ley de Instituciones
          de Crédito vigente, instruye y autoriza expresamente al Banco, se sirva pagar con cargo a la cuenta de
          cheques cuyo número se señala en el anverso de este documento a la Comisión Federal de Electricidad
          (“CFE”) el importe correspondiente a los montos facturados en el recibo de suministro de energía
          eléctrica, de conformidad con lo siguiente (la "Autorización").</strong> <br></br>
          - Cuando el CLIENTE entregue al BANCO la Autorización, el cargo automático en la cuenta del CLIENTE se
          verá reflejado en el próximo periodo de Pago el cual puede ser bimestral o mensual según el contrato
          establecido entre Cliente y CFE y la aplicación del importe de su Aviso-Recibo de energía eléctrica
          y leyenda "IMPORTE ENVIADO A COBRO EN SU BANCO" se reflejará en el Aviso-Recibo de energía eléctrica
          de la siguiente facturación. EL CLIENTE pagará en la forma tradicional el Aviso-Recibo de energía
          eléctrica del mes o bimestre si entregó la autorización en el período en curso. <br></br>
          - En caso de que la autorización se realice en sábado, domingo o días festivos, se debe considerar
          el día hábil bancario inmediato para establecer la fecha del próximo cargo. <br></br>
          <strong>REQUISITOS PARA REALIZAR EL CARGO AUTOMÁTICO. </strong><br></br>
          - Es necesario tener fondos suficientes en la cuenta para cubrir el pago total del Aviso-Recibo de
          energía eléctrica en el momento que el Banco realice el cargo. <br></br>
          - El cargo se intentará aplicar 3 (tres) días antes de la fecha de vencimiento del Aviso-Recibo de
          energía eléctrica, si por algún motivo el primer día no hay saldo suficiente en la cuenta se seguirán
          realizando intentos de cargo diariamente en el mismo horario hasta la fecha de vencimiento. El cargo se
          realizará únicamente en una ocasión. <br></br>
          - En caso de que en el último intento de aplicación de cargo, la cuenta no tuviese fondos suficientes
          para cubrir el IMPORTE TOTAL del Aviso-Recibo de energía eléctrica, el Banco no tendrá responsabilidad
          alguna para cubrir el pago, por lo que el Cliente tendrá que pagar su Aviso-Recibo de energía eléctrica
          en la forma tradicional. El importe del siguiente Aviso-Recibo de energía eléctrica se cargará a su
          cuenta de forma automática a menos que el importe a pagar sea mayor al límite máximo establecido o no
          haya saldo suficiente en la cuenta. <br></br>
          - En caso de que el Cliente no cuente con fondos suficientes en su cuenta para el pago del Aviso-Recibo
          de energía eléctrica durante 3 (tres) meses consecutivos, la Autorización se tendrá por revocada
          automáticamente y sin responsabilidad para el BANCO. CARGOS OBJETADOS. <br></br>
          - El Cliente cuenta con un período de 90 (noventa) días naturales contados a partir del último día
          del periodo del estado de cuenta en donde aparezca el cargo materia de la objeción para objetar
          cualquier cargo, lo cual deberá hacerse en cualquier sucursal del Banco, en términos de la Circular
          23/2009 de Banco de México cuando dicha objeción se realice durante los primeros 60 (sesenta) días
          naturales del plazo antes señalado, el Banco le abonará en su cuenta la totalidad del cargo reclamado
          a más tardar el día hábil bancario inmediato siguiente a la recepción de la objeción, en este caso el
          pago a CFE será anulado, por lo que el Banco no tendrá responsabilidad alguna ante el Cliente por la
          anulación del pago, y en caso de reclamación de la CFE, el cliente deberá de responder. COMISIONES. <br></br>
          - El Cliente no pagará comisión alguna por concepto de pago de cada Aviso-Recibo de energía eléctrica
          cargado automáticamente a su cuenta, ni por los intentos de cargo no exitosos. No obstante lo anterior,
          el Banco podrá cobrar una comisión por éste concepto previo aviso en términos del Contrato celebrado con
          el Banco. COMPROBANTE FISCAL. <br></br>
          - El comprobante fiscal será el Aviso-Recibo de energía eléctrica y el estado de cuenta donde se
          refleje el cargo. LÍMITE MÁXIMO A PAGAR. El Cliente en este acto señala como límite de importe máximo
          a cargar en su cuenta el importe señalado en el anverso del presente documento. <br></br>
          - En caso de que el importe del cargo indicado por CFE sea mayor al límite del importe máximo
          establecido por el Cliente, el Banco no realizará ningún cargo automático, por lo que el Cliente
          tendrá que realizar el PAGO TOTAL de su Aviso-Recibo de energía eléctrica en la forma tradicional
          a más la fecha de vencimiento del Aviso-Recibo de energía eléctrica. El siguiente recibo se cargará
          a la cuenta del Cliente de forma automática a menos que el importe a pagar sea mayor al límite máximo
          establecido o no haya saldo suficiente en la cuenta. <br></br>
          - Los cambios en el límite máximo de cargo se pueden realizar en las sucursales del Banco. CAUSAS DE
          RECHAZO. <br></br>
    `,
    From: 'Desde tu cuenta',
    EmailError: 'Email inválido',
    Mobile: 'mobile',
    Tablet: 'tablet',
    Desktop: 'desktop',
    Reference: 'Folio: 123456789',
    NumberServiceError: 'El número de servicio debe contener 12 o 27 dígitos',
    NoAccountsAvailable: 'No cuentas con tarjetas disponibles'
  };
  static readonly minLengthRequired = (minLength: number) => `Longitud mínima requerida de ${minLength} caracteres`;
  static readonly TYPE_PRODUCT = {
    TDD: 'TDD',
    TDC: 'TDC'
  };
  static readonly PERMITTED_SERVICE = {
    TELMEXD: 'TELMEXD',
    DOMICFE: 'DOMICFE'
  };
  static readonly NO_DIRECT_DEBIT = 'Aún no tienes afiliaciones dadas de alta';
  static readonly TELMEX = 'TELMEX';
  static readonly TELCEL = 'TELCEL';
  static readonly CFE = 'CFE';
  static readonly MESSAGE_DIGITS = 'Debe de contener 16 dígitos';
  static readonly TYPE_DOMICILIATION = {
    permanent: 'Fija',
    term: 'Fijo',
    owner: 'Propia'
  };
  static readonly FORM_CONTROL_NAME = {
    email: 'email',
    service: 'numberService',
    phone: 'numberTelephone',
    headline: 'headline'
  };

  static readonly NOT_FIND_DATA ={
    folioService:'folioService',
    email: 'email',
    isDirectDebitServiceOwnCreditCard:'isDirectDebitServiceOwnCreditCard'
  };

  static readonly EXCEPTIONS = {
    Length: 'Mínimo 2 caracteres'
  };

  static readonly LABELS = {
    MainTitle: 'Operaciones domiciliadas',
    HighButton: 'Alta',
    NoService: 'Número de servicio',
    InformationOperation: 'Información de la operación',
    Headline: 'Titular del servicio',
    CreditCard: 'Tarjeta de crédito a domiciliar',
    Type: 'Tipo domiciliación',
    Date: 'Fecha de vencimiento',
    Success: 'Alta de domiciliación exitosa',
    HighCard: 'Carta Alta'
  };

  static readonly CHANGE_CARD = 'Cambiar de tarjeta';
  static readonly SELECT_CARD = 'Selecciona una tarjeta';
  static readonly NAME_FILE = 'Domiciliación';
  static readonly WITHOUT_OWN_CARDS = 'No pudimos obtener tus tarjetas de crédito. Por favor, intenta más tarde';
  static readonly WITHOUT_PRODUCTS = 'No cuentas con productos para realizar esta operación.';

  static readonly TOOLTIP_TELCEL = 'Contrato a domiciliar';
  static readonly TOOLTIP_TELMEX = 'Teléfono del servicio a domiciliar';
  static readonly HEADER_TELCEL = 'Número de contrato';
  static readonly HEADER_TELMEX = 'Número de teléfono';
}
