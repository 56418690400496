import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  WritableSignal,
  effect,
  signal,
  untracked
} from '@angular/core';
import { AdditionalService } from 'src/app/services/additional-cards.service';
import { map } from 'rxjs';
import {
  DataCheckbookService,
  Directions
} from 'src/app/services/data-checkbook.service';
import { Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Numbers as NumberConstant } from 'src/core/constants/Numbers';
import { MapService, PickerOptions } from 'src/app/services/map.service';
import { StateCardNumberInfoService } from 'src/app/services/state-card-number-info.service';
import { ControlCardConstants } from 'src/core/constants/ControlCardConstants';

@Component({
  selector: 'app-card-branches',
  templateUrl: './card-branches.component.html',
  styleUrls: ['./card-branches.component.css']
})
export class CardBranchesComponent implements OnInit, AfterViewInit {
  @Input() directions: WritableSignal<Array<Directions>> = signal([]);
  @Output() index: EventEmitter<number> = new EventEmitter<number>();
  selectedId: WritableSignal<PickerOptions> = this.mapService.selectedIdSignal;
  dataCheckBook: Directions;
  sliceLength: number;
  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => {
      this.sliceLength = breakpoints.mobile ? Numbers.Fifty : NumberConstant.Eighty;
      return { breakpoints };
    })
  );
  constructor(
    private readonly dataCheckbook: DataCheckbookService,
    private readonly additionalCard:AdditionalService,
    private readonly responsiveService: ResponsiveService,
    private readonly mapService: MapService,
    private readonly cardNumberInfoService: StateCardNumberInfoService
  ) {
    effect(() => {
      untracked(() => {
        const direction = this.mapService.directionCopySignal()[this.mapService.selectedIdSignal().id];
        this.dataCheckBook = {
          address: direction?.address,
          phone: direction?.phone,
          id: direction?.id,
          name: direction?.name
        };
        this.dataCheckbook.directionsData = this.dataCheckBook;
        this.additionalCard.addressAdditional = direction.address;
        this.additionalCard.addressIdAdditional = Number(direction.id);
      });
      if (this.mapService.selectedIdSignal().scroll) {
        this.scrollSelectedCardIntoView();
      }
    });
  }

  ngAfterViewInit(): void {
    Utils.enableSlide(Strings.CARD_BRANCHES.ScrollProperty);
  }

  ngOnInit(): void {
    this.additionalCard.addressAdditional = this.directions()[this.mapService.selectedIdSignal().id]?.address;
    this.cardNumberInfoService.idBranch = this.directions()[this.mapService.selectedIdSignal().id]?.id;
    this.additionalCard.addressIdAdditional = Number(this.directions()[this.mapService.selectedIdSignal().id]?.id);
  }

  selectCard(id: number, idAddress?: string) {
    this.cardNumberInfoService.idBranch = idAddress;
    this.mapService.selectedId = { id, scroll: false };
    this.index.emit(id);
  }

  scrollSelectedCardIntoView() {
    const selectedCard = document.getElementById(this.mapService.selectedIdSignal().id.toString());
    if (selectedCard && selectedCard.parentElement) {
      this.cardNumberInfoService.idBranch = this.directions()[this.mapService.selectedIdSignal().id]?.id;
      this.additionalCard.addressAdditional = this.directions()[this.mapService.selectedIdSignal().id]?.address;
     selectedCard.parentElement.scrollLeft = selectedCard.offsetLeft;
    }
  }
}
