import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SidebarService } from '../../sidebar.service';
import { ElementsHTML } from 'src/core/constants/Constants';

@Component({
  selector: 'content-wrapper',
  templateUrl: './content-wrapper.component.html',
  styleUrls: ['./content-wrapper.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentWrapperComponent {
  @Input() contentPadding?: string = ElementsHTML.DefaultPadding;
  @Input() contentMargin?: string = ElementsHTML.BasicContentDesktopMargin;
  @Input() useFlex?: boolean = false;

  sidebarState$ = this.sidebarService.stateObservable;

  constructor(private readonly sidebarService: SidebarService) {}
}
