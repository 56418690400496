import { Injectable } from '@angular/core';
import { ModalMyCardsComponent } from 'src/app/control-card/components/modal-my-cards/modal-my-cards.component';
import { ModalMyCardsService } from 'src/app/control-card/components/modal-my-cards/service/modal-my-cards.service';
import { GetDataCardResponse } from 'src/app/interface/dto/GetDataCardResponse';
import { ICardNumberInfo } from 'src/app/interface/dto/ICardNumberInfo';
import { ControlCardService } from 'src/app/services/control-card.service';
import { CreditCardInfoStatusService } from 'src/app/services/credit-card-info-status.service';
import { CreditDetailsMovementsService } from 'src/app/services/credit-details-movements.service';
import { MapService } from 'src/app/services/map.service';
import { ModalMyCardsExtensionThreeService } from 'src/app/services/modal-my-cards-three.extension.service';
import { DebitCardType, Length, Numbers, Source } from 'src/core/constants/Constants';
import { ControlCardConstants, OperationType } from 'src/core/constants/ControlCardConstants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ModalMyCardsLimitsService extends ModalMyCardsService {

  constructor(
    override readonly componentExtensionThree: ModalMyCardsExtensionThreeService,
    override readonly mapService: MapService,
    private readonly controlCardService: ControlCardService,
    private readonly creditDetailsMovementsService: CreditDetailsMovementsService,
    private readonly creditCardInfoStatusService: CreditCardInfoStatusService) {
    super(componentExtensionThree, mapService);
  }

  async getDataCards(context: ModalMyCardsComponent) {
    try {
      context.getDataCardsInfo = await context.cardsAdminService
        .getDataCards(
          context.sourceInfo,
          context.cardNumberInfo.CardDataInfo.CardNumber
        );
      context.getDataCardsInfo.Card.IsAditional = this.cardNumberInfoService.cardNumberInfo.CardDataInfo.isAdditional;
      context.getDataCardsInfo.Card.IdAdicional = this.cardNumberInfoService.cardNumberInfo.CardDataInfo.idAdditional;
      context.pictureCard = context.cardsAdminService
        .getPictureCard(context.getDataCardsInfo?.Card);

      if (context.getDataCardsInfo.Card.CardFrontImageUrl === null || !context.getDataCardsInfo.Card.CardFrontImageUrl) {
        if (context.getDataCardsInfo.Card.Source === Source.Mf2) {
          context.pictureCard = Resources.ASSETS_GENERIC_DEBIT_CARD();
        } else {
          context.pictureCard = Resources.ASSETS_GENERIC_CREDIT_CARD();
        }
      } else if (
        context.getDataCardsInfo.Card.CardFrontImageUrl.indexOf(Resources.ASSETS_IMAGES_OSB)
        !== -Numbers.One) {
        const img = context.getDataCardsInfo.Card.CardFrontImageUrl
          .replace(Resources.ASSETS_IMAGES_OSB, Strings.EMPTY);
        context.pictureCard = Resources.ASSETS_CREDIT_CARDS() + img;
      }

      const cardsInfo$: ICardNumberInfo = {
        CardDataInfo:
        {
          CardLocks : context.getDataCardsInfo.Card.Locks,
          CardNumber: context.getDataCardsInfo.Card.CardNumber,
          SourceCard: context.sourceInfo,
          img       : context.pictureCard,
          type      : context.getDataCardsInfo.Card.CardType,
          account   : context.getDataCardsInfo.Card.Account,
          isAdditional:  context.getDataCardsInfo.Card.IsAditional,
          idAdditional: context.getDataCardsInfo.Card.IdAdicional
        }
      };
      context.cardNumberInfoService.cardNumberInfo = cardsInfo$;
      context.flagNotChanges = true;
      await this.getLocksLimits(context);
      this.hiddenModal(false);

    } catch (error) {
      if (!context.finish) {
        context.close();
        context.finish = false;
        const errorDetails = Utils.getErrorMsg(error);
        this.showAlert(context, errorDetails);
      }
    }
  }

  async getLocksLimits(context: ModalMyCardsComponent, onlyCreditLimit: boolean = false, uptadeCreditLimit: boolean= true) {
    try {
      if(!onlyCreditLimit){
        context.getLocksLimitsInfo = await context.cardsAdminService
        .getLocksLimits(
          context.sourceInfo,
          context.cardNumberInfo.CardDataInfo.CardNumber
        );
      }
      if (context.sourceInfo === Source.Infinitus && !this.cardNumberInfoService.cardNumberInfo.CardDataInfo.isAdditional
        && !environment.hiddenComponents.monthlyLimitCredit) {
          if(uptadeCreditLimit){
            await this.creditDetailsMovementsService.getInfoBalance(context.cardNumberInfo.CardDataInfo.CardNumber);
          }
          this.controlCardService.dataControls = {
            Monthly: {
              ...this.controlCardService.dataControls.Monthly,
              OriginAmount: this.creditCardInfoStatusService.balances.LimiteMensual,
              Amount: this.creditCardInfoStatusService.balances.LimiteMensual,
              Lock: false,
              MaximumLimit: this.creditCardInfoStatusService.balances.LimiteCredito,
              MinimumLimit: this.creditCardInfoStatusService.balances.SaldoTramite + this.creditCardInfoStatusService.balances.SaldoTotal
            }
          };
        context.getLocksLimitsInfo.Limits.Amounts.Mensual = this.controlCardService.dataControls.Monthly.OriginAmount;
      } else {
        this.controlCardService.dataControls = {
          Monthly: {
            ...this.controlCardService.dataControls.Monthly,
            OriginAmount: context.getLocksLimitsInfo.Limits.Amounts.Mensual,
            Amount: context.getLocksLimitsInfo.Limits.Amounts.Mensual,
            Lock: context.getLocksLimitsInfo.Locks.Mensual,
            MaximumLimit: ControlCardConstants.MAXIMUM_MONTHLY_AMOUNT,
            MinimumLimit: ControlCardConstants.MINIMUM_MONTHLY_AMOUNT
          }
        };
      }
      context.atmInformation = context.getLocksLimitsInfo.Limits.Amounts.Atm;
      context.atmValue = context.getLocksLimitsInfo.Limits.Movements.Atm;
      context.commerceInformation = context.getLocksLimitsInfo.Limits.Amounts.Pos;
      context.commerceValue = context.getLocksLimitsInfo.Limits.Movements.Pos;
      context.onlineInformation = context.getLocksLimitsInfo.Limits.Amounts.Int;
      context.locksAtm = context.getLocksLimitsInfo.Locks.Atm;
      context.locksCommerce = context.getLocksLimitsInfo.Locks.Pos;
      context.locksInt = context.getLocksLimitsInfo.Locks.Int;
      context.codiInformation = context.getLocksLimitsInfo.Limits.Amounts.Codi;
      context.locksCodi = context.getLocksLimitsInfo.Locks.Codi == null
        ? false
        : context.getLocksLimitsInfo.Locks.Codi;
      context.locksOpi = context.getLocksLimitsInfo.Locks.Opi;
      context.finish = true;
      this.filterData(context.getDataCardsInfo, context.sourceInfo, context);
      context.arrayValuesLimits = JSON.parse(JSON.stringify(context.getLocksLimitsInfo.Limits));

    } catch (error) {
      this.close(context);
      context.finish = false;
      const errorDetails = Utils.getErrorMsg(error);
      this.showAlert(context, errorDetails);
    }
  }

  filterData(accountInfo: GetDataCardResponse, sourceInfo: string, context:ModalMyCardsComponent ) {
    if (accountInfo.Card.IsAditional ||
      (sourceInfo === Source.Mf2 && environment.hiddenComponents.monthlyLimitDebit) ||
      (sourceInfo !== Source.Mf2 && environment.hiddenComponents.monthlyLimitCredit)) {
      this.controlCardService.dataControls.Monthly.Lock = true;
      context.getLocksLimitsInfo.Locks.Mensual = true;
    }
    if (accountInfo.Card.CardType === DebitCardType.GroceryVoucherCard || accountInfo.Card.CardType === DebitCardType.WalmartGroceryVoucherCard) {
      context.getLocksLimitsInfo.Locks.Atm = true;

    }
    if (accountInfo.Card.CardType === DebitCardType.WebCard) {
      context.getLocksLimitsInfo.Locks.Atm = true;
      context.getLocksLimitsInfo.Locks.Pos = true;
    }
  }

  async updateLockLimitsCard(context: ModalMyCardsComponent) {
    let uptadeCreditLimit = false;
    let onlyCreditLimit = false;
    let errorCreditLimit = false;
    let successfulCreditLimit = false;
    let amountLimit = Strings.EMPTY;
    try {
      context.lockLimitsCardValues.forEach(value => {
        if (value.Name.includes(OperationType.UPDATE_LIMIT_MONTHLY) && context.getDataCardsInfo.Card.Source === Source.Infinitus) {
          uptadeCreditLimit = true;
          amountLimit = value.Value;
          return;
        }
      });
      if (context.lockLimitsCardValues.length === Numbers.One && uptadeCreditLimit) {
        onlyCreditLimit= true;
        await context.cardsAdminService.updateCreditLimit(
          context.lockLimitsCardValues[Numbers.Zero].Value, context.getDataCardsInfo.Card.Account);
      } else if (!uptadeCreditLimit) {
        context.updateLockLimitsCardInfo = await context.cardsAdminService
          .updateLockLimitsCard(
            context.getDataCardsInfo.Card.Account,
            context.getDataCardsInfo.Card.CardNumber,
            context.lockLimitsCardValues,
            context.getDataCardsInfo.Card.Source
          );
      } else {
        try {
         await context.cardsAdminService.updateCreditLimit(amountLimit, context.getDataCardsInfo.Card.Account);
            successfulCreditLimit= true;
        } catch (error) {
          errorCreditLimit = true;
        } finally {
          context.updateLockLimitsCardInfo = await context.cardsAdminService
            .updateLockLimitsCard(
              context.getDataCardsInfo.Card.Account,
              context.getDataCardsInfo.Card.CardNumber,
              context.lockLimitsCardValues,
              context.getDataCardsInfo.Card.Source
            );
        }
      }
        this.hiddenModal(true);
        context.operation.updateLocksOperations();
        context.alertService.showPopupAlert(
          {
            message: this.showErrorMessage(errorCreditLimit, `${Strings.MSG_UPDATE_BLOCKING_AND_LIMITS}`),
            header: Strings.MSG_POPUP_TITLE,
            btnLabel: Strings.MSG_POPUP_ACCEPT,
            btnExit: false,
            imgHead: errorCreditLimit ? Resources.CLOSE_ICON_RED: Strings.EMPTY,
            imgButton: false
          },
          {
            onSuccess: () => this.updateData(context, onlyCreditLimit, uptadeCreditLimit),
            onClose: () => this.updateData(context, onlyCreditLimit, uptadeCreditLimit)
          });

      context.isContinueLockValid = false;
    } catch (error) {
      this.hiddenModal(true);
      const errorDetails = Utils.getErrorMsg(error);
      context.alertService.showPopupAlert(
        {
          message: this.showErrorMessage(successfulCreditLimit, Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code), errorCreditLimit),
          header: Strings.MSG_POPUP_TITLE,
          btnLabel: Strings.MSG_POPUP_ACCEPT,
          imgHead: Resources.CLOSE_ICON_RED,
          btnExit: false
        },
        {
          onSuccess: () => {
            this.updateData(context,onlyCreditLimit, uptadeCreditLimit);
          }
        });
      context.isContinueLockValid = false;
      context.operationBlock.updateLocksOperations();

    }
    context.lockLimitsCardValues = [];
    context.operationBlock.locksOptionList = [];
  }

  showErrorMessage(errorValidation: boolean, message: string, errorCreditLimit: boolean= false){
    return errorValidation? ControlCardConstants.ERROR_CREDIT_MONTHLY: errorCreditLimit ? `${Strings.SERVICES.UnexpectedMsg} ${ControlCardConstants.LIMIT_UPDATE_FAILED}`: message;
  }

  async updateLimits(context: ModalMyCardsComponent) {
    if (context.lockLimitsCardValues?.length !== Length.Empty &&
      context.selectLock === Strings.ACTIVE
      && context.amountValue && context.amountMaxValue) {
      await this.updateLockLimitsCard(context);
    }
  }

  async updateData(context: ModalMyCardsComponent, onlyCreditLimit: boolean, uptadeCreditLimit: boolean) {
    await this.getLocksLimits(context, onlyCreditLimit, uptadeCreditLimit);
    this.hiddenModal(false);
    context.flagNotChanges = true;
  }

  showAlert(context: ModalMyCardsComponent, errorDetails:{msg: string, code: number}){
    context.alertService.showPopupAlert(
      {
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        btnExit: false,
        imgHead: Resources.CLOSE_ICON_RED
      });
  }
}
