import { Pipe, PipeTransform } from '@angular/core';
import { Char, Numbers } from 'src/core/constants/Constants';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: number | string, groupSize: number): number | string {
    if(isNaN(parseInt(value?.toString()))) return value;
    const segments = [];
    for (let i = Numbers.Zero; i < value.toString().length; i += groupSize) {
      segments.push(value?.toString().substring(i, i + groupSize));
    }
    return segments.join(Char.WhiteSpace);
  }

}
