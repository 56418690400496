export class AddresseStrings {
  static readonly MAX_LIMIT = 'Límite diario en moneda nacional ($100,000.00)';
  static readonly TITLE_MANUAL = 'Manual transferencias internacionales';
  static readonly NAME_BROKERAGE_HOUSE = 'Contrato casa de bolsa';
  static readonly TABS = ['Otros bancos', 'Cuentas propias', 'Terceros Inbursa', 'Internacionales', 'Casa de bolsa', 'Selecciona'];
  static readonly ADDRESSE_TEXT = {
    Form:{
      labelAccount: 'Número de cuenta, tarjeta o CLABE',
      labelAccountCB: 'Número de Contrato, Número de cuenta, tarjeta o CLABE',
    },
    Confirm: {
      labelCard: 'Número de tarjeta',
      labelContract: 'Número de contrato'
    },
    Delete: {
      questionDelete: '¿Estás seguro de que quieres eliminar esta cuenta?',
      questionDeleteCB: '¿Estás seguro de que quieres eliminar este contrato?',
      contractNumber: 'con número de contrato',
      contractDelete: 'Se ha eliminado el contrato:'
    }
  };
}
