<ng-container *ngIf="vm$ | async as vm">
  <div id="card-modal"
    #ModalContainer
    class="modal-card-container pt-3 pb-2"
    [class.px-4]="!vm.breakpoints.mobile"
    [class.mx-2]="!vm.breakpoints.mobile"
    [ngClass]="vm.extraPadding?'pb-4':'pb2'">
    <header
      #Header
      class="d-flex flex-column pb-3">
        <div [class.close-btn]="vm.breakpoints.mobile" class="d-flex flex-row-reverse col-12">
          <modal-close [image]="modalCloseIcon" [clickFn]="close.bind(this)"></modal-close>
        </div>
        <span
          class="header-s text-secondary-800"
          [class.text-center]="vm.breakpoints.mobile"
          [class.px-4]="!vm.breakpoints.mobile">
            Selecciona una cuenta
        </span>
        <div
          class="d-flex flex-row col-12 pt-3 justtify-content-start select-l pt-3"
          [class.px-3]="vm.breakpoints.mobile"
          [class.px-4]="!vm.breakpoints.mobile">
            <div class="input-group p-0">
                <input
                  id
                  #searchInput
                  type="text"
                  class="form-control"
                  placeholder="Buscar"
                  (input)="setSearch(searchInput.value)"
                  cleanInput [cleanRegex]="regexSearch"/>
                  <app-reset-input-value
                    [targetInput]="searchInput"
                    (clickEmmiter)="setSearch('')">
                  </app-reset-input-value>
                <span class="input-group-text">
                  <img [src]="searchIcon | cdnImage" alt="icono de buscar"  height="24" width="24"/>
                </span>
            </div>
        </div>
    </header>
    <main
      tabindex="-1"
      class="d-flex justify-content-center"
      [class.px-3]="vm.breakpoints.mobile"
      [class.px-4]="!vm.breakpoints.mobile">
        <div class="d-flex flex-column w-100">
            <ng-container *ngFor="let card of vm.cards; let indice = index">
                <app-card-account
                  class="tarjeta"
                  [card]="card"
                  [id]="indice+1"
                  (cardInformation)="cardInformation($event)"
                  (click)="cardSelectedAccount(modal)"
                ></app-card-account>
            </ng-container>
        </div>
    </main>
    <footer [class.my-2]="vm.cards.length <= 3" class="d-flex flex-column justify-content-center" #Footer>
        <app-pagination
          [showWithoutResultsPadding]="false"
          withoutResultsClasses="pb-4 px-3"></app-pagination>
        <ng-container *ngIf="false">
          <div class="d-flex flex-row justify-content-center mt-1">
              <button class="btn-card-modal button-m mb-3 modals-btn" (click)="cardSelectedAccount(modal)">
                  <a for="" class="name-btn-card-modal header-xs">
                      Seleccionar
                  </a>
                  <img
                    height="24"
                    width="24"
                    [src]="shapeContinueIcon | cdnImage" alt=">"
                    class="icon-main-transfer-card">
              </button>
          </div>
        </ng-container>
    </footer>
  </div>
</ng-container>
