<ng-container *ngIf="vm$ | async as vm">
  <content-wrapper
    [contentMargin]="contentMargin"
    [useFlex]="!vm.breakpoints.mobile"
  >
    <div *ngIf="typeAccount" content class="width-content">
      <ng-container>
        <app-header-transfer-card
          [title]="title"
          [subtitle]="subtitle"
          [urlLocation]="homePath"
          [returnFn]="return"
          [showSubtitle]="true"
        ></app-header-transfer-card>
      </ng-container>
      <div id="main-content">
        <div>
          <app-enter-amount [label]="labelAmount" class="label-size"
            [minAmount]="account?.saldos?.disponible !== 0 ? minAmount : null"
            [maxAmount]="account?.saldos?.disponible"
            [noBalance]="true"
            [noBalanceMessage]="noBalanceMessage">
          </app-enter-amount>
          <div class="detail-m mt-2" *ngIf="!isDeposit">
            {{waitingTime}}
          </div>
          <card *ngIf="!isDeposit" class="card-height" label="Desde tu cuenta">
            <div card-content class="card-content card-content-amount">
              <ng-container>
                <div class="align-self-center card-align">
                  <img width="56" height="38" [src]="account.directorioImagen" alt="Imagen Cuenta" />
                  <div class="d-flex flex-column detail-s align-self-center default-color">
                    <span>{{account.tipoCuenta}}</span>
                    <span class="account-weight">{{account.numeroCuenta | maskAccount: 4}}</span>
                  </div>
                </div>
              </ng-container>
              <div class="d-flex flex-column header-xs align-self-center">
                <span class="default-color align-self-end text-overflow-ellipsis-h-100  text-ellipsis-align"
                [ngbTooltip]="account?.saldos?.disponible|currency">
                  {{account?.saldos?.disponible | currency}}</span>
                <span class="detail-s align-self-end"> Disponible </span>
              </div>
            </div>
          </card>
          <div *ngIf="isDeposit">
            <div class="header-xs default-color my-3 card-label">{{originAccountLabel}}</div>
            <app-card-account [card]="originCard" [isModal]="false" [maskClabe]="true"></app-card-account>
          </div>
        </div>
        <div>
          <card *ngIf="isDeposit" class="card-height" [label]="headSelect">
            <div card-content class="card-content card-content-amount">
              <ng-container>
                <div class="align-self-center card-align">
                  <img width="56" height="38" [src]="card.image" alt="Imagen Cuenta" />
                  <div class="d-flex flex-column detail-s align-self-center default-color">
                    <span>{{card.cardType}}</span>
                    <span class="account-weight">{{card.cardNumber | maskAccount: 4}}</span>
                  </div>
                </div>
              </ng-container>
              <div class="d-flex flex-column header-xs align-self-center">
                <span class="default-color align-self-end text-overflow-ellipsis-h-100  text-ellipsis-align"
                [ngbTooltip]="card?.balance | currency">
                  {{card?.balance | currency}}</span>
                <span class="detail-s align-self-end"> Disponible </span>
              </div>
            </div>
          </card>
          <app-select-account *ngIf="!isDeposit" [headSelAccount]="headSelect" [codeAllowOperations]="exceptionFilter"
          [isModal]="false" [maskClabe]="true">
          </app-select-account>
          <div [ngClass]="vm.breakpoints.desktop && isDeposit ? 'mt-2' : ''">
            <app-enter-date
              [head]="headDate"
              headClasses="mt-3 mb-2 pb-1"
              labelInput="Fecha"
              [minDate]="minDate"
              [maxDate]="maxDate"
              [inputState]="false"
              [disabled]="isDeposit"
              [customDate]="date"
              (outputDate)="dateEvent = $event"
              (isDateError)="isInvalidDateError($event)"
              [iconHead]="questionMark"
              [toolTipIcon]="toolTipIcon"
              class="label-size"
            ></app-enter-date>
          </div>
        </div>
      </div>
      <div
        class="btn-container d-flex justify-content-center"
        [ngClass]="vm.breakpoints.mobile ? buttonsSpacing.mobile : buttonsSpacing.desktop"
      >
        <button
          class="colored-btn button-height btnGeneric"
          (click)="continue()"
          [ngClass]="vm.breakpoints.mobile ? 'button-s' : 'button-m'"
          (keydown.enter)="$event.preventDefault()">
          Continuar
          <img [src]="chevronRightIcon | cdnImage" alt=">" width="auto" height="auto"/>
        </button>
      </div>
    </div>
  </content-wrapper>
</ng-container>
