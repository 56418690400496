import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { ModalService } from 'src/app/shared/modal.service';
import { ClassHTML, Constants } from 'src/core/constants/Constants';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ModalEndProcessAlertComponent } from 'src/app/shared/components/modal-end-process-alert/modal-end-process-alert.component';

@Component({
  selector: 'app-modal-form-code-security',
  templateUrl: './modal-form-code-security.component.html'
})
export class ModalFormCodeSecurityComponent implements OnInit {
  @Input() headerToken: string = Strings.EMPTY;
  @Input() tokenContainerClass: string = Strings.EMPTY;
  @Input() dataForm: Array<string | boolean> = [];
  @Output() showConfirmData = new EventEmitter();
  @Output() exitModal = new EventEmitter();
  @Input() classInput: string = ClassHTML.InputToken;
  @Input() modalOpt: {component: unknown, options: ModalOptions, typeModal?: string} = {component: null, options: null, typeModal: Strings.EMPTY};
  @Input() btnClass: string = ClassHTML.ButtonCodeSecurity;
  @Input() isEndProcess: boolean = false;
  @Input() closedModalHelp: boolean = false;
  frameClose: string = Resources.CLOSE_CIRCLE_BLUE;
  @Input() modalInstance: NgbModalRef;
  optionsToken: ModalOptions = {
    modalDialogClass: ModalConstants.MODAL_OPTIONS.DeleteOtp,
    size: Constants.MODAL_OPTIONS.SizeSm,
    centered: true
  };
  constructor(private readonly modalService: ModalService) {}

  ngOnInit(): void {}

  close(){
    this.exitModal?.emit();
    if (this.closedModalHelp) { this.modalInstance.close(); }
    if(!this.isEndProcess){this.modalService?.close(ModalFormCodeSecurityComponent);}
    if(this.isEndProcess){
      const options: ModalOptions = {
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeSm,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAlertEndProcess
      };
      this.modalInstance.update({
        modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal
      });
      const endModal = this.modalService.open( ModalEndProcessAlertComponent, options, ModalConstants.MODAL_OPTIONS.DeleteOtp);
      endModal.componentInstance.returnModal = {modalOptions:this.optionsToken,
        instance:this.modalInstance};
       
    }else if (this.modalOpt.component && this.modalOpt.options) {
      this.modalService.open(this.modalOpt.component, this.modalOpt.options, this.modalOpt.typeModal);
    }
  }

  emitConfirmData(params: boolean){
      this.showConfirmData.emit(params);
  }
}
