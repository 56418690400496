import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalMyCardsService } from 'src/app/control-card/components/modal-my-cards/service/modal-my-cards.service';
import { MapService } from 'src/app/services/map.service';
import { ModalMyCardsExtensionThreeService } from 'src/app/services/modal-my-cards-three.extension.service';
import { Constants } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
  providedIn: 'root'
})

export class ModalMyCardsFormsService extends ModalMyCardsService {

  constructor(
    override readonly componentExtensionThree: ModalMyCardsExtensionThreeService,
    private readonly _formBuilder: FormBuilder,
    override readonly mapService: MapService) {
    super(componentExtensionThree, mapService);
  }

  initForm(): FormGroup {
    return this._formBuilder.group({
      nip: [Strings.EMPTY, [Validators.required, Validators.minLength(Constants.MAX_LENGTH_NIP),],],
      confirmPin: [Strings.EMPTY, [Validators.required, Validators.minLength(Constants.MAX_LENGTH_NIP),],],
      cvvMyCards: [Strings.EMPTY, [Validators.minLength(this.minLength)]]
    });
  }

  initFormCvv(): FormGroup {
    return this._formBuilder.group(
      {
        cvvMyCards: [Strings.EMPTY, [Validators.required, Validators.minLength(this.minLength)]]
      });
  }

  initMapForm(): FormGroup {
    return this._formBuilder.group(
      {
        branch: [Strings.EMPTY, [Validators.required]]
      });
  }
}
