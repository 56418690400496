import { PathModel } from '../models/PathModel';

interface  ICancelOthersBanksRequest{
  idSesion : string,
  idAgenda : number,
  TipoAgenda : string,
  nombreAplicacion : string,
  tipoAcceso : string,
  Token : {
    OTP : string
  }
}

export class CancelOthersBanksRequest{
    constructor(public data: ICancelOthersBanksRequest){}

    get endpoint(): PathModel {
      return PathModel.CancelOthersBanks;
  }
}
