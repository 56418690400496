import { PathModel } from 'src/app/models/PathModel';

type TConsultRetainedRequest = {
    sesion : string,
    idCuentaProducto : number
};

export class ConsultRetainedRequest {
    constructor(public data: TConsultRetainedRequest) { }

    get endpoint(): PathModel {
        return PathModel.ConsultRetained;
    }
}