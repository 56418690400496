import { Resources } from 'src/core/constants/Resources';
import { AccountType, Char, Constants, PositionDate, Tab } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { TInvestment } from '../interface/TInvestment';
import { BehaviorSubject, Observable } from 'rxjs';
import { TabsModel } from '../interface/TabsModel';
import { ProductAccountResponse } from '../interface/dto/ProductAccountResponse';
import { CheckInvestmentsResponse } from '../interface/dto/ICheckInvestmentsResponse';
import { ICheckingAccountsResponse } from '../interface/ICheckingAccountsResponse';
import { PointsConstants } from 'src/core/constants/PointsConstants';
import { LoyaltyService } from './loyalty.service';
import { AccountModel } from 'src/app/interface/AccountModel';
import { CreditCardModel } from 'src/app/interface/CreditCardModel';
import { IAforePLResponse } from 'src/app/interface/IAforePLResponse';
import { ICreditsData } from 'src/app/interface/ICreditsData';
import { IInsurancePolicyInfo } from 'src/app/interface/IInsurancePolicyInfo';
import { InvestmentAccountsData } from 'src/app/interface/GetInvestmentAccountsModel';

export class ProductsInfoService {
    private readonly emptyTabs: TabsModel = {
        tabAccount: false,
        tabInvestment: false,
        tabInvestmentCompanies: false,
        tabCreditCard: false,
        tabAfore: false,
        tabInsurance: false,
        tabCredits: false
    };

    private readonly _tabs: BehaviorSubject<TabsModel> = new BehaviorSubject<TabsModel>(this.emptyTabs);
    private readonly _currentTab: BehaviorSubject<Tab> = new BehaviorSubject<Tab>(null);
    constructor() { }

    protected getCardImage(image: string) {
        if (!image || image.indexOf(Resources.ASSETS_IMAGES_OSB) !== Constants.NOT_FOUND_ITEM) {
            const auxImage = image.replace(Resources.ASSETS_IMAGES_OSB, Strings.EMPTY);
            image = `${Resources.ASSETS_CREDIT_CARDS()}${auxImage}`;
            return image;
        } else {
            return `${Resources.ASSETS_GENERIC_CREDIT_CARD}${image}`;
        }
    }

    protected formatInvestment(item: TInvestment): TInvestment {
        if (item.vencimiento) {
            item.vencimiento = item.vencimiento.split(Char.WhiteSpace)[PositionDate.Zero];
        }
        return item;
    }

    protected fillInvestmentAccountsData(accountList: ProductAccountResponse,
        checkInvestmentsResponse: CheckInvestmentsResponse) {

        checkInvestmentsResponse.PagareInfo.forEach(item => {
            if (item) {
                accountList.datos.plazos.push({
                    descripcionCuenta: item.DescripcionTipoCuenta,
                    numeroCuenta: item.NumeroCuenta,
                    saldos: { disponible: item.SaldoDisponible, total: item.SaldoDisponible },
                    vencimiento: item.PlazoFechaVencimiento,
                    plazo: item.Plazo,
                    periodo: item.Periodo,
                    rendimientoNeto: item.RendimientoNeto,
                    plazoFechaInicio: item.PlazoFechaInicio,
                    numeroCertificado: item.NumeroCertificado,
                    plazoTasaRendimiento: item.PlazoTasaRendimiento,
                    acccionVencimiento: item.InstruccionVencimiento,
                    idCuentaProducto: item.IdCuentaproducto,
                });
            }
        });
        accountList.datos.plazos = accountList.datos.plazos.map(this.formatInvestment);
    }

    protected pointsDebitCard(productsResponse: ICheckingAccountsResponse, loyaltyService: LoyaltyService) {
        const firstValidAccount = productsResponse.Debito.CuentaCheques?.some(account => account.TipoCuenta === AccountType.NOM);
        const secondValidAccount = productsResponse.Debito.segCuentaDepositoInf?.some(account => account.TipoCuenta === PointsConstants.BLACK);
        if (firstValidAccount || secondValidAccount) {
            loyaltyService.isAvailable = true;
        }
    }

    public get tabs$(): Observable<TabsModel> {
        return this._tabs.asObservable();
    }

    public set tabs(data: TabsModel) {
        this._tabs.next(data);
    }

    public get tabs() {
        return this._tabs.getValue();
    }

    get currentTab$() {
      return this._currentTab.asObservable();
    }

    get currentTab(){
      return this._currentTab.getValue();
    }

    set currentTab(data: Tab) {
      this._currentTab.next(data);
    }

    clearTabs() {
        this._tabs.next(this.emptyTabs);
    }

    canLoadAccount(account: AccountModel, status: boolean): boolean {
        return account.numeroCuenta && status;
    }

    canLoadTdc(tdc: CreditCardModel, status: boolean): boolean {
        return tdc.numeroCuenta && status;
    }

    canLoadAfore(afore: IAforePLResponse, status: boolean): boolean {
        return afore.cuenta.idCuenta && status;
    }

    canLoadInsurances(insurances: IInsurancePolicyInfo, status: boolean): boolean {
        return insurances.PolicyKey && status;
    }

    canLoadCredits(credits: ICreditsData, status: boolean): boolean {
        return credits.NumeroCredito && status;
    }

    canLoadInvestmentCompanies(isInvestmentCompanies: InvestmentAccountsData, status: boolean): boolean {
        return isInvestmentCompanies.cuenta && status;
    }
}
