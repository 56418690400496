import { PathModel } from 'src/app/models/PathModel';

type TPayrollCreditAdvanceConsultRequest = {
  IdSession: string,
  TipoConsulta: string
};
export class PayrollCreditAdvanceConsultRequest {
  constructor(public data: TPayrollCreditAdvanceConsultRequest) { }

  get endpoint(): PathModel {
    return PathModel.PayrollCreditAdvance;
  }
}