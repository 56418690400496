<div class="legend-with-icon">
  <label *ngIf="title" [ngClass]="headClasses" [ngClass]="{'title-select-account header-xs default-color':true,
'my-3':!ownCreditCard, 'credit-card-margin':ownCreditCard}">
    {{ head }}
  </label>
  <img *ngIf="iconHead" class="input-icon cursor-pointer" width="auto" height="auto" [alt]="iconHead"
    [src]="iconHead | cdnImage" [ngbTooltip]="toolTipIcon" #tooltip="ngbTooltip" (shown)="showTooltip = true"
    (click)="closeTooltip(tooltip)" (keydown.enter)="$event.preventDefault()" (hidden)="showTooltip= false">
</div>

<div class="position-relative" [class.contain]="showCard" [class.drop-shadow-m]="showCard === true">
  <form class="w-100" [formGroup]="dateForm">
    <label for="date" class="ms-2 position-absolute detail-s-highlight pos-label-input label-input style-lbl-date">
      <span class="small bg-white px-1"
      [ngClass]="{'label-red': isCustomError || (model!==undefined?showDateError:validHasDate(model))}">
      {{labelInput}}</span>
    </label>
    <div class="position-relative">
      <img class="input-icon cursor-pointer position-absolute top-50 translate-middle"
           (click)="openCalendar()"
           [src]="disabled ? (disabledImg | cdnImage) : (defaultImg | cdnImage )" alt="calendar" width="auto"
           height="auto"
           (keydown.enter)="$event.preventDefault()">
      <label for="datepicker" class=" m-2 position-absolute detail-s-highlight pos-label-input style-lbl-date"
             [class.place-holder-main-transfer]="disabled">
      </label>
      <fieldset [disabled]="disabled">
        <legend hidden="true"></legend>
        <input type="text" class="form-control inputs body-s" #calendar
          [class.place-holder-main-transfer]="disabled"
          [class.border-error]="isCustomError || (model!==undefined?showDateError:validHasDate(model))"
          id="datepicker" [ngClass]="{'input-error': dateIsError}" autocomplete="off" name="dp" [(ngModel)]="model"
          ngbDatepicker navigation="arrows" #d="ngbDatepicker" [minDate]="minDate" [maxDate]="maxDate"
          (click)="openCalendar()" placeholder="dd/mm/aaaa" (closed)="closeCalendar(model)"
          (blur)="(model!==undefined && model!==null)?dateValue(model,$event):null" formControlName="date"
          (change)="notifyDateChanged($event)" maxlength="10" (keypress)="keypress($event)"
          onpaste="return false" (keydown.enter)="handleKeyEnter()"
          (input)="validateKeyboardEvent($event)"
          (ngModelChange)="!codeflex ? dateSelected(model): dateCodeflex(model)"
          (focus)="getDateFocus($event)"/>
      </fieldset>
      <button [id]="id" class="btn btn-outline-secondary bi bi-calendar3 d-none"
      (click)="d.toggle(); $event.stopPropagation();"
              role="button" (keydown.enter)="$event.preventDefault()"></button>
    </div>
    <span *ngIf="model!==undefined?showDateError:validHasDate(model)"
    class="label-error detail-m mt-2">{{errorDate}}</span>
  </form>
</div>
