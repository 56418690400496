<ng-container *ngIf="vm$ | async as vm">
    <ng-container *ngIf="editLimit">
        <div class="p-3 main-edit-limit">

            <div class="my-3-limit d-flex justify-content-center">
                <header class="header-l text-primary-1000">{{accountSelected}}</header>
            </div>
            <div id="limit-amount">
                <div id="Limit-cta" class="height-input ">
                    <div class="position-relative w-100">
                        <label for="limit"
                            class="ms-2 position-absolute pos-label-input bg-white px-1 detail-s-highlight"
                            [ngClass]="errorLimit || isMaxAmount ? 'label-red' : 'text-secondary-800'">
                            Límite a Cuenta Eje
                        </label>
                    </div>
                    <input type="text" class="form-control col-12 inputs body-s custom-border-input" placeholder="$0.00"
                        id="limit" (ngModelChange)="dailyLimit.set($event)" [ngModel]="dailyLimit()"
                        name="limit" #limit autocomplete="off" inputmode="numeric"
                        [ngClass]="errorLimit ? 'border-red ' : ''" (blur)="changeFormat(limit.value)"
                        (input)="changeLimitError(limit.value)"  numeric [decimals]="2" [maxlength]="10"
                        onkeypress="return event.charCode>=48 && event.charCode<=57 || event.charCode == 46" />

                    <div *ngIf="errorLimit" [ngClass]="errorLimit ? 'label-red' : ''" class="detail-s">
                        Este campo es requerido
                    </div>
                    <div *ngIf="isMaxAmount" [ngClass]="isMaxAmount ? 'label-red' : ''" class="detail-s">{{errorMsg}}
                    </div>
                </div>
            </div>
            <hr class="my-3">
            <div class="d-flex justify-content-center">
                <div id="buttons" class="w-100">
                    <button class="colored-btn modals-btn modalLimit w-100" [ngClass]="
                vm.breakpoints.mobile || vm.breakpoints.tablet
                  ? 'button-m'
                  : 'button-s'" (click)="action(limit.value)" [ngClass]="disabledButton? 'disabled-btn': ''"
                  [disabled]="disabledButton">
                        Modificar
                    </button>


                    <button class="colored-btn modals-btn btn-edit btn-edit-text w-100"
                    [ngClass]="vm.breakpoints.mobile || vm.breakpoints.tablet
                  ? 'button-m'
                  : 'button-s'" (click)="closeModal()">
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="confirmEditLimit">
        <div class="p-3">

            <div class="my-3 d-flex justify-content-center">
                <header class="header-l">Modificar Límite</header>
            </div>

            <div class="my-1 d-flex justify-content-center">
                <p class="body-m p-detail">Confirma los datos de la operación</p>
            </div>

            <div class="my-2 d-flex justify-content-left">
                <header class="header-s">Información de la operación</header>
            </div>


            <div class="parent">
                <div class="div-2">
                    <div>
                        <label class="body-s">{{labelAmount}}</label>
                        <label class="detail-s col-12">Límite a Cuenta Eje</label>
                    </div>
                    <hr class="input-division-form-confirm-addressee">
                </div>
            </div>
            <div class="d-flex justify-content-center space-btn">
                <div id="buttons" class="w-100">
                    <button class="colored-btn modals-btn w-100" [ngClass]="
                vm.breakpoints.mobile || vm.breakpoints.tablet
                  ? 'button-m'
                  : 'button-s'" (click)="modifyLimitCTS()">
                        Confirmar y continuar
                        <img [src]="chevronImage | cdnImage" width="24px" height="24px" [alt]="chevronImage">
                    </button>
                    <button class="colored-btn modals-btn btn-edit btn-edit-text w-100" [ngClass]="
                vm.breakpoints.mobile || vm.breakpoints.tablet
                  ? 'button-m'
                  : 'button-s'" (click)="modify()">
                        Modificar
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="notifyEdit">
        <div class="p-3 pt-main-edit-limit-confirm">
            <div class="d-flex flex-column justify-content-center pt-22 align-items-center">
                <img width="auto" height="auto" [src]="checkIcon | cdnImage" class="icon-check" alt="">
                <div class="pt-3 transfer-title header-l header-mobile">{{head}}</div>
                <div class="pt-2 subtitle-transfer body-m" *ngIf="hideFolio">{{body}}</div>

                <div class="pt-2 subtitle-transfer detail-m">
                    {{completeDate}}h
                </div>
            </div>
            <div class="download" *ngIf="hideDownload">
                <app-shared-buttons [download]="false" [fileName]="'LímiteCuentaEje'"
                    [showButtons]="{email: false, download: true, share: true, print: false}"></app-shared-buttons>
            </div>
            <div class="my-2 d-flex pt-4">
                <header class="header-s pl-10">Información de la operación</header>
            </div>
            <div class="parent">
                <div class="div-2 col-left">
                    <label class="body-s padding-left">{{labelAmount}}</label>
                    <label class="detail-s col-12 padding-left">Límite a Cuenta Eje</label>
                </div>
                <hr class="input-division-form-confirm-addressee width-hr">
            </div>
            <div class="d-flex justify-content-center space-btn">
                <div id="buttons" class="patrimonial-ok-btn">
                    <button class="colored-btn patrimonial-limit-btn modals-btn" [ngClass]="
                vm.breakpoints.mobile || vm.breakpoints.tablet
                  ? 'button-m'
                  : 'button-s'" (click)="closeModal(true)">
                        Listo
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
