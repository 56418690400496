import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Constants, Numbers, Regex } from 'src/core/constants/Constants';
import { ModalOptions, OptionsModalToken } from 'src/app/interface/modal-options';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { BureauAlertsService } from 'src/app/services/bureau-alerts.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageService } from 'src/app/services/storage.service';
import { Utils } from 'src/core/utils/utils';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OtpCardReplacementComponent } from 'src/app/shared/components/otp-card-replacement/otp-card-replacement.component';
import { ModalSuccessfulRequestComponent } from 'src/app/component/modal-successful-request/modal-successful-request.component';
import { BureauAlertsStrings } from 'src/core/constants/BureauAlertsStrings';
import { BureauAlertsConstants } from 'src/core/constants/BureauAlertsConstants';
import { ModalConstants } from 'src/core/constants/ModalConstants';

@Component({
  selector: 'app-modal-bureau-alert',
  templateUrl: './modal-bureau-alert.component.html',
  styleUrls: ['./modal-bureau-alert.component.css']
})
export class ModalBureauAlertComponent implements OnInit {
  @ViewChild(BureauAlertsStrings.BUREAU_ALERTS.BureauAlerts.Email1Vc) email1Vc!: ElementRef;

  breakpoints$ = this.responsiveService.observe;
  title: string = BureauAlertsStrings.BUREAU_ALERTS.BureauAlerts.Title;
  clientName: string;
  paragraphOne: string = BureauAlertsStrings.BUREAU_ALERTS.BureauAlerts.ParagraphOne;
  items: string[] = BureauAlertsStrings.BUREAU_ALERTS.BureauAlerts.Items;
  regexEmail: RegExp = Regex.DenyValidateEmail;
  labelChangeEmail: string = BureauAlertsStrings.BUREAU_ALERTS.BureauAlerts.LabelChangeEmail;
  labelEmail: string = BureauAlertsStrings.BUREAU_ALERTS.BureauAlerts.LabelEmail;
  required: string = BureauAlertsStrings.BUREAU_ALERTS.BureauAlerts.Required;
  invalidEmail: string = BureauAlertsStrings.BUREAU_ALERTS.BureauAlerts.InvalidEmail;
  differentData: string = BureauAlertsStrings.BUREAU_ALERTS.BureauAlerts.DifferentData;
  acceptLabel: string = BureauAlertsStrings.BUREAU_ALERTS.BureauAlerts.Hire;
  removeIcon: string = Resources.CLOSE_CIRCLE_BLUE;
  optionsModalToken: OptionsModalToken;
  email1: string = Strings.EMPTY;
  email2: string = Strings.EMPTY;
  emailInputs: boolean = false;
  errorEmail: boolean = false;
  errorConfirmEmail: boolean = false;
  errorDifferent: boolean = false;
  isPhone: boolean = false;
  isIphone: boolean = false;
  required1: boolean = false;
  required2: boolean = false;
  formGroup: FormGroup;
  modalData: string;
  modalInstance: NgbModalRef;

  modalTokenClass: ModalOptions = {
    windowClass: ModalConstants.MODAL_OPTIONS.ModalPartialWithdrawals,
    modalDialogClass: ModalConstants.MODAL_OPTIONS.IsCentered
  };

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly bureauAlertsService: BureauAlertsService,
    private readonly formBuilder: FormBuilder,
    private readonly storage: StorageService
  ) {
    this.formGroup = this.formBuilder.group({
      email: [Strings.EMPTY, [Validators.required]]
    });

  }
  get email(){
    return this.formGroup.get(Constants.FORM_CONTROLS.Email);
   }


  ngOnInit(): void {
    const dataUser = this.storage.getUser();
    this.clientName = `${dataUser.name} ${dataUser.lastName}`;
    this.isMobile();
    this.optionsModalToken = {
      backModal: Constants.MODAL_OPTIONS.ModalBureauAlertComponent,
      backClass: { windowClass: ModalConstants.MODAL_OPTIONS.GenericWindowClass},
      nextModal: Constants.MODAL_OPTIONS.ModalSuccessfulRequestComponent,
      nextClass: { windowClass: ModalConstants.MODAL_OPTIONS.GenericWindowClass}
    };
  }

  continue() {
    if(this.emailInputs){
      this.email.markAsTouched();
      this.emailExist();
      this.validateDiffEmail(this.email1, this.email2);
    }
    if (!this.errorEmail && !this.errorConfirmEmail && !this.errorDifferent && !this.required1 && !this.required2) {
      this.close();
      this.bureauAlertsService.dataBureauAlert = {...this.bureauAlertsService.dataBureauAlert, email: this.email1};
      const modalOptions: ModalOptions = {
        centered: false,
        size: Constants.MODAL_OPTIONS.SizeSm,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.OtpChecksConfirmation
      };
      const modalData = {
        showOtp: true,
        nextComponent: ModalSuccessfulRequestComponent,
        nextModalOptions :{
          centered: false,
        size: Constants.MODAL_OPTIONS.SizeLg,
        modalDialogClass: BureauAlertsConstants.MODAL_BUREAU_ALERT_OTP
        }
      };
     const ref= this.modalService.open(OtpCardReplacementComponent, modalOptions, modalData);
     ref.componentInstance.autofocus = false;
     ref.componentInstance.optionsToken = this.optionsModalToken;
    }
  }

  emailChange() {
    this.emailInputs = true;
    this.acceptLabel = BureauAlertsStrings.BUREAU_ALERTS.BureauAlerts.Continue;
  }

  changeEmailError() {
    this.errorEmail = false;
    this.errorConfirmEmail = false;
    this.errorDifferent = false;
    this.required1 = false;
    this.required2 = false;

  }

  validateDiffEmail(email1: string, email2: string) {
    const result = Utils.validateEmailMatch(email1, email2);
    this.errorEmail = result.hasEmailError;
    if(!this.errorEmail){
      this.errorDifferent = result.hasConfirmEmailError || result.matchError;
    }else{
      this.errorDifferent = result.matchError;
    }
  }

  emailExist() {
    this.required1 = this.email1.length <= Numbers.Zero;
    this.required2 = this.email2.length <= Numbers.Zero;
  }

  close() {
    this.modalInstance?.close();
  }

  isMobile() {
    const agent = navigator.userAgent;
    const maxWidth = Constants.RESOLUTIONS.PhoneMaxWidth;
    const minWidth = Constants.RESOLUTIONS.PhoneMinWidth;
    if (((window.screen.width <= maxWidth) && (window.screen.width >= minWidth))
      && agent.match(Constants.USER_AGENT.MobileIos)) {
      this.isPhone = true;
      if (agent.match(Constants.USER_AGENT.Iphone)) {
        this.isIphone = true;
      } else {
        this.isIphone = false;
      }
    } else {
      this.isPhone = false;
      this.isIphone = false;
    }
  }

  @HostListener('window:popstate')
  onBack() {
    this.close();
  }
}
