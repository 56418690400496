
export class VerifyAccountResponse {
  model: VerifyAccountModel;

  constructor(model: VerifyAccountModel) {
    this.model = model;
  }

  get hasAccountInvestments(): boolean {
    return this.model?.contadorInversiones > 0;
  }

  get hasInvestments(): boolean {
    return this.model?.contadorPlazos > 0;
  }

  get hasCreditCards(): boolean {
    return this.model?.contadorTdc > 0;
  }

}

export type VerifyAccountModel = {
  contadorCuentas: number,
  contadorTdc: number,
  contadorInversiones: number,
  contadorPlazos: number
};
