import { Router } from '@angular/router';
import { AppNavigation } from 'src/app/models/AppNavigation';
import { AccountCardService } from 'src/app/services/account-card.service';
import { NotifyClearFormService } from 'src/app/services/notify-clear-form.service';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { AppScreen, AppMethod } from 'src/core/constants/AppConstants';
import { Constants, InputTypes, Position, Tab } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { AppBridge } from 'src/core/utils/AppBridge';
import { environment } from 'src/environments/environment';
import { Strings } from 'src/core/constants/Strings';
import { ChangeDetectorRef } from '@angular/core';
import { NotifyRouteService } from 'src/app/services/notify-route.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HTMLConstants } from 'src/core/constants/HTMLConstants';
import { NavigationConstants } from 'src/core/constants/NavigationConstants';
import { AppUtils } from 'src/core/utils/AppUtils';

export class NavigationUtils {

  static async pageTransitionDefaults(home = true, notifyClearFormService: NotifyClearFormService,
    idService: string, router: Router, stateServicePayIdService: StateServicePayIdService, accountCardService: AccountCardService,notifyRouteService?:NotifyRouteService) {
    notifyClearFormService.clearFormData = { value: true };
    if (idService === Constants.SERVICE_CODE.CreditPayment) {
      stateServicePayIdService.clearService();
      accountCardService.clearCardAccount();
    }
    if (AppUtils.platform.isApp && AppUtils.platform.isXamarin) {
      const navigationParams: AppNavigation = { Data: AppScreen.Home };
      await AppBridge.invoke<string>(AppMethod.SendNativeScreen, navigationParams);
    } else {
      if (home) {
        router.navigate([PATH.Home]);
      } else {
        notifyRouteService.navigateToTab(Tab.CreditsPayment);
      }
    }
  }

  static fixNavigation(changeDetector:ChangeDetectorRef,routes?: string[]) {
    const pathname = environment.globalPathname;
    const home = PATH.Home;
    changeDetector.detectChanges();
      history.pushState({}, Strings.EMPTY, `${pathname}${home}`);
      if (routes) {
        for (const route of routes) {
          changeDetector.detectChanges();
          history.pushState({}, Strings.EMPTY, `${pathname}${route}`);
        }
      }
  }

  /**
 * Añade o elimina una clase de un elemento.
 *
 * @param {Element} element - El elemento al que se le va a añadir o eliminar la clase.
 * @param {string} className - El nombre de la clase que se va a añadir o eliminar.
 * @param {boolean} add - Si es verdadero, se añade la clase. Si es falso, se elimina.
 */

static addOrRemoveClass(element: Element, className: string, add: boolean) {
  if (element?.classList) {
    if (add) {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  }
}

/**
 * Actualiza la visualización de un modal.
 *
 * @param {HTMLElement} container - El contenedor del modal.
 * @param {string} display - El tipo de visualización (none, block, etc.).
 * @param {string} className - El nombre de la clase que se va a añadir o eliminar.
 * @param {boolean} add - Si es verdadero, se añade la clase. Si es falso, se elimina.
 * @param {boolean} background - Si es verdadero, se añade o elimina la clase al fondo del modal.
 */
static updateModalDisplay(container: HTMLElement, display: string, className: string, add: boolean, background: boolean) {
  if (container) {
    container.style.display = display;

    const parent = this.findParent(container, HTMLConstants.MODAL_OPTIONS.NgbModalWindow);
    this.addOrRemoveClass(parent, className, add);
    if(background){
      const parentBack = document.getElementsByTagName(HTMLConstants.MODAL_OPTIONS.NgbModalBackdrop)?.[Position.Zero];
      this.addOrRemoveClass(parentBack, className, add);
    }
  }
}

/**
 * Navega y asegura que solo se muestre un modal.
 *
 * @param {HTMLElement} container - El contenedor del modal.
 * @param {NgbModalRef} reference - La referencia al modal.
 * @param {boolean} isDismissed - Si es verdadero, se cierra el modal. Si es falso, se mantiene abierto.
 * @param {boolean} background - Si es verdadero, se añade o elimina la clase al fondo del modal.
 */
static navigateAndEnsureSingleModalDisplay(container: HTMLElement, reference: NgbModalRef, isDismissed: boolean, background = false) {
  this.updateModalDisplay(container, InputTypes.None, HTMLConstants.MODAL_OPTIONS.HiddenModalUtils, true, background);
  const modalRef = (isDismissed ? reference.dismissed : reference.closed).subscribe(() => {
    this.updateModalDisplay(container, InputTypes.Block, HTMLConstants.MODAL_OPTIONS.HiddenModalUtils, false, background);
    modalRef.unsubscribe();
  });
}


/**
 * Encuentra el elemento padre que coincide con el selector proporcionado.
 *
 * @param {HTMLElement} element - El elemento del que se va a buscar el padre.
 * @param {string} selector - El selector del elemento padre que se está buscando.
 * @return {HTMLElement | null} - El elemento padre que coincide con el selector, o null si no se encuentra ninguno.
 */
  static findParent(element: HTMLElement, selector: string) {
    while (element) {
      if (element.matches(selector)) {
        return element;
      }
      element = element.parentElement;
    }
    return null;
  }

  static getOtpFromState(router: Router){
    if (router.getCurrentNavigation()?.extras?.state) {
      return router.getCurrentNavigation().extras.state[NavigationConstants.NAVIGATION_PARAMETERS.OtpCode];
    }
    return null;
  }

}
