import { PathModel } from 'src/app/models/PathModel';
import { DataInvestmentPurchaseSale } from '../DataInvestmentPurchaseSale';

export class InvestmentPurchaseRequest{
    constructor(public data: DataInvestmentPurchaseSale){ }

    get endpoint(): PathModel {
      return PathModel.InvestmentPurchase;
  }
}
