import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IInsurancePolicyInfo } from 'src/app/interface/IInsurancePolicyInfo';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
  providedIn: 'root'
})

export class InsurancePolicyInfoStatusService {

  private readonly emptyInsurancePolicy: IInsurancePolicyInfo = {
    Poliza: Strings.EMPTY,
    Cis: Strings.EMPTY,
    Familia: Strings.EMPTY,
    Nombre: Strings.EMPTY,
    Vencimiento: Strings.EMPTY,
    Ramo: Strings.EMPTY,
    Carpeta: Strings.EMPTY,
    Emisor: Strings.EMPTY,
    Tipo: Strings.EMPTY,
    NivelCobro: Strings.EMPTY,
    TraeCondiciones: false,
    Producto: Strings.EMPTY,
    ObjetoAsegurado: {}
  };

  private readonly _insurancePolicy: BehaviorSubject<IInsurancePolicyInfo> = new BehaviorSubject<IInsurancePolicyInfo>(this.emptyInsurancePolicy);
  constructor() { }

  get insurancePolicy$() {
    return this._insurancePolicy.asObservable();
  }

  get insurancePolicy(): IInsurancePolicyInfo {
    return this._insurancePolicy.getValue();
  }

  set insurancePolicy(data: IInsurancePolicyInfo) {
    this._insurancePolicy.next(data);
  }

  clearInsurancePolicy() {
    this._insurancePolicy.next(this.emptyInsurancePolicy);
  }
}
