import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';

interface IServiceContractOptions {
  dataContract: Array<DataContractModel>;
  dataContractMobile: Array<DataContractMobile>;
}
interface DataContractModel {
  img: string;
  header: string;
}

interface DataContractMobile {
  imgMobile: string;
  header: string;
}

export const SERVICES_CONTRACT_OPTIONS: IServiceContractOptions = {
  dataContract: [
    { img: Resources.PAYROLL_CREDIT, header: Strings.SERVICES_CONTACT.HeaderPayrollCredit },
    { img: Resources.PAYROLL_ADVANCE, header: Strings.SERVICES_CONTACT.HeaderPayrollAdvance },
    { img: Resources.PAYROLL_CREDIT, header: Strings.SERVICES_CONTACT.HeaderInsurance } 
  ],
  dataContractMobile: [
    { imgMobile: Resources.ICON_PAYROLL_CREDIT, header: Strings.SERVICES_CONTACT.HeaderPayrollCredit },
    { imgMobile: Resources.ICON_PAYROLL_ADVANCE, header: Strings.SERVICES_CONTACT.HeaderPayrollAdvance },
    { imgMobile: Resources.ICON_PAYROLL_CREDIT, header: Strings.SERVICES_CONTACT.HeaderInsurance }
  ]
};
