<ng-container *ngIf="vm$ | async as vm" #ModalCards>
  <div id="modal-my-cards" class="container-fluid p-0 adjust-modal-size" #ModalContainer
    [ngClass]="'vm.breakpoints.mobile'? 'm-0':''">
    <div class="row width-mobile-close" [ngClass]="'vm.breakpoints.mobile'?'withMobileClose-mobile':''">
      <div class="col-sm-12">
        <div class="d-flex justify-content-end" ngbAutofocus>
          <img role="button" class="close" aria-label="Close" (click)="close()" [src]=" closeCircleBlue | cdnImage"
            [alt]="getDataCardsInfo?.Card?.CardType" width="auto" height="auto"
            (keydown.enter)="$event.preventDefault()">
        </div>
      </div>
    </div>
    <div class="d-flex row col-12 pb-4 align-card-content">
      <div class="img-cards align-card" *ngIf="getDataCardsInfo" [ngClass]="vm.breakpoints.mobile? 'mobile-card':''">
        <label class="tittle-mobile" *ngIf="vm.breakpoints.mobile">
          Administrar tarjeta</label>
        <div  class="additional-content" *ngIf="getDataCardsInfo?.Card?.IsAditional && blockade">
          <div class="icon-additional"></div>
          <label class="p-0 detail-s-highlight type-card">
            Adicional</label>
        </div>
        <div class="content-card">
          <img class="image image-card" src="{{pictureCard}}" width="auto" height="auto" alt="image card" />
          <text-ellipsis *ngIf="getDataCardsInfo && vm.breakpoints.mobile" 
          [text]="getDataCardsInfo?.Card?.CardType"
           classes="p-0 detail-s-highlight type-card mt-2 text-card" [styles]="styles"
            [lines]="lines"></text-ellipsis>
          <div class="detail-m text-color" *ngIf="vm.breakpoints.mobile">
            {{getDataCardsInfo?.Card?.CardNumber | maskAccount: 4}}</div>
          <br *ngIf="!vm.breakpoints.mobile">
          <div class="p-0 detail-s-highlight type-card mt-2" *ngIf="getDataCardsInfo && !vm.breakpoints.mobile">
            Tarjeta de {{getCardType(getDataCardsInfo?.Card?.Source)}} - {{getDataCardsInfo?.Card?.CardType}}</div>
        </div>
      </div>
        <div class="span-cards amount-position" *ngIf="getDataCardsInfo && !vm.breakpoints.mobile">
          <span class="detail-s col-12 d-flex lbl-saldo text-color">
            {{ getCardType(sourceInfo) == debitType ? 'Saldo disponible' : 'Saldo al día' }}</span>
          <span class="header-l col-12 d-flex color-label">
            {{getDataCardsInfo?.Card?.Balance | currency }}</span>
          <span class="detail-m col-12 d-flex justify-card-number text-color">
            {{getDataCardsInfo?.Card?.CardNumber | maskAccount: 4}}</span>
        </div>
        <ng-container *ngIf="!beta">
          <div class="p-1 m-2 btn-renewal-position" *ngIf="activeOption ">
            <button class="outline-btn btn-renewal" (click)="renovationOption(getDataCardsInfo?.Card?.Source)"
            (keydown.enter)="$event.preventDefault()">
              <img role="button" [src]=" blankcardFav| cdnImage" width="20px" height="20px" alt="blankcardFav">
              Renovación de Tarjeta
            </button>
          </div>
        </ng-container>
    </div>
    <ng-container>
      <div class="form-select-adjust">
        <ul *ngIf="!vm.breakpoints.mobile" class="nav style-my-nav"></ul>
        <app-input-template id="options" [input]="inputConfig[0]" [form]="optionform">
            <div >
                <div ngbDropdown #MyDropdownPeriods="ngbDropdown">
                    <div id="dropdownMenu" ngbDropdownToggle
                        class="cursor-pointer form-control form-select input-height"
                        [ngClass]="vm.breakpoints.mobile? 'color-select':''"
                        (keydown.enter)="MyDropdownPeriods.toggle()"
                        #Reason>
                        <span class="dropdown-item mt-2 pt-1 text-overflow-ellipsis style-text"
                        [ngbTooltip]="reasonValue">
                            {{ (reasonValue | ellipsisText :
                            vm.breakpoints.mobile ? 25 : 40) }} </span>
                    </div>
                    <div class="container-dropdown" *ngIf="showDropDown">
                        <div ngbDropdownMenu class="dropdown-item-container">
                          <ng-container *ngFor="let option of reasonOptions">
                            <div *ngIf="(
                              option.beta && (getDataCardsInfo?.Card?.CardType !== 'TARJETA WEB' || (
                              option.text !== 'Cancelar tarjeta' &&
                              getDataCardsInfo?.Card?.CardType === 'TARJETA WEB')) &&
                              (option.value !== 'adicional' ||
                                (getDataCardsInfo?.Card?.Source === 'INFI' && !isAditional)
                              ))"
                            [ngClass]="{'disabled-item disabled':option.disabled}"
                             ngbDropdownItem id="userMenuItem"
                            class="cursor-pointer dropdown-item m-0 text-wrap"
                            (click)="changeReason(option)"
                            (keydown.enter)="changeReason(option)"> {{ option.text }} </div>
                          </ng-container>
                        </div>
                      </div>
                </div>
            </div>
        </app-input-template>
      </div>
    </ng-container>

    <div *ngIf="blockade" [ngClass]="!vm.breakpoints.mobile ? 'adjust-size-desk-cotainer':''">
      <div class="row padding-lock" [ngClass]="vm.breakpoints.mobile ? '':'col-12'">
        <div class="row">
          <div class="col-12" [ngClass]="!vm.breakpoints.mobile ? 'adjust-title-position':''">
            <label class="header-s font adjust-block-text default-color font-size-tittle">
              Bloqueo por tipo de operación</label>
          </div>
        </div>
        <app-operation-block #Operation [cardType]="getDataCardsInfo?.Card?.CardType" [isUpdateLocks]="true"
          [dataCard]="getDataCardsInfo" [isdebit]="getCardType(getDataCardsInfo?.Card?.Source)"
          [isAdditional]="getDataCardsInfo?.Card?.IsAditional"
          (lockOperationBlock)="getEmitLockOpBlock($event)" class="add-padding">
        </app-operation-block>
        <app-locks-limits [context]="this">

        </app-locks-limits>
      </div>
    </div>
    <div *ngIf="modify">
      <div class="row col-12 py-3">
        <span class="header-s">Modificar NIP</span>
      </div>
      <form [formGroup]="adminCardForm" autocomplete="off" class="form-group-space">
        <div class="d-flex justify-content-between gap-1" [ngClass]="vm.breakpoints.mobile ? 'flex-column':''">
          <div class="d-flex flex-column" [ngClass]="!vm.breakpoints.mobile ? 'col-6 pe-2':''">
            <div class="position-relative w-100 ">
              <label for="nip" class="pos-label-cvv ms-2 px-2 position-absolute bg-white detail-s-highlight"
              [ngClass]="{'label-red':adminCardForm.get('nip').touched  && adminCardForm.get('nip')?.errors,
                'custom-label-red':adminCardForm.get('nip').touched && adminCardForm.get('nip')?.errors,
                'equals-label-red':errorConfirmNip && (adminCardForm.get('nip')?.errors===null)
                && adminCardForm.get('confirmPin').touched
                && adminCardForm.get('nip').touched}">
                Ingresa tu nuevo NIP
              </label>
              <input inputmode="numeric" type="text" class="form-control col-12 input-modify inputs body-s" id="nip"
                (select)="handleSelect($event, 'nip')" (input)="validateNip(Nip.value,ConfirmPin.value, $event, 'nip')"
                autocomplete="off" placeholder="4 dígitos" formControlName="nip" maxlength="4" onpaste="return false"
                (keypress)="onKeyPress($event)"
                [ngClass]="{'equals-error':errorConfirmNip && (adminCardForm.get('nip')?.errors===null)
                  && adminCardForm.get('nip').touched && adminCardForm.get('confirmPin').touched,
                  'input-error':adminCardForm.get('nip').touched
                  && adminCardForm.get('nip')?.errors}" #Nip>
            </div>
            <div *ngIf="adminCardForm.get('nip').touched &&
            adminCardForm.get('nip')?.hasError('required')" class="detail-m label-red">
              Este campo es requerido
            </div>
            <div *ngIf="adminCardForm.get('nip').touched &&
            adminCardForm.get('nip')?.hasError('minlength')" class="detail-m label-red">
              Debes ingresar 4 dígitos
            </div>
            <div *ngIf="errorConfirmNip && (adminCardForm.get('confirmPin')?.errors===null) &&
            adminCardForm.get('confirmPin').touched"
            [ngClass]="errorConfirmNip && adminCardForm.get('confirmPin').touched
            && errorConfirmNip ? 'label-red' : 'label-red'" class="detail-m">
              Ambos campos deben coincidir
            </div>
          </div>

          <div class="d-flex flex-column" [ngClass]="!vm.breakpoints.mobile ? 'col-6 ps-2':'pt-3'">
            <div class="position-relative w-100  d-flex justify-content-start">
              <label for="confirmNip" class=" pos-label-cvv ms-2 px-2 position-absolute bg-white detail-s-highlight"
                [ngClass]="{'label-red':adminCardForm.get('confirmPin').touched  &&
                adminCardForm.get('confirmPin')?.errors && errorConfirmNip,
                'custom-label-red':adminCardForm.get('confirmPin').touched &&
                adminCardForm.get('confirmPin')?.hasError('required'),
                'length-label-red':adminCardForm.get('confirmPin')?.hasError('minlength')
              && adminCardForm.get('confirmPin').touched,
              'equals-label-red':errorConfirmNip && (adminCardForm.get('confirmPin')?.errors===null) &&
              adminCardForm.get('confirmPin').touched}">
                Confirma tu NIP
              </label>
              <input inputmode="numeric" type="text" class="form-control col-12 input-modify inputs body-s"
                id="confirmNip" autocomplete="off" (select)="handleSelect($event,'confirmPin')"
                (keypress)="onKeyPress($event)" placeholder="4 dígitos" formControlName="confirmPin" maxlength="4"
                onpaste="return false" [ngClass]="{
                'required-error':adminCardForm.get('confirmPin').touched &&
                adminCardForm.get('confirmPin')?.hasError('required'),
                'length-error':adminCardForm.get('confirmPin')?.hasError('minlength')
                && adminCardForm.get('confirmPin').touched,
                'equals-error':errorConfirmNip && (adminCardForm.get('confirmPin')?.errors===null)
                 && adminCardForm.get('confirmPin').touched}"
                (input)="validateNip(Nip.value,ConfirmPin.value, $event, 'confirmPin')" #ConfirmPin>
            </div>
            <div *ngIf="adminCardForm.get('confirmPin').touched &&
            adminCardForm.get('confirmPin')?.hasError('required')" class="detail-m label-red">
              Este campo es requerido
            </div>
            <div *ngIf="adminCardForm.get('confirmPin')?.hasError('minlength')
              && adminCardForm.get('confirmPin').touched" class="detail-m label-red">
              Debes ingresar 4 dígitos
            </div>
            <div *ngIf="errorConfirmNip && (adminCardForm.get('confirmPin')?.errors===null) &&
            adminCardForm.get('confirmPin').touched"
            [ngClass]="errorConfirmNip && adminCardForm.get('confirmPin').touched
            && errorConfirmNip ? 'label-red' : 'label-red'" class="detail-m">
              Ambos campos deben coincidir
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="dinamycCVV" class="d-flex justify-content-center flex-column">
      <form [formGroup]="cvvCardForm" autocomplete="off">
        <div *ngIf="cvv">
          <div class="row col-12 py-3">
            <span class="header-s"> {{ hideCvvForm ? 'Registro de CVV':'Código de seguridad dinámico'}}</span>
          </div>
          <div class="co-12 mb-2 label-mobile-component">
            <label class="body-s subtitle">Realiza compras seguras vía internet, generando
              un CVV dinámico y único por cada compra</label>
          </div>
          <div class="d-flex flex-column align-items-center control-container" [class.mt-3]="vm.breakpoints.mobile"
            *ngIf="!hasCVV">
            <div [class.w-100]="vm.breakpoints.mobile">
              <div class="d-flex flew-row gap-2" *ngIf="hideCvvForm">
                <div class="w-100">
                  <label for="cvvInput" class="ms-2 position-absolute pos-label-cvv bg-white px-2 detail-s-highlight"
                    [ngClass]="{'label-red':cvvCardForm.get('cvvMyCards').touched  &&
                  (cvvCardForm.get('cvvMyCards')?.hasError('minlength') ||
                  cvvCardForm.get('cvvMyCards')?.hasError('required'))}">
                    CVV
                  </label>
                  <input inputmode="numeric" type="text" class=" form-control col-12 inputs input-modify body-s"
                    id="cvvInput" name="cvvInput" autocomplete="off" placeholder="3 dígitos" #CvvInput minlength="3"
                    maxlength="3" [ngClass]="{'input-error': cvvCardForm.get('cvvMyCards').touched
                    && (cvvCardForm.get('cvvMyCards')?.hasError('minlength') ||
                  cvvCardForm.get('cvvMyCards')?.hasError('required')),
                  'asterisk':hideData &&  cvvCardForm.get('cvvMyCards').value.length > 0}"
                    (select)="handleSelect($event, 'cvvMyCards')" formControlName="cvvMyCards" [(ngModel)]="cardCvv"
                    onpaste="return false" #Cvv (input)="showicon($event,'cvvMyCards');
                     removeEspecialCharacters($event, 'cvvMyCards', cvvCardForm,3)" (blur)="validateRequired()"
                    (focus)="validateRequired()" (keypress)="onKeyPress($event); preventSpaceKey($event)">
                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <img [src]="helpIcon | cdnImage" class="help-icon-card" alt="help" (click)="modalHelpCvv()"
                    *ngIf="!timeCvv" width="auto" height="auto" (keydown.enter)="$event.preventDefault()">
                </div>
              </div>
            </div>
            <div [class.w-100]="vm.breakpoints.mobile">
              <div *ngIf="cvvCardForm.get('cvvMyCards')?.touched &&
                cvvCardForm.get('cvvMyCards')?.hasError('required') " class="detail-m  label-error ">
                Este campo es requerido
              </div>
              <div *ngIf="cvvCardForm.get('cvvMyCards')?.hasError('minlength') " class="detail-m  label-error">
                Este campo debe contener 3 dígitos
              </div>
            </div>
          </div>
        </div>
      </form>

      <div>
        <span class="label-cvv" *ngIf="!showDcvv">{{lblDcvv}}</span>
      </div>
      <div class="time-cvv" *ngIf="timeCvv">
        <span class="txt-time">Tiempo válido de código</span>
        <div class="txt-time-cvv">
          <img [src]=" clockIcon | cdnImage" class="time-icon-card" width="auto" height="auto" alt="image clock">
          <p>{{minutes}}:{{seconds.toString().length == 1?'0':''}}{{seconds}}</p>
        </div>
      </div>
    </div>
    <div *ngIf="cancel">
      <app-cancel-card [getDataCardsInfo]="getDataCardsInfo" [cancelReopen]="cancelReopen"></app-cancel-card>
    </div>
    <div *ngIf="additional">
      <app-request-additional-card [modifyReception]="modifyReception" [showBranches]="showBranches">
      </app-request-additional-card>
    </div>
    <div #Content></div>
    <div *ngIf="status">
      <app-consult-card-status></app-consult-card-status>
    </div>
    <div #Footer></div>
    <ng-container *ngIf="selectedLockTab">
      <div class="row btn-continue" *ngIf="activeCvv && hideButton"
        [ngClass]="!vm.breakpoints.mobile ? 'col-mobile-inputs':''">
        <div class="align-center d-flex justify-content-center">
          <button [class.button-s]="vm.breakpoints.mobile"
          [class.button-m]="!vm.breakpoints.mobile"
          [class.disabled-btn]="flagNotChanges || locksAndLimitsButtonState() || !amountMaxValue || !amountValue"
            [class.pe-none]="locksAndLimitsButtonState()" class="btn-save modals-btn border-0"
            (click)="(locksAndLimitsButtonState()) ? null : saveChange('block')"
            (keydown.enter)="$event.preventDefault()">
            <span>{{buttonModalCard}}</span>
            <span>
              <img [src]="chevronRight | cdnImage" alt=">" width="auto" height="auto">
            </span>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedModifyTab">
      <div class="row mt-4 colMovilInputs btn-continue" *ngIf="activeCvv && hideButton">
        <div class="align-center d-flex justify-content-center" [ngClass]="vm.breakpoints.mobile ? 'col-12':'col-6'">
          <button [class.disabled-btn]="modifyCvvButtonState()" [class.pe-none]="modifyCvvButtonState()"
            class=" btn-save modals-btn border-0" (click)="(modifyCvvButtonState()) ? null : saveChange('modify')"
            (keydown.enter)="$event.preventDefault()">
            <span>{{buttonModalCard}}</span>
            <span>
              <img [src]=" chevronRight | cdnImage" alt=">" width="auto" height="auto">
            </span>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedCvvTab">
      <div class="row mt-4 colMovilInputs btn-continue" *ngIf="activeCvv && hideButton">
        <div class="align-center d-flex justify-content-center" [ngClass]="vm.breakpoints.mobile ? 'col-12':'col-6'">
          <button [class.disabled-btn]="((cvvCardForm.get('cvvMyCards')?.hasError('required') ||
            cvvCardForm.get('cvvMyCards')?.hasError('minlength')))"
            [class.pe-none]="((cvvCardForm.get('cvvMyCards')?.hasError('required') ||
            cvvCardForm.get('cvvMyCards')?.hasError('minlength')))"
            class="btn-save modals-btn border-0" (click)="((cvvCardForm.get('cvvMyCards')?.hasError('required') ||
            cvvCardForm.get('cvvMyCards')?.hasError('minlength'))) ? null : saveChange('cvv')"
            (keydown.enter)="$event.preventDefault()">
            <span>{{buttonModalCard}}</span>
            <span>
              <img [src]=" chevronRight | cdnImage" alt=">" width="auto" height="auto">
            </span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #CancelCard>
    <app-modal-exceptions [idModal]="idModal" [headerModal]="headerModal" [labelMain]="labelMain" [continue]="continue"
      (modalCall)="closeModal($event)"></app-modal-exceptions>
  </ng-template>
</ng-container>
<ng-template #Otp>
  <app-otp-card-replacement class="class" [token]="closeOtpModal" (confirmData)="getOtp($event)" [dataForm]="[]"
    [showOtp]="true" (closeModalOtp)="hiddenModal(!$event)"></app-otp-card-replacement>
</ng-template>
<ng-template #ModalCVV>
  <app-modal-alerts [btnExit]="false" [alertInfo]="messageCvv" [isControlCard]="true" [controlCard]="true"
    [hasAction]="true" [onClose]="closeAlert" (continueReplacement)="closeAlert()"></app-modal-alerts>
</ng-template>
<ng-template #ModalCVVChange>
  <app-modal-alerts [btnExit]="false" [alertInfo]="messageChanges" [isControlCardButton]="true" [isControlCard]="true"
    [controlCard]="true" [hasAction]="true" (continueReplacement)="closeAlertChange()"></app-modal-alerts>
</ng-template>
<ng-template #ReceptionModal>
  <app-modal-request-confirmation [renovation]="renovation" [reception]="reception"></app-modal-request-confirmation>
</ng-template>
<ng-template #Modalmap>
  <app-modal-map></app-modal-map>
</ng-template>
<ng-template #ModalMapFooter>
  <ng-container *ngIf="vm$ | async as vm">
    <div class="d-flex justify-content-center align-items-center mt-3">
      <button [class.button-s]="vm.breakpoints.mobile" [class.button-m]="!vm.breakpoints.mobile"
        class="btn-save modals-btn" (click)="continuetoRenovation()" (keydown.enter)="$event.preventDefault()">
        {{lblContinue}}
        <img [src]="chevronRight | cdnImage" alt=">" width="auto" height="auto" *ngIf="!vm.breakpoints.mobile" />
      </button>
    </div>
  </ng-container>
</ng-template>
<ng-template #ModalMapSelect>
  <ng-container *ngIf="vm$ | async as vm">
    <p [class.header-l]="!vm.breakpoints.mobile" [class.header-s]="vm.breakpoints.mobile"
      [class.mt-3]="!vm.breakpoints.mobile" [class.mb-2]="!vm.breakpoints.mobile" [class.my-3]="vm.breakpoints.mobile"
      class="default-color" id="top-header" #Map>
      {{ titleModalBranch }}
    </p>
    <p class="secondary-color body-s">
      {{ subtitle }}
    </p>
    <div class="d-flex justify-content-between gap-3 align-items-baseline" [class.custom-mx2]="!vm.breakpoints.mobile"
      [class.flex-column]="vm.breakpoints.mobile">
      <div class="custom-ps w-100">
        <form [formGroup]="mapForm">
          <div class="row d-flex input-cancel-card flex-column">
            <div class="position-relative w-100 d-flex input-cancel-card" [ngClass]="{'mb-2':vm.breakpoints.mobile}">
              <app-input-template id="optionsBranch" [input]="branchInputConfig[0]" [form]="mapForm">
                <div class="select">
                  <div ngbDropdown #MyDropdownPeriods="ngbDropdown">
                    <div id="dropdownMenu" ngbDropdownToggle
                    [ngClass]="{'border-red':mapForm.get(branchInputConfig[0].name)?.touched
                        && mapForm.get(branchInputConfig[0].name)?.hasError('required')}"
                      (blur)="changeBranchSelect(branchInputConfig[0].name)"
                      class="cursor-pointer form-control form-select input-height " #CancelOption>
                      <span class="dropdown-item mt-2 pt-1 overflow text-truncate pe-3 body-s">
                        {{
                        (extractLabel(
                        branchInputConfig[0].options,mapForm.
                        get(branchInputConfig[0].name).value)??
                        'Selecciona')}}</span>
                    </div>
                    <div class="container-dropdown">
                      <div ngbDropdownMenu class="dropdown-item-container">
                        <ng-container *ngFor="let option of branchInputConfig[0].options">
                          <div ngbDropdownItem id="userMenuItem"
                            (click)="changeBranchValue(option, branchInputConfig[0].name)"
                            (keydown.enter)="$event.preventDefault()"
                            class="cursor-pointer dropdown-item m-0 text-wrap"> {{ option.label }} </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </app-input-template>
            </div>
            <span *ngIf="clickedButton && mapForm.get('branch').hasError('required')" #Inputs
              class="label-red label-error error-spacing"
              [ngClass]="{'adjust-branch-error':!vm.breakpoints.mobile}">Este campo es requerido</span>
          </div>
        </form>
      </div>
      <div class="w-100 control-card-search">
        <app-search-component classSearch="input-group p-0 control-margin" [placeholder]="
            vm.breakpoints.mobile
              ? 'Buscar por C.P. '
              : 'Buscar por C.P. o dirección' " [searchCustom]="false" [onlyNumber]="false"
          [idSearch]="'searchControlCardBranch'"
          [classCheckbookInput]="vm.breakpoints.mobile ? 'search-custom-class-renew':'search-custom-desktop-class'"
          (dataFromSearch)="receiveDataFromSearch($event)"></app-search-component>
      </div>
    </div>
    <div class="mb-3">
      <ng-container *ngIf="directions().length != 0; else WithoutResults">
        <app-card-branches [directions]="directions" (index)="setLocation($event)"></app-card-branches>
      </ng-container>
    </div>
  </ng-container>
</ng-template>
<ng-template #WithoutResults>
  <div class="header-m d-flex text-center justify-content-center">
    Sin resultados<br>No se encontraron resultados para tu búsqueda
  </div>
</ng-template>
<ng-template #ModalAlertCVV>
  <app-control-card-alert [buttonText]="modalAlertBtn" [title]="modalAlertTitle"
    (stateModal)="hiddenModal($event)"></app-control-card-alert>
</ng-template>
