import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { GetAccountBalanceStatusVisibilityRequest } from 'src/app/interface/dto/GetAccountBalanceStatusVisibilityRequest';
import { GetAccountBalanceStatusVisibilityResponse } from 'src/app/interface/dto/GetAccountBalanceStatusVisibilityResponse';
import { StorageService } from 'src/app/services/storage.service';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { UpdateAccountBalanceStatusVisibilityRequest } from 'src/app/interface/dto/UpdateAccountBalanceStatusVisibilityRequest';

@Injectable({
  providedIn: 'root'
})
export class ToggleAccountBalanceService {

  private readonly _showAccountBalance: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private readonly baseService: IBaseService,
    private readonly storage: StorageService
  ) { }

  get showAccountBalance$() {
    return this._showAccountBalance.asObservable();
  }

  get showAccountBalance() {
    return this._showAccountBalance.getValue();
  }

  set showAccountBalance(data: boolean) {
    this._showAccountBalance.next(data);
  }

  async getAccountBalanceStatusVisibility(){
    try {
      const request = new GetAccountBalanceStatusVisibilityRequest({ idUsuario: Number(this.storage.getUser().userId) });
      const response = await this.baseService.genericPost<GenericResponse<GetAccountBalanceStatusVisibilityResponse>>(request, { isSOA: true });
      if (response && response?.datos && response?.datos?.mostrarSaldo || !response?.datos?.mostrarSaldo) {
        this.showAccountBalance = response.datos.mostrarSaldo;
        return;
      }
      this.showAccountBalance = true;
    } catch (error) {
      this.showAccountBalance = true;
    }
  }

  async updateAccountBalanceStatusVisibility(isVisibility: boolean){
    try {
    const request = new UpdateAccountBalanceStatusVisibilityRequest({ idUsuario: Number(this.storage.getUser().userId), datos: { mostrarSaldo: isVisibility } });
    await this.baseService.genericPost<GenericResponse<null>>(request, { isSOA: true });
    this.showAccountBalance = isVisibility;
    }catch(error){
      this.showAccountBalance = isVisibility;
    }
  }
}
