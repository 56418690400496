<div id="style-only-consult" class="credit">
  <div class="col-12 d-flex direction-icon align-btn-close">
    <form>
      <input type="button" (click)="onlyClose()" class="form-icon-btn">
    </form>
  </div>
  <div *ngIf="textConditions" class="flex-src">
    <div class="mt-3">
      <iframe title="" [src]="urlIframe | safe">
      </iframe>
    </div>
    <div class="d-flex justify-content-center align-content-center mt-40 mb-3">
      <button class="main-confirm-data-btn button-m btn-generic wrap" (click)="acceptConsult('conditions')">
        Aceptar</button>
    </div>
  </div>
  <div *ngIf="textConsult">
    <div id="scroll-div">
      <div class="align-tittle">
        <label class="header-l ">Autorización de consulta en Buró de crédito</label>
      </div>
      <label class="body-s">
        Por este conducto autorizo expresamente a Banco Inbursa S.A.,
        Institución de Banca Múltiple, Grupo Financiero Inbursa, para
        que por conducto de sus funcionarios facultados lleve a cabo
        investigaciones sobre mi comportamiento crediticio en las
        Sociedades de Información Crediticia que estime conveniente.
        Asimismo, declaro que conozco la naturaleza y alcance de la
        información que se solicitará, del uso que Banco Inbursa S.A.,
        Institución de Banca Múltiple, Grupo Financiero Inbursa hará de
        tal información y de que ésta podrá realizar consultas periódicas
        de mi historial crediticio, consintiendo que esta autorización se
        encuentre vigente por un período de 3 años contados a partir de
        la fecha de su expedición y en todo caso, durante el tiempo que
        mantenga relación jurídica conmigo.
        <br>
        <br>
        Estoy consciente y acepto que este documento quede bajo propiedad
        de Banco Inbursa S.A., Institución de Banca Múltiple,
        Grupo Financiero Inbursa, para efectos de control y
        cumplimiento del artículo 28 de la Ley para Regular a las
        Sociedades de Información Crediticia, el cual se transcribe en el
        Anexo "A" de la presente Solicitud-Carátula-Contrato, el cual
        forma parte integrante de la misma.
      </label>
    </div>
    <div id="mobile-btn" class="pt-3 d-flex justify-content-center align-content-center mt-3 mb-3">
      <button class="main-confirm-data-btn button-m btn-generic wrap" (click)="acceptConsult('consult')">
        Aceptar</button>
    </div>
  </div>
</div>