<ng-container *ngIf="vm$ | async as vm">
    <content-wrapper [contentMargin]="contentMargin" [useFlex]="true">
        <div content class="token-container">
            <div class="pb-3">
                <app-header-transfer-card [title]="title" [subtitle]="subtitle"
                    [urlLocation]="beforeView" [dataInternational]="dataInternational" [dataForm]="dataForm"
                    [showSubtitle]="true">
                </app-header-transfer-card>
            </div>
            <app-form-code-security-serie class="justify-content: center; align-items: center; display: grid;"
                [head]="head" [nextView]="nextView" [dataInternational]="dataInternational" 
                [serialNumber]="serialNumber" [dataForm]="dataForm">
            </app-form-code-security-serie>
        </div>
    </content-wrapper>
</ng-container>
