import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceTransferIdService } from 'src/app/services/service-transfer-id.service';
import { ServiceWarrantyTransferInternationalService } from 'src/app/services/service-warranty-transfer-international.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalOptions } from 'src/app/interface/modal-options';
import { PayrollDataService } from 'src/app/services/payroll-data.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Constants, Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ServiceStrings } from 'src/core/constants/ServiceStrings';
import { ServiceConstants } from 'src/core/constants/ServiceConstants';

@Component({
  selector: 'app-fee-info',
  templateUrl: './fee-info.component.html',
  styleUrls: ['./fee-info.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FeeInfoComponent {
  @Input() timeToApply: number = Numbers.Zero;
  @Input() fee: number = Numbers.Zero;
  @Input() isCreditModal: boolean = false;
  @Input() classLabel?: string;
  @Input() alertCondition: boolean = false;
  @Input() alertConsult: boolean = false;
  conditions: string = Strings.EMPTY;
  isInternationalTransfer: boolean = false;
  fromInternationalTransfer: boolean = false;
  noWarranty: boolean = true;
  warranty: boolean = false;
  warrantySave: string = Strings.EMPTY;
  breakpoints$ = this.responsiveService.observe;
  @ViewChild('modalConditions', { static: true }) modalConditions: ElementRef;
  @Output() sendAnswerToModal = new EventEmitter();
  viewModal: string = Strings.EMPTY;
  modify: boolean = false;
  checkConditions: boolean = false;
  checkConsult: boolean = false;
  warrantyFlag: boolean = false;
  noWarrantyFlag: boolean = false;
  @Input() isFundsSale : boolean;
  @Input() isFundsBuys : boolean;
  iconBalance: string = Resources.CIRCLE_INFO_LIGHT_BLUE;


  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly serviceTransferId: ServiceTransferIdService,
    private readonly router: Router,
    private readonly serviceWarrantyTransferInternationalService: ServiceWarrantyTransferInternationalService,
    private readonly modalService: ModalService,
    private readonly payrollDataService: PayrollDataService
  ) { }

  ngOnInit() {
    this.serviceWarrantyTransferInternationalService.warrantyTransferInternationalObservable.subscribe(data => {
      this.warranty = data.warranty;
      if (this.warranty) {
        this.warrantyFlag = true;
        this.noWarrantyFlag = false;
      } else {
        this.noWarrantyFlag = true;
        this.warranty = false;
      }
    });
    this.serviceTransferId.stateTransferIdObservable.subscribe(data => {
      if (data.id === Constants.INTERNATIONAL && this.router.url === PATH.ConfirmInternationalTransfer || this.router.url === PATH.TransferComplete) {
        this.isInternationalTransfer = true;
        this.fromInternationalTransfer = true;
      }
    });
    if (this.router.url === PATH.ConfirmInternationalTransfer) {
      this.fromInternationalTransfer = true;
    }
    if (this.router.url === PATH.InternationalTransfers) {
      this.isInternationalTransfer = true;
      if (this.warranty) {
        this.warrantySave = Constants.WARRANTY;
      } else {
        this.warrantySave = Constants.NOT_WARRANTY;
      }
    }

    this.payrollDataService.viewModifyObservable.subscribe(
      (modify) => {
        this.modify = modify.value;
        if (this.modify) {
          this.sendAnswerToModal.emit(Constants.CONDITIONS);
          this.alertCondition = false;
          this.sendAnswerToModal.emit(Constants.CONSULT);
          this.alertConsult = false;
        }
      });
  }

  getConditions(timeToApply: number): string {
    return ServiceStrings.SERVICE_PAYMENT_TIME_TO_APPLY(timeToApply);
  }

  warrantyTransferInternational(event: string) {
    if (event !== Strings.EMPTY) {
      if (event === Constants.WARRANTY) {
        this.warrantySave = Constants.WARRANTY;
        this.warranty = true;
        this.noWarranty = false;
        this.serviceWarrantyTransferInternationalService.warrantyTransferInternationalData = { warranty: true, noWarranty: false, warrantySave: this.warrantySave };
      } else {
        this.warrantySave = Constants.NOT_WARRANTY;
        this.warranty = false;
        this.noWarranty = true;
        this.serviceWarrantyTransferInternationalService.warrantyTransferInternationalData = { warranty: false, noWarranty: true, warrantySave: this.warrantySave };
      }
    } else {
      this.warranty = false;
      this.noWarranty = true;
      this.serviceWarrantyTransferInternationalService.warrantyTransferInternationalData = { warranty: false, noWarranty: true, warrantySave: this.warrantySave };
    }
  }
  payrollConditions(data: string) {
    this.viewModal = data;
    const modalOptions: ModalOptions = {
      centered: false,
      windowClass: ModalConstants.MODAL_OPTIONS.ModalPayroll,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.IsCentered
    };
    this.modalService.open(this.modalConditions, modalOptions);
  }

  generalAnswer($event: string) {
    switch ($event) {
      case Constants.CONDITIONS:
        this.checkConditions = true;
        this.sendAnswerToModal.emit(Constants.CONDITIONS);
        this.alertCondition = false;
        break;
      case Constants.CONSULT:
        this.checkConsult = true;
        this.sendAnswerToModal.emit(Constants.CONSULT);
        this.alertConsult = false;
        break;
      default:
    }
  }

  validateSelection(data: string) {
    this.sendAnswerToModal.emit(data);
    if (data === Constants.CONSULT) { this.alertConsult = false; }
    if (data === Constants.CONDITIONS) { this.alertCondition = false; }
  }
}
