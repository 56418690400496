<ng-container #ResetInputValue>
  <img
    [id]="id"
    alt="Limpiar búsqueda"
    width="auto"
    height="auto"
    [src]="modalCloseWhiteImage | cdnImage"
    [ngStyle]="{ backgroundColor: customColor }"
    class="input-group-text" (click)="resetInputValue()"
    [ngClass]="styleReset ? styleReset : ''"
    [class.d-none]="targetInput.value.length === 0"/>
</ng-container>
