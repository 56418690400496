import { Char, Length, Position } from '../constants/Constants';
import { Strings } from '../constants/Strings';

export { };
export { };

declare global {

    interface String {

        /**
         * Replaces all text occurrences in a string, 
         * using an object that supports replacement within a string.
         * @param searchValue A object can search for and replace matches within a string.
         * @param replacer A function that returns the replacement text.
         */
        gfiReplaceAll(searchValue: string, replacer: string): string;

        /**
         * Converts first character to uppercase.
         * @param separator Converts characters after separator
         */
        gfiCapitalize(separator?: string): string;

        /**
         * Delete first slash to Path
         */
        gfiToRoute(): string;
    }
}

String.prototype.gfiReplaceAll = function(searchValue: string, replacer: string) {
    return this.split(searchValue).join(replacer);
};

String.prototype.gfiCapitalize = function(separator?: string) {
    if(this == null) return this;
    if(this.length < Length.Two) return this.toUpperCase();

    if(separator == null){
      separator = Char.WhiteSpace;
    }
    
    if(separator !== Strings.EMPTY){
      return this.split(separator).map((item: string) => item.gfiCapitalize(Strings.EMPTY)).join(separator);
    }
    else{
      return `${this.charAt(Position.Zero).toUpperCase()}${this.slice(Position.One)
        .toLowerCase()}`;
    }
};

String.prototype.gfiToRoute = function () {
  if (this === null || this.length === Length.Empty || this.charAt(Position.Zero) !== Char.Slash) {
    return this;
  }
  return this.slice(Position.One);
};
