import { PathModel } from 'src/app/models/PathModel';

interface IAforeVoluntaryWithdrawalRequest{
  folio: string;
  aportacion: number;
  clienteInbursaAfore: number;
  actividad: number;
  monto: number;
  tipoRetiro: number;
  cuentaClabe: string;
}

export class AforeVoluntaryWithdrawalRequest{
  constructor(public data: IAforeVoluntaryWithdrawalRequest){}

  get endpoint(): PathModel{
    return PathModel.VoucherAforeVoluntaryWithdrawal;
  }
}
