<div *ngIf="vm$ | async as vm" [class.container]="!vm.breakpoints.mobile">
  <div class="row">
    <div class="content-head">
      <div class="d-flex flex-row pl-2 justify-content-center header-l align-form-title main-color-label">
        Modificar cuenta
      </div>
      <div class="d-flex flex-row pt-2 pl-2 justify-content-center subtitle-modal body-m secondary-color text-center">
        Ingresa los datos de la operación
      </div>
    </div>
  </div>
  <form  [formGroup]="formControl" class="align-form-modify">
    <div class="row">
      <div class="col-12 mt-2">
        <div *ngIf="codeType != agendaThirdPartiesID && codeType
        != serviceAgendaCode && codeType != nonAgendableService">
          <label class="body-s main-color-label">
            {{codeType == brokerageHouse ? vm.accountInformation.contract : vm.accountInformation.account}}</label>
          <label class="detail-s col-12 main-color-label">{{labelAccount}}</label>
          <hr class="input-division-form-confirm-addressee">
        </div>
        <div *ngIf="codeType == agendaThirdPartiesID || codeType == serviceAgendaCode && codeType
        != nonAgendableService">
          <label class="body-s main-color-label">
            {{vm.accountInformation.account}}
          </label>
          <label class="detail-s col-12 detail-s-color main-color-label">
            Número de cuenta, tarjeta o CLABE</label>
          <hr class="input-division-form-confirm-addressee">
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngIf="codeType != nonAgendableService" class="col-12 mt-2">
        <label for="account-alias" class="m-2 position-absolute detail-s-highlight style-custom-lbl">
          <span class="h6 small bg-white px-1 bold-span"
          [ngClass]="{'error-validation': shouldHideAliasLabel || showLabelAlias}">
            Alias de la cuenta</span>
        </label>
        <input #alias id="account-alias" type="text" [formControlName]="formControls.Alias"
          [ngClass]="{'border-red': shouldHideAliasLabel || showLabelAlias}"
          class="form-control input-from-modify place-holder-main-transfer body-s"
          placeholder="Ej. cuenta de José"
          [maxlength]="aliasLength"
          cleanInput autocomplete="off"
          (focus)="onFocusValidateInputModified()"
          (input)="validateInputModified(false, true, false, false)"
          (blur)="validateInputModified(true, true, false, false)"
          [cleanRegex]="leadingSpacesLettersAccents"
          [cleanFormControl]="formControl.controls[this.formControls.Alias]">
        <div>
          <div class="detail-m error-validation" *ngIf="showLabelAlias">
            El nuevo alias debe ser distinto al actual
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isServicesAgenda()">
      <div class="row">
        <div class="col-12 mt-4">
          <label for="service-name" class="m-2 position-absolute detail-s-highlight style-custom-lbl">
            <span class="h6 small bg-white px-1 bold-span">Nombre del servicio</span>
          </label>
          <input id="service-name" type="text" [value]="vm.accountInformation.name"
            class="form-control input-from-modify place-holder-main-transfer body-s" [disabled]="disableAccountNumber">
        </div>
      </div>
    </div>
    <ng-container *ngIf="codeType == agendaInternationalID ">
      <div class="row">
        <div class="col-12 mt-4">
          <label for="currency" class="m-2 position-absolute detail-s-highlight style-custom-lbl">
            <span class="h6 small bg-white px-1 bold-span">Tipo de Divisa</span>
          </label>
          <input id="currency" [disabled]="disableAccountNumber" type="text" [value]="vm.accountInformation.badge"
            class="form-control input-from-modify place-holder-main-transfer body-s"
            [class.disabled-input]="disableAccountNumber">
        </div>
      </div>
    </ng-container>

    <div class="row">
      <div class="col-12 mt-4">
        <label for="limit" class="m-2 position-absolute detail-s-highlight style-custom-lbl">
          <span class="h6 small bg-white px-1 bold-span"
          [ngClass]="{'error-validation': validateLimits() || shouldHideLabel || showLabelLimit}">
          Límite diario máximo</span>
        </label>
        <input id="limit" type="text" [formControlName]="formControls.MaximumLimit"
          numeric [decimals]="decimals" [maxDigits]="maxLength" [currency]="true"
          [updateAmount] = "false" [blockPaste] = "true"
          cleanInput [cleanRegex]="regexAmount"
          [cleanFormControl]="formControl.controls[this.formControls.MaximumLimit]"
          class="form-control input-from-modify place-holder-main-transfer body-s"
          inputmode="decimal" autocomplete="off"
          (focus)="onFocusValidateInputModified()"
          (input)="validateInputModified(true, false, true, false)"
          (blur)="validateInputModified(false, false, false, false)"
          (blur)="validateInputZero()"
          [ngClass]="{'border-red': validateLimits() || shouldHideLabel || showLabelLimit}">
        <div>
          <div class="detail-m error-validation" *ngIf="validateLimits() && codeType !== brokerageHouse">
            El límite diario máximo debe ser mayor a $0.00
          </div>
          <div class="detail-m error-validation" *ngIf="validateLimits() && codeType === brokerageHouse">
            El límite diario máximo debe ser mayor o igual a $1000.00
          </div>
          <div class="detail-m error-validation"
            *ngIf="showLabelLimit && !validateLimits()">
            El nuevo límite debe ser distinto al actual
          </div>
        </div>
      </div>

    </div>
    <ng-container *ngIf="codeType == agendaInternationalID || codeType == agendaOthersBanksID ||
    codeType == agendaThirdPartiesID || (codeType == brokerageHouse && originEmail)">
      <div class="row">
        <div class="col-12 mt-4">
          <label for="email" class="m-2 position-absolute detail-s-highlight style-custom-lbl">
            <span class="h6 small bg-white px-1 bold-span"
            [ngClass]="{'error-validation': validationForm(formControls.Email) || showLabelEmail}">
              Email del beneficiario</span>
          </label>
          <input id="email" type="text" [formControlName]="formControls.Email"
            [ngClass]="{'border-red': validationForm(formControls.Email) || showLabelEmail}"
            cleanInput [cleanRegex]="regexEmail"
            [cleanFormControl]="formControl.get(formControls.Email)"
            (blur)="emailTrimInput()" autocomplete="off"
            (focus)="onFocusValidateInputModified()"
            (input)="validateInputModified(true, false, false, true)"
            (blur)="validateInputModified(false, false, false, false)"
            class="form-control input-from-modify place-holder-main-transfer body-s" placeholder="Ejemplo@dominio.com"
            maxlength="80">
          <div>
            <div class="detail-m error-validation" *ngIf="showLabelEmail">
              El nuevo email debe ser distinto al actual
            </div>
          </div>
          <div>
            <label class="detail-m error-validation" *ngIf="validationForm(formControls.Email)">Email inválido</label>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</div>
