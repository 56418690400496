import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { ISimpleValue } from '../interface/ISimpleValue';
import {FormGroup} from '@angular/forms';
import { TPortability } from '../interface/dto/IPortabilityResponse';
import { Strings } from 'src/core/constants/Strings';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class PortabilityService {
  private readonly _portabilityForm: BehaviorSubject<FormGroup> =
    new BehaviorSubject<FormGroup>(null);

  private readonly _portabilities: BehaviorSubject<TPortability> =
    new BehaviorSubject<TPortability>({
      bancoOrdenante: Strings.EMPTY,
      bancoReceptor: Strings.EMPTY,
      cuentaOrdenante: Strings.EMPTY,
      cuentaReceptora: Strings.EMPTY,
      estatus: Strings.EMPTY,
      fechaAlta: Strings.EMPTY,
      fechaNacimiento: Strings.EMPTY,
      folioSolicitud: Strings.EMPTY,
      idPortabilidadNomina: 0,
      tipoCuentaOrdenante: Strings.EMPTY,
      tipoCuentaReceptora: Strings.EMPTY,
      tipoPortabilidad: Strings.EMPTY
    });

  private readonly _portability: BehaviorSubject<TPortability> =
    new BehaviorSubject<TPortability>(null);

  private readonly successfulPortability: BehaviorSubject<ISimpleValue> =
    new BehaviorSubject<ISimpleValue>({ value: false });

  private readonly _showPortability: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly showPortability = this._showPortability.asObservable();

  constructor(private readonly storage: StorageService) {
    let savedValue = this.storage.getPortabilityOption();
    if(!savedValue){
      savedValue = false;
    }
    this._showPortability.next(savedValue);
  }

  get portabilityForm$(): Observable<FormGroup> {
    return this._portabilityForm.asObservable();
  }

  get portabilityForm(): FormGroup {
    return this._portabilityForm.getValue();
  }

  set portabilityForm(data: FormGroup) {
    this._portabilityForm.next(data);
  }

  get successfulPortability$(): Observable<ISimpleValue> {
    return this.successfulPortability.asObservable();
  }


  set portabilityInformation(data: ISimpleValue) {
    this.successfulPortability.next(data);
  }

  get portabilities$(): Observable<TPortability> {
    return this._portabilities.asObservable();
  }

  get portabilities(): TPortability {
    return this._portabilities.getValue();
  }

  set portabilities(data: TPortability) {
    this._portabilities.next(data);
  }

  get portability$(): Observable<TPortability> {
    return this._portability.asObservable();
  }

  get portability(): TPortability {
    return this._portability.getValue();
  }

  set portability(data: TPortability) {
    this._portability.next(data);
  }

  clearPortabilityForm() {
    this._portabilityForm.next(null);
  }

  set showPortabilityData (data: boolean) {
    this._showPortability.next(data);
  }
}
