export class InsurancePlanConstants {
  static readonly FIFTY = 50;
}

export class InsurancePlanStrings{
  static readonly PIXELS = 'px';
  static readonly PORTAL = '/portal/';
  static readonly INSURANCE = 'Seguros';
  static readonly COMPLETE = 'complete';
  static readonly MESSAGE = 'message';
  static readonly TRUE = 'true';
  static readonly SMOOTH = 'smooth';
}
