import { Injectable } from '@angular/core';
import { GetPendingWithdrawalsRequest } from 'src/app/interface/dto/GetPendingWithdrawalsRequest';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { GetPendingWithdrawalsResponse } from 'src/app/interface/dto/GetPendingWithdrawalsResponse';
import { GetCancellationWithdrawalsRequest } from 'src/app/interface/dto/GetCancellationWithdrawalsRequest';
import { GetCancellationWithdrawalsResponse } from 'src/app/interface/dto/GetCancellationWithdrawalsResponse';
import { Utils } from 'src/core/utils/utils';
import { GetWithdrawalsRequest } from 'src/app/interface/dto/GetWithdrawalsRequest';
import { GetWithdrawalsResponse } from 'src/app/interface/dto/GetWithdrawalsResponse';
import { Strings } from 'src/core/constants/Strings';
import { Withdrawal } from 'src/app/models/Withdrawal';
import { MaskAccountPipe } from 'src/app/pipes/mask-account.pipe';
import { CodeflexConstants } from 'src/core/constants/CodeflexConstants';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { GetDepositResponse } from 'src/app/interface/dto/GetDepositResponse';
import { GetDepositRequest } from 'src/app/interface/dto/GetDepositRequest';
import { AccountCardService } from 'src/app/services/account-card.service';
import { Numbers } from 'src/core/constants/Numbers';

@Injectable({
  providedIn: 'root'
})
export class CodeflexService {

  mainAccountType: string = Strings.EMPTY;
  mainAccountNumber: string = Strings.EMPTY;

  constructor(
    private readonly baseService: BaseServiceService,
    private readonly accountCardService: AccountCardService
  ) { }

  async getPendingWithdrawals(productAccount: number): Promise<Withdrawal[]>{
    const request = new GetPendingWithdrawalsRequest({
      idCuentaProducto : productAccount
    });
    const response = await this.baseService.genericPost<GenericResponse<GetPendingWithdrawalsResponse[]>>(request, {isSecure:true, addSession:true});
    return  response ? this.formatResponse(response.datos) : null;
  }

  formatResponse(input: GetPendingWithdrawalsResponse[]): Withdrawal[]{
    const result: Array<Withdrawal> = [];
      for(const pendingWithdrawal of input){
        const item : Withdrawal = {
          account : pendingWithdrawal.cuentaDestino.numero ?
          CodeflexConstants.CODEFLEX_ACCOUNT(pendingWithdrawal.cuentaDestino.tipo, new MaskAccountPipe().transform(pendingWithdrawal.cuentaDestino.numero, Numbers.Four)):
          CodeflexConstants.CODEFLEX_ACCOUNT(this.accountCardService.mainAccountCodeflex.tipoCuenta,
            new MaskAccountPipe().transform(this.accountCardService.mainAccountCodeflex.numeroCuenta, Numbers.Four)),
          amount : pendingWithdrawal.monto,
          date : pendingWithdrawal.fechaOperacion,
          reference : pendingWithdrawal.folio,
          selected : false
        };
        result.push(item);
      }
    return result;
  }

  getCancellationWithdrawals(folioNumber: number){
    const request = new GetCancellationWithdrawalsRequest({
      folio : folioNumber,
    });
    return this.baseService.genericPost<GenericResponse<GetCancellationWithdrawalsResponse>>(request, {isSecure:true, addSession:true});
  }

  getWithdrawals(source: number, destination: number, amount: string, days: number){
    const request = new GetWithdrawalsRequest({
      idCuentaProductoOrigen : source,
      idCuentaProductoDestino : destination,
      monto : parseFloat(Utils.transformAmount(amount)),
      dias : days + Numbers.One
    });

    return this.baseService.genericPost<GenericResponse<GetWithdrawalsResponse>>(request, {isSecure:true, addSession:true});
  }

  getDeposit(source: number, destination: number, amount: string) {
    const request = new GetDepositRequest({
      idCuentaProductoOrigen: source,
      idCuentaProductoDestino: destination,
      monto: parseFloat(Utils.transformAmount(amount)),
    });

    return this.baseService.genericPost<GenericResponse<GetDepositResponse>>(request, { isSecure: true, addSession: true });
  }


  static maxDateCodeflex() : NgbDateStruct{
    const maxDate = new Date(CodeflexConstants.CODEFLEX_YEAR, CodeflexConstants.CODEFLEX_MONTH, CodeflexConstants.CODEFLEX_DAYS_31);
    return {year: maxDate.getFullYear(), month: maxDate.getMonth() + Numbers.One, day: maxDate.getDate()
    };
  }

}
