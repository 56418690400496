import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CreditCardList } from '../interface/CreditCardList';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
  providedIn: 'root'
})
export class CreditCardStatusService {

  private readonly _account: BehaviorSubject<CreditCardList> = new BehaviorSubject<CreditCardList>({
    tdc:[],
    bureauAlert: Strings.EMPTY
  });
  constructor() { }

  get account$() {
    return this._account.asObservable();
  }

  get account(){
    return this._account.getValue();
  }

  set account(data: CreditCardList) {
    this._account.next(data);
  }
}

