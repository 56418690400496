import { PathModel } from 'src/app/models/PathModel';
export interface ValidateClaimNumber{
    numeroSiniestro : string;
}
export class ValidateClaimNumberRequest {
  constructor(public data: ValidateClaimNumber) { }

  get endpoint(): PathModel {
    return PathModel.ValidateClaimNumber;
  }
}