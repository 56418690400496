import { PathModel } from 'src/app/models/PathModel';

export interface IGetAddresseeRequest {
    idSesion?: string;
    tipo?: string;
    IdAdicional?: number;
    IdPersonaTitular?: number;
    agenda?: string;
    origen?: string;
}
export class GetAddresseeRequest{
  constructor (public data: IGetAddresseeRequest){}

  get endpoint(): PathModel {
    return PathModel.ConsultAgenda;
}
}
