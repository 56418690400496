import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { map, tap } from 'rxjs';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Resources } from 'src/core/constants/Resources';
import { PATH } from 'src/core/constants/Path';
import { ElementsHTML, Numbers } from 'src/core/constants/Constants';
import { SUA } from 'src/app/interface/SUA';
import { ModalService } from 'src/app/shared/modal.service';
import { SuaService } from 'src/app/services/sua.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-sent-files',
  templateUrl: './modal-sent-files.component.html',
  styleUrls: ['./modal-sent-files.component.css']
})
export class ModalSentFilesComponent implements OnInit {
  totalSize: number = Numbers.Zero;
  contentMargin: string = ElementsHTML.ContentMargin;
  fileData: SUA;
  closeCircle: string = Resources.CLOSE_CIRCLE_BLUE;
  check: string = Resources.GREEN_CHECK_FILL;
  fileIcon: string = Resources.PILLS_FORMS_P;
  servicesPath: string = PATH.Services;
  shortAnswer: boolean = false;
  isSua: boolean = false;
  modalData: boolean = false;

  vm$ = this.responsiveService.observe.pipe(
    tap(breakpoints =>
      this.contentMargin = breakpoints.mobile ? ElementsHTML.ContentMargin : ElementsHTML.ContentMarginBreakpoints
    ),
    map(breakpoints => ({ breakpoints }))
  );

  constructor(public activeModal: NgbActiveModal,
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly suaService: SuaService,
    private readonly router: Router) { }

  ngOnInit(): void {
    this.isSua = this.modalData;
    this.fileData = this.suaService.uploadedFile;
    if(!this.fileData.folioSua) {
      this.shortAnswer = true;
    }
    this.totalSize = Number(this.fileData.size.toFixed(Numbers.Three));    
  }

  close() {
    this.activeModal.dismiss();
    this.router.navigate([PATH.Home]);
  }

  otherOperation() {
    this.modalService.close();
  }

}
