import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { ModalService } from '../../../app/shared/modal.service';
import { ResponsiveService } from '../../../app/shared/responsive.service';
import { PATH } from '../../../core/constants/Path';
import { DataConfirm } from 'src/app/interface/Investment';
import { ElementsHTML, Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';
import { InvestmentRegisterService } from 'src/app/services/investment-register.service';

@Component({
  selector: 'app-confirm-invesment',
  templateUrl: './confirm-invesment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmInvesmentComponent implements OnInit {
  title: string = Strings.INVESTMENT.LabelTitle;
  subtitle: string = Strings.INVESTMENT.ConfirmOperation;
  card: string = 'investment';
  dataConfirm = new Array<DataConfirm>;

  contentMargin: string = ElementsHTML.ContentMarginBreakpoints;
  pathNewInvesment: string = PATH.Investment;

  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints }))
  );

  constructor(
    readonly router: Router,
    readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly investmentRegisterService: InvestmentRegisterService
  ) {
    if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras.state) {
      this.dataConfirm = this.router.getCurrentNavigation().extras.state['dataConfirm'];
    }
    if(this.investmentRegisterService.investmentFormData){
      this.dataConfirm = this.investmentRegisterService.investmentFormData;
    }
  }

  ngOnInit(): void {
    NavigationUtils.fixNavigation(this.changeDetector,[PATH.Investment, PATH.ConfirmInvestment]);
    if (this.dataConfirm.length === Numbers.Zero) {
      this.router.navigate([this.pathNewInvesment]);
    }
  }

  ngOnDestroy() {
    this.modalService.close();
  }

  dataBack() {
    this.router.navigate([this.pathNewInvesment],
      {
        state: {
          dataConfirm: this.dataConfirm
        }
      });
  }
}
