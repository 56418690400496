import { PathModel } from 'src/app/models/PathModel';

interface IUpdateAccountBalanceStatusVisibilityRequest {
  idUsuario: number;
  datos: ShowAccountBalance;
}

interface ShowAccountBalance{
  mostrarSaldo: boolean;
}

export class UpdateAccountBalanceStatusVisibilityRequest {
  constructor (public data: IUpdateAccountBalanceStatusVisibilityRequest){}

  get endpoint(): PathModel {
    return PathModel.UpdateAccountBalanceStatusVisibility;
  }
}
