import { PathModel } from 'src/app/models/PathModel';
import { TToken } from '../TToken';

export interface ICancelThirdAgendaRequest{
    idAgenda : number,
    idSesion : string,
    tipoAgenda : string,
    Token : TToken
    tipoAcceso : string,
    nombreAplicacion : string,
    token? : string,
    traza? : string,
    IdAdicional? : string,
    IdPersonaTitular? : string
}

export class CancelThirdAgendaRequest {
    constructor (public data: ICancelThirdAgendaRequest) {}
    get endpoint(): PathModel {
        return PathModel.CancelThirdAgenda;
    }
}