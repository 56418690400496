import { PathModel } from './../../models/PathModel';
type TNoAgendableServiceRequest = {
    idSesion: string;
};

export class NoAgendableServiceRequest{
    constructor(public data: TNoAgendableServiceRequest){ }

    get endpoint(): PathModel{
        return PathModel.PaymentNoScheduledServices;
    }

}
