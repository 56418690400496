export class CreditResources {
  static readonly BANK = 'Bank.svg';
  static readonly HOME = 'Home.svg';
  static readonly USER = 'User.svg';
  static readonly CAR  = 'car_blue.svg';
  static readonly FILE_BLUE = 'file_blue.svg';
  static readonly IMPLOC_NEW = 'imploc.svg';
  static readonly LOGO_SAT = 'logo_sat.svg';
  static readonly EDITH_BLUE = 'edit_b.svg';
  static readonly IMPFEDR = 'impfedr.svg';
}
