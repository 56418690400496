<div class="d-flex flex-row-reverse close-modal-technical-requirements">
</div>
<div class="d-flex flex-column body-modal-technical-requirements">
  <p class="text-center header-modal-technical-requirements mx-auto">
    Requerimientos técnicos mínimos para el uso de la Banca en Línea
  </p>
  <p class="text-start subtitle-modal-technical-requirements">
    Navegadores compatibles
  </p>
  <div class="supported-browsers-modal-technical-requirements">
    <div class="row-browsers-modal-technical-requirements">
      <div class="row-item-left-modal-technical-requirements">
        <div class="d-flex flex-row justify-content-start">
          <img
            class="ChromeBrowser"
            [src]="iconChrome | cdnImage"
            alt="Chrome"
            width="auto"
            height="auto"
          />
          <p
            class="detail-m-highlight browser-name-modal-technical-requirements my-auto"
          >
            Chrome 103 o superior
          </p>
        </div>
      </div>
      <div class="row-item-right-modal-technical-requirements">
        <div class="d-flex flex-row justify-content-start">
          <img
            class="EdgeBrowser"
            [src]="iconEdge | cdnImage"
            alt="Edge"
            width="auto"
            height="auto"
          />
          <p
            class="detail-m-highlight browser-name-modal-technical-requirements my-auto"
          >
            Edge 102 o superior
          </p>
        </div>
      </div>
    </div>
    <div class="row-browsers-modal-technical-requirements">
      <div class="row-item-left-modal-technical-requirements">
        <div class="d-flex flex-row justify-content-start">
          <img
            class="SafariBrowser"
            [src]="iconSafari | cdnImage"
            alt="Safari"
            width="auto"
            height="auto"
          />
          <p
            class="detail-m-highlight browser-name-modal-technical-requirements my-auto"
          >
            Safari 13 o superior
          </p>
        </div>
      </div>
      <div class="row-item-right-modal-technical-requirements">
        <div class="d-flex flex-row justify-content-start">
          <img
            class="FirefoxBrowser"
            [src]="iconFireFox | cdnImage"
            alt="Firefox"
            width="auto"
            height="auto"
          />
          <p
            class="detail-m-highlight browser-name-modal-technical-requirements my-auto"
          >
            Firefox 102 o superior
          </p>
        </div>
      </div>
    </div>
    <div class="row-browsers-modal-technical-requirements">
      <div class="row-item-left-modal-technical-requirements">
        <div class="d-flex flex-row justify-content-start">
          <img
            class="Android"
            [src]="iconAndroid | cdnImage"
            alt="Android"
            width="auto"
            height="auto"
          />
          <p
            class="detail-m-highlight browser-name-modal-technical-requirements my-auto"
          >
            Android 15 o superior
          </p>
        </div>
      </div>
      <div class="row-item-right-modal-technical-requirements">
        <div class="d-flex flex-row justify-content-start">
          <img
            class="OperaBrowser"
            [src]="iconOpera | cdnImage"
            alt="Opera"
            width="auto"
            height="auto"
          />
          <p
            class="detail-m-highlight browser-name-modal-technical-requirements my-auto"
          >
            Opera 89 o superior
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<hr />
<div class="row card-section-modal-technical-requirements">
  <div
    class="col-lg-6 col-md-12 col-sm-12 card-left-modal-technical-requirements"
  >
    <div
      class="d-flex flex-row drop-shadow-m card-content-modal-technical-requirements justify-content-center mx-auto"
    >
      <img
        class="Windows"
        [src]="iconWindows | cdnImage"
        alt="Windows"
        width="auto"
        height="auto"
      />
      <div class="d-flex flex-column text-card-modal-technical-requirements">
        <p class="header-xs text-os">Windows 8 o superior</p>
        <p class="required-ram header-l">4 GB de RAM</p>
      </div>
    </div>
  </div>
  <div
    class="col-lg-6 col-md-12 col-sm-12 card-right-modal-technical-requirements"
  >
    <div
      class="d-flex flex-row drop-shadow-m card-content-mac-modal-technical-requirements justify-content-center mx-auto"
    >
      <img
        class="Mac"
        [src]="iconMac | cdnImage"
        alt="Mac"
        width="auto"
        height="auto"
      />
      <div class="d-flex flex-column text-card-modal-technical-requirements">
        <p class="button-s text-os">MacOS High Sierra 10.13</p>
        <p class="required-ram header-l">4 GB de RAM</p>
      </div>
    </div>
  </div>
</div>
<hr />
<button
  class="button-m colored-btn mx-auto btn-ok-modal-technical-requirements"
  (click)="actionModalRequirements(true)"
>
  {{ btnLabel }}
</button>
