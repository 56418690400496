<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="vm.showPagination && (hasResults$ | async)">
    <div class="d-flex justify-content-center">
    <div
      [class.pb-4]="showPadding"
      [class.pb-2]="!showPadding"
      class="pagination gap-3 mt-4"
    >
      <ng-container *ngIf="hasPreviousPage(); else Space">
        <div
          class="d-flex gap-2 align-items-center justify-content-end page-control cursor-pointer"
          (click)="previousPage()">
          <img
            width="16"
            height="16"
            [src]="FILLED | cdnImage"
            alt="Página anterior"
            class="cursor-pointer"
          />
          <label *ngIf="!vm.breakpoints.mobile" class="detail-m  cursor-pointer" role="button">
            Anterior
          </label>
        </div>
      </ng-container>
      <form class="d-flex flex-row-reverse" [formGroup]="paginationForm">
        <ng-container *ngIf="vm.pagination as pagination">
          <div class="d-flex gap-3">
            <div class="position-relative">
              <div
                #PaginationDropdown="ngbDropdown"
                ngbDropdown display="dynamic" dropdownMaxHeight [bsDropdownPadding]="false">
                <div id="PaginationDropdown" ngbDropdownToggle [tabIndex]="0"
                  class="cursor-pointer form-select form-control pagination-select"
                  [class.active-dropdown]="PaginationDropdown.isOpen()">
                  <span class="dropdown-item m-0 overflow cut-text">
                    {{pagination.currentPage}}
                  </span>
                </div>
                <div ngbDropdownMenu class="form-options">
                  <ng-container *ngFor="let option of pagination.pages">
                    <div ngbDropdownItem id="PaginationItem"
                      role="button"
                      class="m-0 text-wrap detail-m-highlight text-primary-1000 text-center"
                      [ngClass]="{'dropdown-item-selected' : pagination.currentPage == option}"
                      (click)="goToPage(option)"
                      (keypress)="keyPressEnter(option)"
                      > {{ option }} </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <span class="detail-m pt-2">de {{ pagination.totalPages }}</span>
          </div>
        </ng-container>
      </form>
      <ng-container *ngIf="hasNextPage(); else Space">
        <div
          class="d-flex gap-2 align-items-center justify-content-start page-control cursor-pointer"
          (click)="nextPage()">
          <label *ngIf="!vm.breakpoints.mobile" class="detail-m cursor-pointer" role="button">
            Siguiente
          </label>
          <img
            width="16"
            height="16"
            [src]="FILLED | cdnImage"
            alt="Página siguiente"
            class="rotate cursor-pointer"
          />
        </div>
      </ng-container>
    </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!(hasResults$ | async)">
    <div
      class="header-m d-flex text-center justify-content-center"
      [class.py-4]="showWithoutResultsPadding"
      [ngClass]="withoutResultsClasses">
      Sin resultados<br>No se encontraron resultados para tu búsqueda
    </div>
  </ng-container>
</ng-container>

<ng-template #Space>
  <div></div>
</ng-template>
