import { Component, ElementRef, OnInit, TemplateRef } from '@angular/core';
import { Toast, ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  host: {
    class: 'toast-container position-fixed top-0 end-0 p-3'
  }
})
export class ToastComponent implements OnInit {
  constructor(readonly toastService: ToastService) {}

  ngOnInit(): void {}

  isTemplate(toast: Toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  cast(template: string | TemplateRef<ElementRef> ) {
    return template as TemplateRef<ElementRef>;
  }
}
