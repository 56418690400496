import { PathModel } from 'src/app/models/PathModel';

type TVoluntaryWithdrawalRequest = {
    numeroCuenta: string,
    monto: number,
    tipoSubCuenta: string,
    tipo: string,
    idOcupacion: number
};

export class VoluntaryWithdrawalRequest {
    constructor(public data: TVoluntaryWithdrawalRequest) { }

    get endpoint(): PathModel {
        return PathModel.AforeVoluntaryWithdrawal;
    }

}