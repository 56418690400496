<div class="margin-btn-back">
  <app-header-transfer-card [urlLocation]="urlHome" [hideTemplate]="true"></app-header-transfer-card>
</div>
<ng-container *ngIf="vm$ | async as vm">
  <div class="m-4" [ngClass]="{'margin-siderbar': vm.sidebar}" scrollToTop>
    <div>
      <div [ngClass]="{'header-l': !vm.breakpoints.mobile, 'header-m': vm.breakpoints.mobile}"> Contratación </div>
      <div class="mt-2 secondary-color"
      [ngClass]="{'body-m ': !vm.breakpoints.mobile, 'body-s': vm.breakpoints.mobile}">
        Elige el producto que quieres contratar</div>
    </div>
    <div class="mt-4 cards-contract" *ngIf="!vm.breakpoints.mobile">
      <div *ngFor="let data of dataService.dataContract" class="card card-contract drop-shadow-m w-100">
        <img *ngIf="vm.breakpoints.desktop" class="img-card-payroll" [src]="data.img | cdnImage" width="100%"
          height="134px" [alt]="data.img"
          [ngClass]="{'img-desktop-advancement ': data.header == 'Adelanto de Nómina'}" />
        <img *ngIf="vm.breakpoints.tablet" class="img-card-payroll" [src]="data.img | cdnImage" width="100%"
          height="134px" [alt]="data.img" [ngClass]="{
                'img-tablet-insurance': data.header == 'Seguros',
                'img-tablet-payroll-credit': data.header == 'Crédito de Nómina',
                'img-tablet-advancement': data.header == 'Adelanto de Nómina',
                'img-tablet-account-credit': data.header == 'Tarjeta de crédito'}" />

        <div class="w-100 px-3">
          <p class="width-head" [ngClass]="{'div-tablet': vm.breakpoints.tablet, 'header-s': !vm.breakpoints.mobile}">
            {{data.header}}</p>
          <div class="d-flex justify-content-center align-items-center"
            [ngClass]="{'mt-4': !isDeviceMT, 'mar-top': data.header != 'Adelanto de Nómina'}">
            <button
             class="btn-contract button-s" (click)="data.header !== 'Seguros' ?
              showCardModalComponent(myModal, data.header):goPage()" (keydown.space)="$event.preventDefault()"
              (keydown.enter)="$event.preventDefault()">
              Contratar </button>
        </div>
      </div>
      <ng-template #myModal let-modal>
        <app-card-modal [modal]="modal" [modalData]="accountOperations" (savedAccount)="savedAccountEmit()">
        </app-card-modal>
      </ng-template>
    </div>
    </div>
    <div class="mt-4 cards-contract" *ngIf="vm.breakpoints.mobile">
      <div *ngFor="let data of dataService.dataContractMobile" class="card card-contract drop-shadow-m w-100"
      (keydown.enter)="$event.preventDefault()" (click)="data.header !== 'Seguros' ?
      showCardModalComponent(myModal, data.header):goPage()">
        <img *ngIf="vm.breakpoints.mobile" [src]="data.imgMobile | cdnImage"
        width="30px" height="30px" [alt]="data.imgMobile" />
        <div class="w-100">
          <p class="width-head header-xs d-flex justify-content-center align-items-center">
            {{data.header}}</p>
        </div>
      </div>
      <ng-template #myModal let-modal>
        <app-card-modal [modal]="modal" [modalData]="accountOperations" (savedAccount)="savedAccountEmit()">
        </app-card-modal>
      </ng-template>
    </div>
  </div>
</ng-container>
