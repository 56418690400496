import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Char, CoinType, Constants, ElementsHTML, Length, Numbers, Position, Regex } from 'src/core/constants/Constants';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { Strings } from 'src/core/constants/Strings';
import { ServiceConstants } from 'src/core/constants/ServiceConstants';
import { AdditionalConstants } from 'src/core/constants/AdditionalConstants';
import { Regex as Emojis } from 'src/core/constants/RegexConstants';
@Directive({
  selector: '[numeric]'
})
export class NumericDirective {
  @Input(Constants.DECIMALS) decimals: number = Numbers.Zero;
  @Input(Constants.MAXIMUM_LENGTH) maxLength: number = Numbers.Zero;
  @Input(Constants.CURRENCY) currency: boolean = false;
  @Input(Constants.FORMAT) format: boolean = false;
  @Input(Constants.INPUT_AMOUNT.maxAmount) maxAmount: number = Numbers.Zero;
  @Input(Constants.INPUT_AMOUNT.minAmount) minAmount: number = Numbers.Zero;
  @Input(Constants.INPUT_AMOUNT.updateAmount) updateAmount: boolean = true;
  @Input(ServiceConstants.BLOCK_PASTE) blockPaste: boolean;
  @Input(Constants.INPUT_AMOUNT.internationalBadge) internationalBadge: string = Strings.EMPTY;
  @Input(AdditionalConstants.MAXIMUM_DIGITS) maxDigits: number = Numbers.Zero;
  @Output() getMessageError = new EventEmitter();
  @Output() currencyConversion = new EventEmitter();

  private readonly emojiRegex: RegExp = Emojis.DenyEmoji;
  private numberRegex: RegExp;
  private readonly currencyPipe: CurrencyPipe;
  private readonly decimalPipe: DecimalPipe;
  oldValue: string = Strings.EMPTY;

  constructor(private readonly elementRef: ElementRef,
    private readonly notifyAmountService: NotifyAmountService) {
    this.currencyPipe = new CurrencyPipe(Constants.LOCALE_ES_MX);
    this.decimalPipe = new DecimalPipe(Constants.LOCALE_ES_MX);
  }

  ngOnInit(): void {
    this.oldValue = this.elementRef.nativeElement.value;
  }

  @HostListener('input', ['$event'])
  onInput($event:Event) {
    this.noEmojis($event);
    const data = ($event.target as HTMLInputElement).value;
    const currencyValue = data.replace(Regex.DifferentFromDecimals, Strings.EMPTY);
    if (data !== Strings.EMPTY && !this.validate(data)) {
      this.elementRef.nativeElement.value = this.oldValue;
    } else {
      this.oldValue = currencyValue;
      this.elementRef.nativeElement.value = this.oldValue;
    }
  }

  @HostListener('blur', ['$event'])
  onBlur() {
    if (this.currency) {
      const digitInfo = (this.decimals !== Constants.DECIMALS_EMPTY &&
        this.decimals !== Constants.TOTAL_DECIMALS) ? `${Numbers.One}${Char.Dot}${this.decimals}${Char.MiddleDash}${this.decimals}` : Constants.VERSION;
      const getAmount = this.elementRef.nativeElement.value;

      if (this.internationalBadge === CoinType.EUR) {
        this.elementRef.nativeElement.value = this.currencyPipe.transform(this.elementRef.nativeElement.value, Char.CurrencyEuro, ElementsHTML.Symbol, digitInfo);
        this.currencyConversion.emit(getAmount);
      } else {
        this.elementRef.nativeElement.value = this.currencyPipe.transform(this.elementRef.nativeElement.value, Char.CurrencySymbol, ElementsHTML.Symbol, digitInfo);
        this.currencyConversion.emit(getAmount);
      }
      if (this.updateAmount) {
        this.notifyAmountService.amount = { amount: this.elementRef.nativeElement.value };
      }

      if (getAmount > this.maxAmount || parseFloat(getAmount) < this.minAmount) {
        this.getMessageError.emit(true);
      } else { this.getMessageError.emit(false); }
    }
    else if (this.format) {
      const digitInfo = `${Numbers.One}.${this.decimals}-${this.decimals}`;
      this.elementRef.nativeElement.value = this.decimalPipe.transform(this.elementRef.nativeElement.value, digitInfo);
    }
  }

  @HostListener('focus', ['$event'])
  onFocus() {
    if (this.currency) {
      const decimals = this.elementRef.nativeElement.value.split(Char.Dot)[Position.One];
      if (parseInt(decimals) === Numbers.Zero) {
        this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.split(Char.Dot)[Position.Zero];
      }
      if (this.internationalBadge === CoinType.EUR) {
        this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.replace(Char.CurrencyEuro, Strings.EMPTY).replace(Regex.MatchComma, Strings.EMPTY);
      } else {
        this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.replace(Char.CurrencySymbol, Strings.EMPTY).replace(Regex.MatchComma, Strings.EMPTY);
      }
    }
    else if (this.format) {
      this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.replace(Regex.MatchComma, Strings.EMPTY);
    }
    this.oldValue = this.elementRef.nativeElement.value;
  }

  private validate(value: string) {
    if (this.maxDigits > Numbers.Zero) {
      const digits = value.split(Char.Dot)[Position.Zero].length;
      if (digits > this.maxDigits) {
        return false;
      }
    } else {
      const currentLength = value.includes(Char.Dot) && !value.endsWith(Char.Dot) ? value.length - Numbers.One : value.length;
      if (this.maxLength !== Length.Empty && currentLength > this.maxLength) {
        return false;
      }
    }
    this.numberRegex = (this.decimals <= Constants.DECIMALS_EMPTY) ? new RegExp(Regex.AllDigits) :
      new RegExp(`${Regex.DirectiveTwo}${this.decimals}${Regex.DirectiveThree}${this.decimals}${Regex.DirectiveFour}`);
    return this.numberRegex.test(value);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    if (this.blockPaste) {
      const ClipboardData = event.clipboardData;
      const pastedText = ClipboardData?.getData('text');
      if (!this.validateDecimalFormat(pastedText)) {
        event.preventDefault();
      }
    }
  }

  validateDecimalFormat(value: string | undefined): boolean {
    const decimalRegex = ServiceConstants.DECIMAL_FORMATTER;
    return !!value && decimalRegex.test(value);
  }

  noEmojis(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const cursorPosition = inputElement.selectionStart || Numbers.Zero;
    const originalValue = inputElement.value;
    const sanitizedValue = inputElement.value.replace(this.emojiRegex, Strings.EMPTY);
    if (sanitizedValue !== originalValue) {
      this.elementRef.nativeElement.value = sanitizedValue;
      inputElement.value = sanitizedValue;
      const newCursorPosition = cursorPosition - (originalValue.length - sanitizedValue.length);
      inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
    }
  }
}
