import { PathModel } from 'src/app/models/PathModel';

type IModifyCreditLimitRequest = {
  IdSession: string;
  NumeroCuenta: string;
  LimiteMensual: number;
  Otp?: string;
};

export class ModifyCreditLimitRequest {
  constructor(public data: IModifyCreditLimitRequest) { }

  get endpoint(): PathModel {
    return PathModel.ModifyCreditLimit;
  }
}
