import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IServiceReferenceNumber } from '../interface/dto/IServiceReferenceNumber';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
  providedIn: 'root'
})
export class ServiceReferenceNumberService {
  private readonly referenceNumber: BehaviorSubject<IServiceReferenceNumber> = new BehaviorSubject<IServiceReferenceNumber>({ referenceNumber: Strings.EMPTY, isError: false});

  constructor() { }

  get serviceReferenceNumberObservable(): Observable<IServiceReferenceNumber> {
    return this.referenceNumber.asObservable();
  }

  set serviceReferenceNumberData(data: IServiceReferenceNumber) {
    this.referenceNumber.next(data);
  }

  get serviceReferenceNumberBS(): BehaviorSubject<IServiceReferenceNumber> {
    return this.referenceNumber;
  }
}
