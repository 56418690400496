export class HTMLConstants{

  static readonly MODAL_OPTIONS = {
    HiddenModalUtils: 'hidden-modal',
    NgbModalWindow: 'ngb-modal-window',
    NgbModalBackdrop: 'ngb-modal-backdrop'
  };

   static readonly ID_MODAL = {
    ModalModifyAccounts : 'ModalModifyAccounts',
    ModalContainerAddressee : 'ModalContainerAdressee',
    ModalTokenSecurity : 'otp-card-replacement',
    ModalContainerModify: 'ModalContainerModify'
  };

  static readonly ELEMENTS_NAME = {
    SearchInputCross: '#searchInputCross',
    Checks: 'checks',
    Filter: '#filter',
    ClearFilter: '#clearFilter',
    MovementsInputCross: '#movementsInputCross',
    CheckbookReset: '#checkbookReset'
  };

  static readonly CROSS_ORIGIN_ATTRIBUTE = 'crossorigin';
  static readonly IMAGE_PARAM_VERSION = 'v';
  static readonly IMAGE_ELEMENT= 'img';
  static readonly TAB_BORDER_CLASS = 'tabs-container';

  static readonly ID_ELEMENTS={
    ElementWrapper:'wrapper',
    MovementsInputCross: '#movementsInputCross',
    CheckbookReset: '#checkbookReset'
  };

}

export enum ClassHTML {
  WebkitBox = '-webkit-box',
  BoxOrientVertical = 'vertical',
  BreakAll = 'break-all',
  TabsChecks = 'tabsChecks',
  TabsSlider = 'tabsSlider',
  Content = 'content',
  None = 'none',
  CalendarImage = '--bg-calendar-image',
  DisplayNone = 'd-none',
  DisplayBlock = 'd-block',
  Block = 'block',
  PaddingThree = 'p-3',
  ButtonClassDefaultAlertModal= 'btn-w',
  NoScrollModal ='no-scroll-modal',
  MovementsCard = 'modal-transaction'
}

export enum HTMLElements {
  ResponsiveServiceChecksStyle = '(min-width: 1024px) and (max-width: 1200px)'
}

export enum EventType {
  Click = 'click',
  Focus = 'focus',
  Keydown = 'keydown',
  Change = 'Change'
}

export enum ClassHTMLTabs {
  ScrollProductsUlNoneSidebar = 'scroll-ul-products-none-sidebar'
}

export enum HostnameType {
  IPv4 = 'localhost',
  IPv6=  '[::1]'
}

export enum ClassHTMLHeader {
  HeaderHeight = 'div-app-header'
}
