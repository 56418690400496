import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';
import { EventType } from 'src/core/constants/HTMLConstants';
import { Numbers } from 'src/core/constants/Numbers';

@Directive({
  selector: '[dropdownMaxHeight]'
})
/**
 * @Description Directiva que permite calcular dinamicamente la altura de los dropdowns
 * Se nevesita una estructura especifica para que funcione, validar en la documentacion bootrstrap
 * div ngbDropdown dropdownMaxHeight [numberOfElements]="" [bsDropdownPadding]="">
 *  div[ngbDropdownToggle]
 *  div[ngbDropdownMenu]
 * @param numberOfElements: number default value 5, numero de elementos que se  mostraran en el dropdown
 * @param bsDropdownPadding: boolean default value true
 * @Note los elementos ngbDropdown por defecto tienen la propiedad --bs-dropdown-padding-y=0.5rem
 * Suma el equivalente a 18px para permitir que se visualice adecuadamente,
 *  si se manda en false agrega una clase que reescrobe el valor en 0 aplica para paginacion.
 */
export class DropdownMaxHeightDirective implements AfterViewInit, OnDestroy {

  @Input() numberOfElements: number = Numbers.Five;
  @Input() bsDropdownPadding: boolean = true;

  constructor(private readonly el: ElementRef, private readonly render: Renderer2) { }
  private resizeObserver: ResizeObserver;

  ngAfterViewInit(): void {
    const dropdownToggle = this.el.nativeElement.querySelector('[ngbDropdownToggle]');
    dropdownToggle.addEventListener(EventType.Click, ()=>{
      this.setMaxHeight();
      this.scrollToSelectedElement();
    });

    dropdownToggle.addEventListener(EventType.Keydown, ()=>{
      this.setMaxHeight();
      this.scrollToSelectedElement();
    });

    this.resizeObserver = new ResizeObserver(()=>{
      this.setMaxHeight();
      this.scrollToSelectedElement();
    });

    this.resizeObserver.observe(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void{
    this.setMaxHeight();
  }

  private setMaxHeight():void{
    const dropdownMenu = this.el.nativeElement.querySelector('.dropdown-menu');
    const dropdownItems = dropdownMenu.children;
    let totalHeight = Numbers.Sixteen;
    for(let i = Numbers.Zero; i < Math.min(this.numberOfElements,dropdownItems.length); i++){
      totalHeight += dropdownItems[i].offsetHeight;
    }
    this.render.setStyle(dropdownMenu,'max-height', `${totalHeight + (this.bsDropdownPadding ? Numbers.Eighteen : Numbers.Three)}px`);
    this.render.setStyle(dropdownMenu,'overflow-y', 'auto');
    if(!this.bsDropdownPadding){
      this.render.addClass(dropdownMenu,'remove-bs-dropdown-padding-y');
    }
  }

  private scrollToSelectedElement(){
    const dropdownMenu = this.el.nativeElement.querySelector('.dropdown-menu');
    if(!dropdownMenu) return;
    const targetItem = dropdownMenu.querySelector(`.dropdown-item-selected`);
    if (!targetItem) return;
    const itemTop: number = targetItem.offsetTop;
    const itemBottom: number = itemTop + parseFloat(targetItem.offsetHeight);
    const menuScrollTop: number = dropdownMenu.scrollTop;
    const menuHeight:number = dropdownMenu.clientHeight;

    if(itemTop < menuScrollTop || itemBottom > (menuScrollTop + menuHeight)) {
      dropdownMenu.scrollTop = itemTop;
    }
  }
}
