import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { Char, Constants, FormatDatePosition, Language, Length, Months, Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
const I18N_VALUES = {
  es: {
    months:  Object.values(Months).map(month => month.toLowerCase()),
  }
};
@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
formatDay = false;
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split(Char.Slash);
      this.isDay(dateParts);
      if (this.formatDay) {
        return { day: toInteger(dateParts[FormatDatePosition.Day]), month: null, year: null };
      } else if (dateParts.length === Length.Two && isNumber(dateParts[FormatDatePosition.Day]) && isNumber(dateParts[FormatDatePosition.Month])) {
        return {
          day: toInteger(dateParts[FormatDatePosition.Day]),
          month: toInteger(dateParts[FormatDatePosition.Month]),
          year: null
        };
      } else if (dateParts.length === Length.Three && isNumber(dateParts[FormatDatePosition.Day]) && 
      isNumber(dateParts[FormatDatePosition.Month]) && isNumber(dateParts[FormatDatePosition.Year])) {
        return {
          day: toInteger(dateParts[FormatDatePosition.Day]),
          month: toInteger(dateParts[FormatDatePosition.Month]),
          year: toInteger(dateParts[FormatDatePosition.Year])
        };
      }
    }
    return null;
  }

  isDay(dateParts: string[]){
    if (dateParts.length === Length.One && isNumber(dateParts[FormatDatePosition.Day])) {
      this.formatDay = true;
    }
  }

  format(date: NgbDateStruct): string {
    return date ? 
    `${date.day.toString().
      padStart(Numbers.Two, Numbers.Zero.toString())}${Char.Slash}${I18N_VALUES[Language.Spanish].months[date.month - Numbers.One]}${Char.Slash}${date.year}` : Strings.EMPTY;
  }
}
export function toInteger(value: string): number {
  return parseInt(`${value}`, Numbers.Ten);
}

export function isNumber(value): value is number {
  return !isNaN(toInteger(value));
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `${Numbers.Zero.toString()}${value}`.slice(-Constants.TOTAL_DIGITS_DATE);
  } else {
    return Strings.EMPTY;
  }
}
