import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IAccountInter } from '../interface/IAccountInter';
import { Strings } from 'src/core/constants/Strings';

@Injectable({
  providedIn: 'root'
})
export class ServiceAccountInterService {

  private readonly accountInterService: BehaviorSubject<IAccountInter> = new BehaviorSubject<IAccountInter>({
    type: Strings.EMPTY,
    taxIdentifier: Strings.EMPTY,
    transfer: Strings.EMPTY,
    isErrorType: false,
    isErrorTaxIdentifier: false,
    isErrorTransfer: false
  });

  constructor() { }

  get accountInterObservable() {
    return this.accountInterService.asObservable();
  }

  set accountInterData(data: IAccountInter) {
    this.accountInterService.next(data);
  }
  get accountInterBS(): BehaviorSubject<IAccountInter> {
    return this.accountInterService;
  }
}
