import { PathModel } from 'src/app/models/PathModel';

interface IGetAccountBalanceStatusVisibilityRequest {
  idUsuario: number;
}

export class GetAccountBalanceStatusVisibilityRequest {
  constructor (public data: IGetAccountBalanceStatusVisibilityRequest){}

  get endpoint(): PathModel {
    return PathModel.GetAccountBalanceStatusVisibility;
  }
}
