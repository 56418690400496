import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { combineLatestWith, map, Observable, SubscriptionLike } from 'rxjs';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { ISimpleValue } from 'src/app/interface/ISimpleValue';
import { ModalOptions } from 'src/app/interface/modal-options';
import { AccountCardService } from 'src/app/services/account-card.service';
import { SelectAccountErrorService } from 'src/app/services/select-account-error.service';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { Constants } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { CardModalComponent } from 'src/app/shared/components/card-modal/card-modal.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectAccountComponent implements OnInit,OnDestroy {
  @Input() showPadding?: boolean = false;
  @Output() cardSelected = new EventEmitter;
  @Input() selectAccountLabel = Strings.SELECT_ACCOUNT.SelectAccount;
  @Input() styleCard: number;
  @Input() headSelAccount = Strings.SELECT_ACCOUNT.FromYourAccount;
  @Input() bodySelAccount = Strings.SELECT_ACCOUNT.SelectAccount;
  @Input() portability: boolean;
  @Input() accountOperations ={ operation: Strings.EMPTY, transactionType: Strings.EMPTY};
  @Input() getCreditCards: boolean;
  @Input() onlyCreditCards:boolean = false;
  @Input() filterCreditCards:string = Strings.EMPTY;
  @Input() filter: string[] = [];
  @Output() savedAccount = new EventEmitter();
  @Input() style: string = Strings.EMPTY;
  @Input() paddingCredit: boolean = true;
  @Input() changeSelAccount = Strings.CHANGE_ACCOUNT;
  @Input() skipSubAccounts: boolean = false;
  @Input() filterSkip: string[] = [];
  @Input() showImage: boolean = false;
  @Input() filterAmexAccount: boolean = false;
  @Input() maskClabe: boolean = false;
  @Input() isModal: boolean = true;
  @Input() codeAllowOperations: string = Strings.EMPTY;

  selectAccountError$: Observable<ISimpleValue>;
  isCreditPayment: boolean = false;
  idService: string;
  ownCreditCard:boolean = false;
  enableAccountChange: boolean = true;
  cardAccountServiceSL: SubscriptionLike;
  stateServicePayIdServiceSL: SubscriptionLike;

  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(
      this.selectAccountError.accountErrorObservable,
      this.cardAccountService.accountCard$
    ),
    map(([breakpoints, { value }, selectedCard]) => ({
      breakpoints,
      error: value,
      selectedCard
    }))
  );
  modal: NgbModalRef;

  constructor(
    private readonly modalService: ModalService,
    public cardAccountService: AccountCardService,
    private readonly selectAccountError: SelectAccountErrorService,
    private readonly stateServicePayIdService: StateServicePayIdService,
    private readonly responsiveService: ResponsiveService
  ) {
    this.selectAccountError$ = selectAccountError.accountErrorObservable;
  }

  ngOnInit(): void {
    this.ownCreditCard = this.stateServicePayIdService.service.id === Constants.AGENDABLE_SERVICE_CODE.Tcinb;
    this.selectAccountError.accountErrorData = { value: false };
    this.stateServicePayIdServiceSL= this.stateServicePayIdService.service$.subscribe(data => {
      if (data != null) {
        this.idService = data.id;
        if (this.idService === Constants.SERVICE_CODE.CreditPayment) {
          this.isCreditPayment = true;
        }
      }
    });
    this.cardAccountServiceSL = this.cardAccountService.accountCard$.subscribe(data => {
      if (!data.productId) {
        this.enableAccountChange = true;
      }
    });
  }
  cardInformation(event: { cardAccount, enableAccountChange }) {
    if (event.cardAccount.selected) {
        this.enableAccountChange = event.enableAccountChange;
        this.cardSelected.emit(event.cardAccount.source);
    }
  }

  get getCardSelected(): ICardAccount {
    this.selectAccountError.accountErrorData = { value: false };
    this.cardAccountService.selectedAccountCard.selected = false;
    this.cardAccountService.accountCard = { ...this.cardAccountService.selectedAccountCard };

    return this.cardAccountService.selectedAccountCard;
  }

  showCardModalComponent(content: TemplateRef<CardModalComponent>) {
    if(!this.cardAccountService.getModalAccountOpen){
      const modalOptions: ModalOptions = {
        ariaLabelledBy: Constants.MODAL_OPTIONS.AriaLabel,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal,
        windowClass:Constants.MODAL_OPTIONS.NoBorder,
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeMd
      };
      this.modal =  this.modalService.open(content, modalOptions);
      this.cardAccountService.setModalAccountOpen = true;
    }
  }

  savedAccountEmit(){
    this.savedAccount.emit(true);
  }

  ngOnDestroy(): void {
    this.cardAccountService.setModalAccountOpen = false;
    this.cardAccountServiceSL?.unsubscribe();
    this.stateServicePayIdServiceSL?.unsubscribe();
  }
}
