import { AfterContentInit, Component, HostListener, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from 'src/app/interface/modal-options';
import { BureauAlertsService } from 'src/app/services/bureau-alerts.service';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { ModalFreeBureauAlertComponent } from 'src/app/component/modal-free-bureau-alert/modal-free-bureau-alert.component';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ModalService } from 'src/app/shared/modal.service';
import { Numbers } from 'src/core/constants/Numbers';
import { ClassHTML, HTMLConstants } from 'src/core/constants/HTMLConstants';

@Component({
  selector: 'app-modal-leave-page',
  templateUrl: './modal-leave-page.component.html',
  styleUrls: ['./modal-leave-page.component.css']
})
export class ModalLeavePageComponent implements AfterContentInit {
  @Input() href: string = null;
  modalInstance: NgbModalRef;
  exclamationMark: string = Resources.EXCLAMATION_MARK_BLUE;

  constructor(private readonly bureauAlertsService: BureauAlertsService,
              private readonly modalService: ModalService) {}

  ngAfterContentInit(): void {
      this.showModal();
  }

  leave() {
    window.open(this.href, Strings.TARGET_BLANK);
    this.close();
    if (this.bureauAlertsService.leaveModal.value){
      const modalOptions: ModalOptions = {
        centered: false,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.GenericClass
      };
      this.modalService.open(ModalFreeBureauAlertComponent, modalOptions);
    }
  }

  close() {
    this.modalInstance?.close();
    if (this.bureauAlertsService.leaveModal.value){
      const modalOptions: ModalOptions = {
        centered: false,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.GenericClass
      };
      this.modalService.open(ModalFreeBureauAlertComponent, modalOptions);
    } else if (this.bureauAlertsService.modalOpenLave.options && this.bureauAlertsService.modalOpenLave.component){
      this.modalService.open(this.bureauAlertsService.modalOpenLave.component, this.bureauAlertsService.modalOpenLave.options);
    }
    this.bureauAlertsService.leaveModal.value = false;
    this.bureauAlertsService.leaveModalSuccess.value = false;
    this.bureauAlertsService.modalOpenLave = {
      modal: Strings.EMPTY,
      options: null,
      component: null
    };
    this.showModal(false);
  }

  @HostListener('window:popstate')
  onBack() {
    this.modalInstance?.close();
  }

  showModal (hideModal: boolean = true){
    const openModals = document.getElementsByTagName(HTMLConstants.MODAL_OPTIONS.NgbModalWindow);
    if(openModals.length <= Numbers.One && hideModal === true) return;
    Array.from(openModals).forEach((el, i) => {
      this.addOrRemoveClass(hideModal, el, i);
    });
    const backdrops = document.getElementsByTagName(HTMLConstants.MODAL_OPTIONS.NgbModalBackdrop);
    Array.from(backdrops).forEach((el, i) => {
     this.addOrRemoveClass(hideModal, el, i);
    });
  }

  addOrRemoveClass (hideModal: boolean, el: Element, i: number) {
    if(i === Numbers.Zero){
      hideModal?el.classList.add(ClassHTML.DisplayNone):el.classList.remove(ClassHTML.DisplayNone);
    }
  }

}
