import { PathModel } from 'src/app/models/PathModel';

type TConsultPerformanceRequest = {
    CodigoSubCuenta: string,
    Plazo: string,
    Monto: number,
    IdSession: string,
    Cuenta: string,
    InstruccionVencimiento: string
};

export class ConsultPerformanceRequest {
    constructor(public data: TConsultPerformanceRequest) { }

    get endpoint(): PathModel {
        return PathModel.ConsultPerformance;
    }
}
