import { Pipe, PipeTransform } from '@angular/core';
import { Char, Constants, Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';

@Pipe({
  name: Constants.PIPE_MAIN_CONFIRM
})
export class MaskAccountPipe implements PipeTransform {

  transform(value: string | number, maskNumber: number, customFormat: boolean = false): string {
    if (typeof value === 'number') value = value.toString();
    try {
      let outString = Strings.EMPTY;

      for (let i = Numbers.Zero; i  < maskNumber; i++) {
        outString += Char.Asterisk;
      }
      
      return `${customFormat ? Char.OpeningParenthesis : Strings.EMPTY}${outString}`
        + `${value.substr(value.length - Constants.REMOVE_DIGITS_TO_MASK_ACCOUNT)}`
        + `${customFormat ? Char.ClosingParenthesis : Strings.EMPTY}`;
    } catch (error) {
      return value;
    }
  }

}
