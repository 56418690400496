<content-wrapper
  *ngIf="vm$ | async as vm"
  [contentMargin]="contentMargin"
  [useFlex]="!vm.breakpoints.mobile"
>
  <div id="successful-operation" content class="width-content canvas"
    [ngClass]="vm.breakpoints.mobile || vm.breakpoints.desktop ? 'pt-3':'pt-4'"
    *ngIf="!errorMsg && vm.date else operationError">
    <div *ngIf="folio" class="d-flex flex-column justify-content-center align-items-center">
      <img
        [src]="check | cdnImage"
        class="icon-check"
        alt=""
        width="auto"
        height="auto"
      />
      <div class="pt-2 header-l default-color">{{ message }}</div>
      <div class="pt-2 body-m label default-color" [ngClass]="vm.breakpoints.mobile ? 'mb-3' : 'mb-2'">
        Folio: {{ folio }}</div>
      <show-amount [useBreak]="vm.breakpoints.mobile" label="" color="var(--secondary-800)"></show-amount>
      <div class="pb-2 detail-m">{{ operationDate }}</div>
      <app-shared-buttons
        class="ignore-element"
        [captureImage]="captureImage"
        [shareCapture]="captureImage"
        [hideMail]="true"
        [webCard]="true"
      ></app-shared-buttons>
    </div>
    <div
      id="main-content"
      [ngClass]="vm.breakpoints.mobile ? 'mt-3 pt-3' : 'pt-4'"
      *ngIf="folio">
      <div>
        <selected-card
          [classes]="'margin-card'"
          label="Desde tu cuenta"
          [classes]="vm.breakpoints.mobile ? 'selected-codeflex' : 'selected-codeflex pb-4'"
          [cardImage]="account.directorioImagen"
          [cardName]="account.tipoCuenta"
          [cardNumber]="numberAccount"
          [dividerClass]="vm.breakpoints.mobile ? 'my-2' : 'divider-class ms-0'"
          [cardId]="'card-information-success'"
          [showImage]="true"
        ></selected-card>
        <div class="mt-3">
          <selected-card
          [classes]="'margin-card'"
          [cardImage]="selectedCard.image"
          [cardName]="selectedCard.cardType"
          [cardNumber]="cardTransfer| maskAccount: 4"
          [cardId]="'card-information-success'"
          [dividerClass]="vm.breakpoints.mobile ? 'my-2' : 'divider-class ms-0'"
          [showImage]="true"
        ></selected-card>
        </div>
      </div>
      <div>
        <div
          class="header-xs default-color"
          [ngClass]="vm.breakpoints.mobile ? 'mt-2' : ''"
        >
          Fecha de {{vm.operation.toLowerCase()}}
        </div>
        <app-info-label
          classes="mt-2 margin-bottom-date"
          [head]="vm.date| customDate:'dd/Month/yyyy'"
          body="Fecha"
          [margin]="vm.breakpoints.mobile ? 'my-2' : 'm-2 ms-0'"
        ></app-info-label>
        <div
          class="detail-m text-align-justify secondary-color margin-label-info"
        >
         {{info}}
        </div>
      </div>
    </div>
    <div
        class="btn-container d-flex justify-content-center ignore-element button-spacing"
        *ngIf="folio"
    >
      <button
        class="colored-btn btnGeneric height-button"
        (click)="home()"
        [ngClass]="vm.breakpoints.mobile ? 'button-s' : 'button-m'"
        (keydown.enter)="$event.preventDefault()">
        Listo
      </button>
    </div>
    <div
      class="btn-container d-flex justify-content-center mt-3 ignore-element"
      *ngIf="folio"
    >
      <button
        class="outline-btn btnGeneric height-button"
        (click)="otherOperationButton()"
        [ngClass]="vm.breakpoints.mobile ? 'button-s' : 'button-m'"
        (keydown.enter)="$event.preventDefault()">
        Otra operación
      </button>
    </div>
  </div>
</content-wrapper>

<ng-template #operationError>
  <app-error-exception [otherOperation]="otherOperation" [showButtons]="true"
    [errorMessage]="errorMsg"></app-error-exception>
</ng-template>
