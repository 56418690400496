<ng-container *ngIf="vm$ | async as vm">
  <div class="d-flex justify-content-center text-center mb-2 title-terms"
  [ngClass]="vm.mobile ? 'header-m': 'header-l'"
  *ngIf="service == ''">
    {{WebCardStrings.WebCard.MODAL_TERMS_AND_CONDITION.Title}}
  </div>

  <div *ngIf="service.includes('CFE')"
  class="d-flex justify-content-center text-center style-cfe"
  [class.mb-2]="vm.mobile"
  [ngClass]="vm.mobile ? 'header-m': 'header-l'">{{termsConditions}}
  </div>
  <div *ngIf="service.includes('TELMEX') || service.includes('TELCEL')"
  class="d-flex justify-content-center text-center mb-2 style-telephone"
  [ngClass]="vm.mobile ? 'header-m': 'header-l'">{{termsConditionsTelcel}}
  </div>

  <div class="d-flex justify-content-center text-center mx-3 policy-terms"
    [ngClass]="vm.mobile ? 'header-s': 'header-m'" *ngIf="service == ''">
    {{WebCardStrings.WebCard.MODAL_TERMS_AND_CONDITION.Subtitle}}
    </div>


    <div class="terms-container moz-scroll body-s" [innerHTML]="termsAndConditions"
    *ngIf="service == ''">
  </div>

  <div *ngIf="service.includes('CFE')" class="terms-container body-s moz-scroll"
    [innerHTML]="termsAndConditionsCfe">
  </div>
  <div *ngIf="service.includes('TELMEX') || service.includes('TELCEL')"
  class="terms-container body-s moz-scroll" [innerHTML]="termsAndConditionsTelephone">
  </div>

  <div class="btn-container">
    <app-template-primary-button [properties]="btnProperties">
      {{Strings.MSG_POPUP_ACCEPT}}
    </app-template-primary-button>
  </div>
</ng-container>
