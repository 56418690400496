import { Constants } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';

export class AforeStrings {
  static readonly INACTIVE ='inactive';
  static readonly FORMATDATE:'yyyyMMddhhmmss';
  static readonly SELECT_PLACEHOLDER = 'Selecciona';
  static readonly SIMPLE_SELECT_OPTIONS = ['Sí', 'No'];
  static readonly REFERENCE_NUMBER = 'Referencia numérica';
  static readonly REFERENCE_TEXT = 'Referencia';
  static readonly AFORE_TITLE = 'Sucursales AFORE';
  static readonly AFORE_SUBTITLE = 'Consulta una sucursal cercana AFORE INBURSA';
  static readonly VOLUNTARY_SAVINGS_CONTRIBUTIONS = {
    Title: 'Aportaciones de Ahorro Voluntario',
    EnterAmountLabel: '¿Cuánto quieres aportar?',
    RequiredInfoLabel:
      'Los siguientes campos son requeridos para su formato de aportación',
    SelectLabels: {
      Type: 'Tipo de aportación',
      BusinessAcctivity: 'Ocupación y/o Actividad económica actual',
      MakeDeductible: '¿Desea hacer deducible la aportación?'
    },
    SelectTypeOptions: [
      'Aportaciones Voluntarias',
      'Aportaciones Complementarias de Retiro',
      'Aportaciones de Ahorro a Largo Plazo',
      'Aportaciones con Perspectiva de Inversión a Largo Plazo'
    ],
    SelectBusinessActivityOptions: ['Empleado Público', 'Empleado Privado'],
    ReferenceDefaultPlaceholder: 'Ej. 123',
    Placeholder: '----',
    WhatCharge: '¿Qué cargo?',
    DateThatLeaveCharge: 'Fecha en que dejó el cargo',
    Relationship: 'Parentesco',
    MarriageAlert: 'Mínimo 2 caracteres',
    DateMin: 'dd/mm/aaaa',
    InformationTable: [
      [
        'Deducción para efectos sobre el Impuesto Sobre la Renta (ISR)',
        'No Deducible',
        'Deducible',
        'Deducible',
        'Deducible'
      ],
      [
        'Retención del ISR sobre los rendimientos generados',
        '0.15%',
        '0.15%',
        '0.15%',
        '0.15%'
      ],
      [
        'Retención del ISR sobre la aportación realizada',
        'No aplica',
        '20%',
        '20%',
        '20%'
      ],
      [
        'Disposición',
        'En cualquier momento',
        'Cada 2 meses  posteriores a la primera aportación o al último retiro',
        'Cada 12 meses posteriores a la primera aportación o al último retiro',
        'Cuando se tenga derecho a recibir una pensión'
      ]
    ],
    DeductibleOptions: {
      'Aportaciones Voluntarias': false,
      'Aportaciones Complementarias de Retiro': true,
      'Aportaciones de Ahorro a Largo Plazo': null,
      'Aportaciones con Perspectiva de Inversión a Largo Plazo': true
    }
  };

  static readonly INFO_WITHDRAWAL_AFORE_STYLES = {
    Voluntary: 'voluntary',
    Complementary: 'complementary',
    LongTerm: 'long-term',
    Investment: 'investment'
  };

  static readonly WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS = {
    VoluntaryContributions:{
      Withdrawals: 'Retiro de Aportaciones Voluntarias',
      SubtitleFormVoluntary: 'Ingresa los siguientes datos',
      Alert: 'El monto de retiro debe ser menor o igual al de la subcuenta seleccionada',
      SectorNames: ['Sector Público', 'Sector Privado'],
      PublicSectorCode: '98',
      PrivateSectorCode: '99',
      WithdrawalTypes: ['Parcial', 'Total'],
      PartialWithdrawalCode: 'P',
      TotalWithdrawalCode: 'T',
      SubAccountType: ['AF07', 'AF14', 'AF15', 'AF24'],
      Withdrawal: 'Retiro de aportaciones <br> voluntarias',
      WithdrawalSubAccount: 'Subcuenta de retiro',
      TotalAmount: 'Cantidad Total de la Subcuenta',
      CodeAccount: 'Cuenta Clabe',
      Occupation:'Ocupación',
      WithdrawalType: 'Tipo de retiro',
      OnLine: 'En Línea',
      OutLine: 'Fuera De Línea',
      TitleAgeError: 'Lo sentimos, no se puede realizar la operación',
      SubtitleAgeError: 'No se cumple el requisito\nde edad para el retiro.',
      ErrorWithoutContributions: 'No puedes realizar el retiro porque actualmente no cuentas con aportaciones.'
    }
  };
  static readonly MAX_DATE_DETAILS_AFORE = { year: new Date().getFullYear(), month: new Date().getMonth() + Constants.MONTH.ONE, day: new Date().getDate() };
  static readonly MIN_DATE_DETAILS_AFORE = { year: new Date().getFullYear(), month: new Date().getMonth() + Constants.MONTH.ONE, day: new Date().getDate() };
  static readonly VALIDATION_DATE='La fecha final no puede ser menor a la fecha inicial';
  static readonly SELECT_DATE = 'Selecciona una fecha válida';
  static readonly CONSTANTS_AFORE = {
      NamePDFStateAccount: 'Estado_de_cuenta_afore',
      NamePDFCertification: 'certificacion_de_saldos',
      DateLastYear: '12/31/2023',

  };
  static readonly LABELS_AFORE = {
      MsgButton: 'Documentos Afore',
      TooltipList: [
        'Detalle de movimientos',
        'Generación de certificación de saldos',
        'Estado de cuenta de la AFORE'
      ],
      MovementType: 'movements detail',
      StatementAccount: 'statement of account',
      Period: 'Periodo del',
      To: 'Hasta',
      MovementDetail: 'Detalle de movimientos',
      ChoosePeriod: 'Elige el periodo',
      AccountStatus: 'Estado de Cuenta AFORE',
      MonthList: [
        'Enero - Abril',
        'Mayo - Agosto',
        'Septiembre - Diciembre'
      ]

    };

    static readonly ADD_PRODUCT = {
      LabelNssCurp: 'NSS',
      LabelRfc: 'RFC',
      LabelOrigin: 'Origen',
      LabelAfore: 'Cuenta Afore',
      TypeWithDrawal: 'partial'
    };

  static readonly DOWNLOAD_DOCUMENT_DETAIL_MOVEMENTS = 'Detalle_de_movimiento';

    static readonly AFORE_WITHDRAWAL_TOTAL = {
      TypeRetirement : 'Elige el tipo de retiro que deseas realizar',
      RetirementPension: 'retirementPension',
      Resolution : 'Resolución de pensión',
      RetirementByAge: 'Retiro por edad',
      ViewChildSelection: 'Selection',
      ViewOption : 'AforeTotal',
      ExceptionRetirementTotal : 'ExceptionRetirementTotal',
      RetirementTotal: 'Retiro total',
      SuccessfulData: 'Trámite registrado exitosamente',
      TitleSuccesAge: 'Retiro por Edad Avanzada',
      BirthCertificate: 'Adjuntar Acta de nacimiento digitalizada',
      Alert: 'Usted cuenta con la edad requerida para solicitar el trámite de Retiro Total por Amparo a la Edad Avanzada.'+
        '<br> Esta modalidad de Retiro contemplar el pago de las subcuentas Sar 92 e INFONAVIT 92.',
      AlertTablet: 'Usted cuenta con la edad requerida <br>para solicitar el trámite de Retiro<br> Total por Amparo a la Edad <br>Avanzada.'+
        '<br> Esta modalidad de Retiro <br>contemplar el pago de las<br> subcuentas Sar 92 e INFONAVIT 92.',
      AlertMobile: 'Usted cuenta con la edad<br> requerida para solicitar el trámite <br>de Retiro Total por Amparo a la <br>Edad Avanzada.'+
        '<br> Esta modalidad de Retiro <br>contemplar el pago de las <br>subcuentas Sar 92 e<br>INFONAVIT 92.',
      ModalTitleTotal: 'Validación de Expediente para solicitud de Retiro Total',
      ModalTitleTotalMobile: 'Validación de Expediente <br> para solicitud <br> de Retiro Total',
      Header: `<label class="header-xs-new">${Strings.RITGH_REQUEST_REJECTED} </label>`,
      TitleQuestion : '¿Qué retiro deseas realizar?',
      Total: 'total',
      Title: 'Validación de Derecho cargado o Edad Avanzada',
      AgeAlert: 'Usted cuenta con la edad requerida para solicitar el trámite de Retiro Total por Amparo a la EdadAvanzada.'+
        '<br> Esta modalidad de Retiro contemplar el pago de las subcuentas Sar 92 e<br>INFONAVIT 92.',
      VALID_RESOLUTION: {
        HEADER: 'Sin registro de resolución<br>de pensión',
        MESSAGE: 'No cuenta con una resolución de pensión registrada. Favor de acudir a su instituto emisor para solicitar más información',
      },
      VALID_AGE: {
        HEADER: 'Error',
        MESSAGE: 'No cuenta con 65 años cumplidos para un Retiro por Edad',
        TITLE: 'Validación de Derecho Cargado o Edad Avanzada',
        CONDITIONS: 'A continuación el Portal Bancario validará que usted cuente con una Resolución de Pensión o Negativa de Pensión registrada en Afore'+
          ' Inbursa; o bien que cumpla con los 65 años para el Retiro por Edad Avanzada.'
      },
      SpecificResolution: 'Usted cuenta con una resolución cargada con las siguientes especificaciones:',
      Type: '73 Tipo',
      Regime: 'Régimen',
      Zero: '00-',
      Benefit : 'Prestación',
      PensionPaid:'Pensión Pagada',
      IVType: 'IV - IV Tipo',
      InsuranceType: 'Tipo Seguro',
      TotalAmount: '$150.00',
      DepositAmount: 'Monto Depósito',
      TotalType: '02 - Retiro Total IMSS',
      WithdrawalType: 'Tipo Retiro',
      Date: '16/06/2023',
      ResolutionDate: 'Fecha Resolución',
      StartDate: 'Fecha Inicio de Pensión',
      CardNumber: '***2007',
      CardName: 'Nómina Efe',
      VeVe: 'Ve - Ve',
      Pension: 'Pensión',
      WithdrawalTotal: '02 - Retiro Total ',
      Conditions: '<p>Te notificaremos del estatus de tu solicitud vía correo electrónico.</p>' +
        '<p>En caso de que tu solicitud sea exitosa, el depósito se realizará en un máximo de 5 días hábiles.</p>'+
        '<p>Si tienes algún problema con esta solicitud comunícate con nosotros al:</p>' +
        '<p>800 90 90000</p>',
      InvalidProcess: 'No cuentas con un expediente válido para realizar este trámite',
      InvalidProcessMobile: 'No cuentas con un <br> expediente <br> válido para realizar este <br> trámite',
      InvalidProcesDetail: 'Actualiza tu información en la sucursal Inbursa más cercana',
      RetirementInProcess: 'No es posible realizar la solicitud',
      RetirementInProcessDetail: 'Ya cuentas con una solicitud activa de este trámite'
    };
    static readonly AFORE_WITHDRAWAL_MARRIAGE = {
      MarriageAlert: 'Mínimo 2 caracteres',
      MarriageRetirement: 'Retiro por Matrimonio',
      TotalWeeks: 'Tener al menos 150 semanas de cotización',
      SELECT: 'Seleccionar',
      Title: 'Retiro parcial por Matrimonio',
      TitleFormMarriage: 'Adjuntar Acta de Matrimonio',
      SubtitleFormMarriage: 'Adjuntar una copia digitalizada del documento de la Resolución de Pensión con un peso mínimo de 256 KB.',
      Marriage: 'marriage',
      Application: 'Su solicitud de Derecho para Retiro por Matrimonio fue aceptada por el IMSS, favor de seleccionar el botón “Continuar” para seguir con el trámite',
      TitleModalModality: 'Solicitud de Certificado de Derecho para Retiro por Matrimonio',
      TitleModalModalityMobile: 'Solicitud de Certificado de <br> Derecho para Retiro <br> por Matrimonio',
      MarriageApplication: 'Solicitud de retiros parciales por matrimonio',

      Conditions: 'No haber efectuado este retiro anteriormente',
      AddConditions: 'Tener una copia de tu Acta de Matrimonio en archivo con formato',
      Format: '(JPG, TIF y PDF)',
      Process: 'Asegurarte de capturar correctamente la fecha de matrimonio, ya que si no coincide con la que indique el Acta, se rechazará el trámite.',
      DetailsSuccess: 'Servicio registrado exitosamente',
      Details: 'Detalles acerca del Retiro por  Matrimonio',
      DetailsError: 'El archivo no se cargó correctamente',
      UMA: `El retiro por matrimonio equivale a 30 veces el valor de la UMA. Si continuas con este trámite, aceptas que se reducirá el monto de tu ahorro para el retiro,
       lo que puede impactar en tu pensión futura`,
      ErrorMessage: 'Asegúrese de que cumple con los requisitos de formato y tamaño antes de intentar nuevamente la carga.',
      MaxFile: 1000000,
      MinFile: 256000,
      Files: 'files',
      AlertSize: 'El tamaño del archivo es mayor al establecido.',
      FormatInvalid: 'El formato del archivo no es válido',
      File: 'file',
      Certificate: 'certificate',
      CertificateFileName: 'certificateFileName',
      Upload: 'upload',
      RequiredCertificate: 'Debe seleccionar un archivo',
      PensionResolution: 'Adjuntar Resolución de pensión',
      ValidFormat: 'Formatos: .jpg, .tif y .pdf<br>Peso mínimo : 256KB hasta 1MB',
      ValidFormatTotal: 'Formatos: .jpg, .tif y .pdf',
      MinimumWeight: 'Peso mínimo: 256KB hasta 1MB',
      DateMarriage: 'marriageDate',
      WeddingDate: 'Fecha de matrimonio',
      NameSpouse: 'Nombre de cónyuge',
      SecondNameSpouse: 'Segundo nombre',
      Name: 'spouseName',
      SecondName:'spouseSecondName',
      FirstName: 'Primer apellido de cónyuge',
      Pattern: 'spousePatternalSurname',
      LastName: 'Segundo apellido de cónyuge',
      Maternal: 'spouseMaternalSurname',
      GenderSpouse: 'Género del cónyuge',
      Gender: 'spouseGender',
      Masculine: 'Masculino',
      Feminine: 'Femenino',
      MarriageState: 'Entidad federativa del matrimonio',
      State: 'marriageState',
      DataSpouse: 'Datos de cónyuge',
      ConfirmInformation: 'Confirma tu información',
      Classes: {
        CloseButton: {
          Classes: 'cross-btn-modal-marriage-certificate marriage-requiremet-margin'
        },
        ContinueButton: {
          Classes: 'btn-afore modals-btn',
          CardModalClass: 'modal-retirement-unemployment',
          ErrorModal: 'modal-validate-error',
          MarriageCertificateModal: 'modal-marriage-certificate',
        }
      },
      CrossButtonModalConfirmSpouseData: 'confirm-btn-modal-confirm-spouse-data modals-btn',
      ConfirmSpouseButtonModalBtn: 'spuose-button modals-btn btn-afore',
      ModalConfirmSpouseData: 'modal-confirm-spouse-data',
      ModalSpouseData: 'modal-spuose-data',
      WebKitFillAvailable: '-webkit-fill-available',
      ButtonMargin: '0.625rem .6rem -26px -2px',
      ModalsBtnMarriageRequirementsButton: 'marriage-btn modals-btn',
      ModalRetirementMarriage: 'modal-retirement-marriage',
      ModalError: 'modalerror',
      CrossBUttonError: 'cross-button-error',
      RetirementUnemployementModalsBtnMarriageRequirementButton: 'retirement-unemployment modals-btn marriage-requirement-button',
      ModalsBtn: 'modals-btn',
      SpouseButtonModalsBtnMarriageSpouseData: 'spuose-button modals-btn btn-afore',
      ButtonmSpoueseButtonModalsBtnMarriageSpouseData: 'button-m spuose-button modals-btn marriage-spouse-data',
      CrossButtonModalSpuse: 'cross-btn-modal-spouse',
      NgbDpWeekDaySmall: 'ngb-dp-weekday small',
      NgbDpDay: 'ngb-dp-day',
      NgbDatePicker: 'ngb-datepicker',
      BodyM: 'body-m',
      ModalSpouse: 'modal-spouse',
      TransferDatepicker: 'transfer-datepicker',
      requiredAmount: 'Monto Solicitado'
    };
    static readonly ESTATES_AFORE = {
      AGUASCALIENTES: 'AGUASCALIENTES',
      BAJA_CALIFORNIA: 'BAJA CALIFORNIA',
      BAJA_CALIFORNIA_SUR: 'BAJA CALIFORNIA SUR',
      CAMPECHE: 'CAMPECHE',
      COAHUILA: 'COAHUILA',
      COLIMA: 'COLIMA',
      CHIAPAS: 'CHIAPAS',
      CHIHUAHUA: 'CHIHUAHUA',
      CIUDAD_DE_MEXICO: 'CIUDAD DE MEXICO',
      DURANGO: 'DURANGO',
      GUANAJUATO: 'GUANAJUATO',
      GUERRERO: 'GUERRERO',
      HIDALGO: 'HIDALGO',
      JALISCO: 'JALISCO',
      MEXICO: 'MEXICO',
      MICHOACAN: 'MICHOACAN',
      MORELOS: 'MORELOS',
      NAYARIT: 'NAYARIT',
      NUEVO_LEON: 'NUEVO LEON',
      OAXACA: 'OAXACA',
      PUEBLA: 'PUEBLA',
      QUERETARO: 'QUERETARO',
      QUINTANA_ROO: 'QUINTANA ROO',
      SANLUIS_POTOSI: 'SAN LUIS POTOSI',
      SINALOA: 'SINALOA',
      SONORA: 'SONORA',
      TABASCO: 'TABASCO',
      TAMAULIPAS: 'TAMAULIPAS',
      TLAXCALA: 'TLAXCALA',
      VERACRUZ: 'VERACRUZ',
      YUCATAN: 'YUCATAN',
      ZACATECAS: 'ZACATECAS',

  };

  static readonly MODAL_ADD_PRODUCT = {
    ModalCard: 'Agregar Producto',
    InvalidFormat: 'NSS inválido',
    LabelProduct: 'Producto',
    LabelOrigin: `${AforeStrings.ADD_PRODUCT.LabelOrigin}${':'}`,
    ModalID: 'addProductError',
    LabelMain: 'El cliente no se encuentra en <br> Afore - Inbursa (-9999)',
    AlertDataProduct: 'Requerido',
    GlobalStyle: 'container-add-product',
    Required: 'Requerido',
    AlertISSSTE: 'Si escogiste ISSSTE debes utilizar tu CURP 18 posiciones',
    AlertIMSS: 'Si escogiste IMSS debes utilizar tu NSS 11 posiciones',
    Imss: 'IMSS',
    Issste: 'ISSSTE',
    Nss: 'NSS',
    Curp: 'CURP',
    ModalAforeTitle: 'Agregar consulta del Estado de Cuenta Afore',
    ModalLeave:'Trámites',
    ModalDownloadAfore:'Más información'
  };

  static readonly YES = 'S';
  static readonly NO = 'N';
  static readonly TYPE_OPTIONS = ['AF07', 'AF14', 'AF15', 'AF24'];
  static readonly CHARGE_OPTIONS = ['PU', 'PR'];
  static readonly FORM = {
    ReferenceNumber: 'referenceNumber',
    Reference: 'reference',
    Charge: 'charge',
    Relationship: 'relationship'
  };
  static readonly ERROR_AFORE_APPLICATION = 'No pudimos realizar el registro de tu operación.<br>Inténtalo nuevamente.';
  static readonly DEDUCTIBILITY = ['Monto Deducible', 'Monto no Deducible'];
  static readonly NO_BALANCE_AVAILABLE = 'Saldo insuficiente para realizar la aportación';

  static readonly REGEX_CONSENT = /\(.*?\)/g;

  static readonly MODAL_ACCOUNT_ASSOCIATION = {
    Select: 'Seleccionar',
    Association: 'association',
    ChooseAccount: 'chooseAccount',
    ConfirmAccount: 'confirmAccount',
    Token: 'token',
    Success: 'success'
  };
  static readonly MODAL_EXCEPTIONS_AFORE = 'Lo sentimos, no fue posible <br> asociar tu AFORE';
  static readonly MODAL_EXCEPTIONS_AFORE_STYLE_TITLE = 'text-title';
  static readonly MODAL_EXCEPTIONS_AFORE_STYLE_BODY = 'text-body';

  static readonly ERROR_STATUS = {
    ErrorStatus: 'Expediente de Identidad y Enrolamiento biométrico'
  };
}

export enum AforeNumbers {
  TwentyEight = 28,
  TwentyNine = 29,
  Thirty = 30,
  ThirtyOne = 31,
  FourHundred = 400
}

export enum Slide{
  options = 'options',
}

export enum StylesAfore {
  displayNone = 'display-none-confirm',
  modalBackdrop = 'modal-backdrop',
  backdropColor = 'backdrop-color',
  modalContent ='modal-content',
  backdropTest='backdrop-test'
}
