import { Component, OnDestroy } from '@angular/core';
import { ModalService } from '../../../shared/modal.service';
import { ResponsiveService } from '../../../shared/responsive.service';
import { Resources } from '../../../../core/constants/Resources';
import { SubscriptionLike, fromEvent } from 'rxjs';
import { HtmlEvent, Keys } from 'src/core/constants/Constants';
@Component({
  selector: 'app-modal-local-services',
  templateUrl: './modal-local-services.component.html',
  styleUrls: ['./modal-local-services.component.css']
})
export class ModalLocalServicesComponent implements OnDestroy {

  breakpoints$ = this.responsiveService.observe;
  chevronRightConstrast: string = Resources.CHEVRON_RIGHT_CONSTRAST;
  remove: string = Resources.CLOSE_CIRCLE_BLUE ;
  key: SubscriptionLike;

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.initComponent();
  }

  initComponent(){
    this.key = fromEvent<KeyboardEvent>(document, HtmlEvent.Keydown).subscribe(x => {
      if (x.key === Keys.Tab) {
        x.preventDefault();
      }
    });
  }

  close() {
    this.modalService.close();
  }

  ngOnDestroy() {
    this.modalService?.close();
    this.key?.unsubscribe();
  }
}
