<ng-container *ngIf="vm$ | async as vm">
  <div id="main" class="px-3 pt-4" [class.px-4]="vm.breakpoints.tablet" [class.mx-3]="vm.breakpoints.tablet">
    <section class="d-flex flex-column align-items-center">
      <div class="text-container">
        <ng-container
          [ngTemplateOutlet]="Button"
          [ngTemplateOutletContext]="{id:1}">
        </ng-container>
        <span class="text">
          Ubica la opción <strong>Lector QR</strong> en la pantalla de inicio de tu app <strong>Inbursa Móvil</strong>
        </span>
      </div>
      <img class="pt-2" [src]="images.StepOne | cdnImage" alt="Parte 1" width="auto" height="auto">
    </section>
    <section class="d-flex flex-column align-items-center">
      <div class="text-container">
        <ng-container
          [ngTemplateOutlet]="Button"
          [ngTemplateOutletContext]="{id:2}">
        </ng-container>
        <span class="text">
          Con la función <strong>Lector QR</strong> escanea el <strong>código QR</strong>
          que te aparece en tu computadora
        </span>
      </div>
      <img class="pt-2" [src]="images.StepTwo | cdnImage" alt="Parte 1" width="auto" height="auto">
    </section>
    <section class="d-flex flex-column align-items-center">
      <div class="text-container">
        <ng-container
          [ngTemplateOutlet]="Button"
          [ngTemplateOutletContext]="{id:3}">
        </ng-container>
        <span class="text">
          Prepárate para ser identificado con<strong> Facepass </strong>en tu <strong>app Inbursa Móvil</strong>
        </span>
      </div>
      <img class="pt-2" [src]="images.StepThree | cdnImage" alt="Parte 1" width="auto" height="auto">
    </section>
  </div>
  <section class="body-m text-center pb-4 pt-3" [class.px-3]="!vm.breakpoints.desktop">
    Sigue las instrucciones en la app y en tu computadora para el ingreso en el portal
  </section>
  <div class="d-flex justify-content-center mb-4 mx-3">
    <button class="colored-btn button-m modals-btn" (click)="close()"> Aceptar </button>
  </div>
</ng-container>

<ng-template #Button let-id="id">
  <div class="circle header-m">{{id}}
  </div>
</ng-template>
