<section class="px-3 pt-3 pb-3">
  <div class="d-flex justify-content-end">
    <img role="button" aria-label="Close" width="24" height="24" [src]="iconClose | cdnImage" alt="Close"
      class="close" (click)="close()" ngbAutofocus (keydown.enter)="$event.preventDefault()">
  </div>
  <header class="header-s text-secondary-1200 text-center">Has excedido el límite establecido de traspasos</header>
  <main class="detail-m secondary-color pt-1 text-center">
    La operación se aplicará después de 48 horas.
    <br /><br />
    Puedes modificar tu límite de operación para que el depósito aplique de manera inmediata.
  </main>
  <footer class="mt-4">
    <button class="colored-btn modals-btn button-m w-100" (keydown.enter)="$event.preventDefault()"
    (click)="setFutureTransaction()">Sí, continuar</button>
    <button class="outline-btn modals-btn button-m w-100" (keydown.enter)="$event.preventDefault()"
    (click)="modifyLimit()">Modificar límite</button>
  </footer>
</section>
