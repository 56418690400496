<div class="column-flex modal-main-container">
    <span class="header-l text-align-center text-color">¡Felicidades!</span>
    <span class="header-xs text-align-center">Información de la operación</span>
    <span class="body-s  text-color text-align-center">
        En reconocimiento al excelente manejo de su 
        <span class="bold"> Tarjeta de crédito {{modalData.productName}}, </span>
        usted cuenta con un incremento Autorizado de su Línea de Crédito actual
    </span>
    <div class="column-flex box-info">
        <span class="header-xs"> Información del crédito </span>
        <span class="body-s text-color"> {{modalData.cardNumber | maskAccount: 4}} </span>
        <span class="detail-s"> Número de tarjeta </span>
    </div>
    <div class="column-flex box-info">
        <span class="body-s text-color"> {{modalData.productName}} </span>
        <span class="detail-s"> Producto </span>
    </div>
    <div class="column-flex box-info">
        <span class="header-xs"> Incremento autorizado </span>
        <span class="body-s text-color"> {{modalData.authorizedAmount | currency}} </span>
        <span class="detail-s"> Límite </span>
    </div>
    <button class="btn-primary-portal" (click)="close()" (keydown.enter)="$event.preventDefault()"> 
        Aceptar
    </button>
</div>