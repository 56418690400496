import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[focusError]',
})
export class FocusErrorDirective implements AfterViewInit {
  constructor(private readonly ref: ElementRef<HTMLSpanElement>) {}

  ngAfterViewInit(): void {
    const { left, top } = this.ref.nativeElement.getBoundingClientRect();
    window.scrollTo(left + window.scrollX, top + window.scrollY);
  }
}
