<ng-container *ngIf="vm$ | async as vm">
  <content-wrapper [contentMargin]="contentMargin" [useFlex]="!vm.breakpoints.mobile">
    <div content content class="width-content" *ngIf="accountType">
      <app-header-transfer-card [title]="title" [subtitle]="subtitle" [urlLocation]="codeflexPath"
        [returnFn]="return"  [optional]="vm.breakpoints.mobile ? 'header-m' : ''"
        [showSubtitle]="true"></app-header-transfer-card>

      <div id="main-content" [ngClass]="vm.breakpoints.mobile ? 'mt-2': ''">
        <div>
          <div [ngClass]="!vm.breakpoints.mobile ? 'mt-3' : ''">
            <show-amount [useBreak]="true" label="Cantidad" color="var(--primary-1000)"></show-amount>
          </div>
          <selected-card [classes]="vm.breakpoints.mobile ? 'pt-4 mt-3 margin-card' : 'mt-3 margin-card'"
            label="Desde tu cuenta"
            [cardImage]="account.directorioImagen"
            [cardName]="account.tipoCuenta"
            [cardNumber]="numberAccount"
            [cardId]="'card-information-codeflex'"
            [dividerClass]="'divider-class'"
            [showImage]="true"
          ></selected-card>
        </div>
        <div [ngClass]="!vm.breakpoints.mobile ? 'mt-4' : 'mt-3'">
          <selected-card
            [classes]="'selected-codeflex'"
            [cardImage]="selectedCard.image"
            [cardName]="selectedCard.cardType"
            [cardNumber]="cardTransfer| maskAccount: 4"
            [cardId]="'card-information-codeflex'"
            [dividerClass]="'my-2 ms-0 divider-class'"
            [classes]="'margin-card'"
            [showImage]="true"
          ></selected-card>
          <div class="header-xs default-color" [ngClass]="!vm.breakpoints.mobile ? 'mt-3' : 'mt-2'">
            {{titleDateLabel}}
          </div>
          <app-info-label classes="mt-2" [head]="vm.date | customDate:'dd/Month/yyyy'"
            [body]="dateLabel"
            [margin]="'margin-hr ms-0'" [bodyClass]="'detail-s color-details-s margin-body-date'"></app-info-label>
          <div class="detail-m text-align-justify mt-2 secondary-color"
          [ngClass]="vm.breakpoints.desktop ? 'mt-2' : 'mt-3'">
            {{message}}
          </div>
        </div>
      </div>
      <div
        class="btn-container d-flex justify-content-center button-spacing"
      >
        <button
          class="colored-btn btnGeneric height-button"
          [routerLink]="completedWithdrawalPath"
          [ngClass]="vm.breakpoints.mobile ? 'button-s' : 'button-m'"
        >
          Confirmar y continuar
          <img [src]="chevronRightIcon | cdnImage" alt=">" width="auto" height="auto"/>
        </button>
      </div>
      <div
        class="btn-container d-flex justify-content-center mt-3">
        <button
          class="outline-btn btnGeneric height-button"
          (click)="return()"
          [ngClass]="vm.breakpoints.mobile ? 'button-s' : 'button-m'"
          (keydown.enter)="$event.preventDefault()">
          Modificar
        </button>
      </div>
    </div>
  </content-wrapper>
</ng-container>
