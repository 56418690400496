import { PathModel } from 'src/app/models/PathModel';

type IModifyInsuranceAccountAliasRequest = {
  idSesion: string,
  alias: string,
  emisor: number,
  numeroDocumento?: number,
  tipoDocumento?: string,
  ramo: string
  
};
export class ModifyInsuranceAccountAliasRequest {
    constructor(public data: IModifyInsuranceAccountAliasRequest) { }
  
    get endpoint(): PathModel {
      return PathModel.ModifyInsuranceAccountAlias;
    }
  } 