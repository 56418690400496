import { PathModel } from 'src/app/models/PathModel';

export interface IDynamicCvvRequest{
        Account: string,
        CardFrontImageUrl: string,
        CardHolder: string,
        CardNumber: string,
        CardType : string,
        CVV2 ?: string,
        CardLimitATM ?: string,
        CardLimitPOS ?: string,
        ChipPin: string,
        ClavbeWebCard ?:string ,
        CreditMovements ?:{
          BalanceDay : number,
          BalancePoints : number,
          BalanceQuotas : number,
          ChargesApplied ?: [
            {
              Ammount: number,
              Card: string,
              ChargeDate: string,
              Coin: string,
              Description: string,
              OperationId: number
            }
          ],
          ChargesApply ?:[
            {
              Ammount: number,
              Card: string,
              ChargeDate: string,
              Coin: string,
              Description: string,
              OperationId: number
            }
          ],
          CreditAvailable?: number,
          CreditLimit?: number,
          InterestRate?: number,
          PayDayLimit?: number ,
          PendingTransactions?: number,
          TotalBalance?: number,
          TotalPayment?: number
        },
        CustomerId?: string,
        DCVV ?: string,
        DCVVTime ?: string,
        DebitMovements ?: {
          ListaMovimientosDebito?: [
            {
              Fecha: string,
              Monto: string,
              NumeroReferencia: string,
              Saldo: string,
              TipoServicio: string
            }
          ],
          Resumen?: {
            CuentaProductoID: string,
            DepositosPendientes: string,
            LineaDeCreditoDisponible: string,
            SaldoActual: string,
            SaldoRetenido: string,
            Saldodisponible: string
          }
        },
        DueDay ?:string,
        EspecificLimits ?: {
          ATMAmount : number,
          ATMMovements : number,
          CommerceAmount : number,
          CommerceMovements : number,
          InternetAmount : number
        },
        EspecificLocks ?: {
          ATM : boolean,
          INT : boolean,
          OPI : boolean,
          POS : boolean,
          TEL : boolean
        },
        ExpirationDate?: string,
        Holder?: boolean,
        IdCuentaProducto?: string,
        IdLocalizador?: string,
        IdPersona?: number,
        Lock?: boolean,
        LockString?: string,
        NickName?: string,
        Registered?: boolean,
        Solinume?: string,
        Source?: string,
        StateCard?: string,
        VisualizacionBotones?:string,
        Limits?: string,
        Locks?: {
          ATM: boolean,
          CODI:boolean,
          INT: boolean,
          OPI: boolean,
          POS: boolean,
          TEL: boolean,
          isATMDefault: boolean,
          WALLET: boolean,
          ApplePay: boolean,
          Apy: boolean,
          WEARABLE: boolean
        },
        VisualizacionBotonesBloqueado ?: {
          Bloqueos :string ,
          Cancelacion : string,
          Dcvv :string ,
          Descripcion : string,
          Estatus : string,
          Limites : string,
          Origen :string ,
          Personaliza : string,
          SaldosMovimientos : string,
          Visualizar : string
        },
        cvvWebCard ?: string
      }

export class DynamicCvvRequest{
    constructor(public data: IDynamicCvvRequest){}

    get endpoint(): PathModel{
        return PathModel.DynamicCVV;
    }
}
