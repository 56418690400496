import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { SubscriptionLike, combineLatestWith, distinctUntilChanged, interval, map, take, tap } from 'rxjs';
import { Alert } from 'src/app/interface/Alert';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { GetFacepassTransactionRequest } from 'src/app/interface/dto/GetFacepassTransactionRequest';
import { GetFacepassTransactionResponse } from 'src/app/interface/dto/GetFacepassTransactionResponse';
import { AlertService } from 'src/app/services/alert.service';
import { FacePassLoginService } from 'src/app/services/face-pass-login.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SentinelService } from 'src/app/services/sentinel.service';
import { StateQrService } from 'src/app/services/state-qr.service';
import { StorageService } from 'src/app/services/storage.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ClassHTML, Constants, FacepassStatusCode, GeolocationPermission, Numbers } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { LoginOrqRequest } from 'src/app/interface/dto/LoginOrqRequest';
import { LoginOrqResponse } from 'src/app/interface/dto/LoginOrqResponse';
import { SessionManagerService } from 'src/app/services/session-manager.service';
import { SidebarService } from 'src/app/shared/sidebar.service';
import { ExpressTransferService } from 'src/app/services/express-transfer.service';
import { PatrimonialStatusService } from 'src/app/services/patrimonial-status.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { AccountCardService } from 'src/app/services/account-card.service';
import { VoluntarySavingsContributionsService } from 'src/app/services/voluntary-savings-contributions.service';
import { ProductsService } from 'src/app/services/products.service';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { AccountsStatusService } from 'src/app/services/accounts-status.service';
import { BureauAlertsService } from 'src/app/services/bureau-alerts.service';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalFacepassManualComponent } from 'src/app/login/components/modal-facepass-manual/modal-facepass-manual.component';
import { DataCheckbookService } from 'src/app/services/data-checkbook.service';
import { StateVoluntaryWithdrawalService } from 'src/app/services/state-voluntary-withdrawal.service';
import { AppUtils } from 'src/core/utils/AppUtils';
import { ExchangeRateService } from 'src/app/services/exchange-rate.service';
import { PermittedOperations } from 'src/app/services/permitted-operations.service';
@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerComponent implements OnInit, OnDestroy {
  @ViewChild('content', { static: true }) content: ElementRef;
  idInterval: string = Strings.EMPTY;
  page: string = Constants.PAGE_DEFAULT.toString();
  facePassAlert: Alert = {
    class: ClassHTML.ErrorAlertClass,
    type: Constants.ERROR_LOGIN,
    iconHeader: Resources.CLOSE_WHITE,
    message: Strings.EMPTY,
    icon: Resources.CLOSE_WHITE_CIRCLE
  };
  errorAlert: Alert = {
    type: Constants.ERROR_LOGIN,
    class: ClassHTML.ErrorAlertClass,
    iconHeader: Resources.CLOSE_WHITE,
    message: Strings.EMPTY,
    icon: Resources.CLOSE_WHITE_CIRCLE
  };
  statusModal: boolean = false;
  navigation: Navigation;
  locationError: string = Strings.EMPTY;
  private geolocationState: PermissionState;
  private qrValidationSL: SubscriptionLike;
  private isLoading: boolean = false;

  vm$ = this.stateQrService.stateQrObservable.pipe(
    distinctUntilChanged(),
    tap(({ value }) => this.onChangeQrStatus(value.valueOf())),
    combineLatestWith(this.sentinelService.sentinel$, this.stateQrService.transaction$),
    map(([{ value }, { ip }, transaction]) => {
      window.Sentinel = ip;
      return { qrEvent: value, transaction };
    })
  );

  facepassIcon:string = Resources.FACEPASS;
  chevronRightIcon:string = Resources.CHEVRON_RIGHT_BLUE;
  closeButton: string = Resources.CLOSE_CIRCLE_BLUE;

  playAudio: boolean = false;

  constructor(
    private readonly stateQrService: StateQrService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly baseService: IBaseService,
    private readonly storage: StorageService,
    private readonly facePassLogin: FacePassLoginService,
    private readonly modalService: ModalService,
    private readonly sessionManager: SessionManagerService,
    private readonly sentinelService: SentinelService,
    private readonly loaderService: LoaderService,
    private readonly sidebarService: SidebarService,
    private readonly expressTransferService: ExpressTransferService,
    private readonly patrimonialService: PatrimonialStatusService,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly accountCardService: AccountCardService,
    private readonly voluntarySavingsContributionService: VoluntarySavingsContributionsService,
    private readonly productsService: ProductsService,
    private readonly accountInfoService: AccountInfoStatusService,
    private readonly accountsStatusService: AccountsStatusService,
    private readonly bureauAlertsService: BureauAlertsService,
    private readonly loyaltyService: LoyaltyService,
    private readonly dataCheckBook: DataCheckbookService,
    private readonly stateVoluntaryWithdrawalService: StateVoluntaryWithdrawalService,
    private readonly currencyExchangeService:ExchangeRateService,
    private readonly permittedOperations:PermittedOperations
  ) {
    this.paramsValidation();
  }

  private paramsValidation() {
    const alertMessage = this.alertService.alertMessage;
    if (alertMessage) {
      this.alertService.alertMessage = null;
      setTimeout(() => {
        this.errorAlert.message = alertMessage;
        this.alertService.showAlert(this.errorAlert);
      });
    }
  }

  ngOnInit(): void {
    this.clearData();
    this.sessionManager.closeSession({
      redirect: AppUtils.platform.isApp
    }).catch(Utils.printLogError);
    this.modalService.close();
    this.validateGeolocation();
    if(!this.stateQrService.stateQrData){
      this.playAudio = true;
    }
  }

  clearData(){
    this.voluntarySavingsContributionService.clearData();
    this.accountCardService.clearCardAccount();
    this.notifyAmountService.clearAmount();
    this.sidebarService.resetState();
    this.productsService.clearUserProducts();
    this.accountsStatusService.accountNumberCompare = Numbers.Zero;
    this.accountsStatusService.status = {value: false};
    this.accountInfoService.clearAccount();
    this.patrimonialService.isFutureTransaction = false;
    this.expressTransferService.isExpressTransfer = false;
    this.bureauAlertsService.bureauAlertExists = {value: null, isError: true};
    this.loyaltyService.isAvailable = false;
    this.dataCheckBook.resetData();
    this.stateVoluntaryWithdrawalService.resetVoluntaryWithdrawalProcessValues();
    this.currencyExchangeService.sigantureCurrency = null;
    this.permittedOperations.clearData();
  }

  private validateGeolocation() {
    if (navigator.permissions && navigator.permissions.query && navigator.geolocation) {
      navigator.permissions.query({ name: Constants.PERMISSIONS_GEOLOCATION }).then(result => {
        this.geolocationState = result.state;

        if (this.geolocationState !== GeolocationPermission.Granted) {
          this.openModal(this.content);
        }

        result.onchange = () => {
          this.geolocationState = result.state;
          if (this.geolocationState !== GeolocationPermission.Granted) {
            window.location.reload();
            this.openModal(this.content);
          } else {
            this.modalService.close(Constants.MODAL_OPTIONS.CloseModal);
          }
        };
      });
    } else {
      this.openModal(this.content);
    }
  }

  /*Metodo que muestra modal para activar ubicacion */
  openModal(content: ElementRef) {
    this.modalService.open(content, { windowClass: Constants.MODAL_OPTIONS.ContainerPopup, modalDialogClass: ModalConstants.MODAL_OPTIONS.IsCentered });
  }

  /*Metodo que cierra modal de ubicacion*/
  closeModal() {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.modalService.close();
  }

  /**
   * Método que se ejecuta al realizar click para iniciar por Facepass
   */
  clickEventQr() {
    if (this.alertService.alert?.type === Constants.ERROR_LOGIN) {
      this.alertService.showAlert(null);
    }

    this.stateQrService.stateQrData = { value: true };
    if (this.geolocationState !== GeolocationPermission.Granted) {
      this.openModal(this.content);
    }
    this.page = Constants.LOGIN_FACEPASS_ID.toString();
  }

  private onChangeQrStatus(status: boolean) {
    if (status) {
      this.playAudio = false;
      this.generateQRCode();
    }
    else {
      this.stateQrService.transaction = Strings.EMPTY;
      this.qrValidationSL?.unsubscribe();
    }
  }

  private async generateQRCode() {
    try {
      this.loaderService.showLoader();
      const geolocation = await Utils.getGeolocation();
      const response = await this.baseService.genericPost<GenericResponse<GetFacepassTransactionResponse>>(
        new GetFacepassTransactionRequest({ nombreAplicacion: Constants.BANK }), {});

      if (!response?.datos?.transaccion) {
        throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
      }

      this.stateQrService.transaction = response.datos.transaccion;

      this.qrValidationSL = interval(Constants.QR_FACEPASS_TIMER).pipe(
        take(Constants.QR_FACEPASS_ATTEMPTS + Constants.SUBSCRIBE_ONLY_ONCE)
      ).subscribe((attempt) => {
        if (attempt < Constants.QR_FACEPASS_ATTEMPTS) {
          this.validateQrTransaction(geolocation);
        }
        else {
          this.showFacepassError(Strings.QR_FACEPASS_TIMEOUT_MSG);
        }
      });
    } catch (error) {
      let errorMessage: string;
      if (error instanceof GeolocationPositionError) {
        errorMessage = error.code === GeolocationPositionError.PERMISSION_DENIED ?
          Strings.GEOLOCATION_PERMISSIONS_MSG : Strings.GEOLOCATION_ERROR_MSG;
      }
      else {
        const errorDetail = Utils.getErrorMsg(error);
        errorMessage = Utils.SERVICE_ERROR_MSG(errorDetail.msg, errorDetail.code);
      }

      this.showFacepassError(errorMessage);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  private async validateQrTransaction(geolocation: { latitude: number, longitude: number }) {
    if(!this.isLoading) {
      this.isLoading = true;
      try {
        const loginAccessRequest = new LoginOrqRequest({
          tipoAutenticacion: Constants.FACEPASS_AUTHENTICATION,
          guidFacePass: this.stateQrService.transaction,
          ip: this.sentinelService.sentinel.ip,
          latitud: geolocation.latitude,
          longitud: geolocation.longitude,
          medioAcceso: Constants.ACCESS_METHOD_PORTAL
        });

        const response = await this.baseService.genericPost<LoginOrqResponse>(loginAccessRequest, {});

        this.onSuccessAuthentication(response, geolocation);
      } catch (error) {
        this.isLoading = false;
        Utils.printLogError(error);
        const errorDetail = Utils.getErrorMsg(error);
        if(errorDetail.code !== FacepassStatusCode.Waiting && errorDetail.code !== FacepassStatusCode.Validating){
          this.showFacepassError(Utils.SERVICE_ERROR_MSG(errorDetail.msg, errorDetail.code));
        }
      }

      this.isLoading = false;
    }
  }

  private onSuccessAuthentication(response: LoginOrqResponse, geolocation: { latitude: number, longitude: number }) {
    try {
      if (!response?.datos || !response.datos.idUsuario || !response.datos.idSesion) {
        throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
      }

      const data = response.datos;
      this.storage.saveGeolocation({ latitude: geolocation.latitude, longitude: geolocation.longitude });
      this.storage.saveSession(data.idSesion, response.datos.tokenJwt);
      this.storage.saveUser({ name: data.nombre, lastName: data.apellidos, userId: data.idUsuario, applicationCode: data.codigoAplicacion });

      this.stateQrService.stateQrData = { value: false };
      this.facePassLogin.isFacePassLogin = { value: true };
      this.sessionManager.trackSession();
      this.router.navigate([PATH.LoginPassword], {
        state: {
          validNavigation: true
        }
      });
    } catch (error) {
      const errorDetail = Utils.getErrorMsg(error);
      this.showFacepassError(Utils.SERVICE_ERROR_MSG(errorDetail.msg, errorDetail.code));
    }
  }

  private showFacepassError(error: string) {
    this.stateQrService.stateQrData = { value: false };
    this.facePassAlert.message = error;
    this.alertService.showAlert(this.facePassAlert);
  }

  close() {
    this.stateQrService.stateQrData = { value: false };
  }

  showFacepassModal(){
    const modalOptions: ModalOptions = {
      size: Constants.MODAL_OPTIONS.SizeXl,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalFacepassManual
    };
    this.modalService.open(ModalFacepassManualComponent, modalOptions);
  }
}
