import { PathModel } from 'src/app/models/PathModel';

type TAforePartialWithdrawalRegistrationRequest = {
  nss: string,
  folioTys: string,
  folioAfore: string,
  numeroCuenta: string,
  cuentaClabe: string,
  numeroResolucion: string,
  llaveNegocio: string,
  otp: string,
  tipo: string,
  datosMatrimonio?: {
    primerNombreConyuge?: string,
    segundoNombreConyuge?: string,
    apellidoPaternoConyuge?: string,
    apellidoMaternoConyuge?: string,
    entidadFederativa?: string,
    fecha?: string,
    generoConyuge?: number
  }
};

export class AforePartialWithdrawalRegistrationRequest {
    constructor(public data: TAforePartialWithdrawalRegistrationRequest) { }

    get endpoint(): PathModel {
        return PathModel.AforePartialWithdrawalRegistration;
    }

}
