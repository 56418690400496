<div class="main-container">
  <div>
    <div class="d-flex justify-content-end">
      <img role="button" aria-label="Close" width="auto" height="auto" [src]="closeCircle | cdnImage" alt="Close"
      class="close" (click)="closeModal()">
    </div>
  </div>
    <div class="header">
      <p class="header_title"> Tarjetas </p>
    </div>
    <div class="card">
      <div class="background-image" id="cardGMM"></div>
      <div  class=" overlay-content">
        <label class="overlay-content_text"> Número Póliza:
          <label class="overlay-content_text_data"> {{ policy.Poliza }}  </label></label>
        <label class="overlay-content_text"> Fecha Vencimiento:
          <label class="overlay-content_text_data"> {{ policy.Vencimiento }} </label> </label>
        <label class="overlay-content_text"> Nombre Titular:
          <label class="overlay-content_text_data">  {{ policy.Nombre }} </label></label>
        <label class="overlay-content_text"> Número Cis: 
          <label class="overlay-content_text_data"> {{ policy.Cis }}  </label></label>
      </div>
    </div>
    <div>
      <label class="text"> Titular: <label>{{ policy.Nombre }} </label>
     
     </label>
    </div>
    <div class="body" *ngIf="showShared">
      <div class="body_content">
        <img [src]="shareImage | cdnImage" alt="shared" height="24" width="24" (click)="screenShot()"/>
      </div>
    </div>
  </div>
  