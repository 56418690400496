import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { IAdditionalContributions } from 'src/app/interface/IAdditionalContributions';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { MaskAccountPipe } from 'src/app/pipes/mask-account.pipe';
import { AccountCardService } from 'src/app/services/account-card.service';
import { AdditionalContributionsService } from 'src/app/services/additional-contributions.service';
import { NotifyRouteService } from 'src/app/services/notify-route.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Numbers, Tab } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';

@Component({
  selector: 'app-additional-contributions-confirm',
  templateUrl: './additional-contributions-confirm.component.html',
  styleUrls: ['./additional-contributions-confirm.component.css']
})
export class AdditionalContributionsConfirmComponent implements OnInit {

  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints }))
  );

  selectedCard!: ICardAccount;
  addContributionsData: IAdditionalContributions;

  title: string = Strings.ADDITIONAL_CONTRIBUTIONS.Title;
  subtitle: string = Strings.ADDITIONAL_CONTRIBUTIONS.Confirm.SubTitle;
  preview: string = PATH.AdditionalContributions;
  cardNumber: string = Strings.EMPTY;
  chevronRight: string = Resources.BTN_CHEVRON_RIGHT;

  constructor(private readonly responsiveService: ResponsiveService,
    private readonly accountCardService: AccountCardService,
    private readonly additionalContributionsService: AdditionalContributionsService,
    private readonly router: Router,
    private readonly notifyRouteService: NotifyRouteService) { }

  ngOnInit(): void {
    this.initComponent();
  }

  async initComponent() {
    try {
      if (this.additionalContributionsService.additionalContributions.typeContribution && this.additionalContributionsService.additionalContributions.policyNumber) {
        this.selectedCard = this.accountCardService.accountCard;
        this.cardNumber = new MaskAccountPipe().transform(this.accountCardService.accountCard.cardNumber, Numbers.Four);
        this.addContributionsData = this.additionalContributionsService.additionalContributions;
      } else {
        this.notifyRouteService.navigateToTab(Tab.Insurance);
      }
    } catch (error) {
      this.notifyRouteService.navigateToTab(Tab.Insurance);
    } 
  }
  
  continue() {
    this.router.navigate([PATH.AdditionalContributionsComplete]);
  }

  modify() {
    this.router.navigate([PATH.AdditionalContributions], {
      state: {
        modify: Strings.ADDITIONAL_CONTRIBUTIONS.ParameterOk
      }
    });
  }
}
