<div ngbDropdownItem id="userMenuItem">
  <div class="item-notification">
    <div class="body-card" [ngClass]="notification.isSelected ? stateSelected :''">
      <div class="body-card-message message-text">
          {{notification.tituloMensaje}}
      </div>
      <div class="description">
        <div class="description-text">
          {{notification.cuerpoMensaje}}
        </div>
      </div>
      <div class="date">
        <div class="date-text">
          {{ getDateOnText(notification.fechaRegistro, notification.fechaEnvio) }}</div>
      </div>
    </div>
  </div>
</div>
