import { Injectable } from '@angular/core';
import { ModalMyCardSelectOption } from 'src/app/interface/ModalMyCardSelectOption';
import { LockCardValueModel } from 'src/app/interface/LockCardValueModel';
import { Constants, DebitCardType, Numbers, OperationStatus } from 'src/core/constants/Constants';
import { ControlCardConstants, InputModalMyCard, OperationType } from 'src/core/constants/ControlCardConstants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalMyCardsComponent } from 'src/app/control-card/components/modal-my-cards/modal-my-cards.component';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ModalMyCardsExtensionThreeService } from 'src/app/services/modal-my-cards-three.extension.service';
import { NipInputPasswordService } from 'src/app/services/nip-input-password.service';
import { ControlCardService } from 'src/app/services/control-card.service';

@Injectable({
  providedIn: 'root'
})
export class ModalMyCardsExtensionService {

  constructor(
    readonly componentExtensionThree: ModalMyCardsExtensionThreeService,
    readonly nipSer: NipInputPasswordService,
    private readonly controlCardService: ControlCardService
  ){}

  setContributionType(contributionType: string, context: ModalMyCardsComponent) {
    context.contributionType.set(contributionType);
    context.contributionTypeError.set(false);
  }

  changeReason(event: ModalMyCardSelectOption, context: ModalMyCardsComponent) {
    context.reasonValue = event.text;
    context.modifyReception = false;
    context.tabsFunction(event.value);
  }

  lockOperation(locksValues: Array<LockCardValueModel>, context: ModalMyCardsComponent) {
    context.lockLimitsCardValues = locksValues;
    locksValues?.filter(lockItem => lockItem.Type === Constants.UPDATE_LOCK).forEach(lock => {
      const locksValue = lock.Value === OperationStatus.Blocked.toString();
      context.isContinueLockValid = true;
      context.showErrorLoad=false;
      switch (lock.Name) {
        case Constants.UPDATE_ATM_LABEL:
          context.locksAtm = locksValue;
          context.resetInputsDeactivate(locksValue, Constants.UPDATE_ATM_LABEL);
          break;
          case Constants.UPDATE_POS_LABEL:
          context.locksCommerce = locksValue;
          context.resetInputsDeactivate(locksValue, Constants.UPDATE_POS_LABEL);
          break;
          case Constants.UPDATE_INT_LABEL:
          context.locksInt = locksValue;
          context.resetInputsDeactivate(locksValue, Constants.UPDATE_INT_LABEL);
          break;
          case OperationType.UPDATE_CODI_LABEL:
          context.locksCodi = locksValue;
          context.resetInputsDeactivate(locksValue, OperationType.UPDATE_CODI_LABEL);
          break;
          case Constants.UPDATE_OPI_LABEL:
          context.locksOpi = locksValue;
          context.resetInputsDeactivate(locksValue, Constants.UPDATE_OPI_LABEL);
          break;
      }
    });
  }

  validOption(option: string, context: ModalMyCardsComponent) {
    if (option === InputModalMyCard.Block) {
      context.updateLimits();
    }
    if (option === InputModalMyCard.Modify) {
      context.modifystate = true;
      if (context.adminCardForm.valid) {
        context.getPin();
      }
    }
    if (option === InputModalMyCard.Cvv) {
      context.cvvstate = true;
      context.hideData= true;
      context.cvvCardForm.patchValue({cvvMyCards:this.componentExtensionThree.limitInput(this.componentExtensionThree.nip,ControlCardConstants.CVV_LENGTH)});
      if (context.cvvCardForm.valid) {
        context.helpIcon = Resources.HELP_ICON;
        context.getRegisterCvv();
      } else {
        context.helpIcon = Resources.QUESTION_MARK_RED;
      }
    }
  }

  validateAdminCard(context: ModalMyCardsComponent) {
    if (context.adminCardForm.invalid && context.selectModify === Strings.ACTIVE) { context.showRequiredForm = true; }
    if (!context.locksAtm && context.atmInformation > Constants.MINIMUM_ATM_AMOUNT && context.atmInformation !==
      context.getLocksLimitsInfo.Limits.Amounts.Atm) {
        context.lockLimitsCardValues.push({
          Name: Constants.UPDATE_ATM_LABEL,
          Type: Constants.UPDATE_AMOUNT, Value: context.atmInformation.toString()
        });
    }
  }
  validateZeroMovementsData(context: ModalMyCardsComponent) {
    context.lockLimitsCardValues?.filter(lockItem => lockItem.Type === Constants.UPDATE_LOCK).forEach(lock => {
      context.isContinueLockValid = true;
      switch (lock.Name) {
        case Constants.UPDATE_ATM_LABEL:
          context.validateContinueLock(context.atmValue);
          if (context.isContinueLockValid) {
            context.zeroAtm = false;
            context.locksAtm = false;
          } else { context.zeroAtm = true; }
          break;
        case Constants.UPDATE_POS_LABEL:
          context.validateContinueLock(context.commerceValue);
          if (context.isContinueLockValid) {
            context.zeroPos = false;
            context.locksCommerce = false;
          } else { context.zeroPos = true; }
          break;
      }
    });
    context.validateErrors();
  }
  validateErrors(context: ModalMyCardsComponent) {
    if (!context.zeroAtm && !context.zeroPos && !context.zeroInt && !context.zeroCodi) {
      context.continueSave = true;
    } else {
      context.continueSave = false;
    }
  }
  validateInputsAmount(context: ModalMyCardsComponent) {
    if (!context.locksCommerce && context.commerceInformation >= Constants.MINIMUM_COMMERCE_AMOUNT &&
      context.commerceInformation !== context.getLocksLimitsInfo.Limits.Amounts.Pos) {
        context.lockLimitsCardValues.push({
          Name: Constants.UPDATE_POS_LABEL, Type: Constants.UPDATE_AMOUNT, Value: context.commerceInformation.toString()
        });
    }
    if (!context.locksInt && context.onlineInformation >= Constants.MINIMUM_ONLINE_AMOUNT &&
      context.onlineInformation !== context.getLocksLimitsInfo.Limits.Amounts.Int) {
        context.lockLimitsCardValues.push({
          Name: Constants.UPDATE_INT_LABEL, Type: Constants.UPDATE_AMOUNT, Value: context.onlineInformation.toString()
        });
    }
    context.continueValidateInputsAmount();
  }
  validateAmounts(context: ModalMyCardsComponent) {
    const checks = [
      { info: context.commerceInformation, min: context.minimumCommerceAmount, lock: context.locksCommerce },
      { info: context.onlineInformation, min: context.minimumOnlineAmount, lock: context.locksInt },
      { info: context.foreinInformation, min: context.minimumForeingAmount, lock: context.locksOpi },
      { info: context.atmInformation, min: context.minimumAtmAmount, lock: context.locksAtm },
      { info: this.controlCardService.dataControls.Monthly.Amount,
        min: this.controlCardService.dataControls.Monthly.MinimumLimit, lock: this.controlCardService.dataControls.Monthly.Lock}
    ];

    for (const check of checks) {
      if (check.info < check.min && !check.lock) {
        context.amountValue = false;
        return;
      }
    }

    context.amountValue = true;
  }

  validateMaxAmounts(context: ModalMyCardsComponent) {
    const checks = [
      { info: context.commerceInformation, min: context.maximumCommerceAmount, lock: context.locksCommerce },
      { info: context.onlineInformation, min: context.maximumOnlineAmount, lock: context.locksInt },
      { info: context.foreinInformation, min: context.maximumForeingAmount, lock: context.locksOpi },
      { info: context.atmInformation, min: context.maximumAtmAmount, lock: context.locksAtm },
      { info: this.controlCardService.dataControls.Monthly.Amount,
        min: this.controlCardService.dataControls.Monthly.MaximumLimit, lock: this.controlCardService.dataControls.Monthly.Lock}
    ];
    if(context.getDataCardsInfo?.Card.CardType ===DebitCardType.GroceryVoucherCard){
      context.amountValue = true;
      return;
    }
    for (const check of checks) {
      if (check.info > check.min && !check.lock) {
        context.amountMaxValue = false;
        return;
      }
    }
    context.amountMaxValue = true;
  }

  continueValidateInputsAmount(context: ModalMyCardsComponent) {
    if (!context.locksCodi && context.codiInformation >= ControlCardConstants.MINIMUM_CODI_AMOUNT &&
      context.codiInformation !== context.getLocksLimitsInfo.Limits.Amounts.Codi) {
      context.lockLimitsCardValues.push({
        Name: OperationType.UPDATE_CODI_LABEL, Type: Constants.UPDATE_AMOUNT, Value: context.codiInformation.toString()
      });
    }

    if (!this.controlCardService.dataControls.Monthly.Lock && this.controlCardService.dataControls.Monthly.Amount >= ControlCardConstants.MINIMUM_MONTHLY_AMOUNT &&
      this.controlCardService.dataControls.Monthly.Amount !== this.controlCardService.dataControls.Monthly.OriginAmount) {
      context.lockLimitsCardValues.push({
        Name: OperationType.UPDATE_LIMIT_MONTHLY, Type: Constants.UPDATE_AMOUNT, Value: this.controlCardService.dataControls.Monthly.Amount.toString()
      });
    }
  }
  validateInputsLimit(context: ModalMyCardsComponent) {
    if (!context.locksAtm && context.atmValue > Constants.MOVEMENTS_ZERO &&
      context.atmValue !== context.getLocksLimitsInfo.Limits.Movements.Atm) {
        context.lockLimitsCardValues.push({
          Name: Constants.UPDATE_ATM_LABEL, Type: Constants.UPDATE_MOVEMENTS, Value: context.atmValue.toString()
        });
    }
    if (!context.locksCommerce && context.commerceValue > Constants.MOVEMENTS_ZERO &&
      context.commerceValue !== context.getLocksLimitsInfo.Limits.Movements.Pos) {
        context.lockLimitsCardValues.push({
          Name: Constants.UPDATE_POS_LABEL, Type: Constants.UPDATE_MOVEMENTS, Value: context.commerceValue.toString()
        });
    }
    if (!context.locksCodi && context.codiValue > Constants.MOVEMENTS_ZERO &&
      context.codiValue !== Numbers.Zero) {
      context.lockLimitsCardValues.push({
        Name: InputModalMyCard.Codi, Type: Constants.UPDATE_MOVEMENTS, Value: context.codiValue.toString()
      });
    }
  }

  resetInputsDeactivate(lockValue: boolean, lockName: string, context: ModalMyCardsComponent) {
    if (lockValue) {
      switch (lockName) {
        case Constants.UPDATE_ATM_LABEL:
          context.atmValue = context.getLocksLimitsInfo.Limits.Movements.Atm;
          context.atmInformation = context.getLocksLimitsInfo.Limits.Amounts.Atm;
          break;
        case Constants.UPDATE_POS_LABEL:
          context.commerceValue = context.getLocksLimitsInfo.Limits.Movements.Pos;
          context.commerceInformation = context.getLocksLimitsInfo.Limits.Amounts.Pos;
          break;
        case Constants.UPDATE_INT_LABEL:
          context.onlineInformation = context.getLocksLimitsInfo.Limits.Amounts.Int;
          break;
        case OperationType.UPDATE_CODI_LABEL:
          context.codiInformation = context.getLocksLimitsInfo.Limits.Amounts.Codi == null ? Numbers.Zero : context.getLocksLimitsInfo.Limits.Amounts.Codi;
          break;
        case  Constants.UPDATE_OPI_LABEL:
          //Solo Mock
          context.foreinInformation = ControlCardConstants.START_PERCENT;
          break;
      }
    }
  }

  closeModal($event: string, context: ModalMyCardsComponent) {
    context.modalService.close();
    const modalOptions: ModalOptions = {
      centered: true,
      size: Strings.EMPTY,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.MyCards,
    };
    context.modalService.open(ModalMyCardsComponent, modalOptions);
    context.selectCancel = Strings.ACTIVE;
    context.selectLock = Strings.EMPTY;
    context.selectModify = Strings.EMPTY;
    context.selectCVV = Strings.EMPTY;
    context.blockade = false;
    context.modify = false;
    context.cvv = false;
    context.cancel = true;
  }

  checkInterval(register: boolean, getCvv: boolean, context: ModalMyCardsComponent) {
    if (context.minutes === Constants.CVV_TIME_EXPIRED && context.seconds === Constants.CVV_TIME_EXPIRED && register
      || (context.minutes === Constants.CVV_TIME_EXPIRED && context.seconds === Constants.CVV_TIME_EXPIRED)
      || (context.minutes === Constants.CVV_TIME_EXPIRED && context.seconds === Constants.CVV_TIME_EXPIRED && getCvv)) {
        window.clearInterval(context.cvvTimer);
      context.dynamicCvvService();
    }
  }
}
