import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { FileDataService } from 'src/app/services/file-data.service';
import { ModalLoadFilesComponent } from 'src/app/component/modal-load-files/modal-load-files.component';
import { ModalService } from 'src/app/shared/modal.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { combineLatestWith, map } from 'rxjs';
import { Char, Constants, Format } from 'src/core/constants/Constants';
import { Format as FormatDate } from 'src/core/constants/FormatDate';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { DatePipes } from 'src/app/pipes/date.pipe';
import { NotifyClearFormService } from 'src/app/services/notify-clear-form.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { Utils } from 'src/core/utils/utils';
import { AlertService } from 'src/app/services/alert.service';
import { SearchComponentComponent } from 'src/app/shared/components/search-component/search-component.component';
import { SuaService } from 'src/app/services/sua.service';
import { SuaPayment } from 'src/app/interface/SuaPayment';
@Component({
  selector: 'app-modal-payment-receipt',
  templateUrl: './modal-payment-receipt.component.html',
  styleUrls: ['./modal-payment-receipt.component.css']
})
export class ModalPaymentReceiptComponent implements OnInit {

  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(this.paginationService.pagination),
    map(([breakpoints, pagination]) => ({ breakpoints, pagination: pagination.data }))
  );

  amount: number = Constants.AMOUNT_ZERO;
  elementsPerPage: number = Constants.PAGE_SIZE_ACCOUNT;
  bankCardIcon: string = Resources.BANK_CARD_WHITE;
  plusIcon: string = Resources.PLUS_CR_FR;
  dataReceipt: SuaPayment[] = [];
  xIcon: string = Resources.CLOSE_CIRCLE_BLUE;
  filterEmpty: boolean = false;
  selectedDate: string = Strings.EMPTY;
  dateFilter: string = Strings.EMPTY;
  filterDates: Set<string> = new Set<string>();
  refresh: string = Resources.REFRESH;
  currentSearchFilter: string = Strings.EMPTY;
  filteredItems: SuaPayment[] = [];
  @ViewChild('search') searchComponent: SearchComponentComponent;

  constructor(private readonly fileDataService: FileDataService,
    public readonly activeModal: NgbActiveModal,
    private readonly router: Router,
    private readonly ngModal: NgbModal,
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly paginationService: PaginationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly notifyClearFormService: NotifyClearFormService,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly alertService: AlertService,
    private readonly suaService: SuaService) { }

  ngOnInit(): void {
    this.initComponent();
  }

  initComponent(){
    this.dataReceipt = this.suaService.registries;
    this.filteredItems = this.dataReceipt;
    this.dateLabel();
    this.paginationService.setInitialData(this.dataReceipt, this.elementsPerPage);
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  goToPayment(registry: SuaPayment) {
    this.suaService.selectedRegistry = registry;
    this.notifyAmountService.amount = {
      amount: Utils.formatValue(registry.quantity, Format.Currency)
    };
    this.ngModal.dismissAll();
    this.activeModal.dismiss();
    this.notifyClearFormService.clearFormData = {value: false};
    this.router.navigate([PATH.NoSchedulableServices]);
  }

  addNewFileSua() {
    this.fileDataService.clearData();
    this.ngModal.dismissAll();
    const modalOptions: ModalOptions = {
      centered: true,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalSuaLoadFile
    };
    this.modalService.open(ModalLoadFilesComponent, modalOptions);
  }

  validateJustToPay(registry: SuaPayment) {
    if(registry.status.label === Strings.PAYMENT_RECEIPT.Validated){
      this.goToPayment(registry);
    }
  }

  private dateLabel() {
    this.filterDates = new Set();
    this.filteredItems.forEach(element => {
      this.filterDates.add(element.movement.date);
    });
  }

  receiveDataFromSearch(searchFilter: string) {
    this.currentSearchFilter = searchFilter;
    this.changeDetectorRef.detectChanges();
    this.paginationService.search(this.filterItems.bind(this));
  }

  receiveDateFromSearch(date: string) {
    this.dateFilter = date;
    this.changeDetectorRef.detectChanges();
    this.paginationService.search(this.filterItems.bind(this));
  }

  filterItems() {
    const value = this.currentSearchFilter?.toUpperCase();
    const datePipe = new DatePipes();
    const dateSearch = datePipe.transform(this.dateFilter, FormatDate.FormatDayMonthYear)?.toUpperCase();
    let result = [...this.dataReceipt];
    result = this.filterByDate(result, dateSearch);
    result = this.filterByText(result, value);
    this.filteredItems = result;
    this.dateLabel();
    return this.filteredItems;
  }

  filterByText(items: SuaPayment[], searchText: string){
    if(!searchText) {
      return items;
    }
    return items.filter(dataFilter =>
      (dataFilter.folio         ?.toUpperCase()?.includes(searchText)) ||
      (dataFilter.name          ?.toUpperCase()?.includes(searchText)) ||
      (dataFilter.registry      ?.toUpperCase()?.includes(searchText)) ||
      (dataFilter.status?.label ?.toUpperCase()?.includes(searchText)) ||
      (dataFilter.movement.date ?.toUpperCase()?.includes(searchText)) ||
      (dataFilter.movement.time ?.toUpperCase()?.includes(searchText)) ||
      (Char.CurrencySymbol.concat(dataFilter.quantity)?.toUpperCase()?.includes(searchText))
    );
  }

  filterByDate(items: SuaPayment[], filterDate: string) {
    const datePipe = new DatePipes();
    if(!filterDate) {
      return items;
    }
    return items.filter(dataFilter =>
      (datePipe.transform(dataFilter.movement.date, FormatDate.FormatDayMonthYear)?.toUpperCase() === filterDate)
    );
  }

  selectDate(date: string) {
    this.filterEmpty = date !== null;
    this.selectedDate = date;
    this.receiveDateFromSearch(this.selectedDate);
  }

  async refreshRegistries() {
    try{
      await this.suaService.consultRegistries();
      this.initComponent();
      this.searchComponent.searchFn(Strings.EMPTY);
      this.selectDate(null);
    } catch (error) {
      this.modalService.close();
      const errorDetail = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetail.msg,errorDetail.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      },
      {
        onSuccess: () => this.modalService.close()
      });
    }
  }
}
