<form (change)="sendInformation()">
  <div class="container-circle-info">
    <label class="label-service-to-pay mt-3 mb-3 header-xs" for="">{{informationLabel}}</label>
    <img [src]=" infoImage | cdnImage" class="icon-info" alt="i" width="24" height="24" 
    (click)="openInfoInvestment()" (keydown.enter)="openInfoInvestment()">
  </div>
  <div class="data-pay drop-shadow-m">
    <div class="col-12 mb-1 select display-investment">
      <label for="dueInvestment" class="m-2 position-absolute detail-s-highlight pos-label-input label-input">
        <span class="h6 small bg-white px-1">{{toTheExpiration}}</span>
      </label>
      <select [disabled]="disabledRenewal" id="dueInvestment" name="dueInvestment"
        class="form-select body-s input-detail" required role="button" style="font-family: inherit"
        (change)="renewal($any($event).target.value)"
        [ngClass]="{'border-red':withOutRenewal || withOutSelect}">
        <option [selected]="changeOptionInvestment == true || withOutSelect == true " value="">
          Selecciona </option>
        <option [selected]="elementsDueDateBack == data.name" *ngFor="let data of instructionAtDueDate"
          value="{{data.name}}">
          {{data.name}}</option>
      </select>
      <div *ngIf="withOutRenewal || withOutSelect" class="detail-m validation-error">
        Este campo es requerido.
      </div>
    </div>
    <div *ngFor="let dataI of informationInvestment" class="col-12 mb-1">
      <label for="{{dataI.detail}}" class="m-2 position-absolute detail-s-highlight pos-label-input label-input">
        <span class="h6 small bg-white px-1">{{dataI.detail}}</span>
      </label>
      <input type="text" id="{{dataI.detail}}" readonly="true"
        class="form-control inputs place-holder-main-transfer body-s" value="{{dataI.value}}">
    </div>
  </div>
</form>
