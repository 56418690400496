<div class="position-relative div-password mt-2" [ngClass]="{'focus-effect':inputTouched,'border-red':passwordError}">
  <ng-content select="[input]"></ng-content>
  <img
    role="button"
    class="eye"
    [src]="show ? (eye | cdnImage) : (eyeOff | cdnImage)"
    alt="Mostrar"
    width="24"
    height="24"
    (click)="showValue()"
  />
</div>
