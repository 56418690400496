import { Injectable } from '@angular/core';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { Strings } from 'src/core/constants/Strings';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { Char, Constants, Length, Numbers, Position } from 'src/core/constants/Constants';
import { GetAdditionalCardsRequest } from 'src/app/interface/dto/GetAdditionalCardsRequest';
import { GetAdditionalCardsResponse, Policies } from 'src/app/interface/dto/GetAdditionalCardsResponse';
import { BehaviorSubject, Observable } from 'rxjs';
import { ImageUtils } from 'src/core/utils/imageUtils';
import { UpdateAdditionalCardsRequest } from 'src/app/interface/dto/UpdateAdditionalCardsRequest';
import { UpdateAdditionalCardsResponse } from 'src/app/interface/dto/UpdateAdditionalCardsResponse';
import { AdditionalLocksLimitsModel } from 'src/app/interface/dto/AdditionalLocksLimitsModel';
import { UpdateAdditionalCardRequest } from 'src/app/interface/dto/UpdateAdditionalCardRequest';
import { UpdateAdditionalCardResponse } from 'src/app/interface/dto/UpdateAdditionalCardResponse';
import { GetAdditionalLocksLimitsRequest } from 'src/app/interface/dto/GetAdditionalLocksLimitsRequest';
import { GetAdditionalLocksLimitsResponse } from 'src/app/interface/dto/GetAdditionalLocksLimitsResponse';
import { CardByUserModel } from 'src/app/interface/CardByUserModel';
import { GetAdditionalCardsByUserRequest } from 'src/app/interface/dto/GetAdditionalCardsByUserRequest';
import { GetAdditionalCardsByUserResponse } from 'src/app/interface/dto/GetAdditionalCardsByUserResponse';
import { FormGroup } from '@angular/forms';
import { StorageService } from 'src/app/services/storage.service';
import { AdditionalCreditCardResponse } from 'src/app/interface/dto/AdditionalCreditCardResponse';
import { Utils } from 'src/core/utils/utils';
import { Resources } from 'src/core/constants/Resources';
import { ModalService } from 'src/app/shared/modal.service';
import { AlertService } from 'src/app/services/alert.service';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { ModalMyCardsComponent } from 'src/app/control-card/components/modal-my-cards/modal-my-cards.component';
import { AddressConstants } from 'src/core/constants/AddressConstants';
import { UserInfoRequest } from 'src/app/interface/dto/UserInfoRequest';
import { UserInfoResponse } from 'src/app/interface/dto/UserInfoResponse';
import { StringUtils } from 'src/core/utils/stringUtils';
import { AdditionalCreditCardRequest } from 'src/app/interface/dto/AdditionalCreditCardRequest';
import { MapService } from 'src/app/services/map.service';

export type TAditionalCard = {
  firstName: string,
  secondName: string
  lastName: string,
  secondlastName: string
  rfc: string
  gender: string
  civilStatus: string
  birthday: string
  percent: string
};

@Injectable({
  providedIn: 'root'
})

export class AdditionalService {
  private readonly productsClean: GetAdditionalCardsResponse = {
    Tarjetas: [],
    Polizas: [{}]
  };

  private readonly cardByUserClean: CardByUserModel = {
    cardNumber: Strings.EMPTY,
    image: Strings.EMPTY,
    holder: Strings.EMPTY,
    source: Strings.EMPTY,
    accountNumber: Strings.EMPTY,
    product: Strings.EMPTY
  };

  private readonly limitsClean: AdditionalLocksLimitsModel = {
    Locks: {
      Int: false,
      Pos: false,
      Atm: false,
      Opi: false,
      Wallet: false,
      Codi: false
    },
    Amounts: {
      Atm: Strings.EMPTY,
      Pos: Strings.EMPTY,
      Int: Strings.EMPTY,
      Wallet: Strings.EMPTY,
      Codi: Strings.EMPTY
    },
    Movements: {
      Atm: Numbers.Zero,
      Pos: Numbers.Zero,
    },
    CreditLine: Numbers.Zero,
    AdditionalCreditLine: Numbers.Zero
  };

  private readonly _products: BehaviorSubject<GetAdditionalCardsResponse> = new BehaviorSubject<GetAdditionalCardsResponse>(this.productsClean);
  private readonly _cardByUser: BehaviorSubject<CardByUserModel> = new BehaviorSubject<CardByUserModel>(this.cardByUserClean);
  private readonly _validateUserCreation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _cardHolder: string = null;
  private _cardNumber: string = null;
  private _errorAdditionalUpdated: { msg: string } = { msg: Strings.EMPTY };
  private _updateCard: boolean = false;
  private _locksLimits: AdditionalLocksLimitsModel = this.limitsClean;
  private readonly _status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _aditionalCardForm: BehaviorSubject<FormGroup> =
  new BehaviorSubject<FormGroup>(null);
  private _address:string=Strings.EMPTY;
  private _branchId: number = Numbers.Zero;
  private _addressObtained: string = Strings.EMPTY;

  constructor(
    private readonly baseService: IBaseService,
    private readonly storage: StorageService,
    private readonly modalService: ModalService,
    private readonly alertService: AlertService,
    private readonly mapService: MapService
  ) { }

  get products$() {
    return this._products.asObservable();
  }

  get products() {
    return this._products.getValue();
  }

  set products(data: GetAdditionalCardsResponse) {
    this._products.next(data);
  }

  get validateUserCreation$() {
    return this._validateUserCreation.asObservable();
  }

  get validateUserCreation() {
    return this._validateUserCreation.getValue();
  }

  set validateUserCreation(data:boolean) {
    this._validateUserCreation.next(data);
  }

  set cardHolder(holder: string) {
    this._cardHolder = holder;
  }

  get cardHolder(): string {
    return this._cardHolder;
  }

 set cardNumber(number: string) {
    this._cardNumber = number;
  }

  get cardNumber(): string {
    return this._cardNumber;
  }


 set updateCard(update: boolean) {
  this._updateCard = update;
}

get updateCard(): boolean {
  return this._updateCard;
}

  set additionalUpdated(additionalUpdated: { msg: string }) {
    this._errorAdditionalUpdated = additionalUpdated;
  }

  get additionalUpdated(): { msg: string } {
    return this._errorAdditionalUpdated;
  }

  get locksLimits() {
    return this._locksLimits;
  }

  set locksLimits(data: AdditionalLocksLimitsModel) {
    this._locksLimits = data;
  }

  get status$() {
    return this._status.asObservable();
  }

  get status() {
    return this._status.getValue();
  }

  set status(data: boolean) {
    this._status.next(data);
  }

  get cardByUser$() {
    return this._cardByUser.asObservable();
  }

  get cardByUser() : CardByUserModel {
    return this._cardByUser.getValue();
  }

  set cardByUser(data: CardByUserModel) {
    this._cardByUser.next(data);
  }

  clearProducts() {
    this._products.next(this.productsClean);
  }

  clearCardByUser() {
    this._cardByUser.next(this.cardByUserClean);
  }

  clearLocksLimits() {
    this._locksLimits = this.limitsClean;
  }

  clearAddress() {
    this._address = Strings.EMPTY;
    this._addressObtained = Strings.EMPTY;
  }

  async getAdditionalCards(request: GetAdditionalCardsRequest): Promise<GetAdditionalCardsResponse> {
    const response = await this.baseService.genericPost<GetAdditionalCardsResponse>(request, {
      isSOA: true
    });

    if ((!response || (!response.Tarjetas && !response?.Polizas))) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    } else {
      if (response.Tarjetas?.length === Length.Empty && (this.validObjectsPolicies(response) || this.validPolicies(response.Polizas))) {
        throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
      }
    }

    response.Tarjetas?.forEach(item => {
      item.Imagen = ImageUtils.getAdditionalImage(item.Imagen);
    });
    return response;
  }

  validObjectsPolicies(response: GetAdditionalCardsResponse): boolean {
    return !response.Polizas[Position.Zero]?.Auto && !response.Polizas[Position.Zero]?.Danos && !response.Polizas[Position.Zero]?.GMM && !response.Polizas[Position.Zero]?.Vida;
  }

  validPolicies(policies: Policies): boolean {
    return policies[Position.Zero].Auto?.length === Length.Empty && policies[Position.Zero].Danos?.length === Length.Empty &&
      policies[Position.Zero].GMM?.length === Length.Empty && policies[Position.Zero].Vida?.length === Length.Empty;
  }

  async updateAdditionalCards(request: UpdateAdditionalCardsRequest) {
    const response = await this.baseService.genericPost<UpdateAdditionalCardsResponse>(request, {
      isSOA: true
    });
    if ((!response || !response.Error)) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
  }

  async getAdditionalCardsByUser(request: GetAdditionalCardsByUserRequest): Promise<GetAdditionalCardsByUserResponse> {
    const response = await this.baseService.genericPost<GetAdditionalCardsByUserResponse>(request, {
      isSOA: true
    });
    if ((!response || !response.Tarjetas || response.Tarjetas.length === Length.Empty)) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response;
  }

  async getAdditionalLocksLimits(request: GetAdditionalLocksLimitsRequest): Promise<GetAdditionalLocksLimitsResponse> {
    const response = await this.baseService.genericPost<GetAdditionalLocksLimitsResponse>(request, {
      isSOA: true
    });

    if (!response) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return response;
  }

  async updateAdditionalCard(request: UpdateAdditionalCardRequest){
    const response = await this.baseService.genericPost<UpdateAdditionalCardResponse>(request, {
      isSOA: true
    });

    if ((!response || !response.Error)) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
  }

  async additionalCardRequest(account: string){
    this.mapService.showMap = true;
    const beneficiary = this.aditionalForm.value;
    const request = new AdditionalCreditCardRequest({
      idSesion       : this.storage.getSession(),
      soliNume       : account,
      primerNombre   : beneficiary.firstName.trim(),
      segundoNombre  : beneficiary.secondName.trim(),
      apellidoPaterno: beneficiary.lastName.trim(),
      apellidoMaterno: beneficiary.secondlastName.trim(),
      rfc            : beneficiary.rfc,
      curp           : beneficiary.curp,
      estadoCivil    : beneficiary.civilStatus.charAt(Position.Zero),
      sexo           : beneficiary.gender.charAt(Position.Zero),
      idSucursal     : Number(this.addressIdAdditional),
      porcentaje     : beneficiary.percent,
      fechaNacimiento: beneficiary.birthday
    });
    const response = (await this.baseService.genericPost<AdditionalCreditCardResponse>(request, { isSOA: true })).folioAltaAdicional;
    if(!response || isNaN(Number(response))){
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
    return Number(response);
  }

  async registeredAddress(){
    try {
      this.addressIdAdditional = Numbers.Zero;
      const request: UserInfoRequest = new UserInfoRequest({
        IdSession: this.storage.getSession()
      });
      const response = (await this.baseService.genericPost<UserInfoResponse>(request, { isSOA: true })).GeneralData.Direccion;
      const separator  = Char.Comma                  .concat(Char.WhiteSpace);
      const street     = response.Calle.trim()       .concat(Char.WhiteSpace);
      const colony     = AddressConstants.COLONY     .concat(Char.WhiteSpace, response.Colonia);
      const postalCode = AddressConstants.POSTAL_CODE.concat(Char.WhiteSpace, response.CodigoPostal);
      this.addressObtained = StringUtils.capitalizeWords(this.addressObtained.concat(
        response.Calle             ? response.NumeroExterior ? street : response.Calle.concat(separator) : Strings.EMPTY,
        response.NumeroExterior    ? response.NumeroExterior   .concat(separator) : Strings.EMPTY,
        response.Colonia           ? colony                    .concat(separator) : Strings.EMPTY,
        response.CodigoPostal      ? postalCode                .concat(separator) : Strings.EMPTY,
        response.MunicipioAlcaldia ? response.MunicipioAlcaldia.concat(separator) : Strings.EMPTY,
        response.Ciudad            ? response.Ciudad                              : Strings.EMPTY
      ).replace(AddressConstants.FORMAT_REGEX, Strings.EMPTY));
      return true;
    } catch (error) {
      this.modalService.close();
      const errorDetail = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetail.msg,errorDetail.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      },
      {
        onSuccess: () => {
          this.modalService.close();
          const modalOptions = {
            centered: true,
            size:Constants.MODAL_OPTIONS.SizeXl,
            modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalMyCards,
          };
          const ref = this.modalService.open(ModalMyCardsComponent, modalOptions);
          ref.componentInstance.modifyReception = true;
          ref.componentInstance.showBranches = true;
        }
      });
      return false;
    }
  }

  get aditionalForm$(): Observable<FormGroup> {
    return this._aditionalCardForm.asObservable();
  }

  get aditionalForm(): FormGroup {
    return this._aditionalCardForm.getValue();
  }

  set aditionalForm(data: FormGroup) {
    this._aditionalCardForm.next(data);
  }

  concatName(data: FormGroup): string {
    return `${data.value.firstName.trim()} ${(data.value.secondName==null ? Strings.EMPTY : data.value.secondName .trim())}
    ${data.value.lastName.trim()} ${data.value.secondlastName.trim()}`;
  }
  get addressAdditional(){
    return this._address;
  }
  set addressAdditional(data:string){
    this._address=data;
  }

  get addressIdAdditional(){
    return this._branchId;
  }

  set addressIdAdditional(id: number){
    this._branchId = id;
  }

  get addressObtained(){
    return this._addressObtained;
  }
  set addressObtained(data:string){
    this._addressObtained = data;
  }
}
