import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConstants, AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { Char, Constants, Tab } from 'src/core/constants/Constants';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { AppBridge } from 'src/core/utils/AppBridge';
import { Utils } from 'src/core/utils/utils';
import { AppUserModel } from 'src/app/models/AppUserModel';
import { AlertService } from 'src/app/services/alert.service';
import { FacePassLoginService } from 'src/app/services/face-pass-login.service';
import { LoaderService } from 'src/app/services/loader.service';
import { StateProfileService } from 'src/app/services/save-profile.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppCardModel } from 'src/app/models/AppCardModel';
import { AccountCardService } from 'src/app/services/account-card.service';
import { ExpressTransferService } from 'src/app/services/express-transfer.service';
import { HeaderTransferCardComponent } from 'src/app/shared/components/header-transfer-card/header-transfer-card.component';
import { AppNavigation } from 'src/app/models/AppNavigation';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { AppPolicyModel } from 'src/app/models/AppPolicyModel';
import { InsurancePolicyInfoStatusService } from 'src/app/services/insurance-policy-info-status.service';
import { AppCreditModel } from 'src/app/models/AppCreditModel';
import { ServiceCreditPaymentService } from 'src/app/services/service-credit-payment.service';
import { ServiceReferenceNumberService } from 'src/app/services/service-reference-number.service';
import { ServicePaymentTypeService } from 'src/app/services/service-payment-type.service';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { NotifyRouteService } from 'src/app/services/notify-route.service';
import { AppUtils } from 'src/core/utils/AppUtils';
import { ConfigurationApp } from 'src/app/services/servicesApp/configuration-app.service';
import { ToggleAccountBalanceService } from 'src/app/services/toggle-account-balance.service';
import { CardAddresseeService } from 'src/app/services/card-addressee.service';
import { ProductConstants } from 'src/core/constants/ProductConstants';


@Component({ template: Strings.EMPTY })
export class AppLoginComponent implements OnInit {

    constructor(private readonly loaderService: LoaderService,
        private readonly router: Router,
        private readonly storageService: StorageService,
        private readonly alertService: AlertService,
        private readonly stateProfileService: StateProfileService,
        private readonly facePassLoginService: FacePassLoginService,
        private readonly accountCardService: AccountCardService,
        private readonly route: ActivatedRoute,
        private readonly expressTransferService: ExpressTransferService,
        private readonly insurancePolicyInfoService: InsurancePolicyInfoStatusService,
        private readonly serviceCreditPaymentService: ServiceCreditPaymentService,
        private readonly serviceReferenceNumberService: ServiceReferenceNumberService,
        private readonly servicePaymentTypeService: ServicePaymentTypeService,
        private readonly configurationApp: ConfigurationApp,
        private readonly toggleAccountBalanceService: ToggleAccountBalanceService,
        private readonly cardAddresseeService: CardAddresseeService,
        private readonly notifyRouteService: NotifyRouteService) {
        loaderService.showLoader();
    }

    ngOnInit(): void {
        this.init().catch((error) =>{
          AppUtils.hideSpinner();
          this.loginError(error);
        });
    }

    private async init() {
        let target = this.route.snapshot.params[AppConstants.ROUTE];
        await this.getSession(target);
        if (target === Utils.gfiToRoute(PATH.Transfers) || target === Utils.gfiToRoute(PATH.Services) || target === Utils.gfiToRoute(PATH.Investment)) {
          if(AppUtils.platform.isApp && AppUtils.platform.isXamarin){
            await this.goToTransfer();
          }
        } else if (target === Utils.gfiToRoute(PATH.PaymentPolicies)) {
            await this.goToPolicy();
        } else if (target === Utils.gfiToRoute(PATH.CreditPayment)) {
          await this.goToCredit();
        } else if(target === Utils.gfiToRoute(PATH.TransferExpress)){
          this.isTransferExpress();
          target=PATH.TransferData;
          this.configurationApp.configurationApp = {
            ... this.configurationApp.configurationApp,
            target
          };
        }

      this.router.navigate([`${Char.Slash}${target}`]);
      AppUtils.hideSpinner();
      window.addEventListener(AppConstants.LISTENER_APP, this.navigationBack);
    }

  isTransferExpress() {
    this.expressTransferService.isExpressTransfer = true;
    this.cardAddresseeService.clearCardAddressee();
  }

    async getSession(target:string) {
        if(target === Utils.gfiToRoute(PATH.FrequentlyQuestions)) return;
        const userInfo = await AppBridge.invoke<AppUserModel>(AppMethod.GetUserInformation);
        this.configurationApp.setFirstLoginApp(true);
        if (!userInfo?.success || !userInfo.data) {
            throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
        }
        this.initUserInfo(userInfo.data,target);
        if (userInfo?.data?.UserId) {
            this.stateProfileService.reloadImages({ thumbnail: true });
            this.toggleAccountBalanceService.getAccountBalanceStatusVisibility();
        }
    }

    async goToTransfer() {
        const accountInfo = await AppBridge.invoke<AppCardModel>(AppMethod.GoToTransfer);
        if (!accountInfo?.success) {
            return;
        }
        if (accountInfo.data) {
            if (accountInfo.data.IsExpress) {
                this.expressTransferService.isExpressTransfer = true;
            }
            if (accountInfo.data.AccountNumber) {
                this.setAccount(accountInfo.data);
            }
        }
    }

    async goToPolicy() {
        const policyInfo = await AppBridge.invoke<AppPolicyModel>(AppMethod.GetPolicy);
        if (!policyInfo?.success) {
            throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
        }
        if (policyInfo.data) {
            this.setPolicy(policyInfo.data);
        }
    }

    async goToCredit() {
        const creditInfo = await AppBridge.invoke<AppCreditModel>(AppMethod.GetCredit);
        if (!creditInfo?.success) {
            throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
        }
        if (creditInfo.data) {
            this.setCredit(creditInfo.data);
        }
    }

    private initUserInfo(user: AppUserModel, targetParam: string) {
      this.storageService.saveSession(user.Session, user.Token);
      this.storageService.saveUser({
        personId: user.PersonId,
        userId: user.UserId,
        name: user.Name,
        lastName: user.LastName,
        applicationCode: user.codigoAplicacion
      });

      this.storageService.saveGeolocation({
        latitude: user.Geolocation.Latitude,
        longitude: user.Geolocation.Longitude
      });
      this.configurationApp.configurationApp = {
        isExternal: user?.Configuration?.isExternal,
        goBack: user?.Configuration?.goBack,
        target: targetParam,
        isFromLogin:  user?.Configuration?.isFromLogin
      };
      ProductConstants.ORIGIN_CONSULT_DATA = this.configurationApp?.configurationApp?.isFromLogin 
      ? ProductConstants.ORIGIN_CONSULT.Login: ProductConstants.ORIGIN_CONSULT.BackHome;


      this.facePassLoginService.isFacePassLogin = { value: user.IsLoggedWithFacepass };
      if (Object.values(Tab).includes(user.TabProduct as Tab)) {
        this.notifyRouteService.valueTab = user.TabProduct as Tab;
      }
    }

    private setAccount(selectedAccount: AppCardModel) {
        const accountCard: ICardAccount = {
            clabe: selectedAccount.Clabe,
            image: selectedAccount.Image,
            cardNumber: selectedAccount.AccountNumber,
            productId: selectedAccount.AccountId,
            balance: selectedAccount.Balance,
            selected: true,
            cardType: selectedAccount.AccountType,
            accountNumber: selectedAccount.AccountNumber,
            currency: selectedAccount.CurrencyType,
            accountType: selectedAccount.AccountType
        };
        this.accountCardService.selectedAccountCard = accountCard;
        this.accountCardService.selected = true;
    }

    private setPolicy(selectedPolicy: AppPolicyModel) {
        const policy = {
            Poliza: Strings.EMPTY,
            Cis: Strings.EMPTY,
            Familia: Strings.EMPTY,
            Nombre: Strings.EMPTY,
            Vencimiento: Strings.EMPTY,
            Ramo: selectedPolicy.Ramo,
            Carpeta: selectedPolicy.Carpeta,
            Emisor: selectedPolicy.Emisor,
            Tipo: Strings.EMPTY,
            NivelCobro: Strings.EMPTY,
            TraeCondiciones: false
        };
        this.insurancePolicyInfoService.insurancePolicy = policy;
    }

    private setCredit(selectedCredit: AppCreditModel) {
        this.serviceCreditPaymentService
            .serviceCreditPaymentData = { creditPayment: selectedCredit.NumeroCredito, isError: false };

        this.serviceReferenceNumberService
            .serviceReferenceNumberData = { referenceNumber: selectedCredit.ReferenciaPagoCredito, isError: false };

        this.servicePaymentTypeService.paymentTypeService = {
            options: { nextPayment: selectedCredit.ProximoPago, currentBalance: selectedCredit.SaldoLiquidarActual, prepaidCreditAmount: selectedCredit.MontoPrepagoCredito },
            type: Strings.EMPTY, excessAmount: selectedCredit.BolaceptaPRPP, isError: false
        };
    }

    private async loginError(error: { msg: string, code: number }) {
        const message = Utils.getErrorMsg(error);
        this.loaderService.hideLoader();
        this.alertService.showPopupAlert({
            header: Strings.MSG_POPUP_TITLE,
            message: Utils.SERVICE_ERROR_MSG(message.msg, message.code)
        }, {
            onSuccess: async () => {
                const navigationParams: AppNavigation = {
                    Data: AppScreen.Home
                };
                await AppBridge.invoke<string>(AppMethod.SendNativeScreen, navigationParams);
            },
        });
    }

    private async navigationBack(event: Event) {
        const route = event[AppConstants.APP_DATA];
        if (!HeaderTransferCardComponent.instance.goBack()) {
            const navigationParams: AppNavigation = {
                Data: route
            };
            await AppBridge.invoke<string>(AppMethod.SendNativeScreen, navigationParams);
        }
    }
}
