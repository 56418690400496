export class InvestmentsConstants {
  static readonly DAYS = '360';
  static readonly UPPER_LIMIT = 30000.0;
  static readonly FILTER_ACCOUNT = 'ASOCOB';
  static readonly WITHDRAWAL = 'Retiro';
  static readonly ADD_INVESTMENT = 'folder_Add.png';
  static readonly INVESTMENT_COVER = 'file-download.png';
  static readonly MODAL_MODIFY_INVESTMENT = 'modalModifyInvestment';
  static readonly SHOW_PDF = 'ShowPDF';
  static readonly BLANK_SPACE = ' ';
  static readonly COVER = 'CARATULA';
  static readonly HIDE_MODIFY_INVESTMENT = 'container-modify-investment';


  static readonly Type = {
    CTAPAG: 'CTAPAG'
  };

  static readonly  PROMISSORY_NOTE = 'cuenta pagare';
  static readonly  INBURSA_DEPOSIT_CERTIFICATE = 'constancia de deposito inbursa';

}

export class InvestmentFundsConstants {
  static readonly INVESTMENT_FOUND_TYPE='DINBUR';
  static readonly ACCOUNTS_EFE_ID =[
    'EFED',
    'EFEN',
    'NOM',
    'EFE',
    'CT'
  ];
  static readonly FORMAT_AMOUNT = '1.6-6';
  static readonly TOTAL_TITLES = 125.250536;
}
