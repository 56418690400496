import { Component, OnInit, Input } from '@angular/core';
import { ButtonProperties } from 'src/app/interface/IButtonProperties';
import { Strings } from 'src/core/constants/Strings';
import { ResponsiveService } from 'src/app/shared/responsive.service';

@Component({
  selector: 'app-template-secondary-button',
  templateUrl: './template-secondary-button.component.html',
  styleUrls: ['./template-secondary-button.component.css']
})
export class TemplateSecondaryButtonComponent implements OnInit {

  @Input() properties: ButtonProperties = {
    styles: {},
    classes: Strings.EMPTY,
    doAction: () => { }
  };

  vm$ = this.responsiveService.observe;

  constructor(private readonly responsiveService: ResponsiveService) {}

  ngOnInit(): void {}
}
