import { Injectable } from '@angular/core';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { StorageService } from 'src/app/services/storage.service';
import { NotifyClearFormService } from 'src/app/services/notify-clear-form.service';
import { ServiceCreditPaymentService } from 'src/app/services/service-credit-payment.service';
import { ServiceReferenceNumberService } from 'src/app/services/service-reference-number.service';
import { ServicePaymentTypeService } from 'src/app/services/service-payment-type.service';
import { Utils } from 'src/core/utils/utils';
import { Strings } from 'src/core/constants/Strings';
import { CreditDetailsRequest } from 'src/app/interface/dto/CreditDetailsRequest';
import { ICreditsData } from 'src/app/interface/ICreditsData';
import { CreditDetailsResponse } from 'src/app/interface/dto/CreditDetailsResponse';
import { GetCreditAmortizationRequest } from 'src/app/interface/dto/GetCreditAmortizationRequest';
import { GetCreditAmortizationResponse } from 'src/app/interface/dto/GetCreditAmortizationResponse';
import { CreditCardMovementsRequest } from 'src/app/interface/dto/CreditCardMovementsRequest';
import { Source } from 'src/core/constants/Constants';
import { CreditCardMovementsResponse } from 'src/app/interface/dto/CreditCardMovementsResponse';
import { CreditCardInfoStatusService } from 'src/app/services/credit-card-info-status.service';

@Injectable({
  providedIn: 'root'
})
export class CreditDetailsMovementsService {

  constructor(
    private readonly baseService: IBaseService,
    private readonly storageService: StorageService,
    private readonly creditCardInfoStatusService: CreditCardInfoStatusService,
    private readonly notifyClearFormService: NotifyClearFormService,
    private readonly serviceCreditPaymentService: ServiceCreditPaymentService,
    private readonly serviceReferenceNumberService: ServiceReferenceNumberService,
    private readonly servicePaymentTypeService: ServicePaymentTypeService
  ) { }

  async getCreditDetails(credit: ICreditsData): Promise<string |
  CreditDetailsResponse> {
    try {
      const request = new CreditDetailsRequest({
        IdSession: this.storageService.getSession(), NumeroCredito: credit.NumeroCredito
      });
      const response = await this.baseService.genericPost<CreditDetailsResponse>(request, { isSOA: true });
      this.notifyClearFormService.clearFormData = { value: false };
      this.serviceCreditPaymentService.serviceCreditPaymentData = { creditPayment: response.NumeroCredito.toString(), isError: false };
      this.serviceReferenceNumberService.serviceReferenceNumberData = { referenceNumber: response.ReferenciaPagCredito, isError: false };
      this.servicePaymentTypeService.paymentTypeService = {
        options: { nextPayment: response.ProximoPago, currentBalance: response.SaldoLiquidarActual, prepaidCreditAmount: response.MontoPrepagoCredito },
        type: Strings.EMPTY, excessAmount: response.BolaceptaPRPP, isError: false
      };
      return response;
    }
    catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      return Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code);
    }
  }

  getAmortization(session: string, numCredit: string){
    const request = new GetCreditAmortizationRequest({
      IdSession: session,
      NumCredito: numCredit
    });
    return this.baseService.genericPost<GetCreditAmortizationResponse>(request, { isSOA: true });
  }

  async getInfoBalance(account: string) {
    const request = new CreditCardMovementsRequest({
      CardNumber: account,
      IdSession: this.storageService.getSession(),
      Source: Source.Infinitus,
      IsAditional: false
    });
    const response = await this.baseService.genericPost<CreditCardMovementsResponse>(request, { isSOA: true });
    this.creditCardInfoStatusService.balances = response.CreditMovements.Balances;
    return response;
  }


}
