import { PathModel } from './../../models/PathModel';

export class ServiceDetailsRequest {
    constructor(){ }

    get endpoint(): PathModel {
        return PathModel.ServiceDetails;
    }
}


