<ng-container *ngIf="vm$ | async as vm">
  <div class="container-sua-load-file " id="mod-load-files">
    <div class="d-flex justify-content-end p-modify-btn">
      <img role="button" aria-label="Close" width="auto" height="auto" [src]="iconClose | cdnImage"
        alt="Close" class="close" (click)="closeModalLoad()" ngbAutofocus>
    </div>

    <div class="p-body-load">
      <div id="headerText" class=" flex-row col-12 pl-2 d-flex justify-content-center align-items-center margin-title">
        <label class="color-header" [ngClass]="vm.breakpoints.mobile ? 'header-mobile' : 'header-s'">
          Para realizar un nuevo pago sube el archivo generado por el SUA</label>
      </div>

      <div>
        <app-load-files (disabledButtonEmitter)="enableButton($event)" [showRegistries]="showRegistries">
        </app-load-files>
        <button
          [disabled]="disabledButton"
          [ngClass]="disabledButton ? 'disabled-btn' : ''"
          class="send-sua button-m" (click)="sendFile()">
          <span> Enviar </span>
          <img [src]="shapeContinue | cdnImage" width="auto" height="auto" alt=">">
        </button>
      </div>
    </div>

  </div>
</ng-container>
