<ng-container *ngIf="vm$ | async as vm">
  <div class="container-padding">
    <div class="d-flex flex-row-reverse">
      <img
        role="button"
        class="close"
        aria-label="Close"
        (click)="exitSendEmail()"
        [src]="iconClose | cdnImage"
        alt="Close"
        width="auto"
        height="auto" ngbAutoFocus
      />
    </div>
    <div class="header-l text-center mt-2">Enviar correo electrónico</div>
    <div class="body-m mt-3 text-center" [class.px-4]="!vm.mobile">
      Si deseas enviar el correo a más de una cuenta de correo electrónico, sepáralos con una coma ( ‘ , ’ ).
    </div>
    <form [formGroup]="emailDataForm" class="mt-2" [class.px-2]="!vm.mobile">
      <div class="row mt-4">
        <div class="col-sm-12">
          <div class="d-flex justify-content-start">
            <label for="emailBeneficiary" class="body-s">Correo del beneficiario</label
            ><br />
          </div>
          <div class="responsive-mobile">
            <input
              class="detail-s col-12 inp"
              placeholder="Escribe aquí el correo del beneficiario"
              required
              type="email"
              id="emailBeneficiary"
              name="email"
              autocomplete="off"
              formControlName="email"
              [maxlength]="emailLength"
              cleanInput [cleanRegex]="regexEmail"
            />
            <hr class="my-2" />
            <div class="spacing">
              <ng-container *ngIf="emailDataForm.get('email')?.touched">
                <span
                  *ngIf="emailDataForm.get('email').hasError('required')"
                  class="required"
                >
                  {{ errorMsg }}
                </span>
                <span
                  *ngIf="emailDataForm.get('email').hasError('email')"
                  class="required"
                >
                  Correo no válido
                </span>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="col-sm-12 mt-1">
          <div class="d-flex justify-content-start">
            <label
              for="emailCopy"
              class="body-s margin-bottom"
              [ngClass]="{ 'margin-bottom': vm.mobile }"
            >
              Correo con copia a</label
            ><br />
          </div>
          <div class="responsive-mobile">
            <input
              class="detail-s col-12 inp"
              id="emailCopy"
              name="emailCopy"
              formControlName="emailCopy"
              autocomplete="off"
              placeholder="Escribe aquí el correo"
              type="email"
              [maxlength]="emailCopiesLength"
              cleanInput [cleanRegex]="regexEmail"
            />
            <hr class="my-2" />
            <div *ngIf="emailDataForm.get('emailCopy')?.touched; else spacing">
              <span
                *ngIf="emailDataForm.get('emailCopy').hasError('email'); else spacing"
                class="required"
              >
                Correo no válido
              </span>
            </div>
          </div>
        </div>

        <div class="col-sm-12 mt-1">
          <div class="d-flex justify-content-start">
            <label class="body-s">Escribe aquí tu mensaje del correo</label
            ><br />
          </div>
          <div class="responsive-mobile">
            <input
              id="emailMsg"
              name="emailMsg"
              formControlName="emailMsg"
              autocomplete="off"
              class="detail-s col-12 inp"
              [maxlength]="commentsLength"
              placeholder="Mensaje del correo"
              cleanInput [cleanRegex]="regexComments"
            />
            <hr class="my-2" />
          </div>
          <div class="spacing">
            <ng-container *ngIf="emailDataForm.get('emailMsg')?.touched">
              <span
                *ngIf="emailDataForm.get('emailMsg').hasError('required')"
                class="required"
              >
                {{ errorMsg }}
              </span>
            </ng-container>
          </div>
        </div>

        <div class="col-12 d-flex justify-content-end mt-3">
          <label for="copyToMe" class="switch">
            <input
              id="copyToMe"
              name="copyToMe"
              formControlName="copyToMe"
              type="checkbox"
              name="otherDate"
              checked
            />
            <span class="slider round"></span>
          </label>
          <label class="texto-copia ms-3 header-s">Con copia a mí</label>
        </div>
      </div>
    </form>

    <div class="d-flex justify-content-center mt-4">
      <button class="colored-btn modals-btn" (click)="onSubmitEmail()">
        <span class="button-s"> Enviar </span>
      </button>
    </div>
  </div>
</ng-container>
<ng-template #spacing>
  <div class="spacing"></div>
</ng-template>
