<ng-container *ngIf="vm$ | async as vm">
  <div class="backgroup col-xl-12">
    <div class="d-flex flex-row justify-content-center mb-4">
      <img [src]="location | cdnImage" alt="" width="auto" height="auto">
    </div>
    <div>
      <div class="d-flex flex-row justify-content-center">
        <label class="tittle header-xs mb-2">{{title}}</label>
      </div>
      <div class="d-flex flex-row justify-content-center">
        <label class="subtittle detail-m mb-4">{{subtitle}}</label>
      </div>
    </div>
    <div class="flex-row d-flex justify-content-between mt-1">
      <button class="button-hacer button-m " (click)="openPdf(vm.breakpoints)">
        {{openManual}}
      </button>
      <button class="accept-button button-m" (click)="closeModal()">
        {{closeButton}}
      </button>
    </div>
  </div>
</ng-container>
