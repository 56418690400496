import { PathModel } from 'src/app/models/PathModel';

type TUpdateUserImageRequest = {
    idUsuario: number;
    imagen: string;
    tipo: number;
};

export class UpdateUserImageRequest {
    constructor(public data: TUpdateUserImageRequest){ }

    public get endpoint(): PathModel {
        return PathModel.UpdateUserImage;
    }
}