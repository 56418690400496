<div class="content">
  <div class="container-fluid d-flex flex-column align-items-center px-0">
  <div class="face-pass-user mt-4">
    <div id="login-pass">
      <div class="d-flex flex-column gap-2 justify-content-center align-items-center" >
        <app-header-card-login-component></app-header-card-login-component>
        <app-form-card-login></app-form-card-login>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <app-download-app-component [page]="page"></app-download-app-component>
  </div>
</div>
<div class="container-fluid">
  <app-footer></app-footer>
</div>
</div>
