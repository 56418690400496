<ng-container *ngIf="vm$ | async as vm">
  <div *ngIf="hasResult" class="modal-voluntary"
    [ngClass]="showConfirmData ? 'width-modal-confirm modal-voluntary' : 'width-modal-form modal-voluntary'">
    <div class="d-flex justify-content-end padding-button-close" ngbAutofocus>
      <img role="button" aria-label="Close" width="auto" height="auto" [src]="removeOperation | cdnImage" alt="Close"
        class="close close-style" (click)="close('close')" autofocus #imgClose tabindex="0">
    </div>
    <div [ngClass]="showConfirmData ? 'padding-content-confirm' : 'padding-content-form'">
      <div [ngClass]="showSuccessView || showConfirmData || vm.breakpoints.mobile ? 'text-align': '' ">
        <div *ngIf="!showSuccessView" class="mb-title" [innerHTML]="labelWithdrawal"
          [ngClass]="vm.breakpoints.mobile ? 'header-m' : 'header-l'">
        </div>
        <div *ngIf="showSuccessView" class="mb-title">
          <img [src]="checkFill | cdnImage" class="icon-check mb-img" alt="éxito" width="auto" height="auto" /><br>
          <div class="mb-row w-100" [ngClass]="vm.breakpoints.mobile ? 'header-m' : 'header-l'">
            {{labelSuccessfulWithdrawal}}
          </div>
          <div class="mb-row body-m w-100">
            {{labelIdOperation}}
          </div>
          <div class="detail-m w-100 mb-date">
            {{completeDate}}
          </div>
          <div class="d-flex justify-content-center mb-shared">
            <app-shared-buttons (downloadFiles)="downloadPdf()" [hideMail]="true">
            </app-shared-buttons>
          </div>
        </div>
        <div class="body-m " [ngClass]="showConfirmData ? 'mb-subtitle-confirm' : 'mb-subtitle'">{{subtitle}}</div>
      </div>
      <div *ngIf="!showConfirmData">
        <div class="select w-100 mb-inputs">
          <custom-select #subAccountObject [label]="labelWithdrawalSubAccount" [options]="subAccountsDescriptions"
            (isOptionSelected)="changeSubAccount($event)" [customValue]="subAccount.descripcion">
          </custom-select>
        </div>
        <div class="position-relative w-100 mb-inputs">
          <label for="amount" class="ms-2 position-absolute position-label-input bg-white detail-s px-1">
            {{labelTotalAmount}}
          </label>
          <input type="text" class="form-control col-12 inputs body-s color-label-voluntary" id="amount" name="amount"
            autocomplete="off" [value]="totalAmount | currency" disabled readonly>
        </div>
        <div class="select w-100 mb-inputs ">
          <div class="position-relative">
            <label for="account" class="ms-2 position-absolute position-label-select inputLabel
            bg-white detail-s px-1">
              {{labelCodeAccount}}
            </label>
          </div>
          <input type="text" class="form-control col-12 inputs body-s color-label-voluntary" id="account" name="account"
            autocomplete="off" [(ngModel)]="clabe" disabled readonly>
        </div>
        <div class="position-relative w-100 mb-inputs">
          <label for="amountSub" class="ms-2 position-absolute position-label-input bg-white detail-s px-1"
            [ngClass]="showLabelError ? 'label-red' : ''">
            {{labelWithdrawalAmount}}
          </label>
          <input *ngIf="isTotalWithdrawal" type="text" class="form-control col-12 inputs body-s color-label-voluntary"
            id="amountSub" name="amountSub" autocomplete="off"
            [value]="withdrawalAmount | currency" disabled readonly>
          <input *ngIf="!isTotalWithdrawal" type="text" class="form-control col-12 inputs body-s color-label-voluntary"
            id="amountSub" name="amountSub" autocomplete="off" maxlength="50"
            cleanInput [cleanRegex]="onlyNumbers" inputmode="numeric"  (focus)="onFocusAmount = true"
            (blur)="onFocusAmount = false; validateAmount(withdrawalAmount)"
            [ngClass]="showLabelError ? 'border-red ' : ''"
            [ngModel]="onFocusAmount ? withdrawalAmount : (withdrawalAmount | currency )"
            [readOnly]="isTotalWithdrawal" [placeholder]="'$0.00'" (ngModelChange)="withdrawalAmount = $event">
          <div *ngIf="showLabelError" [ngClass]="showLabelError ? 'label-red' : ''" class="detail-s">
            {{labelError}}
          </div>
        </div>
        <div class="select w-100 mb-input-type mb-input-type">
          <custom-select [label]="labelOccupation" [options]="occupationLabels"
            (isOptionSelected)="changeOccupation($event)" [customValue]="occupationLabel">
          </custom-select>
          <div *ngIf="showErrOccupation" [ngClass]="showErrOccupation ? 'label-red' : ''" class="detail-s">
            {{labelErrorOccupation}}
          </div>
        </div>

        <div class="select w-100 mb-input-type pt-withdrawal-type">
          <custom-select [label]="labelWithdrawalType" [options]="withdrawalTypes"
            (isOptionSelected)="changeWithdrawal($event)" [customValue]="withdrawal">
          </custom-select>
        </div>
        <button class="button-send-voluntary button-m" (click)="continueVoluntary()">
          {{buttonContinue}}</button>
        <button class="button-cancel-voluntary button-m" (click)="close('close')">
          {{buttonCancel}}</button>
      </div>
      <div *ngIf="showConfirmData">
        <app-form-voluntary-confirm (showSuccessMessage)="changeViewModal($event)">
        </app-form-voluntary-confirm>
      </div>
    </div>
  </div>
</ng-container>
