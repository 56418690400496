import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IPaymentType } from '../interface/dto/IPaymentType';
import { Strings } from 'src/core/constants/Strings';
import {  Numbers } from 'src/core/constants/Constants';

@Injectable({
  providedIn: 'root'
})
export class ServicePaymentTypeService {

  private readonly _paymentTypeService: BehaviorSubject<IPaymentType> = new BehaviorSubject<IPaymentType>(
    { type: Strings.EMPTY, options:{
      currentBalance: Numbers.Zero, nextPayment: Numbers.Zero, prepaidCreditAmount: Strings.EMPTY}, excessAmount:false, isError: false});

  constructor() { }

  get paymentTypeService$() {
    return this._paymentTypeService.asObservable();
  }

  get paymentTypeService(): IPaymentType {
    return this._paymentTypeService.getValue();
  }
  set paymentTypeService(data: IPaymentType) {
    this._paymentTypeService.next(data);
  }

  clearPaymentType(){
    this._paymentTypeService.next({
      typeBank: Strings.EMPTY,
      isError: false,
      type: Strings.EMPTY,
      options: {
        currentBalance:Numbers.Zero,
        nextPayment:Numbers.Zero,
        prepaidCreditAmount:Strings.EMPTY
      },
      excessAmount:false
    });
  }


}
