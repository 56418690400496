import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatestWith, map, tap } from 'rxjs';
import { ButtonProperties } from 'src/app/interface/IButtonProperties';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import { WebCardActiveService } from 'src/app/services/web-card-active.service';
import { WebCardLayoutService } from 'src/app/services/web-card-layout.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Resources } from 'src/core/constants/Resources';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { formatDate } from '@angular/common';
import { ClassHTML, Constants, FormatDate, Numbers } from 'src/core/constants/Constants';
import { WebCardStrings } from 'src/core/constants/WebCardStrings';
import { WebCardApplicationRequest } from 'src/app/interface/dto/WebCardApplicationRequest';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { Utils } from 'src/core/utils/utils';
import { environment } from 'src/environments/environment';
import { WebCardApplicationResponse } from 'src/app/interface/dto/WebCardApplicationResponse';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { AccountModel } from 'src/app/interface/AccountModel';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';

@Component({
  selector: 'app-web-card-data-confirmation',
  templateUrl: './web-card-data-confirmation.component.html',
  styleUrls: ['./web-card-data-confirmation.component.css']
})
export class WebCardDataConfirmationComponent {
  private readonly accountData: BehaviorSubject<AccountModel> = new BehaviorSubject<AccountModel>(null);
  pathWebCardRequest: string = PATH.WebCardRequest;
  checkFill: string = Resources.GREEN_CHECK_FILL;
  statusProcess: string = Strings.CONFIRM;
  errorMessage: string = Strings.EMPTY;
  completeDate: string = Strings.EMPTY;
  imageName: string = Strings.EMPTY;
  subtitle: string = Strings.CONFIRM_OPERATION;
  folio: string = Strings.EMPTY;

  primarybuttonClass: string =
    WebCardStrings.WebCard.WEB_CARD_DATA_CONFIRMATION_BTN;
  title: string = WebCardStrings.WebCard.TITLE;
  contentMargin: string =
    WebCardStrings.WebCard.CLASSES.CONTENT_MARGIN_NOT_MOBILE;
  firstName: string = WebCardStrings.WebCard.WEB_FORM.FIRST_NAME.name;
  secondName: string = WebCardStrings.WebCard.WEB_FORM.SECOND_NAME.name;
  lastName: string = WebCardStrings.WebCard.WEB_FORM.LAST_NAME.name;
  secondLastName: string =
    WebCardStrings.WebCard.WEB_FORM.SECOND_LAST_NAME.name;
  gender: string = WebCardStrings.WebCard.WEB_FORM.GENDER.name;
  infoMessage: string = WebCardStrings.WebCard.INFO_MESSAGE;
  @ViewChild('saveImageContainer') saveImageContainer: ElementRef<HTMLElement>;
  buttonClicked: boolean = true;
  webCardDownload: boolean = environment.hiddenComponents.webCardDownload;
  captureImage: {name:string, element: HTMLElement};
  shareCapture: {name:string, element: HTMLElement};

  applicantInformation: string = WebCardStrings.WebCard.LABELS_WEB_CARD.ApplicantInformation;
  firstNameLabel: string = WebCardStrings.WebCard.WEB_FORM.FIRST_NAME.label;
  secondNameLabel: string = WebCardStrings.WebCard.WEB_FORM.SECOND_NAME.label;
  lastNameLabel: string = WebCardStrings.WebCard.WEB_FORM.LAST_NAME.label;
  secondLastNameLabel: string = WebCardStrings.WebCard.WEB_FORM.SECOND_LAST_NAME.label;
  genderLabel: string = WebCardStrings.WebCard.WEB_FORM.GENDER.label;
  btnReady: string = Strings.BUTTONS.Ready;
  cardUsageMessage: string = WebCardStrings.WebCard.LABELS_WEB_CARD.CardUsageMessage;
  btnConfirm: string = Strings.BUTTONS.ConfirmAndContinue;
  btnModifyLabel: string = Strings.BUTTONS.Modify;
  folioLabel: string = Strings.FOLIO;
  successfulRequest: string = Strings.MESSAGES.SuccessfulRequest;
  sourceAccount: string = Strings.FROM_YOUR_ACCOUNT;
  numberOfLines: number = Numbers.One;


  btnContinue: ButtonProperties = {
    styles: {},
    classes: this.primarybuttonClass,
    doAction: () => {
      this.getDataFormWebCard();
    }
  };

  styles: Partial<CSSStyleDeclaration> = {
    display: ClassHTML.WebkitBox,
    webkitBoxOrient: ClassHTML.BoxOrientVertical,
    overflowWrap : ClassHTML.AnyWhere
  };

  btnModify: ButtonProperties = {
    styles: {},
    classes: this.primarybuttonClass,
    doAction: () => {
      this.buttonClicked = false;
      this.statusProcess = Strings.CONFIRM;
      this.modify();
    }
  };

  btnDone: ButtonProperties = {
    styles: {},
    classes: this.primarybuttonClass,
    doAction: () => {
      this.webCardActiveService.webCardIsActiveData = true;
      this.webCardLayoutService.isWebCard = true;
      this.dynamicFormService.clearForm();
      this.webCardLayoutService.formTermsBs.next(null);
      this.router.navigate([PATH.Home]);
    }
  };

  vm$ = this.responsiveService.observe.pipe(
    tap((breakpoints) => {
      if (breakpoints.mobile) {
        this.contentMargin =
          WebCardStrings.WebCard.CLASSES.CONTENT_MARGIN_MOBILE;
      } else {
        this.contentMargin =
          WebCardStrings.WebCard.CLASSES.CONTENT_MARGIN_NOT_MOBILE;
      }
    }),
    combineLatestWith(this.accountData),
    map(([breakpoints, selectedAccount]) => ({ breakpoints, selectedAccount }))
  );

  constructor(
    private readonly responsiveService: ResponsiveService,
    public readonly dynamicFormService: DynamicFormService,
    private readonly webCardActiveService: WebCardActiveService,
    private readonly webCardLayoutService: WebCardLayoutService,
    private readonly accountInfoStatusService: AccountInfoStatusService,
    private readonly baseService: IBaseService,
    private readonly router: Router,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    NavigationUtils.fixNavigation(this.changeDetector,[PATH.WebCardRequest,PATH.WebCardRequestConfirmation]);

    if (!this.accountInfoStatusService.account.numeroCuenta || !this.dynamicFormService.formBS.value) {
      this.router.navigate([PATH.Home]);
      return;
    }

    await this.getWebAccount();

    const date = new Date();
    this.completeDate = formatDate(
      date,
      FormatDate.DayMonthYearComplete,
      Constants.LOCALE_ES_MX
    );
    this.imageName = formatDate(
      date,
      WebCardStrings.WebCard.FORMATDATE,
      Constants.LOCALE_ES_MX
    );
  }

  modify(){
    this.router.navigate([PATH.WebCardRequest]);
  }

  async getDataFormWebCard() {

    const formService = this.dynamicFormService.formBS.value.controls;
    const webForm = WebCardStrings.WebCard.WEB_FORM;
    const request = new WebCardApplicationRequest({
      numeroCuenta: this.accountInfoStatusService.account.numeroCuenta,
      primerNombre: formService[webForm.FIRST_NAME.name].value,
      segundoNombre: formService[webForm.SECOND_NAME.name].value
        ? formService[webForm.SECOND_NAME.name].value
        : null,
      apellidoPaterno: formService[webForm.LAST_NAME.name].value
        ? formService[webForm.LAST_NAME.name].value
        : null,
      apellidoMaterno: formService[webForm.SECOND_LAST_NAME.name].value
        ? formService[webForm.SECOND_LAST_NAME.name].value
        : null,
      sexo: formService[webForm.GENDER.name].value,
      panelFirma: formService[webForm.SIGNATURE_PANEL.name].value
    });
    try {
      const response =
        await this.baseService.genericPost<WebCardApplicationResponse>(
          request,
          { isSecure: true, addSession: true }
        );
      if (!response?.datos) {
        throw new BusinessError(
          Strings.SERVICES.UnexpectedMsg,
          Constants.UNEXPECTED_CODE
        );
      } else {
        this.folio = response.datos.toString();
        this.statusProcess = Strings.COMPLETE;
        setTimeout(() => {   
          this.shareCapture = {
            name: this.imageName,
            element: this.saveImageContainer.nativeElement
          };
          this.captureImage = {...this.shareCapture};
        });
      }
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.statusProcess = WebCardStrings.WebCard.ERROR;
      this.errorMessage = Utils.SERVICE_ERROR_MSG(
        errorDetails.msg,
        errorDetails.code
      );
    }
  }

  async getWebAccount() {
    const account = await this.webCardActiveService.getWebAccount();
    this.accountData.next(account);
  }

  @HostListener('window:popstate')
  goBack() {
    if (this.statusProcess === Strings.COMPLETE) {
      this.dynamicFormService.clearForm();
    }
    this.buttonClicked = false;
  }
}
