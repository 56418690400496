import { Injectable } from '@angular/core';
import { RSLocationRequest } from 'src/app/interface/dto/RSLocationRequest';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { Locations, RSLocationResponse } from 'src/app/interface/dto/RSLocationResponse';
import { StorageService } from 'src/app/services/storage.service';
import { LocationConstants } from 'src/core/constants/LocationConstants';
import { BehaviorSubject } from 'rxjs';
import { Numbers } from 'src/core/constants/Constants';


@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private readonly _center: BehaviorSubject<google.maps.LatLngLiteral> = new BehaviorSubject<google.maps.LatLngLiteral>({lat:Numbers.Zero, lng: Numbers.Zero});
  
  get center$() {
    return this._center.asObservable();
  }

  set center(data: google.maps.LatLngLiteral) {
    this._center.next(data);
  }

  get center(): google.maps.LatLngLiteral{
    return this._center.getValue();
  }

  constructor(private readonly baseService: IBaseService,
    private readonly storage: StorageService) { }

    async getRSLocation(filter: string, latitude: number, longitude: number): Promise<{icon: string, locations: Locations[]}> {
        const request = new RSLocationRequest({
            method: LocationConstants.METHOD,
            latitude,
            longitude,
            filter,
            idSession: this.storage.getSession()
        });
        const response= await this.baseService.genericGet<RSLocationResponse>(request, { isSecure: true});
        const icon = (response.NearLocationsResult.Locations.Config.find(config => config.IdTipoUbicacion === filter)).IconoMapa;
        const locations = response.NearLocationsResult.Locations.Location.filter(location => location.IdTipoUbicacion === filter);
        return {icon,locations};
    }
}
