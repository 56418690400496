import { PathModel } from '../models/PathModel';
export interface IDebitCardMovements {
  idSesion: string;
  numeroCuenta: string;
  idCuentaProducto: number;
  numeroTarjeta?: string;
  fechaInicio?: string;
  fechaFin?: string;
}
export class DebitCardMovementsRequest {
  constructor(public data: IDebitCardMovements) { }

  get endpoint(): PathModel {
    return PathModel.DebitCardMovements;
  }
}