import { Subscription, SubscriptionLike } from 'rxjs';
import { NotifyDateService } from 'src/app/services/notify-date.service';
import {Component,Input,OnInit,ChangeDetectionStrategy,ChangeDetectorRef,OnDestroy,Output,EventEmitter,ElementRef,ViewChild,AfterViewInit} from '@angular/core';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountType, Char, ClassHTML, Constants, DataType, EventType, FormatDate, Numbers, Regex } from 'src/core/constants/Constants';
import { NgbDateStruct, NgbDatepickerI18n, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18nService, I18n } from 'src/app/shared/custom-datepicker-i18n.service';
import { Utils } from 'src/core/utils/utils';
import { Strings } from 'src/core/constants/Strings';
import { Resources } from 'src/core/constants/Resources';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { AccountModel } from 'src/app/interface/AccountModel';
import { AforeStrings, AforeNumbers } from 'src/core/constants/AforeStrings';
import { formatDate } from '@angular/common';
import { CodeflexConstants } from 'src/core/constants/CodeflexConstants';
import { DateUtils } from 'src/core/utils/dateUtils';
import { ControlCardStrings } from 'src/core/constants/ControlCardStrings';
import { CdnImagePipe } from 'src/app/pipes/cdn-image.pipe';
import { EnterDateExtensionService } from 'src/app/services/enter-date-extension.service';
import { ClassHTML as HTMLConstants } from 'src/core/constants/HTMLConstants';
import { StylesConstants } from 'src/core/constants/StylesConstants';

@Component({
  selector: 'app-enter-date',
  templateUrl: './enter-date.component.html',
  styleUrls: ['./enter-date.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }]
})

/**
 * @minDate Default Value/ you need set custom value.
 * @maxDate Default Value/ you need set custom value.
 */
export class EnterDateComponent implements OnInit, OnDestroy, AfterViewInit {
  showDateError: boolean = false;
  idService: string;
  dateForm: FormGroup;
  dateIsError: boolean;
  model: NgbDateStruct;
  today: NgbDateStruct;
  showCalendar: boolean = false;
  date: string = Strings.EMPTY;
  regexNumber: RegExp = Regex.OneMoreNumbers;
  dateService: SubscriptionLike;
  servicePaySL: SubscriptionLike;
  notifyObservable: SubscriptionLike;
  dateFormatRegex: RegExp = Regex.ValidateDateFormat;
  showContain: boolean;
  formControls = Constants.FORM_CONTROLS;
  currentAccount: AccountModel;
  codeflex: boolean = false;
  ownCreditCard: boolean = false;
  @Input() isControlCard: boolean = false;
  @Input() isDateRange: boolean = false;
  @Input() title: boolean = true;
  @Input() minDate: { year: number, month: number, day: number } = Constants.MIN_DATE_DEFAULT;
  @Input() maxDate: { year: number, month: number, day: number } = Constants.MAX_DATE_DEFAULT;
  @Input() validateEmptyValue: boolean = false;
  @Input() head: string = Strings.DATE_LABEL;
  @Input() clearDatePortability: boolean = true;
  @Input() labelInput: string = Strings.LIMIT_DATE_LABEL;
  @Input() classCustom: string = Strings.EMPTY;
  @Input() inputState: boolean;
  @Input() headClasses: string = Strings.EMPTY;
  @Input() showCard: boolean = true;
  @Input() id: string = ClassHTML.Document;
  @Input() currentDate: boolean = true;
  @Input() disabled: boolean = false;
  @Input() customDate: { year: number, month: number, day: number } = null;
  @Output() outputDate: EventEmitter<string> = new EventEmitter<string>();
  @Output() isDateError: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() isExternalError?: boolean = false;
  @Input() validAge: boolean = false;
  @Input() aforeDocumentFlow?: boolean = false;
  @Input() isCustomError: boolean = false;
  @Input() iconHead: string;
  @Input() toolTipIcon: string;
  errorDate: string =ControlCardStrings.GENERIC_ERROR_EMPTY_DATE;
  external:boolean=false;
  subscription: Subscription;
  @Input() operation: string;
  @Input() useService: boolean = true;
  readonly defaultImg: string = Resources.CALENDAR_BLUE;
  readonly disabledImg: string = Resources.CALENDAR_GREY;
  @ViewChild('calendar', { static: false }) calendarImage: ElementRef;
  cdnImagePipe: CdnImagePipe = new CdnImagePipe();
  calendar: string = Resources.CALENDAR_BLUE;
  showTooltip: boolean = false;
  handleKeyEnter() {
    this.openCalendar();
  }
  constructor(
     readonly notifyDateService: NotifyDateService, private readonly stateServicePayIdService: StateServicePayIdService,
     readonly formBuilder: FormBuilder, private readonly changeDetectionReference: ChangeDetectorRef,
    private readonly accountInfoService: AccountInfoStatusService, private readonly enterDateService: EnterDateExtensionService,
    private readonly enterDateExtensionService: EnterDateExtensionService
) { }
  ngAfterViewInit(): void {
    this.calendarImage.nativeElement.style.setProperty(HTMLConstants.CalendarImage, StylesConstants.URL_STYLE(this.cdnImagePipe.transform(this.defaultImg)));
    this.subscription=this.notifyDateService.isExternalErrorObservable.subscribe((error)=>{
      if(error && this.model===undefined){
        this.external=true;
        this.showDateError= true;
      }else{
        this.external=false;
        this.showDateError= false;
        if(this.validAge){
          if((typeof this.model !== DataType.Object)){
              const formatedDate = String(this.model).split(Char.Slash);
              this.enterDateService.validateAge({year: Number(formatedDate[Numbers.Two]),month:Number(formatedDate[Numbers.One]),day:Number(formatedDate[Numbers.Zero])}, this);
          }else{
            this.enterDateService.validateAge(this.model, this);
          }
        }
      }
    });
  }

  clearDate(){
    this.model = null;
    this.showDateError= false;
    this.external = false;
    this.notifyDateService.isErrorData = false;
    this.isDateError.emit(false);
    this.emittDate(Strings.EMPTY);
  }

  ngOnInit(): void {
    this.ownCreditCard = this.stateServicePayIdService.service.params?.esTDC || false;
    this.external=false;
    this.showDateError= false;
    if(!this.isControlCard){
      this.model = null;
    }
    this.currentAccount = this.accountInfoService.account;
    if(!this.currentAccount?.tipoCuenta?.toLowerCase()?.includes(CodeflexConstants.CODEFLEX_ROOT)){
      this.dateForm = this.formBuilder.group({
        date: [
          {
            value: null,
            disabled: this.disabled
          },
          [Validators.required]
        ]
      });
    }
    if(this.currentAccount?.tipoCuenta?.includes(AccountType.CODEFLEX)){
      this.codeflex = true;
    }
    this.resetDate();
    this.checksOtherBanks();
    this.checkDateError();
    if (this.currentAccount?.tipoCuenta?.toLowerCase()?.includes(CodeflexConstants.CODEFLEX_ROOT) && this.customDate !== null) {
      this.model = this.customDate;
    }
    this.servicePaySL = this.stateServicePayIdService.service$.subscribe((data) => {
      this.idService = data.id;
      if (this.idService === Constants.SERVICE_CODE.MexicoState) {
        this.dateForm = this.formBuilder.group({
          date: [
            {
              value: null,
              disabled: this.disabled,
            },
            [Validators.required]
          ]
        });
      } else if (this.currentDate) {
        this.enterDateService.formatInputDate(this);
      }  else if (this.customDate){
        this.dateForm.patchValue({
          date: this.customDate
        });
        this.notifyDateService.dateStructData = this.customDate;
        this.emittDate(this.notifyDateService.dateFormat);
      }
      if (!!this.notifyDateService.dateStruct.value) {
        this.dateForm.patchValue({
          date: this.customDate ?? this.notifyDateService.dateStruct.getValue()
        });
        this.model = {
          year: parseInt(this.dateForm.value.date.year),
          month: parseInt(this.dateForm.value.date.month),
          day: parseInt(this.dateForm.value.date.day)
        };
      }
      this.notifyDateService.isErrorData = false;
      this.changeDetectionReference.detectChanges();
    });
    this.notifyObservable = this.notifyDateService.isErrorObservable.subscribe((isError) => {
      if (isError) {
        this.dateForm.controls[Constants.FORM_CONTROLS.Date].setErrors({ required: true });
      }
    });
    this.enterDateService.idSipare(this);
    this.showDateError = false;
    const randNumber = new Uint8Array(Numbers.One);
    crypto.getRandomValues(randNumber);
    this.id = `${this.id}${Char.MiddleDash}${randNumber}`;
  }

  resetDate(){
    if(this.clearDatePortability){
      this.notifyDateService.dateStructData = null;
    }
  }
  checksOtherBanks() {
    this.dateService = this.notifyDateService.isErrorObservable.subscribe((data) => {
      if (!this.operation) {
        this.showDateError = data;
        this.changeDetectionReference.detectChanges();
      }
    });
  }
  ngOnDestroy(): void {
    this.dateService?.unsubscribe();
    this.servicePaySL?.unsubscribe();
    this.notifyObservable?.unsubscribe();
  }
  private checkDateError() {
    if (this.useService) {
      this.dateService = this.notifyDateService.isErrorObservable.subscribe((isError) => {
        this.showDateError = isError;
        this.changeDetectionReference.detectChanges();
        if (isError) {
          this.dateForm.controls[Constants.FORM_CONTROLS.Date].setErrors({ required: true });
        }
      });
    }
  }
  notifyDateChanged($event: Event) {
    if (($event.target as HTMLInputElement).value === undefined) {
      this.showDateError = true;
      this.isDateError.emit(true);
    } else {
      this.showDateError = false;
      this.isDateError.emit(false);
    }
  }
  isValidDate() {
    this.dateIsError = false;
  }

  remplaceDate(model: NgbDateStruct){
    if(!this.clearDatePortability){
      this.notifyDateService.dateStructData = model;
    }
  }

  dateSelected(model: NgbDateStruct): void {
    this.validateErrorMsg(model);
    if (model !== undefined && model !== null && model.day !== undefined && model.month !== undefined && model.year !== undefined) {
      let modelDate: string = model.month.toString() + Char.Slash + model.day.toString() + Char.Slash + model.year.toString();
      if (this.useService) {
        if (this.enterDateService.isDateLater(model, this.minDate)) {
          this.notifyDateService.isErrorData = false;
          this.showDateError = false;
          this.remplaceDate(model);
          this.isDateError.emit(false);
          if(this.validAge){
            this.enterDateService.validateAge(model,this);
          }
        }else{
          this.notifyDateService.isErrorData = true;
          this.showDateError = true;
          this.isDateError.emit(true);
        }
      }
      if (modelDate !== AforeStrings.CONSTANTS_AFORE.DateLastYear) {
        modelDate = formatDate(modelDate, FormatDate.BasicFormat, Constants.LOCALE_ES_MX);
        this.showDateError = false;
      } else {
        modelDate =  model.day.toString() + Char.Slash +model.month.toString() + Char.Slash + model.year.toString();
      }
      this.emittDate(modelDate);
    }
  }
  processDateSelected(model: NgbDateStruct){
    if (this.isDateRange) {
      this.validateDateInterval(model);
    } else{
      this.dateSelected(model);
    }
  }
  dateCodeflex(model: NgbDateStruct){
      this.notifyDateService.isErrorData = false;
      this.showDateError = false;
      this.isDateError.emit(false);
      const modelDate =  model.day.toString() + Char.Slash +model.month.toString() + Char.Slash + model.year.toString();
      this.emittDate(modelDate);
  }
  getDateFocus($event: Event) {
    if(($event.target as HTMLInputElement).value){
      const date = DateUtils.resetCompleteDate(($event.target as HTMLInputElement).value);
      if (!this.dateFormatRegex.test(date)) {
        this.notifyDateService.dateStructData = undefined;
        this.notifyDateService.isErrorData = true;
      } else {
        this.notifyDateService.isErrorData = false;
      }
    }
  }
  openCalendar() {
    this.calendarImage.nativeElement.blur();
    if(this.disabled) return;
    this.enterDateService.isValidDate(this);
    document.getElementById(this.id)?.dispatchEvent(new MouseEvent(EventType.Click));
    const labelDays = document.getElementsByClassName(ClassHTML.LabelDays);
    const numberDays = document.getElementsByClassName(ClassHTML.NumberDays);
    for (const item of labelDays) {
      item.classList.add(ClassHTML.BodyClass);
    }
    for (const item of numberDays) {
      item.classList.add(ClassHTML.BodyClass);
    }
  }
  validateDate(stringDate: string) {
    if (this.dateFormatRegex.test(stringDate) && Utils.isDateStringValid(stringDate)) {
      this.showDateError = false;
      this.isDateError.emit(false);
    } else {
      if (this.aforeDocumentFlow) {
        if (stringDate !== null && stringDate !== undefined && stringDate !== Strings.EMPTY) {
          this.showDateError = true;
        }
      } else {
        this.showDateError = true;
        this.isDateError.emit(true);
        this.notifyDateService.isErrorData = true;
      }
    }
  }
  validateDateFormatRegex(dateString:string){
    return !this.dateFormatRegex.test(dateString) || !Utils.isDateStringValid(dateString);
  }
  validateEmptyDate($event: Event){
    const dateElement = $event.target as HTMLInputElement;
    return dateElement.value === Strings.EMPTY && this.validateEmptyValue === true;
  }
  validateMinDate(){
    return this.minDate == null && this.maxDate == null;
  }

  dateValue(date: NgbDateStruct, $event: Event) {
    const dateElement = $event.target as HTMLInputElement;
    this.notifyDateService.dateStructData = date;
    this.validateDateEmpty($event);
    if(dateElement.value === `${Numbers.One}${Numbers.One}${Char.Slash}${Numbers.One}${Numbers.One}${Char.Slash}${Numbers.One}${Numbers.One}${Numbers.One}${Numbers.One}`){
      this.notifyDateService.isErrorData = true;
      this.showDateError = true;
      this.isDateError.emit(true);
      dateElement.value = DateUtils.datePipe(dateElement.value);
      return;
    }
    const dateString = DateUtils.resetCompleteDate(dateElement.value);
    this.validateDate(dateString);
    if(this.validateEmptyDate($event)){
      this.enterDateExtensionService.hiddenError(this);
      return;
    }
    if(this.showDateError){return;}
    const datePart = dateString.split(Char.Slash);
    const dateObj = {
      day: parseInt(datePart[Numbers.Zero]),
      month: parseInt(datePart[Numbers.One]),
      year: parseInt(datePart[Numbers.Two])
    };
    if (dateElement.value && (this.validateDateFormatRegex(dateString))) {
      this.notifyDateService.dateStructData = undefined;
      this.notifyDateService.isErrorData = true;
      this.showDateError = true;
      this.isDateError.emit(true);
    } else {
      if (this.validateMinDate()) {
        this.notifyDateService.dateStructData = dateObj;
        this.dateForm.patchValue({dateObj});
        this.model = date;
        this.emittDate(this.notifyDateService.dateFormat);
      } else {
        const minDate = new Date(this.minDate.year, this.minDate.month - Numbers.One, this.minDate.day);
        const maxDate = new Date(this.maxDate.year, this.maxDate.month - Numbers.One, this.maxDate.day);
        const calendarDate = Utils.convertStringToDate(dateString);
        this.notifyDateService.dateStructData = dateObj;
        this.dateForm.patchValue({
          dateObj
        });
        this.emittDate(this.notifyDateService.dateFormat);
        if (calendarDate.getTime() >= minDate.getTime() && calendarDate.getTime() <= maxDate.getTime()) {
          this.model = this.modelDate(date);
          this.notifyDateService.isErrorData = false;
          this.showDateError = false;
          this.isDateError.emit(false);
        } else {
          this.notifyDateService.isErrorData = true;
          this.showDateError = true;
          this.isDateError.emit(true);
        }
      }
    }
    if(this.validAge){
      this.enterDateService.validateAge(date, this);
    }
    dateElement.value = DateUtils.datePipe(dateElement.value);
  }
  validateDateEmpty($event: Event){
    const dateElement = $event.target as HTMLInputElement;
    if(dateElement.value === Strings.EMPTY){
      this.showDateError = true;
      this.errorDate = ControlCardStrings.GENERIC_ERROR_EMPTY_DATE;
    }
  }
  validateKeyboardEvent($event: Event) {
    const inputName = $event.target as HTMLInputElement;
    const cursorPosition = this.enterDateService.calculateCursorPosition($event);
    if (cursorPosition === Numbers.Two && !DateUtils.isNumber(inputName.value.split[Numbers.Zero])) {
      inputName.value = `${inputName.value.slice(Numbers.Zero, Numbers.Two)}${Char.Slash}${inputName.value.slice(Numbers.Two)}`;
    }
    inputName.value = DateUtils.resetCompleteDate(inputName.value);
    DateUtils.formatDateInput($event, this.dateForm, this.formControls.Date, this.calendarImage, cursorPosition);
    this.emittDate(inputName.value);
  }
  emittDate(date: string){
    this.outputDate.emit(date);
  }
  validateDateInterval (model: NgbDateStruct): void {
    if ((model?.day ?? false) && (model?.month ?? false) && (model?.year ?? false)) {
      const currentDate = new Date();
      const daysInMonth = [
      AforeNumbers.ThirtyOne, DateUtils.isLeapYear(model.year) ? AforeNumbers.TwentyNine : AforeNumbers.TwentyEight,
      AforeNumbers.ThirtyOne, AforeNumbers.Thirty, AforeNumbers.ThirtyOne, AforeNumbers.Thirty, AforeNumbers.ThirtyOne,
      AforeNumbers.ThirtyOne, AforeNumbers.Thirty, AforeNumbers.ThirtyOne, AforeNumbers.Thirty, AforeNumbers.ThirtyOne
    ];
      this.notifyDateService.isErrorData = model.year > currentDate.getFullYear() || model.month > Numbers.Twelve || model.day > daysInMonth[model.month - Numbers.One];
      this.showDateError = this.notifyDateService.isErrorData;
      this.isDateError.emit(this.notifyDateService.isErrorData);
      const formattedDate = DateUtils.resetCompleteDate(`${model.day} ${Char.Slash} ${model.month} ${Char.Slash} ${model.year}`);
      this.emittDate(formattedDate);
    }
  }
  closeCalendar(model: NgbDateStruct){
    if(model=== undefined){
      this.showDateError=true;
      this.dateIsError = true;
    }
  }
  validHasDate(model: NgbDateStruct){ return this.enterDateService.validHasDate(model,this); }
  modelDate(date) {return typeof date === DataType.String ? DateUtils.getNgbDateStructFromString(`${date}`) : date;}
  keypress($event:KeyboardEvent){ this.enterDateExtensionService.keypress($event); }
  validateErrorMsg(model: NgbDateStruct){this.enterDateExtensionService.validateErrorMsg(model,this);}
  closeTooltip(tooltip: NgbTooltip) {this.showTooltip? tooltip.close(): tooltip.open();}
}
