import { Component, OnDestroy, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Resources } from 'src/core/constants/Resources';
import { ReferencedPaymentSATService } from 'src/app/services/referenced-payment-sat.service';
import { SatReferencedPaymentStrings } from 'src/core/constants/SatReferencedPaymentStrings';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from 'src/app/interface/modal-options';
import { Char, Constants, FormatDate } from 'src/core/constants/Constants';
import { ModalService } from 'src/app/shared/modal.service';
import { PreviousPaymentsModalComponent } from 'src/app/services-module/components/previous-payments-modal/previous-payments-modal.component';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { IReferencedPaymentInformationSAT } from 'src/app/interface/ConsultSatPaymentsResponse';
import { Utils } from 'src/core/utils/utils';
import { Strings } from 'src/core/constants/Strings';
import { AlertService } from 'src/app/services/alert.service';
import { StorageService } from 'src/app/services/storage.service';
import { formatDate } from '@angular/common';
import { Format } from 'src/core/constants/FormatDate';
import { PaySatVoucherRequest } from 'src/app/interface/dto/PaySatVoucherRequest';
import { FileUtils } from 'src/core/utils/FileUtils';

@Component({
  selector: 'app-detail-previous-referenced-payment-sat',
  templateUrl: './detail-previous-referenced-payment-sat.component.html',
  styleUrls: ['./detail-previous-referenced-payment-sat.component.css']
})

export class DetailPreviousReferencedPaymentSatComponent implements OnInit, OnDestroy {
  modalInstance: NgbModalRef;
  serviceName: string = SatReferencedPaymentStrings.SAT_REFERENCES_PAYMENT.ServiceName;
  src = Resources.GREEN_CHECK_FILL;
  clearInfo:boolean = true;
  localData:IReferencedPaymentInformationSAT;
  completeDate: string = Strings.EMPTY;
  dateReceived: string = Strings.EMPTY;
  presentationMedium = SatReferencedPaymentStrings.SAT_REFERENCES_PAYMENT.PresentationMedium;
  paymentChannel = SatReferencedPaymentStrings.SAT_REFERENCES_PAYMENT.PaymentChannel;
  paymentMethod = SatReferencedPaymentStrings.SAT_REFERENCES_PAYMENT.PaymentMethod;

  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints }))
  );
  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly referencedPaymentSATService: ReferencedPaymentSATService,
    private readonly modalService: ModalService,
    private readonly alertService: AlertService,
    private readonly storage: StorageService
  ) { }

  ngOnDestroy(): void {
    if(this.clearInfo){
      this.referencedPaymentSATService.cleanFilters();
    }
  }

  ngOnInit(){
    this.localData = this.referencedPaymentSATService.getArray;
    const dateOfApplication = `${this.localData.FechaAplicacion.split(Char.MiddleDash).reverse().join(Char.Slash)}`;
    const date = new Date();
    this.completeDate = formatDate(date, Format.DayMonthYearCompleteH, Constants.LOCALE_ES_MX);
    this.dateReceived =  Utils.dateFormat(dateOfApplication, FormatDate.FormatDateTransactionsCard);
  }

  previousPaymentClose(){
    this.clearInfo = false;
    this.modalInstance?.close();
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.HiddenModal
    };
    this.modalService.open(PreviousPaymentsModalComponent, modalOptions);
  }

  async downloadPdf() {
    try {
      const request = this.getPaySatVoucherRequest();
      const response = await this.referencedPaymentSATService.getPaySatVoucher(request);
      if(response && response.ComprobanteBase64){
        const fileName = this.completeDate;
        FileUtils.downloadPdf(response.ComprobanteBase64, fileName);
      }
    } catch (error) {
      this.modalInstance.close();
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        imgHead: Resources.CLOSE_ICON_RED,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        btnExit: false
      });
    }
  }

  private getPaySatVoucherRequest(){
    const originalDate = `${this.localData.FechaAplicacion} ${this.localData.HoraAplicacion}`;
    return new PaySatVoucherRequest({
      IdSesion: this.storage.getSession(),
      LineaCaptura: this.localData.LineaCaptura,
      IdCuentaProducto: this.referencedPaymentSATService.idSelected,
      NumeroCuenta: this.referencedPaymentSATService.accountSelected,
      IdTransaccion: this.localData.IdTransaccion.toString(),
      FechaHora: originalDate,
      Monto: this.localData.Monto.toString()
    });
  }

}
