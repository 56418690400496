<ng-container *ngIf="vm$ | async as vm">
  <div class="p-3">

    <div
      [class.header-xs]="vm.breakpoints.mobile"
      [class.header-s]="!vm.breakpoints.mobile"
      [class.px-2]="vm.breakpoints.mobile"
      [class.px-5]="!vm.breakpoints.mobile"
      class="text-center my-4"
    >
      Ingresa el número de serie de tu Token Móvil
    </div>
    <div class="d-flex justify-content-center">
      <img
        [class.w-100]="vm.breakpoints.mobile"
        [class.w.75]="!vm.breakpoints.mobile"
        [src]="token | cdnImage"
        alt="Número de serie token"
        width="auto"
        height="auto"
      />
    </div>

    <div class="mt-4 pt-2 btn-container d-flex justify-content-center">
      <button
        [class.button-s]="vm.breakpoints.mobile"
        [class.button-m]="!vm.breakpoints.mobile"
        class="colored-btn modal-btn"
        (click)="close()"
      >
        Aceptar
      </button>
    </div>
  </div>
</ng-container>
