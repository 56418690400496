
import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { IAmount } from 'src/app/interface/IAmount';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { AccountCardService } from 'src/app/services/account-card.service';
import { EnterAmountErrorService } from 'src/app/services/enter-amount-error.service';
import { InvestmentRegisterService } from 'src/app/services/investment-register.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { NotifyClearFormService } from 'src/app/services/notify-clear-form.service';
import { NotifyServicePaymentService } from 'src/app/services/notify-service-payment.service';
import { SelectAccountErrorService } from 'src/app/services/select-account-error.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Constants, ElementsHTML, FormatDate, Length, Numbers, Position, Tab } from 'src/core/constants/Constants';
import { Utils } from 'src/core/utils/utils';
import { ConsultSubAccountsRequest } from 'src/app/interface/dto/ConsultSubAccountsRequest';
import { ConsultSubAccountsResponse } from 'src/app/interface/dto/ConsultSubAccountsResponse';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { StorageService } from 'src/app/services/storage.service';
import { AlertService } from 'src/app/services/alert.service';
import { Strings } from 'src/core/constants/Strings';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';
import { NotifyRouteService } from 'src/app/services/notify-route.service';
import { InvestmentData } from 'src/app/interface/InvestmentData';
import { InversionsStrings } from 'src/core/constants/InvestmentsStrings';
import { InvestmentService } from 'src/app/services/investment.service';
import { ProductConstants } from 'src/core/constants/ProductConstants';

@Component({
  selector: 'app-investment',
  templateUrl: './investment.component.html',
  styleUrls: ['./investment.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestmentComponent implements OnInit {
  dataBack: InvestmentData[] =
  new Array<InvestmentData>();
  title: string = Strings.INVESTMENT.LabelTitle;
  subtitle: string = Strings.INVESTMENT.EnterInvestmentData;
  enterAmountLabel: string = Strings.INVESTMENT.EnterAmountLabel;
  limitDate: string = Strings.EMPTY;
  dueDate: string = Strings.EMPTY;
  formatDueDate: string = Strings.EMPTY;
  formatDateToDay: string = Strings.EMPTY;
  dateDue: string = Strings.EMPTY;
  dateToDay: string = Strings.EMPTY;
  selectedType: string = Strings.EMPTY;
  selectedDate: string = Strings.EMPTY;
  netReturn: string = Strings.EMPTY;
  interestRate: string = Strings.EMPTY;
  expirationAmount: string = Strings.EMPTY;
  toTheExpirationValue: string = Strings.EMPTY;
  dataForSend: InvestmentData[] = new Array<InvestmentData>();
  alertDataType: boolean = false;
  alertDataDate: boolean = false;
  alertDataRenewal: boolean = false;
  alertService: boolean = false;
  alertRenewal: boolean = false;
  clearForm: boolean = false;
  selectedTypeDescription: string = Strings.EMPTY;
  selectedAccount$: Observable<ICardAccount>;
  amount$: Observable<IAmount>;
  startDate: string = Strings.EMPTY;
  contentMargin: string = ElementsHTML.ContentMargin;
  endDate: string = Strings.EMPTY;
  limitDateBack: string = Strings.EMPTY;
  dueDateBack: string = Strings.EMPTY;
  selectedTypeBack: string = Strings.EMPTY;
  selectedDateBack: string = Strings.EMPTY;
  netReturnBack: string = Strings.EMPTY;
  interestRateBack: string = Strings.EMPTY;
  toTheExpirationBack: string = Strings.EMPTY;
  investmentCode: string = Strings.EMPTY;
  serviceOut: boolean = false;
  disabledRenewal: boolean = false;
  isValidAmount: boolean = false;
  minAmount: number = Constants.MINIMUM_INVESTMENT_AMOUNT;
  titleComponentType : string = Strings.INVESTMENT.SelectInvestmentLabel;
  decimalsAmount: number = ProductConstants.INVESTMENT_DECIMALS_AMOUNT;
  maxDigits: number = ProductConstants.INVESTMENT_MAX_DIGITS_AMOUNT;
  maxLengthAmount: number = ProductConstants.INVESTMENT_MAX_LENGTH_AMOUNT;

  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints }))
  );
  chevronRight: string = Resources.SHAPE_CONTINUE;
  amountMessage: string = Strings.AMOUNT_ERRORS.GreaterAmount;

  constructor(
    private readonly router: Router,
    public accountCardService: AccountCardService,
    private readonly selectAccountError: SelectAccountErrorService,
    private readonly notifyAmount: NotifyAmountService,
    private readonly amountError: EnterAmountErrorService,
    private readonly notifyServicePayment: NotifyServicePaymentService,
    private readonly notifyClearForm: NotifyClearFormService,
    private readonly responsiveService: ResponsiveService,
    private readonly investmentRegisterService: InvestmentRegisterService,
    private readonly modalService: ModalService,
    private readonly baseService: IBaseService,
    private readonly storage: StorageService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly modalAlertService: AlertService,
    private readonly notifyRouteService: NotifyRouteService,
    private readonly investmentService: InvestmentService
  ) {
    this.amount$ = notifyAmount.amount$;
    this.selectedAccount$ = accountCardService.accountCard$;
    if(this.investmentRegisterService.investmentFormData){
      this.dataBack = this.investmentRegisterService.investmentFormData;
    }
  }

  async ngOnInit() {
    this.investmentRegisterService.investmentData$.subscribe(investmentData => {
      if(investmentData?.startDate && investmentData?.endDate){
        this.formatDateToDay = formatDate(investmentData.startDate, FormatDate.InvestmentToday, Constants.LOCALE_ES_MX);
        this.formatDueDate = formatDate(investmentData.endDate, FormatDate.InvestmentToday, Constants.LOCALE_ES_MX);
      }
    });
    if(this.dataBack.length === Length.Empty){
      const accountNumber = this.accountCardService.selectedAccountCard.originAccount;
      if(accountNumber){
        await this.consultSubAccounts(accountNumber);
      }
    }else{
      this.limitDateBack = this.dataBack[Position.Zero].limitDate;
      this.dueDateBack = this.dataBack[Position.Zero].dueDate;
      this.selectedTypeBack = this.dataBack[Position.Zero].selectedType;
      this.selectedDateBack = this.dataBack[Position.Zero].selectedDate;
      this.netReturnBack = this.dataBack[Position.Zero].netReturn;
      this.interestRateBack = this.dataBack[Position.Zero].interestRate;
      this.toTheExpirationBack = this.dataBack[Position.Zero].toTheExpiration;
      this.investmentCode = this.dataBack[Position.Zero].investmentCode;
    }

    this.notifyAmount.amount$.subscribe(dataAmount => {
      const amount = Utils.transformAmount(dataAmount.amount);
      if (Number(amount) === Constants.AMOUNT_ZERO || Number(amount) < Constants.MINIMUM_INVESTMENT_AMOUNT) {
        this.isValidAmount = true;
      } else {
        this.isValidAmount = false;
      }
      this.disabledRenewal = (this.selectedDate === Strings.EMPTY || this.selectedType === Strings.EMPTY || this.isValidAmount);
    });
    NavigationUtils.fixNavigation(this.changeDetector,[PATH.Investment]);
  }


  async consultSubAccounts(account: string) {
    const request = new ConsultSubAccountsRequest({
      IdSession: this.storage.getSession(),
      Cuenta: account
    });
    try {
      const response =
        await this.baseService.genericPost<ConsultSubAccountsResponse>(request, {
          isSOA: true,
        });
      this.investmentRegisterService.investment = response;
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.modalAlertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      }, {
        onSuccess: () => {
          this.accountCardService.clearCardAccount();
          this.investmentRegisterService.clearInvestmentObservable();
        }
      });
    }
  }

  receiveDates(data: Event) {
    this.selectedTypeDescription = data[Position.Zero].dataDescription;
    this.selectedType = data[Position.Zero].dataType;
    this.selectedDate = data[Position.Zero].dataDate;
    this.disabledRenewal = (this.selectedDate === Strings.EMPTY || this.selectedType === Strings.EMPTY || this.isValidAmount);
  }

  receiveInformation(data: Event) {
    this.netReturn = data[Position.Zero]?.netReturn;
    this.interestRate = data[Position.Zero]?.interestRate;
    this.toTheExpirationValue = data[Position.Zero]?.toTheExpirationValue;
    this.serviceOut = data[Position.Zero]?.serviceOut;
  }

  confirmData() {
    const type = this.selectedType;
    const date = this.selectedDate;
    const renewal = this.toTheExpirationValue;
    const serviceOut = this.serviceOut;
    if (type === Strings.EMPTY) {
      this.alertDataType = true;
    } else {
      this.alertDataType = false;
      if (date === Strings.EMPTY) {
        this.alertDataDate = true;
      } else {
        this.alertDataDate = false;
      }
      if (serviceOut) {
        this.alertService = true;
      } else {
        this.alertService = false;
      }
    }
    this.alertDataRenewal = renewal === Strings.EMPTY;
    try {
      const isValidAccount = this.validateSelectedAccount();
      const isValidAmount = this.validateAmount();
      if (isValidAmount && isValidAccount && type && date && renewal && !serviceOut) {
        this.notifyServicePayment.servicePaymentData = { value: true };
        this.notifyClearForm.clearFormData = { value: false };
        this.dataForSend.push(
          {
            limitDate: this.formatDateToDay,
            dueDate: this.formatDueDate,
            selectedType: this.selectedTypeDescription,
            selectedDate: this.selectedDate,
            netReturn: this.netReturn,
            interestRate: this.interestRate,
            toTheExpiration: this.toTheExpirationValue,
            investmentCode: this.selectedType
          });
        this.router.navigate([PATH.ConfirmInvestment],
          {
            state: {
              dataConfirm: this.dataForSend
            }
          });
          this.investmentRegisterService.investmentFormData = this.dataForSend;
      }
    } catch (error) {
      Utils.printLogError(error);
      const errorDetails = Utils.getErrorMsg(error);
      this.modalAlertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MSG_POPUP_TITLE
      });
    }
  }
  private validateSelectedAccount(): boolean {
    const selectedAccount: ICardAccount = this.accountCardService.accountCard;
    const isError = selectedAccount.cardNumber?.length <= Length.Empty || !selectedAccount.productId;
    this.selectAccountError.accountErrorData = { value: isError };
    return !isError;
  }

  private validateAmount(): boolean {
    const userAmount: IAmount = this.notifyAmount.amount;
    const amount = Utils.transformAmount(userAmount.amount);
    let isError = false;
    let errorMsg = Strings.EMPTY;

    if (!amount || Number(amount) === Numbers.Zero) {
        isError = true;
        errorMsg = Strings.GENERIC_ERRORS.Required;
    } else if (Number(amount) < Constants.MINIMUM_INVESTMENT_AMOUNT) {
        isError = true;
        errorMsg = InversionsStrings.MINIMUM_INVESTMENT_MESSAGE;
    }
    this.amountError.amountErrorData = { isError, errorMsg };
    return !isError;
}
  ngOnDestroy() {
    this.modalService.close();
  }

  goBack() {
    const tab = this.investmentService.hasInvestment? Tab.Investment: null;
    this.investmentService.setGoBackTab(this.investmentService.hasInvestment);
    this.notifyRouteService.navigateToTab(tab);
  }

  @HostListener('window:popstate')
  onBack() {
    this.goBack();
  }
}
