import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionLike, map } from 'rxjs';
import { ModalSendEmailComponent } from 'src/app/component/modal-send-email/modal-send-email.component';
import { ConsultationReceiptEdoMexRequest } from 'src/app/interface/IConsultationReceiptEdoMexRequest';
import { IConsultationReceiptEdoMexResponse } from 'src/app/interface/IConsultationReceiptEdoMexResponse';
import { ITransactionReceiptPDFResponse } from 'src/app/interface/ITransactionReceiptPDFResponse';
import { ITransactionVoucher, TransactionVoucherRequest } from 'src/app/interface/TransactionVoucherRequest';
import { GetPDFPromissoryNoteResponse } from 'src/app/interface/dto/GetPDFPromissoryNoteResponse';
import { ITaxConsultResponse } from 'src/app/interface/dto/ITaxConsultResponse';
import { TaxConsultRequest } from 'src/app/interface/dto/TaxConsultRequest';
import { ValidLineResponse } from 'src/app/interface/dto/ValidLineCaptureResponse';
import { ModalOptions } from 'src/app/interface/modal-options';
import { AccountCardService } from 'src/app/services/account-card.service';
import { AlertService } from 'src/app/services/alert.service';
import { ExpressTransferExtraDataService } from 'src/app/services/express-transfer-extra-data.service';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { InvestmentRegisterService } from 'src/app/services/investment-register.service';
import { LocalTaxesCardService } from 'src/app/services/local-taxes-card.service';
import { StorageService } from 'src/app/services/storage.service';
import { ModalService } from 'src/app/shared/modal.service';
import { AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { Char, Constants, ElementsHTML, FileFormats, Files, Numbers, Position, Regex } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { AppBridge } from 'src/core/utils/AppBridge';
import { Utils } from 'src/core/utils/utils';
import { GetPDFPromissoryNoteRequest, TGetPDFPromissoryNoteRequest } from 'src/app/interface/dto/GetPDFPromissoryNoteRequest';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { StateServicePayIdService } from 'src/app/services/state-service-pay-id.service';
import { GetPDFSipareResponse } from 'src/app/interface/dto/GetPDFSipareResponse';
import { GetPDFSipareRequest } from 'src/app/interface/dto/GetPDFSipareRequest';
import * as htmlToImage from 'html-to-image';
import { WebCardStrings } from 'src/core/constants/WebCardStrings';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { FileUtils } from 'src/core/utils/FileUtils';
import { ImageUtils } from 'src/core/utils/imageUtils';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { StylesConstants } from 'src/core/constants/StylesConstants';
import { AppUtils } from 'src/core/utils/AppUtils';
import { AppNavigation } from 'src/app/models/AppNavigation';
import { HTMLConstants } from 'src/core/constants/HTMLConstants';
import { ModalTransactionsComponent } from 'src/app/home/components/modal-transactions/modal-transactions.component';

@Component({
  selector: 'app-shared-buttons',
  templateUrl: './shared-buttons.component.html',
  styleUrls: ['./shared-buttons.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedButtonsComponent implements OnInit {

  fileDownloadingLc: string = Resources.FILE_DOWNLOAD_IN_LC;
  shareImage: string = Resources.SHARE;
  mailImage: string = Resources.MAIL;
  referenceNumber: number;
  referenceNumberSubscription: SubscriptionLike;
  accountInfo: string;
  accountInfoSubscription: SubscriptionLike;
  idService: string = Strings.EMPTY;
  accountNumber: string = Strings.EMPTY;
  certificateNumber: string = Strings.EMPTY;
  requestInfo: ITransactionReceiptPDFResponse;
  requestEdoMex: ConsultationReceiptEdoMexRequest;
  requestInvestment: GetPDFPromissoryNoteRequest;

  @Input() data: ITransactionVoucher;
  @Input() investmentVoucher: TGetPDFPromissoryNoteRequest;
  @Input() download: boolean = true;
  @Input() showButtons?: { email: boolean, download: boolean, share: boolean, print: boolean };
  @Input() fileName: string;
  @Input() origin: string;
  @Input() showMovementsModel: boolean = false;
  @Input() hideDownload?: boolean;
  @Input() captureImage?: { name: string, element: HTMLElement };
  @Input() shareCapture?: { name: string, element: HTMLElement };
  @Input() webCard?: boolean;
  @Input() hideMail?: boolean;
  @Input() emailTransferSuccessful?: string;
  @Output() downloadFiles = new EventEmitter<void>();
  @Output() onCaptureError = new EventEmitter<NgbModalRef>();
  @Output() paymentFederalTaxByConcept = new EventEmitter<void>();

  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints }))
  );

  downloadLabel: string = Strings.SHARED_BUTTONS.Download;
  shareLabel: string = Strings.SHARED_BUTTONS.Share;
  mailLabel: string = Strings.SHARED_BUTTONS.Mail;

  constructor(
    private readonly baseService: IBaseService,
    private readonly storage: StorageService,
    private readonly expressTransferExtraDataService: ExpressTransferExtraDataService,
    public readonly cardAccountService: AccountCardService,
    private readonly modalService: ModalService,
    private readonly responsiveService: ResponsiveService,
    private readonly dataTaxesCardService: LocalTaxesCardService,
    private readonly alertService: AlertService,
    private readonly investmentRegisterService: InvestmentRegisterService,
    private readonly _router: Router,
    private readonly stateServicePayIdService: StateServicePayIdService,
    private readonly modalAlertService: AlertService
  ) { }

  ngOnInit(): void {
    this.referenceNumberSubscription = this.cardAccountService.accountCard$.subscribe(
      async (e) => {
        this.accountInfo = e.accountNumberExpress;
      }
    );
    this.referenceNumberSubscription =
      this.expressTransferExtraDataService.expressTransferExtraDataObservable.subscribe(
        async (e) => {
          this.referenceNumber = parseInt(e.referencia);
        }
      );
    this.investmentRegisterService.investmentData$.subscribe(investmentData => {
      this.certificateNumber = investmentData.certificateNumber;
    });
    this.idService = this.stateServicePayIdService.service.id;
  }

  ngOnDestroy(): void {
    this.referenceNumberSubscription?.unsubscribe();
    this.accountInfoSubscription?.unsubscribe();
  }

  private async pdfTransaction(device: boolean[]) {
    let voucherRequest: TransactionVoucherRequest;
    let requestEdoMex: ConsultationReceiptEdoMexRequest;
    let requestInvestment: GetPDFPromissoryNoteRequest;
    let requestSipare: GetPDFSipareRequest;
    if (this.data && this.idService === Constants.SERVICE_CODE.MexicoState) {
      requestEdoMex = new ConsultationReceiptEdoMexRequest({
        Clave: this.data.NumeroReferencia.toString(),
        IdSession: this.data.IdSession
      });
    } else if (this.origin === Constants.SERVICE_CODE.Sipare) {
      requestSipare = new GetPDFSipareRequest({
        idPagoSipare: this.data.NumeroReferencia
      });
    } else if (this.data) {
      voucherRequest = new TransactionVoucherRequest({
        IdSession: this.data.IdSession,
        NumeroCuenta: this.data.NumeroCuenta,
        NumeroReferencia: this.data.NumeroReferencia
      });
    } else if (this._router.url === PATH.CompleteInvestmentRegistration) {
      this.investmentRegisterService.accountNumber$.subscribe(accountNumber => {
        this.accountNumber = accountNumber.NumeroCuenta;
      });
      requestInvestment = new GetPDFPromissoryNoteRequest({
        NumeroCertificado: this.certificateNumber,
        IdSession: this.storage.getSession(),
        NumeroCuenta: this.accountNumber
      });
      this.investmentVoucher = {
        NumeroCertificado: requestInvestment.data.NumeroCertificado,
        IdSession: requestInvestment.data.IdSession,
        NumeroCuenta: requestInvestment.data.NumeroCuenta
      };
    } else {
      voucherRequest = new TransactionVoucherRequest({
        IdSession: this.storage.getSession(),
        NumeroCuenta: this.accountInfo,
        NumeroReferencia: this.referenceNumber
      });
      this.data = {
        IdSession: voucherRequest.data.IdSession,
        NumeroCuenta: voucherRequest.data.NumeroCuenta,
        NumeroReferencia: voucherRequest.data.NumeroReferencia
      };
    }
    await this.getBase64(requestEdoMex, requestInvestment, voucherRequest, device, requestSipare);
  }


  async getBase64(requestEdoMex: ConsultationReceiptEdoMexRequest,
    requestInvestment: GetPDFPromissoryNoteRequest, voucherRequest: TransactionVoucherRequest, device: boolean[], requestSipare: GetPDFSipareRequest) {
    try {
      const base64 = await this.getFile(requestEdoMex, requestInvestment, voucherRequest, device, requestSipare);

      this.fileName = (this.fileName === undefined || this.fileName === Strings.EMPTY) ? Files.InbursaFile : this.fileName;
      if (AppUtils.platform.isApp) {
        try {
          const navigationParams: AppNavigation = {
            Data: AppScreen.ShowPDF,
            DataParams: base64
          };
          this.resetModal();
          await AppBridge.invoke<string>(AppMethod.SendNativeScreen, navigationParams);
        } catch (error) {
          const message = Utils.getErrorMsg(error);
          this.alertService.showPopupAlert({
            message: Utils.SERVICE_ERROR_MSG(message.msg, message.code),
            header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
            imgHead: Resources.CLOSE_ICON_RED,
            btnExit: false
          });
        }
      } else if (base64) {
        this.downloadPDF(base64, device);
      }
    } catch (error) {
      this.modalService.close();
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      });
    }
  }

  async getFile(requestEdoMex: ConsultationReceiptEdoMexRequest,
    requestInvestment: GetPDFPromissoryNoteRequest, voucherRequest: TransactionVoucherRequest, device: boolean[], requestSipare: GetPDFSipareRequest) {
    let base64 = Strings.EMPTY;
    if (this.idService === Constants.SERVICE_CODE.MexicoState) {
      const transactionEdoMexPDF =
        await this.baseService.genericGet<IConsultationReceiptEdoMexResponse>(
          requestEdoMex,
          {
            isSOA: true,
            urlOptions: [
              requestEdoMex.data.Clave,
              requestEdoMex.data.IdSession
            ]
          }
        );
      base64 = transactionEdoMexPDF.PDFBase64;
    } else if (this.idService === Constants.SERVICE_CODE.Imploc) {
      const cardLocalTax: ValidLineResponse = this.dataTaxesCardService.localCardTaxes;
      const options = [
        cardLocalTax.lineaCaptura,
        this.data.NumeroReferencia,
        this.data.IdSession
      ];
      const transactionCDMX =
        await this.baseService.genericGet<ITaxConsultResponse>(
          new TaxConsultRequest(),
          { isSOA: true, urlOptions: options }
        );
      base64 = transactionCDMX.PdfBase64 || transactionCDMX.Reporte;
    } else if (this._router.url === PATH.CompleteInvestmentRegistration) {
      const promissoryNotePDF =
        await this.baseService.genericPost<GetPDFPromissoryNoteResponse>(requestInvestment, { isSOA: true, }
        );
      base64 = promissoryNotePDF.base64;
    } else if (this.origin === Constants.SERVICE_CODE.Sipare) {
      const pdfSipare =
        await this.baseService.genericPost<GetPDFSipareResponse>(requestSipare, { addSession: true, isSecure: true });
      base64 = pdfSipare.datos;
    } else if (voucherRequest.data.NumeroReferencia) {
      const transactionPDF =
        await this.baseService.genericPost<ITransactionReceiptPDFResponse>(
          voucherRequest,
          { isSOA: true }
        );
      base64 = transactionPDF.Base64;
    }
    return base64;
  }

  downloadPDF(base64: string, device: boolean[]) {
    if (this.download) {
      this.fileName === Constants.NAME_PDF_TDC_PAYMENT ? Utils.downloadFile(base64, this.fileName) :
        Utils.downloadAccountStatusPdf(base64);
    } else if (device[Position.Zero]) {
      Utils.downloadFile(base64, this.fileName.replace(Regex.Space, Strings.EMPTY));
    } else if (device[Position.One]) {
      Utils.shareFile(base64, this.fileName.replace(Regex.Space, Strings.EMPTY));
    } else {
      FileUtils.downloadPdf(base64);
    }
  }

  getTransactionPDF(device: boolean[]) {
    if (this.captureImage) {
      this.captureElements();
    } else {
      if (this.downloadFiles.observed) {
        this.downloadFiles.emit();
      } else if (this.paymentFederalTaxByConcept.observed) {
        this.paymentFederalTaxByConcept.emit();
      } else {
        this.pdfTransaction(device);
      }
    }
  }

  resetModal(){
    if (AppUtils.platform.isApp && this.showMovementsModel) {
      this.modalService.close();
      const modalOptions: ModalOptions = {
        centered: true,
        size: Constants.MODAL_OPTIONS.SizeMd,
        modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAccountInformation
      };
      this.modalService.open(ModalTransactionsComponent, modalOptions);
    } else{
      this._router.navigate([PATH.Home]);
    }
  }

  showModalSendEmail() {
    const modalData = {
      NumeroReferencia: this.data.NumeroReferencia,
      NumeroCuenta: this.data.NumeroCuenta,
      email: this.emailTransferSuccessful
    };
    this.modalService.close();
    const modalOptions: ModalOptions = {
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalSendEmail,
      centered: true
    };
    this.modalService.open(ModalSendEmailComponent, modalOptions, modalData);
  }


  captureElements() {
    const imgs = this.addCacheBusting(true);
    htmlToImage.toJpeg(this.captureImage?.element, { filter: this.filter, backgroundColor: StylesConstants.HEX_WHITE_COLOR })
      .then((dataUrl) => {
        this.removeCrossOrigin(imgs);
        if (AppUtils.platform.isApp) {
          this.shareApp(dataUrl);
        }
        else {
          const link = document.createElement(ElementsHTML.LabelA);
          link.download = this.captureImage.name;
          link.href = dataUrl;
          link.click();
        }

      }).catch((error) => {
        this.removeCrossOrigin(imgs);
        const errorDetails = Utils.getErrorMsg(error);
        const res = this.modalAlertService.showPopupAlert({
          message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
          header: Strings.MSG_POPUP_TITLE,
          btnLabel: Strings.MSG_POPUP_ACCEPT,
          imgHead: Resources.CLOSE_ICON_RED,
          btnExit: false
        });
        this.onCaptureError.emit(res);
      });
  }


  shareElements(): void {
    const imgs = this.addCacheBusting(false);
    htmlToImage.toJpeg(this.shareCapture.element, { filter: this.filter, backgroundColor: StylesConstants.HEX_WHITE_COLOR })
      .then((dataUrl: string) => {
        this.removeCrossOrigin(imgs);
        if (AppUtils.platform.isApp) {
          this.shareApp(dataUrl);
        } else {
          const blob = ImageUtils.imageUrlToBlob(dataUrl);
          const file = new File([blob], `${this.shareCapture.name}${WebCardStrings.WebCard.ImageFormat}`, { type: FileFormats.PNGImage });
          if (navigator.share) {
            navigator.share({
              files: [file]
            })
              .catch(() => {
                this.captureElements();
              });
          } else {
            throw new BusinessError(
              Strings.SERVICES.UnexpectedMsg,
              Constants.UNEXPECTED_CODE
            );
          }
        }
      })
      .catch(() => {
        this.removeCrossOrigin(imgs);
        this.captureElements();
      });
  }

  shareApp(dataUrl:string) {
    const imgBase64 = dataUrl.split(Char.Comma)[Numbers.One];
    const navigationParams: AppNavigation = {
      Data: imgBase64
    };
    AppBridge.invoke<string>(AppMethod.GetScreenShot, navigationParams).catch(error => {
      const message = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(message.msg, message.code),
        header: Strings.MODAL_EXCEPTIONS_POINTS.Service.Header,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      });
    });
  }

  filter = (node: HTMLElement) => {
    const exclusionClasses = [ModalConstants.IGNORE_ELEMENT];
    return !exclusionClasses.some((classname) => node.classList?.contains(classname));
  }

  addCacheBusting(isCaptureImage: boolean) {
    if (!this.captureImage && !this.shareCapture) {
      return null;
    }
    const imgs = (isCaptureImage ? this.captureImage : this.shareCapture).element.querySelectorAll(HTMLConstants.IMAGE_ELEMENT);
    imgs.forEach((img) => {
      const url = new URL(img.src);
      url.searchParams.set(HTMLConstants.IMAGE_PARAM_VERSION, new Date().getTime().toString());
      img.src = url.href;
      img.crossOrigin = Strings.EMPTY;
    });
    return imgs;
  }

  removeCrossOrigin(imgs: NodeListOf<HTMLImageElement>) {
    if(imgs){
      imgs.forEach((img) => {
        img.removeAttribute(HTMLConstants.CROSS_ORIGIN_ATTRIBUTE);
      });
    }
  }
}
