import { Component, OnInit } from '@angular/core';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { DataCheckbookService } from 'src/app/services/data-checkbook.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Utils } from 'src/core/utils/utils';
import { DataCheckBook } from 'src/app/interface/DataCheckBook';
import { Resources } from 'src/core/constants/Resources';
import { ClassHTML, Constants } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { Numbers } from 'src/core/constants/Numbers';
import { ModalTransactionsComponent } from 'src/app/home/components/modal-transactions/modal-transactions.component';
import { StringsChecks } from 'src/core/constants/ChecksStrings';

@Component({
  selector: 'app-modal-checks-request-data',
  templateUrl: './modal-checks-request-data.component.html',
  styleUrls: ['./modal-checks-request-data.component.css']
})
export class ModalChecksRequestDataComponent implements OnInit {
  removeOperation: string = Resources.CLOSE_CIRCLE_BLUE;
  breakpoints$ = this.responsiveService.observe;
  completeDate!: string;
  vm$ = this.responsiveService.observe;
  showModal: boolean = false;
  showBranch: boolean = false;
  selected: boolean = false;
  checkbookData: DataCheckBook;
  isIpad: boolean = false;
  counterNum:number = Numbers.Zero;
  widthTablet: number = Constants.RESOLUTIONS.IpadMinWidth;
  selectCheckBookType = [{value: Numbers.Fifty},{value: Numbers.ThreeHundred}];
  value: string = Numbers.Fifty.toString();
  textButton: string = Strings.BUTTONS.Continue;
  classBtnContinue: string = ClassHTML.SendButton;
  chevronRight: string = Resources.BTN_CHEVRON_RIGHT;

  checkbookRequest: string = StringsChecks.checkbookRequest;
  checkbookSerialNumber: string = StringsChecks.checkbookSerialNumber;
  continueBtn: string = Strings.BUTTONS.Continue;
  constructor(private readonly modalService: ModalService,
    private readonly responsiveService: ResponsiveService,
    private readonly dataCheckBookService: DataCheckbookService,
    private readonly accountInfoStatusService: AccountInfoStatusService) { }

  ngOnInit(): void {
    this.isTabletMobile();
  }

  selectedType(event: Event) {
    this.value = (event.target as HTMLInputElement).value;
  }

  close() {
    this.modalService.close();
    this.modalService.open(ModalTransactionsComponent);
  }
  continue() {
    this.showBranch = true;
    this.showModal = true;
    this.dataCheckBookService.checkBookArray$.subscribe((data) => {
      this.counterNum = data.length;
    });
    this.counterNum++;
    this.checkbookData = {
      accountNumber: Utils.maskNumber(this.accountInfoStatusService.account.numeroCuenta),
      accountName: this.accountInfoStatusService.account.descripcionCuenta,
      typeCheckBook: this.value,
      numberCheckBook: this.counterNum.toString(),
      state: Strings.CHECK_ACTIVATION.Filters.Pending,
      active: true
    };
    this.dataCheckBookService.branchData = this.checkbookData;
    this.close();
  }

  isTabletMobile() {
    const agent = window.navigator.userAgent.toLowerCase();
    if ((window.screen.width <= this.widthTablet && agent.match(Constants.USER_AGENT.Mac)) || (agent.match(Constants.USER_AGENT.Mobile))) {
      this.isIpad = true;
    } else {
      this.isIpad = false;
    }
  }
}
