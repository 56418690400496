import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { finalize, Observable} from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { Constants, Numbers } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: number = Numbers.Zero;
  private readonly skipLoader = [
    Resources.LoaderInterceptor.IMAGE_LOGIN,
    Resources.LoaderInterceptor.KEY_BANK,
    Resources.LoaderInterceptor.LOGIN,
    Resources.LoaderInterceptor.MOVEMENTS,
    Resources.LoaderInterceptor.PERIODS_CONSULT,
    Resources.LoaderInterceptor.IFM_PROMOTION_CDMX
  ];

  constructor(private readonly loaderService: LoaderService) {
    this.loaderService.resetRequest$.subscribe(data => {
      if (data){
        this.requests = Numbers.Zero;
      }
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.requests++;

    if (this.showSpinner(request.url)) {
      this.loaderService.showLoader();
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (this.requests > Numbers.Zero){
          this.requests--;
        }

        if (this.requests === Numbers.Zero) {
          this.loaderService.hideLoader();
        }
      })
    );
  }

  private showSpinner(url: string) {
    return this.skipLoader.findIndex(skip => url.indexOf(skip) > Constants.NOT_FOUND_ITEM) === Constants.NOT_FOUND_ITEM;
  }
}
