import { Injectable } from '@angular/core';
import { environment, services } from 'src/environments/environment';
import { PathModelLegacy } from '../models/PathModel';
import { Resources } from 'src/core/constants/Resources';
import { Utils } from 'src/core/utils/utils';
import { DeviceUtils } from 'src/core/utils/deviceUtils';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private appConfig: {[key: string]: string} | PathModelLegacy;

  constructor() { }

  async loadConfig(){
    this.appConfig = this.appConfig = services;
    Resources.PATH_CDN =environment.HostCdn;
    this.validateEnvironment();
    return this.appConfig;
  }

  getConfig():  {[key: string]: string} | PathModelLegacy{
    return this.appConfig;
  }

  private validateEnvironment() {
    try{
      if(DeviceUtils.isLocalhost) {
        services.Environment = services.EnvironmentDev;
      }
    }
    catch(error) {
      Utils.printLogError(error);
    }
  }

}
