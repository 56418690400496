import { formatDate } from '@angular/common';
import { Component, ElementRef, HostListener, TemplateRef, ViewChild, WritableSignal, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, SubscriptionLike, combineLatestWith, map, tap } from 'rxjs';
import { AccountModel } from 'src/app/interface/AccountModel';
import { DataCheckBook } from 'src/app/interface/DataCheckBook';
import { ModalOptions } from 'src/app/interface/modal-options';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { BureauAlertsService } from 'src/app/services/bureau-alerts.service';
import { CheckBoxService } from 'src/app/services/checks-value-service';
import { DataCheckbookService, Directions } from 'src/app/services/data-checkbook.service';
import { ModalMapComponent } from 'src/app/shared/components/modal-map/modal-map.component';
import { OtpCardReplacementComponent } from 'src/app/shared/components/otp-card-replacement/otp-card-replacement.component';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { ChecksResources } from 'src/core/constants/ChecksResources';
import { StringsChecks } from 'src/core/constants/ChecksStrings';
import { ClassHTML, Constants, FormatDate } from 'src/core/constants/Constants';
import { FormRegex } from 'src/core/constants/FormConstants';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { Numbers } from 'src/core/constants/Numbers';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { LeaveModalUtils } from 'src/core/utils/LeaveModalUtils';
import { Utils } from 'src/core/utils/utils';
import { AlertService } from 'src/app/services/alert.service';
import { ChecksConstants } from 'src/core/constants/ChecksConstants';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-modal-confirm-checkbook-details',
  templateUrl: './modal-confirm-checkbook-details.component.html',
  styleUrls: ['./modal-confirm-checkbook-details.component.css']
})
export class ModalConfirmCheckbookDetailsComponent {
  isMobile: boolean = false;
  modalData:string;
  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(
      this.dataCheckBookService.directionsData$,this.dataCheckBookService.resultBranches$),
    tap(([breakpoints, directions]) => {
      this.isMobile = breakpoints.mobile;
      this.branchName = directions.name;
      this.numberBranch = Number(directions.id);
    }),
    map(([breakpoints,  directionsData, resultBranches ]) => (
      { breakpoints,  directionsData, resultBranches}
    ))
  );
  imgClose: string = Resources.CLOSE_CIRCLE_BLUE;
  imgCheck: string = ChecksResources.CHECK_BANK;
  chevronRight: string = Resources.BTN_CHEVRON_RIGHT;
  checkFill: string = Resources.GREEN_CHECK_FILL;
  iconChevron: string = Resources.BTN_CHEVRON_RIGHT;
  successConfirmChecks: boolean = false;
  checkbookAdd: DataCheckBook;
  accountNumber: string = Strings.EMPTY;
  numberCheckBook: string = Strings.EMPTY;
  typeCheckBook: string = Strings.EMPTY;
  branch: string = Strings.EMPTY;
  completeDate: string = Strings.EMPTY;
  accountInfo$: Observable<AccountModel>;
  accountName: string = Strings.EMPTY;
  requestConfirmation: string = StringsChecks.requestConfirmation;
  dataConfirmationLbl: string = StringsChecks.dataConfirmation;
  checkbookRequest: string = StringsChecks.checkbookRequest;
  associatedAccount: string = StringsChecks.associatedAccount;
  chargeToAccount: string = StringsChecks.chargeToAccount;
  operationInformation: string = StringsChecks.operationInformation;
  branchName: string = Strings.EMPTY;
  checkbookPickupLocation: string = StringsChecks.checkbookPickupLocation;
  confirmAndContinue: string = Strings.BUTTONS.ConfirmAndContinue;
  modify: string = Strings.BUTTONS.Modify;
  ready: string = Strings.BUTTONS.Ready;
  other: string = Strings.BUTTONS.OtherOperation;
  continueBtn: string = Strings.BUTTONS.Continue;
  branchSelection: string = StringsChecks.branchSelection;
  nearbyBranchPickupPrompt: string = StringsChecks.nearbyBranchPickupPrompt;
  numberBranch: number = Numbers.Zero;
  successPost: boolean = false;
  styles: Partial<CSSStyleDeclaration> = {
    display: ClassHTML.WebkitBox,
    webkitBoxOrient: ClassHTML.BoxOrientVertical,
    overflowWrap : ClassHTML.AnyWhere
  };
  captureImage: {name:string,element:HTMLElement};
  @ViewChild(Strings.LABEL_ADD_PRODUCT.OTP) otpModal: ElementRef<HTMLInputElement>;
  @ViewChild('ModalMapFooter') modalMapFooter: TemplateRef<HTMLElement>;
  @ViewChild('ModalMapSelect') modalMapSelect: TemplateRef<HTMLElement>;
  @ViewChild('containerGeneral') containerGeneral: ElementRef<HTMLElement>;
  modalTokenClass: ModalOptions = {
    centered: false,
    windowClass: ModalConstants.MODAL_OPTIONS.GenericClass
  };
  options: ModalOptions = {
    size: Constants.MODAL_OPTIONS.SizeMd,
    modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalAlertEndProcess
  };
  directions: WritableSignal<Array<Directions>> = signal([]);
  stringLocation = FormRegex.Locations;
  imageCard = Strings.EMPTY;
  applyScreenClass: boolean = false;
  accountInfoSL: SubscriptionLike;
  modalInstance: NgbModalRef;

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly modalService: ModalService,
    private readonly dataCheckBookService: DataCheckbookService,
    readonly accountInfoService: AccountInfoStatusService,
    private readonly bureauAlertsService: BureauAlertsService,
    private readonly checkBoxService: CheckBoxService,
    private readonly router: Router,
    private readonly alertService: AlertService) {
      this.accountInfo$ = accountInfoService.account$;
  }

  ngOnInit(): void {

    const date = new Date();
    this.completeDate = formatDate(date, FormatDate.DayMonthYearComplete, Constants.LOCALE_ES_MX);

    this.accountInfoSL = this.accountInfo$.subscribe(accountInfo => {
      this.accountNumber = `${accountInfo.numeroCuenta}`;
      this.accountName = `${accountInfo.descripcionCuenta}`;
      this.imageCard = `${accountInfo.directorioImagen}`;
    });
    this.vm$.subscribe(data => {
      this.branchName = data.directionsData.name;
      this.numberBranch = Number(data.directionsData.id);
    });

    this.successConfirmChecks = this.dataCheckBookService.checkbookRequestProcess;

    if (this.successConfirmChecks && this.modalData) {
      this.sendRequest();
    }
  }
  async sendRequest() {
    try {
      const response = await this.dataCheckBookService.checkbookRequest(this.numberBranch, this.accountNumber, this.modalData);
      if (!response.datos) {
        throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
      }
      this.successPost = true;
      setTimeout(() => {
        this.captureImage = {
          name: `${ChecksConstants.NAME_VOUCHER}${this.accountNumber}`,
          element: this.containerGeneral.nativeElement
        };
        this.applyScreenClass = true;
      });
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.successPost = false;
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: errorDetails.code === Constants.UNEXPECTED_CODE ? Strings.MSG_POPUP_TITLE : Strings.MODAL_BRANCHES.ErrorTitle,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      }, {
        onSuccess: () => this.modalService.close()
      });
    }
    this.dataCheckBookService.checkbookRequestProcess = false;
  }

  continue(){
    this.dataCheckBookService.statusConfirmOTP = true;
    this.modalService.close();
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.OtpChecksConfirmation
    };
    const modalRef = this.modalService.open(OtpCardReplacementComponent, modalOptions);
    this.dataCheckBookService.checkbookRequestProcess$.next(true);
    modalRef.componentInstance.token = modalRef;
  }

  closeModal(isMobile: boolean) {
    this.modalService.close();
    this.dataCheckBookService.checkbookRequestProcess$.next(false);
    this.dataCheckBookService.statusData = true;
    if (isMobile) {
      this.modalService.showModal();
    }
  }

  modifyLocation(){
    this.dataCheckBookService.checkbookRequestProcess$.next(false);
    this.modalService.close();
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.MapModalChecks,
      windowClass: Constants.MODAL_OPTIONS.NoBorder
    };
    const ref = this.modalService.open(ModalMapComponent, modalOptions);
    ref.componentInstance.context = this;
    ref.componentInstance.footerTemplate = this.modalMapFooter;
    ref.componentInstance.isCustomTemplate = true;
    ref.componentInstance.isChecksRequest = true;
    ref.componentInstance.functionClose = () => {
      if(this.isMobile) {
        Utils.showModalTransactions(this.modalService);
        this.checkBoxService.setCheckHome(true);
        return;
     }else{
      this.modalService.close();
     }
    };
    this.bureauAlertsService.leaveModalMap = {value: false};
  }

  showConfirmCheck(){
    this.modalService.close(ModalConfirmCheckbookDetailsComponent);
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalConfirmCheckbookDetails
    };
    this.modalService.open(ModalConfirmCheckbookDetailsComponent, modalOptions);
  }

  confirmLeavePage() {
    this.modalService.close();
    this.bureauAlertsService.leaveModalMap.value = true;
    LeaveModalUtils.confirmLeavePage(this.modalService);
  }


  otherOperation(){
    this.closeModal(this.isMobile);
    if(this.isMobile) {
      Utils.showModalTransactions(this.modalService);
      return;
    }
  }

  returnChecks(){
    this.modalService.close();
    this.router.navigate([PATH.Home]);
    this.dataCheckBookService.reloadCheckBooks = true;
  }

  onDestroy() {
    this.accountInfoSL?.unsubscribe();
  }

  @HostListener('window:popstate')
  backNavigation() {
    this.modalInstance?.dismiss();
  }
}
