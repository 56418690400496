import { Component } from '@angular/core';
import { map } from 'rxjs';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Resources } from 'src/core/constants/Resources';

@Component({
  selector: 'app-modal-help-series-token',
  templateUrl: './modal-help-series-token.component.html',
  styleUrls: ['./modal-help-series-token.component.css']
})
export class ModalHelpSeriesTokenComponent {
  token: string = Resources.VIEW_TOKEN_3;
  closeImg: string = Resources.CLOSE_CIRCLE_BLUE;

  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints }))
  );
  constructor(
    private readonly modalService: ModalService,
    private readonly responsiveService: ResponsiveService
  ) {}

  close() {
    this.modalService.close();
  }

}
