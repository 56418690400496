import { PathModel } from 'src/app/models/PathModel';

export interface IFederalTaxPayment{
  cadena: string;
  cadenaDatos: string;
  persona: UserData;
  importe: number;
  cuentaClabe: string;
  idCuentaProducto: number;
  otp: string;
}

interface UserData{
  nombre: string;
  apellidoPaterno: string;
  apellidoMaterno?: string;
  rfc?: string;
}

export class FederalTaxPaymentRequest{
  constructor(public data: IFederalTaxPayment){}

  get endpoint(): PathModel{
      return PathModel.FederalTaxPayment;
  }
}
