import { Injectable, WritableSignal, signal } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from 'src/app/interface/modal-options';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  modalRef: NgbModalRef;
  private  _modalInstance: NgbModalRef = null;
  private readonly modalVisibleSubject = new BehaviorSubject<boolean>(false);
  modalTransactionsVisible$ = this.modalVisibleSubject.asObservable();
  private _yesToken: boolean = false;
  private readonly _disableTabNavigation: WritableSignal<boolean> = signal<boolean>(false);
  hasOpenModals:boolean;
  constructor(private readonly modalServ: NgbModal) { }

  open(content: any, options?: ModalOptions, data?: any): NgbModalRef {
    this.modalRef = this.modalServ.open(content, {
      ...options,
      keyboard: false,
      backdrop: 'static',
      animation: false,
      windowClass: `moz-hide-scroll ${options?.windowClass}`
    });
    this.modalInstance = this.modalRef;
    this.disableTabNavigation = true;
    if(this.modalRef.componentInstance){
      this.modalRef.componentInstance.modalData = data;
      this.modalRef.componentInstance.modalInstance = this.modalRef;
    }
    this.modalInstance.result.finally(()=>{
      this.disableTabNavigation = false;
    });
    this.hasOpenModals = this.modalServ.hasOpenModals();
    return this.modalRef;
  }

  close(result?: any) {
    this.modalInstance = this.modalRef;
    if (result != null) {
      this.modalRef?.close(result);
    } else {
      this.modalServ?.dismissAll();
    }
    this.hasOpenModals = false;
    this.disableTabNavigation = false;
  }

  get modalInstance(){
    return this._modalInstance;
  }

  set modalInstance(data: NgbModalRef) {
    this._modalInstance = data;
  }

  clearModalInstance() {
    this.hasOpenModals = false;
    this._modalInstance = null;
  }

  showModal(){
    this.modalVisibleSubject.next(true);
  }

  hideModal() {
    this.modalVisibleSubject.next(false);
  }

  setYesToken(state: boolean) {
    this._yesToken = state;
}

  getYesToken(){
      return this._yesToken;
  }

  reset(){
    this._yesToken = false;
  }

  get disableTabNavigation() {
    return this._disableTabNavigation();
  }

  set disableTabNavigation(data: boolean) {
    this._disableTabNavigation.update(() => data);
  }
}
