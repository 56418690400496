<div id="modal-unemployment" >
<div  [ngClass]="!nextPDF ? '' : 'modal-two'">
    <div class="d-flex col-12 flex-row-reverse icon-modal">
      <img role="button" (click)="close()" class="close-modal"
      src="{{ iconClose | cdnImage }}"
      width="auto" height="auto" alt="close-modal">
    </div>
    <div class="d-title-m">
      <label class="header-l" [ngClass]="!nextPDF ? '' : 'header-two'">{{title}} <br></label>
    </div>
    <div id="modal-subtitle" class="mt-2-sub body-m" >
    {{'Valida la información'}}
  </div>
    <div class="pdf-modal ustify-content-center col-12 mt-2">
      <div class="pdf-view" [ngClass]="!nextPDF ? 'format-one' : 'format-two'">
        <iframe id="idFrame" title="" class="pdf-style" [src]="viewPdf"></iframe>
      </div>
    </div>

    <div class="col-12 center-btn">
      <button class="style-btn modals-btn btn-afore button-m"
      (click)="nextModal()">
      {{buttonContinue}} <img alt="modal-btn" [src]="chevronRight | cdnImage" width="auto" height="auto" />
      </button>
    </div>
  </div>
</div>
<ng-template #otp>
  <app-otp-card-replacement [optionsToken]="optionsModalToken" [token]="closeOtpModal">
  </app-otp-card-replacement>
</ng-template>
