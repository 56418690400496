import { PathModel } from '../models/PathModel';

export interface ITransactionVoucher {
  NumeroReferencia: number;
  IdSession: string;
  NumeroCuenta: string;
}

export class TransactionVoucherRequest {
  constructor(public data: ITransactionVoucher) { }

  get endpoint(): PathModel {
    return PathModel.TransactionReceiptPDF;
  }
}
