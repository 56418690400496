<ng-container *ngIf="vm$ | async as vm">
  <div
    class="header-xxxl lh-sm"
    [class.break]="customCurrency ? !useBreak :useBreak"
    [class.break-max-width]="maxWidth"
    [class.text-center]="textCenter"
    [ngStyle]="{ color: color }"
    [ngClass]="amountClass"
  >
    {{ vm.amount }}
    <label *ngIf="customCurrency" class="policy-label">{{currency}}</label>
  </div>
  <div class="detail-m" [ngClass]="centeredLabel ? 'd-flex justify-content-center' : ''" *ngIf="showLabel">
    {{ label }}
  </div>
</ng-container>
