import { Numbers } from 'src/core/constants/Numbers';

export class BrokerageHouseStrings {
  static readonly CODE_BROKERAGE_HOUSE = Numbers.Three;
  static readonly SUBTITLE = 'Confirma los datos de la operación';
  static readonly ZERO_CONTRACT = '0';

  static readonly BROKERAGE_HOUSE_INFORMATION = {
    SharedText:{
      Head:{
        Account: 'Datos de la cuenta',
        Addresee: 'Datos del destinatario',
        Limit: 'Límite',
        Contact: 'Contacto para notificaciones'
      },
      Value:{
        TypeAdresee: 'Contrato casa de bolsa'
      },
      Body:{
        TypeAddressee: 'Tipo de destinatario',
        Contract: 'Número de contrato',
        Name: 'Nombre del titular',
        Alias: 'Alias de la cuenta',
        Limit: 'Límite diario máximo',
        Email: 'Email del beneficiario'
      },
    },
    Success:{
      Title: 'Destinatario registrado',
      Subtitle: 'Información de la operación',
      FileName: 'Alta_Casa_De_Bolsa'
    }
  };
  static readonly BROKERAGE_HOUSE_THIRD_PARTIES = 'Casa de Bolsa Terceros Inbursa';
}
