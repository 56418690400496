import { Char, FormValidations, InputTypes, Numbers, Regex} from 'src/core/constants/Constants';
import { InputConfig } from 'src/app/interface/IInputConfig';
import { Resources } from 'src/core/constants/Resources';
import { Validators } from '@angular/forms';
import { Strings } from 'src/core/constants/Strings';
import { WebCardStrings } from 'src/core/constants/WebCardStrings';
import { FormUtils } from 'src/core/utils/FormUtils';

export const WebRegex = {
  DenyOnlyLettersNoAccentsNoSpace: /^\s+|\s(?=\s)|[^a-zA-Z\s]+/g
};

const requiredValidation = {
  name: FormValidations.Required,
  validator: Validators.required
};

const minLengthValidation = (minLength: number) => ({
  name: FormValidations.MinLength,
  validator: Validators.minLength(minLength),
  message: Strings.MINIMUM_CHARACTERS(minLength)
});

export const WebCardFormConfig:InputConfig[] = [
  {
    type: InputTypes.Text,
    label: WebCardStrings.WebCard.WEB_FORM.FIRST_NAME.label,
    name: WebCardStrings.WebCard.WEB_FORM.FIRST_NAME.name,
    placeholder: WebCardStrings.WebCard.WEB_FORM.FIRST_NAME.label,
    validations: [requiredValidation],
    maxlength: 54,
    input: FormUtils.onlyTextInput
  },
  {
    type: InputTypes.Text,
    label: WebCardStrings.WebCard.WEB_FORM.SECOND_NAME.label,
    name: WebCardStrings.WebCard.WEB_FORM.SECOND_NAME.name,
    placeholder: WebCardStrings.WebCard.WEB_FORM.SECOND_NAME.label,
    maxlength: 54,
    input: FormUtils.onlyTextInput
  },
  {
    type: InputTypes.Text,
    label: WebCardStrings.WebCard.WEB_FORM.LAST_NAME.label,
    name: WebCardStrings.WebCard.WEB_FORM.LAST_NAME.name,
    placeholder: WebCardStrings.WebCard.WEB_FORM.LAST_NAME.label,
    validations: [requiredValidation],
    maxlength: 54,
    input:FormUtils.onlyTextInput
  },
  {
    type: InputTypes.Text,
    label: WebCardStrings.WebCard.WEB_FORM.SECOND_LAST_NAME.label,
    name: WebCardStrings.WebCard.WEB_FORM.SECOND_LAST_NAME.name,
    placeholder: WebCardStrings.WebCard.WEB_FORM.SECOND_LAST_NAME.label,
    validations: [requiredValidation],
    maxlength: 54,
    input: FormUtils.onlyTextInput
  },
  {
    type: InputTypes.Select,
    label: WebCardStrings.WebCard.WEB_FORM.GENDER.label,
    name: WebCardStrings.WebCard.WEB_FORM.GENDER.name,
    options: [
      { value: WebCardStrings.WebCard.WEB_FORM.GENDER.options.male, label: WebCardStrings.WebCard.WEB_FORM.GENDER.options.male },
      { value: WebCardStrings.WebCard.WEB_FORM.GENDER.options.female, label: WebCardStrings.WebCard.WEB_FORM.GENDER.options.female }
    ],
    fnGeneric: () => {},
    validations: [requiredValidation]
  },
  {
    type: InputTypes.Text,
    label: WebCardStrings.WebCard.WEB_FORM.SIGNATURE_PANEL.label,
    name: WebCardStrings.WebCard.WEB_FORM.SIGNATURE_PANEL.name,
    placeholder: WebCardStrings.WebCard.WEB_FORM.SIGNATURE_PANEL.placeholder,
    image: {
      src: Resources.QUESTION_MARK_LIGHT_BLUE,
      alt: Char.QuestionMark,
      clickFunction: () => {}
    },
    imageError: {
      src: Resources.QUESTION_MARK_RED,
      alt: Char.QuestionMark,
      clickFunction: () => {}
    },
    validations: [
      requiredValidation,
      minLengthValidation(Numbers.Nine)
    ],
    event_onkeypress: 'isNumericInput',
    maxlength: Numbers.Nine,
    style: '-webkit-text-security: disc;',
    inputMode: WebCardStrings.WebCard.WEB_FORM.SIGNATURE_PANEL.inputMode
  }
];

export const WEB_CARD_MOVEMENTS = [
  {
    'fecha':'01/01/2023 00:00:00',
    'concepto':'Traspaso de tu cuenta (WEB) - 50031026018 A CUENTA (INBURCAN) - 9862 -',
    'cuentaOrigen':'',
    'cuentaDestino':'',
    'moneda':'',
    'monto':'-5.00',
    'referencia':'',
    'referenciaNumerica':'1111111111',
    'tipoOperacion':'',
    'saldo':'4225',
    'codigoTransaccion':''
  },
  {
    'fecha':'02/01/2023 00:00:00',
    'concepto':'Traspaso de tu cuenta (WEB) - 50031026018 A CUENTA (INBURCAN) - 9863 -',
    'cuentaOrigen':'',
    'cuentaDestino':'',
    'moneda':'',
    'monto':'-5.00',
    'referencia':'',
    'referenciaNumerica':'1111111111',
    'tipoOperacion':'',
    'saldo':'4225',
    'codigoTransaccion':''
  },
  {
    'fecha':'03/01/2023 00:00:00',
    'concepto':'Traspaso de tu cuenta (WEB) - 50031026018 A CUENTA (INBURCAN) - 9864 -',
    'cuentaOrigen':'',
    'cuentaDestino':'',
    'moneda':'',
    'monto':'-5.00',
    'referencia':'',
    'referenciaNumerica':'1111111111',
    'tipoOperacion':'',
    'saldo':'4225',
    'codigoTransaccion':''
  },
  {
    'fecha':'04/01/2023 00:00:00',
    'concepto':'Traspaso de tu cuenta (WEB) - 50031026018 A CUENTA (INBURCAN) - 9865 -',
    'cuentaOrigen':'',
    'cuentaDestino':'',
    'moneda':'',
    'monto':'-5.00',
    'referencia':'',
    'referenciaNumerica':'1111111111',
    'tipoOperacion':'',
    'saldo':'4225',
    'codigoTransaccion':''
  },
  {
    'fecha':'05/01/2023 00:00:00',
    'concepto':'Traspaso de tu cuenta (WEB) - 50031026018 A CUENTA (INBURCAN) - 9866 -',
    'cuentaOrigen':'',
    'cuentaDestino':'',
    'moneda':'',
    'monto':'-5.00',
    'referencia':'',
    'referenciaNumerica':'1111111111',
    'tipoOperacion':'',
    'saldo':'4225',
    'codigoTransaccion':''
  },
  {
    'fecha':'06/01/2023 00:00:00',
    'concepto':'Traspaso de tu cuenta (WEB) - 50031026018 A CUENTA (INBURCAN) - 9867 -',
    'cuentaOrigen':'',
    'cuentaDestino':'',
    'moneda':'',
    'monto':'-5.00',
    'referencia':'',
    'referenciaNumerica':'1111111111',
    'tipoOperacion':'',
    'saldo':'4225',
    'codigoTransaccion':''
  },
  {
    'fecha':'07/01/2023 00:00:00',
    'concepto':'Traspaso de tu cuenta (WEB) - 50031026018 A CUENTA (INBURCAN) - 9868 -',
    'cuentaOrigen':'',
    'cuentaDestino':'',
    'moneda':'',
    'monto':'-5.00',
    'referencia':'',
    'referenciaNumerica':'1111111111',
    'tipoOperacion':'',
    'saldo':'4225',
    'codigoTransaccion':''
  },
  {
    'fecha':'08/01/2023 00:00:00',
    'concepto':'Traspaso de tu cuenta (WEB) - 50031026018 A CUENTA (INBURCAN) - 9869 -',
    'cuentaOrigen':'',
    'cuentaDestino':'',
    'moneda':'',
    'monto':'-5.00',
    'referencia':'',
    'referenciaNumerica':'1111111111',
    'tipoOperacion':'',
    'saldo':'4225',
    'codigoTransaccion':''
  },
  {
    'fecha':'09/01/2023 00:00:00',
    'concepto':'Traspaso de tu cuenta (WEB) - 50031026018 A CUENTA (INBURCAN) - 9870 -',
    'cuentaOrigen':'',
    'cuentaDestino':'',
    'moneda':'',
    'monto':'-5.00',
    'referencia':'',
    'referenciaNumerica':'1111111111',
    'tipoOperacion':'',
    'saldo':'4225',
    'codigoTransaccion':''
  },
  {
    'fecha':'10/01/2023 00:00:00',
    'concepto':'Traspaso de tu cuenta (WEB) - 50031026018 A CUENTA (INBURCAN) - 9871 -',
    'cuentaOrigen':'',
    'cuentaDestino':'',
    'moneda':'',
    'monto':'-5.00',
    'referencia':'',
    'referenciaNumerica':'1111111111',
    'tipoOperacion':'',
    'saldo':'4225',
    'codigoTransaccion':''
  },
  {
    'fecha':'11/11/2023 00:00:00',
    'concepto':'Traspaso de tu cuenta (WEB) - 50031026018 A CUENTA (INBURCAN) - 9872 -',
    'cuentaOrigen':'',
    'cuentaDestino':'',
    'moneda':'',
    'monto':'-5.00',
    'referencia':'',
    'referenciaNumerica':'1111111111',
    'tipoOperacion':'',
    'saldo':'4225',
    'codigoTransaccion':''
  },
  {
    'fecha':'12/12/2023 00:00:00',
    'concepto':'Traspaso de tu cuenta (WEB) - 50031026018 A CUENTA (INBURCAN) - 9873 -',
    'cuentaOrigen':'',
    'cuentaDestino':'',
    'moneda':'',
    'monto':'-5.00',
    'referencia':'',
    'referenciaNumerica':'1111111111',
    'tipoOperacion':'',
    'saldo':'4225',
    'codigoTransaccion':''
  },
];
