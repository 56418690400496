import { PathModel } from 'src/app/models/PathModel';

interface IConsultSatPaymentsRequest {
    IdSesion: string;
    IdCuentaProducto: number;
    NumeroCuenta: string;

}

export class ConsultSatPaymentsRequest {
    constructor (public data: IConsultSatPaymentsRequest) {
    }
    get endpoint(): PathModel {
        return PathModel.ConsultSatPayments;
    }
}
