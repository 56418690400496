import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

enum Breakpoints {
  'Small' = '(max-width: 767.98px)',
  'Medium' = '(min-width: 768px) and (max-width: 1023.98px)',
  'Large' = '(min-width: 1024px)',
}

export interface BreakpointState {
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  private readonly observer$ = this.breakpointObserver.observe([
    Breakpoints.Small,
    Breakpoints.Medium,
    Breakpoints.Large,
  ]);

  constructor(private readonly breakpointObserver: BreakpointObserver) {}

  get observe() {
    return this.observer$.pipe(
      map(result => result.breakpoints),
      map(
        breakpoints =>
          ({
            mobile: breakpoints[Breakpoints.Small],
            tablet:
              breakpoints[Breakpoints.Medium],
            desktop:
              breakpoints[Breakpoints.Large]
          } as BreakpointState)
      )
    );
  }

  customObserve(breakpoints: string){
    return this.breakpointObserver.observe(breakpoints);
  }
}
