import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Strings } from 'src/core/constants/Strings';
import { PaginationService } from 'src/app/services/pagination.service';
import { TDebitCardsMovements } from 'src/app/interface/DebitCardMovementsResponse';
import { DatePipes } from 'src/app/pipes/date.pipe';
import { Constants, Position, Regex, Sorts } from 'src/core/constants/Constants';
import { Numbers } from 'src/core/constants/Numbers';
import { CreditCardsStatusService } from 'src/app/services/credit-cards-status.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MovementsConstants } from 'src/core/constants/MovementsConstants';
import { Utils } from 'src/core/utils/utils';
import { DateUtils } from 'src/core/utils/dateUtils';

@Injectable({
  providedIn: 'root'
})
export class MovementsCardService {

  showMessageWithoutResultsSubject = new Subject<boolean>();
  showMessageWithoutResults$ = this.showMessageWithoutResultsSubject.asObservable();
  currentSearchFilter: string = Strings.EMPTY;
  transactionFilter: string = Strings.EMPTY;
  orderFilter: string = Strings.EMPTY;
  hasMovementsResults: boolean;

  constructor(){
  }

  receiveDataFromSearch(searchFilter: string, paginationService: PaginationService, creditCardsService: CreditCardsStatusService, hasMovementsResults:boolean){
    this.hasMovementsResults = hasMovementsResults;
    this.currentSearchFilter = searchFilter;
    paginationService.search<TDebitCardsMovements[]>(this.filterMovements.bind(this));
  }

  receiveTypeTransactionSearch(transaction: string, paginationService: PaginationService, changeDetectorRef: ChangeDetectorRef, hasMovementsResults:boolean ) {
    this.hasMovementsResults = hasMovementsResults;
    this.transactionFilter = transaction;
    changeDetectorRef.detectChanges();
    paginationService.search<TDebitCardsMovements[]>(this.filterMovementsTransaction.bind(this));
  }

  receiveOrderTransactionSearch(order: string, paginationService: PaginationService, changeDetectorRef: ChangeDetectorRef, hasMovementsResults:boolean ) {
    this.hasMovementsResults = hasMovementsResults;
    this.orderFilter = order;
    changeDetectorRef.detectChanges();
    paginationService.search<TDebitCardsMovements[]>(this.filterMovements.bind(this));
  }

  private filterMovements(data: TDebitCardsMovements[]){
    const filterData = this.filterByTransaction(data);
    const filteredMovements = filterData.filter(searchData => (searchData.concepto?.toLowerCase().includes(this.currentSearchFilter.trim() ?? Strings.EMPTY) ||
    searchData.monto?.toString().includes(this.currentSearchFilter ?? Strings.EMPTY) ||
    new DatePipes().transform(searchData.Date)?.toString().toLowerCase().includes(this.currentSearchFilter ?? Strings.EMPTY)));
    const filteredOrderedMovements = this.orderMovements(filteredMovements);
    this.showMessageWithoutResultsSubject.next(filteredOrderedMovements.length === Numbers.Zero && this.hasMovementsResults);
    return filteredOrderedMovements;
  }

  private filterMovementsTransaction(data: TDebitCardsMovements[]){
    const filterData = this.filterByTransaction(data);
    const filteredMovements = filterData.filter(searchData => (searchData.concepto?.toLowerCase().includes(this.currentSearchFilter.trim() ?? Strings.EMPTY) ||
    searchData.monto?.toString().includes(this.currentSearchFilter ?? Strings.EMPTY) ||
    new DatePipes().transform(searchData.Date)?.toString().toLowerCase().includes(this.currentSearchFilter ?? Strings.EMPTY)));
    this.showMessageWithoutResultsSubject.next(filteredMovements.length === Numbers.Zero && this.hasMovementsResults);
    return this.orderMovements(filteredMovements);
  }

  private orderMovements(data: TDebitCardsMovements[]){
    if(this.orderFilter === Sorts.Ascending || this.orderFilter === Sorts.Descending){
      return this.orderByAmount(data);
    }else if(this.orderFilter === MovementsConstants.FILTER_ORDER[Position.Two].value ||
      this.orderFilter === MovementsConstants.FILTER_ORDER[Position.Three].value){
      return this.orderByDate(data);
    }
    return data;
  }

  private orderByAmount(data: TDebitCardsMovements[]){
    const sortFunction = (a, b) => {
      const amountA = parseFloat(a.monto);
      const amountB = parseFloat(b.monto);
      if(this.orderFilter === Sorts.Ascending){
        return amountA - amountB;
      }else if(this.orderFilter === Sorts.Descending){
        return amountB - amountA;
      }
      return 0;
    };
    return data.sort(sortFunction);
  }

  private orderByDate(data: TDebitCardsMovements[]){
    if(this.orderFilter === MovementsConstants.FILTER_ORDER[Position.Two].value){
      return data.sort((firstElement, secondElement) => Utils.orderByDate(
        DateUtils.resetCompleteDate(firstElement.originalDate), DateUtils.resetCompleteDate(secondElement.originalDate), false));
    }else if(this.orderFilter === MovementsConstants.FILTER_ORDER[Position.Three].value){
      return data.sort((firstElement, secondElement) => Utils.orderByDate(
        DateUtils.resetCompleteDate(firstElement.originalDate), DateUtils.resetCompleteDate(secondElement.originalDate)));
    }
    return data;
  }

  filterByTransaction(data: TDebitCardsMovements[]){
    this.currentSearchFilter = this.currentSearchFilter.replace(Regex.LeadingSpaces, Strings.EMPTY);
    if(this.transactionFilter ===  Constants.PAYMENT) {
      return data.filter(searchData => parseFloat(searchData.monto) > Numbers.Zero);
    }
    else if(this.transactionFilter ===  Constants.CHARGE) {
      return data.filter(searchData => parseFloat(searchData.monto) < Numbers.Zero);
    }
    return data;
  }

  private readonly signalCleanSubject = new BehaviorSubject<void>(undefined);

  get signalCleanInput(): Observable<void> { return this.signalCleanSubject.asObservable(); }

  emitSignalToClean() { this.signalCleanSubject.next(); }
}
