import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { AforeStrings } from 'src/core/constants/AforeStrings';

@Component({
  selector: 'app-modal-voluntary-savings-type',
  templateUrl: './modal-voluntary-savings-type.component.html',
  styleUrls: ['./modal-voluntary-savings-type.component.css']
})
export class ModalVoluntarySavingsTypeComponent implements OnInit {

  modalInstance: NgbModalRef;
  tableData: string[][] = AforeStrings.VOLUNTARY_SAVINGS_CONTRIBUTIONS.InformationTable;

  vm$ = this.responsiveService.observe.pipe(
    map(breakpoints => ({ breakpoints }))
  );

  constructor(private readonly responsiveService: ResponsiveService) { }

  ngOnInit(): void {
  }

  close(): void {
    this.modalInstance.close();
  }

}
