import { Component, Input } from '@angular/core';
import { ButtonProperties } from 'src/app/interface/IButtonProperties';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Strings } from 'src/core/constants/Strings';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WebCardStrings } from 'src/core/constants/WebCardStrings';
import { DomiciliationStrings } from 'src/core/constants/DomiciliationStrings';

@Component({
  selector: 'app-modal-terms-and-conditions',
  templateUrl: './modal-terms-and-conditions.component.html',
  styleUrls: ['./modal-terms-and-conditions.component.css']
})
export class ModalTermsAndConditionsComponent {
  @Input() service: string = Strings.EMPTY;
  vm$ = this.responsiveService.observe;

  termsAndConditions = WebCardStrings.WEBTERMSANDCONDITIONS;
  WebCardStrings = WebCardStrings;
  Strings = Strings;
  modalInstance: NgbModalRef;
  termsAndConditionsCfe = DomiciliationStrings.DOMICILIATION_REGISTER.ConditionsCfe;
  termsAndConditionsTelephone = DomiciliationStrings.DOMICILIATION_REGISTER.ConditionsTelephone;
  termsConditions = DomiciliationStrings.DOMICILIATION_REGISTER.TermsConditions;
  termsConditionsTelcel = DomiciliationStrings.DOMICILIATION_REGISTER.TermsConditionsTelcel;
  findOutAboutCheckbox: boolean = false;

  constructor(
    private readonly responsiveService: ResponsiveService) { }

  closeButtonProperties: ButtonProperties = {
    classes: WebCardStrings.WebCard.MODAL_TERMS_AND_CONDITION.Close_Btn_Properties,
    doAction: () => {
      this.modalInstance.close();
    }
  };

  btnProperties: ButtonProperties = {
    styles: {},
    classes: WebCardStrings.WebCard.MODAL_TERMS_AND_CONDITION.Btn_Properties,
    doAction: () => {
      this.modalInstance.close();
    }
  };

}
