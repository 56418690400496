import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Constants } from 'src/core/constants/Constants';

interface SentinelClient {
  ip: string;
}

@Injectable({
  providedIn: 'root',
})
export class SentinelService {
  private readonly _sentinel: BehaviorSubject<SentinelClient> =
    new BehaviorSubject<SentinelClient>({ ip: Constants.DEFAULT_IP });

  constructor() {}

  get sentinel$() {
    return this._sentinel.asObservable();
  }

  get sentinel() {
    return this._sentinel.getValue();
  }

  set sentinel(data: SentinelClient) {
    window.Sentinel = data.ip;
    this._sentinel.next(data);
  }
}
