import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RetirementType } from 'src/core/constants/AforeConstants';
@Injectable({
  providedIn: 'root'
})
export class NotifyRetirementTypeService {

  private readonly _retirementType:  BehaviorSubject<RetirementType> = new BehaviorSubject<RetirementType>(null);
  private isAfore: boolean = false;
  private retirementPartialAfore: boolean = false;
  private alertAforeError: boolean = false;

  constructor() { }

  set retirementTypeData(value: RetirementType) {
    this._retirementType.next(value);
  }

  get retirementTypeObservable(): Observable<RetirementType> {
    return this._retirementType.asObservable();
  }

  get retirementTypeBs(): BehaviorSubject<RetirementType> {
    return this._retirementType;
  }

  set setIsAfore(data: boolean) {
    this.isAfore = data;
  }

  get getIsAfore() {
    return this.isAfore;
  }

  set setPartialAfore(data: boolean) {
    this.retirementPartialAfore = data;
  }

  get getPartialAfore() {
    return this.retirementPartialAfore;
  }

  setAlertAforeError(data: boolean) {
    this.alertAforeError = data;
  }

  getAlertAforeError() {
    return this.alertAforeError;
  }

}
