import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Observable, SubscriptionLike } from 'rxjs';
import { ISearch } from 'src/app/interface/ISearch';
import { InvestmentCompaniesInfoStatusService } from 'src/app/services/investment-companies-info-status.service';
import { SearchMovementsService } from 'src/app/services/search-movements.service';
import { Constants, Numbers, Regex } from 'src/core/constants/Constants';
import { FormRegex } from 'src/core/constants/FormConstants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';

@Component({
  selector: 'app-search-component',
  templateUrl: './search-component.component.html',
  styleUrls: ['./search-component.component.css']
})
export class SearchComponentComponent implements AfterViewInit, OnDestroy {
  searchTransactions$: Observable<ISearch>;
  @Output() dataFromSearch = new EventEmitter();
  @Output() searchAction = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Input() idSearch: string | number ='searchI';
  idSearchStyle: string = Strings.EMPTY;
  @Input() searchCustom: boolean;
  @Input() placeholder: string = Constants.SEARCH_LABEL;
  @Input() referenceLength: number = Constants.REFERENCE_LENGTH;
  @Input() onlyNumber: boolean = false;
  @Input() changueWidth: boolean = false;
  @Input() searchCard: boolean = false;
  @Input() classSearch: string = 'input-group';
  @Input() classCheckbookInput: string = Strings.EMPTY;
  @Input() styleSearch: string = Strings.EMPTY;
  @Output() showSearchComponent = new EventEmitter();
  @Input() valueFilter: string;
  @Input() regex: RegExp = Regex.DenyNoAccents;
  @Input() realValue: boolean = false;
  @Input() customColor: string = Strings.EMPTY;
  @Input() removePadding: boolean = false;
  @Input() resetInputId: string = Strings.EMPTY;
  @Input() tabIndex: string = Numbers.Zero.toString();
  regexNumber: RegExp = Regex.OneMoreNumbers;
  search: string = Resources.SEARCH;
  formatInsurance: boolean = false;
  regexOneMoreNumbers:RegExp = Regex.OneMoreNumbers;
  investmentAccountSL: SubscriptionLike;
  regexTextTarget = FormRegex.SearchValidText;
  @Input() customInputFn: (value: string, event: KeyboardEvent) => void;
  @Input() isClick: boolean = true;

  @ViewChild('SearchInput', { static: false }) searchInput: ElementRef<HTMLInputElement>;

  constructor(private readonly searchTransactionService: SearchMovementsService,
    private readonly investmentCompaniesInfoStatusService: InvestmentCompaniesInfoStatusService) {
    this.searchTransactions$ = searchTransactionService.filteredMovementObservable;
  }

  ngOnDestroy(): void {
    this.investmentAccountSL?.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.idSearch === Strings.EMPTY) {
      this.idSearch = Constants.WIDTH_SEARCH_ID;
    } else {
      if (this.idSearch === Constants.DEFAULT_SEARCH_ID) {
        this.formatInsurance = true;
      }
    }
    if(this.investmentCompaniesInfoStatusService.status.value){
      this.investmentAccountSL= this.investmentCompaniesInfoStatusService.investmentCompaniesInfo$.subscribe(async account => {
        this.showSearch();
      });
    }
  }

  showSearch() {
    this.searchInput.nativeElement.value = Strings.EMPTY;
    this.showSearchComponent.emit(false);
    this.dataFromSearch.emit(Strings.EMPTY);
  }

  cleanValue(value: string, event: KeyboardEvent) {
    if(this.customInputFn) {
      this.customInputFn(value, event);
    }
  }

  searchFn(dataSearch: string): void {
    dataSearch = dataSearch.replace(this.regex, Strings.EMPTY);
    this.valueFilter = dataSearch;
    dataSearch = dataSearch.toLowerCase();
    if(this.realValue) {
      this.dataFromSearch.emit(this.valueFilter);
    } else {
      this.dataFromSearch.emit(dataSearch);
    }
    this.searchTransactionService.filteredMovementData = { concept: dataSearch, date: null, amount: null };
  }

  searchActionFn(): void {
    this.getFocus();
    this.searchAction.emit(this.valueFilter);
  }

  getInputClasses(searchInput:HTMLInputElement) {
    return {
      'form-control': true,
      [this.classCheckbookInput]: !!this.classCheckbookInput,
      'search-with-cross-width': searchInput.value.length !== Numbers.Zero && this.changueWidth,
    };
  }

  getFocus(){
    if(this.isClick){
      this.searchInput.nativeElement.focus();
    }
  }
}
