<ng-container *ngIf="vm$ | async as vm" ngbAutoFocus>
  <div class="padding-container">
    <header class="d-flex flex-column justify-content-center align-items-center gap-2 pt-3">
      <img [src]="checkFill | cdnImage" alt="image" width="56" height="56" />
      <div class="header-l text-center text-secondary-800">{{completeProcess}}</div>
      <div class="body-m text-secondary-700">Folio: {{vm.certificate.numeroResolucion}}</div>
      <div class="detail-m">{{completeDate}}h</div>
      <div class="d-flex flex-column justify-content-center align-items-center amount-container pb-2"
      *ngIf="retirement">
        <show-amount label="{{amountTotal}}" color="var(--primary-1000)" *ngIf="vm.breakpoints.mobile"></show-amount>
      </div>
    </header>
    <main class="d-flex justify-content-between pt-3" [ngClass]="vm.breakpoints.mobile ? 'flex-column':'gap-4'">
        <section class="w-100">
          <div *ngIf="retirement && !vm.breakpoints.mobile">
            <show-amount label={{amountTotal}} color="var(--primary-1000)"></show-amount>
          </div>
          <div class="pt-1" [class.pt-3]="retirement">
            <label class="header-xs pb-3">{{toAccount}}</label>
            <div class="d-flex flex-row gap-3 align-items-center">
              <div>
                <img [src]="imgCard.match('\/') ? imgCard : (imgCard | cdnImage)" alt="image"
                width="auto" height="auto" class="card-img-afore">
              </div>
              <div class="d-flex flex-column">
                <span class="body-s default-color">{{card | maskAccount : 4}}</span>
                <span class="detail-s">{{nameCard}}</span>
              </div>
            </div>
            <hr class="margin-hr">
          </div>
        </section>
        <section class="w-100">
          <div *ngIf="retirement" [class.w-100]="vm.breakpoints.mobile">
            <label class="header-xs main-color-label label-info pb-2 pt-2">{{operationData}}</label>
            <app-info-label
              [head]="retirementPartial" [body]="procedure" bodyClass="detail-s"
              headClass="body-s mb-2 text-secondary-800" [classes]="''" margin="mt-2">
            </app-info-label>
            <app-info-label
              [head]="modality" [body]="salary"
              bodyClass="body-s default-color"
              headClass="header-xs mb-2" [classes]="''" margin="mt-2">
            </app-info-label>
            <label class="header-xs mb-2">{{startDate}}</label>
            <app-info-label
              [head]="formatDateToDay" [body]="startProcess" bodyClass="detail-s"
              headClass="body-s mb-2 default-color" [classes]="''" margin="mt-2">
            </app-info-label>
          </div>
          <div *ngIf="!retirement" [class.w-100]="vm.breakpoints.mobile">
            <label class="header-xs main-color-label label-info pb-2 pt-2">{{infoOperation}}</label>
            <app-info-label
              [head]="processTitle" [body]="'Trámite'" bodyClass="detail-s"
              headClass="body-s mb-2 text-secondary-800" [classes]="''" margin="mt-2">
            </app-info-label>
            <app-info-label
              [head]="dataByOrigin" [body]="origin === imss ? nss : curp" bodyClass="detail-s"
              headClass="body-s mb-2 text-secondary-800" [classes]="''" margin="mt-2">
            </app-info-label>
            <app-info-label
              [head]="origin" [body]="'Origen'" bodyClass="detail-s"
              headClass="body-s mb-2 text-secondary-800" [classes]="''" margin="mt-2">
            </app-info-label>
          </div>
        </section>
    </main>
    <div class="pb-3">
      <label class="detail-m text-center">{{conditions}}</label>
    </div>
    <footer class="pb-3">
      <div class="footer-buttons">
        <div class="btn-payment">
          <app-template-primary-button [properties]="completeProperties">
            {{btnOk}}
          </app-template-primary-button>
        </div>
        <div class="btn-payment" >
          <app-template-secondary-button [properties]="otherOperationProperties">
            {{btnOtherOperation}}
          </app-template-secondary-button>
        </div>
      </div>
    </footer>
  </div>
</ng-container>
