import { Component, OnInit } from '@angular/core';
import { combineLatestWith, map } from 'rxjs';
import { AccountModifyService } from 'src/app/services/info-accounts-edit.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { ServiceConstants } from 'src/core/constants/ServiceConstants';
import { Strings } from 'src/core/constants/Strings';
import { ProductUtils } from 'src/core/utils/ProductUtils';
import { AgendaType } from 'src/core/constants/AddressConstants';
import { AddresseStrings } from 'src/core/constants/AddresseStrings';

@Component({
  selector: 'app-confirm-modify-account',
  templateUrl: './confirm-modify-account.component.html',
  styleUrls: ['./confirm-modify-account.component.css']
})
export class ConfirmModifyAccountComponent implements OnInit {
  nonAgendableType: number = ServiceConstants.NON_AGENDABLE_TYPE;
  formatLimit: number = parseFloat(this.accountModifyService.account.maximumLimit);
  vm$ = this.accountModifyService.account$.pipe(
    combineLatestWith(this.responsiveService.observe),
    map(([accountModify, breakpoints]) => ({
      accountModify,
      breakpoints
    }))
  );

  accountAddress = AgendaType.AccountAddress;
  ownAccounts = AgendaType.OwnAccounts;
  brokerageHouse = AgendaType.BrokerageHouse;
  accountLabel = Strings.ACCOUNT_NUMBER;
  serviceAgendaCode: number = AgendaType.AccountAddress;
  labelCard: string = AddresseStrings.ADDRESSE_TEXT.Confirm.labelCard;

  constructor(private readonly accountModifyService: AccountModifyService, private readonly responsiveService: ResponsiveService) { }

  ngOnInit(): void {
    if(this.accountModifyService.account.type === this.brokerageHouse) this.labelCard = AddresseStrings.ADDRESSE_TEXT.Confirm.labelContract;
    this.accountLabel = ProductUtils.getAccountLabel(this.accountModifyService.account.type, this.serviceAgendaCode,
      this.accountModifyService.account.codeServices, Strings.ACCOUNT_NUMBER);
  }

  isServiceAgenda(codeType: AgendaType) {
    return codeType === AgendaType.AccountAddress || codeType === AgendaType.NonAgendableService;
  }
}
