import { PathModel } from 'src/app/models/PathModel';
import { TToken } from '../TToken';

type IPinModificationRequest = {

    IdSesion : string,
    Token : TToken,
    Tarjeta : {
      Numero : string,
      Nip : string
    },
    tipoAcceso : string,
    nombreAplicacion : string,
    token ?: string,
    traza ?: string,
    Source ?: string,
    IdPersona ?: string

};

export class PinModificationRequest{
  constructor(public data: IPinModificationRequest){}

  get endpoint(): PathModel {
    return PathModel.PinModification;
  }
}
