import { PathModel } from 'src/app/models/PathModel';

type TConsultInsurancePolicyCoverRequest = {
    Emisor: string;
    Carpeta: string;
    Poliza: string;
    Ramo: string;
    IdSession: string;
};

export class ConsultInsurancePolicyCoverRequest {
    constructor(public data: TConsultInsurancePolicyCoverRequest) { }
    get endpoint(): PathModel {
        return PathModel.ConsultInsurancePolicyCover;
    }
}