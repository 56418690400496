import { PathModel } from '../../../app/models/PathModel';

export type IWebCardApplication = {
  numeroCuenta: string,
  primerNombre: string,
  segundoNombre?: string,
  apellidoPaterno?: string,
  apellidoMaterno?: string,
  sexo: string,
  panelFirma: string
};

export class WebCardApplicationRequest{
  constructor(public data: IWebCardApplication){}

  get endpoint(): PathModel{
    return PathModel.WebCardApplication;
  }
}
