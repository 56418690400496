import { Injectable, WritableSignal, signal } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { AlertService } from 'src/app/services/alert.service';
import { LocationService } from 'src/app/services/location.service';
import { LocationConstants } from 'src/core/constants/LocationConstants';
import { Constants, Length, Numbers } from 'src/core/constants/Constants';
import { DataCheckbookService, Directions } from 'src/app/services/data-checkbook.service';
import { Resources } from 'src/core/constants/Resources';
import { ModalService } from 'src/app/shared/modal.service';
import { Utils } from 'src/core/utils/utils';
import { Strings } from 'src/core/constants/Strings';

export type PickerOptions = { id: number, scroll: boolean };

@Injectable({
  providedIn: 'root'
})
export class MapService {

  private readonly _hasResult: WritableSignal<boolean>  = signal<boolean>(false);
  private readonly _withoutResults: WritableSignal<boolean> = signal<boolean>(false);

  private _markerPositions: google.maps.LatLngLiteral[];
  private _markerOptions: google.maps.MarkerOptions;

  private readonly _directions: WritableSignal<Array<Directions>> = signal([]);
  private readonly _directionCopy: WritableSignal<Array<Directions>> = signal([]);

  private readonly _withResults: WritableSignal<boolean> = signal<boolean>(false);
  private readonly _errorMessage: WritableSignal<string> = signal<string>(Strings.EMPTY);

  private readonly _selectedId: WritableSignal<PickerOptions> = signal<PickerOptions>({id: Numbers.Zero, scroll:false});
  private readonly _showMap: WritableSignal<boolean> = signal<boolean>(true);

  constructor(
    private readonly storage: StorageService,
    private readonly alertService: AlertService,
    private readonly locationService: LocationService,
    private readonly modalService: ModalService,
    private readonly dataCheckbookService: DataCheckbookService,
  ){}

  async getDirections() {
    try {
      const geolocation = this.storage.getGeolocation();
      const response = await this.locationService.getRSLocation(LocationConstants.BRANCH_ID, geolocation.latitude, geolocation.longitude);
      this.locationService.center = { lat: geolocation.latitude, lng: geolocation.longitude };
      if (response.locations.length === Numbers.Zero) {
        this.withoutResults = true;
      }else{
        this.markerPositions = response.locations.map((element) => ({
          lat: Number(element.Latitud),
          lng: Number(element.Longitud)
        }));
        this.directions = response.locations.map((element) => ({
          address: element.DireccionCompleta,
          phone: element.Data.find(data => data.Title === LocationConstants.PHONE_TITLE).Value,
          id: element.Data.find(data => data.Title === LocationConstants.BRANCH_NUMBER).Value,
          name: element.Data.find(data => data.Title === LocationConstants.NAME).Value
        }));
        this.markerOptions = {
          icon: {
            url: Resources.CDN_BRANCH(response.icon),
            scaledSize: new google.maps.Size(LocationConstants.ICON_WIDTH, LocationConstants.ICON_HEIGHT)
          }
        };
        this.directionCopy =  [...this.directionsSignal()];
        this.dataCheckbookService.resultsBranches = true;
        this.withResults = true;
      }
      this.hasResult = true;

    } catch (error) {
      this.modalService.close();
      const errorDetails = Utils.getErrorMsg(error);
      this.errorMessage = errorDetails.msg;
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code),
        header: errorDetails.code === Constants.UNEXPECTED_CODE ? Strings.MSG_POPUP_TITLE : Strings.MODAL_BRANCHES.ErrorTitle,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit : false
      }, {
        onSuccess: () => this.modalService.close()
      });
    }
  }

  setLocation(event: number){
    this.locationService.center = this.markerPositions[event];
  }

  filterDirections(filter: string) {
    this.directionCopy = [...this.directionsSignal()];
    if(filter === Strings.EMPTY){
      this.withResults = true;
      return;
    }

    const result = this.directionCopySignal().filter((directionFiltered) => directionFiltered.address.toLowerCase().includes(filter.toLowerCase()));
    if (result.length === Length.Empty) {
      this.withResults = false;
    } else {
      this.withResults = true;
      this.resetSelection();
      this.setLocation(this.directionCopySignal().findIndex(item => item.id === result[Numbers.Zero].id));
    }
    this.directionCopy = result;
  }

  resetSelection() {
    this.selectedId = {id: Numbers.Zero, scroll:false};
  }

  get directionsSignal() {
    return this._directions;
  }

  set directions(value: Array<Directions>) {
    this._directions.update(() => value);
  }

  get directionCopySignal() {
    return this._directionCopy;
  }

  set directionCopy(value: Array<Directions>) {
    this._directionCopy.update(() => value);
  }

  get hasResultSignal() {
    return this._hasResult;
  }

  set hasResult(value: boolean) {
    this._hasResult.update(() => value);
  }

  get withoutResultsSignal() {
    return this._withoutResults;
  }

  set withoutResults(value: boolean) {
    this._withoutResults.update(() => value);
  }

  get markerPositions() {
    return this._markerPositions;
  }

  set markerPositions(value: google.maps.LatLngLiteral[]) {
    this._markerPositions = value;
  }

  get markerOptions() {
    return this._markerOptions;
  }

  set markerOptions(value: google.maps.MarkerOptions) {
    this._markerOptions = value;
  }

  get errorMessageSignal() {
    return this._errorMessage;
  }

  set errorMessage(value: string) {
    this._errorMessage.update(() => value);
  }

  get withResultsSginal() {
    return this._withResults;
  }

  set withResults(value: boolean) {
    this._withResults.update(() => value);
  }

  get selectedIdSignal() {
    return this._selectedId;
  }

  set selectedId(value: PickerOptions) {
    this._selectedId.update(() => value);
  }

  get showMapSignal() {
    return this._showMap;
  }

  set showMap(value: boolean) {
    this._showMap.update(() => value);
  }
}
