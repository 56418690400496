import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalOptions } from 'src/app/interface/modal-options';
import { Position } from 'src/core/constants/Constants';
import { ModalConfig } from 'src/app/interface/IModalWithdrawal';
import { ModalVoluntaryWithdrawalInfoComponent } from 'src/app/component/modal-voluntary-withdrawal-info/modal-voluntary-withdrawal-info.component';
import { AforeStrings } from 'src/core/constants/AforeStrings';
import { Resources } from 'src/core/constants/Resources';
import { ModalConstants } from 'src/core/constants/ModalConstants';

@Injectable({
  providedIn: 'root'
})
export class ModalWithdrawalService {
  private readonly modalConfigSubject = new BehaviorSubject<ModalConfig | null>(null);
  modalConfig$ = this.modalConfigSubject.asObservable();

  constructor(private readonly modalService: ModalService) { }

  readonly INFO_WITHDRAWAL_AFORE = [
    {
      subAccount: AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.SubAccountType[Position.Zero],
      style: AforeStrings.INFO_WITHDRAWAL_AFORE_STYLES.Voluntary,
      htmlCDN: Resources.CDN_URL_VOLUNTARY(Resources.VOLUNTARY_CDN.VOLUNTARY)
    },
    {
      subAccount: AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.SubAccountType[Position.One],
      style: AforeStrings.INFO_WITHDRAWAL_AFORE_STYLES.Complementary,
      htmlCDN: Resources.CDN_URL_VOLUNTARY(Resources.VOLUNTARY_CDN.COMPLEMENTARY)
    },
    {
      subAccount: AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.SubAccountType[Position.Two],
      style: AforeStrings.INFO_WITHDRAWAL_AFORE_STYLES.LongTerm,
      htmlCDN: Resources.CDN_URL_VOLUNTARY(Resources.VOLUNTARY_CDN.LONG_TERM)
    },
    {
      subAccount: AforeStrings.WITHDRAWAL_VOLUNTARY_CONTRIBUTIONS.VoluntaryContributions.SubAccountType[Position.Three],
      style: AforeStrings.INFO_WITHDRAWAL_AFORE_STYLES.Investment,
      htmlCDN: Resources.CDN_URL_VOLUNTARY(Resources.VOLUNTARY_CDN.INVESTMENT)
    }
  ];

  openModal(config: ModalConfig ){
    this.modalConfigSubject.next(config);
    const modalOptions: ModalOptions = {
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalVoluntaryWithdrawalInfo,
      centered: true
    };
    this.modalService.open(ModalVoluntaryWithdrawalInfoComponent, modalOptions);
  }

  get subAccountTypeInfo() {
    return this.INFO_WITHDRAWAL_AFORE;
  }
}
