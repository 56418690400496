import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISelectedPolicies } from '../interface/ISelectedPolicies';
import { FormGroup } from '@angular/forms';
import { Strings } from 'src/core/constants/Strings';

export interface DataPaymentOther {
  name?: string,
  email?: string,
  phone?: string,
  address?: string,
  code?: string
  timeLimit?: string,
  currency?: string,
  exchangeRate?: string,
  nameCard?: string,
  numberCard?: string
}
@Injectable({
  providedIn: 'root'
})

export class InsurancePolicies {
  private readonly closeModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _startPaymentIFM: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly selectedConsecutivesPolicies: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _errorService: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _errorWithoutPolicy: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _isIFMPayment: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _monthSelected: BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);
  private readonly _currencyStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _currencyValue: BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);
  private readonly _currencyMXN: BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);
  private readonly _currencySymbol: BehaviorSubject<string> = new BehaviorSubject<string>(Strings.EMPTY);
  policySelected: ISelectedPolicies = {
    SelectedPolicies: [{
      id: Strings.EMPTY, policy: Strings.EMPTY, receipt: Strings.EMPTY, total: Strings.EMPTY, status: Strings.EMPTY,
      paymentDate: Strings.EMPTY, isSelect: false, currencyCode: Strings.EMPTY,
      typeReceipt: Strings.EMPTY, issuerCode: Strings.EMPTY, ambSeg: Strings.EMPTY, yearNumber: Strings.EMPTY
    }]
  };
  public _policiesSelected: BehaviorSubject<ISelectedPolicies> = new BehaviorSubject<ISelectedPolicies>({
    SelectedPolicies: []
  });
  private readonly _newPaymentInsurance: BehaviorSubject<FormGroup> =
    new BehaviorSubject<FormGroup>(null);

  constructor() { }

  get policiesSelected$() {
    return this._policiesSelected.asObservable();
  }
  get policiesSelected(): ISelectedPolicies {
    return this._policiesSelected.getValue();
  }

  set policiesSelected(data: ISelectedPolicies) {
    this._policiesSelected.next(data);
  }

  clearSelectedPolicies() {
    this.policySelected = { SelectedPolicies: [] };
    this._policiesSelected.next(this.policySelected);
  }
  get valueObservable() {
    return this.closeModal.asObservable();
  }

  set valueData(data: boolean) {
    this.closeModal.next(data);
  }
  get newPaymentInsurance$(): Observable<FormGroup> {
    return this._newPaymentInsurance.asObservable();
  }

  get newPaymentInsurance():FormGroup {
    return this._newPaymentInsurance.getValue();
  }

  set newPaymentInsurance(data: FormGroup) {
    this._newPaymentInsurance.next(data);
  }
  get startPaymentIFM$() {
    return this._startPaymentIFM.asObservable();
  }

  set startPaymentIFM(data: boolean) {
    this._startPaymentIFM.next(data);
  }

  get startPaymentIFM(): boolean{
    return this._startPaymentIFM.getValue();
  }

  get isIFMPayment$() {
    return this._isIFMPayment.asObservable();
  }

  set isIFMPayment(data: boolean) {
    this._isIFMPayment.next(data);
  }

  get isIFMPayment(): boolean{
    return this._isIFMPayment.getValue();
  }

  get currencyStatus$() {
    return this._currencyStatus.asObservable();
  }

  set currencyStatus(data: boolean) {
    this._currencyStatus.next(data);
  }

  get currencyStatus(): boolean{
    return this._currencyStatus.getValue();
  }

  get currencyValue$() {
    return this._currencyValue.asObservable();
  }

  set currencyValue(data: string) {
    this._currencyValue.next(data);
  }

  get currencyValue(): string{
    return this._currencyValue.getValue();
  }

  get currencyMXN$() {
    return this._currencyMXN.asObservable();
  }

  set currencyMXN(data: string) {
    this._currencyMXN.next(data);
  }

  get currencyMXN(): string{
    return this._currencyMXN.getValue();
  }

  get currencySymbol$() {
    return this._currencySymbol.asObservable();
  }

  set currencySymbol(data: string) {
    this._currencySymbol.next(data);
  }

  get currencySymbol(): string{
    return this._currencySymbol.getValue();
  }

  get monthSelected$() {
    return this._monthSelected.asObservable();
  }

  set monthSelected(data: string) {
    this._monthSelected.next(data);
  }

  get monthSelected(): string{
    return this._monthSelected.getValue();
  }

  get valueConsecutives() {
    return this.selectedConsecutivesPolicies.asObservable();
  }

  set valueDataConsecutives(data: boolean) {
    this.selectedConsecutivesPolicies.next(data);
  }
  get errorService$() {
    return this._errorService.asObservable();
  }

  set errorService(data: boolean) {
    this._errorService.next(data);
  }

  get errorWithoutPolicy$() {
    return this._errorWithoutPolicy.asObservable();
  }

  set errorWithoutPolicy(data: boolean) {
    this._errorWithoutPolicy.next(data);
  }
}
