import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppNavigation } from 'src/app/models/AppNavigation';
import { AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { AppBridge } from 'src/core/utils/AppBridge';
import { AppUtils } from 'src/core/utils/AppUtils';

@Injectable({
  providedIn: 'root'
})
export class LoginAppGuard  {

  constructor(private readonly router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (AppUtils.platform.isApp) {
      const navigationParams: AppNavigation = {
        Data: AppScreen.Login
      };
      AppBridge.invoke<string>(AppMethod.SendNativeScreen, navigationParams);
      return false;
    }
    return true;
  }

}
