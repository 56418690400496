import { PathModel } from '../../models/PathModel';

interface IGetPendingWithdrawalsRequest {
  idCuentaProducto: number;
}

export class GetPendingWithdrawalsRequest {
  constructor (public data: IGetPendingWithdrawalsRequest){}

  get endpoint(): PathModel {
    return PathModel.PendingWithdrawalsCodeFlex;
  }
}


