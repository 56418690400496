<ng-container *ngIf="vm$ | async as vm">
  <div *ngIf="errorMsg !== '' && vm.selected.name else operationSuccess">
    <app-error-exception [otherOperation]="servicesHome"
      [showButtons]="true" [errorMessage]="errorMsg"></app-error-exception>
  </div>
  <ng-template #operationSuccess>
    <div class="parent" *ngIf="vm.show && vm.amount.amount" scrollTop>
        <div class="div-3 mt-2">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <img [src]="greenCheckFill | cdnImage" class="icon-check" alt="" width="auto" height="auto">
            <div class="pt-2 successful-title header-l default-color">Pago realizado</div>
            <p class="body-m mb-2 default-color">Folio: {{paymentFolio}}</p>
            <div class=" label-amount" [ngClass]="!vm.breakpoints.mobile ? 'header-xxxl' : 'header-xxl'">
              {{vm.selected.quantity | currency}}
            </div>
            <div class="detail-m pb-2">
              {{completeDate}}h
            </div>
            <app-shared-buttons (downloadFiles)="downloadPdf()" [hideMail]="true">
            </app-shared-buttons>
          </div>

        </div>
        <div class="div-4 left-col" [ngClass]="!vm.breakpoints.mobile ? 'pt-4' : ''">
          <div>

            <div *ngIf="vm.breakpoints.mobile">
              <label class="body-m header-xs mt-4 mb-2 default-color">Desde tu cuenta</label>
              <div class="row">
                <div class="col-3 d-flex align-items-center custom-padding img-card-account">
                  <div *ngIf="!vm.breakpoints.mobile">
                    <img [src]="selectedCard.image" alt="Bank Card" width="auto" height="auto">
                  </div>
                </div>
                <div [ngClass]="vm.breakpoints.mobile ? 'col-12' : 'col-9 ps-4'">
                  <label class="body-s">
                    {{ vm.account.accountNumber | maskAccount: 4 }}
                  </label>
                  <label class="detail-s col-12 detail-s-color">{{selectedCard.cardType}}</label>
                </div>
              </div>
              <hr class="my-2">
            </div>

            <label class="body-m header-xs mb-2 col-12">Servicio a pagar</label>
            <div>
              <div class="col-12 p-0">
                <label class="body-s default-color"> Pago SUA </label>
                <label class="body-s default-color">{{vm.selected.rfc}}</label>
                <label class="detail-s col-12"> RFC </label>
              </div>
            </div>
            <hr class="my-2">
            <div>
              <div class="col-12 p-0">
                <label class="body-s default-color">{{vm.selected.registry}}</label>
                <label class="detail-s col-12"> Registro Patronal </label>
              </div>
            </div>
            <hr class="my-2">
          </div>

          <div *ngIf="!vm.breakpoints.mobile">
            <label class="body-m header-xs mt-2 mb-2">Desde tu cuenta</label>
            <div class="row">
              <div class="col-2 d-flex align-items-center custom-padding">
                <div *ngIf="!vm.breakpoints.mobile">
                  <img [src]="selectedCard.image" alt="Bank Card" width="48px" height="auto">
                </div>
              </div>
              <div [ngClass]="vm.breakpoints.mobile ? 'col-12 ps-3' : 'col-9'">
                <label class="body-s default-color">
                  {{ vm.account.accountNumber | maskAccount: 4 }}
                </label>
                <label class="detail-s col-12 detail-s-color default-color">{{selectedCard.cardType}}</label>
              </div>
            </div>
            <hr [ngClass]="vm.breakpoints.mobile ? '' : 'my-2'">
          </div>

        </div>

        <div class="div-5 column-right" [ngClass]="vm.breakpoints.mobile ? '' : 'pt-4'">
          <div>
            <label class="body-m header-xs col-12" [ngClass]="vm.breakpoints.mobile ? 'mb-2 mt-2' : 'margin-info'">
              Información de pago
            </label>
            <div>
              <div class="header-s default-color">Folio SUA: {{vm.selected.folio}}</div>
              <div class="body-s default-color">Archivo de Pago: {{vm.selected.name}}</div>
              <div class="header-s default-color">{{vm.selected.quantity | currency}}</div>
              <div class="detail-s default-color">Periodo de Pago: {{vm.selected.period}}</div>
            </div>
            <hr class="my-2">
          </div>

          <div>
            <app-fee-info [timeToApply]="vm.service.applyTime" [fee]="vm.service.commission"></app-fee-info>
          </div>

        </div>

        <div class="div-6 " [ngClass]=" vm.breakpoints.mobile ? 'mt-sua-btn margin-mobile-btn' : 'mt-5'">
          <div class="row d-flex justify-content-center align-content-center mb-3">
            <button class="confirm-sua-btn btn-generic" (click)="next()"
              [ngClass]="vm.breakpoints.mobile ? 'button-s' : 'button-m space-btn'"> Listo</button>
          </div>
          <div class="row d-flex justify-content-center align-content-center">
            <button class="modify-sua-btn btn-generic" (click)="back()"
              [ngClass]="vm.breakpoints.mobile ? 'button-s' : 'button-m'">
              Otra operación
            </button>
          </div>
        </div>
    </div>
  </ng-template>
</ng-container>
