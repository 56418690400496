import { PathModel } from 'src/app/models/PathModel';

interface IGetCardsDirectDebitRequest {
  numeroCuenta: string;
}

export class GetCardsDirectDebitRequest {
  constructor(public data: IGetCardsDirectDebitRequest) { }

  get endpoint(): PathModel {
    return PathModel.GetCardsDirectDebitService;
  }
}
