import { Injectable } from '@angular/core';
import { Constants } from 'src/core/constants/Constants';
import { SendMessagePLRequest } from '../interface/dto/SendMessagePLRequest';
import { SendMessageRequest } from '../interface/dto/SendMessageRequest';
import { StorageService } from './storage.service';
import { EmailModel } from '../models/EmailModel';
import { Strings } from 'src/core/constants/Strings';
import { IBaseService } from './i-base-service.service';
import { SendMessagePLResponse } from '../interface/dto/SendMessagePLResponse';
import { GenericResponse } from '../interface/dto/GenericResponse';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private readonly storage: StorageService,
    private readonly baseService: IBaseService
  ) { }

  async sendEmail(email:EmailModel) {
    if (Constants.EMAIL_MS_ENABLED) {
        const request = new SendMessagePLRequest({
            idSesion:  this.storage.getSession(),
            numeroCuenta: email.accountNumber,
            comentarios: email.comments,
            copiaRemitente: email.copyToMe,
            idOperacion: email.referenceNumber,
            receptor: email.email,
            copia: email.emailCopy !== Strings.EMPTY ? email.emailCopy : null
        });
        await this.baseService.genericPost<GenericResponse<void>>(request,{isSecure: true,addSession: true});
    } else {
        const request = new SendMessageRequest({
            Recipients: email.email,
            PersonId: this.storage.getUser().personId,
            Comments: email.comments,
            OperationId: email.referenceNumber,
            CopyToMe: email.copyToMe,
            WithCopy: email.emailCopy,
            AccountNumber: email.accountNumber
        });
        await this.baseService.genericPost<SendMessagePLResponse>(request, { isSOA: true });
    }
  }
 
}
