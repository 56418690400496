import { Component, Input, OnInit } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ClassHTML, Numbers } from 'src/core/constants/Constants';

@Component({
  selector: 'text-ellipsis',
  templateUrl: './text-ellipsis.component.html',
  styleUrls: ['./text-ellipsis.component.css']
})
export class TextEllipsisComponent implements OnInit {
  @Input() styles: Partial<CSSStyleDeclaration> = {
    display: ClassHTML.WebkitBox,
    webkitBoxOrient: ClassHTML.BoxOrientVertical
  };
  @Input() classes: string;
  @Input() text?: string | number;
  @Input() lines: number = Numbers.One;

  constructor() { }

  ngOnInit(): void {
    this.styles = {
      webkitLineClamp: this.lines.toString(),
      ...this.styles
    };
  }
  toggleTooltip(tooltip: NgbTooltip) {
    if (tooltip.isOpen()) {
      tooltip.close();
    } else {
      tooltip.open();
    }
  }

}
