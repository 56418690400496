import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Check, DataCheckBook } from 'src/app/interface/DataCheckBook';
import { Strings } from 'src/core/constants/Strings';
import { CheckbookRequest } from 'src/app/interface/dto/CheckbookRequest';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { Numbers } from 'src/core/constants/Numbers';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { checkbookData } from 'src/app/interface/dto/CheckbookResponse';

export interface CheckbookType {
  id: number,
  type: string
}
export interface CheckBookRequest {
  checkbookType: [],
  accountNumber: string,
  accountName: string,
  typeCheckBook: string,
  numberCheckBook: string,
  state: string,
  active: boolean
}
export interface Directions {
  address: string;
  phone: string;
  id?: string;
  name?: string;
}
@Injectable({
  providedIn: 'root'
})
export class DataCheckbookService {
  private readonly _checkBookArray: BehaviorSubject<DataCheckBook[]> = new BehaviorSubject<DataCheckBook[]>([]);
  private readonly _reloadCheckBooks: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _resultsBranches: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _showTableCheckBook: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _checkbookRequestProcess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _activationCheckProcess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private selectedCheckbookNumber: string = Strings.EMPTY;
  private readonly rangeCheckBook: number = Numbers.Fifty;
  private nextId = Numbers.One;
  private lastCheckNumber: number = Numbers.Zero;
  private _checkbookInquiry: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _branchData$ = new BehaviorSubject<DataCheckBook>({
    id: Numbers.Zero,
    accountNumber: Strings.EMPTY,
    accountName: Strings.EMPTY,
    typeCheckBook: Strings.EMPTY,
    numberCheckBook: Strings.EMPTY,
    state: Strings.EMPTY,
    active: false,
    checkNumber: Strings.EMPTY,
    protected: Strings.EMPTY
  });
  private readonly _directionsData = new BehaviorSubject<Directions>({
    address: Strings.EMPTY,
    phone: Strings.EMPTY,
    id: Strings.EMPTY,
    name: Strings.EMPTY
  });

  private readonly _dataCheckBook$ = new BehaviorSubject<Check>({
    active: false,
    checkNumber: Strings.EMPTY,
    state: Strings.EMPTY,
    protected: Strings.EMPTY
  });

  private readonly _statusConfirm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _statusCheckbookOTP: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _statusDataC: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private readonly baseService: IBaseService) { }

  get checkBookArray$() {
    return this._checkBookArray.asObservable();
  }

  get checkBookArray(): DataCheckBook[] {
    return this._checkBookArray.getValue();
  }

  set checkBookArray(data: DataCheckBook[]) {
    this._checkBookArray.next(data);
  }

  addCheckbook(checkBook: DataCheckBook): void {
    if (this.lastCheckNumber === Numbers.Zero) {
      this.lastCheckNumber = Numbers.One;
    } else {
      this.lastCheckNumber += Numbers.FiftyOne;
    }
    const startNumber = this.lastCheckNumber;
    const endNumber = this.lastCheckNumber + this.rangeCheckBook;
    checkBook.numberCheckBook = `${startNumber} - ${endNumber}`;
    const arrayRequest = this.checkBookArray;
    arrayRequest.push(checkBook);
    this.checkBookArray = arrayRequest;
  }

  branchs = this._branchData$.asObservable();
  data = this._directionsData.asObservable();
  setDataCheckBook(dataCheckBookRegister: Check) {
    this._dataCheckBook$.next(dataCheckBookRegister);
  }

  get branchData$() {
    return this._branchData$.asObservable();
  }

  get branchData(): DataCheckBook {
    return this._branchData$.getValue();
  }

  set branchData(data: DataCheckBook) {
    this._branchData$.next(data);
  }

  get directionsData$() {
    return this._directionsData.asObservable();
  }

  get directionsData(): Directions {
    return this._directionsData.getValue();
  }

  set directionsData(data: Directions) {
    this._directionsData.next(data);
  }

  get dataCheckBook$() {
    return this._dataCheckBook$.asObservable();
  }

  get dataCheckBook(): Check {
    return this._dataCheckBook$.getValue();
  }

  set dataCheckBook(data: Check) {
    this._dataCheckBook$.next(data);
  }

  get statusData$() {
    return this._statusDataC.asObservable();
  }

  set statusData(data: boolean) {
    this._statusDataC.next(data);
  }

  get statusConfirm$() {
    return this._statusConfirm.asObservable();
  }

  get statusConfirm() {
    return this._statusConfirm.getValue();
  }

  set statusConfirm(data: boolean) {
    this._statusConfirm.next(data);
  }

  get statusConfirmOTP$() {
    return this._statusCheckbookOTP.asObservable();
  }

  set statusConfirmOTP(data: boolean) {
    this._statusCheckbookOTP.next(data);
  }

 async checkbookRequest(deliveryOfficeId: number, accountNumber: string, otp: string): Promise<GenericResponse<checkbookData>> {
    const request = new CheckbookRequest({
      idOficinaEntrega: deliveryOfficeId,
      numeroCuenta: accountNumber,
      otp
    });
      return this.baseService.genericPost<GenericResponse<checkbookData>>(request, { addSession: true, isSecure: true });

  }

  get SelectedCheckbookNumber(): string {
    return this.selectedCheckbookNumber;
  }

  get showTableCheckBook(): BehaviorSubject<boolean> {
    return this._showTableCheckBook;
  }

  set showTableCheckBook(value: BehaviorSubject<boolean>) {
    this._showTableCheckBook = value;
  }

  get checkbookRequestProcess$(): BehaviorSubject<boolean> {
    return this._checkbookRequestProcess;
  }

  get checkbookRequestProcess(): boolean {
    return this._checkbookRequestProcess.getValue();
  }

  set checkbookRequestProcess(value: boolean) {
    this._checkbookRequestProcess.next(value);
  }

  get activationCheckBookProcess(): BehaviorSubject<boolean> {
    return this._activationCheckProcess;
  }
  set activationCheckBookProcess(value: BehaviorSubject<boolean>){
    this._activationCheckProcess = value;
  }
  setSelectedCheckbookNumber(number: string): void {
    this.selectedCheckbookNumber = number;
  }
  getSelectedCheckbookNumber(): string {
    return this.selectedCheckbookNumber;
  }
  getNextId(): number {
    const id = this.nextId;
    this.nextId++;
    return id;
  }
  resetData() {
    this.checkBookArray = [];
    this.statusData = false;
  }

  set resultsBranches(value: boolean){
    this._resultsBranches.next(value);
  }

  get resultsBranches() {
    return this._resultsBranches.getValue();
  }

  get resultBranches$(){
    return this._resultsBranches.asObservable();
  }
  get checkbookInquiry(): BehaviorSubject<boolean> {
    return this._checkbookInquiry;
  }
  set checkbookInquiry(value: BehaviorSubject<boolean>) {
    this._checkbookInquiry = value;
  }

  get reloadCheckBooks$() {
    return this._reloadCheckBooks.asObservable();
  }

  get reloadCheckBooks() {
    return this._reloadCheckBooks.getValue();
  }

  set reloadCheckBooks(data: boolean) {
    this._reloadCheckBooks.next(data);
  }
}
