import { FacePassLoginService } from 'src/app/services/face-pass-login.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalOptions } from 'src/app/interface/modal-options';
import { ModalService } from 'src/app/shared/modal.service';
import { ModalHelpSeriesTokenComponent } from 'src/app/component/modal-help-series-token/modal-help-series-token.component';
import { Constants, ElementsHTML } from 'src/core/constants/Constants';
import { Utils } from 'src/core/utils/utils';
import { AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { AppBridge } from 'src/core/utils/AppBridge';
import { AlertService } from 'src/app/services/alert.service';
import { AppNavigation } from 'src/app/models/AppNavigation';
import { PATH } from 'src/core/constants/Path';
import { Strings } from 'src/core/constants/Strings';
import { Resources } from 'src/core/constants/Resources';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { map } from 'rxjs';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { AppUtils } from 'src/core/utils/AppUtils';
import { BrokerageHouseService } from 'src/app/services/brokerage-house.services';
import { Numbers } from 'src/core/constants/Numbers';
import { FormRegex } from 'src/core/constants/FormConstants';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';

@Component({
  selector: 'app-series-token',
  templateUrl: './series-token.component.html',
  styleUrls: ['./series-token.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeriesTokenComponent implements OnInit, OnDestroy {

  dataForm: Array<string | boolean> = null;
  title: string = Strings.SECURITY_CODE.TitleToken;
  subtitle: string = Strings.PAGE_HEADER.Subtitle;
  regex: RegExp = FormRegex.SeriesToken;
  closeWhiteCircle = Resources.CLOSE_WHITE_CIRCLE;
  circleBlueQuestion = Resources.CIRCLE_BLUE_QUESTION;
  btnChevronRight = Resources.BTN_CHEVRON_RIGHT;
  head = Strings.SERIAL_NUMBER;
  alert: boolean = true;
  errorToken: boolean = false;
  setError: boolean = false;
  dataInternational: Array<string>;
  errorMsg: string = Strings.EMPTY;
  idOperation: string;
  navigateHome: boolean = false;
  isAddresseeInternational: boolean | null = null;
  beforeView: string;
  isContinue: boolean = false;
  isApp:  boolean = AppUtils.platform.isApp;
  contentMargin: string = ElementsHTML.ContentMarginBreakpoints;
  vm$ = this.responsiveService.observe.pipe(
    map(breakpoints => ({ breakpoints }))
  );

  constructor(
    private readonly router: Router,
    private readonly modalService: ModalService,
    private readonly alertService: AlertService,
    private readonly facePassLoginService: FacePassLoginService,
    private readonly responsiveService: ResponsiveService,
    private readonly brokerageHouse: BrokerageHouseService,
    private readonly changeDetector: ChangeDetectorRef
  ) {

    if (this.router.getCurrentNavigation()?.extras?.state) {
      this.isAddresseeInternational = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.Next];
      this.idOperation = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.IdOperation];
      if (this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.DataInternational] !== Strings.EMPTY) {
        this.dataInternational = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.DataInternational];
      } else {
        this.dataInternational = [];
      }
      if (this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.DataForm] !== Strings.EMPTY) {
        this.dataForm = this.router.getCurrentNavigation().extras.state[Constants.NAVIGATION_PARAMETERS.DataForm];
      } else {
        this.dataForm = [];
      }
    }
    else {
      this.navigateHome = true;
    }
  }

  async ngOnInit() {
    if (this.navigateHome && !this.dataForm && !this.brokerageHouse.getIsBrokerage) {
      Utils.navigationError(this.router);
    } else if (this.facePassLoginService.isFacePassLogin.value) {
      this.navigate();
    } else {
      if (AppUtils.platform.isApp) {
        try {
          await AppBridge.invoke<boolean>(AppMethod.GetToken, 'true');
        } catch (error) {
          const message = Utils.getErrorMsg(error);
          this.alertService.showPopupAlert({
            header: Strings.MSG_POPUP_TITLE,
            message: Utils.SERVICE_ERROR_MSG(message.msg, message.code)
          }, {
            onSuccess: () => this.onAppError()
          });
        }
      }

      this.beforeViewsValidation();

      if (this.dataForm) {
        this.title = Strings.AGENDA.TitleNewAgenda;
        this.subtitle = Strings.PAGE_HEADER.Subtitle;
      }
    }
  }

  async onAppError() {
    if (AppUtils.platform.isXamarin) {
      const navigationParams: AppNavigation = {
        Data: AppScreen.Home
      };
      await AppBridge.invoke<string>(AppMethod.SendNativeScreen, navigationParams);
    }
  }

  closeAlert() {
    this.alert = false;
  }

  async showConfirmAddress(serialNumber: string) {
    this.isContinue = true;
    this.setError = serialNumber.length !== Constants.PHYSICAL_TOKEN_SERIAL_LENGTH &&
      serialNumber.length !== Constants.DIGITAL_TOKEN_SERIAL_LENGTH;

    if (!this.setError) {
      this.navigate(serialNumber);
    }
    else {
      this.errorToken = true;
      this.errorMsg = serialNumber.length === Numbers.Zero  ? Strings.GENERIC_ERRORS.Required : Strings.INCOMPLETE_SERIAL_NUMBER;
    }
  }

  private navigate(serialNumber: string = null) {
    const navParams = {
      state: {
        serialNumber,
        idOperation: this.idOperation,
        next: this.isAddresseeInternational,
        dataInternational: this.dataInternational,
        dataForm: this.dataForm
      }, replaceUrl: true
    };
    this.router.navigate([PATH.SerialNumber], navParams);
  }

  validateTokenSeries(token: string) {
    if (token.length === Constants.PHYSICAL_TOKEN_SERIAL_LENGTH ||
      token.length === Constants.DIGITAL_TOKEN_SERIAL_LENGTH) {
      this.setError = false;
      this.errorToken = false;
    } else {
      this.setError = true;
      this.errorToken = true;
      this.errorMsg = token.length === Numbers.Zero  ? Strings.GENERIC_ERRORS.Required : Strings.INCOMPLETE_SERIAL_NUMBER;
    }
  }

  modalHelp() {
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalHelpSerie,
    };
    this.modalService.open(ModalHelpSeriesTokenComponent, modalOptions);
  }

  beforeViewsValidation(){
    if(this.brokerageHouse.getIsBrokerage){
      this.beforeView = PATH.ConfirmBrokerageHouse;
      NavigationUtils.fixNavigation(this.changeDetector, [PATH.ConfirmBrokerageHouse, PATH.TokenNumber]);
    }else{
      if (this.idOperation === Constants.OPERATION_ID) {
        this.beforeView = PATH.ConfirmServiceDetails;
      } else if (this.isAddresseeInternational) {
        this.beforeView = PATH.ConfirmNewInternationalAgenda;
      }else{
        this.beforeView = PATH.NewAddressee;
      }
    }
  }


  async ngOnDestroy() {
    if (AppUtils.platform.isApp) {
      try {
        await AppBridge.invoke<boolean>(AppMethod.GetToken, 'false');
      } catch (error) {
        Utils.printLogError(error);
      }
    }
    if(this.brokerageHouse.getIsBrokerage && !(this.router.url === PATH.ConfirmBrokerageHouse || this.router.url === PATH.SerialNumber)){
      this.brokerageHouse.clearAdresse();
      this.brokerageHouse.setIsBrokerage = false;
    }
    this.modalService.close();
  }
}
