import { AppConstants, AppMethod, RateApp } from 'src/core/constants/AppConstants';
import { Strings } from 'src/core/constants/Strings';
import { AppBridge } from 'src/core/utils/AppBridge';
import { Constants } from 'src/core/constants/Constants';
import { AppNavigation } from 'src/app/models/AppNavigation';

export class AppUtils {
  private static isFromApp: boolean = undefined;
  private static isXamarin: boolean = undefined;


  static get platform() {

    if (this.isFromApp === undefined) {
      this.validateAppAccess();
    }
    if (this.isXamarin === undefined) {
      this.validateAppAccessOrigin();
    }

    return {
      isXamarin: this.isXamarin,
      isApp: this.isFromApp,
      android: window[AppConstants.ANDROID_CONTEXT],
      iOS: window[AppConstants.IOS_CONTEXT]?.messageHandlers
    };
  }

  private static validateAppAccess() {
    try {
      if (window[AppConstants.ANDROID_CONTEXT]) {
        window[AppConstants.ANDROID_CONTEXT][AppMethod.SendNativeScreen](Strings.EMPTY, Strings.EMPTY);
      }
      else {
        window[AppConstants.IOS_CONTEXT].messageHandlers[AppMethod.SendNativeScreen].postMessage(Strings.EMPTY);
      }
      this.isFromApp = true;
      Constants.ACCESS_METHOD_PORTAL=AppConstants.ACCESS_METHOD_APP;
    } catch (error) {
      this.isFromApp = false;
    }
  }

  private static validateAppAccessOrigin() {
    try {
      if (window[AppConstants.ANDROID_CONTEXT]) {
        window[AppConstants.ANDROID_CONTEXT][AppMethod.GetInfoPlatform]();
      } else {
        window[AppConstants.IOS_CONTEXT].messageHandlers[AppMethod.GetInfoPlatform].postMessage(Strings.EMPTY);
      }
      this.isXamarin = true;
    } catch (error) {
      this.isXamarin = false;
    }
  }

  public static async hideSpinner() {
    await AppBridge.invoke<string>(AppMethod.ShowSpinner, Constants.FALSE);
  }

  public static ProcessedSuccessfully(url) {
    if (this.platform.isApp && RateApp.TRANSFERS_RATE.some(service => service === url)) {
      AppBridge.invoke<string>(AppMethod.PortalSuccess, null);
    }
  }



/**
 * La función `PortalSaveInfoStorage` guarda información en el almacenamiento utilizando AppBridge en TypeScript.
 * @param {string} keyStorage - El parámetro `keyStorage` es una cadena que representa la clave bajo la cual el valor será almacenado en el almacenamiento.
 * @param {T} valueStorage - El parámetro `valueStorage` en la función `PortalSaveInfoStorage` es de tipo `T`, 
 * lo que significa que puede ser de cualquier tipo de dato. Al llamar a esta función, puedes pasar cualquier valor de cualquier tipo
 * de dato como parámetro `valueStorage`.
 * @returns La función `PortalSaveInfoStorage` devuelve una promesa que resuelve a un valor booleano.
 */
  public static async PortalSaveInfoStorage<T>(keyStorage:string,valueStorage:T){
    
    const dataSaveStorage:AppNavigation={
      Data: keyStorage,
      DataParams: btoa(JSON.stringify(valueStorage))
    };
    return AppBridge.invoke<boolean>(AppMethod.SaveInfoStorage,dataSaveStorage);
  }

 /**
 * Esta función de TypeScript recupera un valor del almacenamiento utilizando una clave especificada.
 * @param {string} keyStorage - El parámetro `keyStorage` es una cadena que representa la clave utilizada para
 * recuperar un valor del almacenamiento.
 * @returns La función `PortalGetValueStorage` devuelve una promesa que se resuelve con el resultado de
 * invocar el método `AppBridge.invoke` con los parámetros `AppMethod.SendNativeScreen` y `getValueStorage`.
 */
  public static async  PortalGetValueStorage<T>(keyStorage:string){
    const getValueStorage: AppNavigation = {
      Data: AppMethod.PortalGetValueStorage,
      DataParams: keyStorage
    };
    const responseValue = await AppBridge.invoke<T>(AppMethod.SendNativeScreen,getValueStorage);
    if(responseValue.data){
      const decodeBase64 = atob(responseValue.data as string);
      try {
        return JSON.parse(decodeBase64) as T;
      } catch (error) {
        return decodeBase64 as T;
      }
    }else{
      return responseValue.data;
    }
  }

  public static parseData(data: string) {
    try{
        return JSON.parse(data);
    }
    catch(error) {
        return data;
    }
  }
}
