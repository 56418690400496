import { Component } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationIncreaseLineCredit } from 'src/core/constants/CreditConstants';

@Component({
  selector: 'app-modal-credit-line-increase',
  templateUrl: './modal-credit-line-increase.component.html',
  styleUrls: ['./modal-credit-line-increase.component.css']
})
export class ModalCreditLineIncreaseComponent {
  modalData: NotificationIncreaseLineCredit;
  modalInstance: NgbModalRef;
  
  close() {
    this.modalInstance?.close();
  }
}

