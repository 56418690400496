import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'modal-close',
  templateUrl: './modal-close.component.html',
  styleUrls: ['./modal-close.component.css'],
  host: {
    class: 'd-flex align-items-center',
  }
})
export class ModalCloseComponent implements OnInit {
  @Input() image: string;
  @Input() clickFn: () => void;

  constructor() {}

  ngOnInit(): void {}
}
