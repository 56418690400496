import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Numbers } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';

@Directive({
    selector: 'input[cleanInput], textarea[cleanInput]'
})
export class CleanInputDirective {
    @Input() cleanRegex: RegExp;
    @Input() cleanFormControl: AbstractControl;

    constructor(private readonly element: ElementRef) { }

    @HostListener('input', ['$event.target.value'])
    onInput(value: string) {
        const inputElement = this.element.nativeElement;
        const oldValue = value.length;
        const cursorPosition = inputElement.selectionStart;

        if (this.cleanFormControl) {
            this.cleanFormControl.patchValue(this.filterText(value));
        }
        else {
            this.element.nativeElement.value = this.filterText(value);
        }
        const newValue = this.element.nativeElement.value.length;
        if (oldValue < newValue) {
            inputElement.setSelectionRange(parseInt(cursorPosition) + Numbers.One, parseInt(cursorPosition) + Numbers.One);
        } else if (oldValue > newValue) {
            inputElement.setSelectionRange(parseInt(cursorPosition) - Numbers.One, parseInt(cursorPosition) - Numbers.One);
        } else {
            inputElement.setSelectionRange(cursorPosition, cursorPosition);
        }
    }

    private filterText(data: string) {
        if (!data) {
            return data;
        }

        if (this.cleanRegex) {
            return data.replace(this.cleanRegex, Strings.EMPTY);
        }

        return data;
    }

}
