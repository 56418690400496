import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { InvestmentRegisterService } from 'src/app/services/investment-register.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { NotifyDateService } from 'src/app/services/notify-date.service';
import { Constants, FormatDate } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';

@Component({
  selector: 'app-investment-date',
  templateUrl: './investment-date.component.html',
  styleUrls: ['./investment-date.component.css'],
  providers: [NotifyDateService]
})
export class InvestmentDateComponent implements OnInit {
  @Input() dateToDay: string = Strings.EMPTY;
  @Input() dueDate: string = Strings.EMPTY;
  @Input() dateToDayBack: string;
  @Input() dueDateBack: string;
  titleComponent: string = Strings.INVESTMENT.OperationLabel;
  limitDateLabel: string = Strings.LIMIT_DATE_LABEL;
  dueDateLabel: string = Strings.DUE_DATE_LABEL;
  showDateError: boolean = false;
  dateToDaySave: string = Strings.EMPTY;
  dueDateSave: string = Strings.EMPTY;
  dateEnd: string = Strings.EMPTY;
  dateStart: string = Strings.EMPTY;
  minDate = Constants.MIN_DATE_SERVICES_PAYMENT;
  maxDate = Constants.MAX_DATE_SERVICES_PAYMENT;
  head: string = Strings.EMPTY;
  startDate: string = Constants.INVESTMENT_DATE.startDate;
  endDate: string = Constants.INVESTMENT_DATE.endDate;

  constructor(private readonly investmentRegisterService: InvestmentRegisterService,
    private readonly notifyAmountService: NotifyAmountService) { }

  ngOnInit(): void {
    this.dateToDaySave = this.dateToDay;
    this.dueDateSave = this.dueDate;

    this.notifyAmountService.amount$.subscribe(() => {
      this.dateToDay = Strings.EMPTY;
      this.dueDate = Strings.EMPTY;
    });

    this.investmentRegisterService.investmentData$.subscribe(data => {
      if(data?.startDate && data?.endDate){
        this.dateToDay = formatDate(data.startDate, FormatDate.InvestmentToday, Constants.LOCALE_ES_MX);
        this.dueDate = formatDate(data.endDate, FormatDate.InvestmentToday, Constants.LOCALE_ES_MX);
      }
    });

    this.investmentRegisterService.investmentData$.subscribe(() => {
      this.dateToDay = Strings.EMPTY;
      this.dueDate = Strings.EMPTY;
    });

    this.investmentRegisterService.investmentData$.subscribe(data => {
      if(data?.startDate && data?.endDate){
        this.dateToDay = formatDate(data.startDate, FormatDate.InvestmentToday, Constants.LOCALE_ES_MX);
        this.dueDate = formatDate(data.endDate, FormatDate.InvestmentToday, Constants.LOCALE_ES_MX);
      }
    });

    if (this.dateToDayBack !== Strings.EMPTY || this.dueDateBack !== Strings.EMPTY) {
      this.dateToDay = this.dateToDayBack;
      this.dueDate = this.dueDateBack;
    }
  }
}
