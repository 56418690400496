import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';
import { Char, Constants, FormatDate, PositionDate } from 'src/core/constants/Constants';
import { StatementsAccountPLRequest } from '../interface/StatementsAccountPLRequest';
import { IBaseService } from './i-base-service.service';
import { IStatementsAccountPLResponse } from '../interface/IStatementsAccountPLResponse';
import { StatementsAccountPDFRequest } from '../interface/IStatementsAccountPDFRequest';
import { IStatementsAccountPDFResponse } from '../interface/IStatementsAccountPDFResponse';
import { BusinessError } from 'src/core/exceptions/BusinessError';
import { Strings } from 'src/core/constants/Strings';
import { ConsultPDFCreditCardRequest } from '../interface/dto/ConsultPDFCreditCardRequest';
import { ConsultPDFCreditCardResponse } from '../interface/dto/ConsultPDFCreditCardResponse';
import { Utils } from 'src/core/utils/utils';
import { SystemId } from 'src/core/constants/SystemId';
import { SentinelService } from 'src/app/services/sentinel.service';

@Injectable({
  providedIn: 'root'
})
export class StatementAccountService {
  private readonly emptyModel: IStatementsAccountPLResponse = {
    ListPeriodos: { Periodo: [] }
  };

  private readonly _periods: BehaviorSubject<IStatementsAccountPLResponse> = new BehaviorSubject<IStatementsAccountPLResponse>(this.emptyModel);

  constructor(private readonly baseService: IBaseService,
    private readonly storageService: StorageService,
    private readonly sentinelService: SentinelService) { }

  get periods$() {
    return this._periods.asObservable();
  }

  get periods() {
    return this._periods.getValue();
  }

  set periods(data: IStatementsAccountPLResponse) {
    this._periods.next(data);
  }

  async getPeriods(account: string, systemId: string) {
    const request = new StatementsAccountPLRequest({
      Sesion: this.storageService.getSession(),
      idSistema: systemId,
      cuenta: account,
      tipoConsulta: Constants.TYPE_OF_QUERY_ACCOUNT_PERIODS
    });

    const response = await this.baseService.genericPost<IStatementsAccountPLResponse>(request, { isSOA: true });
    this.periods = response;
  }

  async getPeriodsPDF(params: {id: SystemId; account: string;}, ip: string, months: string){
    const request: StatementsAccountPDFRequest = new StatementsAccountPDFRequest({
      Sesion: this.storageService.getSession(),
      tipo: Constants.TYPE_OF_QUERY_ACCOUNT_PERIODS,
      idSistema: Number.parseInt(params.id),
      ipUsuario: this.sentinelService.sentinel.ip,
      cuenta: params.account,
      idPeriodo: Number.parseInt(months),
      nombreOrigen: Constants.ACCESS_METHOD_PORTAL,
      Origen: Constants.ORIGIN_PDF_ACCOUNT_STATEMENTS,
      MedioAcceso: Constants.ACCESS_METHOD_PORTAL
    });
    const response = await this.baseService.genericPost<IStatementsAccountPDFResponse>(request, { isSOA: true });
    if (!response.ArrayByteVO) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
    }
    return response;
  }

  async getCreditPeriodsPDF(creditCardNumber: number, monthsFilter: string, systemId: string){
    const geolocation = this.storageService.getGeolocation();
    const resultDates = this.periods.ListPeriodos.Periodo.filter(date => {
      return date.idPeriodo === Number.parseInt(monthsFilter);
    });
    const startPeriodFormat = Utils.dateFormat(resultDates[PositionDate.Zero].inicioPeriodo, FormatDate.FormatAccountStatement);
    const endPeriodFormat = Utils.dateFormat(resultDates[PositionDate.Zero].finPeriodo, FormatDate.FormatAccountStatement);
    const dateType = `${startPeriodFormat}${Char.MiddleDash}${endPeriodFormat}`;
    const request: ConsultPDFCreditCardRequest = new ConsultPDFCreditCardRequest({
      IdPeriodo: Constants.ZERO_DATA_REQUEST_CREDIT_CARD,
      Cuenta: creditCardNumber.toString(),
      Tipo: dateType,
      Tarjeta: creditCardNumber.toString(),
      Mes: Constants.ZERO_DATA_REQUEST_CREDIT_CARD.toString(),
      Tasa: Constants.ZERO_DATA_REQUEST_CREDIT_CARD.toString(),
      Estatus: Constants.STATUS_REQUEST_CREDIT_CARD,
      IdSistema: Number(systemId),
      Origen: Constants.ORIGIN_PDF_ACCOUNT_STATEMENTS,
      MedioAcceso: Constants.ACCESS_METHOD_PORTAL
    });

    const response = await this.baseService.genericPost<ConsultPDFCreditCardResponse>(request, {
      isSOA: true, urlOptions: [
        geolocation.latitude,
        geolocation.longitude,
        this.storageService.getSession()
      ]
    });
    if (!response.ArrayByteVO) {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.NULL_CODE);
    }
    return response;
  }

  empty() {
    this.periods = this.emptyModel;
  }

}
