export interface InsuranceIcons {
    gmm: { isHidden: boolean };
    auto: { isHidden: boolean };
    vida: { isHidden: boolean };
    daños: { isHidden: boolean};
    idSelected: string;
}
export class PolicyConstants{
    static readonly UNITED_STATES_CURRENCY = 'USD';
    static readonly POLICY_ACCESS_METHOD = 'BANCA';
    static readonly POLICY_RESULT_ID = 'policyResult';
    static readonly POLICIES = {
        IdCurrencyMXN: '01',
        TypeGMM: 'GMM',
        TypeDamage: 'daños',
        TypeCar: 'auto',
        TypeResponseDamage: 'danos'
    };
    static readonly COLLECTION_LEVEL = {
        Familia: 'FA',
        Cis: 'CI'
    };
}
