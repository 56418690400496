import { PathModel } from '../models/PathModel';

interface IConsultationReceiptEdoMexRequest{
    IdSession: string,
    Clave: string
}

export class ConsultationReceiptEdoMexRequest{
    constructor(public data:IConsultationReceiptEdoMexRequest){}

    get endpoint(): PathModel {
      return PathModel.ReceiptEdoMex;
  }
}