import { PathModel } from '../models/PathModel';

export interface IAforePLRequest {
    idSesion: string;
    MedioAcceso: string;
    OrigenConsulta: string;
    IpCliente: string;
}

export class AforePLRequest{
    constructor (public data: IAforePLRequest){ }

    get endpoint(): PathModel {
      return PathModel.Afore;
  }
}
