import { PathModel } from 'src/app/models/PathModel';

export interface IGetDirectDebitCreditCardRequest {
  numeroCuenta: string;
  origen: string;
  description?: string;
  card?: string;
}

export class GetDirectDebitCreditCardRequest {
  constructor(public data: IGetDirectDebitCreditCardRequest) { }

  get endpoint(): PathModel {
    return PathModel.GetDirectDebitServicesTDC;
  }
}
