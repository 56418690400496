export { };

declare global {
    interface Array<T> {
        /**
         * Ordena elementos de manera aleatoria
         */
        gfiShuffle<T>(): Array<T>;
    }
}

Array.prototype.gfiShuffle = function<T>() {
    return this.map((item: T) => ({ order: Math.random(), value: item }))
    .sort((a: { order: number; }, b: { order: number; }) => a.order - b.order)
    .map(({value}) => value);
};
