import { PathModel } from '../models/PathModel';

interface  IGetCheckAccountsRequest{
  IdSession?: string;
  MedioAcceso?: string;
  TipoTransaccion?: string;
  Transaccion?: string;
  OrigenConsulta?: string;
  IpCliente: string;
}

export class GetCheckAccountsRequest{
    constructor(public data: IGetCheckAccountsRequest){}

    get endpoint(): PathModel {
      return PathModel.GetCheckAccounts;
  }
}
