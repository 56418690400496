import { PathModel } from 'src/app/models/PathModel';

interface ICheckInvestmentsRequest {
    IdSession: string;
    MedioAcceso: string;
    OrigenConsulta: string;
    IpCliente: string;
}

export class CheckInvestmentsRequest{
    constructor(public data: ICheckInvestmentsRequest){}

    get endpoint(): PathModel{
        return PathModel.ConsultInvestments;
    }
}
