import { PathModel } from '../models/PathModel';

export interface ICancelarTokenPLRequest {
  Sesion: string;
  TipoToken: string;
  NumeroSerie: string;
  MedioAcceso: string;
  OrigenConsulta?: string;
  IpCliente?: string;
}

export class CancelarTokenPLRequest{
    constructor (public data: ICancelarTokenPLRequest){ }

    get endpoint(): PathModel {
      return PathModel.CancelToken;
  }
}
