import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IAforeEvent } from '../interface/IAforeEvent';
@Injectable({
  providedIn: 'root'
})
export class AforeStatusService {
  private readonly _status: BehaviorSubject<IAforeEvent> = new BehaviorSubject<IAforeEvent>({ value: false });

  constructor() { }

  get status$() {
    return this._status.asObservable();
  }

  get status() {
    return this._status.getValue();
  }

  set status(data: IAforeEvent) {
    this._status.next(data);
  }
}
