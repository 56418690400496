import { Injectable } from '@angular/core';
import { Constants, FormValidations, InputTypes, Numbers, Regex} from 'src/core/constants/Constants';
import { InputConfig } from 'src/app/interface/IInputConfig';
import { PaymentSATStrings } from 'src/core/constants/PaymentSATStrings';
import { Validators } from '@angular/forms';
import { Strings } from 'src/core/constants/Strings';
import { FederalTaxPaymentRequest, IFederalTaxPayment } from 'src/app/interface/dto/FederalTaxPaymentRequest';
import { IBaseService } from 'src/app/services/i-base-service.service';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { FederalTaxPaymentModel } from 'src/app/interface/FederalTaxPaymentModel';
import { FederalTaxPaymentVoucherRequest, IFederalTaxPaymentVoucherRequest } from 'src/app/interface/dto/FederalTaxPaymentVoucherRequest';
import { FederalTaxPaymentVoucherResponse } from 'src/app/interface/dto/FederalTaxPaymentVoucherResponse';

export interface TContributionData {
  CURP: string;
  firstName: string;
  secondName: string;
  name: string;
  dependency: string;
  razonSocial?: string;
  RFC: string;
  dataJs?: string;
  originalString?: string
}

export interface TContributionConcept {
  idConcept?: number;
  conceptAmount?: string;
  paidAmount?: string;
  updatedPart?: string;
  surcharges?: string;
  finesForCorrection?: string;
  compensations?: string;
  DPAReferenceKey?: string;
  dependencyChain?: string;
  period?: string;
  exercise?: string;
  iva?: boolean;
}

export interface TGeneralContributionByConcept {
  contributionData: TContributionData;
  contributionConcept: TContributionConcept[];
}

@Injectable({
  providedIn: 'root'
})
export class PaymentSatService {
  private readonly generalContributionByConcept: TGeneralContributionByConcept = {
    contributionData: null,
    contributionConcept: []
  };

  private readonly _inputConfig: InputConfig[] = [
    {
      type: InputTypes.Text,
      label: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.LabelName.RFC,
      name: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.TextFields.RFC,
      idLabel: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.IDLabel.RFC,
      validations: [
        {
          name: FormValidations.Required,
          validator: Validators.required,
          message: Strings.GENERIC_ERRORS.Required
        },
        {
          name: Constants.FORM_CONTROLS.MinimumLength,
          validator: Validators.minLength(Numbers.Thirteen),
        },
        {
          name: FormValidations.Pattern,
          validator: Validators.pattern(Regex.ValidateRFC),
          message: PaymentSATStrings.EXCEPTIONS.RFC
        }
      ]
    },
    {
      type: InputTypes.Text,
      label: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.LabelName.ConfirmRFC,
      name: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.TextFields.ConfirmRFC,
      idLabel: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.IDLabel.RFC,
      validations: [
        {
          name: FormValidations.Required,
          validator: Validators.required,
          message: Strings.GENERIC_ERRORS.Required
        },
        {
          name: Constants.FORM_CONTROLS.MinimumLength,
          validator: Validators.minLength(Numbers.Thirteen)
        },
        {
          name: FormValidations.Pattern,
          validator: Validators.pattern(Regex.ValidateRFC),
          message: PaymentSATStrings.EXCEPTIONS.RFC
        }
      ]
    },
    {
      type: InputTypes.Text,
      label: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.LabelName.CURP,
      name: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.TextFields.CURP,
      idLabel: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.IDLabel.CURP,
      validations: [
        {
          name: FormValidations.Required,
          validator: Validators.required,
          message: Strings.GENERIC_ERRORS.Required
        },
        {
          name: Constants.FORM_CONTROLS.MinimumLength,
          validator: Validators.minLength(Numbers.Eighteen),
        },
        {
          name: FormValidations.Pattern,
          validator: Validators.pattern(Regex.CURP),
          message: PaymentSATStrings.EXCEPTIONS.CURP
        }
      ]
    },
    {
      type: InputTypes.Text,
      label: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.LabelName.FirstName,
      name: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.TextFields.FirstName,
      idLabel: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.IDLabel.FirstName,
      validations: [
        {
          name: FormValidations.Required,
          validator: Validators.required,
          message: Strings.GENERIC_ERRORS.Required
        }
      ]
    },
    {
      type: InputTypes.Text,
      label: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.LabelName.SecondName,
      name: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.TextFields.SecondName,
      idLabel: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.IDLabel.SecondName,
      validations: [
        {
          name: FormValidations.Required,
          validator: Validators.required,
          message: Strings.GENERIC_ERRORS.Required
        }
      ]
    },
    {
      type: InputTypes.Text,
      label: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.LabelName.Name,
      name: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.TextFields.Name,
      idLabel: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.IDLabel.Name,
      validations: [
        {
          name: FormValidations.Required,
          validator: Validators.required,
          message: Strings.GENERIC_ERRORS.Required
        }
      ]
    },
    {
      type: InputTypes.Text,
      label: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.LabelName.RazonSocial,
      name: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.TextFields.RazonSocial,
      idLabel: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.IDLabel.RazonSocial
    },
    {
      type: InputTypes.Select,
      label: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.LabelName.Dependency,
      name: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.TextFields.Dependency,
      idLabel: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.IDLabel.Dependency
    },
    {
      type: InputTypes.Select,
      label: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.LabelName.Exercise,
      name: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.TextFields.Exercise,
      idLabel: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.IDLabel.Exercise
    },
    {
      type: InputTypes.Text,
      label: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.LabelName.DPAReferenceKey,
      name: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.TextFields.DPAReferenceKey,
      idLabel: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.IDLabel.DPAReferenceKey,
      validations: [
        {
          name: FormValidations.Required,
          validator: Validators.required,
          message: Strings.GENERIC_ERRORS.Required
        },
        {
          name: Constants.FORM_CONTROLS.MinimumLength,
          validator: Validators.minLength(Numbers.Nine),
          message: PaymentSATStrings.EXCEPTIONS.DPA
        }
      ]
    },
    {
      type: InputTypes.Select,
      label: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.LabelName.Period,
      name: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.TextFields.Period,
      idLabel: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.IDLabel.Period
    },
    {
      type: InputTypes.Text,
      label: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.LabelName.DependencyChain,
      name: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.TextFields.DependencyChain,
      idLabel: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.IDLabel.DependencyChain,
      validations: [
        {
          name: FormValidations.Required,
          validator: Validators.required,
          message: Strings.GENERIC_ERRORS.Required
        },
        {
          name: Constants.FORM_CONTROLS.MinimumLength,
          validator: Validators.minLength(Numbers.Fourteen),
          message: PaymentSATStrings.EXCEPTIONS.Dependency
        }
      ]
    },
    {
      type: InputTypes.Text,
      label: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.LabelName.Balance,
      name: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.TextFields.Balance,
      idLabel: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.IDLabel.Balance
    },
    {
      type: InputTypes.Select,
      label: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.LabelName.Detail,
      name: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.TextFields.Detail,
    },
    {
      type: InputTypes.Text,
      label: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.LabelName.TaxCard,
      name: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.TextFields.TaxCard,
      idLabel: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.IDLabel.TaxCard
    },
    {
      type: InputTypes.Text,
      label: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.LabelName.Import,
      name: PaymentSATStrings.PAYMENT_CONTRIBUTIONS_CONCEPT.Form.TextFields.Import,
      validations: [
        {
          name: FormValidations.Required,
          validator: Validators.required,
          message: Strings.GENERIC_ERRORS.Required
        }
      ]
    }
  ];
  constructor(private readonly baseService: IBaseService){}

  set contributionData(data: TContributionData) {
    this.generalContributionByConcept.contributionData = data;
  }

  addConcepts(concept: TContributionConcept[]){
    this.generalContributionByConcept.contributionConcept = concept;
  }

  cleanConcepts(){
    this.generalContributionByConcept.contributionConcept = [];
  }

  cleanGeneralContribution(){
    this.generalContributionByConcept.contributionConcept = [];
    this.generalContributionByConcept.contributionData = null;
  }

  get contributionConcept(): TContributionConcept[] {
    return this.generalContributionByConcept.contributionConcept;
  }

  get contributionData(): TContributionData {
    return this.generalContributionByConcept.contributionData;
  }

  get inputConfig(){
    return this._inputConfig;
  }

  async federalTaxPayment(federalTaxPaymentData: IFederalTaxPayment) {
    const request = new FederalTaxPaymentRequest(federalTaxPaymentData);
    return (await this.baseService.genericPost<GenericResponse<FederalTaxPaymentModel>>(request, { addSession: true, isSecure: true })).datos;
  }

  async federalTaxPaymentVoucher(federalTaxPaymentVoucherData: IFederalTaxPaymentVoucherRequest) {
    const request = new FederalTaxPaymentVoucherRequest(federalTaxPaymentVoucherData);
    return this.baseService.genericPost<FederalTaxPaymentVoucherResponse>(request, { isSOA: true });
  }
}
