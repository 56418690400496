import { PathModel } from 'src/app/models/PathModel';

type TConsultPDFCreditCardRequest = {
    IdPeriodo: number,
    Cuenta: string,
    Tipo: string,
    Tarjeta: string,
    Mes: string,
    Tasa: string,
    Estatus: number,
    IdSistema: number,
    Origen: number,
    MedioAcceso: string
};

export class ConsultPDFCreditCardRequest {
    constructor(public data: TConsultPDFCreditCardRequest) { }

    public get endpoint(): PathModel {
        return PathModel.ConsultPDFCreditCard;
    }
}