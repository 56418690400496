import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalMyCardsComponent } from 'src/app/control-card/components/modal-my-cards/modal-my-cards.component';
import { Char, CoinType, ElementsHTML, Keys } from 'src/core/constants/Constants';
import { ControlCardConstants, InputNames, OperationType } from 'src/core/constants/ControlCardConstants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { FirstNipService } from 'src/app/services/first-nip.service';
import { SecondNipService } from 'src/app/services/second-nip.service';
import { Utils } from 'src/core/utils/utils';
import { Numbers } from 'src/core/constants/Numbers';
import { FormValidations } from 'src/core/constants/FormConstants';

@Injectable({
  providedIn: 'root'
})
export class ModalMyCardsExtensionThreeService {
  confirmNip:string = Strings.EMPTY;
  nip:string = Strings.EMPTY;
  constructor(
    private readonly currencyPipe: CurrencyPipe,
    private readonly _firstNip: FirstNipService,
    private readonly _secondNip: SecondNipService) { }

  modifyCvvButtonState(context: ModalMyCardsComponent){
    return context.showRequiredForm &&
    context.adminCardForm.get(InputNames.Nip)?.hasError(FormValidations.Required) ||
    context.adminCardForm.get(InputNames.Nip)?.touched && context.adminCardForm.get(InputNames.Nip)?.hasError(FormValidations.MinLength) ||
    context.adminCardForm.get(InputNames.ConfirmPin)?.hasError(FormValidations.Required) ||
    context.adminCardForm.get(InputNames.ConfirmPin)?.touched && context.adminCardForm.get(InputNames.ConfirmPin)?.hasError(FormValidations.MinLength) ||
    context.errorConfirmNip;
  }

  locksAndLimitsButtonState(context: ModalMyCardsComponent){
     return context.flagNotChanges && this.validateCardType;
  }

  private validateCardType(context: ModalMyCardsComponent):boolean{
    return context.getDataCardsInfo.Card.CardType !== context.webCard
    && context.getDataCardsInfo.Card.CardType !== context.groceryVoucherCard
    && context.getDataCardsInfo.Card.CardType !== context.walmartGroceryVoucherCard;
  }

  validateChangeValueAmount(typeLimit: string, event: Event, context: ModalMyCardsComponent) {
    typeLimit = typeLimit === OperationType.UPDATE_LIMIT_MONTHLY ? OperationType.MOVEMENTS_MONTHLY: typeLimit;
    context.arrayValuesLimits[OperationType.AMOUNTS][typeLimit] = Number((event.target as HTMLInputElement).value);
    this.validateMovementsZeros(context);
    context.flagNotChanges = this.evaluateLimitsAndLocks(context.getLocksLimitsInfo.Locks, context.arrayValuesLimits, context);
    if (!context.flagNotChanges) {
      context.flagNotChanges = JSON.stringify(context.arrayValuesLimits) === JSON.stringify(context.getLocksLimitsInfo.Limits);
    }
  }
  validateChangeMovements(type:string,event:Event,context:ModalMyCardsComponent){
      context.arrayValuesLimits[OperationType.MOVEMENTS][type] = Number((event.target as HTMLInputElement).value);
      this.validateMovementsZeros(context);
      context.flagNotChanges = this.evaluateLimitsAndLocks(context.getLocksLimitsInfo.Locks,context.arrayValuesLimits, context);
      if(!context.flagNotChanges){
        context.flagNotChanges = JSON.stringify(context.arrayValuesLimits) === JSON.stringify(context.getLocksLimitsInfo.Limits);
      }

  }
 /**
   * Valida si estan habilitados los locks, para el cambio de banderas zeroAtm
   * Para mostrar o no el mensaje de movimientos.
   * en modal my cards.
   * @param context se le pasa el componente ModalMyCards
  */
  validateMovementsZeros(context:ModalMyCardsComponent){
    if(!context.locksAtm && context.arrayValuesLimits[OperationType.MOVEMENTS][OperationType.MOVEMENTS_ATM] === Numbers.Zero){
      context.flagNotChanges = true;
      context.zeroAtm = true;
    }else{
      context.zeroAtm = false;
    }
    if(!context.locksCommerce &&  context.arrayValuesLimits[OperationType.MOVEMENTS][OperationType.MOVEMENTS_POS] === Numbers.Zero){
      context.zeroPos = true;
      context.flagNotChanges = true;
    }else{
      context.zeroPos = false;
    }
  }
  /**
   * Valida los locks en false para evaluar si vienen en 0 sus Amounts
   * Para habilitar o deshabilitar la bandera del boton continuar
   * en modal my cards.
   * @param objectFlags objeto de los locks
   * @param objectData objeto de los limites.
   * @returns un booleano que representa la variable flagNotChanges
  */
  evaluateLimitsAndLocks(objectFlags, objectData, context: ModalMyCardsComponent) {
    let flagNotChanges = false;
    for (const clave in objectFlags) {
      if (clave.toLocaleUpperCase() === OperationType.UPDATE_IOS_LABEL || clave.toLocaleUpperCase() === OperationType.UPDATE_ANDROID_LABEL
      || clave.toLocaleUpperCase() === OperationType.UPDATE_SMART_LABEL_OBJECT) {
        continue;
      }
      if (!objectFlags[clave]) {
        if (objectData.Amounts.hasOwnProperty(clave) && objectData.Amounts[clave] === Numbers.Zero) {
          flagNotChanges = true;
          break;
        }
        if (objectData.Movements.hasOwnProperty(clave) && objectData.Movements[clave] === Numbers.Zero) {
          flagNotChanges = true;
          break;
        }
      }
    }
    context.validateMaxAmounts();
    context.validateAmounts();
    return flagNotChanges;
  }

  getInput(event: Event) {
    let value = (event.target as HTMLInputElement).value;
    if(value=== Strings.EMPTY){
      value = Numbers.Zero.toString();
    }else{
      while (value.startsWith(ControlCardConstants.DOUBLE_ZERO)) {
        value = value.slice(Numbers.One);
      }
    }
    (event.target as HTMLInputElement).value = value;
  }

  getInputPorcent(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    if (value===Numbers.Zero.toString() || value ===Strings.EMPTY){
      (event.target as HTMLInputElement).value = `${Numbers.Zero}%`;
    }
  }

  onBlur(event) {
    const value = Utils.transformAmount(event.target.value);
    const transformedValue = this.currencyPipe.transform(value, CoinType.USD, ElementsHTML.Symbol, ControlCardConstants.ONE_TWO_ZERO);
    event.target.value = transformedValue;
  }

  handleSelect($event: Event, inputName: string) {
    if(inputName === InputNames.Nip){
      this._firstNip.handleSelect($event, inputName);
    }
    if(inputName === InputNames.ConfirmPin){
      this._secondNip.handleSelect($event, inputName);
    }
    if(inputName === InputNames.CvvMyCards){
      this._secondNip.handleSelect($event, inputName);
    }
  }

  onlyNumbers($event: Event, inputName: string, formGroup: FormGroup) {
    if(inputName === InputNames.Nip){
      this._firstNip.onlyNumbers($event,inputName,formGroup);
      this.nip = this._firstNip.nip;
    }
    if(inputName === InputNames.ConfirmPin){
      this._secondNip.onlyNumbers($event,inputName,formGroup);
      this.confirmNip = this._secondNip.nip;
    }
    if(inputName === InputNames.CvvMyCards){
      this._secondNip.onlyNumbers($event,inputName,formGroup);
      this.nip = this._secondNip.nip;
    }
  }

  onKeyPress(event: KeyboardEvent) {
    const charCode = event.key;
    if(charCode === Char.WhiteSpace) {
      event.preventDefault();
      return false;
    }
    if (!isNaN(Number(charCode)) || event.ctrlKey || event.metaKey || event.key === Keys.Backspace || event.key === Keys.Delete) {
        return true;
    } else {
        event.preventDefault();
        return false;
    }
  }

  removeCharacters(event: Event, controlName: string, form: FormGroup, regex: RegExp, context, lenght?:number) {
    const value = (event.target as HTMLInputElement).value;
    const newValue = value.replace(regex, Strings.EMPTY);
    const formatValue = this.limitInput(newValue, lenght);
    form.get(controlName).setValue(formatValue);
    context.helpIcon = context.cvvCardForm.get(InputNames.CvvMyCards).value.length >= Numbers.Zero &&
    (context.cvvCardForm.get(InputNames.CvvMyCards)?.hasError(FormValidations.Required)
      || context.cvvCardForm.get(InputNames.CvvMyCards)?.hasError(FormValidations.MinLength))
      ? Resources.QUESTION_MARK_RED :Resources.HELP_ICON;
  }

  reset(){
    this.confirmNip = Strings.EMPTY;
    this.nip = Strings.EMPTY;
    this._firstNip.resetData();
    this._secondNip.resetData();
  }

  hasAnyError(context:ModalMyCardsComponent){
    context.isContinueLockValid =  this.continueHasErrorThree(context)
    &&
    this.continueHasErrorTwo(context)
    && this.continueHasError(context);
  }

  continueHasError(context:ModalMyCardsComponent){
    if(!context.locksInt){
      return context.onlineInformation !== context.getLocksLimitsInfo.Limits.Amounts.Int &&
      context.onlineInformation < ControlCardConstants.MAXIMUM_ONLINE_AMOUNT && context.onlineInformation !== Numbers.Zero;
    }else{
      return true;
    }
  }

  continueHasErrorTwo(context:ModalMyCardsComponent){
    if(!context.locksCommerce){
      return context.commerceInformation !== context.getLocksLimitsInfo.Limits.Amounts.Pos &&
      context.commerceInformation !== Numbers.Zero && context.commerceInformation < context.maximumCommerceAmount;
    }else{
      return true;
    }
  }

  continueHasErrorThree(context:ModalMyCardsComponent){
    if(!context.locksAtm){
      return context.atmInformation !== context.getLocksLimitsInfo.Limits.Amounts.Atm
      && context.atmInformation !== Numbers.Zero && context.atmInformation < ControlCardConstants.MAXIMUM_ATM_AMOUNT;
    }else{
      return true;
    }
  }

  limitInput(value:string,lenght:number){
    if(value.length>lenght){
      return value.substring(Numbers.Zero,lenght);
    }
    return value;
  }
}
