<ng-container *ngIf="vm$ | async as vm" scrollTop>
    <div>
      <div [class.m-4]="!vm.breakpoints.mobile" [class.mx-3]="vm.breakpoints.mobile" [class.m-3]="vm.breakpoints.mobile"
        class="d-flex flex-column" [class.gap-4]="!dataIsEmpty" #ModalContainer>
        <header #Header>
          <span class="text-start header-s text-secondary-800" >
            Traspasos {{ getAccountType(vm.account.type) }}
          </span>
          <div [ngClass]="vm.breakpoints.mobile ? 'mt-3' : 'mt-4'">
            <div [class.flex-column]="vm.breakpoints.mobile" [class.flex-row]="!vm.breakpoints.mobile"
              class="d-flex justify-content-center gap-2">
              <div class="w-100" ngbAutofocus>
                <app-enter-date (outputDate)="getStartDate($event)" [minDate]="minDate" [maxDate]="maxDateOne"
                  [title]="false" [showCard]="false" [id]="'startdate'" labelInput="Periodo del"
                  [customDate]="minDate" [useService]="false" [operation]="operation"
                  (isDateError)="isValidDate($event)" #FirstDate>
                </app-enter-date>
              </div>
              <div class="w-100">
                <app-enter-date (outputDate)="getEndDate($event)" [minDate]="minDateTwo" [maxDate]="maxDate"
                  [title]="false" [showCard]="false" [id]="'enddate'" labelInput="Hasta"
                  [customDate]="maxDate" [useService]="false" [operation]="operation"
                  (isDateError)="isValidDate($event)" (click)="resetError()">
                </app-enter-date>
              </div>
            </div>
            <div *ngIf="showErrorDate" class="detail-m label-error">
              {{ errorDateLabel }}
            </div>
          </div>
        </header>
        <main #Container>
          <div class="d-flex gap-2 align-items-start align-self-stretch justify-content-end"
          *ngIf="!dataIsEmpty">
            <span class="button-m text-secondary-800">Total: {{ amount | currency }}</span>
          </div>
          <div *ngIf="!dataIsEmpty && historical$ | async as listOfRecipients" #content>
            <div>
              <ng-container *ngFor="let history of orderedHistory(listOfRecipients.data)">
                <div class="button-m text-primary-1000 mb-3">
                  {{ (history[0] !== year ? history[0] : year) }}
                </div>
                <ng-container *ngFor="let byMonthAndDay of orderByMonthAndDay(history[1])">
                  <div class="detail-m text-primary-1000">
                    {{ formatMonth(byMonthAndDay[0]) }}
                  </div>
                  <ng-container *ngFor="let payment of byMonthAndDay[1]">
                    <app-transfer-history-item [data]="payment"></app-transfer-history-item>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </main>
        <footer #Footer>
          <div>
            <app-pagination [startElement]="Container"></app-pagination>
          </div>
          <div class="d-flex flex-column gap-3 align-items-center">
            <button (click)="close()"
            class="justify-content-center colored-btn button-m btn-detail modals-btn">
              Cerrar
            </button>
          </div>
        </footer>

      </div>
    </div>
  </ng-container>
