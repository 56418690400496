<ng-container *ngIf="!pageError">
  <ng-container *ngIf="vm$ | async as vm">
    <div id="container-touch" >
      <ng-container *ngIf="vm.switchRoute !== null">
        <ng-container *ngIf="vm.switchRoute; else HomeView">
          <toast></toast>
          <app-loader></app-loader>
          <div class="login-wrapper">
            <app-sidebar id="login-sidebar"></app-sidebar>
            <app-header id="login-header"></app-header>
            <router-outlet id="router-outlet"></router-outlet>
            <div
              *ngIf="!vm.breakpoints.desktop"
              class="footer d-flex flex-column justify-content-center gap-4 py-5"
              [ngClass]="
                vm.breakpoints.mobile
                  ? 'align-items-start ps-5'
                  : 'align-items-center'
              "
            >
              <a class="option d-flex gap-2" [href]="links.App" target="_blank">
                <img
                  [src]="mobile02 | cdnImage"
                  alt="Icono Menu"
                  height="24"
                  width="24"
                />
                <span class="text body-s">Conoce Inbursa Móvil</span>
              </a>
              <a class="option d-flex gap-2">
                <img
                  [src]="phoneVoice | cdnImage"
                  alt="Icono Menu"
                  height="24"
                  width="24"
                />
                <a href="tel:800 90 90000" class="text-decoration-none text body-s"
                  >800 90 90000</a
                >
              </a>
              <a
                class="option d-flex gap-2"
                [href]="links.SecurityTips"
                target="_blank"
              >
                <img
                  [src]="lock | cdnImage"
                  alt="Icono Menu"
                  height="24"
                  width="24"
                />
                <span class="text body-s">Tips de Seguridad</span>
              </a>
              <a class="text-decoration-none text body-soption d-flex gap-2 cursor-pointer"
                (click)="onShowRequirements()" (keydown.enter)="$event.preventDefault()">
                <img
                  class="align-content-start"
                  [src]="monitor | cdnImage"
                  alt="Icono Menu"
                  height="auto"
                  width="auto"
                />
                <span class="text body-s"> Requerimientos mínimos técnicos </span>
              </a>
              <a class="text-decoration-none text body-soption d-flex gap-2 cursor-pointer"
                (click)="showGeolocationManual(vm.breakpoints)" (keydown.enter)="$event.preventDefault()">
                <img
                  class="align-content-start"
                  [src]="locationIcon | cdnImage"
                  alt="Icono Menu"
                  height="auto"
                  width="auto"
                />
                <span class="text body-s"> Manual de Geolocalización </span>
              </a>
            </div>
          </div>
        </ng-container>

        <ng-template #HomeView>
          <app-loader></app-loader>
          <toast></toast>
          <div class="d-flex" id="wrapper" >
            <app-sidebar *ngIf="userId !== undefined && userSession"
            [hidden]="(isApp && isXamarin) || (userId === undefined) || isExternalApp" ></app-sidebar>
              <div id="page-content-wrapper">
                <div [class]="classHeight" [hidden]="(isApp && isXamarin) || (userId === undefined) || isExternalApp" >
                  <app-header (show)="toggleSearch($event)"></app-header>
                </div>
                <div class="p-search" *ngIf="showSearch && vm.breakpoints.mobile">
                  <app-main-search (show)="toggleSearch($event)" (showModalChanged)="onShowModalChanged($event)"
                  [showModal]="showModal"></app-main-search>
                </div>
              <div class="data-user" [hidden]="!vm.profile && !showIdClient"
                [class.pt-2]="showSearch" *ngIf="userId !== undefined && vm.profile && showIdClient">
                <label for="" >ID cliente: {{userId}}</label>
              </div>
              <router-outlet></router-outlet>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="pageError">
  <app-maintenance [dataError]="parentMessage"></app-maintenance>
</ng-container>
