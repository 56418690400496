import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ILoyaltyForm, ILoyaltyProgram, ILoyaltyProgramsResponse } from 'src/app/interface/Loyalty';
import { LoyaltyMovement } from '../interface/dto/GetRedemptionMovementsResponse';
import { Constants, Length, Numbers, TypeRedemption } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { GetRedemptionsRequest } from '../interface/dto/GetRedemptionsRequest';
import { GetRedemptionsResponse } from '../interface/dto/GetRedemptionsResponse';
import { StorageService } from './storage.service';
import { IBaseService } from './i-base-service.service';
import { BusinessError } from 'src/core/exceptions/BusinessError';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyService {

  constructor(
    private readonly storage: StorageService,
    private readonly baseService: IBaseService
  ) { }

  private readonly _isAvailable  : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _selectedTab  : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly _loyaltyPoints: BehaviorSubject<number>  = new BehaviorSubject<number>(Numbers.Zero);
  private readonly _addProgram   : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _noPrograms   : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly _errorPrograms: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly _redeemPoints : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _errorPoints  : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _noMovements  : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly _loyaltyForm: BehaviorSubject<ILoyaltyForm> =
    new BehaviorSubject<ILoyaltyForm>({showForm: true, showConfirm: false, showConfirmed: false});

  private readonly _loyaltyMovements: BehaviorSubject<LoyaltyMovement[]> =
    new BehaviorSubject<LoyaltyMovement[]>([]);

  private readonly _loyaltyRegisteredPrograms: BehaviorSubject<ILoyaltyProgram[]> =
    new BehaviorSubject<ILoyaltyProgram[]>([]);

  private _loyaltyRegisteredProgramsArr: Array<ILoyaltyProgram> = [];

  private readonly _loyaltyPrograms: BehaviorSubject<ILoyaltyProgramsResponse> =
    new BehaviorSubject<ILoyaltyProgramsResponse>({programs: [] });

  private readonly _loyaltySelectedProgram: BehaviorSubject<ILoyaltyProgram> =
    new BehaviorSubject<ILoyaltyProgram>({idProgramRedemption: Numbers.Zero, descriptionRedemption: Strings.EMPTY});

  get isAvailable$() {
    return this._isAvailable.asObservable();
  }

  get isAvailable() {
    return this._isAvailable.getValue();
  }

  set isAvailable(data: boolean) {
    this._isAvailable.next(data);
  }

  get addProgram$() {
    return this._addProgram.asObservable();
  }

  get addProgram() : boolean {
    return this._addProgram.getValue();
  }

  set addProgram(data: boolean) {
    this._addProgram.next(data);
  }

  get noPrograms$() {
    return this._noPrograms.asObservable();
  }

  get noPrograms() : boolean {
    return this._noPrograms.getValue();
  }

  set noPrograms(data: boolean) {
    this._noPrograms.next(data);
  }

  get errorPrograms$() {
    return this._errorPrograms.asObservable();
  }

  get errorPrograms() : boolean {
    return this._errorPrograms.getValue();
  }

  set errorPrograms(data: boolean) {
    this._errorPrograms.next(data);
  }

  get redeemPoints$() {
    return this._redeemPoints.asObservable();
  }

  get redeemPoints() : boolean {
    return this._redeemPoints.getValue();
  }

  set redeemPoints(data: boolean) {
    this._redeemPoints.next(data);
  }

  get loyaltyPrograms$() {
    return this._loyaltyPrograms.asObservable();
  }

  get loyaltyPrograms() : ILoyaltyProgramsResponse {
    return this._loyaltyPrograms.getValue();
  }

  set loyaltyPrograms(data: ILoyaltyProgramsResponse) {
    this._loyaltyPrograms.next(data);
  }

  get selectedTab$() {
    return this._selectedTab.asObservable();
  }

  set selectedTab(data: boolean) {
    this._selectedTab.next(data);
  }

  get loyaltyPoints$() {
    return this._loyaltyPoints.asObservable();
  }

  get loyaltyPoints(): number {
    return this._loyaltyPoints.getValue();
  }

  set loyaltyPoints(data: number) {
    this._loyaltyPoints.next(data);
  }

  get loyaltyForm$() {
    return this._loyaltyForm.asObservable();
  }

  set loyaltyForm(data: ILoyaltyForm) {
    this._loyaltyForm.next(data);
  }

  get loyaltySelectedProgram$() {
    return this._loyaltySelectedProgram.asObservable();
  }

  get loyaltySelectedProgram():ILoyaltyProgram {
    return this._loyaltySelectedProgram.getValue();
  }

  set loyaltySelectedProgram(data: ILoyaltyProgram) {
    this._loyaltySelectedProgram.next(data);
  }

  get loyaltyMovements$() {
    return this._loyaltyMovements.asObservable();
  }

  set loyaltyMovements(data: Array<LoyaltyMovement>) {
    this._loyaltyMovements.next(data);
  }

  get loyaltyRegisteredPrograms$() {
    return this._loyaltyRegisteredPrograms.asObservable();
  }

  get loyaltyRegisteredPrograms(): ILoyaltyProgram[] {
    return this._loyaltyRegisteredPrograms.getValue();
  }

  set loyaltyRegisteredPrograms(data: Array<ILoyaltyProgram>) {
    this._loyaltyRegisteredProgramsArr = data;
    this._loyaltyRegisteredPrograms.next(this._loyaltyRegisteredProgramsArr);
  }

  get errorPoints$() {
    return this._errorPoints.asObservable();
  }

  set errorPoints(data: boolean) {
    this._errorPoints.next(data);
  }

  get noMovements$() {
    return this._noMovements.asObservable();
  }

  set noMovements(data: boolean) {
    this._noMovements.next(data);
  }

  addLoyaltyRegisteredPrograms(data: ILoyaltyProgram) {
    this._loyaltyRegisteredProgramsArr.push(data);
    this._loyaltyRegisteredPrograms.next(this._loyaltyRegisteredProgramsArr);
  }

  removeLoyaltyRegisteredPrograms(idProgramRedemption: number) {
    this._loyaltyRegisteredProgramsArr =
      this._loyaltyRegisteredProgramsArr.filter(
        (program) => program.idProgramRedemption !== idProgramRedemption
      );
    this._loyaltyRegisteredPrograms.next(this._loyaltyRegisteredProgramsArr);
  }

  clearLoyaltyRegisteredPrograms() {
    this._loyaltyRegisteredProgramsArr = [];
    this._loyaltyRegisteredPrograms.next(this._loyaltyRegisteredProgramsArr);
  }

  async getPrograms() {
    this.clearLoyaltyRegisteredPrograms();
    this.addProgram = false;

    const request = new GetRedemptionsRequest({
      IdSession: this.storage.getSession()
    });

    const response =
        await this.baseService.genericPost<GetRedemptionsResponse>(request, {
          isSOA: true,
        });

    if (response?.Redenciones?.length > Length.Empty) {
      const redemptions = new Array<ILoyaltyProgram> ();
      response.Redenciones.forEach(redemption => {
        if(redemption && redemption.IdCuentaRedencion && redemption.Descripcion && redemption.IdConvenio && redemption.NumeroCuenta) {
          redemptions.push({
            idProgramRedemption: redemption.IdCuentaRedencion,
            descriptionRedemption: redemption.Descripcion,
            idAgreement: redemption.IdConvenio,
            membership: redemption.NumeroCuenta,
            type: redemption.AutoRedencion ? TypeRedemption.Automatic : TypeRedemption.None
          });
        }
      });
      this.loyaltyRegisteredPrograms = redemptions;
    } else {
      throw new BusinessError(Strings.SERVICES.UnexpectedMsg, Constants.UNEXPECTED_CODE);
    }
  }
}
