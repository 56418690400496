<ng-container *ngIf="vm$ | async as vm">
  <div class="body-modal-checks-confirmation">
    <div class="col-sm-12 d-flex justify-content-end close" width="auto" height="auto">
    </div>
    <div class="col-sm-12 mt-3">
      <div class="div-title">
        <div class="d-flex justify-content-center">
          <span class="main-color-label"
            [ngClass]="vm.breakpoints.mobile ? 'header-s' : 'header-l wrap'">
            {{activationConfirmation}}
          </span>
        </div>
        <div class="d-flex justify-content-center" [ngClass]="{'padding-confirm': !vm.breakpoints.mobile}">
          <span [ngClass]="vm.breakpoints.mobile ? 'body-s secondary-color' : 'body-s secondary-color'">
            {{dataConfirmation}}
          </span>
        </div>
      </div>
      <div class="mobile-checks-activation" [ngClass]="!vm.breakpoints.mobile?'mt-3':''">
        <div class="pb-2">
          <span [class]="!vm.breakpoints.mobile?'header-s main-color-label'
                          :'header-xs main-color-label'">
            {{operationInformation}}
          </span>
        </div>
        <div [ngClass]="!vm.breakpoints.mobile ? 'pb-2' : '' ">
          <app-info-label headClass="body-s" classes="p-0"
          margin="mt-2 mb-2 margin-hr" [showHr]="true" [head]="checkDataActivation" body="No. de chequera">
          </app-info-label>
        </div>
      </div>
      <div [ngClass]="vm.breakpoints.mobile?'':'margin-top-1'">
        <div class="d-flex justify-content-center" [ngClass]="vm.breakpoints.mobile? 'pt-2':''">
          <button class="btn-ready-modal-check-confirmation btn-confirm border-btn-ok btn-modal-check"
            [ngClass]="isApple?'btn-confirm-apple':''" (click)="continue()"
            [ngClass]="vm.breakpoints.mobile?'button-s':'button-m'">
            {{confirmAndContinue}}
            <img [class]="!vm.breakpoints.mobile? 'img-medium':'img-small'" [src]="chevronRight | cdnImage" alt=">"
              class="close" width="auto" height="auto">
          </button>
        </div>
        <div class="pt-3 d-flex justify-content-center">
          <button class="btn-other-modal-check-confirmation  border-btn-other btn-modal-check"
            [ngClass]="vm.breakpoints.mobile?'button-s':'button-m'" (click)="close(vm.breakpoints.mobile)">
            {{modify}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
