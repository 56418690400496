<div class="" [ngClass]="classes">
  <div *ngIf="body === 'Propósito del pago'">
    <text-ellipsis [text]="head.toString()"
      classes="body-s adjust-label main-color-label"
      [styles]="styles"></text-ellipsis>
  </div>
  <div [ngClass]="headClass" class="{{specialFont}}"
  *ngIf="body !== 'Propósito del pago'" [ngbTooltip]="tooltip ? head.toString() : null">{{ head }}</div>
  <div [ngClass]="bodyClass">{{ body }}</div>
</div>
<hr *ngIf="showHr"  [ngClass]="margin">
