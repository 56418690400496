export class ServiceStrings{
  static readonly LIMIT_MESSAGE = (amount: string) => `El monto de esta operación supera el límite diario establecido
  para esta cuenta o servicio.<br>¿Deseas que <strong>${amount}</strong> sea el nuevo monto límite diario?`;
  static readonly DAILY_LIMIT_EXCEEDED = 'Límite diario excedido';
  static readonly MESSAGE_NO_REGISTERED_SERVICES  = 'No cuentas con servicios agendables registrados';
  static readonly PAY_CREDIT_CARD = 'Pagar TDC';
  static readonly MIN_CREDIT_CARD_AMOUNT_MESSAGE = 'La cantidad a ingresar debe ser mayor a $300';
  static readonly CREDIT_CARD_PAYMENT_RESTRICTION = 'Por el momento no se puede realizar este tipo de pagos con tarjetas de crédito.';
  static readonly NO_CREDIT_CARD_MONTHS = 'No pudimos obtener los meses. Por favor, intenta más tarde';

  static readonly SERVICE_PAYMENT_TIME_TO_APPLY = (time: number) => time > 1 ?
  `El pago se verá reflejado después de ${time} horas, sin embargo, conservará la fecha en que fue realizado.`: time === 1?
  `El pago se verá reflejado después de ${time} hora, sin embargo, conservará la fecha en que fue realizado.` :
  `El pago se verá reflejado de inmediato, sin embargo, se conservará la fecha en que fue realizado.`;

  static readonly SERVICE_TEXT = {
    TitleOther:'Pago rápido',
    SubtitleOther:'Define un límite diario y listo.',
    TextPay:'Agregar pago rápido'
  };

  static readonly ADD_SERVICE = {
    AttValue: 'Compra de Tiempo Aire AT&T',
    UnefonValue: 'Compra de Tiempo Aire Unefón'
  };

  static readonly FILE_SUA = 'Selecciona un archivo .sua';
  static readonly IMPLOC_CREDIT_CARD_PAYMENT_NAME = 'Impuestos locales CDMX';
}
