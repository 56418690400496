<div class="modal drop-shadow-m">
    <div class="d-flex justify-content-end div-image-detail">
        <img role="button" class="close" aria-label="Close" (click)="close()" height="24" width="24"
            [src]="closeIcon | cdnImage" alt="Close" />
    </div>
    <div class="detail">
        <div class="text-center">
            <p class="header-l">{{title}}</p>
            <p class="body-m">Folio de pago: {{modalData.folioTransaccion}}</p>
            <br />
            <p class="header-xxxl text-color">{{modalData.total | currency}}</p>
            <p class="detail-m">{{modalData.fechaPago}}</p>
        </div>
    </div>
    <app-shared-buttons [download]="false" [fileName]="'DetallePago'" [data]="receiptDataRequest"
        [origin]="payment"></app-shared-buttons>

    <div class="div-info">
        <label class="col-12 body-s"> {{modalData.lineaCaptura | quantityFormat: 28}} </label>
        <label class="col-12 detail-s">Línea de captura</label>
        <hr class="my-2">

        <label class="col-12 body-s">{{modalData.numeroCuenta | maskAccount: 4}}</label>
        <label class="col-12 detail-s">Con cargo a tu cuenta</label>
        <hr class="my-2">
    </div>
    <div class="button-mobile-container">
        <div class="btn-container py-2">
            <button class="button-m colored-btn btn-generic" (click)="otherOperation()">
                Otra operación
            </button>
        </div>
        <div class="btn-container">
            <button class="button-m  close-btn text-close btn-generic w-100" (click)="close()">
                Cerrar
            </button>
        </div>
    </div>
</div>
