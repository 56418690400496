import { Component, Input } from '@angular/core';
import { NotificationModel } from 'src/app/interface/NotificationModel';
import { Char, Constants, Numbers, Position, Regex } from 'src/core/constants/Constants';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';

@Component({
  selector: 'app-item-notification',
  templateUrl: './item-notification.component.html',
  styleUrls: ['./item-notification.component.css']
})
export class ItemNotificationComponent {
  @Input() notification: NotificationModel;
  stateUnread: string = Constants.STATES_NOTIFICATIONS.Unread;
  stateRead: string = Constants.STATES_NOTIFICATIONS.Read;
  stateSelected: string = Constants.STATES_NOTIFICATIONS.Selected;

  constructor() {}

  getDateOnText(registrationDate: string, shippingDate: string) {
    if (registrationDate && shippingDate) {
      return Utils.convertDateToText(registrationDate)
        .concat(Char.WhiteSpace)
        .concat(shippingDate.substring(Position.Eleven, Position.Sixteen))
        .concat(Char.WhiteSpace).concat(Strings.HOURS);
    }
    return Strings.EMPTY;
  }
}
