<div class="GeneralDIV" id="generalSAT">
    <div class="ContenedorSAT" id="divSAT">
        <header class="w-100">
            <app-header-transfer-card [title]="title" [subtitle]="subtitle" [opcional]="ligaInformacion"
                [urlLocation]="'/pagoreferenciadosat'"></app-header-transfer-card>
        </header>

        <body class="w-100 bg-white">
            <div class="VersionABM">
                <label>Versión AWT.</label>
                <br>
                <label>ver. 5.1 20190716 [Derechos - l ABM]</label>
            </div>
            <br>
            <div id="ContentPago">
                <div class="formSATPr">
                    <div class="row">
                        <form>
                            <div class="mb-3">
                                <label for="rfc" class="form-label formSAT ">RFC:</label>
                                <input type="text" class="form-control inputs" id="rfc">
                            </div>
                            <div class="mb-3">
                                <label for="confirmaRFC" class="form-label formSAT">Confirma RFC:</label>
                                <input type="text" class="form-control inputs" id="confirmaRFC">
                            </div>


                            <div class="divBotones">
                                <div>
                                    <button class="ContinuarSinRFC"> Continuar</button>
                                </div>
                                <div>
                                    <button class="ContinuarSinRFC">Sin RFC</button>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="curp" class="form-label formSAT">CURP:</label>
                                <input type="text" class="form-control inputs" id="curp">
                            </div>
                            <div class="mb-3">
                                <label for="aPaterno" class="form-label formSAT">Apellido paterno:</label>
                                <input type="text" class="form-control inputs" id="aPaterno">
                            </div>

                            <div class="mb-3">
                                <label for="aMaterno" class="form-label formSAT">Apellido materno:</label>
                                <input type="text" class="form-control inputs" id="aMaterno">
                            </div>
                            <div class="mb-3">
                                <label for="nombre" class="form-label formSAT">Nombre:</label>
                                <input type="text" class="form-control inputs" id="nombre">
                            </div>
                            <div class="mb-3">
                                <label for="dependencias" class="form-label formSAT">Dependencias y Entidades:</label>
                                <input type="text" class="form-control inputs" id="dependencias">
                            </div>
                            <br />
                        </form>
                        <form>
                            <div class="RadioDist">
                                <div class="form-check-inline">
                                    <label class="form-check-label formSAT" for="radio">
                                        <input type="radio" value="No aplica" class="Iradio" name="Periodo"
                                        id="radio">No aplica
                                        periodo
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="mount" class="form-check-label formSAT">
                                        <input type="radio" value="Mensual" class="Iradio" name="Periodo"
                                        id="mount">Mensual
                                    </label>
                                </div>
                            </div>

                            <div class="RadioDist">
                                <div class="form-check-inline">
                                    <label for="bimount" class="form-check-label formSAT">
                                        <input type="radio" value="Bimestral" class="Iradio" name="Periodo"
                                        id="bimount">Bimestral
                                    </label>
                                </div>

                                <div class="form-check-inline">
                                    <label for="trimount" class="form-check-label formSAT">
                                        <input type="radio" value="Trimestral" class="Iradio" name="Periodo"
                                        id="trimount">Trimestral
                                    </label>
                                </div>
                            </div>


                            <div class="RadioDist">
                                <div class="form-check-inline">
                                    <label for="cuatmount" class="form-check-label formSAT">
                                        <input type="radio" value="Cuatrimestral" class="Iradio"
                                            name="Periodo" id="cuatmount">Cuatrimestral
                                    </label>
                                </div>
                                <div class="form-check-inline">
                                    <label for="sem" class="form-check-label formSAT">
                                        <input type="radio" value="Semestral" class="Iradio" name="Periodo"
                                        id="sem">Semestral
                                    </label>
                                </div>
                            </div>

                            <div class="RadioDist">
                                <div class="form-check-inline">
                                    <label for="ejer" class="form-check-label formSAT">
                                        <input type="radio" value="Del ejercicio" class="Iradio" name="Periodo"
                                        id="ejer">Del
                                        ejercicio
                                    </label>
                                </div>
                            </div>

                            <br />
                            <div id="Saldo" class="form-group row">
                                <label for="saldo" class="col-sm-5 col-form-label formSAT">Saldo a favor</label>
                                <div id="inputTotal" class="col-sm-7">
                                    <input type="text" class="form-control inputs" id="saldo">
                                </div>
                            </div>

                            <br />

                            <div class="row tituloTabla">
                                <div class="col-4"></div>
                                <div class="col-4">
                                    <label class="formSAT"> DPA</label>
                                </div>
                                <div class="col-4">
                                    <label class="formSAT">IVA ACTOS ACCIDENTA</label>
                                </div>
                            </div>

                            <div class="form-group  DistDPA">
                                <label class="col-form-label formSAT" for="ImporteDPA"> Importe</label>
                                <div>
                                    <input type="text" class="form-control inputsdpa" id="ImporteDPA">
                                </div>
                                <div>
                                    <input type="text" class="form-control inputsdpa" id="ImporteIVA">
                                </div>
                            </div>
                            <div class="form-group  DistDPA">
                                <label class="col-form-label formSAT" for="RecargosDPA"> Recargos</label>
                                <div>
                                    <input type="text" class="form-control inputsdpa" id="RecargosDPA">
                                </div>
                                <div>
                                    <input type="text" class="form-control inputsdpa" id="RecargosIVA">
                                </div>
                            </div>
                            <div class="form-group  DistDPA">
                                <label class="col-form-label formSAT" for="MultasDPA">Multas por corrección</label>
                                <div>
                                    <input type="text" class="form-control inputsdpa" id="MultasDPA">
                                </div>
                                <div>
                                    <input type="text" class="form-control inputsdpa" id="MultasIVA">
                                </div>
                            </div>
                            <div class="form-group  DistDPA">
                                <label class="col-form-label formSAT" for="CompensacionesDPA"> Compensaciones</label>
                                <div>
                                    <input type="text" class="form-control inputsdpa" id="CompensacionesDPA">
                                </div>

                            </div>
                            <div class="form-group  DistDPA">
                                <label class="col-form-label formSAT" for="CantidadDPA">Cantidad a pagar</label>
                                <div>
                                    <input type="text" class="form-control inputsdpa" id="CantidadDPA">
                                </div>
                                <div>
                                    <input type="text" class="form-control inputsdpa" id="CantidadIVA">
                                </div>
                            </div>
                        </form>



                    </div>

                    <div class="row">
                        <form>

                            <div id="Derecha">
                                <div class="mb-3 Derecha">
                                    <label for="detalle" class="form-label formSAT"> Detalle</label>
                                    <textarea class="textareaD" (resize)="false" name="" id="detalle" cols="30"
                                        rows="10"></textarea>
                                </div>
                                <div>
                                    <div class="mb-3 Derecha">
                                        <label for="total" class="form-label formSAT" style="text-align: center;">Total
                                            efectivamente pagado</label>
                                        <input type="text" class="form-control inputs" id="total" style="width: 88%;">
                                    </div>
                                </div>

                                <div id="BOTONES">
                                    <div class="divBotonesC">
                                        <div>
                                            <button class="botonConcepto fuenteConcepto">Agregar Concepto</button>
                                        </div>
                                        <div>
                                            <button class="botonConcepto fuenteConcepto">Eliminar Concepto</button>
                                        </div>
                                    </div>

                                    <div class="divBotonesC" style="padding-top: 10px;">
                                        <div>
                                            <button class="botonConcepto fuenteConcepto">Confirmar Datos</button>
                                        </div>
                                        <div>
                                            <button class="botonConcepto fuenteConcepto">Borrar Todo</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="enviar">
                <button class="btnEnviar txtEnviar"> Enviar</button>

            </div>
        </body>
    </div>
</div>
