import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { map, SubscriptionLike } from 'rxjs';
import { ClarificationList, FacepassList, HistoryList, LoyaltyPointsList, PortabilityList, ProgrammedOperationsList, SearchList } from 'src/app/interface/ListSiderbar';
import { Browser, Constants, HtmlEvent, InputTypes, Position, Regex } from 'src/core/constants/Constants';
import { IconResources } from 'src/core/constants/IconResources';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { environment } from 'src/environments/environment';
import { SidebarService } from 'src/app/shared/sidebar.service';
import { LoyaltyService } from 'src/app/services/loyalty.service';
import { FacePassLoginService } from 'src/app/services/face-pass-login.service';
import { PortabilityService } from 'src/app/services/portability.service';
import { Utils } from 'src/core/utils/utils';
import { Numbers } from 'src/core/constants/Numbers';
import { Keys } from 'src/core/constants/Keys';
import { ResponsiveService } from 'src/app/shared/responsive.service';

@Component({
  selector: 'app-main-search',
  templateUrl: './main-search.component.html',
  styleUrls: ['./main-search.component.css']
})
export class MainSearchComponent implements OnInit, OnDestroy{
  vm$ = this.responsiveService.observe.pipe(
    map((breakpoints) => ({ breakpoints }))
  );
  @ViewChild('searchInput') searchInput: ElementRef;
  @Output() show: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showModalChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() showModal: boolean = true;
  optionsSearch: typeof SearchList = SearchList;
  search: string = Resources.SEARCH;
  close: string = IconResources.CANCEL_GRAY;
  referenceLength: number = Constants.REFERENCE_LENGTH;
  placeholder: string = Constants.SEARCH_LABEL;
  showSearches: boolean = false;
  regexSearch: RegExp = Regex.OnlyText;
  withoutResult: string = Strings.WITHOUT_RESULTS;
  loyaltyServiceSL: SubscriptionLike;
  portabilityServiceSL: SubscriptionLike;
  completeOptions: typeof SearchList;
  selectedOptionIndex: number = Position.Last;
  showAppSearch: boolean = false;
  isSelectedOption: boolean = false;
  showSearchCleaner: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly sidebarService: SidebarService,
    private readonly loyaltyService: LoyaltyService,
    private readonly facePassLoginService: FacePassLoginService,
    private readonly portabilityService: PortabilityService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly responsiveService: ResponsiveService
  ) { }

  ngOnInit(): void {  
    this.optionsSearch = this.facePassLoginService.isFacePassLogin.value ? this.optionsSearch.concat(FacepassList) : this.optionsSearch;
    this.optionsSearch = !environment.hiddenComponents.history? this.optionsSearch.concat(HistoryList): this.optionsSearch;
    this.optionsSearch = !environment.hiddenComponents.programmedOperations? this.optionsSearch.concat(ProgrammedOperationsList) : this.optionsSearch;
    this.optionsSearch = !environment.hiddenComponents.clarifications ? this.optionsSearch.concat(ClarificationList): this.optionsSearch;
    this.completeOptions = this.optionsSearch;
    this.portabilityServiceSL = this.portabilityService.showPortability.subscribe((isAvailable)=>{
      if(isAvailable){
        this.compareObjets(PortabilityList);
      }
    });
    this.loyaltyServiceSL = this.loyaltyService.isAvailable$.subscribe((isAvailable)=>{
      if(isAvailable){
         this.compareObjets(LoyaltyPointsList);
      }
    });
      document.addEventListener(HtmlEvent.MouseDown, (event) =>this.optionListDisplay(event, HtmlEvent.MouseUp));
      this.getBrowser();
  }  

  compareObjets(list: typeof SearchList){
    const auxLoyaltyList = list.every(item =>
      this.optionsSearch.some(option => option.path === item.path)            
    );
    if(!auxLoyaltyList){
        this.optionsSearch = this.optionsSearch.concat(list);
    }
    this.completeOptions = this.optionsSearch;
  }
  navigate(dataSearch: string){
    this.searchInput.nativeElement.blur();
    if(dataSearch){
      dataSearch = Utils.removeAccents(dataSearch).gfiCapitalize(Strings.EMPTY);
      const data = this.completeOptions.find((element) => Utils.removeAccents(element.option).gfiCapitalize(Strings.EMPTY) === dataSearch);
      if(data.path && this.router.url !== data.path){
        this.router.navigate([data.path]);
        this.sidebarService.clearData(this.router.url);
      }
      this.showSearches= false;
      this.searchInput.nativeElement.value = Strings.EMPTY;
      this.show.emit(false); 
      this.showAppSearch = false;
      this.optionsSearch = this.completeOptions;
    }
  }

  filterOperation(data: string){
    if(data){
      data = Utils.removeAccents(data);
      this.optionsSearch = this.completeOptions.filter((val) => Utils.removeAccents(val.option.toLowerCase()).includes(data.toLowerCase()) === true );
      this.showSearches = true;
    }else{
      this.showSearches= false;
    }
    this.selectedOptionIndex = Position.Last;
  }

  ngOnDestroy(){
    this.loyaltyServiceSL?.unsubscribe();
    this.portabilityServiceSL?.unsubscribe();
    document.removeEventListener(HtmlEvent.MouseDown, this.optionListDisplay);
  }
  
  optionListDisplay(event?: Event, typeEvent?: string) {
    if(!this.showModal){
      this.showModal = true;
      this.showModalChanged.emit(this.showModal);
    }
    const container = document.getElementById(InputTypes.searchContainer);
    const optionsList = document.getElementById(InputTypes.listSearch);
    if (optionsList) {
      optionsList.style.display = container.contains(event.target as HTMLElement) ? InputTypes.Block : InputTypes.None;
      if (optionsList.style.display === InputTypes.None && typeEvent === HtmlEvent.MouseUp) {
        const input = document.getElementById(InputTypes.searchInput) as HTMLInputElement;
        input.value = Strings.EMPTY;
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  handleKeydown(event: KeyboardEvent) {
    switch (event.key) {
      case Keys.KEY_DOWN:
        this.setSelectedOptionIndex(this.selectedOptionIndex + Numbers.One);
        break;
      case Keys.KEY_UP:
        this.setSelectedOptionIndex(this.selectedOptionIndex - Numbers.One);
        break;
      case Keys.KEY_ENTER:
        if (this.selectedOptionIndex >= 0 && this.selectedOptionIndex < this.optionsSearch.length) {
          this.setOption(this.optionsSearch[this.selectedOptionIndex].option);
        }
        break;
    }
  }

  setSelectedOptionIndex(index: number) {
    if (index >= Position.Last && index < this.optionsSearch.length) {
      this.selectedOptionIndex = index;
    }
  }

  setOption(dataSearch: string) {      
    this.isSelectedOption = true;
    this.searchInput.nativeElement.value = dataSearch;
    this.showSearches = false;
    this.navigate(dataSearch);
  }

  closeSearchInput(){
    this.showAppSearch = false;
    this.showSearches = false;
    this.show.emit(false); 
  }

  clearSearchList(){
    setTimeout(() => {
      if(!this.isSelectedOption){
        this.showSearches= false;
        this.changeDetectorRef.detectChanges();
      }     
      this.isSelectedOption = false;    
    },Numbers.TwoHundred);
  }

  getBrowser() {
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent.indexOf(Browser.Firefox.toLowerCase()) > Constants.NOT_FOUND_ITEM) {
      this.showSearchCleaner = true;
    }
  }

  clearSeach(){
    const input = document.getElementById(InputTypes.searchInput) as HTMLInputElement;
    input.value = Strings.EMPTY;
    input.focus();
  }

}
