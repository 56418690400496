import { PathModel } from './../../models/PathModel';

interface ISendMessageRequest {
    Recipients: string;
    PersonId: number;
    Comments: string;
    OperationId: number;
    CopyToMe: boolean;
    WithCopy: string;
    AccountNumber: string;
}

export class SendMessageRequest {

  constructor(public data: ISendMessageRequest){ }

  get endpoint(): PathModel{
    return PathModel.SendMessage;
  }

}
