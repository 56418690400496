import { Component, ChangeDetectionStrategy } from '@angular/core';
import { combineLatestWith, map } from 'rxjs';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { SidebarService } from 'src/app/shared/sidebar.service';
import { Constants } from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';

@Component({
  selector: 'app-modal-technical-requirements',
  templateUrl: './modal-technical-requirements.component.html',
  styleUrls: ['./modal-technical-requirements.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalTechnicalRequirements {
  vm$ = this.responsiveService.observe.pipe(
    combineLatestWith(this.sidebarService.stateObservable),
    map(([breakpoints, show]) => ({
      breakpoints,
      show
    }))
  );

  btnLabel = Constants.DEFAULT_BTN_LABEL;
  removeOperation = Resources.CLOSE_CIRCLE_BLUE;
  iconChrome = Resources.CHROME;
  iconEdge = Resources.EDGE;
  iconSafari = Resources.SAFARI;
  iconFireFox = Resources.FIREFOX;
  iconAndroid = Resources.ANDROID;
  iconOpera = Resources.OPERA;
  iconWindows = Resources.WINDOWS;
  iconMac = Resources.MAC;

  constructor(
    private readonly modalService: ModalService,
    private readonly responsiveService: ResponsiveService,
    private readonly sidebarService: SidebarService
  ) {}
  ngOnInit(): void {}

  actionModalRequirements(action:boolean) {
    this.modalService.close(action);
  }

  close() {
    this.modalService.close();
  }
}
