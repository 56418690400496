import { PathModel } from 'src/app/models/PathModel';

export interface IBureauAlertsRequest {
    medioAceptacion: string;
    correo?: string;
    otp: string;
}

export class BureauAlertsRequest {
    constructor(public data: IBureauAlertsRequest) { }

    get endpoint(): PathModel {
        return PathModel.BureauAlerts;
    }
}