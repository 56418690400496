import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatestWith, map, tap } from 'rxjs';
import { AccountCardService } from 'src/app/services/account-card.service';
import { NotifyAmountService } from 'src/app/services/notify-amount.service';
import { NotifyDateService } from 'src/app/services/notify-date.service';
import { ModalService } from 'src/app/shared/modal.service';
import { ResponsiveService } from 'src/app/shared/responsive.service';
import { Constants, ElementsHTML, Numbers } from 'src/core/constants/Constants';
import { PATH } from 'src/core/constants/Path';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { Utils } from 'src/core/utils/utils';
import { AccountInfoStatusService } from 'src/app/services/account-info-status.service';
import { CodeflexService } from 'src/app/services/codeflex.service';
import { AccountModel } from 'src/app/interface/AccountModel';
import { IAmount } from 'src/app/interface/IAmount';
import { GetWithdrawalsResponse } from 'src/app/interface/dto/GetWithdrawalsResponse';
import { GenericResponse } from 'src/app/interface/dto/GenericResponse';
import { MaskAccountPipe } from 'src/app/pipes/mask-account.pipe';
import { AlertService } from 'src/app/services/alert.service';
import { AccountsStatusService } from 'src/app/services/accounts-status.service';
import { CodeflexElementsHTML } from 'src/core/constants/CodeflexConstants';
import { DateUtils } from 'src/core/utils/dateUtils';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';
import { ICardAccount } from 'src/app/interface/ICardAccount';
import { NotifyClearFormService } from 'src/app/services/notify-clear-form.service';
import { CodeflexLayoutService } from 'src/app/services/codeflex-layout.service';
import { CodeflexStrings } from 'src/core/constants/CodeflexStrings';
import { GetDepositResponse } from 'src/app/interface/dto/GetDepositResponse';
import { Format } from 'src/core/constants/FormatDate';

@Component({
  selector: 'app-operation-success',
  templateUrl: './operation-success.component.html',
  styleUrls: ['./operation-success.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OperationSuccessComponent implements OnInit{
  contentMargin: string = ElementsHTML.ContentMargin;
  check: string = Resources.GREEN_CHECK_FILL;
  account: AccountModel;
  selectedCard!: ICardAccount;
  cardTransfer!: string;
  amount: IAmount;
  message: string = Strings.OPERATION_SUCCESSFUL;
  folio: string = Strings.EMPTY;
  operationDate: string = Strings.EMPTY;
  response: GenericResponse<GetWithdrawalsResponse>| GenericResponse<GetDepositResponse>;
  errorMsg: string = Strings.EMPTY;
  otherOperation: string = PATH.Codeflex;
  numberAccount: string = Strings.EMPTY;
  captureImage: { name: string, element: HTMLElement };
  imageName: string = Strings.EMPTY;
  operationCodeflex: string = Strings.EMPTY;
  info: string = Strings.EMPTY;

  vm$ = this.responsiveService.observe.pipe(
    tap((breakpoints) =>
      breakpoints.mobile
        ? (this.contentMargin = ElementsHTML.ContentMargin)
        : (this.contentMargin = ElementsHTML.ContentMarginBreakpoints)
    ),
    combineLatestWith(
      this.accountCardService.accountCard$,
      this.notifyDateService.dateNgbStruct,
      this.accountInfoService.account$,
      this.accountCardService.mainAccountCodeflex$,
      this.notifyAmountService.amount$,
      this.codeflexLayoutService.titleOperation$
    ),
    map(([breakpoints, selectedCard, account, accounts, amount, , operation]) => {
      this.selectedCard = selectedCard;
      this.operationCodeflex = operation;
      return{
      breakpoints,
      selectedCard,
      date : this.notifyDateService.dateStruct.value ? this.notifyDateService.dateFormat : Strings.EMPTY,
      account,
      accounts,
      amount,
      operation
      };
    })
  );

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly accountCardService: AccountCardService,
    private readonly notifyDateService: NotifyDateService,
    private readonly router: Router,
    private readonly notifyAmountService: NotifyAmountService,
    private readonly modalService: ModalService,
    private readonly accountInfoService: AccountInfoStatusService,
    private readonly codeFlexService: CodeflexService,
    private readonly changeDetectorRef : ChangeDetectorRef,
    private readonly alertService: AlertService,
    private readonly accountsStatusService: AccountsStatusService,
    private readonly notifyClearFormService: NotifyClearFormService,
    private readonly codeflexLayoutService: CodeflexLayoutService
  ) {}

  async ngOnInit() {
    NavigationUtils.fixNavigation(this.changeDetectorRef,[PATH.Codeflex, PATH.CodeflexCompletedWithdrawal]);
    try{
      if(this.notifyAmountService.amount.amount===Strings.EMPTY){
        this.home();
      }else{
        this.account = this.accountInfoService.account;
        this.numberAccount = new MaskAccountPipe().transform(this.account.numeroCuenta, Numbers.Four);
        this.cardTransfer = this.accountCardService.accountCard.cardNumber;
        this.amount = this.notifyAmountService.amount;
        await this.getDataModal();
        this.info = this.codeflexLayoutService.titleOperation !== CodeflexStrings.CODEFLEX_ACTIONS[Numbers.Zero] ?
        CodeflexStrings.messages.Deposit : CodeflexStrings.messages.Withdrawal(this.selectedCard.accountType);
      }
      const date = new Date();
      this.imageName = formatDate(date,Format.YearMonthDayTime, Constants.LOCALE_ES_MX);
      this.captureImage = {
        name: this.imageName,
        element: document.getElementById(CodeflexElementsHTML.SuccessfulOperation)
      };
      this.changeDetectorRef.detectChanges();
    }catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.alertService.showPopupAlert({
        message: Utils.SERVICE_ERROR_MSG(errorDetails.msg,errorDetails.code),
        header: Strings.MSG_POPUP_TITLE,
        btnLabel: Strings.MSG_POPUP_ACCEPT,
        imgHead: Resources.CLOSE_ICON_RED,
        btnExit: false
      },{
        onClose:() => {
          this.home();
        },
        onSuccess:() => {
          this.home();
        }
      });
    }
  }

  home() {
    this.accountsStatusService.status = {value: false, isProductSelected: Strings.EMPTY, id: Numbers.Zero};
    this.router.navigate([PATH.Home]);
  }

  @HostListener('window:popstate')
  otherOperationButton() {
    if(!this.errorMsg){
      this.notifyClearFormService.clearFormData = { value: true };
      this.codeflexLayoutService.updateAccounts = this.codeflexLayoutService.titleOperation === CodeflexStrings.CODEFLEX_ACTIONS[Numbers.Zero];
      this.router.navigate([PATH.Codeflex]);
    }else{
      this.notifyClearFormService.clearFormData = { value: false };
    }
  }

  async getDataModal() {
    try {
      if(this.operationCodeflex === CodeflexStrings.CODEFLEX_ACTIONS[Numbers.One]){
        await this.getWithdrawalsCodeflex();
      }else{
        await this.getDepositsCodeflex();
      }
      const adjustDate = new Date(this.response.datos.fechaOperacion);
      adjustDate.setDate(adjustDate.getDate() + Numbers.One);
      this.response.datos.fechaOperacion = Utils.formatDate(adjustDate);
      const date = new Date();
      this.operationDate = formatDate(date, Format.DayMonthYearCompleteH, Constants.LOCALE_ES_MX);
    } catch (error) {
      const errorDetails = Utils.getErrorMsg(error);
      this.errorMsg = Utils.SERVICE_ERROR_MSG(errorDetails.msg, errorDetails.code);
      this.changeDetectorRef.detectChanges();
    }
  }

  async getWithdrawalsCodeflex(){
    let days: number;
    const dateEntered = this.notifyDateService.dateStructData;
    const dateUser = new Date(dateEntered.year, dateEntered.month - Constants.MONTH.ONE, dateEntered.day);
    const dateCurrently = new Date();
    const millisecondsDifference = DateUtils.differenceBetweenDates(dateUser, dateCurrently);
    days = DateUtils.millisecondsToDays(millisecondsDifference);
    this.response = await this.codeFlexService.getWithdrawals(this.account.idCuentaProducto,
    this.selectedCard.productId, this.amount.amount, days);
    this.response.mensaje = Strings.OPERATION_SUCCESSFUL;
    this.folio = this.response.datos.folio.toString();
  }

  async getDepositsCodeflex(){
    this.response = await this.codeFlexService.getDeposit(this.account.idCuentaProducto,
      this.selectedCard.productId, this.amount.amount);
      this.response.mensaje = Strings.OPERATION_SUCCESSFUL;
      this.folio = this.response.datos.idTransaccion.toString();
  }

  ngOnDestroy() {
    this.modalService.close();
    if(this.errorMsg === Strings.EMPTY){
      this.notifyDateService.dateStructData = undefined;
    }
  }

}
