<ng-container *ngIf="vm$ | async as vm">
  <div class="p-3">


  <div class="d-flex flex-row-reverse">
    <img ngbAutoFocus height="auto" width="auto" class="cursor-pointer"
      (keydown.enter)="$event.preventDefault()" (click)="close()"
      [src]="closeCircleBlue | cdnImage" alt="Cerrar" />
  </div>
  <ng-container *ngIf="modalData.selectedOption === 'movements detail'; else StatementAccount">
    <div class="d-flex flex-column detail-box-position"
      [ngClass]="vm.breakpoints.mobile ? 'mx-3 pb-4 pt-3' : 'mx-auto pb-3 pt-4'">
      <div class="d-flex flex-column align-items-start gap-3 header-m"
        [ngClass]="vm.breakpoints.mobile ? 'mb-2 color-label-general' : 'detail-title-position'">
        {{movementDetail}}
      </div>
      <div class="d-flex flex-column gap-3">
        <div [ngClass]="vm.breakpoints.mobile ? 'body-xs' : 'body-s'">{{choosePeriod}}</div>
        <div>
          <div [ngClass]="vm.breakpoints.mobile ? 'mb-4' : 'mb-3'">
            <app-enter-date (outputDate)="getStartDate($event)" [minDate]="minimumConsultation" [maxDate]="maxStartDate"
              [title]="false" [currentDate]="currentDate" [showCard]="false"
              [id]="'startdate'" [labelInput]="period" [aforeDocumentFlow]="true" [isDateRange]="isDateRange"
              (isDateError)="isDateError($event)" [ngClass]="showErrorSameDay? 'input-error-border':''">
            </app-enter-date>
            <span *ngIf="showErrorSameDay" class="detail-m label-red mt-1">{{selectDate}}</span>
          </div>
          <div>
            <app-enter-date (outputDate)="getEndDate($event)" [minDate]="minimumConsultation" [maxDate]="maxStartDate"
              [title]="false" [currentDate]="currentDate" [showCard]="false"
              [id]="'enddate'" [labelInput]="to" [aforeDocumentFlow]="true" [isDateRange]="isDateRange"
              (isDateError)="isDateError($event)" [ngClass]="showErrorDate? 'input-error-border':''">
            </app-enter-date>
          </div>
          <span *ngIf="showErrorDate" class="detail-m label-red mt-1">{{errorDateLabel}}</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #StatementAccount>
    <div class="d-flex flex-column  gap-3 mb-2" [ngClass]="{
    'py-4' : vm.breakpoints.mobile && monthsToShow.length !== 1,
    'p-4' : !vm.breakpoints.mobile && monthsToShow.length !== 1,
    'pt-4' : vm.breakpoints.mobile && monthsToShow.length === 1,
    'pt-4 px-4': !vm.breakpoints.mobile && monthsToShow.length === 1
  }">
      <div class="title-account-status mb-3"><span>{{accountStatus}}</span></div>
      <div class="d-flex flex-column gap-2">
        <span class="subtitle-account-status">{{choosePeriod}} </span>
      </div>
      <app-input-template [input]="inputConfig[0]" [form]="formConsultationAfore">
        <div class="select-container">
          <select name="" id="" #period class="cursor-pointer select-container select form-select"
          (keydown.enter)="$event.preventDefault()" (click)="onChange(period.value)">
            <option class="dropdown-item m-0" *ngFor="let item of monthsToShow" [value]="item">{{item}}</option>
          </select>
        </div>
      </app-input-template>
    </div>
  </ng-template>

  <div class="btn-container d-flex justify-content-center">
    <button class="colored-btn modals-btn" [ngClass]="vm.breakpoints.mobile ? 'button-s' : 'button-m mb-4'"
      (keydown.enter)="$event.preventDefault()"
      (click)="downloadFile()" [disabled]="disabled"
      [class.disabled-btn]="disabled">
      {{msgButton}}
    </button>
  </div>
</div>
</ng-container>
